<div class="pop-up">
        <div class="">
            <span  class="vertical-line mx-0 mx-md-3"> </span>
             <span class="header mb-0">{{screen == 'VDI'? 'VDI' : 'VM'}}</span> 
            <!-- <span (click)="selectedOption('Yes')" class="material-icons close-icon">
                close
              </span> -->
     </div>
     <hr class="margin-class" />
        <div class="popup-container">
           
            <div class="" >
                <h3 class="mb-3 col-md-12 mt-2" *ngIf="screen == 'VDI'">Your selected VDI's process has been initiated.Kindly refresh the page after few seconds.</h3>
                <h3 class="mb-3 col-md-12 mt-2" *ngIf="screen == 'VM-Restore'">Your {{reqID}} for VM restore process has been initiated.Kindly refresh the page after 5 mins.</h3>
                <div class="text-right">
                    <div class="row" >
                        <div class="col-md-12 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="selectedOption('Yes')">OK</button>
                            </div>
                        <!-- <div class="col-md-3 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="popUpClose('No')">No</button>
                            </div> -->
                    </div>
                </div>
            </div>
           
        </div>
    </div>