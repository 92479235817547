import { Component, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';
import { Logger } from 'msal';
export interface vmTool {
  toolName: string;
  ID: string;
  azureId: string;
  db_id: string;
  name: string;
  description: string;
  Vnet: string;
  toolGroup: string;
  toolGroup_id: string;
  Subnet: string;
  ResourceGroup: string;
  VMResourceGroup: string;
  azure_subscription_id: string;
}

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}


@Component({
  selector: 'app-admin-vm',
  templateUrl: './admin-vm.component.html',
  styleUrls: ['./admin-vm.component.scss']
})
export class AdminVmComponent implements OnInit, CanComponentLeave {

  showVmForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  checklist: any;
  masterSelected: boolean;

  VdiConfig_details: any = [];
  check: any = []

  checkedList: any;
  project_selectedcheckbox = false;


  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];
  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  AzuredrpDisable = false;
  toolGrDisable = false;
  nameDisable = false;
  ResourceDisable = false;
  VnetDisable = false;
  SubDisable = false;
  SCMArray = [];
  project_toolGrId: string;
  vmObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;

  ResourceGroupArr = [];
  VnetArr = [];
  SubnetArr = [];
  project_VNetResourceGroup: string;
  project_VMResourceGroup: string;
  project_Vnet: string;
  project_Subnet: string;
  SubscriptionList: Subscription_iddropdown[] = [];
  Role: string = "";
  itemSelected: boolean = false;
  selectedTool_group_name: any;
  nextUrl: any;
  canLeave(url): boolean {
    // console.log(url);

    // debugger
    this.nextUrl = url
    if (this.nextUrl == "/admin-scm-tool") {
      return true
    }
    else {
      if ((this.toolsGroup.Vnetdd == '' || this.toolsGroup.Vnetdd == undefined) &&
        (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
        (this.toolsGroup.description == '' || this.toolsGroup.description == undefined) &&
        (this.toolsGroup.rgdd == '' || this.toolsGroup.rgdd == undefined) &&
        (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
        && (this.toolsGroup.Subnetdd == '' || this.toolsGroup.Subnetdd == undefined)) {
        return true
      }
      if ((this.toolsGroup.Vnetdd != '' || this.toolsGroup.Vnetdd != undefined) &&
        (this.toolsGroup.toolGr != '' || this.toolsGroup.toolGr != undefined) &&
        (this.toolsGroup.description != '' || this.toolsGroup.description != undefined) &&
        (this.toolsGroup.rgdd != '' || this.toolsGroup.rgdd != undefined) &&
        (this.toolsGroup.nameInstance != '' || this.toolsGroup.nameInstance != undefined)
        && (this.toolsGroup.Subnetdd != '' || this.toolsGroup.Subnetdd != undefined)) {
        let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
        return false
      }
    }


    return true
  }

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) {
    this.masterSelected = false;

  }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if (this.Role == "Admin") {
      this.getTypeT();
      this.dtOptions = {
        order: []
      };
      var PopupToolGroup = localStorage.getItem('Popup_Open_Vm')
      if (PopupToolGroup == "true") {
        this.showVmForm = true;
        var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Vm')
        this.toolsGroup.toolGr = Popup_NewToolGroupID;
        // console.log("this.toolsGroup.toolGr");
        //console.log(this.toolsGroup.toolGr);
        var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Vm')
        this.project_toolGrName = Popup_NewToolGroupName;
        localStorage.setItem("Popup_Open_Vm", "false");
      } else {
        this.showVmForm = false;
      }
      this.getSubscriptionList();
      this.GetVmtableDetails();
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
      });
    }
  }

  backNavigation() {
    if ((this.toolsGroup.Vnetdd == '' || this.toolsGroup.Vnetdd == undefined) &&
      (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined) &&
      (this.toolsGroup.rgdd == '' || this.toolsGroup.rgdd == undefined) &&
      (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
      && (this.toolsGroup.Subnetdd == '' || this.toolsGroup.Subnetdd == undefined)) {
      this.router.navigate(["/admin-catalog-config"])
    }
    else {
      let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
    }
  }

  GetVmtableDetails() {

    this.spinner.show();
    this.apiService.Admin_VmToolGrTableDetails().subscribe((res: any) => {
      // 
      // console.log(res,"Network VM Details")
      ////debugger;
      res.forEach(member => {

        let VMResourceGroupAssign;
        if (!member.tool_instance_details.network_config_tool_instance.vm_resource_group) {
          VMResourceGroupAssign = member.tool_instance_details.network_config_tool_instance.resource_group
        } else {
          VMResourceGroupAssign = member.tool_instance_details.network_config_tool_instance.vm_resource_group
        }

        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "azureId": member.tool_instance_details.network_config_tool_instance.azure_tool_instance_id,
          "azure_subscription_id": member.tool_instance_details.network_config_tool_instance.azure_subscription_id,
          "db_id": member._id,
          "name": member.tool_instance_details.network_config_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.network_config_tool_instance.description,
          "Vnet": member.tool_instance_details.network_config_tool_instance.vnet,
          "Subnet": member.tool_instance_details.network_config_tool_instance.subnet,
          "ResourceGroup": member.tool_instance_details.network_config_tool_instance.resource_group,
          "VMResourceGroup": VMResourceGroupAssign,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }

  getSubscriptionList(): void {
    // debugger
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      //this.apiService.Admin_AzureTableDetails().subscribe(data => {
      if (res) {
        // console.log(res,"data");
        //  debugger
        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name
          }
          this.SubscriptionList.push(obj)
        })
        // console.log(this.SubscriptionList,"SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        // console.log("SCM");
        //console.log(data.body);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }

  onChangeResourceGoup($event) {
    let ResourceGroupValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_VNetResourceGroup = ResourceGroupValue;

    // console.log("ResourceGroupValue", this.project_ResourceGroup);

    //*** Get Vnet Value from Azure ***//

    this.getVnetValue(this.vdi_Subscription_ID, this.project_VNetResourceGroup)
    this.VnetArr = [];

    this.SubnetArr = [];
  }
  getVnetValue(SubscriptionId, ResourceGroup): void {
    // debugger;
    let ResourceBody = {
      "subscriptionId": SubscriptionId,
      "ResourceGroups": ResourceGroup
    }
    

    this.apiService.Admin_VnetDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      //   debugger;
      //   console.log(res,"Vnet Body");
      if (res.status == 200) //doesnt work
      {
        this.VnetArr = [];
        for (let i = 0; i < res.body.value.length; i++) {
          //@author-priyanka kale - value should be null instead of undefined
          if (res.body.value[i] != null) {
            // @author-priyanka kale- dropdown should have name and id both values not only name
            // this.VnetArr.push(res.body.value[i].name);
            this.VnetArr.push(res.body.value[i]);
          }
        }
      }
      if (res.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })


  }

  getSubnetValue(SubscriptionId, ResourceGroup, Vnet): void {
    // debugger;
    let ResourceBody = {
      "subscriptionId": SubscriptionId,
      "ResourceGroups": ResourceGroup,
      "virtualNetworkName": Vnet
    }

    this.apiService.Admin_SubnetDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      //   debugger;
      // console.log(res,"Subnet Body");
      if (res.status == 200) //doesnt work
      {
        this.SubnetArr = [];
        for (let i = 0; i < res.body.value.length; i++) {
          //@author-priyanka kale - value should  be null instead of undefined
          if (res.body.value[i] != undefined) {
            // @author-priyanka kale - subnet dropdown should have id and name not only name value
            // this.SubnetArr.push(res.body.value[i].name);
            this.SubnetArr.push(res.body.value[i]);
          }
        }
      }
      if (res.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
  }

  onChangeVMResourceGoup($event) {
    let VMResourceGroupValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_VMResourceGroup = VMResourceGroupValue;
  }

  onChangeVnet($event) {
    let VnetValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_Vnet = VnetValue;
    //console.log("onChangeVnet", this.project_Vnet);

    //*** Get Vnet Value from Azure ***//
    this.getSubnetValue(this.vdi_SubId, this.project_VNetResourceGroup, this.project_Vnet)

  }

  onChangeSubnet($event) {
    let SubnetValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_Subnet = SubnetValue;
    // console.log("onChangeSubnet", this.project_Subnet);
  }

  setVmEditing(aksObj: vmTool) {
    //debugger;

    this.toolsGroup.toolGr = aksObj.toolGroup_id;
    this.get_EditeAzureDetails(aksObj.azureId);

    var azure_subscription_id = aksObj.azure_subscription_id;
    this.getResourceGroup(azure_subscription_id);
    this.toolsGroup.rgdd = aksObj.ResourceGroup;

    //  this.toolsGroup.ResourceGroup = vdiObj.ResourceGroup;
    this.getVnetValue(azure_subscription_id, this.toolsGroup.rgdd);
    this.toolsGroup.Vnetdd = aksObj.Vnet;

    this.getSubnetValue(azure_subscription_id, this.toolsGroup.rgdd, this.toolsGroup.Vnetdd);
    this.toolsGroup.Subnetdd = aksObj.Subnet
    this.toolsGroup.rgvm=aksObj.VMResourceGroup
    this.project_Vnet = this.toolsGroup.Vnetdd;
    this.project_Subnet = this.toolsGroup.Subnetdd;
    this.project_VNetResourceGroup = this.toolsGroup.rgdd;
    this.project_VMResourceGroup = this.toolsGroup.rgvm;

    this.project_id_AksEdit = aksObj.ID;
    this.project_id_db = aksObj.db_id;
    this.toolsGroup.nameInstance = aksObj.name;
    this.toolsGroup.description = aksObj.description;
    //this.toolsGroup.ResourceGroup = aksObj.ResourceGroup;
  }

  getRecord(row) {
    //
    this.clearVMDetails();
    this.vmObj = row;
    try {
      localStorage.setItem('vmToolObj', JSON.stringify(this.vmObj));
      var getObj = JSON.parse(localStorage.getItem('vmToolObj'));
      this.setVmEditing(getObj);
    }
    catch {
      return throwError('Data not found');
    }
    this.editMode = true;
    this.showVmForm = true;
    this.UpdateConnDisable = true;
    this.ResourceDisable = false;
    this.VnetDisable = false;
    this.SubDisable = false;
    this.AzuredrpDisable = true;
    this.toolGrDisable = true;
    this.nameDisable = true;
  }
  clearVMDetails() {
    this.toolsGroup.Vnetdd = "";
    this.toolsGroup.nameInstance = "";
    this.toolsGroup.description = "";
    this.toolsGroup.Subnetdd = "";
    this.toolsGroup.rgvm = "";

    this.toolsGroup.toolGr = "";
    this.toolsGroup.rgdd = "";
    this.toolsGroup.azureId = "";
    this.ResourceGroupArr = [];
    this.VnetArr = [];
    this.SubnetArr = [];
    this.UpdateConnDisable = true;
    this.ResourceDisable = false;
    this.AzuredrpDisable = false;
    this.VnetDisable = false;
    this.SubDisable = false;
    this.toolGrDisable = false;
    this.nameDisable = false;
  }

  addVm() {
    this.clearVMDetails();
    this.showVmForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshVm() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-vm']);
    });
  }
  deleteRecord() {
    if (this.VdiConfig_details.length == 0) {
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
    // debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
    // this.checkedList = JSON.stringify(this.checkedList);

    // @author - Priyanka Kale - set itemSelected flag to false 
    this.itemSelected = false;
    this.VdiConfig_details = [];

    for (let index = 0; index < this.checkedList.length; index++) {
      //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
      this.itemSelected = true;

      this.project_selectedcheckbox = true;
      // debugger;
      var SelectedItem = this.checkedList[index];
      let VdiConfigBoby = {
        "vdi_dropdown_id": SelectedItem,
      }
      this.VdiConfig_details.push(VdiConfigBoby);

      // For Loop of tag

      // Add image des 

    }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }

  DeleteConfig() {
    // debugger;
    var deleteBody: any;
    this.check = []

    this.VdiConfig_details.forEach(element => {
      this.check.push(element.vdi_dropdown_id)

    });
    deleteBody = {
      "tool_ids": this.check

    }
    this.apiService.NetworkConfigVMDropdowDeleteData(deleteBody).subscribe((res: any) => {

      if (res.status == 200) //doesnt work
      {


        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-vm']);
        });
        //  this.alertService.success("Data Delete Successfully", this.options);
        this.alertService.success(res.body.message, this.options)
      }
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 446) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })

  }

  onChange($event) {
    let AksToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = AksToolGrName;
    //  console.log("scmToolGrName",AksToolGrName);
  }

  onChangeAzureId($event) {
    //  debugger
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;
    // console.log("Azure selected value",azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    // console.log("Split Value ",i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
    // debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
      // debugger
      if (res) {
        //   console.log(res,"Id details");
        res.forEach(member => {
          
          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;


        })


        localStorage.setItem("vdi_SubId", this.vdi_SubId);

        //*** Get Resource Group From Azure ***//
        this.getResourceGroup(this.vdi_SubId)
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  getResourceGroup(SubscriptionId): void {
    //// debugger;
    let ResourceBody = {
      "subscriptionId": SubscriptionId
    }

    this.apiService.Admin_ResourceGroupDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      // debugger;
      // console.log(res,"Resource Group Body");
      if (res.status == 200) //doesnt work
      {
        this.ResourceGroupArr = [];
        for (let i = 0; i < res.body.value.length; i++) {
          //@author-priyanka kale - value should  be null instead of undefined
          if (res.body.value[i] != null) {
            //@author -priyanka kale - dropdown should have id and name not only name value
            // this.ResourceGroupArr.push(res.body.value[i].name);
            this.ResourceGroupArr.push(res.body.value[i]);
          }
        }
      }
      if (res.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })


  }
  get_EditeAzureDetails(azureId) {
    //  debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
      //  debugger
      if (res) {
        // console.log(res,"Id get_EditeAzureDetails");


        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }


  CheckAksDuplication() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (
      this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " " || this.toolsGroup.toolGr == undefined
      || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.Subnetdd == " " || this.toolsGroup.Subnetdd == " "
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " || this.toolsGroup.description == " "
      || this.toolsGroup.description == ""
      || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.rgdd == undefined ||
      this.toolsGroup.rgdd == " " || this.toolsGroup.rgdd == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if (this.toolsGroup.nameInstance.length < 3) {
        this.alertService.error("  Please enter minimum 3 characters for saving VM Name ", this.options);
        return;
      }
      if (sp.test(this.toolsGroup.nameInstance)) {

        this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
        return;
      }
      if (this.toolsGroup.nameInstance.length > 20) {
        this.alertService.error("  Please enter maximum 20 characters for saving VM Name ", this.options);
        return;
      }
      if (this.toolsGroup.description.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving VM Description ", this.options);
        return;
      }
      if (this.toolsGroup.description.length > 50) {
        this.alertService.error("  Please enter maximum 50 characters for saving VM Description ", this.options);
        return;
      }
      // this.project_nameInstance = this.storyBoard.nameInstance;

      let validateBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-VM",
        "tool_instance_details": {
          "network_config_tool_instance": {
            "tool_instance_name": this.toolsGroup.nameInstance
          }
        }
      }

      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
        //  //debugger;
        //console.log("Inside Validate");
        //console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            //  case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //    break;
            case "02":
              this.alertService.error(res.body.message, this.options);
              break;

            default:
              this.VMSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  VMSave() {
    //
    //debugger;
    if (
      this.toolsGroup.rgdd == undefined || this.toolsGroup.rgdd == "" || this.toolsGroup.rgdd == " "
      || this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " "
      || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.Subnetdd == "" || this.toolsGroup.Subnetdd == " "
      || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined
      || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == ""
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " "
      || this.toolsGroup.description == " " || this.toolsGroup.description == ""
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " "
      || this.toolsGroup.azureId == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if (this.toolsGroup.nameInstance.length < 3) {
        this.alertService.error("  Please enter minimum 3 characters for saving  VM Name ", this.options);
        return;
      }
      if (this.toolsGroup.nameInstance.length > 20) {
        this.alertService.error("  Please enter maximum 20 characters for saving VM Name ", this.options);
        return;
      }
      if (this.toolsGroup.description.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving VM Description ", this.options);
        return;
      }
      if (this.toolsGroup.description.length > 50) {
        this.alertService.error("  Please enter maximum 50 characters for saving VM Description", this.options);
        return;
      }
      var saveBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########
      saveBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-VM",
        "tool_group_details": {
          "tool_group_id": this.project_toolGrId,
          "tool_group_name": this.project_toolGrName
        },
        "tool_instance_details": {
          "network_config_tool_instance": {
            "azure_tool_instance_id": this.vdi_Azure_Id,
            "azure_subscription_id": this.vdi_Subscription_ID,
            "azure_tenant_id": this.vdi_tenantId,
            "tool_instance_name": this.toolsGroup.nameInstance,

            "description": this.toolsGroup.description,

            "vnet": this.project_Vnet,
            "subnet": this.project_Subnet,
            "resource_group": this.project_VNetResourceGroup,
            "vm_resource_group": this.project_VMResourceGroup
          }
        },

        "created_by": localStorage.getItem('mail').toLowerCase()
      }
      // console.log("saveBody");
      // //debugger;

      this.apiService.Admin_Azure_VM_SaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        // //debugger; 
        if (res.status == 200) {
          //          console.log(res);
          // this.alertService.success("Data Saved Successfully",this.options);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vm']);

          });
          this.alertService.success(res.body.message, this.options)

        }
        else if (res.status == 202) {
          // this.alertService.success("Data Saved Successfully",this.options);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
          this.alertService.success(res.body.message, this.options)

        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 436 || error.status == 430) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {

  }
  cancelok() {
    this.clearVMDetails();
    localStorage.setItem("refreshJira", "true");

    if (this.nextUrl == undefined) {
      this.refreshVm()
    }
    else {
      this.router.navigate([this.nextUrl])

    }

  }
  closeAks() {
    this.showVmForm = false;
    this.editMode = false;
    this.refreshVm()

  }
  updateVm() {
    if (
      this.toolsGroup.rgdd == undefined || this.toolsGroup.rgdd == "" || this.toolsGroup.rgdd == " "
      || this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " "
      || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.Subnetdd == "" || this.toolsGroup.Subnetdd == " "
      || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " " || this.toolsGroup.nameInstance == undefined
      || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == ""
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " "
      || this.toolsGroup.description == " " || this.toolsGroup.description == ""
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " "
      || this.toolsGroup.azureId == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      var updateBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;

      this.ToolGroupList.forEach(element => {
        if (element.tool_group_id == this.project_toolGrId) {
          this.selectedTool_group_name = element.tool_group_name
        }
      });
      updateBody = {
        "category": "VM",
        "tool_id": this.project_id_AksEdit,
        "tool_group_id": this.project_toolGrId,
        "tool_group_name": this.selectedTool_group_name,

        "azure_subscription_id": this.vdi_Subscription_ID,
        "azure_tenant_id": this.vdi_tenantId,
        "tool_instance_name": this.toolsGroup.nameInstance,
        "description": this.toolsGroup.description,

        "vnet": this.project_Vnet,
        "subnet": this.project_Subnet,
        "azure_tool_instance_id": this.vdi_Azure_Id,
        "resource_group": this.project_VNetResourceGroup,
        "vm_resource_group": this.project_VMResourceGroup,
        "updated_by": localStorage.getItem('mail').toLowerCase()

      }

      this.apiService.Admin_Azure_VM_Update(updateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vm']);
            // this.alertService.success("Data Updated Successfully",this.options);
            this.alertService.success(res.body.message, this.options)

          });
        }
        else if (res.status == 202) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vm']);
            // this.alertService.success("Data Updated Successfully",this.options);
            this.alertService.success(res.body.message, this.options)

          });
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430 || error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupVmDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"

    });
  }
}


@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-vm.component.scss']
})

export class AddToolGroupVmDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = "";
    this.model.toolDescription = "";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {

        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by": localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Vm", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Vm", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Vm", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vm']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436 || error.status == 430) //doesnt work
        {
          this.alertService.error(error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}