import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../../service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VirtualTimeScheduler } from 'rxjs';
import { ProjectSummaryComponent } from 'src/app/project-details/project-summary/project-summary.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VdiDetailsComponent, DialogProjectInfoDialog } from 'src/app/project-configurations/vdi-details/vdi-details.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { element } from 'protractor';
import { NgxSpinnerService } from 'ngx-spinner';


export interface userList {
  name: string;
  ps: string;
  email: string;
  roles: string
}
export interface memberModel {
  name: string;
  ps: string;
  email: string;
  roles: string
  new_member: Boolean;
  old_member: Boolean;
  removed_member: Boolean;
  color: string

}


const USER_DATA: userList[]=[];
@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
  projectId: any
  projectData: any
  members: any = []
  projectName: String
  xprojectId: String
  finalMemberList: any = []
  Role: string = "";
  finalClassifiedMemberList: any = []
  finalList: any = []


 // USER_DATA: userList[];
  displayedColumns: string[] = ['name', 'ps', 'email', 'roles'];
  dataSource = new MatTableDataSource<userList>(USER_DATA);

 // dataClassifiedSource = new MatTableDataSource<userList>(this.USER_DATA);
  selection = new SelectionModel<userList>(false, []);

  public parentRef: ProjectSummaryComponent;

  //Sync members Data 
  new_members = []
  previous_members: any = []
  removed_members = []

  databaseMembers = []

  newColor: string;
  oldmember: boolean;
  removedMember: boolean;
  newmember: boolean;

  tableArray: any = []
  finalMemberArray: any = []
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  private footerLogoPath = environment.footerLogoPath;
  footerimageSrc: any;
  footerimageAlt: any;
  env: string;
  Duser_email: any;
  Duser_name: any;
  Drole_assigned: any;
  selectedTool: any;

  constructor(private http: HttpClient, public alertService: AlertService, public dialog: MatDialog,private spinner: NgxSpinnerService,
    private apiService: AuthService, public router: Router,) {
    //console.log("footer Logo Path", this.footerLogoPath);
    this.footerimageSrc = this.footerLogoPath;
    this.footerimageAlt = 'Footer';
    this.env=environment.scmEnv
  }

  ngOnInit(): void {
    // @author-Priyanka Kale - getting projectId from localstorage ,so that data should not get cleared on page refresh
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {

      this.projectId = localStorage.getItem('projectId');

      this.getTeamMembers();
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }

  TeamDmemberInfo(el) {
    
   
    this. Duser_email= el.email,
    this. Duser_name= el.name,
    this.Drole_assigned= el.roles

    const User = this.dialog.open(deleteTeamMemberDialog, {
      width: '40%',
      height: '40%',
      disableClose: true,

      data: {
        info: el
      }
    });
    // this.spinner.hide()
    User.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          // console.log(data);
          this.deleteMember(el)
          
          // this.startStopConfirmation(req_id,"Delete")
        }
      }
    );
     }



  memberInfo(el,Tool) {
     
    let user_details = {
      user_email: el.email,
      user_name: el.name,
      role_assigned: el.roles,
      
    };
    // this.spinner.show();
    localStorage.setItem("selectedTool", Tool);
   // this.selectedTool=Tool
    const User = this.dialog.open(approvedTeamMemberDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,

      data: {
        info: user_details
      }
    });
    // this.spinner.hide()

    User.afterClosed().subscribe(result => {      
      if (result !== 'No') {
      setTimeout(() => {
        this.getTeamMembers();
      }, 1000);
     }
  });

  }

  deleteMember(el) {
    // debugger
    this.spinner.show();

    
   let requestorMail = localStorage.getItem("mail").toLowerCase();

   // let reqBody = {
   //   project_id: this.projectId,
   //   project_name: this.projectName,
   //   requestor_id: requestorMail,
   //   member_details: {
   //     member_email: this.memberArray.user_email,
   //     member_name: this.memberArray.user_name,
   //     role_assigned: this.memberArray.role_assigned
   //   },
   //   status: this.tempData.Status
   // }
   let reqBody = {
     project_id: this.projectId,
     project_name: this.projectName,
     requestor_id: requestorMail,
     user_details: {
       user_email: this.Duser_email,
       user_name: this.Duser_name,
       role_assigned: this.Drole_assigned
     },
     status: 'false'
   }
   //console.log(reqBody);
   ;
   this.apiService.deleteMemberFromProject(reqBody).subscribe((data: any) => {
    this.spinner.hide();

   
     this.alertService.success(data.message, this.options);
     this.getTeamMembers();
   }, (error: HttpErrorResponse) => {
     if(error.status==433){
      this.spinner.hide();

       // console.log(error);
       
       
       this.alertService.error(error.error.message, this.options);
       
     }
     else if (error.status == 454) {
      this.spinner.hide();

       this.alertService.error(error.error.status, this.options);
     }
     else if (error.status == 403){
      this.spinner.hide();

     }
     else{
      this.spinner.hide();

     }
   })

  this.Drole_assigned="";
  this.Duser_email="";
  this.Duser_name="";
 }

  getTeamMembers() {
    this.spinner.show();
    this.members = [];
    //Get Project by Id and get all user and its roles from it
    this.apiService.getProjectByIdWithTeamMemberStatus(this.projectId).subscribe(project => {

      project[0].members.forEach(member => {
        let memberObject = {
          "name": member.user_name,
          "ps": member.user_id,
          "email": member.user_email,
          "roles": member.role_name,
          "template_roles": member.template_role_name,
          "privilege_access" : member.privilege_access,
          "gitlab":member.toolStatus.gitlab,
          "jenkins":member.toolStatus.jenkins,
          "jira":member.toolStatus.jira,
          "vdi":member.toolStatus.vdi,
          "vm":member.toolStatus.vm,
          "deleteStatus":member.toolStatus.deleteStatus
        }
        this.members.push(memberObject)
      });

      this.projectData = project;
      this.members = this.members
      this.new_members = project[0].team_members_delta.newMembers;
      this.removed_members = project[0].team_members_delta.removedMembers;
      this.projectName = project[0].project_name
      this.xprojectId = project[0].project_id
      this.finalMemberSyncClassifier()
      this.spinner.hide();


    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  addMember() {
    const dialogRef = this.dialog.open(AddMemberDialog, {
      width: '50%',
      height: '65%',
      disableClose: true,
      data: {
        data: this.members
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'No') {
          this.getTeamMembers();
      }
    });
  }

  finalMemberSyncClassifier() {

    if (this.new_members.length == 0) {
      this.members.forEach(member => {
        let obj = {
          "name": member.user_name,
          "ps": member.user_id,
          "email": member.user_email,
          "roles": member.role_name,
          "new_member": false,
          "old_member": true,
          "removed_member": false,
          "color": "#f0f0f1"
        }
        this.finalMemberList.push(obj)
      })
      //this.dataClassifiedSource.data = this.finalMemberList as memberModel[]
    } else {
      this.members.forEach((member, index) => {
        this.newColor = "#f0f0f1"
        this.oldmember = true;
        this.newmember = false
        this.removedMember = false

        for (var i = 0; i < this.new_members.length; i++) {

          if (member.ps == this.new_members[i].EmployeeId) {

            this.newColor = "#76de8e";
            this.oldmember = false
            this.newmember = true
            this.removedMember = false

            break;
          }
        }
        this.members[index]['color'] = this.newColor
        this.members[index]['old_member'] = this.oldmember
        this.members[index]['removed_member'] = this.removedMember
        this.members[index]['new_member'] = this.newmember
      })
    }

    if (this.removed_members.length == 0) {
      this.tableArray = this.members;
      this.finalMemberArray = this.members
    } else {
      this.members.forEach((array, index) => {
        var flag = false
        for (var i = 0; i < this.removed_members.length; i++) {

          if (array.ps == this.removed_members[i].EmployeeId) {

            this.newColor = "#f58b95";
            this.removedMember = true
            this.oldmember = false
            this.newmember = false
            flag = true;
            break;
          }
        }
        if (flag) {
          this.members[index]['color'] = this.newColor
          this.members[index]['old_member'] = this.oldmember
          this.members[index]['removed_member'] = this.removedMember
          this.members[index]['new_member'] = this.newmember
          flag = false
        }

      })
    }
   // this.dataClassifiedSource.data = this.members as memberModel[]

    this.syncProjects()
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTeamMembers();
    });
  }

  syncProjects() {
    /*let projectSummary =  {
      "ps_number" :"10603933",
      "project_id" : this.projectId
  
    }
    this.apiService.syncImanageTeamMembers(projectSummary).subscribe((data : any)=>{
    })*/


  }

}


@Component({
  selector: 'show-catalog-info-dialog',
  templateUrl: 'add-member.html',
  styleUrls: ['./team-members.component.scss']
})
export class AddMemberDialog {
  showMemberTable: boolean = false;
  secondFormGroup: FormGroup;
  psNumber: any;
  customMemberArr = [];
  profile: any;
  tableArr: any;
  project_id: any;
  memberArray = [];
  globalMemberArray = []
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  userNotFound: boolean;
  template_id: string;
  userExists: boolean;
  errMessage: any;

  constructor(public apiService: AuthService, private _formBuilder: FormBuilder, public alertService: AlertService,
    public dialogRef: MatDialogRef<AddMemberDialog>, @Inject(MAT_DIALOG_DATA) public data: any,public dialog: MatDialog) {
    this.memberArray = data.data
  }

  onNoClick(): void {
    this.dialogRef.close('No');
  }

  ngOnInit() {
    this.secondFormGroup = this._formBuilder.group({
      memberEmail: ['', [Validators.pattern('^[a-zA-Z0-9.]+[@]{1}(lntinfotech\.com|ltimindtree\.com)$')]],
    });

    this.memberArray.forEach(member => {
      this.globalMemberArray.push(member.email.toLowerCase())
    })
  }

  get memberEmail() {
    return this.secondFormGroup.get('memberEmail');
  }

  searchEmployee() {
    this.userNotFound = false;
    this.userExists = false;
    let comparisonVariable = this.memberEmail.value.toLowerCase()
    if (this.globalMemberArray.indexOf(comparisonVariable) !== -1) {
      // console.log(this.globalMemberArray);
      
      this.userExists = true;
      // this.alertService.error("Member already added.", this.options);
      this.secondFormGroup.reset();
    } else {
      this.userExists = false;
      if (this.customMemberArr.length == 0) {
        this.apiService.getUserVerification(this.memberEmail.value).subscribe((data: any) => {
          this.secondFormGroup.reset();

          if (data.ps_number == null) {
          }
          else {
            this.showMemberTable = true;
            this.apiService.psNumber.subscribe(result => {
              this.psNumber = result;
            })
 
            let memberObj = {
              "user_id": data.ps_number,
              "user_name": data.displayName,
              'user_email': data.mail,
              'role_name': 'Team Member'
            }

            this.customMemberArr.push(memberObj)
          }

        }
          , (err: HttpErrorResponse) => {
            if (err.status == 434){
              if(err.error.message == "Unable to fetch data for User"){
                this.userNotFound = true;
                this.errMessage = err.error.message;
              }
              else{
                this.userNotFound = false;
                this.errMessage = "Please enter valid email address";
              }
             // this.alertService.error(err.error.message, this.options);
               }

          })
      }
      else {

        let compareArray = []
        var duplicate;
        for (var i = 0; i < this.customMemberArr.length; i++) {
          compareArray.push(this.customMemberArr[i].user_email.toLowerCase())
        }

        if (compareArray.indexOf(comparisonVariable) !== -1) {
          duplicate = "true"
          this.alertService.error("Member already added.", this.options);
          this.secondFormGroup.reset();
        }
        if (duplicate != "true") {
          this.apiService.getUserVerification(this.memberEmail.value).subscribe((data: any) => {
            this.secondFormGroup.reset();

            if (data.ps_number == null) {
            }
            else {
              this.showMemberTable = true;
              this.apiService.psNumber.subscribe(result => {
                this.psNumber = result;
              })

              let memberObj = {
                "user_id": data.ps_number,
                "user_name": data.displayName,
                'user_email': data.mail,
                'role_name': 'Team Member'
              }

              this.customMemberArr.push(memberObj)
            }

          }, (err: HttpErrorResponse) => {
            if (err.status == 434){
              if(err.error.message == "Unable to fetch data for User"){
                this.userNotFound = true;
                this.errMessage = err.error.message;
              }
              else{
                this.userNotFound = false;
                this.errMessage = "Please enter valid email address";
              }
             // this.alertService.error(err.error.message, this.options);
               }
            else if (err.status == 403){}
            else {
              this.alertService.error("Invalid User", this.options);
              throw err;
            }

          }
          )
        }
      }
    }

  }

  addMember() {
    this.project_id = localStorage.getItem('projectId');
    this.template_id = localStorage.getItem('template_id');
    this.apiService.getTemplateRoles(this.template_id).subscribe((data: any)=>{
      this.customMemberArr.forEach(element => {
          data.forEach(template => {
            if(element.role_name === template.role_name) {              
              element.template_role_name = ""
              element.privilege_access = template.privilege_access
            }
          });
      })
      let requestorMail = localStorage.getItem("mail").toLowerCase();

      let body = {
        
        "project_id": this.project_id,
        "requestor_id":requestorMail,
        "members": this.customMemberArr,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }
      //console.log(body);
      

      
      this.apiService.addNewMember(body).subscribe((data: any) => {
        this.customMemberArr = [];
        //if (data.Status == "Member added successfully") {
          this.alertService.success(data.message, this.options);
          setTimeout(() => {
            // this.alertService.error('Kindly assign roles to newly added members',this.options);
            const dialogRef = this.dialog.open(ModalComponent, {
              width: '30%',
              height: '27%',
              disableClose: true,
              // id:req_id,
           
             
            });


          }, 1000);
    
       // }
        this.dialogRef.close();
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 434) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error(" Invalid Input ", this.options);
          throw error;
        }
        this.dialogRef.close();
  
      })
    })
      
  }


}

export interface MemberDetails {
  tool_name: string;
  request_id: string;
  project_name: string;
}
const ELEMENT_DATA: MemberDetails[]=[];
@Component({
  selector: 'approved-team-member-dialog',
  templateUrl: 'approved-members.html',
  styleUrls: ['./team-members.component.scss']
})
export class approvedTeamMemberDialog {

  psNumber: any;
  memberArray: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  projectId: string;
  projectName: string;
  ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['tool_name', 'request_id', 'project_name', 'status'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  tempData: any ;
  finalTable: any = [];
  gitLab: any = [];
  Jenkins: any = [];
  Jira: any = [];
  VDI: any = [];
  VM: any = [];
  showcont: boolean;
  ToolName: string;


  constructor(public apiService: AuthService, public alertService: AlertService,private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<approvedTeamMemberDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.memberArray = data.info
    // console.log(data.info);

  }

  onNoClick(): void {
    this.dialogRef.close('No');
  }



  ngOnInit() {
   // debugger;
    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');
this.ToolName = localStorage.getItem('selectedTool')
    let reqBody = {
      project_id: this.projectId,
      project_name: this.projectName,
      filter: localStorage.getItem('selectedTool'),
      user_details: this.memberArray
    }
    //console.log(reqBody);
// this.spinner.show()
    this.apiService.getMemberInfoForTool(reqBody).subscribe((data:any) =>{
      this.tempData = data;
      this.dataSource.data = this.tempData as MemberDetails[]
    //  debugger;
    //  console.log(this.tempData.Jira);
      // if(this.tempData) {
      //   this.gitLab = this.tempData.Gitlab;
      //   this.Jira = this.tempData.Jira;
      //   this.Jenkins = this.tempData.Jenkins;
      //   this.VDI = this.tempData.VDI;
      //   this.VM = this.tempData.VM;
  
      // }
    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  deleteMember() {
     
    let requestorMail = localStorage.getItem("mail").toLowerCase();

    // let reqBody = {
    //   project_id: this.projectId,
    //   project_name: this.projectName,
    //   requestor_id: requestorMail,
    //   member_details: {
    //     member_email: this.memberArray.user_email,
    //     member_name: this.memberArray.user_name,
    //     role_assigned: this.memberArray.role_assigned
    //   },
    //   status: this.tempData.Status
    // }
    let reqBody = {
      project_id: this.projectId,
      project_name: this.projectName,
      requestor_id: requestorMail,
      user_details: {
        user_email: this.memberArray.user_email,
        user_name: this.memberArray.user_name,
        role_assigned: this.memberArray.role_assigned
      },
      status: this.tempData.Status
    }
    //console.log(reqBody);
    this.apiService.deleteMemberFromProject(reqBody).subscribe((data: any) => {
      
      this.dialogRef.close();
      this.alertService.success(data.message, this.options);
    }, (error: HttpErrorResponse) => {
      if(error.status==433){
        // console.log(error);
        
        
        this.alertService.error(error.error.message, this.options);
        
      }
      else if (error.status == 454) {
        this.alertService.error(error.error.status, this.options);
      }
      else if (error.status == 403){}
    })
    this.dialogRef.close()

  }
}
@Component({
  selector: 'info-dialog',
  templateUrl: 'info.html',
  styleUrls: ['./team-members.component.scss']
})

export class ModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'delete-member-dialog',
  templateUrl: 'delete-member.html',
  styleUrls: ['./team-members.component.scss']
})

export class deleteTeamMemberDialog {
  elementData: any;
  deleteString: string;

  constructor(
    public dialogRef: MatDialogRef<deleteTeamMemberDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
    this.elementData=data.info
    // console.log(this.elementData);
     this.deleteString=""
     if(this.elementData.gitlab){
      this.deleteString+="Gitlab"
     }
     if(this.elementData.jenkins){
      if (this.deleteString.length>0){
        this.deleteString+=",Jenkins"

      }
      else{
        this.deleteString+="Jenkins"

      }
     }
     if(this.elementData.jira){
      if (this.deleteString.length>0){
        this.deleteString+=",Jira"

      }
      else{
        this.deleteString+="Jira"

      }
     }
     if(this.elementData.vdi){
      if (this.deleteString.length>0){
        this.deleteString+=",VDI"

      }
      else{
        this.deleteString+="VDI"

      }
     }
     if(this.elementData.vm){
      if (this.deleteString.length>0){
        this.deleteString+=",VM"

      }
      else{
        this.deleteString+="VM"

      }
     }
    // console.log(this.de1leteString);
    
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
