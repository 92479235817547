import { Component, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

export interface aksTool {
  toolName: string;
  ID: string;
  azureId: string;
  db_id: string;
  name: string;
  description: string;
  Vnet: string;
  toolGroup: string;
  toolGroup_id: string;
  Subnet: string;
  ResourceGroup: string;
}

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}

@Component({
  selector: 'app-admin-aks',
  templateUrl: './admin-aks.component.html',
  styleUrls: ['./admin-aks.component.scss']
})
export class AdminAksComponent implements OnInit {
  showAksForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];
  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  SCMArray = [];
  project_toolGrId: string;
  aksObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;

  SubscriptionList: Subscription_iddropdown[] = [];
  Role:string="";
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.getTypeT();

    var PopupToolGroup = localStorage.getItem('Popup_Open_Aks')
    if (PopupToolGroup == "true") {
      this.showAksForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Aks')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
      
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Aks')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Aks", "false");
    } else {
      this.showAksForm = false;
    }
    this.getSubscriptionList();
    this.GeAkstableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }


  getSubscriptionList(): void {
   // debugger
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      
      if (res) {
        //console.log(res, "data");
     
        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name
          }
          this.SubscriptionList.push(obj)
        })
        //console.log(this.SubscriptionList, "SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  GeAkstableDetails() {

    this.spinner.show();
    this.apiService.Admin_AksToolGrTableDetails().subscribe((res: any) => {
      // 
     // console.log(res, "OUTPut")
      // //debugger;
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "azureId": member.tool_instance_details.network_config_tool_instance.azure_tool_instance_id,
          "db_id": member._id,
          "name": member.tool_instance_details.network_config_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.network_config_tool_instance.description,
          "Vnet": member.tool_instance_details.network_config_tool_instance.vnet,
          "Subnet": member.tool_instance_details.network_config_tool_instance.subnet,
          "ResourceGroup": member.tool_instance_details.network_config_tool_instance.resource_group,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.SCMArray.push(obj)

      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }
    )
  }

  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        // console.log("SCM");
        //console.log(data.body);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }


  setAksEditing(aksObj: aksTool) {
    //debugger;
    //console.log("scmObj", scmObj)
    this.toolsGroup.toolGr = aksObj.toolGroup_id;
    this.get_EditeAzureDetails(aksObj.azureId);
    this.toolsGroup.Vnet = aksObj.Vnet;
    this.toolsGroup.Subnet = aksObj.Subnet;
    this.project_id_AksEdit = aksObj.ID;
    this.project_id_db = aksObj.db_id;
    this.toolsGroup.nameInstance = aksObj.name;
    this.toolsGroup.description = aksObj.description;
    this.toolsGroup.ResourceGroup = aksObj.ResourceGroup;
  }

  getRecord(row) {
    ////debugger;
    this.clearAksDetails();
    this.aksObj = row;
    try{
      localStorage.setItem('aksToolObj', JSON.stringify(this.aksObj));
      let getObj = JSON.parse(localStorage.getItem('aksToolObj'));
      this.setAksEditing(getObj);
      this.editMode = true;
      this.showAksForm = true;
      this.UpdateConnDisable = true;
    }
    catch {
      return throwError('Data not found');
    }
    
   
  }
  clearAksDetails() {
    this.toolsGroup.Vnet = "";
    this.toolsGroup.nameInstance = "";
    this.toolsGroup.description = "";
    this.toolsGroup.Subnet = "";
    this.toolsGroup.toolGr = "";
    this.toolsGroup.ResourceGroup = "";
    this.toolsGroup.azureId = "";
    this.UpdateConnDisable=true;

  }

  addAks() {
    this.clearAksDetails();
    this.showAksForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshAks() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-aks']);
    });
  }
  onChange($event) {
    let AksToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = AksToolGrName;
   // console.log("scmToolGrName", AksToolGrName);
  }

  onChangeAzureId($event) {
    //debugger
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;
   // console.log("Azure selected value", azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    //console.log("Split Value ", i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
   // debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
     // debugger
      if (res) {
       // console.log(res, "Id details");


        res.forEach(member => {


          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;
        })
 localStorage.setItem("vdi_SubId", this.vdi_SubId);       
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  get_EditeAzureDetails(azureId) {
    //debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
      //debugger
      if (res) {
        //console.log(res, "Id get_EditeAzureDetails")
        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  CheckAksDuplication() {
    if (
      this.toolsGroup.Vnet == undefined || this.toolsGroup.Vnet == "" || this.toolsGroup.Vnet == " " || this.toolsGroup.toolGr == undefined
      || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.Subnet == " " || this.toolsGroup.Subnet == " "
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
      || this.toolsGroup.Subnet == undefined || this.toolsGroup.ResourceGroup == undefined ||
      this.toolsGroup.ResourceGroup == " " || this.toolsGroup.ResourceGroup == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for AKS Name save", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for AKS Name save", this.options);
        return;
      }
      if(this.toolsGroup.description.length<10){
        this.alertService.error("  Please enter minimum 10 characters for AKS Description save", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for AKS Description save", this.options);
        return;
      }
 
      let validateBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-AKS",
        "tool_instance_details": {
          "network_config_tool_instance": {
            "tool_instance_name": this.toolsGroup.nameInstance
          }
        }
      }

     // console.log("validateBody");
      //console.log(validateBody);
      //debugger;
      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
        //debugger;
        //console.log("Inside Validate");
       // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            case "01":
              this.alertService.error(res.body.msg, this.options);
              break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.AskSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {

        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  AskSave() {
    
    //debugger;
    if (
      this.toolsGroup.Vnet == undefined || this.toolsGroup.Vnet == "" || this.toolsGroup.Vnet == " " || this.toolsGroup.toolGr == undefined
      || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.Subnet == " " || this.toolsGroup.Subnet == " "
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
      || this.toolsGroup.Subnet == undefined || this.toolsGroup.ResourceGroup == undefined ||
      this.toolsGroup.ResourceGroup == " " || this.toolsGroup.ResourceGroup == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      
      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for AKS Name save", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for AKS Name save", this.options);
        return;
      }
      if(this.toolsGroup.description.length<10){
        this.alertService.error("  Please enter minimum 10 characters for AKS Description save", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for AKS Description save", this.options);
        return;
      }
      var saveBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########

      saveBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-AKS",
        "tool_group_details": {
          "tool_group_id": this.project_toolGrId,
          "tool_group_name": this.project_toolGrName
        },
        "tool_instance_details": {
          "network_config_tool_instance": {
            "azure_tool_instance_id": this.vdi_Azure_Id,
            "azure_subscription_id": this.vdi_Subscription_ID,
            "azure_tenant_id": this.vdi_tenantId,
            "tool_instance_name": this.toolsGroup.nameInstance,
            "description": this.toolsGroup.description,
            "vnet": this.toolsGroup.Vnet,
            "subnet": this.toolsGroup.Subnet,
            "resource_group": this.toolsGroup.ResourceGroup
          }
        }
      }

      this.apiService.Admin_Azure_AksSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        // //debugger; 
        if (res.status == 200) {
          //          console.log(res);
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-aks']);
          });
        }
        else if (res.status == 202) {
          this.alertService.success("Data Saved Successfully", this.options);

          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {

  }
  closeAks() {
    this.showAksForm = false;
  }
  updateAks() {

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupAksDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"

    });
  }
}


@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-aks.component.scss']
})

export class AddToolGroupAksDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
       
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Aks", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Aks", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Aks", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-aks']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}
