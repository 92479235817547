
    <div class="pop-up">
        <span  class="vertical-line mx-0 mx-md-3"> </span>
         <span class="header mb-0"> Member Details</span> 
        <span (click)="closeDialog()" class="material-icons close-icon">
            close
          </span>
 </div>
  
    <hr class="margin-class" />

    <div class="row">
        <div class="col-12 col-md-4 img__wrap userRow" *ngFor="let user of memberList">
            <span >
                <img src="../../../assets/img/UserIcon.png"><br>
                {{user.name}}
                <br>
                <span *ngIf="user.status == 'Pending'">(Inactive)</span>
                  <!-- <span *ngIf="user.status == 'Approved'">({{user.status | titlecase}})</span> -->
                <!-- <span class="close_icon cursor" *ngIf="reqStatus=='Completed' && user.status == 'Approved'" title="Remove User" (click)="deleteUser(user.email)">x</span> -->
                <br>
                <span class="img__description_layer img__details card">
                  <img src="../../../assets/img/UserIcon.png">
                  <h3 class="font-14">{{user.name}}</h3>
                  <h5>{{user.email}}</h5>
                  <h4>{{user.role}}</h4>
                  <h4 *ngIf="user.user_access">{{user.user_access | titlecase}}</h4>
                  <span *ngIf="user.status == 'Pending'">(Inactive)</span>
                  <!-- <span *ngIf="user.status == 'Approved'">({{user.status | titlecase}})</span> -->

                </span>
            </span>
        </div>
       
           
    </div>