import { Component, OnInit, Inject } from '@angular/core';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerModule } from 'ngx-spinner';
export class ChannelTeam {
  constructor(public channelName: string) { }
}

@Component({
  selector: 'app-teams-channel',
  templateUrl: './teams-channel.component.html',
  styleUrls: ['./teams-channel.component.scss']
})
export class TeamsChannelComponent implements OnInit {
  showForm: boolean = false;
  existingSpaces: boolean = false;
  projectId: any;
  projectName: any;
  deleteChannelName: any;
  channelName: any = [];
  channelArrayData = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  spaceTable: any = [];

  constructor(public dialog: MatDialog, private apiService: AuthService, public alertService: AlertService, private spinner: NgxSpinnerService, private SpinnerService: NgxSpinnerService) { }

  channelTeam;
  ngOnInit(): void {

    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');

    this.channelTeam = new ChannelTeam('General')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Retrospection')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Backlog Refinement')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Sprint Planning')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Sprint Review')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Daily Scrum')
    this.channelArrayData.push(this.channelTeam)
    this.channelTeam = new ChannelTeam('Quality')
    this.channelArrayData.push(this.channelTeam)

    this.dtOptions = {
      searching: false,
      paging: false,
      ordering: true,
      info: false
    };

    this.getExistingChannels();

  }

  showSpaceForm() {
    this.showForm = true
  }

  enableEdit() {
    //this.inputDisabled = false;
    this.channelTeam = new ChannelTeam('')
    this.channelArrayData.push(this.channelTeam)
  }

  removeChannelName(index) {
    this.channelArrayData.splice(index, 1);
  }

  cancel() {
    this.showForm = false
    this.existingSpaces = true
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  // Get existing channel start
  getExistingChannels() {
    var bodyChannel =
    {
      "projectName": this.projectName
    };
    // this.showMenu = false;
    this.spaceTable = []
    this.spinner.show();
    this.apiService.getChannels(bodyChannel).subscribe((data: any) => {
      //console.log(data)
      if (data.length == 0) {
        this.existingSpaces = false
      } else {
        this.existingSpaces = true;
        this.spaceTable = data;
        this.spaceTable = this.spaceTable.channelList;
      }
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }
    })
  }
  // Get existing channel end

  // Create channel start
  createTeamsChannel() {
    this.spinner.show();
    this.showForm = false
    this.channelArrayData.forEach(element => {
      this.channelName.push(element.channelName)

    });
    var bodydata =
    {
      "projectName": this.projectName,
      "channelList": this.channelName,
    };

    this.apiService.createChannel(bodydata).subscribe((data: any) => {
      if (data.length > 0) {
        data.forEach(res => {
          // console.log(res)
          this.spaceTable.push(res)
        })
        this.existingSpaces = true;
        this.getExistingChannels();
      }
      this.getExistingChannels();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Error while creating Teams channel.", this.options);
        throw error;
      }
    })
  }
  // Create channel end

  // Delete channel start
  deleteChannel(spaceId) {
    // console.log(spaceId,"SpaceIdToDelete")
    this.deleteChannelName = spaceId;
    const dialogRef = this.dialog.open(DeleteMembersSpace, {
      width: '35%',
      height: '37%',
      disableClose: true,
      data: {
        spaceId: spaceId
      }
    });

    this.apiService.selectedSpace.next(spaceId)

    dialogRef.afterClosed().subscribe(result => {
      //console.log("result", result);
      if (result == "yes") {
        var deleteBody = {
          "projectName": this.projectName,
          "channelName": this.deleteChannelName,
        }

        this.apiService.deleteChannel(deleteBody).subscribe(response => {

          if (response == "Space Deleted") {
            this.alertService.success('Channel Deleted Successfully', this.options)
            this.getExistingChannels()
          }
          else {
            // this.alertService.error(response, this.options);
            this.getExistingChannels()
          }
        }, (error: HttpErrorResponse) => {
          if (error.status == 438) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("Error while deleting Channel.", this.options);
            throw error;
          }

        })
      }

    });

  }
  // Delete channel end

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  //View member start
  viewUserForChannel(channelName) {
    var viewBody = {
      "projectName": this.projectName,
    }
    this.apiService.getMembersForChannel(viewBody).subscribe(response => {
      // console.log(response,"memberApi")
      const dialogRef = this.dialog.open(ViewMembersSpace, {
        width: '50%',
        height: '52%',
        disableClose: true,
        data: {
          member: response
        }
      });

      this.apiService.selectedSpace.next(channelName)

      dialogRef.afterClosed().subscribe(result => {
        // this.getExistingSpaces()
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }
  //View member end

  //Remove member start
  openUserForChannel(channelName, option) {
    var viewBody = {
      "projectName": this.projectName,
    }
    this.apiService.getMembersForChannel(viewBody).subscribe(response => {
      const dialogRef = this.dialog.open(RemoveMemberDialog, {
        width: '50%',
        height: '52%',
        disableClose: true,
        data: {
          member: response,
          id: channelName,
          operation: option
        }
      });
      this.apiService.selectedSpace.next(channelName)
      dialogRef.afterClosed().subscribe(result => {
        // console.log(result)


        if (result == "Refresh") {
          this.getExistingChannels()

          // this.showMenu = true
        }

      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }
    })
    // console.log(channelName)

    // this.apiService.selectedSpace.next(channelName)


  }
  //Remove member end


}

//delete channel component
@Component({
  selector: 'delete-member-space',
  templateUrl: 'delete-member-space.html',
  styleUrls: ['./teams-channel.component.scss']
})

export class DeleteMembersSpace {
  spaceId: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  constructor(public apiService: AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<DeleteMembersSpace>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.spaceId = data.spaceId
  }
  ngOnInit() {
  }
  deleteYes() {
    this.dialogRef.close("yes")
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  exit() {
    this.dialogRef.close();
  }


}

//view member component
@Component({
  selector: 'members-space',
  templateUrl: 'view-member-channel.html',
  styleUrls: ['./teams-channel.component.scss']
})

export class ViewMembersSpace {
  memberList: any = [];
  selectedRole: any;
  projectId: any;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(public apiService: AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<ViewMembersSpace>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.memberList = data.member;
    this.memberList = this.memberList.memberDetails;
  }

  ngOnInit() {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  exit() {
    this.dialogRef.close();
  }


}

//Remove member component
export interface MemberDetails {
  user_name: string;
  mail: string;
  user_email: string;
  role_name: string;
  user_status: string;
}
const ELEMENT_DATA: MemberDetails[]=[];
@Component({
  selector: 'remove-member',
  templateUrl: 'remove-member.html',
  styleUrls: ['./teams-channel.component.scss']
})

export class RemoveMemberDialog {
  memberList: any = [];
  selectedRole: any;
  projectId: any;
  spaceId: any;
  projectName: any;
  option = "";
  finalArray: any = [];
  members: any = [];
  //ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  existingMeetingMembers: any;
  isallSelected: boolean;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
   // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
     this.selection.clear() :
     this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.mail + 1}`;
  }

  constructor(public apiService: AuthService, public alertService: AlertService, private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RemoveMemberDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.memberList = data.member.memberDetails
    this.spaceId = data.id
    this.option = data.operation

  }

  ngOnInit() {
    this.userBody = {}
    //console.log(this.userBody)
    // @author-Priyanka Kale - getting projectId,ProjectName from localstorage ,so that data should not get cleared on page refresh

    // this.apiService.projectKey.subscribe(projectId => {
    //   this.projectId = projectId;
    // })
    this.projectId = localStorage.getItem('projectId');

    // this.apiService.projectName.subscribe(projectName => {
    //   this.projectName = projectName;
    // })
    this.projectName = localStorage.getItem('projectName');

    this.apiService.selectedRecurrsiveMeetingMember.subscribe((members: any) => {
      this.existingMeetingMembers = members
    })
    // this.apiService.projectName.subscribe(projectName => {
    //   this.projectName = projectName;
    // })
    this.projectName = localStorage.getItem('projectName');
    if (this.option == "Add") {
      this.getUser();

    } else if (this.option == "Delete") {
      //console.log(this.memberList)
      this.dataSource.data = this.memberList as MemberDetails[]

    }

  }

  allSelected() {
    this.isallSelected = true
  }
  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      //console.log(project)
      this.members = project[0].members;
      // console.log(this.members)
      // console.log(this.memberList)
      this.members.forEach((element, index) => { //alll members 2
        for (var i = 0; i < this.memberList.length; i++) {
          if (this.memberList[i].mail.toLowerCase() == element.user_email.toLowerCase()) {
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
          }
        }

      });
      this.members.forEach((object: any) => {
        if (!object.duplicate) {
          this.finalArray.push(object)
        }
      })
      //console.log(this.finalArray)
      this.dataSource.data = this.finalArray as MemberDetails[]
    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }


  userBody = {}
  addUserForChannel() {
    let selectedSpace = "";
    let memberArray = [];
    this.userBody = {};
    let body = {};
    // console.log("api call function")

    this.apiService.selectedSpace.subscribe(Space => {
      selectedSpace = Space;
    })
    // console.log(this.selection.selected)
    this.selection.selected.forEach(member => {
      body = {
        "email_id": member.user_email,
        "role_name": member.role_name[0]
      }
      memberArray.push(body)
      // memberArray.push(member.user_email)
    })

    this.userBody = {
      // "channelList": this.spaceId,
      "memberList": memberArray,
      "projectName": this.projectName
    }
    //console.log(this.userBody, "memberApiBody")
    this.apiService.addMemberForChannel(this.userBody).subscribe(response => {
      // console.log("Member Response",response)
      // if (response == "Success") {
      this.alertService.success('Member Added Successfully', this.options)
      this.dialogRef.close("Refresh")

      // }
      // else {
      //   this.alertService.error('Error adding Member', this.options);
      //   this.dialogRef.close("")

      // }
      this.apiService.closeDialog.subscribe(res => {
        if (res == "close") { this.dialogRef.close("") }
      })

    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Error while assigning member.", this.options);
        throw error;
      }

    })


  }

  deleteUserForChannel() {
    this.projectName = localStorage.getItem('projectName');
    let body = {};
    let deleteMemberArray = []

    this.selection.selected.forEach((member: any) => {
      body = {
        "memberIDList": member.id,
        "projectName": this.projectName
      }
      deleteMemberArray.push(body)
    })
    if (deleteMemberArray.length > 0) {
      this.apiService.deleteMemberForChannel(deleteMemberArray).subscribe(response => {
        //   console.log("Member Response",response)
        if (response == "Member Deleted") {
          this.alertService.success('Member Deleted Successfully', this.options)
          this.dialogRef.close("Refresh")

        }
        else {
          //this.alertService.error('Error deleting Member', this.options);
          this.dialogRef.close("")

        }

      }, (error: HttpErrorResponse) => {
        if (error.status == 438) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Error while deleting member.", this.options);
          throw error;
        }

      })
      this.apiService.closeDialog.subscribe(res => {
        if (res == "close") { this.dialogRef.close("") }
      })
      //  this.dialogRef.close("")

    }


  }
  exit() {

    this.dialogRef.close("");

  }
}