<div class="pop-up" *ngIf="modalType == 'alert'">
           <span  class="vertical-line mx-1 mx-md-3"> </span>
            <span class="header mb-0"> Alert Details</span> 
           <span (click)="exit()" class="material-icons close-icon">
               close
             </span>
   
    <hr class="margin-class" />
   <div class="popup-container">
   <div class="row ">
       <div class="col-md-2">
           <span class="field-name"> Description </span>
       </div>
       <div class="col-md-10">
               <span> : &nbsp;{{alertInfo.description}}</span>
                </div>
   </div>
   
   <div class="row">
       <div class="col-md-2">
           <span class="field-name">Next Step </span>
       </div>
       <div class="col-md-10">
               <span> : &nbsp;{{alertInfo.next_step}}</span>
       </div>
   </div>
   
   <div class="row ">
       <div class="col-md-2">
           <span class="field-name">Status </span>
       </div>
       <div class="col-md-10">
               <span> : &nbsp;{{alertInfo.status | titlecase}}</span>
       </div>
   </div>
   
   <div class="row ">
       <div class="col-md-2">
           <span class="field-name"> ID </span>
       </div>
       <div class="col-md-10">
               <span> : &nbsp;{{alertInfo.id}}</span>
       </div>
   </div>
   </div>
   </div>


   <div class="pop-up" *ngIf="modalType == 'observations'">
    <span  class="vertical-line mx-1 mx-md-3"> </span>
     <span class="header mb-0"> Observation Details</span> 
    <span (click)="exit()" class="material-icons close-icon">
        close
      </span>

<hr class="margin-class" />
<div class="popup-container">
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Account ID </span></div>
        <div class="col-md-10"><span> : {{alertInfo.account_id}} </span></div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Authority </span></div>
        <div class="col-md-10"><span> : {{alertInfo.authority}} </span>    </div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Event </span></div>
        <div class="col-md-10"><span> : {{alertInfo.event}} </span></div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Event ID </span></div>
        <div class="col-md-10"><span> : {{alertInfo.event_id}} </span>    </div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Event Type</span></div>
        <div class="col-md-10"><span> : {{alertInfo.event_type}} </span></div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> ID </span></div>
        <div class="col-md-10"><span> : {{alertInfo.id}} </span>    </div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> IP Address</span></div>
        <div class="col-md-10"><span> : {{alertInfo.ip_address}} </span></div>
    </div>
    <div class="row ">
        <div class="col-md-2"><span class="field-name"> Resource Type</span></div>
        <div class="col-md-10"><span> : {{alertInfo.resource_type}} </span></div>
    </div>
</div>
</div>