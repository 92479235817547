<div class="inner-page-container">

    <div class="inner-top-container">
        <div class="row">
          <div class="col-md-6">        
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb bg-transparent px-0">
                <li class="breadcrumb-item"><a routerLink="/projectSummary">Home</a></li>
                <li class="breadcrumb-item"><a routerLink="/workBench">Workbench</a></li>
                <li class="breadcrumb-item active" aria-current="page">Cast</li>
              </ol>
            </nav>
          </div>
          <div class="col-md-5 p-md-0 my-auto">
          </div>
          <div class="col-md-1 p-md-0 my-auto table-options">
            <span routerLink="/workBench" class="float-right text-light">
              <!-- <i class="fas fa-times"></i> -->
              <i class="fa fa-step-backward "></i> Back</span>
          </div>
        </div>
       
    </div>

    <div class="inner-mid-container">
        <div class="row">
            <div class="col-sm-3">
                <h1 class="text-light mb-0">
                    <img width="40" src="assets/img/cast-small.png">
                    <div class="vertical-line mx-3"> </div> <span>Cast</span>      
                  </h1>
            </div>
            <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
                {{projectId}}</span>
                <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
            </div>
            <div class="col-md-4" style="padding-top: 8px;"><span class="field-name">Cast CoE Email Id</span><span class="field-value"> :
                </span></div>
            
        </div>

        <table datatable class="table fixed-table mt-2" *ngIf="!castStatus">
            <thead>
                <tr>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Role</th>
                    <th> Project Status </th>
                    <th>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of members">
                    <td> {{element.user_name}} </td>
                    <td> {{element.user_email}} </td>
                    <td> {{element.role_name}} </td>
                    <td> <span *ngIf="element.user_project_status=='existing'"
                            class="glyphicon glyphicon-ok-sign onboarded-sign"></span>
                        <span *ngIf="element.user_project_status=='deleted'"
                            class="glyphicon glyphicon-remove-sign denied-sign"></span>
                        <span *ngIf="element.user_project_status=='new'"
                            class="glyphicon glyphicon-minus-sign new-sign"></span>
                    </td>
                    <td>
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(element) : null"
                            [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                        </mat-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>

        <p *ngIf="castStatus" class="field-name" style="text-align: center;">Email has been sent to CAST CoE. <br>
            Project Setup Initiated in Cast</p>

        <div class="offset-md-9 col-md-3">
            <button class="create-pipeline" mat-raised-button color="primary" (click)="createPipeline()"
                *ngIf="!castStatus">Create Project</button>
        </div>

    </div>
</div>
