import { Component, OnInit, Inject } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}

export interface vdiTool {
  client_name: string;
  client_id: string;
  // webex_config_id:string;
  client_secret: string;
  redirect_uri: string;
  hostemail: string;
  siteurl: string;
  webex_config_id:string
}


@Component({
  selector: 'app-admin-webex-propertire',
  templateUrl: './admin-webex-propertire.component.html',
  styleUrls: ['./admin-webex-propertire.component.scss']
})
export class AdminWebexPropertireComponent implements OnInit,CanComponentLeave {

  showVdiForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];
  itemSelected:boolean=false;
  userMailId: string;
  ResourceGroupArr = [];
  VnetArr = [];
  SubnetArr = [];
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox = false;
  checkedList: any;

  VdiConfig_details:any=[];
  check:any=[]
  project_ResourceGroup:string;
  project_Vnet:string;
  project_Subnet:string;

  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  AzuredrpDisable = false;

  ResourceDisable=false;
  VnetDisable=false;
  SubDisable=false;
  SCMArray = [];
  project_toolGrId: string;
  vdiObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;
  Role:string="";
  SubscriptionList: Subscription_iddropdown[] = [];
  nextUrl: any;
  canLeave(url):boolean{
  
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.clientname == '' || this.toolsGroup.clientname == undefined) &&
      (this.toolsGroup.client_id == '' || this.toolsGroup.client_id == undefined) &&
       (this.toolsGroup.client_secret == '' || this.toolsGroup.client_secret == undefined)  &&
       (this.toolsGroup.siteurl == '' || this.toolsGroup.siteurl == undefined)  &&
        (this.toolsGroup.redirect_uri == '' || this.toolsGroup.redirect_uri == undefined)
         && (this.toolsGroup.hostemail == '' || this.toolsGroup.hostemail == undefined)){
        return true
      }
      if ((this.toolsGroup.clientname != '' || this.toolsGroup.clientname != undefined) &&
      (this.toolsGroup.client_id != '' || this.toolsGroup.client_id != undefined) &&
       (this.toolsGroup.client_secret != '' || this.toolsGroup.client_secret != undefined)  &&
       (this.toolsGroup.siteurl != '' || this.toolsGroup.siteurl != undefined)  &&
        (this.toolsGroup.redirect_uri != '' || this.toolsGroup.redirect_uri != undefined)
         && (this.toolsGroup.hostemail != '' || this.toolsGroup.hostemail != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { 
      this.masterSelected = false;

    }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };

    var PopupToolGroup = localStorage.getItem('Popup_Open_webex')
    if (PopupToolGroup == "true") {
      this.showVdiForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_webex')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
     // console.log("this.toolsGroup.toolGr");
      //console.log(this.toolsGroup.toolGr);
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_webex')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_webex", "false");
    } else {
      this.showVdiForm = false;
    }
   
    this.GeWebXtableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }


  backNavigation(){
    if ((this.toolsGroup.clientname == '' || this.toolsGroup.clientname == undefined) &&
     (this.toolsGroup.client_id == '' || this.toolsGroup.client_id == undefined) &&
      (this.toolsGroup.client_secret == '' || this.toolsGroup.client_secret == undefined)  &&
      (this.toolsGroup.siteurl == '' || this.toolsGroup.siteurl == undefined)  &&
       (this.toolsGroup.redirect_uri == '' || this.toolsGroup.redirect_uri == undefined)
        && (this.toolsGroup.hostemail == '' || this.toolsGroup.hostemail == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  GeWebXtableDetails() {

    this.spinner.show();
    this.apiService.Admin_WebXGrTableDetails().subscribe((res: any) => {
     
      res.forEach(member => {
        //  
        let obj = {
          "client_name": member.client_name,
          "client_id": member.client_id,
          "webex_config_id":member.webex_config_id,
          "client_secret":member.client_secret,
         "redirect_uri":member.redirect_uri,
          "hostemail": member.hostemail,
          "siteurl": member.siteurl,
          
        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }





  setVdiEditing(vdiObj: vdiTool) {
   // debugger; 
    //console.log("scmObj", scmObj)
   
    this.toolsGroup.clientname = vdiObj.client_name;
    this.toolsGroup.client_id = vdiObj.client_id;
    // this.toolsGroup.webex_config_id=vdiObj.webex_config_id;
    this.toolsGroup.client_secret = vdiObj.client_secret;
    this.toolsGroup.hostemail = vdiObj.hostemail;
    this.toolsGroup.redirect_uri = vdiObj.redirect_uri;
    this.toolsGroup.siteurl = vdiObj.siteurl;
    this.project_id_AksEdit=vdiObj.webex_config_id;
   
  }

  getRecord(row) {
    //
    this.clearWebXDetails();
    this.vdiObj = row;
    try {
      localStorage.setItem('vdiToolObj', JSON.stringify(this.vdiObj));
      var getObj = JSON.parse(localStorage.getItem('vdiToolObj'));
      this.setVdiEditing(getObj);
    }
    catch {
      return throwError('Data not found');
    }
    this.editMode = true;
    this.showVdiForm = true;
    this.UpdateConnDisable = true;
    this. ResourceDisable=true;
    this.VnetDisable=true;
    this.SubDisable=true;
    this.AzuredrpDisable = true;
  }
  clearWebXDetails() {
    this.toolsGroup.clientname = "";
    this.toolsGroup.client_id = "";
    // this.toolsGroup.webex_config_id="";
    this.toolsGroup.client_secret = "";
    this.toolsGroup.redirect_uri = "";
    this.toolsGroup.hostemail = "";
    this.toolsGroup.siteurl = "";
   
  
   this. ResourceDisable=false;
    this.VnetDisable=false;
    this.SubDisable=false;
this.UpdateConnDisable=true;
this.AzuredrpDisable = false;
  }

  addWebX() {
    this.clearWebXDetails();
    this.showVdiForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshWebX() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-webex-propertire']);
    });
  }

  
  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].client_name);
        // this.checkedList.push(this.checklist[i].webex_config_id);

        
    }
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        // this.checkedList.push(this.checklist[i].client_name);
        this.checkedList.push(this.checklist[i].webex_config_id);

        
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length-1; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                  var SelectedItemtwo=this.checkedList[index+1]
                 let VdiConfigBoby={
                    "webex_config_id":SelectedItemtwo,
                    "client_name":SelectedItem,


                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    //console.log(this.VdiConfig_details, "checkedList")
    //}
  }
 
  DeleteConfig(){
    // debugger
    //console.log(this.VdiConfig_details);
    //console.log(this.VdiConfig_details[0]);
         var deleteBody: any;

    
    this.userMailId = localStorage.getItem("mail").toLowerCase()
    
    this.check=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      //console.log(this.VdiConfig_details[i]);
      this.check.push(this.VdiConfig_details[i])
      
    }
    //  this.VdiConfig_details.forEach(element => {
    //    console.log(element);
       
    //    this.check.push(element)
       
    //  });
    //console.log(this.check);
    
     deleteBody = {
      "resource_type": "webex",

      "requester_email":this.userMailId,

       "webex_details":this.check,
     }
     //console.log(deleteBody,"deletebody");
     
    
    // debugger;
    //  var deleteBody: any;
    //  this.check=[]
    //  this.VdiConfig_details.forEach(element => {
    //    this.check.push(element.vdi_dropdown_id)
       
    //  });
    //  deleteBody = {
    //    "tool_ids":this.check
    
    //  }
    // deleteBody=this.VdiConfig_details[0]
    
     this.apiService.Admin_WebexDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-webex-propertire']);
         });
         this.alertService.success(res.body.message, this.options);        }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
 

  get_EditeAzureDetails(azureId) {
  //  debugger
    this.spinner.show();
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
    //  debugger
      if (res) {
        // console.log(res, "Id get_EditeAzureDetails");


        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
      this.spinner.hide();
  }

  CheckWebXDuplication() {

    //debugger;

    if (

    this.toolsGroup.clientname == undefined || this.toolsGroup.clientname == "" || this.toolsGroup.clientname == " "
    ||  this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " "
    || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " "
    ||this.toolsGroup.redirect_uri == undefined || this.toolsGroup.redirect_uri == "" || this.toolsGroup.redirect_uri == " "
    ||this.toolsGroup.hostemail == undefined || this.toolsGroup.hostemail == "" || this.toolsGroup.hostemail == " "
    ||this.toolsGroup.siteurl == undefined || this.toolsGroup.siteurl == "" || this.toolsGroup.siteurl == " "
    // ||this.toolsGroup.webex_config_id == undefined || this.toolsGroup.webex_config_id == "" || this.toolsGroup.webex_config_id == " "

      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

     

      this.apiService.Admin_WebXValidateTableDetails(this.toolsGroup.clientname).subscribe((res: HttpResponse<any>) => {
        ////debugger;
        // console.log("Inside Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            case "01":
              this.alertService.error(res.body.msg, this.options);
              break;
            // case "02":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;

            default:
              this.WebXSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  WebXSave() {
    
   //debugger;
    if (
    
      this.toolsGroup.clientname == undefined || this.toolsGroup.clientname == "" || this.toolsGroup.clientname == " "
      ||  this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " "
      || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " "
      ||this.toolsGroup.redirect_uri == undefined || this.toolsGroup.redirect_uri == "" || this.toolsGroup.redirect_uri == " "
      ||this.toolsGroup.hostemail == undefined || this.toolsGroup.hostemail == "" || this.toolsGroup.hostemail == " "
      ||this.toolsGroup.siteurl == undefined || this.toolsGroup.siteurl == "" || this.toolsGroup.siteurl == " "
      // ||  this.toolsGroup.webex_config_id == undefined || this.toolsGroup.webex_config_id == "" || this.toolsGroup.webex_config_id == " "
      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
     
      var saveBody: any;
     // this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########
      saveBody = {
        "client_name": this.toolsGroup.clientname,
    "client_id":  this.toolsGroup.client_id,
    "client_secret":  this.toolsGroup.client_secret,
    "redirect_uri":  this.toolsGroup.redirect_uri,
    "hostemail":  this.toolsGroup.hostemail,
    "siteurl":  this.toolsGroup.siteurl,
    "webexCode": " ",
    "access_token":" ",
    "expires_in": " ",
    "refresh_token": " ",
    "refresh_token_expires_in": " ",
    "access_token_date": "2021-10-20T16:57:01.963Z",
    "refresh_token_date": "2021-10-20T16:57:01.963Z",
    "resource_type": "webex",
    "webex_config_id":"",
    "created_by":localStorage.getItem('mail').toLowerCase()

      }
      //console.log("saveBody");
      // //debugger;

      this.apiService.Admin_WebX_SaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        //  //debugger; 
        if (res.status == 200) {
          //          console.log(res);
          
          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-webex-propertire']);
          });

          this.alertService.success(res.body.message, this.options); 
        }
        else if (res.status == 202) {

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-webex-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {

  }
  cancelok(){
    this.clearWebXDetails();

  if(this.nextUrl==undefined){
    this.refreshWebX()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  
  closeAks() {
    this.showVdiForm = false;
    this.editMode = false;
    this.refreshWebX()

  }
  showImageInfo(domain) {
    //console.log(domain);
    const dialogRef = this.dialog.open(WebexInfoDialog, {
      width: '50%',
      height: '35%',
      disableClose: true,
      data: {
        // image: domain.showImage,
         webexvar:domain
       }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
   
   
  }
  updateWebex() {
    if (
     
      this.toolsGroup.clientname == undefined || this.toolsGroup.clientname == "" || this.toolsGroup.clientname == " "
      ||  this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " "
      || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " "
      ||this.toolsGroup.redirect_uri == undefined || this.toolsGroup.redirect_uri == "" || this.toolsGroup.redirect_uri == " "
      ||this.toolsGroup.hostemail == undefined || this.toolsGroup.hostemail == "" || this.toolsGroup.hostemail == " "
      ||this.toolsGroup.siteurl == undefined || this.toolsGroup.siteurl == "" || this.toolsGroup.siteurl == " ") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      var updateBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      updateBody = 
        {
          "client_name": this.toolsGroup.clientname,
          "client_id": this.toolsGroup.client_id,
          "client_secret": this.toolsGroup.client_secret,
          "redirect_uri": this.toolsGroup.redirect_uri,
          "hostemail": this.toolsGroup.hostemail,
          "siteurl": this.toolsGroup.siteurl,
          "updated_by":localStorage.getItem('mail').toLowerCase()
          }
      }

      this.apiService.Admin_Azure_UpdateWebexProp(updateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-webex-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }
        else if (res.status == 202) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-webex-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }

      },(error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    //}//else

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupwebexDialog, {
      width: '30%',
      height: '48%',

    });
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'project-info.html',
  styleUrls: ['./admin-webex-propertire.component.scss']
})

export class WebexInfoDialog {
  //  image: any = [];
  // Template_id:any;
  //   enabledIcons: any[];
  //   workbenchIcons: any[];
  //   roleData: any;
  clientID:any;
  clientSecret:any;
  redirectUrl:any
 

    
    constructor(
      public dialogRef: MatDialogRef<WebexInfoDialog>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService, public alertService: AlertService,
    ) { 
    
    
      this.clientID=data.webexvar.client_id;
      this.clientSecret=data.webexvar.client_secret;
      this.redirectUrl=data.webexvar.redirect_uri
     

      // console.log(this.stealthwatchtoken);
      
  
      
  
    }


    exit(): void {
      this.dialogRef.close();
    }
  
  }

@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-webex-propertire.component.scss']
})

export class AddToolGroupwebexDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
        
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_webex", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_webex", new_ToolGroupName);
          localStorage.setItem("Popup_Open_webex", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-webex-propertire']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

}
