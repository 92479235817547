<div class="main-holder">

  <mat-card class="main-container">
    <div class="row">
      <div class="offset-md-11 col-md-1">
        <button class="btn-newproject" mat-raised-button color="primary" style="background-color: #1460a3;"
          routerLink="/home">Back</button>
      </div>

    </div>

    <div class="example-container">
      <mat-accordion>
        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              As a PM how do I access Canvas Application ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          To Access Canvas Application, PM needs to be added in Application Directory and should be able to connect to
          internet. 
          <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('temp','As a PM how do I access Canvas Application ?')"> here </a>
          for more details -->
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              As a PM please list down all the features I can use 
            </mat-panel-title>
          </mat-expansion-panel-header>
          PM should be able to do following activity from Canvas Application:-
          <ul>
            <li>Provision/De-Provision VDI</li>
            <li>Start and Stop VDI</li>
            <li>Provision/De-Provision Gitlab repository</li>
            <li>Provision/De-Provision Jira</li>
            <li>Provision/De-Provision Jenkins</li>
            <li>Provision/De-Provision Linux and Windows VM</li>
            <li>Configure VDI Scheduler</li>
            <li>Soft deletion of all tools</li>
            <li>Permanent deletion and restoration of all tools from Recycle bin</li>


          </ul>
          <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('1','As a PM please list down all the features I can use')"> here </a>
          for more details -->
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              What all different IT personas are available in Canvas ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          Canvas makes the onboarding process seamless by providing a single platform for provisioning all the resources used in the development process for all IT personas. 
          Click 
          <a href = "javascript:void(0);" (click) = "onClick('project_onboarding','What all different IT personas are available in Canvas ?')"> here </a>
          for more details
          </mat-expansion-panel>
    
        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              How do I provision VDI for my team ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          PM/Secondary PM can provision VDI to entire team by navigating to VDI screen and selecting data on each mandatory field and finally adding members. Click 
          <a href = "javascript:void(0);" (click) = "onClick('VDI_Creation','How do I provision VDI for my team ?')"> here </a>
          for more details
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              How do I provision VM for my team ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          PM/Secondary PM can provision VDI to entire team by navigating to VM screen and selecting data on each mandatory field and finally adding members. Click 
          <a href = "javascript:void(0);" (click) = "onClick('Virtual Machine','How do I provision VM for my team ?')"> here </a>
          for more details
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              How do I provision Gitlab for my team ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          PM/Secondary PM can provision VDI to entire team by navigating to Gitlab screen and selecting data on each mandatory field and finally adding members. Click 
          <a href = "javascript:void(0);" (click) = "onClick('gitlab','How do I provision Gitlab for my team ?')"> here </a>
          for more details
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              How do I provision Jira for my team ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          PM/Secondary PM can provision VDI to entire team by navigating to Jira screen and selecting data on each mandatory field and finally adding members. Click 
          <a href = "javascript:void(0);" (click) = "onClick('Jira','How do I provision Jira for my team ?')"> here </a>
          for more details
        </mat-expansion-panel>

    
        
        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Why VM in Stop state can not be Soft-delete ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          When VM is in Stop state user allocated to the VM can't be offboarded(removed), so in order to Soft-delete a VM it need to be in a running state. 
          <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('8','Why VM in Stop state can not be Soft-delete ?')"> here </a>
          for more details -->
        </mat-expansion-panel>
        

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              What all Operating Systems , Hardware Configurations & Software are provisioned with the VDI ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          Canvas Provision gives you access to:-
          <ul class="list-scroll">
            <li>Operating System:-</li>
            <ul>
              <li>Windows</li>
              <li>Linux</li>
            </ul>
            <li>Hardware:-</li>
            <ul>
              <li>Disk at beginning D2s_V3 containing v core CPUs and NIC containing IP address</li>
            </ul>

            <li>Software:-</li>

            <ul>
              <li>Microsoft Office 365 ProPlus with latest Patches</li>
              <li>Falcon</li>
              <li>Prohance</li>
              <li>Symantec Endpoint Protection</li>
              <li>WSS</li>
              <li>Adobe Acrobat Reader DC</li>
              <li>Rights Management Sharing Application</li>
              <li>WebEx meeting client</li>
              <li>Chrome</li>
              <li>Local Administrator Password Solution (LAPS)</li>
              <li>Java</li>
              <li>Dot Net (.Net) Framework</li>
              <li>Cisco Anyconnect</li>
              <li>Tortoise SVN</li>
              <li>BigFix </li>
              <li>Cisco Jabber</li>
              <li>WebEx Teams</li>
              <li>FORCEPOINT-ONE-ENDPOINT - DLP</li>
              <li>Microsoft Teams</li>
              <li>Dependency Agent</li>
              <li>Microsoft Edge</li>
              <li>Microsoft Monitoring Agent</li>
              <li>Microsoft RMS Addins</li>
              <li>Notepad</li>
              <li>iTime</li>
              <li>iAttendance</li>
              <li>myltiapps</li>
              <li>iAssist</li>
              <li>iRequest</li>
              <li>imanage</li>
              <li>iProfile</li>
              <li>sscportal</li>
              <li>Java 1.8(JRE)</li>
              <li>Eclipse for Java EE</li>
              <li>MySQL</li>
              <li>Docker Desktop</li>
              <li>Maven</li>
              <li>MySQL Workbench(8.0)</li>
              <li>Apache JMeter</li>
              <li>Apache Tomcat(8.5.54)</li>
              <li>Node JS</li>
              <li>Visual Studio Code</li>
              <li>Python</li>
              <li>Pycharm</li>
              <li>Angular</li>
              <li>MongoDB Compass</li>
              <li>Git</li>
              <li>Notepad++</li>
              <li>7zip</li>
              <li>SSCM Agent</li>
              <li>Citrix Workspace 2009</li>
              <li>Vmware Horizon Client</li>
              <li>Postman</li>
              <li>Putty</li>

            </ul>
          </ul>
          <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('9','What all Operating Systems , Hardware Configurations & Software are provisioned with the VDI ?')"> here </a>
          for more details -->
        </mat-expansion-panel>

        <!-- <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Can I run code scans using CAST, If yes then how
            </mat-panel-title>
          </mat-expansion-panel-header>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </mat-expansion-panel> -->

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Please list the communication channels and share details on how to configure them
            </mat-panel-title>
          </mat-expansion-panel-header>
          In Canvas Provisioning, we have following Channels:-
          <ul>
            <li>MS Teams</li>
            <li>Web-Ex</li>
          </ul>
          <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('10','Please list the communication channels and share details on how to configure them')"> here </a>
          for more details -->
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              How do I setup my CI/CD Pipeline in Canvas ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          Canvas Workplace enables you to setup CI/CD pipeline by navigating to Dev-ops Screen and Selecting Jenkins. Here we have enabled functionality to create project with different instance and project type also add members in it.Click 
          <a href = "javascript:void(0);" (click) = "onClick('Jenkin','How do I provision Jenkins for my team ?')"> here </a>
          for more details
           <!-- Click 
          <a href = "javascript:void(0);" (click) = "onClick('11', 'How do I setup my CI/CD Pipeline in Canvas ?')"> here </a>
          for more details -->
        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Do we have an option to customize roles ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          Canvas Workplace enables you to use template as per the requirement which has been configured through admin panel, as per the selected template different customize roles will be seen in User Panel. Click <a href = "javascript:void(0);" (click) = "onClick('Project_role', 'Do we have an option to customize roles ?')"> here </a>
          for more details

        </mat-expansion-panel>

        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Is there a limit to number of team members I can add ?
            </mat-panel-title>
          </mat-expansion-panel-header>
          There is no such limitation on adding members to a particular project. Members can be added either in the middle or start of project. 
          Click 
          <a href = "javascript:void(0);" (click) = "onClick('TeamMembers', 'Is there a limit to number of team members I can add ?')"> here </a>
          for more details
        </mat-expansion-panel>



        <mat-expansion-panel class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              What are the next MVP and Release functionalities expected ?
            </mat-panel-title>
          </mat-expansion-panel-header>
              AWS Workspace and EC2, Cost Reports, Subscription Automation, Soft deletion in Admin panel, Schedule meetings through Teams and Webex, Project onboarding through Imanage are part of next MVP. 
              <!-- Click 
              <a href = "javascript:void(0);" (click) = "onClick('14', 'What are the next MVP and Release functionalities expected ?')"> here </a>
              for more details -->
            </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="faq-body">
          <mat-expansion-panel-header>
            <mat-panel-title class="bold">
              Kindly share Support Details
            </mat-panel-title>
          </mat-expansion-panel-header>
          For any assistance you can reach out to:-
          {{mailId}}
        </mat-expansion-panel>



      
                

      </mat-accordion>

      


    </div>

  </mat-card>
</div>