<div class="pop-up">
        <div class="">
            <span  class="vertical-line mx-0 mx-md-3"> </span>
             <span class="header mb-0">{{req}} : Move to Recycle ?</span> 
            <span (click)="onNoClick()" class="material-icons close-icon">
                close
              </span>
     </div>
     <hr class="margin-class" />
        <div class="popup-container">
           
            <div class="" >
                <h3 class="mb-3 col-md-12 mt-2">Kindly inform assigned team members to take backup to avoid loss of data</h3>
                <div class="text-right">
                    <div class="row" >
                        <div class="col-md-9 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="selectedOption('Yes')">Yes</button>
                            </div>
                        <div class="col-md-3 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="popUpClose('No')">No</button>
                            </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>