import { Component, OnInit } from '@angular/core';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-teams-dashboard',
  templateUrl: './teams-dashboard.component.html',
  styleUrls: ['./teams-dashboard.component.scss']
})
export class TeamsDashboardComponent implements OnInit {
  projectId: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
