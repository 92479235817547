import { Component, OnInit, Inject } from '@angular/core';
import { ChartOptions, ChartType, Chart,ChartDataSets } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogOverviewExampleDialog, DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { AuthService } from '../../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert'; 
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import * as pluginLabels from 'chartjs-plugin-labels';
import {map, startWith} from 'rxjs/operators';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {Observable} from 'rxjs';

import { MatTableDataSource } from '@angular/material/table';
import { DetailViewDialog, gitlabDetailViewDialog, jiraDetailViewDialog, jenkinsViewDialog } from '../reports/reports.component';
import { MembersListDialog } from '../scm-tool/scm-tool.component';
import { resource } from 'selenium-webdriver/http';

@Component({
  selector: 'app-report-cost',
  templateUrl: './report-cost.component.html',
  styleUrls: ['./report-cost.component.scss']
})
export class ReportCostComponent implements OnInit {
  
  Role:string="";
  projectId: any;
  AzureArray = [];
  JiraArray = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  
  dtOptions: DataTables.Settings = {
    searching: false,

    paging: false,

    ordering: false,

    info: false
  };
  DailycostDict={}
  dtOptions2: DataTables.Settings = {};
  dtTrigger = new Subject();
  jiraDetailsData: any;
  jiraMembers: any;
  detailsData: any;
  scmDetailsData: any;
  VmDetailsData: any;
  jenkinsDetailsData: any;
  summaryData: any;
  monthNumber: string;
  label: any;
  vmCost: any;
  networkCost: any;
  diskCost: any;
  Total: any;
  // pieChartData2: any;
  public resourceGroupArray = ['Select'];
  public resourceCostTypeArray = ['Select','Daily', 'Aggregate'];
  public resourceCostCurrencyArray=['INR','USD']
  public resourceCostDurationArray = ['Select','Custom', 'Last Month','Current Month'];
  // pieChartLabels2: any;
  public selectOptions = ['2021', '2022','2023'];
  public selectOptions2 = ['January', 'February','March','April','May','June','July','August','September','October','November','December'];
  public graphYearArray = ['2021', '2022','2023'];
  public graphMonthArray = ['January', 'February','March','April','May','June','July','August','September','October','November','December'];
  currencyOptions = ['USD','INR'];
  selectedCurrency :any;
  public selectedOption = 'Select year';
  public selectedOption2 = 'Select month';
  //
  public resourceGroupName = 'Select';
  public resourceCostType = 'Daily';
  public resourceCostDuration = 'Custom';
  public resourceCostCurrency = 'INR';

  //
  public graphYear = 'Select year';
  public testValue='None'
  public graphMonth = 'Select month';
  showcostdiv: boolean;
  showToolBarChart: boolean;
  ShowToolTableView: boolean;
  exportFlag:boolean=false;

  showDetails = false;
  timelineChartData: any;
  tableRepoArray: any[];
  vdiTableData: any[];
  memberString: any;
  showTable: boolean;
  pooledVdiTableData: any[];
  personalVdiTableData: any[];
  chartData: any;
  dtOptionsPooled :  DataTables.Settings = {};
  dtOptionsPersonal :  DataTables.Settings = {}; 
  
  //New pie chart code

public pieChartOptions2 = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    labels: {
      render: 'label',
      fontColor: ['green', 'white', 'red'],
      fontStyle: 'bold',     
    }
   

  },
  legend: {
    display: true,
    labels: {
      fontColor: 'red',
      fontSize:24,
    },
  },
};
//public pieChartPlugins2 = [pluginLabels];

public pieChartLabels2: Label[] = [];
public pieChartLabelsDaily: Label[] = [];

public options2: any = {
  legend: { position: 'right',
  display: true,
  labels: {
    fontColor: 'black',
    fontSize: 20,
    boxWidth:120,
    fontStyle: 'bold',     

  },    
},
   


  plugins: {
    labels: {
      render: 'percentage',
      // fontColor: ['green', 'white', 'red'],
      fontColor:'black',
      fontStyle: 'bold',
      fontSize:18,     
      precision: 2,

    }
   

  },
  
};
public optionsDaily: any = {
  legend: { position: 'top',
  display: true,
  labels: {
    fontColor: 'black',
  
    fontStyle: 'bold',     

  },    
},
   


  plugins: {
    labels: {
      render: 'percentage',
      // fontColor: ['green', 'white', 'red'],
      fontColor:'black',
      fontStyle: 'bold',
      fontSize:18,     
      precision: 2,

    }
   

  },
  
};
public options3: any = {
  legend: { position: 'right',
  display: true,
  labels: {
    fontColor: 'black',
    fontSize: 12,
    // boxWidth:120,
    // boxHeight:20,
    fontStyle: 'bold',     

  },    
},
   


  plugins: {
    labels: {
      render: 'percentage',
      // fontColor: ['green', 'white', 'red'],
      fontColor:'black',
      fontStyle: 'bold',
      fontSize:10,     
      precision: 2,

    }
   

  },
  
};
pieChartColor:any = [
  {
      backgroundColor:
       ['rgba(30, 169, 224, 0.8)',
      'rgba(255,165,0,0.9)',
      'rgba(139, 136, 136, 0.9)',
      'rgba(255, 161, 181, 0.9)',
      'rgba(255, 102, 0, 0.9)'
      ]
  }
]
pieChartColorsDaily:any = [
  {
      backgroundColor:
       ['rgba(30, 169, 224, 0.8)',
      'rgba(255,165,0,0.9)',
      'rgba(139, 136, 136, 0.9)',
      'rgba(255, 161, 181, 0.9)',
      'rgba(255, 102, 0, 0.9)',
      'rgba(245, 40, 145, 0.8)',
      'rgba(19, 65, 12, 0.8)',
      'rgba(199, 190, 23, 0.8)',
      'rgba(233, 72, 15, 0.8)',
      'rgba(204, 88, 232, 0.83)',
      'rgba(88, 232, 228, 0.83)',
      'rgba(88, 156, 232, 0.83)',
      'rgba(17, 173, 31, 0.83)',
      'rgba(126, 214, 28, 0.83)'
      ]
  }
]
public pieChartData2: SingleDataSet = [];
public pieChartDataDaily: SingleDataSet = [];

// Linechart config
machineDailyCostFlag:boolean=false
public lineChartData: ChartDataSets[] = [
  { data: [], label: 'Series A' },
];
public lineChartLabels: Label[] = [];
public lineChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false
};
public lineChartColors: Color[] = [
  {
    borderColor: 'black',
    backgroundColor: 'rgba(0,0,128,0.6)',
  },
];
public barchartColors: Array<any> = [
  { // first color
    backgroundColor: 'rgba(0,255,0,0.8)',
    
  },

];
public lineChartLegend = true;
public lineChartType: ChartType = 'line';
public lineChartPlugins = [];
 dailyMachineData:any =[];
 dailyMachineLabel: any = [];



//
// public pieChartColorsDaily: Color[] = [
//   { backgroundColor: '#09519B' },
//   { backgroundColor: '#F5C767' },
//   { backgroundColor: '#7A62BA' },
//   { backgroundColor: '#566970' },
//   { backgroundColor: '#EF7721' },
//   { backgroundColor: '#39A768' },
//   { backgroundColor: '#347ED3' },
//   { backgroundColor: '#FFD75E' },
// ]
public pieChartType2: ChartType = 'pie';
public pieChartTypeDaily: ChartType = 'pie';

public pieChartTypeGraph: ChartType = 'pie';
//Line chart

//
public pieChartLegend2 = true;
  vdiCount: any;
  vmCount: any;
  currency: any;
  flag: boolean;
  selectedCurrencyAzure: string;
  JiraCount: any;
  JiraCurrency: any;
  JiraUnitCost: any;
  JiraTotalCost: any;
  JiraMembers: any;
  JiraCostArray: any;
  psnew: string;
  noData: any;
  width: any;
  height: any;
  requestID: any;
  year: any;
  flaggraph:boolean=false;
  datasets=[[
    { "data": [0, 30, 20, 40, 35, 45, 33, 0, 0], "label": "Bar1" },
    { "data": [0, 50, 60, 55, 59, 30, 40, 0, 0], "label": "Bar 2" },
   
  ]]

  labels=[ ["FirstPlaceholder", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "LastPlaceholder"]]
  chartinfi = {
    
    
    // "labels": ["FirstPlaceholder", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "LastPlaceholder"],
    
    "options": {
      "legend": {
        "text": "You awesome chart with average line",
        "display": true,
      },
      "scales": {
        "yAxes": [{
          "ticks": {
          "beginAtZero": true
          }
        }],
        "xAxes": [{
          "ticks": {
          "min": "Monday",
          "max": "Sunday",
          }
        }],
      }
    }
  };
  allVnetInfo: any=[];
  currentValue: any=[];
  limitValue: any=[];
  finaldataSets:any=[]
  
  // finaldataSets:{data:number[],label:string}[];
  labelValue: any=[];
  headLabel: any=[];
  // datasets2=[]
  // labels2=[]
  myControl = new FormControl('');
  myControlDaily = new FormControl('');

  optionsSearch: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  optionsSearchDaily: string[] = ['One', 'Two', 'Three'];
  filteredOptionsDaily: Observable<string[]>;
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true
  };
  public resBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public resBarChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];

  public barChartType: ChartType = 'horizontalBar';
  public barChartLegend = true;
  public resBarChartLegend = true;
  public resBarChartType: ChartType = 'bar';


  public barChartPlugins = [];
  public resBarChartPlugins = [];

  public barChartOptions2: ChartOptions = {
    maintainAspectRatio:true,
    responsive: true,


  };

    public barChartData2: ChartDataSets[] = [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
     
    ];
    public resBarChartData: ChartDataSets[] = [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
     
    ];

    public barChartLabels2: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartType2: ChartType = 'bar';
    public barChartLegend2 = true;
    public barChartPlugins2 = [];
  
      public barChartData: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
       
      ];

      
  monthlyData:any=[]
  monthlyLabel:any=[]
  monthlyVdiVMData: any=[];
  monthlyVdiVMLabel: any=[];
  monthlyFlag: boolean=false;
  // monthlyMachineData: any=[];
  public monthlyMachineData: SingleDataSet = [];
  public monthlyMachineLabel: Label[] = [];
  pieMachineMonth: any;
  pieMachineYear: any;
  pieMachineMonthTotal: any;
  machineDataValue: string="";
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  resourceCostINR: any[];
  resourceCostUSD: any[];
  resourceCostLabels: any[];
  resourceCostLabel: any;
  resourceGraphFlag: boolean=false;
//Service cost chart
public serViceCostData: SingleDataSet = [];
public serViceCostDataLabels: Label[] = [];
public serviceType: ChartType = 'pie';
public serviceLegend = true;

public serviceOption: any = {
  legend: { position: 'right',
  display: true,
  labels: {
    fontColor: 'black',
    fontSize: 13,
  
    fontStyle: 'bold',     

  },    
},
   


  plugins: {
    labels: {
      render: 'percentage',
      // fontColor: ['green', 'white', 'red'],
      fontColor:'black',
      fontStyle: 'bold',
      fontSize:18,     
      precision: 2,

    }
   

  },
  
};
// public serViceCostData: ChartDataSets[] = [
//   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
 
// ];


// public serViceCostDataLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  serviceData: any=[];
  serviceLabel: any=[];
  serviceMonthlyCost: any;
  dailyMachineProductData: any[][]=[];
  dailyMachineProductLabel: any[][]=[];
  DateWiseFlagPie: boolean=false;
  VisFlag:boolean=true;
//

  constructor(private datePipe: DatePipe, public alertService: AlertService, private apiService: AuthService, private spinner: NgxSpinnerService, public dialog: MatDialog, public router: Router) {

   }

   ngOnInit(): void {
    

    // console.log(localStorage);
    setTimeout(() => {
      this.VisFlag=false
      
    }, 5000);
    this.flaggraph=false
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.filteredOptionsDaily = this.myControlDaily.valueChanges.pipe(
      startWith(''),
      map(value => this._filterDaily(value || '')),
    );
  


    //  debugger
      //      this.router.navigateByUrl('/projectSummary', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/reports-cost']);
      // });
      
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  
    const date = new Date();
    
    // console.log(monthNames[date.getMonth()]);
    // console.log(date.getFullYear());

    
    this.dtOptions2 = {
      searching: false,
      paging:false,
       ordering: false,
       info:false
       //pageLength: 5
      };
      this.selectedCurrency = "USD";
      this.selectedCurrencyAzure = "INR";

    // this.onToolSelectedJira(this.selectOptions2,this.selectedOption,"INR")
    // this.vdiCount='-';
    // this.vmCount='-';
    this.ShowToolTableView=true;


    //  debugger;
    
    this.projectId=localStorage.getItem('projectId');
    
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      // this.router.navigateByUrl('/projectSummary', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/reports-cost']);
      // });
      let element : HTMLElement = document.getElementById('CallModel2') as HTMLElement;
      if(element!=null){
      element.click();
      }
      this.selectedOption2=monthNames[date.getMonth()];
      this.selectedOption=String(date.getFullYear());
      this.graphMonth=monthNames[date.getMonth()];
      this.graphYear=String(date.getFullYear());
      this.onToolSelected(this.selectedOption2,this.selectedOption)

      let newCatalog_id = localStorage.getItem('catalogId');
      this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
        
        // console.log(data.network_config_tools);
        // debugger
        var VnetDetails=data.network_config_tools
        this.resourceGroupArray=['Select']
        for(let i=0;i<VnetDetails.length;i++){
          
          let obj= {
            "resource_group": VnetDetails[i].resource_group,
            "vnet":VnetDetails[i].vnet,
            "subnet":VnetDetails[i].subnet
        }
        this.allVnetInfo.push(obj)
        this.resourceGroupArray.push(VnetDetails[i].resource_group)

        }
        let body={
          "vnet_details":this.allVnetInfo
        }
        this.apiService.getVnetGraphDetails(body).subscribe((data: any) => {
        
          // console.log(data.Usage_Details);
          for (let i=0;i<data.Usage_Details.length;i++){
            let dummyCurrentValue=[]
            let dummyLimitValue=[]
            let dummyLabel=[]
            // console.log(data.Usage_Details[i].resource_group+" "+"Vs "+data.Usage_Details[i].vnet);
            
            this.headLabel.push(data.Usage_Details[i].resource_group+" "+"Vs "+data.Usage_Details[i].vnet)
            for (let j=0;j<data.Usage_Details[i].usage.length;j++){
              let x=(data.Usage_Details[i].usage[j].currentValue)
              dummyCurrentValue.push(x)
              dummyLimitValue.push((data.Usage_Details[i].usage[j].limit))
              dummyLabel.push(data.Usage_Details[i].usage[j].subnet)
              


            }
            this.currentValue.push(dummyCurrentValue)
            this.limitValue.push(dummyLimitValue)
            this.labelValue.push(dummyLabel)


          }

     
          // console.log(this.headLabel);
          
          
          
          
        }, (error: HttpErrorResponse) => {
          if (error.status == 437) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.alertService.error("Unable to fetch data", this.options);
            throw error;
          }
  
        })
        
      }, (error: HttpErrorResponse) => {
        if (error.status == 437) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Unable to fetch data", this.options);
          throw error;
        }

      })
      // console.log(this.allVnetInfo);

      //this.getJiraData();
      //this.getAzureData();

    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsSearch.filter(option => option.toLowerCase().includes(filterValue));
  }
  test(month,year){
    
    this.machineDailyCostFlag=false
    this.myControlDaily.reset('')
    this.DateWiseFlagPie=false
    this.DailycostDict={}
    this.optionsSearchDaily=[]

    this.monthlyMachineData=[]
    this.monthlyMachineLabel=[]
    this.dailyMachineData=[]
    this.dailyMachineLabel=[]
    this.dailyMachineProductData=[]
    this.dailyMachineProductLabel=[]
    // console.log(month,year);
    let machineValue=this.myControl.value
    this.machineDataValue=""
    // console.log(machineValue);
    
    this.apiService.getDailyMonthlyMachineDetails(machineValue,month,year).subscribe((data:any)=>{
      // console.log(data);
      this.machineDataValue=data.cost.ResourceName
      let machineData=data.cost.Monthly_ProductWise_Costs
      let machineDailyData=data.cost.Daily_Costs
      this.pieMachineMonth=data.cost.month
      this.pieMachineYear=data.cost.year
      this.pieMachineMonthTotal=parseFloat(data.cost.MonthlyBillinginINR).toFixed(2)

      
      for(let i=0;i<machineData.length;i++){
        this.monthlyMachineData.push((machineData[i].CostInBillingCurrency))
        this.monthlyMachineLabel.push(machineData[i].ProductName)
      }
      for(let i=0;i<machineDailyData.length;i++){
        this.dailyMachineData.push(machineDailyData[i].DailyBillinginINR)
        this.dailyMachineLabel.push(machineDailyData[i].Date)
        var tempProductDailyLabel=[]
        var tempProductDailyData=[]
        
        for (let j=0;j<machineDailyData[i].ProductWiseCost.length;j++){
          tempProductDailyLabel.push(machineDailyData[i].ProductWiseCost[j].ProductName)
          tempProductDailyData.push(machineDailyData[i].ProductWiseCost[j].CostInBillingCurrency)


        }
        this.dailyMachineProductData.push(tempProductDailyData)
        this.dailyMachineProductLabel.push(tempProductDailyLabel)

      }

      


      this.lineChartData=[
        { data: this.dailyMachineData, label: 'Cost(INR)' },
      ];
      this.lineChartLabels=this.dailyMachineLabel
      this.machineDailyCostFlag=true
   
      for(let i=0;i<this.dailyMachineLabel.length;i++){
        this.DailycostDict[this.dailyMachineLabel[i]]=[this.dailyMachineProductData[i],this.dailyMachineProductLabel[i]]

      }
      this.optionsSearchDaily=this.dailyMachineLabel

      
      
      
      
      

 
  }
    , (error: HttpErrorResponse) => {
      // debugger
      if (error.status == 400){

        this.spinner.hide()
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        this.spinner.hide();
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
    
  }
  private _filterDaily(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.optionsSearchDaily.filter(option => option.toLowerCase().includes(filterValue));
  }
  test2(){
    this.DateWiseFlagPie=true
    let machineValue=this.myControlDaily.value
// console.log(this.DailycostDict);

    // console.log(this.DailycostDict[machineValue][0]);
    // console.log(this.DailycostDict[machineValue][1]);
    this.pieChartDataDaily=this.DailycostDict[machineValue][0]
    this.pieChartLabelsDaily=this.DailycostDict[machineValue][1]

    
  }
  dailyCost(){
    // console.log(this.currentValue);
    
    for(let i=0;i<this.currentValue.length;i++){
      let obj=[
        {"data":this.currentValue[i],"label":"Curent"},
        {"data":this.limitValue[i],"label":"Limit "},

      ]
      
      this.finaldataSets.push(obj)
    }

    this.datasets=this.finaldataSets
    this.labels=this.labelValue
    this.chartinfi = {
      // "datasets": this.finaldataSets,
      
      // [
      //   { "data": a, "label": "Bar1" },
      //   { "data": [0, 50, 60, 55, 59, 30, 40, -1, 0], "label": "Bar 2" },
       
      // ],
      // "labels": this.labelValue,
      
      "options": {
        "legend": {
          "text": "You awesome chart with average line",
          "display": true,
        },
        
        "scales": {
          "yAxes": [{
            "ticks": {
            "beginAtZero": true
            }
          }],
          "xAxes": [{
            "ticks": {
            "min": "Monday",
            "max": "Sunday",
            }
          }],
        }
      }
    };
    this.flaggraph=true

  }
   convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  resourceGroupCostFunc(resourceGroupName,resourceCostType,resourceCostDuration,date,currency){
    
    
    let startDate = this.convertDate(date.start)
    let endDate=this.convertDate(date.end)
    
    this.resourceCostINR=[]
    this.resourceCostUSD=[]
    this.resourceCostLabels=[]
    this.resourceGraphFlag=false

    this.spinner.show()
    // let finalresourceGroupName=""
    let finalresourceCostType=resourceCostType
    let finalresourceCostDuration=resourceCostDuration
    if (resourceCostType=="Aggregate"){
      finalresourceCostType="None"
    }
    if (resourceCostDuration=="Last Month"){
      finalresourceCostDuration="LastMonth"
    }
    else if(resourceCostDuration=="Current Month"){
      finalresourceCostDuration="CurrentMonth"
    }
    this.apiService.getResourceGraphDetails(resourceGroupName,finalresourceCostType,finalresourceCostDuration,startDate,endDate).subscribe((res: any) => {
       
        // debugger
        
        
        this.spinner.hide()
        this.resourceGraphFlag=true
        for(let i=0;i<res.rows.length;i++){
          this.resourceCostINR.push(res.rows[i][0])
          this.resourceCostUSD.push(res.rows[i][1])
          if(finalresourceCostType!='None'){
            let startDate=String(res.rows[i][2])
            let year = startDate.substring(0, 4);
            let month = startDate.substring(4, 6);
            let day = startDate.substring(6, 8);
            this.resourceCostLabels.push(year + '-' + month + '-' + day)
          }
          else{
            this.resourceCostLabels.push(res.rows[i][2])
          }
         

        }
      
        if(currency=='USD'){
          this.resBarChartData=[
            { data: this.resourceCostUSD, label: 'Cost (USD)', stack: 'a' },
           
          ];
        }
        else{
          this.resBarChartData=[
            { data: this.resourceCostINR, label: 'Cost (INR)', stack: 'a' },
           
          ];
        }

        this.resBarChartLabels=this.resourceCostLabels

        
        
    }
      , (error: HttpErrorResponse) => {
        //
        this.spinner.hide();
        if (error.status == 436) //doesnt work
        {
          this.spinner.hide()


          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { 
          this.spinner.hide()

        }
        else {
          this.spinner.hide()

          this.alertService.error(error.error.message, this.options);
          // throw error;
        }
      })
    
    

  }
  getJiraData() {
    this.spinner.show();
    this.JiraArray = [];
    this.apiService.getCostEstimateJira(this.projectId).subscribe((res: any) => {
      //console.log(res);
      let memberArray = [];
      res.forEach(member => {

        member.members.forEach(member => {
          if (member !== '-') {
            let memberObj = {
              name: member,

            }
            memberArray.push(memberObj)
            //console.log(memberArray, "memberArray")
          }

        })

        let obj = {
          "count": member.count,
          "unitcost": member.unitcost,
          "totalcost": member.totalcost,
          "members": memberArray,
          "currency": member.currency
        }
        this.JiraArray.push(obj)
        //console.log(obj, "obj")
      })
      // this.dataSource.data = this.AzureArray as AzureDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        //
        this.spinner.hide();
        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          // this.alertService.error("Invalid Input", this.options);
          // throw error;
        }
      })

   }
   showBarChart(){
    //  console.log("first");
     
    this.showToolBarChart = true;
    this.exportFlag=true
    this.ShowToolTableView = false;
    this.hideFlags('bar-chart');
  }
  showTableView(){
    // console.log("sec");

    this.showToolBarChart = false;
    this.exportFlag=false;
    this.ShowToolTableView = true;
  }
  getAzure(){
    setTimeout(() => {
    }, 2000)
    this.selectedOption2="January";
    this.selectedOption="2021";  }


  onToolSelectedJira(month,year,currency){
    // console.log(month,year,currency);
    
    //  debugger
  
      // console.log("hello");
      // console.log(this.projectId);
      if(month=="January"){
        this.monthNumber='01';
      }
      else if(month=="February"){
        this.monthNumber="02"
      }
      else if(month=="March"){
        this.monthNumber="03"
      }
      else if(month=="April"){
        this.monthNumber="04"
      }
      else if(month=="May"){
        this.monthNumber="05"
      }
      else if(month=="June"){
        this.monthNumber="06"
      }
      else if(month=="July"){
        this.monthNumber="07"
      }
      else if(month=="August"){
        this.monthNumber="08"
      }
      else if(month=="September"){
        this.monthNumber="09"
      }
      else if(month=="October"){
        this.monthNumber="10"
      }
      else if(month=="November"){
        this.monthNumber="11"
      }
      else if(month=="December"){
        this.monthNumber="12"
      }
      this.JiraArray=[]
      this.spinner.show();
      this.apiService.getCostEstimateJiraCost(this.projectId,this.monthNumber,year,currency).subscribe((data:any)=>{
//console.log(data);

  let memberArray = [];
 // data.forEach(member => {

    data.members.forEach(member => {
      if (member !== '-') {
        let memberObj = {
          name: member,

        }
        memberArray.push(memberObj)
        //console.log(memberArray, "memberArray")
      }

    })

    let obj = {
      "count": data.count,
      "unitcost": data.unitcost,
     "totalcost": parseInt(data.totalcost).toFixed(2),
      "members": memberArray,
      "currency": data.currency
    }
    this.JiraArray.push(obj)
    
    
    this.spinner.hide();
    this.dtTrigger.next();
    //console.log(obj, "obj")
  //})
  // this.dataSource.data = this.AzureArray as AzureDetails[]
 
}
  , (error: HttpErrorResponse) => {
    //
    this.spinner.hide();
    if (error.status == 400) //doesnt work
    {

      this.alertService.error(error.error.message, this.options);

    }
    else if (error.status == 403) { }
    else {

      // this.alertService.error("Invalid Input", this.options);
      // throw error;
    }
  })
        // this.JiraCostArray=data
        // this.JiraCount=data.count
    
        //   this.JiraCurrency=data.currency
        //   this.JiraUnitCost=data.unitcost
        //   this.JiraTotalCost=data.totalcost

        
        // this.JiraMembers=data.members
        
        //console.log(this.JiraCount);
      // this.spinner.hide()

      

      
      

  }
  onToolSelectedGraph(month,year){
    this.monthlyFlag=false
    this.monthlyData=[]
    this.monthlyLabel=[]
    this.monthlyVdiVMData=[]
    this.monthlyVdiVMLabel=[]
    this.optionsSearch=[]
    this.serviceData=[]
    this.serviceLabel=[]
    this.machineDailyCostFlag=false
    this.myControl.reset('')

    this.DateWiseFlagPie=false
    // console.log(month,year);
    // console.log(localStorage);
    this.apiService.getMonthlyGraphDetails(this.projectId,month,year).subscribe((data:any)=>{
      let dataLen=data.cost.other_cost
      let serviceCost=data.cost.Service_Cost_Distribution
      this.serviceMonthlyCost=parseFloat(serviceCost.MonthlyCost).toFixed(2)

      for(let i=0;i<serviceCost.ProductWiseCost.length;i++){
        this.serviceData.push(parseFloat(serviceCost.ProductWiseCost[i].CostInBillingCurrency).toFixed(2))
        this.serviceLabel.push(serviceCost.ProductWiseCost[i].ProductName)
      }     
      this.serViceCostData=this.serviceData
      this.serViceCostDataLabels=this.serviceLabel
      for(let i=0;i<dataLen.length;i++){
        this.monthlyData.push(dataLen[i].BillinginINR)
        this.monthlyLabel.push(dataLen[i].ResourceName)
      }
      let vdi_vm_cost=data.cost.vdi_vm_cost
      for(let i=0;i<vdi_vm_cost.length;i++){
        this.monthlyVdiVMData.push(vdi_vm_cost[i].BillingInINR)
        this.monthlyVdiVMLabel.push(vdi_vm_cost[i].vmName)
      }
      this.optionsSearch=this.monthlyVdiVMLabel
      
      
      this.barChartData=[
        { data: this.monthlyData, label: 'Cost (INR)', stack: 'a' },
       
      ];
      this.barChartLabels=this.monthlyLabel


      this.barChartData2=[
        { data: this.monthlyVdiVMData, label: 'Cost (INR)', stack: 'a' },
       
      ];
      this.barChartLabels2=this.monthlyVdiVMLabel
      this.monthlyFlag=true
      // console.log();
      
      
      

 
  }
    , (error: HttpErrorResponse) => {
      // debugger
      if (error.status == 400){

        this.spinner.hide()
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        this.spinner.hide();
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })

       
    

  }
   onToolSelected(month,year){
     this.year=year
    // debugger
    this.hideFlags('search');
     //console.log(month,year);
     
    //  debugger
     this.spinner.show()
  
      //console.log("hello");
      //console.log(this.projectId);
      if(month=="January"){
        this.monthNumber='01';
      }
      else if(month=="February"){
        this.monthNumber="02"
      }
      else if(month=="March"){
        this.monthNumber="03"
      }
      else if(month=="April"){
        this.monthNumber="04"
      }
      else if(month=="May"){
        this.monthNumber="05"
      }
      else if(month=="June"){
        this.monthNumber="06"
      }
      else if(month=="July"){
        this.monthNumber="07"
      }
      else if(month=="August"){
        this.monthNumber="08"
      }
      else if(month=="September"){
        this.monthNumber="09"
      }
      else if(month=="October"){
        this.monthNumber="10"
      }
      else if(month=="November"){
        this.monthNumber="11"
      }
      else if(month=="December"){
        this.monthNumber="12"
      }
      let userPsNumber = "";
      let userName = localStorage.getItem('profile');
      this.apiService.psNumber.subscribe((psNumber: any) => {
        userPsNumber = psNumber
      })
      //console.log(userPsNumber);
      this.psnew=userPsNumber
      
  
      
    // this.spinner.show();
    this.apiService.getCostEstimate(this.projectId,this.monthNumber,year,this.psnew).subscribe((data:any)=>{
      // debugger
      //console.log(data);
      this.spinner.hide();
      this.alertService.success(data.message, this.options);

      if(data.message== "The report will be sent to you via mail. This process can take a few minutes."){
        this.label=""
        this.currency=""
        this.vmCost=""
        this.vdiCount=""
        this.vmCount=""
        this.diskCost=""
        this.networkCost=""
        this.Total=""
        this.pieChartLabels2=[]
        this.pieChartData2=[]
        // let element : HTMLElement = document.getElementById('CallModel2') as HTMLElement;
        // element.click();
      }
      else{


      
      // debugger

      this.flag=true;
      this.label=month;
      this.currency="INR"
      if (data==null)
      {

        this.vmCost=0
        this.vdiCount=0
        this.vmCount=0
        this.diskCost=0
        this.networkCost=0
        this.Total=0
        this.pieChartLabels2=[]
        this.pieChartData2=[]

      }
      else{

     
      if (data.estimatedVdiVmCostForProject.vmCost=="0.00"){
        this.vmCost=0;
      }
      else{
        this.vmCost=(data.estimatedVdiVmCostForProject.vmCost);

      }
      if (data.estimatedVdiVmCostForProject.networkCost=="0.00"){
        this.networkCost=0;
      }
      else{
        this.networkCost=(data.estimatedVdiVmCostForProject.networkCost);

      }
      if (data.estimatedVdiVmCostForProject.diskCost=="0.00"){
        this.diskCost=0;
      }
      else{
        this.diskCost=(data.estimatedVdiVmCostForProject.diskCost);

      }
      if (data.estimatedVdiVmCostForProject.totalCost=="0.00"){
        this.Total=0;
      }
      else{
        this.Total=(data.estimatedVdiVmCostForProject.totalCost);

      }
      // if (data.vdiVmRecordsCostForProject.projectVdiVmCostDetails.length==0){
      //   this.currency='INR'
      // }
      // else{
      //   this.currency=data.vdiVmRecordsCostForProject.projectVdiVmCostDetails[0].cost_records.billingCurrency;


      // }
      this.vdiCount=data.vDIvMcount.vdiCount; 

      this.vmCount=data.vDIvMcount.vmCount; 
      this.pieChartData2=data.estimatedVdiVmCostForProject.pieChartData.valuesAndLabels.value;
     
      
      
      this.pieChartLabels2=data.estimatedVdiVmCostForProject.pieChartData.valuesAndLabels.label;
    }

    
    }
  }
    , (error: HttpErrorResponse) => {
      // debugger
      if (error.status == 400){

        this.spinner.hide()
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        this.spinner.hide();
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })

  

    //  console.log(value);
     
    // if(value == "All"){
    //   this.apiService.getDashboardData().subscribe(post => {
    //     //console.log("post data", post);
    //     this.toolChartArray = post.All;
    //     this.getToolChartData();
    //     this.getToolChart();
    //   }, error => {
 
    //   })
    //  }
    // if(month=="March" && year== "2021"){
    //   // debugger
    //  this.apiService.getsample().subscribe(post => {
    //    debugger
    //    this.label=post.estimatedVdiVmCostForProject.label;
    //    this.vmCost=post.estimatedVdiVmCostForProject.vmCost;
    //    this.networkCost=post.estimatedVdiVmCostForProject.networkCost;
    //    this.diskCost=post.estimatedVdiVmCostForProject.diskCost;
    //    this.Total=post.estimatedVdiVmCostForProject.totalCost;
      // this.pieChartData2=post.data;
      // this.pieChartLabels2=post.label;
      //  debugger
      //  console.log("post data", post);
      //  this.toolChartArray = post.janToolData;
      //  this.getToolChartData();
      //  this.getToolChart();
    //  }, error => {

    //  })
    // }
    // else if(value == "Febraury-21"){
    //   this.apiService.getsample().subscribe(post => {

    //     this.pieChartData2=post.data2;
    //     this.pieChartLabels2=post.label2;
    //    // console.log("post data", post);
    //     // this.toolChartArray = post.febToolData;
    //     // this.getToolChartData();
    //     // this.getToolChart();
    //   }, error => {
 
    //   })
    // }
    // else if(value == "March-21"){
    //   console.log(this.selectedOption);

    //   this.apiService.getsample().subscribe(post => {
    //    // console.log("post data", post);
    //    this.pieChartData2=post.data3;
    //    this.pieChartLabels2=post.label3;
    //     // this.toolChartArray = post.marToolData;
        
    //     // this.getToolChartData();
    //     // this.getToolChart();
    //   }, error => {
 
    //   })
    // }
  }
 getAzureData(){
    this.spinner.show();
    this.AzureArray = [];
    this.apiService.getCostEstimateAzure().subscribe((res: any) => {
      //console.log(res);
      res.forEach(member => {
        //  
        let obj = {
          "BillingMonth": member.BillingMonth,
          "Cost": member.Cost,
          "Currency": member.Currency,
          "ResourceType": member.ResourceType

        }
        this.AzureArray.push(obj)

      })
      // this.dataSource.data = this.AzureArray as AzureDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

  }
  resetWindowSize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    //console.log(this.height,this.width);
    
  }

  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        member: memberArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  hideFlags(btn) {
    this.exportFlag=true
    if(btn == 'activeVDI') {
      // console.log("third");

      this.showToolBarChart = false;
      this.exportFlag=false;
      this.ShowToolTableView = true;
      this.showDetails = false;
      this.showTable = false;
    }
    else if(btn == 'search'){
      // console.log("fourth");

      this.showToolBarChart = false;
      this.exportFlag=false;

      this.ShowToolTableView = true;
      this.showDetails = false;
      this.showTable = false;
    }
    else if(btn == 'chart'){
      // console.log("fifth");

      this.showToolBarChart = false;
      this.exportFlag=true;

      this.ShowToolTableView = false;
      this.showDetails = false;
      this.showTable = true;
    }
    else if(btn == 'bar-chart'){
      // console.log("sixth");

      this.showToolBarChart = true;
      this.exportFlag=true;

      this.ShowToolTableView = false;
      this.showDetails = false;
      this.showTable = false;
    }
  }

  showVDIInfo(vdiReq) {
    // console.log(vdiReq);
    
    this.exportFlag=false

    
    this.chartData = [];
    this.requestID = vdiReq.reqId;
    // console.log("seventh");

   this.showToolBarChart = false;
   this.exportFlag=false;

   this.ShowToolTableView = false;
  
   this.showTable = false;
   let userPsNumber = "";
      this.apiService.psNumber.subscribe((psNumber: any) => {
        userPsNumber = psNumber
      })
    // this.chartData= [
    //   ["Date","Status","From","To"],
    //   ['12 May 2022', 'Removed', 0 ,0,0,0],
    //   ['12 May 2022', 'Stopped', 0,0, 9,0],
    //   ['12 May 2022', 'Running', 9,0,10,0],
    //     ['12 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['12 May 2022', 'Running', 14, 15, 15,0],
    //     ['12 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['12 May 2022', 'Running', 19, 0, 23, 0],
    //   ['13 May 2022', 'Stopped', 0,0, 24,0],
    //   ['14 May 2022', 'Removed', 0 ,0,0,0],
    //   ['14 May 2022', 'Stopped', 0,0, 9,0],
    //   ['14 May 2022', 'Running', 9,0,10,0],
    //     ['14 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['14 May 2022', 'Running', 14, 15, 15,0],
    //     ['14 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['14 May 2022', 'Running', 19, 0, 23, 0],
    //   ['15 May 2022', 'Removed', 0 ,0,0,0],
    //   ['15 May 2022', 'Stopped', 0,0, 9,0],
    //   ['15 May 2022', 'Running', 9,0,10,0],
    //     ['15 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['15 May 2022', 'Running', 14, 15, 15,0],
    //     ['15 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['15 May 2022', 'Running', 19, 0, 23, 0],
    //   ['16 May 2022', 'Removed', 0 ,0,0,0],
    //   ['16 May 2022', 'Stopped', 0,0, 9,0],
    //   ['16 May 2022', 'Running', 9,0,10,0],
    //     ['16 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['16 May 2022', 'Running', 14, 15, 15,0],
    //     ['16 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['16 May 2022', 'Running', 19, 0, 23, 0],
    //   ['17 May 2022', 'Removed', 0 ,0,0,0],
    //   ['17 May 2022', 'Stopped', 0,0, 9,0],
    //   ['17 May 2022', 'Running', 9,0,10,0],
    //     ['17 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['17 May 2022', 'Running', 14, 15, 15,0],
    //     ['17 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['17 May 2022', 'Running', 19, 0, 23, 0],
    //   ['18 May 2022', 'Removed', 0 ,0,0,0],
    //   ['18 May 2022', 'Stopped', 0,0, 9,0],
    //   ['18 May 2022', 'Running', 9,0,10,0],
    //     ['18 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['18 May 2022', 'Running', 14, 15, 15,0],
    //     ['18 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['18 May 2022', 'Running', 19, 0, 23, 0],
    //   ['19 May 2022', 'Removed', 0 ,0,0,0],
    //   ['19 May 2022', 'Stopped', 0,0, 9,0],
    //   ['19 May 2022', 'Running', 9,0,10,0],
    //     ['19 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['19 May 2022', 'Running', 14, 15, 15,0],
    //     ['19 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['19 May 2022', 'Running', 19, 0, 23, 0],
    //   ['20 May 2022', 'Removed', 0 ,0,0,0],
    //   ['20 May 2022', 'Stopped', 0,0, 9,0],
    //   ['20 May 2022', 'Running', 9,0,10,0],
    //     ['20 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['20 May 2022', 'Running', 14, 15, 15,0],
    //     ['20 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['20 May 2022', 'Running', 19, 0, 23, 0],
    //   ['21 May 2022', 'Removed', 0 ,0,0,0],
    //   ['21 May 2022', 'Stopped', 0,0, 9,0],
    //   ['21 May 2022', 'Running', 9,0,10,0],
    //     ['21 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['21 May 2022', 'Running', 14, 15, 15,0],
    //     ['21 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['21 May 2022', 'Running', 19, 0, 23, 0],
    //   ['22 May 2022', 'Removed', 0 ,0,0,0],
    //   ['22 May 2022', 'Stopped', 0,0, 9,0],
    //   ['22 May 2022', 'Running', 9,0,10,0],
    //     ['22 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['22 May 2022', 'Running', 14, 15, 15,0],
    //     ['22 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['22 May 2022', 'Running', 19, 0, 24, 0],
    //   ['23 May 2022', 'Removed', 0 ,0,0,0],
    //   ['23 May 2022', 'Stopped', 0,0, 9,0],
    //   ['23 May 2022', 'Running', 9,0,10,0],
    //     ['23 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['23 May 2022', 'Running', 14, 15, 15,0],
    //     ['23 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['23 May 2022', 'Running', 19, 0, 23, 0],
    //   ['24 May 2022', 'Removed', 0 ,0,0,0],
    //   ['24 May 2022', 'Stopped', 0,0, 9,0],
    //   ['24 May 2022', 'Running', 9,0,10,0],
    //     ['24 May 2022', 'Logged In', 10, 30, 14, 0],
    //     ['24 May 2022', 'Running', 14, 15, 15,0],
    //     ['24 May 2022', 'Logged In', 15, 30, 19, 0],
    //     ['24 May 2022', 'Running', 19, 0, 23, 0],
    //     ['24 May 2022', 'Stopped', 23, 0, 24, 0],
    //     ['25 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['25 May 2022', 'Running', 9, 0, 10,0],
    //     ['25 May 2022', 'Logged In', 10, 15,14,0],
    //     ['25 May 2022', 'Running', 14,0, 15,30],
    //     ['25 May 2022', 'Logged In', 15,45, 19,0],
    //     ['25 May 2022', 'Running', 19,0, 23,0],
    //     ['25 May 2022', 'Stopped', 23,12, 24,0],
    //     ['26 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['26 May 2022', 'Running', 9, 0, 10,0],
    //     ['26 May 2022', 'Logged In', 10, 15,14,0],
    //     ['26 May 2022', 'Running', 14,0, 15,30],
    //     ['26 May 2022', 'Logged In', 15,45, 19,0],
    //     ['26 May 2022', 'Running', 19,0, 23,0],
    //     ['26 May 2022', 'Stopped', 23,12, 24,0],
    //     ['27 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['27 May 2022', 'Running', 9, 0, 10,0],
    //     ['27 May 2022', 'Logged In', 10, 15,14,0],
    //     ['27 May 2022', 'Running', 14,0, 15,30],
    //     ['27 May 2022', 'Logged In', 15,45, 19,0],
    //     ['27 May 2022', 'Running', 19,0, 23,0],
    //     ['27 May 2022', 'Stopped', 23,12, 24,0],
    //     ['28 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['28 May 2022', 'Running', 9, 0, 10,0],
    //     ['28 May 2022', 'Logged In', 10, 15,14,0],
    //     ['28 May 2022', 'Running', 14,0, 15,30],
    //     ['28 May 2022', 'Logged In', 15,45, 19,0],
    //     ['28 May 2022', 'Running', 19,0, 23,0],
    //     ['28 May 2022', 'Stopped', 23,12, 24,0],
    //     ['29 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['29 May 2022', 'Running', 9, 0, 10,0],
    //     ['29 May 2022', 'Logged In', 10, 15,14,0],
    //     ['29 May 2022', 'Running', 14,0, 15,30],
    //     ['29 May 2022', 'Logged In', 15,45, 19,0],
    //     ['29 May 2022', 'Running', 19,0, 23,0],
    //     ['29 May 2022', 'Stopped', 23,12, 24,0],
    //     ['30 May 2022', 'Stopped', 0, 0, 9,0],
    //     ['30 May 2022', 'Running', 9, 0, 10,0],
    //     ['30 May 2022', 'Logged In', 10, 15,14,0],
    //     ['30 May 2022', 'Running', 14,0, 15,30],
    //     ['30 May 2022', 'Logged In', 15,45, 19,0],
    //     ['30 May 2022', 'Running', 19,0, 23,0],
    //     ['30 May 2022', 'Stopped', 23,12, 24,0],
    // ]
    this.spinner.show();
    this.apiService.getChartData(this.projectId, vdiReq.vdi, vdiReq.reqId, userPsNumber).subscribe((res: any) => {
     this.chartData = res
     if(this.chartData.length > 0){
       this.exportFlag=true

       let finalChart = []
       let tempchart = []
         for(let i = 0; i<this.chartData.length; i++){
           tempchart = []
           for(let j = 0; j<this.chartData[i].length; j++){
             if(i == 0){
              tempchart.push(this.chartData[0][j])
             }
             else{
                if(j == 2 || j == 4){
                  if(this.chartData[i][2] == 0 && this.chartData[i][4] == 24 && j == 4){
                    tempchart.push(new Date(0,0,0,23,59,59))
                  }
                  else{
                    tempchart.push(new Date(0,0,0,this.chartData[i][j],this.chartData[i][j+1],0))
                  }
                }
                else if(j == 0 || j == 1){
                tempchart.push(this.chartData[i][j])
              } 
             }
                     
           }
           finalChart.push(tempchart)
         }
        //  console.log("--",finalChart);
         
         this.timelineChartData =  {
           chartType: 'Timeline',
           dataTable: finalChart,
           options: {

            colors: ['#4285f4','#db4437',"#00A300"]


            }
           }
           this.spinner.hide();
         this.showDetails = true;
         
        }
        else {
          // debugger
          this.exportFlag=true

          this.spinner.hide();
          // this.Message=this.storyBoard.privilege+" is already added to Persona Name "+ this.temprole_name+". Do you want to override the existing role ?"
          let element : HTMLElement = document.getElementById('CallModel1') as HTMLElement;
          element.click();
          return
          // this.noData = "No data available";
        }
      })
  }

  showAzureCostTable() {
    // console.log("eigth");

    this.showToolBarChart = false;
    this.exportFlag=false;

    this.ShowToolTableView = true;
    this.showDetails = false;
    this.showTable = false;
  }

  showVdiDetails(){
    // console.log("ninth");

    this.exportFlag=true
    this.showToolBarChart = false;
    this.exportFlag=true;

    this.ShowToolTableView = false;
    //this.showDetails = true;
   
   this.dtOptions = {
    order: []
  };
  this.spinner.show();
  this.tableRepoArray = []
  this.personalVdiTableData = []
  this.pooledVdiTableData = []
  this.apiService.getActiveVDI(this.projectId,this.monthNumber,this.year).subscribe((data: any) => {
    // console.log(data);
    
    let tool_id = "";

    // data.forEach(vdi=>{
    var toolInstance = "";

    if(data.pooled.length==0){
      this.spinner.hide();
    }
    data.pooled.forEach((request: any) => {
      toolInstance = request.tool_instance

      tool_id = request.vdi_tool_id
      //  request.vdi_user.forEach(user=>{
      let requestStatus = (request.vdi_user.approval).toLowerCase();

     
      let tableObj;

      this.memberString = []
      if (request.vdi_user.approval == "Failed" || "Pending" || "Processing") {
        request.vdi_user.user_mail.forEach(element => {
          let userMail = element;
          for (let i = 0; i < request.vdi_user.user_name.length; i++) {
            let userName = request.vdi_user.user_name[0];
            request.vdi_user.user_name.splice(i, 1);
            let membObj = {
              name: userName,
              email: userMail,
              role: ""
            }
            this.memberString.push(membObj)
            break;
          };
        });
        request.vdi_user.user_status?.forEach((element) => { 
          this.memberString.forEach((el,i) => {
            if(element.user_status == "Offboarded" && element.user_mail == el.email) {
              this.memberString.splice(i,1);
            }
          });        
        });
      }
      else {
        let membObj = {
          name: request.vdi_user.user_name,
          email: request.vdi_user.user_mail,
          role: ""
        }
        this.memberString.push(membObj)
      }

      //   setTimeout(() => {
      tableObj =
      {
        "reqId": request.request_id,
        "vdi": request.vdi_type,
        "toolStack": tool_id,
        "status": request.vdi_user.approval.charAt(0).toUpperCase() + request.vdi_user.approval.slice(1),
        "members": this.memberString,
        "action": "Update",
        "name_prefix": request.vdi_user.name_prefix,
        "hostpool_name": request.vdi_user.hostpool_name,
        "application_group": request.vdi_user.application_group,
        "workspace_name": request.vdi_user.workspace_name

      }
      //console.log(tableObj)
        this.showTable = true
      this.tableRepoArray.push(tableObj)
      this.pooledVdiTableData = this.tableRepoArray;
      //console.log(this.vdiTableData);
      
      this.dtTrigger.next();
      this.spinner.hide();

    })
    this.tableRepoArray = []
      if(data.personal.length==0){
        this.spinner.hide();
      }
      data.personal.forEach((request: any) => {
        toolInstance = request.tool_instance
  
        tool_id = request.vdi_tool_id
        let requestStatus = (request.vdi_user.approval).toLowerCase();
  
       
        let tableObj;
  
        this.memberString = []
        if (request.vdi_user.approval == "Failed" || "Pending" || "Processing") {
          request.vdi_user.user_mail.forEach(element => {
            let userMail = element;
            for (let i = 0; i < request.vdi_user.user_name.length; i++) {
              let userName = request.vdi_user.user_name[0];
              request.vdi_user.user_name.splice(i, 1);
              let membObj = {
                name: userName,
                email: userMail,
                role: ""
              }
              this.memberString.push(membObj)
              break;
            };
          });
          request.vdi_user.user_status?.forEach((element) => { 
            this.memberString.forEach((el,i) => {
              if(element.user_status == "Offboarded" && element.user_mail == el.email) {
                this.memberString.splice(i,1);
              }
            });        
          });
        }
        else {
          let membObj = {
            name: request.vdi_user.user_name,
            email: request.vdi_user.user_mail,
            role: ""
          }
          this.memberString.push(membObj)
        }
        // console.log(request);
        
        //   setTimeout(() => {
        tableObj =
        {
          "reqId": request.request_id,
          "vdi": request.vdi_type,
          "toolStack": tool_id,
          "status": request.vdi_user.approval.charAt(0).toUpperCase() + request.vdi_user.approval.slice(1),
          "members": this.memberString,
          "action": "Update",
          "name_prefix": request.vdi_user.name_prefix,
          "hostpool_name": request.vdi_user.hostpool_name,
          "application_group": request.vdi_user.application_group,
          "workspace_name": request.vdi_user.workspace_name
  
        }
        //console.log(tableObj)
          this.showTable = true
        this.tableRepoArray.push(tableObj)
        this.personalVdiTableData = this.tableRepoArray;
        
        this.dtTrigger.next();
        this.spinner.hide();
  

    })



  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403){}
    else {
      this.alertService.error("No data available.", this.options);
      throw error;
    }

  })
  }

 
  downloadJiraData(type) {
    if (type == 'excel') {
      this.spinner.show();
      this.apiService.getCostEstimateJiraExcel(this.projectId).subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'Jira Cost Report.xlsx';
        link.click();
        this.spinner.hide();
      })
    }
    else if (type == 'csv') {
      this.spinner.show();
      this.apiService.getCostEstimateJiraCsv(this.projectId).subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = 'Jira Cost Report.csv';
        link.click();
        this.spinner.hide();
      })
    }
    else if (type == 'pdf') {
      this.spinner.show();
      this.apiService.getCostEstimateJiraPdf(this.projectId).subscribe(data => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'Jira Cost Report.pdf'
        link.click();
        this.spinner.hide();
      })
    }

  }

  openAddedUserDialog(memberArray) {
     if(this.selectedOption2=="January"){
        this.monthNumber='01';
      }
      else if(this.selectedOption2=="February"){
        this.monthNumber="02"
      }
      else if(this.selectedOption2=="March"){
        this.monthNumber="03"
      }
      else if(this.selectedOption2=="April"){
        this.monthNumber="04"
      }
      else if(this.selectedOption2=="May"){
        this.monthNumber="05"
      }
      else if(this.selectedOption2=="June"){
        this.monthNumber="06"
      }
      else if(this.selectedOption2=="July"){
        this.monthNumber="07"
      }
      else if(this.selectedOption2=="August"){
        this.monthNumber="08"
      }
      else if(this.selectedOption2=="September"){
        this.monthNumber="09"
      }
      else if(this.selectedOption2=="October"){
        this.monthNumber="10"
      }
      else if(this.selectedOption2=="November"){
        this.monthNumber="11"
      }
      else if(this.selectedOption2=="December"){
        this.monthNumber="12"
      }
    const dialogRef = this.dialog.open(Report_MembersListDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data: {
        member: memberArray,
        month: this.monthNumber,
        year:this.selectedOption

      }
    });


  }
  summaryViewData(){
    this.spinner.show();
      this.apiService.getSummaryDetailsData(this.projectId).subscribe(data => {
       // console.log("count data", data);
        this.summaryData = data;
        //console.log(this.summaryData)
        this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 447){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
   }
   downloadAzureData(type,month,year){
    this.spinner.show()
   this.projectId=localStorage.getItem('projectId');
 
    //console.log(this.projectId);
    
   if(month=="January"){
     this.monthNumber='01';
   }
   else if(month=="February"){
     this.monthNumber="02"
   }
   else if(month=="March"){
     this.monthNumber="03"
   }
   else if(month=="April"){
     this.monthNumber="04"
   }
   else if(month=="May"){
     this.monthNumber="05"
   }
   else if(month=="June"){
     this.monthNumber="06"
   }
   else if(month=="July"){
     this.monthNumber="07"
   }
   else if(month=="August"){
     this.monthNumber="08"
   }
   else if(month=="September"){
     this.monthNumber="09"
   }
   else if(month=="October"){
     this.monthNumber="10"
   }
   else if(month=="November"){
     this.monthNumber="11"
   }
   else if(month=="December"){
     this.monthNumber="12"
   }
   if(type=='excel'){
     this.apiService.getCostEstimateAzureExcel(this.projectId,this.monthNumber,year).subscribe(data=>{
       const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       const url= window.URL.createObjectURL(blob);
       var link = document.createElement('a');
       link.href = url;
       link.download = 'Azure.xlsx';
       link.click();  
       this.spinner.hide()      
     }
     ,
     (error: HttpErrorResponse) => {
       //
 
       if (error.status == 450) //doesnt work
       {
            this.spinner.hide()
         this.alertService.error("No data available", this.options);
 
       }
       else {
         this.spinner.hide()
 
         this.alertService.error("No data available", this.options);
         throw error;
       }
     })
   }
   else if(type=='csv'){
     this.apiService.getCostEstimateAzureCsv(this.projectId,this.monthNumber,year).subscribe(data=>{
       const blob = new Blob([data], { type: 'text/csv' });
       const url= window.URL.createObjectURL(blob);
       var link = document.createElement('a');
       link.href = url;
       link.download = 'Azure.csv';
       link.click();
       this.spinner.hide()
         
     }
     ,
     (error: HttpErrorResponse) => {
       //
 
       if (error.status == 450) //doesnt work
       {
            this.spinner.hide()
         this.alertService.error("No data available", this.options);
 
       }
       else {
         this.spinner.hide()
 
         this.alertService.error("No data available", this.options);
         throw error;
       }
     })
   }
   else if(type=='pdf'){
     this.apiService.getCostEstimateAzurePdf(this.projectId,this.monthNumber,year).subscribe(data=>{
       var downloadURL = window.URL.createObjectURL(data);
       var link = document.createElement('a');
       link.href = downloadURL;
       link.download = 'Azure.pdf'
       link.click();
       this.spinner.hide()
     },
     (error: HttpErrorResponse) => {
       //
 
       if (error.status == 450) //doesnt work
       {
            this.spinner.hide()
         this.alertService.error("No data available", this.options);
 
       }
       else {
         this.spinner.hide()
 
         this.alertService.error("No data available", this.options);
         throw error;
       }
     }
     )
   }
   
   
 
     }
     
  detailViewData() {
    this.spinner.show();
    // this.userEmail = currentUserEmail;
    //VDI and VM data fetchning
    this.apiService.getVdiDetailsData(this.projectId).subscribe(data => {
      //console.log("details data", data);
      this.detailsData = data;
      this.dtTrigger.next();
      //this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 447) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    });

    this.apiService.getVmDetailsData(this.projectId).subscribe(data => {
      //console.log("details data", data);
      this.VmDetailsData = data;
      this.dtTrigger.next();
      //this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 447) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    });
    this.apiService.getSCMDetailsData(this.projectId).subscribe(data => {
      // console.log("SCM details data", data);
      this.scmDetailsData = data;
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 447) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }
    });
   
  

 
    
    this.apiService.getJiraDetailsData(this.projectId).subscribe((data: any) => {
      // console.log("Jira details data", data);
      this.jiraDetailsData = data;
      for (var i = 0; i < data.length; i++) {
        this.jiraMembers = data[i].project_members;
        //  console.log("this.jiraMembers", this.jiraMembers);
      }

      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 447) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    });


    this.apiService.getJenkinsDetailsData(this.projectId).subscribe((data: any) => {
      // console.log("Jira details data", data);
      this.jenkinsDetailsData = data;

      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 447) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    });

  }

  showProjectInfo(dataArray, screen) {
    const dialogRef = this.dialog.open(DetailViewDialog, {
      width: '70%',
      height: '60%',
      disableClose: true,
      data: {
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showProjectInfoModal() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showJiraInfo(dataArray, screen) {
    const dialogRef = this.dialog.open(jiraDetailViewDialog, {
      width: '50%',
      height: '66%',
      disableClose: true,
      data: {
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showGitLabInfo(dataArray, screen) {
    const dialogRef = this.dialog.open(gitlabDetailViewDialog, {
      width: '70%',
      height: '60%',
      disableClose: true,
      data: {
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showJenkinsInfo(dataArray,screen) {
    const dialogRef = this.dialog.open(jenkinsViewDialog, {
      width: '50%',
      height: '66%',
      disableClose: true,
      data:{
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

@Component({
  selector: 'members-list',
  templateUrl: 'members-info.html',
  styleUrls: ['./report-cost.component.scss']
})

export class Report_MembersListDialog {
  NewmemberList: any = [];
  reqStatus: any;
  request: any;
  projectId: string;
  screen: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  month: any;
  year: any;

  constructor(
    public dialogRef: MatDialogRef<Report_MembersListDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: AuthService, public alertService: AlertService, public dialog: MatDialog) {


    this.NewmemberList = data.member
    this.month=data.month
    this.year=data.year
    //console.log(this.NewmemberList, "this.memberList")

  }

  showMemberInfo(user_name) {
    const dialog = this.dialog.open(MembersJiraCostInfoDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data: {
        member: user_name,
        month:this.month,
        year:this.year,
      }
    });

    dialog.afterClosed().subscribe(result => {
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

export interface MemberJiraDetails {
  jiraProjectKey: string;
  jiraProjectName: string;
}
const ELEMENT_DATA: MemberJiraDetails[] = [];
@Component({
  selector: 'member-jira-info',
  templateUrl: 'member-jira-info.html',
  styleUrls: ['./report-cost.component.scss']
})

export class MembersJiraCostInfoDialog {

  projectId: string;
  memberName: string;
  ELEMENT_DATA: MemberJiraDetails[];
  displayedColumns: string[] = ['jiraProjectKey ', 'jiraProjectName'];
  dataSource = new MatTableDataSource<MemberJiraDetails>(ELEMENT_DATA);
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  month: any;
  year: any;

  constructor(
    public dialogRef: MatDialogRef<Report_MembersListDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: AuthService, public alertService: AlertService) {


    this.memberName = data.member;
    this.month=data.month;
    this.year=data.year

  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  ngOnInit() {
    this.projectId = localStorage.getItem('projectId');
    this.apiService.getJiraCostInfoPerMember(this.projectId,this.memberName,this.month,this.year).subscribe((data: any) => {
      this.dataSource.data = data as MemberJiraDetails[];
    })
  }
  closeDialog(): void {
    this.dialogRef.close();
  }

}







