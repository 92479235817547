import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogProjectInfoDialog, DialogConfirmationPopup, addMemberVdiDialog } from '../vdi-details/vdi-details.component';
import { AuthService } from 'src/app/service/api.service';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { Inject, ViewChild } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-aws-vdi',
  templateUrl: './aws-vdi.component.html',
  styleUrls: ['./aws-vdi.component.scss']
})
export class AwsVdiComponent implements OnInit {
  secondFormGroup: FormGroup;
  compute_type_nameVal = [];
  running_modeVal = [];
  root_volume_size_gbVal = [];
  user_volume_size_gbVal = [];
  bundle_idVal = ["wsb-bck6dktxg"]
  directory_idVal = ["d-96671ddfca"]
  showRepo: boolean = false;
  projectId: any;
  projectName: any;
  catalog_id: any;
  catalog_name: any;
  template_id: any;
  template_name: any;
  filterStatus: any = "Active";

  showUserRow: boolean = false;
  selectedUserArray: any = []
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  tableRepoArray: any = [];
  vdiTableData: any = [];
  tableArr: any = [];
  awsData: any;
  showAwsTable: boolean = false;
  showForm: boolean = false;
  Role: string = "";
  resourceGrouparn: any;
  ImageData: any;
  NetworkData: any;
  keyPairData:any;
  Purpose: any;
  PurposeFinal: any;
  Ec2TypeValues: any;
  Ec2DiskTypeValues: any;
  imageName: any;
  networkName: any;
  purposeName: any;
  ec2DisplayName: any;
  ec2PropertyName: any;
  diskDisplayName: any;
  diskPropertyName: any;
  memberArray: any = [];
  toolgrp: any;
  awsConfig: any;
  getTableData: any = [];
  tableArrTable: [];
  refreshDisable: boolean;
  ImageinfoMap = {}
  NetworkinfoMap = {}
  Ec2TypeinfoMap = {}
  Ec2DiskTypeinfoMap = {}
  Ec2PurposeinfoMap = {}

  isUserTM: string;
  osVal=""
  keyPair: any;
  keyPairAwsId: any;
  masterSelected: boolean;
  project_selectedcheckbox: boolean;
  checkedList: any[];
  checklist: any;
  VdiConfig_details: any[];
  manageDisable: boolean=true;
  itemSelected: boolean;
  checkDisable = true;
  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService, private _formBuilder: FormBuilder, public router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    // console.log(this.getTableData.length);
    // console.log(this.selectedUserArray);
    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.secondFormGroup = this._formBuilder.group({
        bundle_id: [null, Validators.required],
        directory_id: [null, Validators.required],
        // key_pair: [null],
        key_pair: [null],

        compute_type_name: [null, Validators.required],
        root_volume_size_gb: [null, Validators.required],
        password: ['', [ Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W_]).{12,}$'), Validators.minLength(12)]],

        running_mode: [null, Validators.required],
        // user_volume_size_gb: [null, Validators.required]
      });
      this.apiService.componentName.next("AWS");

      this.projectId = localStorage.getItem('projectId');



      this.projectName = localStorage.getItem('projectName');

      this.selectedUserArray = [];

      this.catalog_id = localStorage.getItem('catalogId');
      this.isUserTM = localStorage.getItem('isUserTM');


      this.catalog_name = localStorage.getItem('catalogName');
      this.apiService.getProjectById(this.projectId).subscribe((data: any[]) => {
        // debugger

        this.template_id = data[0].template_id;
        this.template_name = data[0].template_name;

      },
        (error: HttpErrorResponse) => {
          if (error.status == 440) {
            this.alertService.error(error.error.message, this.options);
          }
          else {
            this.alertService.error("Unable to start.", this.options);
            throw error;
          }

        })
      this.apiService.getAwsEc2Status(this.projectId).subscribe((data: any[]) => {
        // debugger

        // console.log(data);

      },
        (error: HttpErrorResponse) => {
          if (error.status == 440) {
            this.alertService.error(error.error.message, this.options);
          }
          else {
            this.alertService.error("Unable to start.", this.options);
            throw error;
          }

        })
      this.apiService.getAllAwsEc2StatusFilter(this.projectId, this.filterStatus).subscribe((data: any[]) => {
        // debugger
        // console.log(data);

        this.getTableData = data

        for (let i = 0; i < this.getTableData.length; i++) {

          let tableObj =
          {
            "request_id": this.getTableData[i].request_id,
            "os": this.getTableData[i].ec2.os,
            "type": this.getTableData[i].ec2.ec2_type,
            "name": this.getTableData[i].ec2.name,
            "ip_address": this.getTableData[i].ec2.ip_address,
            "members": this.getTableData[i].ec2.approval,
            "approval": this.getTableData[i].ec2.approval,

          }

          // this.tableArrTable.push(tableObj)
        }
        // console.log(this.getTableData.length);


      },
        (error: HttpErrorResponse) => {
          if (error.status == 440) {
            this.alertService.error(error.error.message, this.options);
          }
          else {
            this.alertService.error("Unable to start.", this.options);
            throw error;
          }

        })
      let newCatalog_id = localStorage.getItem('catalogId');
      this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
        // if (data.deploymentstore_tools.length == 0) {
        //   this.deploymentFlag = true;
        // }
      },
        (error: HttpErrorResponse) => {
          if (error.status == 440) {
            this.alertService.error(error.error.message, this.options);
          }
          else {
            this.alertService.error("Unable to start.", this.options);
            throw error;
          }

        })
        
        setTimeout(() => {
          
          this.getAWSVdi()
           // console.log("hi");
           
         }, 2000)



    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }
  checkUncheckAll() {
    // console.log(this.VdiConfig_details);
    // console.log(this.checklist);
    

    
    
    for (var i = 0; i < this.checklist.length; i++) {
      if(
        this.checklist[i].ec2.approval=='Stopped' || this.checklist[i].ec2.approval=='Running'
      ){
        this.checklist[i].isSelected = this.masterSelected;

      }
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    // debugger
    this.masterSelected = this.checklist.every(function (item: any) {
      if(item.isSelected){
        // console.log(item);
        
      }
      
      return item.isSelected == true;
    })
    // console.log(this.masterSelected);
    
    

    this.getCheckedItemList();
  }
  getCheckedItemList() {
    // debugger
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    // console.log(this.checkedList);
    //console.log(this.checklist);
    
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push([this.checklist[i].aws_config_tool_id,this.checklist[i].ec2.ec2_id]);

    }
    // console.log(this.checkedList);
    
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index][0];
                  var SelectedItemStatus=this.checkedList[index][1];

                 let VdiConfigBoby={
                    "request_id":SelectedItem,
                    "status":SelectedItemStatus,

                    }
                   this.VdiConfig_details.push(VdiConfigBoby);


                  
                 // For Loop of tag

                // Add image des 
                               
             }
            //  console.log(this.VdiConfig_details);
             if(this.VdiConfig_details.length==0 ){
               this.manageDisable=true
               
             }
             else if(this.VdiConfig_details.length!=0 ){
              this.manageDisable=false
              
            }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  passValid(){
    if(this.secondFormGroup.controls.password.untouched || this.secondFormGroup.controls.password.hasError('minlength') || this.secondFormGroup.controls.password.hasError('pattern')){
      return true
    }
    return false

  }
  getPasswordErrorMessage() {
    // debugger
    // if (this.secondFormGroup.controls.password.touched && this.secondFormGroup.controls.password.value==""){
    //   return "Password is required"
    // }

    return this.secondFormGroup.controls.password.hasError('minlength') ? 'Password must be 12 characters long' : this.secondFormGroup.controls.password.hasError('pattern') ?
      'Password must include alteast one uppercase, one lowercase letter, one number and one special character' :
      '';
  }
  //dropdown functions
  onChangeImageName($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];
    // console.log();

    this.imageName = $event.target.options[$event.target.options.selectedIndex].value;
    this.osVal=this.ImageinfoMap[this.imageName].ec2_os
    this.secondFormGroup.get('password').reset(); 
    this.secondFormGroup.get('key_pair').reset(); 

    // console.log(this.ImageinfoMap[this.imageName]);






  }
  onChangeNetworkName($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.networkName = $event.target.options[$event.target.options.selectedIndex].value;
    // console.log(this.NetworkinfoMap[this.networkName]);
    let body={

      "aws_config_tool_id": this.keyPairAwsId,
      "group": { 
        "arn": this.NetworkinfoMap[this.networkName].resource_group.arn,
        "name": this.NetworkinfoMap[this.networkName].resource_group.name,
    }
  }
  this.apiService.getkeyPairDataWorkspace(body).subscribe((result: any) => {
    // debugger
    this.keyPairData=result
// console.log(this.keyPairData);




  },
  (error: HttpErrorResponse) => {
    if (error.status == 440) {
      this.alertService.error(error.error.message, this.options);
    }
    else {
      this.alertService.error("Unable to start.", this.options);
      throw error;
    }

  });
 
    
    // console.log(this.networkName);




  }
  onChangeKeyPairName($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.keyPair = $event.target.options[$event.target.options.selectedIndex].value;
    // console.log(this.networkName);




  }
  getKeypairs(){
    this.apiService.getkeyPairWorkspace(this.toolgrp).subscribe((result: any) => {
      // debugger
      this.keyPairAwsId=result[0].tool_id
  // console.log(this.keyPairAwsId);
  
  
  
  
    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }
  
    });
   
  }
  onChangePurposeName($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.purposeName = $event.target.options[$event.target.options.selectedIndex].value;
    this.ec2info(this.purposeName)
    // console.log(this.purposeName);





  }
  onChangeEc2Name($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.ec2DisplayName = $event.target.options[$event.target.options.selectedIndex].text;
    this.ec2PropertyName = $event.target.options[$event.target.options.selectedIndex].value;
    // console.log(this.ec2DisplayName, this.ec2PropertyName);



  }


  onChangeDiskTypeName($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.diskDisplayName = $event.target.options[$event.target.options.selectedIndex].text;
    this.diskPropertyName = $event.target.options[$event.target.options.selectedIndex].value;


    // console.log(this.diskDisplayName);
    // console.log(this.diskPropertyName);




  }
  //
  get compute_type_name() {
    return this.secondFormGroup.get('compute_type_name');
  }
  get root_volume_size_gb() {
    return this.secondFormGroup.get('root_volume_size_gb');
  }
  get running_mode() {
    return this.secondFormGroup.get('running_mode');
  }
  get user_volume_size_gb() {
    return this.secondFormGroup.get('user_volume_size_gb');
  }
  get bundle_id() {
    return this.secondFormGroup.get('bundle_id');
  }
  get directory_id() {
    return this.secondFormGroup.get('directory_id');
  }
  get key_pair() {
    return this.secondFormGroup.get('key_pair');
  }
  showPopupInfo(type) {
    // console.log(this.imageName);
    // debugger

    if (type == "Image" && this.imageName != undefined) {
      const dialogRef = this.dialog.open(ImageInfoPop, {
        width: '50%',
        height: '40%',
        disableClose: true,
        data: {
          ImageInfo: this.ImageinfoMap[this.imageName],
          type: type
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

     if (type == "Network" && this.networkName != undefined) {
      const dialogRef = this.dialog.open(DialogNetworkInfo, {
        width: '55%',
        height: '40%',
        disableClose: true,
        data: {
          networkInfo: this.NetworkinfoMap[this.networkName],
          type: type

        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if (type == "EC2_Type" && this.ec2PropertyName != undefined) {
      const dialogRef = this.dialog.open(DialogEc2TypeInfo, {
        width: '40%',
        height: '18%',
        disableClose: true,
        data: {
          Ec2Info: this.Ec2TypeinfoMap[this.ec2PropertyName],
          type: type

        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    else if (type == "EC2_DiskType" && this.diskPropertyName != undefined) {
      const dialogRef = this.dialog.open(DialogEc2DiskTypeInfo, {
        width: '40%',
        height: '18%',
        disableClose: true,
        data: {
          diskInfo: this.Ec2DiskTypeinfoMap[this.diskPropertyName],
          type: type

        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if (type == "Purpose" && this.purposeName != undefined) {
      const dialogRef = this.dialog.open(DialogPurposeInfo, {
        width: '50%',
        height: '37%',
        disableClose: true,
        data: {
          purposeInfo: this.Ec2PurposeinfoMap[this.purposeName],
          type: type

        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }







  }
  AddAwsVdi() {
    
    this.showRepo = true;
    // this.getKeypairs()
    this.selectedUserArray = []
    this.apiService.selectedVDIMembersEC2.next([])

    this.apiService.getCatalogById(this.catalog_id, 'AWS-EC2-Image').subscribe((result: any) => {

      this.ImageData = result.Tool_list

      // console.log(this.ImageData);
      for (let i = 0; i < this.ImageData.length; i++) {
        let key = this.ImageData[i].tool_id
        let val = this.ImageData[i].tool_instance_details.aws_ec2_image_tool_instance
        this.ImageinfoMap[key] = val
      }
      // console.log(this.ImageinfoMap);





    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });

    this.apiService.getCatalogById(this.catalog_id, 'AWS-Network-Config').subscribe((result: any) => {
      this.NetworkData = result.Tool_list

      for (let i = 0; i < this.NetworkData.length; i++) {
        let key = this.NetworkData[i].tool_id
        let val = this.NetworkData[i].tool_instance_details.aws_network_config_tool_instance
        this.NetworkinfoMap[key] = val
      }
      // console.log(this.NetworkinfoMap);

      this.toolgrp = result.Tool_list[0].tool_group_details.tool_group_id
      this.getKeypairs()
      // console.log(this.toolgrp);


    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });


    // debugger
    this.apiService.GetAllAWSComputePurposes().subscribe((result: any) => {
      // console.log(result);
      
      this.Purpose = result
      for (let i = 0; i < this.Purpose.length; i++) {
        let key = this.Purpose[i].purpose_name
        let val = this.Purpose[i].purpose_description
        this.Ec2PurposeinfoMap[key] = val
      }
      // console.log(this.Ec2PurposeinfoMap);
      


    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });





  }
  ec2info(purpose) {
    this.apiService.GetDropdownValueEC2(purpose).subscribe((result: any) => {
      this.Ec2TypeValues = result.instanceTypes,
        this.Ec2DiskTypeValues = result.EBSVolumeTypes
      for (let i = 0; i < this.Ec2TypeValues.length; i++) {
        let key = this.Ec2TypeValues[i].property_value
        let val = this.Ec2TypeValues[i].description
        this.Ec2TypeinfoMap[key] = val
      }

      for (let i = 0; i < this.Ec2DiskTypeValues.length; i++) {
        let key = this.Ec2DiskTypeValues[i].property_value
        let val = this.Ec2DiskTypeValues[i].description
        this.Ec2DiskTypeinfoMap[key] = val
      }


    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });
  }
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '30%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.createAwsFirst();
        }
      }
    );
  }
  refresh(){
    setTimeout(() => {
     this.getAWSVdi()
      // console.log("hi");
      
    }, 3000)

  }
  addMemberToRepo(data, type) {
    // console.log(data);

    const scmUser = this.dialog.open(addMemberAWSDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        values: data,
        screen: type
      }
    });


    scmUser.afterClosed().subscribe(result => {
      // this.getAWSVdi()  
      this.refresh()    
    })
    
  }

  toggleDisplay(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.cancelconfirmationPopUp();

      // this.showRepo = false;
      // this.selectedUserArray = [];

    } else {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];


    }
  }
  
     // confirmation popup
     cancelconfirmationPopUp() {
      const dialogRef = this.dialog.open(ec2CancelConfirmationPopUpDialog, {
        width: '30%',
        height: '33%',
        disableClose: true,
       
      });
      dialogRef.afterClosed().subscribe(
        
        
        data => {
          // debugger
          if (data == 'Yes') {
            // console.log("hello");
            this.showRepo = false;
            this.showUserRow = false;
            this.selectedUserArray = [];
            this.secondFormGroup.reset();
            
   
            
          }
    
          
        }
      );
    }

  openUser() {
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
      // console.log(this.selectedUserArray);
      
      this.apiService.selectedVDIMembersEC2.next(this.selectedUserArray);

    })
  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)

  }
  createAwsFirst() {
    this.apiService.Admin_GetAWSConfig(this.toolgrp).subscribe((result: any) => {
      // debugger

      this.awsConfig = result[0].tool_id
      this.createAWSVdi()



    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });
    // debugger
    // console.log(this.awsConfig);
  }
  getStatus(status) {
    // console.log(status);
    this.spinner.show()
    this.filterStatus = status;
    this.apiService.getAllAwsEc2StatusFilter(this.projectId, this.filterStatus).subscribe((data: any[]) => {
      // debugger
      // console.log(data);

      this.getTableData = data
      this.checklist = this.getTableData;

      this.spinner.hide()

      for (let i = 0; i < this.getTableData.length; i++) {

        let tableObj =
        {
          "request_id": this.getTableData[i].request_id,
          "os": this.getTableData[i].ec2.os,
          "type": this.getTableData[i].ec2.ec2_type,
          "name": this.getTableData[i].ec2.name,
          "ip_address": this.getTableData[i].ec2.ip_address,
          "members": this.getTableData[i].ec2.approval,
          "approval": this.getTableData[i].ec2.approval,

        }

        // this.tableArrTable.push(tableObj)
      }
      // console.log(this.getTableData.length);


    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      })



    // this.router.navigate(['/awsVdi'])

  }
  downloadData(type) {
    if (type == 'pdf') {
      this.apiService.downloadAwsVmInPdf(this.projectId, this.filterStatus).subscribe(data => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName + '-AwsVM.pdf'
        link.click();
      })
    }
    else if (type == 'excel') {
      this.apiService.downloadAwsVmInExcel(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-AwsVM.xlsx';
        link.click();
      })
    }
    else if (type == 'csv') {
      this.apiService.downloadAwsVmInCsv(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-AwsVM.csv';
        link.click();
      })
    }

  }

  createAWSVdi() {
    var body={}
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    var passValue=""
    var keyPairValue=""
    if (this.osVal=="CentOS7"){
      passValue=""
      keyPairValue=this.keyPair
      let member={
        "user_email": localStorage.getItem('mail').toLowerCase(),
        "user_name": userName,

        "user_status": "Approved",
      }
      body = {
        "request_type": "AWS-EC2",
        "resource_type": "request",
        "request_status": "Pending",
        "requestor_id": userPsNumber,
        "requestor_name": userName,
        "project_details":
        {
          "project_id": this.projectId,
          "project_name": this.projectName,
          "catalog_id": this.catalog_id,
          "catalog_name": this.catalog_name
        },
  
        "request_details":
        {
          "aws_ec2_details":
          {
            "ec2_details":
            {
              "aws_config_tool_id": this.awsConfig,
              "aws_network_config_tool_id": this.networkName,
              "aws_ec2_image_tool_id": this.imageName,
              "ebs_type": this.diskPropertyName,
              "keyPair":keyPairValue,
              "password": passValue,
              "ec2_type": this.ec2PropertyName,
              "members": [member],
            }
          }
        },
        "created_by": localStorage.getItem('mail').toLowerCase()
      }
    }
    else{
      passValue=this.secondFormGroup.controls.password.value
      keyPairValue=""
    
    // debugger


    //
    for (var i = 0; i < this.selectedUserArray.length; i++) {

      let bodymember =
      {
        "user_email": this.selectedUserArray[i].user_email,
        "user_name": this.selectedUserArray[i].user_name,

        "user_status": "Approved",
      }
      this.memberArray.push(bodymember)
      //Do something
    }

  
    body = {
      "request_type": "AWS-EC2",
      "resource_type": "request",
      "request_status": "Pending",
      "requestor_id": userPsNumber,
      "requestor_name": userName,
      "project_details":
      {
        "project_id": this.projectId,
        "project_name": this.projectName,
        "catalog_id": this.catalog_id,
        "catalog_name": this.catalog_name
      },

      "request_details":
      {
        "aws_ec2_details":
        {
          "ec2_details":
          {
            "aws_config_tool_id": this.awsConfig,
            "aws_network_config_tool_id": this.networkName,
            "aws_ec2_image_tool_id": this.imageName,
            "ebs_type": this.diskPropertyName,
            "keyPair":keyPairValue,
            "password": passValue,
            "ec2_type": this.ec2PropertyName,
            "members": this.memberArray,
          }
        }
      },
      "created_by": localStorage.getItem('mail').toLowerCase()
    }
  }
    this.apiService.LaunchAwInstance(body).subscribe((result: any) => {

      // debugger

      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsEc2']);
      });
      this.alertService.success(result.message, this.options);

      // this.router.navigate(['/awsVdi'])



    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      });
    // console.log(body);



    // console.log(this.secondFormGroup);

    // console.log(this.secondFormGroup.controls.password.value);




    //







    // console.log(this.selectedUserArray);

    // this.tableArr = [];
    // let member = this.selectedUserArray[0].user_name.replace(' ', '_').toLowerCase();
    // // console.log(member);

    // let vdiBody = {
    //   "project_id": this.projectId,
    //   "vdi_type": "Dedicated",
    //   "vdi_request": [
    //     {
    //       "vdi_user": {
    //         "resource_type": "request",
    //         "bundle_id": this.bundle_id.value,
    //         "directory_id": this.directory_id.value,
    //         "user_name": member,
    //         "compute_type_name": this.compute_type_name.value,
    //         "root_volume_size_gb": this.root_volume_size_gb.value,
    //         "running_mode": this.running_mode.value,
    //         // "user_volume_size_gb": this.user_volume_size_gb.value
    //       }
    //     }
    //   ]
    // }

    // this.apiService.createAWSVdi(vdiBody).subscribe((data: any) => {
    //   let windowsMembers = [];
    //   this.selectedUserArray.forEach(el => {
    //     let membObj = {
    //       name: el.user_name,
    //       email: el.user_mail,
    //     }
    //     windowsMembers.push(membObj)
    //   });

    //   let tableObj =
    //   {
    //     "status": "Pending",
    //     //"members": windowsMembers,
    //     "bundle_id": this.bundle_id.value,
    //     "directory_id": this.directory_id.value,
    //     "user_name": member,
    //     "compute_type_name": this.compute_type_name.value,
    //     "root_volume_size_gb": this.root_volume_size_gb.value,
    //     "running_mode": this.running_mode.value,
    //     "user_volume_size_gb": this.user_volume_size_gb.value
    //   }
    //   this.tableArr.push(tableObj)
    //   this.awsData = this.tableArr
    //   this.showAwsTable = true;
    //   this.showRepo = false;
    //   this.showUserRow = false;
    //   this.dtTrigger.next();

    // }, (error: HttpErrorResponse) => {
    //   if (error.status == 440) {
    //     this.alertService.error(error.error.message, this.options);
    //   }
    //   else {
    //     this.alertService.error("No data available.", this.options);
    //     throw error;
    //   }

    // })
  }

  getAWSVdi() {

    this.getTableData = [];
    this.tableRepoArray = [];
    this.refreshDisable = false;

    setTimeout(() => {
      this.refreshDisable = true;
    }, 2000)
    this.apiService.getAwsEc2Status(this.projectId).subscribe((data: any[]) => {
      // debugger
      // this.checklist = data;

      // console.log(data);

    },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }

      })
    this.getStatus('Active')
    this.projectId = localStorage.getItem('projectId');

  }
  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        member: memberArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  startAws(awsReqId, insReqId) {
    this.spinner.show()
    var arr=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      arr.push(this.VdiConfig_details[i].status)
    }
    let reqBody={
  
      "aws_config_tool_id": this.VdiConfig_details[0].request_id,
  
      "InstanceIds": arr
  
  }
    // let reqBody = {

    //   "aws_config_tool_id": awsReqId,

    //   "InstanceIds": insReqId

    // }
    this.apiService.StartAwInstance(reqBody).subscribe((data: any) => {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsEc2']);
      });
      this.spinner.hide()
      this.alertService.success(data.message, this.options);


      // setTimeout(() => {
      //   this.getAWSVdi();
      // }, 6000);
    }, (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })
    setTimeout(() => {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsEc2']);
      });
      this.spinner.hide()
    }, 3000)
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/awsEc2']);
    });

  }

  stopAws(reqID, reqId) {
    this.spinner.show()
    // let reqBody = {

    //   "aws_config_tool_id": reqID,

    //   "InstanceIds": reqId

    // }
    var arr=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      arr.push(this.VdiConfig_details[i].status)
    }
    let reqBody={
  
      "aws_config_tool_id": this.VdiConfig_details[0].request_id,
  
      "InstanceIds": arr
  
  }
    this.apiService.StopAwInstance(reqBody).subscribe((data: any) => {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsEc2']);
      });
      this.spinner.hide()
      this.alertService.success(data.message, this.options);

      // setTimeout(() => {
      //   this.getAWSVdi();
      // }, 6000);
    }, (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to stop.", this.options);
        throw error;
      }

    })
    setTimeout(() => {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsEc2']);
      });
      this.spinner.hide()
    }, 3000)
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/awsEc2']);
    });


  }
}
@Component({
  selector: 'dialog-info-network-dialog',
  templateUrl: 'infoPopNetwork.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class DialogNetworkInfo {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogNetworkInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type


    this.dataInfo = data.networkInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'dialog-info-image',
  templateUrl: 'imageDetails.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class ImageInfoPop {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<ImageInfoPop>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type
    // console.log(thsis.type);
    


    this.dataInfo = data.ImageInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}




@Component({
  selector: 'dialog-info-type-dialog',
  templateUrl: 'infoPopEc2Type.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class DialogEc2TypeInfo {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogEc2TypeInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type


    this.dataInfo = data.Ec2Info




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-info-disk-dialog',
  templateUrl: 'infoDisk.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class DialogEc2DiskTypeInfo {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogEc2DiskTypeInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type


    this.dataInfo = data.diskInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-info-image-purpose-dialog',
  templateUrl: 'infoPurpose.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class DialogPurposeInfo {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogPurposeInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type


    this.dataInfo = data.purposeInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  checked: boolean
}
//add memebr to existing Pooled VDI
const ELEMENT_DATA: MemberDetails[] = [];
@Component({
  selector: 'add-member-aws-vdi-dialog',
  templateUrl: 'add-to-existing-aws.html',
  styleUrls: ['./aws-vdi.component.scss']
})

export class addMemberAWSDialog {
  projectId: any;
  projectName: any;
  members: any = [];
  ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  memberList: any = [];
  newMembersAdd: any = [];
  finalArray: any = [];

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dummyMemberArray: any = [];
  tempBody: any;
  rowValue: any;
  catalog_id: any;
  catalog_name: any;
  memberString: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  type: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, public router: Router, public dialogRef: MatDialogRef<addMemberAWSDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar,private spinner: NgxSpinnerService) {
    // debugger
    this.memberList = data.values.ec2.members
    this.rowValue = data.values;
    this.type = data.screen
    // console.log(this.memberList);

  }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');
    this.getUser();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  allSelected() {
    this.isallSelected = true
  }

  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;

      this.members.forEach((element, index) => {
        for (var i = 0; i < this.memberList.length; i++) {
          if (this.memberList[i].user_email == element.user_email) {
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
          }
        }

      });

      this.members.forEach((object: any) => {
        if (!object.duplicate) {
          this.finalArray.push(object)
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }
      })


      if (this.type === "add") {
        this.memberList.forEach(member => {
          for (let i = 0; i < this.finalArray.length; i++) {
            if (member.name == this.finalArray[i].user_name) {
              this.finalArray.splice(i, 1);
            }
            else {
            }
          }
        });
      }
      let members = []
      if (this.type === "delete") {

        this.memberList.forEach(user => {
          let object = {
            "user_name": user.name,
            "user_email": user.email,
          }
          members.push(object)
        })
        this.finalArray = members
      }

      this.dataSource.data = this.finalArray as MemberDetails[]
    })
  }

  addUserForVDI() {
    if (this.selection.selected.length == 0) {
      this._snackBar.open("Select User", "", {
        duration: 3000,
      })
    }
    else {
      if (this.type === "add") {
        let memberArray = [];
        this.selection.selected.forEach(user => {
          let bodymember =
          {
            "user_email": user.user_email,
            "user_name": user.user_name,
    
            "user_status": "Approved",
          }
          memberArray.push(bodymember)
        })

        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
        let mail = localStorage.getItem('mail');

        let ec2BodyAdd =
          { 
            "aws_config_tool_id": this.rowValue.aws_config_tool_id, 
            "InstanceIds": this.rowValue.ec2.ec2_id, 
            "project_details": { 
              "project_id": this.projectId,
               "project_name": this.projectName 
              }, 
              "request_id": this.rowValue.request_id, 
              "requestor_mail": mail, 
              "members": memberArray
        }


        this.spinner.show()
        this.apiService.addMemberinExisingEc2(ec2BodyAdd).subscribe((result: any) => {
          this.spinner.hide()

          this.alertService.success(result.message, this.options);

        }, (error: HttpErrorResponse) => {
          this.spinner.hide()

          if (error.status == 435) {

            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {

            this.alertService.error("Error While Adding Member", this.options);
            throw error;
          }

        });
        // console.log(ec2BodyAdd);

        this.dialogRef.close()
      }
      else {
        let memberArray = [];
        this.selection.selected.forEach(user => {
          // memberArray.push(user.user_email)
          let bodymember =
          {
            "user_email": user.user_email,
            "user_name": user.user_name,
    
            "user_status": "Offboarded",
          }
          memberArray.push(bodymember)
        })

        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
        let mail = localStorage.getItem('mail');

        let ec2BodyDelete =
          { 
            "aws_config_tool_id": this.rowValue.aws_config_tool_id, 
            "InstanceIds": this.rowValue.ec2.ec2_id, 
            "project_details": { 
              "project_id": this.projectId,
               "project_name": this.projectName 
              }, 
              "request_id": this.rowValue.request_id, 
              "requestor_mail": mail, 
              "members": memberArray
        }
        this.spinner.show()
        this.apiService.deleteMemberFromExisingEc2(ec2BodyDelete).subscribe((result: any) => {

          this.spinner.hide()
          this.alertService.success(result.message, this.options);

        }, (error: HttpErrorResponse) => {
          this.spinner.hide()

          if (error.status == 435) {
            this.alertService.success("User offboarded successfully.", this.options);
          }
          else if (error.status == 403){}
          else {

            this.alertService.error("Error While Offboarding Member", this.options);
            throw error;
          }

        });
        this.dialogRef.close()
      }


    }

  }


  exit() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./aws-vdi.component.scss']
})
export class ec2CancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<ec2CancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.dialogRef.close();
      this.dialogRef.close('Yes');

      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}