<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a (click)="goHome(secondFormGroup)">Home</a></li>
            <li class="breadcrumb-item link-blue"><a (click)="goWorkBeanch(secondFormGroup)">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Jenkins</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <!-- <span (click)="addPipeline()" *ngIf="!cicdFlag"><i class="fas fa-plus"></i> Add</span>
          <span data-toggle="modal" data-target="#myModal" *ngIf="cicdFlag"><i class="fas fa-plus"  ></i> Add</span> -->
          <span (click)="checkToolData()" ><i class="fas fa-plus"></i> Add</span>
            <span id="CallModel"  data-toggle="modal" data-target="#myModal"></span>
          <span *ngIf="refreshDisable" (click)="getProjects(filterStatus)"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span *ngIf="!refreshDisable" class="disabled-img"><i class="fas fa-sync-alt"></i> Refresh</span>

          <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back
        </span>
      </div>
    </div>

  </div>

  <div class="inner-mid-container">

    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-sm-5">
        <h1 class="mb-0">
          <img width="40" class="image-resize" src="assets/img/Jenkins.png">
          <div class="vertical-line mx-3"> </div> <span>Jenkins</span>
        </h1>
      </div>
      <div class="col-md-7"><span class="field-name">Project ID </span><span class="field-value"> :
          {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information"
          (click)="showProjectInfo()">info</span>
      </div>
    </div>
  </div>

      <div class="col-md-6 mb-2">
        <div class="text-right">
          <div class="row">
          <div class="col-md-10 col-12">
              <mat-form-field class="mr-1">
                  <mat-label>Status</mat-label>
                  <mat-select [(ngModel)]="filterStatus" (selectionChange)="getStatus($event.value)" name="fieldName" ngDefaultControl> 
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Active">Active</mat-option>
                    <mat-option value="Failed">Failed</mat-option>
                  </mat-select>
                </mat-form-field>
          </div>
          
          <div class="col-md-2 col-12">
            <div class="dropdown" *ngIf="cicdTable.length>0">
              <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                <i class="fas fa-download "></i> Export
              </a>
              <div class="dropdown-menu more-drop-down">
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('pdf')">
                    <i width="20" class="fas fa-file-pdf color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> PDF</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('excel')">
                    <i width="20" class="fas fa-file-excel color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> EXCEL</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('csv')">
                    <i width="20" class="fas fa-file-csv color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> CSV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
          
        </div>
      </div>
    </div>
    <div class="row my-5 pb-5 border-bottom" *ngIf="showJenkinsForm">
      <form [formGroup]="secondFormGroup" class="col-md-12">

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="description">Description</label>
              <input #input placeholder="Enter Description" id="description" name="description"
                class="form-control form-control-height" required formControlName="description"
                [class.is-invalid]="description.invalid && description.touched">
              <small class="text-danger"
                [class.d-none]="description.valid || description.untouched">{{getProjDescErrorMessage()}}
              </small>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="repo">Select Repository</label>
              <select id="repo" name="repo" class="form-control form-control-height" required formControlName="repo"
                [class.is-invalid]="repo.invalid && repo.touched">
                <option [value]="null" disabled selected hidden>Select Repository</option>
                <option *ngFor="let scm of repoList" [value]="scm">{{scm}}</option>
              </select>
              <small class="text-danger" [class.d-none]="repo.valid || repo.untouched">Repo is
                required</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-3">
            <div class="form-group">
              <label for="name">Project Name</label>
              <input #input placeholder="Enter Name" id="name" name="name" class="form-control form-control-height"
                required formControlName="name" [class.is-invalid]="name.invalid && name.touched">
              <small class="text-danger" [class.d-none]="name.valid || name.untouched">{{getProjNameErrorMessage()}}
              </small>
            </div>
          </div>

          <div class="col-12 col-md-3">
            <div class="form-group">
              <label for="project_type">Select Project-Type</label>
              <select id="project_type" name="project_type" class="form-control form-control-height" required
                formControlName="project_type" [class.is-invalid]="project_type.invalid && project_type.touched">
                <option [value]="null" disabled selected hidden>Select Project Type</option>
                <option *ngFor="let project of projecttype" [value]="project">{{project}}</option>
              </select>
              <small class="text-danger" [class.d-none]="project_type.valid || project_type.untouched">Project type is
                required</small>
            </div>
          </div>

          <div class="col-12 col-md-3">
            <div class="form-group">
              <label for="tool_instance">Select Tool Instance</label>
              <select id="tool_instance" name="tool_instance" class="form-control form-control-height" required
                formControlName="tool_instance" [class.is-invalid]="tool_instance.invalid && tool_instance.touched">
                <option [value]="null" disabled selected hidden>Select Instance</option>
                <option *ngFor="let tool of toolInstance" [ngValue]="tool.tool_instance_name">
                  {{tool.tool_instance_name}}</option>
              </select>
              <small class="text-danger" [class.d-none]="tool_instance.valid || tool_instance.untouched">Tool Instance
                type is required</small>
            </div>
          </div>

          <div class="col-12 col-md-1 text-center mt-4 img-width">
            <div class="col-md-6">
              <span class="material-icons add-user-icon-one" (click)="openUser()" title="Add User">
                person_add
              </span>
            </div>
          </div>

          <div class="col-12 col-md-2 col-lg-1 mt-4">
            <button mat-button (click)="createJenkinsPipeLine()"
              [disabled]="!secondFormGroup.valid || !selectedUserArray.length" mat-raised-button
              class="jenkins-btn width-100">
              Create
            </button>
          </div>

          <div class="col-12 col-md-2 col-lg-1 mt-4">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2"
              (click)="toggleDisplay(secondFormGroup)">Cancel</button>
          </div>

        </div>

      </form>
    </div>
    <div class="row userRow" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">

        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>
    <div class="mt-2">
      <div class="table-responsive">
        <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="cicdTable.length>0">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Project Name</th>
              <th>Repository</th>
              <th>Jenkin URL</th>
              <th>Project Type</th>
              <th>Tool Instance</th>
              <th>Status</th>
              <th>Members</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of cicdTable">
              <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''">{{element.request_id}}</td>
              <td class="word-break" [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''">{{element.project_name ? element.project_name : '--NA--' }}</td>
              <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''" class="word-break">{{element.repo ? element.repo : '--NA--'}}</td>
              <td  *ngIf="element.Jenkin_url && element.status!='Moved_to_Recycle'" class="word-break"><a target="_blank"
                  href="{{element.Jenkin_url}}">{{element.Jenkin_url}}</a></td>
                  <td class="softDelete-cell" *ngIf="element.status=='Moved_to_Recycle'">
                      <h4 class="word-break" *ngIf="element.Jenkin_url && (element.status=='Moved_to_Recycle')" >{{element.Jenkin_url}}</h4>
                      
                    </td>
              <td *ngIf="!element.Jenkin_url">--NA--</td>
              <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''">{{element.project_type ? element.project_type : '--NA--'  }}</td>
              <td  [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''" class="word-break">{{element.tool_instance ? element.tool_instance : '--NA--' }}</td>
              <td *ngIf='element.status!="Moved_to_Recycle"'>{{element.status}}</td>
              <td class="softDelete-cell" *ngIf='element.status=="Moved_to_Recycle"'>Moved to Recycle</td>              
              <td *ngIf='element.status!="Moved_to_Recycle"'>
                <h4 *ngIf="element.members.length==0">
                  --NA--
                </h4>
                <button *ngIf="element.members.length >1" class="view-member-btn"
                  (click)="openUserDialog(element.members)">View</button>
                <div class="" *ngIf="element.members.length == 1">
                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                    <h4>{{user.name }}</h4>
                  </span>

                </div>
              </td>
              <td  class="w15-soft-delete" *ngIf='element.status=="Moved_to_Recycle"'>
                  <button *ngIf="element.members.length >1" class="view-member-btn-inactive"
                    (click)="openUserDialog(element.members)">View</button>
                  <div class="" *ngIf="element.members.length == 1">
                    <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                      <h4>{{user.name}}</h4>
                    </span>
  
                  </div>
                </td>
              <td *ngIf="element.status!='Moved_to_Recycle'">
                <div class="dropdown">
                  <a href="#" class="nav-link" data-toggle="dropdown">
                    <i class="fas fa-ellipsis-h"></i>
                  </a>
                  <div class="dropdown-menu more-drop-down">
                    <!-- <a class="dropdown-item">
                      <span class="table-crud table-edit-icon" disabled>
                        <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span
                          class="vertical-line-more mx-2"></span> Start </span>

                    </a> -->
                    <!-- <div class="dropdown-divider"></div>
                    <a class="dropdown-item">
                      <span class="table-crud table-save-icon" disabled>
                        <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span
                          class="vertical-line-more mx-2"></span> Stop </span>

                    </a>
                    <div class="dropdown-divider"></div> -->
                    <a class="dropdown-item">
                      <span *ngIf="element.status=='Completed'" (click)="deleteconfirmationPopUp(element)">
                        <img width="20" src="../../../assets/img/Delete-blue.png">
                        <span class="vertical-line-more mx-2"></span>Move to Recycle
                      </span>
                      <span
                        *ngIf= "element.status=='Processing' || element.status!='Completed'"
                        disabled>
                        <img width="20" src="../../../assets/img/Delete-Deactivate.png">
                        <span class="vertical-line-more mx-2"></span>Move to Recycle
                      </span>


                    </a>
                  </div>
                </div>
              </td>
              <td *ngIf="element.status=='Moved_to_Recycle'" class="softDelete-cell"> 
                  <a href="#" class="nav-link" data-toggle="dropdown" *ngIf="element.status=='Moved_to_Recycle'">
                      <i class="fas fa-ellipsis-h" style="color:rgba(0, 0, 0, 0.86);"></i>
                    </a>
              </td>
            </tr>
          </tbody>
        </table>

        <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="cicdTable.length==0">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Project Name</th>
              <th>Repository</th>
              <th>Jenkin URL</th>
              <th>Project Type</th>
              <th>Tool Instance</th>
              <th>Status</th>
              <th>Members</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>
      </div>
    </div>

<div class="modal fade" id="myModal"  role="dialog">
  <div class="modal-dialog">
    <div class="modal-content modal-adjust" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <h2 class="text-center">No tool instance Available</h2>
      </div>
      <div class="modal-header">
        
      </div>
    </div>
    
  </div>
</div>

<div class="modal fade" id="myModal"  role="dialog">
  <div class="modal-dialog">
    <div class="modal-content modal-adjust" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <h2 class="text-center">No tool instance Available</h2>
      </div>
      <div class="modal-header">
        
      </div>
    </div>
    
  </div>
</div>
