
<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up">
    <div class="">
           <span  class="vertical-line mx-1 mx-md-3"> </span>
            <span class="header mb-0"> Client Poperties Details</span> 
           <span (click)="exit()" class="material-icons close-icon">
               close
             </span>
    </div>
    <hr class="margin-class" />
   <div class="popup-container">
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Stealthwatch Token </span>
       </div>
       <div class="col-md-6">
               <span class="fieldStyle"> : {{stealthwatchtoken}}</span>
       </div>
   </div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">AMP4E Access Token </span>
       </div>
       <div class="col-md-6">
               <span class="fieldStyle"> : {{ampaccesstoken}}</span>
       </div>
   </div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Imanage Token </span>
       </div>
       <div class="col-md-6">
               <span class="fieldStyle"> : {{imanagetoken}}</span>
       </div>
   </div>
   <div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Azure Auth Token</span>
    </div>
    <div class="col-md-6">
            <span class="fieldStyle"> : {{azurezuthtoken}}</span>
    </div>
</div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Jenkins Auth Token</span>
       </div>
       <div class="col-md-6">
               <span class="fieldStyle"> : {{jenkinsauthtoken}}</span>
       </div>
   </div>
   </div>
   </div>