<div class="member-card">
    <div class="row ">
      <div class="offset-md-11 col-md-1">
        <span class="material-icons" mat-dialog-close title="close" style="float: right;cursor: pointer;">
          close
        </span>
      </div>
    </div>
   
    <div class="row mt-3">
        <div class="col-md-6 configuration-items">
            <img src="../../../assets/img/clone.png" height="60" width="60"><br><br>
            <button mat-raised-button style="width:125px;" class="border_none">Clone</button>
        </div>
        <div class="col-md-6 configuration-items">
            <img src="../../../assets/img/download.png" height="60" width="60"><br><br>
            <button mat-raised-button style="width:125px;" class="border_none">Download</button>
        </div>
    </div>
    
  
  </div>