<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up" *ngIf="type == 'vmsize'">
    <div class="">
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0"> VDI Size Details</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">RAM</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{vm.RAM}} GiB</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">vCPUs </span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{vm.vCPUs}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Temp Storage</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{vm.Temp_Storage}} GiB</span>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Description</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{vm.Description}}</span>
            </div>
        </div>
    </div>
</div>
<div class="pop-up" *ngIf="type == 'tokenexpire'">
    <div class="">
        <span class="vertical-line mx-1 mx-md-3"> </span>
        <span class="header mb-0"> Session Expired</span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <div class="row pt-2">
            <h3 class="mb-4 col-md-12">Your session has expired. Do you want to continue working?</h3>
            <div class="offset-md-9">
                <div class="row">
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('Yes')" class="border_none vdi-button">Yes</button>
                    </div>
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">No</button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>