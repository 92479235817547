<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container" *ngIf="showJenkin">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

        <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-7">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Jenkins config</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right pt-3">
        <span class="table-options ">
          <span (click)="addJenkins()"><i class="fas fa-plus"></i> Add</span>
          <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

          <span (click)="refreshJenkins()"><i class="fas fa-sync-alt"></i> Refresh</span>
         <!-- <span><i class="fas fa-cog"></i> Manage</span>-->
         <a class="float-right step-back" (click)="backNavigation()">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </span>
      </div>
    </div>

    <h1 class="text-light mb-0">
      <img width="40" src="assets/img/Jenkins.png">
      <div class="vertical-line mx-3"> </div> <span>Jenkins</span>      
    </h1>

  </div>

    <div class="inner-mid-container">
                  <!-- Modal -->
                  <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
                  <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
                    aria-labelledby="confirmationModal" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content pop-up">
                        <div class="modal-header-popup">
                          <span class="vertical-line mx-3"> </span>
                          <span class="header mb-0">Jenkins</span>
                          <span data-dismiss="modal" class="material-icons close-icon">
                            close
                          </span>
                        </div>
                        <hr class="margin-class" />
                        <div class="modal-body">
                          Are you sure want to cancel?
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                          <button type="button"  class="btn btn-canvas-blue-2" data-dismiss="modal"
                            (click)="cancelok()">Ok</button>
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
            <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content pop-up">
                        <div class="modal-header-popup">
                          <span  class="vertical-line mx-3"> </span>
                           <span class="header mb-0">Jenkins</span> 
                          <span  data-dismiss="modal" class="material-icons close-icon">
                              close
                            </span>
                        </div>
                        <hr class="margin-class" />
                        <div class="modal-body">
                          Are you sure want to Delete?
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
      
        <div class="admin-jenkin-form" *ngIf="showForm">
            <h2 *ngIf="!editMode">New Jenkins Instance</h2>
            <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
            <form (ngSubmit)="onSubmit()" #jenkinsForm="ngForm">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="jenkinstoolGR">Tool Group <span class="required">&#42;</span></label>
                            <select (change)="onChange($event)" [disabled]="ToolGroupDisable" id="jenkinstoolGR" name="jenkinstoolGR"
                                class="form-control form-control-lg" required [(ngModel)]="jenkins.jenkinstoolGR" #jenkinstoolGR="ngModel">
                                <!-- <option value="">Choose Tool Group</option> -->
                                <option value="" disabled selected hidden>Select Tool Group</option>
                                <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}
                                </option>
                            </select>

                            <div *ngIf="jenkinstoolGR.errors && jenkinstoolGR.touched"
                                class="alert alert-danger">
                                <div *ngIf="jenkinstoolGR.errors.required">Tool Group is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <label> &nbsp;</label>
                        <div>
                        <a class="link-col" (click)="openDialog()">Add New Tool
                            Group</a>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="nameInstance"> Name <span class="required">&#42;</span></label>
                            <input id="nameInstance" name="nameInstance"  maxlength="20" minlength="3" pattern="^[a-zA-Z0-9_-]+$"
                                class="form-control form-control-lg" required [disabled]="ToolInstanceNameDisable"
                                [(ngModel)]="jenkins.nameInstance"
                                #nameInstance="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">

                            <div   *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
                                class="alert alert-danger">

                                <div *ngIf="nameInstance.errors.required">
                                    Name is required.
                                </div>
                                <div *ngIf="nameInstance.errors.minlength">
                                  Please enter minimum 3 characters for saving Tool Name
                                </div>
                                <div *ngIf="nameInstance.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="description">Description  <span class="required">&#42;</span></label>
                            <input  id="description" name="description"  maxlength="50" pattern="^[a-zA-Z0-9_ -]+$" minlength="20" 
                                class="form-control form-control-lg" required [(ngModel)]="jenkins.description"
                                #description="ngModel">

                            <div  *ngIf="description.invalid && (description.dirty || description.touched)"
                                class="alert alert-danger">

                                <div *ngIf="description.errors.required">
                                    description  is required.
                                </div>
                                <div *ngIf="description.errors.minlength">
                                    Please enter minimum 20 characters for saving Description 

                                </div>
                                <div *ngIf="description.invalid && !description.errors.minlength &&description.errors.pattern">
                                  Description must include only alphabets ,numbers or _,-.</div>
                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="proURL">Project URL <span class="required">&#42;</span></label>
                            <input id="proURL" name="proURL"  pattern="^[a-zA-Z0-9:./#-]+$"
                                class="form-control form-control-lg" required [(ngModel)]="jenkins.proURL"  #proURL="ngModel" [disabled]="UrlDisable">

                            <div *ngIf="proURL.invalid && (proURL.dirty || proURL.touched)"
                                class="alert alert-danger">

                                <div *ngIf="proURL.errors.required">
                                    Project URL is required.
                                </div>
                                <div *ngIf="proURL.errors.pattern">
                                    Invalid Project URL</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="proToken">Token <span class="required">&#42;</span></label>
                            <input  id="proToken" class="form-control form-control-lg"
                                required name="proToken" [(ngModel)]="jenkins.proToken" pattern="^[a-zA-Z0-9-]+$" #proToken="ngModel" [disabled]="PrivateDisable">

                            <div *ngIf="proToken.invalid && (proToken.dirty || proToken.touched)"
                                class="alert alert-danger">

                                <div *ngIf="proToken.errors.required">
                                    Token is required.
                                </div>
                                <div *ngIf="proToken.errors.pattern">
                                    Invalid Token</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="jenkinsEmail">Admin Username <span class="required">&#42;</span></label>
                            <input id="jenkinsEmail" name="jenkinsEmail"
                                class="form-control form-control-lg" required email
                                [(ngModel)]="jenkins.jenkinsEmail" #jenkinsEmail="ngModel"  [disabled]="EmailDisable">

                            <div 
                            *ngIf="jenkinsEmail.invalid && (jenkinsEmail.dirty || jenkinsEmail.touched)"
                            class="alert alert-danger">

                            <div *ngIf="jenkinsEmail.errors.required">
                                Admin Username is required.
                            </div>

                            <div id="emailerror" *ngIf="jenkinsEmail.errors?.email"> 
                                Jenkins Admin Username not valid.
                           </div> 
                            
                        </div>

                        </div>
                    </div>
                </div>
                <div class="row ">
                    <!-- <div class="col-md-6">
                       
                    </div> -->
                    <div class="col-12 text-right">
                        <button [disabled]="testConnDisable || !jenkinsForm.form.valid" type="submit" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0">
                            Test Connection
                        </button> 
                        <button *ngIf="!editMode" [disabled]="saveConnDisable || !jenkinsForm.form.valid" type="button" (click)="CheckJenkinLabDuplication()" class="btn-canvas-blue mr-2">
                            Save
                        </button>
                        <button *ngIf="editMode" [disabled]="UpdateConnDisable || !jenkinsForm.form.valid" type="button" class="btn-canvas-blue mr-2" (click)="updateJenkin()">
                            Update
                          </button>
                        <!-- <button type="button" (click)="hideJenkin()" class="btn-canvas-blue">
                            Cancel
                        </button> -->
                        <!--button class="mr-2 btn-canvas-blue"  type="button" data-toggle="modal"
                        data-target="#confirmationModal">Cancel</button-->
                        <button class="mr-2 btn-canvas-blue"  type="button" data-toggle="modal" *ngIf="!jenkinsForm.form.pristine" data-target="#confirmationModal">Cancel</button>
                        <button class="mr-2 btn-canvas-blue" *ngIf="jenkinsForm.form.pristine" (click)="cancelok()">Cancel</button>
          
                    </div>
          
        
                </div>
            </form>
        </div>
        <div class="my-5 table-container">
            <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
                <thead>
                  <tr>
                        <th style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                                    (change)="checkUncheckAll()" />
                            </th>
                   <!-- <th style="width: 15px;"><input type="checkbox"></th>-->
                   <th>ID</th>
                   <th>Connection Name</th>
                   <th>Jenkins URL</th>               
                   <th>Tool Group</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let cicd of cicdArray">
                        <td style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="cicd.isSelected" name="list_name" value="{{cicd.id}}"
                                    (change)="isAllSelected()" />
                            </td>
                   
                    <td><span class="link-col" (click)="getRecord(cicd)">{{cicd.ID}}</span></td>
                    <td>{{cicd.name}}</td>
                    <td>{{cicd.projectURL}}</td>
                    <td>{{cicd.toolGroup}}</td>
                  </tr>  
                </tbody>
              </table>
            </div>
        </div>
    </div>
</div>

