import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { CastDialogComponent } from "src/app/project-configurations/work-bench/work-bench.component";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { AuthService } from "src/app/service/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "src/app/_alert";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { error } from "@angular/compiler/src/util";
import { Subject } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ViewChild } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { exit } from "process";
import { CanComponentLeave } from "src/app/unsaved-changes.guard";
import { any } from "prop-types";
import { ThemePalette } from "@angular/material/core";
import { TemplateModal } from "src/app/project-configurations/dev-test-framework/dev-test-framework.component";

export interface Fruit {
  name: string;
}
export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
export interface TaskVdi {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasksVdi?: TaskVdi[];
}
export interface TaskLive {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasksLive?: TaskLive[];
}
export interface configList {
  toolName: string;
  ID: string;
  db_id: string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

export interface storyBoardDetails {
  toolName: string;
  ID: string;
  db_id: string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}
export interface DomainDetails {
  template_id: string;
  template_name: string;
  template_description: string;
  functional_area: string;
}
export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_id_display: string;
  tool_group_id: string;
  tool_group_name: string;
}

@Component({
  selector: "app-admin-project-template",
  templateUrl: "./admin-project-template.component.html",
  styleUrls: ["./admin-project-template.component.scss"],
})
export class AdminProjectTemplateComponent
  implements OnInit, CanComponentLeave
{
  // @ViewChild('confirmationModal2') cfm;

  disableAzure: boolean = false;
  isCheckVDI: boolean = false;
  isCheckStoryBoard: boolean = false;
  isCheckSCM: boolean = false;
  isCheckDevOps: boolean = false;
  isCheckColl: boolean = false;
  isCheckMonitoring: boolean = false;
  isCheckDeploy: boolean = false;
  isValid: boolean = true;
  dropdownSettings: IDropdownSettings;
  toolDescription: any;
  model: any = {};
  Role: string = "";
  Message: string = "";
  testConnDisable = false;
  saveConnDisable = true;
  UpdateConnDisable = true;
  CreateTemplateDisable = false;
  vdi_Azure_Iddiv = true;
  ToolGroupDisable = false;
  ToolInstanceNameDisable = false;
  date = new FormControl(new Date());

  // Variable for Save/Edit body
  NameDisable = false;
  addroleDisable = false;
  deleteDisable = false;
  project_Token: string;
  project_URL: string;
  project_toolGroupName: string;
  project_id_scmEdit: string;
  project_id_db: string;
  project_nameInstance: string;
  project_description: string;
  project_jiraEmail: string;
  project_toolId: String;
  project_toolGrId: string;

  token: any;
  //projectURLVal: any;
  toolName: any;

  storyBoard: any = {};
  TablePage: boolean = true;
  //jiraToolDetails: jiraTool;
  selectedToolvalue: any;

  formMode: string = "";
  editMode: boolean = false;
  deleteMode: boolean = false;

  toolGr: any;
  proURL: any;
  proToken: any;
  nameInstance: any;
  description: any;
  jiraEmail: any;

  personaName: any;
  logicalImageName: any;
  actualImageName: any;
  storyBoardCheck = false;
  ActualImageArray = [];
  persona = [];
  logical = [];
  privilegeArray = [
    "Group Member",
    "Primary Group Owner",
    "Secondary Group Owner",
  ];
  RoleNameArrayPrivellege = [
    "Team Member",
    "Project Manager",
    "Secondary Project Manager",
  ];

  roleArray = [];
  deletedRoleArray = [];

  testArray: any;
  jiraPage: boolean = true;
  addDomainDiv: boolean = false;
  storyBoardObj: object[];
  ToolGroupList = [];
  domainArray = [];
  selectedItems = [];
  submitted = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };
  DB_role_name: any;
  temprole_name: any;
  temprole_local_image: any;
  tempazure_image: any;
  tempazure_id: any;
  tempprivilege_access: any;
  tempvdi_tool_id: any;
  tempvdi_type: any;
  tempDB_role_name: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  //selectedItems = [];
  subRubrosSelec = [];
  masterDomainTemple = [];
  //public selectedItems = [];
  HideStoryBoard = false;
  HideMonitoring = false;
  HideReports = false;
  HideDeployment = false;
  HideVDI = false;
  HideCollaboration = false;
  HideDevOps = false;
  HideSCM = false;

  EnableStoryBoard = false;

  EnableDeployment = false;
  EnableVDI = false;
  EnableCollaboration = false;
  EnableDevOps = false;
  EnableSCM = false;
  toolsGroup: any = {};
  SubscriptionList: Subscription_iddropdown[] = [];
  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  privilege: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;
  DisableStoryBoard = true;
  DisableSCM = true;
  DisableDevOps = true;
  DisableCollaboration = true;
  DisableVDI = true;
  DisableDeployment = true;
  chkStoryBoard = false;
  chkSCM = false;
  chkDevOps = false;
  chkCollaboration = false;
  chkVDI = false;
  chkDeployment = false;
  tempOb = {};
  chkHideStoryBoard = false;
  chkHideSCM = false;
  chkHideDevOps = false;
  chkHideCollaboration = false;
  chkHideVDI = false;
  chkHideDeployment = false;
  chkHideMonitoring = false;
  chkHideReports = false;
  vdi_tool_id: string;
  vdi_type: string;
  AzuredrpDisable = true;
  task: Task = {
    name: "Deployment Store",
    completed: false,
    color: "accent",
    subtasks: [
      { name: "Azure VM", completed: false, color: "accent" },
      { name: "AWS EC2", completed: false, color: "accent" },
      // {name: 'Warn', completed: false, color: 'accent'},
    ],
  };
  taskVdi: TaskVdi = {
    name: "VDI",
    completed: false,
    color: "accent",
    subtasksVdi: [
      { name: "Azure VDI", completed: true, color: "accent" },
      { name: "AWS Workplace", completed: false, color: "accent" },
      // {name: 'Warn', completed: false, color: 'accent'},
    ],
  };
  taskLive: TaskLive = {
    name: "Live Monitoring",
    completed: false,
    color: "accent",
    subtasksLive: [
      { name: "Resillence", completed: false, color: "accent" },
      // {name: 'AWS Workplace', completed: false, color: 'accent'},
      // {name: 'Warn', completed: false, color: 'accent'},
    ],
  };
  allComplete: boolean = false;
  allCompleteVdi: boolean = false;
  allCompleteLive: boolean = false;

  popoverTitle: string = "Delete Role";
  popoverMessage: string = "Are you sure want delete?";
  cancelClicked: boolean = false;
  showDiv: boolean;
  showDivAdd: boolean;
  errorMsg = "";
  errorMsgAdd = "";
  VdiConfig_details: any[];
  check: any[];
  deletebutton: boolean;
  flag: boolean;
  flagmsg: string;
  flag2: boolean;
  flagmsg2: string;
  flags: boolean;
  flagmsgs: string;
  flagl: boolean;
  flagmsgl: string;
  // public ProjectIconArr =["Project Details","WorkBeanch","Team Member","Live Monitoring"];
  //public workbeanchArry = ["Project Role","Assign VDI","SCM Tool","Story Board","Devops","Deployment Store","Collaboration"];
  nextUrl: any;
  vdiImage: boolean;
  checkedEntry = [];
  deploymentAWS: boolean = false;
  deploymentAzure: boolean = false;
  resillenceFlag:boolean=false;
  deployment: boolean;
  vdi: boolean;
  vdiAzure: boolean;
  vdiAWS: boolean;
  isCheckReports: boolean;
  roleEditFlag: boolean = false;
  privellegeDisable: boolean = false;
  editIndex: any;
  azureImageIndex: number;
  editRoleDetails: any;
  roleVariable: any;
  tempUpdateArray = [];
  secondaryIndex: number;
  RoleNameArray: any[];
  tempRoleName: any;
  deleteIndex: any;
  deletebody: any;
  canLeave(url): boolean {
    // console.log(url);

    // debugger
    this.nextUrl = url;
    if (this.nextUrl == "/admin-story-board") {
      return true;
    } else {
      if (
        (this.storyBoard.nameInstance == "" ||
          this.storyBoard.nameInstance == undefined) &&
        (this.storyBoard.description == "" ||
          this.storyBoard.description == undefined) &&
        (this.storyBoard.personaName == "" ||
          this.storyBoard.personaName == undefined) &&
        (this.storyBoard.logicalImageName == "" ||
          this.storyBoard.logicalImageName == undefined) &&
        (this.storyBoard.actualImageName == "" ||
          this.storyBoard.actualImageName == undefined) &&
        (this.storyBoard.privilege == "" ||
          this.storyBoard.privilege == undefined) &&
        (this.storyBoard.azureId == "" || this.storyBoard.azureId == undefined)
      ) {
        return true;
      }
      if (
        (this.storyBoard.nameInstance != "" ||
          this.storyBoard.nameInstance != undefined) &&
        (this.storyBoard.description != "" ||
          this.storyBoard.description != undefined) &&
        (this.storyBoard.personaName != "" ||
          this.storyBoard.personaName != undefined) &&
        (this.storyBoard.logicalImageName != "" ||
          this.storyBoard.logicalImageName != undefined) &&
        (this.storyBoard.actualImageName != "" ||
          this.storyBoard.actualImageName != undefined) &&
        (this.storyBoard.privilege != "" ||
          this.storyBoard.privilege != undefined) &&
        (this.storyBoard.azureId != "" || this.storyBoard.azureId != undefined)
      ) {
        let element: HTMLElement = document.getElementById(
          "CallModel"
        ) as HTMLElement;
        element.click();
        return false;
      }
    }

    return true;
  }
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private apiService: AuthService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public alertService: AlertService
  ) {}

  ELEMENT_DATA: storyBoardDetails[];
  displayedColumns: string[] = [
    "toolName",
    "ID",
    "projectURL",
    "email",
    "toolGroup",
    "select",
  ];
  //dataSource = new MatTableDataSource<storyBoardDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<storyBoardDetails>(true, []);

  ngOnInit(): void {
    this.privilegeArray = [
      "Group Member",
      "Primary Group Owner",
      "Secondary Group Owner",
    ];

    this.dtOptions = {
      order: [],
    };
    this.Role = localStorage.getItem("Role");
    if (this.Role == "Admin") {
      this.getSubscriptionList();
      this.GetDomaintableDetails();
    } else {
      this.router
        .navigateByUrl("/admin-scm-tool", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["/home"]);
        });
    }
  }

  getSubscriptionList(): void {
    // debugger

    this.apiService.Admin_AzureTableDetails().subscribe(
      (res: any) => {
        //this.apiService.Admin_AzureTableDetails().subscribe(data => {
        if (res) {
          this.SubscriptionList = [];
          res.forEach((member) => {
            //

            let obj = {
              subscription_id:
                member.tool_instance_details.azure_tool_instance
                  .subscription_id,
              Azure_ConnectionName:
                member.tool_instance_details.azure_tool_instance
                  .tool_instance_name,
              tenant_id:
                member.tool_instance_details.azure_tool_instance.tenant_id,
              tool_id: member.tool_id,
              tool_id_display:
                member.tool_id +
                " (" +
                member.tool_instance_details.azure_tool_instance
                  .tool_instance_name +
                ")",
              tool_group_id: member.tool_group_details.tool_group_id,
              tool_group_name: member.tool_group_details.tool_group_name,
            };

            this.SubscriptionList.push(obj);
          });
          // console.log(this.SubscriptionList, "SubscriptionList")
        }
      },
      (error: HttpErrorResponse) => {
        //
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        } else if (error.status == 403) {
        } else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    );
  }
  // Deploytment store nested functions
  updateAllComplete() {
    //  console.log("update");

    //  debugger
    this.allComplete =
      this.task.subtasks != null &&
      this.task.subtasks.every((t) => t.completed);
  }

  someComplete(): boolean {
    // console.log("some");

    // debugger
    if (this.task.subtasks == null) {
      return false;
    } else {
      return (
        this.task.subtasks.filter((t) => t.completed).length > 0 &&
        !this.allComplete
      );
    }

    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach((t) => (t.completed = completed));
  }

  ////////////////////////////////////////////////

  // Vdi nested functions

  updateAllCompleteLive() {
    //  console.log("update");

    //  debugger
    this.allCompleteLive =
      this.taskLive.subtasksLive != null &&
      this.taskLive.subtasksLive.every((t) => t.completed);
  }

  someCompleteLive(): boolean {
    // console.log("some");

    // debugger
    if (this.taskLive.subtasksLive == null) {
      return false;
    } else {
      return (
        this.taskLive.subtasksLive.filter((t) => t.completed).length > 0 &&
        !this.allCompleteLive
      );
    }

    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAllLive(completed: boolean) {
    this.allCompleteLive = completed;
    if (this.taskLive.subtasksLive == null) {
      return;
    }
    this.taskLive.subtasksLive.forEach((t) => (t.completed = completed));
  }

  //////////////////////////////////////////////////

  // Vdi nested functions

  updateAllCompleteVdi() {
    //  console.log("update");

    //  debugger
    this.allCompleteVdi =
      this.taskVdi.subtasksVdi != null &&
      this.taskVdi.subtasksVdi.every((t) => t.completed);
  }

  someCompleteVdi(): boolean {
    // console.log("some");

    // debugger
    if (this.taskVdi.subtasksVdi == null) {
      return false;
    } else {
      return (
        this.taskVdi.subtasksVdi.filter((t) => t.completed).length > 0 &&
        !this.allCompleteVdi
      );
    }

    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAllVdi(completed: boolean) {
    this.allCompleteVdi = completed;
    if (this.taskVdi.subtasksVdi == null) {
      return;
    }
    this.taskVdi.subtasksVdi.forEach((t) => (t.completed = completed));
  }
  onChangeImages($event) {
    let azureImages =
      $event.target.options[$event.target.options.selectedIndex].text;
  }
  panels = [
    {
      CategoryId: "31b7a227-9fda-4d14-8e1f-1dee5beeccb4",
      Id: "26cfdb68-ef69-4df0-b4dc-5b9c6501b0dd",
      Name: "Dummy deployment",
      checked: false,
      Tests: [
        {
          Id: "e2bb4607-c227-4483-a3e9-55c1bc5a6781",
          Name: "Azure",
          checked: false,
        },
        {
          Id: "e2bb4607-c227-4483-a3e9-55c1bc5a6781",
          Name: "AWS",
          checked: false,
        },
      ],
    },
  ];

  PanelChanged(index: string, event: Event) {
    // debugger;
    // const c=event.target.checked
    const ischecked = (event.target as HTMLInputElement).checked;

    if (ischecked) {
      this.panels[index].Tests.forEach((val) => {
        val.checked = true;
        this.deploymentAWS = true;
        this.deploymentAzure = true;
        // this.checkedEntry.push(this.panels[index])
      });
    }
    if (!ischecked) {
      this.panels[index].Tests.forEach((val) => {
        val.checked = false;
        this.deploymentAWS = false;
        this.deploymentAzure = false;
      });
    }
    // console.log(this.panels);
  }
  PanelChangedd(index: string, event: Event) {
    // debugger;
    // const c=event.target.checked
    const ischecked = (event.target as HTMLInputElement).checked;

    if (ischecked) {
      this.panels[0].Tests[index].checked = true;
      if (index == "0") {
        this.deploymentAzure = true;
        this.panels[0].checked = true;
      } else if (index == "1") {
        this.deploymentAWS = true;
        this.panels[0].checked = true;
      }
    } else {
      this.panels[0].Tests[index].checked = false;
      if (index == "0") {
        this.deploymentAzure = false;
      } else if (index == "1") {
        this.deploymentAWS = false;
      }
    }

    // console.log(this.panels[0].Tests);
  }
  backNavigation() {
    if (
      (this.storyBoard.nameInstance == "" ||
        this.storyBoard.nameInstance == undefined) &&
      (this.storyBoard.description == "" ||
        this.storyBoard.description == undefined) &&
      (this.storyBoard.personaName == "" ||
        this.storyBoard.personaName == undefined) &&
      (this.storyBoard.logicalImageName == "" ||
        this.storyBoard.logicalImageName == undefined) &&
      (this.storyBoard.actualImageName == "" ||
        this.storyBoard.actualImageName == undefined) &&
      (this.storyBoard.privilege == "" ||
        this.storyBoard.privilege == undefined) &&
      (this.storyBoard.azureId == "" || this.storyBoard.azureId == undefined)
    ) {
      this.router.navigate(["/admin-catalog-config"]);
    } else {
      let element: HTMLElement = document.getElementById(
        "CallModel"
      ) as HTMLElement;
      element.click();
    }
  }

  onChangeAzureId($event) {
    // debugger
    let azuretext =
      $event.target.options[$event.target.options.selectedIndex].text;
    // console.log("Azure selected value", azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    // console.log("Split Value ", i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
    this.vdi_Azure_Iddiv = true;
  }

  getAzureIdDetails(azureId) {
    //  debugger
    this.AzuredrpDisable = true;
    this.apiService.Admin_AzureIdDetails(azureId).subscribe(
      (res: any) => {
        //  debugger
        if (res) {
          // console.log(res, "Id details");

          res.forEach((member) => {
            this.vdi_SubId =
              member.tool_instance_details.azure_tool_instance.subscription_id;
            this.vdi_tenantId =
              member.tool_instance_details.azure_tool_instance.tenant_id;
            this.vdi_Azure_Id = member.tool_id;
            this.vdi_Subscription_ID =
              member.tool_instance_details.azure_tool_instance.subscription_id;
            this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
            this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
            this.vdi_Azure_ConnectionName =
              member.tool_instance_details.azure_tool_instance.tool_instance_name;
            if (!this.editMode) {
              this.LoadImage();
            }
          });
        }
      },
      (error: HttpErrorResponse) => {
        //
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        } else if (error.status == 403) {
        } else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    );
  }

  LoadImage() {
    //debugger;

    this.ActualImageArray = [];
    this.apiService
      .Admin_VDI_getDomainImageDetails(this.vdi_Azure_Id)
      .subscribe((data: any) => {
        this.vdiImage = false;
        if (data) {
          var i;
          for (i = 0; i < data.body.length; i++) {
            //
            //  console.log(data.body[i].tool_group_name);
            if (
              data.body[i].tool_instance_details.vdi_tool_instance.name !=
              undefined
            ) {
              //
              this.ActualImageArray.push(data.body[i]);
              this.vdiImage = true;
              this.AzuredrpDisable = false;
            }
          }
        }
        if (this.vdiImage == false) {
          this.showDivAdd = true;

          this.errorMsgAdd = "There is no VDI Images for selected Azure ID";

          setTimeout(() => {
            this.showDivAdd = false;
          }, 3000);
        }
        //res.body.forEach(member => {
        //
        // this.ActualImageArray.push(member.tool_instance_details.vdi_tool_instance.name);
        //})
        //console.log(this.tableResourceArray,"tableResourceArray")
        // this.dataSource.data = this.tableResourceArray as ResourceTable[]
      });
  }

  public seleccionRubrosHideStoryBoard(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.chkHideStoryBoard = false;
      this.HideStoryBoard = false;
      this.DisableStoryBoard = true;
      this.chkStoryBoard = false;
      this.EnableStoryBoard = false;
      this.isCheckStoryBoard = false;
    } else {
      this.HideStoryBoard = true;
      this.DisableStoryBoard = false;
      this.chkStoryBoard = true;
      this.EnableStoryBoard = true;
      this.chkHideStoryBoard = true;
      this.isCheckStoryBoard = true;
    }
  }

  public seleccionRubrosHideSCM(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.HideSCM = false;
      this.DisableSCM = true;
      this.chkSCM = false;
      this.chkHideSCM = false;
      this.EnableSCM = false;
      this.isCheckSCM = false;
    } else {
      this.HideSCM = true;
      this.DisableSCM = false;
      this.chkSCM = true;
      this.EnableSCM = true;
      this.chkHideSCM = true;
      this.isCheckSCM = true;
    }
  }
  public seleccionRubrosHideDevOps(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.HideDevOps = false;
      this.DisableDevOps = true;
      this.chkDevOps = false;
      this.EnableDevOps = false;
      this.chkHideDevOps = false;
      this.isCheckDevOps = false;
    } else {
      this.HideDevOps = true;
      this.DisableDevOps = false;
      this.chkDevOps = true;
      this.EnableDevOps = true;
      this.chkHideDevOps = true;
      this.isCheckDevOps = true;
    }
  }
  public seleccionRubrosHideCollaboration(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.HideCollaboration = false;
      this.DisableCollaboration = true;
      this.chkCollaboration = false;
      this.EnableCollaboration = false;
      this.chkHideCollaboration = false;
      this.isCheckColl = false;
    } else {
      this.HideCollaboration = true;
      this.DisableCollaboration = false;
      this.chkCollaboration = true;
      this.EnableCollaboration = true;
      this.chkHideCollaboration = true;
      this.isCheckColl = true;
    }
  }

  public seleccionRubrosHideVDI(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.HideVDI = false;
      this.DisableVDI = true;
      this.chkVDI = false;
      this.EnableVDI = false;
      this.chkHideVDI = false;
      this.isCheckVDI = false;
    } else {
      this.HideVDI = true;
      this.DisableVDI = false;
      this.chkVDI = true;
      this.EnableVDI = true;
      this.chkHideVDI = true;
      this.isCheckVDI = true;
    }
  }

  public seleccionRubrosHideDeployment(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.HideDeployment = false;
      this.DisableDeployment = true;
      this.chkDeployment = false;
      this.EnableDeployment = false;
      this.chkHideDeployment = false;
      this.isCheckDeploy = false;
    } else {
      this.HideDeployment = true;
      this.DisableDeployment = false;
      this.chkDeployment = true;
      this.EnableDeployment = true;
      this.chkHideDeployment = true;
      this.isCheckDeploy = true;
    }
  }

  public seleccionRubrosHideMonitoring(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.chkHideMonitoring = false;
      this.HideMonitoring = false;
      this.isCheckMonitoring = false;
    } else {
      this.HideMonitoring = true;
      this.chkHideMonitoring = true;
      this.isCheckMonitoring = true;
    }
  }

  public seleccionRubrosHideReports(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.chkHideReports = false;
      this.HideReports = false;
      this.isCheckReports = false;
    } else {
      this.chkHideReports = true;
      this.HideReports = true;
      this.isCheckReports = true;
    }
  }

  // public seleccionRubrosEnbleStoryBoard(event: MatCheckboxChange) {
  //   if (!event.source.checked) {
  //      this.EnableStoryBoard = false;
  //   }
  //   else{
  //     this.EnableStoryBoard = true;

  //   }

  // };

  // public seleccionRubrosEnbleSCM(event: MatCheckboxChange) {
  // if (!event.source.checked) {
  //    this.EnableSCM = false;
  // }
  // else{
  //   this.EnableSCM = true;

  // }

  // };
  // public seleccionRubrosEnbleDevOps(event: MatCheckboxChange) {
  // if (!event.source.checked) {
  //    this.EnableDevOps = false;
  // }
  // else{
  //   this.EnableDevOps = true;

  // }

  // };
  // public seleccionRubrosEnbleCollaboration(event: MatCheckboxChange) {

  // if (!event.source.checked) {
  //    this.EnableCollaboration = false;
  // }
  // else{
  //   this.EnableCollaboration = true;

  // }

  // };

  // public seleccionRubrosEnbleVDI(event: MatCheckboxChange) {
  // if (!event.source.checked) {
  //    this.EnableVDI = false;
  // }
  // else{
  //   this.EnableVDI = true;

  // }

  // };

  // public seleccionRubrosEnbleDeployment(event: MatCheckboxChange) {

  // if (!event.source.checked) {
  //    this.EnableDeployment = false;
  // }
  // else{
  //   this.EnableDeployment = true;

  // }

  // };
  addRole() {
    // debugger
    var check = 0;
    let spChars = /[!^+$@#%&*()_\=\-\{};':"\\|,.<>\/?123456790]+/;
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/? ]+/;

    if (spChars.test(this.storyBoard.personaName)) {
      this.flags = true;

      this.flagmsgs = "Persona name must contain only alphabets";
      setTimeout(() => {
        this.flags = false;
        // check=1;
      }, 3000);
      return;
    } else if (sp.test(this.storyBoard.logicalImageName)) {
      this.flagl = true;

      this.flagmsgl =
        "Logical name must include only alphabets ,numbers and _,-";
      setTimeout(() => {
        this.flagl = false;
        // check=1;
      }, 3000);
      return;
    }
    for (let i = 0; i < this.roleArray.length; i++) {
      if (this.roleArray[i].new_template_role_name != undefined) {
        var templateRoleName = this.roleArray[i].new_template_role_name;
      } else {
        var templateRoleName = this.roleArray[i].template_role_name;
      }
      if (this.roleArray[i].new_role_local_image != undefined) {
        var roleLocalImage = this.roleArray[i].new_role_local_image;
      } else {
        var roleLocalImage = this.roleArray[i].role_local_image;
      }

      if (
        templateRoleName.toLowerCase() ==
          this.storyBoard.personaName.toLowerCase() ||
        roleLocalImage.toLowerCase() ==
          this.storyBoard.logicalImageName.toLowerCase()
      ) {
        this.flag = true;
        check = 1;
        this.flagmsg = "Duplicate Persona/Logical not allowed";
        setTimeout(() => {
          this.flag = false;
          check = 1;
        }, 3000);
      }
      // else{
      //   check=0;
      // }
    }

    if (check == 0) {
      if (
        this.storyBoard.actualImageName == "" ||
        this.storyBoard.personaName == "" ||
        this.storyBoard.logicalImageName == "" ||
        this.storyBoard.privilege == "" ||
        this.storyBoard.actualImageName == undefined ||
        this.storyBoard.personaName == undefined ||
        this.storyBoard.logicalImageName == undefined ||
        this.storyBoard.privilege == undefined
      ) {
        this.showDivAdd = true;
        this.errorMsgAdd = "Please enter details In all the field for add role";
        setTimeout(() => {
          this.showDivAdd = false;
        }, 3000);
        return;
      } else {
        if (this.roleArray.length > 0) {
          if (
            this.storyBoard.privilege == "Primary Group Owner" ||
            this.storyBoard.privilege == "Secondary Group Owner"
          ) {
            // debugger

            for (var s = 0; s < this.roleArray.length; s++) {
              var obj = this.roleArray[s];
              var definedRoleName;
              var roleName = "";
              if (obj.new_defined_role_name != undefined) {
                definedRoleName = obj.new_defined_role_name;
              } else {
                definedRoleName = obj.defined_role_name;
              }
              ////////////////////
              for (let j = 0; j < definedRoleName.length; j++) {
                if (definedRoleName[j].charCodeAt(0) != 160) {
                  roleName = roleName + definedRoleName[j];
                }
              }

              // str = str.replace(/ +/g, "");
              roleName = roleName.replace(/ +/g, "");
              var privellegeStoryBoard = this.storyBoard.privilege.replace(
                / +/g,
                ""
              );

              if (privellegeStoryBoard == roleName) {
                if (this.roleArray[s].new_template_role_name != undefined) {
                  this.temprole_name = this.roleArray[s].new_template_role_name;
                  this.temprole_local_image =
                    this.roleArray[s].new_role_local_image;
                  this.tempazure_image = this.roleArray[s].azure_image;
                  this.tempazure_id = this.roleArray[s].azure_id;
                  this.tempvdi_tool_id = this.roleArray[s].vdi_tool_id;
                  this.tempvdi_type = this.roleArray[s].vdi_type;
                  this.tempprivilege_access =
                    this.roleArray[s].new_defined_role_name;
                  this.tempRoleName = this.roleArray[s].new_role_name;
                } else {
                  this.temprole_name = this.roleArray[s].template_role_name;
                  this.temprole_local_image =
                    this.roleArray[s].role_local_image;
                  this.tempazure_image = this.roleArray[s].azure_image;
                  this.tempazure_id = this.roleArray[s].azure_id;
                  this.tempvdi_tool_id = this.roleArray[s].vdi_tool_id;
                  this.tempvdi_type = this.roleArray[s].vdi_type;
                  this.tempprivilege_access =
                    this.roleArray[s].defined_role_name;
                  this.tempRoleName = this.roleArray[s].role_name;
                }

                //this.tempDB_role_name=this.roleArray[s].DB_role_name;

                //  this.Message=this.storyBoard.privilege+" is already added to Pesona Name "+ this.temprole_name+". Want to create again?"
                this.Message =
                  this.storyBoard.privilege +
                  " is already added to Persona Name " +
                  this.temprole_name +
                  ". Do you want to override the existing role ?";
                let element: HTMLElement = document.getElementById(
                  "CallModel1"
                ) as HTMLElement;
                element.click();

                return;
              }
            }
            if (this.storyBoard.privilege == "Primary Group Owner") {
              this.DB_role_name = "Project Manager";
            }
            if (this.storyBoard.privilege == "Secondary Group Owner") {
              this.DB_role_name = "Secondary Project Manager";
            }
            if (this.storyBoard.privilege == "Group Member") {
              this.DB_role_name = "Team Member";
            }

            for (var i = 0; i < this.ActualImageArray.length; i++) {
              if (
                this.storyBoard.actualImageName ==
                this.ActualImageArray[i].tool_instance_details.vdi_tool_instance
                  .name
              ) {
                this.storyBoard.actualImageName =
                  this.ActualImageArray[
                    i
                  ].tool_instance_details.vdi_tool_instance.name;
                this.vdi_tool_id = this.ActualImageArray[i].tool_id;
                this.vdi_type =
                  this.ActualImageArray[
                    i
                  ].tool_instance_details.vdi_tool_instance.vdi_type;
              }
            }
            if (this.editMode == false) {
              let tempOb = {
                template_role_name: this.storyBoard.personaName,
                role_local_image: this.storyBoard.logicalImageName,
                azure_image: this.storyBoard.actualImageName,
                azure_id: this.vdi_Azure_Id,
                defined_role_name: this.storyBoard.privilege,
                role_name: this.DB_role_name,
                vdi_tool_id: this.vdi_tool_id,
                vdi_type: this.vdi_type,
              };

              this.roleArray.push(tempOb);
            } else {
              let tempOb = {
                template_role_name: this.storyBoard.personaName,
                role_local_image: this.storyBoard.logicalImageName,
                azure_image: this.storyBoard.actualImageName,
                azure_id: this.vdi_Azure_Id,
                defined_role_name: this.storyBoard.privilege,
                role_name: this.DB_role_name,
                vdi_tool_id: this.vdi_tool_id,
                vdi_type: this.vdi_type,
                new_role_flag: true,
                update_role_flag: false,
                delete_role_flag: false,
                new_template_role_name: this.storyBoard.personaName,
                new_role_local_image: this.storyBoard.logicalImageName,
                new_defined_role_name: this.storyBoard.privilege,
                new_role_name: this.DB_role_name,
              };

              this.roleArray.push(tempOb);
            }

            // this.persona.push(this.storyBoard.personaName)
            // this.logical.push(this.storyBoard.logicalImageName)
          } else {
            if (this.storyBoard.privilege == "Primary Group Owner") {
              this.DB_role_name = "Project Manager";
            }
            if (this.storyBoard.privilege == "Secondary Group Owner") {
              this.DB_role_name = "Secondary Project Manager";
            }
            if (this.storyBoard.privilege == "Group Member") {
              this.DB_role_name = "Team Member";
            }

            for (var i = 0; i < this.ActualImageArray.length; i++) {
              if (
                this.storyBoard.actualImageName ==
                this.ActualImageArray[i].tool_instance_details.vdi_tool_instance
                  .name
              ) {
                this.storyBoard.actualImageName =
                  this.ActualImageArray[
                    i
                  ].tool_instance_details.vdi_tool_instance.name;
                this.vdi_tool_id = this.ActualImageArray[i].tool_id;
                this.vdi_type =
                  this.ActualImageArray[
                    i
                  ].tool_instance_details.vdi_tool_instance.vdi_type;
              }
            }
            if (this.editMode == false) {
              let tempOb = {
                template_role_name: this.storyBoard.personaName,
                role_local_image: this.storyBoard.logicalImageName,
                azure_image: this.storyBoard.actualImageName,
                azure_id: this.vdi_Azure_Id,
                defined_role_name: this.storyBoard.privilege,
                role_name: this.DB_role_name,
                vdi_tool_id: this.vdi_tool_id,
                vdi_type: this.vdi_type,
              };

              this.roleArray.push(tempOb);
            } else {
              let tempOb = {
                template_role_name: this.storyBoard.personaName,
                role_local_image: this.storyBoard.logicalImageName,
                azure_image: this.storyBoard.actualImageName,
                azure_id: this.vdi_Azure_Id,
                defined_role_name: this.storyBoard.privilege,
                role_name: this.DB_role_name,
                vdi_tool_id: this.vdi_tool_id,
                vdi_type: this.vdi_type,
                new_role_flag: true,
                update_role_flag: false,
                delete_role_flag: false,
                new_template_role_name: this.storyBoard.personaName,
                new_role_local_image: this.storyBoard.logicalImageName,
                new_defined_role_name: this.storyBoard.privilege,
                new_role_name: this.DB_role_name,
              };

              this.roleArray.push(tempOb);
            }

            // this.persona.push(this.storyBoard.personaName)
            // this.logical.push(this.storyBoard.logicalImageName)
          }
        } else {
          if (this.storyBoard.privilege == "Primary Group Owner") {
            this.DB_role_name = "Project Manager";
          }
          if (this.storyBoard.privilege == "Secondary Group Owner") {
            this.DB_role_name = "Secondary Project Manager";
          }
          if (this.storyBoard.privilege == "Group Member") {
            this.DB_role_name = "Team Member";
          }

          for (var i = 0; i < this.ActualImageArray.length; i++) {
            if (
              this.storyBoard.actualImageName ==
              this.ActualImageArray[i].tool_instance_details.vdi_tool_instance
                .name
            ) {
              this.storyBoard.actualImageName =
                this.ActualImageArray[
                  i
                ].tool_instance_details.vdi_tool_instance.name;
              this.vdi_tool_id = this.ActualImageArray[i].tool_id;
              this.vdi_type =
                this.ActualImageArray[
                  i
                ].tool_instance_details.vdi_tool_instance.vdi_type;
            }
          }

          if (this.editMode == false) {
            let tempOb = {
              template_role_name: this.storyBoard.personaName,
              role_local_image: this.storyBoard.logicalImageName,
              azure_image: this.storyBoard.actualImageName,
              azure_id: this.vdi_Azure_Id,
              defined_role_name: this.storyBoard.privilege,
              role_name: this.DB_role_name,
              vdi_tool_id: this.vdi_tool_id,
              vdi_type: this.vdi_type,
            };

            this.roleArray.push(tempOb);
          } else {
            let tempOb = {
              template_role_name: this.storyBoard.personaName,
              role_local_image: this.storyBoard.logicalImageName,
              azure_image: this.storyBoard.actualImageName,
              azure_id: this.vdi_Azure_Id,
              defined_role_name: this.storyBoard.privilege,
              role_name: this.DB_role_name,
              vdi_tool_id: this.vdi_tool_id,
              vdi_type: this.vdi_type,
              new_role_flag: true,
              update_role_flag: false,
              delete_role_flag: false,
              new_template_role_name: this.storyBoard.personaName,
              new_role_local_image: this.storyBoard.logicalImageName,
              new_defined_role_name: this.storyBoard.privilege,
              new_role_name: this.DB_role_name,
            };

            this.roleArray.push(tempOb);
          }

          // this.persona.push(this.storyBoard.personaName)
          // this.logical.push(this.storyBoard.logicalImageName)
        }

        this.storyBoard.actualImageName = "";
        this.storyBoard.personaName = "";
        this.storyBoard.logicalImageName = "";
        //this.storyBoard.azureId="";
        this.storyBoard.privilege = "";
        // this.ActualImageArray=[];
        //this.vdi_Azure_Iddiv=false;
        this.AzuredrpDisable = false;
      }
      if (this.roleArray.length == 0) {
        this.alertService.error(
          "Please add and select for added Role",
          this.options
        );
        this.showDiv = true;
        this.errorMsg = "Please add and select for added Role";
        setTimeout(() => {
          this.showDiv = false;
        }, 3000);
      } else {
        this.disableAzure = true;
      }
    }
  }
  UpdateRole() {}
  addUpdatedRole() {
    // console.log(this.roleArray);

    var check = 0;
    let spChars = /[!^+$@#%&*()_\=\-\{};':"\\|,.<>\/?123456790]+/;
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/? ]+/;

    if (spChars.test(this.storyBoard.personaName)) {
      // console.log("hi");
      this.flags = true;

      this.flagmsgs = "Persona name must contain only alphabets";
      setTimeout(() => {
        this.flags = false;
        // check=1;
      }, 3000);
      return;
    } else if (sp.test(this.storyBoard.logicalImageName)) {
      this.flagl = true;

      this.flagmsgl =
        "Logical name must include only alphabets ,numbers and _,-";
      setTimeout(() => {
        this.flagl = false;
        // check=1;
      }, 3000);
      return;
    }

    for (let i = 0; i < this.roleArray.length; i++) {
      if (this.editIndex != i) {
        if (this.roleArray[i].new_template_role_name != undefined) {
          var templateRoleName = this.roleArray[i].new_template_role_name;
        } else {
          var templateRoleName = this.roleArray[i].template_role_name;
        }
        if (this.roleArray[i].new_role_local_image != undefined) {
          var roleLocalImage = this.roleArray[i].new_role_local_image;
        } else {
          var roleLocalImage = this.roleArray[i].role_local_image;
        }

        if (
          templateRoleName.toLowerCase() ==
            this.storyBoard.personaName.toLowerCase() ||
          roleLocalImage.toLowerCase() ==
            this.storyBoard.logicalImageName.toLowerCase()
        ) {
          //console.log("hi");
          this.flag = true;
          check = 1;
          this.flagmsg = "Duplicate Persona/Logical not allowed";
          setTimeout(() => {
            this.flag = false;
            check = 1;
          }, 3000);
        }
        // else{
        //   check=0;
        // }
      }
    }
    if (check == 0) {
      let role = this.editRoleDetails;

      // this.roleArray.splice(this.editIndex,1);
      // console.log(this.roleArray);
      // console.log(this.storyBoard);
      for (let i = 0; i < this.ActualImageArray.length; i++) {
        // let var8= this.ActualImageArray[i].replace(/ +/g, "");
        if (
          this.ActualImageArray[i].tool_instance_details.vdi_tool_instance
            .name === this.storyBoard.actualImageName
        ) {
          // console.log(i);
          this.azureImageIndex = i;
          // this.storyBoard.actualImageName=this.ActualImageArray[i].tool_instance_details.vdi_tool_instance.name
        }
      }
      // debugger
      // privilegeArray=['Group Member','Primary Group Owner','Secondary Group Owner'];

      if (this.storyBoard.privilege == "Group Member") {
        var newRoleName = this.RoleNameArrayPrivellege[0];
      }
      if (this.storyBoard.privilege == "Primary Group Owner") {
        var newRoleName = this.RoleNameArrayPrivellege[1];
      }
      if (this.storyBoard.privilege == "Secondary Group Owner") {
        var newRoleName = this.RoleNameArrayPrivellege[2];
      }

      let body = {
        template_role_name: role.template_role_name,
        role_local_image: role.role_local_image,
        azure_image: this.storyBoard.actualImageName,
        azure_id: role.azure_id,
        defined_role_name: role.defined_role_name,
        role_name: role.role_name,
        vdi_tool_id: this.ActualImageArray[this.azureImageIndex].tool_id,
        vdi_type:
          this.ActualImageArray[this.azureImageIndex].tool_instance_details
            .vdi_tool_instance.vdi_type,
        new_role_flag: false,
        update_role_flag: true,
        delete_role_flag: false,
        new_template_role_name: this.storyBoard.personaName,
        new_role_local_image: this.storyBoard.logicalImageName,
        new_defined_role_name: this.storyBoard.privilege,
        new_role_name: newRoleName,
      };

      if (this.roleArray.length > 0) {
        // debugger
        var flagDefinedRoleName = false;

        for (let i = 0; i < this.roleArray.length; i++) {
          var obj = this.roleArray[i];
          var definedRoleName;
          var roleName = "";
          if (obj.new_defined_role_name != undefined) {
            definedRoleName = obj.new_defined_role_name;
          } else {
            definedRoleName = obj.defined_role_name;
          }
          ////////////////////
          for (let j = 0; j < definedRoleName.length; j++) {
            if (definedRoleName[j].charCodeAt(0) != 160) {
              roleName = roleName + definedRoleName[j];
            }
          }

          // str = str.replace(/ +/g, "");
          roleName = roleName.replace(/ +/g, "");

          ////////////////

          if (roleName == "SecondaryGroupOwner") {
            flagDefinedRoleName = true;
            var secondaryIndex = i;
          }
        }
      }

      if (
        flagDefinedRoleName == true &&
        this.storyBoard.privilege == "Secondary Group Owner"
      ) {
        if (this.roleArray[this.editIndex].new_role_name != undefined) {
          var checkRoleName = this.roleArray[this.editIndex].new_role_name;
        } else {
          var checkRoleName = this.roleArray[this.editIndex].role_name;
        }
        // var g=checkRoleName.replace(/\&nbsp;/g, '');

        // console.log(checkRoleName == this.RoleNameArrayPrivellege[2]);
        // console.log(checkRoleName);
        // console.log(this.RoleNameArrayPrivellege[2]);
        var checkRoleNameUpdate = "";
        for (let j = 0; j < checkRoleName.length; j++) {
          // console.log(role.defined_role_name[i].charCodeAt(0),role.defined_role_name[i]);

          if (checkRoleName[j].charCodeAt(0) != 160) {
            checkRoleNameUpdate = checkRoleNameUpdate + checkRoleName[j];
          }
        }
        checkRoleNameUpdate = checkRoleNameUpdate.replace(/ +/g, "");

        if (checkRoleNameUpdate != "SecondaryProjectManager") {
          this.Message =
            this.storyBoard.privilege +
            " is already added to Persona Name " +
            this.temprole_name +
            ". Do you want to override the existing role ?";
          let element: HTMLElement = document.getElementById(
            "CallModelUpdateRole"
          ) as HTMLElement;
          element.click();
          this.tempUpdateArray.push(body);

          this.tempUpdateArray.push(secondaryIndex);

          return;
        }
      }

      // console.log(body);
      this.roleArray.splice(this.editIndex, 1);

      this.roleArray.push(body);
      this.storyBoard.actualImageName = "";
      this.storyBoard.personaName = "";
      this.storyBoard.logicalImageName = "";
      //this.storyBoard.azureId="";
      this.storyBoard.privilege = "";
      this.roleEditFlag = false;
      this.privellegeDisable = false;
      this.editIndex = undefined;

      // this.storyBoard.personaName
    }
  }
  clearUpdatedRole() {
    this.storyBoard.actualImageName = "";
    this.storyBoard.personaName = "";
    this.storyBoard.logicalImageName = "";
    //this.storyBoard.azureId="";
    this.storyBoard.privilege = "";
    this.roleEditFlag = false;
    this.privellegeDisable = false;
    this.editIndex = undefined;
  }
  UpdateTemplateRole() {
    // console.log(this.roleArray);
    // console.log(this.tempUpdateArray);
    var index = this.tempUpdateArray[1];
    // console.log(this.roleArray[index]);
    if (this.roleArray[index].new_template_role_name != undefined) {
      var newTemplateRoleName = this.roleArray[index].new_template_role_name;
    } else {
      var newTemplateRoleName = this.roleArray[index].template_role_name;
    }
    if (this.roleArray[index].new_role_local_image != undefined) {
      var newLocalRoleName = this.roleArray[index].new_role_local_image;
    } else {
      var newLocalRoleName = this.roleArray[index].role_local_image;
    }

    // this.roleArray.splice(this.editIndex,1);
    // this.roleArray.push(this.tempUpdateArray[0])

    var body;
    if (this.editMode == false) {
      body = {
        template_role_name: newTemplateRoleName,
        role_local_image: newLocalRoleName,
        azure_image: this.roleArray[index].azure_image,
        azure_id: this.roleArray[index].azure_id,
        defined_role_name: this.privilegeArray[0],
        role_name: "Team Member",
        vdi_tool_id: this.roleArray[index].vdi_tool_id,
        vdi_type: this.roleArray[index].vdi_type,
      };
    } else {
      body = {
        template_role_name: this.roleArray[index].template_role_name,
        role_local_image: this.roleArray[index].role_local_image,
        azure_image: this.roleArray[index].azure_image,
        azure_id: this.roleArray[index].azure_id,
        defined_role_name: this.roleArray[index].defined_role_name,
        role_name: this.roleArray[index].role_name,
        vdi_tool_id: this.roleArray[index].vdi_tool_id,
        vdi_type: this.roleArray[index].vdi_type,
        new_role_flag: false,
        update_role_flag: true,
        delete_role_flag: false,
        new_template_role_name: newTemplateRoleName,
        new_role_local_image: newLocalRoleName,
        new_defined_role_name: this.privilegeArray[0],
        new_role_name: "Team Member",
      };
    }

    this.roleArray.splice(this.editIndex, 1);
    for (let i = 0; i < this.roleArray.length; i++) {
      let obj = this.roleArray[i];

      if (this.roleArray[i].new_role_name != undefined) {
        var checkRoleName = this.roleArray[i].new_role_name;
      } else {
        var checkRoleName = this.roleArray[i].role_name;
      }

      var checkRoleNameUpdate = "";
      for (let j = 0; j < checkRoleName.length; j++) {
        // console.log(role.defined_role_name[i].charCodeAt(0),role.defined_role_name[i]);

        if (checkRoleName[j].charCodeAt(0) != 160) {
          checkRoleNameUpdate = checkRoleNameUpdate + checkRoleName[j];
        }
      }
      checkRoleNameUpdate = checkRoleNameUpdate.replace(/ +/g, "");

      if (checkRoleNameUpdate == "SecondaryProjectManager") {
        var deleteIndex = i;
      }
    }

    this.roleArray.splice(deleteIndex, 1);

    this.roleArray.push(body);
    this.roleArray.push(this.tempUpdateArray[0]);
    this.storyBoard.actualImageName = "";
    this.storyBoard.personaName = "";
    this.storyBoard.logicalImageName = "";
    //this.storyBoard.azureId="";
    this.storyBoard.privilege = "";
    this.roleEditFlag = false;
    this.privellegeDisable = false;
    this.tempUpdateArray = [];

    // this.roleArray[index].
  }
  editRole(role, index) {
    // console.log(role,"detailssssssssss");
    // console.log(role.new_template_role_name,"gsjslk");

    this.editIndex = index;
    this.editRoleDetails = role;
    this.privilegeArray = [
      "Group Member",
      "Primary Group Owner",
      "Secondary Group Owner",
    ];

    this.roleEditFlag = true;
    this.privellegeDisable = false;

    // this.storyBoard.actualImageName="hhhh"
    // console.log(this.ActualImageArray);

    // debugger
    // console.log(role);
    // debugger
    if (role.new_template_role_name != undefined) {
      this.storyBoard.personaName = role.new_template_role_name;
    }
    if (role.new_role_local_image != undefined) {
      this.storyBoard.logicalImageName = role.new_role_local_image;
    } else {
      (this.storyBoard.personaName = role.template_role_name),
        (this.storyBoard.logicalImageName = role.role_local_image);
    }

    // const var1=this.privilegeArray[0]

    let roleName = "";
    if (role.new_defined_role_name != undefined) {
      this.roleVariable = role.new_defined_role_name;
    } else {
      this.roleVariable = role.defined_role_name;
    }
    for (let i = 0; i < this.roleVariable.length; i++) {
      // console.log(role.defined_role_name[i].charCodeAt(0),role.defined_role_name[i]);

      if (this.roleVariable[i].charCodeAt(0) != 160) {
        roleName = roleName + this.roleVariable[i];
      }
    }

    // str = str.replace(/ +/g, "");
    roleName = roleName.replace(/ +/g, "");
    for (let i = 0; i < this.privilegeArray.length; i++) {
      let var2 = this.privilegeArray[i].replace(/ +/g, "");
      if (var2 === roleName) {
        // console.log(i);

        this.storyBoard.privilege = this.privilegeArray[i];

        if (i == 1) {
          this.privellegeDisable = true;
        }
        if (i == 0) {
          let primaryFlag = false;

          for (let i = 0; i < this.roleArray.length; i++) {
            let name = "";
            if (this.roleArray[i].new_role_name) {
              name = this.roleArray[i].new_role_name;
            } else {
              name = this.roleArray[i].role_name;
            }

            name = name.replace(/\u00a0/g, " ");
            name = name.replace(/\s/g, "");
            // for (let i=0;i<name.length;i++) {
            //   console.log(name[i],name[i].charCodeAt(0));

            // }

            if (name == "ProjectManager") {
              primaryFlag = true;
            }
          }
          if (primaryFlag) {
            this.privilegeArray = ["Group Member", "Secondary Group Owner"];
            this.storyBoard.privilege = this.privilegeArray[0];
          } else {
            this.privilegeArray = [
              "Group Member",
              "Primary Group Owner",
              "Secondary Group Owner",
            ];
            this.storyBoard.privilege = this.privilegeArray[0];
          }
        }
        if (i == 2) {
          let secFlag = false;

          for (let i = 0; i < this.roleArray.length; i++) {
            let name = "";
            if (this.roleArray[i].new_role_name) {
              name = this.roleArray[i].new_role_name;
            } else {
              name = this.roleArray[i].role_name;
            }
            name = name.replace(/\u00a0/g, " ");
            name = name.replace(/\s/g, "");
            if (name == "ProjectManager") {
              secFlag = true;
            }
          }
          if (secFlag) {
            this.privilegeArray = ["Group Member", "Secondary Group Owner"];
            this.storyBoard.privilege = this.privilegeArray[1];
          } else {
            this.privilegeArray = [
              "Group Member",
              "Primary Group Owner",
              "Secondary Group Owner",
            ];
            this.storyBoard.privilege = this.privilegeArray[2];
          }

          // this.privilegeArray=['Group Member','Secondary Group Owner'];
          // this.storyBoard.privilege=this.privilegeArray[1]
        }
      }
    }
    // debugger
    // console.log(this.storyBoard);

    for (let i = 0; i < this.ActualImageArray.length; i++) {
      // let var8= this.ActualImageArray[i].replace(/ +/g, "");
      if (
        this.ActualImageArray[i].tool_instance_details.vdi_tool_instance
          .name === role.azure_image
      ) {
        // console.log(i);
        this.azureImageIndex = i;
        this.storyBoard.actualImageName =
          this.ActualImageArray[i].tool_instance_details.vdi_tool_instance.name;
      }
    }
    // this.storyBoard.actualImageName=this.ActualImageArray[1].tool_instance_details.vdi_tool_instance.name
    // console.log(this.storyBoard.actualImageName);
    // this.addUpdatedRole(role,index)
    // let body={

    //     "template_role_name": role.template_role_name,
    //     "role_local_image": role.role_local_image,
    //     "azure_image": this.storyBoard.actualImageName,
    //     "azure_id": role.azure_id,
    //     "defined_role_name": role.defined_role_name,
    //     "role_name": role.role_name,
    //     "vdi_tool_id":this.ActualImageArray[this.azureImageIndex].tool_id,
    //     "vdi_type": this.ActualImageArray[this.azureImageIndex].tool_instance_details.vdi_type,
    //     "new_role_flag": false,
    //     "update_role_flag": true,
    //     "delete_role_flag": false,
    //     "new_template_role_name": this.storyBoard.personaName,
    //     "new_role_local_image": this.storyBoard.logicalImageName,
    //     "new_defined_role_name": this.storyBoard.privilege,
    //     "new_role_name": "Team Member"

    // }
    // this.roleArray.push(body)

    // console.log();

    // this.storyBoard.privilegeArray=this.privilegeArray[2]

    // this.storyBoard.actualImageName=role.azure_image,

    // this.storyBoard.privilege="role.defined_role_name"
  }
  AddTempleteRole() {
    for (var s = 0; s < this.roleArray.length; s++) {
      var obj = this.roleArray[s];
      var definedRoleName;
      var roleName = "";
      if (obj.new_defined_role_name != undefined) {
        definedRoleName = obj.new_defined_role_name;
      } else {
        definedRoleName = obj.defined_role_name;
      }
      ////////////////////
      for (let j = 0; j < definedRoleName.length; j++) {
        if (definedRoleName[j].charCodeAt(0) != 160) {
          roleName = roleName + definedRoleName[j];
        }
      }

      // str = str.replace(/ +/g, "");
      roleName = roleName.replace(/ +/g, "");
      var privellegeStoryBoard = this.storyBoard.privilege.replace(/ +/g, "");

      if (roleName == privellegeStoryBoard) {
        this.roleArray.splice(s, 1);
      }
    }
    if (this.editMode == false) {
      let tempOb1 = {
        template_role_name: this.temprole_name,
        role_local_image: this.temprole_local_image,
        azure_image: this.tempazure_image,
        azure_id: this.tempazure_id,
        defined_role_name: "Group Member",
        role_name: "Team Member",
        vdi_tool_id: this.tempvdi_tool_id,
        vdi_type: this.tempvdi_type,
      };
      this.roleArray.push(tempOb1);
    } else {
      let tempOb1 = {
        template_role_name: this.temprole_name,
        role_local_image: this.temprole_local_image,
        azure_image: this.tempazure_image,
        azure_id: this.tempazure_id,
        defined_role_name: this.tempprivilege_access,
        role_name: this.tempRoleName,
        vdi_tool_id: this.tempvdi_tool_id,
        vdi_type: this.tempvdi_type,
        new_role_flag: false,
        update_role_flag: true,
        delete_role_flag: false,
        new_template_role_name: this.temprole_name,
        new_role_local_image: this.temprole_local_image,
        new_defined_role_name: "Group Member",
        new_role_name: "Team Member",
      };
      this.roleArray.push(tempOb1);
    }

    if (this.storyBoard.privilege == "Primary Group Owner") {
      this.DB_role_name = "Project Manager";
    }
    if (this.storyBoard.privilege == "Secondary Group Owner") {
      this.DB_role_name = "Secondary Project Manager";
    }
    if (this.storyBoard.privilege == "Group Member") {
      this.DB_role_name = "Team Member";
    }

    for (var i = 0; i < this.ActualImageArray.length; i++) {
      if (
        this.storyBoard.actualImageName ==
        this.ActualImageArray[i].tool_instance_details.vdi_tool_instance.name
      ) {
        this.storyBoard.actualImageName =
          this.ActualImageArray[i].tool_instance_details.vdi_tool_instance.name;
        this.vdi_tool_id = this.ActualImageArray[i].tool_id;
        this.vdi_type =
          this.ActualImageArray[
            i
          ].tool_instance_details.vdi_tool_instance.vdi_type;
      }
    }
    if (this.editMode == false) {
      let tempOb = {
        template_role_name: this.storyBoard.personaName,
        role_local_image: this.storyBoard.logicalImageName,
        azure_image: this.storyBoard.actualImageName,
        azure_id: this.vdi_Azure_Id,
        defined_role_name: this.storyBoard.privilege,
        role_name: this.DB_role_name,
        vdi_tool_id: this.vdi_tool_id,
        vdi_type: this.vdi_type,
      };
      this.roleArray.push(tempOb);
    } else {
      let tempOb = {
        template_role_name: this.storyBoard.personaName,
        role_local_image: this.storyBoard.logicalImageName,
        azure_image: this.storyBoard.actualImageName,
        azure_id: this.vdi_Azure_Id,
        defined_role_name: this.storyBoard.privilege,
        role_name: this.DB_role_name,
        vdi_tool_id: this.vdi_tool_id,
        vdi_type: this.vdi_type,
        new_role_flag: true,
        update_role_flag: false,
        delete_role_flag: false,
        new_template_role_name: this.storyBoard.personaName,
        new_role_local_image: this.storyBoard.logicalImageName,
        new_defined_role_name: this.storyBoard.privilege,
        new_role_name: this.DB_role_name,
      };
      this.roleArray.push(tempOb);
    }

    this.storyBoard.actualImageName = "";
    this.storyBoard.personaName = "";
    this.storyBoard.logicalImageName = "";
    //this.storyBoard.azureId="";
    this.storyBoard.privilege = "";
    // this.ActualImageArray=[];
    //this.vdi_Azure_Iddiv=false;
    this.AzuredrpDisable = false;
  }
  public seleccionRubros(event: MatCheckboxChange) {
    if (!event.source.checked) {
      this.subRubrosSelec = [];
    } else {
      this.subRubrosSelec = [event.source.id];
    }
  }

  refreshDomain() {
    localStorage.setItem("refreshJira", "true");
    this.router
      .navigateByUrl("/admin-story-board", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["/admin-projectTemplate"]);
      });
  }
  deleteRecord() {}
  // DeleteConfig(){

  // }
  // deleteRecord(domain){
  //   console.log(domain);
  //   console.log(domain.template_id);
  //   this.VdiConfig_details=[];
  //   var SelectedItem=domain.template_id
  //   let VdiConfigBoby={
  //      "vdi_dropdown_id":SelectedItem,
  //      }
  //     this.VdiConfig_details.push(VdiConfigBoby);

  //   console.log(this.VdiConfig_details);

  // }

  DeleteConfig() {
    // debugger
    var deleteBody: any;
    this.check = [];
    this.VdiConfig_details.forEach((element) => {
      this.check.push(element.vdi_dropdown_id);
    });

    //console.log(this.check);

    deleteBody = {
      template_ids: this.check,
    };

    this.apiService.Admin_TemplateDeleteData(deleteBody).subscribe(
      (res: any) => {
        if (res.status == 200) {
          //doesnt work
          // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['/admin-projectTemplate']);
          // });
          this.refreshDomain();
          //this.alertService.success("Data Delete Successfully", this.options);
          this.alertService.success(res.body.message, this.options);
        }
      },
      (error: HttpErrorResponse) => {
        //

        if (error.status == 446) {
          //doesnt work
          this.alertService.error(error.error.message, this.options);
        } else if (error.status == 403) {
        }
        if (error.error.code == "02") {
          this.router
            .navigateByUrl("/admin-scm-tool", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/admin-projectTemplate"]);
            });
          this.alertService.error(error.error.msg, this.options);
          return;
        } else {
          this.router
            .navigateByUrl("/admin-scm-tool", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/admin-projectTemplate"]);
            });

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    );
  }

  onChange($event) {
    let jiraToolGrName =
      $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGroupName = jiraToolGrName;
    //console.log("scmToolGrName",jiraToolGrName);
  }

  GetDomaintableDetails() {
    this.spinner.show();

    this.masterDomainTemple = [];
    this.apiService.Admin_Domain_ProjectTableDetails().subscribe(
      (res: any) => {
        this.masterDomainTemple = res;

        res.forEach((member) => {
          let obj = {
            // "functional_area": member.functional_area,
            template_description: member.template_description,
            template_id: member.template_id,
            template_name: member.template_name,
            _id: member._id,
            // "email": member.tool_instance_details.storyboard_tool_instance.tool_auth.auth_mail,
            //"name": member.tool_instance_details.storyboard_tool_instance.tool_instance_name,
            //"description": member.tool_instance_details.storyboard_tool_instance.tool_description,
            //"projectURL": member.tool_instance_details.storyboard_tool_instance.tool_url,
            //"toolGroup": member.tool_group_details.tool_group_name,
            //"toolGroup_id": member.tool_group_details.tool_group_id
          };
          this.domainArray.push(obj);
        });
        // console.log(this.StoryBoardArray)

        this.dtTrigger.next();
        this.spinner.hide();
      },
      (error: HttpErrorResponse) => {
        //

        if (error.status == 436) {
          //doesnt work
          this.alertService.error(error.error.message, this.options);
        } else if (error.status == 403) {
        } else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    );
    // SCMArray
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showJiraPage() {
    //this.dialogCheck = false;
    this.jiraPage = true;
  }

  CreateTemplateCancel() {
    this.hideJira();
    this.TablePage = true;
    this.refreshDomain();
  }
  cancelok() {
    this.clearAllSCMDetails();

    if (this.nextUrl == undefined) {
      this.refreshDomain();
    } else {
      this.router.navigate([this.nextUrl]);
    }
  }
  CreateTemplateReset() {
    //this.clearAllSCMDetails();
    this.CreateTemplateCancel();
    this.addDomain();
  }

  hideJira() {
    this.addDomainDiv = false;
    this.editMode = false;

    this.testConnDisable = false;
    this.saveConnDisable = true;
    this.UpdateConnDisable = true;
  }

  addDomain() {
    this.clearAllSCMDetails();
    this.addDomainDiv = true;
    this.storyBoard.azureId = "";
    this.getSubscriptionList();
    this.TablePage = false;
    // this.ToolGroupDisable=false;
    //this.ToolInstanceNameDisable = false;
  }

  getRecord(row, key) {
    // console.log(this.storyBoard);

    if (key == "delete") {
      this.deletebutton = true;
      this.deleteMode = true;
    } else {
      this.deletebutton = false;
    }

    this.clearAllSCMDetails();
    this.TablePage = false;
    this.editMode = true;
    this.addDomainDiv = true;
    this.setDomainEditing(row);

    this.CreateTemplateDisable = true;
    this.NameDisable = true;
    this.addroleDisable = true;
    this.deleteDisable = true;
    this.ToolGroupDisable = true;
    this.ToolInstanceNameDisable = true;

    this.VdiConfig_details = [];
    var SelectedItem = row.template_id;
    let VdiConfigBoby = {
      vdi_dropdown_id: SelectedItem,
    };
    this.VdiConfig_details.push(VdiConfigBoby);
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, document.body.scrollHeight);
  }

  deleteVDIimages(i) {
    this.deleteIndex = i;

    if (this.editMode) {
      this.deletebody = this.roleArray[i];
      this.deletebody["delete_role_flag"] = true;
      this.deletebody["new_role_flag"] = false;
      this.deletebody["update_role_flag"] = false;

      this.deletedRoleArray.push(this.deletebody);
      //  console.log(body);
    }

    let element: HTMLElement = document.getElementById("cfm") as HTMLElement;
    element.click();

    // this.roleArray.splice(i,1);
    // this.roleArray.push(this.deletebody)
    // if(this.roleArray.length==0){
    //   this.disableAzure=false;
    // }
  }
  deleteRole() {
    this.roleArray.splice(this.deleteIndex, 1);
    //   this.roleArray.push(this.deletebody)
    if (this.roleArray.length == 0 && !this.editMode) {
      this.disableAzure = false;
    }

    // debugger
    // .hide()
    // this.cfm.close()
  }
  CheckTemplateDuplication() {
    //      console.log( this.panels[0].checked,"Master");

    //     console.log(this.deploymentAzure,"Azure");

    // console.log(this.deploymentAWS,"AWS");

    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == " " ||
      this.storyBoard.nameInstance == "" ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == " " ||
      this.storyBoard.description == "" ||
      this.roleArray.length == 0
    ) {
      //  this.alertService.error("Please enter details In all the fields and aleast add one Group Member and Primary Group Owner", this.options);
      this.showDiv = true;
      this.errorMsg =
        "Please enter details In all the fields and aleast add one Group Member and Primary Group Owner";
      setTimeout(() => {
        this.showDiv = false;
      }, 3000);

      return;
    } else {
      if (sp.test(this.storyBoard.nameInstance)) {
        this.alertService.error(
          "Name must include only alphabets ,numbers or _,-.",
          this.options
        );
        return;
      }
      if (sp.test(this.storyBoard.description)) {
        this.alertService.error(
          "Description must include only alphabets ,numbers or _,-.",
          this.options
        );
        return;
      }
      let GroupAble = false;
      let PrimaryAble = false;

      for (var s = 0; s < this.roleArray.length; s++) {
        if (this.roleArray[s].defined_role_name == "Group Member") {
          GroupAble = true;
        }
        if (this.roleArray[s].defined_role_name == "Primary Group Owner") {
          PrimaryAble = true;
        }
      }
      if (!GroupAble || !PrimaryAble) {
        //  this.alertService.error("Please select aleast one Group Member and Primary Group Owner", this.options);
        this.showDiv = true;
        this.errorMsg =
          "Please select aleast one Group Member and Primary Group Owner";
        setTimeout(() => {
          this.showDiv = false;
        }, 3000);
        return;
      }

      if (
        !this.isCheckStoryBoard &&
        !this.isCheckSCM &&
        !this.isCheckDevOps &&
        !this.isCheckColl &&
        !this.isCheckVDI &&
        !this.isCheckDeploy &&
        !this.isCheckMonitoring
      ) {
        //  this.alertService.error("Please select aleast one Tool for creating the template", this.options);
        this.showDiv = true;
        this.errorMsg =
          "Please select aleast one Tool for creating the template";
        setTimeout(() => {
          this.showDiv = false;
        }, 3000);
        return;
      }
      // this.project_nameInstance = this.storyBoard.nameInstance;

      //  debugger

      this.apiService
        .Admin_CheckValidateTemplateName(this.storyBoard.nameInstance)
        .subscribe(
          (res: HttpResponse<any>) => {
            // debugger
            //console.log("Inside Validate");
            // console.log(res.body);
            if (res.status == 200) {
              let code = res.body.code;

              switch (code) {
                //     case "01":
                //       this.showDiv = true;
                // this.errorMsg=res.body.msg;
                // setTimeout(() => {
                //   this.showDiv = false;
                // }, 3000);
                //          this.alertService.error(res.body.msg, this.options);
                //   break;
                case "02":
                  //  this.alertService.error(res.body.msg, this.options);
                  this.showDiv = true;
                  this.errorMsg = res.body.msg;
                  setTimeout(() => {
                    this.showDiv = false;
                  }, 3000);
                  this.CreateTemplate();
                  break;

                default:
                  this.CreateTemplate();
                  break;
              }
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status == 436) {
              //doesnt work
              this.alertService.error(error.error.message, this.options);
            } else if (error.status == 403) {
            }
            if (error.status == 500) {
              //doesnt work
              this.alertService.error("already Exist", this.options);
            } else {
              this.alertService.error("Invalid Input", this.options);

              throw error;
            }
          }
        );
    } //else
  }
  Test() {
    // console.log(this.chkHideReports);
  }
  CreateTemplate() {
    if (
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == " " ||
      this.storyBoard.nameInstance == "" ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == " " ||
      this.storyBoard.description == "" ||
      this.roleArray.length == 0
    ) {
      //  this.alertService.error("Please enter details In all the fields and aleast add one Group Member and Primary Group Owner", this.options);
      this.showDiv = true;
      this.errorMsg =
        "Please enter details In all the fields and aleast add one Group Member and Primary Group Owner";
      setTimeout(() => {
        this.showDiv = false;
      }, 3000);

      return;
    } else {
      // console.log(this.task.subtasks);
      if (this.task.subtasks[0].completed == true) {
        this.deploymentAzure = true;
      } else {
        this.deploymentAzure = false;
      }
      if (this.task.subtasks[1].completed == true) {
        this.deploymentAWS = true;
      } else {
        this.deploymentAWS = false;
      }
      if (this.taskLive.subtasksLive[0].completed == true) {
        this.resillenceFlag = true;
      } else {
        this.resillenceFlag = false;
      }
      if (
        this.task.subtasks[0].completed == true ||
        this.task.subtasks[1].completed == true
      ) {
        this.deployment = true;
      } else {
        this.deployment = false;
      }
      if (this.taskVdi.subtasksVdi[0].completed == true) {
        this.vdiAzure = true;
      } else {
        this.vdiAzure = false;
      }
      if (this.taskVdi.subtasksVdi[1].completed == true) {
        this.vdiAWS = true;
      } else {
        this.vdiAWS = false;
      }
      if (
        this.taskVdi.subtasksVdi[0].completed == true ||
        this.taskVdi.subtasksVdi[1].completed == true
      ) {
        this.vdi = true;
      } else {
        this.vdi = false;
      }

      let GroupAble = false;
      let PrimaryAble = false;

      for (var s = 0; s < this.roleArray.length; s++) {
        if (this.roleArray[s].defined_role_name == "Group Member") {
          GroupAble = true;
        }
        if (this.roleArray[s].defined_role_name == "Primary Group Owner") {
          PrimaryAble = true;
        }
      }
      if (!GroupAble || !PrimaryAble) {
        //  this.alertService.error("Please select aleast one Group Member and Primary Group Owner", this.options);
        this.showDiv = true;
        this.errorMsg =
          "Please select aleast one Group Member and Primary Group Owner";
        setTimeout(() => {
          this.showDiv = false;
        }, 3000);
        return;
      }

      if (
        !this.isCheckStoryBoard &&
        !this.isCheckSCM &&
        !this.isCheckDevOps &&
        !this.isCheckColl &&
        !this.isCheckVDI &&
        !this.isCheckDeploy &&
        !this.isCheckMonitoring
      ) {
        //  this.alertService.error("Please select aleast one Tool for creating the template", this.options);
        this.showDiv = true;
        this.errorMsg =
          "Please select aleast one Tool for creating the template";
        setTimeout(() => {
          this.showDiv = false;
        }, 3000);
        return;
      }

      var saveBody: any;
      saveBody = {
        resource_type: "template",
        template_name: this.storyBoard.nameInstance,
        template_description: this.storyBoard.description,
        // "functional_area":this.storyBoard.functionalarea,
        template_roles: this.roleArray,
        template_icons: {
          project_details: {
            view: true,
            access: true,
          },
          workbench: [
            {
              project_role: {
                view: true,
                access: true,
              },
              // "assign_vdi": {
              //     "view": true,//this.HideVDI,
              //     "access": this.HideVDI
              // },
              assign_vdi: {
                view: true,
                access: this.vdi,
                azure: {
                  view: true,
                  access: this.vdiAzure,
                },
                aws: {
                  view: true,
                  access: this.vdiAWS,
                },
              },
              scm_tool: {
                view: true, //this.HideSCM,
                access: this.HideSCM,
              },
              story_board: {
                view: true, //this.HideStoryBoard,
                access: this.HideStoryBoard,
              },
              devops: {
                view: true, //this.HideDevOps,
                access: this.HideDevOps,
              },
              development_frameworks: {
                view: false,
                access: false,
              },
              lti_store: {
                view: false,
                access: false,
              },
              
              deployment_store: {
                view: true,
                access: this.deployment,
                azure: {
                  view: true,
                  access: this.deploymentAzure,
                },
                aws: {
                  view: true,
                  access: this.deploymentAWS,
                },
              },

              collaboration: {
                view: true,
                access: this.HideCollaboration,
              },
            },
          ],
          live_monitoring: {
            resilience: { 
              view: true,
               access:this.resillenceFlag,
              
          }},
          team_members: {
            view: true,
            access: true,
          },
          approval_center: {
            view: false,
            access: false,
          },
          // live_monitoring: {
          //   view: true, //this.HideMonitoring,
          //   access: this.HideMonitoring,
          // },

          reports: {
            view: true,
            access: this.HideReports,
          },
        },
        created_by: localStorage.getItem("mail").toLowerCase(),
      };

      this.spinner.show();
      this.apiService.Admin_DomainSaveConnData(saveBody).subscribe(
        (res: HttpResponse<any>) => {
          if (res.status == 200) {
            //doesnt work
            //this.alertService.success("Data Saved Successfully", this.options);
            this.testConnDisable = false;
            this.spinner.hide();
            this.editMode = true;

            this.router
              .navigateByUrl("/admin-story-board", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/admin-projectTemplate"]);
              });
            //this.alertService.success("Creation of project template  Saved Successfully", this.options);
            this.alertService.success(res.body.message, this.options);
          }
        },
        (error: HttpErrorResponse) => {
          //
          this.spinner.hide();
          if (error.status == 430 || error.status == 436) {
            //doesnt work
            this.alertService.error(error.error.message, this.options);
            this.showDiv = true;
            this.errorMsg = error.error.message;
            setTimeout(() => {
              this.showDiv = false;
            }, 3000);
          } else if (error.status == 403) {
          } else {
            this.alertService.error("Invalid Input", this.options);
            throw error;
          }
        }
      );
    }
  }

  updateTemplate() {
    if (
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == undefined ||
      this.storyBoard.nameInstance == " " ||
      this.storyBoard.nameInstance == "" ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == undefined ||
      this.storyBoard.description == " " ||
      this.storyBoard.description == "" ||
      this.roleArray.length == 0
    ) {
      //  this.alertService.error("Please enter details In all the fields and aleast add one Group Member and Primary Group Owner", this.options);
      this.showDiv = true;
      this.errorMsg =
        "Please enter details In all the fields and aleast add one Group Member and Primary Group Owner";
      setTimeout(() => {
        this.showDiv = false;
      }, 3000);

      return;
    }
    let GroupAble = false;
    let PrimaryAble = false;

    for (var s = 0; s < this.roleArray.length; s++) {
      if (this.roleArray[s].new_defined_role_name != undefined) {
        var checkRole = this.roleArray[s].new_defined_role_name;
      } else {
        var checkRole = this.roleArray[s].defined_role_name;
      }
      var finalCheckRole = "";
      for (let i = 0; i < checkRole.length; i++) {
        if (
          checkRole[i].charCodeAt(0) != 160 &&
          checkRole[i].charCodeAt(0) != 32
        ) {
          finalCheckRole = finalCheckRole + checkRole[i];
        }
      }

      if (finalCheckRole == "GroupMember") {
        GroupAble = true;
      }
      if (finalCheckRole == "PrimaryGroupOwner") {
        PrimaryAble = true;
      }
    }
    if (!GroupAble || !PrimaryAble) {
      //  this.alertService.error("Please select aleast one Group Member and Primary Group Owner", this.options);
      this.showDiv = true;
      this.errorMsg =
        "Please select aleast one Group Member and Primary Group Owner";
      setTimeout(() => {
        this.showDiv = false;
      }, 3000);
      return;
    }

    //  console.log(this.taskVdi.subtasksVdi);

    if (this.task.subtasks[0].completed == true) {
      this.deploymentAzure = true;
    } else {
      this.deploymentAzure = false;
    }
    if (this.task.subtasks[1].completed == true) {
      this.deploymentAWS = true;
    } else {
      this.deploymentAWS = false;
    }

    if (this.taskLive.subtasksLive[0].completed == true) {
      this.resillenceFlag = true;
    } else {
      this.resillenceFlag = false;
    }
    if (
      this.task.subtasks[0].completed == true ||
      this.task.subtasks[1].completed == true
    ) {
      this.deployment = true;
    } else {
      this.deployment = false;
    }
    if (this.taskVdi.subtasksVdi[0].completed == true) {
      this.vdiAzure = true;
    } else {
      this.vdiAzure = false;
    }
    if (this.taskVdi.subtasksVdi[1].completed == true) {
      this.vdiAWS = true;
    } else {
      this.vdiAWS = false;
    }
    if (
      this.taskVdi.subtasksVdi[0].completed == true ||
      this.taskVdi.subtasksVdi[1].completed == true
    ) {
      this.vdi = true;
    } else {
      this.vdi = false;
    }

    //  console.log(this.task.subtasks);

    //  console.log(this.deployment);

    //  console.log(this.deploymentAzure);
    //  console.log(this.deploymentAWS);

    // console.log(this.roleArray);
    for (let i = 0; i < this.roleArray.length; i++) {
      if (this.roleArray[i].new_role_flag == undefined) {
        (this.roleArray[i].delete_role_flag = false),
          (this.roleArray[i].new_role_flag = false),
          (this.roleArray[i].update_role_flag = false);
      }
    }

    let updateBody = {
      resource_type: "template",
      template_id: this.project_id_scmEdit,
      template_name: this.storyBoard.nameInstance,
      template_description: this.storyBoard.description,
      // "functional_area":this.storyBoard.functionalarea,
      deleted_template_roles: this.deletedRoleArray,
      template_roles: this.roleArray,
      template_icons: {
        project_details: {
          view: true,
          access: true,
        },
        workbench: [
          {
            project_role: {
              view: true,
              access: true,
            },
            // "assign_vdi": {
            //     "view": true,//this.HideVDI,
            //     "access": this.HideVDI
            // },
            assign_vdi: {
              view: true,
              access: this.vdi,
              azure: {
                view: true,
                access: this.vdiAzure,
              },
              aws: {
                view: true,
                access: this.vdiAWS,
              },
            },
            scm_tool: {
              view: true, //this.HideSCM,
              access: this.HideSCM,
            },
            story_board: {
              view: true, //this.HideStoryBoard,
              access: this.HideStoryBoard,
            },
            devops: {
              view: true, //this.HideDevOps,
              access: this.HideDevOps,
            },
            development_frameworks: {
              view: false,
              access: false,
            },
            lti_store: {
              view: false,
              access: false,
            },
            deployment_store: {
              view: true,
              access: this.deployment,
              azure: {
                view: true,
                access: this.deploymentAzure,
              },
              aws: {
                view: true,
                access: this.deploymentAWS,
              },
            },
            collaboration: {
              view: true,
              access: this.HideCollaboration,
            },
          },
        ],
        team_members: {
          view: true,
          access: true,
        },
        approval_center: {
          view: false,
          access: false,
        },
        // live_monitoring: {
        //   view: true, //this.HideMonitoring,
        //   access: this.HideMonitoring,
        // },
        live_monitoring: {
          resilience: { 
            view: true,
             access:this.resillenceFlag,
            
        }},
        reports: {
          view: true,
          access: this.HideReports,
        },
      },
      updated_by: localStorage.getItem("mail").toLowerCase(),
    };
    //console.log(updateBody)
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (sp.test(this.storyBoard.description)) {
      this.alertService.error(
        "Description must include only alphabets ,numbers or _,-.",
        this.options
      );
      return;
    }
    // console.log(this.roleArray);

    // this.spinner.show();

    this.apiService.Admin_DomainUpdate(updateBody).subscribe(
      (res: HttpResponse<any>) => {
        if (res.status == 200) {
          //doesnt work
          // this.alertService.success("Data Updated Successfully", this.options);
          this.testConnDisable = false;
          this.spinner.hide();
          this.editMode = true;

          this.router
            .navigateByUrl("/admin-story-board", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/admin-projectTemplate"]);
            });
          this.alertService.success(res.body.message, this.options);
        }
      },
      (error: HttpErrorResponse) => {
        //
        this.spinner.hide();
        if (error.status == 430 || error.status == 436) {
          //doesnt work
          this.alertService.error(error.error.message, this.options);
          this.showDiv = true;
          this.errorMsg = error.error.message;
          setTimeout(() => {
            this.showDiv = false;
          }, 3000);
        } else if (error.status == 403) {
        } else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    );
  }

  setDomainEditing(domainObj: DomainDetails) {
    this.getSubscriptionList();

    this.storyBoard.nameInstance = domainObj.template_name;
    this.storyBoard.description = domainObj.template_description;
    this.storyBoard.functionalarea = domainObj.functional_area;
    this.project_id_scmEdit = domainObj.template_id;
    this.roleArray = [];

    for (var s = 0; s < this.masterDomainTemple.length; s++) {
      if (domainObj.template_id == this.masterDomainTemple[s].template_id) {
        for (
          var r = 0;
          r < this.masterDomainTemple[s].template_roles.length;
          r++
        ) {
          this.storyBoard.azureId =
            this.masterDomainTemple[s].template_roles[r].azure_id;
          this.vdi_Azure_Id =
            this.masterDomainTemple[s].template_roles[r].azure_id;
          // this.vdi_tool_id=this.masterDomainTemple[s].template_roles[r].vdi_tool_id
          let tempOb = {
            template_role_name:
              this.masterDomainTemple[s].template_roles[r].template_role_name,
            role_local_image:
              this.masterDomainTemple[s].template_roles[r].role_local_image,
            azure_image:
              this.masterDomainTemple[s].template_roles[r].azure_image,
            azure_id: this.masterDomainTemple[s].template_roles[r].azure_id,
            defined_role_name:
              this.masterDomainTemple[s].template_roles[r].defined_role_name,
            role_name: this.masterDomainTemple[s].template_roles[r].role_name,
            vdi_tool_id:
              this.masterDomainTemple[s].template_roles[r].vdi_tool_id,
            vdi_type: this.masterDomainTemple[s].template_roles[r].vdi_type,
            new_role_flag:
              this.masterDomainTemple[s].template_roles[r].new_role_flag,
            update_role_flag:
              this.masterDomainTemple[s].template_roles[r].update_role_flag,
            delete_role_flag:
              this.masterDomainTemple[s].template_roles[r].delete_role_flag,
            new_template_role_name:
              this.masterDomainTemple[s].template_roles[r]
                .new_template_role_name,
            new_role_local_image:
              this.masterDomainTemple[s].template_roles[r].new_role_local_image,
            new_defined_role_name:
              this.masterDomainTemple[s].template_roles[r]
                .new_defined_role_name,
            new_role_name:
              this.masterDomainTemple[s].template_roles[r].new_role_name,
          };
          this.roleArray.push(tempOb);
        }

        if (
          this.masterDomainTemple[s].template_icons.workbench[0].assign_vdi
            .azure.access == true &&
          this.masterDomainTemple[s].template_icons.workbench[0].assign_vdi.aws
            .access == true
        ) {
          this.allCompleteVdi = true;
          this.taskVdi.subtasksVdi[0].completed = true;
          this.taskVdi.subtasksVdi[1].completed = true;

          this.chkVDI = true;
          this.EnableVDI = true;
          this.DisableVDI = false;
          this.chkHideVDI = true;
          this.HideVDI = true;
        } else if (
          this.masterDomainTemple[s].template_icons.workbench[0].assign_vdi
            .azure.access == true
        ) {
          this.taskVdi.subtasksVdi[0].completed = true;
        } else if (
          this.masterDomainTemple[s].template_icons.workbench[0].assign_vdi.aws
            .access == true
        ) {
          this.taskVdi.subtasksVdi[1].completed = true;
        } else {
          this.allCompleteVdi = false;
          this.taskVdi.subtasksVdi[0].completed = false;
          this.taskVdi.subtasksVdi[1].completed = false;

          this.chkVDI = false;
          this.EnableVDI = false;
          this.DisableVDI = true;
          this.chkHideVDI = false;
          this.HideVDI = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].assign_vdi.view==true)
        // {
        //  this.chkHideVDI=true;

        // }
        // else{

        //   this.chkHideVDI=false;
        // }
        if (
          this.masterDomainTemple[s].template_icons.workbench[0].collaboration
            .access == true
        ) {
          this.chkCollaboration = true;
          this.EnableCollaboration = true;
          this.DisableCollaboration = false;
          this.chkHideCollaboration = true;
          this.HideCollaboration = true;
        } else {
          this.chkCollaboration = false;
          this.EnableCollaboration = false;
          this.DisableCollaboration = true;
          this.chkHideCollaboration = false;
          this.HideCollaboration = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].collaboration.view==true){
        //   this.chkHideCollaboration=true;
        // }
        // else{
        //   this.chkHideCollaboration=false;
        // }

        if (
          this.masterDomainTemple[s].template_icons.workbench[0]
            .deployment_store.azure.access == true &&
          this.masterDomainTemple[s].template_icons.workbench[0]
            .deployment_store.aws.access == true
        ) {
          this.allComplete = true;
          this.task.subtasks[0].completed = true;
          this.task.subtasks[1].completed = true;

          this.chkDeployment = true;
          this.EnableDeployment = true;
          this.DisableDeployment = false;
          this.chkHideDeployment = true;
          this.HideDeployment = true;
        } else if (
          this.masterDomainTemple[s].template_icons.workbench[0]
            .deployment_store.azure.access == true
        ) {
          this.task.subtasks[0].completed = true;
        } else if (
          this.masterDomainTemple[s].template_icons.workbench[0]
            .deployment_store.aws.access == true
        ) {
          this.task.subtasks[1].completed = true;
        } else {
          this.chkDeployment = false;
          this.EnableDeployment = false;
          this.DisableDeployment = true;
          this.chkHideDeployment = false;
          this.HideDeployment = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].deployment_store.view==true){
        //   this.chkHideDeployment=true;
        // }
        // else{
        //   this.chkHideDeployment=false;
        // }
        if (
          this.masterDomainTemple[s].template_icons.workbench[0].devops
            .access == true
        ) {
          this.chkDevOps = true;
          this.EnableDevOps = true;
          this.DisableDevOps = false;
          this.chkHideDevOps = true;
          this.HideDevOps = true;
        } else {
          this.chkDevOps = false;
          this.EnableDevOps = false;
          this.DisableDevOps = true;
          this.chkHideDevOps = false;
          this.HideDevOps = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].devops.view==true){
        //   this.chkHideDevOps=true;
        // }
        // else{
        //   this.chkHideDevOps=false;
        // }
        if (
          this.masterDomainTemple[s].template_icons.workbench[0].scm_tool
            .access == true
        ) {
          this.chkSCM = true;
          this.EnableSCM = true;
          this.DisableSCM = false;
          this.chkHideSCM = true;
          this.HideSCM = true;
        } else {
          this.chkSCM = false;
          this.EnableSCM = false;
          this.DisableSCM = true;
          this.chkHideSCM = false;
          this.HideSCM = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].scm_tool.view==true){
        //   this.chkHideSCM=true;
        // }
        // else{
        //   this.chkHideSCM=false;
        // }
        if (
          this.masterDomainTemple[s].template_icons.workbench[0].story_board
            .access == true
        ) {
          this.chkStoryBoard = true;
          this.EnableStoryBoard = true;
          this.DisableStoryBoard = false;
          this.chkHideStoryBoard = true;
          this.HideStoryBoard = true;
        } else {
          this.chkStoryBoard = false;
          this.EnableStoryBoard = false;
          this.DisableStoryBoard = true;
          this.chkHideStoryBoard = false;
          this.HideStoryBoard = false;
        }
        // if(this.masterDomainTemple[s].template_icons.workbench[0].story_board.view==true){
        //   this.chkHideStoryBoard=true;
        // }
        // else{
        //   this.chkHideStoryBoard=false;
        // }
        //debugger;
        if (this.masterDomainTemple[s].template_icons.reports.access == true) {
          this.chkHideReports = true;
          this.HideReports = true;

          // this.chkStoryBoard=false;
          //this.EnableStoryBoard=false;
          //this.DisableStoryBoard=true;
          //this.chkHideStoryBoard=false;
          //this.HideStoryBoard = false;
        } else {
          this.chkHideReports = false;
          this.HideReports = false;
        }
        if (
          this.masterDomainTemple[s].template_icons.live_monitoring.resilience.access ==
          true
        ) {
          this.chkHideMonitoring = true;
          this.HideMonitoring = true;
          this.taskLive.subtasksLive[0].completed=true;
          this.allCompleteLive=true

        } else {
          this.chkHideMonitoring = false;
          this.HideMonitoring = false;
          // this.taskLive.subtasksLive[0].completed=false;
          this.taskLive.completed=false
          this.allCompleteLive=false



        }
      }
    }
    this.getAzureIdDetails(this.storyBoard.azureId);
    this.vdi_Azure_Iddiv = true;
    this.AzuredrpDisable = false;
    this.disableAzure = true;
    //this.CreateTemplateCancel();
    this.LoadImage();
    window.scrollTo(document.body.scrollHeight, 0);
  }

  selectedTool(e) {}

  validateEmail(email) {
    const regularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  onSubmit() {}

  updateGitLab() {}
  clearAllSCMDetails() {
    this.storyBoard.privilege = "";
    this.storyBoard.actualImageName = "";
    this.storyBoard.logicalImageName = "";
    this.storyBoard.personaName = "";
    this.storyBoard.nameInstance = "";
    this.storyBoard.description = "";
    this.DisableStoryBoard = true;
    this.storyBoard.azureId = "";
    this.DisableSCM = true;
    this.DisableDevOps = true;
    this.DisableCollaboration = true;
    //this.DisableVDI=true;
    this.DisableDeployment = true;

    this.chkStoryBoard = false;
    this.chkSCM = false;
    this.chkDevOps = false;
    this.chkCollaboration = false;
    // this.chkVDI=false;
    this.chkDeployment = false;

    this.chkHideStoryBoard = false;
    this.chkHideSCM = false;
    this.chkHideDevOps = false;
    this.chkHideCollaboration = false;
    //this.chkHideVDI=true;
    this.chkHideDeployment = false;
    this.chkHideMonitoring = false;
    // this.storyBoard.jiraEmail = "";
    this.editMode = false;
    this.HideVDI = true;
    this.DisableVDI = false;
    this.chkVDI = true;
    this.EnableVDI = true;
    this.chkHideVDI = true;
    this.isCheckVDI = true;
    this.roleArray = [];
    this.ActualImageArray = [];
    this.SubscriptionList = [];
    this.AzuredrpDisable = true;
    this.CreateTemplateDisable = false;
    this.vdi_Azure_Iddiv = false;
    this.disableAzure = false;
    this.NameDisable = false;
    this.addroleDisable = false;
    this.deleteDisable = false;
  }

  showImageInfo(domain) {
    //console.log(domain);

    const dialogRef = this.dialog.open(AdminDialogTemplateOverview, {
      width: "55%",
      height: "80%",
      disableClose: true,
      data: {
        // image: domain.showImage,
        toolId: domain,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}

@Component({
  selector: "admin-dialog-overview",
  templateUrl: "image-details.html",
  styleUrls: ["./admin-project-template.component.scss"],
})
export class AdminDialogTemplateOverview {
  // image: any = [];
  Template_id: any;
  enabledIcons: any[];
  workbenchIcons: any[];
  roleData: any;
  constructor(
    public dialogRef: MatDialogRef<AdminDialogTemplateOverview>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: AuthService,
    public alertService: AlertService
  ) {
    this.Template_id = data.toolId.template_id;
    this.apiService.templateInfo(this.Template_id).subscribe((result) => {
      this.roleData = result[0].template_roles;
      let iconData = [];
      let workbenchIconData = [];
      if (
        result[0].template_icons.workbench[0].project_role.view == true &&
        result[0].template_icons.workbench[0].project_role.access == true
      ) {
        workbenchIconData.push("Project Role");
      }
      if (
        result[0].template_icons.workbench[0].assign_vdi.view == true &&
        result[0].template_icons.workbench[0].assign_vdi.access == true
      ) {
        workbenchIconData.push("VDI");
      }
      if (
        result[0].template_icons.workbench[0].scm_tool.view == true &&
        result[0].template_icons.workbench[0].scm_tool.access == true
      ) {
        workbenchIconData.push("SCM");
      }
      if (
        result[0].template_icons.workbench[0].story_board.view == true &&
        result[0].template_icons.workbench[0].story_board.access == true
      ) {
        workbenchIconData.push("Story Board");
      }
      if (
        result[0].template_icons.workbench[0].devops.view == true &&
        result[0].template_icons.workbench[0].devops.access == true
      ) {
        workbenchIconData.push("Devops");
      }
      if (
        result[0].template_icons.workbench[0].development_frameworks.view ==
          true &&
        result[0].template_icons.workbench[0].development_frameworks.access ==
          true
      ) {
        workbenchIconData.push("Development Framework");
      }
      if (
        result[0].template_icons.workbench[0].lti_store.view == true &&
        result[0].template_icons.workbench[0].lti_store.access == true
      ) {
        workbenchIconData.push("LTI Store");
      }
      if (
        result[0].template_icons.workbench[0].deployment_store.view == true &&
        result[0].template_icons.workbench[0].deployment_store.access == true
      ) {
        workbenchIconData.push("Deployment Store");
      }
      if (
        result[0].template_icons.workbench[0].collaboration.view == true &&
        result[0].template_icons.workbench[0].collaboration.access == true
      ) {
        workbenchIconData.push("Collaboration");
      }
      this.enabledIcons = iconData;
      this.workbenchIcons = workbenchIconData;
    });
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };
  onNoClick(): void {
    this.dialogRef.close();
  }
}
