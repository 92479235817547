<div class="pop-up">
    <div class="">
        <span class="vertical-line mx-3"> </span>
        <span class="header-title mb-0">Confirm</span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <div class="row pt-2">
            <h3 class="mb-3 col-12">Do you want to logout?</h3>
            <div class="col-12">
                <div class="row offset-sm-4 offset-lg-8">
                    <div class="col-sm-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('Yes')" class="border_none vdi-button">Yes</button>
                    </div>
                    <div class="col-sm-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>