<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container" *ngIf="jiraPage">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Template config</li>
          </ol>
        </nav>
      </div>


      <div class="col-sm-4 col-md-5 text-right pt-3">
        <span class="table-options ">
          <span (click)="addDomain();deletebutton=false;"><i class="fas fa-plus"></i> Add</span>
          <span (click)="refreshDomain()"><i class="fas fa-sync-alt"></i> Refresh</span>

          <a (click)="backNavigation()" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </span>
      </div>
      <!-- <div class="col-md-1 p-md-0 my-auto">
        <a routerLink="/admin-catalog-config" class="float-right step-back">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div> -->
    </div>


    <h1 class="text-light mb-0">
      <img width="40" src="assets/img/jira-logo.png">
      <div class="vertical-line mx-3"> </div> <span>Template</span>
    </h1>

  </div>

  <div class="inner-mid-container">
    <div class="modal fade " id="confirmationModal3" tabindex="-1" role="dialog" aria-labelledby="confirmationModal3"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content pop-up">
          <div class="modal-header-popup">
            <span class="vertical-line mx-3"> </span>
            <span class="header mb-0">Template config</span>
            <span data-dismiss="modal" class="material-icons close-icon">
              close
            </span>
          </div>
          <hr class="margin-class" />
          <div class="modal-body">
            Are you sure want to Delete?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
          </div>
        </div>
      </div>
    </div>

    <div class="add-jira-form" *ngIf="addDomainDiv">
      <h2 *ngIf="!editMode">New Domain Template Instance</h2>
      <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
      <form (ngSubmit)="onSubmit()" #storyBoardForm="ngForm">
        <div class="row ml-1">
          <div class="col-md-3">
            <div class="form-group">

              <label for="nameInstance"> Name <span class="required">&#42;</span></label>
              <input id="nameInstance" autocomplete="off" name="nameInstance" type="text" maxlength="20" minlength="3"
                class="form-control form-control-lg" pattern="^[a-zA-Z0-9_-]+$" required
                [(ngModel)]="storyBoard.nameInstance" #nameInstance="ngModel" [disabled]="NameDisable"
                onPaste="return false">
              <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
                class="alert alert-danger">
                <div *ngIf="nameInstance.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>
                <div *ngIf="nameInstance.errors.minlength">
                  Please enter minimum 3 characters for saving Name
                </div>
                <div *ngIf="nameInstance.errors.required">
                  Name is required.
                </div>

              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="description"> Description <span class="required">&#42;</span></label>
              <input id="description" autocomplete="off" name="description" type="text" maxlength="50" minlength="20"
                pattern="^[a-zA-Z0-9_ -]+$" class="form-control form-control-lg" required
                [(ngModel)]="storyBoard.description" #description="ngModel">
              <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
                <div *ngIf="description.errors.required">
                  Description is required.
                </div>
                <div *ngIf="description.errors.minlength">
                  Please enter minimum 20 characters for saving Description

                </div>
                <div *ngIf="description.invalid && !description.errors.minlength &&description.errors.pattern">
                  Description must include only alphabets ,numbers or _,-.</div>

              </div>
            </div>
          </div>

          <!--<div class="col-md-3">
                         <div class="form-group">
                            <label for="functionalarea"> Functional Area <span class="required">&#42;</span></label>
                            <input id="functionalarea" autocomplete="off" name="functionalarea" type="text"
                                class="form-control form-control-lg" required [(ngModel)]="storyBoard.functionalarea"
                                 #functionalarea="ngModel"
                                (keydown.space)="$event.preventDefault();" onPaste="return false">
                        </div> 
                    </div>-->
        </div>
        <div>
          <!--Code added for Azure Id with Details on i button-->
          <!-- <div class="form-group col-md-0 pt-3">
                  <label  class="example-margin fs-15">Roles</label>
                </div> -->
          <fieldset class="border p-3 example-margin">
            <legend class="w-auto" style="font-weight: bold;">Roles</legend>

            <div class="row pl-3 pt-3">
              <div class="form-group col-md-3">
                <label for="azureId">Azure ID <span class="required">&#42;</span></label>
                <select id="azureId" name="azureId" class="form-control form-control-lg" required
                  (change)="onChangeAzureId($event)" [(ngModel)]="storyBoard.azureId" #azureId="ngModel"
                  [disabled]="disableAzure">
                  <!-- <option selected>Select Azure ID</option>-->
                  <!--<option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}                
              </option>-->
                  <!-- <option value="" disabled selected hidden>Select Azure ID</option> -->
                  <option *ngFor="let s of SubscriptionList" [value]="s.tool_id">{{s.tool_id_display}}
                  </option>
                </select>
                <!-- <div *ngIf="azureId.errors && azureId.touched" class="alert alert-danger">
              <div *ngIf="azureId.errors.required">Azure is required.</div>
            </div> -->
              </div>

              <div class="position-relative pt-5 img-width">
                <span *ngIf="this.vdi_Azure_Iddiv" style="font-size: 18px;cursor:pointer;"
                  class="material-icons add-user" title="System Information" data-toggle="modal"
                  data-target="#AzureDetailsModal">info</span>

                <!-- Modal -->
                <div class="modal fade pop-up" id="AzureDetailsModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="info-header">
                        <span class="vertical-line mx-3"> </span>
                        <span class="header mb-0"> Azure ID Details</span>
                        <span type="button" data-dismiss="modal" class="material-icons close-icon">
                          close
                        </span>
                      </div>

                      <div class="modal-body popup-container">
                        <div class="row">
                          <div class="col-md-4">
                            <span class="field-name">Azure ID </span>
                          </div>
                          <div class="col-md-8">
                            <span> : &nbsp;{{this.vdi_Azure_Id}}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <span class="field-name">Subscription ID </span>
                          </div>
                          <div class="col-md-8">
                            <span> : &nbsp;{{this.vdi_Subscription_ID}}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <span class="field-name">Tenant ID </span>
                          </div>
                          <div class="col-md-8">
                            <span> : &nbsp;{{this.vdi_tenantId}}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <span class="field-name">Connection Name </span>
                          </div>
                          <div class="col-md-8">
                            <span> : &nbsp;{{this.vdi_Azure_ConnectionName}}</span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4">
                            <span class="field-name">Tool Group Name </span>
                          </div>
                          <div class="col-md-8">
                            <span> : &nbsp;{{this.Vdi_ToolGr_Name}}</span>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="offset-md-6 col-md-6">
              <div *ngIf="showDivAdd" class="alert alert-danger">
                {{errorMsgAdd}}
              </div>
            </div>
            <div class="row pl-3 pt-3">

              <div class="col-md-2">
                <div class="form-group">
                  <label for="personaName"> Persona Name <span class="required">&#42;</span></label>
                  <input id="personaName" maxlength="25" autocomplete="off" name="personaName" type="text"
                    class="form-control form-control-lg" required [(ngModel)]="storyBoard.personaName"
                    #personaName="ngModel">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="logicalImageName">Logical Image Name <span class="required">&#42;</span></label>
                  <input id="logicalImageName" maxlength="25" autocomplete="off" name="logicalImageName" type="text"
                    class="form-control form-control-lg" required [(ngModel)]="storyBoard.logicalImageName"
                    #logicalImageName="ngModel">
                </div>
              </div>
              <div class="form-group col-md-3">
                <label for="actualImageName"> Actual Image Name <span class="required">&#42;</span></label>
                <select id="actualImageName" [(ngModel)]="storyBoard.actualImageName" name="actualImageName"
                  class="form-control form-control-lg" (change)="onChangeImages($event)" required
                  [disabled]="AzuredrpDisable">
                  <option *ngFor="let temp of ActualImageArray"
                    [value]="temp.tool_instance_details.vdi_tool_instance.name">
                    {{temp.tool_instance_details.vdi_tool_instance.name}}</option>
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="privilege"> Privilege Access <span class="required">&#42;</span></label>
                <select id="privilege" [(ngModel)]="storyBoard.privilege" name="privilege"
                  class="form-control form-control-lg" required [disabled]="privellegeDisable">
                  <option *ngFor="let privi of privilegeArray" [value]="privi">{{privi}}</option>
                </select>
              </div>
              <div class="col-md-2 pt-4">
                <!-- <button mat-button mat-raised-button  class="hover users-button width-100"
            title="Add Role">Add Role</button> -->
                <button type="button" (click)="addRole()" class="button btn btn-primary btn-lg mr-2"
                  *ngIf="!roleEditFlag">
                  Add Role
                </button>
                <button type="button" (click)="addUpdatedRole()" class="button btn btn-primary mr-2"
                  *ngIf="roleEditFlag">
                  Update
                </button>
                <button type="button" (click)="clearUpdatedRole()" class="button btn btn-primary" *ngIf="roleEditFlag">
                  Clear
                </button>

              </div>
              <div class="col-md-12">
                <div *ngIf="flags" class="alert alert-danger">
                  {{flagmsgs}}
                </div>
              </div>
              <div class="col-md-12">
                <div *ngIf="flagl" class="alert alert-danger">
                  {{flagmsgl}}
                </div>
              </div>
              <div class="col-md-12">
                <div *ngIf="flag" class="alert alert-danger">
                  {{flagmsg}}
                </div>
              </div>


            </div>
            <button data-toggle="modal" hidden="true" class="B-none" id="CallModel1"
              data-target="#confirmationModal"></button>
            <button data-toggle="modal" hidden="true" class="B-none" id="CallModelUpdateRole"
              data-target="#confirmationModalUpdteRole"></button>
            <!-- Modal update role -->
            <div class="modal fade" id="confirmationModalUpdteRole" tabindex="-1" role="dialog"
              aria-labelledby="confirmationModalUpdteRole" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                    <span class="vertical-line mx-3"> </span>
                    <span class="header mb-0"> Template Config</span>
                    <span data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                    <!-- Are you sure want to create role? -->
                    {{Message}}
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                      (click)='UpdateTemplateRole()'>Yes</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog"
              aria-labelledby="confirmationModal" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                    <span class="vertical-line mx-3"> </span>
                    <span class="header mb-0"> Template Config</span>
                    <span data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                    <!-- Are you sure want to create role? -->
                    {{Message}}
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">No</button>
                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                      (click)='AddTempleteRole()'>Yes</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="roleArray.length>0">
              <div class="table-responsive roles">
                <table class="table container m-3 table-role">
                  <thead class="fs-15">
                    <tr>
                      <th>Role/Persona Name</th>
                      <th>Logical Image Name</th>
                      <th>Actual Image Name</th>
                      <!-- <th>azure_id</th> -->
                      <th>Privilege Access</th>
                      <!-- <th>defined_role_nam</th>  -->
                      <!-- <th>role_name</th>  -->
                      <!-- <th>vdi_tool_id</th>  -->
                      <!-- <th>vdi_type</th>  -->
                      <th style="width: 8%;" *ngIf="!deleteMode">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let temp of roleArray;let i = index">
                      <td>{{temp.new_template_role_name || temp.template_role_name}}</td>
                      <td>{{temp.new_role_local_image || temp.role_local_image}}</td>
                      <td>{{temp.azure_image}}</td>
                      <!-- <td>{{temp.azure_id}}</td> -->
                      <td>{{temp.new_defined_role_name || temp.defined_role_name}}</td>
                      <!-- <td>{{temp.role_name}}</td>  -->
                      <!-- <td>{{temp.vdi_tool_id}}</td> -->
                      <!-- <td>{{temp.vdi_type}}</td> -->
                      <td style="width: 8%;" *ngIf="!deleteMode">
                        <!-- <span class="hover" *ngIf="!editMode" mwlConfirmationPopover [popoverTitle]="popoverTitle"
                          [popoverMessage]="popoverMessage" placement="top" (confirm)="deleteVDIimages(i)"
                          (cancel)="cancelClicked = true">
                          <i class="fa fa-trash table-icon color-danger" aria-hidden="true"></i>
                        </span> -->
                        <!-- <span class="hover mr-3"  
                        (cancel)="cancelClicked = true" title="delete"  data-toggle="modal" data-target="#confirmationModal2">
                        <i class="fa fa-trash table-icon color-danger" aria-hidden="true"></i>
                      </span> -->
                        <span class="hover mr-3" (cancel)="cancelClicked = true" title="delete" *ngIf="i != editIndex"
                          (click)="deleteVDIimages(i)">
                          <img width="18" src="../../../assets/img/Delete-blue.png">

                          <!-- <i class="fa fa-trash table-icon color-danger" aria-hidden="true" ></i> -->
                        </span>
                        <span class="hover mr-3" (cancel)="cancelClicked = true" title="delete" *ngIf="i == editIndex"
                          style="pointer-events: none; color: rgb(138, 138, 138);">
                          <!-- <i class="fa fa-trash table-icon color-danger" aria-hidden="true" ></i> -->
                          <img width="18" src="../../../assets/img/Delete-Deactivate.png">
                        </span>
                        <span class="table-crud table-icon color-danger" title="Update" *ngIf="editMode"
                          (click)="editRole(temp,i)">

                          <img width="20" src="../../../assets/img/pencil.png"></span>
                        <!-- <p>{{storyBoard.personaName}}</p> -->


                        <button data-toggle="modal" hidden="true" class="B-none" id="cfm"
                          data-target="#confirmationModal2"></button>
                        <div class="modal fade " id="confirmationModal2" tabindex="-1" role="dialog"
                          aria-labelledby="confirmationModal2" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content pop-up">
                              <div class="modal-header-popup">
                                <span class="vertical-line mx-3"> </span>
                                <span class="header mb-0">Project Template</span>
                                <span data-dismiss="modal" class="material-icons close-icon">
                                  close
                                </span>
                              </div>
                              <hr class="margin-class" />
                              <div class="modal-body">
                                Are you sure want to Delete?
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                                  (click)="deleteRole()">Ok</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset>
        </div>



        <div class="row border-box">
          <div class="table-responsive show">

            <div class="col-md-2 pt-3">
              <label for="Hide/Show" class="example-margin fs-15">Show <span class="required">&#42;</span></label>
            </div>
            <section class="example-section pl-3">
              <mat-checkbox class="example-margin" [checked]="chkHideStoryBoard"
                (change)="seleccionRubrosHideStoryBoard($event)">StoryBoard</mat-checkbox>
              <mat-checkbox class="example-margin" [checked]="chkHideSCM" (change)="seleccionRubrosHideSCM($event)">SCM
              </mat-checkbox>
              <mat-checkbox class="example-margin" [checked]="chkHideDevOps"
                (change)="seleccionRubrosHideDevOps($event)">DevOps</mat-checkbox>
              <mat-checkbox class="example-margin" [checked]="chkHideCollaboration"
                (change)="seleccionRubrosHideCollaboration($event)">Collaboration</mat-checkbox>
              <!-- <mat-checkbox class="example-margin" [checked]="chkHideVDI" (change)="seleccionRubrosHideVDI($event)">VDI
              </mat-checkbox>
              <mat-checkbox class="example-margin" [checked]="chkHideDeployment"
                (change)="seleccionRubrosHideDeployment($event)">Deployment Store</mat-checkbox> -->
              <!-- <mat-checkbox class="example-margin" [checked]="chkHideMonitoring"
                (change)="seleccionRubrosHideMonitoring($event)">Live Monitoring</mat-checkbox> -->
              <mat-checkbox class="example-margin" [checked]="chkHideReports"
                (change)="seleccionRubrosHideReports($event)">Reports</mat-checkbox>
              <!-- Live Monitoring nested  -->
              <div style="margin-top: 27px;">
                <mat-checkbox class="example-margin" [checked]="allCompleteLive" [color]="taskLive.color"
                  [indeterminate]="someCompleteLive()" (change)="setAllLive($event.checked)">
                  {{taskLive.name}}
                </mat-checkbox>
                <div class="tests">
                  <span *ngFor="let subtaskLive of taskLive.subtasksLive">
                    <mat-checkbox class="example-margin-label" [(ngModel)]="subtaskLive.completed"
                      [color]="subtaskLive.color" (ngModelChange)="updateAllCompleteLive()"
                      [ngModelOptions]="{standalone: true}">
                      {{subtaskLive.name}}
                    </mat-checkbox>
                    <br>
                    <!-- <mat-checkbox [(ngModel)]="subtask.completed" *ngIf="subtask.name=='AWS'" [disabled]="true" [color]="subtask.color"
      (ngModelChange)="updateAllComplete()" [ngModelOptions]="{standalone: true}">
      {{subtask.name}}
    </mat-checkbox> -->
                  </span>
                </div>
              </div>
              <!-- VDI nested  -->
              <div style="margin-top: 62px;">
                <mat-checkbox class="example-margin" [checked]="allCompleteVdi" [color]="taskVdi.color"
                  [indeterminate]="someCompleteVdi()" (change)="setAllVdi($event.checked)">
                  {{taskVdi.name}}
                </mat-checkbox>
                <div class="tests">
                  <span *ngFor="let subtaskVdi of taskVdi.subtasksVdi">
                    <mat-checkbox class="example-margin-label" [(ngModel)]="subtaskVdi.completed"
                      [color]="subtaskVdi.color" (ngModelChange)="updateAllCompleteVdi()"
                      [ngModelOptions]="{standalone: true}">
                      {{subtaskVdi.name}}
                    </mat-checkbox>
                    <br>
                    <!-- <mat-checkbox [(ngModel)]="subtask.completed" *ngIf="subtask.name=='AWS'" [disabled]="true" [color]="subtask.color"
      (ngModelChange)="updateAllComplete()" [ngModelOptions]="{standalone: true}">
      {{subtask.name}}
    </mat-checkbox> -->
                  </span>
                </div>
              </div>
              <!-- Deployment store nested  -->
              <div class="ml-2" style="margin-top: 62px;">
                <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                  [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                  {{task.name}}
                </mat-checkbox>
                <div class="tests">
                  <span *ngFor="let subtask of task.subtasks">
                    <mat-checkbox class="example-margin-label" [(ngModel)]="subtask.completed" [color]="subtask.color"
                      (ngModelChange)="updateAllComplete()" [ngModelOptions]="{standalone: true}">
                      {{subtask.name}}
                    </mat-checkbox>
                    <br>
                    <!-- <mat-checkbox [(ngModel)]="subtask.completed" *ngIf="subtask.name=='AWS'" [disabled]="true" [color]="subtask.color"
                    (ngModelChange)="updateAllComplete()" [ngModelOptions]="{standalone: true}">
                    {{subtask.name}}
                  </mat-checkbox> -->
                  </span>
                </div>
              </div>



            </section>



          </div>
        </div>

        <!-- <div class="row border-box">
                  <div class="form-group col-md-1 pt-3">
                    <label for="Hide/Show" class="example-margin fs-15">Enable</label>
                  </div>
                  <section class="example-section">
                    <mat-checkbox class="example-margin" [checked]="chkStoryBoard" [disabled]="DisableStoryBoard" (change)="seleccionRubrosEnbleStoryBoard($event)">StoryBoard</mat-checkbox>
                    <mat-checkbox class="example-margin" [checked]="chkSCM"  [disabled]="DisableSCM" (change)="seleccionRubrosEnbleSCM($event)">SCM</mat-checkbox>
                    <mat-checkbox class="example-margin" [checked]="chkDevOps"  [disabled]="DisableDevOps" (change)="seleccionRubrosEnbleDevOps($event)">DevOps</mat-checkbox>
                    <mat-checkbox class="example-margin" [checked]="chkCollaboration" [disabled]="DisableCollaboration"  (change)="seleccionRubrosEnbleCollaboration($event)">Collaboration</mat-checkbox>
                    <mat-checkbox class="example-margin" [checked]="chkVDI"  [disabled]="DisableVDI" (change)="seleccionRubrosEnbleVDI($event)">VDI</mat-checkbox>
                    <mat-checkbox class="example-margin" [checked]="chkDeployment" [disabled]="DisableDeployment"  (change)="seleccionRubrosEnbleDeployment($event)">Deployment Store</mat-checkbox>
                   
                  </section>
                </div> -->

        <!-- <div class="row">
                  <div class="offset-md-6 col-md-3">
                    <button mat-button mat-raised-button class="hover users-button width-100"
                    title="Create Template" (click)="CreateTemplate()">Create Template</button>
                </div>
                <div class="col-md-1">
                  <button mat-button mat-raised-button class="hover users-button width-100"
                  title="Create Template">Reset</button>
              </div>
              <div class="col-md-1">
                <button mat-button mat-raised-button class="hover users-button width-100"
                title="Create Template">Cancel</button>
            </div>
              </div> -->
        <div class="offset-md-6 col-md-6">
          <div *ngIf="showDiv" class="alert alert-danger">
            {{errorMsg}}
          </div>
        </div>

        <div class="col-12 text-right">


          <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" (click)="CheckTemplateDuplication()"
            [disabled]="CreateTemplateDisable" class="btn-canvas-blue mr-2">
            Create Template
          </button>
          <button *ngIf="editMode && !deletebutton" type="button" (click)="updateTemplate()"
            class="btn-canvas-blue mr-2">
            Update Template
          </button>
          <button *ngIf="deletebutton" type="button" class="btn-canvas-blue mr-2" (click)="deleteRecord()"
            data-toggle="modal" data-target="#confirmationModal3">
            Delete Template
          </button>
          <!-- ///////// Modal//// -->
          <!-- <div class="modal fade "  id="confirmationModal3" tabindex="-1" role="dialog" aria-labelledby="confirmationModal3" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                    <span  class="vertical-line mx-3"> </span>
                     <span class="header mb-0">Template config</span> 
                    <span  data-dismiss="modal" class="material-icons close-icon">
                        close
                      </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                    Are you sure want to Delete?
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                  </div>
                </div>
              </div>
            </div> -->

          <!-- /////////// -->

          <!-- <button (click)="CreateTemplateReset()"  type="button" class="btn-canvas-blue mr-2" >
                  Reset
                  </button> -->
          <!--button type="button" (click)="CreateTemplateCancel()" class="btn-canvas-blue">
            Cancel
          </button-->
          <button class="mr-2 btn-canvas-blue" data-toggle="modal" *ngIf="!storyBoardForm.form.pristine"
            data-target="#confirmationModal5">Cancel</button>
          <button class="mr-2 btn-canvas-blue" *ngIf="storyBoardForm.form.pristine"
            (click)="CreateTemplateCancel()">Cancel</button>

        </div>
        <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal5"></button>

        <div class="modal fade " id="confirmationModal5" tabindex="-1" role="dialog"
          aria-labelledby="confirmationModal5" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
              <div class="modal-header-popup">
                <span class="vertical-line mx-3"> </span>
                <span class="header mb-0"> Template Information</span>
                <span data-dismiss="modal" class="material-icons close-icon">
                  close
                </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                Are you sure want to cancel?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>

    <div class="mt-2 table-container" *ngIf="TablePage">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let domain of domainArray">
              <td>{{domain.template_id}}</td>
              <td>{{domain.template_name}}</td>
              <td>{{domain.template_description}}</td>
              <td style="padding: 5px;">
                <span class="table-crud table-delete-icon" title="Update" (click)="getRecord(domain,'edit')">

                  <img width="20" src="../../../assets/img/pencil.png"></span>
                <span class="table-crud table-delete-icon px-4" title="Delete" (click)="getRecord(domain,'delete')">
                  <img width="20" src="../../../assets/img/Delete-blue.png"></span>
                <!-- <span  class="table-crud table-delete-icon px-4" title="Delete"  (click)="deleteRecord(domain)" data-toggle="modal" data-target="#confirmationModal2" >
                <img width="15"  src="../../../assets/img/Delete-blue.png"></span> -->

                <!-- ///////// Modal//// -->
                <!-- <div class="modal fade "  id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content pop-up">
                        <div class="modal-header-popup">
                          <span  class="vertical-line mx-3"> </span>
                           <span class="header mb-0">Template config</span> 
                          <span  data-dismiss="modal" class="material-icons close-icon">
                              close
                            </span>
                        </div>
                        <hr class="margin-class" />
                        <div class="modal-body">
                          Are you sure want to Delete?
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig(domain)">Ok</button>
                        </div>
                      </div>
                    </div>
                  </div>
                 -->
                <!-- /////////// -->


                <!-- <span  (click) = "deleteRecord()" data-toggle="modal" data-target="#confirmationModal2">
                  <i class="fas fa-trash"></i> Delete</span> -->

                <span class="table-crud table-delete-icon" title="Template Information" (click)="showImageInfo(domain)">
                  <img width="20" src="../../../assets/img/info.jpg"></span>
                <!-- <span style="font-size: 18px;" class="material-icons add-user"
             
              title="Template Information" (click)="showImageInfo(domain)">info</span> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal fade "  id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal3" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content pop-up">
        <div class="modal-header-popup">
          <span  class="vertical-line mx-3"> </span>
           <span class="header mb-0">Template config</span> 
          <span  data-dismiss="modal" class="material-icons close-icon">
              close
            </span>
        </div>
        <hr class="margin-class" />
        <div class="modal-body">
          Are you sure want to Delete?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig(domain)">Ok</button>
        </div>
      </div>
    </div>
  </div> -->