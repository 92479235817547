<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a (click)="goHome(f.form)">Admin Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">VM Images</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
        
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto">
        <a class="float-right step-back" (click)="toggleDisplay(f.form)">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div>
    </div> 


    <h1 class="text-light mb-0">
      <img width="40" src="assets/img/VM-configuration-White.svg">
      <div class="vertical-line mx-3"> </div> <span>VM Images</span>
    </h1>

  </div>

  <div class="inner-mid-container">
    <div class="row mt-3">
      <div class="col-md-12 p-0">
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
         
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group ">
                <label>Azure ID</label>
                <!-- (change)="setSelectedStatus(model.Subscription)" -->
                <select id="Subscription" name="Subscription" class="form-control form-control-lg" required
                  [(ngModel)]="model.Subscription" #Subscription="ngModel"
                 
                  (change)="onChange($event)"
                  [ngClass]="{ 'is-invalid': f.submitted && Subscription.invalid }">
                  <!-- <option value="">Choose Azure ID</option> -->
                  <option value="" disabled selected hidden>Select Azure ID</option>
                  <option *ngFor="let s of SubscriptionList" [value]="s.subscription_id">{{s.tool_id}}
                  </option>
                </select>

                <div *ngIf="f.submitted && Subscription.invalid" class="alert alert-danger">
                  <div *ngIf="Subscription.errors.required">Azure ID is required.</div>
                </div>
              </div>
            </div>
            <div class="position-relative col-sm-1 pt-5 img-width">
              <span *ngIf="this.vdi_Azure_Id" style="font-size: 18px;cursor:pointer;" class="material-icons add-user"
                title="System Information" data-toggle="modal" data-target="#AzureDetailsModal">info</span>



              <!-- Modal -->
              <div class="modal fade pop-up" id="AzureDetailsModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="info-header">
                      <span  class="vertical-line mx-3"> </span>
                       <span class="header mb-0"> Azure ID Details</span> 
                      <span  type="button"  data-dismiss="modal" class="material-icons close-icon">
                          close
                        </span>
                    </div>
                    <div class="modal-body popup-container">
                      <div class="row">
                        <div class="col-md-4">
                            <span class="field-name">Azure ID  </span>
                        </div>
                        <div class="col-md-8">
                                <span> : &nbsp;{{this.vdi_Azure_Id}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                            <span class="field-name">Subscription ID  </span>
                        </div>
                        <div class="col-md-8">
                                <span> : &nbsp;{{this.vdi_Subscription_ID}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                            <span class="field-name">Tenant ID  </span>
                        </div>
                        <div class="col-md-8">
                                <span> : &nbsp;{{this.vdi_tenantId}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                            <span class="field-name">Connection Name </span>
                        </div>
                        <div class="col-md-8">
                                <span> : &nbsp;{{this.vdi_Azure_ConnectionName}}</span>
                        </div>
                      </div>
                     
                      <div class="row">
                        <div class="col-md-4">
                            <span class="field-name">Tool Group Name  </span>
                        </div>
                        <div class="col-md-8">
                                <span> : &nbsp;{{this.Vdi_ToolGr_Name}}</span>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7 text-right pt-5">
              <button (click)="vdiGetImage()" class="btn-canvas-blue mr-2">
                Get VM Image
              </button>
              <button (click)="VMSaveData()" class="btn-canvas-blue mr-2">Save</button>
              <button class="btn-canvas-blue mr-2"  (click)="toggleDisplay(f.form)">Cancel</button>

            </div>
          </div>


         

        </form>

        <!-- Modal -->
        <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
        <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
            aria-labelledby="confirmationModal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content pop-up">
                    <div class="modal-header-popup">
                        <span class="vertical-line mx-3"> </span>
                        <span class="header mb-0">VM Images</span>
                        <span data-dismiss="modal" class="material-icons close-icon">
                            close
                        </span>
                    </div>
                    <hr class="margin-class" />
                    <div class="modal-body">
                        Are you sure want to cancel?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-canvas-blue-2"
                            data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                    </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
            </div>
        </div>
       <!-- Modal -->

        <!-- ############ Table For Images ########### -->

        <div *ngIf="showTable">
          <div class="my-5">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table fixed-table">
              <thead>
                <tr>
                  <th style="width: 24%;">Name</th>
                  <th style="width: 10%;">Location</th>
                  <th style="width: 16%;">Type</th>
                  <th style="width: 8%;">VM OS</th>
                  <th style="width: 8%;">VM Server</th>
                  <th style="width: 10%;">VM Type</th>
                  <th style="width: 12%;">Description</th>
                  <th style="width: 8%;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let resource of tableResourceArray;let i = index">
                  <td style="width: 24%;">{{resource.name}}</td>
                  <td style="width: 10%;">{{resource.location}}</td>
                  <td style="width: 16%;">{{resource.type}}</td>
                  <td style="width: 8%;">{{resource.vmos}}</td>
                  <td style="width: 8%;">{{resource.vmse}}</td>
                  <td style="width: 10%;">{{resource.vmty}}</td>
                  <td style="width: 12%;">{{resource.image}}</td>
                  <td style="width: 8%;">
                    <span
                    class="hover" 
                    mwlConfirmationPopover
                    [popoverTitle]="popoverTitle"
                    [popoverMessage]="popoverMessage"
                    placement="top"
                    (confirm)="deleteVDI(i)"
                    (cancel)="cancelClicked = true"
                  >
                    <i class="fa fa-trash table-icon color-danger"
                    aria-hidden="true"></i>
              </span>
              
                </td>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>