<div class="dialogue">
  
    <div class="inner-top-container">
        <div class="row">
            <div class="col-sm-10 pt-3">
                <span  class="vertical-line mx-3"> </span>
               <span class="header mb-0">VM Image Details</span> 
              </div>
            <div class="col-sm-2 text-right pt-3">
                <span (click)="onNoClick()" class="material-icons" style="float: right;cursor: pointer;">
                    close
                </span>
            </div>
         </div>
         <hr class="margin-class" />
         <div class="inner-page">
            <div class="row mb-2">
                <div class="col-sm-4 field-name-VM">VM Image ID</div>
                <div class="col-sm-8 field-value"> : {{image.vm_image_id}}</div>
            
            </div>
            <div class="row mb-2">
                <div class="col-sm-4 field-name-VM">VM OS</div>
                <div class="col-sm-8 field-value">: {{image.vm_os}}</div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-4 field-name-VM"> VM Server</div>
                <div class="col-sm-8 field-value">: {{image.vm_server}}</div>
            </div>
            <div class="row">
                <div class="col-sm-4 mb-2 field-name-VM"> VM Type</div>
                <div class="col-sm-8 field-value">  : {{image.vm_type}}</div>
               
            </div>
         </div>
               
          
        <!--<div class="row">
            <div class="col-sm-4 img-margin" *ngFor="let img of image">
                    <div class="text-center"><img class="w40" src="{{img.software_image_path}}"></div>
                    <div  class="text-center">{{img.software_name}}</div>
                    <div  class="text-center"><span>Version - </span>{{img.software_version}}</div>
        
        </div>
        </div> -->
        
    </div>
</div>