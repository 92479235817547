<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

<div class="inner-page-container">
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a (click)="goHome(secondFormGroup)">Home</a></li>
            <li class="breadcrumb-item link-blue"><a (click)="goWorkBeanch(secondFormGroup)">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Project Role</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span  *ngIf="addRoleDisable && !addRoleDisableSecond" (click)="showAddRoleMethod()"><i class="fas fa-plus"></i> Add</span>
          <span class="disabled-img" *ngIf="!addRoleDisable || addRoleDisableSecond"><i class="fas fa-plus"></i> Add</span>
          <span (click)="getRoles()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
  
  </div>

  <div class="inner-mid-container">
    <div class="row mb-4">
      <div class="col-sm-3">
        <h1 class="page-name mb-0">
          <img width="40" src="../../../assets/img/project-role.png">
          <div class="vertical-line mx-3"> </div> <span>Project Role</span> 
          <!-- <span style="font-size: 18px;" class="material-icons show-project-info" title="Role Information" data-toggle="modal" data-target="#myModal">info</span>      -->
        </h1>
        
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
        {{projectId}}</span>
        <span style="font-size: 18px;" class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
      </div>
    </div>

    <div *ngIf="showAddRole">

      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row align-items-center">
          
          <div class="col-12 col-md-6 col-lg-3">
            <div class="form-group">
              <label for="roleType">Select Role</label>
              <select id="roleType" name="roleType" class="form-control form-control-height"
                required formControlName="roleType" [class.is-invalid]="roleType.invalid && roleType.touched">
                <option value="" disabled selected hidden>Select Role</option>
                <option *ngFor="let role of selectRoleArray" [ngValue]="role">{{role.template_role_name}}</option>
              </select>
              <small class="text-danger" [class.d-none]="roleType.valid || roleType.untouched">Role selection is
                required</small>
              
            </div>
          </div>
  
          <div class="col-12 col-md-1 mt-1 mr-2 mb-3 text-center img-width">
            <span class="material-icons add-user" (click)="openUser()" title="Add User">
              person_add
            </span>
          </div> 
  
          <div class="col-12 text-center col-md-1 mt-2 mb-4 mr-4">
            <button class="hover width-100 users-button" mat-button mat-raised-button (click)="addRowApi()"
              [disabled]="!secondFormGroup.valid " title="Add Role">
             Add Role
            </button>
          </div>
  
          <div class="col-12 text-center col-md-1 mt-2 mb-4">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2" (click)="toggleDisplay(secondFormGroup)">Cancel</button>
            
          </div>
        </div>
      </form>
    </div>

    <div class="row userRow" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">
        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>

    <div class="table-responsive scroll" >
      <table class="table fixed-table mt-4" datatable *ngIf="tableArr.length>0" [dtOptions]="dtOptions">
        <thead>
          <tr>
          <th>Role Name</th>
          <th>Privilege Access</th>
          <th>Members</th>
          <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let val of tableArr">
            <!-- <td *ngIf="val.role_name == 'Secondary Project Manager'">Project Manager</td>
            <td *ngIf="val.role_name != 'Secondary Project Manager'">{{val.template_role_name}}</td> -->
            <td>{{val.template_role_name}}</td>
            <td>{{val.privilege_access}}</td>
            <td *ngIf="val.members.length > 0;">
              <mat-chip-list  #chipList aria-label="Fruit selection" *ngFor="let user of val.members; let i = index">
                <div *ngIf="i < 2">
                  <mat-chip class="chip-format">{{user.user_name}}
                   
                  </mat-chip>
                </div>
                  <div class="link-col" *ngIf="i === 2" ><span  (click)="openAddedUserDialog(val.role_name,val.template_role_name,val.members,val.privilege_access,'View')">View More </span></div>
                   
             
              </mat-chip-list>
            </td>
            <td *ngIf="val.members.length==0">--NA--</td>
            <td >
              <a href="#" class="nav-link" data-toggle="dropdown" *ngIf="val.privilege_access=='Primary Group Owner'" style="pointer-events: none;" >
                <i class="fas fa-ellipsis-h" style="color:rgba(0, 0, 0, 0.86);"></i>
              </a>
              <div class="dropdown" *ngIf="val.privilege_access!='Primary Group Owner'">
                <a href="#" class="nav-link" data-toggle="dropdown">
                  <i class="fas fa-ellipsis-h"></i>
                </a>
                <div class="dropdown-menu more-drop-down">
                  <a class="dropdown-item" (click)="openAddedUserDialog(val.role_name,val.template_role_name,val.members,val.privilege_access,'Delete')">
                  <img width="20" src="../../../assets/img/View-Team-member.png"> <span class="vertical-line-more mx-2"></span> Delete Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="openUserForRole(val.role_name,val.template_role_name,val.members,val.privilege_access)">
                    <img width="20" src="../../../assets/img/Add-Team-member.png"> <span class="vertical-line-more mx-2"></span>  Add Member</a>
                    <div class="dropdown-divider" *ngIf="projectCreatedBy == profile && val.role_name == 'Secondary Project Manager'"></div>
                    <a class="dropdown-item" *ngIf="projectCreatedBy == profile && val.role_name == 'Secondary Project Manager'" (click)="updatePM(val.role_name,val.members)">
                      <img width="20" src="../../../assets/img/View-Team-member.png"> <span class="vertical-line-more mx-2"></span> Update Primary Manager</a>
                </div>
              </div>
          </td>


          </tr>
        </tbody>
      </table>

      <table class="table fixed-table mt-4" datatable *ngIf="tableArr.length==0" [dtOptions]="dtOptions">
        <thead>
          <tr>
          <th>Role Name</th>
          <th>Privilege Access</th>
          <th>Action</th>
          </tr>
        </thead>
        <tbody>

        </tbody>
      </table>
    </div>

  </div>
  <!-- <footer>
    <p>Powered By &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br>
        <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" > -->
       <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
      <!-- </p>
  </footer> -->

</div>

<!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

  <!-- Modal content-->
  <div class="modal-content">
    <div class="modal-body">
      <div class="mt-2">
        <table datatable class="table fixed-table" [dtOptions]="dtOptions">
          <thead>
            <tr>
              <th>Role</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rol of configList">
              <td>{{rol.Roles}}</td>
              <td>{{rol.Details}}</td>
            </tr>
          </tbody>
        </table>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
    </div>
  </div>
</div>


