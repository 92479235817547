<div class="pop-up">
    <span  class="vertical-line mx-1 mx-md-3"> </span>
     <span class="header mb-0"> Observation Details</span> 
    <span (click)="exit()" class="material-icons close-icon">
        close
      </span>

<hr class="margin-class" />
<div class="popup-container">
    <!-- AWS CloudTrail Event code start here -->
    <div  *ngIf="modalType == 'AWS CloudTrail Event'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Event</span></div>
            <div class="col-md-9"><span> : {{alertInfo.event}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Event ID </span></div>
            <div class="col-md-9"><span> : {{alertInfo.event_id}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Resource Type </span></div>
            <div class="col-md-9"><span> : {{alertInfo.resource_type}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> User Name</span></div>
            <div class="col-md-9"><span> : {{alertInfo.user_name}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Account ID</span></div>
            <div class="col-md-9"><span> : {{alertInfo.account_id}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">IP Address</span></div>
            <div class="col-md-9"><span> : {{alertInfo.ip_address}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Authority</span></div>
            <div class="col-md-9"><span> : {{alertInfo.authority}} </span></div>
        </div>
    </div>
     <!-- AWS CloudTrail Event code end here -->

      <!-- Azure Advisor Recommendation code start here -->
    <div  *ngIf="modalType == 'Azure Advisor Recommendation'">
        <div class="row ">
            <div class="col-md-4"><span class="field-name"> Recommendation ID</span></div>
            <div class="col-md-8"><span> : {{alertInfo.recommendation_id}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name">Recommendation Type ID</span></div>
            <div class="col-md-8"><span> : {{alertInfo.recommendation_type_id}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name"> Affected Resource </span></div>
            <div class="col-md-8"><span> : {{alertInfo.affected_resource}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name"> Device</span></div>
            <div class="col-md-8"><span> : {{alertInfo.device}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name"> Description</span></div>
            <div class="col-md-8"><span> : {{alertInfo.description}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name">Affected Resource Type</span></div>
            <div class="col-md-8"><span> : {{alertInfo.affected_resource_type}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-4"><span class="field-name">Impact</span></div>
            <div class="col-md-8"><span> : {{alertInfo.impact}} </span></div>
        </div>
    </div>
    <!--Azure Advisor Recommendation code end here -->
    
     <!-- Multiple Access Failures code start here -->
     <div  *ngIf="modalType == 'Multiple Access Failures'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Port</span></div>
            <div class="col-md-9"><span> : {{alertInfo.port}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Profile </span></div>
            <div class="col-md-9"><span> : {{alertInfo.profile}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Connected IP </span></div>
            <div class="col-md-9"><span> : {{alertInfo.connected_ip}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Failed Attempts</span></div>
            <div class="col-md-9"><span> : {{alertInfo.failed_attempts}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Connected External</span></div>
            <div class="col-md-9"><span> : {{alertInfo.connected_is_external}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Connected Device</span></div>
            <div class="col-md-9"><span> : {{alertInfo.connected_device}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Connected IP Country Code</span></div>
            <div class="col-md-9"><span> : {{alertInfo.connected_ip_country_code}} </span></div>
        </div>
    </div>
     <!-- Multiple Access Failures code end here -->
   
      <!-- Persistent External Server code start here -->
      <div  *ngIf="modalType == 'Persistent External Server'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.end_time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> External IP </span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> New Tag </span></div>
            <div class="col-md-9"><span> : {{alertInfo.new_tag}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> New Ports</span></div>
            <div class="col-md-9"><span> : {{alertInfo.new_ports}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Bytes In</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Bytes Out</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_out}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">External IP Country Code</span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip_country_code}} </span></div>
        </div>
    </div>
     <!--Persistent External Server code end here -->

       <!--New External Server code start here -->
    <div  *ngIf="modalType == 'New External Server'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.end_time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> External IP </span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> New Tag </span></div>
            <div class="col-md-9"><span> : {{alertInfo.new_tag}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> New Ports</span></div>
            <div class="col-md-9"><span> : {{alertInfo.new_ports}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Bytes In</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Bytes Out</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_out}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">External IP Country Code</span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip_country_code}} </span></div>
        </div>
    </div>

     <!-- New External Server code end here -->
       <!-- Anomalous Profile code start here -->
       <div  *ngIf="modalType == 'Anomalous Profile'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.end_time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Anomaly Type </span></div>
            <div class="col-md-9"><span> : {{alertInfo.anomaly_type}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Bytes In </span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Bytes Out</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_out}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Connection Count</span></div>
            <div class="col-md-9"><span> : {{alertInfo.conns_count}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Days Active</span></div>
            <div class="col-md-9"><span> : {{alertInfo.days_active}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">New Ports</span></div>
            <div class="col-md-9"><span> : {{alertInfo.new_ports}} </span></div>
        </div>
    </div>
        <!-- Anomolous Profile code end here -->


     <!-- Azure Permissive Storage Setting'  code start here -->
     <div  *ngIf="modalType == 'Azure Permissive Storage Setting'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Name</span></div>
            <div class="col-md-9"><span> : {{alertInfo.name}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Description</span></div>
            <div class="col-md-9"><span> : {{alertInfo.description}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Resource Id</span></div>
            <div class="col-md-9"><span> : {{alertInfo.resource_id}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Location</span></div>
            <div class="col-md-9"><span> : {{alertInfo.location}} </span></div>
        </div>
  
    </div>
        <!-- Azure Permissive Storage Setting'  code end here -->

    <!-- Azure VM in Unused Location code start here -->

    <div  *ngIf="modalType == 'Azure VM in Unused Location'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Resource</span></div>
            <div class="col-md-9"><span> : {{alertInfo.resource_id}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Location</span></div>
            <div class="col-md-9"><span> : {{alertInfo.location}} </span></div>
        </div>
     
  
    </div>




    <!-- Azure VM in Unused Location code end here -->


    <!-- Azure Security Event code start here -->
    <div  *ngIf="modalType == 'Azure Security Event'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Resource Name</span></div>
            <div class="col-md-9"><span> : {{alertInfo.resource_name}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Resource Type</span></div>
            <div class="col-md-9"><span> : {{alertInfo.resource_type}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Severity</span></div>
            <div class="col-md-9"><span> : {{alertInfo.severity}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Intent</span></div>
            <div class="col-md-9"><span> : {{alertInfo.intent}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Caller IPS</span></div>
            <div class="col-md-9"><span> : {{alertInfo.caller_ips}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Context</span></div>
            <div class="col-md-9"><span> : {{alertInfo.context}} </span></div>
        </div>
  
    </div>



    <!-- Azure Security Event code end here -->


        <!-- Bad Protocol code start here -->
        <div  *ngIf="modalType == 'Bad Protocol'">
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> End Time</span></div>
                <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Connected IP</span></div>
                <div class="col-md-9"><span> : {{alertInfo.connected_ip}} </span>    </div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Violating Port</span></div>
                <div class="col-md-9"><span> : {{alertInfo.violating_port}} </span></div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Packets In</span></div>
                <div class="col-md-9"><span> : {{alertInfo.packets_in}} </span>    </div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Packets Out</span></div>
                <div class="col-md-9"><span> : {{alertInfo.packets_out}} </span></div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Bytes In</span></div>
                <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span>    </div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Bytes Out</span></div>
                <div class="col-md-9"><span> : {{alertInfo.bytes_out}} </span></div>
            </div>
            <div class="row ">
                <div class="col-md-3"><span class="field-name"> Capture</span></div>
                <div class="col-md-9"><span> : {{alertInfo.pcap_url}} </span></div>
            </div>
      
        </div>

            <!-- Bad protocol code end here -->

            <!-- External Port Scanner code start here -->
            <div  *ngIf="modalType == 'External Port Scanner'">
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> End Time</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Scanner IP</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.scanner_ip}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Scanned IP</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.scanned_ip}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Scan Type</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.scan_type}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Port Count</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.port_count}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Port Ranges</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.port_ranges}} </span>    </div>
                </div>
             
          
            </div>
            <!-- External Port Scanne code end here -->

            <!-- Heartbeat code start here -->
            <div  *ngIf="modalType == 'Heartbeat'">
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> End Time</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Remote IP</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.remote_ip}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Remote Port</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.remote_port}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Protocol</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.protocol}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name">Number of Hearbeats</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.count}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Hearbeat Period (sec)</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.period_seconds}} </span>    </div>
                </div>
             
          
            </div>
            <!-- Hearbeat code end here -->

            <!-- New internal device code start here -->

            <div  *ngIf="modalType == 'New Internal Device'">
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Time</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Bytes In</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Bytes Out</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.bytes_out}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Packets In</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.packets_in}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name">Packets Out</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.packets_out}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name">Past days</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.lookback_start_time}} </span>    </div>
                </div>
             
          
            </div>

            <!-- New internal device code end here -->

            <!-- Remote Access Server code start here -->
            
            <div  *ngIf="modalType == 'Remote Access'">
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Time</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Local Port</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.local_port}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Affected Resource</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.affected_resource}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Remote IP</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.remote_ip}} </span>    </div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name">Connected Device"</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.connected_device}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name">Remote IP Country Code</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.remote_ip_country_code}} </span>    </div>
                </div>
             
          
            </div>
            <!-- Remote Access Server code end here -->

            <!-- Unusual DNS Resolver' code start here -->
              <div  *ngIf="modalType == 'Unusual DNS Resolver'">
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Time</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.time}} </span></div>
                </div>
                <div class="row ">
                    <div class="col-md-3"><span class="field-name"> Remote Device</span></div>
                    <div class="col-md-9"><span> : {{alertInfo.connected_device}} </span>    </div>
                </div>
                </div>
            <!-- Unusual DNS Resolver' code end here -->









       <!-- Watchlist Interaction code start here -->
    <div  *ngIf="modalType == 'Watchlist Interaction'">
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Names</span></div>
            <div class="col-md-9"><span> : {{alertInfo.names}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Watchlist Types </span></div>
            <div class="col-md-9"><span> : {{alertInfo.watchlist_types}} </span>    </div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Domains</span></div>
            <div class="col-md-9"><span> : {{alertInfo.domains}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name"> Internal IP</span></div>
            <div class="col-md-9"><span> : {{alertInfo.internal_ip}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">External IP</span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">End Time</span></div>
            <div class="col-md-9"><span> : {{alertInfo.end_time}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">Bytes In</span></div>
            <div class="col-md-9"><span> : {{alertInfo.bytes_in}} </span></div>
        </div>
        <div class="row ">
            <div class="col-md-3"><span class="field-name">External IP Country Code</span></div>
            <div class="col-md-9"><span> : {{alertInfo.external_ip_country_code}} </span></div>
        </div>
    </div>
     <!-- Watchlist Interaction code end here -->
</div>
</div>