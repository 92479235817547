import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { AuthService } from './service/api.service';
import { MsalService } from '@azure/msal-angular';
import { AlertService } from 'src/app/_alert';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogVminfoDialog } from './project-configurations/vdi-details/vdi-details.component';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  tokenExpired: boolean = false;
  userMailId: any;
  tokenCount = 0;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  popUp: string;
  expiry: number;
  popUpActive: boolean = false;

  constructor(private injector: Injector, public apiService: AuthService, private authService: MsalService, private alertService: AlertService, public dialog: MatDialog) { 
    // console.log("ff");
    // debugger
    
  }

  intercept(req, next) {
    // debugger

    // let authService=this.injector.get(AuthService).getToken()
    //  let tokenized=req.clone({
    //    setHeaders: { 
    //      Authorization: `Bearer ${authService}`
    //    }
    //  })
    this.userMailId = localStorage.getItem("mail")
    
    if (localStorage.getItem('token') != null) {
      try {
        this.expiry = (JSON.parse(atob(localStorage.getItem('token').split('.')[1]))).exp;
      }
      catch {
        return throwError('Data not found');
      }
      if ((Math.floor((new Date).getTime() / 1000)) <= this.expiry-60) {
        this.tokenExpired = false;
        //console.log(this.tokenCount,"tokenNotExpire");
        localStorage.setItem("isPopOpen", "false")
        req = this.addToken(req, localStorage.getItem("token"), "ini");
        // return true; 
      }
      else {
        this.tokenExpired = true;
        this.tokenCount += 1;
        localStorage.setItem("isPopOpen", "true")

      }
    }
    //console.log(localStorage.getItem("popup"));

    this.popUp = localStorage.getItem("isPopOpen")
    //console.log(this.popUp)


    if (this.popUp == "true" && (Math.floor((new Date).getTime() / 1000)) >= this.expiry-60 && this.popUpActive == false) {
     //console.log("test------------", this.tokenCount);
      this.apiService.modalType.next("tokenexpire");
      this.popUpActive = true;
      const dialogRef = this.dialog.open(DialogVminfoDialog, {
        width: '51%',
        height: '40%',
        disableClose: true
      });
      
      dialogRef.afterClosed().subscribe(
        data => {
          if (data == 'Yes') {
            let accessBody = {
              "user_mail": this.userMailId
            }
            // console.log(window.location.hash);
            // debugger
            const adConnnectorFlag = localStorage.getItem("onboardID");
            if (adConnnectorFlag != "undefined" || window.location.hash=='#/VdiRequestForm') {
              // debugger
              this.apiService.generateTokengig(accessBody).subscribe(
                (data: any) => {
                  // debugger

                  localStorage.setItem("token", data.token)
                  this.tokenCount = 0;
                  localStorage.setItem("isPopOpen", "false")             
                  next.handle(this.addToken(req, data.token, "gen"));
                  this.popUpActive = false;
                  window.location.reload();
                  // console.log("tokennOne");
  
                  // window.location.reload();
  
                  // this.router.navigate(["/admin-gig"])
                },
                (error: HttpErrorResponse) => {
                  if (error.status == 432) {
                    this.alertService.error(error.error.message, this.options);
                  } else {
                    this.alertService.error(
                      "Error generating token.",
                      this.options
                    );
                    throw error;
                  }
                }
              );
            }
            else if(adConnnectorFlag == "undefined" || window.location.hash!='#/VdiRequestForm'){
            this.apiService.generateToken(accessBody).subscribe((data: any) => {
              localStorage.setItem("token", data.token)
              this.tokenCount = 0;
              localStorage.setItem("isPopOpen", "false")             
              next.handle(this.addToken(req, data.token, "gen"));
              this.popUpActive = false;
              window.location.reload();
            }, (error: HttpErrorResponse) => {
              if (error.status == 432){
              this.alertService.error(error.error.message, this.options);
               }
              else {
              this.alertService.error("Error generating token.", this.options);
              throw error;
               }
              
               })
              }
            //localStorage.setItem("popup", "0")
           // console.log(localStorage.getItem("popup"));
            
           
          }
          else {
            //code start - after the logout to clear all local storage
            this.tokenCount = 0;

            localStorage.clear();
            sessionStorage.clear();

            //code end - after the logout to clear all local storage

            this.authService.logout();
            this.apiService.projectKey.next("");

            localStorage.removeItem('profile')

          }
          
        }

      );


    }
    
    
      
      return next.handle(req)

  }
  private addToken(request, token: string, type) {
    //console.log("in add", type);

    return request.clone({
      setHeaders: {
        'Authorization': `Bearer Bearer ${token}`,
        'user_mail': localStorage.getItem("mail").toLowerCase()
      }
    });
  }
}


