<!-- New code-->
<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
             <!-- <li class="breadcrumb-item"><a routerLink="/admin-scm-tool">SCM Tool</a></li>-->
              <li class="breadcrumb-item active" aria-current="page">AWS Config</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 col-md-5 text-right">
          <div class="table-options pt-3">
            <span (click)="addGitlab()"><i class="fas fa-plus"></i> Add</span>
            <span (click)="refreshAws()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <a routerLink="/admin-catalog-config" class="float-right step-back">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
            
          </div>
        </div>
        <!-- <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div> -->
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/AWS.png">
          <div class="vertical-line mx-3"> </div> <span>AWS Config</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
      <div *ngIf="showGitlabForm" class="git-Lab-Form">
        <h2 *ngIf="!editMode">New Aws Instance</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
        <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="toolGr">Tool Group <span class="required">&#42;</span></label>
              <select id="toolGr" name="toolGr" class="form-control form-control-lg" required
              (change)="onChange($event)" [disabled]="ToolGroupDisable"
                [(ngModel)]="toolsGroup.toolGr" #toolGr="ngModel">
                <option value="" disabled selected hidden>Select Tool Group</option>
                <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}</option>
              </select>
              <div *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
                <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="toolGr">&nbsp;</label>
              <div>
                <a class="pt-24 link-col" (click)="openDialog()">
                  <u>Add New Tool Group</u>
                </a>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="nameInstance"> Name <span class="required">&#42;</span></label>
              <input id="nameInstance" name="nameInstance" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.nameInstance" [disabled]="ToolInstanceNameDisable" #nameInstance="ngModel"
                (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
                class="alert alert-danger">
                <div *ngIf="nameInstance.errors.required">
                  Name is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="description">Description <span class="required">&#42;</span></label>
              <input id="description" name="description" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.description" #description="ngModel">
              <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
                <div *ngIf="description.errors.required">
                  Description is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
                <label for="proBundles">Bundles <span class="required">&#42;</span></label>
                <select id="proBundles" name="proBundles" class="form-control form-control-lg" required 
                [(ngModel)]="toolsGroup.proBundles" #proBundles="ngModel">
                <option value="" disabled selected hidden>Select Bundles</option>
               
                <option *ngFor="let b of BundlesArr" [value]="b">{{b}}</option>
              </select>
  
              <div *ngIf="proBundles.invalid && (proBundles.dirty || proBundles.touched)" class="alert alert-danger">
                <div *ngIf="proBundles.errors.required">
                    Bundles is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="proDirectories">Directories <span class="required">&#42;</span></label>
              <select id="proDirectories" name="proDirectories" class="form-control form-control-lg" required 
                [(ngModel)]="toolsGroup.proDirectories" #proDirectories="ngModel">
                <option value="" disabled selected hidden>Select Directories</option>
               
                <option *ngFor="let d of DirectoriesArr" [value]="d">{{d}}</option>
              </select>
  
  
              <div *ngIf="proDirectories.invalid && (proDirectories.dirty || proDirectories.touched)" class="alert alert-danger">
                <div *ngIf="proDirectories.errors.required">
                    Directories is required.
                </div>
              </div>
            </div>
          </div>
         
          <div class="row">
            <div class="col-md-6">
            
            </div>
            <div class="col-md-6 text-right">
  
             
              <button *ngIf="!editMode"  type="button" class="btn-canvas-blue mr-2" (click)="CheckDuplication()">
                Save
              </button>
              <button *ngIf="editMode" [disabled]="UpdateConnDisable" type="button" class="btn-canvas-blue mr-2" (click)="updateGitLab()">
                Update
              </button>
              <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeGitlab()">
                Cancel
              </button>
            </div>
          </div>
  
  
        </form>
      </div>
      <div class="my-5 table-container">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
            
              <th>ID</th>
              <th>Connection Name</th>
              <!-- <th>Tool Type</th> -->
              <th>Bundles</th>
              <th>Tool Group</th>
            </tr>
          </thead>
          <tbody class="data-table-height">
            <tr *ngFor="let AWS of AWSArray">
             
              <td><span class="link-col" (click)="getRecord(AWS)">{{AWS.ID}}</span></td>
              <td>{{AWS.name}}</td>
              <!-- <td>{{SCM.toolName}}</td> -->
              <td>{{AWS.proBundles}}</td>
              <td>{{AWS.toolGroup}}</td>
            </tr>
  
          </tbody>
        </table>
         </div>
      </div>
  
    </div>
  </div>
  
  <!--New code close-->
  
  