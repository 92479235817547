<div class="pop-up"   >
    <div class="" >
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0"> Compute Details:</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />

    <div class="popup-container">
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">SSD root volume GB:</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.SSD_root_volume_GB || "-" }}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">SSD temporary local storage GB: </span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.SSD_temporary_local_storage_GB || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">SSD User  storage GB :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.SSD_user_storage_GB || "-"}}</span>
            </div>
        </div>
      


        
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Memory Gib :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.memory_GiB || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">vCPU :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.vCPUs || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">vGPU :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.vGPUs || "-"}}</span>
            </div>
        </div>


        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Video Memory GiB :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.video_memory_GiB || "-"}}</span>
            </div>
        </div>

    </div>
</div>
