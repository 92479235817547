<div class="pop-up">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Delete Space Member</span> 
        <span (click)="closeDialog()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
       <h3>Are you sure to Delete?</h3>
        <div class="offset-md-6 text-right">
            <button mat-button mat-raised-button class="add-button mr-4" (click)="deleteYes()" >Yes</button>
            <button mat-button mat-raised-button class="add-button btn-canvas-blue-2" (click)="closeDialog()" >No</button>
        </div>
      
    </div>
</div>