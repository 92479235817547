import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './admin-home/admin-home.component';

import { AdminGitlabComponent, AddToolGroupDialog } from './admin-gitlab/admin-gitlab.component';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';
import { AdminJiraComponent, AddJiraToolGroupDialog, jiraCancelConfirmationPopUpDialog, jiraHomeConfirmationPopUpDialog } from './admin-jira/admin-jira.component';
import { AdminScmToolComponent, SCMToolDialog } from './admin-scm-tool/admin-scm-tool.component';
import { AdminToolgroupComponent, AdminToolgroupDialog } from './admin-toolgroup/admin-toolgroup.component';
import { ToolConfigComponent } from './tool-config/tool-config.component';
import { AdminGithubComponent } from './admin-github/admin-github.component';
import { AdminCatalogConfigComponent, CatalogToolScmModel, CatalogToolTableModel} from './admin-catalog-config/admin-catalog-config.component';
import { AlertModule } from '../_alert';
import { AdminStoryBoardComponent, StoryBoardDialog } from './admin-story-board/admin-story-board.component';
import { AdminCiCdComponent, CICDDialog } from './admin-ci-cd/admin-ci-cd.component';
import { AdminJenkinsComponent, AddJenkinsToolGroupDialog } from './admin-jenkins/admin-jenkins.component';
import { AdminVdiConfigComponent,  AdminDialogOverview } from './admin-vdi-config/admin-vdi-config.component';
import { VdiConfigSettingComponent,VdiImageModel, DialogOverviewDialog, vdiHomeConfirmationPopUpDialog, vdiCancelConfirmationPopUpDialog,AdminDialogImage } from './vdi-config-setting/vdi-config-setting.component';
import { DataTablesModule } from 'angular-datatables';
import { AdminCastConfigComponent } from './admin-cast-config/admin-cast-config.component';
import { AdminAzureConfigComponent,AddToolGroupAzureDialog } from './admin-azure-config/admin-azure-config.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { AdminDeploymentStoreVMComponent, VmDialogOverview } from './admin-deployment-store-vm/admin-deployment-store-vm.component';
import { AdminDeploymentStoreComponent } from './admin-deployment-store/admin-deployment-store.component';
import { AdminDeploymentVMConfigComponent ,VMImageModel, DialogOverviewDVMialog, vmCancelConfirmationPopUpDialog, vmHomeConfirmationPopUpDialog,AdminDialogImageVM } from './admin-deployment-vm-config/admin-deployment-vm-config.component';
import { AdminAksClusterComponent } from './admin-aks-cluster/admin-aks-cluster.component';
import { AdminAksComponent ,AddToolGroupAksDialog} from './admin-aks/admin-aks.component';
import { AdminVmComponent,AddToolGroupVmDialog } from './admin-vm/admin-vm.component';
import { AdminVdiComponent,AddToolGroupVdiDialog } from './admin-vdi/admin-vdi.component';
import { DevTestComponent, AddTagDetailsDialog } from './dev-test/dev-test.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { ChartsModule } from 'ng2-charts';
import { AdminVDIDropdownConfigComponent } from './admin-vdi-dropdown-config/admin-vdi-dropdown-config.component';
import { AdminAwsConfigComponent,AddToolGroupAwsDialog } from './admin-aws-config/admin-aws-config.component';
import { AdminTestComponent } from './admin-test/admin-test.component';
import { WebexLoginComponent } from './webex-login/webex-login.component';
import { CatchClearTestComponent } from './catch-clear-test/catch-clear-test.component';
import { AdminTenantPropertireComponent,AddToolGrouptenantDialog, TenantInfoDialog } from './admin-tenant-propertire/admin-tenant-propertire.component';
import { AdminClientPropertireComponent,AddToolGroupclientDialog, ClientInfoDialog } from './admin-client-propertire/admin-client-propertire.component';
import { AdminWebexPropertireComponent,AddToolGroupwebexDialog, WebexInfoDialog } from './admin-webex-propertire/admin-webex-propertire.component';
import { AdminProjectTemplateComponent ,AdminDialogTemplateOverview} from './admin-project-template/admin-project-template.component';
import { AdminAwsComponent } from './admin-aws/admin-aws.component';
import { CanvasGigComponent, AdminDialogImageGig } from './canvas-gig/canvas-gig.component';
import { AdminDialogImageVdiForm } from '../project-configurations/vdi-request-form/vdi-request-form.component';




@NgModule({
  declarations: [
    AdminHomeComponent,
    AdminGitlabComponent,
    AdminJiraComponent,
    AdminToolgroupComponent,
    AdminScmToolComponent,
    AddToolGroupAzureDialog,
    SCMToolDialog,
    AddToolGroupDialog,
    AddToolGroupVmDialog,
    AddToolGroupVdiDialog,
    AddToolGroupclientDialog,
    AddToolGroupwebexDialog,
    AddToolGrouptenantDialog,
    AddToolGroupAwsDialog,
    AddTagDetailsDialog,
    AddToolGroupAksDialog,
    AdminToolgroupDialog,
    ToolConfigComponent,
    AdminGithubComponent,
    AdminCatalogConfigComponent,
    CatalogToolScmModel,
    CatalogToolTableModel,
    AdminDialogTemplateOverview,
    ClientInfoDialog,
    WebexInfoDialog,
    TenantInfoDialog,

VdiImageModel,
DialogOverviewDialog, vdiHomeConfirmationPopUpDialog, vdiCancelConfirmationPopUpDialog,
VMImageModel,
DialogOverviewDVMialog,vmCancelConfirmationPopUpDialog,jiraCancelConfirmationPopUpDialog,jiraHomeConfirmationPopUpDialog,vmHomeConfirmationPopUpDialog,

    AdminStoryBoardComponent,
    StoryBoardDialog,
    AddJiraToolGroupDialog,
    
    AddJenkinsToolGroupDialog,
    AdminCiCdComponent,
    CICDDialog,
    AdminJenkinsComponent,
    AdminVdiConfigComponent,
    AdminDialogOverview,
    AdminDialogImage,
    AdminDialogImageGig,
    AdminDialogImageVdiForm,
    AdminDialogImageVM,
    VmDialogOverview,
    VdiConfigSettingComponent,
    AdminCastConfigComponent,
    AdminAzureConfigComponent,
    AdminDeploymentStoreVMComponent,
    AdminDeploymentStoreComponent,
    AdminDeploymentVMConfigComponent,
    AdminAksClusterComponent,
    AdminAksComponent,
    AdminVmComponent,
    AdminVdiComponent,
    DevTestComponent,
    AdminReportsComponent,
    AdminVDIDropdownConfigComponent,
    AdminAwsConfigComponent,
    AdminTestComponent,
    WebexLoginComponent,
    CatchClearTestComponent,
    AdminTenantPropertireComponent,
    AdminClientPropertireComponent,
    AdminWebexPropertireComponent,
    AdminProjectTemplateComponent,
    AdminDialogTemplateOverview,
    ClientInfoDialog,
    TenantInfoDialog,
    WebexInfoDialog,
    AdminAwsComponent,
    CanvasGigComponent
  ],
 

  imports: [
    CommonModule,
    MaterialModule,
    AppRoutingModule,
    NgxSpinnerModule,
    AlertModule, ChartsModule,
    DataTablesModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    })
    
  ],
  entryComponents: [
    SCMToolDialog,
    AddToolGroupAzureDialog,
    AddToolGroupAksDialog,
   
    AdminToolgroupDialog,
    CatalogToolScmModel,
    DialogOverviewDialog, vdiHomeConfirmationPopUpDialog,vdiCancelConfirmationPopUpDialog,
    VMImageModel,
DialogOverviewDVMialog,vmCancelConfirmationPopUpDialog, jiraCancelConfirmationPopUpDialog,jiraHomeConfirmationPopUpDialog,vmHomeConfirmationPopUpDialog,
AddToolGroupVmDialog,
    CatalogToolTableModel,
    AddToolGroupDialog,
    AddToolGroupVdiDialog,
    AddToolGroupclientDialog,
    AddToolGroupwebexDialog,
    AddToolGrouptenantDialog,
    AddToolGroupAwsDialog,
    AddTagDetailsDialog,
    StoryBoardDialog,
    AddJiraToolGroupDialog,
    
    AddJenkinsToolGroupDialog,
    CICDDialog,
    
    AdminDialogTemplateOverview,
    ClientInfoDialog,
    TenantInfoDialog,
    WebexInfoDialog,
VdiImageModel  ,
AdminDialogOverview ,
AdminDialogImage,
AdminDialogImageVM,
VmDialogOverview
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class AdminModule { }
