<div class="pop-up" >
    <div class="">
        <span  class="vertical-line mx-0 mx-md-3"> </span>
         <span class="header mb-0">Canvas Workplace</span> 
        <span (click)="exit()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
    <div class="popup-container">
       
        <div class="" >
            <h3 class="mb-3 col-md-12 mt-2">You are not authorized to use this portal. Please click 'OK' to logout.
            </h3>
            <div class="text-right">
                <div class="row" >
                    <div class="col-md-9 col-12 mt-3">
                            <!-- <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="selectedOption2('Yes')">Yes</button> -->
                        </div>
                    <div class="col-md-3 col-12 mt-3 text-right">
                            <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="exit()">OK</button>
                        </div>
                </div>
            </div>
        </div>
       
    </div>
</div>