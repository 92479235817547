<!-- New code-->
<!-- <div class="inner-page-container"> -->

<div class="inner-page-container">
 
    <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner> -->
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Webex</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 text-right">
          <!-- <div class="table-options pt-3">
            <span (click)="addDevFramework()"><i class="fas fa-plus"></i> Add</span>
            <span (click)="refreshDevTest()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <span><i class="fas fa-cog"></i> Manage</span>
          </div> -->
        </div>
        <div class="col-sm-1 pt-4">
          <!-- <a routerLink="/admin-catalog-config" class="float-right text-light"><i class="fas fa-times"></i></a> -->
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div>
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/webex.svg">
          <div class="vertical-line mx-3"> </div> <span>Webex</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
      
     <div class="text-center" style="padding-top: 50px;">
        <div>
          <button mat-raised-button color="primary" class="login-button" (click)="getWebexAccessCode()">Login
          </button>
        </div>
        <div *ngIf="successFlag">
          <h4>Webex login Successful. </h4>
        </div> 
        <div *ngIf="expireFlag">
          <h4>Webex Refresh Token will be expires within <span>{{totalDaysRemaining}}</span> Days</h4>
        </div>
    </div>
  
    </div>
  </div>
