<div class="inner-page-container" *ngIf="!submission">
    <div class="inner-top-container">
        <div class="row">

            <div>
                <h1 class="text-light mb-0"><img width="40" src="assets/img/VDI-Icon-White.svg">
                    <div class="vertical-line mx-3"> </div> <span class="mainHead">Canvas Workplace Onboarding</span>
                </h1>
            </div>
        </div>
    </div>
    <div class="inner-mid-container">

        <form [formGroup]="secondFormGroup" class="pl-0">
            <div class="row ml-1 mt-3">

                <div class="col-3">
                    <div class="form-group">
                        <label for="projectOwner">Project Owner Name <span class="required">&#42;</span></label>
                        <input #input id="projectOwner" name="projectOwner" class="form-control form-control-height" 
                            placeholder="Enter Project Owner Name" required formControlName="projectOwner"
                            [class.is-invalid]="projectOwner.invalid && projectOwner.touched">
                        <small class="text-danger"
                            [class.d-none]="projectOwner.valid || projectOwner.untouched">{{getProjectOwnerNameErrorMessage()}}</small>

                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="projectOwnerEmail">Project Owner Email <span class="required">&#42;</span></label>
                        <input #input id="projectOwnerEmail" name="projectOwnerEmail"  
                            class="form-control form-control-height" placeholder="Enter Project Owner Email" required
                            formControlName="projectOwnerEmail"
                            [class.is-invalid]="projectOwnerEmail.invalid && projectOwnerEmail.touched">
                        <small class="text-danger"
                            [class.d-none]="projectOwnerEmail.valid || projectOwnerEmail.untouched">{{getProjectOwnerEmailErrorMessage()}}</small>

                    </div>
                </div>

                <div class="col-3">
                    <div class="form-group">
                        <label for="domainName">Domain Name <span class="required">&#42;</span></label>
                        <input #input id="domainName" name="domainName" class="form-control form-control-height" 
                            placeholder="Enter Domain Name" required formControlName="domainName"
                            [class.is-invalid]="domainName.invalid && domainName.touched">
                        <small class="text-danger"
                            [class.d-none]="domainName.valid || domainName.untouched">{{getDomainNameErrorMessage()}}</small>

                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="BU">DU/BU <span class="required">&#42;</span></label>
                        <input #input id="BU" name="BU" class="form-control form-control-height" 
                            placeholder="Enter DU/BU" required formControlName="BU"
                            [class.is-invalid]="BU.invalid && BU.touched">
                        <small class="text-danger"
                            [class.d-none]="BU.valid || BU.untouched">{{getBUErrorMessage()}}</small>

                    </div>
                </div>
            </div>

            <div class="row ml-3 mt-3">
                <!-- <div class="alert alert-info">
                        <strong>Note:</strong> New users can be added using LTI Canvas Workplace once infrastructure provisioning is done
                    </div> -->
                <div class="alert alert-info alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Note:</strong> New users can be added using LTI Canvas Workplace once infrastructure
                    provisioning is done
                </div>
            </div>
            <div class="row mt-5 ml-1">
                <span class="col-3"><label>Maximum number of provisioned VDIs at any given time.</label></span>
                <!-- <span class="col-2"><label>Other software</label></span> -->
                <div class="col-md-4">
                    <div class="form-group">

                        <!-- <label for="projectName">Project ID <span class="required">&#42;</span></label> -->
                        <!-- <label for="otherSoftware">Project Owner Name</label> -->
                        <input #input id="maxVdiCount" type='number' name="maxVdiCount" min="1" max="4096"
                            class="form-control form-control-height" required formControlName="maxVdiCount">
                            <small class="text-danger" *ngIf="!isInteger(secondFormGroup.value.maxVdiCount)">Maximum number of provisioned VDIs must be a natural number</small>
                            <br>

                            
                        <small class="text-danger" *ngIf="secondFormGroup.value.maxVdiCount>4096 || secondFormGroup.value.maxVdiCount<1">Maximum number of provisioned VDIs must be in the range  1 - 4096</small>

                    </div>
                </div>
            </div>
            <!-- <div class="row ml-3 mt-3">
                <div class="alert alert-info alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Note:</strong> Number of IPs will be allocated accordingly, eg: 8 or 16 or 32 or 64 or 128 or 256 or 512 or 1024 or 2048 or 4096.
                </div>      

            </div> -->


            <div class="row  mt-3">
                <div class="col">
                    <fieldset class="border p-3 example-margin pl-3" style="width: 720px;">
                        <legend class="w-auto" style="font-weight: bold;">Select Tools</legend>
                        <div class="table-responsive show">
                            <section class="example-section pl-3">
                                <div class="col-1"></div>
                                <!-- <mat-checkbox class="example-margin" [checked]="chkHideVDI"
                                    (change)="seleccionRubrosHideVDI($event)">Azure VDI</mat-checkbox>
                                <span class="material-icons show-project-info" title="System Information">info</span>
                                <div class="div col-3"></div> -->
                                <!-- <mat-checkbox class="example-margin " [checked]="chkHideAzureVDI"
                                    (change)="seleccionRubrosHideAzure($event)">Azure VDI</mat-checkbox>
                                    <div class="div col-2"></div> -->

                    <!-- VDI nested -=------------------------------ -->
                                                <div style="margin-top: 58px;">

                                                    <mat-checkbox class="example-margin" [checked]="allCompleteVdi" 
                                                        [color]="taskVdi.color" [indeterminate]="someCompleteVdi()"
                                                        (change)="setAllVdi($event.checked)">
                                                        {{taskVdi.name}}
                
                                                    </mat-checkbox>
                                                    <span class="material-icons show-project-info"  title="System Information" (click)="showProjectInfo('VDI')"
                                                    >info</span>
                
                                                    <div class="tests">
                                                        <span *ngFor="let subtaskVdi of taskVdi.subtasksVdi">
                                                            <mat-checkbox class="example-margin-label" [(ngModel)]="subtaskVdi.completed" ngDefaultControl [color]="subtaskVdi.color"
                                                                (ngModelChange)="updateAllCompleteVdi()"
                                                                [ngModelOptions]="{standalone: true}">
                                                                {{subtaskVdi.name}}
                                                            </mat-checkbox>
                                                            <br>
                
                                                        </span>
                                                    </div>
                                                </div> 
                                                <div class="div col-3"></div>


                                <mat-checkbox class="example-margin" [checked]="chkAWSWorkspace" disabled
                                    (change)="seleccionRubrosHideWorkspace($event)">AWS VDI</mat-checkbox>
                                <span class="material-icons show-project-info" style="color: grey"  title="System Information">info</span>

                            </section>

                        </div>


                    </fieldset>

                </div>

            </div>

            <div class="row  ml-2" *ngIf="chkHideVDI  ">
                <span col-1 class="example-margin mt-5"><label>Azure VDI Type :</label> </span>
                <div class="col-4 mt-5">

                    <mat-checkbox class="example-margin" [checked]="chkHidePersonal"
                        (change)="seleccionRubrosHidePersonal($event)">Personal</mat-checkbox>
                    <span class="material-icons show-project-info" title="System Information">info</span>
                    <mat-checkbox class="example-margin pl-3" [checked]="chkHidePooled"
                        (change)="seleccionRubrosHidePooled($event)">Pooled</mat-checkbox>
                    <span class="material-icons show-project-info" title="System Information">info</span>

                </div>





            </div>

            <div class="row mt-5 ml-1">
                <div class="table-responsive col-6 " style="max-height: 300px;" *ngIf="this.taskVdi.subtasksVdi[0].completed || this.taskVdi.subtasksVdi[1].completed">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">

                            <thead>
                                    <tr>
                                        <th style="width: 15px;">
                                            <input type="checkbox" [(ngModel)]="masterSelected" ngDefaultControl
                                                (change)="checkUncheckAllVM()" [ngModelOptions]="{standalone: true}" />
                                        </th>
            
                                        <th>VDI Images</th>
                                        <!-- <th>Location</th> -->
                                        <!-- <th>Type</th> -->
                                        <th>Units</th>

                                        <th>Info</th>
                        
                                    </tr>
                                </thead>

                                <tbody>
                                        <tr *ngFor="let SCM of checklist,let i = index">
                
                                            <td style="width: 15px;">
                                                <input type="checkbox" [(ngModel)]="SCM.checked" ngDefaultControl
                                                    (change)="isAllSelectedVM()" [ngModelOptions]="{standalone: true}"/>
                                            </td>
                                            <td>{{SCM.name}}</td>
                                            <!-- <td>{{SCM.location}}</td> -->
                                            <!-- <td>{{SCM.type}}</td> -->

                                            <td style="width: 100px;"><input id="vm" autocomplete="off" name="VMimages" type="text"  class="form-control form-control-lg"  
                                                [(ngModel)]="VMunits[i]" #vdiImages="ngModel" ngDefaultControl [disabled]='!SCM.checked'
                                                onPaste="return false" [ngModelOptions]="{standalone: true}" > <span *ngIf="SCM.checked && (check(VMunits[i]))" class="text-danger" style="margin-left: 15px">Invalid</span></td> 
                                                <td> <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfo(SCM)">info</span></td>



                                        </tr>
                                    </tbody>


                    </table>

                
            </div>

            <div class="table-responsive col-6 " style="max-height: 300px;" *ngIf="chkAWSWorkspace">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">

                        <thead>
                                <tr>
                                    <th style="width: 15px;">
                                        <input type="checkbox" [(ngModel)]="masterSelectedAWS" ngDefaultControl 
                                            (change)="checkUncheckAllAWS()" [ngModelOptions]="{standalone: true}" />
                                    </th>
        
                                    <th>AWS Images</th>
                                    <!-- <th>Location</th> -->
                                    <!-- <th>Type</th> -->
                                    <th>Units</th>

                                    <th>Info</th>
                    
                                </tr>
                            </thead>

                            <tbody>
                                    <tr *ngFor="let SCM of checklistAWS,let i = index">
            
                                        <td style="width: 15px;">
                                            <input type="checkbox" [(ngModel)]="SCM.checked" ngDefaultControl
                                                (change)="isAllSelectedAWS()" [ngModelOptions]="{standalone: true}"/>
                                        </td>
                                        <td>{{SCM.name}}</td>
                                        <!-- <td>{{SCM.location}}</td> -->
                                        <!-- <td>{{SCM.type}}</td> -->

                                        <td style="width: 100px;"><input id="aws" autocomplete="off" name="AWSimages" type="text"  class="form-control form-control-lg"  
                                            [(ngModel)]="AWSunits[i]" #vdiImages="ngModel" ngDefaultControl  
                                            onPaste="return false" [ngModelOptions]="{standalone: true}" ></td> 
                                            <td> <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfoAWS(SCM)">info</span></td>



                                    </tr>
                                </tbody>


                </table>

            
        </div>
            </div>

            <div class="row mt-3 ml-1">
                <span class="col-3"><label>Other software <br>(Request software if not found in the list)</label></span>
                <!-- <span class="col-2"><label>Other software</label></span> -->
                <div class="col-md-8">
                    <div class="form-group">

                        <!-- <label for="projectName">Project ID <span class="required">&#42;</span></label> -->
                        <!-- <label for="otherSoftware">Project Owner Name</label> -->
                        <input #input id="otherSoftware" name="otherSoftware" class="form-control form-control-height"
                            required formControlName="otherSoftware" maxlength="250">
                        <!-- <small class="text-danger" [class.d-none]="otherSoftware.valid || otherSoftware.untouched">{{getProjectOwnerNameErrorMessage()}}</small> -->

                    </div>
                </div>
            </div>

            <div class="row ml-3 mt-3">
                <!-- <div class="alert alert-info">
                        <strong>Note:</strong> New users can be added using LTI Canvas Workplace once infrastructure provisioning is done
                    </div> -->
                <div class="alert alert-info alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Note:</strong> If the selected infrastructure is required only for a limited time, please ensure that the tentative end date is input.
                </div>
            </div>

            <div class="row mt-3 mt-4 ml-1">
                <div class="col-12 col-md-4">
                    <div class="form-group">
                        <label for="start">Start Date <span class="required">&#42;</span></label>
                        <input [min]="myDate" type="date" class="form-control" id="start"
                            [(ngModel)]="model.start" required name="start" #name="ngModel" ngDefaultControl
                            [ngModelOptions]="{standalone: true}">
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group" (click)="getMinimumEndDate()">
                        <label for="end">End Date </label>
                        <input [min]="minimumEndDate" type="date" class="form-control" id="end" [(ngModel)]="model.end"
                            name="end" #name="ngModel" ngDefaultControl [ngModelOptions]="{standalone: true}">
                    </div>
                </div>



                <div class="col-1"></div>
                <div class="mt-3 ml-5 text-right">
                                    <button  type="button" class="btn-canvas-blue"  
                                    [disabled]="!secondFormGroup.controls.projectOwner.valid || !secondFormGroup.controls.projectOwnerEmail.valid
                                    || !secondFormGroup.controls.domainName.valid || !secondFormGroup.controls.BU.valid || !model.start || (model.end!='' && model.start>=model.end)"
    
                                    
    
                                    (click)="submit()">
                                    Submit
                                  </button>
                                  <button  type="button" (click)="reset()"  class="btn-canvas-blue ml-4">
                                        Reset
                                      </button>
                            </div>

            </div>
            <div class="row ml-1">
                <div class="text-danger ml-3" *ngIf="(model.start >= model.end && model.end!='')">
                    <p>End date must be greater than start date</p>
                </div>
            </div>



        </form>

    </div>



</div>
<div class="inner-page-container" *ngIf="submission">

    <div class="inner-mid-container" style="text-align: center; height: 500px;padding: 130px 0; ">
        <img src="assets/img/GigSubmit.png" alt="Submit">

        <h1 class="heading mt-3">Thank you</h1>
        <p style="font-size: 18px;">Your submission has been sent </p>
    </div>
</div>
