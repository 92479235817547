<!-- New code-->
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
      [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
             
              <li class="breadcrumb-item active" aria-current="page">Tenant Properties</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 col-md-5 text-right pt-3">
          <div class="table-options">
            <span (click)="addTenant()"><i class="fas fa-plus"></i> Add</span>
            <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

            <span (click)="refreshTenant()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <a class="float-right step-back" (click)="backNavigation()">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
           
          </div>
        </div>
        <!-- <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div> -->
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/VDI-Icon-White.svg">
          <div class="vertical-line mx-3"> </div> <span>Tenant Properties</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
        <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                <div class="modal-header-popup">
                  <span  class="vertical-line mx-3"> </span>
                   <span class="header mb-0">Tenant Properties</span> 
                  <span  data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                  Are you sure want to Delete?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                </div>
              </div>
            </div>
          </div>
      <div *ngIf="showVdiForm" class="git-Lab-Form">
        <h2 *ngIf="!editMode">New Tenant Instance</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
        <form (ngSubmit)="toolsGroupForm.form.valid && onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">
           
           

            <div class="form-group col-md-4">
              <label for="azureId">Azure ID <span class="required" >&#42;</span></label>
              <select id="azureId" name="azureId" class="form-control form-control-lg" required [disabled]="AzuredrpDisable"
                (change)="onChangeAzureId($event)" [(ngModel)]="toolsGroup.azureId" #azureId="ngModel">
               <!-- <option selected>Select Azure ID</option>-->
                <!--<option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}                
                </option>-->
                <option value="" disabled selected hidden>Select Azure ID</option>
                <option *ngFor="let s of SubscriptionList" [value]="s.tool_id">{{s.tool_id}}
                </option>
              </select>
              <div *ngIf="azureId.errors && azureId.touched" class="alert alert-danger">
                <div *ngIf="azureId.errors.required">Azure ID is required.</div>
              </div>
            </div>
  
            <div class="position-relative pt-5 img-width">
              <span style="font-size: 18px;cursor:pointer;" class="material-icons add-user" (click)="getAzureIdDetails(toolsGroup.azureId)"
                title="System Information" data-toggle="modal" data-target="#AzureDetailsModal">info</span>
  
  
  
              <!-- Modal -->
              <div class="modal fade pop-up" id="AzureDetailsModal" tabindex="-1" role="dialog"
                aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="info-header">
                      <span class="vertical-line mx-3"> </span>
                      <span class="header mb-0"> Azure ID Details</span>
                      <span type="button" data-dismiss="modal" class="material-icons close-icon">
                        close
                      </span>
                    </div>
  
                    <div class="modal-body popup-container">
                      <div class="row">
                        <div class="col-md-4">
                          <span class="field-name">Azure ID </span>
                        </div>
                        <div class="col-md-8">
                          <span> : &nbsp;{{this.vdi_Azure_Id}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <span class="field-name">Subscription ID </span>
                        </div>
                        <div class="col-md-8">
                          <span> : &nbsp;{{this.vdi_Subscription_ID}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <span class="field-name">Tenant ID </span>
                        </div>
                        <div class="col-md-8">
                          <span> : &nbsp;{{this.vdi_tenantId}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-4">
                          <span class="field-name">Connection Name </span>
                        </div>
                        <div class="col-md-8">
                          <span> : &nbsp;{{this.vdi_Azure_ConnectionName}}</span>
                        </div>
                      </div>
                    <!--  <div class="row">
                        <div class="col-md-4">
                          <span class="field-name">Tool Group Name </span>
                        </div>
                        <div class="col-md-8">
                          <span> : &nbsp;{{this.Vdi_ToolGr_Name}}</span>
                        </div>
                      </div>-->
  
                    </div>
  
                  </div>
                </div>
              </div>
            </div>


            <div class="form-group col-md-4" >
              <label for="group_id">Group Id <span class="required">&#42;</span></label>
              <input id="group_id" type="text" autocomplete="off" name="group_id" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.group_id" #group_id="ngModel">
              <div *ngIf="group_id.invalid && (group_id.dirty || group_id.touched)" class="alert alert-danger">
                <div *ngIf="group_id.errors.required">
                  Group Id is required.
                </div>
              </div>
            </div>
  
            <div class="form-group col-md-4">
              <label for="group_name">Group Name <span class="required">&#42;</span></label>
              <input id="group_name" type="text" autocomplete="off" name="group_name" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.group_name" #group_name="ngModel">
              <div *ngIf="group_name.invalid && (group_name.dirty || group_name.touched)" class="alert alert-danger">
                <div *ngIf="group_name.errors.required">
                  Group Name is required.
                </div>
              </div>
            </div>
        
            <div class="form-group col-md-4">
              <label for=" ">Client Name <span class="required">&#42;</span></label>
              <input id="client_name" type="text" autocomplete="off" name="client_name" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.client_name" #client_name="ngModel">
              <div *ngIf="client_name.invalid && (client_name.dirty || client_name.touched)" class="alert alert-danger">
                <div *ngIf="client_name.errors.required">
                  Client Name is required.
                </div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label for="client_id">Client Id <span class="required">&#42;</span></label>
              <input id="client_id" type="text" autocomplete="off" name="client_id" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.client_id" #client_id="ngModel">
              <div *ngIf="client_id.invalid && (client_id.dirty || client_id.touched)" class="alert alert-danger">
                <div *ngIf="client_id.errors.required">
                  Client Id is required.
                </div>
              </div>
            </div>
            
            <div class="form-group col-md-4">
              <label for="client_secret">Client Secret <span class="required">&#42;</span></label>
              <input id="client_secret" type="text" autocomplete="off" name="client_secret" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.client_secret" #client_secret="ngModel">
              <div *ngIf="client_secret.invalid && (client_secret.dirty || client_secret.touched)" class="alert alert-danger">
                <div *ngIf="client_secret.errors.required">
                  Client Secret is required.
                </div>
              </div>
            </div>
         
            <div class="form-group col-md-4">
              <label for="client_secret_mst">Client Secret Mst <span class="required">&#42;</span></label>
              <input id="client_secret_mst" type="text" autocomplete="off" name="client_secret_mst" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.client_secret_mst" #client_secret_mst="ngModel">
              <div *ngIf="client_secret_mst.invalid && (client_secret_mst.dirty || client_secret_mst.touched)" class="alert alert-danger">
                <div *ngIf="client_secret_mst.errors.required">
                  Client Secret Mst is required.
                </div>
              </div>
            </div>
            <!--Code added for Azure Id with Details on i button-->
  
</div>
  
          
          <div class="row">
            <div class="col-md-6">
             
            </div>
            <div class="col-md-6 text-right">
              <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2"
                (click)="CheckTenantDuplication()">
                Save
              </button>
              <button *ngIf="editMode" type="button" class="btn-canvas-blue mr-2"
                (click)="updateTenant()">
                Update
              </button>
              <!-- <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
                Cancel
              </button> -->
              <!--button class="mr-2 btn-canvas-blue" type="button" data-toggle="modal"
              data-target="#confirmationModal">Cancel</button-->

              <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="toolsGroupForm.form.pristine" (click)="closeAks()">Cancel</button>
 
      </div>
 
      <!-- Modal -->
      <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
      <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
          aria-labelledby="confirmationModal" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                      <span class="vertical-line mx-3"> </span>
                      <span class="header mb-0">Tenant Properties</span>
                      <span data-dismiss="modal" class="material-icons close-icon">
                          close
                      </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                      Are you sure want to cancel?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-canvas-blue-2"
                          data-dismiss="modal">Close</button>
                      <button type="button" [disabled]="CancelConnDisable" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                  </div>
              </div>
              <!-- </div> -->
              <!-- </div> -->
          </div>
      </div>
          </div>
  
  
        </form>
      </div>
      <div class="my-5 table-container">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
          <thead>
            <tr>
                <th style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()" />
                </th>

  
              <th>ID</th>
              <th>Client Name</th>
              <!-- <th>Tool Type</th> -->
              <!-- <th>Client Id</th>
              <th>Client Secret</th>
              <th>Client Secret Mst</th> -->
              <th>Subscription Id</th>
              <th>Group Name</th>
              <!-- <th>Group Id</th> -->
              <th>Action</th>

            </tr>
          </thead>

          <tbody class="data-table-height">
            <tr *ngFor="let Tenant of SCMArray">
                <td style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="Tenant.isSelected" name="list_name" value="{{Tenant.id}}"
                        (change)="isAllSelected()" />
                </td>
  
              <td ><span class="link-col" (click)="getRecord(Tenant)">{{Tenant.tenant_config_id}}</span></td>
              <td >{{Tenant.client_name}}</td>
              <!-- <td>{{Tenant.client_id}}</td>
              <td >{{Tenant.client_secret}}</td>
              <td >{{Tenant.client_secret_mst}}</td> -->
              <td >{{Tenant.subscription_id}}</td>
              <td>{{Tenant.group_name}}</td>
              <!-- <td >{{Tenant.group_id}}</td> -->
              <td>  <span class="table-crud table-delete-icon" title="Template Information" (click)="showImageInfo(Tenant)">
                  <img width="20" src="../../../assets/img/info.jpg"></span></td>
  
            </tr>
  
          </tbody>
        </table>
      </div>
      </div>
  
    </div>
  </div>