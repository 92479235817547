import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_alert'; 
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/api.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dev-test-framework',
  templateUrl: './dev-test-framework.component.html',
  styleUrls: ['./dev-test-framework.component.scss']
})
export class DevTestFrameworkComponent implements OnInit {

  framework ="Not Selected"
  secondFormGroup: FormGroup;
  FrameworkVal = ["Development","Test"]
  PricingVal = ["$1000","$1500"];
  languageDropdown = [];
  templateToShow = []
  template = []; 
 
  // language : ["Java","DotNet","C#","Angular","Node","Selenium","JUnit","NUnit","JMeter","Jasmine"]
  devLanguage = [];//["Java","DotNet","C#","Angular","Node"]
  testLanguage = []; //["Selenium","JUnit","NUnit","JMeter","Jasmine"]
  projectId;
  projectName;
  catalog_id
  catalog_name: string;
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  constructor(private _formBuilder: FormBuilder, public router: Router,public alertService: AlertService,private apiService: AuthService,public dialog: MatDialog,) { }

  ngOnInit(): void {
    // @author-Priyanka Kale - getting projectId,ProjectName,catalogId,catalogName from localstorage ,so that data should not get cleared on page refresh
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
  
    this.projectId=localStorage.getItem('projectId');

   
  this.catalog_id=localStorage.getItem('catalogId');
   
    this.catalog_name=localStorage.getItem('catalogName');
   

   
    this.projectName=localStorage.getItem('projectName');

   
    this.catalog_id=localStorage.getItem('catalogId');

    this.apiService.getCatalogById(this.catalog_id, 'VDI').subscribe((result: any) => {
    })

    this.secondFormGroup = this._formBuilder.group({
      Framework: ['', Validators.required],
      language: ['', Validators.required],
      Pricing: ['', Validators.required]
    });

    /* Get All Frameworks */
    this.apiService.getAllFrameworks().subscribe((data:any) => {
      this.template = data;
      data.forEach(element => {
        if(element.framework == 'Development') {
          this.devLanguage.push(element.language)
        }
        else {
          this.testLanguage.push(element.language)
        }
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 441){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
      }
      else{
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
      });
      }
  }

  onSelectFirstDropdown(framework) {
    

    if(framework.target.value=='Development'){
      this.framework ="dev";
      this.templateToShow = []
    this.languageDropdown = this.devLanguage 
  
    this.template.forEach(temp=>{
      if(temp.framework == "Development"){
        this.templateToShow.push(temp)
        
      }
    })
  }
    else{
      this.framework ="test";

      this.templateToShow = []

      this.languageDropdown = this.testLanguage

      this.template.forEach(temp=>{
        if(temp.framework == "Test"){
          this.templateToShow.push(temp)
        }
      })
    }
    
   
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onSelectSecondDropdown(language){
    this.templateToShow = this.template.filter(x => x.language === language.target.value)

  }

  openTemplate(){
    const template = this.dialog.open(TemplateModal, {
      width: '25%',
      height: '35%',
      disableClose: true
    });

    template.afterClosed().subscribe(result => {
      
    })
  }

  get Framework() {
    return this.secondFormGroup.get('Framework');
  }
  get language() {
    return this.secondFormGroup.get('language');
  }
  get Pricing() {
    return this.secondFormGroup.get('Pricing');
  }

  onItemChange(item,event) {
  }

}

@Component({
  selector: 'template-modal',
  templateUrl: 'template-modal.html',
  styleUrls: ['./dev-test-framework.component.scss']
})
export class TemplateModal {

  constructor(){}
  ngOnInit(): void {

  }

}