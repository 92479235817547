import { Router } from '@angular/router';
import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject, throwError } from 'rxjs';	
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


export interface VDIDetails {
  toolID: string;
 
  tool_group_name: string;  
azure_tool_instance_id: string;  
vdi_type: string;  
}


export interface configList {
  toolID: string;
  tenantID: string;
  subscriptionID: string;
  Cost: string;
  tool_group_name: string;
  azure_tool_instance_id:string;
  vdi_type:string; 
}



@Component({
  selector: 'app-admin-deployment-store-vm',
  templateUrl: './admin-deployment-store-vm.component.html',
  styleUrls: ['./admin-deployment-store-vm.component.scss']
})
export class AdminDeploymentStoreVMComponent implements OnInit {

  projectId
  vdiObj: object[];
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox = false;
  checkedList: any;
  VdiConfig_details:any=[];
  check:any=[]



  CatlogArray =[];
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();
  Role:string="";
  itemSelected:boolean=false;

  constructor(public apiService: AuthService, private router: Router,
     private spinner: NgxSpinnerService, public alertService: AlertService, public dialog: MatDialog) { 
      this.masterSelected = false;

     }

  ELEMENT_DATA: VDIDetails[];
  displayedColumns: string[] = ['toolID', 'tenantID', 'subscriptionID', 'select'];
  //dataSource = new MatTableDataSource<VDIDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<VDIDetails>(true, []);
  

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };
    this.spinner.show();
    this.GetDeploymentStore_VMtableDetails();
    this.spinner.hide();
    }
    else{
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
    });
    }
}

  refreshVdi(){
    
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-deploymentStore-VM']);
      });
      }
      deleteRecord(){
        if(this.VdiConfig_details.length==0){
          this.alertService.error("Select one or more option to delete", this.options);
        }

      }
      checkUncheckAll() {
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
        this.getCheckedItemList();
      }
      isAllSelectedcheck() {
        this.masterSelected = this.checklist.every(function (item: any) {
          return item.isSelected == true;
        })
        this.getCheckedItemList();
      }
      getCheckedItemList() {
        this.project_selectedcheckbox = false;
      
        this.checkedList = [];
        
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].isSelected)
            this.checkedList.push(this.checklist[i].toolID);
        }
        // console.log(this.checkedList);
        
       // this.checkedList = JSON.stringify(this.checkedList);
       
       // @author - Priyanka Kale - set itemSelected flag to false 
       this.itemSelected=false;
       this.VdiConfig_details=[];
       
        for (let index = 0; index < this.checkedList.length; index++) 
                  {
                   //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
                   this.itemSelected=true;
      
                    this.project_selectedcheckbox = true;
               // debugger;
                      var SelectedItem=this.checkedList[index];
                     let VdiConfigBoby={
                        "vdi_dropdown_id":SelectedItem,
                        }
                       this.VdiConfig_details.push(VdiConfigBoby);
                      
                     // For Loop of tag
      
                    // Add image des 
                                   
                 }
        //}
      }
      
      DeleteConfig(){
         var deleteBody: any;
         this.check=[]
         this.VdiConfig_details.forEach(element => {
           this.check.push(element.vdi_dropdown_id)
           
         });
         
         
         deleteBody = {
           "tool_ids":this.check
        
         }
        
         this.apiService.NetworkConfigVMDropdowDeleteData(deleteBody).subscribe((res:any) => {
           if (res.status == 200) //doesnt work
           {
             
            
             this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
               this.router.navigate(['/admin-deploymentStore-VM']);
             });
             this.alertService.success(res.body.message, this.options);
            
           }
         }, (error: HttpErrorResponse) => {
           //
      
           if (error.status == 446) //doesnt work
           {
                
             this.alertService.error(error.error.message, this.options);
      
           }
           else if (error.status == 403){}
           else {
            
             this.alertService.error("Invalid Input", this.options);
             throw error;
           }
         })
      
       }
      
  GetDeploymentStore_VMtableDetails(){
    //
   //    console.log("GetCatalogtableDetails");

       this.apiService.Admin_Deployment_VM_TableDetails().subscribe((res:any)=>{

        res.forEach(member => {
          let obj = {
            "toolID": member.tool_id,
            "tool_group_name": member.tool_group_details.tool_group_name,
            "image_name":member.tool_instance_details.deploymentstore_tool_instance.name,
            //"vdi_type":member.tool_instance_details.vdi_tool_instance.vdi_type, 
            "azure_tool_instance_id":member.tool_instance_details.deploymentstore_tool_instance.azure_tool_instance_id,  
            "location":member.tool_instance_details.deploymentstore_tool_instance.location,
            "type":member.tool_instance_details.deploymentstore_tool_instance.type ,
            "vdi_type":member.tool_instance_details.deploymentstore_tool_instance.vdi_type,
            "vm_image_id":member.tool_instance_details.deploymentstore_tool_instance.vm_image_id,
            "vm_os":member.tool_instance_details.deploymentstore_tool_instance.vm_os,
            "vm_server":member.tool_instance_details.deploymentstore_tool_instance.vm_server,
            "vm_type":member.tool_instance_details.deploymentstore_tool_instance.vm_type             
          }
          
         this.CatlogArray.push(obj)
         this.checklist = this.CatlogArray;

        })

            this.dtTrigger.next();
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
       // SCMArray
  }
 

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
  //  const numRows = this.dataSource.data.length;
   // return numSelected === numRows;
   return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }



  setFormMode() {

    localStorage.setItem("formMode", "Save");
    
  }

  getRecord(row) {
    
    //console.log("Selected Row of VDI Table");
    //console.log(row);
    this.vdiObj = row;
    //this.apiService.SCMToolDetails.next(this.scmObj)
    try {
      localStorage.setItem('vdiObj', JSON.stringify(this.vdiObj));
      localStorage.setItem("formMode", "Edit")
      this.router.navigate(['/vdi-config-setting']);
    }
    catch{
      return throwError('Data not found');
    }
  }

  showImageInfo(catalog) {

    const dialogRef = this.dialog.open(VmDialogOverview, {
      width: '35%',
      height: '45%',
      disableClose: true,
      data: {
        image: catalog
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

@Component({
  selector: 'vm-dialog-overview',
  templateUrl: 'image-details.html',
  styleUrls: ['./admin-deployment-store-vm.component.scss']
})
export class VmDialogOverview {
  image: any = [];

  constructor(
    public dialogRef: MatDialogRef<VmDialogOverview>,@Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    //console.log(data.image)
    this.image = data.image
    //console.log("image software",this.image)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

