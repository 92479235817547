<div class="pop-up">
  <div class="">
    <span  class="vertical-line mx-2 mb-1"> </span>
     <span class="header mb-0">Add Member</span> 
    <span (click)="exit()" class="material-icons close-icon">
        close
      </span>
  </div>

    <hr class="margin-class" />
    <div class="popup-container justify-content-center">
      <div class="noData offset-md-5" *ngIf="dataSource.filteredData.length==0">
        <h2>No Users Available</h2>
      </div>

      <div class="example-container1" *ngIf="dataSource.filteredData.length!=0">
        <table mat-table [dataSource]="dataSource">
    
    
          <!-- Name Column -->
          <ng-container matColumnDef="user_name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
          </ng-container>
    
          <!-- Email Column -->
          <ng-container matColumnDef="user_email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
          </ng-container>
      
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (click)="allSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]=true [aria-label]="checkboxLabel(row)"> 
              </mat-checkbox>
    
              <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
          </tr>
        </table>
    
    
      </div>
    </div>
  
    <div class="row mt-4" *ngIf="dataSource.filteredData.length!=0">
      <div class="col-12 offset-md-9 text-center col-md-3">
  
        <button mat-button  mat-raised-button class="add-button" (click)="addUserForRole()" >+ Add Member</button>
      </div>
    </div>
  </div>