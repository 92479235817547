<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-multiple"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="contain-fluid">
  <div class="project-summary">
    <div class="sub-nav-bar mb-4" style="height: auto">
      <div class="row">
        <div class="col-md-12 details">
          <div class="col-md-9 vLine pull-left">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <h2 class="projectName">
                  Project ID :
                  <span class="projectDetail">{{ projectId }}</span>
                  <span
                    class="material-icons show-project-info"
                    title="System Information"
                    (click)="showProjectInfo()"
                    >info</span
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="col-md-2 text-md-right pull-right position-icon">
            <div>
              <!--mat-icon (click)="syncImanageTeamMemberProject()" class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                    title="Sync data from IManage">sync</mat-icon-->
              <mat-icon
                class="border_none"
                class="home-back"
                title="Home"
                aria-hidden="false"
                color="primary"
                routerLink="/projectSummary"
                aria-label="Example home icon"
                >home</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="d-block text-right">
        <div class="row">
          <div class="offset-md-10 col-md-2 p-md-0 my-auto">
            <!-- <span (click)="getTeamMembers()"><i class="fas fa-sync-alt"></i> Refresh</span> -->
            <span class="table-options pull-right">
              <span (click)="addMember()"
                ><i class="fas fa-plus"></i> Add Member</span
              >
            </span>
          </div>
        </div>
      </div>

      <div class="team-container">
        <div class="row pt-1">
          <div
            class="col-md-4 mb-5 pb-md-0 pb-5"
            *ngFor="let element of members; let i = index"
          >
            <div
              class="card card-icon"
              [ngStyle]="{ 'background-color': element.color }"
            >
              <div class="row m-0">
                <div class="col-md-3 p-0 icon">
                  <div class="center">
                    <img
                      src="../../assets/img/Union 11.png"
                      class="grid-icon"
                    />
                  </div>
                </div>
                <div class="col-md-9 p-2">
                  <div class="grid-title">
                    <!--span>{{element.name}}</span-->
                    <span>{{ element.name }}</span>
                    <!-- <span class="material-icons add-user" title="Member Information">info</span> -->
                  </div>
                  <div class="grid-subtitle">
                    <div>{{ element.ps }}</div>
                    <div>{{ element.email }}</div>
                    <div>
                      {{
                        element.template_roles.length == 1
                          ? element.template_roles.join(", ")
                          : element.privilege_access
                      }}
                    </div>
                    <div >
                      <span
                        [hidden]="element.gitlab == false"
                        title="Gitlab"
                        class="paddingGitIcon"
                        (click)="memberInfo(element, 'gitlab')"
                        ><img
                          width="20"
                          src="../../../assets/img/gitlab-logo.png"
                        />
                        <!-- {{element.gitlab}} --> </span
                      ><span
                        [hidden]="element.jenkins == false"
                        title="Jenkins"
                        class="paddingIcon"
                        (click)="memberInfo(element, 'jenkins')"
                        ><img width="18" src="assets/img/Jenkins.png" />
                        <!-- {{element.jenkins}} -->
                      </span>
                      <span
                        [hidden]="element.jira == false"
                        title="Jira"
                        class="paddingIcon"
                        (click)="memberInfo(element, 'jira')"
                        ><img width="20" src="assets/img/jira-logo.png" />
                        <!-- {{element.jira}} --> </span
                      ><span
                        [hidden]="element.vdi == false"
                        title="VDI"
                        class="paddingIcon"
                        (click)="memberInfo(element, 'vdi')"
                        ><img width="20" src="../../../assets/img/addVdi.png" />
                        <!-- {{element.vdi}} --> </span
                      ><span
                        [hidden]="element.vm == false"
                        title="VM"
                        class="paddingIcon"
                        (click)="memberInfo(element, 'vm')"
                        ><img width="20" src="assets/img/azure-vm.png" />
                        <!-- {{element.vm}} -->
                      </span>

                      <span *ngIf="element.roles[0]!='Secondary Project Manager' && element.roles[0]!='Project Manager'"
                      title="Delete Team Member"
                      style="float: right;"
                      
                      
                      (click)="TeamDmemberInfo(element)"
                      ><img
                        width="15"
                        src="../../../assets/img/Delete-blue.png"
                      />
                    </span>
                      <!-- <span  [hidden]="element.deleteStatus == false" title="Delete Team Member" class="paddingDeleteIcon" (click)="TeamDmemberInfo(element)" data-toggle="modal" data-target="#confirmationModal2"><img width="15"  src="../../../assets/img/Delete-blue.png"> -->
                      <!-- {{element.deleteStatus}} -->

                    </div>


            
                    <!-- <div>{{element.jenkins}}</div>
                    <div>{{element.jira}}</div>
                    <div>{{element.vdi}}</div> -->
                    <!-- <div>{{element.email}}</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="modal fade"
              id="confirmationModal2"
              tabindex="-1"
              role="dialog"
              aria-labelledby="confirmationModal2"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                    <span class="vertical-line mx-3"> </span>
                    <span class="header mb-0">Team Member</span>
                    <span
                      data-dismiss="modal"
                      class="material-icons close-icon"
                    >
                      close
                    </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">Are you sure want to Delete?</div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-canvas-blue-2"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="btn btn-canvas-blue-2"
                      data-dismiss="modal"
                      (click)="deleteMember(element)"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <p>
      Powered By &nbsp;
      <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" />
      <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
    </p>
  </footer>
</div>
