import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { AlertService } from 'src/app/_alert';

export interface configList {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}


export interface SCMDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

@Component({
  selector: 'app-admin-scm-tool',
  templateUrl: './admin-scm-tool.component.html',
  styleUrls: ['./admin-scm-tool.component.scss']
})
export class AdminScmToolComponent implements OnInit {

  SCMArray = [];

  projectId
  scmObj: object[];
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();


  constructor(public apiService: AuthService, private router: Router,private spinner: NgxSpinnerService,
    public dialog: MatDialog, public alertService: AlertService) { }


  ELEMENT_DATA: SCMDetails[];
  displayedColumns: string[] = [ 'ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
 // dataSource = new MatTableDataSource<SCMDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<SCMDetails>(true, []);

  ngOnInit(): void {
    this.apiService.projectKey.subscribe(projectId => {
      // this.userEmail = currentUserEmail;
      this.projectId = projectId;
    })
    this.GetScmtableDetails();
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  GetScmtableDetails() {
this.spinner.show();
    this.apiService.Admin_ScmToolGrTableDetails().subscribe((res: any) => {
     // 
      //console.log(res,"OUTPut")
      res.forEach(member => {
      //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id":member._id,
          "name": member.tool_instance_details.scm_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.scm_tool_instance.tool_description,
          "projectToken": member.tool_instance_details.scm_tool_instance.tool_auth.auth_token,
          "projectURL": member.tool_instance_details.scm_tool_instance.tool_url,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.SCMArray.push(obj)
       
      })
     // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
    // SCMArray
  }


  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    //const numRows = this.dataSource.data.length;
  //  return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolName + 1}`;
  }

  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }

  getRecord(row) {
//    console.log(row);
    this.scmObj = row;
    try {
      localStorage.setItem('scmToolObj', JSON.stringify(this.scmObj));
      localStorage.setItem("formMode", "Edit")
      this.router.navigate(['/admin-gitlab']);
    }
    catch {
      return throwError('Data not found');
    }
  }


  openDialog() {

    localStorage.setItem("formMode", "Add");
    const dialogRef = this.dialog.open(SCMToolDialog, {
      width: '40%',
      height: '40%',
    });
  }

}


@Component({
  selector: 'scm-tools-dialog',
  templateUrl: 'scm-tool-dialog.html',
  styleUrls: ['./admin-scm-tool.component.scss']
})

export class SCMToolDialog { }




