import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './../../service/api.service';
import { Subject } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert';
import { Router, NavigationEnd } from '@angular/router';
import { any } from 'prop-types';

@Component({
  selector: 'app-admin-aws',
  templateUrl: './admin-aws.component.html',
  styleUrls: ['./admin-aws.component.scss']
})
export class AdminAwsComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  model: any = {};

  ToolGroupArray: any = [];
  dtOptions: DataTables.Settings = {};
  // dtOptionsToolGroup: DataTables.Settings = {};
  dtTrigger = new Subject();
  dtTriggerToolGroup = new Subject();
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  panelOpenState = false;
  resourceGroupVal: any;
  resourceGroupValWorkspace: any;

  awsToolTabledata = [];
  networkConfigTabledata = [];
  networkConfigTabledataWorkspace = [];

  selectedRow: any;
  favoriteSeason: any;
  radioSelected: any;
  testConnDisable = false;
  saveConnDisable = true;
  AccessDisable = true;
  SecretDisable = false;
  saveNWConfigWorkspace = false
  saveConfigWorkspace=true
  saveNWConfigEc2 = true

  StepOneFlag=false;
  selectedRow2: any;
  selectedRow3: any;
  masterSelected: boolean;
  masterSelectedWorkspace: boolean;


  selectedRow1: any;
  vnetVal: Object;
  resourceGroupname: any;
  resourceGrouparn: any;
  vnetName: any;
  vnetID: any;
  subVnetName: any;
  subVnetID: any;
  subvnetVal: Object;
  securityVal: Object;
  Securityname: any;
  SecurityID: any;
  repoName: any;
  amazonMachineData: Object;
  checklist: any;
  checklistWorkspace: any=[];

  project_selectedcheckbox: boolean;
  project_selectedcheckboxWorkspace: boolean;

  checkedList: any[];
  checkedListWorkspace: any[];

  itemSelected: boolean;
  VdiConfig_details: any[];
  SCMArray: any = [];
  SCMArrayWorkspace: any = [];

  AddImageArray: any = [];
  imageBody: any;
  imageBodyWorkspace:any;
  amazonDataTable: any = [];
  Role: string;
  directoryVal: any;
  bundelVal: any = [];
  resourceGroupnameWorkspace: any;
  resourceGrouparnWorkspace: any;
  directoryName: any;
  directoryValue: any;
  awsToolTabledataWorspace: any=[];
  selectedRow4: any;
  AddImageArrayWorkspace: any=[];
  StepTwoFlag: boolean;

  constructor(private _formBuilder: FormBuilder, private spinner: NgxSpinnerService, public apiService: AuthService, public alertService: AlertService, private router: Router, ) {
    this.masterSelected = false;
    this.masterSelectedWorkspace=false
  }

  ngOnInit(): void {
    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if (this.Role == "Admin") {
   
      this.firstFormGroup = this._formBuilder.group({
        name: ['', Validators.required],
        // accesskeyid: ['', Validators.required],
        accesskeyid: [{value:'',disabled:false}, Validators.required],

        description: ['', Validators.required],
        // description: ['', [Validators.required,Validators.minLength(3),Validators.pattern('^[A-Za-z0-9? ()@&_-]+$')]],

        secretkeyid: [{value:'',disabled:false}, Validators.required],
      });
      this.secondFormGroup = this._formBuilder.group({
        securityGroup: ['', Validators.required],
        subnet: ['', Validators.required],
        vnet: ['', Validators.required],
        resourceGroup: ['', Validators.required],
        name: ['', Validators.required],
        description: ['', Validators.required],
        
      });
      this.thirdFormGroup = this._formBuilder.group({
        securityGroup: ['', Validators.required],
        subnet: ['', Validators.required],
        vnet: ['', Validators.required],
        resourceGroup: ['', Validators.required],
        name: ['', Validators.required],
        description: ['', Validators.required],
      });

      this.GetToolGrtableDetails();
      // this.awsToolTable();
      // this.getNetworkConfigToolTable();
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }

  }

  get name() {
    return this.firstFormGroup.get('accesskeyid');
  }
  get description() {
    return this.firstFormGroup.get('secretkeyid');
  }
  get accesskeyid() {
    // console.log(this.firstFormGroup.get('accesskeyid'));
    return this.firstFormGroup.get('accesskeyid');
  }
  get secretkeyid() {
    return this.firstFormGroup.get('secretkeyid');
  }

  //step 1
  GetToolGrtableDetails() {
    this.spinner.show();
    this.apiService.Admin_ToolGrTableDetails().subscribe((res: any) => {
      res.forEach(member => {
        let obj = {
          "Name": member.tool_group_name,
          "ID": member.tool_group_id,
          "Description": member.tool_group_description,
          "DB_toolGroup_ID": member._id
        }
        this.ToolGroupArray.push(obj)
        // console.log(this.ToolGroupArray);

      })
      this.dtTrigger.next();
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }
  createView(){
    if(!this.StepOneFlag){
      this.alertService.error("Tool Group can't be empty", this.options);
    }
  }

  //step 2
  CancelFirstForm() {
    this.saveConnDisable = true;
    this.testConnDisable = false;
    this.firstFormGroup.controls['accesskeyid'].enable();
    this.firstFormGroup.controls['secretkeyid'].enable();
    this.firstFormGroup.reset();
    // this.router.navigate(['/admin-aws'])
     this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-aws']);
        });


  }
  cancelOK(){

    this.router.navigate(['/admin-aws'])

  }
  testID() {
    // console.log(this.firstFormGroup.get('description').value);
    let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
    // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


    
    if(this.firstFormGroup.get('name').value.length<3){
      this.alertService.error("  Please enter minimum 3 characters for Name", this.options);
      return;
    }
    if(spName.test(this.firstFormGroup.get('name').value)){
      
      this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
      return;

    }
    if(this.firstFormGroup.get('description').value.length<20){
      this.alertService.error("  Please enter minimum 20 characters for Description", this.options);
      return;
    }
    // if(spDes.test(this.firstFormGroup.get('description').value)){
      
    //   this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
    //   return;

    // }
  

    let obj = {
      "aws_access_key_id": this.accesskeyid.value,
      "aws_secret_access_key": this.secretkeyid.value
    }
    this.apiService.Admin_testIDAws(obj).subscribe((data: any) => {
      // debugger
      this.alertService.success(data.message, this.options);
      this.saveConnDisable = false,
        this.testConnDisable = true
      this.firstFormGroup.controls['accesskeyid'].disable();
      this.firstFormGroup.controls['secretkeyid'].disable();



      // console.log(data);

    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 446) //doesnt work
      { }
      else if (error.status == 403) { }
      else {
        // console.log("hii");


        this.alertService.error(error.error.message, this.options);
        throw error;
      }
    })

  }
  
  checkUncheckAll() {
    // console.log(this.SCMArray);
    // console.log(this.checklist);
// debugger

    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    // debugger
    // console.log(this.SCMArray);
    // console.log(this.checklist);

    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.AddImageArray=[]


// debugger

    this.project_selectedcheckbox = false;

    this.checkedList = [];


    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected) {
        this.checkedList.push(this.checklist[i]);

      }
    }
    this.checkedList.forEach(member => {
      // console.log(member);

      //  
      let obj = {
        "id": member.ID,
        "name": member.name,
        "tool_description": member.description,
        "ec2_os": member.os,
        "ec2_server": member.server,
        "ec2_type": member.type

      }
      // console.log(obj);

      this.AddImageArray.push(obj)


    })

    //  console.log(this.checkedList);
    this.imageBody = {
      "resource_type": "tool",
      "tool_category": "AWS-EC2-Image",
      "tool_name": "AWS-EC2-Image Golden Image",
      "tool_group_details": {
        "tool_group_name": this.selectedRow2.Name,
        "tool_group_id": this.selectedRow2.ID,
      },
      "tool_instance_details": {
        "aws_ec2_image_tool_instances": this.AddImageArray
      },
    "created_by":localStorage.getItem('mail').toLowerCase()


    }

    // console.log(this.imageBody);
    

  }
  //Workspace------------------------------------------------------------------>
  checkUncheckAllWorkspace() {
    // debugger
    // console.log(this.SCMArray);
    // console.log(this.checklist);


    for (var i = 0; i < this.checklistWorkspace.length; i++) {
      this.checklistWorkspace[i].isSelected = this.masterSelectedWorkspace;
    }
    this.getCheckedItemListWorkspace();
  }
  isAllSelectedWorkspace() {

    this.masterSelectedWorkspace = this.checklistWorkspace.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemListWorkspace();
  }
  getCheckedItemListWorkspace() {
    // debugger
    this.AddImageArrayWorkspace=[]



    this.project_selectedcheckboxWorkspace = false;

    this.checkedListWorkspace = [];


    for (var i = 0; i < this.checklistWorkspace.length; i++) {
      if (this.checklistWorkspace[i].isSelected) {
        this.checkedListWorkspace.push(this.checklistWorkspace[i]);

      }
    }
    this.checkedListWorkspace.forEach(member => {
      // console.log(member);

      //  
      let obj =    { 
        "name": member.name, 
      "tool_description": member.description,
      "bundle_id": member.id, 
      "imageId": member.imageId, 
      "rootStorage": member.rootStorage, 
      "userStorage": member.userStorage, 
      "computeType": member.computeType 
    }
      // console.log(obj);

      this.AddImageArrayWorkspace.push(obj)


    })
    // console.log(this.AddImageArrayWorkspace);
    
    
    this.imageBodyWorkspace = {
      "resource_type": "tool", 
      "tool_category": "AWS-Workspace-Image", 
      "tool_name": "AWS-Workspace-Image", 
      "tool_group_details": { 
        "tool_group_name": this.selectedRow2.Name,
        "tool_group_id": this.selectedRow2.ID,
      }, 
      "tool_instance_details": { 
        "aws_workspace_image_tool_instance": this.AddImageArrayWorkspace
    }

    }
    // console.log(this.imageBodyWorkspace);
    
  }



    //---------------------------------->
    saveConfigureAwsToolWorkspace() {
      let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
    // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


    
    if(this.secondFormGroup.get('name').value.length<3){
      this.alertService.error("  Please enter minimum 3 characters for Name", this.options);
      return;
    }
    if(spName.test(this.secondFormGroup.get('name').value)){
      
      this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
      return;

    }
    if(this.secondFormGroup.get('description').value.length<20){
      this.alertService.error("  Please enter minimum 20 characters for Description", this.options);
      return;
    }
      // console.log(this.secondFormGroup);
      
      let obj = {
        "resource_type": "AWS-Workspace-Network-Config-Tool",
        "tool_category": "AWS-Workspace-Network-Config",
        "tool_name": this.secondFormGroup.value.name,
        "tool_group_details": {
          "tool_group_name": this.selectedRow2.Name,
          "tool_group_id": this.selectedRow2.ID,
        },
        "tool_instance_details": {
          "aws_workspace_network_config_tool_instance": {
            "resource_group": {
              "name": this.resourceGroupnameWorkspace,
              "arn": this.resourceGrouparnWorkspace,
            },
            "directory": {
              "name": this.directoryName,
              "id": this.directoryValue,
            },
            "tool_description": this.secondFormGroup.get('description').value

          }
        }
      }
      // console.log(obj);

      this.apiService.Admin_AddAWSWorkspaceNetworkConfigTool(obj).subscribe((data: any) => {
        // debugger
        this.alertService.success(data.message, this.options);
        this.getNetworkConfigToolTableWorkspace()
        // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/admin-aws']);
        // });

        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    saveConfigureAwsTool() {
      let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
      // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
  
  
      
      if(this.firstFormGroup.get('name').value.length<3){
        this.alertService.error("  Please enter minimum 3 characters for Name", this.options);
        return;
      }
      if(spName.test(this.firstFormGroup.get('name').value)){
        
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
  
      }
      if(this.firstFormGroup.get('description').value.length<20){
        this.alertService.error("  Please enter minimum 20 characters for Description", this.options);
        return;
      }
      // console.log(this.firstFormGroup.get('accesskeyid').value);
      // console.log(this.firstFormGroup.get('description').value);



      // debugger
      let obj = {
        "resource_type": "AWS-Config-Tool",
        "tool_category": "AWS-Config",
        "tool_name": "AWS-Config",
        "tool_group_details": {
          "tool_group_name": this.selectedRow2.Name,
          "tool_group_id": this.selectedRow2.ID,
        },
        "tool_instance_details": {
          "aws_config_tool_instance": {
            "access_key_id": this.accesskeyid.value,
            "secret_access_key": this.secretkeyid.value,
            "tool_description": this.firstFormGroup.get('description').value
          }
        }
      }
      // console.log(obj);

      this.apiService.Admin_saveConfigureAwsTool(obj).subscribe((data: any) => {
        // debugger
        this.alertService.success(data.message, this.options);
        this.awsToolTable()
        this.CancelFirstForm()
        // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/admin-aws']);
        // });

        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    // aws api integration
    GetAmazonImage() {
      this.apiService.Admin_CreateAmazonTable(this.selectedRow2.ID).subscribe(data => {
        this.amazonDataTable = data
        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
    }

    GetAllResourceGroups() {
      this.SCMArray=[]

      let obj =
      {
        "aws_config_tool_id": this.selectedRow1.tool_id,
        "group": {
          "arn": this.resourceGrouparn,
          "name": this.resourceGroupname
        }
      }




      this.apiService.Admin_awsRG(obj).subscribe((data: any) => {
        console.log(data.length);
        this.amazonMachineData = data
        data.forEach(member => {
          // console.log(member);

          //  
          let obj = {
            "ID": member.id,
            "name": member.name,
            "description": member.tags.description,
            "os": member.tags.os,
            "server": member.tags.type,
            "type": member.tags.type

          }
          // console.log(obj);

          this.SCMArray.push(obj)
          this.checklist = this.SCMArray;


        }
        )

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
      // console.log(this.SCMArray);
      


    }
    setradio(id) {
      if (id == '1') {
        // console.log("gfufu");

      }

    }
    firstNext(stepper) {
      console.log(stepper._selectedIndex);
      
      if(!this.StepOneFlag){
        this.alertService.error("Tool Group can't be empty", this.options);
        stepper.previous();
      }
      

    }
    secondNext(stepper) {
      // console.log("hiii");
      if(!this.StepOneFlag){
        this.alertService.error("Tool Group can't be empty", this.options);
        stepper.previous();

      }
      else if(!this.StepTwoFlag){
        this.alertService.error("AWS Tool can't be empty", this.options);
        stepper.previous();

      }
      
    }
    thirdNext() {
      // console.log("hiii");
      if(!this.StepOneFlag){
        this.alertService.error("Tool Group can't be empty", this.options);
      }
      else if(!this.StepTwoFlag){
        this.alertService.error("AWS Tool can't be empty", this.options);
      }
    }
    GetAllVPCsintheResourceGroup(arnbody, namebody) {
      // console.log(this.selectedRow1.tool_id);


      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id,
        "group": {
          "arn": arnbody,
          "name": namebody
        }
      }



      this.apiService.Admin_awsGetAllVPCs(obj).subscribe(data => {
        this.vnetVal = data;

        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })


    }
    onChangeSecurityName($event) {
      this.Securityname = $event.target.options[$event.target.options.selectedIndex].text;
      this.SecurityID = $event.target.options[$event.target.options.selectedIndex].value;
      this.saveNetworkConfigParams()


    }
    onChangePropertyNameWorkspace($event) {
      
      // debugger
      // this.model.ProptValue=""
      // this.propertyValueList=[];

      this.resourceGroupnameWorkspace = $event.target.options[$event.target.options.selectedIndex].text;
      this.resourceGrouparnWorkspace = $event.target.options[$event.target.options.selectedIndex].value;
      this.saveNetworkConfigWorkspaceParams()
      // this.GetAllVPCsintheResourceGroup(this.resourceGrouparn,this.resourceGroupname)



    }
    onChangeDirectoryName($event) {
      // debugger
      // this.model.ProptValue=""
      // this.propertyValueList=[];

      this.directoryName = $event.target.options[$event.target.options.selectedIndex].text;
      this.directoryValue = $event.target.options[$event.target.options.selectedIndex].value;
      // this.GetAllVPCsintheResourceGroup(this.resourceGrouparn,this.resourceGroupname)
      this.saveNetworkConfigWorkspaceParams()




    }

    onChangePropertyName($event) {
      // debugger
      // this.model.ProptValue=""
      // this.propertyValueList=[];

      this.resourceGroupname = $event.target.options[$event.target.options.selectedIndex].text;
      this.resourceGrouparn = $event.target.options[$event.target.options.selectedIndex].value;
      this.saveNetworkConfigParams()
      this.GetAllVPCsintheResourceGroup(this.resourceGrouparn, this.resourceGroupname)




    }
    onChangeVnetName($event) {
      // debugger
      // this.model.ProptValue=""
      // this.propertyValueList=[];

      this.vnetName = $event.target.options[$event.target.options.selectedIndex].text;
      this.vnetID = $event.target.options[$event.target.options.selectedIndex].value;
      // console.log(this.resourceGroupname,this.resourceGrouparn);
      this.saveNetworkConfigParams()


      this.GetAllsubnetsintheVPC()




    }
    onChangeSubVnetName($event) {
      // debugger
      // this.model.ProptValue=""
      // this.propertyValueList=[];

      this.subVnetName = $event.target.options[$event.target.options.selectedIndex].text;
      this.subVnetID = $event.target.options[$event.target.options.selectedIndex].value;
      // console.log(this.resourceGroupname,this.resourceGrouparn);
      this.saveNetworkConfigParams()

      this.GetAllSecurityGroupsintheResourceGroup()




    }
    GetAllsubnetsintheVPC() {

      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id,
        "group": {
          "arn": this.resourceGrouparn,
          "name": this.resourceGroupname,
        },
        "vpc": {
          "id": this.vnetID,
          "name": this.vnetName
        }
      }




      this.apiService.Admin_awsGetAllSubnets(obj).subscribe(data => {
        // console.log(data);
        this.subvnetVal = data

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
      )


    }

    GetAllSecurityGroupsintheResourceGroup() {

      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id,
        "group": {
          "arn": this.resourceGrouparn,
          "name": this.resourceGroupname
        }
      }





      this.apiService.Admin_awsSecurityGroup(obj).subscribe(data => {
        // console.log(data);
        this.securityVal = data

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })


    }
    onChangeEc2NetworkName($event) {
      // this.saveNetworkConfigParams()
      
    }
saveNetworkConfigParams(){
  let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
  let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

  // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


  
  if((this.thirdFormGroup.get('name').value.length<3) || (spName.test(this.thirdFormGroup.get('name').value)) || (this.thirdFormGroup.get('name').value==null || this.thirdFormGroup.get('name').value.length==0) ){
    this.saveNWConfigEc2=true
    // return;
  }
  else if((this.thirdFormGroup.get('description').value.length<20) || (spDes.test(this.thirdFormGroup.get('description').value)) || (this.thirdFormGroup.get('description').value==null || this.thirdFormGroup.get('description').value.length==0) ){
    this.saveNWConfigEc2=true
    // return;
  }
  else if(this.resourceGrouparn==null || this.resourceGrouparn.length==0)
{
  this.saveNWConfigEc2=true

}  

else if(this.vnetID==null || this.vnetID.length==0)
{
  this.saveNWConfigEc2=true

}  

else if(this.subVnetID==null || this.subVnetID.length==0)
{
  this.saveNWConfigEc2=true

}  
else if(this.SecurityID==null || this.SecurityID.length==0)
{
  this.saveNWConfigEc2=true

}  

else{
    this.saveNWConfigEc2=false
  }


}
saveNetworkConfigWorkspaceParams(){
  let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
  let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

  // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


  
  if((this.secondFormGroup.get('name').value.length<3) || (spName.test(this.secondFormGroup.get('name').value)) || (this.secondFormGroup.get('name').value==null || this.secondFormGroup.get('name').value.length==0) ){
    this.saveConfigWorkspace=true
    // return;
  }
  else if((this.secondFormGroup.get('description').value.length<20) || (spDes.test(this.secondFormGroup.get('description').value)) || (this.secondFormGroup.get('description').value==null || this.secondFormGroup.get('description').value.length==0) ){
    this.saveConfigWorkspace=true
    // return;
  }
  else if(this.resourceGrouparnWorkspace==null || this.resourceGrouparnWorkspace.length==0)
{
  this.saveConfigWorkspace=true

}  

else if(this.directoryValue==null || this.directoryValue.length==0)
{
  this.saveConfigWorkspace=true

}  


else{
    this.saveConfigWorkspace=false
  }


}
    AddAWSNetworkConfigTool() {
      let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
      // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
  
  
      
      if(this.thirdFormGroup.get('name').value.length<3){
        this.alertService.error("  Please enter minimum 3 characters for Name", this.options);
        return;
      }
      if(spName.test(this.thirdFormGroup.get('name').value)){
        
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
  
      }
      if(this.thirdFormGroup.get('description').value.length<20){
        this.alertService.error("  Please enter minimum 20 characters for Description", this.options);
        return;
      }
      // console.log(this.thirdFormGroup.get('name').value);
      // console.log(this.thirdFormGroup.get('description').value);


      //   let obj={
      //     "resource_type": "tool",
      //     "tool_category": "AWS-Network-Config",
      //     "tool_name": "AWS-Network-Config-EC2",
      //     "tool_group_details": {
      //       "tool_group_name": this.selectedRow2.Name,
      //           "tool_group_id": this.selectedRow2.ID,
      //     },
      //     "tool_instance_details": {
      //         "aws_network_config_tool_instance": {
      //             "aws_config_tool_instance_id":this.selectedRow1.tool_id,

      //             "access_key_id": this.selectedRow1.tool_instance_details.aws_config_tool_instance.access_key_id,
      //             "secret_access_key": this.selectedRow1.tool_instance_details.aws_config_tool_instance.secret_access_key,
      //             "tool_instance_name": this.thirdFormGroup.get('name').value,
      //             "description": this.thirdFormGroup.get('description').value,
      //             "vpc": this.vnetName,
      //             "subnet": this.subVnetName,
      //             "resource_group": this.resourceGroupname,
      //         }
      //     }
      // }
      let obj = {

        "resource_type": "AWS-Network-Config-Tool",

        "tool_category": "AWS-Network-Config",

        "tool_name": this.thirdFormGroup.value.name,

        "tool_group_details": {

          "tool_group_name": this.selectedRow2.Name,
          "tool_group_id": this.selectedRow2.ID,

        },

        "tool_instance_details": {

          "aws_network_config_tool_instance": {

            "vpc": {

              "name": this.vnetName,

              "id": this.vnetID

            },

            "subnet": {

              "name": this.subVnetName,

              "id": this.subVnetID

            },

            "resource_group": {

              "name": this.resourceGroupname,

              "arn": this.resourceGrouparn
            },

            "security_group": {

              "name": this.Securityname,

              "id": this.SecurityID

            },

            "tool_description": this.thirdFormGroup.get('description').value,

          }

        },
        "created_by":localStorage.getItem('mail').toLowerCase()

      }




      // this.spinner.show()

      this.apiService.Admin_awsNetworkConfigTool(obj).subscribe((data: any) => {
    

        this.GetAllResourceGroups()
        
        this.alertService.success(data.message, this.options);
        this.getNetworkConfigToolTable()
        // this.spinner.hide()


        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          this.alertService.error(error.error.message, this.options);


          // this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
      this.spinner.hide()


    }

    GetAWSResourceGroup() {

      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id,
        "group": {
          "arn": "arn:aws:resource-groups:ap-south-1:434378572545:group/LTI-CANVAS-WORKPLACE-PROVISIONING-DEV-RG",
          "name": "LTI-CANVAS-WORKPLACE-PROVISIONING-DEV-RG"
        }
      }





      this.apiService.Admin_awsRG(obj).subscribe(data => {
        // console.log(data);

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // this.alertService.error("Invalid Input", this.options);

          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })


    }

    AddEC2ImageTool() {
      if(this.AddImageArray.length==0){
        this.alertService.error("Select one or more options", this.options);
        return

      }







      this.apiService.Admin_awsAddEc2ImageTool(this.imageBody).subscribe((data: any) => {
        // console.log(data);
        // debugger
        this.alertService.success(data.message, this.options);
        setTimeout(() => {
          this.GetAmazonImage()

          
        }, 2000);
        for (let i = 0; i < this.checklist.length; i++) {
            if(this.checklist[i].isSelected){
              this.checklist[i].isSelected=false
            }
        }

        this.masterSelected=false
        this.checkedList=[]
        this.AddImageArray=[]
        // console.log(this.checklist);
        
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { 
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { 
          this.alertService.error(error.error.message, this.options);

        }
        else {
          this.alertService.error(error.error.message, this.options);

          // this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })


    }
    AddWorkspaceImageTool() {
      if(this.AddImageArrayWorkspace.length==0){
        this.alertService.error("Select one or more options", this.options);
        return

      }
      






      this.apiService.Admin_AddWorkspaceImageTool(this.imageBodyWorkspace).subscribe((data: any) => {
        // console.log(data);
        this.alertService.success(data.message, this.options);
        setTimeout(() => {
          this.awsToolTableWorkspace()

          
        }, 2000);
        for (let i = 0; i < this.checklistWorkspace.length; i++) {
          if(this.checklistWorkspace[i].isSelected){
            this.checklistWorkspace[i].isSelected=false
          }
      }
      this.masterSelectedWorkspace=false
      this.checkedListWorkspace=[]
      this.AddImageArrayWorkspace=[]

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          this.alertService.success(error.error.message, this.options);

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })


    }
    //

    awsToolTable() {
      this.apiService.Admin_GetAWSConfig(this.selectedRow2.ID).subscribe((data: any) => {
        this.awsToolTabledata = data;
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    awsToolTableWorkspace() {
      this.apiService.Admin_GetAWSWorkspaceImageTools(this.selectedRow2.ID).subscribe((data: any) => {
        this.awsToolTabledataWorspace = data;
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    selectRoww(event: any, row) {


      this.selectedRow2 = row;
      this.StepOneFlag=true;

      // console.log(this.firstFormGroup.get('accesskeyid'));
      this.GetAmazonImage()
      this.awsToolTable()
      this.getNetworkConfigToolTable()
      this.getNetworkConfigToolTableWorkspace()
      this.awsToolTableWorkspace()


      // console.log(row);

      // this.getResourceGroup()
    }
    Finish() {
      // console.log("hii");

      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-aws']);
      });

    }
    selectRowWorkspace(event: any, row) {
      // debugger
      this.selectedRow4 = row;
      // console.log(row.tool_instance_details.aws_ec2_image_tool_instance.resource_group);

      this.resourceGrouparn = row.tool_instance_details.aws_network_config_tool_instance.resource_group.arn,
        this.resourceGroupname = row.tool_instance_details.aws_network_config_tool_instance.resource_group.name

      // this.GetAllResourceGroups()


      // console.log(row);

      // this.getResourceGroup()
    }
    selectRowww(event: any, row) {
      // debugger
      this.selectedRow3 = row;
      // console.log(row.tool_instance_details.aws_ec2_image_tool_instance.resource_group);

      this.resourceGrouparn = row.tool_instance_details.aws_network_config_tool_instance.resource_group.arn,
        this.resourceGroupname = row.tool_instance_details.aws_network_config_tool_instance.resource_group.name,
        this.GetAllResourceGroups()


      // console.log(row);

      // this.getResourceGroup()
    }
    getProjDesErrorMessage(){
      let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

      if(this.firstFormGroup.get('description').value.length>0 && this.firstFormGroup.get('description').value.length<20){
        return("Please enter minimum 20 characters for Description");
      }
      if(spDes.test(this.firstFormGroup.get('description').value)){
        return ('Description must include only alphabets ,numbers or _,-')
         }
    }

    getProjAccessErrorMessage(){
      let spDes = /[!^+$@#%&*()\=\\ {};':"\\|,.<>\/?]+/;

      if(this.firstFormGroup.get('accesskeyid').value==null || this.firstFormGroup.get('accesskeyid').value.length==0){
        this.testConnDisable = true
        // return("Access key is required");
      }else{
        if(this.saveConnDisable == false){
          this.testConnDisable = true;
        }else{
          this.testConnDisable = false;
        }
      }
      
      if(spDes.test(this.firstFormGroup.get('accesskeyid').value)){
        return ('Invalid access key')
         }
    }

    getProjSecretErrorMessage(){
      let spDes = /[!^$@#%&*()\=\\ {};':"\\|,.<>\/?]+/;

      if(this.firstFormGroup.get('secretkeyid').value==null ||this.firstFormGroup.get('secretkeyid').value.length==0){
        this.testConnDisable = true
        // return("Secret key is required");
      }else{
        if(this.saveConnDisable == false){
          this.testConnDisable = true;
        }else{
          this.testConnDisable = false;
        }
      }
      if(spDes.test(this.firstFormGroup.get('secretkeyid').value)){
        return ('Invalid secret key')
         }
    }

    getProjNameErrorMessage(){
              // return('Project Name is required')
              let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
              // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
          
          
              if(this.firstFormGroup.get('name').value==null || this.firstFormGroup.get('name').value.length==0){
                this.saveNWConfigWorkspace = true
                // return;
              }
              if(this.firstFormGroup.get('name').value>0 && this.firstFormGroup.get('name').value.length<3){
                this.saveNWConfigWorkspace = true
                return("Please enter minimum 3 characters for Name");
                // return;
              }
              if(spName.test(this.firstFormGroup.get('name').value)){
                this.saveNWConfigWorkspace = true
                return("Name must include only alphabets ,numbers or _,-.");
                // return;
          
              }
       
  
      // else{
      //   return('Project Name is required')
      // } 
    }
    getProjDesErrorMessage2(){
      let saveFlag1 = true;
      let saveFlag2 = true;
      let saveFlag3 = true;
    
      let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

      if(this.secondFormGroup.get('description').value.length ==0 || this.secondFormGroup.get('description').value == null ){
        this.saveNetworkConfigWorkspaceParams()

        saveFlag1 = true;
      }else{
        this.saveNetworkConfigWorkspaceParams()

        saveFlag1 = false;
      }
      
      if(this.secondFormGroup.get('description').value.length >0 && this.secondFormGroup.get('description').value.length<20 ){
        saveFlag2 = true;
        this.saveNetworkConfigWorkspaceParams()

        return("Please enter minimum 20 characters for Description");
      }else{
        this.saveNetworkConfigWorkspaceParams()

        saveFlag2 = false;
      }

      if(spDes.test(this.secondFormGroup.get('description').value)){
        saveFlag3 = true;
        this.saveNetworkConfigWorkspaceParams()

        return ('Description must include only alphabets ,numbers or _,-')
      }else{
        this.saveNetworkConfigWorkspaceParams()

        saveFlag3 = false;
      }

      if(saveFlag1 == false && saveFlag2 == false && saveFlag3 == false){
        this.saveNWConfigWorkspace = false
      }
    }

    getProjNameErrorMessage2(){
              // return('Project Name is required')
              let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
              // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
          
          
              
              if(this.secondFormGroup.get('name').value >0 && this.secondFormGroup.get('name').value.length<3){
                this.saveNetworkConfigWorkspaceParams()
                return("Please enter minimum 3 characters for Name");
                // return;
              }
              if(spName.test(this.secondFormGroup.get('name').value)){
                this.saveNetworkConfigWorkspaceParams()

                return("Name must include only alphabets ,numbers or _,-.");
                // return;
          
              }
       
  
      // else{
      //   return('Project Name is required')
      // } 
    }
    getProjDesErrorMessage3(){
      let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

      if(this.thirdFormGroup.get('description').value.length >0 && this.thirdFormGroup.get('description').value.length<20){
        this.saveNetworkConfigParams()

        return("Please enter minimum 20 characters for Description");
      }
      if(spDes.test(this.thirdFormGroup.get('description').value)){
        this.saveNetworkConfigParams()

        return ('Description must include only alphabets ,numbers or _,-')
         }
      else{
        this.saveNetworkConfigParams()

      }
    }

    getProjNameErrorMessage3(){
      
      
              // return('Project Name is required')
              let spName = /[!^+$@#%&*()\=\\{} ;':"\\|,.<>\/?]+/;
              // let spDes = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
          
          
              
              if(this.thirdFormGroup.get('name').value >0 && this.thirdFormGroup.get('name').value.length<3){
                this.saveNetworkConfigParams()

                return("Please enter minimum 3 characters for Name");
                // return;
              }
              if(spName.test(this.thirdFormGroup.get('name').value)){
                this.saveNetworkConfigParams()

                
                return("Name must include only alphabets ,numbers or _,-.");
                // return;
          
              }
              else{
                this.saveNetworkConfigParams()

              }
       
  
      // else{
      //   return('Project Name is required')
      // } 
    }
    selectRow(event: any, row) {

      this.selectedRow1 = row;
      this.StepTwoFlag=true;

      // console.log(row);

      // console.log(this.firstFormGroup.get('accesskeyid'));
      // console.log(this.selectedRow1);



      this.getResourceGroup()
      this.getResourceGroupWorkspace()
      this.getDirectoryWorkspace()
      this.getBundleWorkspace()
    }

    //step 3
    getNetworkConfigToolTable() {
      this.apiService.Admin_NetworkConfigToolTable(this.selectedRow2.ID).subscribe((data: any) => {
        // console.log(data);

        this.networkConfigTabledata = data;
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    getNetworkConfigToolTableWorkspace() {
      this.apiService.Admin_GetAWSWorkspaceImageToolsNeworkConfig(this.selectedRow2.ID).subscribe((data: any) => {
        this.networkConfigTabledataWorkspace = data;
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    getResourceGroup() {
      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id
      }
      this.apiService.Admin_getResourceGroup(obj).subscribe((data: any) => {
        //   console.log(data);
        //   for (let i = 0; i < data.length; i++) {
        //     console.log(this.resourceGroupVal[i]);

        // }
        this.resourceGroupVal = data;
        // console.log(this.resourceGroupVal);
        // debugger
        // for(let x in this.resourceGroupVal){
        //   console.log(x.name);


        // }



      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    getResourceGroupWorkspace() {
      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id
      }
      this.apiService.Admin_getAllResourceGroupWorkspace(obj).subscribe((data: any) => {

        this.resourceGroupValWorkspace = data;




      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
    }
    getDirectoryWorkspace() {
      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id
      }
      this.apiService.Admin_getWorkspaceDirectoriesAccount(obj).subscribe((data: any) => {

        this.directoryVal = data;




      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })

    }
    getBundleWorkspace() {
      let obj = {
        "aws_config_tool_id": this.selectedRow1.tool_id
      }
      this.apiService.Admin_GetAWSBundlesAccount(obj).subscribe((data: any) => {

        this.bundelVal = data;
        // console.log(data);
        
        data.forEach(member => {
          // console.log(member);

          //  
          let obj = {
            computeType: member.computeType,
            description: member.description,
            id: member.id,
            imageId: member.imageId,
            name: member.name,
            rootStorage: member.rootStorage,
            userStorage: member.userStorage,

          }
          // console.log(obj);

          this.SCMArrayWorkspace.push(obj)
          this.checklistWorkspace = this.SCMArrayWorkspace;


        }
        )
        




      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        { }
        else if (error.status == 403) { }
        else {
          // console.log("hii");


          this.alertService.error(error.error.message, this.options);
          throw error;
        }
      })
      // console.log(this.bundelVal);


    }

  }  
  // getNetworkConfigToolTableWorkspace() {
  //   throw new Error("Method not implemented.");
  // }
  // awsToolTable() {
  //   throw new Error("Method not implemented.");
  // }
  // GetAllVPCsintheResourceGroup(resourceGrouparn: any, resourceGroupname: any) {
  //   throw new Error("Method not implemented.");
  // }
  // GetAllsubnetsintheVPC() {
  //   throw new Error("Method not implemented.");
  // }
  // GetAllSecurityGroupsintheResourceGroup() {
  //   throw new Error("Method not implemented.");
  // }
  // GetAllResourceGroups() {
  //   throw new Error("Method not implemented.");
  // }
  // getNetworkConfigToolTable() {
  //   throw new Error("Method not implemented.");
  // }
  // GetAmazonImage() {
  //   throw new Error("Method not implemented.");
  // }
  // getResourceGroup() {
  //   throw new Error("Method not implemented.");
  // }
  // getResourceGroupWorkspace() {
  //   throw new Error("Method not implemented.");
  // }
  // getDirectoryWorkspace() {
  //   throw new Error("Method not implemented.");
  // }
  // getBundleWorkspace() {
  //   throw new Error("Method not implemented.");
  // }

