<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="pop-up">
  <div class="row">
    <div style="padding-left: 16px;width: 51%;">
      <span *ngIf="this.ToolName=='gitlab'"><img width="30"  src="../../../assets/img/gitlab-logo.png">
      </span>
        <!-- {{element.gitlab}} -->
        <span *ngIf="this.ToolName=='jenkins'"><img width="30"  src="assets/img/Jenkins.png">
        <!-- {{element.jenkins}} -->
        </span>
        <span *ngIf="this.ToolName=='jira'"><img width="30" src="assets/img/jira-logo.png">
          <!-- {{element.jira}} -->
        </span>
        <span *ngIf="this.ToolName=='vdi'"><img width="30"  src="../../../assets/img/addVdi.png">
          <!-- {{element.vdi}} -->
        </span>
        <span *ngIf="this.ToolName=='vm'"><img width="30"  src="assets/img/azure-vm.png">
          <!-- {{element.vm}} -->
        </span>
      <span class="vertical-line mx-1 mx-md-3"> </span>
      <!-- <span class="header mb-0"> Member Tools Information</span> -->
      <span class="header mb-0">  {{(this.ToolName == 'vdi' || this.ToolName =='vm') ? (ToolName |uppercase)  : ToolName | titlecase}} Tool Information</span>
    </div>
    <div class="col-md-5" >
      <mat-form-field fxFlex="60%" *ngIf="finalTable?.length>0">
        <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here"
          autocomplete="off">
      </mat-form-field>
    </div>
    <div style="padding-right: 25px;padding-top:5px ;">
      <span (click)="onNoClick()" class="material-icons close-icon">
        close
      </span>
    </div>
  </div>

  <hr class="margin-class" />
  <div class="popup-container">
    
    <div class="noData-style" *ngIf="dataSource.filter!='' && dataSource.filteredData.length==0">
      <h2>No data available</h2>
    </div>
    <div class="example-container1" *ngIf="dataSource.filteredData.length!=0 ">
      <table mat-table [dataSource]="dataSource">


        <ng-container matColumnDef="tool_name">
          <th mat-header-cell *matHeaderCellDef> Tool </th>
          <td mat-cell *matCellDef="let element">{{(element.tool == 'vdi' || element.tool =='vm') ? (element.tool |uppercase)  : element.tool | titlecase}} </td>
 
        </ng-container>

        <ng-container matColumnDef="request_id">
          <th mat-header-cell *matHeaderCellDef> Request ID </th>
          <td mat-cell *matCellDef="let element"> {{element.request_id| titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="project_name">
          <th mat-header-cell *matHeaderCellDef> Instance Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status| titlecase}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      </table>

    </div>

    <!-- <div class="noData" *ngIf="dataSource.filteredData.length==0 && dataSource.filter=='' "> -->
        <div class="noData" *ngIf="showcont">

      <h2>User is not associated with any tools and can be deleted</h2>
    </div>
    
    <div class="row justify-content-center">
      <div class="col-md-8" *ngIf="dataSource.filteredData.length!=0">
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
        </mat-paginator>
      </div>
      <div>
        <!-- <div class="col-auto col-md-3 mt-3" *ngIf ="!tempData?.Status && dataSource.filter==''"> -->
            <div class="col-auto col-md-3 mt-3" *ngIf ="showcont">
          <button mat-button  mat-raised-button class="add-button"  (click)="deleteMember()"> Delete Member</button>
        </div>
      </div>
      
    </div>
  </div>