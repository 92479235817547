<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar">
      <div class="mr-5" *ngIf="gigFlag"></div>
      <button type="button" *ngIf="!gigFlag" mat-button (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button> 
      <h1 class="example-app-name" routerLink='/admin-catalog-config'>
        <!-- <img class="img-fluid"  src="/assets/img/logo/Logo.svg" alt="Logo">   -->
        <img class="img-fluid"  src="/assets/img/logo/Canvas-Workplace_without-BG.png" alt="Logo" height="35" width="250" style="margin-left: -15px">  

        <span *ngIf="!gigFlag" class="admin-text ml-2">(Admin)</span>
        <!-- <label>(Admin)</label> -->
      </h1>
      
      <!-- TBD -->
      
                                  
  
      <!--TBD End-->
      <span class="spacer"></span>
  
      <span>
  
        <!-- <button id="iconBtn" value="Dashboard" matTooltip="Dashboard" class="menuIcon ">
          <mat-icon class="iconsetting color-theme-blue">search</mat-icon>
        </button>
        <span class="VerticalLine">|</span>   -->   
        <button id="iconBtn" *ngIf="!gigFlag" value="home" matTooltip="home" class="menuIcon" routerLink="/admin-catalog-config" (click)="drawer.close()">
          <mat-icon class="iconsetting color-theme-yellow">home</mat-icon>
        </button>
      
        <!-- <span class="VerticalLine">|</span>   -->
        <span class="VerticalLine mx-0 mx-sm-3">|</span>
        
      </span>
  
      <a class="color-primary">{{profile}}</a>
      
  
      <div style="background: transparent !important;">
        <button class="menuIcon mt-0" mat-button [matMenuTriggerFor]="beforeMenu">
          <mat-icon class="login color-theme-yellow">arrow_drop_down_circle</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <div class="d-flex flex-row">
            <div class="px-1">
              <div class="img-user-initials" *ngIf="profileImageFlag">{{initials}}</div>
              <img *ngIf="!profileImageFlag" alt="Avatar"
              [src]="imageToShow"
              class="user-avatar">           
            </div>          
            <div class="px-1 vLine">
              <span class="user-name">{{profile}}</span><br />
              <span class="user-email">{{userMailId}}</span>
            </div>
          </div>
          <div class="d-flex flex-row my-3" *ngIf="adminRole">
            <div class="px-2 user-role" *ngIf="!gigFlag">Switch Role</div>
            <div class="px-2">
              <div class="role-dropdown" (click)="changeAccess()">
                <div *ngIf="roleAccess == 'User'">Admin</div>
                <div *ngIf="roleAccess == 'Admin'">User</div>
              </div>

            </div>
          </div>
          <div class="seprator">&nbsp;</div>
          <div class="col-md-12 text-center user-name mt-2">
            <span class="material-icons">
              login
              </span>
              <span class="logout-text" (click)="openLogoutModal();">Logout</span>
              <!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    Launch demo modal
  </button> -->
  
  <!-- Modal -->

          </div>
          
        </mat-menu>
      </div>
    </mat-toolbar>
  
    <mat-drawer-container class="example-container" autosize>
      <!-- <mat-drawer #drawer class="example-sidenav side-nav" mode="over"> -->
        <mat-drawer #drawer class="example-sidenav side-nav"  [mode]="(screenWidth > 840) ? 'side' : 'over'">
          <mat-nav-list class="side-nav-title" routerLinkActive="active"  #cat="routerLinkActive">
            <mat-list-item class="parent" routerLink="/admin-catalog-config" (click)="drawer.close(); closeTools();">              
              <img class="img-fluid mr-3" [src]="cat.isActive ? 'assets/icon/menu/catelog-active-icon.png' : 'assets/icon/menu/catelog-icon.png' " />            
              <span class="menu-name">Canvas Subscription</span>
            </mat-list-item>        
          </mat-nav-list>
          <mat-nav-list class="side-nav-title" routerLinkActive="active"  #tool="routerLinkActive">
            <mat-list-item class="parent" routerLink="/admin-tool-group" (click)="drawer.close(); closeTools();">            
              <img class="img-fluid mr-3" [src]="tool.isActive ? 'assets/icon/menu/tool-active-icon.png' : 'assets/icon/menu/tool-icon.png' " />           
              <span class="menu-name">Tool Group</span>
            </mat-list-item>        
          </mat-nav-list>

          <mat-nav-list class="side-nav-title tool-active">
            <mat-list-item class="parent" [ngClass]="{'showSubmenu' : showSubmenu}" (click)="toggleMenu()">            
              <img class="img-fluid mr-3 tool-icon" height="40" [src]="tool.isActive ? 'assets/icon/menu/Tools.svg' : 'assets/icon/menu/Tools.svg' " />           
              <span class="menu-name">Tools</span>
                <span *ngIf="showSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                <span *ngIf="!showSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
            </mat-list-item>   
            <div class="submenu" [ngClass]="{'expanded' : showSubmenu}" *ngIf="showSubmenu">
                <mat-list-item class="parent side-nav-title"  (click)="toggleScmMenu()" routerLinkActive="active"  #scm="routerLinkActive">             
                  <img class="img-fluid mr-3" [src]="scm.isActive ? 'assets/icon/menu/scm-tool-active-icon.png' : 'assets/icon/menu/scm-tool-active-icon.png' " />           
                  <span class="menu-name">SCM Tool</span>
                  <span *ngIf="showScmSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                  <span *ngIf="!showScmSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
                </mat-list-item>   
                <div class="showScmSubmenu" [ngClass]="{'expanded' : showScmSubmenu}" *ngIf="showScmSubmenu">
                  <mat-nav-list class="side-nav-title" routerLinkActive="active"  #gitLab="routerLinkActive">
                    <mat-list-item class="parent" routerLink="/admin-gitlab" (click)="drawer.close()">            
                      <img class="img-fluid mr-3" [src]="gitLab.isActive ? 'assets/img/gitlab-logo.png' : 'assets/img/gitlab-logo.png' " />              
                      <span class="sub-menu-name">Git Lab</span>
                    </mat-list-item>        
                  </mat-nav-list>
                  <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #gitHub="routerLinkActive">
                    <mat-list-item class="parent" routerLink="/admin-aks-cluster" (click)="drawer.close()">            
                      <img class="img-fluid mr-3" [src]="gitHub.isActive ? 'assets/img/github-logo.png' : 'assets/img/github-logo.png' " />              
                      <span class=sub-menu-name>Git Hub</span>
                    </mat-list-item>        
                  </mat-nav-list-->
                </div>  
                <mat-list-item class="parent side-nav-title" routerLinkActive="active"  #story="routerLinkActive" (click)="toggleStoryBoardMenu()" >            
                  <img class="img-fluid mr-3" [src]="story.isActive ? 'assets/icon/menu/story-board-active-icon.png' : 'assets/icon/menu/story-board-active-icon.png' " />              
                  <span class="menu-name">Story Board</span>
                  <span *ngIf="showStoryBoardSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                  <span *ngIf="!showStoryBoardSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
                </mat-list-item>        
              <div class="showStoryBoardSubmenu" [ngClass]="{'expanded' : showStoryBoardSubmenu}" *ngIf="showStoryBoardSubmenu">
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #jira="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-jira" (click)="drawer.close()">            
                    <img class="img-fluid mr-3" [src]="jira.isActive ? 'assets/img/jira-logo.png' : 'assets/img/jira-logo.png' " />              
                    <span class="sub-menu-name">Jira</span>
                  </mat-list-item>        
                </mat-nav-list>
              </div>
                <mat-list-item class="parent side-nav-title"  routerLinkActive="active"  #cd="routerLinkActive" (click)="toggleCdMenu()" >           
                  <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/img/CI-CD-White.svg' : 'assets/img/CI-CD-White.svg' " />            
                  <span class="menu-name">Devops</span>
                  <span *ngIf="showCdSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                  <span *ngIf="!showCdSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
                </mat-list-item>        
              <div class="showCdSubmenu" [ngClass]="{'expanded' : showCdSubmenu}" *ngIf="showCdSubmenu">
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #cd="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-jenkins" (click)="drawer.close()">            
                    <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/img/Jenkins.png' : 'assets/img/Jenkins.png' " />              
                    <span class="sub-menu-name">Jenkins</span>
                  </mat-list-item>        
                </mat-nav-list>
              </div>
              
              <!--Azure menu-->
              <mat-list-item class="parent side-nav-title"  routerLinkActive="active"  #azure="routerLinkActive" (click)="toggleAzureMenu()" >           
                <img class="img-fluid mr-3" [src]="azure.isActive ? 'assets/icon/menu/azure-active-icon.png' : 'assets/icon/menu/azure-active-icon.png' " />            
                <span class="menu-name">Azure</span>
                <span *ngIf="showAzureSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                <span *ngIf="!showAzureSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item>        
            <div class="showAzureSubmenu" [ngClass]="{'expanded' : showAzureSubmenu}" *ngIf="showAzureSubmenu">
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #az="routerLinkActive">
                <mat-list-item  routerLinkActive="active"   routerLink="/admin-azure-config" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="az.isActive ? 'assets/icon/menu/azure-icon.png' : 'assets/icon/menu/azure-icon.png' " />                       
                  <span class="sub-menu-name">Azure Config</span>
                </mat-list-item>
              </mat-nav-list>
               <!--VDI config menu -->
               <mat-nav-list class="side-nav-title" routerLinkActive="active"  #vdc="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-vdi-config" (click)="drawer.close()">           
                  <img class="img-fluid mr-3" [src]="vdc.isActive ? 'assets/img/VDI-Icon.svg' : 'assets/img/VDI-Icon.svg' " />            
                  <span class="sub-menu-name">VDI Images</span>
                </mat-list-item>        
              </mat-nav-list>
                       
               <!--VDI-dropdown config menu -->
               <mat-nav-list class="side-nav-title" routerLinkActive="active"  #vdc="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-vdi-dropdown-config" (click)="drawer.close()">           
                  <img class="img-fluid mr-3" [src]="vdc.isActive ? 'assets/icon/menu/MaterP-White.png' : 'assets/icon/menu/MasterP.png' " />            
                  <span class="sub-menu-name">Master Properties</span>
                </mat-list-item>        
              </mat-nav-list>
 <!--Master Configuration-->
              
              <mat-list-item class="parent p-sub-menu side-nav-title"  routerLinkActive="active"  #network="routerLinkActive" (click)="togglePropertiresMenu()" >           
                <img class="img-fluid mr-3" [src]="network.isActive ? 'assets/img/CI-CD-White.svg' : 'assets/img/CI-CD-img.svg' " />            
                <span class="menu-name">Master Configuration</span>
                <span *ngIf="showPropertiresSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                <span *ngIf="!showPropertiresSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item> 
              <div class="showPropertiresSubmenu" class="sub-sub-menu" *ngIf="showPropertiresSubmenu">
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netAks="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-tenant-propertire" (click)="drawer.close()">            
                    <img class="img-fluid mr-3 ml-3" [src]="netAks.isActive ? 'assets/img/azure- cluster.png' : 'assets/img/azure- cluster.png' " />              
                    <span class="sub-menu-name">Tenant Properties</span>
                  </mat-list-item>        
                </mat-nav-list>
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVdi="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-client-propertire" (click)="drawer.close()">            
                    <img class="img-fluid mr-3 ml-3" [src]="netVdi.isActive ? 'assets/img/VDI-Icon-White.svg' : 'assets/img/VDI-Icon.svg' " />              
                    <span class=sub-menu-name>Client Properties</span>
                  </mat-list-item>        
                </mat-nav-list>
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVm="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-webex-propertire" (click)="drawer.close()">            
                    <img class="img-fluid mr-3 ml-3" [src]="netVm.isActive ? 'assets/img/azure-vm.png' : 'assets/img/azure-vm.png' " />              
                    <span class=sub-menu-name>Webex Properties</span>
                  </mat-list-item>        
                </mat-nav-list>
              </div>
            <!--Network Config-->
              <mat-list-item class="parent p-sub-menu side-nav-title"  routerLinkActive="active"  #network="routerLinkActive" (click)="toggleVPCMenu()" >           
                <img class="img-fluid mr-3" [src]="network.isActive ? 'assets/img/CI-CD-White.svg' : 'assets/img/CI-CD-img.svg' " />            
                <span class="menu-name">Network Config</span>
                <span *ngIf="showVpcSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                <span *ngIf="!showVpcSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item> 
              <div class="showVpcSubmenu" class="sub-sub-menu" *ngIf="showVpcSubmenu">
                <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #netAks="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-aks" (click)="drawer.close()">            
                    <img class="img-fluid mr-3" [src]="netAks.isActive ? 'assets/img/azure- cluster.png' : 'assets/img/azure- cluster.png' " />              
                    <span class="sub-menu-name">AKS</span>
                  </mat-list-item>        
                </mat-nav-list-->
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVdi="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-vdi" (click)="drawer.close()">            
                    <img class="img-fluid mr-3 ml-3" [src]="netVdi.isActive ? 'assets/img/VDI-Icon-White.svg' : 'assets/img/VDI-Icon.svg' " />              
                    <span class=sub-menu-name>VDI</span>
                  </mat-list-item>        
                </mat-nav-list>
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVm="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/admin-vm" (click)="drawer.close()">            
                    <img class="img-fluid mr-3 ml-3" [src]="netVm.isActive ? 'assets/img/azure-vm.png' : 'assets/img/azure-vm.png' " />              
                    <span class=sub-menu-name>VM</span>
                  </mat-list-item>        
                </mat-nav-list>
              </div>
               <!-- Deployment store -->
               <mat-list-item class="parent p-sub-menu side-nav-title" routerLinkActive="active"  #ds="routerLinkActive" (click)="toggleAwsDSMenu()">            
                <img class="img-fluid mr-3" [src]="ds.isActive ? 'assets/icon/menu/Deployment-Store.svg' : 'assets/icon/menu/Deployment-Store.svg' " />                       
                <span class="menu-name">Deployment Store</span>
                <span *ngIf="showAwsDSSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
              <span *ngIf="!showAwsDSSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item>        
               <div class="showAwsDSSubmenu" [ngClass]="{'expanded' : showAwsDSSubmenu}" *ngIf="showAwsDSSubmenu">
                 <mat-nav-list class="side-nav-title" routerLinkActive="active"  #vm="routerLinkActive">
                 <mat-list-item class="parent" routerLink="/admin-deploymentStore-VM" (click)="drawer.close()">            
                <img class="img-fluid mr-3" [src]="vm.isActive ? 'assets/img/azure-vm.png' : 'assets/img/azure-vm.png' " />              
                <span class="sub-menu-name">VM Images</span>
                </mat-list-item>        
                </mat-nav-list>
                  <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #aks="routerLinkActive">
                    <mat-list-item class="parent" routerLink="/admin-aks-cluster" (click)="drawer.close()">            
                     <img class="img-fluid mr-3" [src]="aks.isActive ? 'assets/img/azure- cluster.png' : 'assets/img/azure- cluster.png' " />              
                    <span class="sub-menu-name">AKS Cluster</span>
                  </mat-list-item>        
                 </mat-nav-list-->
                 </div>
            </div>
            <mat-list-item class="parent side-nav-title" routerLinkActive="active" #azure="routerLinkActive" routerLink="/admin-aws" (click)="drawer.close(); closeTools();">
              <img class="img-fluid mr-3" [src]="azure.isActive ? 'assets/img/VDI-Icon-White.svg' : 'assets/img/VDI-Icon-White.svg' " />
              <span class="menu-name">AWS</span>
              <span *ngIf="showAwsSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>
              <span *ngIf="!showAwsSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item>

              <!-- <mat-list-item class="parent side-nav-title" routerLinkActive="active" #azure="routerLinkActive" routerLink="/admin-gig" (click)="drawer.close(); closeTools();">
                <img class="img-fluid mr-3" [src]="azure.isActive ? 'assets/img/VDI-Icon-White.svg' : 'assets/img/VDI-Icon-White.svg' " />
                <span class="menu-name">Canvas Gig</span>
                <span *ngIf="showAwsSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>
                <span *ngIf="!showAwsSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
                </mat-list-item> -->
             <!--AWS menu-->
             <!-- for Live Development purpose -->
              <!--mat-list-item class="parent side-nav-title"  routerLinkActive="active"  #azure="routerLinkActive" (click)="toggleAwsMenu()" >           
              <img class="img-fluid mr-3" [src]="azure.isActive ? 'assets/icon/menu/azure-active-icon.png' : 'assets/icon/menu/azure-active-icon.png' " />            
              <span class="menu-name">AWS</span>
              <span *ngIf="showAwsSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
              <span *ngIf="!showAwsSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
            </mat-list-item-->       
          <div class="showAwsSubmenu" [ngClass]="{'expanded' : showAwsSubmenu}" *ngIf="showAwsSubmenu">
            <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #az="routerLinkActive">
              <mat-list-item  routerLinkActive="active"  routerLink="/admin-aws-config" (click)="drawer.close()">            
                <img class="img-fluid mr-3" [src]="az.isActive ? 'assets/icon/menu/azure-icon.png' : 'assets/icon/menu/azure-icon.png' " />                       
                <span class="sub-menu-name">AWS Config</span>
              </mat-list-item>
            </mat-nav-list>
             
             <mat-nav-list class="side-nav-title" routerLinkActive="active"  #vdc="routerLinkActive">
              <mat-list-item class="parent" routerLink="/admin-vdi-config" routerLink="/admin-cast-config"  (click)="drawer.close()">           
                <img class="img-fluid mr-3" [src]="vdc.isActive ? 'assets/img/VDI-Icon.svg' : 'assets/img/VDI-Icon.svg' " />            
                <span class="sub-menu-name">Workspace AMI</span>
              </mat-list-item>        
            </mat-nav-list>
                     
            <mat-list-item class="parent p-sub-menu side-nav-title"  routerLinkActive="active"  #network="routerLinkActive" (click)="toggleNetworkMenu()" >           
              <img class="img-fluid mr-3" [src]="network.isActive ? 'assets/img/CI-CD-White.svg' : 'assets/img/CI-CD-img.svg' " />            
              <span class="menu-name">VPC Config</span>
              <span *ngIf="showNetworkSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
              <span *ngIf="!showNetworkSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
            </mat-list-item--> 
            <div class="showNetworkSubmenu" [ngClass]="{'expanded' : showNetworkSubmenu}" *ngIf="showNetworkSubmenu">
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netAks="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-aks"  routerLink="/admin-cast-config" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="netAks.isActive ? 'assets/img/azure- cluster.png' : 'assets/img/azure- cluster.png' " />              
                  <span class="sub-menu-name">ECS</span>
                </mat-list-item>        
              </mat-nav-list>
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVdi="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-vdi"  routerLink="/admin-cast-config" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="netVdi.isActive ? 'assets/img/VDI-Icon-White.svg' : 'assets/img/VDI-Icon.svg' " />              
                  <span class=sub-menu-name>AMI</span>
                </mat-list-item>        
              </mat-nav-list>
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #netVm="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-vm"  routerLink="/admin-cast-config" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="netVm.isActive ? 'assets/img/azure-vm.png' : 'assets/img/azure-vm.png' " />              
                  <span class=sub-menu-name>EC2</span>
                </mat-list-item>        
              </mat-nav-list>
            </div>
             <!-- Deployment store -->
             <mat-list-item class="parent p-sub-menu side-nav-title" routerLinkActive="active"  #ds="routerLinkActive" (click)="toggleDSMenu()">            
              <img class="img-fluid mr-3" [src]="ds.isActive ? 'assets/icon/menu/Deployment-Store.svg' : 'assets/icon/menu/Deployment-Store.svg' " />                       
              <span class="menu-name">Deployment Store</span>
              <span *ngIf="showDSSubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
            <span *ngIf="!showDSSubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
            </mat-list-item>        
        <div class="showDSSubmenu" [ngClass]="{'expanded' : showDSSubmenu}" *ngIf="showDSSubmenu">
          <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #vm="routerLinkActive">
            <mat-list-item class="parent"  routerLink="/admin-cast-config" (click)="drawer.close()">            
              <img class="img-fluid mr-3" [src]="vm.isActive ? 'assets/img/azure-vm.png' : 'assets/img/azure-vm.png' " />              
              <span class="sub-menu-name">EC2 AMI</span>
            </mat-list-item>        
          </mat-nav-list>
          <mat-nav-list class="side-nav-title" routerLinkActive="active"  #aks="routerLinkActive">
            <mat-list-item class="parent"  routerLink="/admin-cast-config" (click)="drawer.close()">            
              <img class="img-fluid mr-3" [src]="aks.isActive ? 'assets/img/azure- cluster.png' : 'assets/img/azure- cluster.png' " />              
              <span class="sub-menu-name">ECS</span>
            </mat-list-item>        
          </mat-nav-list-->
        </div>
       
          </div>

          
                <!--mat-list-item class="parent side-nav-title"  routerLinkActive="active"  #ci="routerLinkActive" (click)="toggleCIMenu()" >           
                  <img class="img-fluid mr-3" [src]="ci.isActive ? 'assets/icon/menu/metis-active-icon.png' : 'assets/icon/menu/metis-active-icon.png' " />            
                  <span class="menu-name">LTI Store</span>
                  <span *ngIf="showCISubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                  <span *ngIf="!showCISubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
                </mat-list-item-->        
              <div class="showCISubmenu" [ngClass]="{'expanded' : showCISubmenu}" *ngIf="showCISubmenu">
                <mat-nav-list class="side-nav-title" routerLinkActive="active"  #cd="routerLinkActive">
                  <mat-list-item class="parent" routerLink="/catch-clear-test" (click)="drawer.close()">            
                    <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/icon/menu/metis-icon.png' : 'assets/icon/menu/metis-icon.png' " />              
                    <span class="sub-menu-name">Canvas Insights</span>
                  </mat-list-item>    
                  <!--
                  <mat-list-item class="parent" routerLink="/admin-Test" (click)="drawer.close()">            
                    <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/img/jenkins-logo.png' : 'assets/img/jenkins-logo.png' " />              
                    <span class="sub-menu-name">Canvas Test</span>
                  </mat-list-item>-->     
                </mat-nav-list>
              </div>  
              <!-- SCA menu -->
              <!--mat-list-item class="parent side-nav-title"  routerLinkActive="active"  #sca="routerLinkActive" (click)="toggleSCAMenu()" >           
                <img class="img-fluid mr-3" [src]="sca.isActive ? 'assets/icon/menu/SCA-White.png' : 'assets/icon/menu/SCA-White.png' " />            
                <span class="menu-name">SCA</span>
                <span *ngIf="showSCASubmenu" class="glyphicon glyphicon-chevron-up arrow-space"></span>  
                <span *ngIf="!showSCASubmenu" class="glyphicon glyphicon-chevron-down arrow-space"></span>
              </mat-list-item-->        
            <div class="showSCASubmenu" [ngClass]="{'expanded' : showSCASubmenu}" *ngIf="showSCASubmenu">
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #cd="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-aks-cluster" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/icon/menu/cast-config-icon.png' : 'assets/icon/menu/cast-config-icon.png' " />              
                  <span class="sub-menu-name">Cast Config</span>
                </mat-list-item>        
              </mat-nav-list>
              <mat-nav-list class="side-nav-title" routerLinkActive="active"  #cd="routerLinkActive">
                <mat-list-item class="parent" routerLink="/admin-aks-cluster" (click)="drawer.close()">            
                  <img class="img-fluid mr-3" [src]="cd.isActive ? 'assets/icon/menu/Sonarqube.png' : 'assets/icon/menu/Sonarqube.png' " />              
                  <span class="sub-menu-name">SonarQube</span>
                </mat-list-item>        
              </mat-nav-list>
            </div> 
              
            </div>     
          </mat-nav-list>
  
        
       
          <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #devTest="routerLinkActive">
            <mat-list-item class="parent" routerLink="/admin-dev-test" (click)="drawer.close(); closeTools();">            
              <img class="img-fluid mr-3" [src]="devTest.isActive ? 'assets/img/Dev Framework White.svg' : 'assets/img/Dev Framework.svg' " />           
              <span class="menu-name">Dev Framework</span>
            </mat-list-item>        
          </mat-nav-list-->

          <mat-nav-list class="side-nav-title" routerLinkActive="active"  #devTest="routerLinkActive">
            <mat-list-item class="parent" routerLink="/admin-projectTemplate" (click)="drawer.close(); closeTools();">            
              <img class="img-fluid mr-3" [src]="devTest.isActive ? 'assets/img/Dev Framework White.svg' : 'assets/img/Dev Framework.svg' " />           
              <span class="menu-name">Template</span>
            </mat-list-item>        
          </mat-nav-list>

          <mat-nav-list class="side-nav-title" routerLinkActive="active"  #devTestweb="routerLinkActive">
            <mat-list-item class="parent" routerLink="/webex-login" (click)="drawer.close(); closeTools();">            
              <img class="img-fluid mr-3" [src]="devTestweb.isActive ? 'assets/img/webex.svg' : 'assets/img/webex.svg' " />           
              <span class="menu-name">Webex</span>
            </mat-list-item>        
          </mat-nav-list>
          
          <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #reports="routerLinkActive">
            <mat-list-item class="parent" routerLink="/admin-reports" (click)="drawer.close(); closeTools();">            
              <img class="img-fluid mr-3" [src]="reports.isActive ? 'assets/icon/menu/Dashboard White.svg' : 'assets/icon/menu/Dashboard color.svg' " />                                                      
              <span class="menu-name">Reports</span>
            </mat-list-item>        
          </mat-nav-list-->

        <!--mat-nav-list class="side-nav-title" routerLinkActive="active"  #lc="routerLinkActive">
          <mat-list-item class="parent" routerLink="/admin-aks-cluster" (click)="drawer.close(); closeTools();">            
            <img class="img-fluid mr-3" [src]="lc.isActive ? 'assets/icon/menu/licence-active-icon.png' : 'assets/icon/menu/licence-icon.png' " />                                                      
            <span class="menu-name">License</span>
          </mat-list-item>        
        </mat-nav-list--> 
      </mat-drawer>
    
      <div class="example-sidenav-content admin-main">
         <div class="bg"> 

          <div class="error-continaer">
            <alert></alert>
          </div>
          

          <router-outlet></router-outlet>
        </div> 
      </div>
    </mat-drawer-container>
      
  </div>
