import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../service/api.service';
import { RouterModule, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import { PhoneNumberValidator } from "./number.validator"
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { AlertService } from 'src/app/_alert';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MaterialModule } from '../material.module';



const GRAPH_ENDPOINT = environment.GRAPH_ENDPOINT;

@Component({
  selector: 'app-project-list-page',
  templateUrl: './project-list-page.component.html',
  styleUrls: ['./project-list-page.component.scss']
})
export class ProjectListPageComponent implements OnInit {
  Role: string = "";
  profile;
  initials;
  projectData: any;
  selectedProject: any;
  designation;
  userMailId: string;
  userRole: string;

  profilePhotoImage;
  members: any = [];
  projectRole: any = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  isUserTM: boolean;
  isUserTeamMember: boolean = true;

  private footerLogoPath = environment.footerLogoPath;
  footerimageSrc: any;
  footerimageAlt: any;
  canvasAdmin: boolean;
  env: string;

  constructor(private spinner: NgxSpinnerService, private router: Router, public alertService: AlertService,
    private http: HttpClient, public dialog: MatDialog, private apiService: AuthService, private _router: Router
  ) {
    this.profile = localStorage.getItem('profile');

    this.designation = localStorage.getItem("designation");
    this.initials = this.profile.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
    this.env = environment.scmEnv
    this.footerimageSrc = this.footerLogoPath;
    this.footerimageAlt = 'Footer';
  }

  response
  responseFinal: any
  profileImageFlag
  ngOnInit(): void {
    // debugger
    this.env = environment.scmEnv

    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.apiService.getProfileImage(GRAPH_ENDPOINT + "/me/photo/$value").subscribe(data => {
        this.createImageFromBlob(data)
      })

      this.apiService.profileImageFlag.subscribe(data => {
        // console.log(data);

        this.profileImageFlag = data
      })

      this.profile = localStorage.getItem('profile');
      this.userMailId = localStorage.getItem('mail').toLowerCase();
      this.userRole = localStorage.getItem('role');
      this.getProjectList();
    }
    else {
      this._router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/admin-catalog-config']);
      });
    }

  }

  minajFlag: boolean = false


  imageToShow: any;
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  profileImage: any
  getProfilePhoto() {
    var response = this.http.get(GRAPH_ENDPOINT + "/me/photo/$value").toPromise()
      .then(profile => {

      }).toString();
    var b64Response = btoa(response);
    var outputImg = document.createElement('img');

    outputImg.src = 'data:image/png;base64,' + b64Response;

    this.profilePhotoImage = outputImg.src

    document.body.appendChild(outputImg);
  }

  getProjectId(event, id) {
    // debugger
    // debugger
    let userPsNumber = "";
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    this.apiService.getCatalogProjectById(id).subscribe(project => {
      // console.log("API Call");

      // console.log(project,"project detailssssssssss");
      // debugger
      // console.log(project[0].members.length);



    })

    // console.log("hello");
    // this.isUserTM = localStorage.getItem('isUserTM');
    // console.log(this.isUserTM);
    // console.log(this.isUserTM=='false');
    // localStorage.setItem('projectId',id);

    this.apiService.getCatalogProjectById(id).subscribe(project => {
      
      // console.log(project,"details");
      localStorage.setItem('projectId', project[0].project_id);

      this.members = project[0].members
      this.members.forEach(element => {
        if (element.user_email == this.userMailId) {
          this.projectRole = element.role_name
        }

      });
      for (let i = 0; i < project[0].members.length; i++) {
        if (userPsNumber == project[0].members[i].user_id) {
          var roleNameComp = ""

          let roleName = project[0].members[i].role_name[0]

          /////////
          for (let j = 0; j < roleName.length; j++) {

            if (roleName[j].charCodeAt(0) != 160
            ) {
              roleNameComp = roleNameComp + roleName[j]

            }




          }



          // str = str.replace(/ +/g, "");
          roleName = roleNameComp.replace(/ +/g, "");


          ////////
          // console.log(roleName=="ProjectManager");

          if (roleName == "ProjectManager") {
            this.isUserTeamMember = false
          }

        }
      }
      // console.log(project[0].roles_assigned);
      // debugger
      // console.log(project[0].roles_assigned.length==1);
      // console.log(project[0].roles_assigned.length == 1,"length");
      // console.log(this.isUserTeamMember,"TM");


      if (project[0].roles_assigned.length == 1 && project[0].members.length>1) {
        // console.log("hi");
        




        if (!this.isUserTeamMember) {
          
          
          // debugger
          // this.alertService.error('You have not assigned any role yet for this project.', this.options);
          // const dialogRef = this.dialog.open(ModalComponent, {
          //   width: '30%',
          //   height: '27%',
          //   disableClose: true,
          //   // id:req_id,


          // });
          // this._router.navigate(['/projectSummary'])

          // this._router.navigate(['/project-roles'])
          this.apiService.getMemberStatusForProject(id).subscribe((data: any) => {
            // debugger;
            // if (data.length != 0) {
            //   this.deleteMemberProject(data, 'delete')
            // } else {

            // }
          })
          this.router.navigateByUrl('/projectSummary', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/project-roles']);

          });
          
          return
        }

      }
      this.projectRole.forEach(element => {
        if (element == "Secondary Project Manager" || element == "Project Manager" || element == "PM") {
          this._router.navigate(['/projectSummary'])
          this.apiService.getMemberStatusForProject(id).subscribe((data: any) => {
            // debugger;
            // if (data.length != 0) {
            //   this.deleteMemberProject(data, 'delete')
            // } else {

            // }
          })
        }
        else {
          this._router.navigate(['/projectSummary'])
        }
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })

  }
  deleteMemberProject(data, type) {
    const pooledUser = this.dialog.open(deleteMemberFromProject, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        values: data,
        screen: type
      }
    });
  }

  public getProjectList() {
    this.spinner.show();
    this.userMailId = localStorage.getItem('mail').toLowerCase()
    this.apiService.getProjectByEmailId(this.userMailId).subscribe((data: any) => {
      if (data.pmArray.length == 0) {
        this.projectData = []
      }
      else {
        this.projectData = data.pmArray
        this.canvasAdmin = data.userInfo.canvasAdmin
      }
      this.spinner.hide();
    })
  }

  addProject() {
    if (this.canvasAdmin == false) {
      const addProjectDialogRef = this.dialog.open(AddProjectDialog, {
        width: '45%',
        height: '25%',
        disableClose: true
      });

      addProjectDialogRef.afterClosed().subscribe(result => {

      });
    }
    else {
      const addProjectDialogRef = this.dialog.open(AddProjectDialog, {
        width: '70%',
        height: '90%',
        disableClose: true
      });

      addProjectDialogRef.afterClosed().subscribe(result => {

        this.getProjectList();

      });
    }


  }


}



export interface projectList {
  teamMembers: any;
  projectId: string;
  projectName: string;
  projectManager: string;
  kdmName: string;
  startDate: string;
  endDate: string;
  projectType: string;
  customerName: string,
  deliveryBU: string,
  isNew: string;

}


export interface catalogList {
  catalog_id: string
  catalog_name: string;
  catalog_description: string
}

export interface userList {
  user_id: string;
  user_name: string;
  user_email: string;
  role_name: string;
  project_start_date: string;
  project_end_date: string;
  location: string
}

export interface customUserList {
  user_id: string;
  user_name: string;
  user_email: string;
  action: string;
}

const ELEMENT_DATA: projectList[] = [];

const USER_DATA: userList[] = [];

const CUSTOM_USER_DATA: customUserList[] = [];
@Component({
  selector: 'add-project',
  templateUrl: 'add-project.html',
  styleUrls: ['./project-list-page.component.scss'],
  providers: [DatePipe]
})
export class AddProjectDialog {

  catalogFlag = true

  // ELEMENT_DATA: projectList[];
  displayedColumns: string[] = ['projectId', 'projectName', 'projectManager', 'kdmName', 'startDate', 'endDate', 'projectType', 'customerName', 'deliveryBU', 'isNew'];
  dataSource = new MatTableDataSource<projectList>(ELEMENT_DATA);

  CATALOG_DATA: catalogList[];
  displayedColumnsCatalog: string[] = ['catalogID', 'catalogName', 'catalogDescription', "catalogSelect"];
  //dataSourceCatalog = new MatTableDataSource<catalogList>(this.CATALOG_DATA);


  USER_DATA: userList[];
  displayedColumnsUser: string[] = ['user_id', 'user_name', 'user_email', 'role_name', 'project_start_date', 'project_end_date', 'location'];
  dataSourceUser = new MatTableDataSource<userList>(USER_DATA);

  CUSTOM_USER_DATA: customUserList[];
  displayedColumnsCustomUser: string[] = ['user_id', 'user_name', 'user_email', 'action'];
  dataSourceCustomUser = new MatTableDataSource<customUserList>(CUSTOM_USER_DATA);

  userNotFound: boolean = false;
  showCatalog: boolean = false;
  showNext: boolean;
  showFinish: boolean;
  selectedIndex: any;
  showError: boolean;
  projectData: any;
  memberData: any;
  projectInfo: any;
  userInfo: any;
  imanageprojectInfo: any = []
  iManageProjArray: any = [];
  iManageUserArray: any = [];
  dateNow: Date = new Date()
  currentDate;
  projectIManageEndDate;
  teamsMemberArray: any = [];
  userMailId: string;
  profile: string;
  selectedType: string = 'Custom';// 'iManage';
  firstDivision: boolean = true;
  types: any = [

    "Custom", "iManage"
  ];
  roles: any = [
    "PM",
    "TM"
  ];
  showDetails: boolean = true;
  showMembers: boolean = false;
  customMemberArr: any = []
  enableRegister: boolean = false
  catalogObj: any;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  filteredMemberArr = [];
  psNumber: any;
  singleCatalogData: any;
  showProjIdError: boolean = false;
  noImanageProjects: boolean = false;
  projectCreateError: any;
  thirdFormGroup: FormGroup;
  selectedTemplateName: any;
  selectedTemplateId: any;
  templateFlag: boolean = false;
  adminRole: boolean;
  modalData: any;
  managerFlag: boolean = false;
  errMessage: any;
  userExists: boolean;
  subFlag: boolean = false;
  nextFlag: boolean;
  emptySubs: string;

  constructor(public alertService: AlertService, private http: HttpClient, public router: Router, public dialogRef: MatDialogRef<AddProjectDialog>, public apiService: AuthService, private snackBar: MatSnackBar, public dialog: MatDialog, private _formBuilder: FormBuilder, private datePipe: DatePipe) { }

  selection = new SelectionModel<projectList>(false, []);

  catalogTableFlag = false
  catalogData = [];
  toolGroupString: string = "";

  myControl = new FormControl();
  myControlId = new FormControl();
  filteredOptions: Observable<string[]>;
  filteredOptionsId: Observable<string[]>;
  catalog_name_array = [];
  catalog_id_array = [];
  showSearchById: boolean = true;
  showSearchByName: boolean = false;
  domainList = [];

  catalogSearchedData = {
    "catalog_id": "",
    "catalog_name": "",
    "catalog_description": ""
  }

  ngOnInit() {
    this.showNext = false
    this.showFinish = false
    if (localStorage.getItem('canvasAdminRole') == "false") {
      this.adminRole = false;
    }
    else {
      this.adminRole = true;
    }
    this.getProjects()
    this.currentDate = this.datePipe.transform(this.dateNow, 'yyyy-MM-dd');
    this.userMailId = localStorage.getItem('mail').toLowerCase()
    this.profile = localStorage.getItem('profile');
    this.firstFormGroup = this._formBuilder.group({
      customId: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30), Validators.pattern('^([a-zA-Z_0-9])+$')]],
      customName: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30), Validators.pattern('^([a-zA-Z_0-9])+$')]],
    });
    this.secondFormGroup = this._formBuilder.group({
      memberEmail: ['', [Validators.pattern('^[a-zA-Z0-9.]+[@]{1}(lntinfotech\.com|ltimindtree\.com)$')]],

    }); //^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{2,4}$
    this.thirdFormGroup = this._formBuilder.group({
      domainName: ['', Validators.required],

    });

    //GET CATALOG DATA
    this.apiService.getCatalogList().subscribe((data: any) => {
      this.catalogData = data;
      this.catalogData.forEach(element => {
        this.catalog_name_array.push(element.catalog_name);
      });
      this.catalogData.forEach(element => {
        this.catalog_id_array.push(element.catalog_id);
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 437) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No Subscription found.", this.options);
        throw error;
      }

    })

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    this.filteredOptionsId = this.myControlId.valueChanges.pipe(
      startWith(''),
      map(value => this._filterId(value))
    );

  }

  get domainName() {
    return this.thirdFormGroup.get('domainName');
  }

  getDomain(value) {
    this.templateFlag = true;
    this.selectedTemplateId = value.template_id;
    this.apiService.selectedTemplateId.next(this.selectedTemplateId);
    this.selectedTemplateName = value.template_name;
  }

  nextTemplate() {

    this.templateFlag = false;
    this.selectedTemplateId = "";
    this.selectedTemplateName = "";
    this.thirdFormGroup.get('domainName').setValue(" ");
  }
  selectionChange(event) {
    let stepIndex = event.selectedIndex
    if (stepIndex == "2") {
      this.nextTemplate();
    }
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.catalog_name_array.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);

  }
  private _filterId(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.catalog_id_array.filter(option => option.toLowerCase().indexOf(filterValue) !== -1);
  }

  setradio(value) {
    if (value == 'Id') {
      this.showSearchById = true;
      this.showSearchByName = false;
    } else {
      this.showSearchById = false;
      this.showSearchByName = true;
    }
  }

  get f() { return this.secondFormGroup.controls; }

  searchCatalogByName(name) {
    this.emptySubs = "";

    this.catalogData.forEach(catalog => {

      if (catalog.catalog_name == name) {
        this.catalogSearchedData = catalog;
        catalog.tool_groups.forEach(element => {
          this.toolGroupString = "";
          this.toolGroupString += element.tool_group_name + ","
          this.toolGroupString = this.toolGroupString.replace(/,(?=\s*$)/, '');
        })
      }
    })
    this.catalogTableFlag = true
    this.apiService.getCatalogInformation(this.catalogSearchedData.catalog_id).subscribe((data: any) => {
      this.singleCatalogData = data;
      this.subFlag = false
      this.nextFlag = false
      // console.log(data);


      if (data.azure_tools.length == 0 && data.cicd_tools.length == 0
        && data.deploymentstore_tools.length == 0 && data.network_config_tools.length == 0
        && data.scm_tools.length == 0 && data.storyboard_tools.length == 0 && data.aws_config_tools.length == 0 && data.aws_ec2_image_tools.length == 0 && data.aws_network_config_tools.length == 0 && data.aws_workspace_tools.length == 0) {
        this.subFlag = true
        this.nextFlag = true


      }

      this.domainList = [];

      this.domainList = this.singleCatalogData.project_templates

    }, (error: HttpErrorResponse) => {
      if (error.status == 437) {
        // console.log("ddd");

        this.subFlag = true
        this.nextFlag = true

        // this.emptySubs = "This subscription doesn't have any data. Please select another subscription"
        // this.alertService.error(error.error.message, this.options);
        // this.alertService.error(this.emptySubs, this.options);


      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Unable to fetch data", this.options);
        throw error;
      }

    })
  }

  searchCatalogById(id) {
    this.emptySubs = "";
    this.singleCatalogData = [];
    this.catalogData.forEach(catalog => {
      if (catalog.catalog_id == id) {
        this.catalogSearchedData = catalog;
        catalog.tool_groups.forEach(element => {
          this.toolGroupString = "";
          this.toolGroupString += element.tool_group_name + ","
          this.toolGroupString = this.toolGroupString.replace(/,(?=\s*$)/, '');
        })
      }

    })
    this.catalogTableFlag = true
    this.apiService.getCatalogInformation(this.catalogSearchedData.catalog_id).subscribe((data: any) => {
      this.singleCatalogData = data;
      this.subFlag = false
      this.nextFlag = false
      if (data.azure_tools.length == 0 && data.cicd_tools.length == 0
        && data.deploymentstore_tools.length == 0 && data.network_config_tools.length == 0
        && data.scm_tools.length == 0 && data.storyboard_tools.length == 0 && data.aws_config_tools.length == 0 && data.aws_ec2_image_tools.length == 0 && data.aws_network_config_tools.length == 0 && data.aws_workspace_tools.length == 0) {
        this.subFlag = true
        this.nextFlag = true


      }

      this.domainList = [];

      this.domainList = this.singleCatalogData.project_templates


    }, (error: HttpErrorResponse) => {
      if (error.status == 437) {
        this.subFlag = true
        this.nextFlag = true
        // this.emptySubs = "This subscription doesn't have any data. Please select another subscription"

        // this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Unable to fetch data", this.options);
        throw error;
      }

    })
  }

  selectCatalog() {
    this.catalogFlag = false;
  }

  getCustomNameErrorMessage() {
    return this.firstFormGroup.controls.customName.hasError('pattern') ?
      'Project name must include only alphabets ,numbers and _' : this.firstFormGroup.controls.customName.hasError('maxlength') ? 'Maximum length should be 30 characters' : this.firstFormGroup.controls.customName.hasError('minlength') ?
        'Minimum length should be 4 characters' :
        'Project Name is required'

  }
  getCustomIdErrorMessage() {

    return this.firstFormGroup.controls.customId.hasError('pattern') ?
      'Project ID must include only alphabets ,numbers and _' : this.firstFormGroup.controls.customId.hasError('maxlength') ? 'Maximum length should be 30 characters' : this.firstFormGroup.controls.customId.hasError('minlength') ?
        'Minimum length should be 4 characters' :
        'Project ID is required'

  }

  showCatalogInfo(type) {
    if (type == "subscription") {
      this.modalData = this.singleCatalogData
    }

    else {
      this.modalData = ""
    }

    this.apiService.singleCatalogInfo.next(this.singleCatalogData);

    this.apiService.modalTypeInProjectList.next(type);
    // if(this.modalData != ""){
    const dialogRef = this.dialog.open(ShowCatalogInfoDialog, {
      width: '73%',
      height: 'auto',
      disableClose: true,
      data: { data: this.modalData }
    });

    dialogRef.afterClosed().subscribe(result => {
    });

    // }


  }

  get customId() {
    return this.firstFormGroup.get('customId');
  }
  get customName() {
    return this.firstFormGroup.get('customName');
  }
  get memberEmail() {
    return this.secondFormGroup.get('memberEmail');
  }

  get selectedRole() {
    return this.secondFormGroup.get('selectedRole');
  }
  public showHide() {
    this.showDetails = false;
    this.showMembers = true;

  }

  public showDetailsTab() {
    this.showDetails = true;
    this.showMembers = false;
  }

  searchEmployee() {
    this.userNotFound = false;
    this.userExists = false;
    this.apiService.getUserVerification(this.memberEmail.value).subscribe((data: any) => {
      if (data.ps_number == null || this.memberEmail.value == " ") {
      }
      else if (this.filteredMemberArr.findIndex(x => x.user_email.toLowerCase() === this.memberEmail.value.toLowerCase()) !== -1) {
        this.userExists = true;
        this.secondFormGroup.reset();
      }
      else {
        this.apiService.psNumber.subscribe(result => {
          this.psNumber = result;
        })

        this.customMemberArr.push({
          "user_id": this.psNumber,
          "user_name": this.profile,
          'user_email': this.userMailId,
          "role_name": ["Project Manager"]
        })
        this.enableRegister = true;
        let memberObj: any = {
          "user_id": data.ps_number,
          "user_name": data.displayName,
          'user_email': data.mail,
          "role_name": ["Team Member"]
        }

        this.customMemberArr.push(memberObj)
        const uniqIds = {};
        this.filteredMemberArr = this.customMemberArr.filter(obj => !uniqIds[obj.user_id] && (uniqIds[obj.user_id] = true));
        this.secondFormGroup.reset();

        this.dataSourceCustomUser.data = this.filteredMemberArr as customUserList[];

      }
      if (data.status == "Unable to create Request.") {
        this.userNotFound = true;
      }
    }, (err: HttpErrorResponse) => {
      if (err.status == 434) {
        if (err.error.message == "Unable to fetch data for User") {
          this.userNotFound = true;
          this.errMessage = err.error.message;
        }
        else {
          this.userNotFound = false;
          this.errMessage = "Please enter valid email address";
        }
        // this.alertService.error(err.error.message, this.options);
      }
    })
  }

  deleteRecord(ele) {
    this.customMemberArr.forEach((element, index) => {
      if (element.user_email == ele.user_email) {
        this.customMemberArr.splice(index, 1)
      }
    });
    const uniqIds = {};
    this.filteredMemberArr = this.customMemberArr.filter(obj => !uniqIds[obj.user_id] && (uniqIds[obj.user_id] = true));
    this.dataSourceCustomUser.data = this.filteredMemberArr as customUserList[];
  }


  public getProjects() {
    this.userMailId = localStorage.getItem('mail').toLowerCase()

    this.apiService.getImanageProjects(this.userMailId).subscribe((projects: any) => {

      this.imanageprojectInfo = projects;
      if (projects.length == 0) {

      }
      else {
        projects.forEach(project => {
          let projectBody = {
            "projectId": project.info.ProjectId,
            "projectName": project.info.ProjName,
            "projectManager": project.info.PmName,
            "kdmName": project.info.KdmName,
            "startDate": project.info.StartDate,
            "endDate": project.info.ActuEndDate,
            "projectType": project.info.ProjType,
            "customerName": project.info.CustName,
            "deliveryBU": project.info.DeliveryBu,
            "isNew": project.isNew
          }
          this.iManageProjArray.push(projectBody)
        });
        this.projectData = projects
        this.dataSource.data = this.iManageProjArray as projectList[]
      }
    }
      , (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.noImanageProjects = true;
        }
        else if (error.status == 403) { }
      })
  }

  public getMember(id) {
    this.apiService.getTeamMembers(id).subscribe((members: any) => {
      this.userInfo = members;
      members.forEach(member => {
        let userObject = {
          "user_id": member.EmployeeId,
          "user_name": member.EmployeeName,
          'user_email': member.EmpEmailId.toLowerCase(),
          'role_name': [member.RoleType],
          'project_start_date': member.AllocStartDate,
          'project_end_date': member.AllocEndDate,
          'location': member.WbsName
        }
        this.iManageUserArray.push(userObject)
      })
      this.dataSourceUser.data = this.iManageUserArray as userList[]
    }
      , (error: HttpErrorResponse) => {
        if (error.status == 434) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No members available.", this.options);
          throw error;
        }

      })

  }


  exit() {
    this.dialogRef.close();
  }

  naviagte() {
    this.showNext = true
    this.showFinish = true


    if (this.selection.selected.length === 1 && this.selection.selected[0].isNew === "NEW") {
      this.showNext = true
      this.showError = false
    } else {
      this.showError = true
      this.showNext = false
      this.showFinish = false
    }
    const pid = this.selection.selected[0].projectId
    this.imanageprojectInfo.forEach(imanageInfo => {
      if (imanageInfo.info.ProjectId == pid) {
        this.projectInfo = imanageInfo.info
      }
    })
    this.getMember(pid)
  }
  setIndex(event) {
    this.selectedIndex = event.selectedIndex;
  }

  triggerClick() {
    if (this.selectedIndex === 1) {
      if (this.showNext) {
        this.showFinish = true
      }
    }
    else {
      this.showFinish = false
    }
  }

  createImanageProject() {
    const dataMap = {
      catalog_id: this.catalogSearchedData.catalog_id,
      catalog_name: this.catalogSearchedData.catalog_name,
      project_id: this.selection.selected[0].projectId,
      project_name: this.selection.selected[0].projectName,
      members: this.iManageUserArray,
      imanage_project_info: this.projectInfo,
      imanage_team_info: this.userInfo
    }

    this.apiService.saveToCatalog(dataMap).subscribe((data: any) => {
      this.showProjIdError = false;
      this.exit()
      this.alertService.success('Project Registered Successfully !', this.options);
      this.apiService.getCatalogProjectById(dataMap.project_id).subscribe((data: any[]) => {
        // debugger



      },
        (error: HttpErrorResponse) => {
          if (error.status == 440) {
            this.alertService.error(error.error.message, this.options);
          }
          else {
            this.alertService.error("Unable to start.", this.options);
            throw error;
          }

        })
      // this.getProjectId()

    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.projectCreateError = error.error.message
        this.showProjIdError = true;
      }
      else if (error.status == 403) { }

    })


  }

  showCatalogData() {
    this.showCatalog = true;
  }

  createCustomProject() {
    // Add project manager without TM
    this.apiService.psNumber.subscribe(result => {
      this.psNumber = result;
    })
    this.customMemberArr.push({
      "user_id": this.psNumber,
      "user_name": this.profile,
      'user_email': this.userMailId,
      "role_name": ["Project Manager"]
    })

    this.apiService.getTemplateRoles(this.selectedTemplateId).subscribe((data: any) => {

      if (this.filteredMemberArr.length > 0) {
        this.filteredMemberArr.forEach(member => {
          member.role_name.forEach(element => {
            data.forEach(template => {
              /*if(element === template.role_name) {
                console.log(template.role_name);
                member.template_role_name = [template.template_role_name]
              }*/
              if (element === template.role_name && element == "Project Manager") {

                member.template_role_name = [template.template_role_name]
                member.privilege_access = [template.privilege_access]
              }
              else if (element === template.role_name && element == "Team Member") {
                member.template_role_name = []
                member.privilege_access = [template.privilege_access]
              }
            });
          });


        });
      }
      else {
        this.customMemberArr.forEach(member => {
          member.role_name.forEach(element => {
            data.forEach(template => {
              if (element === template.role_name) {
                member.template_role_name = [template.template_role_name]
                member.privilege_access = [template.privilege_access]
              }
            });
          });
        })
      }

      const customObj = {
        catalog_id: this.catalogSearchedData.catalog_id,
        catalog_name: this.catalogSearchedData.catalog_name,
        project_id: this.customId.value,
        project_name: this.customName.value,
        template_id: this.selectedTemplateId,
        template_name: this.selectedTemplateName,
        members: this.filteredMemberArr.length > 0 ? this.filteredMemberArr : this.customMemberArr
      }

      this.apiService.saveToCatalog(customObj).subscribe((data: any) => {
        this.showProjIdError = false;
        if (data.length != 0) {

          // console.log(data,"Project created");
          this.alertService.success(data.message, this.options);


          this.exit()

        } else {
          // console.log(data,"Project created");

          this.exit()
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.projectCreateError = error.error.message
          this.showProjIdError = true;
        }
        else if (error.status == 403) { }

      })

    })




  }

  checkEndDateNotifier(projectEndDate) {
    var currentDate = new Date(this.currentDate)
    var endDate = new Date(projectEndDate)
    var timeDiff = Math.abs(endDate.getTime() - currentDate.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (currentDate.getTime() == endDate.getTime()) {
    } else if (diffDays < 30 || diffDays < 31) {
    }
  }
}


@Component({
  selector: 'info-project',
  templateUrl: 'info.html',
  styleUrls: ['./project-list-page.component.scss'],
})

export class ModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'show-catalog-info-dialog',
  templateUrl: 'catalog-info.html',
  styleUrls: ['./project-list-page.component.scss']
})
export class ShowCatalogInfoDialog {
  catalogEntireData: any;
  showImages: boolean = true;
  src: string = "";
  toolDetails: [];
  toolDetailsScm: [];
  toolDetailsScmLength;
  toolDetailsJira: [];
  toolDetailsJiraLength;
  toolDetailsVdi: [];
  toolDetailsVdiLength;
  azure_tools: [];
  network_config_tools: [];
  emptyFlag: boolean = false;
  deploymentStore_tools: [];
  jenkins_tools: [];
  aws_tools = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  modalTypeInProjectList: string;
  roleData: any;
  enabledIcons = [];
  workbenchIcons: any[];
  aws_config_tools: any;
  aws_ec2_image_tools: any;
  aws_network_config_tools: any;

  constructor(
    public dialogRef: MatDialogRef<ShowCatalogInfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any, public apiService: AuthService) {

    this.catalogEntireData = data.data;
    this.apiService.modalTypeInProjectList.subscribe(data => {
      this.modalTypeInProjectList = data
    })

    this.apiService.selectedTemplateId.subscribe(data => {

      this.apiService.templateInfo(data).subscribe(result => {
        this.roleData = result[0].template_roles;
        let iconData = [];
        let workbenchIconData = [];
        // if(result[0].template_icons.workbench[0].project_role.view == true){
        //   workbenchIconData.push("Project Role")
        // }
        // if(result[0].template_icons.workbench[0].assign_vdi.view == true){
        //   workbenchIconData.push("VDI")
        // }
        // if(result[0].template_icons.workbench[0].scm_tool.view == true){
        //   workbenchIconData.push("SCM")
        // }
        // if(result[0].template_icons.workbench[0].story_board.view == true){
        //   workbenchIconData.push("Story Board")
        // }
        // if(result[0].template_icons.workbench[0].devops.view == true){
        //   workbenchIconData.push("Devops")
        // }
        // if(result[0].template_icons.workbench[0].development_frameworks.view == true){
        //   workbenchIconData.push("Development Framework")
        // }
        // if(result[0].template_icons.workbench[0].lti_store.view == true){
        //   workbenchIconData.push("LTI Store")
        // }
        // if(result[0].template_icons.workbench[0].deployment_store.view == true){
        //   workbenchIconData.push("Deployment Store")
        // }
        // if(result[0].template_icons.workbench[0].collaboration.view == true){
        //   workbenchIconData.push("Collaboration")
        // }


        if (result[0].template_icons.workbench[0].project_role.view == true && result[0].template_icons.workbench[0].project_role.access == true) {
          workbenchIconData.push("Project Role")
        }
        if (result[0].template_icons.workbench[0].assign_vdi.view == true && result[0].template_icons.workbench[0].assign_vdi.access == true) {
          workbenchIconData.push("VDI")
        }
        if (result[0].template_icons.workbench[0].scm_tool.view == true && result[0].template_icons.workbench[0].scm_tool.access == true) {
          workbenchIconData.push("SCM")
        }
        if (result[0].template_icons.workbench[0].story_board.view == true && result[0].template_icons.workbench[0].story_board.access == true) {
          workbenchIconData.push("Story Board")
        }
        if (result[0].template_icons.workbench[0].devops.view == true && result[0].template_icons.workbench[0].devops.access == true) {
          workbenchIconData.push("Devops")
        }
        if (result[0].template_icons.workbench[0].development_frameworks.view == true && result[0].template_icons.workbench[0].development_frameworks.access == true) {
          workbenchIconData.push("Development Framework")
        }
        if (result[0].template_icons.workbench[0].lti_store.view == true && result[0].template_icons.workbench[0].lti_store.access == true) {
          workbenchIconData.push("LTI Store")
        }
        if (result[0].template_icons.workbench[0].deployment_store.view == true && result[0].template_icons.workbench[0].deployment_store.access == true) {
          workbenchIconData.push("Deployment Store")
        }
        if (result[0].template_icons.workbench[0].collaboration.view == true && result[0].template_icons.workbench[0].collaboration.access == true) {
          workbenchIconData.push("Collaboration")
        }
        this.enabledIcons = iconData
        this.workbenchIcons = workbenchIconData

      })
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.toolDetailsScm = []
    this.toolDetailsJira = []
    this.toolDetailsVdi = []
    this.azure_tools = [];
    this.network_config_tools = [];
    this.deploymentStore_tools = [];
    this.jenkins_tools = [];
    this.aws_tools = [];
    if (this.catalogEntireData) {
      // console.log(this.catalogEntireData);

      if ((this.catalogEntireData.scm_tools.length != 0) || (this.catalogEntireData.vdi_tools.length != 0) ||
        (this.catalogEntireData.storyboard_tools.length != 0) || (this.catalogEntireData.azure_tools.length != 0) ||
        (this.catalogEntireData.azure_tools.length != 0) || (this.catalogEntireData.aws_config_tools.length != 0) ||
        (this.catalogEntireData.network_config_tools.length != 0) || (this.catalogEntireData.aws_workspace_tools.length != 0) ||
        (this.catalogEntireData.deploymentstore_tools.length != 0) || (this.catalogEntireData.cicd_tools.length != 0) ||
        (this.catalogEntireData.aws_config_tools.length != 0) || (this.catalogEntireData.aws_network_config_tools.length != 0)) {

        this.toolDetails = this.catalogEntireData;
        this.toolDetailsScm = this.catalogEntireData.scm_tools;
        this.toolDetailsJira = this.catalogEntireData.storyboard_tools;
        this.toolDetailsVdi = this.catalogEntireData.vdi_tools;
        this.azure_tools = this.catalogEntireData.azure_tools;
        this.network_config_tools = this.catalogEntireData.network_config_tools;
        this.deploymentStore_tools = this.catalogEntireData.deploymentstore_tools;
        this.jenkins_tools = this.catalogEntireData.cicd_tools;
        this.aws_tools = this.catalogEntireData.aws_workspace_tools;
        this.aws_config_tools = this.catalogEntireData.aws_config_tools;
        this.aws_ec2_image_tools = this.catalogEntireData.aws_ec2_image_tools;
        this.aws_network_config_tools = this.catalogEntireData.aws_network_config_tools;
        // this.aws_tools = this.catalogEntireData.aws_workspace_tools;

      }
      else {
        this.emptyFlag = true;
      }
    }
    this.dtTrigger.next();


  }



}


//delete memebr from Project if not part of any tool
export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_assigned: Array<string>;
}
const ELEMENT_DATA1: MemberDetails[] = [];
@Component({
  selector: 'add-member-vdi-dialog',
  templateUrl: 'delete-member-from-project.html',
  styleUrls: ['./project-list-page.component.scss']

})

export class deleteMemberFromProject {
  projectId: any;
  projectName: any;
  members: any = [];
  ELEMENT_DATA1: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA1);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  memberList: any = [];
  newMembersAdd: any = [];
  finalArray: any = [];

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dummyMemberArray: any = [];
  tempBody: any;
  rowValue: any;
  catalog_id: any;
  catalog_name: any;
  memberString: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  type: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, public router: Router, public dialogRef: MatDialogRef<deleteMemberFromProject>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.memberList = data.values.members
    this.rowValue = data.values;
    this.type = data.screen
  }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');
    this.getUser();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  allSelected() {
    this.isallSelected = true
  }

  public getUser() {
    this.apiService.getMemberStatusForProject(this.projectId).subscribe(data => {
      this.members = data;
      // debugger;


      this.members.forEach((object: any) => {
        if (this.members.length != 0) {
          this.finalArray = this.members;
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }
      })

      let members = []
      if (this.type === "delete") {

        this.finalArray.forEach(user => {
          let object = {
            "user_name": user.user_name,
            "user_email": user.user_email,
            "role_assigned": user.role_assigned
          }
          members.push(object)
        })
        this.finalArray = members
      }

      this.dataSource.data = this.finalArray as MemberDetails[]
    })
  }

  deleteUserFromProject() {
    if (this.selection.selected.length == 0) {
      this._snackBar.open("Select User", "", {
        duration: 3000,
      })
    }
    else {
      let requestorMail = localStorage.getItem("mail").toLowerCase();


      this.selection.selected.forEach(user => {
        let memberDetails =
        {
          "user_email": user.user_email,
          "user_name": user.user_name,
          "role_assigned": user.role_assigned
        };

        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
        debugger;
        let reqBody = {
          project_id: this.projectId,
          project_name: this.projectName,
          requestor_id: requestorMail,
          user_details: memberDetails,
          status: false
        }

        this.apiService.deleteMemberFromProject(reqBody).subscribe((data: any) => {

          this.dialogRef.close();
          this.alertService.success(data.message, this.options);
        }, (error: HttpErrorResponse) => {
          if (error.status == 433) {
            // console.log(error);


            this.alertService.error(error.error.message, this.options);

          }
          else if (error.status == 454) {
            this.alertService.error(error.error.status, this.options);
          }
          else if (error.status == 403) { }
        })
      })


      this.dialogRef.close()
    }
  }


  exit() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}


