<div class="pop-up">
    <div class="">
      <span  class="vertical-line"> </span>
       <span class="header mb-0">Jira Detail View</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
    
      <hr class="margin-class" />
      <div class="popup-container">
        <div class="table-responsive mt-2">
        <table datatable class="table fix-table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Email Id</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of projectMembers">
              <td >
                 {{data.user_name || "-"}}
                </td>
              <td>  {{data.email_id || "-"}}  </td>
              <td>{{data.status || "-"}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>