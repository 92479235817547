export const environment = { 
   // ltim-canvas-workplace-api-live.azurewebsites.net

  // ltim-canvas-workplace-ui-live.azurewebsites.net
  //apiUrl:"https://canvaslive.ltimindtree.com",
  apiUrl:"https://ltim-canvas-workplace-api-live.azurewebsites.net",
  // uiUrl:"https://ltim-canvas-workplace-ui-live.azurewebsites.net",
  uiUrl:"https://canvaslive.ltimindtree.com",

  clientId:"0d37c6a5-1695-4c90-92c4-309f19387e7d",
  scmEnv: "PROD",
  logoPath:"/assets/img/logo/Canvas-Workplace_without-BG.png",
  footerLogoPath:"/assets/img/logo/LTF.PNG",
  webex_clientId:"Cb272fcf8000873d8f991ba8e24970ed7b91481650e819ec0cae34e8d59413eb7",
 // webex_client_secret:"9a1a4af3cb31e642d92b691793fd6ee6c2d899ed61ea85d1dd85d7a7567f1a80",
  webex_redirect_uri:"https://lti-canvas-engineering-ui-live.azurewebsites.net/",
  TimeZone : "Asia/Kolkata",
  gig:false,
  resillenceUrl: "https://canvasinternal.lntinfotech.com/apps/resilience/dev/CanvasResilience/#/login?token=",

  production: false,
  msTeamsScreen:true,
  costUsage:true,

  GRAPH_ENDPOINT : 'https://graph.microsoft.com/v1.0',
  webex_url :"https://webexapis.com/v1/access_token"
};
