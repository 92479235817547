<div class="pop-up">
    <div class="">
        <span class="vertical-line mx-2 mb-1"> </span>
        <span class="header mb-0">{{popupType}} {{screen}} Request Confirmation</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>

    <hr class="margin-class" />
    <div class="popup-container justify-content-center">
        <h3>Do you want to {{popupType | lowercase}} {{req.request_id }} ?
        </h3>
    </div>

    <div class="row mt-4">
            <div class="offset-md-6 col-md-3 mt-3">
                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="restoreDelete()">Yes</button>
            </div>
            <div class="col-md-3 mt-3">
                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="exit()">No</button>
            </div>
    </div>
</div>