<div class="member-card pop-up">
  <div class="row">
    <div class="col-sm-5 pt-3">
      <span  class="vertical-line mx-3"> </span>
     <span class="header mb-0">Select Image</span> 
    </div>
    <div class="col-sm-5">
      <div fxLayout fxLayoutAlign="center center">
        <mat-form-field fxFlex="60%">
          <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm-2 pt-3">
      <span class="material-icons close-icon" mat-dialog-close>
        close
      </span>
    </div>
   
  </div>
  <hr class="margin-class" />

  <div class="add-tool-table vdi-table">
    <div *ngIf="isDisplayTool">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Tool Name Column -->
        <ng-container matColumnDef="toolName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Tool Type </th>
          <td mat-cell  *matCellDef="let element"
             class="width"> {{element.toolName}} </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header style="width: 20%;"> ID </th>
          <td mat-cell *matCellDef="let element" class="width"> {{element.ID}} </td>
        </ng-container> 

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 25%;"> Connection Name </th>
          <td mat-cell  *matCellDef="let element"
            class="width"> {{element.name}} </td>
        </ng-container>

        <!-- projectURL Column -->
        <ng-container matColumnDef="projectURL">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header style="width: 27%;"> Instance URL </th>
          <td mat-cell *matCellDef="let element" class="width"> {{element.projectURL}} </td>
        </ng-container>

        <!-- toolGroup Column -->
        <ng-container matColumnDef="toolGroup">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Tool Group </th>
          <td mat-cell *matCellDef="let element" class="width"> {{element.toolGroup}} </td>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="select">
            <mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" class="select">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row);"
          [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true"
          (mouseout)="row.hovered = false">
        </tr>
      </table>
  </div>

  <div *ngIf="isDisplayToolVDI" class="vdi-table">
    <table mat-table [dataSource]="dataSource1" matSort>

      <!-- Tool Name Column -->
      <ng-container matColumnDef="toolID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;" mat-sort-header> VDI Tool ID </th>
        <td mat-cell  *matCellDef="let element"
           class="width"> {{element.toolID}} </td>
      </ng-container>

      <!-- ID Column -->
      <ng-container matColumnDef="azureTool">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;" mat-sort-header> Azure Tool ID </th>
        <td mat-cell *matCellDef="let element" class="width"> {{element.azure_tool_instance_id}} </td>
      </ng-container> 

      <!-- Name Column -->
      <ng-container matColumnDef="vdiType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;" mat-sort-header> VDI Type </th>
        <td mat-cell  *matCellDef="let element"
          class="width"> {{element.vdi_type}} </td>
      </ng-container>

      <!-- projectURL Column -->
      <ng-container matColumnDef="imageType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 32%;" mat-sort-header> Image Name </th>
        <td mat-cell *matCellDef="let element" class="width"> {{element.image_name}} </td>
      </ng-container>

      <!-- toolGroup Column -->
      <ng-container matColumnDef="toolGroup">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;"  mat-sort-header> Tool Group Name </th>
        <td mat-cell *matCellDef="let element" class="width"> {{element.tool_group_name}} </td>
      </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select">
          <mat-checkbox (change)="$event ? masterToggleVDI() : null"
            [checked]="selectionVDI.hasValue() && isAllSelectedVDI()"
            [indeterminate]="selectionVDI.hasValue() && !isAllSelectedVDI()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionVDI.toggle(row) : null"
            [checked]="selectionVDI.isSelected(row)" [aria-label]="checkboxLabelVDI(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumnsVDI"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsVDI;" (click)="selectionVDI.toggle(row);"
        [ngClass]="{hovered: row.hovered, highlighted: selectionVDI.isSelected(row)}" (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false">
      </tr>
    </table>
</div>

<div *ngIf="isDisplayToolVM">
  <table mat-table [dataSource]="dataSource2" matSort>

    <!-- Tool Name Column -->
    <ng-container matColumnDef="toolID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 30%;"> VM Tool ID </th>
      <td mat-cell  *matCellDef="let element"
         class="width"> {{element.toolID}} </td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="azureTool">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 24%;"> Azure Tool ID </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.azure_tool_instance_id}} </td>
    </ng-container> 

    <!-- Name Column
    <ng-container matColumnDef="vmos">
      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> VM Type </th>
      <td mat-cell  *matCellDef="let element"
        class="width"> {{element.vmos}} </td>
    </ng-container>-->

    <!-- projectURL Column -->
    <ng-container matColumnDef="imageType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 40%;"> Image Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.image_name}} </td>
    </ng-container>

    <!-- toolGroup Column -->
    <ng-container matColumnDef="toolGroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Tool Group Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.tool_group_name}} </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="select text-right" style="width: 10%;">
        <mat-checkbox (change)="$event ? masterToggleVM() : null"
          [checked]="selectionVM.hasValue() && isAllSelectedVM()"
          [indeterminate]="selectionVM.hasValue() && !isAllSelectedVM()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="select text-right">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionVM.toggle(row) : null"
          [checked]="selectionVM.isSelected(row)" [aria-label]="checkboxLabelVM(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumnsVM"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsVM;" (click)="selectionVM.toggle(row);"
      [ngClass]="{hovered: row.hovered, highlighted: selectionVM.isSelected(row)}" (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false">
    </tr>
  </table>
</div>

<div *ngIf="isDisplayToolNetwork">
  <table mat-table [dataSource]="dataSource3" matSort>

    <!-- Tool Name Column -->
    <ng-container matColumnDef="toolID">
      <th mat-header-cell *matHeaderCellDef style="width: 22%;"> Tool ID </th>
      <td mat-cell  *matCellDef="let element"
         class="width"> {{element.toolID}} </td>
    </ng-container>

    <!-- ID Column 
    <ng-container matColumnDef="networkTool">
      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Network Tool ID </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.networkTool}} </td>
    </ng-container> -->

    <!-- Name Column
    <ng-container matColumnDef="vmos">
      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> VM Type </th>
      <td mat-cell  *matCellDef="let element"
        class="width"> {{element.vmos}} </td>
    </ng-container>-->

    <!-- projectURL Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 17%;">  Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="Vnet">
      <th mat-header-cell *matHeaderCellDef style="width: 22%;">  Vnet </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.Vnet}} </td>
    </ng-container>

    <ng-container matColumnDef="Subnet">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 22%;">  Subnet </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.Subnet}} </td>
    </ng-container>

    <ng-container matColumnDef="ResourceGroup">
      <th mat-header-cell *matHeaderCellDef style="width: 22%;">  Resource Group </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.ResourceGroup}} </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef style="width: 22%;">  Description </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.description}} </td>
    </ng-container>

    <!-- toolGroup Column -->
    <ng-container matColumnDef="toolGroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 15%;"> Tool Group Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.tool_group_name}} </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="select text-right" style="width: 10%;">
        <mat-checkbox (change)="$event ? masterToggleNetwork() : null"
          [checked]="selectionNetwork.hasValue() && isAllSelectedNetwork()"
          [indeterminate]="selectionNetwork.hasValue() && !isAllSelectedNetwork()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="select text-right">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionNetwork.toggle(row) : null"
          [checked]="selectionNetwork.isSelected(row)" [aria-label]="checkboxLabelNetwork(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumnsNetwork"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsNetwork;" (click)="selectionNetwork.toggle(row);"
      [ngClass]="{hovered: row.hovered, highlighted: selectionNetwork.isSelected(row)}" (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false">
    </tr>
  </table>
</div>

<div *ngIf="isDisplayToolAWS">
  <table mat-table [dataSource]="dataSource4" matSort>

    <!-- Tool Name Column -->
    <ng-container matColumnDef="toolID">
      <th mat-header-cell *matHeaderCellDef style="width: 30%;"> Tool ID </th>
      <td mat-cell  *matCellDef="let element"
         class="width"> {{element.toolID}} </td>
    </ng-container>

    <!-- ID Column 
    <ng-container matColumnDef="networkTool">
      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> Network Tool ID </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.networkTool}} </td>
    </ng-container> -->

    <!-- Name Column
    <ng-container matColumnDef="vmos">
      <th mat-header-cell *matHeaderCellDef style="width: 25%;"> VM Type </th>
      <td mat-cell  *matCellDef="let element"
        class="width"> {{element.vmos}} </td>
    </ng-container>-->

    <!-- projectURL Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;">  Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="Bundles">
      <th mat-header-cell *matHeaderCellDef style="width: 24%;">  Bundles </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.Bundles}} </td>
    </ng-container>

    <ng-container matColumnDef="Directories">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 24%;">  Directories </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.Directories}} </td>
    </ng-container>

   
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef style="width: 30%;">  Description </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.description}} </td>
    </ng-container>

    <!-- toolGroup Column -->
    <ng-container matColumnDef="toolGroup">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 20%;"> Tool Group Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.tool_group_name}} </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="select text-right" style="width: 10%;">
        <mat-checkbox (change)="$event ? masterToggleAWS() : null"
          [checked]="selectionAWS.hasValue() && isAllSelectedAWS()"
          [indeterminate]="selectionAWS.hasValue() && !isAllSelectedAWS()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="select text-right">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionAWS.toggle(row) : null"
          [checked]="selectionAWS.isSelected(row)" [aria-label]="checkboxLabelAWS(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumnsAWS"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsAWS;" (click)="selectionAWS.toggle(row);"
      [ngClass]="{hovered: row.hovered, highlighted: selectionAWS.isSelected(row)}" (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false">
    </tr>
  </table>
</div>

<div *ngIf="isDisplayProjectT">
  <table mat-table [dataSource]="dataSource5" matSort>

    <!-- Tool Name Column -->
    <ng-container matColumnDef="projectID">
      <th mat-header-cell *matHeaderCellDef style="width: 35%;"> Template ID </th>
      <td mat-cell  *matCellDef="let element"
         class="width"> {{element.template_id}} </td>
    </ng-container>

   
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 35%;">  Name </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.template_name}} </td>
    </ng-container>

 
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef style="width: 60%;">  Description </th>
      <td mat-cell *matCellDef="let element" class="width"> {{element.template_description}} </td>
    </ng-container>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef class="select text-right" style="width: 10%;">
        <mat-checkbox (change)="$event ? masterToggleProjectT() : null"
          [checked]="selectionProjectT.hasValue() && isAllSelectedProjectT()"
          [indeterminate]="selectionProjectT.hasValue() && !isAllSelectedProjectT()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="select text-right">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selectionProjectT.toggle(row) : null"
          [checked]="selectionProjectT.isSelected(row)" [aria-label]="checkboxLabelProjectT(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumnsProjectT"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsProjectT;" (click)="selectionProjectT.toggle(row);"
      [ngClass]="{hovered: row.hovered, highlighted: selectionProjectT.isSelected(row)}" (mouseover)="row.hovered = true"
      (mouseout)="row.hovered = false">
    </tr>
  </table>
</div>

  </div>

  <div class="row">
    <div class="col-sm-8">
      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
      </mat-paginator>
    </div>
    <div class="col-sm-4">
      <div class="float-right pt-3">
        <button type="button" class="mr-2 btn-canvas-blue" (click)="AddToolsDetials()">+ Add Template</button>
        <button type="button" class="btn-canvas-blue" mat-dialog-close>Close</button>
    </div>
    </div>
  </div>
  
</div>