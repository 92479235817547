<div class="admin-home-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="canvas-card py-5">
    <div>     
      <div>
        <div class="text-left table-crud-icons">
          <img class="img-fluid mr-2" src="assets/icon/menu/catelog-icon.png" />
          <!-- <span class="material-icons menu-icon border-0">content_copy</span>    -->
          <span style="font-size: 20px;" class="add-text font-bold">Catalog</span>          
        </div>


        <div class="text-right table-crud-icons">
          <!--   <span class="material-icons">save</span>     
                    <span class="material-icons">edit</span> -->

          <span class="material-icons">delete</span>
          <span routerLink="/admin-catalog-config" style="cursor:pointer">
            <span class="material-icons last">add</span> <span (click)="setFormMode()" class="add-text font-bold">Add</span>
          </span>
        </div>

        <div class="my-5 table-container">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table fixed-table">
            <thead>
              <tr>
              <th style="width: 15px;"><input type="checkbox"></th>
              <th>Name</th>
              <th>ID</th>
              <th>Description</th>              
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let catelog of CatlogArray">
                <td style="width: 15px;"><input type="checkbox"></td>
                <td><span class="link-col" (click)="getRecord(catelog)">{{catelog.Name}}</span></td>
                <td>{{catelog.ID}}</td>
                <td>{{catelog.Description}}</td>                
              </tr> 
              <tr *ngFor="let catelog of CatlogArray">
                <td style="width: 15px;"><input type="checkbox"></td>
                <td><span class="link-col" (click)="getRecord(catelog)">{{catelog.Name}}</span></td>
                <td>{{catelog.ID}}</td>
                <td>{{catelog.Description}}</td>                
              </tr>              
            </tbody>
          </table>
        </div>

      </div>
    </div>

  </div>
</div>