import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, ifsrPopup } from './app.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'
import { AuthService } from './service/api.service';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './token-interceptor.service';
import { MainNavComponent,logoutModal } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MaterialModule } from './material.module';
import { RecaptchaModule} from 'ng-recaptcha';
import { NgxSpinnerModule } from "ngx-spinner";
import { MessageService } from './service/caller.service';
import {MatTabsModule} from '@angular/material/tabs';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { VdiDetailsComponent, DialogOverviewExampleDialog, CancelConfirmationPopUpDialog,WorkbenchConfirmationPopUpDialog, 
  HomeConfirmationPopUpDialog,DialogProjectInfoDialog,DialogConfirmationPopup,DialogVminfoDialog,startStopConfirmationPopup,vdiRefreshInformationPopUpDialog, addMemberVdiDialog, ModalComponent, softwareDialog, softwareUninstallDialog } from './project-configurations/vdi-details/vdi-details.component';
import { MsalModule, MsalInterceptor, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ProjectListPageComponent , AddProjectDialog,ShowCatalogInfoDialog, deleteMemberFromProject} from './project-list-page/project-list-page.component';
import { ProfileComponent } from './profile/profile.component';
import { ProjectSummaryComponent } from './project-details/project-summary/project-summary.component';
import { TeamMembersComponent, AddMemberDialog, approvedTeamMemberDialog, deleteTeamMemberDialog } from './project-configurations/team-members/team-members.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ProjectRolesComponent , MembersRoleDialog, roleMemberDialog, updatePMDialog, rolesCancelConfirmationPopUpDialog,rolesHomeConfirmationPopUpDialog, rolesWorkbenchConfirmationPopUpDialog} from './project-configurations/project-roles/project-roles.component';
import { ProjectConfigurationsModule } from './project-configurations/project-configurations.module';
import { SCMMembers, MembersListDialog, scmCancelConfirmationPopUpDialog,scmdeleteConfirmationPopUpDialog,scmHomeConfirmationPopUpDialog,scmWorkbenchConfirmationPopUpDialog, addMemberRepoDialog } from './project-configurations/scm-tool/scm-tool.component';
import { AdminModule } from './admin/admin.module';
import { AdminMainNavComponent } from './admin-main-nav/admin-main-nav.component';
import { WorkBenchComponent ,   ToolDialogComponent, CastDialogComponent } from './project-configurations/work-bench/work-bench.component';
import { AlertModule } from './_alert';
import { DataTablesModule } from 'angular-datatables';
import { AzureMembers } from './project-configurations/azzure-devops/azzure-devops.component'
import { environment } from 'src/environments/environment';
  import { from } from 'rxjs';
  import { aksVmModal,DialogVMSizeInfo, VMMembers, VMInfoModal, softwareDialogVM, softwareDialogUninstallVM  } from './project-configurations/deployment-store/deployment-store.component';
  import { TemplateModal } from './project-configurations/dev-test-framework/dev-test-framework.component';
  import { StoryBoardComponent, jiraCancelConfirmationPopUpDialog,addMemberRepoJiraDialog,
     jiraWorkbenchConfirmationPopUpDialog, jiraHomeConfirmationPopUpDialog } from './project-configurations/story-board/story-board.component';
import { MembersSpaceDialog, MschannelCreationsComponent, ViewMembersSpace,DeleteMembersSpace, AddDeleteToolsDialog, RecurringMeeting,DialogwebexMeetingInfoDialog } from './project-configurations/mschannel-creations/mschannel-creations.component';
import { DatePipe } from '@angular/common'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { MembersSpaceDialog, MschannelCreationsComponent, ViewMembersSpace } from './project-configurations/mschannel-creations/mschannel-creations.component';
import { ReportsComponent,alertInfoDialog,alertDescription, jenkinsViewDialog } from './project-configurations/reports/reports.component';
import { ChartsModule } from 'ng2-charts';
import { JenkinsMembers } from './project-configurations/ci-cd/ci-cd.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {ProductDetailsComponent} from './project-details/product-details/product-details.component';
import {  MembersJiraCostInfoDialog, ReportCostComponent, Report_MembersListDialog, } from './project-configurations/report-cost/report-cost.component';
import { RestoreDeleteConfirmPopup } from './project-configurations/recycle-bin/recycle-bin.component';
import { UnsavedChangesGuard } from './unsaved-changes.guard';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { addMemberAWSDialog, DialogEc2DiskTypeInfo, DialogEc2TypeInfo, DialogNetworkInfo, DialogPurposeInfo } from './project-configurations/aws-vdi/aws-vdi.component';
import { ImageInfoPopWS } from './project-configurations/aws-workspace/aws-workspace.component';
import { VideoFAQ } from './project-configurations/faq/faq.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [
    AppComponent,
    AdminMainNavComponent,ReportsComponent,alertDescription,
    MainNavComponent,PageNotFoundComponent,
    ProjectListPageComponent,
    ProfileComponent,
    ProjectSummaryComponent,
    VdiDetailsComponent,
    ModalComponent,
    AddProjectDialog,rolesCancelConfirmationPopUpDialog,rolesWorkbenchConfirmationPopUpDialog,rolesHomeConfirmationPopUpDialog,
    SCMMembers,VMMembers, TemplateModal, scmCancelConfirmationPopUpDialog,scmHomeConfirmationPopUpDialog,scmWorkbenchConfirmationPopUpDialog,
     ToolDialogComponent, CastDialogComponent  ,AzureMembers,DialogOverviewExampleDialog,VMInfoModal,CancelConfirmationPopUpDialog,jiraWorkbenchConfirmationPopUpDialog,
     WorkbenchConfirmationPopUpDialog, DialogProjectInfoDialog,HomeConfirmationPopUpDialog,StoryBoardComponent,jiraCancelConfirmationPopUpDialog,jiraHomeConfirmationPopUpDialog,
     MembersListDialog,ShowCatalogInfoDialog,deleteMemberFromProject,AddMemberDialog,MembersRoleDialog,MembersSpaceDialog,AddDeleteToolsDialog,RecurringMeeting,
     ViewMembersSpace,DeleteMembersSpace,roleMemberDialog,softwareDialog,softwareUninstallDialog,softwareDialogVM,softwareDialogUninstallVM,ifsrPopup,updatePMDialog,aksVmModal,DialogVMSizeInfo,DialogConfirmationPopup,vdiRefreshInformationPopUpDialog,DialogVminfoDialog,startStopConfirmationPopup,MschannelCreationsComponent,addMemberRepoDialog,alertInfoDialog
     ,ShowCatalogInfoDialog,AddMemberDialog,MembersRoleDialog,MembersSpaceDialog,ViewMembersSpace,DeleteMembersSpace,roleMemberDialog,aksVmModal,DialogVMSizeInfo,DialogConfirmationPopup,DialogVminfoDialog,startStopConfirmationPopup,MschannelCreationsComponent,addMemberRepoDialog,addMemberRepoJiraDialog,JenkinsMembers,MembersJiraCostInfoDialog
    ,ProductDetailsComponent,logoutModal,DialogwebexMeetingInfoDialog,addMemberVdiDialog,addMemberAWSDialog,DialogPurposeInfo,DialogEc2TypeInfo,ImageInfoPopWS,DialogEc2DiskTypeInfo,DialogNetworkInfo,deleteTeamMemberDialog,approvedTeamMemberDialog,VideoFAQ,Report_MembersListDialog,ReportCostComponent, jenkinsViewDialog, RestoreDeleteConfirmPopup
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    ProjectConfigurationsModule, ChartsModule,
    AdminModule,
    MatNativeDateModule,
    MatDatepickerModule,
    HttpClientModule,
    LayoutModule,
    MaterialModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatSlideToggleModule,
    RecaptchaModule,
    NgxSpinnerModule,
    MatTabsModule,
    FlexLayoutModule,
    AppRoutingModule,   
    DataTablesModule,
    AlertModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    NgMultiSelectDropDownModule.forRoot(),
    // MsalModule.forRoot({
    //   auth: {
    //     clientId: environment.clientId, 
    //     authority: 'https://login.microsoftonline.com/02aa9fc1-18bc-4798-a020-e01c854dd434',
    //   redirectUri: environment.uiUrl,
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // set to true for IE 11
    //   },
    // },
    // {
    //   popUp: !isIE,
    //   consentScopes: [
    //     'user.read',
    //     'openid',
    //     'profile',
    //   ],
    //   unprotectedResources: [],
    //   protectedResourceMap: [
    //     [environment.GRAPH_ENDPOINT+'/me', ['user.read']]
    //    // ['https://graph.microsoft.com/v1.0/me', ['user.read']]
    //   ],
    //   extraQueryParameters: {}
    // }),
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
          clientId: environment.clientId,
          authority: 'https://login.microsoftonline.com/ff355289-721e-4dd7-a663-afec62ab9d54',
          redirectUri: environment.uiUrl,
          postLogoutRedirectUri: environment.uiUrl,
          navigateToLoginRequestUrl: true
      },
      cache: {
          cacheLocation : 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
      },
      system: {
          loggerOptions: {
              loggerCallback: () => {},
              piiLoggingEnabled: false
          }
      }
  }), {
      interactionType: InteractionType.Popup, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read','openid', 'profile',]
      },
      loginFailedRoute: "/login-failed" ,
  }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([ 
          [environment.GRAPH_ENDPOINT+'/me', ['user.read']]
      ])
  })
],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass:TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    AuthService, MessageService, AuthGuard,UnsavedChangesGuard,DatePipe,MsalService
  ],
  entryComponents:[
    AddProjectDialog,scmHomeConfirmationPopUpDialog,scmWorkbenchConfirmationPopUpDialog,
    SCMMembers,scmCancelConfirmationPopUpDialog,scmdeleteConfirmationPopUpDialog, scmdeleteConfirmationPopUpDialog,TemplateModal, ToolDialogComponent, CastDialogComponent,AzureMembers,DialogOverviewExampleDialog,VMInfoModal,
    CancelConfirmationPopUpDialog,WorkbenchConfirmationPopUpDialog,HomeConfirmationPopUpDialog,StoryBoardComponent,jiraCancelConfirmationPopUpDialog,jiraWorkbenchConfirmationPopUpDialog,
    jiraHomeConfirmationPopUpDialog,rolesCancelConfirmationPopUpDialog,rolesWorkbenchConfirmationPopUpDialog, rolesHomeConfirmationPopUpDialog,
     MembersListDialog,ShowCatalogInfoDialog, deleteMemberFromProject, AddMemberDialog,MembersRoleDialog,MembersSpaceDialog,AddDeleteToolsDialog,RecurringMeeting,ViewMembersSpace,DeleteMembersSpace,roleMemberDialog,updatePMDialog,aksVmModal, DialogProjectInfoDialog,DialogVMSizeInfo,DialogConfirmationPopup,startStopConfirmationPopup,alertInfoDialog
    ,DialogVminfoDialog,addMemberRepoDialog,logoutModal
     ,ShowCatalogInfoDialog,AddMemberDialog,MembersRoleDialog,MembersSpaceDialog,ViewMembersSpace,DeleteMembersSpace,roleMemberDialog,softwareDialog,softwareUninstallDialog,softwareDialogUninstallVM,softwareDialogVM,ifsrPopup,aksVmModal, DialogProjectInfoDialog,DialogVMSizeInfo,DialogConfirmationPopup,startStopConfirmationPopup,vdiRefreshInformationPopUpDialog
    ,DialogVminfoDialog,addMemberRepoDialog,addMemberRepoJiraDialog,VMMembers,JenkinsMembers,DialogwebexMeetingInfoDialog,addMemberVdiDialog,addMemberAWSDialog,DialogEc2DiskTypeInfo,DialogPurposeInfo,DialogEc2TypeInfo,ImageInfoPopWS,DialogNetworkInfo,deleteTeamMemberDialog,approvedTeamMemberDialog,VideoFAQ,Report_MembersListDialog,ReportCostComponent,MembersJiraCostInfoDialog, jenkinsViewDialog, RestoreDeleteConfirmPopup
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


}