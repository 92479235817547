import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-deployment-store',
  templateUrl: './admin-deployment-store.component.html',
  styleUrls: ['./admin-deployment-store.component.scss']
})
export class AdminDeploymentStoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
