import { Component, OnInit, Inject } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}

export interface vdiTool {
  global_id: string;
  admin_account_name: string;
  admin_account_password: string;
  stealthwatch_email: string;
  stealthwatch_password: string;
  stealthwatch_token: string;
  amp_access_token: string;
  imanage_token: string;
  azure_auth_token: string;
  jenkins_email:string;
  jenkins_auth_token:string;
  client_config_id:string;
 
}
@Component({
  selector: 'app-admin-client-propertire',
  templateUrl: './admin-client-propertire.component.html',
  styleUrls: ['./admin-client-propertire.component.scss']
})
export class AdminClientPropertireComponent implements OnInit,CanComponentLeave {

  showVdiForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];
  checkedList: any;

  ResourceGroupArr = [];
  VnetArr = [];
  SubnetArr = [];
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox = false;

  VdiConfig_details:any=[];
  check:any=[]



  project_ResourceGroup:string;
  project_Vnet:string;
  project_Subnet:string;

  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  AzuredrpDisable = false;

  ResourceDisable=false;
  VnetDisable=false;
  SubDisable=false;
  SCMArray = [];
  project_toolGrId: string;
  vdiObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;
  Role:string="";
  SubscriptionList: Subscription_iddropdown[] = [];
  itemSelected:boolean=false;
  userMailId: string;
  selectedClientConfigID: any;
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.global_id == '' || this.toolsGroup.global_id == undefined) &&
      (this.toolsGroup.admin_account_name == '' || this.toolsGroup.admin_account_name == undefined) &&
       (this.toolsGroup.admin_account_password == '' || this.toolsGroup.admin_account_password == undefined)  &&
       (this.toolsGroup.stealthwatch_email == '' || this.toolsGroup.stealthwatch_email == undefined)  &&
       (this.toolsGroup.stealthwatch_password == '' || this.toolsGroup.stealthwatch_password == undefined)  &&
       (this.toolsGroup.stealthwatch_token == '' || this.toolsGroup.stealthwatch_token == undefined)  &&
       (this.toolsGroup.amp_access_token == '' || this.toolsGroup.amp_access_token == undefined)  &&
        (this.toolsGroup.imanage_token == '' || this.toolsGroup.imanage_token == undefined)
         && (this.toolsGroup.azure_auth_token == '' || this.toolsGroup.azure_auth_token == undefined)){
        return true
      }
      if ((this.toolsGroup.global_id != '' || this.toolsGroup.global_id != undefined) &&
      (this.toolsGroup.admin_account_name != '' || this.toolsGroup.admin_account_name != undefined) &&
       (this.toolsGroup.admin_account_password != '' || this.toolsGroup.admin_account_password != undefined)  &&
       (this.toolsGroup.stealthwatch_email != '' || this.toolsGroup.stealthwatch_email != undefined)  &&
       (this.toolsGroup.stealthwatch_password != '' || this.toolsGroup.stealthwatch_password != undefined)  &&
       (this.toolsGroup.stealthwatch_token != '' || this.toolsGroup.stealthwatch_token != undefined)  &&
       (this.toolsGroup.amp_access_token != '' || this.toolsGroup.amp_access_token != undefined)  &&
        (this.toolsGroup.imanage_token != '' || this.toolsGroup.imanage_token != undefined)
         && (this.toolsGroup.azure_auth_token != '' || this.toolsGroup.azure_auth_token != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { 
      this.masterSelected = false;

    }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.getTypeT();
    this.dtOptions = {
      order: []
    };
    var PopupToolGroup = localStorage.getItem('Popup_Open_Client')
    if (PopupToolGroup == "true") {
      this.showVdiForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Client')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
     // console.log("this.toolsGroup.toolGr");
      //console.log(this.toolsGroup.toolGr);
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Client')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Client", "false");
    } else {
      this.showVdiForm = false;
    }
    this.getSubscriptionList();
    this.GetClienttableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  backNavigation(){
    if ((this.toolsGroup.global_id == '' || this.toolsGroup.global_id == undefined) &&
     (this.toolsGroup.admin_account_name == '' || this.toolsGroup.admin_account_name == undefined) &&
      (this.toolsGroup.admin_account_password == '' || this.toolsGroup.admin_account_password == undefined)  &&
      (this.toolsGroup.stealthwatch_email == '' || this.toolsGroup.stealthwatch_email == undefined)  &&
      (this.toolsGroup.stealthwatch_password == '' || this.toolsGroup.stealthwatch_password == undefined)  &&
      (this.toolsGroup.stealthwatch_token == '' || this.toolsGroup.stealthwatch_token == undefined)  &&
      (this.toolsGroup.amp_access_token == '' || this.toolsGroup.amp_access_token == undefined)  &&
       (this.toolsGroup.imanage_token == '' || this.toolsGroup.imanage_token == undefined)
        && (this.toolsGroup.azure_auth_token == '' || this.toolsGroup.azure_auth_token == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  getSubscriptionList(): void {
   // debugger
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      //this.apiService.Admin_AzureTableDetails().subscribe(data => {
      if (res) {
        // console.log(res, "data");
     //   debugger
        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name
          }
          this.SubscriptionList.push(obj)
        })
        // console.log(this.SubscriptionList, "SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  GetClienttableDetails() {

    this.spinner.show();
    this.apiService.Admin_ClientTableDetails().subscribe((res: any) => {
      res.forEach(member => {
        let obj = {
        
          "global_id":member.global_id,
    "admin_account_name":member.admin_account_name,
    "admin_account_password":member.admin_account_password,
    "stealthwatch_email": member.stealthwatch_email,
    "stealthwatch_password": member.stealthwatch_password,
    "stealthwatch_token": member.stealthwatch_token,
    "amp_access_token": member.amp_access_token,
    "imanage_token":member.imanage_token,
    "azure_auth_token": member.azure_auth_token,
    
    "jenkins_email": member.jenkins_email,
    "jenkins_auth_token": member.jenkins_auth_token,
    "client_config_id":member.client_config_id,

    



        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }



 
 
  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        // console.log("SCM");
        //console.log(data.body);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }


  setVdiEditing(vdiObj: vdiTool) {
   // debugger; 
    //console.log("scmObj", scmObj)
   
    this.toolsGroup.global_id = vdiObj.global_id;
  this.toolsGroup.admin_account_name=vdiObj.admin_account_name;
    this.toolsGroup.admin_account_password=vdiObj.admin_account_password;
    this.toolsGroup.stealthwatch_email = vdiObj.stealthwatch_email
  
    this.toolsGroup.stealthwatch_password = vdiObj.stealthwatch_password;
    this.toolsGroup.stealthwatch_token = vdiObj.stealthwatch_token;
    this.toolsGroup.amp_access_token = vdiObj.amp_access_token;
    this.toolsGroup.imanage_token = vdiObj.imanage_token;
   
    this.toolsGroup.azure_auth_token = vdiObj.azure_auth_token;
    this.toolsGroup.jenkins_email=vdiObj.jenkins_email;
    this.toolsGroup.jenkins_auth_token=vdiObj.jenkins_auth_token;
    
    this.project_id_AksEdit=vdiObj.client_config_id;

   
  }

  getRecord(row) {
    //
    this.userMailId = localStorage.getItem("mail")

    this.clearVdiDetails();
    this.vdiObj = row;
    try{
      localStorage.setItem('vdiToolObj', JSON.stringify(this.vdiObj));
      let getObj = JSON.parse(localStorage.getItem('vdiToolObj'));
      this.setVdiEditing(getObj);
      this.editMode = true;
      this.showVdiForm = true;
      this.UpdateConnDisable = true;
      this. ResourceDisable=true;
      this.VnetDisable=true;
      this.SubDisable=true;
      this.AzuredrpDisable = true;
      this.selectedClientConfigID = row.client_config_id;
    // console.log(this.userMailId);
      
    // console.log(row);
      this.VdiConfig_details=[];

      let deleteBodyclient={
        "resource_type": "client",

        "client_config_id": row.client_config_id,
    
        "global_id": row.global_id,
    
        "requester_email": this.userMailId

      }
      this.VdiConfig_details.push(deleteBodyclient);

    // console.log(deleteBodyclient);
    
    }
    catch{
      return throwError('Data not found');
    }
    
    
    
  }
  clearVdiDetails() {
    this.toolsGroup.global_id = "";
    this.toolsGroup.admin_account_name = "";
    this.toolsGroup.admin_account_password = "";
    this.toolsGroup.stealthwatch_email = "";
    this.toolsGroup.stealthwatch_password = "";
    this.toolsGroup.stealthwatch_token = "";
    this.toolsGroup.amp_access_token = "";
    this.toolsGroup.imanage_token = "";
    this.toolsGroup.azure_auth_token = "";
    this.toolsGroup.jenkins_email="";
    this.toolsGroup.jenkins_auth_token="";
   
   this. ResourceDisable=false;
    this.VnetDisable=false;
    this.SubDisable=false;
this.UpdateConnDisable=true;
this.AzuredrpDisable = false;
  }

  addVdi() {
    this.clearVdiDetails();
    this.showVdiForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshVdi() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-client-propertire']);
    });
  }
  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
   //debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].global_id);
        // this.checkedList.push(this.checklist[i].client_config_id);

        
    }
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        // this.checkedList.push(this.checklist[i].global_id);
        this.checkedList.push(this.checklist[i].client_config_id);

        
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length-1; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                  var SelectedItemtwo=this.checkedList[index+1]
                 let VdiConfigBoby={
                    "client_config_id":SelectedItemtwo,
                    "global_id":SelectedItem,


                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
   // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
 
  DeleteConfig(){
    // debugger
    // console.log(this.VdiConfig_details);
    // console.log(this.VdiConfig_details[0]);
         var deleteBody: any;

    
    this.userMailId = localStorage.getItem("mail").toLowerCase()
    
    this.check=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
     // console.log(this.VdiConfig_details[i]);
      this.check.push(this.VdiConfig_details[i])
      
    }
    //  this.VdiConfig_details.forEach(element => {
    //    console.log(element);
       
    //    this.check.push(element)
       
    //  });
    //console.log(this.check);
    
     deleteBody = {
       "client_details":this.check,
       "requester_email":this.userMailId,
       "resource_type": "client"
    
     }
   //  console.log(deleteBody,"deletebody");
     
    
    // debugger;
    //  var deleteBody: any;
    //  this.check=[]
    //  this.VdiConfig_details.forEach(element => {
    //    this.check.push(element.vdi_dropdown_id)
       
    //  });
    //  deleteBody = {
    //    "tool_ids":this.check
    
    //  }
    // deleteBody=this.VdiConfig_details[0]
    
     this.apiService.Admin_ClientDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-client-propertire']);
         });
        // this.alertService.success("Data Delete Successfully", this.options);
         this.alertService.success(res.body.message, this.options); 
       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
  onChange($event) {
    let AksToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = AksToolGrName;
    // console.log("scmToolGrName", AksToolGrName);
  }

 
 

 


  onChangeAzureId($event) {
   // debugger
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;
    // console.log("Azure selected value", azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    // console.log("Split Value ", i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
  //  debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
    //  debugger
      if (res) {
        // console.log(res, "Id details");


        res.forEach(member => {


          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;


        })

       
        localStorage.setItem("vdi_SubId", this.vdi_SubId);
        // localStorage.setItem("vdi_tenantId",  this.vdi_tenantId);

      //  debugger;
        //*** Get Resource Group From Azure ***//
     //   this.getResourceGroup(this.vdi_SubId)

      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  get_EditeAzureDetails(azureId) {
  //  debugger
    this.spinner.show();
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
    //  debugger
      if (res) {
        // console.log(res, "Id get_EditeAzureDetails");


        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
      this.spinner.hide();
  }

  CheckAksDuplication() {

   // debugger;

    if (

      this.toolsGroup.global_id == undefined || this.toolsGroup.global_id == "" || this.toolsGroup.global_id == " "
      || this.toolsGroup.admin_account_name == undefined || this.toolsGroup.admin_account_name == "" || this.toolsGroup.admin_account_name == " " 
      || this.toolsGroup.admin_account_password == undefined || this.toolsGroup.admin_account_password == "" || this.toolsGroup.admin_account_password == " " 
      ||   this.toolsGroup.stealthwatch_email == undefined || this.toolsGroup.stealthwatch_email == "" || this.toolsGroup.stealthwatch_email == " "
      || this.toolsGroup.stealthwatch_password == undefined || this.toolsGroup.stealthwatch_password == "" || this.toolsGroup.stealthwatch_password == " " 
      || this.toolsGroup.stealthwatch_token == undefined || this.toolsGroup.stealthwatch_token == "" || this.toolsGroup.stealthwatch_token == " " 
      ||   this.toolsGroup.amp_access_token == undefined || this.toolsGroup.amp_access_token == "" || this.toolsGroup.amp_access_token == " "
      || this.toolsGroup.imanage_token == undefined || this.toolsGroup.imanage_token == "" || this.toolsGroup.imanage_token == " " 
      || this.toolsGroup.azure_auth_token == undefined || this.toolsGroup.azure_auth_token == "" || this.toolsGroup.azure_auth_token == " " 
      || this.toolsGroup.jenkins_email == undefined || this.toolsGroup.jenkins_email == "" || this.toolsGroup.jenkins_email == " " 
      || this.toolsGroup.jenkins_auth_token == undefined || this.toolsGroup.jenkins_auth_token == "" || this.toolsGroup.jenkins_auth_token == " " 


      
      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

    //  let validateBody = {
      //  "resource_type": "tool",
       // "tool_category": "Network-Config",
        //"tool_name": "Network-Config-VDI",
        //"tool_instance_details": {
         // "network_config_tool_instance": {
           // "tool_instance_name": this.toolsGroup.nameInstance
          //}
        //}
      //}

      this.apiService.Admin_CheckClientValidation(this.toolsGroup.global_id).subscribe((res: HttpResponse<any>) => {
        //debugger;
        // console.log("Inside Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            case "01":
              this.alertService.error(res.body.msg, this.options);
              break;
            // case "02":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;

            default:
              this.ClientSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  ClientSave() {

    if (
    
       this.toolsGroup.global_id == undefined || this.toolsGroup.global_id == "" || this.toolsGroup.global_id == " "
      || this.toolsGroup.admin_account_name == undefined || this.toolsGroup.admin_account_name == "" || this.toolsGroup.admin_account_name == " " 
      || this.toolsGroup.admin_account_password == undefined || this.toolsGroup.admin_account_password == "" || this.toolsGroup.admin_account_password == " " 
      ||   this.toolsGroup.stealthwatch_email == undefined || this.toolsGroup.stealthwatch_email == "" || this.toolsGroup.stealthwatch_email == " "
      || this.toolsGroup.stealthwatch_password == undefined || this.toolsGroup.stealthwatch_password == "" || this.toolsGroup.stealthwatch_password == " " 
      || this.toolsGroup.stealthwatch_token == undefined || this.toolsGroup.stealthwatch_token == "" || this.toolsGroup.stealthwatch_token == " " 
      ||   this.toolsGroup.amp_access_token == undefined || this.toolsGroup.amp_access_token == "" || this.toolsGroup.amp_access_token == " "
      || this.toolsGroup.imanage_token == undefined || this.toolsGroup.imanage_token == "" || this.toolsGroup.imanage_token == " " 
      || this.toolsGroup.azure_auth_token == undefined || this.toolsGroup.azure_auth_token == "" || this.toolsGroup.azure_auth_token == " " 
      || this.toolsGroup.jenkins_email == undefined || this.toolsGroup.jenkins_email == "" || this.toolsGroup.jenkins_email == " " 
      || this.toolsGroup.jenkins_auth_token == undefined || this.toolsGroup.jenkins_auth_token == "" || this.toolsGroup.jenkins_auth_token == " " 

      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      
      var saveBody: any;
      
      // ##### Code for New_Save Funtionality ########
      saveBody = {
        "resource_type":"client",

          "client_config_id":"",
          "global_id": this.toolsGroup.global_id,
          "admin_account_name": this.toolsGroup.admin_account_name,
          "admin_account_password": this.toolsGroup.admin_account_password,
          "stealthwatch_email": this.toolsGroup.stealthwatch_email,
          "stealthwatch_password": this.toolsGroup.stealthwatch_password,
          "stealthwatch_token": this.toolsGroup.stealthwatch_token,
          "amp_access_token": this.toolsGroup.amp_access_token,
          "imanage_token": this.toolsGroup.imanage_token,
          "azure_auth_token": this.toolsGroup.azure_auth_token,
          "jenkins_email": this.toolsGroup.jenkins_email,
          "jenkins_auth_token": this.toolsGroup.jenkins_auth_token,
          "created_by":localStorage.getItem('mail').toLowerCase()

      }
     // console.log(saveBody);
     // debugger;

      this.apiService.Admin_Client_SaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
       // debugger; 
        if (res.status == 200) {
          //          console.log(res);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-client-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }
        else if (res.status == 202) {

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-client-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {

  }
  cancelok(){
    this.clearVdiDetails();
    localStorage.setItem("refreshJira", "true");

  if(this.nextUrl==undefined){
    this.refreshVdi()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  
  closeAks() {
    this.showVdiForm = false;
    this.editMode = false;
    this.refreshVdi()

  }
  showImageInfo(domain) {
    //console.log(domain);
    const dialogRef = this.dialog.open(ClientInfoDialog, {
      width: '50%',
      height: '50%',
      disableClose: true,
      data: {
        // image: domain.showImage,
         clientvar:domain
       }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
   
   
  }
  updateClient() {
   // debugger;
    if (
     
      this.toolsGroup.global_id == undefined || this.toolsGroup.global_id == "" || this.toolsGroup.global_id == " "
      || this.toolsGroup.admin_account_name == undefined || this.toolsGroup.admin_account_name == "" || this.toolsGroup.admin_account_name == " " 
      || this.toolsGroup.admin_account_password == undefined || this.toolsGroup.admin_account_password == "" || this.toolsGroup.admin_account_password == " " 
      ||   this.toolsGroup.stealthwatch_email == undefined || this.toolsGroup.stealthwatch_email == "" || this.toolsGroup.stealthwatch_email == " "
      || this.toolsGroup.stealthwatch_password == undefined || this.toolsGroup.stealthwatch_password == "" || this.toolsGroup.stealthwatch_password == " " 
      || this.toolsGroup.stealthwatch_token == undefined || this.toolsGroup.stealthwatch_token == "" || this.toolsGroup.stealthwatch_token == " " 
      ||   this.toolsGroup.amp_access_token == undefined || this.toolsGroup.amp_access_token == "" || this.toolsGroup.amp_access_token == " "
      || this.toolsGroup.imanage_token == undefined || this.toolsGroup.imanage_token == "" || this.toolsGroup.imanage_token == " " 
      || this.toolsGroup.azure_auth_token == undefined || this.toolsGroup.azure_auth_token == "" || this.toolsGroup.azure_auth_token == " " 
      || this.toolsGroup.jenkins_email == undefined || this.toolsGroup.jenkins_email == "" || this.toolsGroup.jenkins_email == " " 
      || this.toolsGroup.jenkins_auth_token == undefined || this.toolsGroup.jenkins_auth_token == "" || this.toolsGroup.jenkins_auth_token == " " 

      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      var updateBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      updateBody = {
          "resource_type":"client",
          "client_config_id": this.selectedClientConfigID,
          "global_id": this.toolsGroup.global_id,
          "admin_account_name": this.toolsGroup.admin_account_name,
          "admin_account_password": this.toolsGroup.admin_account_password,
          "stealthwatch_email": this.toolsGroup.stealthwatch_email,
          "stealthwatch_password": this.toolsGroup.stealthwatch_password,
          "stealthwatch_token": this.toolsGroup.stealthwatch_token,
          "amp_access_token": this.toolsGroup.amp_access_token,
          "imanage_token": this.toolsGroup.imanage_token,
          "azure_auth_token": this.toolsGroup.azure_auth_token,
          "jenkins_email": this.toolsGroup.jenkins_email,
          "jenkins_auth_token": this.toolsGroup.jenkins_auth_token,
          "updated_by":localStorage.getItem('mail').toLowerCase()
      }

      this.apiService.Admin_UpdateClientProperties(updateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-client-propertire']);
          });
          this.alertService.success(res.body.message, this.options);         }
        else if (res.status == 202) {

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-client-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }

      },(error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupclientDialog, {
      width: '30%',
      height: '48%',

    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'project-info.html',
  styleUrls: ['./admin-client-propertire.component.scss']
})

export class ClientInfoDialog {
  //  image: any = [];
  // Template_id:any;
  //   enabledIcons: any[];
  //   workbenchIcons: any[];
  //   roleData: any;
  stealthwatchtoken:any;
  ampaccesstoken:any;
  imanagetoken:any;
  azurezuthtoken:any;
  jenkinsauthtoken:any;

    
    constructor(
      public dialogRef: MatDialogRef<ClientInfoDialog>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService, public alertService: AlertService,
    ) { 
    
    
      this.stealthwatchtoken=data.clientvar.stealthwatch_token;
      this.ampaccesstoken=data.clientvar.amp_access_token;
      this.imanagetoken=data.clientvar.imanage_token;
      this.azurezuthtoken=data.clientvar.azure_auth_token;
      this.jenkinsauthtoken=data.clientvar.jenkins_auth_token;

   //   console.log(this.stealthwatchtoken);
      
  
      
  
    }


    exit(): void {
      this.dialogRef.close();
    }
  
  }
@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-client-propertire.component.scss']
})

export class AddToolGroupclientDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
        
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Client", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Client", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Client", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-client-propertire']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
}
