<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a (click)="goHome(secondFormGroup)">Home</a></li>
            <li class="breadcrumb-item link-blue"><a (click)="goWorkBeanch(secondFormGroup)">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Jira</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <!-- <span (click)="addProject()" *ngIf="!storyboard_flag"><i class="fas fa-plus"></i> Add</span>
          <span  *ngIf="storyboard_flag"  data-toggle="modal" data-target="#myModal"><i class="fas fa-plus"></i> Add</span> -->
          <span (click)="checkToolData()" ><i class="fas fa-plus"></i> Add</span>
          <span id="CallModel"  data-toggle="modal" data-target="#myModal"></span>
          <!-- <span (click)="refreshJira(filterStatus)"><i class="fas fa-sync-alt"></i> Refresh</span> -->
          <span *ngIf="refreshDisable" (click)="refreshJira(filterStatus)"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span *ngIf="!refreshDisable" class="disabled-img"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>

  </div>

  <div class="inner-mid-container">
    <div class="row "> 
      <div class="col-md-6">
        <div class="row">
          <div class="col-sm-5">
            <h1 class="mb-0">
              <img width="40" src="../../../assets/img/jira.png">
              <div class="vertical-line mx-3"> </div> <span>Jira</span>
            </h1>
          </div>
          <div class="col-md-7"><span class="field-name">Project ID </span><span class="field-value"> :
              {{projectId}}</span>
            <span class="material-icons show-project-info" title="System Information"
              (click)="showProjectInfo()">info</span>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-2">
        <div class="text-right">
          <div class="row">
          <div class="col-md-10 col-12">
            <mat-form-field class="mr-1">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="filterStatus" (selectionChange)="getStatus($event.value)" name="fieldName" ngDefaultControl> 
                <mat-option value="All">All</mat-option>
                <mat-option value="Active">Active</mat-option>
                <mat-option value="Failed">Failed</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="col-md-2 col-12">
            <div class="dropdown" *ngIf="tableRepoArray.length>0">
              <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                <i class="fas fa-download "></i> Export
              </a>
              <div class="dropdown-menu more-drop-down">
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('pdf')">
                    <i width="20" class="fas fa-file-pdf color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> PDF</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('excel')">
                    <i width="20" class="fas fa-file-excel color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> EXCEL</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('csv')">
                    <i width="20" class="fas fa-file-csv color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> CSV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
          
        </div>
      </div>
    </div>
    <div class="mb-2" *ngIf="showForm">
      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row">
          <div class="col-12 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="jiraProjName">Enter Project Name</label>
              <input #input style="font-size: 14px; height: 35px;" placeholder="Enter Project Name" id="jiraProjName"
                name="jiraProjName" class="form-control" required formControlName="jiraProjName"
                [class.is-invalid]="jiraProjName.invalid && jiraProjName.touched">
              <small class="text-danger"
                [class.d-none]="jiraProjName.valid || jiraProjName.untouched">{{getJiraProjNameErrorMessage()}}</small>

            </div>
          </div>

          <div class="col-12 col-md-4 col-lg-2">
            <div class="form-group">
              <label for="toolInstance">Select Jira Instance</label>
              <select style="font-size: 14px; height: 35px;" id="toolInstance" name="toolInstance" class="form-control"
                formControlName="toolInstance" [class.is-invalid]="toolInstance.invalid && toolInstance.touched">
                <option value="" disabled selected hidden>Select Jira Instance</option>
                <option *ngFor="let role of toolGroup" [value]="role">{{role}}</option>
              </select>
              <small class="text-danger" [class.d-none]="toolInstance.valid || toolInstance.untouched">Tool Instance is
                required</small>

            </div>
          </div>

          <div class="col-12 text-center col-md-1 mt-1 mr-4 img-width">
            <span class="material-icons add-user" (click)="openUser()" title="Add User">
              person_add
            </span>
          </div>

          <div class="col-12 text-center col-md-1 mt-4 mr-4">
            <button mat-button (click)="confirmationPopup()" mat-raised-button
              [disabled]="!secondFormGroup.valid || !selectedUserArray.length" class="hover users-button width-100"
              title="Create Project">
              Create
            </button>
          </div>

          <div class="col-12 text-center col-md-1 mt-4 mb-2">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2 "
              (click)="toggleDisplay(secondFormGroup)">Cancel</button>
          </div>
        </div>


      </form>
    </div>

    <div class="row userRow mb-4" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">
        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>


    <div class="table-responsive">
    <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="tableRepoArray.length>0">
      <thead>
        <tr>
          <th> Request Id</th>
          <th> Project Name </th>
          <th> URL </th>
          <th> Tool Instance </th>
          <th> Status </th>
          <th class="w15">Members</th>
          <th> Action </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of tableRepoArray">
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.request_id}} </td>
          <td class="word-break"  [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.repoName}} </td>
          <td  *ngIf="element.status!='Moved_to_Recycle'">
              <h4   *ngIf="!element.repoUrl || element.status=='Processing'">--NA--</h4>
              
              <h4 *ngIf="element.repoUrl && (element.status=='Pending'|| element.status=='Partially_Processed'  || element.status=='Completed' || element.status=='Removed' || element.status=='Failed' || element.status=='Deleted')" ><a target="_blank" class="word-break" href="{{element.repoUrl}}">{{element.repoUrl}}</a></h4>
            </td>
            <td class="softDelete-cell" *ngIf="element.status=='Moved_to_Recycle'">
                <h4 class="word-break" *ngIf="element.repoUrl && (element.status=='Moved_to_Recycle')" >{{element.repoUrl}}</h4>
                
              </td>
          <td class="word-break" [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.toolInstance}} </td>
          <td *ngIf='element.status!="Moved_to_Recycle"'>{{element.status}}</td>
          <td class="softDelete-cell" *ngIf='element.status=="Moved_to_Recycle"'>Moved to Recycle</td>
        <td class="w15" *ngIf="element.status!='Moved_to_Recycle'">
            <h4
              *ngIf="element.status=='Removed' || element.status=='Pending' || element.status=='Deleted' || element.status=='Failed'">
              --NA--
            </h4>
            <h4 *ngIf="element.members.length==0 && (element.status=='Completed' || element.status=='Processing' || element.status=='Partially_Processed')">
              --NA--
            </h4>
            <button *ngIf="element.members.length >1 && (element.status!='Removed' && element.status!='Deleted' && element.status!='Failed' && element.status!='Pending')"
              class="view-member-btn"
              (click)="openAddedUserDialog(element.members,element.status,element.request_id,'Jira')">View</button>
            <div *ngIf="element.members.length == 1">
              <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                <h4 *ngIf="user.status=='Approved' && (element.status=='Completed' || element.status=='Processing' || element.status=='Partially_Processed')">
                  {{user.name}}
                  
                  <!-- <span class="close_icon cursor" *ngIf="element.status=='Completed' && user.status == 'Approved'"
                    (click)="deleteUser(element.request_id,user.email)" title="Remove User">x</span> -->
                </h4>
                <h4 *ngIf="user.status=='Pending' && (element.status=='Completed' || element.status=='Processing' || element.status=='Partially_Processed')">
                  {{user.name}}
                  <br>
                  (Inactive)
                  <!-- <span class="close_icon cursor" *ngIf="element.status=='Completed' && user.status == 'Approved'"
                    (click)="deleteUser(element.request_id,user.email)" title="Remove User">x</span> -->
                </h4>
              </span>
            </div>
          </td>

          <td class="w15-soft-delete" *ngIf="element.status=='Moved_to_Recycle'">
              <h4 *ngIf="element.members.length==0 && (element.status=='Moved_to_Recycle')">
                  --NA--
                </h4>
              <button *ngIf="element.members.length >1 && (element.status=='Moved_to_Recycle')" class="view-member-btn-inactive">View</button> 
  
              <button *ngIf="element.members.length >1 && (element.status!='Removed' && element.status!='Deleted' &&  element.status!='Failed' &&  element.status!='Pending' &&  element.status!='Moved_to_Recycle')" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.reqId,'SCM')">View</button> 
                <div *ngIf="element.members.length == 1">
                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                    <h4 *ngIf="user.status!='offboarded' && (element.status=='Completed' || element.status=='Processing')">{{user.name}}
  
                    <!-- <span class="close_icon cursor" *ngIf="element.status=='Completed' && user.status == 'Approved'" (click)="deleteUser(element.reqId,user.email)" title="Remove User">x</span> -->
                  </h4> 
                  <h4 class="table-inactive" *ngIf="user.status!='offboarded' && (element.status=='Moved_to_Recycle')">{{user.name}}</h4> 
  
  
                  </span>
                </div>
            </td>



          <td *ngIf="element.status!='Moved_to_Recycle'"> 
            <div class="dropdown">
              <a href="#" class="nav-link" data-toggle="dropdown">
                <i class="fas fa-ellipsis-h"></i>
              </a>
              <div class="dropdown-menu more-drop-down">
                <a class="dropdown-item">
                  <span class="table-crud table-edit-icon" (click)="addMemberToRepo(element,'add')"
                    *ngIf="element.status=='Partially_Processed' || element.status=='Completed'">
                    <img width="20" src="../../../assets/img/AddMember.png">
                    <span class="vertical-line-more mx-2"></span> Add Member</span>
                  <span class="table-crud table-edit-icon"
                    *ngIf="element.status!='Partially_Processed' && element.status!='Completed'" disabled>
                    <img width="20" src="../../../assets/img/AddMember-disabled.png">
                    <span class="vertical-line-more mx-2"></span> Add Member</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="table-crud table-edit-icon" (click)="addMemberToRepo(element, 'delete')"
                    *ngIf="element.status=='Partially_Processed' || element.status=='Completed'">
                    <img width="20" src="../../../assets/img/AddMember.png">
                    <span class="vertical-line-more mx-2"></span> Delete Member</span>
                  <span class="table-crud table-edit-icon"
                    *ngIf="element.status!='Partially_Processed' && element.status!='Completed'" disabled>
                    <img width="20" src="../../../assets/img/AddMember-disabled.png">
                    <span class="vertical-line-more mx-2"></span> Delete Member</span>
                </a>
                <!-- <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <img width="20" src="../../../assets/img/Save-Deactive.png">
                  <span class="vertical-line-more mx-2"></span> Save</a> -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span *ngIf="element.status=='Completed'" (click)="deleteconfirmationPopUp(element)">
                    <img width="20" src="../../../assets/img/Delete-blue.png">
                    <span class="vertical-line-more mx-2"></span>Move to Recycle
                  </span>
                  <span
                    *ngIf="element.status=='Partially_Processed' || element.status=='Processing' || element.status!='Completed'"
                    disabled>
                    <img width="20" src="../../../assets/img/Delete-Deactivate.png">
                    <span class="vertical-line-more mx-2"></span>Move to Recycle
                  </span>


                </a>
              </div>
            </div>

          </td>
          <td *ngIf="element.status=='Moved_to_Recycle'" class="softDelete-cell"> 
              <a href="#" class="nav-link" data-toggle="dropdown" *ngIf="element.status=='Moved_to_Recycle'">
                  <i class="fas fa-ellipsis-h" style="color:rgba(0, 0, 0, 0.86);"></i>
                </a>
          </td>
        </tr>
      </tbody>
    </table>

    <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="tableRepoArray.length==0">
      <thead>
        <tr>
          <th> Request Id</th>
          <th> Project Name </th>
          <th> URL </th>
          <th> Tool Instance </th>
          <th> Status </th>
          <th class="w15">Members</th>
          <th> Action </th>
        </tr>
      </thead>
      <tbody>
        
      </tbody>
    </table>
  </div>
  </div>


</div>

<div class="modal fade" id="myModal"  role="dialog">
  <div class="modal-dialog">
    <div class="modal-content modal-adjust" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <h2 class="text-center">No tool instance Available.</h2>
      </div>
      <div class="modal-header">
        
      </div>
    </div>
    
  </div>
</div>
