
<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up">
        <div class="">
               <span  class="vertical-line mx-1 mx-md-3"> </span>
                <span class="header mb-0"> Webex Poperties Details</span> 
               <span (click)="exit()" class="material-icons close-icon">
                   close
                 </span>
        </div>
        <hr class="margin-class" />
       <div class="popup-container">
       <div class="row pt-2">
           <div class="col-md-4">
               <span class="field-name">Client ID </span>
           </div>
           <div class="col-md-6">
                   <span class="fieldStyle"> : {{clientID}}</span>
           </div>
       </div>
       
       <div class="row pt-2">
           <div class="col-md-4">
               <span class="field-name">Client Secret </span>
           </div>
           <div class="col-md-6">
                   <span class="fieldStyle"> : {{clientSecret}}</span>
           </div>
       </div>
       
       <div class="row pt-2">
           <div class="col-md-4">
               <span class="field-name">Redirect URL  </span>
           </div>
           <div class="col-md-6">
                   <span class="fieldStyle"> : {{redirectUrl}}</span>
           </div>
       </div>
       <!-- <div class="row pt-2">
        <div class="col-md-4">
            <span class="field-name">Azure Auth Token</span>
        </div>
        <div class="col-md-6">
                <span class="fieldStyle"> : {{azurezuthtoken}}</span>
        </div>
    </div>
       
       <div class="row pt-2">
           <div class="col-md-4">
               <span class="field-name">Jenkins Auth Token</span>
           </div>
           <div class="col-md-6">
                   <span class="fieldStyle"> : {{jenkinsauthtoken}}</span>
           </div>
       </div> -->
       </div>
       </div>