<!-- <link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css"> -->
<div class="inner-page-container">
   <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner> 
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">MS Teams</li>
          </ol>
        </nav>
      </div>
      <div class="offset-md-5 col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/teamsDashboard" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>

  </div>


  <div class="inner-mid-container">
    <div class="row">
      <div class="col-md-3">
        <h1 class="page-name mb-0">
          <img width="40" src="assets/img/teams_logo.png">
          <div class="vertical-line mx-3"> </div> <span>MS Teams</span>
        </h1>
      </div>
      <div class="col-md-3">
        <span class="field-name">Project ID </span><span class="field-value"> :
          {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information"
          (click)="showProjectInfo()">info</span>
      </div>

    </div>
    <div class="row ptPercent">
      <span title="Add new Channel" class="offset-md-11 hover" (click)="showSpaceForm()">
        <i class="fa fa-plus"></i> Add</span>
    </div>
    <div class="row mt1 pl-3" *ngIf="showForm">
      <div class="col-md-2 field-name" style="padding-top: 26px;">Channel Names</div>

      <div class="col-md-10 channelList">
        <div class="row channel-list">
          <div class="pr-3 mb-5" *ngFor="let element of channelArrayData; let i = index">
            <div class="col-md-12">
              <label>Edit Channel Name
                <span style="cursor:pointer;padding-left: 25px;" (click)="removeChannelName(i)"><i class="fa fa-times"
                    aria-hidden="true"></i></span>
              </label>
              <input placeholder="Edit Channel Name" name="channelName{{i}}" [(ngModel)]="element.channelName"
                class="form-control form-control-height" (keypress)="omit_special_char($event)">
            </div>
          </div>
          <div class="col-md-2">
            <span title="Add new Channel" style="cursor:pointer; font-size: 30px;" (click)="enableEdit()">
              <i class="fa fa-plus-circle"></i> </span>
            <!-- <span class="fa fa-plus-circle" style="cursor:pointer; font-size: 30px;"
              (click)="enableEdit()"></span> -->
          </div>
        </div>
      </div>

    </div>

    <div class="row button-class" *ngIf="showForm">
      <button mat-raised-button color="primary" class="create-channel" (click)="createTeamsChannel()">Create Channel
      </button>
      <button mat-raised-button color="primary" class="create-channel" (click)="cancel()">Cancel
      </button>
    </div>

    <!-- <div class="row mt-2" *ngIf="existingSpaces"> -->
    <div class="row mt-2">
      <div class="table-responsive">
      <table class="table " datatable [dtOptions]="dtOptions">
        <thead>
          <tr>
            <th>Channel Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let space of spaceTable">
            <td>{{space.channelName}}</td>
            <td>
              <div class="dropdown dropdown-style">
                <a href="#" class="nav-link" data-toggle="dropdown">
                  <i class="fas fa-ellipsis-h"></i>
                </a>
                <div class="dropdown-menu more-drop-down">
                  <a class="dropdown-item" (click)="viewUserForChannel(space.channelName)">
                    <img width="20" src="../../../assets/img/View-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> View Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item"
                    (click)="openUserForChannel(space.channelName,'Add')"> 
                    <img width="20" src="../../../assets/img/Add-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> Add Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="openUserForChannel(space.channelName, 'Delete')"> 
                    <!-- <a class="dropdown-item"> -->
                    <img width="20" src="../../../assets/img/View-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> Remove Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="deleteChannel(space.channelName)">
                    <img width="15" src="../../../assets/img/Delete-blue.png"> <span
                      class="vertical-line-more mx-2"></span> Delete Channel</a>

                  <!-- <div class="dropdown-divider"></div>
                  <a class="dropdown-item disabled-img"
                    (click)="addDeleteTools(space.space_name,space.members,space.space_id,'Add')">
                    <a class="dropdown-item disabled-img">
                    <i class="fas fa-plus"></i> <span class="vertical-line-more mx-2"></span> Add Tools</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item disabled-img"
                    (click)="addDeleteTools(space.space_name,space.members,space.space_id,'Delete')">
                    <a class="dropdown-item disabled-img">
                    <img width="15" src="../../../assets/img/Delete-blue.png"> <span
                      class="vertical-line-more mx-2"></span> Delete Tools</a> -->

                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

  </div>



</div>
<!-- artifact changes added -->