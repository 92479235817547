import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit,Inject} from '@angular/core';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from "ngx-spinner";

// import { MatDialog } from '@angular/material/dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Router, RouterLink } from '@angular/router';
import { error } from '@angular/compiler/src/util';
import { Subject, throwError } from 'rxjs';	
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Navigation } from 'selenium-webdriver';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';


export interface configList {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

export interface storyBoardDetails {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}
export interface jiraTool {
  toolName: string;
  ID: string;
  db_id:string;
  name: string;
  description: string;
  projectURL: string;
  email:string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

@Component({
  selector: 'app-admin-jira',
  templateUrl: './admin-jira.component.html',
  styleUrls: ['./admin-jira.component.scss']
})
export class AdminJiraComponent implements OnInit,CanComponentLeave {
  isValid: boolean = true;
  
  toolDescription:any;
  masterSelected: boolean;

  model:any={}; 
  Role:string="";
  checklist: any;

  testConnDisable = false;
  saveConnDisable = true;
  UpdateConnDisable=true;
  UrlDisable=false;
  TokenDisable=false;
  EmailDisable=false;
  checkedList: any;

  ToolGroupDisable=false;
  ToolInstanceNameDisable =false;
  project_selectedcheckbox = false;

  VdiConfig_details:any=[];
  check:any=[]



  // Variable for Save/Edit body
  project_Token: string;
  project_URL: string;
  project_toolGroupName: string;
  project_id_scmEdit: string;
  project_id_db:string;
  project_nameInstance: string;
  project_description: string;
  project_jiraEmail: string;
  project_toolId:String;
  project_toolGrId: string;

  token: any;
  //projectURLVal: any;
  toolName: any;

  storyBoard: any = {};

  jiraToolDetails: jiraTool;
  selectedToolvalue: any;

  formMode: string = "";
  editMode:boolean = false;
  toolGr: any;
  proURL: any;
  proToken: any;
  nameInstance:any;
  description:any;
  jiraEmail:any;
  storyBoardForm:any;
  jiraPage:boolean = true;
  addJiraDiv:boolean = false;
  storyBoardObj: object[];
  ToolGroupList = [];
  StoryBoardArray = [];

  submitted = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();

  itemSelected:boolean=false;
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-story-board"){
      return true
    }
    else{
      if((this.storyBoard.proURL == '' || this.storyBoard.proURL == undefined) &&
      (this.storyBoard.toolGr == '' || this.storyBoard.toolGr == undefined) &&
       (this.storyBoard.description == '' || this.storyBoard.description == undefined)  &&
       (this.storyBoard.jiraEmail == undefined || this.storyBoard.jiraEmail == '') &&
        (this.storyBoard.nameInstance == '' || this.storyBoard.nameInstance == undefined)
         && (this.storyBoard.proToken == '' || this.storyBoard.proToken == undefined)){
        return true
      }
      if ((this.storyBoard.proURL != '' || this.storyBoard.proURL != undefined) &&
      (this.storyBoard.toolGr != '' || this.storyBoard.toolGr != undefined) &&
       (this.storyBoard.description != '' || this.storyBoard.description != undefined)  &&
       (this.storyBoard.jiraEmail != undefined || this.storyBoard.jiraEmail != '') &&
        (this.storyBoard.nameInstance != '' || this.storyBoard.nameInstance != undefined)
         && (this.storyBoard.proToken != '' || this.storyBoard.proToken != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,
    private spinner: NgxSpinnerService, public alertService: AlertService ) {
      this.masterSelected = false;

     }

    
  ELEMENT_DATA: storyBoardDetails[];
  displayedColumns: string[] = ['toolName', 'ID', 'projectURL', 'email', 'toolGroup', 'select'];
  //dataSource = new MatTableDataSource<storyBoardDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<storyBoardDetails>(true, []);

  ngOnInit(): void { 
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){   
    this.getTypeT();
    this.dtOptions = {
      order: []
    };
    //
    var PopupToolGroup = localStorage.getItem('Popup_Open_Jira')
    if(PopupToolGroup=="true"){
      this.showJiraPage();
      this.addJiraDiv =true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Jira')
      this.storyBoard.toolGr = Popup_NewToolGroupID;
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Jira')
      this.project_toolGroupName  = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Jira", "false");
    }else{
      this.hideJira();
      var Refresh_Jira = localStorage.getItem('refreshJira')
      if(Refresh_Jira=="true"){
      this.showJiraPage();
      localStorage.setItem("refreshJira", "false");
      }
   
    }
    this.GetStoryBoardtableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  refreshJira(){
    
    localStorage.setItem("refreshJira", "true");
    this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-jira']);
  });
  
  }

  backNavigation(){
    if ((this.storyBoard.proURL == '' || this.storyBoard.proURL == undefined) &&
     (this.storyBoard.toolGr == '' || this.storyBoard.toolGr == undefined) &&
      (this.storyBoard.description == '' || this.storyBoard.description == undefined)  &&
      (this.storyBoard.jiraEmail == undefined || this.storyBoard.jiraEmail == '') &&
       (this.storyBoard.nameInstance == '' || this.storyBoard.nameInstance == undefined)
        && (this.storyBoard.proToken == '' || this.storyBoard.proToken == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
   // debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  
  DeleteConfig(){
    // debugger;
     var deleteBody: any;
     this.check=[]
     this.VdiConfig_details.forEach(element => {
       this.check.push(element.vdi_dropdown_id)
       
     });
     deleteBody = {
       "tool_ids":this.check
    
     }
    
     this.apiService.Admin_JiraDropdowDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-jira']);
         });
        //  this.alertService.success("Data Delete Successfully", this.options);
        this.alertService.success(res.body.message, this.options); 

       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }

  onChange($event){
    let jiraToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGroupName = jiraToolGrName;
   //console.log("scmToolGrName",jiraToolGrName);
    }

  GetStoryBoardtableDetails() {
    this.spinner.show();
    
   
       this.apiService.Admin_StoryBoard_JiraTableDetails().subscribe((res: any) => {
        
        //console.log(res)
         res.forEach(member => {
           let obj = {
             "toolName": member.tool_name,
             "ID": member.tool_id,
             "db_id":member._id,
             "projectToken": member.tool_instance_details.storyboard_tool_instance.tool_auth.auth_token,
             "email": member.tool_instance_details.storyboard_tool_instance.tool_auth.auth_mail,
             "name": member.tool_instance_details.storyboard_tool_instance.tool_instance_name,
             "description": member.tool_instance_details.storyboard_tool_instance.tool_description,
             "projectURL": member.tool_instance_details.storyboard_tool_instance.tool_url,
             "toolGroup": member.tool_group_details.tool_group_name,
             "toolGroup_id": member.tool_group_details.tool_group_id
            
   
           }
           this.StoryBoardArray.push(obj)
           this.checklist = this.StoryBoardArray;

   
         })
        // console.log(this.StoryBoardArray)
       
         this.dtTrigger.next();
         this.spinner.hide();
       }
       , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
       // SCMArray
     }
   
     ngOnDestroy(): void {    
       this.dtTrigger.unsubscribe();
     }

    
  showJiraPage(){
    //this.dialogCheck = false;
    this.jiraPage = true;
  }
  cancelok(){
    this.clearAllSCMDetails();
    localStorage.setItem("refreshJira", "true");

  if(this.nextUrl==undefined){
    this.refreshJira()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  hideJira(){
    //console.log("hello");
 

    this.addJiraDiv = false;
    this.editMode = false;
   
    this.testConnDisable=false;
    this.saveConnDisable=true;
    this.UpdateConnDisable=true;
    
  }

  addJira(){
    this.clearAllSCMDetails();
    this.addJiraDiv = true;
    this.ToolGroupDisable=false;
    this.ToolInstanceNameDisable = false;
  }
  toggleDisplay(form) {
    //console.log("Form data", form);
    if (form.pristine == false) {
      this.cancelconfirmationPopUp();
    } else {
      // this.router.navigate(['/admin-jira'])
      // this.model.Subscription == " "
      this.addJiraDiv = false;
      // this.showUserRow = false;
      // this.selectedUserArray = [];
      // this.cost = this.costPerUser;
    }

  }
  goHome(form) {
    if (form.pristine == false) {
      this.homeConfirmationPopup();
    } else {
      this.router.navigate(['/admin-catalog-config'])
    }
  }
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(jiraCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(jiraHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }
  getRecord(row) {
    
    // console.log(row);
     this.storyBoardObj = row;
     try {
      localStorage.setItem('storyBoardObjToolObj', JSON.stringify(this.storyBoardObj));
      localStorage.setItem("formMode", "Edit")
     }
     catch {
      return throwError('Data not found');
     }
     this.setStoryBoardEditing(row);
     this.addJiraDiv =true;
     this.testConnDisable=false;

     this.editMode = true;
     this.ToolGroupDisable=true
     this.ToolInstanceNameDisable = true;
     this.UpdateConnDisable=true;


   }
   
 

  getTypeT(): void {
    //
    this.spinner.show();
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        
       
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
        //  console.log(data.body[i].tool_group_name);
          if (data.body[i].tool_group_name != undefined) {
           // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
        this.spinner.hide();
      }
    }
    ,(error: HttpErrorResponse) => {
     // 
     
      if (error.status == 500)
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);       
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Invalid Input", this.options);       
        throw error;
      }
      this.spinner.hide();
    })
  }


  setStoryBoardEditing(jiraObj: jiraTool) {
    //debugger;
    this.storyBoard.toolGr = jiraObj.toolGroup_id;
    this.storyBoard.proURL = jiraObj.projectURL;
    this.storyBoard.nameInstance = jiraObj.name;
    this.storyBoard.description = jiraObj.description;
    this.storyBoard.jiraEmail = jiraObj.email;
    this.storyBoard.proToken = jiraObj.projectToken;
    this.project_id_scmEdit = jiraObj.ID;
    this.project_id_db =jiraObj.db_id;
    this.project_toolId = jiraObj.ID
    this.project_toolGrId = jiraObj.toolGroup_id;
    this.project_toolGroupName= jiraObj.toolGroup;
  }

  selectedTool(e) {

  }

  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  onSubmit() {
    // debugger;
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
   // console.log(this.storyBoard.proURL);
  
// if (!this.validateEmail(this.storyBoard.jiraEmail))
// {
//   this.alertService.error("Jira Email not valid", this.options);  
//   return;
// }

    if (this.storyBoard.proURL == undefined || this.storyBoard.toolGr == undefined
      ||this.storyBoard.nameInstance == undefined || this.storyBoard.description == undefined
      ||this.storyBoard.jiraEmail == undefined || this.storyBoard.toolGr == "" || this.storyBoard.toolGr == " " 
      || this.storyBoard.proToken == undefined  || this.storyBoard.jiraEmail == " "|| this.storyBoard.jiraEmail == "" 
      || this.storyBoard.description == " "|| this.storyBoard.description == "" || this.storyBoard.nameInstance == " " || this.storyBoard.nameInstance == ""
      || this.storyBoard.proURL == " " || this.storyBoard.proURL == "" || this.storyBoard.proToken == " "|| this.storyBoard.proToken == "") {
        this.testConnDisable = true;
        this.alertService.error("Please enter details In all the fields", this.options);  
        
        setTimeout( ()=>{
          this.testConnDisable = false;
          }, 2500)
    }
    else if(this.storyBoard.description.length<20 || this.storyBoard.nameInstance<3 || this.storyBoard.nameInstance>20 || this.storyBoard.description.length>50 || (!this.validateEmail(this.storyBoard.jiraEmail)) || sp.test(this.storyBoard.nameInstance)){
      this.testConnDisable = true;

      // this.alertService.error("Please enter minimum 20 characters for Description ", this.options);
      setTimeout(() => {
        this.testConnDisable = false;
      }, 2)

          }
    else if (!this.validateEmail(this.storyBoard.jiraEmail))
{
  this.alertService.error("Jira Email not valid", this.options);  
  return;
}
    else {

      //
      if(this.storyBoard.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.description.length<20){
        this.alertService.error("Please enter minimum 20 characters for saving Description", this.options);
        return;
      }
      if(this.storyBoard.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
      var url: string = this.storyBoard.proURL;
      var nameInstance: string = this.storyBoard.nameInstance;
      var description: string = this.storyBoard.description;
      var jiraEmail: string = this.storyBoard.jiraEmail;
      var token: string = this.storyBoard.proToken;
      var toolGroup: string = this.storyBoard.toolGr;

      url = url.trim();
      nameInstance = nameInstance.trim();
      description = description.trim();
      jiraEmail = jiraEmail.trim();
      token = token.trim();
      toolGroup = toolGroup.trim();

      this.jiraTestConnection(url, token, jiraEmail);
    }
   
  }


  jiraTestConnection(project_URL, project_Token, project_email) {
       let testConnBody ={
      "tool_name":"Jira",
      "tool_url":project_URL,
      "auth_token":project_Token,
      "auth_mail":project_email
  }

    //console.log(testConnBody);
    this.spinner.show();
    this.apiService.Admin_JiraTestConnection(testConnBody).subscribe((res: HttpResponse<any>) => {
     // debugger;
      //console.log(res);
      if (res.status == 200) //doesnt work
      {
      
        // this.alertService.success("Test Connection Successfully. Please Save the Connection", this.options);  
        this.alertService.success(res.body.message, this.options); 

        this.testConnDisable = true;
        // this.saveConnDisable = false;
        // this.UpdateConnDisable=false;
        this.spinner.hide();
        if (this.editMode==true){
          this.UpdateConnDisable = false;
          this.saveConnDisable = true;
          this.UrlDisable=true;
          this.TokenDisable=true;
          this.EmailDisable=true;
          // this.TenantDisable=true;

        }
        else{
          this.UpdateConnDisable = true;
          this.saveConnDisable = false;
          this.UrlDisable=true;
          this.TokenDisable=true
          this.EmailDisable=true;
          // this.TenantDisable=true;
        }

      }
      if (res.status == 500) //doesnt work
      {        
   //     debugger;
        this.alertService.error("Please Enter Valid Inputs", this.options);  
        this.spinner.hide();

      }
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      if (error.status == 436) //doesnt work
      {
        this.alertService.error(error.error.message, this.options); 
      }
      else if (error.status == 403){}
      if (error.status == 500) //doesnt work
      {
     //   debugger;
        this.testConnDisable = true;
        this.alertService.error("Please Check URL and Email-ID or confirm if your token has sufficient access permissions"
        , this.options); 
        this.spinner.hide();
       
        setTimeout(()=>{
          this.testConnDisable = false;
          }, 2700)
      }
      else {        
        //this.alertService.error("Invalid Inputs", this.options); 
        this.spinner.hide();
        throw error;
      }
    })

  }

  updateGitLab(){
    if (!this.validateEmail(this.storyBoard.jiraEmail))
    {
      this.alertService.error("Jira Email not valid", this.options);  
      return;
    }    
    if (this.storyBoard.proURL == undefined || this.storyBoard.toolGr == undefined
      ||this.storyBoard.nameInstance == undefined || this.storyBoard.description == undefined
      ||this.storyBoard.jiraEmail == undefined || this.storyBoard.toolGr == "" || this.storyBoard.toolGr == " " 
      || this.storyBoard.proToken == undefined  || this.storyBoard.jiraEmail == " "|| this.storyBoard.jiraEmail == "" 
      || this.storyBoard.description == " "|| this.storyBoard.description == "" || this.storyBoard.nameInstance == " " || this.storyBoard.nameInstance == ""
      || this.storyBoard.proURL == " " || this.storyBoard.proURL == "" || this.storyBoard.proToken == " "|| this.storyBoard.proToken == "") {

      this.alertService.error("Please enter details In all the fields", this.options); 
    }
    else {

      if(this.storyBoard.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if(this.storyBoard.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
      var updateBody: any;
      this.project_URL = this.storyBoard.proURL;
      this.project_nameInstance = this.storyBoard.nameInstance;
      this.project_description = this.storyBoard.description;
      this.project_jiraEmail = this.storyBoard.jiraEmail;
      this.project_Token = this.storyBoard.proToken;
      this.project_toolGrId = this.storyBoard.toolGr;
            
        // ##### Code for Save Funtionality ########

        updateBody = {
          "tool_id": this.project_toolId,
          "tool_category": "StoryBoard",
          "tool_name": "Jira",
          "tool_group_details": {
              "tool_group_id": this.project_toolGrId,
              "tool_group_name": this.project_toolGroupName
          },
          "tool_instance_details": {
              "storyboard_tool_instance": {
                  "tool_instance_name":this.project_nameInstance,
                  "tool_url": this.project_URL,
                  "tool_description": this.project_description,
                  "tool_auth": {
                      "auth_type": "",
                      "auth_username": "",
                      "auth_password": "",
                      "auth_token": this.project_Token,
                      "auth_mail":  this.project_jiraEmail
                  }
              }
          },
          "updated_by":localStorage.getItem('mail').toLowerCase()
      }  

     
      //console.log(updateBody);
      //debugger;
     this.spinner.show();
      this.apiService.Admin_Jira_UpdateConnData(updateBody).subscribe((res: HttpResponse<any>) => {
        //
  
        if (res.status == 200) //doesnt work
        {
          //console.log(res);         
          // this.alertService.success("Data Saved Successfully", this.options); 
          this.testConnDisable = false;
          this.saveConnDisable = true;
          this.addJiraDiv = false;
          this.editMode = false;
          this.spinner.hide();
          this.refreshJira();
          this.alertService.success(res.body.message, this.options); 

        
        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    
  }
  }
  clearAllSCMDetails() {
    this.storyBoard.proURL = "";
    this.storyBoard.proToken = "";
    this.storyBoard.toolGr = "";
    this.storyBoard.nameInstance = "";
    this.storyBoard.description = "";
    this.storyBoard.jiraEmail = "";
    this.editMode=false;
    this.UrlDisable=false;
    this.TokenDisable=false;
    this.EmailDisable=false;

  }

  CheckDuplication()
  {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    // debugger
    if (!this.validateEmail(this.storyBoard.jiraEmail))
{
  this.alertService.error("Jira Email not valid", this.options);  
  return;
}
    if (this.storyBoard.proURL == undefined || this.storyBoard.toolGr == undefined
      ||this.storyBoard.nameInstance == undefined || this.storyBoard.description == undefined
      ||this.storyBoard.jiraEmail == undefined || this.storyBoard.toolGr == "" || this.storyBoard.toolGr == " " 
      || this.storyBoard.proToken == undefined  || this.storyBoard.jiraEmail == " "|| this.storyBoard.jiraEmail == "" 
      || this.storyBoard.description == " "|| this.storyBoard.description == "" || this.storyBoard.nameInstance == " " || this.storyBoard.nameInstance == ""
      || this.storyBoard.proURL == " " || this.storyBoard.proURL == "" || this.storyBoard.proToken == " "|| this.storyBoard.proToken == "") {
    this.alertService.error("Please enter details In all the fields", this.options);
  }
  if(this.storyBoard.nameInstance.length<3){
    this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
    return;
  }
  if(sp.test(this.storyBoard.nameInstance)){
    
    this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
    return;
  }
  if(this.storyBoard.nameInstance.length>20){
    this.alertService.error("  Please enter maximum 20 characters for saving Tool Name", this.options);
    return;
  }
  if(this.storyBoard.description.length<20){
    this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
    return;
  }
  if(this.storyBoard.description.length>50){
    this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
    return;
  }
  else { 

    
    this.project_nameInstance = this.storyBoard.nameInstance;

    let validateBody ={
      "resource_type":"tool",
      "tool_category": "StoryBoard",
      "tool_name": "Jira",
      "tool_instance_details": {
      "storyboard_tool_instance": {
      "tool_instance_name": this.storyBoard.nameInstance
              }
          }
      }

    this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
     // 
     // console.log("Inside Validate");
     // console.log(res.body);
      if (res.status == 200) {

        let code = res.body.code
        
         switch (code) {
          //  case "01":
          //   this.alertService.error(res.body.msg, this.options);
          //    break;
             case "02":
              this.alertService.error(res.body.message, this.options);
              break;
         
           default:
             this.JiraConnSave();
             break;
         }
  
      }
     

    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      if (error.status == 436) //doesnt work
      {
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("already Exist", this.options);
       
      }
      else {       
      
       // this.alertService.error("Invalid Input", this.options);

        throw error;
      }
    })

  } //else
    
  }


  JiraConnSave() {
    if (!this.validateEmail(this.storyBoard.jiraEmail))
    {
      this.alertService.error("Jira Email not valid", this.options);  
      return;
    }
    
    if (this.storyBoard.proURL == undefined || this.storyBoard.toolGr == undefined || 
      this.storyBoard.toolGr == " " || this.storyBoard.jiraEmail == undefined ||
       this.storyBoard.nameInstance == undefined || this.storyBoard.description == undefined  || 
       this.storyBoard.proURL == ""  || this.storyBoard.toolGr == "" || this.storyBoard.proURL == " "
        || this.storyBoard.proToken == " "  || this.storyBoard.jiraEmail == " " || 
         this.storyBoard.nameInstance == " " ||  this.storyBoard.description == " " || 
         this.storyBoard.proToken == undefined) {

      this.alertService.error("Please enter details In all the fields", this.options); 
    }
    else {
      if(this.storyBoard.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.storyBoard.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if(this.storyBoard.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
    
      var saveBody: any;
      this.project_URL = this.storyBoard.proURL;
      this.project_nameInstance = this.storyBoard.nameInstance;
      this.project_description = this.storyBoard.description;
      this.project_jiraEmail = this.storyBoard.jiraEmail;
      this.project_Token = this.storyBoard.proToken;
      this.project_toolGrId = this.storyBoard.toolGr;
            
        // ##### Code for Save Funtionality ########
        saveBody = {
          "resource_type": "tool",
          "tool_category": "StoryBoard",
          "tool_name": "Jira",
          "tool_group_details": {
          "tool_group_id": this.project_toolGrId,
          "tool_group_name": this.project_toolGroupName
           },
          "tool_instance_details": {
          "storyboard_tool_instance": {
          "tool_instance_name":  this.project_nameInstance,
          "tool_url":  this.project_URL,
          "tool_description":  this.project_description,
          "tool_auth": {
          "auth_type": "",
          "auth_username": "",
          "auth_password": "",
          "auth_token":  this.project_Token,
          "auth_mail":  this.project_jiraEmail
           }
           }
           },
           "created_by":localStorage.getItem('mail').toLowerCase()
          }

     // console.log("saveBody");
      //console.log(saveBody);
      //
     this.spinner.show();
      this.apiService.Admin_ScmSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        //
  
        if (res.status == 200) //doesnt work
        {
         // console.log(res);         
          // this.alertService.success("Data Saved Successfully", this.options); 
          this.testConnDisable = false;
          this.saveConnDisable = true;
          this.addJiraDiv = false;
          this.editMode = false;
          this.spinner.hide();
          this.refreshJira();
          this.alertService.success(res.body.message, this.options); 

        
        }
      },(error: HttpErrorResponse) => {
        //
        this.spinner.hide();
        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          //this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    
  }//else

  }

  openDialog() {    
    const dialogRef = this.dialog.open(AddJiraToolGroupDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"       
    });
  }

 


}
//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./admin-jira.component.scss']
})
export class jiraHomeConfirmationPopUpDialog {
  addJiraDiv: boolean;
  // hideJira: any;

  constructor(
    public dialogRef: MatDialogRef<jiraHomeConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  homePopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      // this.hideJira()

      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./admin-jira.component.scss']
})
export class jiraCancelConfirmationPopUpDialog {
  addJiraDiv: boolean;
  // addJiraDiv: boolean;

  constructor(
    public dialogRef: MatDialogRef<jiraCancelConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService,public router:Router
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      // this.addJiraDiv=false;
      this.hideJira()
      this.router.navigate(['/admin-jira'])
  this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }
  hideJira() {
      this.addJiraDiv=false;
    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'add-jira-tool-group-dialog',
  templateUrl: 'add-jira-tool-group-dialog.html',
  styleUrls: ['./admin-jira.component.scss']
})

export class AddJiraToolGroupDialog {

  
  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,public alertService: AlertService,private spinner: NgxSpinnerService ) {
      
     }

  ngOnInit(): void {


  }

 

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  clearAllSCMDetails() {
    this.model.toolName = "";
    this.model.toolDescription = "";
  }

  

  ToolGrSaveData() {

    // t

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
     
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;

     //       

        // ##### Code for Save Funtionality ########
        saveBody = {
         
          "resource_type":"tool-group",
          "tool_group_description":this.toolGr_description,        
          "tool_group_name": this.toolGr_name, 
          "created_by":localStorage.getItem('mail').toLowerCase()
        }

     // console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
      //  
        //console.log(res.status);

        if (res.status == 200) //doesnt work
        {
         
          var new_ToolGroupId=  res.body.tool_group_id;
          var new_ToolGroupName=  res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Jira", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Jira", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Jira", "true");
            this.alertService.success("Data Saved Successfully",this.options);
  
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-jira']);
          });          
         
         this.dialog.closeAll();
         
        }
      }, (error: HttpErrorResponse) => {
        //
       
        if (error.status == 436||error.status == 430) //doesnt work
        {
         
          this.alertService.error(error.message,this.options);
        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input",this.options);
          throw error;
        }
      })

    }//else

  }


}
