import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections'
import { DatePipe } from '@angular/common';
import { NewEvent } from '../mschannel-creations/create-meeting';

import { AuthService } from 'src/app/service/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ProjectSummaryComponent, } from 'src/app/project-details/project-summary/project-summary.component';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/_alert';
import { ShowCatalogInfoDialog } from '../../project-list-page/project-list-page.component';
import { DialogVMSizeInfo } from '../deployment-store/deployment-store.component';
import { HttpErrorResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-vdi-details',
  templateUrl: './vdi-details.component.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class VdiDetailsComponent implements OnInit {
  projectId;
  poolName;
  projectName;
  rdshName;
  selectedUserArray: any = [];
  vdiInformation: any
  vdiStatus: string = "NotCreated"
  secondFormGroup: FormGroup;
  tableRepoArray: any = [];
  showTable: boolean = false;
  showAddVdi: boolean = true;
  showVDIForm: boolean = false;
  showUserRow: boolean = false;
  manageDisable: boolean = true;
  slideChecked = false;
  slideDisabled = false;

  checkDisable = true;
  checkedBtn: any[]=[];
  isChecked:any[]=[];
  color: ThemePalette = 'primary';

  memberString = []
  apiMemberObjArray = []
  infoVdiArray: any = []
  vdiTableData: any;
  catalog_id: any;
  Role: string = "";
  toolGroup: any = [
    "Catalog 1",
    "Catalog 2",
    "Catalog 3"
  ]
  pooledImage: any = [];   //Shared Vdi 
  personalImage: any = []; //Dedicated Vdi
  selectedVdiType: any;
  vdiImageList: any = [];
  emptyvdiImageList: boolean = false;
  vditype: any = [
    "Pooled",
    "Personal"
  ]
  public parentRef: ProjectSummaryComponent;

  ELEMENT_DATA: vdiTable[];
  displayedColumns: string[] = ['reqId', 'vdi', 'toolStack', 'status', 'members', 'action'];
  //dataSource = new MatTableDataSource<vdiTable>(this.ELEMENT_DATA);


  dtOptions: DataTables.Settings = {};
  // dtTrigger = new Subject();
  dtTrigger: Subject<any> = new Subject<any>();
  catalog_name: string;
  cost: number = 0;
  totalCost: number = 0;
  totalActualCost: number = 0;
  costPerUser: number;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  actualCost: number = 0;
  tool_id: any;
  showImage: any = [];
  // locationVal = [
  //   "central US", "east US", "CentralIndia", "south india", "west india"
  // ]
  locationVal: any;
  //[ "CentralIndia"]
  // VMSizeVal = [
  //     "Standard_D2s_v3", "Standard_D4s_v4", "Standard_D8s_v3", "Standard_A8_v2", "Standard_D16s_v4", "Standard_D16as_v4", "Standard_B16ms", "Standard_F8s_v2", "Standard_F16s_v2"

  // ]
  VMSizeVal: any;
  //["Standard_D2s_v3"]
  diskTypeVal: any;
  //["Premium_LRS"]
  projectKey: string;
  vdi_tool_id: any;
  networkConfigList: any;
  startBtn: boolean = false;
  stopBtn: boolean = false;
  deleteBtn: boolean = false;
  isUserTM: string;
  isUserSM: string;

  refreshDisable: boolean = true;
  minimumEndDate: string;
  model = new NewEvent();
  model2 = new NewEvent();


  filterStatus = 'active';
  diskValues = [];
  subscription_ID: any;
  resourceGroup: any;
  virtual_network: any;
  subnet: any;
  template_id: string;
  project_template: any = [];
  templateRoles: any = [];
  members: any;
  loggedInUserRole: any = [];
  vdi_flag: boolean = false;
  downloadFile: any;
  ImageName: any;
  VdiLocation: any;
  vdiImageListCopy: any;
  idproject: string;
  template_name: any;
  msg: any;
  statusMsg: any;
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox: boolean;
  checkedList: any[];
  itemSelected: boolean;
  VdiConfig_details: any[];
  targets: any[];

  role: any;
  roleTemplate: any;
  viewFlag: boolean=false;
  userMailId: string;
  // isChecked: boolean=false;

  constructor(public dialog: MatDialog, private _router: Router, private _formBuilder: FormBuilder,
    private apiService: AuthService, private spinner: NgxSpinnerService, public alertService: AlertService, public datepipe: DatePipe
  ) { 
    this.masterSelected = false;

  }

  ngOnInit(): void {

    this.dtOptions = {
      order: []
    };
    let newCatalog_id=localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
      if (data.vdi_tools.length == 0) {
        this.vdi_flag = true;
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.alertService.yesClickedEvent
        .subscribe((data: string) => {
          this.showVDIForm = false;
          this.showUserRow = false;
          this.selectedUserArray = [];
          this.secondFormGroup.reset();
        });

      this.alertService.workbenchYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this._router.navigate(['/workBench'])
        });

      this.alertService.homeYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this._router.navigate(['/projectSummary'])
        });

      this.apiService.componentName.next("VDI");

      this.apiService.vdiStatus.subscribe((status: any) => {
        this.vdiStatus = status;
      })

      this.projectId = localStorage.getItem('projectId');


      this.catalog_id = localStorage.getItem('catalogId');

      this.catalog_name = localStorage.getItem('catalogName');
      this.userMailId = localStorage.getItem('mail').toLowerCase();

      this.isUserTM = localStorage.getItem('isUserTM');
      this.isUserSM = localStorage.getItem('isUserSM');
      // console.log(this.isUserSM,"ddddddddddddddddd");
      
      if (this.isUserTM == "false") {
        this.getVdiInfo(this.projectId, 'active');
      }
      else {
        this.getVDIForTM();
      }

    
      this.projectName = localStorage.getItem('projectName');


      this.catalog_id = localStorage.getItem('catalogId');
      this.idproject = localStorage.getItem('projectId');

      this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
  
        // this.template_id = data[0].template_id;
        this.template_name = data[0].template_name;
        
      })  

      this.apiService.getCatalogInformation(this.catalog_id).subscribe((data: any) => {
        this.networkConfigList = data.network_config_tools.filter(x => x.tool_name == "Network-Config-VDI");
        this.project_template = data.project_templates;
        // console.log(this.project_template);
        

        this.template_id = localStorage.getItem("template_id");
        this.project_template.forEach(element => {
          if (element.template_id === this.template_id) {
            this.templateRoles = element.template_roles;
          }
        });
        this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
          this.members = project[0].members;
          let userName = localStorage.getItem('profile');
          this.members.forEach((element) => {
            if (element.user_name == userName) {
              this.loggedInUserRole = element.role_name;
            }
          });
        })
        this.templateRoles.forEach(element => {
          if (element.vdi_type == 'Pooled') {
            let pooledImageDetails = {
              "tool_instance_name": element.role_local_image,
              "template_role_name":element.template_role_name,
              "tool_id": element.vdi_tool_id,
              "tool_image_name": element.azure_image

            }
            this.pooledImage.push(pooledImageDetails)
          }
          else {
            let personalImageDetails = {
              "tool_instance_name": element.role_local_image,
              "template_role_name":element.template_role_name,

              "tool_id": element.vdi_tool_id,
              "tool_image_name": element.azure_image

            }
            this.personalImage.push(personalImageDetails)
          }

        });

      }, (error: HttpErrorResponse) => {
        if (error.status == 437) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Unable to fetch data", this.options);
          throw error;
        }

      })

    


      this.apiService.vdiInformationArray.subscribe(array => {
        this.infoVdiArray = array
      })


      this.apiService.vdiEmail.subscribe(vdiEmail => {
        if (vdiEmail && vdiEmail != "" && vdiEmail != null) {
          if (Object.keys(vdiEmail).length !== 0) {
           // this.dataSource.filter = "";
            this.apiService.vdiEmail.next("");
          }
        }
      })
      this.apiService.vdiEmail.next("");

      this.secondFormGroup = this._formBuilder.group({
        vdi: [null, Validators.required],
        toolStack: [null, Validators.required],
        location: [null, Validators.required],
        VMSize: [null, Validators.required],
        vmDiskType: [null, Validators.required],
        network_config_vdi: [null, Validators.required]
      });
    }
    else {
      this._router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/admin-catalog-config']);
      });
    }
  }
  toggleVDI(){
    
  }
  onToggleChange2($event,i,val) {
    // console.log(val);
    // console.log(localStorage.getItem("mail")
    // );
    // console.log(this.projectId);
    
    
    
    let body={

      "request_id": val.reqId,
  
      "vdi_type":val.vdi,
  
  
      "project_id": this.projectId,
  
      "requester_email": localStorage.getItem("mail"),
  
  
  
  
  }
    // debugger;
    // console.log($event);
    $event.preventDefault();
    if((this.isUserTM == 'true' && val.vdi == 'Shared') || val.status=='Pending' || val.status=='Processing'  || val.status=='Removed'  || val.status=='Failed' || val.status=='Moved_to_Recycle'){
      return
    }
    if((this.isUserSM == 'true' && val.vdi == 'Shared') || val.status=='Pending' || val.status=='Processing'  || val.status=='Removed'  || val.status=='Failed' || val.status=='Moved_to_Recycle'){
      return
    }
    if((this.isUserSM == 'true' && val.vdi == 'Dedicated' &&  val.members[0].email[0]!=this.userMailId) || val.status=='Pending' || val.status=='Processing'  || val.status=='Removed'  || val.status=='Failed' || val.status=='Moved_to_Recycle'){
      return
    }
    // debugger
    

    if(this.isChecked[i]==false){
      const dialogRef = this.dialog.open(ModalComponent, {
        width: '33%',
        height: '77%',
        disableClose: true,
        // id:req_id,
        data:{
          req: this.isChecked[i],
          info:body,
          viewFlag:this.viewFlag,

        }
       
      });
      dialogRef.afterClosed().subscribe(
        data => {
          if (data == 'Yes') {
            this.isChecked[i] = true
            // console.log("yesssssssssssssssssssssssss");
            setTimeout(() => {
              if (this.isUserTM == "false") {
                this.getVdiInfo(this.projectId, this.filterStatus);
              }
              else {
                this.getVDIForTM();
              }
            }, 1500);
            
            // this.startStopConfirmation(req_id,"Delete")
          }
          else{
            this.isChecked[i] = false
            // console.log("Noooooooooooooooooooooooooooooo");
            
          }
          
        }
      );
      }
      else{
        const dialogRef = this.dialog.open(ModalComponent, {
          width: '30%',
          height: '37',
          disableClose: true,
          // id:req_id,
          data:{
            req:this.isChecked[i],
            info:body,
            viewFlag:this.viewFlag,


          }
  
         
        });
  
      dialogRef.afterClosed().subscribe(
        data => {
          if (data == 'Yes') {
            this.isChecked[i]=false
            setTimeout(() => {
              if (this.isUserTM == "false") {
                this.getVdiInfo(this.projectId, this.filterStatus);
              }
              else {
                this.getVDIForTM();
              }
            }, 1500);
            
          }
          else{
            this.isChecked[i]=true
          }
          
        }
      );
      }
   
   
   
   
   
   
    // if (this.isChecked[i]) {
    //   this.dialog
    //     .open(ModalComponent)
    //     .afterClosed()
    //     .subscribe((confirmed) => {
    //       if (confirmed) this.isChecked[0] = false;
    //     });
    // } else {
    //   this.isChecked[i] = true;
    // }
  }


  checkUncheckAll() {
    // console.log(this.VdiConfig_details);
    // console.log(this.checklist);
    

    
    // debugger
    for (var i = 0; i < this.checklist.length; i++) {
      if(
        (this.isUserTM=='true' && this.checklist[i].vdi!='Shared' && (this.checklist[i].status=='Stopped' || this.checklist[i].status=='Running')) 
      || 
      (
        (this.isUserTM=='false' && (this.checklist[i].status=='Stopped' || this.checklist[i].status=='Running') )
        )
      ){
        this.checklist[i].isSelected = this.masterSelected;

      }
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    // debugger
    this.masterSelected = this.checklist.every(function (item: any) {
      if(item.isSelected){
        // console.log(item);
        
      }
      
      return item.isSelected == true;
    })
    // console.log(this.masterSelected);
    
    

    this.getCheckedItemList();
  }
  getCheckedItemList() {
    // debugger
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    // console.log(this.checkedList);
    
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
      // console.log(this.checklist,"wertyui");
      
        this.checkedList.push([this.checklist[i].reqId,this.checklist[i].status,this.checklist[i].name_prefix,this.checklist[i].ip]);

    }
    // console.log(this.checkedList);
    
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   this.targets=[]
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
          //  console.log(this.checkedList,"dfghjk");
           
                  var SelectedItem=this.checkedList[index][0];
                  var SelectedItemStatus=this.checkedList[index][1];
                  var SelectedItemName=this.checkedList[index][2]
                  var SelectedItemIP=this.checkedList[index][3]


                 let VdiConfigBoby={
                    "request_id":SelectedItem,
                    "status":SelectedItemStatus,

                    }

                  let installVdiSoftware={

                     "host":SelectedItemIP,
                    
                    "hostname":SelectedItemName,
                    
                     "username":"canvasadmin",
                    
                     "password":"Canvas@dm!n202o",
                    
                     "os":"windowsVDI"
                    
                    }
                    this.targets.push(installVdiSoftware)
                   this.VdiConfig_details.push(VdiConfigBoby);


                  
                 // For Loop of tag

                // Add image des 
                               
             }
            //  console.log(this.VdiConfig_details);
             if(this.VdiConfig_details.length==0 ){
               this.manageDisable=true
               
             }
             else if(this.VdiConfig_details.length!=0 ){
              this.manageDisable=false
              
            }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }

  onSelectFirstDropdown(vdi) {
    this.secondFormGroup.controls.toolStack.setValue(null)
    this.secondFormGroup.controls.location.setValue(null)
    this.selectedUserArray = [];
    this.apiService.selectedVDIMembers.next([])

    this.locationVal=[]
    this.vdiImageList=[]
    
    this.locationVal=this.VdiLocation
    this.selectedVdiType = vdi;
    if (vdi == 'Pooled') {
      this.vdiImageList = this.pooledImage
      this.vdiImageListCopy=this.pooledImage
      this.emptyvdiImageList = false;
    }
    else if (vdi == 'Personal') {
      this.vdiImageList = this.personalImage
      this.emptyvdiImageList = false;
      this.vdiImageListCopy=this.pooledImage

    }
    if (this.vdiImageList.length == 0) {
      this.emptyvdiImageList = true;
    }
  }

  downloadData(type){    
    if(type=='pdf'){
      this.apiService.downloadVDIInPdf(this.projectId,this.filterStatus).subscribe(data=>{
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName+'-VDI.pdf'
        link.click();
      })
    }
    else if(type=='excel'){
      this.apiService.downloadVDIInExcel(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-VDI.xlsx';
        link.click();        
      })
    }
    else if(type=='csv'){
      this.apiService.downloadVDIInCsv(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-VDI.csv';
        link.click();        
      })
    }

  }

  onSelectSecondDropdown(event) {
    this.selectedUserArray = [];
    this.apiService.selectedVDIMembers.next([])
    this.locationVal=[]
    // console.log(event);
    this.roleTemplate=event.template_role_name
    
    
    // debugger
    this.ImageName=event.tool_image_name
    if(this.ImageName=="LTI-Personal-WVD-Image-v1"){
     // this.locationVal=['eastus']
     this.locationVal=this.locationVal.filter(x=> x.property_value == 'eastus')
    }
    else {
      this.locationVal=this.VdiLocation
    }
    this.showImage = [];
    this.vdi_tool_id = event.tool_id;
    this.role=event.tool_instance_name    
    // this.tool_id = event.target.value
    this.apiService.getTagsInfo(event.tool_id).subscribe(data => {
      this.showImage = data
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }
    })
  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }

  editItem() {
    alert('record edited sucessfully');
  }

  saveItem() {
    alert('record saved sucessfully');
  }

  deleteItem() {
    alert('record deleted sucessfully');
  }

  get vdi() {
    return this.secondFormGroup.get('vdi');
  }

  get toolStack() {
    return this.secondFormGroup.get('toolStack');
  }

  get location() {
    return this.secondFormGroup.get('location');
  }

  get VMSize() {
    return this.secondFormGroup.get('VMSize');
  }
  get vmDiskType() {
    return this.secondFormGroup.get('vmDiskType');
  }
  get network_config_vdi() {
    return this.secondFormGroup.get('network_config_vdi');
  }

  checkToolData() {
    this.apiService.getCatalogInformation(this.catalog_id).subscribe((data: any) => {
      this.networkConfigList = data.network_config_tools.filter(x => x.tool_name == "Network-Config-VDI");
      if(this.networkConfigList.length == 0){
        this.vdi_flag=true;
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
      }
      else {
        this.addVdi();
      }
      });
  }

  addVdi() {
    this.selectedUserArray = [];
    this.apiService.selectedVDIMembers.next([])
    this.showVDIForm = true;
    this.showAddVdi = false;

    let dropdownBody = {
      "dropdown_category": "Azure-VDI",
      "screen_name": "Workbench-VDI"
    }
    this.apiService.getDropdowns(dropdownBody).subscribe((data: any) => {
      //data.forEach(item => {
    //  this.locationVal = data.dropdownLocation;
    //  this.locationVal =['centralindia']
    //   this.VdiLocation=this.locationVal

    //   this.VMSizeVal = data.dropdownVdiSize;
    //   this.diskTypeVal = data.dropdownVdiDiskType;
    //   this.diskValues = [...this.diskTypeVal];
    this.locationVal = data.dropdownLocation;
    //  this.locationVal =['centralindia']
       this.VdiLocation=this.locationVal.filter(x=> x.property_value != 'eastus')

      this.VMSizeVal = data.dropdownVdiSize;
      this.diskTypeVal = data.dropdownVdiDiskType;
      this.diskValues = [...this.diskTypeVal];
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  showVMSizeInfo(data, type) {

    this.apiService.modalType.next(type);

    if (type == 'networkConfig' && data) {
      this.apiService.getToolById(this.network_config_vdi.value?.tool_id).subscribe((response: any) => {
        this.subscription_ID = response[0].tool_instance_details.network_config_tool_instance.azure_subscription_id;
        this.resourceGroup = response[0].tool_instance_details.network_config_tool_instance.resource_group;
        this.virtual_network = response[0].tool_instance_details.network_config_tool_instance.vnet;
        this.subnet = response[0].tool_instance_details.network_config_tool_instance.subnet;

        let networkConfigData = {
          "subscription_id": this.subscription_ID,
          "resource_group": this.resourceGroup,
          "virtual_network": this.virtual_network,
          "subnet": this.subnet
        }
        this.apiService.networkConfigData.next(networkConfigData);
      })
    }

    const dialogRef = this.dialog.open(DialogVMSizeInfo, {
      width: '50%',
      height: '30%',
      disableClose: true,
      data: {
        vmInfo: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getStatus(status) {
    this.filterStatus = status;
    this.getVdiInfo(this.projectId, this.filterStatus)
  }

  toggleDisplay(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.confirmationPopUp();
    } else {
      this.showVDIForm = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.cost = this.costPerUser;
    }

  }

  confirmationPopUp() {

    const dialogRef = this.dialog.open(CancelConfirmationPopUpDialog, {
      width: '30%',
      height: '26%',
      disableClose: true,
      data: {
        image: this.showImage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  addMemberToVdi(data, type) {
    const pooledUser = this.dialog.open(addMemberVdiDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        values: data,
        screen: type
      }
    });

    pooledUser.afterClosed().subscribe(result => {
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 6000);
    })
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '22%',
      height: '13%',
      disableClose: true,

    });
  }

  selectedValue(value) {
    
    if (value.property_value == "Standard_A8_v2") {
      this.diskTypeVal = this.diskValues.filter(el => el.property_value != "Premium_LRS")
    }
    else {
      this.diskTypeVal = this.diskValues;
    }

  }

  openUser() {
    // console.log(this.role);
    
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        projectid: this.projectId ,
        templateid: this.template_id,
        role:this.roleTemplate
      }
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
      this.apiService.selectedVDIMembers.next(this.selectedUserArray);
      if (this.selectedVdiType == 'Pooled') {  //Shared
        this.cost = this.costPerUser;
      }
      else {
        this.cost = this.costPerUser * this.selectedUserArray.length;
      }
    })

  }


  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        member: memberArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  softDeleteVDI(element){
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    let body={
      "project_details": {
      "project_id": this.projectId,        
      "project_name": this.projectName,    
      "catalog_id": this.catalog_id,    
      "catalog_name": this.catalog_name,    
      "template_id": this.template_id,    
      "template_name": this.template_name,
      "env" : environment.scmEnv
  },

  "request_details": {
      "vdi_request_details": [
          {
              "requestor_id": userPsNumber,
              "requestor_name": localStorage.getItem('profile'),
              "request_id": element.reqId,
              "vdi_type":element.vdi,
          }
      ]
  }
}
this.spinner.show()
  this.apiService.softdeleteProject(body).subscribe((data: any)=>{
    this.refresh()
    this.alertService.success(data.message, this.options);
    this.spinner.hide()

  }, (error: HttpErrorResponse) => {
    this.spinner.hide()
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403){}
    else {
    }

  })
  }
  viewSchedularConfirmation(val){
    // console.log(val.custom_scheduler=="OFF");
    
    // console.log(val);
    // debugger
    let body={

      "request_id": val.reqId,
  
      "vdi_type":val.vdi,
  
  
      "project_id": this.projectId,
  
      "requester_email": localStorage.getItem("mail"),
      "start_date":val.start_date_time,
      "stop_date":val.stop_date_time
  
  
  
  
  }
    this.viewFlag=true
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '33%',
        height: '70%',
      disableClose: true,
      id:val,
      data:{
        viewData: body,
        viewFlag:this.viewFlag,
        
        
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          setTimeout(() => {
            if (this.isUserTM == "false") {
              this.getVdiInfo(this.projectId, this.filterStatus);
            }
            else {
              this.getVDIForTM();
            }
          }, 1500);
          
        }
        else{
        }
        
      }
    );
    this.viewFlag=false

  }
  deleteconfirmationPopUp(req_id) {
    // debugger

    const dialogRef = this.dialog.open(vdideleteConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      id:req_id,
      data:{
        req: req_id.reqId
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.softDeleteVDI(req_id);
          // this.startStopConfirmation(req_id,"Delete")
        }
      }
    );
  
  }
 
  vdiApiCall() {
    this.showVDIForm = false;
    this.showAddVdi = true;
    this.showUserRow = false;
    let hostPoolValue = ""
    this.memberString = [];
    let location = this.location.value.property_value
    /*if(this.location.value == 'centralIndia' || this.location.value == 'CentralIndia'){
       location = 'centralIndia'
    }
    else if(this.location.value == 'East US' || this.location.value == 'eastus' || this.location.value == 'EastUS'){
         location = 'eastus'
    }*/

    this.selectedUserArray.forEach(user => {
      let membObj = {
        name: user.user_name,
        email: user.user_email,
        role: user.role_name
      }

      let apiMemberObj = {
        "email": user.user_email
      }

      hostPoolValue += user.user_email + ","
      this.apiMemberObjArray.push(apiMemberObj)
      this.memberString.push(membObj)
    })
    hostPoolValue = hostPoolValue.replace(/,(?=\s*$)/, '');


    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    let vdiType = this.vdi.value;
    //let toolStackValue = this.toolStack.value.tool_instance_name;
    let toolStackValue = this.toolStack.value.tool_id;
    this.actualCost = parseFloat(this.actualCost.toFixed(2))

    if (vdiType == "Personal") {
      let personalBody = {
        "request_type": "VDI",
        "resource_type": "request",
        "requestor_id": userPsNumber,
        "requestor_name": userName,
        "request_status": "Pending",
        "project_details": {
          "project_id": this.projectId,
          "project_name": this.projectName,
          "catalog_id": "1111",
          "catalog_name": "Genius"
        },
        "request_details": {
          "vdi_requests": {
            "vdi_type": "Dedicated",
            "vdi_tool_id": this.vdi_tool_id,
            "network_config_vdi": this.network_config_vdi.value.tool_id,
            "create_dedicated_vdi": {
              "project_id": this.projectId,
              "vdi_type": "Dedicated",
              "vdi_request": {
                "variables": {
                  "assignedUsers": {
                    "value": hostPoolValue
                  },
                  "vmLocation": {
                    "value": location
                  },
                  "vmDiskType": {
                    "value": this.vmDiskType.value.property_value
                  },
                  "project_id": {
                    "value": this.projectId
                  },
                  "hostpoolType": {
                    "value": this.vdi.value
                  },
                  "vmSize": {
                    "value": this.VMSize.value.property_value
                  },
                  "requester_name": {
                    "value": userName
                  }
                }
              }
            }
          }
        },
        "created_by":localStorage.getItem('mail').toLowerCase()
      }
     // console.log(personalBody,this.vmDiskType)
     this.apiService.createVdi(personalBody).subscribe((vdiresult: any) => {
        let tableObj =
        {
          "reqId": vdiresult.request_id,
          "vdi": vdiType,
          "toolStack": toolStackValue,
          "status": "Pending",
          "members": this.memberString,
          "cost": this.costPerUser,
          "actualCost": this.actualCost,
          "action": "Update",
          "name_prefix": "--NA--",
          "hostpool_name": "--NA--",
          "application_group": "--NA--",
          "workspace_name": "--NA--",
        }
       // this.tableRepoArray.push(tableObj)
       this.alertService.success(vdiresult.message,this.options)
        // this.alertService.success('VDI Request Added Successfully !', this.options);
       // this.vdiTableData = this.tableRepoArray;
       this.getVdiInfo(this.projectId, this.filterStatus);
        this.calculateSum();
        this.dtTrigger.next();
        this.secondFormGroup.reset()
        this.showTable = true;
      }, (error: HttpErrorResponse) => {
        if (error.status == 435) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Error creating VDI request.", this.options);
          throw error;
        }

     })
    }
    else if (vdiType == "Pooled") {
      let pooledBody =
      {
        "request_type": "VDI",
        "resource_type": "request",
        "requestor_id": userPsNumber,
        "requestor_name": userName,
        "request_status": "Pending",
        "project_details": {
          "project_id": this.projectId,
          "project_name": this.projectName,
          "catalog_id": "1111",
          "catalog_name": "Genius"
        },
        "request_details": {
          "vdi_requests": {
            "vdi_type": "Shared",
            "vdi_tool_id": this.vdi_tool_id,
            "network_config_vdi": this.network_config_vdi.value.tool_id,
            "create_shared_vdi": {
              "project_id": this.projectId,
              "vdi_type": "Shared",
              "vdi_request": {
                "variables": {
                  "assignedUsers": {
                    "value": hostPoolValue
                  },
                  "vmLocation": {
                    "value": location
                  },
                  "vmDiskType": {
                    "value": this.vmDiskType.value.property_value
                  },
                  "project_id": {
                    "value": this.projectId
                  },
                  "hostpoolType": {
                    "value": "Pooled"
                  },
                  "vmSize": {
                    "value": this.VMSize.value.property_value
                  },
                  "requester_name": {
                    "value": userName
                  }
                }
              }
            }
          }
        },
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      this.apiService.createVdi(pooledBody).subscribe((vdiresult: any) => {
        let tableObj =
        {
          "reqId": vdiresult.request_id,
          "vdi": vdiType,
          "toolStack": toolStackValue,
          "status": "Pending",
          "members": this.memberString,
          "cost": this.costPerUser,
          "actualCost": this.actualCost,
          "action": "Update",
          "name_prefix": "--NA--",
          "hostpool_name": "--NA--",
          "application_group": "--NA--",
          "workspace_name": "--NA--",
        }
        // this.tableRepoArray.push(tableObj)
       this.alertService.success(vdiresult.message,this.options)
       // this.alertService.success('VDI Request Added Successfully !', this.options);
      // this.vdiTableData = this.tableRepoArray;
      this.getVdiInfo(this.projectId, this.filterStatus);
        this.calculateSum();
        this.dtTrigger.next();
        this.secondFormGroup.reset()
        this.showTable = true;
      }, (error: HttpErrorResponse) => {
        if (error.status == 435) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Error creating VDI request.", this.options);
          throw error;
        }

      })
    }

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  showImageInfo(value, dataReceived) {
    // console.log(dataReceived);
    
    this.apiService.modalType.next(value);
    if (value == 'vdiInfo') {
      this.apiService.modalData.next(dataReceived);
    }


    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: value == 'imageInfo' ? '75%' : '50%',
      // height: value == 'dataReceived.status=="Running"' ? '50%' : '30%',

      height: '45%',
      disableClose: true,
      data: {
        image: this.showImage,
        projectID:this.projectId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showVmInfo(value, VMSize) {
    // console.log(value,VMSize);
    
    this.apiService.modalType.next(value);

    let vmInfo = [{
      "VMSize": "Standard_D2s_v3",
      "RAM": 8,
      "vCPUs": 2,
      "Temp_Storage": 32,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D4s_v3",
      "RAM": 16,
      "vCPUs": 4,
      "Temp_Storage": 0,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D8_v3",
      "RAM": 32,
      "vCPUs": 8,
      "Temp_Storage": 32,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D16s_v3",
      "RAM": 16,
      "vCPUs": 8,
      "Temp_Storage": 80,
      "Description": "Entry level workloads (Dev or Test)",
    },
    {
      "VMSize": "Standard_D16s_v4",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 0,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D16as_v4",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_B16ms",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Ideal for workloads that do not need continuous full CPU performance",
    },
    {
      "VMSize": "Standard_F8s_v2",
      "RAM": 64,
      "vCPUs": 8,
      "Temp_Storage": 16,
      "Description": "Compute Optimized(Up to 2x performance for vector processing workloads)",
    },
    {
      "VMSize": "Standard_F16s_v2",
      "RAM": 32,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Compute Optimized(Up to 2x performance for vector processing workloads)",
    }]

    let vmInfoObj;
    vmInfo.forEach(el => {
      if (VMSize == el.VMSize) {
        vmInfoObj = {
          "VMSize": el.VMSize,
          "RAM": el.RAM,
          "vCPUs": el.vCPUs,
          "Temp_Storage": el.Temp_Storage,
          "Description": el.Description,
        }
      }
    })

    const dialogRef = this.dialog.open(DialogVminfoDialog, {
      width: '50%',
      height: '30%',
      disableClose: true,
      data: {
        vm: vmInfoObj
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  startStopConfirmation(reqId, value) {
    const dialogRef = this.dialog.open(startStopConfirmationPopup, {
      width: '35%',
      height: '30%',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes' && value == 'Start') {
          this.startVdi(reqId);
        }
        else if (data == 'Yes' && value == 'Stop') {
          this.stopVdi(reqId);
        }
        else if (data == 'Yes' && value == 'Delete') {
          this.deleteVdi(reqId);
        }
      }
    );
  }
startvdi(){
  // console.log(this.VdiConfig_details);
  let reqBody = {
    "project_id": this.projectId,
    "request_info": this.VdiConfig_details
     
  
  
  
  }
  

  this.apiService.startVdi(reqBody).subscribe((data: any) => {
    // this.VdiConfig_details=[]
    // debugger
    // console.log(data.vdiAddMember,"hello");
    
    this.manageDisable=true
    var message=''
    // console.log(data.vdiAddMember.length);
    
    for(let i=0;i<data.vdiAddMember.length;i++){
      message=message+data.vdiAddMember[i]
      message=message+' '
    }
    // console.log(message);
    
    if(data.message!='All VDIs are in running state only.'){
      this.refreshvdi()
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 3000);
    }
    else{
      this.alertService.success(data.message,this.options)
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 1000);

    }
    if (data.vdiAddMember.length>0){
      this.addMemberPopUp(message)
    }
    //this.refresh()
 

  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403){}
    else {
      this.alertService.error("Error starting VDI.", this.options);
      throw error;
    }

  })
  
}
installSoftware(){
  // console.log(this.targets);
  
  const scmUser = this.dialog.open(softwareDialog, {
    width: '50%',
    height: '72%',
    disableClose: true,
    data:{
      member: this.targets
    }
  });
  scmUser.afterClosed().subscribe(result => {      
    // console.log(result);
    
    setTimeout(() => {
      this.refresh();
    }, 1000);
    this.manageDisable=true
   
});
}
installSoftwareVdi(ip,name,type){
  console.log(type);
  let vdiTargets=[]
  //console.log(os,ip,name);
  let installVdiSoftware={

    "host": ip,
   
   "hostname":name,
   
   "username":"canvasadmin",
                    
   "password":"Canvas@dm!n202o",
   
    "os": 'windowsVDI',
    "vdi_type":type,
   
   }
   vdiTargets.push(installVdiSoftware);
  const scmUser = this.dialog.open(softwareDialog, {
    width: '50%',
    height: '72%',
    disableClose: true,
    data:{
      member: vdiTargets
    }
  });
  scmUser.afterClosed().subscribe(result => {      
    // console.log(result);
    
    setTimeout(() => {
      this.refresh();
    }, 1000);
    // this.manageDisable=true
   
});
}
uninstallSoftwareVdi(ip,name,type){
  // console.log(this.targets);
  let uninstallVdiTargets=[]
  //console.log(os,ip,name);
  let uninstallVdiSoftware={

    "host": ip,
   
   "hostname":name,
   
   "username":"canvasadmin",
                    
   "password":"Canvas@dm!n202o",
   
    "os": 'windowsVDI',
    "vdi_type":type,

   
   }
   uninstallVdiTargets.push(uninstallVdiSoftware);
  const scmUser = this.dialog.open(softwareUninstallDialog, {
    width: '50%',
    height: '65%',
    disableClose: true,
    data:{
      member: uninstallVdiTargets
    }
  });
  scmUser.afterClosed().subscribe(result => {      
    // console.log(result);
    
    setTimeout(() => {
      this.refresh();
    }, 1000);
    // this.manageDisable=true
   
});
}

stopvdi(){
  let reqBody = {
    "project_id": this.projectId,
    "request_info": this.VdiConfig_details

    // "request_id": reqId
  }
  this.apiService.stopVdi(reqBody).subscribe((data: any) => {
    // console.log(this.VdiConfig_details);
    // this.VdiConfig_details=[]
    this.manageDisable=true
    if(data.message!='All VDIs are in Stopped state only.'){
      this.refreshvdi()
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 3000);
    }
    else{
      this.alertService.success(data.message,this.options)
      
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 1000);

    }
    
  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403){}
    else {
      this.alertService.error("Error Stopping VDI.", this.options);
      throw error;
    }

  })



}
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '50%',
      height: '50%',
      disableClose: true,
      data: {
        data: this.vdi.value
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.vdiApiCall();
        }
      }
    );
  }

  workbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(WorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(HomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }

  generatePoolName() {
    var projectName = "LTI-CANVAS-SPRINT"
    var length = 3;
    var possible = "0123456789";

    for (var i = 0; i < length; i++) {
      projectName += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    this.poolName = projectName;
    return this.poolName;
  }

  generateRdshName() {
    var projectName = "CANVAS-"
    var length = 3;
    var possible = "0123456789";

    for (var i = 0; i < length; i++) {
      projectName += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    this.rdshName = projectName;
    return this.rdshName;
  }
  refresh(){
    if (this.isUserTM == "false") {
      this.getVdiInfo(this.projectId, this.filterStatus);
    }
    else {
      this.getVDIForTM();
    }
  }
  refreshvdi(){
    const dialogRef = this.dialog.open(vdiRefreshInformationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      data: {
        // req: reqId,
        screen: 'VDI'
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          // console.log("hello");
         // this.refresh()
          // setTimeout(() => {
          //   this.refresh()
          //   },1000)
    
        }
      }
    );
  }
  addMemberPopUp(message){
    // console.log(message);
    
    const dialogRef = this.dialog.open(addMemberPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      data: {
        // req: reqId,
        msg: message
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          // console.log("hello");
         // this.refresh()
          // setTimeout(() => {
          //   this.refresh()
          //   },1000)
    
        }
      }
    );
  }
  startVdi(reqId) {
    // this.alertService.success('Vdi start process initiated', this.options);
    let reqBody = {
      "project_id": this.projectId,
      "request_id": reqId
    }

    this.apiService.startVdi(reqBody).subscribe((data: any) => {
      // this.refreshvdi(reqId)
      // this.alertService.success(data.message,this.options)
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 3000);
      //this.refresh()
   
  
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Error starting VDI.", this.options);
        throw error;
      }

    })
  }

  stopVdi(reqId) {
    // this.alertService.success('Vdi stop process initiated', this.options);
    let reqBody = {
      "project_id": this.projectId,
      "request_id": reqId
    }
    this.apiService.stopVdi(reqBody).subscribe((data: any) => {
      // this.refreshvdi(reqId)
      // this.alertService.success(data.message,this.options)

      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 1000);
      
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Error Stopping VDI.", this.options);
        throw error;
      }

    })
  }

  deleteVdi(reqId) {
    // this.alertService.success('Vdi delete process initiated', this.options);
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    let reqBody = {
      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName
      },
      "request_details": {
        "requestor_id": userPsNumber,
        "requestor_name": userName,
        "request_id": reqId
      }
    }
    this.apiService.deleteVdi(reqBody).subscribe((data: any) => {
      this.alertService.success(data.message,this.options);
      setTimeout(() => {
        if (this.isUserTM == "false") {
          this.getVdiInfo(this.projectId, this.filterStatus);
        }
        else {
          this.getVDIForTM();
        }
      }, 6000);
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Error deleting VDI.", this.options);
        throw error;
      }

    })
  }

  getVdiInfo(project_id, status) {
    // console.log("hello");
    
    this.masterSelected=false
    this.dtOptions = {
      order: []
    };
    this.spinner.show();
    this.tableRepoArray = []
    this.vdiTableData = []
   // if (data.length > 0) {
      this.apiService.statusUpdate(project_id).subscribe((statusInfo: any) => {

       // this.alertService.success(data.message, this.options);
        this.msg = statusInfo.message
        this.refreshDisable = false;
        setTimeout(() => {
          this.refreshDisable = true;
        }, 2000)

        this.apiService.getVdiOnStatus(this.projectId, status).subscribe((data: any) => {
          // debugger
          let tool_id = "";
          this.vdiInformation = data
    
          // data.forEach(vdi=>{
          var toolInstance = "";
    
          if(data.length==0){
            this.spinner.hide();
          }
          else {
            // this.alertService.success(this.msg, this.options);
            data.forEach((request: any) => {
              this.startBtn = false;
              this.stopBtn = false;
              this.deleteBtn = false;
              toolInstance = request.tool_instance
      
              tool_id = request.vdi_tool_id
              //  request.vdi_user.forEach(user=>{
              let requestStatus = (request.vdi_user.approval).toLowerCase();
      
              if (requestStatus == "pending") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else if (requestStatus == "running") {
                this.startBtn = false;
                this.stopBtn = true;
                this.deleteBtn = true;
              }
              else if (requestStatus == "completed") {
                this.startBtn = false;
                this.stopBtn = true;
                this.deleteBtn = true;
              }
              else if (requestStatus == "deallocated") {
                this.startBtn = true;
                this.stopBtn = false;
                this.deleteBtn = true;
              }
              else if (requestStatus == "deallocating") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else if (requestStatus == "stopped") {
                this.startBtn = true;
                this.stopBtn = false;
                this.deleteBtn = true;
              }
              else if (requestStatus == "stopping") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else if (requestStatus == "deleting") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else if (requestStatus == "deleted") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else if (requestStatus == "starting") {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
              else {
                this.startBtn = false;
                this.stopBtn = false;
                this.deleteBtn = false;
              }
      
      
              let actualCostBody = {
                "filterType": "ResourceID",
                "requestID": request.request_id,
                "granularity": "monthly",
                "project_id": this.projectId
              }
      
              this.actualCost = parseFloat(this.actualCost.toFixed(2))
              let tableObj;
      
              this.memberString = []
              if (request.vdi_user.approval == "Failed" || "Pending" || "Processing") {
                request.vdi_user.user_mail.forEach(element => {
                  let userMail = element;
                  for (let i = 0; i < request.vdi_user.user_name.length; i++) {
                    let userName = request.vdi_user.user_name[0];
                    request.vdi_user.user_name.splice(i, 1);
                    let membObj = {
                      name: userName,
                      email: userMail,
                      role: ""
                    }
                    this.memberString.push(membObj)
                    break;
                  };
                });
                request.vdi_user.user_status?.forEach((element) => { 
                  this.memberString.forEach((el,i) => {
                    if(element.user_status == "Offboarded" && element.user_mail == el.email) {
                      this.memberString.splice(i,1);
                    }
                  });        
                });
              }
              else {
                let membObj = {
                  name: request.vdi_user.user_name,
                  email: request.vdi_user.user_mail,
                  role: ""
                }
                this.memberString.push(membObj)
              }
      
              //   setTimeout(() => {
                var flag=false
                // console.log(request.request_id=='AZVDCNGWR0867-0');
                
              // if(request.request_id=='REQUEST-2380_0'){
              //     flag=true
              // }
              // else{
              //   flag=false
              // }
              
              tableObj =
              {
                "reqId": request.request_id,
                "vdi": request.vdi_type,
                "toolStack": tool_id,
                "status": request.vdi_user.approval.charAt(0).toUpperCase() + request.vdi_user.approval.slice(1),
                "members": this.memberString,
                "cost": this.costPerUser,
                "actualCost": this.actualCost,
                "action": "Update",
                "startStatus": this.startBtn,
                "stopStatus": this.stopBtn,
                "deleteStatus": this.deleteBtn,
                "name_prefix": request.vdi_user.name_prefix,
                "ip":request.vdi_user.ip,
                "hostpool_name": request.vdi_user.hostpool_name,
                "application_group": request.vdi_user.application_group,
                "workspace_name": request.vdi_user.workspace_name,
                "custom_scheduler": request.vdi_user.custom_scheduler,
                "start_date_time": request.vdi_user.start_date_time,
                "stop_date_time": request.vdi_user.stop_date_time

      
              }
              if (data == null || data == undefined || data.length == 0) {
                this.vdiStatus = "NotCreated"
      
              } else {
                this.vdiStatus = "Created"
                this.showTable = true
              }
              this.tableRepoArray.push(tableObj)
              this.checklist = this.tableRepoArray;
              // console.log(this.checklist);
              
              this.vdiTableData = this.tableRepoArray;
              for(let i=0;i<this.vdiTableData.length;i++){
                // this.checkedBtn[i]=this.vdiTableData[i].flag
                if(this.vdiTableData[i].custom_scheduler=="ON"){
                  this.isChecked[i]=true

                }
                else{
                  this.isChecked[i]=false

                }

              }
              // console.log(this.checkedBtn);
              // for (var i = 0; i < this.checklist.length; i++) {
              //   debugger
              //   if(this.checklist[i].status!="Running" || this.checklist[i].status!="Stopped"){
              //     this.checkDisable=true
              //   }
              // }              
              this.dtTrigger.next();
              this.spinner.hide();
              this.calculateSum();
      
      
      
            })
          }
         
    
    
    
        }, (error: HttpErrorResponse) => {
          if (error.status == 446) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("No data available.", this.options);
            throw error;
          }
    
        })
    
      }, (error: HttpErrorResponse) => {
        if (error.status == 446) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })
 //   }
    

  }

  getVDIForTM() {
    this.masterSelected=false
    this.dtOptions = {
      order: []
    };
    this.spinner.show();
    this.tableRepoArray = []
    this.vdiTableData = []
  //  if (data.length > 0) {
      this.apiService.statusUpdate(this.projectId).subscribe((status: any) => {
       // this.alertService.success(data.message, this.options);
       this.statusMsg = status.message
        this.refreshDisable = false;
        setTimeout(() => {
          this.refreshDisable = true;
        }, 2000)

        let userMailId = localStorage.getItem('mail').toLowerCase()
    let body = {
      "project_id": this.projectId,
      "user_mail": userMailId
    }
    this.apiService.getUserVdiInfo(this.projectId, userMailId).subscribe((data: any) => {
      
     

      let tool_id = "";
      this.vdiInformation = data
      var toolInstance = "";

      if(data.length==0){
        this.spinner.hide();
      }
      else {
        // this.alertService.success(this.statusMsg, this.options);
        data.forEach((request: any) => {
          this.startBtn = false;
          this.stopBtn = false;
          this.deleteBtn = false;
          toolInstance = request.tool_instance
  
          tool_id = request.vdi_tool_id
          let requestStatus = (request.vdi_user.approval).toLowerCase();
  
          if (requestStatus == "pending") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else if (requestStatus == "running") {
            this.startBtn = false;
            this.stopBtn = true;
            this.deleteBtn = true;
          }
          else if (requestStatus == "completed") {
            this.startBtn = false;
            this.stopBtn = true;
            this.deleteBtn = true;
          }
          else if (requestStatus == "deallocated") {
            this.startBtn = true;
            this.stopBtn = false;
            this.deleteBtn = true;
          }
          else if (requestStatus == "deallocating") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else if (requestStatus == "stopped") {
            this.startBtn = true;
            this.stopBtn = false;
            this.deleteBtn = true;
          }
          else if (requestStatus == "stopping") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else if (requestStatus == "deleting") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else if (requestStatus == "deleted") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else if (requestStatus == "starting") {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
          else {
            this.startBtn = false;
            this.stopBtn = false;
            this.deleteBtn = false;
          }
  
  
          let actualCostBody = {
            "filterType": "ResourceID",
            "requestID": request.request_id,
            "granularity": "monthly",
            "project_id": this.projectId
          }
          this.actualCost = parseFloat(this.actualCost.toFixed(2))
          let tableObj;
  
          this.memberString = []
          if (request.vdi_user.approval == "Failed" || "Pending" || "Processing") {
            request.vdi_user.user_mail.forEach(element => {
              let userMail = element;
              for (let i = 0; i < request.vdi_user.user_name.length; i++) {
                let userName = request.vdi_user.user_name[0];
                request.vdi_user.user_name.splice(i, 1);
                let membObj = {
                  name: userName,
                  email: userMail,
                  role: ""
                }
                this.memberString.push(membObj)
                break;
              };
            });
          }
          else {
            let membObj = {
              name: request.vdi_user.user_name,
              email: request.vdi_user.user_mail,
              role: ""
            }
            this.memberString.push(membObj)
          }
          tableObj =
          {
            "reqId": request.request_id,
            "vdi": request.vdi_type,
            "toolStack": tool_id,
            "status": request.vdi_user.approval.charAt(0).toUpperCase() + request.vdi_user.approval.slice(1),
            "members": this.memberString,
            "cost": this.costPerUser,
            "actualCost": this.actualCost,
            "action": "Update",
            "startStatus": this.startBtn,
            "stopStatus": this.stopBtn,
            "deleteStatus": this.deleteBtn,
            "name_prefix": request.vdi_user.name_prefix,
            "ip":request.vdi_user.ip,
            "hostpool_name": request.vdi_user.hostpool_name,
            "application_group": request.vdi_user.application_group,
            "workspace_name": request.vdi_user.workspace_name,
            "custom_scheduler": request.vdi_user.custom_scheduler,
            "start_date_time": request.vdi_user.start_date_time,
            "stop_date_time": request.vdi_user.stop_date_time

  
          }
          if (data == null || data == undefined || data.length == 0) {
            this.vdiStatus = "NotCreated"
  
          } else {
            this.vdiStatus = "Created"
            this.showTable = true
          }
          this.tableRepoArray.push(tableObj)
          this.vdiTableData = this.tableRepoArray;
          for(let i=0;i<this.vdiTableData.length;i++){
            // this.checkedBtn[i]=this.vdiTableData[i].flag
            if(this.vdiTableData[i].custom_scheduler=="ON"){
              this.isChecked[i]=true

            }
            else{
              this.isChecked[i]=false

            }

          }
          // console.log(this.vdiTableData,"TM");
          
          this.checklist=this.vdiTableData;
          this.dtTrigger.next();
          this.spinner.hide();
        })
      }
      
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
      }, (error: HttpErrorResponse) => {
        if (error.status == 446) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })
  //  }
    


  }

  calculateSum() {
    this.totalCost = 0;
    this.totalActualCost = 0;
    this.tableRepoArray.forEach(element => {
      this.totalCost += element.cost
    });
    this.totalCost = parseFloat(this.totalCost.toFixed(2))

    this.tableRepoArray.forEach(element => {
      this.totalActualCost += element.actualCost
    });
    //this.actualCost = parseFloat(this.actualCost.toFixed(2))
    this.totalActualCost = parseFloat(this.totalActualCost.toFixed(2))
  }

  getApprovalData(data) {
    if (data == 'Pending' || data == 'Approved') {
      return true

    } else {
      return false
    }
  }
  goWorkBeanch(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.workbenchConfirmationPopup();
    } else {
      this._router.navigate(['/workBench'])
    }
  }
  goHome(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.homeConfirmationPopup();
    } else {
      this._router.navigate(['/projectSummary'])
    }
  }
}

export interface vdiTable {
  vdi: string;
  repoUrl: string;
  toolStack: string;
  status: string;
  members: [];
  action: string;
}
export interface DialogData {
  animal: string;
  name: string;
}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class CancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<CancelConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Slide modal
@Component({
  selector: 'app-modal',
  templateUrl: 'modal.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class ModalComponent implements OnInit{
  slideChecked: boolean;
  minimumEndDate: string;
  minimumEndDate2: string;

  model = new NewEvent();
  model2 = new NewEvent();

  myDate: any;
  myDate2: any;
  save:boolean=false
  viewFlag: boolean=false;
  body: any;
  viewData: any;
  viewID: any;
  req: any;
  startselectedtime = '';
  endselectedtime = '';
  startselectedtime2 = '';
  endselectedtime2 = '';
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  public selectedOptions = 'Select month';

  public selectOptions2 = ['00', '01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];

  constructor(public apiService: AuthService,
    public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,public datepipe: DatePipe,
    public alertService: AlertService,private spinner: NgxSpinnerService
  )
  // constructor(public dialogRef: MatDialogRef<ModalComponent>,public dialog: MatDialog, private _router: Router, private _formBuilder: FormBuilder,
  //   private apiService: AuthService, private spinner: NgxSpinnerService, public alertService: AlertService, public datepipe: DatePipe
  // ) 
  {

    
    
    // debugger
    this.slideChecked=data.req
    this.viewFlag=data.viewFlag
    this.viewData=data.viewData
    // console.log(this.slideChecked,"slidechecked");
    // console.log(this.viewFlag,"viewflag");
    if(this.viewData != null){
      // console.log(this.viewData);
      
      this.model2.start=this.viewData.start_date.split("T")[0]
    
      this.model2.end=this.viewData.stop_date.split("T")[0]
      this.viewID=data.viewData.request_id
      this.startselectedtime2="T"+this.viewData.start_date.split("T")[1].substring(0,5);
      this.endselectedtime2="T"+this.viewData.stop_date.split("T")[1].substring(0,5);

      // console.log(this.viewData.start_date.split("T")[1].substring(0,5));
      // console.log(this.startselectedtime2);
      
      
  
    }

    this.body=data.info
    if (this.body != null){
      this.req=this.body.request_id
    }
// console.log(this.body);

    // console.log(this.slideChecked);
    
  }
  ngOnInit(): void {
    this.selectedOptions=this.selectOptions2[2]
    this.myDate = new Date();
    this.myDate = this.datepipe.transform(this.myDate, 'yyyy-MM-dd');
  }
  getMinimumEndDate() {
    this.minimumEndDate = this.datepipe.transform(this.model.start, 'yyyy-MM-dd');
  }
  onEndTime(ob){

    
    
  }
  onStartTime(ob){


    
  }
  onEndTime2(ob){
    
    
    
  }
  onStartTime2(ob){
    
    
  }
  getMinimumEndDate2() {
    this.minimumEndDate2 = this.datepipe.transform(this.model2.start, 'yyyy-MM-dd');
  }
toggleVDI(){
  this.spinner.show()
  // debugger
  let body={

    "request_id": this.body.request_id,

    "vdi_type":this.body.vdi_type,

    "custom_scheduler": "ON",

    "project_id": this.body.project_id,

    "requester_email":  this.body.requester_email,

    "start_date_time": this.model.start.concat("", this.startselectedtime),

    "stop_date_time": this.model.end.concat("", this.endselectedtime),

    "time_zone": ""

}


this.apiService.toggleVDI(body).subscribe((data: any) => {
  this.spinner.hide()
  this.alertService.success(data.message, this.options);


}, (error: HttpErrorResponse) => {
  if (error.status == 437){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);

   }
   else if (error.status == 403){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
   }
  else {
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
  throw error;
   }
  
   })


}
viewtoggleVDI(){
  // debugger
  this.spinner.show()
  let body={

    "request_id": this.viewData.request_id,

    "vdi_type":this.viewData.vdi_type,

    "custom_scheduler": "ON",

    "project_id": this.viewData.project_id,

    "requester_email":  this.viewData.requester_email,

    "start_date_time": this.model2.start.concat("", this.startselectedtime2),

    "stop_date_time": this.model2.end.concat("", this.endselectedtime2),

    "time_zone": ""

}

this.apiService.toggleVDI(body).subscribe((data: any) => {
  this.spinner.hide()
  this.alertService.success(data.message, this.options);


}, (error: HttpErrorResponse) => {
  if (error.status == 437){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
   }
  else {
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
  throw error;
   }
  
   })
// console.log(body);


}
toggleOffVDI(){
  this.spinner.show()
  let body={

    "request_id": this.body.request_id,

    "vdi_type":this.body.vdi_type,

    "custom_scheduler": "OFF",

    "project_id": this.body.project_id,

    "requester_email":  this.body.requester_email,

    "start_date_time": "",

    "stop_date_time":"",

    "time_zone": ""

}

this.apiService.toggleVDI(body).subscribe((data: any) => {
this.spinner.hide()
this.alertService.success(data.message, this.options);

}, (error: HttpErrorResponse) => {
  if (error.status == 437){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
   }
  else {
    this.spinner.hide()
    this.alertService.error(error.error.message, this.options);
  throw error;
   }
  
   })



}
  selectedOption(option) {
    if (option == 'Yes') {
      this.toggleVDI()
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }
  selectedOption2(option) {
    if (option == 'Yes') {
      this.toggleOffVDI()
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }
  selectedOption3(option) {
    if (option == 'Yes') {
      this.viewtoggleVDI()
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
//Delete confirmation pop up
@Component({
  selector: 'delete-confirmation-popup-dialog',
  templateUrl: 'delete-confirmation-popup.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class vdideleteConfirmationPopUpDialog {
  req_id;
  req: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<vdideleteConfirmationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    this.req=data.req

  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class WorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<WorkbenchConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  workbenchPopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class HomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<HomeConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  homePopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


//Show image popup component
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'image-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class DialogOverviewExampleDialog {
  image: any = [];
  type: any;
  dataReceived: any;
  imageList = [];
  installSoftwareList: any=[];
  imageListInstall: any=[[]];
  projectID: any;


  constructor(
    private apiService: AuthService, public alertService: AlertService,public dialogRef: MatDialogRef<DialogOverviewExampleDialog>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.image = data.image
    this.projectID=data.projectID
    // console.log(data.image);
    

    const n = 3 //number of tables
    this.imageList = [[], [], []]

    const wordsPerLine = Math.ceil(this.image.length / 3)

    for (let line = 0; line < n; line++) {
      for (let i = 0; i < wordsPerLine; i++) {
        const value = this.image[i + line * wordsPerLine]
        if (!value) continue //avoid adding "undefined" values
        this.imageList[line].push(value)
      }
    }


  }

  ngOnInit() {
    this.apiService.modalType.subscribe(data => {
      this.type = data;
    })

    this.apiService.modalData.subscribe(data => {
      this.dataReceived = data;
    })
    // console.log(this.dataReceived);
    
    
    let vdiListBody={
      
    

      "machine_details": 
  
          {
  
            "host": this.dataReceived.ip,

            "username": "canvasadmin",
    
            "password": "Canvas@dm!n202o",
            "os":"windowsVDI",
            "project_id": this.projectID ,
            "vdi_type":this.dataReceived.vdi
  
          }
  
  
    }
    
    this.apiService.uninstallSoftwareListVDI(vdiListBody).subscribe((data: any) => {
      // console.log(data.body);
      // console.log(data.body[5]);
      this.installSoftwareList=data.body
      // this.installSoftwareList.slice(1)
      this.divideData(this.installSoftwareList);

      
   
      
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
        // this.spinner2.hide()
  
      // this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        // this.spinner2.hide()
  
      // this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

  }
  divideData(imageData){
    const n = 3 //number of tables
    this.imageListInstall = [[], [], []] 
    const wordsPerLine = Math.ceil(imageData.length / 3)
    for (let line = 0; line < n; line++) {
      for (let i = 0; i < wordsPerLine; i++) {
        const value = imageData[i + line * wordsPerLine]
        if (!value) continue //avoid adding "undefined" values
        this.imageListInstall[line].push(value)
      }
    }
    //console.log(this.imageList);
    
    
  }
  onNoClick(): void {
    this.dialogRef.close();
    this.dataReceived = [];
  }

}

//Project info pop up
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'project-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class DialogProjectInfoDialog {
  catalog_id;
  catalog_name;
  costPerUser;
  cost;
  projectName;
  singleCatalogData: any;
  projectCreatedBy: any;
  selectedTemplateId:any;
  selectedTemplateName:any;
  idproject:any;
  template_id:any;
  template_name:any;



  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  modalData: any;

  constructor(
    public dialogRef: MatDialogRef<DialogProjectInfoDialog>, public alertService: AlertService, private apiService: AuthService, public dialog: MatDialog
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // console.log("details_page");
    
    this.idproject = localStorage.getItem('projectId');
    // console.log(this.idproject);
    
    let userPsNumber = "";
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
      // console.log(data,"template");
      

      this.template_id = data[0].template_id;
      this.template_name = data[0].template_name;
      this.catalog_id=data[0].catalog_id;
      this.catalog_name = data[0].catalog_name;
      this.projectName = data[0].project_name;

      for(let i=0;i<data[0].members.length;i++){
        if(userPsNumber==data[0].members[i].user_id){
          var roleNameComp=""

          let roleName=data[0].members[i].role_name[0]

          /////////
          for (let j = 0; j < roleName.length; j++) {
        
            if(roleName[j].charCodeAt(0)!=160
            ){
              roleNameComp=roleNameComp+roleName[j]
        
            }
            
            
        
        
          }
         
          
        
          // str = str.replace(/ +/g, "");
          roleName=roleNameComp.replace(/ +/g, "");


          ////////
          // console.log(roleName=="ProjectManager");
          
          if(roleName=="ProjectManager"){
            this.projectCreatedBy = data[0].members[i].user_name
          }

        }
      }
      
    })  

    this.catalog_id = localStorage.getItem('catalogId');

    this.catalog_name = localStorage.getItem('catalogName');


    this.projectCreatedBy = localStorage.getItem('projectCreatedBy');
    
    this.selectedTemplateId = this.template_id
  
    
    this.selectedTemplateName = this.template_name



    this.apiService.getVdiCost().subscribe((costValue: any) => {
      this.costPerUser = costValue;
      this.cost = this.costPerUser;
    })


    this.projectName = localStorage.getItem('projectName');

    this.apiService.getCatalogInformation(this.catalog_id).subscribe(data => {
      this.singleCatalogData = data;
    }, (error: HttpErrorResponse) => {
      if (error.status == 437) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Unable to fetch data", this.options);
        throw error;
      }

    })
  }

  showCatalogInfo(type) {
    if(type=="subscription"){
      this.modalData = this.singleCatalogData
    }
    else{
      this.modalData = ""
    }
    
      this.apiService.singleCatalogInfo.next(this.singleCatalogData);
      this.apiService.selectedTemplateId.next(this.template_id);
      
      this.apiService.modalTypeInProjectList.next(type);
        const dialogRef = this.dialog.open(ShowCatalogInfoDialog, {
          width: '73%',
          height: 'auto',
          disableClose: true,
          data:{ data : this.modalData}
        });
  
        dialogRef.afterClosed().subscribe(result => {
        });

  }

  exit() {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-confirmation-popup',
  templateUrl: 'confirmation-popup.html',
  styleUrls: ['./vdi-details.component.scss']
})

export class DialogConfirmationPopup {
  response: String = "";
  vdiType: String;
  showMsg: String;
  disabledAgreement: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmationPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.vdiType = data.data;
    }
    if (this.vdiType == "Pooled") {
      this.showMsg = "You have selected Pooled VDI. Do you want to continue?"
    }
    else {
      this.showMsg = "You have selected Personal VDI. Do you want to continue?"
    }
  }

  onInit() {

  }

  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }



  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//VDI refresh information pop up
@Component({
  selector: 'refresh-information-popup',
  templateUrl: 'refresh-information.html',
  styleUrls: ['./vdi-details.component.scss']
})

export class vdiRefreshInformationPopUpDialog {
  req_id;
  reqID: any;
  screen: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<vdiRefreshInformationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    // this.reqID=data.req;
    this.screen = data.screen;
  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
// add member popup
@Component({
  selector: 'addMember-information-popup',
  templateUrl: 'addMember-information.html',
  styleUrls: ['./vdi-details.component.scss']
})

export class addMemberPopUpDialog {
  req_id;
  reqID: any;
  msg: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<addMemberPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    // this.reqID=data.req;
    this.msg = data.msg;
    // console.log(data);
    // console.log(this.msg);
    
    
  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}



//


@Component({
  selector: 'dialog-confirmation-popup',
  templateUrl: 'start-stop-confirmation.html',
  styleUrls: ['./vdi-details.component.scss']
})

export class startStopConfirmationPopup {
  response: String = "";
  vdiType: String;
  showMsg: String;
  disabledAgreement: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<startStopConfirmationPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.vdiType = data.data;
    }
    if (this.vdiType == "Pooled") {
      this.showMsg = "You have selected Pooled VDI. Do you want to continue?"
    }
    else {
      this.showMsg = "You have selected Personal VDI. Do you want to continue?"
    }
  }

  onInit() {

  }

  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }

  changeCheck(event) {
    this.disabledAgreement = !event.checked;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Show VM info popup component
@Component({
  selector: 'dialog-vm-info-dialog',
  templateUrl: 'vm-info.html',
  styleUrls: ['./vdi-details.component.scss']
})
export class DialogVminfoDialog {
  vm: any = [];
  type: any;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogVminfoDialog>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.vm = data ? data.vm : []


  }
  ngOnInit() {

    this.apiService.modalType.subscribe(data => {
      this.type = data;
    })
  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}
export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  checked: boolean
}
//add memebr to existing Pooled VDI
const ELEMENT_DATA: MemberDetails[]=[];
@Component({
  selector: 'add-member-vdi-dialog',
  templateUrl: 'add-to-existing-vdi.html',
  styleUrls: ['./vdi-details.component.scss']
})

export class addMemberVdiDialog {
  projectId: any;
  projectName: any;
  members: any = [];
  ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  memberList: any = [];
  newMembersAdd: any = [];
  finalArray: any = [];

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dummyMemberArray: any = [];
  tempBody: any;
  rowValue: any;
  catalog_id: any;
  catalog_name: any;
  memberString: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  type: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   const numRows = this.dataSource.data.length;
    return numSelected === numRows;
   // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
     this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, public router: Router, public dialogRef: MatDialogRef<addMemberVdiDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private _snackBar: MatSnackBar) {
    this.memberList = data.values.members
    this.rowValue = data.values;
    this.type = data.screen
  }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');
    this.projectName = localStorage.getItem('projectName');
    this.getUser();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  allSelected() {
    this.isallSelected = true
  }

  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;

      this.members.forEach((element, index) => {
        for (var i = 0; i < this.memberList.length; i++) {
          if (this.memberList[i].user_email == element.user_email) {
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
          }
        }

      });

      this.members.forEach((object: any) => {
        if (!object.duplicate) {
          this.finalArray.push(object)
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }
      })


      if (this.type === "add") {
        this.memberList.forEach(member => {
          for (let i = 0; i < this.finalArray.length; i++) {
            if (member.name == this.finalArray[i].user_name) {
              this.finalArray.splice(i, 1);
            }
            else {
            }
          }
        });
      }
      let members = []
      if (this.type === "delete") {

        this.memberList.forEach(user => {
          let object = {
            "user_name": user.name,
            "user_email": user.email,
          }
          members.push(object)
        })
        this.finalArray = members
      }

      this.dataSource.data = this.finalArray as MemberDetails[]
    })
  }

  addUserForVDI() {
    if (this.selection.selected.length == 0) {
      this._snackBar.open("Select User", "", {
        duration: 3000,
      })
    }
    else {
      if (this.type === "add") {
        let memberArray = [];
        this.selection.selected.forEach(user => {
          memberArray.push(user.user_email)
        })

        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })

          let vdiBody = {
            "project_details": {
              "project_id": this.projectId,
              "project_name": this.projectName,
            },
            "request_details": {
              "requestor_id": userPsNumber,
              "requestor_name": userName,
              "request_id": this.rowValue.reqId,
              "members": memberArray
            }
          }

        this.apiService.addMemberinExisingVDI(vdiBody).subscribe((result: any) => {

          this.alertService.success(result.message, this.options);

        }, (error: HttpErrorResponse) => {
          if (error.status == 435) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("Error While Adding Member", this.options);
            throw error;
          }

        });
        this.dialogRef.close()
      }
      else {
        let memberArray = [];
        this.selection.selected.forEach(user => {
          memberArray.push(user.user_email)
        })

        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
        let deleteBody = {
          "project_details": {
            "project_id": this.projectId,
            "project_name": this.projectName,
          },
          "request_details": {
            "requestor_id": userPsNumber,
            "requestor_name": userName,
            "request_id": this.rowValue.reqId,
            "members": memberArray
          }
        }
        
        this.apiService.deleteMemberFromExisingVDI(deleteBody).subscribe((result: any) => {

          this.alertService.success(result.message, this.options);

        }, (error: HttpErrorResponse) => {
          if (error.status == 435) {
            this.alertService.success("User offboarded successfully.", this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("Error While Adding Member", this.options);
            throw error;
          }

        });
        this.dialogRef.close()
      }


    }

  }


  exit() {
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}


// install software dialog

export interface SoftwareDetails {
  icon: string;
  software_name: string;
  software_version: string;
  ritm: string;
  checked: boolean
}

const ELEMENT_DATAA: SoftwareDetails[]=[
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},



];
@Component({
  selector: 'install-software-dialog',
  templateUrl: 'install-software.html',
  styleUrls: ['./vdi-details.component.scss']
}) 
export class softwareDialog {
  // ELEMENT_DATA: softwareDialog[];
  dataSource = new MatTableDataSource<SoftwareDetails>(ELEMENT_DATAA);
  displayedColumns: string[] = ['icon', 'software_name', 'software_version','software_ritm','select'];
  selection = new SelectionModel<SoftwareDetails>(true, []);
  isallSelected: boolean = false;
  installPopup:boolean=true
  requestPopup:boolean=false

  public userForm: FormGroup;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  softwareList: any=[];
  softwareArray: any=[];
  targets: any=[];
  requestFormNameErrorFlag: boolean=false;
  requestFormVersionErrorFlag: boolean=false;
  requestFormRitmErrorFlag:boolean=false;
  getList: boolean=false;









      /** Whether the number of selected elements matches the total number of rows. */
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SoftwareDetails): string {
      
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.software_version + 1}`;
    }
  constructor(private _fb: FormBuilder,public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.targets = data.member
    this.userForm = this._fb.group({
      software_request: this._fb.array([this.addAddressGroup()])
    });
  }
  
  ngOnInit(): void {
    this.getList=false
    let body={

      "machine_details": {
  
          "host": this.targets[0].host,
  
          "username": "canvasadmin",
  
          "password": "Canvas@dm!n202o"
  
      },
  
      "os": "windowsVDI",
  
      "task": "install",
      "vdi_type":this.targets[0].vdi_type,
      "project_id":localStorage.getItem('projectId'),

  
  }
    
    
    this.apiService.installSoftwareListVDI(body).subscribe((data: any) => {
      this.getList=true
      // this.softwareList.push(data)
      // debugger
      // console.log(data);
      var i=1

      for (var prop in data.body) {
        // console.log("Key:" + prop);
        // console.log("Value:" + data[prop]);
        let body=  {icon: i, software_name: prop, software_version: data.body[prop], ritm: '',checked:false}
        i+=1
        this.softwareArray.push(body)
      }
      this.dataSource.data = this.softwareArray as SoftwareDetails[]

      
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

}
  //Append Fields Set
  private addAddressGroup(): FormGroup {
    return this._fb.group({
      software_name: [],
      software_version: [],
      ritm:[]
      
    });
  }
  //Add Fields
  addAddress(): void {
    // debugger
    this.requestFormNameErrorFlag=false
    this.requestFormVersionErrorFlag=false
    this.requestFormRitmErrorFlag=false

    let len=this.userForm.value.software_request.length
    if(this.userForm.value.software_request[len-1].software_name == null ||
      this.userForm.value.software_request[len-1].software_name == ''){
        this.requestFormNameErrorFlag=true
        // return

    }
    if(this.userForm.value.software_request[len-1].software_version == null ||
      this.userForm.value.software_request[len-1].software_version == ''){
        this.requestFormVersionErrorFlag=true
        // return

    }
    if(this.userForm.value.software_request[len-1].ritm == null ||
      this.userForm.value.software_request[len-1].ritm == ''){
        this.requestFormRitmErrorFlag=true
        // return

    }
    
    if(this.requestFormVersionErrorFlag || this.requestFormNameErrorFlag || this.requestFormRitmErrorFlag){
      

      return
    }
   
    
    // console.log(this.addressArray.length);
    
    this.addressArray.push(this.addAddressGroup());
  }
 

  //Remove Fields
  removeAddress(index: number): void {
    this.addressArray.removeAt(index);
  }
  //Fields Array
  get addressArray(): FormArray {
    return <FormArray>this.userForm.get('software_request');
  }
allSelected() {
  this.isallSelected = true
}
public doFilter = (value: string) => {
  this.dataSource.filter = value.trim().toLocaleLowerCase();
}
exit() {
  this.dialogRef.close();
}
softwareDetails(){
  // console.log(this.targets);
  
  let softwarefinalArray = [];
  this.selection.selected.forEach(obj=>{
    // console.log(obj);
    // softwarefinalArray.push(obj)
    
    // debugger
   let body={

     "software":obj.software_name,
    
    "version":obj.software_version,
    
    "ritm":obj.ritm,
   }
   softwarefinalArray.push(body)
  })
  // console.log(this.softwareArray);
  

  let finalBody={
    "task":"install",
    "targets":this.targets,
    
     "softwares":softwarefinalArray,
     "vdi_type":this.targets[0].vdi_type,
     "project_id":localStorage.getItem('projectId'),
    
    }
    // console.log(finalBody);
    

    this.apiService.postInstallSoftware(finalBody).subscribe((data: any) => {
      this.dialogRef.close();
      // console.log(data);
      

      this.alertService.success(data.body.message, this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
    this.dialogRef.close()
}
requestDetails(){
  this.installPopup=false
  this.requestPopup=true
}
addRequestSoftware(){
  let body={
    "machine_details":this.targets[0],
    "software_request":this.userForm.value.software_request
  }
  this.apiService.postRequestSoftware(body).subscribe((data: any) => {
    this.dialogRef.close();
    // console.log(data);
    

    this.alertService.success(data.body.message, this.options);

  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403) { }
    else {
      this.alertService.error("No data available.", this.options);
      throw error;
    }

  })
  this.dialogRef.close()
}

}


// uninstall software dialog

export interface SoftwareDetailsUninstall {
  // icon: string;
  software_name: string;
  software_version: string;
  // software_ritm: string;
  checked: boolean
}

const ELEMENT_DATA_UNINSTALL: SoftwareDetailsUninstall[]=[
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},



];
@Component({
  selector: 'uninstall-software-dialog',
  templateUrl: 'uninstall-software.html',
  styleUrls: ['./vdi-details.component.scss']
}) 
export class softwareUninstallDialog {
  // ELEMENT_DATA: softwareDialog[];
  dataSource = new MatTableDataSource<SoftwareDetailsUninstall>(ELEMENT_DATA_UNINSTALL);
  displayedColumns: string[] = ['icon','software_name','software_version','select'];
  selection = new SelectionModel<SoftwareDetailsUninstall>(true, []);
  isallSelected: boolean = false;
  installPopup:boolean=true
  requestPopup:boolean=false

  public userForm: FormGroup;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  softwareList: any=[];
  softwareArray: any=[];
  targets: any=[];
  requestFormNameErrorFlag: boolean=false;
  requestFormVersionErrorFlag: boolean=false;
  requestFormRitmErrorFlag:boolean=false;
  uninstallFlag:boolean=false;
  uninstallFlagList:boolean=false;







      /** Whether the number of selected elements matches the total number of rows. */
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SoftwareDetailsUninstall): string {
      
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.software_name + 1}`;
    }
  constructor(private spinner2: NgxSpinnerService,private _fb: FormBuilder,public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.targets = data.member
    this.userForm = this._fb.group({
      software_request: this._fb.array([this.addAddressGroup()])
    });
  }
  
  ngOnInit(): void {
    this.uninstallFlag=false;
    this.uninstallFlagList=false;
    // console.log(this.targets);
    // this.spinner2.show()
    
    let vdiBody={
 

      "machine_details": 
  
          {
  
              "host": this.targets[0].host,
  
              "username": this.targets[0].username,
  
              "password": this.targets[0].password
  
          },
          "os": "windowsVDI",
    
          "task": "uninstall",
          "vdi_type":this.targets[0].vdi_type,
          "project_id":localStorage.getItem('projectId'),
  
  }

    this.apiService.installSoftwareListVDI(vdiBody).subscribe((data: any) => {
      // this.uninstallFlag=true
      // debugger
      // this.spinner2.hide()
      if(data.body.length==0){
        this.uninstallFlagList=true
        
      }
      this.uninstallFlag=true
      
      // this.softwareList.push(data.body)
      // console.log(data.body[0][5]);
      
      // debugger
      // console.log(this.softwareList);
      /////////////////////////////////////////////
      var i=1

      for (var prop in data.body) {
        // console.log("Key:" + prop);
        // console.log("Value:" + data[prop]);
        let body=  {icon: i, software_name: prop,software_version: data.body[prop],checked:false}
        i+=1
        this.softwareList.push(body)
      }
      this.dataSource.data = this.softwareList as SoftwareDetailsUninstall[]

      //////////////////////////////////////////////
      // var i=0

      // for (let i = 0; i < this.softwareList[0].length; i++) {
      //   // console.log("Key:" + prop);
      //   // console.log("Value:" + data[prop]);
        
      //   let body=  {icon: i+1, software_name: this.softwareList[0][i],checked:false}
      //   if (this.softwareList[0][i].length>0){
      //     this.softwareArray.push(body)

      //   }
      //   i+=1

      // }
      // this.dataSource.data = this.softwareArray as SoftwareDetailsUninstall[]

      
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
        // this.spinner2.hide()

      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        // this.spinner2.hide()

      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })


}
  //Append Fields Set
  private addAddressGroup(): FormGroup {
    return this._fb.group({
      software_name: [],
      software_version: [],
      software_ritm:[]
      
    });
  }

allSelected() {
  this.isallSelected = true
}
public doFilter = (value: string) => {
  this.dataSource.filter = value.trim().toLocaleLowerCase();
}
exit() {
  this.dialogRef.close();
}
softwareDetails(){
  // console.log(this.targets);
  
  let softwarefinalArray = [];
  this.selection.selected.forEach(obj=>{
    // console.log(obj);
    // softwarefinalArray.push(obj)
    
    // debugger
   let body={

     "software":obj.software_name,
    
     "version":obj.software_version,

    // "ritm":obj.software_ritm,
   }
   softwarefinalArray.push(body)
  })
  // console.log(this.softwareArray);
  

  let finalBody={
    "task":"uninstall",
    "targets":this.targets,
    
     "softwares":softwarefinalArray,
     "vdi_type":this.targets[0].vdi_type,
     "project_id":localStorage.getItem('projectId'),
    
    }
    // console.log(finalBody);
    

    this.apiService.postInstallSoftware(finalBody).subscribe((data: any) => {
      this.dialogRef.close();
      // console.log(data);
      

      this.alertService.success(data.body.message, this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
    this.dialogRef.close()
}


}
