<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/home">Home</a></li>
            <li class="breadcrumb-item  link-blue"><a routerLink="/projectSummary">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Cost & Resource Report</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">

      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/projectSummary" class="float-right text-light">
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>

  </div>

  <div class="inner-mid-container">

    <div class="row">
      <div class="col-md-10">
        <div class="row">
          <div class="col-sm-6">
            <h1 class="mb-0">
              <img width="30" src="../../../assets/img/Group 3610.png">
              <div class="vertical-line mx-3"> </div> <span>Cost & Resource Report</span>
            </h1>
          </div>
          <div class="col-md-6"><span class="field-name">Project ID </span><span class="field-value"> :
              {{projectId}}</span>
            <span style="font-size: 18px;" class="material-icons show-project-info" title="System Information"
              (click)="showProjectInfoModal()">info</span>

          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid contentent-area">
      <div class="reports-card reports-body">
        <div id="exTab2">
          <ul class="nav nav-tabs">

            <li class="nav-item ">
              <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#cost" role="tab"
                aria-controls="profile" aria-selected="false">Cost</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="home-tab" data-toggle="tab" (click)="summaryViewData()" href="#resource"
                role="tab" aria-controls="home" aria-selected="false">Resource Usage</a>
            </li>
            <li
            [ngClass]="VisFlag ? 'disabled' : 'nav-item'"
            
            >
              <a class="nav-link" id="graph-tab" data-toggle="tab"  href="#graph" 
                role="tab" aria-controls="graph" aria-selected="false"  (click)="dailyCost()" >Visualization</a>
            </li>
          </ul>

          <div class="tab-content ">
            <div class="tab-pane" id="resource">
              <div class="tab-pane" id="usage">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link active" href="#countView" data-toggle="tab" (click)="summaryViewData()"
                      aria-selected="true">Summary View</a>
                  </li>
                  <li><a class="nav-link" href="#detailsView" data-toggle="tab" (click)="detailViewData()">Detail
                      View</a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div class="tab-pane active" id="countView">
                    <div class="row">
                      <div class="col-sm-12 summary-view">
                        <table style="width:100%">
                          <tr>
                            <th colspan="4">Canvas Provisioned Infra & Services</th>
                          </tr>
                          <tr>
                            <th>Items</th>
                            <th>Active</th>
                            <th>Total</th>
                            <th>Types</th>
                          </tr>
                          <tbody>


                            <tr *ngFor="let data of summaryData">
                              <td *ngIf="data.type=='Azure VDI' || data.type=='VDI'">VDI</td>
                              <td *ngIf="data.type=='Azure VM' || data.type=='VM'">VM</td>
                              <td *ngIf="data.type=='Aws'">AWS</td>
                              <td *ngIf="data.type=='Gitlab'">SCM Repositories</td>
                              <td *ngIf="data.type=='Jira'">Story Board Projects</td>
                              <td *ngIf="data.type=='Jenkins'">Pipelines</td>
                              <td *ngIf="data.type=='Webex' || data.type=='WebEx'">Collaboration Channels</td>
                              <td *ngIf="data.type=='Teammember' || data.type=='Teams'">Collaboration Channels</td>
                              <td>{{data.active || 0}}</td>
                              <td>{{data.total || 0}}</td>
                              <td>{{data.type}}</td>
                            </tr>
                            <!-- <tr>
                                    <td>VM</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>Azure VMS</td>
                                  </tr>
                                 
                                  <tr>
                                    <td>SCM Repositories</td>
                                    <td>12</td>
                                    <td>12</td>
                                    <td>Gitlab</td>
                                  </tr>
                                  <tr>
                                    <td>Story Board Projects</td>
                                    <td>3</td>
                                    <td>3</td>
                                    <td>Jira</td>
                                  </tr>
                                   <tr>
                                    <td>Application Servers</td>
                                    <td>2</td>
                                    <td>2</td>
                                    <td>AWS EC2</td>
                                  </tr> 
                                  <tr>
                                    <td>Pipelines</td>
                                    <td>3</td>
                                    <td>5</td>
                                    <td>Jenkins</td>
                                  </tr>
                                  <tr>
                                    <td>Collaboration Channels</td>
                                    <td>7</td>
                                    <td>7</td>
                                    <td>WebEx Teams</td>
                                  </tr>
                                  <tr>
                                    <td>Users/Team Members</td>
                                    <td>16</td>
                                    <td>25</td>
                                    <td>NA</td>
                                  </tr> -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane " id="detailsView">
                    <div class="details-view">

                      <div class="table-responsive scrollbar"  *ngIf="detailsData?.length>0">
                        <h3><b>VDI Details</b></h3>
                        <table datatable [dtOptions]="dtOptions" class="table fix-table">
                          <!--fixed table-->
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Operating System</th>
                              <th>Resource Group</th>
                              <th>Virtual Network</th>
                              <th>Subnet</th>
                              <th>Private IP</th>
                              <th class="text-center">More</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of detailsData">
                              <td>
                                {{data.Name || "-"}}
                              </td>
                              <td> {{data.OperatingSystem || "-"}} </td>
                              <td> {{data.ResorceGroup || "-"}} </td>
                              <td> {{data.VirtualNetwork || "-"}} </td>
                              <td>{{data.Subnet || "-"}}</td>
                              <td> {{data.PrivateIP || "-"}} </td>
                              <td class="text-center"><a (click)="showProjectInfo(data,'resource')" class="action"> <i
                                    class="fas fa-ellipsis-h"></i></a></td>
                            </tr>
                          </tbody>
                        </table>
                        <br><br>
                        <hr>
                      </div>


                      <div class="table-responsive" *ngIf="VmDetailsData?.length>0">
                        <h3><b>VM Details</b></h3>
                        <table datatable [dtOptions]="dtOptions" class="table fix-table">
                          <!--fixed table-->
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Operating System</th>
                              <th>Resource Group</th>
                              <th>Virtual Network</th>
                              <th>Subnet</th>
                              <th>Private IP</th>
                              <th class="text-center">More</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of VmDetailsData">
                              <td>
                                {{data.Name || "-"}}
                              </td>
                              <td> {{data.OperatingSystem || "-"}} </td>
                              <td> {{data.ResorceGroup || "-"}} </td>
                              <td> {{data.VirtualNetwork || "-"}} </td>
                              <td>{{data.Subnet || "-"}}</td>
                              <td> {{data.PrivateIP || "-"}} </td>
                              <td class="text-center"><a (click)="showProjectInfo(data,'resource')" class="action"> <i
                                    class="fas fa-ellipsis-h"></i></a></td>
                            </tr>
                          </tbody>
                        </table>
                        <br><br>
                        <hr>
                      </div>

                      <div class="table-responsive" *ngIf="scmDetailsData?.length>0">
                        <h3><b>Gitlab Details</b></h3>
                        <table datatable [dtOptions]="dtOptions" class="table fix-table">
                          <thead>
                            <tr>
                              <th>Group Name</th>
                              <th>Repo ID</th>
                              <th>Repo Name</th>
                              <th>Tool Instance</th>
                              <th>Status</th>
                              <th class="w-15"> Gitlab URL</th>
                              <!-- <th class="text-center">More</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of scmDetailsData">
                              <td>
                                {{data.gitlab_group_name || "-"}}
                              </td>
                              <td> {{data.gitlab_repo_id || "-"}} </td>
                              <td> {{data.gitlab_repo_name || "-"}} </td>
                              <td> {{data.gitlab_tool_instance || "-"}} </td>
                              <td>{{data.status || "-"}}</td>
                              <td>{{data.gitlab_repo_url || "-"}}</td>
                              <!-- <td class="text-center"><a (click)="showGitLabInfo(data,'resource')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td> -->
                            </tr>
                          </tbody>
                        </table>
                        <br><br>
                        <hr>
                      </div>


                      <div class="table-responsive" *ngIf="jiraDetailsData?.length>0">
                        <h3><b>Jira Details</b></h3>
                        <table datatable [dtOptions]="dtOptions" class="table fix-table">
                          <thead>
                            <tr>
                              <th>Jira Project Name</th>
                              <th>Jira Project Key</th>
                              <th>Tool Instance</th>
                              <th>Status</th>
                              <!-- <th class="text-center">More</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of jiraDetailsData">
                              <td>
                                {{data.jira_project_name || "-"}}
                              </td>
                              <td> {{data.jira_project_key || "-"}} </td>
                              <td> {{data.tool_instance || "-"}} </td>
                              <td>{{data.status || "-"}}</td>

                              <!-- <td class="text-center"><a (click)="showJiraInfo(data,'resource')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td> -->
                            </tr>
                          </tbody>
                        </table>
                        <br><br>
                        <hr>
                      </div>
                      <div class="table-responsive" *ngIf="jenkinsDetailsData?.length>0">
                        <h3><b>Jenkins Details</b></h3>
                        <table datatable [dtOptions]="dtOptions" class="table fix-table">
                          <thead>
                            <tr>
                              <th>Jenkins Project Name</th>
                              <th>Tool Instance</th>
                              <th>Status</th>
                              <!-- <th class="text-center">More</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let data of jenkinsDetailsData">
                              <td>
                                {{data.jenkins_project_name || "-"}}
                              </td>
                              <td> {{data.tool_instance || "-"}} </td>
                              <td>{{data.request_status || "-"}}</td>

                              <!-- <td class="text-center"><a (click)="showJenkinsInfo(data,'resource')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td> -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
            <div class="tab-pane active" id="cost">
              <ul class="nav nav-tabs">
                <!-- <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#jira" role="tab"
                    aria-controls="home" (click)="getJiraData()" aria-selected="true">Jira</a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#azure" role="tab"
                    aria-controls="profile" (click)="getAzure()" aria-selected="false">Azure</a>
                </li>

              </ul>

              <div class="tab-content">
                <!-- VDI Securities Computers -->
                <div class="tab-pane" id="jira">



                  <div class="row mt-2">
                    <!-- <div class="col-sm-4">
                            
                                   </div> -->


                    <div class="col-6">
                      <label class="mr-2">Month</label>
                      <select [(ngModel)]="selectedOption2" name="gender2">
                        <option *ngFor="let option of selectOptions2" [value]="option">
                          {{option}}
                        </option>
                      </select>
                      <label class="ml-3 mr-2">Year</label>
                      <select [(ngModel)]="selectedOption" name="gender">
                        <option *ngFor="let option of selectOptions" [value]="option">
                          {{option}}
                        </option>
                      </select>

                      <label class="ml-3 mr-2">Currency</label>
                      <select [(ngModel)]="selectedCurrency" name="currency">
                        <option *ngFor="let option of currencyOptions" [value]="option">
                          {{option}}
                        </option>
                      </select>

                      <button type="button" class="btn-xs tool-button ml-3 mr-3"
                        (click)="onToolSelectedJira(selectedOption2,selectedOption,selectedCurrency)">
                        <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                          title="Search">search</mat-icon>
                      </button>

                    </div>
                    <div class="col-md-6 col-12 pl-5 pb-2" style="float: right">
                      <div class="dropdown" style="float:right;" *ngIf="JiraArray.length>0">
                        <!-- <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                          <i class="fas fa-download "></i> Export
                        </a> -->
                        <div class="dropdown-menu more-drop-down">

                          <a class="dropdown-item">
                            <span class="color-black" (click)="downloadJiraData('pdf')">
                              <i width="20" class="fas fa-file-pdf color-blue"></i>
                              <span class="vertical-line-more mx-2"></span> PDF</span>
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item">
                            <span class="color-black" (click)="downloadJiraData('excel')">
                              <i width="20" class="fas fa-file-excel color-blue"></i>
                              <span class="vertical-line-more mx-2"></span> EXCEL</span>
                          </a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item">
                            <span class="color-black" (click)="downloadJiraData('csv')">
                              <i width="20" class="fas fa-file-csv color-blue"></i>
                              <span class="vertical-line-more mx-2"></span> CSV</span>
                          </a>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table datatable [dtOptions]="dtOptions" class="table" *ngIf="JiraArray.length>0">
                      <!--fixed-table-->
                      <thead class="details-view">
                        <tr>
                          <th>Count</th>
                          <th>Currency</th>
                          <th>Unit Cost</th>
                          <th>Total Cost</th>
                          <th>Members</th>

                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr> -->
                        <!-- <td>{{JiraCount}}</td>
                        <td>{{JiraCurrency}}</td>
                        <td>{{JiraUnitCost}}</td>
                        <td>{{JiraTotalCost}}</td>
                        <td *ngIf="JiraMembers?.length>0">
                            <button class="view-member-btn" (click)="openAddedUserDialog(JiraMembers)">View</button>
                          </td>
                          <td *ngIf="JiraMembers?.length==0">
                              --NA--
                            </td> -->

                        <tr *ngFor="let data of JiraArray">
                          <td>{{data.count}}</td>
                          <td>{{data.currency}}</td>
                          <td>{{data.unitcost}}</td>
                          <td>{{data.totalcost}}</td>
                          <td *ngIf="data?.members.length>0">
                            <button class="view-member-btn" (click)="openAddedUserDialog(data.members)">View</button>
                          </td>
                          <td *ngIf="data?.members.length==0">
                            --NA--
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="JiraArray.length==0">
                      <!--fixed-table-->
                      <thead class="details-view">
                        <tr>
                          <th>Count</th>
                          <th>Currency</th>
                          <th>Unit Cost</th>
                          <th>Total Cost</th>
                          <th class="w15">Members</th>

                        </tr>
                      </thead>

                    </table>
                  </div>

                </div>
                <!-- VDI Securities Audit log -->
                <div class="tab-pane active" id="azure">

                  <div>

                    <div class="row mt-2">
                      <!-- <div class="col-sm-4">
                            
                                   </div> -->


                      <div class="col-5">
                        <label class="mr-2">Month</label>
                        <select [(ngModel)]="selectedOption2" name="gender2">
                          <!-- <option value="A">Select year</option><br> -->
                          <option *ngFor="let option of selectOptions2" [value]="option">
                            {{option}}
                          </option>
                        </select>
                        <label class="ml-3 mr-2">Year</label>
                        <select [(ngModel)]="selectedOption" name="gender">
                          <!-- <option value="A">Select year</option><br> -->
                          <option *ngFor="let option of selectOptions" [value]="option">
                            {{option}}
                          </option>
                        </select>

                        <!-- <label class="ml-3 mr-2">Currency</label>
                        <select [(ngModel)]="selectedCurrencyAzure" name="currency">
                          <option *ngFor="let option of currencyOptions" [value]="option">
                            {{option}}
                          </option>
                        </select> -->
                        <!-- <span class="table-crud table-delete-icon ml-3" title="Filter data" (click)="onToolSelected(selectedOption2,selectedOption)">
      
                                      <img width="25" src="../../../assets/img/searchcost2.png"></span> -->
                        <button type="button" class="btn-xs tool-button ml-3 mr-3"
                          (click)="onToolSelected(selectedOption2,selectedOption)">
                          <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                            title="Search">search</mat-icon>
                        </button>

                      </div>
                      <div class="col">
                        <!-- <h2 class="heading headStyle"><b>Project Tool's Cost</b></h2> -->

                      </div>

                      <!-- <div class="col" *ngIf='ShowToolTableView && flag'>
                                <li>VDI Count: {{vdiCount}}</li>
                                <li>VM Count : {{vmCount}}</li>
                             </div> -->
                      <div class="col">
                        <span class="float-right table-bar-icons" [ngClass]="{'icon-bg': ShowToolTableView}"
                          (click)=showTableView()>
                          <i class="fa fa-table fa-lg mt-2" aria-hidden="true"></i>
                        </span>

                      </div>
                      <div class="div">
                        <span class="float-right table-bar-icons ml-2 mt-2" [ngClass]="{'icon-bg': showToolBarChart}"
                          (click)=showBarChart()>
                          <i class="far fa-chart-bar fa-lg"></i>
                        </span>
                      </div>

                      <div class="col-md-2 col-12 pl-5 pb-2" style="float: right">
                        <div class="dropdown" style="float:right;">
                          <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown"
                            *ngIf="!exportFlag">
                            <i class="fas fa-download "></i> Export
                          </a>
                          <div class="dropdown-menu more-drop-down">

                            <a class="dropdown-item">
                              <span class="color-black"
                                (click)="downloadAzureData('pdf',selectedOption2,selectedOption)">
                                <i width="20" class="fas fa-file-pdf color-blue"></i>
                                <span class="vertical-line-more mx-2"></span> PDF</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item">
                              <span class="color-black"
                                (click)="downloadAzureData('excel',selectedOption2,selectedOption)">
                                <i width="20" class="fas fa-file-excel color-blue"></i>
                                <span class="vertical-line-more mx-2"></span> EXCEL</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item">
                              <span class="color-black"
                                (click)="downloadAzureData('csv',selectedOption2,selectedOption)">
                                <i width="20" class="fas fa-file-csv color-blue"></i>
                                <span class="vertical-line-more mx-2"></span> CSV</span>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>

                    <!-- <div class="col-md-2 col-12 pl-5 pb-2" style="float: right">
                                <div class="dropdown" style="float:right;" *ngIf="AzureArray.length>0">
                                  <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                                    <i class="fas fa-download "></i> Export
                                  </a>
                                  <div class="dropdown-menu more-drop-down">
                                   
                                    <a class="dropdown-item">
                                        <span class="color-black" (click) = "downloadAzureData('pdf')">
                                          <i width="20" class="fas fa-file-pdf color-blue"></i>
                                          <span class="vertical-line-more mx-2"></span> PDF</span>
                                      </a>
                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item">
                                        <span class="color-black" (click) = "downloadAzureData('excel')">
                                          <i width="20" class="fas fa-file-excel color-blue"></i>
                                          <span class="vertical-line-more mx-2"></span> EXCEL</span>
                                      </a>
                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item">
                                        <span class="color-black" (click) = "downloadAzureData('csv')">
                                          <i width="20" class="fas fa-file-csv color-blue"></i>
                                          <span class="vertical-line-more mx-2"></span> CSV</span>
                                      </a>
                                  </div>
                                </div>
                              </div> -->
                    <div *ngIf="showToolBarChart">
                      <!-- <div class="responsive">
                                    <canvas baseChart height="420"
                                    [colors]="toolBarChartColors"
                                    [datasets]="toolBarChartData"
                                    [labels]="toolBarChartLabels"
                                    [options]="toolBarChartOptions"
                                    [plugins]="toolBarChatPlugins"
                                    [legend]="toolBarChartLegend"
                                    [chartType]="toolBarChartType">
                                    </canvas>
                                  </div> -->
                      <hr size="8" width="100%" color="#F5F5F5">
                      <div class="row">
                        <div class="col-3"></div>

                        <div class="col-10">


                          <canvas baseChart height="19vh" width="70vw" [data]="pieChartData2" [labels]="pieChartLabels2"
                            [chartType]="pieChartType2" [colors]="pieChartColor" [options]="options2"
                            [legend]="pieChartLegend2">
                          </canvas>
                        </div>
                      </div>
                    </div>





                    <div class="tab-pane">
                      <div class="details-view">
                        <div class="table-responsive">

                          <table datatable [dtOptions]="dtOptions2" class="table" *ngIf="ShowToolTableView">
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>Currency</th>
                                <th>Disks</th>
                                <th>Network</th>
                                <th>Virtual Machines</th>
                                <th>VDI Count</th>
                                <th>VM Count</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{label}}</td>
                                <td>{{currency }}</td>

                                <td>{{diskCost}}</td>
                                <td>{{networkCost}}</td>
                                <td>{{vmCost}}</td>

                                <td [ngClass]="vdiCount>0 ? 'url' : ''" (click)="vdiCount>0 ? showVdiDetails() : ''">
                                  {{vdiCount }}</td>
                                <td>{{vmCount }}</td>
                                <td *ngIf="currency=='INR'"><b>₹ {{Total}}</b></td>
                                <td *ngIf="currency!='INR'"><b>$ {{Total}}</b></td>


                              </tr>
                            </tbody>
                          </table>
                          <!-- <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="!ShowToolTableView || !showToolBarChart">   fixed-table -->
                          <!-- <thead>
                                  <tr>
                                      <th>Month</th>
                                      <th>Virtual Machines</th>
                                      <th>Disks</th>
                                      <th>Network</th>
                                      <th>Total</th>
                                   
                                  </tr>
                                </thead>
                               
                              </table> -->

                        </div>

                        <div *ngIf="showDetails" class="overflow-container">
                          <div class="row">
                            <h3 class="col-md-11">VDI Details for {{requestID}}</h3>
                            <span (click)="hideFlags('chart')"
                              class="material-icons close-icon close-btn col-md-1">close</span>


                          </div>



                          <google-chart [data]="timelineChartData"></google-chart>

                        </div>
                        <!-- <h3 class="noData" *ngIf="chartData?.length==0">
                          {{noData}}
                        </h3> -->
                        <!-- Modal -->
                        <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog"
                          aria-labelledby="confirmationModal" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content pop-up">
                              <div class="modal-header-popup">
                                <span class="vertical-line"> </span>
                                <span class="header mb-0">Note</span>
                                <span data-dismiss="modal" class="material-icons close-icon" (click)="hideFlags('chart')">
                                  close
                                </span>
                              </div>
                              <hr class="margin-class" />
                              <div class="modal-body">
                                The requested resource was deleted 30 days prior to the current date.
                                <br>
                                The usage report will be available only within 30 days of resource deletion
                                <!-- {{Message}} -->
                              </div>
                              <div class="modal-footer">
                                <!-- <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">No</button> -->
                                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                                  (click)="hideFlags('chart')">OK</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Modal2 -->
                        <div class="modal fade" id="confirmationModal2" tabindex="-1" role="dialog"
                          aria-labelledby="confirmationModal2" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content pop-up">
                              <div class="modal-header-popup">
                                <span class="vertical-line"> </span>
                                <span class="header mb-0">Note</span>
                                <span data-dismiss="modal" class="material-icons close-icon">
                                  close
                                </span>
                              </div>
                              <hr class="margin-class" />
                              <div class="modal-body">
                                The cost data will be updated after 24 hours of resource provisioning
                                <!-- {{Message}} -->
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">OK</button>
                                <!-- <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                                  (click)="hideFlags('chart')">OK</button> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <span *ngIf="chartData?.length==0" (click)="hideFlags('chart')" class="material-icons close-icon close-btn col-md-1">close</span> -->
                        <div *ngIf="showTable">
                          <div class="table-responsive scrollbar">
                            <div class="row">
                              <h2 class="col-md-11" *ngIf="personalVdiTableData?.length>0">Personal</h2>
                              <span *ngIf="personalVdiTableData?.length>0" (click)="hideFlags('activeVDI')"
                                class="material-icons close-icon close-btn col-md-1">
                                close
                              </span>
                            </div>

                            <table datatable class="table fixed-table" [dtOptions]="dtOptionsPooled"
                              *ngIf="personalVdiTableData?.length>0">
                              <thead>
                                <tr>
                                  <th>Request Id</th>
                                  <th>VDI Type</th>
                                  <th>VM Name</th>
                                  <th>Status</th>
                                  <th>Members</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let val of personalVdiTableData">
                                  <td>{{val.reqId}}
                                    <span style="font-size: 18px;" class="material-icons add-user"
                                      title="System Information" (click)="showVDIInfo(val)">info</span>
                                  </td>

                                  <td>{{val.vdi == 'Shared' ? 'Pooled' : 'Personal'}}</td>
                                  <td>{{val.name_prefix ? val.name_prefix : '--NA--'}}</td>
                                  <td>{{val.status}}</td>
                                  <td>
                                    <h4
                                      *ngIf="val.status=='Removed' || val.status=='Pending' || val.status=='Deleted' || val.status=='Failed'">
                                      --NA--
                                    </h4>
                                    <h4
                                      *ngIf="val.members.length==0 && (val.status=='Running' || val.status=='Processing')">
                                      --NA--
                                    </h4>
                                    <button
                                      *ngIf="val.members.length >1 && (val.status!='Removed' && val.status!='Deleted' && val.status!='Failed' && val.status!='Pending')"
                                      class="view-member-btn" (click)="openUserDialog(val.members)">View</button>
                                    <div class="" *ngIf="val.members.length == 1">
                                      <span class="img__wrap1 userRowOne" *ngFor="let user of val.members">
                                        <h4
                                          *ngIf="val.status=='Running' || val.status=='Processing' || val.status=='Stopped' || val.status == 'Stopping' || val.status == 'Starting' || val.status=='Moved_to_Recycle'">
                                          {{user.name}}</h4>
                                      </span>

                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="row">
                              <h2 class="col-md-11" *ngIf="pooledVdiTableData?.length>0">Pooled</h2>
                              <span *ngIf="pooledVdiTableData?.length>0" (click)="hideFlags('activeVDI')"
                                class="material-icons close-icon close-btn col-md-1">
                                close
                              </span>
                            </div>

                            <table datatable class="table fixed-table" [dtOptions]="dtOptionsPersonal"
                              *ngIf="pooledVdiTableData?.length>0">
                              <thead>
                                <tr>
                                  <th>Request Id</th>
                                  <th>VDI Type</th>
                                  <th>VM Name</th>
                                  <th>Status</th>
                                  <th>Members</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let val of pooledVdiTableData">
                                  <td>{{val.reqId}}
                                    <span style="font-size: 18px;" class="material-icons add-user"
                                      title="System Information" (click)="showVDIInfo(val)">info</span>
                                  </td>

                                  <td>{{val.vdi == 'Shared' ? 'Pooled' : 'Personal'}}</td>
                                  <td>{{val.name_prefix ? val.name_prefix : '--NA--'}}</td>
                                  <td>{{val.status}}</td>
                                  <td>
                                    <h4
                                      *ngIf="val.status=='Removed' || val.status=='Pending' || val.status=='Deleted' || val.status=='Failed'">
                                      --NA--
                                    </h4>
                                    <h4
                                      *ngIf="val.members.length==0 && (val.status=='Running' || val.status=='Processing')">
                                      --NA--
                                    </h4>
                                    <button
                                      *ngIf="val.members.length >1 && (val.status!='Removed' && val.status!='Deleted' && val.status!='Failed' && val.status!='Pending')"
                                      class="view-member-btn" (click)="openUserDialog(val.members)">View</button>
                                    <div class="" *ngIf="val.members.length == 1">
                                      <span class="img__wrap1 userRowOne" *ngFor="let user of val.members">
                                        <h4
                                          *ngIf="val.status=='Running' || val.status=='Processing' || val.status=='Stopped' || val.status == 'Stopping' || val.status == 'Starting' || val.status=='Moved_to_Recycle'">
                                          {{user.name}}</h4>
                                      </span>

                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>

            <div class="tab-pane" id="graph">
              <div class="tab-pane" id="graph-usage">



                <ul class="nav nav-tabs">
                  <li class="nav-item"  >
                    <a class="nav-link active" href="#dailyCostView" data-toggle="tab" 
                      aria-selected="true" (click)="dailyCost()" >Address Usage</a>
                  </li>
              
                  <li><a class="nav-link" href="#monthlyCostView" data-toggle="tab">Monthly Cost View</a>
                  </li>
                  <li><a class="nav-link" href="#resourceCostView" data-toggle="tab">Resource Cost View</a>
                  </li>
                </ul>


                <div class="tab-content">
                  <div class="tab-pane active" id="dailyCostView" *ngIf="flaggraph" >
                    <!-- <h1>Daily</h1> -->

                    <div *ngFor="let user of headLabel; let i = index" class="mt-2 row serviceCost" >
                      <div  class="col-7 mt-4"   style="display: block; width: 700px; height: 350px;">
                        <!-- <h1 style="text-align: center;font-weight: 500;">{{user}}</h1> -->
                        <canvas baseChart 
                        chartType="bar"
                        [datasets]="datasets[i]"
                        [labels]="labels[i]"
                        [options]="chartinfi.options"
                        legend="true" *ngIf="flaggraph" >
                    </canvas>
                    <div class="mt-4" style="margin-bottom:100px ;"></div>

                      </div>
                      <div class="col-5">
                        <h2 style="margin-top: 32%;font-weight: 600;">{{user}}</h2>

                      </div >

                    </div >


                  </div>

                  <div class="tab-pane" id="monthlyCostView">
                    <div class="row mt-3">
                      <div class="col-4 monthlyStyle" >
                        <label class="mr-2">Month</label>
                        <select [(ngModel)]="graphMonth" name="gender3">
                          <option *ngFor="let option of graphMonthArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
                        <label class="ml-5 mr-2">Year</label>
                        <select [(ngModel)]="graphYear" name="gender2">
                          <option *ngFor="let option of graphYearArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
  
  
  
                        <button type="button" class="btn-xs tool-button ml-3 mr-3"
                          (click)="onToolSelectedGraph(graphMonth,graphYear)">
                          <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                            title="Search">search</mat-icon>
                        </button>
  
                      </div>
                    </div>

                    <div class="row" *ngIf="monthlyFlag">
                      <div class="col-12 serviceCost">
                        <h1><u>Project Azure Product-wise Cost  : ₹ {{serviceMonthlyCost}}</u></h1>
                        <canvas height="25vh" width="70vw" baseChart [data]="serViceCostData" [labels]="serViceCostDataLabels"
                        [chartType]="serviceType"  [options]="serviceOption"
                        [legend]="serviceLegend">
                      </canvas>
                      </div>
                      <div class="col-12 serviceCostSecond" >
                        <h2 style="font-weight: 600;margin-top: 2%; text-align: center;"><u>VM/VDI Cost </u></h2>

                        <canvas baseChart height="80"
                        [datasets]="barChartData2"
                        [labels]="barChartLabels2"
                        [options]="barChartOptions2"
                        [plugins]="barChartPlugins2"
                        [legend]="barChartLegend2"
                        [chartType]="barChartType2">
                      </canvas>

                      </div>
                      <div class="col-12" style="margin-top: 2rem;">
                        <div class="row col-4 monthlyStyle">
                          <div class="col-8 mr-2">
                            <form class="example-form">
                              <mat-form-field class="example-full-width">
                                <mat-label>Machine Name</mat-label>
                                <input type="text"
                                       placeholder="Select"
                                       aria-label="Machine Name"
                                       matInput
                                       [formControl]="myControl"
                                       [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete">
    
                                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option.toUpperCase()" > 
                                    {{option | uppercase}}
                     
                                  </mat-option>
                                
                                </mat-autocomplete>
     
                              </mat-form-field>
                            </form>

                  
                          </div>
                          
                          <div class="col-2 ml-5 mt-2">
                            <button type="button" class="btn-xs tool-button ml-3 mr-3"
                            (click)="test(graphMonth,graphYear)">
                            <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                              title="Search">search</mat-icon>
                          </button>     
                          </div>

                      </div>

                        <div class="row serviceCost" *ngIf="machineDailyCostFlag">
                          <div class="col-12">
                            <h2 style="font-weight: 600;margin-top: 2%; text-align: center;margin-bottom: 4%;"><u>{{machineDataValue | uppercase}} Product Wise Cost : {{pieMachineMonth}} {{pieMachineYear}}</u></h2>

                            <canvas baseChart height="20vh" width="70vw"  [data]="monthlyMachineData" [labels]="monthlyMachineLabel"
                            [chartType]="pieChartTypeGraph" [colors]="pieChartColor" [options]="options3"
                            [legend]="pieChartLegend2">
                          </canvas>
                          <h2 style="font-weight: 600;margin-top: 5%; text-align: center;margin-bottom: 5%;"><u>Total Cost :  ₹ {{pieMachineMonthTotal}}</u></h2>

                        

                      </div>
                      </div>

                      <div class="row serviceCost" *ngIf="machineDailyCostFlag" >
                        <div class="col-6 " *ngIf="machineDailyCostFlag"  >
                          <canvas baseChart width="600" height="500"
                          [datasets]="lineChartData"
                          [labels]="lineChartLabels"
                          [options]="lineChartOptions"
                          [colors]="lineChartColors"
                          [legend]="lineChartLegend"
                          [chartType]="lineChartType"
                          [plugins]="lineChartPlugins">
                        </canvas>
                        </div>
                        <div class="col-6"  *ngIf="machineDailyCostFlag">
                          <div class="row" *ngIf="machineDailyCostFlag">
                            <div class="col-4">
                              <form class="example-form">
                                <mat-form-field class="example-full-width">
                                  <mat-label>MM/DD/YYYY</mat-label>
                                  <input type="text"
                                         placeholder="Select"
                                         aria-label="Daily Cost"
                                         matInput
                                         [formControl]="myControlDaily"
                                         [matAutocomplete]="auto">
                                  <mat-autocomplete #auto="matAutocomplete">
      
                                    <mat-option *ngFor="let option of filteredOptionsDaily | async" [value]="option" > 
                                      {{option }}
                       
                                    </mat-option>
                                  
                                  </mat-autocomplete>
       
                                </mat-form-field>
                              </form>
  
                    
                            </div>
                            
                            <div class="col-6">
                              <button type="button" class="btn-xs tool-button ml-3 mr-3"
                              (click)="test2()">
                              <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                                title="Search">search</mat-icon>
                            </button>     
                            </div>
  
                        </div>
                        <div class="row" *ngIf="DateWiseFlagPie && machineDailyCostFlag ">
                          <!-- height="19vh" width="70vw"  -->
                          <canvas baseChart [data]="pieChartDataDaily" [labels]="pieChartLabelsDaily"
                          [chartType]="pieChartTypeDaily" [colors]="pieChartColorsDaily"   [options]="optionsDaily"
                          [legend]="pieChartLegend2">
                        </canvas>


                        </div>

                        </div>
                      </div>

                      </div>













                      <div class="col serviceCostSecond" style="height:80vh;overflow: scroll;">
                        <h2 style="font-weight: 600;margin-top: 2%;text-align: center;">Workplace Other Cost</h2>
                        
                        <canvas baseChart height="80vh"
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions"
                        [plugins]="barChartPlugins"
                        [legend]="barChartLegend"
                        [chartType]="barChartType">
                      </canvas>
                      </div>

                    </div>


                  </div>

                  <div class="tab-pane" id="resourceCostView" >
                    <div  class="Inputparent">
                      <!-- <h1 class="Inputchild">Input</h1> -->
                      <div class="Inputchild">
                        <label class="mr-2">Resource Group Name</label>
                        <select [(ngModel)]="resourceGroupName" name="gender2">
                          <option *ngFor="let option of resourceGroupArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
                        <label class="ml-3 mr-2">Cost Type</label>
                        <select [(ngModel)]="resourceCostType" name="gender">
                          <option *ngFor="let option of resourceCostTypeArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
                        <label class="ml-3 mr-2">Cost Duration</label>
                        <select [(ngModel)]="resourceCostDuration" name="gender">
                          <option *ngFor="let option of resourceCostDurationArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
                        <label class="ml-3 mr-2">Currency</label>
                        <select [(ngModel)]="resourceCostCurrency" name="gender">
                          <option *ngFor="let option of resourceCostCurrencyArray" [value]="option">
                            {{option}}
                          </option>
                        </select>
                      </div>
                      <div class="Inputchild" *ngIf="resourceCostDuration=='Custom'" >
                        <mat-form-field appearance="fill">
                          <mat-label>Enter a date range</mat-label>
                          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                            <input matStartDate formControlName="start" placeholder="Start date">
                            <input matEndDate formControlName="end" placeholder="End date">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-date-range-picker #picker>
                            <mat-date-range-picker-actions>
                              <button mat-button matDateRangePickerCancel>Cancel</button>
                              <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                          </mat-date-range-picker>
                        
                          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                        </mat-form-field>
                        <!-- <p>Selected range: {{range.value | json}}</p> -->

                      </div>
                      <div class="Inputchild">
                        <button [disabled]="(resourceGroupName=='Select' || (resourceCostDuration=='Custom' && (this.range.value.start==null || this.range.value.end==null) ))" type="button" class="btn-xs tool-button ml-3 mr-3"
                        (click)="resourceGroupCostFunc(resourceGroupName,resourceCostType,resourceCostDuration,this.range.value,resourceCostCurrency)">
                        <mat-icon class="refresh-icon" aria-hidden="false" aria-label="Example home icon"
                          title="Search">search</mat-icon>
                      </button>
                      </div>
                    
                    
                    
                    
                    
                    </div>
                    <!-- <p>{{resourceGroupName}} {{range.value.start==null}}</p> -->
                    <div  class="graphParent" *ngIf="resourceGraphFlag">
                      
                        <div class="graphChild">

                          <canvas baseChart height="80vh"
                          [datasets]="resBarChartData"
                          [labels]="resBarChartLabels"
                          [options]="resBarChartOptions"
                          [plugins]="resBarChartPlugins"
                          [legend]="resBarChartLegend"
                          [chartType]="resBarChartType">
                        </canvas>
                        </div>
                      </div>

                  </div>

                  

                </div>







              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <!--  -->
  </div>
</div>
<button data-toggle="modal" hidden="true" class="B-none" id="CallModel1" data-target="#confirmationModal"></button>

<button data-toggle="modal" hidden="true" class="B-none" id="CallModel2" data-target="#confirmationModal2"></button>