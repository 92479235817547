<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">-->
<div class="inner-page-container">
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page"> Framework</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span><i class="fas fa-plus"></i> Add</span>
          <span><i class="fas fa-sync-alt"></i> Refresh</span>
          <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
  </div>



  <div class="inner-mid-container">
    <div class="row">
      <div class="col-sm-3">
        <h1 class="mb-0">
          <img width="40" src="../../../assets/img/template.png">
          <div class="vertical-line mx-3"> </div> <span>Framework</span>
        </h1>
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
        {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
      </div>
    </div>
    <div class="mt-2">
      <form [formGroup]="secondFormGroup" class="pl-0">

        <div class="row">
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="Framework">Framework</label>
              <select id="Framework" name="Framework" class="form-control form-control-height"
                (change)="onSelectFirstDropdown($event)" required formControlName="Framework"
                [class.is-invalid]="Framework.invalid && Framework.touched">
                <option value="" disabled selected hidden>Select Framework</option>
                <option *ngFor="let Framework of FrameworkVal" [value]="Framework">{{Framework}}</option>
              </select>
              <small class="text-danger" [class.d-none]="Framework.valid || Framework.untouched">Framework is
                required</small>
            </div>
          </div>
  
  
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="language">Select Language</label>
  
              <select id="language" name="language" class="form-control form-control-height" required  (change)="onSelectSecondDropdown($event)"
                formControlName="language" [class.is-invalid]="language.invalid && language.touched">
                <option value="" disabled selected hidden>Select language</option>
                <option *ngFor="let language of languageDropdown" [value]="language">{{language}}</option>
              </select>
              <small class="text-danger" [class.d-none]="language.valid || language.untouched">Tool Stack is
                required</small>
  
            </div>
          </div>
  
  
          <div class="col-md-6 col-lg-2" style="display:none">
            <div class="form-group">
              <label for="Pricing">Select Pricing</label>
              <select id="Pricing" name="Pricing" class="form-control form-control-height" required
                formControlName="Pricing" [class.is-invalid]="Pricing.invalid && Pricing.touched">
                <option value="" disabled selected hidden>Select price-range</option>
                <option *ngFor="let Pricing of PricingVal" [value]="Pricing">{{Pricing}}</option>
              </select>
              <small class="text-danger" [class.d-none]="Pricing.valid || Pricing.untouched">Tool Stack is
                required</small>
  
            </div>
          </div>
        </div>
 
      </form>
    </div>

    <div class="row field-name" *ngIf="framework=='dev'" style="margin: 1% 0% -3% 0%">
      <h4>Result</h4>
    </div>
    <div class="row" *ngIf="framework=='dev'">
     
      <div class="card" *ngFor="let temp of templateToShow; let k = index;">
        <div class="row">
          <div class="col-md-3">
            <img class="card-img-top" src="../../../assets/img/template.png" class="template-img" alt="Card image cap">
          </div>
          <div class="col-md-9 template-container">
            <span class="template-name">{{temp.template_name}}</span>
          </div>
        </div>

        <!-- <img class="card-img-top" src="../../../assets/img/IMAGE1.jpg" class="template-img" alt="Card image cap"> -->
        <ul class="list-group list-group-flush">
          <!-- <li class="list-group-item">A template is a file that serves as a starting point for a new document. When you open a template, it is pre-formatted in some way</li> -->
          <li class="list-group-item">{{ temp.description }}</li>
          <li class="list-group-item">{{temp.framework | uppercase}}</li>
          <li class="list-group-item">{{temp.language | uppercase}}</li>
          <li class="list-group-item">
            <div class="half-stars-example3">
              <div class="rating-group">
                  <input class="rating__input rating__input--none" checked name="devrating{{k}}" id="devrating{{k}}-0" value="0" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="0 stars" class="rating__label" for="devrating{{k}}-0">&nbsp;</label>
                  <label aria-label="0.5 stars" class="rating__label rating__label--half" for="devrating{{k}}-05"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 0.5" name="devrating{{k}}" id="devrating{{k}}-05" value="0.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1 star" class="rating__label" for="devrating{{k}}-10"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1" name="devrating{{k}}" id="devrating{{k}}-10" value="1" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1.5 stars" class="rating__label rating__label--half" for="devrating{{k}}-15"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1.5" name="devrating{{k}}" id="devrating{{k}}-15" value="1.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2 stars" class="rating__label" for="devrating{{k}}-20"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2" name="devrating{{k}}" id="devrating{{k}}-20" value="2" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2.5 stars" class="rating__label rating__label--half" for="devrating{{k}}-25"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2.5" name="devrating{{k}}" id="devrating{{k}}-25" value="2.5" type="radio" (change)="onItemChange(temp,$event)" >
                  <label aria-label="3 stars" class="rating__label" for="devrating{{k}}-30"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3" name="devrating{{k}}" id="devrating{{k}}-30" value="3" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="3.5 stars" class="rating__label rating__label--half" for="devrating{{k}}-35"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3.5" name="devrating{{k}}" id="devrating{{k}}-35" value="3.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4 stars" class="rating__label" for="devrating{{k}}-40"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4" name="devrating{{k}}" id="devrating{{k}}-40" value="4" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4.5 stars" class="rating__label rating__label--half" for="devrating{{k}}-45"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4.5" name="devrating{{k}}" id="devrating{{k}}-45" value="4.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="5 stars" class="rating__label" for="devrating{{k}}-50"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 5" name="devrating{{k}}" id="devrating{{k}}-50" value="5" type="radio" (change)="onItemChange(temp,$event)">
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <span (click)="openTemplate()" class="card-link">Select Template</span>
            </li>
        </ul>
       
      </div>
    </div>

    <div class="row field-name" *ngIf="framework=='test'" style="margin: 1% 0% -3% 0%">
      <h4>Result</h4>
    </div>
    <div class="row" *ngIf="framework=='test'">
    
      <div class="card"  *ngFor="let temp of templateToShow; let j = index;">
        <div class="row">
          <div class="col-md-3">
            <img class="card-img-top" src="../../../assets/img/template.png" class="template-img" alt="Card image cap">
          </div>
          <div class="col-md-9 template-container">
            <span class="template-name">{{temp.template_name}}</span>
          </div>
        </div>
        <!-- <img class="card-img-top" src="../../../assets/img/IMAGE1.jpg" class="template-img" alt="Card image cap"> -->
        <ul class="list-group list-group-flush">
          <!-- <li class="list-group-item">A template is a file that serves as a starting point for a new document. When you open a template, it is pre-formatted in some way</li> -->
          <li class="list-group-item">{{ temp.description }}</li>
          <li class="list-group-item">{{temp.framework | uppercase}}</li>
          <li class="list-group-item">{{temp.language | uppercase}}</li>
          <li class="list-group-item">
            <div class="half-stars-example2">
              <div class="rating-group">
                  <input class="rating__input rating__input--none" checked name="testrating{{j}}" id="testrating{{j}}-0" value="0" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="0 stars" class="rating__label" for="testrating{{j}}-0">&nbsp;</label>
                  <label aria-label="0.5 stars" class="rating__label rating__label--half" for="testrating{{j}}-05"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 0.5" name="testrating{{j}}" id="testrating{{j}}-05" value="0.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1 star" class="rating__label" for="testrating{{j}}-10"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1" name="testrating{{j}}" id="testrating{{j}}-10" value="1" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1.5 stars" class="rating__label rating__label--half" for="testrating{{j}}-15"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1.5" name="testrating{{j}}" id="testrating{{j}}-15" value="1.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2 stars" class="rating__label" for="testrating{{j}}-20"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2" name="testrating{{j}}" id="testrating{{j}}-20" value="2" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2.5 stars" class="rating__label rating__label--half" for="testrating{{j}}-25"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2.5" name="testrating{{j}}" id="testrating{{j}}-25" value="2.5" type="radio" (change)="onItemChange(temp,$event)" >
                  <label aria-label="3 stars" class="rating__label" for="testrating{{j}}-30"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3" name="testrating{{j}}" id="testrating{{j}}-30" value="3" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="3.5 stars" class="rating__label rating__label--half" for="testrating{{j}}-35"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3.5" name="testrating{{j}}" id="testrating{{j}}-35" value="3.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4 stars" class="rating__label" for="testrating{{j}}-40"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4" name="testrating{{j}}" id="testrating{{j}}-40" value="4" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4.5 stars" class="rating__label rating__label--half" for="testrating{{j}}-45"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4.5" name="testrating{{j}}" id="testrating{{j}}-45" value="4.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="5 stars" class="rating__label" for="testrating{{j}}-50"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 5" name="testrating{{j}}" id="testrating{{j}}-50" value="5" type="radio" (change)="onItemChange(temp,$event)">
              </div>
            </div> 
          </li>
          <li class="list-group-item">
            <span (click)="openTemplate()" class="card-link">Select Template</span>
            </li>
        </ul>
        
      </div>
    </div>

    <div class="row field-name" style="margin: 1% 0% -3% 0%">
      <h4>Trending</h4>
    </div>
    <div class="row">
            <div class="card" *ngFor="let temp of template; let i = index;">
        <!-- <span class="material-icons template-icon">
          description
          </span> -->
          <div class="row">
            <div class="col-md-3">
              <img class="card-img-top" src="../../../assets/img/template.png" class="template-img" alt="Card image cap">
            </div>
            <div class="col-md-9 template-container">
              <span class="template-name">{{temp.template_name}}</span>
            </div>
          </div>
        
        <ul class="list-group list-group-flush">
          <!--li class="list-group-item" style="font-weight: bold;">{{temp.id}}</li-->
          <!-- <li class="list-group-item">A template is a file that serves as a starting point for a new document. When you open a template, it is pre-formatted in some way</li> -->
          <li class="list-group-item">{{ temp.description }}</li>
          <li class="list-group-item">{{temp.framework | uppercase}}</li>
          <li class="list-group-item">{{temp.language | uppercase}}</li>
          <li class="list-group-item">
            <div class="half-stars-example1">
              <div class="rating-group">
                  <input class="rating__input rating__input--none" checked name="rating{{i}}" id="rating{{i}}-0" value="0" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="0 stars" class="rating__label" for="rating{{i}}-0">&nbsp;</label>
                  <label aria-label="0.5 stars" class="rating__label rating__label--half" for="rating{{i}}-05"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 0.5" name="rating{{i}}" id="rating{{i}}-05" value="0.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1 star" class="rating__label" for="rating{{i}}-10"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1" name="rating{{i}}" id="rating{{i}}-10" value="1" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="1.5 stars" class="rating__label rating__label--half" for="rating{{i}}-15"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 1.5" name="rating{{i}}" id="rating{{i}}-15" value="1.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2 stars" class="rating__label" for="rating{{i}}-20"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2" name="rating{{i}}" id="rating{{i}}-20" value="2" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="2.5 stars" class="rating__label rating__label--half" for="rating{{i}}-25"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 2.5" name="rating{{i}}" id="rating{{i}}-25" value="2.5" type="radio" (change)="onItemChange(temp,$event)" >
                  <label aria-label="3 stars" class="rating__label" for="rating{{i}}-30"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3" name="rating{{i}}" id="rating{{i}}-30" value="3" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="3.5 stars" class="rating__label rating__label--half" for="rating{{i}}-35"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 3.5" name="rating{{i}}" id="rating{{i}}-35" value="3.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4 stars" class="rating__label" for="rating{{i}}-40"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4" name="rating{{i}}" id="rating{{i}}-40" value="4" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="4.5 stars" class="rating__label rating__label--half" for="rating{{i}}-45"><i class="rating__icon rating__icon--star fa fa-star-half"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 4.5" name="rating{{i}}" id="rating{{i}}-45" value="4.5" type="radio" (change)="onItemChange(temp,$event)">
                  <label aria-label="5 stars" class="rating__label" for="rating{{i}}-50"><i class="rating__icon rating__icon--star fa fa-star"></i></label>
                  <input class="rating__input" [checked]="temp.rating == 5" name="rating{{i}}" id="rating{{i}}-50" value="5" type="radio" (change)="onItemChange(temp,$event)">
              </div>
            </div>
          </li>
          <li class="list-group-item">
          <span (click)="openTemplate()" class="card-link">Select Template</span>
          </li>

        </ul>
        
      </div>
    </div>
    
  </div>
</div>