
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { environment } from 'src/environments/environment'
import { AlertService } from 'src/app/_alert';

const GRAPH_ENDPOINT = environment.GRAPH_ENDPOINT;


@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent {
  mobileQuery: MediaQueryList;
  profile;
  showFiller = false;
  mailId;
  userRole ;
  private _mobileQueryListener: () => void;
  projectData : any
  userMailId:string;
  screenWidth: number;
  members: any =[];
  projectRole: any =[];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  private logoPath = environment.logoPath;
  private footerLogoPath = environment.footerLogoPath;
  imageSrc:any;
  imageAlt:any;
  footerimageSrc:any;
  footerimageAlt:any;
  adminRole: boolean;
  roleAccess: string;
  vdiFormFlag: boolean=false;


  constructor(private http: HttpClient, private msalBroadcastService: MsalBroadcastService, public alertService: AlertService, private authService: MsalService,changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    public dialog: MatDialog,
    public _AuthServices:AuthService,
    private _router:Router,
    private _snackBar: MatSnackBar,private apiService : AuthService){

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // set screenWidth on page load
  this.screenWidth = window.innerWidth;
  window.onresize = () => {
    // set screenWidth on screen size change
    this.screenWidth = window.innerWidth;
  };
  this.imageSrc = this.logoPath;  
   this.imageAlt = 'Logo';
   this.footerimageSrc = this.footerLogoPath;  
   this.footerimageAlt = 'Footer';
  //  console.log(this._router.url);
   if(this._router.url=='/VdiRequestForm'){
    this.vdiFormFlag=true
   }
   

  }

  openLogoutModal(){
    const dialogRef = this.dialog.open(logoutModal, {
      width: '40%',
      height: '32%',
      disableClose: true,
    });
  }

  ngAfterContentInit()
  {
    setTimeout( ()=>{
      this.userMailId=localStorage.getItem('mail').toLowerCase();
      }, 2000)
  }

  profileImage  : any
  profileImageFlag;
initials;
  async ngOnInit(){

    await this.getProfile().then(()=>{})
  
    this.apiService.profileImageFlag.subscribe(data=>{
      this.profileImageFlag = data
    })

    this.userMailId=localStorage.getItem('mail').toLowerCase();
    this.profile=localStorage.getItem('profile');
    this.userRole= localStorage.getItem('role');
    if(localStorage.getItem('canvasAdminRole')=="false"){
      this.adminRole = false;
    }
    else{
      this.adminRole = true;
    }
    this.roleAccess = localStorage.getItem("Role");
   
    //this.apiService.profileName.next(this.profile);

    this.initials =this.profile.split(" ").map((n,i,a)=> i === 0 || i+1 === a.length ? n[0] : null).join("");


    this.apiService.getProfileImage(GRAPH_ENDPOINT + "/me/photo/$value").subscribe(data=>{
     
      this.createImageFromBlob(data)
    
      
 
    })
  if(window.location.hash!='#/VdiRequestForm'){

  this.apiService.getProjectByEmailId(this.userMailId).subscribe((data:any) =>{
    
    this.apiService.psNumber.next(data.userInfo.userId)
      this.projectData = data.pmArray   
    
  })
}


  }

  imageToShow: any;
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.imageToShow = reader.result;
    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }

  openSnackBarDiaLog() {

    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {

      width: '32%',

      height: '23%',

      disableClose: true,
 

    });

  }

  changeAccess(){
   
    let reqBody = {
      "user_mail": this.mailId.toLowerCase(),
      // "user_type": this.roleAccess=='Admin' ? 'User' : 'Admin'
    }
    this.apiService.changeRole(reqBody).subscribe((data: any)=>{
      window.location.reload();
    })
  }

  async getProfile() {
    this.http.get(GRAPH_ENDPOINT+ "/me").toPromise()
      .then(profile => {
        this.apiService.loggedInMailId.next(profile['mail'])
        
          localStorage.setItem("profile", profile['displayName'])

          this.profile=localStorage.getItem('profile')

          localStorage.setItem("mail", profile['mail'])

          this.mailId =localStorage.getItem('mail')

          localStorage.setItem("role", profile['jobTitle'])

         // this.mailId =localStorage.getItem('role')

          if(this.mailId == "")
          {
            this.openSnackBarDiaLog()
            // this.apiService.castToolDialog.next("AD Error. Contact Administrator.")
    
          }

        });
        
  }

  selectedIndex: number = null;
   setIndex(index: number) {
      this.selectedIndex = index;
   }
  
  // setProjectId(event,id){    
  //   this.apiService.projectKey.next(id);
  // }

  getProjectId(event, id) {
    // this.apiService.projectKey.next(id);
    
    // @author-priyanka kale - setting projectId in localstorage
    localStorage.setItem('projectId',id);
  
    this.apiService.getCatalogProjectById(id).subscribe(project => {
      this.members = project[0].members
      this.members.forEach(element => {
        if(element.user_email == this.userMailId){
         this.projectRole = element.role_name
        }
        
      });
      if(this.projectRole.length == 0) {
          this.alertService.error('You have not assigned any role yet for this project.', this.options);
      }
      this.projectRole.forEach(element => {
        if(element == "Secondary Project Manager" || element == "Project Manager" || element =="PM"){
          this._router.navigate(['/projectSummary'])
        }
        else{
          this._router.navigate(['/projectSummary'])
        }
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }


}

//logout confirmation modal
@Component({
  selector: 'logout-modal',
  templateUrl: 'logoutPopup.html',
  styleUrls: ['./main-nav.component.scss']
})
export class logoutModal {
  tokenVal: string;

  constructor(private apiService: AuthService, private authService: MsalService,
    public dialogRef: MatDialogRef<logoutModal>
  ) { 
    
  }
  ngOnInit() {
    
  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.logout();
    }
    else {
      this.dialogRef.close('No');
    }
  }
  logout() {
 

    //code start - after the logout to clear all local storage

    this.tokenVal = localStorage.getItem("token")



    let Body = {

      "token": this.tokenVal

    }

    // console.log(Body);



    this.apiService.jwtTokenLogout(Body).subscribe((res: HttpResponse<any>) => {

      // debugger;
      // console.log("fhfh");
      
      localStorage.clear();

      sessionStorage.clear();

      this.authService.logout();

      this.apiService.projectKey.next("");

      localStorage.removeItem('profile')

      // console.log(res.body.message);



    }, (error: HttpErrorResponse) => {

      // debugger;

    })


}
  exit(): void {
    this.dialogRef.close();
  }

}