<h2 mat-dialog-title style="color: #00529C;">Story Board Tools</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-md-12 text-center">
        <a routerLink="/admin-jira" mat-dialog-close>
            <img src="assets/img/jira-logo.png" title="Jira" class="img-fluid">
            <div class="d-block mt-3">
                <button mat-raised-button>JIRA</button>
            </div>            
        </a>
      </div>
      <!-- <div class="col-md-6 text-center">
        <a routerLink="/admin-github" mat-dialog-close>
            <img src="assets/img/github-logo.png" title="GitHub" class="img-fluid">
            <div class="d-block mt-3">
              <button mat-raised-button>GitHub</button>
          </div>
        </a>
      </div> -->
  </div>
</mat-dialog-content>
