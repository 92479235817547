<form (ngSubmit)="AddVdiImage()">
  <div class="member-card  pop-up">
    <div class="row">
      <div fxLayout fxLayoutAlign="center center">
        <span style="color: red;"><label *ngIf="error">{{errorMsg}}</label></span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-5 pt-3">
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0">Select VM Image</span>
      </div>
      <div class="col-sm-5">
        <div fxLayout fxLayoutAlign="center center">
          <mat-form-field fxFlex="60%">
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here"
              autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm-2 pt-3">
        <span class="material-icons close-icon" mat-dialog-close>
          close
        </span>
      </div>

    </div>
    <hr class="margin-class" />

    <div class="example-container vdi-table">

      <div>
        <div style="text-align:center;color: red;padding-top: 5px;" *ngIf="SelectType">Kindly select VM OS, VM Server and VM type to add VM image's</div>
        <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>

          <!-- Resource  Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}}
              <span  class="material-icons add-user ng-star-inserted"  style="font-size: 18px;cursor:pointer;padding-left: 4px; position: relative; top:4px;"
              title="System Information" (click)="showImageInfo(element)">info</span> </td>
          </ng-container>

          <!-- Resource Type Column -->
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.type}} </td>
          </ng-container>


          <!-- ACtion Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let element">
              <button class="" mat-raised-button color="warning"
                style="background-color: #1460a3;">{{element.action}}</button> </td>
          </ng-container>

          <ng-container matColumnDef="vmos">
            <th mat-header-cell *matHeaderCellDef>VM OS </th>
            <!--   <td mat-cell *matCellDef="let element"> {{element.vdiType}} </td> -->
            <td mat-cell *matCellDef="let element">
              <select name="vmos" [(ngModel)]="element.vmos" (change)="onChangeVmOs($event)">
                <option *ngFor="let vmos of VM_OS_List" [value]="vmos.value">
                  {{vmos.viewValue}}
                </option>
              </select>
            </td>
          </ng-container>

          <ng-container matColumnDef="vmse">
            <th mat-header-cell *matHeaderCellDef>VM Server </th>
            <!--   <td mat-cell *matCellDef="let element"> {{element.vdiType}} </td> -->
            <td mat-cell *matCellDef="let element">
              <select [(ngModel)]="element.vmse" name="vmse" >
              <!--   (change)="onChangeVmServer($event)"-->
              
                <option *ngFor="let vmse of VM_Server_List;let i =index;" [value]="vmse.value">
                  {{vmse.viewValue}}
                </option>
              </select>
            </td>
          </ng-container>

<!-- Description Column -->
<ng-container matColumnDef="image">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> image </th>
  <td mat-cell *matCellDef="let element"> {{element.image}} </td>
</ng-container>

          <ng-container matColumnDef="vmty">
            <th mat-header-cell *matHeaderCellDef>VM Type </th>

            <td mat-cell *matCellDef="let element">
              <select name="vmty" [(ngModel)]="element.vmty">
                <option *ngFor="let vmtype of VMType_List;let i =index;"  [value]="vmtype.value">
            {{vmtype.viewValue}}
          </option>
         <!--   {{element.vmty}}
                <option>
                  <div *ngIf="element.vmos == 'Select' && element.vmse == 'Select'"> Select</div> 
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'App'">
                    Tomcat9</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'DataBase'">
                    MSSQLExpr2019</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Web'">
                    Apache-2.4</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Orchestration'">
                    Jenkins-2.263</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Code Analysis'">
                    SonarQube-7.6</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'None'">
                    OS Only</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Others'">
                    IIS10 + SqlExpr2019</div>

                
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'App'">
                    Tomcat-9</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'DataBase'">
                    MongoDB-4.4</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'Web'">
                    Apache-2.4</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'Orchestration'">
                    Jenkins-2.263</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'Code Analysis'">
                    SonarQube-7.6</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'None'">
                    OS Only</div>
                  <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'Others'">
                    Apache24 + MySql8</div>                 

                </option>
               
                <option>

                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Web'">
                    IIS-10</div>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Others'">
                    Tomcat9 + MSSQLExpr2019</div>

                     
                    <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'DataBase'">
                    MySql8</div>

                    <div
                    *ngIf="(element.vmos == 'Linux CentOS 8' || element.vmos == 'Linux RHEL 8') && element.vmse == 'Others'">
                    MySql8 + Tomcat9</div>

                </option> -->

                 <!--Third Row of VM type dropdown

                <option>
                  <div
                    *ngIf="(element.vmos == 'Windows 2016' || element.vmos == 'Windows 2019') && element.vmse == 'Others'">
                    Tomcat9 + Mysql8</div>

                

                </option>-->
              </select>
            </td>
          </ng-container>

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="select">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" class="select">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false">
          </tr>


        </table>
      </div>


    </div>

    <div class="row">
      <div class="col-sm-8">
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
        </mat-paginator>
      </div>
      <div class="col-sm-4">
        <div class="float-right pt-3">
          <button type="button" class="btn-canvas-blue mr-2" (click)="AddVdiImage()">+ Add VM Image</button>
          <button type="button" class="btn-canvas-blue" mat-dialog-close>Close</button>
        </div>
      </div>
    </div>


  </div>
</form>