<div class="popup-dialog">
    <div class="">
        <span (click)="onNoClick()" class="material-icons close-icon">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
    </div>
  
        <div class="content">
            <span class="exl-icon">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span  class="vertical-line mx-1 mx-md-3">
            </span>
            <span class="text">
             Do you want to cancel changes?
            </span>
        </div>
        <div class="text-right">
            <div class="row">
                <div class="col-md-9 col-12 mt-3">
                    <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="workbenchPopUpClose('Yes')">Yes</button>
                </div>
                <div class="col-md-3 col-12 mt-3">
                    <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="workbenchPopUpClose('No')">No</button>
                </div>
            </div>
        </div>
    
</div>