<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container">

 

        <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a  (click)="goHome(secondFormGroup)">Home</a></li>
            <li class="breadcrumb-item link-blue"><a (click)="goWorkBeanch(secondFormGroup)">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Canvas Insight</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span (click)="addRepo()"><i class="fas fa-plus"></i> Add</span>
          <span><i class="fas fa-sync-alt"></i> Refresh</span>
          <span><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
  

  </div>

 

  <div class="inner-mid-container">
    <div class="row">
      <div class="col-sm-3">
        <h1 class="mb-0">
          <img width="40" src="../../../assets/img/metis.svg">
          <div class="vertical-line mx-3"> </div> <span>Canvas Insights</span>      
        </h1>
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
        {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
    </div>
    <div class="col-md-5" style="padding-top:8px;"><span class="field-name">Canvas URL </span><span class="field-value"> :
      <a class="url-text" target="_blank" href="https://canvasdemo.lntinfotech.com:5050/">https://canvasdemo.lntinfotech.com:5050</a> </span>
    </div>
   
    </div>
    <div class="mt-2" *ngIf="showRepo">

      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row">

          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="insightProjName">Project Name</label>
              <input #input id="insightProjName" name="insightProjName" class="form-control form-control-height" placeholder="Enter Project Name"
                required formControlName="insightProjName" [class.is-invalid]="insightProjName.invalid && insightProjName.touched">
              <small class="text-danger" [class.d-none]="insightProjName.valid || insightProjName.untouched">Project Name is
                required</small>
              
            </div>
          </div>

          
    
          <div class="mt-4 img-width">
            <span class="material-icons add-user" (click)="openUser()" title="Add User">
              person_add
            </span>
          </div> 
  
          <div class="col-md-1 col-lg-1 mt-4">
            <button type="button" class="hover users-button" mat-button (click)="confirmationPopup()" mat-raised-button
              [disabled]="!secondFormGroup.valid || !selectedUserArray.length" title="Create Repo">
              Create
            </button>
          </div>
  
          <div class="col-md-1 col-lg-1 mt-4">
            <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="toggleDisplay(secondFormGroup)">Cancel</button>
            
          </div>
        </div>
      </form>
    </div>

    <div class="row userRow" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">
        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>

    <div  *ngIf="showTable" class="mt-2">
      <table class="table fixed-table" datatable>
        <thead>
          <tr>
          <th>LOB Name</th>
          <th>Project Name</th>
          <th>Members</th>
          <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let val of tableArr">
            <td>{{val.lobName}}</td>
            <td>{{val.projectName}}</td>
            <td>
              <button class="view-member-btn" (click)="openAddedUserDialog(val.users)">View</button>
            </td>
            <td>
              <span  class="table-crud table-edit-icon" disabled><i class="far fa-edit"></i></span>
              <span  class="table-crud table-save-icon" disabled><i class="far fa-save"></i></span>
              <span  class="table-crud table-delete-icon" disabled><i class="far fa-trash-alt"></i></span>
          </td>
          </tr>
         
        </tbody>
      </table>
    </div>


  </div>
</div>