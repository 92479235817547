<div class="member-card">
    <div class="row ">
      <div class="offset-md-11 col-md-1">
        <span class="material-icons" (click)="exit()" title="close" style="float: right;cursor: pointer;">
          close
        </span>
      </div>
  
    </div>
  
    <div class="example-container1">
      <form (ngSubmit)="addUserForRepo()">
      <table mat-table [dataSource]="dataSource">
        
  
        <!-- Name Column -->
        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
        </ng-container>
  
        <!-- Email Column -->
        <ng-container matColumnDef="user_email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
        </ng-container>
  
        <!-- Role Column -->
        <ng-container matColumnDef="role_name">
          <th mat-header-cell *matHeaderCellDef> Role </th>
          <td mat-cell *matCellDef="let element"> {{element.role_name}} </td>
        </ng-container>

        <ng-container matColumnDef="groupType">
          <th mat-header-cell *matHeaderCellDef> Group Type </th>
          <td mat-cell *matCellDef="let element">
            <mat-select name="groupType" [(ngModel)]="element.groupType">
              <mat-option *ngFor="let role of GroupTypeVal" (selectionChange)="getVDITypeValue($event.value)" [value]="role.value">
                {{role.viewValue}}
              </mat-option>
            </mat-select>
           </td>
        </ng-container>

        <ng-container matColumnDef="accountLicenseType">
          <th mat-header-cell *matHeaderCellDef> Account License </th>
          <td mat-cell *matCellDef="let element">
            <mat-select name="accountLicenseType" [(ngModel)]="element.accountLicenseType">
              <mat-option *ngFor="let role of accountLicenceTypeVal" (selectionChange)="getVDITypeValue($event.value)" [value]="role.value">
                {{role.viewValue}}
              </mat-option>
            </mat-select>
           </td>
        </ng-container>
  
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
        </tr>
     
      </table>
      <div class="row " >
        <div class="offset-md-9 col-md-3">
    
          <button mat-button  mat-raised-button class="add-button" >+ Add Member</button>
        </div>
      </div>
    </form>
  
    </div>
  
    
  </div>