import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Subject } from 'rxjs';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { environment } from 'src/environments/environment';
import { threadId } from 'worker_threads';
import { VMInfoModal } from '../deployment-store/deployment-store.component';
import { MembersListDialog, scmHomeConfirmationPopUpDialog, scmWorkbenchConfirmationPopUpDialog } from '../scm-tool/scm-tool.component';
import { DialogOverviewExampleDialog, DialogProjectInfoDialog, vdiRefreshInformationPopUpDialog } from '../vdi-details/vdi-details.component';

@Component({
  selector: 'app-recycle-bin',
  templateUrl: './recycle-bin.component.html',
  styleUrls: ['./recycle-bin.component.scss']
})
export class RecycleBinComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtOptionsJira: DataTables.Settings = {};
  dtOptionsVM: DataTables.Settings = {};
  dtOptionsJenkins: DataTables.Settings = {};
  dtTrigger = new Subject();
  tableArr: any = [];
  projectId: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  jiraArray: any = [];
  selectedTab: any = 'Gitlab';
  showGit: boolean;
  showJira: boolean;
  gitDetails: any;
  jiraDetails: any;
  displayNoData: boolean = false;
  vmDetails: any;
  showVM: any;
  vmData: any = [];
  subscription: any;
  resource_grp: any;
  cicdTable: any = [];
  showJenkins: boolean;
  jenkinsDetails: any;
  Role: string;
  refreshDisable: boolean = true; showVDI: boolean;
  vdiDetails: any;
  dtOptionsVDI: DataTables.Settings = {};
  vdiTable: any[];
  memberString: any;
  ;
  constructor(private _router: Router, private spinner: NgxSpinnerService,
    public dialog: MatDialog, public apiService: AuthService, public alertService: AlertService) { }

  async ngOnInit(): Promise<void> {
  
    
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.projectId = localStorage.getItem('projectId');
      this.spinner.show();
      //this.apiService.getDeletedRequests(this.projectId, 'Jira'), this.apiService.getDeletedRequests(this.projectId,'VM'), this.apiService.getDeletedRequests(this.projectId,'Jenkins'),this.apiService.getDeletedRequests(this.projectId,'VDI')
      forkJoin([this.apiService.getDeletedRequests(this.projectId, 'Gitlab'), this.apiService.getDeletedRequests(this.projectId, 'Jira'), this.apiService.getDeletedRequests(this.projectId, 'Jenkins'), this.apiService.getDeletedRequests(this.projectId, 'Vm'), this.apiService.getDeletedRequests(this.projectId, 'VDI')]).subscribe((results: any) => {
        this.gitDetails = results[0];
        this.jiraDetails = results[1];
        this.jenkinsDetails = results[2];
        this.vmDetails = results[3];
        this.vdiDetails = results[4];
        if (this.gitDetails?.length == 0 && this.jiraDetails?.length == 0 && this.jenkinsDetails?.length == 0 && this.vmDetails?.length == 0 && this.vdiDetails?.length == 0) {
          this.displayNoData = true;
        }
        //&& this.jiraDetails?.length == 0 && this.vmDetails?.length == 0 && this.jenkinsDetails?.length == 0 && this.vdiDetails.length == 0
        if (this.gitDetails?.length > 0) {
          this.getGitlab(this.gitDetails);
        }
        if (this.jiraDetails?.length > 0) {
          this.getJira(this.jiraDetails);
        }

        if (this.jenkinsDetails?.length > 0) {
          this.getJenkins(this.jenkinsDetails);
        }

        if (this.vmDetails?.length > 0) {
          this.getVM(this.vmDetails);
        }
        if (this.vdiDetails?.length > 0) {
          
          this.getVDI(this.vdiDetails);
        }
        if (this.gitDetails?.length == 0 && this.jiraDetails?.length > 0) {
          this.getTab('Jira')
        }
        if (this.gitDetails?.length == 0 && this.jiraDetails?.length == 0 && this.jenkinsDetails?.length > 0) {
          this.getTab('Jenkins')
        }
        if (this.gitDetails?.length == 0 && this.jiraDetails?.length == 0 && this.jenkinsDetails?.length == 0 && this.vmDetails?.length > 0) {
          this.getTab('VM')
        }
        if (this.gitDetails?.length == 0 && this.jiraDetails?.length == 0 && this.jenkinsDetails?.length == 0 && this.vmDetails?.length == 0 && this.vdiDetails?.length > 0) {
          this.getTab('VDI')
        }
        this.spinner.hide()
      }, (error: HttpErrorResponse) => {
        this.spinner.hide();
        if (error.status == 452) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.displayNoData = true;

          // this.alertService.error("No data available.", this.options);
        }

      });
    }
    else {
      this._router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/admin-catalog-config']);
      });
    }
  }

  getTab(tab) {
    this.selectedTab = tab;
    if (this.selectedTab == 'Gitlab') {
      this.showGit = true;
      this.showJira = false;
      this.showVM = false;
      this.showJenkins = false;
    }
    if (this.selectedTab == 'Jira') {
      this.showJira = true;
      this.showGit = false;
      this.showVM = false;
      this.showJenkins = false;
    }
    if (this.selectedTab == 'Jenkins') {
      this.showJenkins = true;
      this.showJira = false;
      this.showGit = false;
      this.showVM = false;
    }
    if (this.selectedTab == 'VM') {
      this.showVM = true;
      this.showJira = false;
      this.showGit = false;
      this.showJenkins = false;
    }
    if (this.selectedTab == 'VDI') {
      this.showVDI = true;
      this.showJira = false;
      this.showGit = false;
      this.showJenkins = false;
      this.showVM = false;
    }
  }

  getGitlab(gitData) {

    this.dtOptions = {
      order: []
    };
    this.tableArr = [];

    gitData.forEach(project => {
      let memberArray = [];

      project.gitlab_project_members.forEach(member => {
        let memberObj = {
          name: member.user_name,
          email: member.email,
          role: member.role,
          status: member.status
        }
        if (member.status != 'offboarded' && member.status != 'Pending') {
          memberArray.push(memberObj)
        }
      })

      let tableObj =
      {
        "request_id": project.request_id,
        "repoName": project.gitlab_project_name,
        "repoUrl": project.gitlab_repo_url,
        "toolInstance": project.gitlab_tool_instance,
        "status": project.gitlab_project_status,
        "members": memberArray,
        "action": "Update"
      }

      this.tableArr.push(tableObj)
      this.dtTrigger.next();
      this.spinner.hide()
      this.showGit = true;
    })
  }

  getJira(jiraData) {
    this.dtOptionsJira = {
      order: []
    };
    this.jiraArray = [];
    let memberArray = [];

    jiraData.forEach(project => {
      memberArray = [];
      project.project_members.forEach(member => {
        let memberObj = {
          name: member.user_name,
          email: member.email_id,
          role: member.role_name,
          status: member.status
        }

        if (member.status != 'offboarded' && member.status != 'Pending') {
          memberArray.push(memberObj)
        }
      })
      let tableObj =
      {
        "request_id": project.request_id,
        "repoName": project.jira_project_name,
        "repoUrl": project.planning_project_url,
        "toolInstance": project.tool_instance,
        "status": project.creation_status,
        "jira_project_key": project.jira_project_key,
        "members": memberArray
      }
      this.jiraArray.push(tableObj)
      this.dtTrigger.next();
      this.spinner.hide()
    })
  }

  getVM(VMData) {
    this.dtOptionsVM = {
      order: []
    };
    this.vmData = [];

    VMData.forEach(element => {
      let memberArray = [];

      element.vm_data.members.forEach(member => {
        let membObj = {
          name: member.user_name,
          email: member.user_mail,
          user_access: member.user_access,
          status: member.user_status
        }

        if (member.user_status != 'Offboarded') {
          memberArray.push(membObj)
        }

      });


      if (element.vm_data.resourceID) {
        this.subscription = element.vm_data.resourceID.split('/')[2]
        this.resource_grp = element.vm_data.resourceID.split('/')[4]
      }


      let tableObj =
      {
        "request_id": element.request_id,
        "os_type": element.vm_data.os_type,
        "location": element.vm_data.location,
        "vm_size": element.vm_data.vm_size,
        "deployment_name": element.vm_data.deployment_name,
        "os_disk_type": element.vm_data.os_disk_type,
        "resource_group": this.resource_grp,
        "status": element.vm_data.status,
        "subnet_name": element.vm_data.subnet_name,
        "subscription_id": this.subscription,
        "vnet_name": element.vm_data.vnet_name,
        "ip_address": element.vm_data.ip_address,
        "application_name": element.vm_data.application_name,
        "server_type": element.vm_data.server_type,
        "members": memberArray
      }

      this.vmData.push(tableObj)
      this.dtTrigger.next();
      this.spinner.hide()
    });
  }

  getJenkins(jenkinsData) {
    this.dtOptionsJenkins = {
      order: []
    };
    this.cicdTable = []
    jenkinsData.forEach(element => {
      let members = [];
      element.members.forEach(el => {
        let membObj = {
          name: el.name,
          email: el.email,
          user_access: el.access
        }
        members.push(membObj)
      });

      let tableObj =
      {
        "request_id": element.request_id,
        "description": element.project_description,
        "project_type": element.project_type,
        "tool_instance": element.tool_instance,
        "repo": element.repo_url,
        "project_name": element.jenkins_project_name,
        "status": element.request_status,
        "Jenkin_url": element.jenkins_project_url,
        "members": members,
      }
      this.cicdTable.push(tableObj)
      this.dtTrigger.next();
      this.spinner.hide()
    });

  }

  getVDI(vdiDetails) {
    // console.log(this.vdiDetails);
    
    this.dtOptionsVDI = {
      order: []
    };
    this.vdiTable = []
    let tool_id = "";

    vdiDetails.forEach((request: any) => {
      tool_id = request.vdi_tool_id
      let tableObj;

      this.memberString = []
      if (request.vdi_user.approval == "Failed" || "Pending" || "Processing") {
        request.vdi_user.user_mail.forEach(element => {
          let userMail = element;
          for (let i = 0; i < request.vdi_user.user_name.length; i++) {
            let userName = request.vdi_user.user_name[0];
            request.vdi_user.user_name.splice(i, 1);
            let membObj = {
              name: userName,
              email: userMail,
              role: ""
            }
            this.memberString.push(membObj)
            break;
          };
        });
        request.vdi_user.user_status?.forEach((element) => {
          this.memberString.forEach((el, i) => {
            if (element.user_status == "Offboarded" && element.user_mail == el.email) {
              this.memberString.splice(i, 1);
            }
          });
        });
      }
      else {
        let membObj = {
          name: request.vdi_user.user_name,
          email: request.vdi_user.user_mail,
          role: ""
        }
        this.memberString.push(membObj)
      }

      tableObj =
      {
        "request_id": request.request_id,
        "vdi": request.vdi_type,
        "status":request.request_status,
        "toolStack": tool_id,
        "members": this.memberString,
        "name_prefix": request.vdi_user.name_prefix,
        "hostpool_name": request.vdi_user.hostpool_name,
        "application_group": request.vdi_user.application_group,
        "workspace_name": request.vdi_user.workspace_name

      }

      this.vdiTable.push(tableObj)
      this.dtTrigger.next();
      this.spinner.hide();

    })




  }

  refresh() {
    this.refreshDisable = false;
    setTimeout(() => {
      this.refreshDisable = true;
      this.ngOnInit();
    }, 2000)

  }
  openAddedUserDialog(memberArray, reqStatus, request, type) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data: {
        member: memberArray,
        reqStatus: reqStatus,
        request: request,
        screen: type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showImageInfo(value, dataReceived) {

    this.apiService.modalType.next(value);
    if (value == 'vdiInfo') {
      this.apiService.modalData.next(dataReceived);
    }
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: value == 'imageInfo' ? '75%' : '50%',
      //height: '60%',
      disableClose: true,
      data: {
        image: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showRequestInfo(value) {
    const dialogRef = this.dialog.open(VMInfoModal, {
      width: '50%',
      disableClose: true,
      data: {
        info: value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showPopUp(type, req, screenType) {

    const dialogRef = this.dialog.open(RestoreDeleteConfirmPopup, {
      width: '40%',
      height: '35%',
      disableClose: true,
      data: {
        info: type,
        request: req,
        screen: screenType
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      setTimeout(() => {
        this._router.navigateByUrl('/recycle-bin', { skipLocationChange: true }).then(() => {
          this._router.navigate(['/recycle-bin']);
        });
      }, 1000);

    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
@Component({
  selector: 'restore-delete-confirm-popup',
  templateUrl: 'restore-delete-confirm-popup.html',
  styleUrls: ['./recycle-bin.component.scss']
})

export class RestoreDeleteConfirmPopup {

  popupType: any;
  projectId: string;
  req: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  screen: any;
  userPsNumber: any;

  constructor(private apiService: AuthService, private alertService: AlertService, private spinner: NgxSpinnerService,private router: Router,
    public dialogRef: MatDialogRef<RestoreDeleteConfirmPopup>, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {

    this.popupType = data.info;
    this.req = data.request;
    this.screen = data.screen;
    // console.log(this.alertInfo,"alert");

  }

  ngOnInit() {
    this.projectId = localStorage.getItem('projectId');
  
  }
  restoreDelete() {
    if (this.screen == 'Gitlab') {
      let deletebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile')
      }
      if (this.popupType == 'Delete') {
        this.apiService.deleteProjectGitlab(deletebody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })

      }
      let restorebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "gitlab_name": this.req.repoName,
        "requestor_name": localStorage.getItem('profile'),
        "env" : environment.scmEnv
      }

      if (this.popupType == 'Restore') {
        this.apiService.restoreProjectGitlab(restorebody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
      }
    }

    if (this.screen == 'Jira') {
      let deleteBody = {
        "project_id": this.projectId,
        "jira_project_key": this.req.jira_project_key,
        "request_id": this.req.request_id,
        "tool_project_name": this.req.repoName,
        "requestor_name": localStorage.getItem('profile')
      }
      if (this.popupType == 'Delete') {
        this.apiService.deleteJiraProject(deleteBody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })

      }
      let restorebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile'),
        "tool_name": this.screen,
        "env" : environment.scmEnv
      }

      if (this.popupType == 'Restore') {
        this.apiService.restoreTools(restorebody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
      }

    }
    if (this.screen == 'Jenkins') {
      let deleteBody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile')
      }
      if (this.popupType == 'Delete') {
        this.apiService.deleteJenkinsProj(deleteBody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })

      }
      let restorebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile'),
        "tool_name": this.screen,
        "env" : environment.scmEnv
      }

      if (this.popupType == 'Restore') {
        this.apiService.restoreTools(restorebody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
      }
    }
    if (this.screen == 'VM') {
      let deleteBody = {
        "project_details": {
          "project_id": this.projectId,
          "project_name": localStorage.getItem('projectName'),
          "env" : environment.scmEnv
        },
        "request_details": {
          "request_id": this.req.request_id,
          "requestor_mail": localStorage.getItem('mail').toLowerCase(),
          "requestor_name": localStorage.getItem('profile'),
        }
      }
      if (this.popupType == 'Delete') {
        this.apiService.deleteVM(deleteBody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { 
            this.alertService.error(error.error.message, this.options);

          }
          else{
            this.alertService.error(error.error.message, this.options);


          }
        })

      }
      let restorebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile'),
        "tool_name": this.screen,
        "env" : environment.scmEnv
      }

      if (this.popupType == 'Restore') {
        this.apiService.restoreTools(restorebody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.exit();
          this.restoreVMRefresh(this.req.request_id);
        }, (error: HttpErrorResponse) => {
          this.exit();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
      }

    }
    if (this.screen == 'VDI') {
      this.apiService.psNumber.subscribe((psNumber: any) => {
        this.userPsNumber = psNumber
      })
      let deleteBody = {
        "project_details": {
          "project_id": this.projectId,
          "project_name": localStorage.getItem('projectName'),
          "env" : environment.scmEnv
        },
        "request_details": {
          "request_id": this.req.request_id,
          "requestor_id": this.userPsNumber,
          "requestor_name": localStorage.getItem('profile'),
        }
      }
      if (this.popupType == 'Delete') {
        this.spinner.show();
        this.apiService.deleteVdi(deleteBody).subscribe((data: any) => {
          this.alertService.success(data.message, this.options);
          this.spinner.hide();
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
        this.exit();
      }
      let restorebody = {
        "project_id": this.projectId,
        "request_id": this.req.request_id,
        "requestor_name": localStorage.getItem('profile'),
        "tool_name": this.screen,
        "vdi_type": this.req.vdi,
        "env" : environment.scmEnv
      }      
      this.spinner.show();

      if (this.popupType == 'Restore') {
        this.exit();

        this.apiService.restoreTools(restorebody).subscribe((data: any) => {

          this.alertService.success(data.message, this.options);
          // console.log("hello");
        
          this.spinner.hide()
              setTimeout(() => {
                this.router.navigateByUrl('/recycle-bin', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['/recycle-bin']);
                });
      }, 2000);
       



  
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error.status == 452) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
        })
      }
    }
  }
  restoreVMRefresh(reqId){
    const dialogRef = this.dialog.open(vdiRefreshInformationPopUpDialog, {
      width: '30%',
      height: '34%',
      disableClose: true,
      data: {
        req: reqId,
        screen: 'VM-Restore'
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
      }
    );
  }

  exit(): void {
    this.dialogRef.close();
  }

}
