import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface configList {
  Name: string;
  ID: string;
  Description: string;
  Cost: string;
  screen_name:string;
  DB_toolGroup_ID: string;
  isSelected: boolean;
}

export interface ToolGroupDetails {
  Name: string;
  ID: string;
  Description: string;
  //Screen_Name:string;
  DB_toolGroup_ID: string;
  screen_name:string;
  isSelected: boolean;
}

@Component({
  selector: 'app-admin-vdi-dropdown-config',
  templateUrl: './admin-vdi-dropdown-config.component.html',
  styleUrls: ['./admin-vdi-dropdown-config.component.scss']
})
export class AdminVDIDropdownConfigComponent implements OnInit,CanComponentLeave {

 
  projectId
  ToolGroupArray = [];
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;
  model: any = {};
  toolGr_description: string;
  toolGr_name: string;
  formMode: string = "";
  tool_DB_Id: any;

  editMode:boolean = false;
  project_id_scmEdit: string;
  dtOptions: DataTables.Settings = {};
  dtOptionsToolGroup: DataTables.Settings = {};
  dtTrigger = new Subject();
  dtTriggerToolGroup = new Subject();

  toolGrObj: object[];
  saveButtonDisable:boolean = false
  ToolGroupDisable=false;
  PropertyValueDisable = false;
  ScreenNameDisable=false;
  updateButtonDisable=true;
  showToolGroupForm: boolean = false;

  showToolGroupEditTable: boolean = false;
  showToolGroupMasterTable: boolean = true;
  ToolgroupId:any;
  tableResourceArray: any = [];
  isChecked = false;
  masterSelected: boolean;
  checklist: any;
  checkedList: any;

  project_ScreenName:string;
  project_dropDown_category:string;

  AzureArray = [];
  VdiConfig_details:any=[];
  check:any=[]
  project_selectedcheckbox = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  // @athor - priyanka kale - declare otemSelected Variable
  itemSelected:boolean=false;
  Role:string="";
  propertyValue: any;
  propertyNameValueList: number;
  propertyValues: any;
  displayName: any;
  // propertyNameValueList: any[];
  constructor(public apiService: AuthService, private router: Router, private spinner: NgxSpinnerService,
    public dialog: MatDialog, public alertService: AlertService) { 
      this.masterSelected = false;
    }
    nextUrl: any;
    canLeave(url):boolean{
      // console.log(url);
      
      // debugger
      this.nextUrl=url
      if(this.nextUrl=="/admin-scm-tool"){
        return true
      }
      else{
        if((this.model.screenname == '' || this.model.screenname == undefined) &&
        (this.model.toolGr == '' || this.model.toolGr == undefined) &&
         (this.model.ProptValue == '' || this.model.ProptValue == undefined)){
          return true
        }
        if ((this.model.screenname != '' || this.model.screenname != undefined) &&
        (this.model.toolGr != '' || this.model.toolGr != undefined) &&
         (this.model.ProptValue != '' || this.model.ProptValue != undefined)){
          let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
          return false
        }
      }
      
   
      return true
    }
    
  ELEMENT_DATA: ToolGroupDetails[];
  displayedColumns: string[] = ['Name', 'ID', 'Description', ' Screen_Name', 'select'];
  //dataSource = new MatTableDataSource<ToolGroupDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<ToolGroupDetails>(true, []);
  ScreenDropdownList=['Workbench-VDI','Workbench-VM','Workbench-Workspace'];
 // VdiDropdownList=['Location','VM-DiskType','VM-Size'];
 VdiDropdownList=[];
 propertyValueList=[];
  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };
    this.apiService.projectKey.subscribe(projectId => {
      // this.userEmail = currentUserEmail;
      this.projectId = projectId;
    })
    this.GetVdiConfigtableDetails();
    this.showToolGroupMasterTable=true;
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  refreshToolGroup(){
    
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-vdi-dropdown-config']);
  });
  }

  backNavigation(){
    if ((this.model.screenname == '' || this.model.screenname == undefined) &&
     (this.model.toolGr == '' || this.model.toolGr == undefined) &&
      (this.model.ProptValue == '' || this.model.ProptValue == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  onSubmit() {
    // 
  }


  GetVdiConfigtableDetails() {
    // 
    this.spinner.show();
    this.apiService.Admin_VdiDropdownConfigTableDetails().subscribe((res: any) => {
      // console.log("Get VAlue",res);
      res.forEach(member => {
        let obj = {
          "Name": member.dropdown_name,
          "ID": member.dropdown_id,
          "screen_name":member.screen_name, 
          "Description": member.dropdown_value,
          "dropdown_display_name":member.dropdown_display_name,
          "DB_toolGroup_ID": member._id,
          "isSelected":false
        }
        this.ToolGroupArray.push(obj)
       // this.AzureArray.push(obj)
        ///this.checklist = this.AzureArray;
        this.checklist = this.ToolGroupArray;
      })
     
     // this.dataSource.data = this.ToolGroupArray as ToolGroupDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 446) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
    // SCMArray
  }

  onChangePropertyValue($event){
    this.propertyValue = $event.target.options[$event.target.options.selectedIndex].text;
  }
properyValueApi(screenName,propertyName){

  this.apiService.Admin_getPropertyValues(screenName,propertyName).subscribe((res: any) => {
    // debugger
    this.propertyValues=res.body.property_values
    


  },
  (error:HttpErrorResponse) => {

  })
}
  onChangePropertyName($event)

  {
    // debugger
    this.model.ProptValue=""
    this.propertyValueList=[];

    let propertyName = $event.target.options[$event.target.options.selectedIndex].text;
  this.properyValueApi(this.model.screenname,propertyName)
    // if(propertyName=="Location")
    // {
    //  this.propertyValueList=['centralindia','eastus'];   
    // }
    // else if(propertyName=="Size" && this.model.screenname=="Workbench-VM")
    // {
    //   this.propertyValueList=['Standard_D2s_v3','Standard_D4s_v3','Standard_D8s_v3','Standard_D16s_v3','Standard_D32s_v3','Standard_D48s_v3','Standard_D64s_v3'];    
    // }
    // else if(propertyName=="DiskType" && this.model.screenname=="Workbench-VM")
    // {
    //   this.propertyValueList=['Premium_LRS','Standard_LRS'];   
    // }
    // else if(propertyName=="Size" && this.model.screenname=="Workbench-VDI")
    // {
    //   this.propertyValueList=['Standard_D2s_v3','Standard_D4s_v3','Standard_D8s_v3','Standard_D16s_v3'];    
    // }
    // else if(propertyName=="DiskType" && this.model.screenname=="Workbench-VDI")
    // {
    //   this.propertyValueList=['Premium_LRS','Standard_LRS'];   
    // }
    // else if(this.model.toolGr=="Compute-Type-Name")
    // {
    //   this.propertyValueList=['VALUE','STANDARD','PERFORMANCE','POWER','GRAPHICS','POWERPRO','GRAPHICSPRO'];    
    // }
    // else if(this.model.toolGr=="Running-Mode")
    // {
    //   this.propertyValueList=['AUTO_STOP','ALWAYS_ON'];    
    // }
    // else if(this.model.toolGr=="Root-Volume-Size")
    // {
    //   this.propertyValueList=['80'];    
    // }
    // else if(this.model.toolGr=="User-Volume-Size")
    // {
    //   this.propertyValueList=['10','50','100'];    
    // }
    

  }
propertyNameApi(name){
  // debugger
  this.apiService.Admin_getPropertyNameValues(name).subscribe((res: any) => {
    // debugger
    this.propertyNameValueList=res.body.property_name
    // [Location,Disktype,size]


  },
  (error:HttpErrorResponse) => {}
  )
}
  onChange($event) {
    // debugger
    this.VdiDropdownList=[];
    this.propertyValueList=[]
    // this.propertyNameValueList=[]


    this.project_ScreenName ="";
    this.project_dropDown_category ="";

    let ScreenName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_ScreenName = ScreenName; //screen_name

    this.model.toolGr = ""
  this.propertyNameApi(this.project_ScreenName)
  
    if(ScreenName=="Workbench-VDI")
    {
    //  this.VdiDropdownList=['Location','DiskType','Size'];
     this.project_dropDown_category = "Azure-VDI";
    }
    else if(ScreenName=="Workbench-VM")
    {
      // this.VdiDropdownList=['Location','DiskType','Size'];
     this.project_dropDown_category = "Azure-VM";
    }
    else{
      // THis codition for AWS-Workspace drop down
      // this.VdiDropdownList=['Compute-Type-Name','Running-Mode','Root-Volume-Size','User-Volume-Size'];
     // this.project_dropDown_category = "Aws-Workspace";
     this.project_dropDown_category = "Workbench-Workspace";
    }
  
  }

  deleteRecord()
  {
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }
  }

  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.project_selectedcheckbox = false;
   // debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }

  DeleteConfig(){
   // debugger;
    var deleteBody: any;
    this.check=[]
    // console.log(this.VdiConfig_details);
    
    this.VdiConfig_details.forEach(element => {
      this.check.push(element.vdi_dropdown_id)
      
    });
    deleteBody = {
      "master_dropdown_ids":this.check
   
    }
   // debugger;
    // console.log(deleteBody, "deleteBody")
   
    this.apiService.Admin_VdiDropdowDeleteData(deleteBody).subscribe((res: HttpResponse<any>) => {
   //  debugger;
      if (res.status == 200) //doesnt work
      {
        
       
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-vdi-dropdown-config']);
        });
        this.alertService.success(res.body.message, this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 446)
      {
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })

  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTriggerToolGroup.unsubscribe();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected_old() {
    const numSelected = this.selection.selected.length;
    //const numRows = this.dataSource.data.length;
    //return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected_old() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected_old() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Name + 1}`;
  }

  //displayedColumns: string[] = ['Name', 'ID', 'Description', 'select'];



  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }

  setToolGroupForm(getObj){
    // debugger;
    this.propertyNameApi(getObj.screen_name);
    this.properyValueApi(getObj.screen_name,getObj.Name)
  // 
  // this.model.toolDescription = getObj.Description;
  this.model.toolGr=getObj.Name;

    this.model.ProptValue = getObj.Description;
    this.model.toolId=getObj.ID;
    this.model.toolGr=getObj.Name;
    this.model.screenname=getObj.screen_name;
    if(this.model.screenname=="Workbench-VDI")
    {
    //  this.VdiDropdownList=['Location','DiskType','Size'];
     this.project_dropDown_category = "Azure-VDI";
    }
    else if(this.model.screenname=="Workbench-VM")
    {
      // this.VdiDropdownList=['Location','DiskType','Size'];
     this.project_dropDown_category = "Azure-VM";
    }
    else{
      // THis codition for AWS-Workspace drop down
      // this.VdiDropdownList=['Compute-Type-Name','Running-Mode','Root-Volume-Size','User-Volume-Size'];
     // this.project_dropDown_category = "Aws-Workspace";
     this.project_dropDown_category = "Workbench-Workspace";
    }
    this.model.toolGr = getObj.Name;    

    // if(this.model.toolGr=="Location")
    // {
    //   // this.propertyValueList=['centralindia','eastus'];    
    // }
    // else if(this.model.toolGr=="DiskType" && this.model.screenname=="Workbench-VM")
    // {
    //   this.propertyValueList=['Premium_LRS','Standard_LRS'];    
    // }
    // else if(this.model.toolGr=="Size" && this.model.screenname=="Workbench-VM")
    // {
    //   this.propertyValueList=['Standard_D2s_v3','Standard_D4s_v3','Standard_D8s_v3','Standard_D16s_v3','Standard_D32s_v3','Standard_D48s_v3','Standard_D64s_v3'];    
    // }
    // else if(this.model.toolGr=="DiskType" && this.model.screenname=="Workbench-VDI")
    // {
    //   this.propertyValueList=['Premium_LRS','Standard_LRS'];    
    // }
    // else if(this.model.toolGr=="Size" && this.model.screenname=="Workbench-VDI")
    // {
    //   this.propertyValueList=['Standard_D2s_v3','Standard_D4s_v3','Standard_D8s_v3','Standard_D16s_v3'];   
    // }
    // else if(this.model.toolGr=="Compute-Type-Name")
    // {
    //   this.propertyValueList=['VALUE','STANDARD','PERFORMANCE','POWER','GRAPHICS','POWERPRO','GRAPHICSPRO'];    
    // }
    // else if(this.model.toolGr=="Running-Mode")
    // {
    //   this.propertyValueList=['AUTO_STOP','ALWAYS_ON'];    
    // }
    // else if(this.model.toolGr=="Root-Volume-Size")
    // {
    //   this.propertyValueList=['80'];    
    // }
    // else if(this.model.toolGr=="User-Volume-Size")
    // {
    //   this.propertyValueList=['10','50','100'];    
    // }
    


    // this.model.ProptValue = getObj.Description;
    this.project_id_scmEdit = getObj.ID;
  }

  //Update row data
  updateData(){
    
// debugger;
for (var i = 0; i < this.propertyValues.length; i++) {
  if(this.model.ProptValue==this.propertyValues[i].property_value){
    this.displayName=this.propertyValues[i].display_name
    console.log("dghbjsnkjsndka");
    
  }
  }
    if (  this.model.screenname == undefined || this.model.screenname == " " || this.model.screenname == "" 
      || this.model.toolGr == undefined || this.model.toolGr == " " || this.model.toolGr == ""    
      || this.model.ProptValue == " " || this.model.ProptValue == "" || this.model.ProptValue == undefined
     ) {
      this.alertService.error("Please enter details In all the fields", this.options);

    }
    else {
      var saveBody: any;
      saveBody = {
          "dropdown_id":this.model.toolId,
          "dropdown_category": this.project_dropDown_category,
          "dropdown_display_name":this.displayName,
          //"screen_name":this.project_ScreenName,
          "dropdown_name": this.model.toolGr,
         // "dropdown_value": this.model.toolDescription
          "dropdown_value": this.propertyValue,//this.model.ProptValue
          "updated_by":localStorage.getItem('mail').toLowerCase()
      }
      this.apiService.Admin_VdiDropdownUpdateData(saveBody).subscribe((res: HttpResponse<any>) => {
      // debugger;
        if (res.status == 200) //doesnt work
        {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi-dropdown-config']);
          });
          this.alertService.success(res.body.message, this.options);

        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446||error.status == 430) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
    
  

  // On click ID get row data
  getRecord(row) {
  //  debugger;
    this.toolGrObj = row;
    try {
      localStorage.setItem('toolGrObj', JSON.stringify(this.toolGrObj));
      localStorage.setItem('formMode', "Edit");
    }
    catch {
      return throwError('Data not found');
    }
    this.formMode = localStorage.getItem('formMode')
    if(this.formMode == "Edit"){
      this.ToolGroupDisable = true
      this.PropertyValueDisable = true;
      var getObj = JSON.parse(localStorage.getItem('toolGrObj'));
      // console.log("getObj", getObj);
      this.setToolGroupForm(getObj);
      this.editMode = true;
      this.ScreenNameDisable=true
      this.updateButtonDisable=true;
     // this.getToolIdDetails(row);
    }
    this.showToolGroupForm = true;
  // this.showToolGroupEditTable=true;
   this.showToolGroupMasterTable=true;
    //this.apiService.SCMToolDetails.next(this.scmObj)
   // localStorage.setItem('toolGrObj', JSON.stringify(this.toolGrObj));
    //localStorage.setItem("formMode", "Edit");
    //this.router.navigate(['/admin-tool-config']);
  }

 

  openDialog() {
  
  }

  addToolGroup() {

    this.editMode = false; // For Add
    this.showToolGroupForm = true;
    this.ToolGroupDisable=false;
    this.PropertyValueDisable = false;
    this.ScreenNameDisable=false;
    this.showToolGroupEditTable=false;
    this.showToolGroupMasterTable=true;

    //this.ScreenDropdownList=[];
  
    //this.VdiDropdownList=[];
    //this.propertyValueList=[];
    this.model.toolGr = "";
    //this.model.toolDescription = ""   
    this.model.ProptValue = ""   
    this.model.screenname="";

    this.VdiDropdownList=[];
    this.propertyValueList=[];
  }
  closeToolGroup() {
    this.clearToolGroupForm();
    this.showToolGroupForm = false;
    this.editMode = false;
    this.showToolGroupMasterTable=true;
  }
  cancelok(){
    this.clearToolGroupForm();
    localStorage.setItem("refreshJira", "true");
  //   this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/admin-jira']);
  // });
  if(this.nextUrl==undefined){
    this.refreshToolGroup()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  clearToolGroupForm() {
   
    this.model.toolGr = "";
    //this.model.toolDescription = ""   
    this.model.ProptValue = ""   
    this.model.screenname="";

    this.VdiDropdownList=[];
    this.propertyValueList=[];
  }

  CheckToolNameDuplicationUpdate() {
    // debugger
    for (var i = 0; i < this.propertyValues.length; i++) {
      if(this.model.ProptValue==this.propertyValues[i].property_value){
        this.displayName=this.propertyValues[i].display_name
        // console.log("dghbjsnkjsndka");
        
      }
      // console.log(this.propertyValues[i]);
      
      
    }   // debugger
    if ( this.model.screenname == undefined || this.model.screenname == " " || this.model.screenname == "" 
      || this.model.toolGr == undefined || this.model.toolGr == " " || this.model.toolGr == ""
      || this.model.ProptValue == " " || this.model.ProptValue == ""  || this.model.ProptValue == undefined
      ) {

      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
// debugger
     
      let validateBody = {
      //  "vdi_dropdown_id":this.model.toolId,
       // "dropdown_category": this.project_dropDown_category,
        "screen_name": this.model.screenname,
        "dropdown_name": this.model.toolGr,
        "dropdown_display_name":this.displayName,
        "dropdown_value": this.model.ProptValue
    }

    // debugger

      this.apiService.Admin_CheckVDIConfigValidation(validateBody).subscribe((res: HttpResponse<any>) => {
      // debugger
        // console.log("Inside Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.updateData();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {

        if (error.status == 446) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          // this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else



  }
  
  CheckToolNameDuplication() {
    // debugger
    for (var i = 0; i < this.propertyValues.length; i++) {
      if(this.model.ProptValue==this.propertyValues[i].property_value){
        this.displayName=this.propertyValues[i].display_name
        // console.log("dghbjsnkjsndka");
        
      }
      // console.log(this.propertyValues[i]);
      
      
    }   // debugger
    if ( this.model.screenname == undefined || this.model.screenname == " " || this.model.screenname == "" 
      || this.model.toolGr == undefined || this.model.toolGr == " " || this.model.toolGr == ""
      || this.model.ProptValue == " " || this.model.ProptValue == ""  || this.model.ProptValue == undefined
      ) {

      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
// debugger
     
      let validateBody = {
      //  "vdi_dropdown_id":this.model.toolId,
       // "dropdown_category": this.project_dropDown_category,
        "screen_name": this.project_ScreenName,
        "dropdown_name": this.model.toolGr,
        "dropdown_display_name":this.displayName,
        "dropdown_value": this.model.ProptValue
    }

    // debugger

      this.apiService.Admin_CheckVDIConfigValidation(validateBody).subscribe((res: HttpResponse<any>) => {
      // debugger
        // console.log("Inside Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.VdiConfigSaveData();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {

        if (error.status == 446) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else



  }


  VdiConfigSaveData() {
  //  debugger;

    if ( this.model.screenname == undefined || this.model.screenname == " " || this.model.screenname == "" 
      || this.model.toolGr == undefined || this.model.toolGr == " " || this.model.toolGr == ""             
      || this.model.ProptValue == " " || this.model.ProptValue == ""  || this.model.ProptValue == undefined
      ) {
      // this.openSnackBarDiaLog()
      //this.apiService.castToolDialog.next(" Please enter details In all the fields ")

      this.saveButtonDisable = true;
      this.alertService.error("Please enter details In all the fields", this.options);

      setTimeout( ()=>{
        this.saveButtonDisable = false;
        }, 2500)
    }
    else {
      var saveBody: any;
      // debugger
        // ##### Code for Save Funtionality ########
        saveBody = {
          
          "dropdown_category": this.project_dropDown_category,
          "dropdown_display_name":this.displayName,
          "screen_name":this.project_ScreenName,
          "dropdown_name": this.model.toolGr,
          "dropdown_value": this.model.ProptValue,
          "created_by":localStorage.getItem('mail').toLowerCase()
        }
      
        console.log(saveBody);
    //  debugger
      this.apiService.Admin_VdiDropdownConfigSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
    //    debugger;
        // console.log(res.status);
        if (res.status == 200) //doesnt work
        {
          //  this.openSnackBarDiaLog()
          //this.apiService.castToolDialog.next(" Data Saved Successfully")  
        
          //this.clearAllSCMDetails();
          // this.router.navigate(['/admin-tool-group']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi-dropdown-config']);
          });
          this.alertService.success(res.body.message, this.options);

        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 441||error.status == 430) //doesnt work
        {
          //  this.openSnackBarDiaLog()
          // this.apiService.castToolDialog.next(" Please Enter Valid Inputs ")    
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
          // this.openSnackBarDiaLog()
          // this.apiService.castToolDialog.next(" Invalid Input ")
          // this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
}
