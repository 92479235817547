   

   <div style="margin-top:150px;" *ngIf="!loggedIn">
           <img  style="display: block; margin-left: auto; margin-right: auto; height: 30%;width: 30%;" class="img-fluid" src="/assets/img/logo/Canvas-Workplace_without-BG.png"alt="Logo"><br><br>
     
      <h2 style="text-align: center;">Welcome to Canvas Workplace. </h2>
      <h2 style="text-align: center;">Your are currently not logged in. Click <u style="cursor: pointer; color:blue" (click)="login()">here</u> to login.</h2>
   </div>
   <!-- <button data-toggle="modal" hidden="true" class="B-none" id="CallModel1"

   data-target="#confirmationModal"></button>

   <button data-toggle="modal" hidden="true" class="B-none" id="CallModelUpdateRole"

   data-target="#confirmationModalUpdteRole"></button>

   <div class="modal fade" id="confirmationModalUpdteRole" tabindex="-1" role="dialog"

   aria-labelledby="confirmationModalUpdteRole" aria-hidden="true">

   <div class="modal-dialog" role="document">

     <div class="modal-content pop-up">

       <div class="modal-header-popup">

         <span class="vertical-line mx-3"> </span>

         <span class="header mb-0"> Canvas Workplace</span>

         <span data-dismiss="modal" class="material-icons close-icon">

           close

         </span>

       </div>

       <hr class="margin-class" />

       <div class="modal-body">

         You are not authorized to use this portal. Please click 'OK' to logout.

       

       </div>

       <div class="modal-footer">

         

         <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"

         (click)='Unauthorised()'>Ok</button>

       </div>

     </div>

   </div>

 </div> -->
   <div *ngIf="isUserFlag">
    <app-main-nav *ngIf="authService.instance.getActiveAccount()" ></app-main-nav>
   </div>
  
   <div *ngIf="isAdminFlag">
    <app-admin-main-nav *ngIf="authService.instance.getActiveAccount()" ></app-admin-main-nav>
   </div>


