import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { element } from 'protractor';

@Component({
  selector: 'app-teams-artifacts',
  templateUrl: './teams-artifacts.component.html',
  styleUrls: ['./teams-artifacts.component.scss']
})
export class TeamsArtifactsComponent implements OnInit {
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  projectId: any;
  artifactForm: boolean = false;
  recurrencetime: any = ["00:00", " 00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", " 12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"];
  meetingNameArray: any = []
  meetingNameValue: any;
  meetingDetail: any = "-";
  //orgName: any;
  //meetingName: any;
  //reportType: any;
 // reportTime: any;
  //projName: any;
 // roomName: any;
  existingMSArtifacts: boolean = false;
  artifactTable: any = [];
  meetingArtifacts: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  reportTypeArray: any = ["Defect Report", "Agile Metrics", "Resource Utilization"];
  vendor: any = ["Jira", "Azure DevOps"];
  orgNameArray: any = ["LTICanvas"];
  projNameArray: any = ["Canvas"];
  teamNameArray: any = [];
  spaceNameArray: any = [];
  repoVendorValue: any = "Azure DevOps";
  // teamName: any;
  //repoVendor: any;
  //scheduleTime: any;
  schedulerTimeUTC: any = [];
  firstFormGroup: FormGroup;


  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,
    public router: Router, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');
    this.getMeetingName();
    this.getTeamName();
    this.getMSExistingArtifacts();
    this.firstFormGroup = this._formBuilder.group({
      meetingName: ['', Validators.required],
      repoVendor: ['', Validators.required],
      orgName: [''],
      teamName: ['', Validators.required],
      roomName: ['', Validators.required],
      reportTime: ['1', Validators.required],
      projName: ['', Validators.required],
      reportType: ['', Validators.required],
      scheduleTime: ['00:00'],
    });
  }
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });
  }
  showMeetingInfo() {
    const dialogRef = this.dialog.open(DialogMeetingInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });
  }
  showArtifactForm() {
    this.artifactForm = true
  }

  getMeetingName() {
    let bodyForMeeting = {
      //"user_email": localStorage.getItem('mail').toLowerCase()
      "projectName":localStorage.getItem('projectName')
    }
    this.apiService.getMSMeetingName(bodyForMeeting).subscribe(result => {
     //console.log(result)
      this.meetingNameArray = result
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  meetingNameBody(meetingNameBody) {
   // console.log(meetingNameBody)
    this.meetingNameArray.forEach(meeting => {
      if (meeting.MeetingName == meetingNameBody) {
        localStorage.setItem("MeetingName",meetingNameBody);
        this.meetingDetail = meeting.MeetingType
      }
    })
  }
  getMSExistingArtifacts() {
    this.apiService.getMSArtifacts(this.projectId).subscribe((result: any) => {
      if (result.length) {
        this.existingMSArtifacts = true
        this.artifactTable = result
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }
  getTeamName() {
    // var user_email = localStorage.getItem('mail').toLowerCase()
    var user_email = localStorage.getItem('mail')

    this.apiService.getMSTeamNames(user_email).subscribe(result => {
      // console.log("result",result);
      this.teamNameArray = result
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to fetch data.", this.options);
        throw error;
      }

    })
  }
  get teamName() {
    return this.firstFormGroup.get('teamName');
  }
  get repoVendor() {
    return this.firstFormGroup.get('repoVendor');
  }
  get reportType() {
    return this.firstFormGroup.get('reportType');
  }
  get roomName() {
    return this.firstFormGroup.get('roomName');
  }
  get orgName() {
    return this.firstFormGroup.get('orgName');
  } 
  get meetingName() {
    return this.firstFormGroup.get('meetingName');
  }
  get projName() {
    return this.firstFormGroup.get('projName');
  }
  get reportTime() {
    return this.firstFormGroup.get('reportTime');
  }
  get scheduleTime() {
    return this.firstFormGroup.get('scheduleTime');
  }
  getRepoVendorValue() {
    this.projNameArray = []
    this.repoVendorValue = this.repoVendor.value
    if (this.repoVendorValue == 'Azure DevOps') {
      this.projNameArray = ["Canvas"]
    }
    else {
      this.apiService.getJiraProjectForArtifacts().subscribe(response => {
        this.projNameArray = response
      }, (error: HttpErrorResponse) => {
        if (error.status == 438) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })

    }
  }
  getSpaceNameForArti() {
    this.spaceNameArray = [];
    //console.log(this.teamName.value, "teamId");
    var teamId = this.teamName.value;
     
    this.apiService.getChannelNamesforArtifacts(teamId).subscribe(result => {
      this.spaceNameArray = result
     // console.log("result", result);
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to fetch data.", this.options);
        throw error;
      }

    })
  }
 
  executeMSScheduler() {
    this.schedulerTimeUTC = this.scheduleTime.value.split(':');
    var schedulerTimeHourUTC = new Date();
    schedulerTimeHourUTC.setHours(this.schedulerTimeUTC[0], this.schedulerTimeUTC[1]).toLocaleString();
    let body = {
      "MeetingArtifactsScheduleTime": schedulerTimeHourUTC.getUTCHours() + ":" + schedulerTimeHourUTC.getUTCMinutes(),
      "userEmail" : localStorage.getItem('mail').toLowerCase()
    }
    this.scheduleTime.setValue("");
    this.apiService.executeMSScheduler(body).subscribe((result: any) => {
      if (result.Status == "Success") {
        this.alertService.success("Scheduler started successfully", this.options)
      } else {
        this.alertService.error("Unable to execute scheduler ", this.options)
      }
      this.artifactForm = true
    })
  }
  artifactsMSValue() {
    let body = {
      "projectId": this.projectId,
       "MeetingName": this.meetingName.value,
       "ReportVendor": this.repoVendor.value,
       "ReportType": this.reportType.value,
       "TriggerEventBefore": this.reportTime.value,
       "TeamID": this.teamName.value,
       "SpaceName": this.roomName.value,
       "ProjectName": this.projName.value,
       "OrganizationName": this.orgName.value,
       "userEmail" : localStorage.getItem('mail').toLowerCase()
    }
    //console.log(body)

    this.apiService.scheduleMSMeetingArtifact(body).subscribe((response: any) => {
      //console.log("response",response);
      if (response.Status == "Success") {
        this.alertService.success('Success', this.options)
        this.existingMSArtifacts = true
        this.artifactForm = false
        this.artifactTable.push(response.Data)
      }
      else {
        //  this.alertService.error("Error setting Details", this.options);
       this.existingMSArtifacts = true
        this.artifactForm = false
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }
  clearArtifacts() {
    this.artifactForm = false
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'meeting-info.html',
  styleUrls: ['./teams-artifacts.component.scss']
})
export class DialogMeetingInfoDialog {
  
  meetingNameArray: any = []
  projectPopName:any;
  meetingName:any;
  startDate:any;
  meetingInfoName:any;
  meetingInfoType:any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    public dialogRef: MatDialogRef<DialogProjectInfoDialog>, public alertService: AlertService, private apiService: AuthService,public dialog: MatDialog
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  
    this.meetingName =localStorage.getItem('MeetingName');
    this.apiService.getMSMeetingInfo(this.meetingName).subscribe(result => {
     // console.log(result)
      this.meetingNameArray = result
      this.startDate=this.meetingNameArray[0].StartDate;
      this.meetingInfoName=this.meetingNameArray[0].MeetingName;
      this.meetingInfoType=this.meetingNameArray[0].MeetingType;
      this.projectPopName=this.meetingNameArray[0].ProjectName;
    }, (error: HttpErrorResponse) => {
      if (error.status == 438) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
}



exit() {
  this.dialogRef.close();
}

}
