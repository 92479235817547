<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up"  >
    <div class="" >
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0"> Network Details :</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <!-- <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Resource Group Arn :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.resource_group.arn || "-" }}</span>
            </div>
        </div> -->

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Resource Group name : </span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.resource_group.name || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Security Group id :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.security_group.id || "-"}}</span>
            </div>
        </div>
      

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Security Group name :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.security_group.name || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Subnet id :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.subnet.id}}</span>
            </div>
        </div>
        
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Subnet name :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.subnet.name || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">VPC  id :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.vpc.id || "-"}}</span>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">VPC name :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.vpc.name || "-"}}</span>
            </div>
        </div>


        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Tool description :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.tool_description || "-"}}</span>
            </div>
        </div>
    </div>
</div>

