<div class="pop-up"   >
    <div class="" >
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0"> Running Details:</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />

    <div class="popup-container">
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Description:</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo || "-" }}</span>
            </div>
        </div>

      


   

    </div>
</div>
