import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';

export interface scmTool {
  toolName: string;
  ID: string;
  projectURL: string;
  toolGroup: string;
  projectToken: string;
}

@Component({
  selector: 'app-admin-github',
  templateUrl: './admin-github.component.html',
  styleUrls: ['./admin-github.component.scss']
})
export class AdminGithubComponent implements OnInit {

  testConnDisable = false;
  saveConnDisable = false;
  //submitted = false;

  // Variable for Save/Edit body
  project_Token: string;
  project_URL: string;
  project_toolGroupName: string;
  project_id_scmEdit: string;


  token: any;
  //projectURLVal: any;
  toolName: any;

  hero: any = {};

  scmToolDetails: scmTool;
  selectedToolvalue: any;

  formMode: string = "";

  showGitHubForm:boolean = false;

  toolGr: any;
  proURL: any;
  proToken: any;

  powers = ['Citi', 'Chevron'];

  //hero = {name: '', alterEgo: '', power: this.powers[0]};

  userData = {
    username: '',
    email: '',
    country: '',
    gender: ''
  };
  submitted = false;

  // tools: any = ['Gitlab', 'Github']
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  constructor(private formBuilder: FormBuilder, 
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { }


    ngOnInit(): void {
      this.formMode = localStorage.getItem('formMode')
      if (this.formMode == "Edit") {
       // var getObj = JSON.parse(localStorage.getItem('scmToolObj'));
        //console.log("SCM Tool Info ");
        //console.log(getObj);
        //this.setScmEditing(getObj);
      }
    }

    addGitHub(){
      this.showGitHubForm = true;
    }
    closeGitHub(){
     // this.clearGitLabFormDetails();
      this.showGitHubForm = false;
    }
  
      setScmEditing(scmObj: scmTool) {
      this.hero.toolGr = scmObj.toolGroup;
      this.hero.proURL = scmObj.projectURL;
      this.hero.proToken = scmObj.projectToken;
  
    }
  
    selectedTool(e) {
  
    }
  
    onSubmit() {
      if (this.hero.proURL == undefined || this.hero.toolGr == undefined
        || this.hero.toolGr == "" || this.hero.toolGr == " " || this.hero.proToken == undefined
        || this.hero.proURL == " " || this.hero.proToken == " ") {
        this.alertService.error("Please enter details In all the fields",this.options);
      }
      else {
        var url:string = this.hero.proURL;
        var token:string = this.hero.proToken;
        var toolGroup:string = this.hero.toolGr;
        url = url.trim();
        token = token.trim();
        toolGroup = toolGroup.trim();
        this.scmTestConnection(url,token);
      }
    }
  
  
    scmTestConnection(project_URL,project_Token) {
       let testConnBody = {
          "tool_name":"Gitlab",
          "tool_url":project_URL,
          "auth_token":project_Token
        }
      this.apiService.Admin_ScmTestConnection(testConnBody).subscribe((res: HttpResponse<any>) => {
        //
      //  console.log(res);
        if (res.status == 200) //doesnt work
        {
          this.alertService.success("Test Connection Successfully. Please Save the Connection",this.options);
          this.testConnDisable = true;
          this.saveConnDisable = false;
        }
        if (res.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);
        }
      },(error:HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.message,this.options);  
        }
        else if (error.status == 403){}
        else
        {
        this.alertService.error("Invalid Input",this.options);  
        throw error;
        }
      })
  
    }
  
    openSnackBarDiaLog() {
      const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
        width: '32%',
        height: '23%',
        disableClose: true,
      });
    }
  
  
    clearAllSCMDetails() {
      this.hero.proURL = "";
      this.hero.proToken = "";
      this.hero.toolGr = "";
    }
  
    SCMConnSave() {
  
      if (this.hero.proURL == undefined || this.hero.toolGr == undefined || this.hero.toolGr == " "
        || this.hero.toolGr == "" || this.hero.proURL == " " || this.hero.proToken == " "
        || this.hero.proToken == undefined) {
        this.alertService.error("Please enter details In all the fields",this.options);
      }
      else {
        var saveBody: any;
        this.project_URL = this.hero.proURL;
        this.project_Token = this.hero.proToken;
        this.project_toolGroupName = this.hero.toolGr;
        if (this.formMode == "Edit") {
          // ##### Code for Edit Funtionality ########
          saveBody = {
              "_id": this.project_id_scmEdit,
              "tool_category":"scm",
              "tool_name":"Gitlab",
              "tool_group_name":this.project_toolGroupName, 
              "tool_url":this.project_URL,
              "tool_auth":{
                  "auth_token":this.project_Token
              }
          }
          
        }
        else {
  
          // ##### Code for Save Funtionality ########
          saveBody = {
              "tool_category":"scm",
              "tool_name":"Gitlab",
              "tool_group_name":this.project_toolGroupName, 
              "tool_url":this.project_URL,
              "tool_auth":{
                  "auth_token":this.project_Token
              }
          }
        }
        this.apiService.Admin_ScmSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) //doesnt work
          {
           this.alertService.success("Data Saved Successfully",this.options);
            this.testConnDisable = false;
            this.router.navigate(['/admin-scm-tool']);
  
          }
        } ,(error) => {
          this.alertService.error("Invalid Data, Data Not Save Successfully",this.options);
          throw error;
        })    
  
      }//else
  
    }

}
