<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
  </ngx-spinner>
    <div class="inner-top-container">   
      <div class="row">
        <div class="col-md-6">        
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
              <li class="breadcrumb-item link-blue"><a routerLink="/reports">Live Monitoring</a></li>
              <li class="breadcrumb-item active" aria-current="page">Alert Description</li>
            </ol>
          </nav>
        </div>
        <div class="col-md-5 p-md-0 my-auto">
  
        </div>
        <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/reports" class="float-right text-light"><i class="fas fa-times"></i></a>
        </div>
      </div>
      
      <!-- <div class="row">
        <div class="col-sm-3">
          <h1 class="text-light mb-0">
            <img width="40" src="assets/img/Group 3610.png">
            <div class="vertical-line mx-3"> </div> <span>Reports</span>  
          </h1>
        </div>
  
      </div> -->
      </div>
      <div class="inner-mid-container">
        <div class="container-fluid">
          <div class="row">
               <span class="header mb-0"> Alert Details</span>
      </div>
      <hr class="hr-style">
      <div class="row ">
          <div class="col-md-1">
              <span class="field-name"> Description </span>
          </div>
          <div class="col-md-11">
                  <span> : &nbsp;{{alertInfo.description}}</span>
                   </div>
      </div>
      
      <div class="row">
          <div class="col-md-1">
              <span class="field-name">Next Step </span>
          </div>
          <div class="col-md-11">
                  <span> : &nbsp;{{alertInfo.next_step}}</span>
          </div>
      </div>
      
      <div class="row ">
          <div class="col-md-1">
              <span class="field-name">Status </span>
          </div>
          <div class="col-md-11">
                  <span> : &nbsp;{{alertInfo.status | titlecase}}</span>
          </div>
      </div>
      
      <div class="row ">
          <div class="col-md-1">
              <span class="field-name"> ID </span>
          </div>
          <div class="col-md-11">
                  <span> : &nbsp;{{alertInfo.id}}</span>
          </div>
      </div>
      
      
        <div class="row">            
          <div class="col-12 col-md-2 mx-md-3 mt-3">
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="picker1" [max]="alertObservationEndDate.value" [formControl]="alertObservationStartDate">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-2 mx-md-3 mt-3">
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="picker2"
                [min]="alertObservationStartDate.value" [formControl]="alertObservationEndDate">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-6 col-md-1 text-center mt-3">
            <button type="button" class="btn users-button" (click)="filterAlertObservation()">
              <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                  title="Filter data">search</mat-icon>
            </button>
          </div>
          <!-- <div class="col-6 col-md-1 text-center mt-3">
              <button type="button" class="btn users-button" (click)="resetAlertObservation()">
                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                    title="Reset data">sync</mat-icon>
              </button>
            </div> -->
        </div>

        <div *ngFor="let tab of tempValue2; let i=index">
          <div class="row">
            <span class="header mb-4"> {{tempValue1[i]}}</span>
          </div>
        <div class="table-responsive"> 
          <table class="table fix-table mt-4" datatable >
              <thead>
                <tr>
                <th *ngFor="let val of tab[0] | keyvalue">
                  {{val.key}}
                </th>
                <th>Device</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of tab">
                  <td *ngFor="let td of data | keyvalue; let j=index ">
                    {{td.value}}
                  </td>
                  <td>{{alertInfo.source}}</td>

                </tr>
              </tbody>
            </table>
          </div> 
        </div>
        </div>
      </div>
     
     
  </div>
  
  