import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from "ngx-spinner";
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
export function MatchValues(controlName: string, values: string[]) {
  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];

    let invalidWords = [];
    values.forEach(element => {
      if (control.value !=null && control.value.toLowerCase() == element) {
        invalidWords.push(element);
      }
      else {
      }
    });

    if (control.errors) {

      // return if another validator has already found an error on the control
      return;

    }
    // set error on control if validation fails
    if (invalidWords.length > 0) {
      control.setErrors({ notMatch: true });
    } else {
      control.setErrors(null);
    }
    if (control.errors) {

      // return if another validator has already found an error on the control
      return;

    }
    //set error on control if control ends in . 
    let position = control.value.indexOf('.')
    if (position == control.value.length - 1) {
      control.setErrors({ containsDot: true });
    } else {
      control.setErrors(null);
    }
  }

}
@Component({
  selector: 'app-story-board',
  templateUrl: './story-board.component.html',
  styleUrls: ['./story-board.component.scss']
})
export class StoryBoardComponent implements OnInit {
  projectId;
  leadEmailId;
  projectName;
  projectKey: string;
  members: any = [];
  username: any = [];
  toolName;
  showCatalog: boolean = false;
  secondFormGroup: FormGroup;
  tableRepoArray: any = [];
  Role:string="";
  showTable: boolean = false;
  showForm: boolean = false;
  showUserRow: boolean = false;
  showAddProject: boolean = true;
  tableArr = [];
  memberString = [];
  selectedUserArray: any = []
  toolGroup: any = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  ELEMENT_DATA: ProjectTable[];
  displayedColumns: string[] = ['repoName', 'toolInstance', 'status', 'members', 'action'];
  //dataSource = new MatTableDataSource<ProjectTable>(this.ELEMENT_DATA);

  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  catalog_id: string;
  catalog_name: string;
  userMailId: string;
  profile: string;
  userRole: string;
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  filterStatus= 'Active';
  downloadFile: any;
  refreshDisable: boolean=true;
  storyboard_flag: boolean = false;
  idproject: string;
  template_id: any;
  template_name: any;
  memberArraysoft: any;
  memberArraysoftJira: any;
  mailId: any;

  constructor(public alertService: AlertService, private spinner: NgxSpinnerService,public dialog: MatDialog, private apiService: AuthService, 
     public router: Router, private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.idproject = localStorage.getItem('projectId');
    this.apiService.getEmail().subscribe((res:any) => {
      // console.log(res);
      this.mailId=res.emailRecord.Mail
      


    })

    this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
      // debugger

      this.template_id = data[0].template_id;
      this.template_name = data[0].template_name;
      
    }) 

    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.alertService.yesClickedEvent
    .subscribe((data:string) => {
      this.showForm = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.secondFormGroup.reset();
    });

    this.alertService.workbenchYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/workBench'])
    });

    this.alertService.homeYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/projectSummary'])
    });

    let newCatalog_id=localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
   
      if (data.storyboard_tools.length == 0) {
        this.storyboard_flag = true;
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })
  
    this.projectId=localStorage.getItem('projectId');
    this.catalog_id=localStorage.getItem('catalogId');
   
    this.catalog_name=localStorage.getItem('catalogName');

    this.apiService.componentName.next("jira");

    
    this.projectName=localStorage.getItem('projectName');

    this.apiService.leadEmailId.subscribe(leadEmailId => {
      this.leadEmailId = leadEmailId;
    })

    this.apiService.toolSelectedFromDialog.subscribe(data => {
      this.toolName = data
    })

   
     //this.getJiraProject()
      this.refreshJira(this.filterStatus)
      let values = ["test","admin","user"]

      this.secondFormGroup = this._formBuilder.group({
        jiraProjName: ['', [Validators.required, Validators.pattern('^([a-zA-Z_0-9])+$'),Validators.maxLength(30)]],
        toolInstance: ['', Validators.required]
      } ,{
        validator: [ MatchValues('jiraProjName', values)]
      });
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }
  }

  getJiraProjNameErrorMessage(){
    return this.secondFormGroup.controls.jiraProjName.hasError('pattern') ?
      'Project name must include only alphabets ,numbers and _': this.secondFormGroup.controls.jiraProjName.hasError('maxlength') ?
      'Maximum length can be 30 characters':this.secondFormGroup.controls.jiraProjName.hasError('notMatch') ?
      'This Project name is not allowed':
      'Project Name is required'
  }

  ngAfterViewInit(){
    this.dtTrigger.next();
  }
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '26%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.createProject();
        }
      }
    );
  }

  getJiraProject() { 
    this.toolGroup = [];
    this.apiService.getCatalogById(this.catalog_id, 'StoryBoard').subscribe((result: any) => {
      result.Tool_list.forEach(element => {
        if (element) {
        this.toolGroup.push(element.tool_instance_details.storyboard_tool_instance.tool_instance_name)
        }
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 434){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available", this.options);
      throw error;
       }
      
       })
  }
  checkToolData(){
    let newCatalog_id=localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
   
      if (data.storyboard_tools.length == 0) {
        this.storyboard_flag = true;
          let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
                element.click();
      }
      else {
        this.getJiraProject();
        this.addProject();
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })
  }
 
  getStatus(status){
    this.filterStatus = status;
  this.refreshJira(this.filterStatus)
  }

  downloadData(type){
    if(type=='pdf'){
      this.apiService.downloadJiraInPdf(this.projectId,this.filterStatus).subscribe(data=>{
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName+'-Jira.pdf'
        link.click();
      })
    }
    else if(type=='excel'){
      this.apiService.downloadJiraInExcel(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-Jira.xlsx';
        link.click();        
      })
    }
    else if(type=='csv'){
      this.apiService.downloadJiraInCsv(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-Jira.csv';
        link.click();        
      })
    }

  }

  refreshJira(status) {
    this.dtOptions = {
      order: []
    };
    this.spinner.show()
    this.tableRepoArray = [];
    this.refreshDisable = false;
    setTimeout(() => {
      this.refreshDisable = true;
    }, 2000)
    this.apiService.getJiraProjects(this.projectId,status).subscribe((data: any) => {
            //this.alertService.success(data.status, this.options)

      let memberArray = [];
      if(data.length==0){
        this.spinner.hide();
      }
            data.forEach(project => {
              memberArray = [];
              project.project_members.forEach(member => {
                  let memberObj = {
                    name: member.user_name,
                    email: member.email_id,
                    role: member.role_name,
                    status : member.status
                  }
                  
                  if(member.status!='offboarded'){
                    memberArray.push(memberObj)
                  }
              })
              let tableObj =
              {
                "request_id": project.request_id,
                "repoName": project.jira_project_name,
                "repoUrl":  project.planning_project_url,
                "toolInstance": project.tool_instance,
                "status": project.creation_status,
                "jira_project_key" : project.jira_project_key,
                "members": memberArray
              }
            //  if(tableObj.repoName){
                this.tableRepoArray.push(tableObj)
               
              this.dtTrigger.next();
              this.showTable = true
            this.spinner.hide()
           //   }
              
            })
            
            
            
        }, (error: HttpErrorResponse) => {
            this.spinner.hide();
          if (error.status == 432){
          this.alertService.error(error.error.message, this.options);
           }
           else if (error.status == 403){}
          else {
          this.alertService.error("Invalid User", this.options);
          throw error;
           }
          
           })
  }

  openUser() {
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
      this.apiService.selectedJiraMembers.next(this.selectedUserArray);
    })

  }

  get jiraProjName() {
    return this.secondFormGroup.get('jiraProjName');
  }
  get toolInstance() {
    return this.secondFormGroup.get('toolInstance');
  }

  removeUser(user,index){
    this.selectedUserArray.splice(index,1)
  }


  toggleDisplay(secondFormGroup) {
    if(secondFormGroup.pristine == false){
    this.cancelconfirmationPopUp();
    } else {
      this.showForm = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
    }
 
  }

   // confirmation popup
   cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(jiraCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
     
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.scmworkbenchConfirmationPopup();
      } else {
        this.router.navigate(['/workBench'])
      }
  }
  goHome(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.homeConfirmationPopup();
      } else {
        this.router.navigate(['/projectSummary'])
      }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(jiraWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe( data => {
      }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(jiraHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {   
      }
    );
  }

  //add members to existing repo
  addMemberToRepo(data, type){
    const jiraUser = this.dialog.open(addMemberRepoJiraDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        values: data,
        type: type
      }
    });
    
    jiraUser.afterClosed().subscribe(result => {
      // this.refreshJira(this.filterStatus);
      this.refreshTimeout()      

    })
  }
  refreshTimeout(){
    setTimeout(() => {
      this.refreshJira(this.filterStatus);
      // console.log("hi");
      
    }, 5000)

  }

  addProject() {
    this.selectedUserArray = [];
    this.apiService.selectedJiraMembers.next([])
    this.showAddProject = false;
    this.showForm = true;
  }
  openAddedUserDialog(memberArray,reqStatus,request,type) {
    // console.log(memberArray,"jj");
    
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data:{
          member: memberArray,
          reqStatus : reqStatus,
          request : request,
          screen : type
        }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshJira(this.filterStatus);
    });
  }

  deleteJira(element){
    // debugger
    let deleteBody = {
      "project_id": this.projectId,
      "jira_project_key": element.jira_project_key,
      "request_id": element.request_id,
      "tool_project_name": element.repoName
    }

    this.apiService.deleteJiraProject(deleteBody).subscribe((data: any)=>{
      //this.alertService.success(data.status, this.options)
      this.alertService.success(data.message, this.options)
      this.refreshJira(this.filterStatus)
    }, (error: HttpErrorResponse) => {
      if (error.status == 454){
      //this.alertService.error(error.error.status, this.options);
      }
      else if (error.status == 403){}
      })
  }
  softdeleteJira(element){
    // debugger
    this.memberArraysoftJira=[]

    // console.log(this.memberArraysoftJira);
    
    this.memberArraysoft=[]
    for (let i = 0; i < element.members.length; i++) {
      // element.members[i]
      let body2={
        "role_id": 10200,

        "user_name": element.members[i].name,

        "email_id": element.members[i].email,

        "role_name": element.members[i].role,

        "status": element.members[i].status,

      }
      this.memberArraysoft.push(body2)
      
    }
    // debugger
 
    let body={
      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName,
        "catalog_id": this.catalog_id,
        "catalog_name": this.catalog_name,
        "template_id": this.template_id,
        "template_name": this.template_name,
    },
    "request_details": {
      "jira_project_details": [
          {
              "request_id": element.request_id,
              "request_status": element.status,
              "requestor_name": localStorage.getItem('profile')
              // "project_members": this.memberArraysoft,
          }
        ]
      }
    }
    this.apiService.softdeleteProject(body).subscribe((data: any)=>{
      // debugger
      this.alertService.success(data.message, this.options);
      this.refreshJira(this.filterStatus);

    }, (error: HttpErrorResponse) => {
        if (error.status == 432){
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {

        }
    
     })
    
  }
  deleteconfirmationPopUp(req_id) {
    // debugger

    const dialogRef = this.dialog.open(jiradeleteConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      id:req_id,
      data:{
        req:req_id.request_id
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.softdeleteJira(req_id);
        }
      }
    );
  
  }

  deleteUser(request_id,email){
    let body =  {
      "project_id": this.projectId,
      "request_id": request_id,
      "user_email_list":[email]
  }

this.apiService.deleteUserFromJira(body).subscribe((data: any)=>{
  this.alertService.success(data.message, this.options)
  this.refreshJira(this.filterStatus)
}, (error: HttpErrorResponse) => {
  if (error.status == 454){
  //this.alertService.error(error.error.status, this.options);
  }
  else if (error.status == 403){}
  })
  }

  createProject() {
    this.showAddProject = true;
    const letters = new Set(["test","admin","user"]);
    if (letters.has(this.jiraProjName.value.toLowerCase())){
      this.alertService.error("Project Name is not allowed",this.options);
      this.showForm = true;
      this.showUserRow = true;
      return
    }
    if (this.tableRepoArray.findIndex(x => x.repoName === this.jiraProjName.value && x.status !="Removed") !== -1) {
      this.alertService.error("Project Name already exists!",this.options);
      this.showForm = true;
      this.showUserRow = true;
    }
    else{
      this.showForm = false;
      this.showUserRow = false;
      this.memberString = [];
      let memberArrayToSend = [];
  
      this.userMailId=localStorage.getItem('mail').toLowerCase();
    this.profile=localStorage.getItem('profile');
    this.userRole= localStorage.getItem('role');

      this.selectedUserArray.forEach((user: any) => {
        let membObj = {
          name: user.user_name,
          email: user.user_email,
          role: ""
        }
        this.memberString.push(membObj)
        let memberObj = {
          "user_name": user.user_name,
          "email_id": user.user_email,
          "mail" : 0,
          "role_name": ""
        }
        memberArrayToSend.push(memberObj)
      })
  
      this.generateProjectKey();
  
      let userPsNumber = "";
      let userName = localStorage.getItem('profile');
      this.apiService.psNumber.subscribe((psNumber: any) => {
        userPsNumber = psNumber
      })
      let jiraBody = {
        "resource_type": "request",
        "request_type": "Jira",
        "request_status": "Pending",
         "requestor_id": userPsNumber,
         "requestor_name": userName,
         "member_status":"Pending",
        "project_details": {
            "project_name":this.projectName,
            "project_id": this.projectId,
            "catalog_id": this.catalog_id,
            "catalog_name": this.catalog_name
        },
        "request_details": {
            "story_board_details": {
                "project_name": this.projectName,
                "project_id": this.projectId,
                "jira_project_details": {
                    "tool_project_name": this.jiraProjName.value,
                    "tool_instance": this.toolInstance.value,
                    "notification": 0,
                    "oldproject" : 0,
                    "project_key": this.projectKey,
                    "members": memberArrayToSend,
                    "activeMembers": [],
                    "creation_status": " ",
                    "onboarding_message": " ",
                    "project_url": " ",
                    "webhook_url": "https://outlook.office.com/webhook/0aa4f7ed-1502-4006-95c0-9ec5cc46a2c9@ff355289-721e-4dd7-a663-afec62ab9d54/JIRA/7b46c36dc2594bd89d5a72802fd1bedf/fb18ee70-be4d-4158-a4f0-160de1cfc384",
                    "planning_project_url": "",
                    "planning_project_key": this.projectKey,
                    "lead_email_id": this.mailId,
                }
            }
        },
        "created_by":localStorage.getItem('mail').toLowerCase()
    }
    
         
      this.showTable = true;
      this.apiService.addToQueue(jiraBody).subscribe((jiraResult: any) => {
          let tableObj =
          {
            "request_id": jiraResult.request_id,
            "repoName": this.jiraProjName.value,
            "toolInstance": this.toolInstance.value,
            "status": "Pending",
            "members": this.memberString,
            "repoUrl": ""
          }
  
          //this.tableRepoArray.push(tableObj)
          // console.log(jiraResult);
          
          this.refreshJira(this.filterStatus)
          this.alertService.success(jiraResult.message, this.options);
          this.secondFormGroup.reset();
          //this.dtTrigger.next();
      
      }, (error: HttpErrorResponse) => {
        if (error.status == 435){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("Error creating Jira request.", this.options);
        throw error;
         }
        
         })
    }
    
  }

  

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '22%',
      height: '13%',
      disableClose: true,

    });
  }

  generateProjectKey() {
    var projectId = "";
    var length = 7;
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

let tempKey = possible.charAt(Math.floor(Math.random() * possible.length));


    for (var i = 0; i < length; i++)
    tempKey += possible.charAt(Math.floor(Math.random() * possible.length));
    this.projectKey = tempKey;
    return tempKey;
  }

}

export interface ProjectTable {
  repoName: string;
  repoUrl: string;
  toolInstance: string;
  status: string;
  members: [];
  action: string;
}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./story-board.component.scss']
})
export class jiraCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<jiraCancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Delete confirmation pop up
@Component({
  selector: 'delete-confirmation-popup-dialog',
  templateUrl: 'delete-confirmation-popup.html',
  styleUrls: ['./story-board.component.scss']
})
export class jiradeleteConfirmationPopUpDialog {
  req_id;
  req: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<jiradeleteConfirmationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    this.req=data.req

  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./story-board.component.scss']
})
export class jiraWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<jiraWorkbenchConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  workbenchPopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./story-board.component.scss']
})
export class jiraHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<jiraHomeConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  homePopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  checked: boolean
}
//add memebr to existing repo
const ELEMENT_DATA: MemberDetails[]=[];

@Component({
  selector: 'add-member-repo-jira-dialog',
  templateUrl: 'add-member-repo.html',
  styleUrls: ['./story-board.component.scss']
}) 

  export class addMemberRepoJiraDialog {
    projectId: any;
    projectName: any;
    members: any = [];
    ELEMENT_DATA: MemberDetails[];
    displayedColumns: string[] = ['user_name', 'user_email', 'select'];
    dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
    selection = new SelectionModel<MemberDetails>(true, []);
    componentName: any;
    isallSelected: boolean = false;
  
    dtOptions: DataTables.Settings = {};
    dtTrigger = new Subject();
    existingJiraMembers: any;
    memberList: any = [];
    newMembersAdd: any = [];
    finalArray:any =[];

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dummyMemberArray: any = [];
  tempBody: any;
  rowValue: any;
  catalog_id: any;
  catalog_name: any;
  memberString: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  type: any;

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
     const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    //  return numSelected
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
       this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: MemberDetails): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
    }
    constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { 
      this.memberList = data.values.members
  this.rowValue = data.values;
  this.type = data.type
}
  
    ngOnInit(): void {
      this.projectId=localStorage.getItem('projectId');
      
      this.getUser();
    }
    public doFilter = (value: string) => {
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }
    allSelected() {
      this.isallSelected = true
    }
      
    public getUser() {
      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        this.members = project[0].members;
       
       this.members.forEach((element,index) => {
         for(var i=0; i<this.memberList.length;i++){
           if(this.memberList[i].user_email == element.user_email ){
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
           }
         }
         
       });

       this.members.forEach((object:any)=>{
        if(!object.duplicate){
          this.finalArray.push(object)
        }
       })

       if(this.type === "add") {
        this.memberList.forEach(member => {
          for(let i=0; i<this.finalArray.length; i++){
            if(member.name == this.finalArray[i].user_name){
              this.finalArray.splice(i,1);
            }
            else{
              
            }
          }
        });
      } 

      let members = []
        if(this.type === "delete") {
          // console.log(this.memberList);
          
          this.memberList.forEach(user=>{
            if(user.status!="Pending"){
              let object ={
                "user_name": user.name,
                "user_email": user.email,
              }
              members.push(object)
            }
           
          })
            this.finalArray = members
        }
        this.dataSource.data = this.finalArray as MemberDetails[]
      }, (error: HttpErrorResponse) => {
        if (error.status == 432){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
         })
    }

    addDeleteMemberToProj(){
      if(this.type==="add") {
        let memberArray = [];
      this.selection.selected.forEach(user=>{
        let object ={
          "user_name": user.user_name,
          "email_id": user.user_email,
          "mail" : 0,
          "role_name": "TM"
        }
        memberArray.push(object)
      })

      let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
        
        this.catalog_id=localStorage.getItem('catalogId');
       
        this.catalog_name=localStorage.getItem('catalogName');

        let jiraBody =
            {
            "resource_type": "request",
            "request_type": "Jira",
            "request_status": "Pending",
            "requestor_id": userPsNumber,
            "request_id":this.rowValue.request_id,
            "project_id":this.projectId,
            "name": this.rowValue.repoName,
            "members": memberArray
            }

    this.apiService.addMemberinExisingProject(jiraBody).subscribe((roleResult: any) => {
      //if (roleResult.status == "Request submitted") {
        this.alertService.success(roleResult.message, this.options);
      //}
    
    }, (error: HttpErrorResponse) => {
       if (error.status == 435){
        this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
       else {
          this.alertService.error("Error While Adding Member", this.options);
          throw error;
       }
      
       });
    this.dialogRef.close()
      }
      else {
        let memberArray = [];
        this.selection.selected.forEach(user=>{
          memberArray.push(user.user_email)
        })
        let body =  {
          "project_id": this.projectId,
          "request_id": this.rowValue.request_id,
          "user_email_list": memberArray
      }
        this.apiService.deleteUserFromJira(body).subscribe((data: any)=>{
          this.dialogRef.close();
          this.alertService.success(data.message, this.options);
        }, (error: HttpErrorResponse) => {
          if (error.status == 454){
          //this.alertService.error(error.error.status, this.options);
          }
          else if (error.status == 403){}
          })
          this.dialogRef.close()
      }
    }

    
    exit() {
      this.dialogRef.close();
    }

    ngAfterViewInit(): void {
      this.dataSource.paginator = this.paginator;
    }
  }