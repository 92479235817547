import { Component, OnInit, Inject } from '@angular/core';
import { ChartOptions, ChartType, Chart } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';

import { Subject, throwError } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { AuthService } from '../../service/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { AlertService } from 'src/app/_alert';  

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  label:any;
  vmCost:any;
  diskCost:any;
  networkCost:any;
  Total:any;
  monthNumber:any;

  overleyFlag:boolean = false;
  overleyLineFlag:boolean = false;
  overleyPieFlag:boolean = false;
  overleyPieOneFlag:boolean = false;
  overleyPieTwoFlag:boolean = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  startDate = new FormControl();
  endDate = new FormControl();
  startDate1 = new FormControl();
  endDate1 = new FormControl();
  username = new FormControl("");
  observationType = new FormControl("");
  computerStartDate = new FormControl();
  computerEndDate = new FormControl();
  vdilogStartDate = new FormControl();
  vdilogEndDate = new FormControl();
  auditUserName = new FormControl("");
  Role:string="";
  statusValue:any;
  // observationValue:any;
  osSelectedValue:any;
  public selectOptions = ['2021', '2022',];
  public selectOptions2 = ['January', 'February','March','April','May','June','July','September','October','November','December'];


  public selectedOption = 'Select year';
  public selectedOption2 = 'Select month';


  operatingSys = [
    { value: 'Windows 10 Enterprise', viewValue: 'Windows 10 Enterprise' },
    { value: 'Windows Server 2016 Datacenter', viewValue: 'Windows Server 2016 Datacenter' },
    { value: 'Windows 10 Enterprise for Virtual Desktops', viewValue: 'Windows 10 Enterprise for Virtual Desktops' },
    { value: 'Windows Server 2016 Datacenter', viewValue: 'Windows Server 2016 Datacenter' },
    { value: 'red hat enterprise linux release 8.2', viewValue: 'red hat enterprise linux release 8.2' }
  ];
  
  // Bar chart code start's here
  public barChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { x: [{}], y: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
 public barChartLabels: Label[] = ['Canvas Demo Env', 'Canvas Dev Env', 'Canvas Engineering', 'Canvas Implementation',
  'CITI', 'FSTI', 'HSBC', 'KIMS', 'Maersk Drilling'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartColors: Color[] = [
    { backgroundColor: '#09519B' },
   // { backgroundColor: '#6A227C' },
  ]

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  


  public barChartData = [
   
    { data: [2.61, 1.69, 1.87, 0.15, 0.54, 0.23, 0.10, 0.30, 0.07], label: 'Cost' }
  ];
   // Bar chart code end's here
 
 // Pie chart data start's here
 public pieChartOptions= {
  responsive: true,
  legend: {
    position: 'top',
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        const label = ctx.chart.data.labels[ctx.dataIndex];
        return label;
      },
    },
  }
};
public pieChartLabels: Label[] = ['Bandwidth', 'CIS', 'Storage', 'VM', 'VN'];
public pieChartData: number[] = [3.32, 6.18, 120.31, 409.20, 0.33];
public pieChartType: ChartType = 'pie';
// public pieChartPlugins = [ DatalabelsPlugin ];
public pieChartLegend = true;
public pieChartColors = [
  {
    backgroundColor: ['#09519B', '#0299DE', '#A1D5EB', '#5B8CB7' , '#07529D', '#B7D8F3' ,'#3F7BB1'],
  },
];


public pieChartData1: number[] = [100, 300, 100, 200, 200];
public pieChartType1: ChartType = 'pie';
public pieChartLegend1 = true;
public pieChartColors1 = [
  {
    backgroundColor: ['rgba(255,0,0,1)', 'rgba(0,158,76,1)', 'rgba(0,0,255,1)', 'rgba(14, 17, 230,1)', 'rgba(39, 230, 14,1)'],
  },
];
 // Pie chart data end's here

 //Tool Bar chart code start's here
 toolBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true,
        stepSize : 100,
        callback: label => `${label}$`
      },
      gridLines: {
        display:false
    }
    }]
  }
};
toolBarChartLabels: Label[] = [
];
toolBarChartType: ChartType = 'bar';
toolBarChartLegend = true;
toolBarChatPlugins = [];
toolBarChartColors: Color[] = [
  { backgroundColor: '#09519B' },
  { backgroundColor: '#F5C767' },
  { backgroundColor: '#7A62BA' },
  { backgroundColor: '#566970' },
  { backgroundColor: '#EF7721' },
  { backgroundColor: '#39A768' },
  { backgroundColor: '#347ED3' },
  { backgroundColor: '#FFD75E' },
]
toolBarChartData = [
  {label: 'Cost in $',
data: [],
stack: ''}
]
 //Tool Bar chart code end's here

 //Line chart starts here
 public lineChartData = [
  { data: [15, 29, 30, 41, 56, 65, 70], label: 'Price' },
];
public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
public lineChartOptions = {
  responsive: true,
  scales: {
    // We use this empty structure as a placeholder for dynamic theming.
    xAxes: [{}],
    yAxes: [
      {
        id: 'y-axis-0',
        position: 'left',
      },
      {
        id: 'y-axis-1',
        position: 'right',
        gridLines: {
          color: 'rgba(255,0,0,1)',
        },
        ticks: {
          fontColor: 'red',
        }
      }
    ]
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: 'March',
        borderColor: 'orange',
        borderWidth: 2,
        label: {
          enabled: true,
          fontColor: 'orange',
          content: 'LineAnno'
        }
      },
    ],
  },
};

public lineChartLegend = true;
public lineChartType: ChartType = 'line';
public lineChartColors: Color[] = [
  { backgroundColor: '#7BBA00' }
]
  alertData: any;
  temparray= [];
  description: any;
  page_description: any;
  detailsData : any;
  scmDetailsData : any;
  jiraDetailsData: any;
  summaryData:any;
  computersData:any;
  vdiAuditLog:any;
  projectId: any;
  jiraMembers: any;
  showPolicyDetail: any;

  //CLARIOS code start here
  toolChartArray : any[];
  toolLabelArray : any[];
  toolBandWidthArray : any[];
  toolCisPlanArray : any[];
  toolJiraArray : any[];
  toolStorageArray : any[];
  toolVmArray : any[];
  toolDiskArray:any[];
  toolNetworkArray:any[];
  toolTotalArray:any[];

  toolVmlArray : any[];
  toolVnArray : any[];
  toolFilter:any = {};

  showToolBarChart:boolean = false;
  ShowToolTableView:boolean = true;
  months:any = ['All','January-21', 'Febraury-21', 'March-21'];
  tools:any = ['ALL','CITI', 'KIMS'];
  observationData:any;
  computersFilterData = [];
  status: any;
  osStatus:any;
  // obsStatus:any;
  // observationType2: any;
  //CLARIOS end start here
//New pie chart code
public pieChartOptions2: ChartOptions = {
  responsive: true,
};
public pieChartLabels2: Label[] = [];
pieChartColor:any = [
  {
      backgroundColor: ['rgba(30, 169, 224, 0.8)',
      'rgba(255,165,0,0.9)',
      'rgba(139, 136, 136, 0.9)',
      'rgba(255, 161, 181, 0.9)',
      'rgba(255, 102, 0, 0.9)'
      ]
  }
]
public pieChartData2: SingleDataSet = [];
// public pieChartColors: Color[] = [
//   { backgroundColor: '#09519B' },
//   { backgroundColor: '#F5C767' },
//   { backgroundColor: '#7A62BA' },
//   { backgroundColor: '#566970' },
//   { backgroundColor: '#EF7721' },
//   // { backgroundColor: '#39A768' },
//   // { backgroundColor: '#347ED3' },
//   // { backgroundColor: '#FFD75E' },
// ]
public pieChartType2: ChartType = 'pie';
public pieChartLegend2 = true;
public pieChartPlugins2 = [];
  showLogoByEnv: boolean;
//
   constructor(private datePipe: DatePipe,public alertService: AlertService, private apiService: AuthService, private spinner: NgxSpinnerService, public dialog: MatDialog,public router : Router) { }

   ngOnInit(): void {
    //  debugger
    this.selectedOption2="January";
    this.selectedOption="2021";
    if(environment.costUsage){

      this.showLogoByEnv = true;

    }

    else{

      this.showLogoByEnv = false;

    }

    
     

    //  console.log(yearcal.value);
    
     
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.projectId=localStorage.getItem('projectId');
     this.getDashBoardDetails();
    this.dtOptions = {
    searching: false,
    paging:false,
     ordering: false,
     info:false
     //pageLength: 5
    };

    this.getComputersData();
    
    this.getToolsDataDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }
   }
  // CLARIOS code start's here
   getToolsDataDetails(){
    this.apiService.getsample().subscribe(post => {
      // debugger
      //console.log("post data", post);
      this.toolChartArray = post.estimatedVdiVmCostForProject;
      this.getToolChartData();
      this.getToolChart();
    }, error => {

    })
  }

  getToolChartData(){
    // debugger
    this.toolLabelArray = [];
    this.toolVmArray = [];
    this.toolDiskArray = [];
    this.toolNetworkArray = [];
    this.toolTotalArray = [];


    // this.toolBandWidthArray = [];
    // this.toolCisPlanArray = [];
    // this.toolJiraArray = [];
    // this.toolStorageArray = [];
    // this.toolVmArray = [];
    // this.toolVmlArray = [];
    // this.toolVnArray = [];
    //console.log("Original tool data" , this.toolChartArray);
    
    if(this.toolChartArray){
       for(let i=0; i < this.toolChartArray.length; i++){
         this.toolLabelArray.push(this.toolChartArray[i]['label']);
         this.toolLabelArray.push(this.toolChartArray[i]['vmCost']);
         this.toolLabelArray.push(this.toolChartArray[i]['diskCost']);
         this.toolLabelArray.push(this.toolChartArray[i]['networkCost']);
         this.toolLabelArray.push(this.toolChartArray[i]['totalCost']);

        //  this.toolBandWidthArray.push(this.toolChartArray[i]['bandwidth']);
        //  this.toolCisPlanArray.push(this.toolChartArray[i]['cis']);
        //  this.toolJiraArray.push(this.toolChartArray[i]['network']);
        //  this.toolStorageArray.push(this.toolChartArray[i]['storage']);
        //  this.toolVmArray.push(this.toolChartArray[i]['vm']);
        //  this.toolVmlArray.push(this.toolChartArray[i]['vml']);
        //  this.toolVnArray.push(this.toolChartArray[i]['vn']);
        
       }
    }
  }

  getToolChart(){
    this.toolBarChartLabels =  this.toolLabelArray;
    this.toolBarChartData = [
      {data : this.toolBandWidthArray, label : 'Bandwidth', stack: 'a'},
      {data : this.toolCisPlanArray, label : 'CIS Plan', stack: 'a'},
      {data : this.toolJiraArray, label : 'Network Watcher', stack: 'a'},
      {data : this.toolStorageArray, label : 'Storage', stack: 'a'},
      {data : this.toolVmArray, label : 'Virtual Machines', stack: 'a'},
      {data : this.toolVmlArray, label : 'Virtual Machines Licenses', stack: 'a'},
      {data : this.toolVnArray, label : 'Virtual Network', stack: 'a'},
     // {data : this.toolTotalArray, label : 'Total', stack: 'a'}
    ];
   // console.log("tools data", this.toolBarChartData);
  }

   onToolSelected(month,year){
    //  debugger
     this.spinner.show()
    //  debugger
  
      //console.log("hello");
      //console.log(this.projectId);
      if(month=="January"){
        this.monthNumber='01';
      }
      else if(month=="February"){
        this.monthNumber="02"
      }
      else if(month=="March"){
        this.monthNumber="03"
      }
      else if(month=="April"){
        this.monthNumber="04"
      }
      else if(month=="May"){
        this.monthNumber="05"
      }
      else if(month=="June"){
        this.monthNumber="06"
      }
      else if(month=="July"){
        this.monthNumber="07"
      }
      else if(month=="August"){
        this.monthNumber="08"
      }
      else if(month=="September"){
        this.monthNumber="09"
      }
      else if(month=="October"){
        this.monthNumber="10"
      }
      else if(month=="November"){
        this.monthNumber="11"
      }
      else if(month=="December"){
        this.monthNumber="12"
      }
      
    // this.spinner.show();
    // this.apiService.getCostEstimate(this.projectId,this.monthNumber,year).subscribe((data:any)=>{
    //   console.log("hi");
      
    //   // debugger
    //   this.label=month;
    //   this.vmCost=Math.round(data.estimatedVdiVmCostForProject.vmCost);
    //   this.networkCost=Math.round(data.estimatedVdiVmCostForProject.networkCost);
    //   this.diskCost=Math.round(data.estimatedVdiVmCostForProject.diskCost);
    //   this.Total=Math.round(data.estimatedVdiVmCostForProject.totalCost);
    //   this.pieChartData2=data.estimatedVdiVmCostForProject.pieChartData.valuesAndLabels.value;
    //   this.pieChartLabels2=data.estimatedVdiVmCostForProject.pieChartData.valuesAndLabels.label;
    //   this.spinner.hide();

    
    // })

  

    //  console.log(value);
     
    // if(value == "All"){
    //   this.apiService.getDashboardData().subscribe(post => {
    //     //console.log("post data", post);
    //     this.toolChartArray = post.All;
    //     this.getToolChartData();
    //     this.getToolChart();
    //   }, error => {
 
    //   })
    //  }
    // if(month=="March" && year== "2021"){
    //   // debugger
    //  this.apiService.getsample().subscribe(post => {
    //    debugger
    //    this.label=post.estimatedVdiVmCostForProject.label;
    //    this.vmCost=post.estimatedVdiVmCostForProject.vmCost;
    //    this.networkCost=post.estimatedVdiVmCostForProject.networkCost;
    //    this.diskCost=post.estimatedVdiVmCostForProject.diskCost;
    //    this.Total=post.estimatedVdiVmCostForProject.totalCost;
      // this.pieChartData2=post.data;
      // this.pieChartLabels2=post.label;
      //  debugger
      //  console.log("post data", post);
      //  this.toolChartArray = post.janToolData;
      //  this.getToolChartData();
      //  this.getToolChart();
    //  }, error => {

    //  })
    // }
    // else if(value == "Febraury-21"){
    //   this.apiService.getsample().subscribe(post => {

    //     this.pieChartData2=post.data2;
    //     this.pieChartLabels2=post.label2;
    //    // console.log("post data", post);
    //     // this.toolChartArray = post.febToolData;
    //     // this.getToolChartData();
    //     // this.getToolChart();
    //   }, error => {
 
    //   })
    // }
    // else if(value == "March-21"){
    //   console.log(this.selectedOption);

    //   this.apiService.getsample().subscribe(post => {
    //    // console.log("post data", post);
    //    this.pieChartData2=post.data3;
    //    this.pieChartLabels2=post.label3;
    //     // this.toolChartArray = post.marToolData;
        
    //     // this.getToolChartData();
    //     // this.getToolChart();
    //   }, error => {
 
    //   })
    // }
  }

  showBarChart(){
    this.showToolBarChart = true;
    this.ShowToolTableView = false;
  }
  showTableView(){
    this.showToolBarChart = false;
    this.ShowToolTableView = true;
  }

  // CLARIOS code end here

  getStatus(value){
    this.status = value;
   // console.log(value)
  }

  getOsStatus(value){
    this.osStatus = value;
  //  console.log(value,"value")
  }
  // getObsStatus(value){
  //   this.observationType = value;
  //  // console.log(value)
  // }
   
   getAlertReport(){
    this.spinner.show();
    this.apiService.getAlerts(this.projectId).subscribe((data:any)=>{
      this.alertData = [];

      data.forEach(element => {
        let tempObj ={
          "name" : element.alert_name,
          "source": element.source,
          "creation_time": element.creation_time,
          "id" : element.source_id,
          "time" : element.alert_time,
          "status" : element.status,
          "description" : element.alert_desc,
          "next_step" : element.next_step,
          "priority" : element.priority
        }
        this.alertData.push(tempObj)
      });
      
        this.dtTrigger.next();
      this.spinner.hide();
      
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   }

   
  resetAlertReport(){
    this.startDate.reset();
    this.endDate.reset();
    this.statusValue='';
    // this.observationValue='';
    this.getAlertReport();
  }
 
   alertFilter(){
    this.spinner.show();
    let startDate = this.datePipe.transform(this.startDate.value,"yyyy-MM-dd")
    let endDate = this.datePipe.transform(this.endDate.value,"yyyy-MM-dd")
    if(startDate === null){
      startDate = 'none';
    }
    if(endDate === null){
      endDate = 'none';
    }
    if(this.status === undefined){
      this.status = 'none';
    }
    this.apiService.filterAlert(this.status,startDate,endDate, this.projectId).subscribe((data:any)=>{
      this.alertData = [];

      data.forEach(element => {
        let tempObj ={
          "name" : element.alert_name,
          "source": element.source,
          "creation_time": element.creation_time,
          "id" : element.source_id,
          "time" : element.alert_time,
          "status" : element.status,
          "description" : element.alert_desc,
          "next_step" : element.next_step,
          "priority" : element.priority
        }
        this.alertData.push(tempObj)
      });
      this.spinner.hide();
}, (error: HttpErrorResponse) => {
  if (error.status == 439){
  this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){}
  else {
  this.alertService.error("No data available.", this.options);
  throw error;
   }
  
   })
   }

   resetFilterObservation(){
    this.startDate1.reset();
    this.endDate1.reset();
    this.observationType.reset();
    this.getObservationReport(); 
   }
   filterObservation(){
    this.spinner.show();
    let startDate = this.datePipe.transform(this.startDate1.value,"yyyy-MM-dd")
    let endDate = this.datePipe.transform(this.endDate1.value,"yyyy-MM-dd")
    let observationType = this.observationType.value
    if(observationType == "" || observationType == null){
      observationType ='none'
    }
    // if(this.observationType === undefined){
    //   this.observationType = null;
    // }
    if(startDate === null){
      startDate = 'none';
    }
    if(endDate === null){
      endDate = 'none';
    }
    this.apiService.filterObservation(observationType,startDate,endDate, this.projectId).subscribe((data:any)=>{
      
      this.observationData=data;
      // console.log(this.observationData);

      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   }
   
   //Vikram code start here
   getComputersData(){
    this.spinner.show();
      this.apiService.getComputersReportData(this.projectId).subscribe(data => {
     //   console.log("computers d data", data);
        this.computersData = data;
        this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 439){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
   }
   getVdiAuditLog(){
    this.spinner.show();
    // let currentDate = new Date();
    // let todayDate = this.datePipe.transform(currentDate, "yyyy-MM-dd")
    // let priorDate = new Date().setDate(currentDate.getDate()-5);
    // let preDate = new Date(priorDate);
    // let previousDate = this.datePipe.transform(preDate, 'yyyy-MM-dd');
    // let none = 'none';
    // let username = this.auditUserName.value;
    // if(username === ""){
    //   username = none;
    // }
     this.apiService.getVdiAuditLogData(this.projectId).subscribe(data => {
    //  this.apiService.filterAuditLog(username,previousDate,todayDate, this.projectId).subscribe(data => {
      //  console.log("VdiAuditLogData data", data);
        this.vdiAuditLog = data;
        this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 439){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
   }

   resetComputersFilter(){
    this.computerStartDate.reset();
    this.computerEndDate.reset();
    this.osSelectedValue='';
    this.getComputersData();
   }
   computersFilter(){
    let none = 'none';
    this.spinner.show();
    //this.projectId = "COUNT";
    let startDate = this.datePipe.transform(this.computerStartDate.value,"yyyy-MM-dd")
    let endDate = this.datePipe.transform(this.computerEndDate.value,"yyyy-MM-dd")
    if(startDate === null){
      startDate = none;
    }
    if(endDate === null){
      endDate = none;
    }
    if(this.osStatus === undefined){
      this.osStatus = none;
    }
    this.apiService.filterComputers(this.osStatus,startDate,endDate, this.projectId).subscribe((data:any)=>{
      this.computersData=data;
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
    
   }

   resetFilterAuditLog(){
    this.vdilogStartDate.reset();
    this.vdilogEndDate.reset();
    this.auditUserName.reset();
    this.getVdiAuditLog();
  }

   filterAuditLog(){
    let none = 'none';
    this.spinner.show();
    //this.projectId = "COUNT";
    let startDate = this.datePipe.transform(this.vdilogStartDate.value,"yyyy-MM-dd")
    let endDate = this.datePipe.transform(this.vdilogEndDate.value,"yyyy-MM-dd")
    let username = this.auditUserName.value
    if(startDate === null){
      startDate = none;
    }
    if(endDate === null){
      endDate = none;
    }
    if(username === ""){
      username = none;
    }
    this.apiService.filterAuditLog(username,startDate,endDate, this.projectId).subscribe((data:any)=>{
      this.vdiAuditLog=data;
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   }
   
     //Vikram code end here

   getObservationReport(){
    //  debugger
    this.spinner.show();
      let currentDate = new Date();
      let todayDate = this.datePipe.transform(currentDate, "yyyy-MM-dd")
      let priorDate = new Date().setDate(currentDate.getDate()-122);
      let preDate = new Date(priorDate);
      let previousDate = this.datePipe.transform(preDate, 'yyyy-MM-dd');
      let observationType = this.observationType.value
      if(observationType == "" || observationType == null){
       observationType ='none'
      }

    //  this.apiService.stealthWatchObservations(this.projectId).subscribe((data:any)=>{
      this.apiService.filterObservation(observationType,previousDate,todayDate, this.projectId).subscribe((data:any)=>{
      this.observationData = data;
      // console.log(this.observationData,"sec");

      this.dtTrigger.next();
      this.spinner.hide();
     })
   }

   openAlertDescription(data,type){
    const dialogRef = this.dialog.open(alertInfoDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data:{
          info: data
        }
    });

    this.apiService.reportModalType.next(type)

    dialogRef.afterClosed().subscribe(result => {
    });
   }

   showAlertInfo(info){
     //console.log(info)
     try {
      let temp = localStorage.setItem("alertInformation",JSON.stringify(info));
     }
     catch {
      return throwError('Data not found');
     }

  this.router.navigate(['/reports/alert-description/'+info.id])
 /* window.open('/reports/alert-description/'+info.id,
    '_blank' // <- This is what makes it open in a new window.
  );*/
   }

   openObservationDescription(data,type){
    //  this.observationType2=type
    const dialogRef = this.dialog.open(observationInfoDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      
      data:{
          info: data.other
        }
    });
    

    this.apiService.reportModalType.next(type)

    dialogRef.afterClosed().subscribe(result => {
    });
   }

   showPolicyInfo(policyName){
    //  console.log(policyName,"policy");
     
    this.spinner.show();
    this.apiService.getPolicyInfoByName(policyName).subscribe((data:any)=>{
     this.showPolicyDetail = data;
     this.dtTrigger.next();
     this.spinner.hide();
     this.showPolicyData(this.showPolicyDetail);
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   
   }
   showPolicyData(data){
   
    const dialogRef = this.dialog.open(PolicyOverviewDialog, {
      width: '90%',
      height: '55%',
      disableClose: true,
      data:{
         info: this.showPolicyDetail
        }
    });

    //this.apiService.reportModalType.next(policyName)

    dialogRef.afterClosed().subscribe(result => {
    });
   }
  //  showPolicyInfo(policyName) {
    
   summaryViewData(){
    this.spinner.show();
      this.apiService.getSummaryDetailsData(this.projectId).subscribe(data => {
       // console.log("count data", data);
        this.summaryData = data;
        this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 447){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
   }
   detailViewData(){
    this.spinner.show();
      // this.userEmail = currentUserEmail;
      //VDI and VM data fetchning
      this.apiService.getVdiVMDetailsData(this.projectId).subscribe(data => {
       //console.log("details data", data);
        this.detailsData = data;
        this.dtTrigger.next();
      //this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 447){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });

      this.apiService.getSCMDetailsData(this.projectId).subscribe(data => {
       // console.log("SCM details data", data);
        this.scmDetailsData = data;
        this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 447){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
      this.apiService.getJiraDetailsData(this.projectId).subscribe((data:any) => {
       // console.log("Jira details data", data);
        this.jiraDetailsData = data;
        for(var i = 0; i < data.length; i++){
          this.jiraMembers = data[i].project_members;
        //  console.log("this.jiraMembers", this.jiraMembers);
        }
        
      this.dtTrigger.next();
      this.spinner.hide();
      }, (error: HttpErrorResponse) => {
        if (error.status == 447){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         });
    
   }

   ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
   }
   getDashBoardDetails(){
     
   }

   clickOverley(){
    this.overleyFlag = !this.overleyFlag;
   }
   cancelOverley(){
    this.overleyFlag = false;
   }
   clickLineOverley(){
    this.overleyLineFlag = !this.overleyLineFlag;
   }
   cancelLineOverley(){
    this.overleyLineFlag = false;
   }
   clickPieOverley(){
    this.overleyPieFlag = !this.overleyPieFlag;
   }
   cancelPieOverley(){
    this.overleyPieFlag = false;
   }
   clickPie1Overley(){
    this.overleyPieOneFlag = !this.overleyPieOneFlag;
   }
   cancelPie1Overley(){
    this.overleyPieOneFlag = false;
   }
   clickPie2Overley(){
    this.overleyPieTwoFlag = !this.overleyPieTwoFlag;
   }
   cancelPie2Overley(){
    this.overleyPieTwoFlag = false;
   }
   showProjectInfo(dataArray,screen) {
    const dialogRef = this.dialog.open(DetailViewDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showComputersInfo(dataArray) {
    const dialogRef = this.dialog.open(computerDetailViewDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        rowdata: dataArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showVdiAuditLogInfo(dataArray, type) {
    const dialogRef = this.dialog.open(vdiAuditLogDetailViewDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        rowdata: dataArray.popup_fields
      }
    });
    this.apiService.reportModalType.next(type)
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showGitLabInfo(dataArray,screen) {
    const dialogRef = this.dialog.open(gitlabDetailViewDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  showJiraInfo(dataArray,screen) {
    const dialogRef = this.dialog.open(jiraDetailViewDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        rowdata: dataArray,
        fromScreen: screen
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // getStatus2(observationType2){
  //   console.log(observationType2,"type2");
    
  //   this.status = observationType2;
  //  console.log(this.status,"status2")
  // }

}

//Detail view component
@Component({
  selector: 'detail-view',
  templateUrl: 'detail-view.html',
  styleUrls: ['./reports.component.scss']
})

export class DetailViewDialog {
  detailData:any;
  screen: any;
  type: string;
  dtOptions: DataTables.Settings = {
    searching: false,
    paging: false,
    ordering: false,
    info: false
  };

  constructor(public dialogRef: MatDialogRef<DetailViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.detailData = data.rowdata;
    this.screen = data.fromScreen;
     }

     ngOnInit(){
      if(this.detailData.HostPoolName == "") {
        this.type = "VM";
      }
      else{
        this.type = "VDI"
      }
     }

     exit(): void {
    this.dialogRef.close();
  }

 

}

//Computers view component
@Component({
  selector: 'computer-detail-data',
  templateUrl: 'computer-detail-data.html',
  styleUrls: ['./reports.component.scss']
})

export class computerDetailViewDialog {
  detailData:any;

  constructor(public dialogRef: MatDialogRef<computerDetailViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.detailData = data.rowdata;
     }

     ngOnInit(){
     
     }

     exit(): void {
    this.dialogRef.close();
  }

}

//VDI audit log view component
@Component({
  selector: 'computer-detail-data',
  templateUrl: 'vdi-audit-log-detail-data.html',
  styleUrls: ['./reports.component.scss']
})

export class vdiAuditLogDetailViewDialog {
  detailData:any;
  modalType:string;

  constructor(private apiService: AuthService,public dialogRef: MatDialogRef<vdiAuditLogDetailViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.detailData = data.rowdata;
     }

     ngOnInit(){
      this.apiService.reportModalType.subscribe(data=>{
        this.modalType = data;
      })
     }

     exit(): void {
    this.dialogRef.close();
  }

}

//Gitlab Detail view component
@Component({
  selector: 'gitlab-detail-view',
  templateUrl: 'gitlab-detail-view.html',
  styleUrls: ['./reports.component.scss']
})

export class gitlabDetailViewDialog {
  detailData:any;
  screen: any;
  dtOptions: DataTables.Settings = {
    searching: false,
    paging: false,
    ordering: false,
    info: false
  };

  constructor(public dialogRef: MatDialogRef<gitlabDetailViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.detailData = data.rowdata;
    this.screen = data.fromScreen;
     }

     ngOnInit(){
     
     }

     exit(): void {
    this.dialogRef.close();
  }

 

}

//Jira Detail view component
@Component({
  selector: 'jira-detail-view',
  templateUrl: 'jira-detail-view.html',
  styleUrls: ['./reports.component.scss']
})

export class jiraDetailViewDialog {
  detailData:any;
  projectMembers: any;
  dtOptions: DataTables.Settings = {
    // searching: false,
    // paging: false,
    // ordering: false,
    // info: false
  };
  screen: any;

  constructor(public dialogRef: MatDialogRef<jiraDetailViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.detailData = data.rowdata;
    this.projectMembers = this.detailData.project_members;
    this.screen = data.fromScreen;
   // console.log("this.projectMembers", this.projectMembers);
     }

     ngOnInit(){
      this.dtOptions = {
        searching: false,
        paging:false
        //pageLength: 5
       };
     }

     exit(): void {
    this.dialogRef.close();
  }

}

//Detail view component
@Component({
  selector: 'jenkins-view',
  templateUrl: 'jenkins-view.html',
  styleUrls: ['./reports.component.scss']
})

export class jenkinsViewDialog {
  jenkinsData:any;
  screen: any;
  type: string;
  dtOptions: DataTables.Settings = {
    // searching: false,
    // paging: false,
    // ordering: false,
    // info: false
  };

  constructor(public dialogRef: MatDialogRef<jenkinsViewDialog>, @Inject(MAT_DIALOG_DATA) public data:any) {
    this.jenkinsData = data.rowdata;
    this.screen = data.fromScreen;
     }

     ngOnInit(){
      
     }

     exit(): void {
    this.dialogRef.close();
  }

 

}


@Component({
  selector: 'alert-info',
  templateUrl: 'alert-info.html',
  styleUrls: ['./reports.component.scss']
})

export class alertInfoDialog {
  alertInfo: any = [];
  modalType: string;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<alertInfoDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.alertInfo = data.info
     }

  ngOnInit(){
    this.apiService.reportModalType.subscribe(data=>{
      this.modalType = data;
    })
  }   

  exit(): void {
    this.dialogRef.close();
  }

}

//observation popup
@Component({
  selector: 'observation-info',
  templateUrl: 'observation-info.html',
  styleUrls: ['./reports.component.scss']
})

export class observationInfoDialog {
  alertInfo: any = [];
  modalType: string;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<observationInfoDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.alertInfo = data.info
      // console.log(this.alertInfo,"alert");
      
     }

  ngOnInit(){
    this.apiService.reportModalType.subscribe(data=>{
      this.modalType = data;
      //console.log(this.modalType,"modal");
      
    })
  }   

  exit(): void {
    this.dialogRef.close();
  }

}


//observation popup
@Component({
  selector: 'alert-description',
  templateUrl: 'alert-description.html',
  styleUrls: ['./reports.component.scss']
})

export class alertDescription {
  modalType: string;
  tableArr =[]
  startDate = new FormControl();
  endDate = new FormControl();
  status: any;
  alertInfo : any;
  projectId: string;
  alertData: any[];
  tableData = [];
  tableNumber: any;
  tableHeader =[];
  tableBody =[];
  tempValue1= [];
  tempValue2= [];
  tempValue3 = [];
  alertObservationStartDate = new FormControl();
  alertObservationEndDate = new FormControl();
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  observation: any;

  constructor(private apiService: AuthService,public alertService: AlertService,private datePipe: DatePipe, private spinner: NgxSpinnerService, public dialog: MatDialog) {}


  ngOnInit(){
    this.projectId=localStorage.getItem('projectId');
    let tempValue = localStorage.getItem("alertInformation");
    try {
      this.alertInfo = JSON.parse(tempValue)
    }
    catch {

    }
    
    this.filterAlertObservation();
  }   

  getStatus(value){
    this.status = value;
   // console.log(value)
  }

  // getobservation(value){
  //   this.observation = value;

  // }
  // resetAlertObservation(){
  //   console.log(this.startDate);
    
  //   this.startDate.reset();
  //   this.endDate.reset();
  //   console.log(this.startDate);
    
  //   // this.filterAlertObservation();
  // }

  filterAlertObservation(){
    let startDate = this.datePipe.transform(this.alertObservationStartDate.value,"yyyy-MM-dd")
    let endDate = this.datePipe.transform(this.alertObservationEndDate.value,"yyyy-MM-dd")
    if(startDate === null){
      startDate = 'none';
    }
    if(endDate === null){
      endDate = 'none';
    }
   
    this.spinner.show();
    this.apiService.getAlertObservation(this.alertInfo.id, startDate, endDate).subscribe((data: any) => {
    
    this.tableData = data;
    this.dtTrigger.next();
    this.tableData.forEach(element => {
    //console.log(Object.values(element))
      this.tempValue2 = Object.values(element); //tablebody
      this.tempValue1 = Object.keys(element); //header
     });
     
    this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 439){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   }

}
//Policy detail popup
@Component({
  selector: 'Policy-Overview-Dialog',
  templateUrl: 'policy-info.html',
  styleUrls: ['./reports.component.scss']
})

export class PolicyOverviewDialog {
  alertInfo: any = [];
  modalType: string;
  dtOptions: DataTables.Settings = {};
  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<PolicyOverviewDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.alertInfo = data.info
     }

  ngOnInit(){
    this.dtOptions = {
      searching: false,
      paging:false,
       ordering: false
       //pageLength: 5
      };
    this.apiService.reportModalType.subscribe(data=>{
      this.modalType = data;
    })

    // this.apiService.getPolicyInfoByName(policyName).subscribe(res => {
    //   console.log("computers d data", res);
    //   this.showPolicyDetail = res;
    //   this.dtTrigger.next();
    // });
  }   

  exit(): void {
    this.dialogRef.close();
  }

}

//Show policy popup component
// @Component({
//   selector: 'Policy-Overview-Dialog',
//   templateUrl: 'policy-info.html',
//   styleUrls: ['./reports.component.scss']
// })
// export class PolicyOverviewDialog {
//   image: any = [];
//   type: any;
//   dataReceived: any;
  

//   constructor(
//     private apiService: AuthService, public dialogRef: MatDialogRef<PolicyOverviewDialog>,@Inject(MAT_DIALOG_DATA) public data: any
//   ) { 
//     this.image = data.image
//   }

//   ngOnInit(){
//     this.apiService.modalType.subscribe(data=>{
//       this.type =data;
//     })

//     this.apiService.modalData.subscribe(data=>{
//       this.dataReceived = data;
//     })
    
//   }

//   exit(): void {
//     this.dialogRef.close();
//     this.dataReceived = [];
//   }

// }
