<h2 mat-dialog-title style="color: #00529C;">Add Tag Details</h2>
<mat-dialog-content class="mat-typography">
  <div class="">

    <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <label>Name</label>
        <input type="text" autocomplete="off" name="tagName" class="form-control form-control-lg" maxlength="20" [(ngModel)]="model.tagName"
          #tagName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && tagName.invalid }" required>
        <div *ngIf="f.submitted && tagName.invalid" class="invalid-feedback">
          <div *ngIf="tagName.errors.required"> Name is required</div>
        </div>
      </div>

      <div class="form-group">
        <label>Value</label>
        <input type="text" autocomplete="off" name="tagValue" class="form-control form-control-lg" [(ngModel)]="model.tagValue"
          #tagValue="ngModel" maxlength="100" [ngClass]="{ 'is-invalid': f.submitted && tagValue.invalid }" required>
        <div *ngIf="f.submitted && tagValue.invalid" class="invalid-feedback">
          <div *ngIf="tagValue.errors.required">Value is required</div>
        </div>
      </div>

      <div class="form-group">
        <button (click)="ToolGrSaveData()" class="btn btn-primary btn-lg" color="primary">Save</button>
        &nbsp; &nbsp;

        <button color="primary" type="reset" mat-dialog-close class="btn btn-primary btn-lg">
          Close
        </button>
      </div>
    </form>

  </div>
</mat-dialog-content>