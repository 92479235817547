<div class="dialogue">
  <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
    close
  </span>
</div>

<div *ngIf="adminRole">

  <label id="example-radio-group-label" style="font-size: 15px;">Select Category :</label>
  <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" name="opList"
    [(ngModel)]="selectedType">
    <mat-radio-button style="margin-left: 7%" class="example-radio-button" *ngFor="let selectedtype of types"
      [value]="selectedtype">
      {{selectedtype}}
    </mat-radio-button>
  </mat-radio-group>
  <div *ngIf="selectedType == 'iManage'">
    <div *ngIf="noImanageProjects">
      <h2 class="errorMessage">No iManage projects available.</h2>
    </div>
    <mat-vertical-stepper *ngIf="!noImanageProjects" (selectionChange)="setIndex($event)" (click)="triggerClick()">
      <mat-step label="Add New Project">
        <a style="color: red" *ngIf="showError">**Project selected is already created please select another Project.</a>
        <table mat-table [dataSource]="dataSource" style="width:100%">

          <!-- Name Column -->
          <ng-container matColumnDef="projectId">
            <th mat-header-cell *matHeaderCellDef> iManage ID </th>
            <td mat-cell *matCellDef="let element"> {{element.projectId}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="projectName">
            <th mat-header-cell *matHeaderCellDef> Project Name </th>
            <td mat-cell *matCellDef="let element"> {{element.projectName}} </td>
          </ng-container>

          <!-- PmName Column -->
          <ng-container matColumnDef="projectManager">
            <th mat-header-cell *matHeaderCellDef> Project Manager </th>
            <td mat-cell *matCellDef="let element"> {{element.projectManager}} </td>
          </ng-container>

          <!-- KdmName Column -->
          <ng-container matColumnDef="kdmName">
            <th mat-header-cell *matHeaderCellDef> KDM Name </th>
            <td mat-cell *matCellDef="let element"> - {{element.kdmName}} </td>
          </ng-container>

          <!-- StartDate Column -->
          <ng-container matColumnDef="startDate">
            <th mat-header-cell *matHeaderCellDef> Start Date </th>
            <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
          </ng-container>

          <!-- EndDate Column -->
          <ng-container matColumnDef="endDate">
            <th mat-header-cell *matHeaderCellDef> End Date </th>
            <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
          </ng-container>

          <!-- ProjectType Column -->
          <ng-container matColumnDef="projectType">
            <th mat-header-cell *matHeaderCellDef> Project Type </th>
            <td mat-cell *matCellDef="let element"> {{element.projectType}} </td>
          </ng-container>

          <!-- CustomerName Column -->
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef> Customer </th>
            <td mat-cell *matCellDef="let element"> - {{element.customerName}} </td>
          </ng-container>

          <!-- DeliveryBu Column -->
          <ng-container matColumnDef="deliveryBU">
            <th mat-header-cell *matHeaderCellDef> Delivery BU </th>
            <td mat-cell *matCellDef="let element"> {{element.deliveryBU}} </td>
          </ng-container>

          <!-- description Column -->
          <ng-container matColumnDef="isNew">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.isNew}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i=index"
            (click)="selection.toggle(row); naviagte();"
            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false">
          </tr>
        </table>

      </mat-step>
      <mat-step label="View Team Members" *ngIf="showNext">
        <div class="example-container">
          <table class="member-table" mat-table [dataSource]="dataSourceUser" style="width:100%">

            <!-- ID Column -->
            <ng-container matColumnDef="user_id">
              <th mat-header-cell *matHeaderCellDef> PS Number </th>
              <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="user_name">
              <th mat-header-cell *matHeaderCellDef class="table-align"> Name </th>
              <td mat-cell *matCellDef="let element" class="table-align"> {{element.user_name}}
              </td>
            </ng-container>

            <!-- email Column -->
            <ng-container matColumnDef="user_email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
            </ng-container>

            <!-- role Column -->
            <ng-container matColumnDef="role_name">
              <th mat-header-cell *matHeaderCellDef> Role </th>
              <td mat-cell *matCellDef="let element"> {{element.role_name}} </td>
            </ng-container>

            <!-- StartDate Column -->
            <ng-container matColumnDef="project_start_date">
              <th mat-header-cell *matHeaderCellDef class="table-align"> Start Date
              </th>
              <td mat-cell *matCellDef="let element" class="table-align">
                {{element.project_start_date}} </td>
            </ng-container>

            <!-- EndDate Column -->
            <ng-container matColumnDef="project_end_date">
              <th mat-header-cell *matHeaderCellDef class="table-align"> End Date
              </th>
              <td mat-cell *matCellDef="let element" class="table-align">
                {{element.project_end_date}} </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef class="table-align"> Location </th>
              <td mat-cell *matCellDef="let element" class="table-align"> {{element.location}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsUser;sticky:true" class="table-align"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsUser;"
              [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
              (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
            </tr>
          </table>

        </div>
        <div class="text-center pt-2">
          <button class="btn-register" matStepperNext *ngIf="showNext && showFinish" mat-raised-button
            color="primary">Next</button>
        </div>
      </mat-step>

      <mat-step *ngIf="showNext">
        <ng-template matStepLabel>Select Catalog</ng-template>
        <div class="radioCatalogButton">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="Id" [checked]='true' (click)="setradio('Id')">Search By ID</mat-radio-button>
            <mat-radio-button value="Name" (click)="setradio('Name')">Search By Name</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="row pl-4">
          <form class="example-form">
            <div class="col-md-6">
              <div *ngIf="showSearchByName">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Enter Catalog Name" aria-label="Number" matInput
                    [formControl]="myControl" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option (click)="searchCatalogByName(option)" *ngFor="let option of filteredOptions | async"
                      [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div *ngIf="showSearchById">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Enter Catalog Id" aria-label="Number" matInput
                    [formControl]="myControlId" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option (click)="searchCatalogById(optionId)" *ngFor="let optionId of filteredOptionsId | async"
                      [value]="optionId">
                      {{optionId}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

            </div>
          </form>
        </div>


        <div class="pl-4" *ngIf="catalogTableFlag">
          <div class="row mt-3">
            <div class="col-md-3">
              <span class="field-name">Catalog ID </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_id}}</span>

              <span class="material-icons info-icon" title="System Information"
                (click)="showCatalogInfo('subscription')">info</span>

            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <span class="field-name">Catalog Name </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_name}}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <span class="field-name">Catalog Description </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_description}}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <button mat-raised-button color="primary" class="float-right"
                (click)="createImanageProject()">Register</button>
            </div>
          </div>

        </div>

      </mat-step>

    </mat-vertical-stepper>


  </div>

  <div *ngIf="selectedType == 'Custom'">

    <mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="selectionChange($event)">
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>Add New Project</ng-template>

          <div class="row">
            <div class="col-3 field-name">Project Id</div>
            <div class="col-12 col-md-9">
              <mat-form-field appearance="fill">
                <mat-label>Enter Project Id</mat-label>
                <input matInput #input placeholder="" onPaste="return false" onCopy="return false" name="customId"
                  formControlName="customId" [class.is-invalid]="customId.invalid && customId.touched">
              </mat-form-field>
              <small class="text-danger pl-1"
                [class.d-none]="customId.valid || customId.untouched">{{getCustomIdErrorMessage()}}</small>
            </div>
          </div>
          <div class="row">
            <div class="col-3 field-name">Project Name</div>
            <div class="col-12 col-md-9">
              <mat-form-field appearance="fill">
                <mat-label>Enter Project Name</mat-label>
                <input matInput #input placeholder="" onPaste="return false" onCopy="return false" name="customName"
                  formControlName="customName" [class.is-invalid]="customName.invalid && customName.touched">
              </mat-form-field>
              <small class="text-danger pl-1"
                [class.d-none]="customName.valid || customName.untouched">{{getCustomNameErrorMessage()}}</small>

            </div>
          </div>

          <div class="offset-md-3 col-md-2">
            <button mat-button matStepperNext class="btn-register" mat-raised-button color="primary">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>Member Details</ng-template>

          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Enter Member Email</mat-label>
                <input type="text" matInput #input placeholder="" name="memberEmail" formControlName="memberEmail">
              </mat-form-field>

              <small class="text-danger" *ngIf="userNotFound">{{errMessage}}</small>
              <small class="text-danger" *ngIf="memberEmail.hasError('pattern') && !userNotFound">Please enter valid
                email address</small>
              <small class="text-danger" *ngIf="userExists && !memberEmail.hasError('pattern') && !userNotFound">Member
                already added</small>
            </div>
            <div class="col-md-1">
              <button mat-button mat-raised-button color="primary"
                [disabled]="(memberEmail.value == '' || memberEmail.value == ' ' || memberEmail.value == null) || memberEmail.invalid"
                (click)="searchEmployee()" class="add-button">Add
              </button>
            </div>
          </div>

          <div class="example-container " *ngIf="enableRegister">

            <table class="member-table" mat-table [dataSource]="dataSourceCustomUser">

              <ng-container matColumnDef="user_id">
                <th mat-header-cell *matHeaderCellDef> PS Number </th>
                <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
              </ng-container>

              <ng-container matColumnDef="user_name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.user_name}}
                </td>
              </ng-container>

              <ng-container matColumnDef="user_email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                  <span (click)="deleteRecord(element)" *ngIf="psNumber != element.user_id"><i
                      class="fas fa-trash pointer"></i></span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsCustomUser;sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsCustomUser;">
              </tr>
            </table>

          </div>
          <div class="offset-md-5 col-md-2 mt-2">
            <button class="btn-register" mat-button matStepperNext *ngIf="enableRegister" mat-raised-button
              color="primary">Next</button>

          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Select Subscription</ng-template>
        <div class="radioCatalogButton">
          <mat-radio-group aria-label="Select an option">
            <mat-radio-button value="Id" [checked]='true' (click)="setradio('Id')">Search By ID</mat-radio-button>
            <mat-radio-button value="Name" (click)="setradio('Name')">Search By Name</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="row pl-4">
          <form class="example-form">
            <div class="col-md-6">
              <div *ngIf="showSearchByName">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Enter Subscription Name" aria-label="Number" matInput
                    [formControl]="myControl" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option (click)="searchCatalogByName(option)" *ngFor="let option of filteredOptions | async"
                      [value]="option">
                      {{option}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>

              <div *ngIf="showSearchById">
                <mat-form-field class="example-full-width">
                  <input type="text" placeholder="Enter Subscription Id" aria-label="Number" matInput
                    [formControl]="myControlId" [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option (click)="searchCatalogById(optionId)" *ngFor="let optionId of filteredOptionsId | async"
                      [value]="optionId">
                      {{optionId}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <small class="text-danger" *ngIf="subFlag">No tools available, Please select other subscription to
                proceed.</small>


            </div>
          </form>
        </div>


        <div class="pl-4" *ngIf="catalogTableFlag">
          <div class="row mt-1">
            <div class="col-md-3">
              <span class="field-name">Subscription ID </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_id}}</span>

              <span class="material-icons info-icon" title="System Information" *ngIf="!subFlag"
                (click)="showCatalogInfo('subscription')">info</span>

            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span class="field-name">Subscription Name </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_name}}</span>
            </div>
          </div>
          <div class="row mt-1">

            <div class="col-md-3">
              <span class="field-name">Subscription Description </span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{catalogSearchedData.catalog_description}}</span>
            </div>
          </div>
          <!-- <small class="text-danger pl-1">{{emptySubs}}</small> -->

          <div class="offset-md-5 col-md-2 mt-2">
            <button [disabled]="nextFlag" class="btn-register" mat-button matStepperNext mat-raised-button
              (click)="nextTemplate()" color="primary">Next</button>

          </div>
        </div>

      </mat-step>

      <mat-step [stepControl]="catalogSearchedData.catalog_id">
        <ng-template matStepLabel>Select Project Template</ng-template>

        <div class="row pl-4">
          <form class="example-form" [formGroup]="thirdFormGroup">
            <div class="col-md-6">
              <mat-form-field>
                <mat-select placeholder="Select Project Template" formControlName="domainName"
                  (selectionChange)="getDomain($event.value)">
                  <mat-option *ngFor="let domain of domainList" [value]="domain">
                    {{domain.template_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </form>
        </div>


        <div class="pl-4" *ngIf="templateFlag">
          <div class="row mt-1">
            <div class="col-md-3">
              <span class="field-name">Template Id</span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{selectedTemplateId}}</span>

              <span class="material-icons info-icon" title="System Information"
                (click)="showCatalogInfo('template')">info</span>


            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-3">
              <span class="field-name">Template Name</span>
            </div>
            <div class="col-md-6">
              <span class="field-value"> :
                {{selectedTemplateName}}</span>
            </div>
          </div>


          <div class="row mt-1">
            <div class="col-md-6">
              <button mat-raised-button color="primary" class="float-right"
                (click)="createCustomProject()">Register</button>
              <a style="color: red" *ngIf="showProjIdError">{{projectCreateError}}</a>
            </div>
          </div>

        </div>

      </mat-step>
    </mat-vertical-stepper>

  </div>

</div>

<div *ngIf="!adminRole">
  <h2 class="noAccess">You do not have access to add project. Please contact Administrator.</h2>
</div>
<button data-toggle="modal" hidden="true" class="B-none" id="CallModel2" data-target="#confirmationModal2"></button>
                        <!-- Modal2 -->
                        <div class="modal fade" id="confirmationModal2" tabindex="-1" role="dialog"
                          aria-labelledby="confirmationModal2" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content pop-up">
                              <div class="modal-header-popup">
                                <span class="vertical-line"> </span>
                                <span class="header mb-0">Note</span>
                                <span data-dismiss="modal" class="material-icons close-icon">
                                  close
                                </span>
                              </div>
                              <hr class="margin-class" />
                              <div class="modal-body">
                                The cost data will be updated after 24 hours of resource provisioning
                                <!-- {{Message}} -->
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">OK</button>
                                <!-- <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal"
                                  (click)="hideFlags('chart')">OK</button> -->
                              </div>
                            </div>
                          </div>
                        </div>