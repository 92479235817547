<link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css">
<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">Webex Teams</li>
          </ol>
        </nav>
      </div>
      <div *ngIf="showMenu == true" class="offset-md-5 col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
      <div class="offset-md-5 col-md-1 p-md-0 my-auto table-options" *ngIf="showMenu == false">
        <span (click)="openOptions()" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>

    

  </div>


  <div class="inner-mid-container">
    <div class="row">
      <div class="col-md-3">
        <h1 class="page-name mb-0">
          <img width="40" src="assets/img/webex.jpg">
          <div class="vertical-line mx-3"> </div> <span>Webex Teams</span>
        </h1>
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
          {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information"
          (click)="showProjectInfo()">info</span>
      </div>

    </div>
    <div class="row optionDiv" *ngIf="showMenu">
      <div class="offset-2 col-2 hover" (click)="showSpace()" title="Create/View Spaces">
        <img width="70" src="assets/img/createSpaces.png"><br>
        <span class="optionLabel">Spaces</span>
      </div>
      <div class="offset-1 col-2 hover" (click)="setMeeting()" title="Setup Meetings">
        <img width="65" src="assets/img/setMeeting.png"><br>
        <span class="optionLabel">Meetings</span>
      </div>
      <div class="offset-1 col-2 hover" (click)="setMeetingArtifacts();getMeetingName();getTeamName();getExistingArtifacts()"
        title="Meeting Artifacts">
        <img width="65" src="assets/img/artifactIcon.png"><br>
        <span class="optionLabel">Artifacts</span>
      </div>
    </div>
    <!-- <div class="row ptPercent" *ngIf="showMenu == false">
      <button class="col-1" type="submit" mat-raised-button color="primary" style="width: 16vh;" (click)="openOptions()">Back</button>
    </div> -->
    <div class="row ptPercent" *ngIf="spaces">
      <span title="Add new spaces" class="offset-md-11 hover" (click)="showSpaceForm()"><i class="fas fa-plus"></i>
        Add</span>
    </div>

    <div class="row mt1 pl-3" *ngIf="showForm">
      <div class="col-md-2 field-name" style="padding-top: 26px;">Space Names</div>


      <div class="col-md-10 channelList">
        <div class="row channel-list">
          <div class="pr-3 mb-5" *ngFor="let element of channelArrayData; let i = index">
            <div class="col-md-12">

              <label>Edit Space Name
                <span class="glyphicon glyphicon-remove" style="cursor:pointer;padding-left: 25px;"
                  (click)="removeChannelName(i)"></span>
              </label>
              <input placeholder="Edit Space Name" name="channelName{{i}}" [(ngModel)]="element.channelName"
                class="form-control form-control-height">

            </div>
            <div>

            </div>
          </div>

          <div class="col-md-2">
            <span class="glyphicon glyphicon-plus-sign" style="cursor:pointer; font-size: 30px;"
              (click)="enableEdit()"></span>

          </div>
        </div>
      </div>

      <!--   <div class="col-md-10 channelList">
  
          <div *ngFor="let element of channelArrayData; let i = index">
            <div class="col-md-2 pt-2">
  
              <label>Edit Space Name</label>
              <input placeholder="Edit Channel Name" name="channelName{{i}}" [(ngModel)]="element.channelName"
                class="form-control form-control-height">
            </div>
            <div class="col-md-1 pt-2">
              <span class="glyphicon glyphicon-remove" style="cursor:pointer" (click)="removeChannelName(i)"></span>
            </div>
          </div>
  
          <div class="col-md-2 pt-2">
            <span class="glyphicon glyphicon-plus-sign" style="cursor:pointer; font-size: 30px;"
              (click)="enableEdit()"></span>
  
          </div>
  
        </div>-->
    </div>
    <div class="row button-class" *ngIf="showForm">
      <button mat-raised-button color="primary" class="create-channel" (click)="createSpaces()">Create Space
      </button>
      <button mat-raised-button color="primary" class="create-channel" (click)="cancel()">Cancel
      </button>
    </div>

    <div class="row mt-2" *ngIf="existingSpaces">
      <table class="table fixed-table" datatable [dtOptions]="dtOptions">
        <thead>
          <tr>
            <th>Space Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let space of spaceTable">
            <td>{{space.space_name}}</td>
            <td>

              <div class="dropdown dropdown-style">
                <a href="#" class="nav-link" data-toggle="dropdown">
                  <i class="fas fa-ellipsis-h"></i>
                </a>

                <div class="dropdown-menu more-drop-down">
                  <a class="dropdown-item" (click)="viewUserForSpace(space.space_id,space.space_name,space.members)">
                    <img width="20" src="../../../assets/img/View-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> View Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item"
                    (click)="openUserForSpace(space.space_name,space.members,space.space_id,'Add')">
                    <img width="20" src="../../../assets/img/Add-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> Add Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item"
                    (click)="openUserForSpace(space.space_name,space.members,space.space_id,'Delete')">
                    <img width="20" src="../../../assets/img/View-Team-member.png"> <span
                      class="vertical-line-more mx-2"></span> Remove Member</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="deleteSpace(space.space_id)">
                    <img width="15" src="../../../assets/img/Delete-blue.png"> <span
                      class="vertical-line-more mx-2"></span> Delete Space</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item disabled-img"
                    (click)="addDeleteTools(space.space_name,space.members,space.space_id,'Add')">
                    <i class="fas fa-plus"></i> <span class="vertical-line-more mx-2"></span> Add Tools</a>

                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item disabled-img"
                    (click)="addDeleteTools(space.space_name,space.members,space.space_id,'Delete')">
                    <img width="15" src="../../../assets/img/Delete-blue.png"> <span
                      class="vertical-line-more mx-2"></span> Delete Tools</a>

                </div>
              </div>


            </td>

          </tr>

        </tbody>
      </table>
    </div>


    <div *ngIf="setNewMeeting">

      <div class="row ml-0">
        <label>Meeting Details :</label>
      </div>

      <div class="row">
        <label class="col-12">Select Agile Ceremonies </label>
      </div>
        <!-- To display the agile ceremonies -->
      <div class="row form-group">
        <mat-card [ngClass]="{'highlight' : selectedCard[i], 'not-highlight' : !selectedCard[i]}"
          class="col-12 col-md-2 ceremony-tiles" *ngFor="let ceremony of ceremonys; let i = index"
          (click)="onChange(ceremony);onSelect(i)"> {{ceremony.subject}}</mat-card>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6">
        <div class="form-group">
          <div><label>Select Projects</label></div>
            <ng-multiselect-dropdown [placeholder]="'Select Projects'" [settings]="dropdownSettings" [data]="dropdownList"
              [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
            </ng-multiselect-dropdown>
          </div>
        </div>
      </div>

      <!-- <div class="row">  -->
        <form (ngSubmit)="onSubmit()" #newEventForm="ngForm">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="subject">Subject</label>
                <input type="text" class="form-control" id="subject" [(ngModel)]="model.subject" name="subject"
                  #name="ngModel">
              </div>
            </div>
            <div class="col-12 col-md-4 ">
              <div class="form-group">
                <label for="body">Agenda</label>
                <input type="text" class="form-control" id="body" [(ngModel)]="model.body" name="body" #name="ngModel">
              </div>
            </div>
            <div class="col-12 col-md-4 ">
              <div class="form-group">
                <label for="schedule">Schedule</label>
                <input type="text" class="form-control" id="schedule" readonly name="schedule" #name="ngModel"
                  [(ngModel)]="schedule">
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-12">
              <!-- <div class="form-group"> -->
                <label id="example-radio-group-label">Select Meeting Type :</label>
                <mat-radio-group aria-labelledby="example-radio-group-label" name="opList" [(ngModel)]="selectedType">
                  <mat-radio-button class="col-6 col-md-1 example-radio-button ml-5" *ngFor="let selectedtype of types"
                    [value]="selectedtype" (click)="getSelectedType()">
                    {{selectedtype}}
                  </mat-radio-button>
                </mat-radio-group>
              <!-- </div> -->
            </div>
          </div>

          <div class="row mt-2" *ngIf="selectedType == 'Once'">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="start">Start Date</label>
                <input [min]="myDate" type="datetime-local" class="form-control" id="start" required
                  [(ngModel)]="model.start" name="start" #name="ngModel">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group" (click)="getMinimumEndDate()">
                <label for="end">End Date </label>
                <input [min]="minimumEndDate" type="datetime-local" class="form-control" id="end" required
                  [(ngModel)]="model.end" name="end" #name="ngModel">
              </div>
            </div>

          </div>



          <div class="pop-up" *ngIf="selectedType == 'Recurring'">

            <div class="popup-container">

              <!-- <div class="example-container1"> -->
                <form [formGroup]="secondFormGroup" class="pl-0">

                  <div class="row mt-2">
                    <div class="col-12 col-md-12"> Appointment Time : </div>
                  </div>

                  <div class="row mt-2">

                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="startTime">Start </label>
                        <select id="sttime" name="startTime" formControlName="startTime"
                          class="form-control form-control-recurring form-control-height select">
                          <option value="" disabled selected hidden>Select start time</option>
                          <option class="dropdown-css" *ngFor="let startTime of recurrencetime" [value]="startTime">
                            {{startTime}}</option>
                        </select>
                        <small class="text-danger"
                          [class.d-none]="startTime.valid || startTime.untouched">Required</small>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="endTime">End </label>
                        <select formControlName="endTime"
                          class="form-control form-control-recurring form-control-height select" required>
                          <option value="" disabled selected hidden>Select end time</option>
                          <option class="dropdown-css" *ngFor="let endTime of recurrencetime" [value]="endTime">
                            {{endTime}}</option>
                        </select>
                        <small class="text-danger" [class.d-none]="endTime.valid || endTime.untouched">Required</small>
                      </div>
                    </div>

                  </div>

                  <div class="row mt-2">
                    <div class="col-12 col-lg-12">Recurrence Pattern :</div>
                  </div>  

                  <div class="row mt-2">
                    <div class="form-group col-lg-2 ml-1 col-6 " >
                      <input type="radio" id="Daily" name="Recurrence" value="Daily" (change)="radiovalue('Daily')">
                      <label for="Daily">Daily</label><br>
                      <input type="radio" id="Weekly" name="Recurrence" value="Weekly" checked
                        (change)="radiovalue('Weekly')">
                      <label for="Weekly">Weekly</label><br>
                      <input type="radio" id="Monthly" name="Recurrence" value="Monthly"
                        (change)="radiovalue('Monthly')">
                      <label for="Monthly">Monthly</label><br>
                      <input type="radio" id="Yearly" name="Recurrence" value="Yearly" (change)="radiovalue('Yearly')">
                      <label for="Yearly">Yearly</label>
                    </div>
                    <span class="vertical-line mx-3" style="height: 16vh !important;"> </span>

                    <div class="col-lg-9 ml-4" *ngIf="recurrencePatternRadio == 'Weekly'">
                      <div class="row"> <span> Recur every </span>
                        <input formControlName="weeklyNoOfDays" type="number" min="1"
                          class="col-lg-1 col-5 form-control form-control-recurring" style="margin: 0 1vh">
                        <span>week(s) on</span></div>
                      <div class=" row form-group ">
                        <label class="btn monday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Monday')"> Monday
                        </label> &nbsp;
                        <label class="btn tuesday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Tuesday')"> Tuesday
                        </label> &nbsp;
                        <label class="btn wednesday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Wednesday')"> Wednesday
                        </label> &nbsp;
                        <label class="btn thursday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Thursday')"> Thursday
                        </label> &nbsp;
                        <label class="btn friday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Friday')"> Friday
                        </label> &nbsp;
                        <label class="btn saturday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Saturday')"> Saturday
                        </label> &nbsp;
                        <label class="btn sunday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Sunday')"> Sunday
                        </label>
                      </div>
                    </div>

                    <div class="col-lg-9" *ngIf="recurrencePatternRadio == 'Daily'">
                      <div class="col-lg-4 form-group">
                        <input type="radio" id="Every" name="DailyOption" value="Every" checked
                          (click)="recurrenceRadioDaily('Every')">
                        <label for="Every">Every : </label><br>
                        <input type="radio" id="Every Weekday" name="DailyOption" value="Every Weekday"
                          (click)="recurrenceRadioDaily('Every Weekday')">
                        <label for="Every Weekday">Every Weekday</label>
                      </div>
                      <div class="col-lg-8 form-group">
                        <input formControlName="dailyNoOfDays" type="number" min="1"
                          class="col-lg-1 form-control form-control-recurring" style="margin: 0 1vh">
                        <span>day(s)</span></div>
                    </div>

                    <div class="col-lg-9" *ngIf="recurrencePatternRadio == 'Monthly'">
                      <div class="col-lg-2 form-group">
                        <input type="radio" id="Day" name="MonthlyOption" value="Day" checked
                          (click)="recurrenceRadioMonthly('Day')">
                        <label for="Day">Day</label><br>
                        <input type="radio" id="The" name="MonthlyOption" value="The"
                          (click)="recurrenceRadioMonthly('The')">
                        <label for="The">The</label><br>
                      </div>
                      <div class="col-lg-10">
                        <div class="row ptPercent">
                          <input formControlName="monthlyDayDate" type="number" min="1" max="31"
                            class="col-md-1 ml-md-5 col-2 form-control form-control-recurring">
                          <span class="col-1 ml-3  mr-5 ml-md-0 mr-md-3">of every</span>
                          <input formControlName="monthlyDayMonthCount" type="number" min="1" max="12"
                            class="col-md-1 col-2 ml-md-0 ml-2 form-control form-control-recurring">
                          <span class="col-md-4 col-2 ">month(s) </span>
                        </div>

                        <div class="row ptPercent">
                          <select formControlName="monthlyTheWeekNo"
                              class="col-md-2 col-5 ml-md-5 form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Week no</option>
                              <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                {{weekNo.name}}</option>
                            </select>
                            <select id="weekDayValue" name="weekDayValue" formControlName="monthlyTheDay"
                              class="ml-2 col-5 col-md-2  form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Day</option>
                              <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                {{selectedDay}}</option>
                            </select>
                            <span class="col-lg-1 ml-md-1">of every</span>
                            <input formControlName="monthlyTheMonthCount" type="number" min="1" max="12"
                            class="ml-1 col-md-1 col-5 ml-md-1 form-control form-control-recurring" style="margin: 0 -3vh">
                            <span class="col-1 ml-4">month(s) </span>
                       
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-9 ml-4" *ngIf="recurrencePatternRadio == 'Yearly'">
                      <div class="row ptPercent"> <span> Recur every </span>
                        <input formControlName="yearlyNoOfYears" type="number" min="1"
                          class="col-lg-1 form-control form-control-recurring" style="margin: 0 1vh">
                        <span>year (s) on</span></div>
                      <div class="row ptPercent">
                        <input type="radio" id="On" name="YearlyOption" value="On" checked
                            (click)="recurrenceRadioYearly('On')">
                            <span class="col-md-1 col-12">On</span>
                          <select formControlName="yearlyOnMonth"
                                class="col-md-2 col-5 offset-md-1 form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>
                              <input formControlName="yearlyOnDate" type="number" min="1" max="31"
                              class="col-md-1 col-5 ml-2 form-control form-control-recurring">
                        <!-- <div class="col-lg-3 form-group">
                          <input type="radio" id="On" name="YearlyOption" value="On" checked
                            (click)="recurrenceRadioYearly('On')">
                          <label for="On">On</label><br>
                          <input type="radio" id="On The" name="YearlyOption" value="On The"
                            (click)="recurrenceRadioYearly('On The')">
                          <label for="On The">On The</label><br>
                        </div>
                        <div class="col-lg-9">
                          <div class="row ptPercent" style="margin: 0 -10vh">
                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnMonth"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>

                            </div>

                            <input formControlName="yearlyOnDate" type="number" min="1" max="31"
                              class="col-lg-2 form-control form-control-recurring">
                          </div>
                          <div class="row " style="margin: 0 -10vh">
                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnTheWeekNo"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select week No.</option>
                                <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                  {{weekNo.name}}</option>
                              </select>

                            </div>

                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnTheDay"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Day</option>
                                <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                  {{selectedDay}}</option>
                              </select>

                            </div>

                            <span class="col-lg-1">Of</span>
                            <div class="form-group col-lg-3 " style="margin: 0 -7vh">
                              <select formControlName="yearlyOnTheMonth"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>
                            </div>
                          </div>

                        </div> -->
                      </div>
                        <div class="row pt-2 ptPercent">
                          <input type="radio" id="On The" name="YearlyOption" value="On The"
                          (click)="recurrenceRadioYearly('On The')">
                          <span class="col-md-1 col-12">On The</span>
                            <select formControlName="yearlyOnTheWeekNo"
                                class="col-md-2 col-3 offset-md-1 form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select week No.</option>
                                <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                  {{weekNo.name}}</option>
                              </select>
                              <select formControlName="yearlyOnTheDay"
                                class="col-md-2 col-3 ml-2  form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Day</option>
                                <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                  {{selectedDay}}</option>
                              </select>
                              <span class="ml-md-3 mr-md-3 ml-1 mr-1">Of</span>
                              <select formControlName="yearlyOnTheMonth"
                              class="col-md-2 col-4 form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Month</option>
                              <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                            </select>


                    

                      </div>
                    </div>

                  </div>

                  <div class="row mt-2">
                    <div class="col-md-12 col-lg-12">Range of Recurrence :</div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-12 col-md-3">
                      <div class="form-group">
                        <label>Start</label>
                        <input [min]="customDate" type="date" formControlName="startByCalendar"
                          class="form-control form-control-recurring">
                        <small class="text-danger"
                          [class.d-none]="startByCalendar.valid || startByCalendar.untouched">Required
                        </small>
                      </div>
                    </div>

                    <div class="col-12 col-md-3">
                      <div class="form-group mt-2">
                        <input type="radio" id="End By" name="EndDate" checked value="End By"
                          (click)="endRadio('endBy')">
                        <label for="End By">End By :</label><br>
                        <input type="radio" id="End After" name="EndDate" value="End After"
                          (click)="endRadio('endAfter')">
                        <label for="End After">End After :</label><br>
                        <input type="radio" id="No End Date" name="EndDate" value="No End Date"
                          (click)="endRadio('noEnd')">
                        <label for="No End">No End Date</label>
                      </div>
                    </div>

                      <div class="col-12 col-md-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group" (click)="getMinValue()">
                              <input [min]="startCalender" formControlName="endByCalendar" type="date"
                                class="form-control form-control-recurring">
                            </div>
                          </div>
                        </div>
                        <div class="">
                          <div class="col-12 col-md-6"></div>
                          <div class="form-group">
                            <label>Occurences</label>
                            <input type="number" formControlName="endAfterCount" min="1"
                              class="form-control form-control-recurring">
                          </div>
                        </div>
                      </div>
                    </div>
                </form>
              <!-- </div> -->
            </div>

          </div>

          
          <div *ngIf="selectedType == 'Once'">
            <div class="row">
              <div class="col-12 col-md-1 mt-4">
                <button class="webex-btn width-100" type="submit" mat-raised-button color="primary"
                  [disabled]="!newEventForm.form.valid">Create</button>
              </div>
              <div class="col-12 col-md-1 mt-4">
                <button mat-raised-button color="primary" type="button"
                  (click)="handleClear()" class="btn-canvas width-100 btn-canvas-blue-2">Cancel</button>
              </div>
            </div>
          </div>
          <div *ngIf="selectedType == 'Recurring'">
            <div class="row mt">
              <div class="col-12 col-md-1 mt-4">
                <button class="webex-btn width-100" type="submit" mat-raised-button color="primary"
                  (click)="formvalue()" [disabled]="!secondFormGroup.valid">Create</button>
              </div>
              <div class="col-12 col-md-1 mt-4">  
                <button mat-raised-button color="primary" type="button"
                  class="btn-canvas width-100 btn-canvas-blue-2" (click)="handleClear();clearForm()">Cancel</button>
              </div>
            </div>
          </div>

        </form>
      <!-- </div>  -->
    </div>

    <div class="row ptPercent" *ngIf="meetingArtifacts">
      <span title="Add" class="offset-10 offset-md-11 hover" (click)="showArtifactForm()"><i class="fas fa-plus"></i> Add</span>

      <form [formGroup]="firstFormGroup" class="pl-0" *ngIf="artifactForm" style="width:100%">
        <div class="col-lg-12 pl-3">
          <label>Meeting Artifact Details :</label>
        </div>
        <div class="row padding0 pt10 pl-3">
          <div class="col-12 pl-3"><label>Meeting Name</label>
            <span class="material-icons show-meteing-info" title="System Information"
            (click)="showwebexMeetingInfo()">info</span>
           </div>
          
         
        </div>
        <div class="row padding0 pt10 pl-3">
          <div class="col-12  col-md-4 pl-3">
          <select id="meetingName" name="meetingName" formControlName="meetingName" [(ngModel)]="meetingNameValue"
          class="form-control form-control-recurring form-control-height select"
          (change)="meetingNameBody(meetingNameValue)">
          <option value="" disabled selected hidden>Select meeting name</option>
          <option *ngFor="let meetingName of meetingNameArray" class="dropdown-css"
            [value]="meetingName.MeetingName">{{meetingName.MeetingName}}</option>
          </select>
          <!-- <small class="text-danger" [class.d-none]="meetingName.valid || meetingName.untouched">Required</small> -->
         <!-- <div>
            <label>Meeting Details </label><br>{{meetingDetail}} 
         </div>  -->
        </div>
        </div>
        <div class="row  padding0 pt10 pl-3">
          <div class="col-lg-12 pl-3"><label>Report Utilization :</label> </div>
        </div>

        <div class="row  padding0 pt10 pl-3">
          <div class="form-group col-md-6 col-12">
            <label for="reportType">Report Type </label>
            <select id="reportType" name="reportType" formControlName="reportType"
              class="form-control form-control-recurring form-control-height select">
              <option value="" disabled selected hidden>Select report type</option>
              <option class="dropdown-css" *ngFor="let reportType of reportTypeArray" [value]="reportType">
                {{reportType}}</option>
            </select>
            <small class="text-danger" [class.d-none]="reportType.valid || reportType.untouched">Required</small>

          </div>
          <div class="form-group col-md-6 col-12">
            <label for="reportTime">Report send before minutes </label>

            <input formControlName="reportTime" type="number" min="1" class="form-control form-control-recurring">
            <small class="text-danger" [class.d-none]="reportTime.valid || reportTime.untouched">Required</small>

          </div>
        </div>


        <div class="row padding0 pt10 pl-3">
          <div class="form-group col-12 col-md-4">
            <label for="repoVendor">Report Source </label>
            <select (change)="getRepoVendorValue()" formControlName="repoVendor"
              class="form-control form-control-recurring form-control-height select" required>
              <option value="" disabled selected hidden>Select report vendor</option>
              <option class="dropdown-css" *ngFor="let repoVendor of vendor" [value]="repoVendor">{{repoVendor}}
              </option>
            </select>
            <!-- <small class="text-danger" [class.d-none]="repoVendor.valid || repoVendor.untouched">Required</small> -->
          </div>
          <div class="form-group col-12 col-md-4">
          <div *ngIf="repoVendorValue == 'Azure DevOps'">
           
              <label for="orgName">Organization Name </label>
              <select id="orgName" name="orgName" formControlName="orgName"
                class="form-control form-control-recurring form-control-height select">
                <option value="" disabled selected hidden>Select organization name</option>
                <option class="dropdown-css" *ngFor="let orgName of orgNameArray" [value]="orgName">{{orgName}}
                </option>
              </select>
              <!-- <small class="text-danger" [class.d-none]="orgName.valid || orgName.untouched">Required</small> -->

            </div>
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="projName">Project Name </label>
            <select id="projName" name="projName" formControlName="projName"
              class="form-control form-control-recurring form-control-height select">
              <option value="" disabled selected hidden>Select project name</option>
              <option class="dropdown-css" *ngFor="let projName of projNameArray" [value]="projName">{{projName}}
              </option>
            </select>
            <!-- <small class="text-danger" [class.d-none]="projName.valid || projName.untouched">Required</small> -->

          </div>
        </div>
  

        <div>

        </div>



        <div class="row padding0 pt10 pl-3">
          <div class="col-lg-12"><label>Webex Details :</label> </div>

          <div class="col-lg-4 pull-left ">
            <div class="form-group">
              <label for="teamName">Team Name </label>
              <select (change)="getSpaceNameForArti()" formControlName="teamName"
                class="form-control form-control-recurring form-control-height select" required>
                <option value="" disabled selected hidden>Select team name</option>
                <option class="dropdown-css" *ngFor="let teamName of teamNameArray" [value]="teamName.id">
                  {{teamName.name}}</option>
              </select>
              <small class="text-danger" [class.d-none]="teamName.valid || teamName.untouched">Required</small>
            </div>
          </div>
          <div class="col-lg-4 pull-left ">
            <div class="form-group">
              <label for="roomName">Space Name </label>
              <select id="roomName" name="roomName" formControlName="roomName"
                class="form-control form-control-recurring form-control-height select">
                <option value="" disabled selected hidden>Select space name</option>
                <option class="dropdown-css" *ngFor="let spaceName of spaceNameArray" [value]="spaceName.title">
                  {{spaceName.title}}</option>
              </select>
              <small class="text-danger" [class.d-none]="roomName.valid || roomName.untouched">Required</small>

            </div>
          </div>
        </div>

        <div class="row padding0 pt10 pl-3">
          <div class="col-lg-12"><label>Scheduler Configuration :</label> </div>
          <div class="col-lg-1 pull-left padding-right0">
            <div class="form-group select-editable">
              <label for="url">Schedule Time </label>
              <select onchange="this.nextElementSibling.value=this.value" formControlName="scheduleTime"
                class="form-control form-control-recurring form-control-height select" required>
                <option value="" disabled selected hidden>Select Schedule Time</option>
                <option class="dropdown-css" *ngFor="let scheduleTime of recurrencetime" [value]="scheduleTime">
                  {{scheduleTime}}</option>
              </select>
              <input type="text" name="format" value="" formControlName="scheduleTime" />
              <!-- <small class="text-danger" [class.d-none]="scheduleTime.valid || scheduleTime.untouched">Required</small> -->
            </div>
          </div>

          <span title="Execute Scheduler" class="hover ml-2 mt-3" style="padding-left: 3%;
        padding-bottom: 2%;" (click)="executeScheduler()"><i class="fas fa-sync-alt"></i> </span>


        </div>

        <div class="col-12 padding0 pl-3">
          <button class="col-5" mat-raised-button color="primary" type="submit" 
          (click)="artifactsValue()" class="btn btn-secondary" [disabled]="!firstFormGroup.valid">Submit</button>
          <!-- <button class="col-5" type="submit" mat-raised-button color="primary" style="width: 16vh;"
            (click)="artifactsValue()" [disabled]="!firstFormGroup.valid">Submit</button> -->
          <!-- <button class="col-5" mat-raised-button color="primary" type="button" style="margin-left: 1%;" (click)="executeScheduler()" [disabled]="!firstFormGroup.valid" class="btn btn-secondary">Execute Scheduler</button> -->
          <button class="col-5" mat-raised-button color="primary" type="button" 
            (click)="clearArtifacts()" class="btn btn-secondary ml-3">Cancel</button>
        </div>
      </form>

      <div class="row mt-2" *ngIf="existingArtifacts">
        <table class="table fixed-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Meeting Name</th>
              <th>Report Vendor</th>
              <th>Report Type</th>
              <th>Project Name</th>
              <th>Team Name</th>
              <th>Space Name</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Trigger Event Before</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let artifact of artifactTable">
              <td>{{artifact.MeetingName}}</td>
              <td>{{artifact.ReportVendor}}</td>
              <td>{{artifact.ReportType}}</td>
              <td>{{artifact.ProjectName}}</td>
              <td>{{artifact.TeamName}}</td>
              <td>{{artifact.SpaceName}}</td>
              <td>{{artifact.createdAt}}</td>
              <td>{{artifact.updatedAt}}</td>
              <td>{{artifact.TriggerEventBefore}}</td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>

  </div>



</div>
<!-- artifact changes added -->