
<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up">
 <div class="">
        <span  class="vertical-line mx-1 mx-md-3"> </span>
         <span class="header mb-0"> Project Details</span> 
        <span (click)="exit()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
<div class="popup-container">
<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Subscription ID </span>
    </div>
    <div class="col-md-6">
            <span> : {{catalog_id}}</span>
            <span class="material-icons show-catalog-info" title="System Information" (click)="showCatalogInfo('subscription')">info</span>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Subscription Name </span>
    </div>
    <div class="col-md-6">
            <span> : {{catalog_name}}</span>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Project Name </span>
    </div>
    <div class="col-md-6">
            <span> : {{projectName}}</span>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Primary Manager </span>
    </div>
    <div class="col-md-6">
            <span> : {{projectCreatedBy}}</span>
    </div>
</div>
<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Template ID </span>
    </div>
    <div class="col-md-6">

            <span> : {{template_id}}</span>

            <span class="material-icons show-catalog-info" title="System Information" (click)="showCatalogInfo('template')">info</span>
    </div>
</div>
<div class="row pt-2">
        <div class="col-md-4">
            <span class="field-name">Template Name </span>
        </div>
        <div class="col-md-6">
                <span> : {{template_name}}</span>
        </div>
    </div>
</div>
</div>