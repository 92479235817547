<h2 mat-dialog-title style="color: #00529C;">SCM Tools</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-md-6 text-center">
        <a routerLink="/admin-gitlab" mat-dialog-close>
            <img src="assets/img/gitlab-logo.png" title="Git Lab" class="img-fluid">
            <div class="d-block mt-3">
                <button mat-raised-button>Git lab</button> 
            </div>            
        </a>
      </div>
      <div class="col-md-6 text-center">
        <a routerLink="/admin-github" mat-dialog-close>
            <img src="assets/img/github-logo.png" title="GitHub" class="img-fluid">
            <div class="d-block mt-3">
              <button mat-raised-button>GitHub</button>
          </div>
        </a>
      </div>
  </div>
</mat-dialog-content>
