<div class="dialogue" *ngIf="modalTypeInProjectList == 'subscription'">
    <div class="pop-up">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0"> Subscription Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
    <!--span (click)="onNoClick()" class="material-icons" style="float: right;cursor: pointer;">
        close
    </span><br-->
    <div class="inner-top-container">
        <div class="table-responsive">
        <table class="table subscription-popup-table" [dtOptions]="dtOptions" datatable *ngIf="!emptyFlag">
            <thead>
                <tr>
                <th class="w-25">ID</th>
                <th class="w-15">Type</th>
                <th class="w-30">Name</th>
                <th class="w-30">Description</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cat of toolDetailsScm">
                    <td class="w-25">{{cat.tool_id}} {{toolDetailsVdiLength}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of toolDetailsJira">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of toolDetailsVdi">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of azure_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of network_config_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of deploymentStore_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of jenkins_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description}}</td>
                </tr>
                <tr *ngFor="let cat of aws_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.name}}</td>
                    <td class="w-30">{{cat.description ? cat.description : '-'}}</td>
                </tr>
                <tr *ngFor="let cat of aws_config_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.tool_category}}</td>
                    <td class="w-30">{{cat.description ? cat.description : '-'}}</td>
                </tr>
                <tr *ngFor="let cat of aws_network_config_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.tool_category}}</td>
                    <td class="w-30">{{cat.description ? cat.description : '-'}}</td>
                </tr>
                <tr *ngFor="let cat of aws_ec2_image_tools">
                    <td class="w-25">{{cat.tool_id}}</td>
                    <td class="w-15">{{cat.tool_name}}</td>
                    <td class="w-30 text-display">{{cat.tool_category}}</td>
                    <td class="w-30">{{cat.description ? cat.description : '-'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
        <div class="noData offset-md-5" *ngIf="emptyFlag">
            <h2>No Data Available</h2>
        </div>
    </div>
</div>

<div class="dialogue" *ngIf="modalTypeInProjectList != 'subscription'">
    <div class="pop-up">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0"> Template Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
    <div class="inner-top-container">
        <div class="table-responsive">
            <h2 class="ml-1">Roles</h2>
            <table class="table subscription-popup-table" >
                <thead>
                    <tr>
                    <th class="w-15">Role/Persona Name</th>
                    <th class="w-15">Logical Image Name</th>
                    <th class="w-15">Actual Image Name</th>
                    <th class="w-15">Privilege Access</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cat of roleData">
                        <td class="w-15">{{cat.template_role_name}}</td>
                        <td class="w-15">{{cat.role_local_image}}</td>
                        <td class="w-15 text-display">{{cat.azure_image}}</td>
                        <td class="w-15">{{cat.defined_role_name}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="noData offset-md-5" *ngIf="emptyFlag">
            <h2>No Data Available</h2>
        </div>

        <div class="row">

            <div class="col-md-12">
                <h2>Enabled Screens</h2>
            <table class="icon-table table-bordered">
                <tbody>
                    <tr>
                        <td class="enabled-icons" *ngFor="let cat of workbenchIcons">{{cat}}</td>
                    </tr>
                </tbody>
            </table>
            </div>
            
        </div>
    </div>
</div>