import { OnInit, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-scm-tool',
  templateUrl: './scm-tool.component.html',
  styleUrls: ['./scm-tool.component.scss'],
})
export class ScmToolComponent implements OnInit {
  model: any = {};
  projectId: any;
  projectName: any;
  members: any = [];
  username: any = [];
  toolName;
  downloadFile: any;
  memberArray: any = [];
  showTable: boolean = false;
  showRepo: boolean = false;
  showUserRow: boolean = false;
  showAddRepo: boolean = true;
  selectedUserArray: any = []
  secondFormGroup: FormGroup;
  toolGroup: any = [];
  memberString = [];
  tableArr = [];

  ELEMENT_DATA: RepoTable[];
  displayedColumns: string[] = ['repoName', 'toolInstance', 'status', 'members', 'action'];
  //dataSource = new MatTableDataSource<RepoTable>(this.ELEMENT_DATA);

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  catalog_id: string;
  catalog_name: string;
  emptyTool_list: boolean = false;
  Role: string = "";
  filterStatus = "Active";
  refreshDisable: boolean = true;
  scm_flag: boolean = false;
  template_id: string;
  template_name: string;
  idproject: string;
  memberArraysoft: any[];
  gitlabGroupFlag: boolean=false;
  checkGitlabGroupNameFlag: boolean=false;
  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService,
    private _formBuilder: FormBuilder, public router: Router,
    private _router: Router, private spinner: NgxSpinnerService) { }


  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.alertService.yesClickedEvent
        .subscribe((data: string) => {
          this.showRepo = false;
          this.showUserRow = false;
          this.selectedUserArray = [];
          this.secondFormGroup.reset();
        });

      let newCatalog_id = localStorage.getItem('catalogId');
      this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
        if (data.scm_tools.length == 0) {
          this.scm_flag = true;
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 437) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Unable to fetch data", this.options);
          throw error;
        }

      })

      this.alertService.workbenchYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this._router.navigate(['/workBench'])
        });

      this.alertService.homeYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this._router.navigate(['/projectSummary'])
        });

      this.projectId = localStorage.getItem('projectId');

      this.apiService.componentName.next("SCM");

      this.dtOptions = {
        order: []
      };
      //  console.log("-------");
      this.idproject = localStorage.getItem('projectId');

      this.apiService.getProjectById(this.idproject).subscribe((data: any[]) => {
        // debugger

        this.template_id = data[0].template_id;
        this.template_name = data[0].template_name;

      })
      this.catalog_id = localStorage.getItem('catalogId');

      this.catalog_name = localStorage.getItem('catalogName');
      this.projectName = localStorage.getItem('projectName');
      // this.template_id = localStorage.getItem("template_id");
      // this.template_id = localStorage.getItem("template_name");



      // console.log(this.template_id,this.template_name);
      //this.getGitlabProject();
      this.refreshGitlab(this.filterStatus);
      this.checkGitlabGroupFunc()

      this.apiService.toolSelectedFromDialog.subscribe(data => {
        this.toolName = data
      })

      this.secondFormGroup = this._formBuilder.group({
        repoName: ['', [Validators.required, Validators.pattern('^([a-zA-Z_0-9])+$'), Validators.maxLength(30)]],
        toolInstance: ['', Validators.required]
      });


    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }

  get repoName() {
    return this.secondFormGroup.get('repoName');
  }
  get toolInstance() {
    return this.secondFormGroup.get('toolInstance');
  }

  getRepoNameErrorMessage() {
    return this.secondFormGroup.controls.repoName.hasError('pattern') ?
      'Repo name must include only alphabets ,numbers and _' : this.secondFormGroup.controls.repoName.hasError('maxlength') ?
        'Maximum length can be 30 characters' :
        'Repo Name is required'
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteUser(request_id, email) {
    let body = {
      "project_id": this.projectId,
      "request_id": request_id,
      "members": [email],
      "requestor_name": localStorage.getItem('profile')
    }

    this.apiService.deleteMemberGitlab(body).subscribe(data => {
      this.refreshGitlab(this.filterStatus);
      this.alertService.success("User offboarded successfully.", this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 454) {
        //this.alertService.error(error.error.status, this.options);
      }
      else if (error.status == 403) { }
    })
  }

  deleteSCM(req_id) {
    let body = {
      "project_id": this.projectId,
      "request_id": req_id,
      "requestor_name": localStorage.getItem('profile')
    }

    this.apiService.deleteProjectGitlab(body).subscribe((data: any) => {
      this.refreshGitlab(this.filterStatus);
      this.alertService.success(data.message, this.options);

    })
  }
  softdeleteSCM(element) {
    this.memberArraysoft = []
    for (let i = 0; i < element.members.length; i++) {
      element.members[i]
      let body = {
        "gitlab_username": element.members[i].email.split("@")[0],
        "user_name": element.members[i].name,
        "gitlab_access_level": "40",
        "email": element.members[i].email,
        "role": element.members[i].role,
        "status": element.members[i].status,
      }
      this.memberArraysoft.push(body)
    }
    this.apiService.getProjectById(this.idproject).subscribe((data: any[]) => {
      this.template_id = data[0].template_id;
      this.template_name = data[0].template_name;

    })
    let body = {
      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName,
        "catalog_id": this.catalog_id,
        "catalog_name": this.catalog_name,
        "template_id": this.template_id,
        "template_name": this.template_name
      },
      "request_details": {
        "gitlab_project_details": [
          {
            "request_id": element.reqId,
            "request_status": element.status,
            "gitlab_project_name": element.repoName,
            "gitlab_repo_url": element.repoUrl,
            "gitlab_tool_instance": element.toolInstance,
            "gitlab_project_members": this.memberArraysoft,
            "requestor_name": localStorage.getItem('profile')
          }]
      }
    }
    this.apiService.softdeleteProject(body).subscribe((data: any) => {
      this.refreshGitlab(this.filterStatus);
      this.alertService.success(data.message, this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 454 || error.status == 435) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
    })

  }

  downloadData(type) {
    if (type == 'pdf') {
      this.apiService.downloadGitlabInPdf(this.projectId, this.filterStatus).subscribe(data => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName + '-Gitlab.pdf'
        link.click();
      })
    }
    else if (type == 'excel') {
      this.apiService.downloadGitlabInExcel(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-Gitlab.xlsx';
        link.click();
      })
    }
    else if (type == 'csv') {
      this.apiService.downloadGitlabInCsv(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-Gitlab.csv';
        link.click();
      })
    }

  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '26%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.createRepo();
        }
      }
    );
  }

  getStatus(status) {
    this.filterStatus = status;
    this.refreshGitlab(this.filterStatus)
  }

  refreshGitlab(filter) {
    // this.softdeleteSCM()
    this.dtOptions = {
      order: []
    };
    this.spinner.show()
    this.tableArr = [];
    this.refreshDisable = false;
    setTimeout(() => {
      this.refreshDisable = true;
    }, 2000)
    this.apiService.refreshGitlab(this.projectId, filter).subscribe((data: any) => {
      // console.log(data);
      
      if (data.length == 0) {
        this.spinner.hide();
      }
      data.forEach(project => {
        let memberArray = [];

        project.gitlab_project_members.forEach(member => {
          let memberObj = {
            name: member.user_name,
            email: member.email,
            role: member.role,
            status: member.status
          }
          if (member.status != 'offboarded') {
            memberArray.push(memberObj)
          }
        })

        let tableObj =
        {
          "reqId": project.request_id,
          "repoName": project.gitlab_project_name,
          "repoUrl": project.gitlab_repo_url,
          "toolInstance": project.gitlab_tool_instance,
          "status": project.gitlab_project_status,
          "members": memberArray,
          "action": "Update"
        }

        this.tableArr.push(tableObj)
        this.showTable = true
        this.dtTrigger.next();
        this.spinner.hide()
      })
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No Gitlab projects found.", this.options);
        throw error;
      }

    })
  }

  checkGitlabGroupFunc(){
    
    this.apiService.refreshGitlab(this.projectId, "All").subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        if(data[i].gitlab_project_status=="Completed"){
          this.checkGitlabGroupNameFlag=true
          
          
        }
      }
      
      
    
    }, (error: HttpErrorResponse) => {
      this.spinner.hide();
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No Gitlab projects found.", this.options);
        throw error;
      }

    })
  }

  getGitlabProject() {
    this.toolGroup = [];
    this.apiService.getCatalogProjectById(this.projectId).subscribe((project: any) => {
      var catalog_id = project[0].catalog_id;
      this.apiService.getCatalogById(catalog_id, 'SCM').subscribe((result: any) => {
        if (result.Tool_list.length == 0) {
          this.emptyTool_list = true;
        }

        result.Tool_list.forEach(element => {
          if (element != null) {
            this.toolGroup.push(element.tool_instance_details.scm_tool_instance.tool_instance_name)
          }


        });
      }, (error: HttpErrorResponse) => {
        if (error.status == 434) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available", this.options);
          throw error;
        }

      })

    })
  }

  addRepo() {
    this.selectedUserArray = [];
    this.apiService.selectedSCMMembers.next([])
    this.showRepo = true;
    this.showAddRepo = false;
  }

  checkToolData() {
    // console.log(this.checkGitlabGroupNameFlag);
    
    if((this.checkGitlabGroupNameFlag==true)){
        let newCatalog_id = localStorage.getItem('catalogId');
        this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
          if (data.scm_tools.length == 0) {
            this.scm_flag = true;
            let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
          }
          else {
            // console.log("hi");
    
            this.getGitlabProject();
            this.addRepo();
          }
        }, (error: HttpErrorResponse) => {
          if (error.status == 437) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.alertService.error("Unable to fetch data", this.options);
            throw error;
          }
    
        })
      

    }
    else{
      this.apiService.checkGitlabGroupName(this.catalog_id,"SCM",this.projectId).subscribe((data: any) => {
        if (data==true ){
          let newCatalog_id = localStorage.getItem('catalogId');
          this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
            if (data.scm_tools.length == 0) {
              this.scm_flag = true;
              let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
              element.click();
            }
            else {
              // console.log("hi");
      
              this.getGitlabProject();
              this.addRepo();
            }
          }, (error: HttpErrorResponse) => {
            if (error.status == 437) {
              this.alertService.error(error.error.message, this.options);
            }
            else if (error.status == 403) { }
            else {
              this.alertService.error("Unable to fetch data", this.options);
              throw error;
            }
      
          })
        }
        
   
      }, (error: HttpErrorResponse) => {
        if (error.status == 437) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error(error.error.message, this.options);
          throw error;
        }
  
      })
    }
 
  























  }

  openUser() {
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
      this.apiService.selectedSCMMembers.next(this.selectedUserArray);
    })

  }

  openAddedUserDialog(memberArray, reqStatus, request, type) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data: {
        member: memberArray,
        reqStatus: reqStatus,
        request: request,
        screen: type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshGitlab(this.filterStatus);
    });
  }


  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }


  createRepo() {
    this.spinner.show()
    this.showAddRepo = true;
    const letters = new Set(["\-","badges","blame","blob","builds",
    "commits",
    "create",
    "create_dir",
    "edit",
    "environments/folders",
    "files",
    "find_file",
    "gitlab-lfs/objects",
    "info/lfs/objects",
    "new",
    "preview",
    "raw",
    "refs",
    "tree",
    "update",
    "wikis"]);


    this.apiService.checkToolDuplicateName(this.catalog_id,"SCM",this.projectId,this.repoName.value).subscribe((scmResult: any) => {
      this.spinner.hide()
      if (scmResult==true ) {
        
        this.alertService.error("Repo Name already exists!", this.options);
        this.showRepo = true;
        this.showUserRow = true;
      }
      else if(letters.has(this.repoName.value)){
        this.alertService.error("Project Name is not allowed", this.options);
        this.showRepo = true;
        this.showUserRow = true;
      }
      else {
        this.showRepo = false;
        this.showUserRow = false;
        let memberArrayToSend = []
        this.memberString = [];
  
        this.selectedUserArray.forEach((user: any) => {
          let membObj = {
            name: user.user_name,
            email: user.user_email,
            role: "TM"
          }
          this.memberString.push(membObj)
          let memberObj = {
            "username": user.user_name.replace(' ', '.').toLowerCase(),
            "user_name": user.user_name,
            "email": user.user_email,
            "mail": 0,
            "role": "TM"
          }
          memberArrayToSend.push(memberObj)
        })
  
        let userPsNumber = "";
        let userName = localStorage.getItem('profile');
        this.apiService.psNumber.subscribe((psNumber: any) => {
          userPsNumber = psNumber
        })
  
        let env = environment.scmEnv;
  
        let scmBody = {
          "resource_type": "request",
          "request_type": "Gitlab",
          "request_status": "Pending",
          "requestor_id": userPsNumber,
          "requestor_name": userName,
          "project_details": {
            "project_id": this.projectId,
            "project_name": this.projectName,
            "catalog_id": this.catalog_id,
            "catalog_name": this.catalog_name
          },
          "request_details": {
            "scm_details": {
              "repository_name": this.repoName.value,
              "tool_instance": this.toolInstance.value,
              "gitlab_project_details": {
                "notification": 0,
                "oldrepo": 0,
                "project_name": this.projectName,
                "project_id": this.projectId,
                "env": env,
                "repos": [
                  {
                    "name": this.repoName.value,
                    "tool_instance": this.toolInstance.value,
                    "members": memberArrayToSend,
                    "activeMembers": []
                  }
                ]
              }
            }
          },
          "created_by": localStorage.getItem('mail').toLowerCase()
        }
  
  
        // console.log(scmBody);
  
  
        this.apiService.addToQueue(scmBody).subscribe((scmResult: any) => {
  
  
          // if (scmResult.status == "Success") {
          let tableObj =
          {
            "reqId": scmResult.request_id,
            "repoName": this.repoName.value,
            "member_status": "Pending",
            "repoUrl": "",
            "toolInstance": this.toolInstance.value,
            "status": "Pending",
            "members": this.memberString,
            "action": "Update"
          }
  
          //this.tableArr.push(tableObj)
  
          this.refreshGitlab(this.filterStatus);
  
          this.secondFormGroup.reset()
  
          this.alertService.success(scmResult.message, this.options)
          //  }
  
        }, (error: HttpErrorResponse) => {
          if (error.status == 435) {
            this.spinner.hide()

            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.spinner.hide()

            this.alertService.error("Error creating Gitlab request.", this.options);
            throw error;
          }
  
        })
      }


    }, (error: HttpErrorResponse) => {
      if (error.status == 435) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Error creating Gitlab request.", this.options);
        throw error;
      }

    })

    // debugger


  }

  //add members to existing repo
  addMemberToRepo(data, type) {
    const scmUser = this.dialog.open(addMemberRepoDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        values: data,
        type: type
      }
    });


    scmUser.afterClosed().subscribe(result => {
      this.refreshTimeout()
    })
  }
  refreshTimeout() {
    setTimeout(() => {
      this.refreshGitlab(this.filterStatus);
      // console.log("hi");

    }, 5000)

  }

  deleteconfirmationPopUp(element) {
    // debugger
    // console.log(element);


    const dialogRef = this.dialog.open(scmdeleteConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      // id:element
      data: {
        req: element.reqId
      }

    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.softdeleteSCM(element);
        }
      }
    );

  }


  toggleDisplay(secondFormGroup) {

    if (secondFormGroup.pristine == false) {
      this.cancelconfirmationPopUp();
    } else {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
    }

  }

  // confirmation popup
  cancelconfirmationPopUp() {

    const dialogRef = this.dialog.open(scmCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,

    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.scmworkbenchConfirmationPopup();
    } else {
      this._router.navigate(['/workBench'])
    }
  }
  goHome(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.homeConfirmationPopup();
    } else {
      this._router.navigate(['/projectSummary'])
    }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(scmWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(scmHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  checked: boolean
}

export interface RepoTable {
  repoName: string;
  repoUrl: string;
  toolInstance: string;
  status: string;
  members: [];
  action: string;
}


const ELEMENT_DATAA: MemberDetails[] = [];
@Component({
  selector: 'scm-members',
  templateUrl: 'scm-members.html',
  styleUrls: ['./scm-tool.component.scss']
})
export class SCMMembers {
  projectId: any;
  projectName: any;
  members: any = [];
  newUser: any = [];
  removedUser: any = [];
  newArray: any = [];
  finalMemberArray: any = [];
  tableArray: any = [];
  newStatus: string;
  existingScmMembers: any;
  existingVdiMembers: any;
  existingRoleMembers: any;
  ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATAA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  existingWorkspaceMembers: any;
  existingWorkspaceMembersEC2: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, private _snackBar: MatSnackBar, public router: Router, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SCMMembers>) { }

  ngOnInit(): void {
    // console.log("hi");
    // console.log(this.data);


    this.projectId = localStorage.getItem('projectId');

    this.projectName = localStorage.getItem('projectName');


    this.apiService.selectedSCMMembers.subscribe((members: any) => {
      this.existingScmMembers = members
    })

    this.apiService.componentName.subscribe(componentName => {
      this.componentName = componentName;
    })

    this.apiService.selectedVDIMembers.subscribe((members: any) => {
      // console.log("hgfjh");
      // console.log(members);


      this.existingVdiMembers = members
    })

    this.apiService.selectedJiraMembers.subscribe((members: any) => {
      // console.log(members);

      this.existingJiraMembers = members
    })
    this.apiService.selectedVDIMembersWorkspace.subscribe((members: any) => {
      // console.log(members);

      this.existingWorkspaceMembers = members
    })
    this.apiService.selectedVDIMembersEC2.subscribe((members: any) => {
      // console.log(members);

      this.existingWorkspaceMembersEC2 = members
    })
    this.apiService.selectedRoleMember.subscribe((members: any) => {
      this.existingRoleMembers = members
    })

    this.getUser();
  }
  allSelected() {
    this.isallSelected = true
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  addUserForRepo() {
    // console.log(this.componentName);

    if (this.isallSelected) {
      this.dialogRef.close(this.selection.selected);
    } else {
      if (this.selection.selected.length == 0) {
        this._snackBar.open("Select User", "", {
          duration: 3000,
        })
      } else {
        if (this.componentName == "SCM") {
          if (this.existingScmMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingScmMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingScmMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingScmMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedSCMMembers.next([])
          }
        }
        else if (this.componentName == "VDI") {
          if (this.existingVdiMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingVdiMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingVdiMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingVdiMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedVDIMembers.next([])
          }
        }
        else if (this.componentName == "AWS") {
          if (this.existingVdiMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingVdiMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingVdiMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingVdiMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedVDIMembers.next([])
          }
        }
        else if (this.componentName == "VM") {
          if (this.existingVdiMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingVdiMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingVdiMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingVdiMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedVDIMembers.next([])
          }
        }
        else if (this.componentName == "jira") {
          if (this.existingJiraMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingJiraMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingJiraMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingJiraMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedJiraMembers.next([])
          }
        }
        else if (this.componentName == "AWSWorkspace") {
          if (this.existingWorkspaceMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingWorkspaceMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingWorkspaceMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingWorkspaceMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedVDIMembersWorkspace.next([])
          }
        }
        else if (this.componentName == "AWS") {
          if (this.existingWorkspaceMembersEC2.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingWorkspaceMembersEC2.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingWorkspaceMembersEC2[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingWorkspaceMembersEC2);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedVDIMembersEC2.next([])
          }
        }
        else if (this.componentName == "Role") {
          if (this.existingRoleMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingRoleMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingRoleMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingRoleMembers);
            let finalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                finalArray.push(object)
              }
            })
            this.dialogRef.close(finalArray);
            this.apiService.selectedRoleMember.next([])
          }
        }
      }
    }
  }

  public getUser() {
    // console.log(this.data);
    if (this.componentName == 'VDI') {
      // debugger
      this.apiService.getCatalogProjectByIdVdi(this.data.projectid, this.data.templateid, this.data.role).subscribe(project => {
        this.members = project;
        // console.log(project);

        this.members.forEach((member, index) => {
          // debugger
          // this.members[index]['checked'] = true

          member['checked'] = false
          for (var i = 0; i < this.existingVdiMembers.length; i++) {
            if (member.user_email == this.existingVdiMembers[i].user_email) {
              this.members[index]['checked'] = true
              break;
            }
          }
        })
        this.dataSource.data = this.members as MemberDetails[]



      })

    }
    else {

      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        // console.log(project[0].members);

        this.members = project[0].members;
        if (this.componentName == "SCM") {
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingScmMembers.length; i++) {
              if (member.user_id == this.existingScmMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "VDI") {
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingVdiMembers.length; i++) {
              if (member.user_id == this.existingVdiMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "AWSWorkspace") {
          // this.existingWorkspaceMembers=[]
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingWorkspaceMembers.length; i++) {
              if (member.user_id == this.existingWorkspaceMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "AWS") {
          // this.existingWorkspaceMembers=[]
          // console.log(this.existingWorkspaceMembersEC2);

          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingWorkspaceMembersEC2.length; i++) {
              if (member.user_id == this.existingWorkspaceMembersEC2[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "VM") {
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingVdiMembers.length; i++) {
              if (member.user_id == this.existingVdiMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "jira") {
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingJiraMembers.length; i++) {
              if (member.user_id == this.existingJiraMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
          })
        }
        else if (this.componentName == "Role") {
          this.members.forEach((member, index) => {
            member['checked'] = false
            for (var i = 0; i < this.existingRoleMembers.length; i++) {
              if (member.user_id == this.existingRoleMembers[i].user_id) {
                this.members[index]['checked'] = true
                break;
              }
            }
            this.apiService.parentSelectedRole.subscribe((role: any) => {
              let dummyRoleArray = [...this.members];
              this.members.forEach((member, index) => {
                for (let i = 0; i < member.role_name.length; i++) {
                  if (member.role_name[i] == "Project Manager") {

                    dummyRoleArray.splice(index, 1)
                  }
                  else {

                  }
                }

              });
              this.members = dummyRoleArray
            })
          })
        }
        this.dataSource.data = this.members as MemberDetails[]
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })
    }
  }


  exit() {
    if (this.componentName == "SCM") {
      this.dialogRef.close(this.existingScmMembers);
    }
    else if (this.componentName == "VDI") {
      this.dialogRef.close(this.existingVdiMembers);
    }
    else if (this.componentName == "jira") {
      this.dialogRef.close(this.existingJiraMembers);
    }
    else if (this.componentName == "AWSWorkspace") {
      this.dialogRef.close(this.existingWorkspaceMembers);
    }

    else if (this.componentName == "Role") {
      this.dialogRef.close(this.existingRoleMembers);
    }
    else if (this.componentName == "AWS") {
      this.dialogRef.close(this.existingWorkspaceMembersEC2);
    }
    else if (this.componentName == "VM") {
      this.dialogRef.close(this.existingRoleMembers);
    }
  }
}
@Component({
  selector: 'members-list',
  templateUrl: 'members-info.html',
  styleUrls: ['./scm-tool.component.scss']
})

export class MembersListDialog {
  memberList: any = [];
  reqStatus: any;
  request: any;
  projectId: string;
  screen: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    public dialogRef: MatDialogRef<MembersListDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private apiService: AuthService, public alertService: AlertService) {

    this.memberList = data.member
    // console.log(this.memberList);

    this.reqStatus = data.reqStatus
    this.request = data.request
    this.screen = data.screen
    this.projectId = localStorage.getItem('projectId');
  }

  deleteUser(email) {
    if (this.screen == 'Jira') {
      let body = {
        "project_id": this.projectId,
        "request_id": this.request,
        "user_email_list": [email]
      }
      this.apiService.deleteUserFromJira(body).subscribe((data: any) => {
        this.dialogRef.close();
        this.alertService.success(data.message, this.options);
      }, (error: HttpErrorResponse) => {
        if (error.status == 454) {
          //this.alertService.error(error.error.status, this.options);
        }
        else if (error.status == 403) { }
      })
    }

    if (this.screen == 'SCM') {
      let body = {
        "project_id": this.projectId,
        "request_id": this.request,
        "members": [email],
        "requestor_name": localStorage.getItem('profile')
      }
      this.apiService.deleteMemberGitlab(body).subscribe((data: any) => {
        this.dialogRef.close();
        this.alertService.success(data.message, this.options);
      }, (error: HttpErrorResponse) => {
        if (error.status == 454) {
          //this.alertService.error(error.error.status, this.options);
        }
        else if (error.status == 403) { }
      })
    }


  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./scm-tool.component.scss']
})
export class scmCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<scmCancelConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Delete confirmation pop up
@Component({
  selector: 'delete-confirmation-popup-dialog',
  templateUrl: 'delete-confirmation-popup.html',
  styleUrls: ['./scm-tool.component.scss']
})
export class scmdeleteConfirmationPopUpDialog {
  req_id;
  req: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<scmdeleteConfirmationPopUpDialog
    >, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
    this.req = data.req
  }
  popUpClose(option) {
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./scm-tool.component.scss']
})
export class scmWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<scmWorkbenchConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  workbenchPopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./scm-tool.component.scss']
})
export class scmHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<scmHomeConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  homePopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//add memebr to existing repo

const ELEMENT_DATA: MemberDetails[] = [];
@Component({
  selector: 'add-member-repo-dialog',
  templateUrl: 'add-member-repo.html',
  styleUrls: ['./scm-tool.component.scss']
})

export class addMemberRepoDialog {
  projectId: any;
  projectName: any;
  members: any = [];
  // ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  memberList: any = [];
  newMembersAdd: any = [];
  finalArray: any = [];


  @ViewChild(MatPaginator) paginator: MatPaginator;


  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dummyMemberArray: any = [];
  tempBody: any;
  rowValue: any;
  catalog_id: any;
  catalog_name: any;
  memberString: any[];
  type: any;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, public router: Router, public dialogRef: MatDialogRef<SCMMembers>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.memberList = data.values.members
    this.type = data.type

    this.rowValue = data.values;
  }

  ngOnInit(): void {
    this.projectId = localStorage.getItem('projectId');

    this.getUser();
  }
  allSelected() {
    this.isallSelected = true
  }

  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;

      this.members.forEach((element, index) => {
        for (var i = 0; i < this.memberList.length; i++) {
          if (this.memberList[i].user_email == element.user_email) {
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
          }
        }

      });

      this.members.forEach((object: any) => {
        if (!object.duplicate) {
          this.finalArray.push(object)
        }
      })


      if (this.type === "add") {
        this.memberList.forEach(member => {
          // debugger
          for (let i = 0; i < this.finalArray.length; i++) {
            if (member.name == this.finalArray[i].user_name) {
              this.finalArray.splice(i, 1);
            }
            else {

            }
          }
        });
      }
      let members = []
      if (this.type === "delete") {
        debugger

        this.memberList.forEach(user => {
          if (user.status != "Pending") {
            let object = {
              "user_name": user.name,
              "user_email": user.email,
            }
            members.push(object)
          }
        })
        this.finalArray = members
      }


      this.dataSource.data = this.finalArray as MemberDetails[]
    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  addDeleteMemberToRepo() {

    if (this.type === "add") {
      let memberArray = [];
      this.selection.selected.forEach(user => {
        let userNameEmail = user.user_email
        let userEmailDomainName = userNameEmail.substring(0, userNameEmail.lastIndexOf("@"));
        let userNameWithoutNumber = userEmailDomainName.replace(/[0-9]/g, '');

        let object = {
          "username": userNameWithoutNumber.toLowerCase(),
          "user_name": user.user_name,
          "email": user.user_email,
          "mail": 0,
          "role": "TM",
          "status": "Pending"
        }
        memberArray.push(object)
      })

      let userPsNumber = "";
      let userName = localStorage.getItem('profile');
      this.apiService.psNumber.subscribe((psNumber: any) => {
        userPsNumber = psNumber
      })
      this.catalog_id = localStorage.getItem('catalogId');
      this.catalog_name = localStorage.getItem('catalogName');


      let scmBody =
      {
        "request_type": "Gitlab",
        "request_status": "Pending",
        "requestor_id": userPsNumber,
        "requestor_name": userName,
        "request_id": this.rowValue.reqId,
        "project_id": this.projectId,
        "name": this.rowValue.repoName,
        "members": memberArray
      }

      this.apiService.addMemberinExisingProject(scmBody).subscribe((roleResult: any) => {
        // if (roleResult.status == "Request submitted") {
        this.alertService.success(roleResult.message, this.options);

        // }

      }, (error: HttpErrorResponse) => {
        if (error.status == 435) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Error While Adding Member", this.options);
          throw error;
        }

      });
      this.dialogRef.close()
    }
    else {
      let memberArray = [];
      this.selection.selected.forEach(user => {
        memberArray.push(user.user_email)
      })
      let body = {
        "project_id": this.projectId,
        "request_id": this.rowValue.reqId,
        "members": memberArray,
        "requestor_name": localStorage.getItem('profile')
      }
      this.apiService.deleteMemberGitlab(body).subscribe((data: any) => {
        this.dialogRef.close();
        this.alertService.success(data.message, this.options);
      }, (error: HttpErrorResponse) => {
        if (error.status == 454) {
          //this.alertService.error(error.error.status, this.options);
        }
        else if (error.status == 403) { }
      })
      this.dialogRef.close()
    }

  }


  exit() {
    this.dialogRef.close();
  }
}
