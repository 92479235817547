import { Router, RouterModule, ActivatedRoute } from "@angular/router";
import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "./service/api.service";
import {
  MsalGuardConfiguration,
  MsalBroadcastService,
  MsalService,
} from "@azure/msal-angular";
import { Logger, CryptoUtils } from "msal";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectSummaryComponent } from "src/app/project-details/project-summary/project-summary.component";
import { CastDialogComponent } from "src/app/project-configurations/work-bench/work-bench.component";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/_alert";
import {
  AuthenticationResult,
  EventType,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { catchError, filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { NgModule } from "@angular/core";

const GRAPH_ENDPOINT = environment.GRAPH_ENDPOINT;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  isIframe = false;
  idArray = [];
  verify = false;

  isAdmin: string;
  isAdminFlag: boolean = false;
  isUserFlag: boolean = false;
  loggedIn = false;
  success: boolean = false;
  userMailId: string;
  adminRole: string;
  urlsearch: string;
  urlcode: string;
  bearerToken: string;
  accessCodeVar: any;

  options = {
    autoClose: true,
    keepAfterRouteChange: true,
  };
  private readonly _destroying$ = new Subject<void>();
  isIE: boolean;
  id: any;
  id3: any;
  id5: any;
  nameid5: any;
  tokenVal: string;
  urlDomain: string;

  constructor(
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public alertService: AlertService,
    private apiService: AuthService,
    public authService: MsalService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      // debugger
      // console.log(params,"ggggggggggggggggggggggggggggggggggggg");
      this.id5 = params.id;
      this.nameid5 = params.projectid;

      // var updatedID = ["red","blue","green"];
      this.idArray.push(this.id5);
      this.idArray.push(this.nameid5);

      // this.idArray.push("hello")

      localStorage.setItem("updatedID", JSON.stringify(this.idArray)); //store colors
      // var storedColors = JSON.parse(localStorage.getItem("my_colors")); //
      // console.log();

      // console.log(localStorage,"bbbbbbbbbbbbbbbbbbbbbb");

      // console.log(this.id5,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
      // console.log(localStorage,"aaaaaaaaaaaaaaaaaaaaaaaaaaaa");
    });
  }

  ngOnInit() {
    
    // debugger

    // if(this.loggedIn){
    //   console.log("hii logg naman");

    // }
    // localStorage.clear();

    const onboard = localStorage.getItem("onboardID");
    // console.log(onboard);

    if (onboard == "undefined" || onboard == null) {
      this.activatedRoute.queryParams.subscribe((params) => {
        // console.log(params,"ppppppppppppppppppppp");
        this.id = params.id;

        localStorage.setItem("onboardID", this.id);
      });
    } else if (onboard != "undefined" || onboard != null) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.id3 = params.id;
        // console.log(params.id);

        // localStorage.setItem("updatedonboardID", this.id3);
      });
    }

    // debugger
    // this.userMailId = profile['mail'];

    // console.log(this.userMailId);

    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();
    // if(this.loggedIn){
    //   // console.log("hii logg naman");

    // }
    // this.broadcastService.subscribe('msal:loginSuccess', () => {
    //   this.checkAccount();
    // });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAccount();
        this.checkAndSetActiveAccount();
      });

    // this.authService.instance.addEventCallback((event) => {
    //   // set active account after redirect
    //   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    //     const account = event.payload.account;
    //     this.authService.instance.setActiveAccount(account);
    //   }
    //   else{
    //     console.log("Rrr");

    //   }
    // })

    this.authService.instance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        // const account = this.authService.instance.getActiveAccount();
        // if(!account){
        //   // redirect anonymous user to login page
        //   this.authService.loginRedirect();
        // }
      })
      .catch((err) => {
        // TODO: Handle errors
        //console.log(err);
      });
    // this.authService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     // console.error('Redirect Error: ', authError.errorMessage);
    //     return;
    //   }

    // });

    // this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    // }, {
    //   correlationId: CryptoUtils.createNewGuid(),
    //   piiLoggingEnabled: false
    // }));
    // debugger
    this.urlDomain=window.location.hash
    var url = window.location.href;
    this.getlocationhrefCode(url).then((res) => { });
    //  console.log(localStorage,"local logged in");

    //  const gigID=localStorage.getItem("onboardID");
    //  if(this.loggedIn==true && gigID!=null){
    //    this.loggedIn=false
    //   //  this.ngOnInit()
    //  }
    if (this.loggedIn) {
      this.getEmailID();
    }

    //       var url = window.location.href;
    //  this.getlocationhrefCode(url).then(res=>{
    //   localStorage.setItem("accessCode", res);
    //  });

    //  const webExFirst=localStorage.getItem("webExFirst");
    //  if(webExFirst == 'Yes')
    //  {
    //    localStorage.setItem("webExFirst", "No");
    //    this.router.navigate(['/webEx']);
    //  }
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  async getlocationhrefCode(url) {
    if (url.search("code") != -1) {
      this.urlsearch = this.parseQueryStr(url.substring(-1));
      localStorage.setItem("accessCode", this.urlsearch);
      return this.urlsearch;
    }
  }
  parseQueryStr(queryString) {
    var params = {},
      keyvals,
      temp,
      i,
      l;
    keyvals = queryString.split("&");
    for (i = 0, l = keyvals.length; i < l; i++) {
      var tmp = keyvals[0].split("=");
      this.urlcode = tmp[1];
    }
    return this.urlcode;
  }
  profileImage: any;
  async getEmailID() {
    // debugger
    localStorage.setItem("isPopOpen", "false");
    this.http
      .get(GRAPH_ENDPOINT + "/me")
      .toPromise()
      .then(async (profile) => {
        localStorage.setItem("profile", profile["displayName"]);
        localStorage.setItem("designation", profile["jobTitle"]);
        // await this.getAccessToken();
        // console.log(localStorage.getItem("msal.idtoken"));

        this.apiService.userStatus.subscribe((status) => {
          if (status == "invalid user") {
            this.userMailId = profile["mail"];
            let user_name = profile["displayName"];
            let body = {
              user_mail: this.userMailId.toLowerCase(),
              user_name: user_name,
              user_id: "",
            };
            const onboard = localStorage.getItem("onboardID");
            if (
              onboard == "undefined" ||
              onboard == undefined ||
              onboard == null
            ) {
              this.apiService.registerNewUser(body).subscribe(
                (result) => {
                  window.location.reload();
                },
                (error: HttpErrorResponse) => {
                  if (error.status == 434) {
                    this.alertService.error(error.error.message, this.options);
                  } else {
                    this.alertService.error(
                      "User could not be registered.",
                      this.options
                    );
                    throw error;
                  }
                }
              );
            }
          }
        });

        this.userMailId = profile["mail"];
        localStorage.setItem("mail", profile["mail"]);

        //Set JWT tOken on login start
        if (localStorage.getItem("token") == null) {
          let accessBody = {
            user_mail: this.userMailId,
          };
          // window.location.reload()

          // debugger
     
          
          
          // console.log(localStorage,"AD connector");
          const adConnnectorFlag = localStorage.getItem("onboardID");
          if (adConnnectorFlag != "undefined" || this.urlDomain=='#/VdiRequestForm') {
            // debugger
            await this.apiService.generateTokengig(accessBody).subscribe(
              (data: any) => {
                // debugger
                localStorage.setItem("token", data.token);
                localStorage.setItem("isPopOpen", "false");
                // console.log("tokennOne");

                // window.location.reload();

                // this.router.navigate(["/admin-gig"])
              },
              (error: HttpErrorResponse) => {
                if (error.status == 432) {
                  this.alertService.error(error.error.message, this.options);
                } else {
                  this.alertService.error(
                    "Error generating token.",
                    this.options
                  );
                  throw error;
                }
              }
            );
          } else if (adConnnectorFlag == "undefined" || this.urlDomain!='#/VdiRequestForm') {
            // debugger
            await this.apiService.generateToken(accessBody).subscribe(
              (data: any) => {
                // debugger
                localStorage.setItem("token", data.token);
                localStorage.setItem("isPopOpen", "false");
                // console.log("tokenSecond");

                window.location.reload();

                // this.router.navigate(["/admin-gig"])
              },
              (error: HttpErrorResponse) => {
                // debugger
                if (error.status == 403) {
                  // let element: HTMLElement = document.getElementById('CallModelUpdateRole') as HTMLElement;
                  // element.click();
                  const scmUser = this.dialog.open(ifsrPopup, {
                    width: '40%',
                    height: '27%',
                    disableClose: true,
                    // data:{
                    //   member: this.targets
                    // }
                  });
                  scmUser.afterClosed().subscribe(result => {      
                    // console.log(result);
                    
                    setTimeout(() => {
                      this.Unauthorised();
                    }, 1000);
                   
                });
                  // this.logout();
                  return;
                }
                if (error.status == 432) {
                  this.alertService.error(error.error.message, this.options);
                } else {
                  this.alertService.error(
                    "Error generating token.",
                    this.options
                  );
                  throw error;
                }
              }
            );
          }
        } else {
          localStorage.setItem("isPopOpen", "false");
        }
        //Set JWT tOken on login end
        await this.getAdminRole();
        // this.router.navigate(["/admin-gig"])
      });
  }

  async getProfile() {
    this.http
      .get(GRAPH_ENDPOINT + "/me/photo/$value")
      .toPromise()
      .then((profile) => {
        this.profileImage = profile;
        this.userMailId = profile["mail"];
      });
  }

  async getAdminRole() {
    // debugger

    // this.router.navigate(["/admin-gig"])

    // ######### code Start for checking Admin or Normal user ##########
    const onboard = localStorage.getItem("onboardID");
    if(this.urlDomain=='#/VdiRequestForm'){
      this.isUserFlag = true;

      this.isAdminFlag = false;
      this.router.navigate(["/VdiRequestForm"]);

    }
    else if (onboard != "undefined") {
      // debugger
      this.isUserFlag = false;

      this.isAdminFlag = true;
      this.router.navigate(["/admin-gig"]);
      // console.log("hello gig");
      
      // console.log('/admin-gig?id='+onboard);

      // this.router.navigate(['/admin-gig?id='+onboard])
      // '/admin-gig?id=' + onboard
    } else {
      if (
        this.userMailId != "" &&
        this.userMailId != undefined &&
        this.userMailId != null
      ) {
        // debugger

        this.apiService.getProjectByEmailId(this.userMailId).subscribe(
          (data: any) => {
            // debugger
            this.adminRole = data.userInfo.userType;
            // this.isAdmin ="Admin";
            this.isAdmin = this.adminRole;

            if (this.adminRole == "IFSR") {
              // let element: HTMLElement = document.getElementById('CallModelUpdateRole') as HTMLElement;
              // element.click();
              const scmUser = this.dialog.open(ifsrPopup, {
                width: '40%',
                height: '27%',
                disableClose: true,
                // data:{
                //   member: this.targets
                // }
              });
              scmUser.afterClosed().subscribe(result => {      
                // console.log(result);
                
                setTimeout(() => {
                  this.Unauthorised();
                }, 1000);
               
            });


              return;

              // this.logout();
              // return;
            }


            localStorage.setItem("canvasAdminRole", data.userInfo.canvasAdmin);
            if (this.isAdmin == "Admin") {
              this.isUserFlag = false;
              this.isAdminFlag = true;
              localStorage.setItem("Role", this.isAdmin);
              let webExFirst = localStorage.getItem("webexFirst");
              let accessCodeVar = localStorage.getItem("accessCode");
              let FirstLogin = localStorage.getItem("FirstLogin");
              if (webExFirst == "yes") {
                this.router.navigate(["/webex-login"]);
              } else {
                if (FirstLogin == "Yes") {
                  //  debugger
                  this.router.navigate(["/admin-catalog-config"]);

                  localStorage.setItem("FirstLogin", "NO");
                }
              }
            } else {
              this.isUserFlag = true;
              this.isAdminFlag = false;
              localStorage.setItem("Role", this.isAdmin);
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status == 432) {
              this.alertService.error(error.error.message, this.options);
            } else {
              // debugger
              // this.adminRole = "Admin"
              // // this.isAdmin ="Admin";
              // this.isAdmin = "Admin"
              // localStorage.setItem("canvasAdminRole", 'true')
              // // console.log(data);
              // console.log(this.userMailId);
              // console.log(this.adminRole);
              // this.alertService.error("Error generating token.", this.options);
              // throw error;
            }
          }
        );
      }
    }

    // ######### code End for checking Admin or Normal user ##########
  }

  checkAccount() {
    // this.loggedIn =  !!this.authService.getAccount();
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }
  Unauthorised() {
    this.logout();

    return;
  }
  logout() {
    //code start - after the logout to clear all local storage

    this.tokenVal = localStorage.getItem("token");
    if (this.tokenVal != null) {
      let Body = {
        token: this.tokenVal,
      };

      // console.log(Body);

      this.apiService.jwtTokenLogout(Body).subscribe(
        (res: HttpResponse<any>) => {
          // debugger;

          // console.log("fhfh");

          localStorage.clear();

          sessionStorage.clear();

          this.authService.logout();

          this.apiService.projectKey.next("");

          localStorage.removeItem("profile");

          // console.log(res.body.message);
        },
        (error: HttpErrorResponse) => {
          // debugger;
        }
      );
    } else {
      localStorage.clear();

      sessionStorage.clear();

      this.authService.logout();

      this.apiService.projectKey.next("");

      localStorage.removeItem("profile");
    }
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: "22%",
      height: "13%",
      disableClose: true,
    });
  }

  async login() {
    // debugger
    this.isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;
    // console.log(this.isIE);
    // debugger
    if (this.isIE) {
      // debugger
      // console.log(this.isIE);

      await this.authService.instance.loginRedirect();
    } else {
      // debugger
      // console.log(this.isIE);

      await this.authService.instance.loginPopup().then(
        (response: AuthenticationResult) => {
          // debugger
          this.authService.instance.setActiveAccount(response.account);
          // debugger
          localStorage.setItem("ssoToken",response.accessToken)

          // console.log(response);
          // debugger;
          this.getEmailID().then(() => {
            window.location.reload();
            localStorage.setItem("FirstLogin", "Yes");
            //window.location.replace("http://localhost:4200/")
            // window.location.replace("https://lti-canvas-dev-ui.azurewebsites.net/")

            //window.location.replace(environment.uiUrl)
          });
        },
        (catchError) => { }
      );
    }
    await this.getEmailID();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
@Component({
  selector: 'ifsr-popup-dialog',
  templateUrl: 'ifsr-popup.html',
  styleUrls: ["./app.component.scss"],
}) 
export class ifsrPopup {
  // ELEMENT_DATA: softwareDialog[];
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<ifsrPopup>,@Inject(MAT_DIALOG_DATA) public data: any) { 
  
    
  }
  exit() {
    this.dialogRef.close();
  }
}
