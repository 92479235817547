<h2 mat-dialog-title>Add Tool Group</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-12">

        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="form-group">

            <label>Tool Group Name</label>
            <input type="text" autocomplete="off" name="toolName" class="form-control form-control-lg" [(ngModel)]="model.toolName"
              #toolName="ngModel" maxlength="20" [ngClass]="{ 'is-invalid': f.submitted && toolName.invalid }" required>
            <div *ngIf="f.submitted && toolName.invalid" class="invalid-feedback">
              <div *ngIf="toolName.errors.required">Tool Name is required</div>
            </div>
          </div>

          <div class="form-group">
            <label>Tool Description</label> 
            <input type="text" autocomplete="off" name="toolDescription" class="form-control form-control-lg"
              [(ngModel)]="model.toolDescription" #toolDescription="ngModel" maxlength="100"
              [ngClass]="{ 'is-invalid': f.submitted && toolDescription.invalid }" required>
            <div *ngIf="f.submitted && toolDescription.invalid" class="invalid-feedback">
              <div *ngIf="toolDescription.errors.required">Tool description is required</div>
            </div>
          </div>

          <div class="form-group">
            <button (click)="ToolGrSaveData()" class="btn btn-primary btn-lg"
              color="primary">Save</button> 
             &nbsp; &nbsp;
 
             <button color="primary"
               type="reset" mat-dialog-close class="btn btn-primary btn-lg">
               Close
             </button>
           </div>
        </form>

      </div>
  </div>
</mat-dialog-content>
