import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/api.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';

export interface toolGr {
  in_toolName: string;
  in_ID: string;
  in_toolDescription: string;
  in_Cost: string;
  in_DB_toolGroup_ID: string;
}

@Component({
  selector: 'app-tool-config',
  templateUrl: './tool-config.component.html',
  styleUrls: ['./tool-config.component.scss']
})

export class ToolConfigComponent implements OnInit {

  toolId: any;
  toolName: any="";
  tool_DB_Id: any="";
  toolDescription: any="";
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { }

  ngOnInit(): void {
    this.formMode = localStorage.getItem('formMode')

    if (this.formMode == "Edit") {
      //  var getObj = JSON.parse(localStorage.getItem('toolGrObj'));
      //       console.log(getObj);
      //this.setToolGroupEditing(getObj);
    }

  }

  setToolGroupEditing(toolObj) {
    //
    var toolNm: string;
    var toolDes: string;
    var toolId: string;
    var tool_DB_Id: string;

    //
    toolNm = toolObj.Name;
    // this.model.toolName = toolNm.trim();
    this.model.toolName = toolNm;


    toolDes = toolObj.Description;
    // this.model.toolDescription = toolDes.trim();
    this.model.toolDescription = toolDes;


    toolId = toolObj.ID;
    // this.toolId = toolId.trim();
    this.toolId = toolId;


    tool_DB_Id = toolObj.DB_toolGroup_ID
    // this.tool_DB_Id = tool_DB_Id.trim();
    this.tool_DB_Id = tool_DB_Id;



  }

  selectedTool(e) {

  }

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }


  CheckToolNameDuplication() {
    //debugger
    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == ""
      || this.model.toolDescription == " " || this.model.toolDescription == "") {

      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      // this.project_nameInstance = this.storyBoard.nameInstance;

      var toolGroupName = this.model.toolName;
     // debugger
      this.apiService.Admin_CheckToolGroupNameValidation(toolGroupName).subscribe((res: HttpResponse<any>) => {
        //debugger
        // console.log("Inside Tool Group Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            case "01":
              this.alertService.error(res.body.msg, this.options);
              break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.ToolGrSaveData();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {

        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  ToolGrSaveData() {

    //debugger

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == ""
      || this.model.toolDescription == " " || this.model.toolDescription == "") {
      // this.openSnackBarDiaLog()
      //this.apiService.castToolDialog.next(" Please enter details In all the fields ")
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;


      // ##### Code for Save Funtionality ########
      saveBody = {
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //  console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        //
        //console.log(res.status);
        if (res.status == 200) //doesnt work
        {
          //  this.openSnackBarDiaLog()
          //this.apiService.castToolDialog.next(" Data Saved Successfully")  
          this.alertService.success("Data Saved Successfully", this.options);
          //this.clearAllSCMDetails();
          this.router.navigate(['/admin-tool-group']);

        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 436||error.status == 430) //doesnt work
        {
          //  this.openSnackBarDiaLog()
          // this.apiService.castToolDialog.next(" Please Enter Valid Inputs ")    
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
          // this.openSnackBarDiaLog()
          // this.apiService.castToolDialog.next(" Invalid Input ")
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

}
