<div class="pop-up">
  <div class="row">
    <div class="col-md-3">
      <span  class="vertical-line mx-3 mb-1"> </span>
     <span class="header mb-0">Add Member</span>
    </div>
    <div class="col-md-5">
      <mat-form-field fxFlex="60%">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
    </mat-form-field>
    </div>
    <div class="offset-md-2 col-md-2">
       <span (click)="exit()" class="material-icons close-icon">
        close
      </span>
    </div>
</div>
  <hr class="margin-class" />
  <form (ngSubmit)="addUserForRepo()">
  <div class="popup-container">
    <div class="example-container1">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
        </ng-container>
  
        <ng-container matColumnDef="user_email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
        </ng-container>

        <ng-container matColumnDef="user_access">
          <th mat-header-cell *matHeaderCellDef> Access </th>
          <td mat-cell *matCellDef="let element"> 
            <mat-select name="user_access" [(ngModel)]="element.user_access">
              <mat-option *ngFor="let acc of accessVal" [value]="acc">{{acc | titlecase}}</mat-option>
            </mat-select>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (click)="allSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]=true [aria-label]="checkboxLabel(row)"> 
            </mat-checkbox>
  
            <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
        </tr>
      </table>
  
  
    </div>
  </div>
   
    <div class="row justify-content-center">
      <div class="col-md-8">
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
        </mat-paginator>
      </div>
      <div class="col-auto col-md-3 mt-3">
        <button mat-button mat-raised-button class="add-button">+ Add Member</button>
      </div>
    </div>
  </form>
  </div>
