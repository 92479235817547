<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="dialogue">
   
    <div class="inner-top-container pop-up">
      <div class="row">
          <div class="col-md-8 pl-0">
            <span class="vertical-line mx-1 mx-md-3"> </span>
            <span class="header mb-0"> Add Member</span>
          </div>
          <div class="col-md-3"></div>
          <div (click)="onNoClick()" class="material-icons col-md-1 close-icon" >
            close
          </div>
        
      </div>
      <hr>
        <!-- <h3>Add Member</h3> -->
        
            <form [formGroup]="secondFormGroup">
              <div class="row">
        <div >
            <mat-form-field appearance="fill" class="example-full-width col-md-10">
            <mat-label>Enter Member Email</mat-label>
            <input matInput #input placeholder="" name="memberEmail" formControlName="memberEmail">
            </mat-form-field>
            <div class="text-danger font-small pl-3" *ngIf="userNotFound">{{errMessage}}</div>
            <div class="text-danger font-small pl-3" *ngIf="userExists && !memberEmail.hasError('pattern') && !userNotFound">Member already added</div>
            <div class="text-danger font-small pl-3" *ngIf="memberEmail.hasError('pattern') && !userNotFound">Please enter valid email address</div>
        </div>
        <div >
            <button mat-button mat-raised-button color="primary" [disabled]="(memberEmail.value == '' || memberEmail.value == ' ' || memberEmail.value == null) || memberEmail.invalid" (click)="searchEmployee()"
            class="add-button col-md-2">Add
            </button>
        </div>
      </div> 
    </form>
        
        
        <div *ngIf="showMemberTable">
            <table class="table" style="height:150px;">
                <thead>
                  <tr>
                  <th>PS Number</th>
                  <th>Name</th>
                  <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let val of customMemberArr">
                    <td>{{val.user_id}}</td>
                      <td>{{val.user_name}}</td>
                      <td>{{val.user_email}}</td>                   
                  </tr>
                </tbody>
              </table>

              <div class="offset-md-5">
              <button mat-button mat-raised-button color="primary" (click)="addMember()"
            class="add-button">Register Member
            </button>
        </div>


          </div>
    </div>
</div>