import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './service/api.service';


@Injectable({
  providedIn: 'root'
}) 
export class AuthGuard implements CanActivate {
 
  constructor(private _AuthServices:AuthService,
    private _router:Router){
  }

  canActivate():boolean{
    if (this._AuthServices.loggedIn()){
      return true
    }else{
      this._router.navigate(['/login'])
      return false
    }
  }
}
