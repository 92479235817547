<form (ngSubmit) = "AddVdiImage()">
<div class="member-card pop-up">
  <div class="row">
    <div class="col-sm-5 pt-3">
      <span  class="vertical-line mx-3"> </span>
     <span class="header mb-0">Select VDI Image</span> 
    </div>
    <div class="col-sm-5">
      <div fxLayout fxLayoutAlign="center center">
        <mat-form-field fxFlex="60%">
          <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm-2 pt-3">
      <span class="material-icons close-icon" mat-dialog-close>
        close
      </span>
    </div>
   
  </div>
  <hr class="margin-class" />
 <!-- <div class="row ">
    <div class="offset-md-11 col-md-1">
      <span class="material-icons" mat-dialog-close title="close" style="float: right;cursor: pointer;">
        close
      </span>
    </div>

  </div>  -->
  
  <div class="example-container vdi-table">
    
    <div>
      <div style="text-align:center;color: red;padding-top: 5px;" *ngIf="SelectType">Kindly select VDI type to add VDI image's</div>
      <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>

        <!-- Resource  Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} 
            <span  class="material-icons add-user ng-star-inserted"  style="font-size: 18px;cursor:pointer;padding-left: 4px; position: relative; top:4px;"
             title="System Information" (click)="showImageInfo(element)">info</span>
          </td>
        </ng-container>

        <!-- Resource Type Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
          <td mat-cell *matCellDef="let element"> {{element.location}} </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
          <td mat-cell *matCellDef="let element"> {{element.type}} </td>
        </ng-container>

 <!-- Description Column -->
 <ng-container matColumnDef="endpointsecurity">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Endpoint Security </th>
  <td mat-cell *matCellDef="let element"> {{element.endpointsecurity}} </td>
</ng-container>

<!-- Description Column -->
<ng-container matColumnDef="image">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> image </th>
  <td mat-cell *matCellDef="let element"> {{element.image}} </td>
</ng-container>
        <!-- ACtion Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
          <td mat-cell *matCellDef="let element">
            <button class="" mat-raised-button color="warning" 
              style="background-color: #1460a3;">{{element.action}}</button> </td>
        </ng-container>

        <ng-container matColumnDef="vdiType">
          <th mat-header-cell *matHeaderCellDef>VDI Type </th>
         <!--   <td mat-cell *matCellDef="let element"> {{element.vdiType}} </td> -->
         <td mat-cell *matCellDef="let element">
          <!-- <mat-label>Select</mat-label>
          <mat-select [(ngModel)]="element.vdiType">
            <mat-option  *ngFor="let vdi of VId_TypeList"  [value]="vdi.value">{{vdi.viewValue}}</mat-option>
          </mat-select> -->

        <select  name="vdiType" [(ngModel)]="element.vdiType">
            <option value="Select" disabled selected hidden>Select</option>
            <option *ngFor="let vdi of VId_TypeList"  [value]="vdi.value">
              {{vdi.viewValue}}
            </option>
         </select> 
        </td>
        </ng-container>
        
       <!-- Checkbox Column -->
       <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select">
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
        [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}" (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false">
      </tr>

        
      </table>
  </div>

  </div>
  <div class="row">
    <div class="col-sm-8">
      <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
      </mat-paginator>
    </div>
    <div class="col-sm-4">
      <div class="float-right pt-3">
        <button type="button" class="btn-canvas-blue mr-2" (click)="AddVdiImage()">+ Add VDI Image</button>
        <button type="button" class="btn-canvas-blue" mat-dialog-close>Close</button>
    </div>
    </div>
  </div>
 

</div>
</form>