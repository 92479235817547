export const environment = {
    
  apiUrl:"https://lti-canvas-engineering-api-dev.azurewebsites.net",
  uiUrl:"https://lti-canvas-engineering-ui-dev.azurewebsites.net/",

  // logoPath:"/assets/img/logo/Logo.svg",
  // footerLogoPath:"/assets/img/logo/Lti.png",
  logoPath:"/assets/img/logo/Canvas-Workplace_without-BG.png",
  footerLogoPath:"/assets/img/logo/LTF.PNG",
  scmEnv: "DEV",
  clientId:"db069fea-7791-486e-9fb1-e7e82082be03",
  //webex_clientId:"C3a5e2df642e8e6cc8bbbadaea0d17bd308675c3053f6feba472a6890ced8d2d9",
  webex_clientId:"Cb4c5df0f1eba219acc612c2076e435ae1713289ad49ecd84988d9987893b7fba",///CanvasSuper clientID
  //webex_client_secret:"4759b64613acb8fb06be40100fe09f77e66454df739b4260b6eb9d8e5abf6eb4",
   webex_redirect_uri:"https://lti-canvas-engineering-ui-dev.azurewebsites.net/",
   gig:false,
   resillenceUrl: "https://canvasinternal.lntinfotech.com/apps/resilience/dev/CanvasResilience/#/login?token=",
  //  resillenceUrl:   "https://localhost:4200/#/login?token=",
  // resillenceUrl: "https://canvaslive.lntinfotech.com/canvasresilience/#/login?token=",
  TimeZone : "Asia/Kolkata",
  production: false,
  msTeamsScreen:true,
  costUsage:true,

  GRAPH_ENDPOINT : 'https://graph.microsoft.com/v1.0',
  webex_url :"https://webexapis.com/v1/access_token"
    
   
  };
