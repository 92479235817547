import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/service/api.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { Subject, throwError } from 'rxjs';

import { AlertService } from 'src/app/_alert';
import { NewEvent } from '../mschannel-creations/create-meeting';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ThemePalette } from '@angular/material/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface ResourceTable {
  name: string;
  location: string;
  type: string;
  action: string;
  tags: string;
  id: string;
  vdiType: string;
  image: string;
  endpointsecurity: string;
}
export interface TaskVdi {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasksVdi?: TaskVdi[];
}
@Component({
  selector: 'app-vdi-request-form',
  templateUrl: './vdi-request-form.component.html',
  styleUrls: ['./vdi-request-form.component.scss']
})
export class VdiRequestFormComponent implements OnInit,CanComponentLeave {
  
  
  vdiForm: any = {};
  secondFormGroup: FormGroup;

  NameDisable = false;
  //comment
  //comment
  chkHideVDI = false;
  chkHideAzureVDI = false;
  chkAWSWorkspace = false;
  chkHidePersonal = false;
  chkHidePooled = false;
  model = new NewEvent();
  minimumEndDate: string;
  myDate: any;
  masterSelected: boolean = false;
  masterSelectedAWS: boolean = false;
  projectOwnerEmailModel='';
  projectOwnerModel=''
  domainNameModel=''
  buModel=''
  maxVdiCountModel=0
  otherSoftwareModel=''

  VMunits: any= Array(1000).fill(0)
  AWSunits: any = {
  };
  checklist: any = [];
  checklistAWS: any = [];
  options = {
    autoClose: false,
    keepAfterRouteChange: true
  };
  dtTrigger = new Subject();

  dtOptions: DataTables.Settings = {
    // "paging":   false,
    // "ordering": false,
    // "info":     false
  };
  allCompleteVdi: boolean = false;
  submission:boolean=false

  vdiImage: any[];

  taskVdi: TaskVdi = {
    name: 'VDI',
    completed: false,
    color: 'accent',
    subtasksVdi: [
      { name: 'Personal', completed: false, color: 'accent' },
      { name: 'Pooled', completed: false, color: 'accent' },
      // {name: 'Warn', completed: false, color: 'accent'},
    ],
  };

  private authService: MsalService
  vdiMsg: string;
  msg: string;
  nextUrl: any;
  
  canLeave(url):boolean{
    // console.log(url);
     
    //  debugger
     this.nextUrl=url
     if(this.nextUrl=="/VdiRequestForm"){
       return true
     }
     else{

       if (this.nextUrl!='/VdiRequestForm'){
      //    let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      //  element.click();
      this.router.navigate(["/VdiRequestForm"])
         return false
       }
     }
     
  
     return true
   }
  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService, public datepipe: DatePipe,
    private _formBuilder: FormBuilder, public router: Router, public Mdialog: MatDialog,
    private _router: Router, private spinner: NgxSpinnerService) {
      // debugger
    this.masterSelected = false;
    this.masterSelectedAWS = false

  }

  ngOnInit(): void {
    // debugger
    
    this.secondFormGroup = this._formBuilder.group({
      projectOwner: ['', [Validators.required, Validators.pattern('^([a-zA-Z 0-9])+$'), Validators.maxLength(30)]],
      projectOwnerEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      domainName: ['', [Validators.required, Validators.pattern('^([a-zA-Z 0-9])+$'), Validators.maxLength(30)]],
      BU: ['', [Validators.required, Validators.pattern('^([a-zA-Z0-9])+$'), Validators.maxLength(30)]],
      otherSoftware: [''],
      maxVdiCount: [1, [Validators.pattern("^[0-9]*$")]]

    });
    this.myDate = new Date();
    this.myDate = this.datepipe.transform(this.myDate, 'yyyy-MM-ddThh:mm');

  }
  onSubmit() {

  }
  getProjectOwnerNameErrorMessage() {
    return this.secondFormGroup.controls.projectOwner.hasError('pattern') ?
      'Project Owner Name must include only alphabets ,numbers and _' : this.secondFormGroup.controls.projectOwner.hasError('maxlength') ?
        'Maximum length can be 30 characters' :
        'Project Owner Name is required'
  }
  getProjectOwnerEmailErrorMessage() {
    return this.secondFormGroup.controls.projectOwnerEmail.hasError('pattern') ?
      'Invalid email' : this.secondFormGroup.controls.projectOwnerEmail.hasError('maxlength') ?
        'Maximum length can be 30 characters' :
        'Project Owner Email is required'
  }
  getDomainNameErrorMessage() {
    return this.secondFormGroup.controls.domainName.hasError('pattern') ?
      'Domain Name must include only alphabets ,numbers and _' : this.secondFormGroup.controls.domainName.hasError('maxlength') ?
        'Maximum length can be 30 characters' :
        'Domain Name is required'
  }
  getBUErrorMessage() {
    return this.secondFormGroup.controls.BU.hasError('pattern') ?
      'DU/BU must include only alphabets ,numbers and _' : this.secondFormGroup.controls.BU.hasError('maxlength') ?
        'Maximum length can be 30 characters' :
        'DU/BU is required'
  }
  get projectOwner() {
    return this.secondFormGroup.get('projectOwner');
  }

  get projectOwnerEmail() {
    return this.secondFormGroup.get('projectOwnerEmail');
  }
  get domainName() {
    return this.secondFormGroup.get('domainName');
  }
  get BU() {
    return this.secondFormGroup.get('BU');
  }
  public seleccionRubrosHideVDI(event: MatCheckboxChange) {

    if (!event.source.checked) {
      this.chkHideVDI = false;
    }
    else {
      this.chkHideVDI = true;
    }
  }
  public seleccionRubrosHideAzure(event: MatCheckboxChange) {

    if (!event.source.checked) {
      this.chkHideAzureVDI = false;
    }
    else {
      this.chkHideAzureVDI = true;
    }
  }
  public seleccionRubrosHideWorkspace(event: MatCheckboxChange) {

    if (!event.source.checked) {
      this.chkAWSWorkspace = false;
      this.getAWSImages()
    }
    else {
      this.chkAWSWorkspace = true;
      this.getAWSImages()

    }
  }
  public seleccionRubrosHidePersonal(event: MatCheckboxChange) {

    if (!event.source.checked) {
      this.chkHidePersonal = false;
      this.getVdiImages()

    }
    else {
      this.chkHidePersonal = true;
      this.getVdiImages()

    }
  }
  public seleccionRubrosHidePooled(event: MatCheckboxChange) {

    if (!event.source.checked) {
      this.chkHidePooled = false;
      this.getVdiImages()

    }
    else {
      this.chkHidePooled = true;
      this.getVdiImages()

    }
  }
  getMinimumEndDate() {
    // debugger

    this.minimumEndDate = this.datepipe.transform(this.model.start, 'yyyy-MM-dd');
    // console.log(this.model.start);
    // console.log(this.model.end);


    // this.submitFlag=false
  }
  checkUncheckAllVM() {
    // debugger
    // console.log(this.masterSelected);

    for (let i = 0; i < this.checklist.length; i++) {
      this.checklist[i].checked = this.masterSelected
    }

  }
  isAllSelectedVM() {

    // console.log(this.checklist);
    if (this.checklist.length > 0) {
      var flag = this.checklist[0].checked

      for (let i = 0; i < this.checklist.length; i++) {
        flag = flag && this.checklist[i].checked
      }
      this.masterSelected = flag
    }

  }
  check(val){
    // debugger
    
    // console.log(typeof(x),val,x);
    // console.log(x===NaN);
    // console.log(val>=0);
    
    
    

    if(val>0){
      let x=Number(val)

      if(x<=0 || x>=1001){
        return true
      }
      return false

    }
    else{
      return true

    }

    
 
  }
  getVdiImages() {
    this.VMunits=Array(1000).fill(0)
    // debugger
    this.masterSelected = false
    var resourceType = ""
    if (this.taskVdi.subtasksVdi[0].completed && this.taskVdi.subtasksVdi[1].completed) {
      resourceType = "Pooled,Personal"
    }
    else if (this.taskVdi.subtasksVdi[0].completed && this.taskVdi.subtasksVdi[1].completed == false) {
      resourceType = "Personal"
    }
    else if (this.taskVdi.subtasksVdi[0].completed == false && this.taskVdi.subtasksVdi[1].completed) {
      resourceType = "Pooled"
    }

    this.apiService.GigVdiVmImages('VDI', resourceType).subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
      this.checklist = res


      // this.dataSource.data = this.SCMArray as SCMDetails[]
      // this.dtTrigger.next();
      // this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        // this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        // this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })



  }
  showImageInfo(catalog) {
    //debugger;
    if (catalog.tags == "") {

    }
    else {
      const dialogRef = this.Mdialog.open(AdminDialogImageVdiForm, {
        width: '50%',
        height: '58%',
        disableClose: true,
        data: {
          image: catalog.tags,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  //AWS
  checkUncheckAllAWS() {
    // debugger
    // console.log(this.masterSelected);

    for (let i = 0; i < this.checklistAWS.length; i++) {
      this.checklistAWS[i].checked = this.masterSelectedAWS
    }

  }
  isAllSelectedAWS() {

    // console.log(this.checklist);
    if (this.checklistAWS.length > 0) {
      var flag = this.checklistAWS[0].checked

      for (let i = 0; i < this.checklistAWS.length; i++) {
        flag = flag && this.checklistAWS[i].checked
      }
      this.masterSelectedAWS = flag
    }

  }
  getAWSImages() {
    this.masterSelectedAWS = false
    var resourceType = ""
    // if(this.chkHidePersonal && this.chkHidePooled){
    //   resourceType="Pooled,Personal"
    // }
    // else if(this.chkHidePersonal && !this.chkHidePooled){
    //   resourceType="Personal"
    // }
    // else if(!this.chkHidePersonal && this.chkHidePooled){
    //   resourceType="Pooled"
    // }

    this.apiService.GigVdiVmImages('AWS', 'Personal').subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
      this.checklistAWS = res


      // this.dataSource.data = this.SCMArray as SCMDetails[]
      // this.dtTrigger.next();
      // this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        // this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        // this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })



  }
  showImageInfoAWS(catalog) {

  }
  // showImageInfoAWS(catalog) {
  //   //debugger;
  //   if(catalog.tags==""){

  //   }
  //   else{
  //       const dialogRef = this.Mdialog.open(AdminDialogImageVdiForm, {
  //         width: '50%',
  //         height: '58%',
  //         disableClose: true,
  //         data: {
  //           image: catalog.tags,
  //         }
  //       });

  //       dialogRef.afterClosed().subscribe(result => {
  //       });
  //     }
  //   }

  //
  updateAllCompleteVdi() {
    // console.log("update");

    //  debugger
    this.allCompleteVdi = this.taskVdi.subtasksVdi != null && this.taskVdi.subtasksVdi.every(t => t.completed);
    if (this.taskVdi.subtasksVdi[0].completed == false) {
      this.chkHidePooled = false;
      this.chkHidePersonal = false;
    }
    this.getVdiImages()
    // console.log(this.taskVdi.subtasksVdi,"updateall");


  }

  someCompleteVdi(): boolean {
    // console.log("some");

    // debugger
    if (this.taskVdi.subtasksVdi == null) {
      return false;
    }
    else {
      // console.log(this.taskVdi.subtasksVdi,"some");
      if (this.taskVdi.subtasksVdi[0].completed == false) {
        this.chkHidePooled = false;
        this.chkHidePersonal = false;

      }
      // console.log(this.taskVdi.subtasksVdi[0].completed,"some");


      return this.taskVdi.subtasksVdi.filter(t => t.completed).length > 0 && !this.allCompleteVdi;




    }


    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAllVdi(completed: boolean) {
    // debugger
    // console.log("change");

    this.allCompleteVdi = completed;
    if (this.taskVdi.subtasksVdi == null) {
      return;
    }
    this.taskVdi.subtasksVdi.forEach(t => (t.completed = completed));
    if (this.taskVdi.subtasksVdi[0].completed == false) {
      this.chkHidePooled = false;
      this.chkHidePersonal = false;
    }
    this.getVdiImages()


  }
  isInteger(val){
    return Number.isInteger(val)
  }
  submit() {
    
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let t = mm + '-' + dd + '-' + yyyy;
    this.vdiImage = []
    for (let i = 0; i < this.checklist.length; i++) {

      if (this.checklist[i].checked) {
        let body = {
          "name": this.checklist[i].name,
          "type": this.checklist[i].image_type,
          "count": this.VMunits[i]
        }
        this.vdiImage.push(body)
      }
    }

    let body = {
      "pownername": this.secondFormGroup.value.projectOwner,
      "powneremail": this.secondFormGroup.value.projectOwnerEmail,
      "domainname": this.secondFormGroup.value.domainName,
      "dubu": this.secondFormGroup.value.BU,
      "othersoftware": this.secondFormGroup.value.otherSoftware,
      "maxvdicoun": this.secondFormGroup.value.maxVdiCount,
      "azureVdicheck": this.chkHideVDI,
      "awsdicheck": this.chkAWSWorkspace,
      "personalcheck": this.chkHidePersonal,
      "pooledcheck": this.chkHidePooled,
      "images": this.vdiImage,
      "startdate": this.model.start,
      "enddate": this.model.end

    }
    // console.log(body);

    let finalBody =
    {

      "service_id": "",
      "max_infra":this.secondFormGroup.value.maxVdiCount,

      "business_unit": this.secondFormGroup.value.BU,

      "project_owner_details": {

        "user_name": this.secondFormGroup.value.projectOwner,

        "user_mail": this.secondFormGroup.value.projectOwnerEmail

      },

      "domain_name": this.secondFormGroup.value.domainName,

      "gitlab": {

        "access": false

      },

      "jira": {

        "access": false

      },

      "jenkins": {

        "access": false

      },

      "ms_teams": {

        "access": false

      },

      "webex": {

        "access": false

      },

      "assign_vdi": {

        "azure": {

          "access": this.taskVdi.subtasksVdi[0].completed || this.taskVdi.subtasksVdi[1].completed,

          "azure_images": this.vdiImage,
        },

        "aws": {

          "access": false,

          "aws_images": []

        }

      },

      "deployment_store": {

        "azure": {

          "access": false,

          "azure_images": []

        },

        "aws": {

          "access": false,

          "aws_images": []

        }

      },

      "softwares": this.secondFormGroup.value.otherSoftware,

      "start_date": this.model.start,
      "end_date": this.model.end,
      "tools_request_date": t,
      
      "requester_details": {

        "requester_email": localStorage.getItem("mail"),

        "requester_name": localStorage.getItem("profile")
    }

    }
this.apiService.submitGig(finalBody).subscribe((res: HttpResponse<any>) => {
         
  this.submission=true
  setTimeout(() => {

    this.logout()

    }, 4000);
// if (res.status == 200) //doesnt work
// {        


//   this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
//     this.router.navigate(['/admin-projectTemplate']);
// });
this.alertService.success(res.body.message);

// }
}, (error: HttpErrorResponse) => {
  if (error.status == 436||error.status == 430) //doesnt work
  {
    this.alertService.error(error.error.message, this.options);
  }
  else if (error.status == 403){}
  else {
    this.alertService.error("Invalid Input", this.options);
    throw error;
  }
//

})

  }
  logout() {
 

    //code start - after the logout to clear all local storage
  
    var tokenVal = localStorage.getItem("token")
  
  
  
    let Body = {
  
      "token": tokenVal
  
    }
  
    // console.log(Body);
  
  
  
    this.apiService.jwtTokenLogout(Body).subscribe((res: HttpResponse<any>) => {
  
      // debugger;
      // console.log("fhfh");
      
      localStorage.clear();
  
      sessionStorage.clear();
  
      // this.authService.logout();
      this.apiService.projectKey.next("");
  
      localStorage.removeItem('profile')
      window.location.reload();
  

  
      // console.log(res.body.message);
  
  
  
    }, (error: HttpErrorResponse) => {
  
      // debugger;
  
    })
  
  
  }

reset(){
  // this.router.navigate(["/VdiRequestForm"]);
  // this.ngOnInit()
  // console.log(this.taskVdi);

  
  
  
  
  // // debugger
  // this.secondFormGroup.reset(),
  // console.log(this.secondFormGroup);
  // this.secondFormGroup.reset(this.secondFormGroup.value);
// 
this.secondFormGroup.patchValue({
  projectOwner: '',
  projectOwnerEmail: '',
  domainName: '',
  BU: '',
  otherSoftware: '',
  maxVdiCount: 1
});
// console.log(this.secondFormGroup);

  // this.secondFormGroup.controls.projectOwnerEmailModel.setValue('abc');

  // // debugger
  // this.taskVdi.subtasksVdi[0].completed=false

  // console.log(this.secondFormGroup);
  this.projectOwnerModel=""
  this.projectOwnerEmailModel=""
  this.projectOwnerModel=''
  this.domainNameModel=''
  this.buModel=''
  this.maxVdiCountModel=0
  this.otherSoftwareModel=''
  // this.secondFormGroup.value.projectOwner=""
  // this.secondFormGroup.value.projectOwnerEmail=""
  // this.secondFormGroup.value.otherSoftware=""
  // this.secondFormGroup.value.maxVdiCount=0
  // this.secondFormGroup.value.domainName=""
  // this.secondFormGroup.value.BU=""

  this.model.start=""
  this.model.end=undefined
  this.VMunits=Array(1000).fill(0)
  // // this.secondFormGroup);
  
  // // this.taskVdi.completed=false
  this.taskVdi.subtasksVdi[0].completed=false

  this.taskVdi.subtasksVdi[1].completed=false
  this.updateAllCompleteVdi()
  


}
showProjectInfo(val) {
  this.vdiMsg="VDI or Virtual Desktop infrastructure uses virtual machines to provide and manage virtual desktops"
  // this.vmMsg="VM or Virtual Machine where users can host applications by accessing the servers through any device or location"
  if(val=="VDI"){
    this.msg=this.vdiMsg
  }
  // else{
  //   this.msg=this.vmMsg
  // }
  const dialogRef = this.dialog.open(VdiInfo, {
    width: '40%',
    height: '45%',
    // overflow:any,
    data:{
      value:val,
      msg:this.msg
    },
    disableClose: true
    
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}

}
const ELEMENT_DATAA: ResourceTable[] = [];

@Component({
  selector: 'dialogVdi-Image',
  templateUrl: 'image-details-vdi.html',
  styleUrls: ['./vdi-request-form.component.scss']
})
export class AdminDialogImageVdiForm {
  image: any = [];
  //  ELEMENT_DATA: ResourceTable[];
  displayedColumns: string[] = ['name', 'tags'];
  Selecterror: boolean = false;
  dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATAA);
  tableResourceArray: any = [];
  Vdisoftware_details: any = [];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<AdminDialogImageVdiForm>, @Inject(MAT_DIALOG_DATA) public data: any, public apiService: AuthService,
  ) {
    //debugger;
    this.image = data.image
    // console.log("image software",this.image)
    let TempTags: any = [];
    TempTags.push(this.image);

    for (let index = 0; index < TempTags.length; index++) {
      TempTags.forEach(element => {
        const entries = Object.entries(element);
        //console.log(entries);
        for (let index = 0; index < entries.length; index++) {
          var softwareName = entries[index][0];
          var softwareVersion = entries[index][1];
          let VdiTagsBoby = {
            "software_name": softwareName,
            "software_version": softwareVersion
          }
          this.Vdisoftware_details.push(VdiTagsBoby);
        }
      });
    }

    if (this.Vdisoftware_details.length > 0) {
      this.Selecterror = false;
      this.Vdisoftware_details.forEach(member => {
        //debugger;
        let tableObj = {
          "name": member.software_name,
          "tags": member.software_version
        }
        this.tableResourceArray.push(tableObj)
      })
      //console.log(this.tableResourceArray,"tableResourceArray")
      this.dataSource.data = this.tableResourceArray as ResourceTable[]

    }
    else {
      this.Selecterror = true;
    }

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'info-vdi-dialog',
  templateUrl: 'info.html',
  styleUrls: ['./vdi-request-form.component.scss']
})
export class VdiInfo {

  value: any;
  message: any;
  constructor(
    public dialogRef: MatDialogRef<VdiInfo
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
    this.value=data.value
    this.message=data.msg
  }
  
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
