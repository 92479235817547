import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { AuthService } from 'src/app/service/api.service';
import {SelectionModel} from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import {MatTableDataSource} from '@angular/material/table';
import { MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { MembersListDialog } from '../scm-tool/scm-tool.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';


interface Template {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-ci-cd',
  templateUrl: './ci-cd.component.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class CiCdComponent implements OnInit {

  projecttype = [ 'MultiBranch','FreeStyle', 'Pipeline']
  projectId;
  projectName;
  projectData : any;
  members : any = [];
  username : any = [];
  Role:string="";
 toolName;

 options = {
  autoClose: true,
  keepAfterRouteChange: true
};

  ELEMENT_DATA: MemberDetails[];
 displayedColumns: string[] = ['select','user_name', 'user_email',];
 //dataSource = new MatTableDataSource<MemberDetails>(this.ELEMENT_DATA);

 selection = new SelectionModel<MemberDetails>(true, []);
 dtOptions: DataTables.Settings = {};
 dtTrigger = new Subject();
  showAddPipeline: boolean = true;
  showJenkinsForm: boolean = false;
  secondFormGroup: FormGroup;
  catalog_id: string;
  toolInstance: any;
  repoList: any[];
  selectedUserArray = [];
  showUserRow: boolean =false;
  tableArr: any = [];
  cicdTable: any = [];
  showCicdTable: boolean = false;
  filterStatus= 'Active';
  cicdFlag: boolean = false;
  refreshDisable: boolean;
  catalog_name: string;
  idproject: string;
  template_id: any;
  template_name: any;


 isAllSelected() {
   const numSelected = this.selection.selected.length;
   //const numRows = this.dataSource.data.length;
   //return numSelected === numRows;
   return numSelected
 }

 /** Selects all rows if they are not all selected; otherwise clear selection. */
 masterToggle() {
 //  this.isAllSelected() ?
   //    this.selection.clear() :
     //  this.dataSource.data.forEach(row => this.selection.select(row));
 }

 /** The label for the checkbox on the passed row */
 checkboxLabel(row?: MemberDetails): string {
   if (!row) {
     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
   }
   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
 }


  constructor(public alertService: AlertService, private _formBuilder: FormBuilder,private apiService : AuthService,
    private spinner: NgxSpinnerService, public router :Router,public dialog : MatDialog) { }

  ngOnInit(): void {
    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.alertService.yesClickedEvent
    .subscribe((data:string) => {
      this.showJenkinsForm = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.secondFormGroup.reset();
    });

    let newCatalog_id=localStorage.getItem('catalogId');
    this.catalog_name=localStorage.getItem('catalogName');
    this.idproject = localStorage.getItem('projectId');

    this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
      // debugger

      this.template_id = data[0].template_id;
      this.template_name = data[0].template_name;
      
    }) 
    // console.log(this.template_name);
    
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
   
      if (data.cicd_tools.length == 0) {
        this.cicdFlag = true;
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

    this.alertService.workbenchYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/workBench'])
    });

    this.alertService.homeYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/projectSummary'])
    });

    this.projectId=localStorage.getItem('projectId');

    this.projectName=localStorage.getItem('projectName');

    this.apiService.componentName.next("Jenkins")

    this.getProjects(this.filterStatus);

    this.secondFormGroup = this._formBuilder.group({
      // description: ['', [Validators.required,Validators.maxLength(100)]],
      description: ['', [Validators.required,Validators.maxLength(100),Validators.pattern('^[A-Za-z0-9? ()@&_-]+$')]],
//comment out code for deployment
      project_type: [null, Validators.required],
      name: [null, [Validators.required,Validators.pattern('^(?=.*?[A-Za-z0-9])[a-zA-Z0-9_-]+$'), Validators.maxLength(30)]], //^(?=.*[a-zA-Z])[a-zA-Z0-9]+$
      tool_instance: [null, Validators.required],
      repo: [null, Validators.required]
    });

    this.catalog_id=localStorage.getItem('catalogId');
    this.catalog_name=localStorage.getItem('catalogName');
    this.idproject = localStorage.getItem('projectId');

    this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
     // debugger
 
     this.template_id = data[0].template_id;
     this.template_name = data[0].template_name;
     
   })  
    
    this.toolInstance =[]
    this.apiService.getCatalogById(this.catalog_id,'CICD').subscribe((result: any) => {
      result.Tool_list.forEach(element => {
        let tool = {
          "tool_instance_name": element.tool_instance_details.cicd_tool_instance.tool_instance_name,
          "tool_id": element.tool_id
        }
        this.toolInstance.push(tool)
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available", this.options);
      throw error;
       }
      
       })

    this.repoList = [];
    this.apiService.refreshGitlab(this.projectId,'Active').subscribe((data:any)=>{
      
      data.forEach(project => {
        if(project.gitlab_repo_url){
          this.repoList.push(project.gitlab_repo_url)  
        }
      })
      if(this.repoList.length==0){
        this.repoList = ['NOSCM']
      }
    })
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }
  }

  get description() {
    return this.secondFormGroup.get('description');
  }
  get project_type() {
    return this.secondFormGroup.get('project_type');
  }
  get name() {
    return this.secondFormGroup.get('name');
  }
  get tool_instance() {
    return this.secondFormGroup.get('tool_instance');
  }
  get repo() {
    return this.secondFormGroup.get('repo');
  }

  getProjNameErrorMessage(){
    if(this.secondFormGroup.controls.name.hasError('maxlength')){
      return ('Maximum length can be 30 characters')
    }
    else if(this.secondFormGroup.controls.name.hasError('pattern')){
      return ('Name must include only alphabets ,numbers, _ and-')
    }
    
    else{
      return('Project Name is required')
    } 
  }

  getProjDescErrorMessage(){
    
    if(this.secondFormGroup.controls.description.hasError('maxlength')){
      return ('Maximum length can be 30 characters')
    }
    else if(this.secondFormGroup.controls.description.hasError('pattern')){
      return ('Description can only include alphabets, numbers and the following special characters _-@&()')
    }
    
    else{
      return('Description is required')
    } 
  }

  public doFilter = (value: string) => {
   // this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

 
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
 
  checkToolData() {
    let newCatalog_id=localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
   
      if (data.cicd_tools.length == 0) {
        this.cicdFlag = true;
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
      }
      else {
        this.getJenkinsInfo();
        this.addPipeline();
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

  }

  getJenkinsInfo() {
    this.catalog_id=localStorage.getItem('catalogId');
    
    this.toolInstance =[]
    this.apiService.getCatalogById(this.catalog_id,'CICD').subscribe((result: any) => {
      result.Tool_list.forEach(element => {
        let tool = {
          "tool_instance_name": element.tool_instance_details.cicd_tool_instance.tool_instance_name,
          "tool_id": element.tool_id
        }
        this.toolInstance.push(tool)
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available", this.options);
      throw error;
       }
      
       })
       this.repoList = [];
       this.apiService.refreshGitlab(this.projectId,'Active').subscribe((data:any)=>{
      
        data.forEach(project => {
          if(project.gitlab_repo_url&& project.gitlab_project_status === 'Completed'){
            this.repoList.push(project.gitlab_repo_url)  
          }
        })
        if(this.repoList.length==0){
          this.repoList = ['NOSCM']
        }
      })
  }
  
  getProjects(filter)
  {
    this.dtOptions = {
      order: []
    };
    // debugger
    this.spinner.show();
    this.cicdTable = []
    this.tableArr = []
    this.refreshDisable = false;
    setTimeout(() => {
      this.refreshDisable = true;
    }, 2000)
    this.apiService.refreshJenkins(this.projectId,filter).subscribe((data:any)=>{
      if(data.length==0){
        // console.log("hello");
        
        this.spinner.hide();
      }
    data.jenkins_job_details.jenkins_jobs.forEach(element => {
      let members = [];
      element.members.forEach(el => {
        let membObj = {
        name: el.name,
        email: el.email,
        user_access : el.access
      }
      members.push(membObj)
      });
      
      let tableObj =
      {
        "request_id" : element.request_id,
      "description": element.project_description,
      "project_type": element.project_type,
      "tool_instance": element.tool_instance,
        "repo": element.repo_url,
        "project_name" : element.jenkins_project_name,
        "status" : element.request_status,
        "Jenkin_url": element.jenkins_project_url,
        "members": members,
      }
      this.tableArr.push(tableObj)
      this.cicdTable = this.tableArr
      this.spinner.hide();
      if(this.cicdTable.length==0){
        this.showCicdTable = false;
      }
      else{
        this.showCicdTable = true;
      }
    }, (error: HttpErrorResponse) => {
        this.spinner.hide();
       if (error.status == 453){
        this.alertService.error(error.error.message, this.options);
       }

       else if (error.status == 403){}
          else {
          this.alertService.error("Error getting projects", this.options);
          throw error;
       }
      
       });
    
  })
  this.dtTrigger.next();
  this.spinner.hide();
    
    }

  
  
  openUser() {
      const vmUser = this.dialog.open(JenkinsMembers, {
        width: '50%',
        height: '60%',
        disableClose: true
      });
  
      vmUser.afterClosed().subscribe(result => {
        this.showUserRow = true;
        this.selectedUserArray = result
        this.apiService.selectedVDIMembers.next(this.selectedUserArray);
      })
    

    
  }
  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }
  deleteJenkins(element){
    // debugger
    let deleteBody = {
      "project_id": this.projectId,

      "request_id": element.request_id,
    
    }
    this.apiService.deleteJenkinsProj(deleteBody).subscribe((data:any)=>{
      this.alertService.success(data.status, this.options)
      this.getProjects(this.filterStatus)
    }, (error: HttpErrorResponse) => {
      if (error.status == 453){
      this.alertService.error(error.error.status, this.options);
      }
      else if (error.status == 403){}
      })
  }
  softDeleteJenkins(element){
    // debugger
    let reqName = localStorage.getItem('profile');


    let body=
    {
      "project_details": {
          "project_id": this.projectId,
          "project_name": this.projectName,
          "catalog_id": this.catalog_id,
          "catalog_name": this.catalog_name,
          "template_id": this.template_id,
          "template_name": this.template_name
      },
      "request_details": {
          "jenkins_job_details": [
              {
                  "request_id": element.request_id,
                  "request_status": element.status,
                  "requestor_name": reqName
              }
          ]
      }
  }
  this.apiService.softdeleteProject(body).subscribe((data: any)=>{
    // debugger
    
    this.getProjects(this.filterStatus);
    this.alertService.success(data.message, this.options);

  })
  // console.log(body);
  





  }
  deleteconfirmationPopUp(req_id) {
    // debugger

    const dialogRef = this.dialog.open(jenkinsadeleteConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      id:req_id,
      data:{
        req:req_id.request_id
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.softDeleteJenkins(req_id);
        }
      }
    );
  
  }
  getStatus(status){
    // console.log(status);
    this.spinner.show()
    this.filterStatus = status;
  this.getProjects(this.filterStatus)
  }
  
  addPipeline() {
    this.showJenkinsForm = true;
    this.showAddPipeline = false;
    this.selectedUserArray = [];
    this.apiService.selectedVDIMembers.next([]);
  }

   //Cancel click
   toggleDisplay(secondFormGroup) {
    if(secondFormGroup.pristine == false){
    this.cancelconfirmationPopUp();
    } else {
      this.showJenkinsForm = false;
      this.showUserRow = false
      this.selectedUserArray = [];
      
    }
 
  }

  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
          member: memberArray
        }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // confirmation popup
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(ciCdCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
     
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.scmworkbenchConfirmationPopup();
      } else {
        this.router.navigate(['/workBench'])
      }
  }
  goHome(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.homeConfirmationPopup();
      } else {
        this.router.navigate(['/projectSummary'])
      }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(ciCdWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe( data => {
      }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(ciCdHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {   
      }
    );
  }


  createJenkinsPipeLine(){
    // debugger
    if (this.tableArr.findIndex(x => x.project_name === this.name.value && x.status !="Removed") !== -1) {
      this.alertService.error("Project Name already exists!",this.options);
      // this.showRepo = true;
      this.showUserRow = true;
    }
    else{

    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })

    let members = [];
            this.selectedUserArray.forEach(el => {
              let membObj = {
                name: el.user_name,
                email: el.user_email,
                access : el.user_access
              }
              members.push(membObj)
            });
            let body= {​​​​​​​​
            "resource_type": "request",
            "request_type": "Jenkins",
            "request_status": "Pending",
            "requestor_id": userPsNumber,
        "requestor_name": userName,
        "project_details": {
          "project_id": this.projectId,
           "project_name": this.projectName,
           "catalog_id": "1111",
           "catalog_name": "Genius"
        },
            "request_details": {​​​​​​​​
            "devops_details": {​​​​​​​​
              "jenkins_project_name": this.name.value,
            "cicd_details": {​​​​​​​​
            "project_description": this.description.value,
            "repo_url": this.repo.value =='NOSCM' ? '' : this.repo.value,
            "project_type": this.project_type.value,
            "tool_instance": this.tool_instance.value,
            "members": members
                        }​​​​​​​​
                    }​​​​​​​​
                },
                "created_by":localStorage.getItem('mail').toLowerCase()​​​​​​​​
            }​​​​​​​​
            this.apiService.createJenkins(body).subscribe((data:any)=>{
              let tableObj =
                   {
                     "request_id" : data.request_id,
                    "description": this.description.value,
                    "project_type": this.project_type.value,
                    "tool_instance": this.tool_instance.value,
                    "project_name" : this.name.value,
                     "repo": this.repo.value,
                     "status" : "Pending",
                     "Jenkin_url": "",
                     "members": members
                   }
                   //this.tableArr.push(tableObj)
                  // this.cicdTable = this.tableArr
                  this.getProjects(this.filterStatus);
                   this.showCicdTable = true;
                   this.showUserRow =false;
                   this.showJenkinsForm = false;
                   this.dtTrigger.next();
                   this.alertService.success(data.message,this.options)
                  //  this.alertService.success('Jenkin Request Added Successfully !', this.options);
                   this.secondFormGroup.reset();
                  }, (error: HttpErrorResponse) => {
                    if (error.status == 435){
                    this.alertService.error(error.error.message, this.options);
                     }
                     else if (error.status == 403){}
                    else {
                    this.alertService.error("Error creating Jenkins request.", this.options);
                    throw error;
                     }
                    
                     }) 
                    }
  }
  downloadData(type){
    if(type=='pdf'){
      this.apiService.downloadJenkinsInPdf(this.projectId,this.filterStatus).subscribe(data=>{
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName+'-Jenkins.pdf'
        link.click();
      })
    }
    else if(type=='excel'){
      this.apiService.downloadJenkinsInExcel(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-Jenkins.xlsx';
        link.click();        
      })
    }
    else if(type=='csv'){
      this.apiService.downloadJenkinsInCsv(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-Jenkins.csv';
        link.click();        
      })
    }

  }
  
}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class ciCdCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<ciCdCancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class ciCdWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<ciCdWorkbenchConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  workbenchPopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
//Delete confirmation pop up
@Component({
  selector: 'delete-confirmation-popup-dialog',
  templateUrl: 'delete-confirmation-popup.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class jenkinsadeleteConfirmationPopUpDialog {
  req_id;
  req: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<jenkinsadeleteConfirmationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    this.req=data.req

  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class ciCdHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<ciCdHomeConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  homePopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
const ELEMENT_DATA: MemberDetails[]=[];
@Component({
  selector: 'jenkins-members',
  templateUrl: 'jenkins-members.html',
  styleUrls: ['./ci-cd.component.scss']
})
export class JenkinsMembers {
  projectId: any;
  projectName: any;
  members: any = [];
  newUser: any = [];
  removedUser: any = [];
  newArray: any = [];
  finalMemberArray: any = [];
  existingScmMembers: any;
  existingVdiMembers: any;
  existingRoleMembers : any;
  //ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email','user_access', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;
  accessVal =["Developer","Devops Engineer"]
  selected = 'Developer'
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingJiraMembers: any;
  secondFormGroup: FormGroup;
  VMModalType: any;
  AddMemberVMData: any;
  memberList: any =[];
  finalArray: any =[];
  AddfinalArray: any = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
   // return numSelected 
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
     this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, public router: Router, public dialogRef: MatDialogRef<JenkinsMembers>) { }

  ngOnInit(): void {
    this.projectId=localStorage.getItem('projectId');
    
    this.projectName=localStorage.getItem('projectName');

    this.apiService.componentName.subscribe(componentName => {
      this.componentName = componentName;
    })

    this.apiService.selectedVDIMembers.subscribe((members: any) => {
      this.existingVdiMembers = members
    })
    this.getUser();

    
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  
  get user_access() {
    return this.secondFormGroup.get('user_access');
  }
  allSelected() {
    this.isallSelected = true
  }
  addUserForRepo() {
    if (this.isallSelected) {
      this.dialogRef.close(this.selection.selected);
    } else {
        if (this.componentName == "Jenkins") {
          if (this.existingVdiMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingVdiMembers.forEach((member, indexi) => {
                for(var i=0; i< this.selection.selected.length;i++){
             if(member.user_email == this.selection.selected[i].user_email){
                  this.existingVdiMembers[indexi]["duplicate"]= true
                  this.selection.selected[i]["duplicate"]= true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingVdiMembers);
            this.AddfinalArray = []
            concatArray.forEach((object:any)=>{
              if(!object.duplicate){
                this.AddfinalArray.push(object)
              }
            })
            this.dialogRef.close(this.AddfinalArray);
            this.apiService.selectedVDIMembers.next([])
          }
        }
      
    }
  }



  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;

      this.members.forEach(member => {
        let obj = {
          "user_id": member.user_id,
          "user_name": member.user_name,
          "user_email": member.user_email,
          "role_name": member.role_name,
          "user_access" :  "Developer",
        }
        this.newArray.push(obj)
      })
      this.members = this.newArray;

      if (this.componentName == "Jenkins") {
        this.members.forEach((member, index) => {
          member['checked'] = false
          for (var i = 0; i < this.existingVdiMembers.length; i++) {
            if (member.user_id == this.existingVdiMembers[i].user_id) {
              this.members[index]['checked'] = true
              break;
            }
          }
        })
      }
      
      this.dataSource.data = this.members as MemberDetails[]
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }


  exit() {
   if (this.componentName == "Jenkins") {
      this.dialogRef.close(this.existingVdiMembers);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}
