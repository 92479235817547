import { OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert';  

@Component({
  selector: 'app-cast',
  templateUrl: './cast.component.html',
  styleUrls: ['./cast.component.scss']
})
export class CastComponent implements OnInit {

  projectId: any;
  projectName: any;
  projectData: any;
  cast_status: String = "Not Initiated";
  members: any = [];
  username: any = [];
 
  gitlab_project_id: any;
  gitMemberApprovedArr: any = [];
  gitlabMemberArray: any = [];
  toolName;
  userOnboarded: boolean = false;
  memberArray: any = [];
  newUser: any = [];
  removedUser: any = [];
  newArray: any = [];
  finalMemberArray: any = [];
  tableArray: any = [];
  userStatus = "";
  deletedStatus: string;
  newStatus: string;
  Role:string="";
  ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = [ 'user_name', 'user_email', 'role_name', 'user_project_status','select'];
  //dataSource = new MatTableDataSource<MemberDetails>(this.ELEMENT_DATA);

  selection = new SelectionModel<MemberDetails>(true, []);

  ELEMENT_DATA1: MemberActions[];
  displayedColumns1: string[] = ['user_name', 'user_project_status', 'user_status', 'action'];
  //dataSource1 = new MatTableDataSource<MemberActions>(this.ELEMENT_DATA1);

  gitlab_repo_url : any

  castStatus : boolean =  false
  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  catalog_id: string;
  catalog_name: string;
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   // const numRows = this.dataSource.data.length;
    //return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService, private _snackBar: MatSnackBar, public router: Router) { }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){    
    
    this.projectId=localStorage.getItem('projectId');
    
    this.projectName=localStorage.getItem('projectName');

    this.catalog_id=localStorage.getItem('catalogId');
   
    this.catalog_name=localStorage.getItem('catalogName');

    this.getProjects();

    //Get Tool Data
    this.apiService.toolSelectedFromDialog.subscribe(data => {
      this.toolName = data
    })

    this.apiService.castStatusExtra.subscribe(data=>{
      this.castStatus = data
    })
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public getProjects() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.apiService.projectName.next(project[0].project_name);
      this.members = project[0].members;
      this.newUser = project[0].team_members_delta.newMembers;
      this.removedUser = project[0].team_members_delta.removedMembers;
      this.gitlab_repo_url = project[0].gitlab_project_details.gitlab_repo_url


      if (this.newUser == undefined || this.newUser.length == 0) {
        this.members.forEach(member => {
          let obj = {
            "user_id": member.user_id,
            "user_name": member.user_name,
            "user_email": member.user_email,
            "role_name": member.role_name,
            "user_project_status": "existing"
          }
          this.newArray.push(obj)
        })
        this.members = this.newArray;
      } else {
        this.members.forEach((member, index) => {
          this.newStatus = "existing"

          for (var i = 0; i < this.newUser.length; i++) {
            if (member.user_id == this.newUser[i].EmployeeId) {
              this.newStatus = "new";
              break;
            }
          }
          this.members[index]['user_project_status'] = this.newStatus
        })
      }
      if (this.removedUser == undefined || this.removedUser.length == 0) {
        this.tableArray = this.members;
        this.finalMemberArray = this.members;
    //  this.dataSource.data = this.members as MemberDetails[]
      } else {
        this.members.forEach((array, index) => {
          this.newStatus = array.user_project_status
          for (var i = 0; i < this.removedUser.length; i++) {
            if (array.user_id == this.removedUser[i].EmployeeId) {
              this.newStatus = "deleted";
              break;
            }
          }
          this.members[index]['user_project_status'] = this.newStatus;
        })
    //  this.dataSource.data = this.members as MemberDetails[]
      }
      if (project[0].cast.status == undefined || project[0].cast.status == 'Pending') {
        this.cast_status = "Not Initiated";
      } else {

        this.cast_status = project[0].cast.status;
      }


      this.gitMemberApprovedArr = project[0].gitlab_project_details.gitlab_project_members;

      this.members.forEach((member,index) => {
        var userOnboarded = false;
        var access_to_project = "Not Initiated";
        for(var i = 0;i<this.gitMemberApprovedArr.length;i++) {
          if (this.gitMemberApprovedArr[i].user_name == member.user_name) {
            access_to_project= this.gitMemberApprovedArr[i].access_to_project
            if (this.gitMemberApprovedArr[i].access_to_project == 'Approved') {
              userOnboarded = true;
            } else if (this.gitMemberApprovedArr[i].access_to_project == 'Pending') {
              userOnboarded = false;
            } else if (this.gitMemberApprovedArr[i].access_to_project == 'Denied') {
              userOnboarded = false;
            }
          }
        }
        this.members[index]['action']= userOnboarded;
        this.members[index]['user_status']= access_to_project
      })
     // this.dataSource1.data = this.members as MemberActions[]
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

  createPipeline() {
    
    this.castStatus = true
    
          var memberString = "";
       
          for(var i = 0;i<this.selection.selected.length ; i++){
            memberString = memberString + (i+1)+ ". "+"Name: "+this.selection.selected[i].user_name + "\tEmailId: "+
            this.selection.selected[i].user_email +
                          "\tRole: "+ this.selection.selected[i].role_name+ "\n"
          }
        

var content =  "Hello CAST COE Team,\n\n CAST access is requested for\t"+
                this.projectName+"\tand\t"+ this.projectId+"\tfor team members.\n\n"+
                "Provided project details are –\n\n"+
                "Source code location –\t"+this.gitlab_repo_url+"\n\n"+
                "Credentials –To access source code folder, if any.\n\n"+
                "Team Member Details – \n"+memberString+"\n\n"+
                "Report Upload location – MS Team’s folder location where report will get uploaded.\n\n\n"+
                "Please provide us with below details –\n"+
                "Notification on MS Teams once the report is uploaded of provided folder\n"+
                "Details to access the Health & Engineering Dashboard.\n\n\n"+
                "Thanks and Regards\n"+
                "Team\t"+this.projectName

  
let castBody = {
  "message" : {
    "subject" :  "CAST Setup for project - "+this.projectName+" and id -"+ this.projectId,
    "body"  : {
      "contentType" :"Text",
      "content": content+"\n\nThis is auto generated email. Please do not reply.\n\n"
    },
    "toRecipients":[
      {
     
     }
    ],
    "ccRecipients": [
    {
  
    }
    ]
  },
  "saveToSentItems": "false"

}

this.apiService.sendCastEmail(castBody).subscribe(data=>{
  this.dtTrigger.next();
  
  this.router.navigate(['cast'])
  this.apiService.castStatusExtra.next(true)
}, (error: HttpErrorResponse) => {
  if (error.status == 438){
  this.alertService.error(error.error.message, this.options);
   }
  else {
  this.alertService.error("Unable to send mail.", this.options);
  throw error;
   }
  
   })
  


  }

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '22%',
      height: '13%',
      disableClose: true,

    });
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
}

export interface MemberActions {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  action: string;
}

