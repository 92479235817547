
<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <!-- <li class="breadcrumb-item active" aria-current="page">Reports</li> -->
            <li class="breadcrumb-item ">
              <!-- <h1 class="text-light mb-0"> -->
                <!-- <img width="20" src="assets/img/Group 3610.png"> -->
                <span class="page-title">Live Monitoring</span>  
              <!-- </h1> -->
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">

      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/projectSummary" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
    
    <!-- <div class="row">
      <div class="col-sm-3">
        <h1 class="text-light mb-0">
          <img width="40" src="assets/img/Group 3610.png">
          <div class="vertical-line mx-3"> </div> <span>Reports</span>  
        </h1>
      </div>

    </div> -->
    </div>
    <div  class="container-fluid contentent-area">
      <div class="reports-card reports-body">
          <div id="exTab2" class="container-fluid">	
          <ul class="nav nav-tabs">
             <li class="nav-item">
                <a class="nav-link active" id="profile-tab" (click)="getComputersData()" data-toggle="tab" href="#vdisecurity" role="tab"
                 aria-controls="profile" aria-selected="false">VDI Security</a>
              </li>
              <li class="nav-item">
                 <a class="nav-link" id="home-tab" (click)="getAlertReport()" data-toggle="tab" href="#security" role="tab" 
                 aria-controls="home" aria-selected="true">Infrastructure Security</a>
               </li>
              <!-- <li class="nav-item" *ngIf="showLogoByEnv">
                <a class="nav-link" id="profile-tab"  data-toggle="tab" href="#cost" role="tab"
                 aria-controls="profile" aria-selected="false">Cost</a>
              </li>
              <li class="nav-item" *ngIf="showLogoByEnv" >
                <a class="nav-link" id="profile-tab" (click)="summaryViewData()"  data-toggle="tab" href="#usage" role="tab"
                 aria-controls="profile" aria-selectsed="false">Usage</a>
              </li> -->
                <!-- <li  class="active">
                  <a  href="#security" (click)="getAlertReport()" data-toggle="tab">Infrastructure Security</a>
                </li>
                <li>
                  <a  href="#vdisecurity" (click)="getComputersData()" data-toggle="tab">VDI Security</a>
                </li> -->
                <!-- <li><a href="#cost" data-toggle="tab">Cost</a>
                </li>
                <li><a href="#usage" data-toggle="tab"  (click)="summaryViewData()">Usage</a>
                </li> -->
              </ul>
          
                <div class="tab-content ">
                  <div class="tab-pane active" id="vdisecurity">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#computer" role="tab" 
                        aria-controls="home" (click)="getComputersData()" aria-selected="true">Computers</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#auditlogvdi" role="tab"
                         aria-controls="profile" (click)="getVdiAuditLog()" aria-selected="false">Audit Log</a>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#policies" role="tab" 
                        aria-controls="contact" aria-selected="false">Policies</a>
                      </li> -->
                      <!-- <li  class="active">
                        <a  href="#computer"  (click)="getComputersData()" data-toggle="tab">Computers</a>
                      </li>
                      <li><a href="#auditlogvdi" (click)="getVdiAuditLog()" data-toggle="tab">Audit Log</a>
                      </li>
                      <li><a href="#policies">Policies</a>
                      </li> -->
                     
                    </ul>
                
                      <div class="tab-content">
                        <!-- VDI Securities Computers -->
                        <div class="tab-pane active" id="computer">
                          <div class="row justify-content-end mt-3">
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-select placeholder="OS" name="food" [(ngModel)]="osSelectedValue"
                                 (selectionChange)="getOsStatus($event.value)">
                                  <mat-option *ngFor="let os of operatingSys" [value]="os.value">
                                    {{os.viewValue}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                              
                            </div>
                           
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="pickercomp" [max]="computerEndDate.value"  [formControl]="computerStartDate">
                                <mat-datepicker-toggle matSuffix [for]="pickercomp"></mat-datepicker-toggle>
                                <mat-datepicker #pickercomp></mat-datepicker>
                              </mat-form-field>
                             
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>End Date</mat-label>
                                <input matInput [matDatepicker]="pickercomp2" [min]="computerStartDate.value"
                                 [formControl]="computerEndDate">
                                <mat-datepicker-toggle matSuffix [for]="pickercomp2"></mat-datepicker-toggle>
                                <mat-datepicker #pickercomp2></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="computersFilter()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Filter Data">search</mat-icon>
                              </button>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="resetComputersFilter()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Reset Data">sync</mat-icon>
                              </button>
                            </div>
                          </div>
                         <div>
                          <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="computersData">  <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th>Host Name</th>
                                  <th>Policy</th>
                                  <th>Operating System</th>
                                  <th>Date</th>
                                  <!-- <th>New time</th> -->
                                  <th class="text-center">More</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of computersData">
                                  <td>{{data.hostname}}</td>
                                  <td>{{data.policy.name || "-"}} 
                                    <span style="font-size: 18px;" class="material-icons add-user" 
                                    title="Policy Details" (click)="showPolicyInfo(data.policy.name)">info</span> 
                                  </td>
                                  <td>{{data.operating_system || "-"}}</td>
                                  <!-- <td>{{data.install_date || "-"}}</td> -->
                                  <td>{{data.install_date | date: 'mediumDate'}}</td>
                                  <td class="text-center">
                                    <a (click)="showComputersInfo(data)" class="action"> <i class="fas fa-ellipsis-h"></i></a>
                                  </td>
                                </tr> 
                              </tbody>
                            </table>
                          </div>
                         </div>
                        </div>
                        <!-- VDI Securities Audit log -->
                        <div class="tab-pane " id="auditlogvdi">
                          <div class="row justify-content-end mt-3">
                            <div class="col-12 col-md-2 mx-md-3">
                              <!-- <mat-form-field class="form-element">
                                <input matInput placeholder="User Name" [formControl]="auditUserName">
                              </mat-form-field> -->
                             
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="pickervdilog" [max]="vdilogEndDate.value"  [formControl]="vdilogStartDate">
                                <mat-datepicker-toggle matSuffix [for]="pickervdilog"></mat-datepicker-toggle>
                                <mat-datepicker #pickervdilog></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>End Date</mat-label>
                                <input matInput [matDatepicker]="pickervdilog2" [min]="vdilogStartDate.value" [formControl]="vdilogEndDate">
                                <mat-datepicker-toggle matSuffix [for]="pickervdilog2"></mat-datepicker-toggle>
                                <mat-datepicker #pickervdilog2></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="filterAuditLog()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Filter Data">search</mat-icon>
                              </button>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="resetFilterAuditLog()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Reset Data">sync</mat-icon>
                              </button>
                            </div>
                          </div>
                         <div>
                          <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="vdiAuditLog">   <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th>Event</th>
                                  <!-- <th>User</th> -->
                                  <th>Date</th>
                                  <th class="text-center">More</th>
                                  <!-- <th>Date</th> -->
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let data of vdiAuditLog">
                                  <td>{{data.event}}</td>
                                  <!-- <td>{{data.user || "-"}}</td> -->
                                  <!-- <td>{{data.date || "-"}}</td> -->
                                  <td>{{data.date | date: 'mediumDate'}}</td>
                                  <td class="text-center">
                                    <a (click)="showVdiAuditLogInfo(data, data.event)" class="action"> <i class="fas fa-ellipsis-h"></i></a>
                                  </td>
                                
                                </tr> 
                              </tbody>
                            </table>
                          </div>
                         </div>
                        </div>
                        <!-- VDI Securities Audit log -->
                        <div class="tab-pane " id="policies">
                          <div>
                            policies
                          </div>
                        </div>
                       
                      </div>
                  </div>
                  <div class="tab-pane" id="security">
                    <ul class="nav nav-tabs">
                      <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#alert" role="tab"
                         aria-controls="home" (click)="getAlertReport()" aria-selected="true">Alert</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#Observations" role="tab" 
                        aria-controls="profile" (click)="getObservationReport()" aria-selected="false">Observations</a>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#auditlog" role="tab" 
                        aria-controls="contact" aria-selected="false">Audit Log</a>
                      </li> -->
                      <!-- <li  class="active">
                        <a  href="#alert" (click)="getAlertReport()" data-toggle="tab">Alert</a>
                      </li>
                      <li><a href="#Observations" (click)="getObservationReport()"  data-toggle="tab">Observations</a>
                      </li>
                      <li><a href="#auditlog" data-toggle="tab">Audit Log</a>
                      </li> -->
                    </ul>
                      <div class="tab-content">
                        <div class="tab-pane active" id="alert">                         
                          <div class="row justify-content-end mt-3">
                            <div class="col-12 col-md-2 mx-md-3">
                          <mat-form-field>
                          <mat-label>Status</mat-label>
                          <mat-select (selectionChange)="getStatus($event.value)" [(ngModel)]="statusValue"> <!-- (change)="getStatus($event.target.value)"-->
                            <mat-option value="all">All</mat-option>
                            <mat-option value="open">Open</mat-option>
                            <mat-option value="closed">Closed</mat-option>
                          </mat-select>
                        </mat-form-field>
                        </div>
                          
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="picker1" [max]="endDate.value" [formControl]="startDate">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>End Date</mat-label>
                                <input matInput [matDatepicker]="picker2"
                                  [min]="startDate.value" [formControl]="endDate">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="alertFilter()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Filter data">search</mat-icon>
                              </button>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="resetAlertReport()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Reset data">sync</mat-icon>
                              </button>
                            </div>
                          </div>
                          
                          <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="alertData && alertData.length>0">  <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th> Alert Name </th>
                                  <th>  Source </th>
                                  <th>  Created Date </th>
                                  <th> Last Modified </th>
                                  <th> Status </th>
                                  <th> Priority</th>
                                </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let col of alertData">
                              <td class="url" (click)="showAlertInfo(col)">{{col.name}}</td>
                              <td>{{col.source}}</td>
                              <td>{{col.creation_time | date: 'mediumDate'}}</td>
                              <td>{{col.time}}</td>
                              <td> {{col.status | titlecase}}</td>
                              <td>
                                <span *ngIf="col.priority == 'High'">
                                  <img width="10" src="assets/img/Orange.png">
                                </span>
                                <span *ngIf="col.priority == 'Medium'">
                                <img width="10" src="assets/img/yellow.png">
                              </span>
                                <span *ngIf="col.priority == 'Low'">
                                <img width="10" src="assets/img/green.png">
                                </span>
                                {{col.priority}}
                                </td>
                              <!--td  style="width: 12.5%;">
                                <a (click)="openAlertDescription(col,'alert')" class="action"> <i class="fas fa-ellipsis-h"></i></a>
                              </td-->
                              </tr>
                              </tbody>
                            </table>
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="alertData && alertData.length==0">  <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th> Alert Name </th>
                                  <th>  Source </th>
                                  <th>  Created Date </th>
                                  <th> Last Modified </th>
                                  <th> Status </th>
                                  <th> Priority</th>
                                </tr>
                              </thead>
                          
                            </table>
                          </div>
                        </div>
                        <div class="tab-pane" id="Observations">
                          <div class="row justify-content-end mt-3">
                            <div class="col-12 col-md-2 mx-md-3">
                              
                              
                                <!-- <mat-form-field>
                                    <mat-select placeholder="Type" name="food" [(ngModel)]="osSelectedValue"
                                     (selectionChange)="getOsStatus($event.value)">
                                      <mat-option *ngFor="let col of observationData" [value]="col.observationType">
                                        {{col.observationType}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field> -->
                                <!-- <mat-form-field>
                                    <mat-label>Observation Type</mat-label>
                                    <mat-select (selectionChange)="getObsStatus($event.value)" [(ngModel)]="status"> 
                                          console.log(observationData)
              
                                      <mat-option *ngFor="let col of observationData" [value]="col.value">
                                        
                                          {{col.observationType}}
                                      </mat-option>
                                  
                                    </mat-select>
                                  </mat-form-field> -->

                                  <!-- <mat-form-field>
                                      <mat-select placeholder="Observation Type" name="food" [(ngModel)]="osSelectedValue"
                                       (selectionChange)="getOsStatus($event.value)">
                                        <mat-option *ngFor="let os of operatingSys" [value]="os.value">
                                          {{os.viewValue}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field> -->
<!--                             
                              <mat-form-field class="example-full-width">
                                <mat-label>Observation Type</mat-label>
                                <input matInput placeholder="Observation Type" [formControl]="observationType">
                              </mat-form-field> -->
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>Start Date</mat-label>
                                <input matInput [matDatepicker]="picker3"  [max]="endDate1.value" [formControl]="startDate1">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                              </mat-form-field>
                            </div>
                            <div class="col-12 col-md-2 mx-md-3">
                              <mat-form-field>
                                <mat-label>End Date</mat-label>
                                <input matInput [matDatepicker]="picker4"
                                  [min]="startDate1.value" [formControl]="endDate1">
                                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                <mat-datepicker #picker4></mat-datepicker>
                              </mat-form-field>
                            </div>
                           
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="filterObservation()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Filter data">search</mat-icon>
                              </button>
                            </div>
                            <div class="col-6 col-md-1 text-center">
                              <button type="button" class="btn users-button" (click)="resetFilterObservation()">
                                <mat-icon  class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                    title="Reset Status">sync</mat-icon>
                              </button>
                            </div>
                          </div>
                          <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="observationData && observationData.length>0">  <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th>Observation Type</th>
                                  <th> Resource Name </th>
                                  <th> Time </th>
                                  <th class="text-center"> More</th> 
                                </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let col of observationData">
                              <td>{{col.observationType}}</td>
                              <td>{{col.resource_name}} </td>
                              <td>{{col.time | date: 'mediumDate'}}</td>
                              <td class="text-center">
                                <a (click)="openObservationDescription(col,col.observationType)" class="action"> <i class="fas fa-ellipsis-h"></i></a>
                              </td>
                              </tr>
                              </tbody>
                            </table>
                            <table datatable [dtOptions]="dtOptions" class="table fix-table mt-4" *ngIf="observationData && observationData.length==0">  <!--fixed-table-->
                              <thead>
                                <tr>
                                  <th>Observation Type</th>
                                  <th> Resource Name </th>
                                  <th> Time </th>
                                  <th class="text-center"> More</th> 
                                </tr>
                              </thead>
                           
                            </table>
                          </div>
                        </div>
                        <div class="tab-pane " id="auditlog">
                          <div>Audit Log</div>
                        </div>
                      </div>
                   
                  </div>
                  
                  <div class="tab-pane" id="cost">
                    <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                        <div class="graph-layout"> 
                         
                       <div class="row">
                         <div class="col-sm-3">
                          <h3 class="heading">Project Tool's Cost          
                          </h3>
                         </div>
                         <!-- <div class="col-sm-3">
                          <select class="form-control input-sm" #monthSelect (change)=onToolSelected(monthSelect.value)>
                            <option *ngFor="let month of months">{{month}}</option>
                          </select>
                         </div> -->
                         <div class="col-sm-2">
                            <select [(ngModel)]="selectedOption2" name="gender2">
                                <!-- <option value="A">Select year</option><br> -->
                                   <option *ngFor="let option of selectOptions2" [value]="option">
                                     {{option}}
                                   </option>
                             </select>
                           </div>
                         <div class="col-sm-2">
                            <select [(ngModel)]="selectedOption" name="gender">
                                <!-- <option value="A">Select year</option><br> -->
                                   <option *ngFor="let option of selectOptions" [value]="option">
                                     {{option}}
                                   </option>
                             </select>
                           </div>
                           <div class="col-sm-3">
                              <!-- <button type="submit"  (click)="onToolSelected(selectedOption2,selectedOption)">Submit</button> -->
                              <span class="table-crud table-delete-icon" title="Filter data" (click)="onToolSelected(selectedOption2,selectedOption)">

                                  <img width="25" src="../../../assets/img/searchcost.png"></span>
                           </div>
                           <!-- <button type="button" class="btn btn-outline-primary" (click)="onToolSelected(selectedOption2,selectedOption)">Submit</button> -->

                         <div class="col-sm-2">
                          
                           <span class="float-right table-bar-icons" [ngClass] = "{'icon-bg': showToolBarChart}" (click)= showBarChart()>
                            <i class="far fa-chart-bar"></i>
                           </span>
                           <span class="float-right table-bar-icons" [ngClass] = "{'icon-bg': ShowToolTableView}" (click)= showTableView()>
                            <i class="fa fa-table" 
                            aria-hidden="true"></i> 
                          </span>
                            
                         </div>
                       </div> 
                      
                       <div *ngIf="showToolBarChart">
                        <!-- <div class="responsive">
                          <canvas baseChart height="420"
                          [colors]="toolBarChartColors"
                          [datasets]="toolBarChartData"
                          [labels]="toolBarChartLabels"
                          [options]="toolBarChartOptions"
                          [plugins]="toolBarChatPlugins"
                          [legend]="toolBarChartLegend"
                          [chartType]="toolBarChartType">
                          </canvas>
                        </div> -->
                        <div style="display: block;">
                            <canvas baseChart 
                              [data]="pieChartData2" 
                              [labels]="pieChartLabels2" 
                              [chartType]="pieChartType2"
                              [colors]="pieChartColor"

                              [options]="pieChartOptions2"
                              [plugins]="pieChartPlugins2"
                              [legend]="pieChartLegend2">
                            </canvas>
                          </div>
                       </div>
                       <div *ngIf="ShowToolTableView">
                         <div class="table-responsive">
                          <table datatable [dtOptions]="dtOptions" class="table fix-table">  <!--fixed-table-->
                            <thead>
                              <tr>
                                <th>Month</th>
                                <th>Virtual Machines</th>
                                <th>Disks</th>
                                <th>Network</th>
                                <th>Total</th>
                                <!-- <th>Bandwidth</th>
                                <th>CIS</th>
                                <th>Network</th>
                                <th>Storage</th>
                                <th>VM</th>
                                <th>VML</th>
                                <th>VN</th>
                                <th>Total</th> -->
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Test</td>
                                <td>{{vmCost}}</td>
                                <td>{{diskCost}}</td>
                                <td>{{networkCost}}</td>
                                <td><b>{{Total}}</b></td>

                              </tr>
                              <!-- <tr *ngFor="let tool of toolChartArray">
                                <td>{{tool.label}}</td>
                                <td>{{tool.vmCost}}</td>
                                <td>{{tool.diskCost}}</td>
                                <td>{{tool.networkCost}}</td> -->

                                <!-- <td>{{tool.bandwidth || "-"}}</td>
                                <td>{{tool.cis || "-"}}</td>
                                <td>{{tool.network || "-"}}</td>
                                <td>{{tool.storage || "-"}}</td>
                                <td>{{tool.vm || "-"}}</td>
                                <td>{{tool.vml || "-"}}</td>
                                <td>{{tool.vn || "-"}}</td> -->
                                <!-- <td>{{tool.totalCost || "-"}}</td> -->
                              <!-- </tr>  -->
                            </tbody>
                          </table>
                        </div>
                       </div>
                       
                      </div>
                        
                      </div>
                      <div class="col-sm-2"></div>
                    </div>
                   
                  </div>
                  <div class="tab-pane" id="usage">
                    <ul class="nav nav-tabs">
                      <li  class="nav-item">
                        <a  class="nav-link active" href="#countView" data-toggle="tab" aria-selected="true">Summary View</a>
                      </li>
                      <li><a  class="nav-link" href="#detailsView" data-toggle="tab" (click)="detailViewData()">Detail View</a>
                      </li>
                    </ul>
                
                      <div class="tab-content">
                        <div class="tab-pane active" id="countView">
                          <div class="row">
                            <div class="col-sm-12 summary-view">
                              <table style="width:100%" >
                                <tr>
                                  <th colspan="4">Canvas Provisioned Infra & Services</th>
                                </tr>
                                <tr>
                                  <th>Items</th>
                                  <th>Active</th>
                                  <th>Total</th>
                                  <th>Types</th>
                                </tr>
                                <tbody>
                                 
                                  
                                <tr>
                                  <td>Number of Active VDI</td>
                                  <td>7</td>
                                  <td>12</td>
                                  <td>Azure WVD</td>
                                </tr>
                                <tr>
                                  <td>Database Servers</td>
                                  <td>3</td>
                                  <td>3</td>
                                  <td>Azure VMS</td>
                                </tr>
                                <tr>
                                  <td>Application Servers</td>
                                  <td>2</td>
                                  <td>2</td>
                                  <td>AWS EC2</td>
                                </tr>
                                <tr>
                                  <td>Pipelines</td>
                                  <td>3</td>
                                  <td>5</td>
                                  <td>Jenkins</td>
                                </tr>
                                <tr>
                                  <td>SCM Repositories</td>
                                  <td>12</td>
                                  <td>12</td>
                                  <td>Gitlab</td>
                                </tr>
                                <tr>
                                  <td>Story Board Projects</td>
                                  <td>3</td>
                                  <td>3</td>
                                  <td>Jira</td>
                                </tr>
                                <tr>
                                  <td>Collaboration Channels</td>
                                  <td>7</td>
                                  <td>7</td>
                                  <td>WebEx Teams</td>
                                </tr>
                                <tr>
                                  <td>Users/Team Members</td>
                                  <td>16</td>
                                  <td>25</td>
                                  <td>NA</td>
                                </tr>
                              </tbody>
                              </table> 
                            </div>
                          </div>
                          </div>
                          <div class="tab-pane " id="detailsView">
                            <div class="details-view">
                            <h3><b>VDI/VM Details</b></h3>
                            <div class="table-responsive">
                              <table datatable [dtOptions]="dtOptions" class="table fix-table">   <!--fixed table-->
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Operating System</th>
                                    <th>Resource Group</th>
                                    <th>Virtual Network</th>
                                    <th>Subnet</th>
                                    <th>Private IP</th>
                                    <th class="text-center">More</th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of detailsData">
                                  <td >
                                    {{data.Name || "-"}}
                                    </td>
                                  <td>  {{data.OperatingSystem || "-"}}  </td>
                                  <td>  {{data.ResorceGroup || "-"}}  </td>
                                  <td>  {{data.VirtualNetwork || "-"}}  </td>
                                  <td>{{data.Subnet || "-"}}</td>
                                  <td>  {{data.PrivateIP || "-"}}  </td>
                                  <td class="text-center"><a (click)="showProjectInfo(data,'usage')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <br><br>
                            <h3><b>Gitlab Details</b></h3>
                            <div class="table-responsive">
                              <table datatable [dtOptions]="dtOptions" class="table fix-table">
                                <thead>
                                  <tr>
                                    <th>Group Name</th>
                                    <th>Repo ID</th>
                                    <th>Repo Name</th>
                                    <th>Tool Instance</th>
                                    <th>Status</th>
                                    <th class="text-center">More</th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of scmDetailsData">
                                  <td >
                                    {{data.gitlab_group_name || "-"}}
                                    </td>
                                  <td>  {{data.gitlab_repo_id || "-"}}  </td>
                                  <td>  {{data.gitlab_repo_name || "-"}}  </td>
                                  <td>  {{data.gitlab_tool_instance || "-"}}  </td>
                                  <td>{{data.status || "-"}}</td>
                                  <td class="text-center"><a (click)="showGitLabInfo(data,'usage')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <br><br>
                            <h3><b>Jira Details</b></h3>
                            <div class="table-responsive">
                              <table datatable [dtOptions]="dtOptions" class="table fix-table">
                                <thead>
                                  <tr>
                                    <th>Jira Project Name</th>
                                    <th>Jira Project Key</th>
                                    <th>Tool Instance</th>
                                    <th>Status</th>
                                    <th class="text-center">More</th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let data of jiraDetailsData">
                                  <td >
                                    {{data.jira_project_name || "-"}}
                                    </td>
                                  <td>  {{data.jira_project_key || "-"}}  </td>
                                  <td>  {{data.tool_instance || "-"}}  </td>
                                  <td>{{data.status || "-"}}</td>
                                
                                  <td class="text-center"><a (click)="showJiraInfo(data,'usage')" class="action"> <i class="fas fa-ellipsis-h"></i></a></td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          </div>
                      </div>
                     
                   
                  </div>
                </div>
            </div>
      </div>
    </div>
   <!--  -->
   
</div>

