
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>

  
  <div class="pop-up">
    <div class="">
        <span  class="vertical-line mx-0 mx-md-3"> </span>
         <span class="header mb-0">Confirm</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
    <div class="popup-container">
        <div class="" *ngIf="vdiType && vdiType!=='VM'">
            <h3 class="mb-2 col-md-12" *ngIf="this.vdiType == 'Pooled'">
                Please note you are assigning Session (shared) desktops to selected users .This  desktop  is shared across multiple users. and users will not be allowed to make system changes or install applications. Please click on the “i” icon to know the list of applications which are pre installed. User should save the documents/files in Personal One Drive. The necessary instruction on how to login & use shared VDI will be sent in notification to the selected users.
            </h3>
            <h3 class="mb-5 col-md-12" *ngIf="this.vdiType != 'Pooled'">
               {{showMsg}}
            </h3>
            <div class="col-md-9">
                <mat-checkbox (change)="changeCheck($event)">I acknowledge this.<!-- <a  data-toggle="modal" data-target="#myModal"><u>Terms & Conditions</u></a>--></mat-checkbox>
            </div>
            <div class="mt-3 offset-md-8">
                <div class="row">
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" [disabled]="disabledAgreement" (click)="selectedOption('Yes')" class="border_none vdi-button">Create</button>
                    </div>
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="" *ngIf="vdiType!=='VM' && !vdiType">
            <h3 class="mb-3 col-md-12">Do you want to continue?</h3>
            <div class="text-right">
                <div class="row" >
                    <div class="col-md-9 col-12 mt-3">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('Yes')" class="border_none vdi-button">Yes</button>
                    </div>
                    <div class="col-md-3 col-12 mt-3">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="" *ngIf="vdiType=='VM'">
            <!-- @author -priyanka kale - msg changed -->
            <h3 class="mb-3 col-md-12">For Windows, it takes around 20 min & for Linux, it takes 3-4 min to be created. Do you want to continue? </h3>
            <div class="text-right">
                <div class="row" >
                    <div class="col-md-9 col-12 mt-3">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('Yes')" class="border_none vdi-button">Yes</button>
                    </div>
                    <div class="col-md-3 col-12 mt-3">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog modal-lg">
<div class="modal-content">
    <div class="modal-header">
      <div class="row">
        <div class="col-sm-10">
            <h3 class="mb-0">Terms and conditions</h3>
        </div>
        <div class="col-sm-2 text-right">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
    </div>
    </div>
    <div class="modal-body">
      <h3>1. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
      <h3>2. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</h3>
      <h3>3. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</h3>
      <h3>4. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</h3>
      <h3>5. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College.</h3>
    </div>
  </div>
  
</div>
</div>