<!-- New code-->

<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->
<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
            <!-- <li class="breadcrumb-item"><a routerLink="/admin-scm-tool">SCM Tool</a></li>-->
            <li class="breadcrumb-item active" aria-current="page">GitLab</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right">
        <div class="table-options pt-3">
          <span (click)="addGitlab()"><i class="fas fa-plus"></i> Add</span>
          <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

          <span (click)="refreshGitlab()"><i class="fas fa-sync-alt"></i> Refresh</span>
          
          <a class="float-right step-back" (click)="backNavigation()">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>

        </div>
      </div>
    </div>
    <div>
      <h1 class="text-light mb-0"><img width="40" src="assets/img/gitlab-logo.png">
        <div class="vertical-line mx-3"> </div> <span>GitLab</span>
      </h1>
    </div>

  </div>

  <div class="inner-mid-container">
      <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
      <!-- Modal -->
      <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog"
        aria-labelledby="confirmationModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content pop-up">
            <div class="modal-header-popup">
              <span class="vertical-line mx-3"> </span>
              <span class="header mb-0">GitLab</span>
              <span data-dismiss="modal" class="material-icons close-icon">
                close
              </span>
            </div>
            <hr class="margin-class" />
            <div class="modal-body">
              Are you sure want to cancel?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
              <button type="button" [disabled]="CancelConnDisable" data-dismiss="modal" class="btn btn-canvas-blue-2"
                (click)="cancelOK()">Ok</button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
      <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
              <div class="modal-header-popup">
                <span  class="vertical-line mx-3"> </span>
                 <span class="header mb-0">GitLab</span> 
                <span  data-dismiss="modal" class="material-icons close-icon">
                    close
                  </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                Are you sure want to Delete?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
              </div>
            </div>
          </div>
        </div>
    <div *ngIf="showGitlabForm" class="git-Lab-Form">
      <h2 *ngIf="!editMode">New GitLab Instance</h2>
      <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
      <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
      
        <div class="form-row">
          
          <div class="form-group col-md-4">
            <label for="toolGr">Tool Group <span class="required">&#42;</span></label>
            <select id="toolGr" name="toolGr" class="form-control form-control-lg" required (change)="onChange($event)"
              [disabled]="ToolGroupDisable" [(ngModel)]="toolsGroup.toolGr" #toolGr="ngModel">
              <!-- <option selected>Choose Tool Group</option> -->
              <option value="" disabled selected hidden>Select Tool Group</option>
              <!-- <option [ngValue]="null" disabled selected hidden>Select Tool Group</option> -->
              <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}</option>
            </select>
            <div *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
              <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="toolGr">&nbsp;</label>
            <div>
              <a class="pt-24 link-col" (click)="openDialog()">
                <u>Add New Tool Group</u>
              </a>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="nameInstance"> Name <span class="required">&#42;</span></label>
            <input id="nameInstance" name="nameInstance" maxlength="20"  minlength="3"  pattern="^[a-zA-Z0-9_-]+$" class="form-control form-control-lg" required
              [(ngModel)]="toolsGroup.nameInstance" [disabled]="ToolInstanceNameDisable" #nameInstance="ngModel"
              (keydown.space)="$event.preventDefault();" onPaste="return false">
            <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
              class="alert alert-danger">
              <div *ngIf="nameInstance.errors.required">
                Name is required.
              </div>
              <div *ngIf="nameInstance.errors.minlength">
                Please enter minimum 3 characters for saving Tool Name
              </div>
              <div *ngIf="nameInstance.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>

            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="description">Description<span class="required">&#42;</span></label>
            <input id="description"  maxlength="50" minlength="20" pattern="^[a-zA-Z0-9_ -]+$"  name="description" class="form-control  form-control-lg" required
              [(ngModel)]="toolsGroup.description" #description="ngModel">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
              <div *ngIf="description.errors.required">
                Description is required.
              </div>
              <div *ngIf="description.errors.minlength">
                Please enter minimum 20 characters for saving Description 

            </div>
            <div *ngIf="description.invalid && !description.errors.minlength &&description.errors.pattern">
              Description must include only alphabets ,numbers or _,-.</div>

            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="proURL">GitLab URL <span class="required">&#42;</span></label>
            <input id="proURL" name="proURL" pattern="^[a-zA-Z0-9:.-/#]+$" class="form-control form-control-lg" required
              [(ngModel)]="toolsGroup.proURL" #proURL="ngModel" [disabled]="GitLabDisable">

            <div *ngIf="proURL.invalid && (proURL.dirty || proURL.touched)" class="alert alert-danger">
              <div *ngIf="proURL.errors.required">
                Project URL is required.
              </div>
              <div *ngIf="proURL.errors.pattern">
                  Invalid Project URL</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="proToken">Private Token <span class="required">&#42;</span></label>
            <input id="proToken" class="form-control form-control-lg" required name="proToken"  pattern="^[a-zA-Z0-9-]+$"
              [(ngModel)]="toolsGroup.proToken" #proToken="ngModel" [disabled]="PrivateDisable">


            <div *ngIf="proToken.invalid && (proToken.dirty || proToken.touched)" class="alert alert-danger">
              <div *ngIf="proToken.errors.required">
                Project Token is required.
              </div>
                <div *ngIf="proToken.errors.pattern">
                  Invalid Project Token</div>
            
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-md-6">
          
          </div> -->
          <div class="col-12 text-right">

            <button [disabled]="testConnDisable || !toolsGroupForm.form.valid" type="submit" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0">
              Test Connection
            </button>
            <button *ngIf="!editMode" [disabled]="saveConnDisable || !toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
              (click)="CheckgitLabDuplication()">
              Save
            </button>
            <button *ngIf="editMode" [disabled]="UpdateConnDisable || !toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
              (click)="updateGitLab()">
              Update
            </button>
            <!-- <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeGitlab()">
              Cancel
            </button> -->

              <button class="mr-2 btn-canvas-blue" [disabled]="CancelConnDisable" type="button"  data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
              <button class="mr-2 btn-canvas-blue" [disabled]="CancelConnDisable" type="button" *ngIf="toolsGroupForm.form.pristine" (click)="closeGitlab()">Cancel</button>
  

          </div>
     
        </div>





      </form>
    </div>
    <div class="my-5 table-container">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
              <th style="width: 15px;">
                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
              </th>
              <th>ID</th>
              <th>Connection Name</th>
              <!-- <th>Tool Type</th> -->
              <th>GitLab URL</th>
              <th>Tool Group</th>
            </tr>
          </thead>
          <tbody class="data-table-height">
            <tr *ngFor="let SCM of SCMArray">
                <td style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="SCM.isSelected" name="list_name" value="{{SCM.id}}" (change)="isAllSelected()"/>
                  </td>

              <td><span class="link-col" (click)="getRecord(SCM)">{{SCM.ID}}</span></td>
              <td>{{SCM.name}}</td>
              <!-- <td>{{SCM.toolName}}</td> -->
              <td>{{SCM.projectURL}}</td>
              <td>{{SCM.toolGroup}}</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>

<!--New code close-->