import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { error } from '@angular/compiler/src/util';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export interface scmTool {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  proBundles: string;
  toolGroup: string;
  toolGroup_id: string;
  proDirectories: string;
}

export interface SCMDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  proBundles: string;
  toolGroup: string;
  toolGroup_id: string;
  proDirectories: string;
}


@Component({
  selector: 'app-admin-aws-config',
  templateUrl: './admin-aws-config.component.html',
  styleUrls: ['./admin-aws-config.component.scss']
})
export class AdminAwsConfigComponent implements OnInit {

 
    AWSArray = [];
    scmObj: object[];
    
    toolDescription: any;
    model: any = {};
    
  
    testConnDisable = false;
    saveConnDisable = true;
    CancelConnDisable = false;
    UpdateConnDisable = true;
    
  
    ToolGroupDisable=false;
    ToolInstanceNameDisable =false;
  
  
    // Variable for Save/Edit body
    project_Token: string;
    project_URL: string;
    project_toolGrId: string;
    project_toolGrName: string;
    project_id_scmEdit: string;
    project_id_db: string;
    project_nameInstance: string;
    project_description: string;
    project_toolId:String;
  
    project_old_toolGr_ID: string;
    project_new_ToolGr_ID: string;
    showGitlabForm: boolean = false;
    editMode: boolean = false;
  
    token: any;
    
    toolName: any;
  
    toolsGroup: any = {};
  
    scmToolDetails: scmTool;
    selectedToolvalue: any;
  
    formMode: string = "";
  
    toolGr: any;
    proURL: any;
    proToken: any;
    db_id: any;
    nameInstance: any;
    description: any;
    dtOptions: DataTables.Settings = {};
    dtTrigger = new Subject();
  
  
    ToolGroupList = [];
    BundlesArr=[];
    DirectoriesArr=[];
    Role:string="";
    submitted = false;
    options = {
      autoClose: true,
      keepAfterRouteChange: true
    };
  
    constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
      private http: HttpClient, private router: Router,
      private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { }
  
    ELEMENT_DATA: SCMDetails[];
    displayedColumns: string[] = ['ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
    //dataSource = new MatTableDataSource<SCMDetails>(this.ELEMENT_DATA);
    selection = new SelectionModel<SCMDetails>(true, []);
  
    ngOnInit(): void {
      this.Role = localStorage.getItem("Role");
      if(this.Role=="Admin"){
      this.getTypeT();
      this.dtOptions = {
        order: []
      };
      var PopupToolGroup = localStorage.getItem('Popup_Open_Aws')
      if (PopupToolGroup == "true") {
        this.showGitlabForm = true;
        var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Aws')
        this.toolsGroup.toolGr = Popup_NewToolGroupID;
       // console.log("this.toolsGroup.toolGr");
        //console.log(this.toolsGroup.toolGr);
        var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Aws')
        this.project_toolGrName = Popup_NewToolGroupName;
        localStorage.setItem("Popup_Open_Aws", "false");
      } else {
        this.showGitlabForm = false;
      }
this.getBundles();
this.getDirectories()
      this.GetAwstableDetails();
    }
    else{
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/home']);
      });
      }
    }
  
    getBundles(): void {
     // debugger;
      let BundlesBody = {     
        "IncludeInactive":false
      }
  
        this.apiService.Admin_BundlesDrp(BundlesBody).subscribe((res: HttpResponse<any>) => {
       //   debugger;
         // console.log(res,"Bundles Body");
          if (res.status == 200) //doesnt work
          {
            this.BundlesArr=[];
           
            for (let i = 0; i < res.body.bundles.length; i++) {
              //
              if (res.body.bundles[i].bundleId != undefined) {
                // 
                this.BundlesArr.push(res.body.bundles[i].bundleId);
              }
            }
          }
          if (res.status == 500) //doesnt work
          {
            this.alertService.error("Please Enter Valid Inputs",this.options);
          }
        },(error:HttpErrorResponse) => {
          if (error.status == 500) //doesnt work
          {
            this.alertService.error("Please Enter Valid Inputs",this.options);  
          }
          else if (error.status == 403){}
          else
          {
          this.alertService.error("Invalid Input",this.options);  
          throw error;
          }
        })
  
  
    }

    getDirectories(): void {
      //debugger;
      let DirectoriesBody = {     
        "state":"ENABLED"
      }
  
        this.apiService.Admin_DirectoriesDrp(DirectoriesBody).subscribe((res: HttpResponse<any>) => {
        //  debugger;
         // console.log(res,"DirectoriesBody Body");
          if (res.status == 200) //doesnt work
          {
            this.DirectoriesArr=['d-96671ddfca'];
           
           // for (let i = 0; i < res.body.Directories.length; i++) {
              //
             // if (res.body.Directories[i].bundleId != undefined) {
                // 
               // this.DirectoriesArr.push(res.body.Directories[i].bundleId);
              //}
            //}
          }
          if (res.status == 500) //doesnt work
          {
            this.alertService.error("Please Enter Valid Inputs",this.options);
          }
        },(error:HttpErrorResponse) => {
          if (error.status == 500) //doesnt work
          {
            this.alertService.error("Please Enter Valid Inputs",this.options);  
          }
          else if (error.status == 403){}
          else
          {
          this.alertService.error("Invalid Input",this.options);  
          throw error;
          }
        })
  
  
    }


    refreshAws() {
      
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-aws-config']);
      });
  
    }
  
    addGitlab() {
      this.clearGitLabFormDetails();
      this.showGitlabForm = true;
      this.ToolGroupDisable=false;
      this.ToolInstanceNameDisable = false;
    }
    closeGitlab() {
  
      this.clearGitLabFormDetails();
      this.showGitlabForm = false;
      this.editMode = false;
      this.testConnDisable = false;
      this.saveConnDisable = true;
      this.UpdateConnDisable = true;
    }
  
    GetAwstableDetails() {
      this.spinner.show();
      this.apiService.Admin_AwsTableDetails().subscribe((res: any) => {
        // 
         //console.log(res,"OUTPut")
        res.forEach(member => {
          //debugger;  
          let obj = {
            
            "toolName": member.tool_name,
            "ID": member.tool_id,
            "db_id": member._id,
            "name": member.tool_instance_details.aws_workspace_tool_instance.tool_instance_name,
            "description": member.tool_instance_details.aws_workspace_tool_instance.description,
          
            "proBundles": member.tool_instance_details.aws_workspace_tool_instance.bundle_id,
          
            "proDirectories": member.tool_instance_details.aws_workspace_tool_instance.directory_id,
            "toolGroup": member.tool_group_details.tool_group_name,
            "toolGroup_id": member.tool_group_details.tool_group_id
          }
          this.AWSArray.push(obj)
  
        })
        
        this.dtTrigger.next();
        this.spinner.hide();
      }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
      // SCMArray
    }
  
    getTypeT(): void {
      //
      this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
        if (data) {
          // console.log("SCM");
          //console.log(data.body);
          var i;
          for (i = 0; i < data.body.length; i++) {
            //
            if (data.body[i].tool_group_name != undefined) {
              // 
              this.ToolGroupList.push(data.body[i]);
            }
          }
        }
      }
        , (error: HttpErrorResponse) => {
          // 
          if (error.status == 500) {
            this.alertService.error("Please Enter Valid Inputs", this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("Invalid Input", this.options);
            throw error;
          }
        })
    }
  
    //Assigning row data to form 
    setScmEditing(scmObj: scmTool) {
      //debugger;
  
       //console.log("scmObj",scmObj)
  //debugger;
      this.toolsGroup.toolGr = scmObj.toolGroup_id;
      this.getBundles();
      this.getDirectories();
      this.toolsGroup.proBundles = scmObj.proBundles;
      this.toolsGroup.proDirectories = scmObj.proDirectories;
      this.project_id_scmEdit = scmObj.ID;
      this.project_id_db = scmObj.db_id;
      this.toolsGroup.nameInstance = scmObj.name;
      this.toolsGroup.description = scmObj.description;
      this.project_old_toolGr_ID = scmObj.toolGroup_id
  
      this.project_nameInstance = scmObj.name;
      this.project_description = scmObj.description;
     
      this.project_toolGrId = scmObj.toolGroup_id;
      this.project_toolGrName = scmObj.toolGroup
      this.project_toolId = scmObj.ID
      
  
    }
  
    selectedTool(e) {
  
    }
  
    //Fetch table row data
    getRecord(row) {
      //debugger;
      this.scmObj = row;
      try {
        localStorage.setItem('scmToolObj', JSON.stringify(this.scmObj));
        localStorage.setItem("formMode", "Edit");
        this.formMode = localStorage.getItem('formMode')
        if (this.formMode == "Edit") {
    
          //### Disable the TextBox
          this.ToolGroupDisable=true
          this.ToolInstanceNameDisable = true;
    
          var getObj = JSON.parse(localStorage.getItem('scmToolObj'));
          this.setScmEditing(getObj);
          this.editMode = true;
        }
      }
      catch {
        return throwError('Data not found');
      }
     
      this.showGitlabForm = true;
      this.testConnDisable = false;
      this.UpdateConnDisable = true;
      // this.router.navigate(['/admin-gitlab']);
    }
  
  
    onSubmit() {
  
    }
  
  
  
    clearGitLabFormDetails() {
      this.toolsGroup.proBundles = "";
      this.toolsGroup.nameInstance = "";
      this.toolsGroup.description = "";
      this.toolsGroup.proDirectories = "";
      this.toolsGroup.toolGr = "";
    }
  
    onChange($event) {
      let scmToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
      this.project_toolGrName = scmToolGrName;
      //console.log("scmToolGrName", scmToolGrName);
    }
  
    CheckDuplication() {
      if (this.toolsGroup.proBundles == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
        || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
        || this.toolsGroup.toolGr == "" || this.toolsGroup.proBundles == " " ||this.toolsGroup.proBundles == "" || this.toolsGroup.proDirectories == " "
        || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
        || this.toolsGroup.proDirectories == undefined|| this.toolsGroup.proDirectories == "") {
        this.alertService.error("Please enter details In all the fields", this.options);
      }
      else {
  
  
        // this.project_nameInstance = this.storyBoard.nameInstance;
 // debugger;
          let validateBody = {
          "resource_type": "tool",
          "tool_category": "AWS",
          "tool_name": "AWS-Workspace",
          "tool_instance_details": {
            "aws_workspace_tool_instance": {
              "tool_instance_name": this.toolsGroup.nameInstance
            }
          }
        }
  
      //  debugger
  
        this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
        //  debugger
          //console.log("Inside Validate");
          //console.log(res.body);
          if (res.status == 200) {
  
            let code = res.body.code
  
            switch (code) {
              case "01":
                this.alertService.error(res.body.msg, this.options);
                break;
              case "02":
                this.alertService.error(res.body.msg, this.options);
                break;
  
              default:
                this.AwsSave();
                break;
            }
  
          }
  
  
        }, (error: HttpErrorResponse) => {
          if (error.status == 436) //doesnt work
          {
            this.alertService.error(error.error.message, this.options);
  
          }
          else if (error.status == 403){}
          if (error.status == 500) //doesnt work
          {
            this.alertService.error("already Exist", this.options);
  
          }
          else {
  
            this.alertService.error("Invalid Input", this.options);
  
            throw error;
          }
        })
  
      } //else
  
    }
  
  
    // Update row data
    updateGitLab() {
  
      //
      if (this.toolsGroup.proURL == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
        || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
        || this.toolsGroup.toolGr == "" || this.toolsGroup.proURL == " " || this.toolsGroup.proToken == " "
        || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
        || this.toolsGroup.proToken == undefined) {
        this.alertService.error("Please enter details In all the fields", this.options);
      }
      else {
        var updateBody: any;
        this.project_URL = this.toolsGroup.proURL;
        this.project_nameInstance = this.toolsGroup.nameInstance;
        this.project_description = this.toolsGroup.description;
        this.project_Token = this.toolsGroup.proToken;
        this.project_toolGrId = this.toolsGroup.toolGr;
        this.project_new_ToolGr_ID = this.toolsGroup.toolGr;
  
          // ##### Code for Update Funtionality ########
        updateBody = {
          "tool_id": this.project_toolId,
          "tool_category": "SCM",
          "tool_name": "Gitlab",
          "tool_group_details": {
              "tool_group_id": this.project_toolGrId,
              "tool_group_name": this.project_toolGrName
          },
          "tool_instance_details": {
              "scm_tool_instance": {
                  "tool_instance_name":this.project_nameInstance,
                  "tool_url": this.project_URL,
                  "tool_description": this.project_description,
                  "tool_auth": {
                      "auth_type": "",
                      "auth_username": "",
                      "auth_password": "",
                      "auth_token": this.project_Token,
                      "auth_mail": ""
                  }
              }
          }
      }    
       
        // console.log("Update Body");
         //console.log(updateBody);
          //debugger
        this.apiService.Admin_Scm_UpdateConnData(updateBody).subscribe((res: HttpResponse<any>) => {
          //debugger
          //console.log(res);
          if (res.status == 200) {
           
            this.alertService.success("Data Updated Successfully", this.options);
            this.testConnDisable = false;
           
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-aws-config']);
            });
          }
          else if (res.status == 202) {
            this.alertService.success("Data Saved Successfully", this.options);
            this.testConnDisable = false;
           
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-aws-config']);
            });
          }
  
        }, (error: HttpErrorResponse) => {
  
          if (error.status == 430||error.status == 436) //doesnt work
          {
            this.alertService.error(error.error.message, this.options);
          }
          if (error.status == 500) //doesnt work
          {
            this.alertService.error("Invalid Responce", this.options);
  
          }
          
          else if (error.status == 403){}
          else {
  
            this.alertService.error("Invalid Input", this.options);
  
            throw error;
          }
        })
  
      }//else
  
    }
  
    AwsSave() {
      if (this.toolsGroup.proBundles == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
        || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
        || this.toolsGroup.toolGr == "" || this.toolsGroup.proBundles == " " || this.toolsGroup.proBundles == ""|| this.toolsGroup.proToken == " "
        || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
        || this.toolsGroup.proDirectories == undefined|| this.toolsGroup.proDirectories == ""|| this.toolsGroup.proDirectories == " ") {
        this.alertService.error("Please enter details In all the fields", this.options);
      }
      else {
        var saveBody: any;
        this.project_URL = this.toolsGroup.proURL;
        this.project_nameInstance = this.toolsGroup.nameInstance;
        this.project_description = this.toolsGroup.description;
      
        this.project_toolGrId = this.toolsGroup.toolGr;
        this.project_new_ToolGr_ID = this.toolsGroup.toolGr;
  
      
  
         
  
          // ##### Code for New_Save Funtionality ########
          saveBody = {
            "resource_type": "tool",
            "tool_category": "AWS",
            "tool_name": "AWS-Workspace",
            "tool_group_details": {
              "tool_group_id": this.project_toolGrId,//"TOOLGROUP-1",
              "tool_group_name": this.project_toolGrName
            },
            "tool_instance_details": {
              "aws_workspace_tool_instance": {
                "tool_instance_name":  this.project_nameInstance,
                "bundle_id": this.toolsGroup.proBundles,
                "directory_id": this.toolsGroup.proDirectories,
                "description": this.project_description
                            }
            }
          }
  
        // console.log("saveBody");
         //console.log(saveBody);
        //  debug  r;
        this.apiService.Admin_AwsSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
          // 
          if (res.status == 200) {
            //          console.log(res);
            this.alertService.success("Data Saved Successfully", this.options);
            this.testConnDisable = false;
            
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-aws-config']);
            });
          }
          else if (res.status == 202) {
            this.alertService.success("Data Saved Successfully", this.options);
            this.testConnDisable = false;
            
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-aws-config']);
            });
          }
  
        }, (error: HttpErrorResponse) => {
          //
  
          if (error.status == 430||error.status == 436) //doesnt work
          {
               
            this.alertService.error(error.error.message, this.options);
  
          }
          else if (error.status == 403){}
          else {
           
            this.alertService.error("Invalid Input", this.options);
            throw error;
          }
        })
  
      }//else
  
    }
  
    openDialog() {
      const dialogRef = this.dialog.open(AddToolGroupAwsDialog, {
        width: "55vw",

        height: "50vh",
  
        maxWidth: "500px",
  
        maxHeight: "330px"
  
      });
    }
  
  }
  
  
  @Component({
    selector: 'add-tool-group-aws-dialog',
    templateUrl: 'add-tool-group-aws-dialog.html',
    styleUrls: ['./admin-aws-config.component.scss']
  })
  
  export class AddToolGroupAwsDialog {
  
  
    toolId: any;
    toolName: any;
    toolDescription: any;
    model: any = {};
    formMode: string = "";
  
    toolGr_description: string;
    toolGr_name: string;
  
    submitted = false;
  
    mySubscription: any;
    options = {
      autoClose: true,
      keepAfterRouteChange: true
    };
  
    constructor(private formBuilder: FormBuilder,
      private http: HttpClient, private router: Router,
      private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {
  
    }
  
    ngOnInit(): void {
  
    }
  
    onSubmit() {
      // 
    }
  
    openSnackBarDiaLog() {
      const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
        width: '32%',
        height: '23%',
        disableClose: true,
  
      });
    }
  
    clearAllSCMDetails() {
      this.model.toolName = "";
      this.model.toolDescription = "";
    }
  
    ToolGrSaveData() {
  
      if (this.model.toolName == undefined || this.model.toolDescription == undefined
        || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
        || this.model.toolDescription == "") {
      }
      else {
        var saveBody: any;
        this.toolGr_description = this.model.toolDescription;
        this.toolGr_name = this.model.toolName;
        // ##### Code for Save Funtionality ########
        saveBody = {
          
          "resource_type": "tool-group",
          "tool_group_description": this.toolGr_description,
          "tool_group_name": this.toolGr_name,
          "created_by":localStorage.getItem('mail').toLowerCase()
        }
  
        //      console.log(saveBody);
        //
        this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) //doesnt work
          {
  
            var new_ToolGroupId = res.body.tool_group_id;
            var new_ToolGroupName = res.body.tool_group_name;
            localStorage.setItem("Popup_NewToolGroupID_Aws", new_ToolGroupId);
            localStorage.setItem("Popup_NewToolGroupName_Aws", new_ToolGroupName);
            localStorage.setItem("Popup_Open_Aws", "true");
            this.alertService.success("Data Saved Successfully", this.options);
  
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-aws-config']);
            });
  
            this.dialog.closeAll();
          }
        }, (error: HttpErrorResponse) => {
          if (error.status == 436||error.status == 430) //doesnt work
          {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {
            this.alertService.error("Invalid Input", this.options);
            throw error;
          }
        })
  
      }//else
  
    }
  
  
  }