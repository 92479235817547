<div class="p-5">

  <div class="canvas-card p-5">
    <div class="mb-5 d-block">      
        <a routerLink="/admin-tool-group"><i class="fas fa-arrow-alt-circle-left back-arrow"></i></a> 
        <img src="assets/img/tool-config-icon.png" class="img-fluid project-top-img">
        <span class="project-tool-name">Tool Config</span>
    </div>
    <div class="row">    
      <div class="col-md-12 p-0">
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="form-group col-8">

            <label>Tool Group Name</label>
            <input type="text" autocomplete="off" style="width: 50%; font-size: 14px; height: 35px;" 
            name="toolName" class="form-control" [(ngModel)]="model.toolName"
              #toolName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && toolName.invalid }" required>
            <div *ngIf="f.submitted && toolName.invalid" class="invalid-feedback">
              <div *ngIf="toolName.errors.required">Tool Name is required</div>
            </div>
          </div>

          <div class="form-group col-8">
            <label>Tool Description</label>
            <textarea style="width: 50%; font-size: 14px; height: 60px;" name="toolDescription" class="form-control"
              [(ngModel)]="model.toolDescription" #toolDescription="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && toolDescription.invalid }" required></textarea>
            <div *ngIf="f.submitted && toolDescription.invalid" class="invalid-feedback">
              <div *ngIf="toolDescription.errors.required">Tool description is required</div>
            </div>
          </div>

          <div class="form-group col-8">
           <button type="button" (click)="ToolGrSaveData()" class="btn-canvas-blue mr-2">Save</button> 
          
            
            <button type="reset" class="btn-canvas-blue">
              Cancel
            </button>
          </div>
        </form>

      </div>
    </div>

  </div>
</div>