<div class="pop-up">
    <div class="row">
        <div class="col-md-5 mt-3">
            <span class="vertical-line mx-1 mx-md-3"> </span>
            <span class="header mb-0"> {{memberName}}</span>
        </div>
        <div class="col-md-5">
            <mat-form-field fxFlex="60%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here"
                    autocomplete="off">
            </mat-form-field>
        </div>
        <div class="col-md-2 mt-3">
            <span (click)="closeDialog()" class="material-icons close-icon close-btn">
                close
            </span>
        </div>
    </div>

    <hr class="margin-class" />
    <div class="popup-container">

        <div class="noData-style" *ngIf="dataSource.filter!='' && dataSource.filteredData.length==0">
            <h2>No data available</h2>
        </div>
        <div class="example-container1" *ngIf="dataSource.filteredData.length!=0 ">
            <table mat-table [dataSource]="dataSource">


                <ng-container matColumnDef="jiraProjectKey ">
                    <th mat-header-cell *matHeaderCellDef> Jira Instance ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.jiraProjectKey }} </td>
                </ng-container>

                <ng-container matColumnDef="jiraProjectName">
                    <th mat-header-cell *matHeaderCellDef> Jira Instance Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.jiraProjectName}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            </table>

        </div>


        <div class="row justify-content-center">
            <div class="col-md-8" *ngIf="dataSource.filteredData.length!=0">
                <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
                </mat-paginator>
            </div>

        </div>
    </div>