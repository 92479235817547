<div class="pop-up">
    <span  class="vertical-line mx-3"> </span>
     <span class="header mb-0"> Workspace Request Details</span> 
    <span (click)="exit()" class="material-icons close-icon">
        close
      </span>
<hr class="margin-class" />
<div class="popup-container">
<div class="row pt-2">
<div class="col-md-4">
    <span class="field-name">AWS Config Tool ID </span>
</div>
<div class="col-md-6">
     <span> : {{dataInfo.aws_config_tool_id}}</span>
</div>
</div>

<div class="row pt-2">
<div class="col-md-4">
    <span class="field-name">Workspace Image Tool ID </span>
</div>
<div class="col-md-6">
        <span> : {{dataInfo.aws_workspace_image_tool_id}}</span>
</div>
</div>

<div class="row pt-2">
<div class="col-md-4">
    <span class="field-name">Root Volume size (GiB) </span>
</div>
<div class="col-md-6">
        <span> : {{dataInfo.workspace.root_volume_size_gb}}</span>
</div>
</div>

<div class="row pt-2">
<div class="col-md-4">
    <span class="field-name">User Volume size (GiB)</span>
</div>
<div class="col-md-6">
        <span> : {{dataInfo.workspace.user_volume_size_gb}}</span>
</div>
</div>
</div>
</div>