import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { error } from '@angular/compiler/src/util';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';


export interface scmTool {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

export interface SCMDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}



@Component({
  selector: 'app-admin-gitlab',
  templateUrl: './admin-gitlab.component.html',
  styleUrls: ['./admin-gitlab.component.scss']
})
export class AdminGitlabComponent implements OnInit,CanComponentLeave {
  SCMArray = [];
  scmObj: object[];
  //toolName:any;
  toolDescription: any;
  model: any = {};
  //formMode:string = "";
  checklist: any;

  testConnDisable = false;
  saveConnDisable = true;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  //submitted = false;
  GitLabDisable=false;
  PrivateDisable=false;
  ToolGroupDisable=false;
  ToolInstanceNameDisable =false;
  masterSelected: boolean;
  project_selectedcheckbox = false;
  checkedList: any;

  VdiConfig_details:any=[];
  check:any=[]


  // Variable for Save/Edit body
  project_Token: string;
  project_URL: string;
  project_toolGrId: string;
  project_toolGrName: string;
  project_id_scmEdit: string;
  project_id_db: string;
  project_nameInstance: string;
  project_description: string;
  project_toolId:String;

  project_old_toolGr_ID: string;
  project_new_ToolGr_ID: string;
  showGitlabForm: boolean = false;
  editMode: boolean = false;

  token: any;
  //projectURLVal: any;
  toolName: any;

  toolsGroup: any = {};

  scmToolDetails: scmTool;
  selectedToolvalue: any;

  formMode: string = "";

  toolGr: any;
  proURL: any;
  proToken: any;
  db_id: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  Role:string="";
  ToolGroupList = [];


  submitted = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  itemSelected:boolean=false;
  toolsGroupForm: any;
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.proURL == '' || this.toolsGroup.proURL == undefined) &&
      (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
       (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
        (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
         && (this.toolsGroup.proToken == '' || this.toolsGroup.proToken == undefined)){
        return true
      }
      if ((this.toolsGroup.proURL != '' || this.toolsGroup.proURL != undefined) &&
      (this.toolsGroup.toolGr != '' || this.toolsGroup.toolGr != undefined) &&
       (this.toolsGroup.description != '' || this.toolsGroup.description != undefined)  &&
        (this.toolsGroup.nameInstance != '' || this.toolsGroup.nameInstance != undefined)
         && (this.toolsGroup.proToken != '' || this.toolsGroup.proToken != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  
 

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { 
      this.masterSelected = false;

    }

  ELEMENT_DATA: SCMDetails[];
  displayedColumns: string[] = ['ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
  //dataSource = new MatTableDataSource<SCMDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<SCMDetails>(true, []);

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.getTypeT();
    this.dtOptions = {
      order: []
    };
    var PopupToolGroup = localStorage.getItem('Popup_Open_GitLab')
    if (PopupToolGroup == "true") {
      this.showGitlabForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_GitLab')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
      //console.log("this.toolsGroup.toolGr");
     // console.log(this.toolsGroup.toolGr);
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_GitLab')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_GitLab", "false");
    } else {
      this.showGitlabForm = false;
    }
    this.GetScmtableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  refreshGitlab() {
  //console.log("hhh");
    
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-gitlab']);
    });
    // this.router.navigate(['/admin-gitlab'])

  }

  backNavigation(){
    if ((this.toolsGroup.proURL == '' || this.toolsGroup.proURL == undefined) &&
     (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
       (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
        && (this.toolsGroup.proToken == '' || this.toolsGroup.proToken == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }


  addGitlab() {
    // debugger
    this.closeGitlab();
    this.clearGitLabFormDetails();
    this.showGitlabForm = true;
    this.ToolGroupDisable=false;
    this.ToolInstanceNameDisable = false;
  }
  deleteRecord(){
    // console.log(this.SCM)
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }

  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
   // console.log(this.checkedList);
    
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // console.log(this.checkedList);
    
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);


                  
                 // For Loop of tag

                // Add image des 
                               
             }
           //  console.log(this.VdiConfig_details);
             
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  
  DeleteConfig(){
     var deleteBody: any;
     this.check=[]
     this.VdiConfig_details.forEach(element => {
       this.check.push(element.vdi_dropdown_id)
       
     });
     
     
    // console.log(this.check);
     
     deleteBody = {
       "tool_ids":this.check
    
     }
    
     this.apiService.Admin_GitlabDropdowDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-gitlab']);
         });
        //  this.alertService.success("Data Delete Successfully", this.options);
        this.alertService.success(res.body.message, this.options);
       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
  //  checking(){
  //    if (this.toolsGroupForm.pristine==true){
  //     this.router.navigate(['/admin-jenkins'])

  //    }
  //    else{
  //     this.router.navigate(['/admin-catalog-config'])
  //    }

  //  }
  closeGitlab() {
    //console.log("hi");

    this.clearGitLabFormDetails();
    this.showGitlabForm = false;
    this.editMode = false;
    this.testConnDisable = false;
    this.saveConnDisable = true;
    this.UpdateConnDisable = true;
    // this.canLeave()

  }
  cancelOK(){
    this.closeGitlab()
    // debugger
   // console.log(this.nextUrl);
    if(this.nextUrl==undefined){
      this.refreshGitlab()
    }
    else{
      this.router.navigate([this.nextUrl])
  
    }
    

  }

  GetScmtableDetails() {
    this.spinner.show();
    this.apiService.Admin_ScmToolGrTableDetails().subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.scm_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.scm_tool_instance.tool_description,
          "projectToken": member.tool_instance_details.scm_tool_instance.tool_auth.auth_token,
          "projectURL": member.tool_instance_details.scm_tool_instance.tool_url,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
    // SCMArray
  }

  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        // console.log("SCM");
        //console.log(data.body);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }

  //Assigning row data to form 
  setScmEditing(scmObj: scmTool) {
    //debugger;

     //console.log("scmObj",scmObj)

    this.toolsGroup.toolGr = scmObj.toolGroup_id;
    this.toolsGroup.proURL = scmObj.projectURL;
    this.toolsGroup.proToken = scmObj.projectToken;
    this.project_id_scmEdit = scmObj.ID;
    this.project_id_db = scmObj.db_id;
    this.toolsGroup.nameInstance = scmObj.name;
    this.toolsGroup.description = scmObj.description;
    this.project_old_toolGr_ID = scmObj.toolGroup_id

    // Value for Update functionality

    this.project_URL = scmObj.projectURL;
    this.project_nameInstance = scmObj.name;
    this.project_description = scmObj.description;
    this.project_Token = scmObj.projectToken;
    this.project_toolGrId = scmObj.toolGroup_id;
    this.project_toolGrName = scmObj.toolGroup
    this.project_toolId = scmObj.ID
    

  }

  selectedTool(e) {

  }

  //Fetch table row data
  getRecord(row) {
    //debugger;
    this.scmObj = row;
    try {
    localStorage.setItem('scmToolObj', JSON.stringify(this.scmObj));
    localStorage.setItem("formMode", "Edit");
    }
    catch {
      return throwError('Data not found');
    }
    this.formMode = localStorage.getItem('formMode')
    if (this.formMode == "Edit") {

      //### Disable the TextBox
      this.ToolGroupDisable=true
      this.testConnDisable = false;

      this.ToolInstanceNameDisable = true;
      try {
        var getObj = JSON.parse(localStorage.getItem('scmToolObj'));

      }
      catch {
        return throwError('Data not found');
      }
      this.setScmEditing(getObj);
      this.editMode = true;
      this.GitLabDisable=false;
      this.PrivateDisable=false;
    }
    this.showGitlabForm = true;
    this.testConnDisable = false;
    this.UpdateConnDisable = true;
    // this.router.navigate(['/admin-gitlab']);
  }


  onSubmit() {
    // debugger
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


    if (this.toolsGroup.proURL == undefined || this.toolsGroup.toolGr == undefined ||
      this.toolsGroup.description == undefined || this.toolsGroup.nameInstance == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.toolGr == " " || this.toolsGroup.proToken == undefined
      || this.toolsGroup.description == " " || this.toolsGroup.description == "" || this.toolsGroup.nameInstance == " "|| this.toolsGroup.nameInstance == ""
      || this.toolsGroup.proURL == " " || this.toolsGroup.proURL == "" || this.toolsGroup.proToken == " "|| this.toolsGroup.proToken == "") {
      this.testConnDisable = true;
      this.alertService.error("Please enter details In all the fields", this.options);
      setTimeout(() => {
        this.testConnDisable = false;
      }, 2700)
    }
    else if(this.toolsGroup.description.length<20 || this.toolsGroup.nameInstance.length<3 || this.toolsGroup.nameInstance>20 || this.toolsGroup.description.length>50 || sp.test(this.toolsGroup.nameInstance)){
      this.testConnDisable = true;

      // this.alertService.error("Please enter minimum 20 characters for Description ", this.options);
      setTimeout(() => {
        this.testConnDisable = false;
      }, 2)

          }
    else {

      var url: string = this.toolsGroup.proURL;
      var nameInstance: string = this.toolsGroup.nameInstance;
      var description: string = this.toolsGroup.description;
      var token: string = this.toolsGroup.proToken;
      var toolGroup: string = this.toolsGroup.toolGr;

      url = url.trim();
      nameInstance = nameInstance.trim();
      description = description.trim();
      token = token.trim();
      toolGroup = toolGroup.trim();
      this.scmTestConnection(url, token);
    }

  }


  scmTestConnection(project_URL, project_Token) {

    let testConnBody = {
      "tool_name": "Gitlab",
      "tool_url": project_URL,
      "auth_token": project_Token

    }
    if(this.toolsGroup.nameInstance.length<3){
      this.alertService.error("  Please enter minimum 3 characters for saving Tool Name", this.options);
      return;
    }
    if(this.toolsGroup.nameInstance.length>20){
      this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
      return;
    }
    if(this.toolsGroup.description.length<20){
      this.alertService.error("  Please enter minimum 20 characters for saving Description", this.options);
      return;
    }
    if(this.toolsGroup.description.length>50){
      this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
      return;
    }

    //console.log(testConnBody);
    this.spinner.show();
    this.apiService.Admin_ScmTestConnection(testConnBody).subscribe((res: HttpResponse<any>) => {

      //console.log(res);
      if (res.status == 200) //doesnt work
      {
        this.alertService.success(res.body.message, this.options);
        //this.alertService.success("Test Connection Successfully. Please Save the Connection", this.options);
        this.testConnDisable = true;
        // this.saveConnDisable = false;
        // this.UpdateConnDisable = false;
        this.spinner.hide();
        if (this.editMode==true){
          this.UpdateConnDisable = false;
          this.saveConnDisable = true;
          this.GitLabDisable=true;
          this.PrivateDisable=true;
          // this.TenantDisable=true;

        }
        else{
          this.UpdateConnDisable = true;
          this.saveConnDisable = false;
          this.GitLabDisable=true;
          this.PrivateDisable=true
          // this.TenantDisable=true;
        }
      }
      if (res.status == 500) //doesnt work
      {

        this.alertService.success("Connection failed. Please Enter Valid Inputs", this.options);
        this.spinner.hide();
      }
    }, (error: HttpErrorResponse) => {
      //

     // console.log(error.status);
     if (error.status == 436) //doesnt work
      {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403){}
      if (error.status == 500) //doesnt work
      {
        this.testConnDisable = true;
        this.alertService.error("Connection failed. Please Enter Valid Inputs", this.options);
        setTimeout(() => {
          this.testConnDisable = false;
        }, 2700)
        this.spinner.hide();
      }
      else {
        // this.alertService.error("Invalid Input", this.options);
        this.spinner.hide();
        throw error;

      }
    })

  }

  clearGitLabFormDetails() {
    this.toolsGroup.proURL = "";
    this.toolsGroup.nameInstance = "";
    this.toolsGroup.description = "";
    this.toolsGroup.proToken = "";
    this.toolsGroup.toolGr = "";
    this.GitLabDisable=false;
    this.PrivateDisable=false;
  }

  onChange($event) {
    let scmToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = scmToolGrName;
    //console.log("scmToolGrName", scmToolGrName);
  }

  CheckgitLabDuplication() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (this.toolsGroup.proURL == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.proURL == " "|| this.toolsGroup.proURL == "" || this.toolsGroup.proToken == " "|| this.toolsGroup.proToken == ""
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " || this.toolsGroup.description == " "|| this.toolsGroup.description == " "
      || this.toolsGroup.proToken == undefined) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    if(this.toolsGroup.nameInstance.length<3){
      this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
      return;
    }
    if(sp.test(this.toolsGroup.nameInstance)){
      
      this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
      return;
    }
    if(this.toolsGroup.nameInstance.length>20){
      this.alertService.error("  Please enter maximum 20 characters for saving Tool Name", this.options);
      return;
    }
    if(this.toolsGroup.description.length<20){
      this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
      return;
    }
    if(this.toolsGroup.description.length>50){
      this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
      return;
    }

    else {


      // this.project_nameInstance = this.storyBoard.nameInstance;

        let validateBody = {
        "resource_type": "tool",
        "tool_category": "SCM",
        "tool_name": "Gitlab",
        "tool_instance_details": {
          "scm_tool_instance": {
            "tool_instance_name": this.toolsGroup.nameInstance
          }
        }
      }

    //  debugger

      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
       // debugger
        //console.log("Inside Validate");
       // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":

              this.alertService.error(res.body.message, this.options);
              break;

            default:
              this.SCMConnSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }


  // Update row data
  updateGitLab() {

    //
    if (this.toolsGroup.proURL == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
    || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
    || this.toolsGroup.toolGr == "" || this.toolsGroup.proURL == " "|| this.toolsGroup.proURL == "" || this.toolsGroup.proToken == " "|| this.toolsGroup.proToken == ""
    || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " || this.toolsGroup.description == " "|| this.toolsGroup.description == ""
    || this.toolsGroup.proToken == undefined) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description", this.options);
        return;
      }
 
      var updateBody: any;
      this.project_URL = this.toolsGroup.proURL;
      this.project_nameInstance = this.toolsGroup.nameInstance;
      this.project_description = this.toolsGroup.description;
      this.project_Token = this.toolsGroup.proToken;
      this.project_toolGrId = this.toolsGroup.toolGr;
      this.project_new_ToolGr_ID = this.toolsGroup.toolGr;

        // ##### Code for Update Funtionality ########
      updateBody = {
        "tool_id": this.project_toolId,
        "tool_category": "SCM",
        "tool_name": "Gitlab",
        "tool_group_details": {
            "tool_group_id": this.project_toolGrId,
            "tool_group_name": this.project_toolGrName
        },
        "tool_instance_details": {
            "scm_tool_instance": {
                "tool_instance_name":this.project_nameInstance,
                "tool_url": this.project_URL,
                "tool_description": this.project_description,
                "tool_auth": {
                    "auth_type": "",
                    "auth_username": "",
                    "auth_password": "",
                    "auth_token": this.project_Token,
                    "auth_mail": ""
                }
            }
        },
        "updated_by":localStorage.getItem('mail').toLowerCase() 
    }    
     
      // console.log("Update Body");
      // console.log(updateBody);
       // debugger
      this.apiService.Admin_Scm_UpdateConnData(updateBody).subscribe((res: HttpResponse<any>) => {
        //debugger
       // console.log(res);
        if (res.status == 200) {
         
          this.testConnDisable = false;
          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
          this.alertService.success(res.body.message, this.options);
        }
        else if (res.status == 202) {
          this.testConnDisable = false;
          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
          this.alertService.success(res.body.message, this.options);
        }

      }, (error: HttpErrorResponse) => {

        if (error.status == 430||error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("Invalid Responce", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    }//else

  }

  SCMConnSave() {
    //
    if (this.toolsGroup.proURL == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
    || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
    || this.toolsGroup.toolGr == "" || this.toolsGroup.proURL == " "|| this.toolsGroup.proURL == "" || this.toolsGroup.proToken == " "|| this.toolsGroup.proToken == ""
    || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " || this.toolsGroup.description == " "|| this.toolsGroup.description == " "
    || this.toolsGroup.proToken == undefined) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      
      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description", this.options);
        return;
      }
      var saveBody: any;
      this.project_URL = this.toolsGroup.proURL;
      this.project_nameInstance = this.toolsGroup.nameInstance;
      this.project_description = this.toolsGroup.description;
      this.project_Token = this.toolsGroup.proToken;
      this.project_toolGrId = this.toolsGroup.toolGr;
      this.project_new_ToolGr_ID = this.toolsGroup.toolGr;

        // ##### Code for New_Save Funtionality ########
        saveBody = {
          "resource_type": "tool",
          "tool_category": "SCM",
          "tool_name": "Gitlab",
          "tool_group_details": {
            "tool_group_id": this.project_toolGrId,//"TOOLGROUP-1",
            "tool_group_name": this.project_toolGrName
          },
          "tool_instance_details": {
            "scm_tool_instance": {
              "tool_instance_name": this.project_nameInstance,
              "tool_url": this.project_URL,
              "tool_description": this.project_description,
              "tool_auth": {
                "auth_type": "",
                "auth_username": "",
                "auth_password": "",
                "auth_token": this.project_Token,
                "auth_mail": ""
              }
            }
          },
          "created_by":localStorage.getItem('mail').toLowerCase()
        }      
      // console.log("saveBody");
      
      this.apiService.Admin_ScmSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        // 
        if (res.status == 200) {
          //          console.log(res);
         // this.alertService.success("Data Saved Successfully", this.options);
         
          this.testConnDisable = false;
          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
          this.alertService.success(res.body.message, this.options);
        }
        else if (res.status == 202) {
          
          this.testConnDisable = false;
          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });
          this.alertService.success(res.body.message, this.options);
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"

    });
  }

}


@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-gitlab.component.scss']
})

export class AddToolGroupDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  clearAllSCMDetails() {
    this.model.toolName = "";
    this.model.toolDescription = "";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
        // "tool_category": "ToolGroup",
        // "tool_group_description": this.toolGr_description,
        //"tool_group_name": this.toolGr_name,
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_GitLab", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_GitLab", new_ToolGroupName);
          localStorage.setItem("Popup_Open_GitLab", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-gitlab']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}
