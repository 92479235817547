<div class="pop-up">
    <div class="">
      <span  class="vertical-line mx-1 mx-md-1"> </span>
       <span class="header mb-0" *ngIf="type === 'VDI'">VDI Detail View</span> 
       <span class="header mb-0" *ngIf="type === 'VM'">VM Detail View</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
    
      <hr class="margin-class" />
      <div class="popup-container" *ngIf="screen === 'usage'">
        <div class="row">
            <div class="col-sm-4">
                <div class="field-name">Size</div>
                <div>{{detailData.Size || "-"}}</div>
            </div>
            <div class="col-sm-4">
                <div class="field-name">Disk</div>
                <div>{{detailData.Disk || "-"}}</div>
            </div>
            <div class="col-sm-4">
                <div class="field-name">Location</div>
                <div>{{detailData.Location || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="field-name">Desktop Server</div>
                <div>{{detailData.Desktop_Server || "-"}}</div>
            </div>
            <div class="col-sm-4">
                <div class="field-name">WVD Version</div>
                <div>{{detailData.WVDVersion || "-"}}</div>
            </div>
            <div class="col-sm-4">
               <div class="field-name">Host Pool Name</div>
               <data>{{detailData.HostPoolName || "-"}}</data>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="field-name">Session Host Name</div>
                <div>{{detailData.SessionHostName || "-"}}</div>
            </div>
            <div class="col-sm-4">
                <div class="field-name">User Name</div>
                <div>{{detailData.UserName || "-"}}</div>
            </div>
            <div class="col-sm-4">
               <div class="field-name">Status</div>
               <data>{{detailData.Status || "-"}}</data>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="field-name">Resource Id</div>
                <div>{{detailData.ResourceId || "-"}}</div>
            </div>
        </div>
      </div>
      <div class="popup-container" *ngIf="screen === 'resource'">
        <div class="table-responsive  mt-2" *ngIf="type === 'VDI'">
        <table [dtOptions]="dtOptions" datatable class="table fix-table">
            <thead>
              <tr>
               
                <th>Host Name</th>
                <th>Application Group</th>
                <th>Workspace Name</th>
                <th>Resource Id</th>
              </tr>
            </thead>
            <tbody>
            <tr class="break__word">
             
              <td class="w-25">{{detailData.HostPoolName || "-"}}</td>
              <td class="w-25">{{detailData.ApplicationGroup || "-"}}</td>
              <td class="w-25">{{detailData.WorkSpaceName || "-"}}</td>
              <td class="w-25">{{detailData.ResourceId || "-"}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive  mt-2" *ngIf="type === 'VM'">
          <table datatable [dtOptions]="dtOptions" class="table fix-table">
              <thead>
                <tr>
                 
                  <th>Server Type</th>
                  <th>Application Name</th>
                  <th>Location</th>
                  <th>OS Disk Type</th>
                  <th>VM Size</th>
                  <th>Resource ID</th>
                </tr>
              </thead>
              <tbody>
              <tr class="break__word">
               
                <td class="w-15">{{detailData.Desktop_Server || "-"}}</td>
                <td class="w-15">{{detailData.ApplicationName || "-"}}</td>
                <td class="w-15">{{detailData.Location || "-"}}</td>
                <td class="w-15">{{detailData.Disk || "-"}}</td>
                <td class="w-15">{{detailData.Size || "-"}}</td>
                <td class="w-15">{{detailData.ResourceId || "-"}}</td>
              </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>