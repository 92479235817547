<div class="pop-up">
    <div class="">
      <span  class="vertical-line mx-1 mx-md-3"> </span>
       <span class="header mb-0">Computer Detail Data</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
    
      <hr class="margin-class" />
      <div class="popup-container">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Connector GUID</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.connector_guid || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">External IP</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.external_ip || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Internal IP</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.internal_ips || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Is Compromised</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.is_compromised || "No"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Last Seen</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.last_seen || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Isolation Status</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.isolation.status || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Last Seen</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.last_seen || "-"}}</div>
            </div>
        </div>
       
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                <div class="field-name">Orbital Status</div>
            </div>
            <div class="col-sm-8">
                <div class="detail-field-value">{{detailData.orbital.status || "-"}}</div>
            </div>
        </div>
        
      </div>
    </div>