<div class="pop-up" *ngIf= "type=='vmSizeInfo'">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Selected VM Size Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    
<!--div class="dialogue">
    <span (click)="exit()" class="material-icons" style="float: right;cursor: pointer;">
      close
    </span>
</div-->
<div class="pop-up">
   <div class="popup-container">
   <div class="row pt-8">
       <div class="col-md-4">
           <span class="field-name">RAM</span>
       </div>
       <div class="col-md-6">
               <span> : &nbsp;{{vmSizeInfo.RAM}} GiB</span>
       </div>
   </div>
   
   <div class="row pt-8">
       <div class="col-md-4">
           <span class="field-name">vCPUs </span>
       </div>
       <div class="col-md-6">
               <span> : &nbsp;{{vmSizeInfo.vCPUs}}</span>
       </div>
   </div>
   
   <div class="row pt-8">
       <div class="col-md-4">
           <span class="field-name">Temp Storage</span>
       </div>
       <div class="col-md-6">
               <span> : &nbsp;{{vmSizeInfo.Temp_Storage}} GiB</span>
       </div>
   </div>
   <div class="row pt-8">
    <div class="col-md-4">
        <span class="field-name">Description</span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{vmSizeInfo.Description}}</span>
    </div>
</div>
   </div>
   </div>
</div>

<div class="pop-up" *ngIf= "type=='networkConfig'">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Selected Network Config Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Subscription ID </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{networkConfigData.subscription_id}}</span>
                </div>
            </div>
            
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Resource Group </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{networkConfigData.resource_group}}</span>
                </div>
            </div>
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">{{(networkConfigData.tool_name == 'Network-Config-VM' ) ? "VM"  : "VDI"}}  Resource Group </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{networkConfigData.vm_resource_group || networkConfigData.resource_group }}</span>
                </div>
            </div>
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Virtual Network </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{networkConfigData.virtual_network}}</span>
                </div>
            </div>
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Subnet </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{networkConfigData.subnet}}</span>
                </div>
            </div>
            
      
        
    </div>
</div>
<div class="pop-up" *ngIf= "type=='imageInfo'">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Selected VM Image Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Operating System </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{vmImageInfo.vm_os}}</span>
                </div>
            </div>
            
            <div class="row pt-8 ">
                <div class="col-md-4">
                    <span class="field-name">Server </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{vmImageInfo.vm_server}}</span>
                </div>
            </div>
            <div class="row pt-8">
                <div class="col-md-4">
                    <span class="field-name">Type </span>
                </div>
                <div class="col-md-6">
                        <span> : &nbsp;{{vmImageInfo.vm_type}}</span>
                </div>
            </div>
            
      
        
    </div>
  
    <!-- <div class="row"  *ngIf="vmImageInfo.name=='CentOS8-Jenkins2263-VM-Image'">
        <div class="col-md-4"  >
                <img src="assets/insurance/Adobe.png" class="icons" />

        </div>
        <div class="col-md-4" class="icons">
                <img src="assets/insurance/Adobe.png" class="icons" />

        </div>
        <div class="col-md-4" class="icons">
                <img src="assets/insurance/Adobe.png" class="icons" />

        </div>
       


    </div> -->
    <!-- <div class="row">
            <div class="col-md-4">
                    <img src="assets/insurance/Adobe.png" />
    
            </div>
            <div class="col-md-4">
                    <img src="assets/insurance/Adobe.png" />
    
            </div>
            <div class="col-md-4">
                    <img src="assets/insurance/Adobe.png" />
    
            </div>
           
    
    
        </div> -->
</div>