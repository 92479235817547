<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a class="breadcrumb-title" routerLink="/admin-catalog-config">Admin Home</a>
            </li>

            <li class="breadcrumb-item active" aria-current="page">AWS</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right">

      </div>
    </div>
    <div>
      <h1 class="text-light mb-0"><img width="40" src="assets/img/VDI-Icon-White.svg">
        <div class="vertical-line mx-3"> </div> <span>AWS</span>
      </h1>
    </div>

  </div>

  <div class="inner-mid-container">
      <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
      <!-- Modal -->
      <div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog"
        aria-labelledby="confirmationModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content pop-up">
            <div class="modal-header-popup">
              <span class="vertical-line mx-3"> </span>
              <span class="header mb-0">Configure AWS Tool</span>
              <span data-dismiss="modal" class="material-icons close-icon">
                close
              </span>
            </div>
            <hr class="margin-class" />
            <div class="modal-body">
              Are you sure want to cancel?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
              <button type="button"  data-dismiss="modal" class="btn btn-canvas-blue-2"
                (click)="CancelFirstForm()">Ok</button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    <mat-horizontal-stepper  #stepper>
      <mat-step>
        <ng-template matStepLabel>Select Tool Group</ng-template>
        <div class="table-container">
          <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table fixed-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tool of ToolGroupArray">
                  <td>{{tool.ID}}</td>
                  <td>{{tool.Name}}</td>
                  <td>{{tool.Description}}</td>
                  <td>
                    <mat-radio-button value="tool.ID" (click)="selectRoww($event, tool)" ></mat-radio-button>
                  </td>
                  <!-- <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                    [(ngModel)]="favoriteSeason">
                    <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">
                      {{season}}
                    </mat-radio-button>
                  </mat-radio-group> -->
                  <!-- <td><mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      [(ngModel)]="favoriteSeason">
                      
                    </mat-radio-group></td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center">
            <button class="btn-register" matStepperNext mat-raised-button color="primary"
              (click)="firstNext(stepper)">Next</button>
          </div>
        </div>
      </mat-step>

      <mat-step >
        <div class="example-container"  [ngClass]="!StepOneFlag ? 'disabled-img' : ''">
          <ng-template matStepLabel >Configure AWS Tool</ng-template>
          <!-- <ng-template matStepLabel><p (click)="createView()">Output</p></ng-template> -->

          <form [formGroup]="firstFormGroup" *ngIf="awsToolTabledata.length==0">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jiraProjName">Name<span class="required">&#42;</span></label>
                  <input #input name="name" class="form-control" maxlength="20" minlength="3" pattern="^[a-zA-Z0-9_-]+$" required formControlName="name">
                  <small class="text-danger" [class.d-none]="name.touched">{{getProjNameErrorMessage()}}
                    </small>
                    <!-- <div *ngIf="name.touched" class="alert alert-danger">
                      fff
                    </div> -->

                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jiraProjName">Description<span class="required">&#42;</span></label>
                  <input #input name="description" class="form-control" maxlength="50" minlength="20" pattern="^[a-zA-Z0-9_-]+$" required formControlName="description">
                  <small class="text-danger" [class.d-none]="description.touched">{{getProjDesErrorMessage()}}
                    </small>
                
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jiraProjName">Access Key ID<span class="required">&#42;</span></label>
                  <input #input name="accesskeyid" class="form-control" pattern="^[a-zA-Z0-9_-]+$" required formControlName="accesskeyid" >
                  <small class="text-danger" [class.d-none]="accesskeyid.touched">{{getProjAccessErrorMessage()}}
                    </small>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="jiraProjName">Secret Key ID<span class="required">&#42;</span></label>
                  <input #input name="secretkeyid" class="form-control" pattern="^[a-zA-Z0-9_-]+$" required formControlName="secretkeyid" >
                  <small class="text-danger" [class.d-none]="secretkeyid.touched">{{getProjSecretErrorMessage()}}
                    </small>
                </div>
              </div>
              
              <div class="col-12 text-right">

                  <button  type="submit"  [disabled]="testConnDisable" (click)="testID()" class="btn-canvas-blue mb-md-3 mb-3 mr-md-2 mr-0">
                      Test ID
                    </button>
                    <button   type="button" [disabled]="saveConnDisable" class="btn-canvas-blue mr-2 mb-3"
                    (click)="saveConfigureAwsTool()">
                    Save
                  </button>
                  <button   type="button" class="btn-canvas-blue mr-2 mb-3" *ngIf="!firstFormGroup.pristine" data-toggle="modal" data-target="#confirmationModal"
                    >
                    Cancel
                  </button>
                  <button   type="button" class="btn-canvas-blue mr-2 mb-3" *ngIf="firstFormGroup.pristine" (click)="CancelFirstForm()"
                  >
                  Cancel
                </button>
              </div>

              <!-- <div class="offset-md-4 col-md-1">
                <button class="btn-canvas-blue" (click)="testID()">TestID</button>
              </div>
              <div class="col-md-1">
                <button class="btn-canvas-blue" (click)="saveConfigureAwsTool()">Save</button>
              </div>
              <div class="col-md-1">
                <button class="btn-canvas-blue">Cancel</button>
              </div> -->

            </div>
          </form>

          <div class="table-container">
            <div class="table-responsive">
              <table datatable [dtOptions]="dtOptions" *ngIf="awsToolTabledata.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Connection Name</th>
                    <th>Subscription</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tool of awsToolTabledata">
                    <td>{{tool.tool_id}}</td>
                    <td>{{tool.tool_name}}</td>
                    <td>{{tool.tool_instance_details.aws_config_tool_instance.access_key_id}}</td>
                    <td>
                      <mat-radio-button value="tool" (click)="selectRow($event, tool)">
                      </mat-radio-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="awsToolTabledata.length==0">
                  <thead>
                    <tr>
                      <th>Request Id</th>
                      <th>VDI Type</th>
                      <th>VM Name</th>
                      <th>Status</th>
                      <th>Members</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  </tbody>
                </table>
            </div>
          </div>

        </div>
        <div class="text-center pt-2">
          <button class="btn-register mr-2" matStepperPrevious mat-raised-button color="primary">Previous</button>
          <button class="btn-register" matStepperNext mat-raised-button color="primary" (click)="secondNext(stepper)"  >Next</button>
        </div>
      </mat-step>

      <mat-step>
        <div class="example-container" [ngClass]="!StepOneFlag || !StepTwoFlag ? 'disabled-img' : ''">
          <ng-template matStepLabel>Add Network Configuration Tool</ng-template>
          <div class="row">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Create Network Configuration Tool For Workspace
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="secondFormGroup">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="jiraProjName">Name<span class="required">&#42;</span></label>
                        <input #input name="name" class="form-control"  formControlName="name">
                        <small class="text-danger" [class.d-none]="description.touched">{{getProjNameErrorMessage2()}}
                          </small>
                        
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="jiraProjName">Description<span class="required">&#42;</span></label>
                        <input #input name="description" class="form-control"  formControlName="description">
                        <small class="text-danger" [class.d-none]="description.touched">{{getProjDesErrorMessage2()}}
                          </small>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="resourceGroup">Resource Group<span class="required">&#42;</span></label>
                        <select id="toolGrWS" name="toolGrWS" class="form-control form-control-lg" required (change)="onChangePropertyNameWorkspace($event)"
                        [(ngModel)]="model.toolGrWS" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select Resource Group</option>
                        <option [value]="resource.arn" *ngFor="let resource of resourceGroupValWorkspace">{{resource.name}}
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="directory">Directory<span class="required">&#42;</span></label>
                        <select id="directory" name="directory" class="form-control form-control-lg" required (change)="onChangeDirectoryName($event)"
                        [(ngModel)]="model.directory" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select directory </option>
                        <option [value]="directory.id" *ngFor="let directory of directoryVal">{{directory.name}}
                        </select>
                      </div>
                    </div>
                    <!-- <div class="col-md-4">
                      <div class="form-group">
                        <label for="subnet">Subnet<span class="required">&#42;</span></label>
                        <select formControlName="subnet" id="subnet" name="subnet"
                          class="form-control form-control-height">
                          <option [value]="null" disabled selected hidden>Select Subnet</option>
                         
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="subnet">Security Group<span class="required">&#42;</span></label>
                        <select formControlName="securityGroup" id="securityGroup" name="securityGroup"
                          class="form-control form-control-height">
                          <option [value]="null" disabled selected hidden>Select Security Group</option>
                      
                        </select>
                      </div>
                    </div> -->
                    <div class="col-12 text-right">
                        <button  type="button" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0"
                        (click)="saveConfigureAwsToolWorkspace()" [disabled]="saveConfigWorkspace">
                        Save
                      </button>
                      <!-- <button  type="button" class="btn-canvas-blue mr-2"
                      >Cancel</button> -->
                      </div>

                    <!-- <div class="offset-md-9 col-md-1">
                      <button class="btn-canvas-blue" (click)="saveConfigureAwsTool()">Save</button>
                    </div>
                    <div class="col-md-1">
                      <button class="btn-canvas-blue">Cancel</button>
                    </div> -->

                  </div>
                </form>
              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Create Network Configuration Tool For EC2
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <form [formGroup]="thirdFormGroup">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="jiraProjName">Name<span class="required">&#42;</span></label>
                        <input #input name="name" class="form-control" required formControlName="name">
                        <small class="text-danger" [class.d-none]="description.touched">{{getProjNameErrorMessage3()}}
                          </small>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="jiraProjName">Description<span class="required">&#42;</span></label>
                        <input #input name="description" class="form-control" required formControlName="description">
                        <small class="text-danger" [class.d-none]="description.touched">{{getProjDesErrorMessage3()}}
                          </small>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="resourceGroup">Resource Group<span class="required">&#42;</span></label>
                        <!-- <select formControlName="resourceGroup" id="resourceGroup" name="resourceGroup"
                          class="form-control form-control-height">
                          <option [value]="null" disabled selected hidden>Select Resource Group</option>
                         
                            <option [value]="resource" *ngFor="let resource of resourceGroupVal">{{resource.name}}

                        </select> -->
                        <select id="toolGr" name="toolGr" class="form-control form-control-lg" required (change)="onChangePropertyName($event)"
                        [(ngModel)]="model.toolGr" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select Resource Group</option>
                        <option [value]="resource.arn" *ngFor="let resource of resourceGroupVal">{{resource.name}}
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="vnet">VNet<span class="required">&#42;</span></label>
                        <select id="vnet" name="vnet" class="form-control form-control-lg" required (change)="onChangeVnetName($event)"
                        [(ngModel)]="model.vnet" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select Resource Group</option>
                        <option [value]="vnet.id" *ngFor="let vnet of vnetVal">{{vnet.name}}
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="subnet">Subnet<span class="required">&#42;</span></label>
                        <select id="subvnet" name="subvnet" class="form-control form-control-lg" required (change)="onChangeSubVnetName($event)"
                        [(ngModel)]="model.subvnet" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select Resource Group</option>
                        <option [value]="subvnet.id" *ngFor="let subvnet of subvnetVal">{{subvnet.name}}
                        </select>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="security">Security Group<span class="required">&#42;</span></label>
                        <select id="security" name="security" class="form-control form-control-lg" required (change)="onChangeSecurityName($event)"
                        [(ngModel)]="model.security" #toolGr="ngModel" [ngModelOptions]="{standalone: true}">
                       <!-- <option selected>Choose Property Name</option> -->
                        <option value="" disabled selected hidden>Select Resource Group</option>
                        <option [value]="security.id" *ngFor="let security of securityVal">{{security.name}}
                        </select>
                      </div>
                    </div>
                    <div class="col-12 text-right">
                        <button  type="button" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0"
                        (click)="AddAWSNetworkConfigTool()" [disabled]="saveNWConfigEc2">
                        Save
                      </button>
                      <!-- <button  type="button" class="btn-canvas-blue mr-2"
                      >Cancel</button> -->
                      </div>
                    <!-- <div class="offset-md-9 col-md-1">
                      <button class="btn-canvas-blue" (click)="AddAWSNetworkConfigTool()">Save</button>
                    </div>
                    <div class="col-md-1">
                      <button class="btn-canvas-blue">Cancel</button>
                    </div> -->

                  </div>
                </form>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="mt-2"><h2 style="text-align: center;"><b>Network Configuration Tool For Workspace</b></h2></div>
          <div class="row">
              <div class="table-responsive pt-2">
                  <table datatable [dtOptions]="dtOptions" *ngIf="networkConfigTabledataWorkspace.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                    <thead>
                      <tr>
                        <th>Tool ID</th>
                        <th>Name</th>
                        <th>Resource Group</th>
                        <th>Directory</th>
                        <th>Tool Group</th>
                        <!-- <th>Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tool of networkConfigTabledataWorkspace">
                        <td>{{tool.tool_id}}</td>
                        <td>{{tool.tool_name}}</td>
                        <td>{{tool.tool_instance_details.aws_workspace_network_config_tool_instance.resource_group.name}}</td>
                        <td>{{tool.tool_instance_details.aws_workspace_network_config_tool_instance.directory.name}}</td>
                        <td>{{tool.tool_group_details.tool_group_name}}</td>
                        <!-- <td>
                          <mat-radio-button value="tool.tool_id" (click)="selectRowWorkspace($event, tool)">
                          </mat-radio-button>                    
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                  <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="networkConfigTabledataWorkspace.length==0">
                      <thead>
                        <tr>
                          <th>Tool ID</th>
                          <th>Name</th>
                          <th>Resource Group</th>
                          <th>Directory</th>
                          <th>Tool Group</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                      </tbody>
                    </table>
                </div>
          </div>
          <div class="mt-2"><h2 style="text-align: center;"><b>Network Configuration Tool For EC2</b></h2></div>

          <div class="row">
              

            <div class="table-responsive pt-1">
              <table datatable [dtOptions]="dtOptions" *ngIf="networkConfigTabledata.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Connection Name</th>
                    <th>Vnet</th>
                    <th>Subnet</th>
                    <th>Resource Group</th>
                    <th>Tool Group</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tool of networkConfigTabledata">
                    <td>{{tool.tool_id}}</td>
                    <td>{{tool.tool_name}}</td>
                    <td>{{tool.tool_instance_details.aws_network_config_tool_instance.vpc.name}}</td>
                    <td>{{tool.tool_instance_details.aws_network_config_tool_instance.subnet.name}}</td>
                    <td>{{tool.tool_instance_details.aws_network_config_tool_instance.resource_group.name}}</td>
                    <td>{{tool.tool_group_details.tool_group_name}}</td>
                    <td>
                      <mat-radio-button value="tool.tool_id" (click)="selectRowww($event, tool)">
                      </mat-radio-button>                    
                    </td>
                  </tr>
                </tbody>
              </table>
              <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="networkConfigTabledata.length==0">
                  <thead>
                    <tr>
                        <th>ID</th>
                        <th>Connection Name</th>
                        <th>Vnet</th>
                        <th>Subnet</th>
                        <th>Resource Group</th>
                        <th>Tool Group</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  </tbody>
                </table>
            </div>
          </div>
        </div>
        <div class="text-center pt-2">
          <button class="btn-register mr-2" matStepperPrevious mat-raised-button color="primary">Previous</button>
          <button class="btn-register" matStepperNext mat-raised-button color="primary" (click)="thirdNext()">Next</button>
        </div>
      </mat-step>

      <mat-step>
        <div class="example-container" [ngClass]="!StepOneFlag || !StepTwoFlag ? 'disabled-img' : ''">
          <ng-template matStepLabel>Amazon Machine Image</ng-template>
          <div class="row">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Create Amazon Machine Image For Workspace
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="table-responsive pt-4">
                    <table datatable [dtOptions]="dtOptions"  *ngIf="SCMArrayWorkspace.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                      <thead>
                        <tr>
                            <th style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="masterSelectedWorkspace" name="list_name2" value="m2" (change)="checkUncheckAllWorkspace()"/>
                              </th>
                          <!-- <th>ID</th> -->
                          <th>Name</th>
                          <th>Bundle ID</th>
                          <th>Description</th>
                          <th>Image ID</th>
                          <th>Root Volume Size</th>
                          <th>User Storage</th>
                          <th>Compute Type</th>
                          <!-- <th>Action</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let tool of SCMArrayWorkspace">
                            <td style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="tool.isSelected" name="list_name2" value="{{tool.name}}"  (change)="isAllSelectedWorkspace()"/>
                              </td>
                          <td>{{tool.name}}</td>
                          <td>{{tool.id}}</td>
                          <td>{{tool.description}}</td>
                          <td>{{tool.imageId}}</td>
                          <td>{{tool.rootStorage}}</td>
                          <td>{{tool.userStorage}}</td>
                          <td>{{tool.computeType}}</td>
                          <!-- <td>{{tool.type}}</td> -->
                          <!-- <td>
                            <mat-radio-button value="tool.tool_id" (click)="selectRowww($event, tool.tool_id)">
                            </mat-radio-button>                    
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                    <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="SCMArrayWorkspace.length==0">
                        <thead>
                          <tr>
                              <th style="width: 15px;">
                                  <input type="checkbox" [(ngModel)]="masterSelectedWorkspace" name="list_name2" value="m2" (change)="checkUncheckAllWorkspace()"/>
                                </th>
                                <!-- <th>ID</th> -->
                                <th>Name</th>
                                <th>Bundle ID</th>
                                <th>Description</th>
                                <th>Image ID</th>
                                <th>Root Volume Size</th>
                                <th>User Storage</th>
                                <th>Compute Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        </tbody>
                      </table>
                  </div>
                  <div class="col-12 text-right">
                      <button  type="button" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0"
                      (click)="AddWorkspaceImageTool()" [disabled]="AddImageArrayWorkspace.length==0">
                      Save
                    </button>
                    <!-- <button  type="button" class="btn-canvas-blue mr-2"
                    >Cancel</button> -->
                    </div>
                  <!-- <div class="offset-md-9 col-md-1">
                <button class="btn-canvas-blue" (click)="AddEC2ImageTool()">Save</button>
              </div> -->
              
                </div>

              </mat-expansion-panel>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Create Amazon Machine Image For EC2
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="table-responsive pt-4">
                    <table datatable [dtOptions]="dtOptions"  *ngIf="SCMArray.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                      <thead>
                        <tr>
                            <th style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
                              </th>
                          <th>Name</th>
                          <th>Image ID</th>
                          <th>Description</th>
                          <th>EC2 OS</th>
                          <th>EC2 Server</th>
                          <th>EC2 Type</th>
                          <!-- <th>Action</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let tool of SCMArray">
                            <td style="width: 15px;">
                                <input type="checkbox" [(ngModel)]="tool.isSelected" name="list_name" value="{{tool.id}}" (change)="isAllSelected()"/>
                              </td>
                          <td>{{tool.name}}</td>
                          <td>{{tool.ID}}</td>
                          <td>{{tool.description}}</td>
                          <td>{{tool.os}}</td>
                          <td>{{tool.server}}</td>
                          <td>{{tool.type}}</td>
                          <!-- <td>
                            <mat-radio-button value="tool.tool_id" (click)="selectRowww($event, tool.tool_id)">
                            </mat-radio-button>                    
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                    <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="SCMArray.length==0">
                        <thead>
                          <tr>
                              <th style="width: 15px;">
                                  <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
                                </th>
                            <th>Name</th>
                            <th>Image ID</th>
                            <th>Description</th>
                            <th>EC2 OS</th>
                            <th>EC2 Server</th>
                            <th>EC2 Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                        </tbody>
                      </table>
                  </div>
                  <div class="col-12 text-right">
                      <button  type="button" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0"
                      (click)="AddEC2ImageTool()" [disabled]="this.AddImageArray.length==0">
                      Save
                    </button>
                    <!-- <button  type="button" class="btn-canvas-blue mr-2"
                    >Cancel</button> -->
                    </div>
                  <!-- <div class="offset-md-9 col-md-1">
                <button class="btn-canvas-blue" (click)="AddEC2ImageTool()">Save</button>
              </div> -->
              
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="mt-3"><h2 style="text-align: center;"><b>Amazon Machine Image For Workspace</b></h2></div>
          <div class="table-container mt-3">
            <!-- Workspace data table -->
            <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions"  *ngIf="awsToolTabledataWorspace.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                  <thead>
                    <tr>
                      <th>ID</th>
                        <th>Name</th>
                          <th>Bundle ID</th>
                          <th>Description</th>
                          <th>Image ID</th>
                          <th>Root Volume Size</th>
                          <th>User Storage</th>
                          <th>Compute Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tool of awsToolTabledataWorspace">
                      <td>{{tool.tool_id}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.name}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.bundle_id}}</td>

                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.tool_description}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.imageId}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.rootStorage}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.userStorage}}</td>
                      <td>{{tool.tool_instance_details.aws_workspace_image_tool_instance.computeType}}</td>
                      
                    </tr>
                  </tbody>
                </table>
                <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="awsToolTabledataWorspace.length==0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                          <th>Bundle ID</th>
                          <th>Description</th>
                          <th>Image ID</th>
                          <th>Root Volume Size</th>
                          <th>User Storage</th>
                          <th>Compute Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    </tbody>
                  </table>
              </div>
              <div class="mt-2"><h2 style="text-align: center;"><b>Amazon Machine Image For EC2</b></h2></div>

            <!-- xxxxxxxxxxxxxxxxxxxxxxx -->
              <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions"  *ngIf="amazonDataTable.length>0" [dtTrigger]="dtTrigger" class="table fixed-table-aws">
                  <thead>
                    <tr>
                      <th>ID</th>
                        <th>Name</th>
                          <th>Image ID</th>
                          <th>Description</th>
                          <th>EC2 OS</th>
                          <th>EC2 Server</th>
                          <th>EC2 Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tool of amazonDataTable">
                      <td>{{tool.tool_id}}</td>
                      <!-- <td>{{tool.tool_name}}</td> -->
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.name}}</td>
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.id}}</td>
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.tool_description}}</td>
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.ec2_os}}</td>
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.ec2_server}}</td>
                      <td>{{tool.tool_instance_details.aws_ec2_image_tool_instance.ec2_type}}</td>
                      
                    </tr>
                  </tbody>
                </table>
                <table datatable [dtOptions]="dtOptions" class="table fixed-table" *ngIf="amazonDataTable.length==0">
                    <thead>
                      <tr>
                          <th>Name</th>
                          <th>Image ID</th>
                          <th>Description</th>
                          <th>EC2 OS</th>
                          <th>EC2 Server</th>
                          <th>EC2 Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                    </tbody>
                  </table>
              </div>
            </div>
            
          
          
        </div>
        <div class="text-center pt-2">
          <button class="btn-register mr-2" matStepperPrevious mat-raised-button color="primary">Previous</button>

          <button class="btn-register"  mat-raised-button color="primary" (click)="Finish()"  >Finish</button>
        </div>
      </mat-step>


    </mat-horizontal-stepper>

  </div>


</div>