import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {environment} from 'src/environments/environment';

// Model for the new event form
export class NewEvent {
    subject?: string;
    attendees?: string;
    start?: string;
    end?: string;
    body?: string;
    weekday?: string;
    interval?: number;
    daysOfWeek?: string;
    daysOfWeeks?: any = [];
    startDate?: string;
    endDate?: string;
  
    // Generate a MicrosoftGraph.Event from the model
    getGraphEvent(): MicrosoftGraph.Event {
      const graphEvent: MicrosoftGraph.Event = {
        subject: this.subject,
        start: {
          dateTime: this.start,
          timeZone: `${environment.TimeZone}`
        },
        end: {
          dateTime: this.end,
          timeZone: `${environment.TimeZone}`
        },
        allowNewTimeProposals: true,
        isOnlineMeeting: true,
        onlineMeetingProvider: "teamsForBusiness"
      };
  
      // If there are attendees, convert to array and add them
      if (this.attendees && this.attendees.length > 0) {
        graphEvent.attendees = [];
  
        const emails = this.attendees.split(';');
        emails.forEach(email => {
          graphEvent.attendees.push({
            type: "required",
            emailAddress: {
              address: email
            }
          });
        });
      }
  
      // If there is a body, add it as plain text
      if (this.body && this.body.length > 0) {
        graphEvent.body = {
          contentType: "text",
          content: this.body
        };
      };
  
      return graphEvent;
    }

     // Generate a MicrosoftGraph.Event from the model
     getRecurringGraphEvent(): MicrosoftGraph.Event {
      // console.log(this.daysOfWeeks);
      // console.log(this.subject);
      const graphRecurringEvent: MicrosoftGraph.Event = {
        subject: this.subject,
        start: {
          dateTime: this.start,
          timeZone: `${environment.TimeZone}`
        },
        end: {
          dateTime: this.end,
          timeZone: `${environment.TimeZone}`
        },
        recurrence: {
                pattern: {
                  type: "relativeMonthly",
                  interval: 1,
                  daysOfWeek: this.daysOfWeeks,
                  index: "first"
                },
                range: {
                  type: "endDate",
                  startDate: this.startDate,
                  endDate: this.endDate
                }
              },
              allowNewTimeProposals: true,
              isOnlineMeeting: true,
              onlineMeetingProvider: "teamsForBusiness"
    };
   
  
      // If there are attendees, convert to array and add them
      if (this.attendees && this.attendees.length > 0) {
        graphRecurringEvent.attendees = [];
        // graphRecurringEvent.recurrence.daysOfWeek.push(recurring);

        const emails = this.attendees.split(';');
        emails.forEach(email => {
          graphRecurringEvent.attendees.push({
            type: "required",
            emailAddress: {
              address: email
            }
          });
        });
      }
  
      // If there is a body, add it as plain text
      if (this.body && this.body.length > 0) {
        graphRecurringEvent.body = {
          contentType: "text",
          content: this.body
        };
      };
  
      return graphRecurringEvent;
    }

  }