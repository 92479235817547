<div class="pop-up" *ngIf="type == 'imageInfo'">
    <div class="">
        <span  class="vertical-line mx-1 mx-md-3"> </span>
         <span class="header mb-0">Selected Image Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />

    <div class="popup-container pt-2">
        <div class="row ">
            <div class="col-md-3">
                <span class="field-name">Operating System </span>
            </div>
            <div class="col-md-3">
                    <span> : Windows 10 Enterprise</span>
            </div>
        
            <div class="col-md-3">
                <span class="field-name">Installed memory (RAM) </span>
            </div>
            <div class="col-md-3">
                    <span> : 8.00 GB</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <span class="field-name">System Type </span>
            </div>
            <div class="col-md-3">
                    <span> : 64-bit Operating System</span>
            </div>
        
            <div class="col-md-3">
                <span class="field-name">Domain Name </span>
            </div>
            <div class="col-md-3">
                    <span> : lntinfotech.com</span>
            </div>
        </div>
      
         
        <div class="row mt-3 scroll-container">
            <div class="col-sm-4 img-margin" *ngFor="let imgtable of imageList; let i = index">
                <table class="table table-bordered" *ngIf="imageList[i].length>0">
                    <tr class="table-th">
                        <td>Software Name</td>
                        <td>Version</td>
                    </tr>
                    <tbody>
                        <tr *ngFor="let img of imageList[i]">
                            <td>{{img.software_name}}</td>
                            <td>{{img.software_version}}</td>
                        </tr>
                    </tbody>
                </table>
                   
            </div>
        </div>
        
    </div>
    
</div>

<div class="pop-up" *ngIf="type == 'vdiInfo'">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">VDI Details</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
    <div class="popup-container pt-2">
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">HostPool Name </span>
            </div>
            <div class="col-md-6">
                    <span> : {{dataReceived.hostpool_name ? dataReceived.hostpool_name : '--NA--'}} </span>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">WorkSpace </span>
            </div>
            <div class="col-md-6">
                    <span> : {{dataReceived.workspace_name ? dataReceived.workspace_name : '--NA--'}}  </span>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">IP </span>
            </div>
            <div class="col-md-6">
                    <span> : {{dataReceived.ip ? dataReceived.ip : '--NA--'}}  </span>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Application Group Name </span>
            </div>
            <div class="col-md-6" *ngIf="dataReceived.status != 'Removed'">
                    <span> : {{dataReceived.application_group ? dataReceived.application_group : '--NA--'}}</span>
            </div>
            <div class="col-md-6" *ngIf="dataReceived.status == 'Removed'">
                <span> : It has been removed</span>
        </div>
        </div>

        <div class="row pt-4"*ngIf="imageListInstall[0]?.length>0 and dataReceived.status=='Running' ">
            <div class="col-md-4">
                <span class="field-name">Installed Software List </span>
            </div>
            <div class="col-md-6" *ngIf="imageListInstall[0].length==0">
                    <span>: Loading...</span>
            </div>
      
        </div>

    <div class="row mt-3" *ngIf="imageListInstall[0]?.length>0 and dataReceived.status=='Running' ">
       
        <div class="col-sm-4 img-margin" *ngFor="let imgtable of imageListInstall; let i = index">
            
            <table class="table table-bordered" *ngIf="imageListInstall[i].length>0">
                <tr class="table-th">
                    <td>Software Name</td>
                    <!-- <td>Version</td> -->
                </tr>
                <tbody>
                    <tr *ngFor="let img of imageListInstall[i]">
                        <td>{{img}}</td>
                        <!-- <td>{{img.software_version}}</td> -->
                    </tr>
                </tbody>
            </table>

    </div>
    </div>  
    </div>
</div>