import { Component, OnInit, Inject, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/api.service';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { from, Observable, of, throwError } from 'rxjs';
import {  ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectSummaryComponent } from 'src/app/project-details/project-summary/project-summary.component';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import {  HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { ToastrService } from 'ngx-toastr';
import { NewEvent } from './create-meeting';
import {MatRadioModule} from '@angular/material/radio';
import {FormControl} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
// import { IDropdownSettings, IDropdownSettings, IDropdownSettings } from 'ng-multiselect-dropdown';
export class ChannelTeam{
  constructor(public channelName:string){}
}
export class Teams{
  constructor(
   public metis : string,
  public cast : string,
   public jira : string,
   public developer : string,
   public spoPo : string,
   public jenkins : string,
    public addChannel : ExtraChannel []
  ){

  }
}

export class ExtraChannel{
  constructor(
   
   public channel : string,
  ){

  }
}
@Component({
  selector: 'app-mschannel-creations',
  templateUrl: './mschannel-creations.component.html',
  styleUrls: ['./mschannel-creations.component.scss']
})
export class MschannelCreationsComponent implements OnInit
{
  
  projectId: any;
  projectName: any;
  projectData: any;
  pipeCompletionStatus: String = "Not Initiated";
  members: any = [];
  username: any = [];
 spaceTable : any = [];
  toolName;
  userOnboarded: boolean = false;
  setNewMeeting : boolean = false;
  teamsGroupStatus = "Not Created";
 
  tableArray: any = [];
 
  channelName : any=[];
  inputDisabled : boolean = true;
channelArray :any = []
options = {
  autoClose: true,
  keepAfterRouteChange: true
};
 
public access_token:string;

  public parentRef: ProjectSummaryComponent;

  enableChanel = false

  
  channelArrayData = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  catalog_id: string;
  catalog_name: string;
  
  existingSpaces : boolean = false;
  channelModel = new Teams('Metis','Cast','Jira','Jenkins','Developer','SM-PO-PM',[])
  showForm: boolean = false;
  webexToken: string;
  title = 'calender';
  projectDetails: any;
  teamDetails: any;
  attendeesDetails: any = [];
  model = new NewEvent();
  isShowDiv = true;
  Role:string="";
  selectedProjectId: String = "Select Project";
  selectedTeam: any;
  selectedAttendees: any;
  selectedCeremonyUrl: any;
  body: any;
  subject: any;
  schedule: any;
  // selectedDays: any = [];
  daysofWeeks: any = [];
  show = false;
  member: any = [];
  
  selectedType: string = 'Once';
  types: any = [
    "Once",
    "Recurring"
  ];

  public roomList: Array<string> = ['Room1','Room2','Room3','Room4',];
  public value: Date = new Date(2019, 5, 1, 22);
  public format = 'MM/dd/yyyy HH:mm';

  ceremonys = [
    { "subject": 'Backlog Refinement', "body": 'Walkthrough of requirement for upcoming sprint through user stories drafted by BA', "schedule": 'Fortnightly' },
    { "subject": 'Sprint Planning', "body": 'User stories must be broken into tasks and allocated to development teams', "schedule": 'Fortnightly' },
    { "subject": 'Daily Scrum', "body": 'Address what is completed, what will be working on and any blockers', "schedule": 'Daily' },
    { "subject": 'Sprint Review', "body": 'Walkthrough of increments to business owners by spoc with feedback mindset', "schedule": 'Fortnightly' },
    { "subject": 'Sprint Retrospectives', "body": 'What went well, what did not go well, any suggestion for improvement', "schedule": 'Fortnightly' },
    // { "subject": 'Scrum Of Scrums', "body": 'High level discussion on impediments/issue/blockers and share with respective teams', "schedule": 'As per discussion' },
  ];
  openWindow: boolean = false;


  date = new FormControl(new Date());
  
  selectedDays: any = [];
  // model = new NewEvent();
  recurrencetime : any = ["00:00"," 00:30","01:00","01:30","02:00","02:30","03:00","03:30","04:00","04:30","05:00","05:30","06:00","06:30","07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00"," 12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"];
  selectedRecurrence : String = "Daily"
  recurrenceOptions : any = ["Daily","Weekly","Monthly","Yearly"];
  secondFormGroup: FormGroup;
  firstFormGroup : FormGroup;
  recurrencePatternRadio : String = "Weekly"
  // daysofWeeks : any = []
  selectedCard = [false, false, false, false,false];
  endRadioValue: String = "endBy";
  showEndCalendar : boolean = true;
  showEndNumber : boolean = false;
  showdisabled: boolean = false;
  weekRank : any = [{name:"First",id: "1"},{name:"Second",id: "2"},{name:"Third",id: "3"},{name:"Fourth",id: "4"},{name:"Last",id: "5"}]
  weekDay : any = ["Day","Weekday","Weekend","Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
 month : any = [{name:"January",id:"1"},{name:"February",id:"2"},{name:"March",id:"3"},{name:"April",id:"4"},{name:"May",id:"5"},{name:"June",id:"6"},{name:"July",id:"7"},{name:"August",id:"8"},{name:"September",id:"9"},{name:"October",id:"10"},{name:"November",id:"11"},{name:"December",id:"12"}]
//  webexToken : String = "";
  recurrenceDailyValue: any = "Every";
  recurrenceMonthlyValue: any ="Day";
  recurrenceYearlyValue: any = "On";
  responseBody : any ;
  membersForMeeting :any= [];
  meetingTitle :String
  meetingAgenda : String
  membersArrayToSend :any= [];
reportTypeArray : any = ["Defect Report","Agile Metrics","Resource Utilization"];
vendor : any = ["Jira","Azure DevOps"];
orgNameArray : any = ["LTICanvas"];
projNameArray : any = ["Canvas"];
meetingNameArray : any = []
meetingNameValue : any;
teamNameArray : any = []
spaceNameArray : any = []
existingArtifacts : boolean = false
  myDate :any;
  customDate : any;
  dropdownList = [];
  selectedItems = [];
  // dropdownSettings = {};
  dropdownSettings:IDropdownSettings;
  meetingArtifacts: boolean = false;
  showMenu: boolean = true;
  repoVendorValue: any = "Azure DevOps";
  artifactTable: any =[];
  artifactForm: boolean = false;
  spaces: boolean = false;
  meetingDetail: any = "-";
  projectNameArray: any = [];
  startCalender: any;
  minimumEndDate: string;

  
  constructor(public dialog: MatDialog, private _formBuilder: FormBuilder,public alertService: AlertService,private spinner: NgxSpinnerService,private SpinnerService: NgxSpinnerService ,private apiService: AuthService, private _snackBar: MatSnackBar, public router: Router,private http : HttpClient,
    private fb: FormBuilder,public datepipe: DatePipe) { 
     
    }
  extraChannel = new ExtraChannel('')

  channelTeam;
  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    // @author-Priyanka Kale - getting catalogId,catalogName from localstorage ,so that data should not get cleared on page refresh
    this.daysofWeeks = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    this.apiService.selectedRecurrsiveMeetingMember.next([]); 

    this.myDate =new Date();
    this.myDate = this.datepipe.transform(this.myDate, 'yyyy-MM-ddThh:mm');
    this.customDate = this.datepipe.transform(this.myDate, 'yyyy-MM-dd');

    this.dtOptions = {
      searching: false,
      paging:false,
       ordering: true,
       info:false
      };

//console.log(this.myDate)
   
    this.catalog_id=localStorage.getItem('catalogId');
    
    this.catalog_name=localStorage.getItem('catalogName');
    

    
      this.channelTeam = new ChannelTeam('Cast')
      this.channelArrayData.push(this.channelTeam)
      this.channelTeam = new ChannelTeam('Developer')
      this.channelArrayData.push(this.channelTeam)
      this.channelTeam = new ChannelTeam('SP-PO-PM')
      this.channelArrayData.push(this.channelTeam)
      this.channelTeam = new ChannelTeam('Jenkins')
      this.channelArrayData.push(this.channelTeam)
      this.channelTeam = new ChannelTeam('Jira')
      this.channelArrayData.push(this.channelTeam)
   
    // @author-Priyanka Kale - getting projectId,ProjectName from localstorage ,so that data should not get cleared on page refresh

   
    this.projectId=localStorage.getItem('projectId');

   
    this.getExistingArtifacts()
    this.projectName=localStorage.getItem('projectName');

    this.getProjects();

    //Get Tool Data
    this.apiService.toolSelectedFromDialog.subscribe(data => {
      this.toolName = data
    })
    this.channelArray.push(this.extraChannel)

    // this.GetAccessTokenDetails();
   // const accessCode = localStorage.getItem("accessCode");
    
    // if(accessCode != "undefined")
    // {
    //   const access_token = localStorage.getItem("webexToken");
    //  // console.log("access_token",access_token);
    //   if(access_token == null)
    //   {
    //     this.GetAccessTokenDetails(accessCode).then(res=>{
    //        this.access_token=res;
    //       // console.log(res,"token")
    //       localStorage.setItem("webexToken", res);
  
    //        this.apiService.webexToken.next(res)
    //     });
    //   }
    //   else
    //   {
    //     localStorage.setItem("webexToken", access_token);
  
    //     this.apiService.webexToken.next(access_token)
    //   }
    // }
// this.apiService.webexToken.subscribe(response=>{
//   this.webexToken = response
// })

//this.webexToken = localStorage.getItem("webexToken")

  // this.getExistingSpaces()
  

  this.secondFormGroup = this._formBuilder.group({
    startTime: ['', Validators.required],
    endTime: ['', Validators.required],
   
    dailyNoOfDays : ['5',Validators.required],
    weeklyNoOfDays : ['2', Validators.required],

    monthlyDayDate : ['15', Validators.required],
    monthlyDayMonthCount : ['2', Validators.required],

    monthlyTheWeekNo : [{value: '2', disabled: true} ,Validators.required],
    monthlyTheDay : [{value: 'Monday', disabled: true}, Validators.required],
    monthlyTheMonthCount : [{value: '2', disabled: true}, Validators.required],

    yearlyNoOfYears : ['1', Validators.required],

    yearlyOnMonth : ['5', Validators.required],
    yearlyOnDate : ['20', Validators.required],

    yearlyOnTheWeekNo : [{value: '5', disabled: true}, Validators.required],
    yearlyOnTheDay : [{value: 'Day', disabled: true}, Validators.required],
    yearlyOnTheMonth : [{value: '7', disabled: true}, Validators.required],

    endByCalendar : ['', Validators.required],
    endAfterCount : [{value: '2', disabled: true}, Validators.required],

    startByCalendar : ['', Validators.required],
  });

  this.firstFormGroup = this._formBuilder.group({
    meetingName: ['', Validators.required],
    repoVendor: ['', Validators.required], 
    orgName : [''],
    teamName : ['', Validators.required],
    roomName : ['', Validators.required],
    reportTime : ['1', Validators.required],
    projName : ['' ,Validators.required],
    reportFrom : [''],
    reportTo : [''],
    reportType : ['', Validators.required],
    scheduleTime : ['00:00'],
  });

// this.getExistingSpaces()
let projectlistemail = {
  "user_mail" : localStorage.getItem('mail')
  // "user_mail" : localStorage.getItem('mail').toLowerCase()

}
this.apiService.getProjectListForWebex(projectlistemail).subscribe((response:any)=>{
 // console.log(response)
  response.forEach(res=>{
    let body = {
    project_name: res }
    this.dropdownList.push(body)
  })
}, (error: HttpErrorResponse) => {
  if (error.status == 438){
  this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){}
  else {
  this.alertService.error("No data available.", this.options);
  throw error;
   }
  
   })

this.selectedItems = [
];
// console.log(this.selectedItems)

this.dropdownSettings = {
  singleSelection: false,
  idField: 'project_name',
  textField: 'project_name',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  itemsShowLimit: 3,
  allowSearchFilter: true

};
}
else{
  this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
    this.router.navigate(['/admin-catalog-config']);
});
}
  }


  showwebexMeetingInfo() {
    const dialogRef = this.dialog.open(DialogwebexMeetingInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });
  }

  getTeamName(){
    // let teamBody = {
    //   "token":this.webexToken
    // }
  
    this.apiService.getTeamNames().subscribe(result=>{
   //   console.log(result)
  this.teamNameArray = result
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data.", this.options);
      throw error;
       }
      
       })
  }
  showArtifactForm(){
    this.artifactForm = true
  }
  openOptions(){
    this.showMenu = true;
    this.meetingArtifacts = false;
    this.showForm = false;
    this.existingSpaces = false;
    this.setNewMeeting = false;
    this.spaces = false
  }
  
  getMeetingName(){
    let bodyForMeeting = {
      "projectName": localStorage.getItem('projectName')
    }
    this.apiService.getMeetingName(bodyForMeeting).subscribe(result=>{
  //console.log(result)
  this.meetingNameArray = result
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

  getSpaceNameForArti(){
    this.spaceNameArray = []
    let spaceBody = {
      "teamId":this.teamName.value
     // "token": this.webexToken
    }
  
    this.apiService.getSpaceNamesforArtifacts(spaceBody).subscribe(result=>{
      this.spaceNameArray = result
        }, (error: HttpErrorResponse) => {
          if (error.status == 438){
          this.alertService.error(error.error.message, this.options);
           }
           else if (error.status == 403){}
          else {
          this.alertService.error("Unable to fetch data.", this.options);
          throw error;
           }
          
           })
  }

  getRepoVendorValue(){
    this.projNameArray = []
    this.repoVendorValue = this.repoVendor.value
    if(this.repoVendorValue == 'Azure DevOps'){
      this.projNameArray = ["Canvas"]
    }
    else{
      this.apiService.getJiraProjectForArtifacts().subscribe(response =>{
        this.projNameArray = response
      }, (error: HttpErrorResponse) => {
        if (error.status == 438){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         })

    }
  }

  onItemSelect(item: any) {
    this.projectNameArray.push(item.project_name)
    // console.log(item);
  }
  onSelectAll(items: any) {
    // console.log(items);
  }

  getExistingArtifacts(){
    this.apiService.getArtifacts(this.projectId).subscribe((result:any) =>{
    //  console.log(result)
      if(result.length){
        this.existingArtifacts = true
this.artifactTable = result
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

  getExistingSpaces(){
    this.showMenu = false;
    this.spaceTable = []
    this.spinner.show();
    this.apiService.getSpaces(this.projectName).subscribe((data : any)=>{
    // console.log(data)
      if(data.length == 0){
        this.existingSpaces = false
      }else{
        this.existingSpaces = true;
this.spaceTable = data;
      }
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })

  }

  GetAccessTokenDetails(code) {
    //console.log("GetAccessTokenDetails inside mschannel",code);
    var access_token= this.GetAccessToken(code);
    //console.log("access_token:",access_token);
    return access_token;
  }
  
  async GetAccessToken(code) {
    var access_token;
    const client_id=environment.webex_clientId;
   // const client_secert=environment.webex_client_secret;
    const client_redirectURI = environment.webex_redirect_uri;
    const url =environment.webex_url;
    const config = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    const body = new HttpParams().set('grant_type','authorization_code')
    .set('client_id',client_id)
   // .set('client_secret',client_secert)
    .set('code',code).set('redirect_uri',client_redirectURI);
     var data = await this.http.post(url, body.toString(),config).toPromise();
     access_token =data["access_token"];
     return access_token;
  }

  cancel(){
    this.showForm = false
    // this.showMenu = true;
    this.existingSpaces = true

  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe(result => {
    });
  }
 
  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '22%',
      height: '13%',
      disableClose: true,

    });
  }

  openUserForSpace(spaceName,spaceMember,spaceId,option){

//console.log(spaceMember)
const dialogRef = this.dialog.open(MembersSpaceDialog, {
  width: '50%',
  height: '52%',
  disableClose: true,
  data:{
      member: spaceMember,
      id : spaceId,
      operation : option
    }
});
this.apiService.selectedSpace.next(spaceName)

dialogRef.afterClosed().subscribe(result => {
 //console.log(result)

  // this.membersForMeeting = result
  // this.apiService.selectedRecurrsiveMeetingMember.next(this.membersForMeeting); 
  // this.membersForMeeting.forEach(member=>{
  //   let obj = { 
  //     "email": member.user_email, 
  //     "displayName": member.user_name, 
  //     "coHost": false }
  //   this.membersArrayToSend.push(obj)
  // })

  if(result == "Refresh"){
 this.getExistingSpaces()

  // this.showMenu = true
  }

});
  }

  deleteSpace(spaceId){
    // console.log(spaceId,"SpaceIdToDelete")
    const dialogRef = this.dialog.open(DeleteMembersSpace, {
      width: '35%',
      height: '37%',
      disableClose: true,
      data:{
        spaceId: spaceId
        }
    });
    
  this.apiService.selectedSpace.next(spaceId)
  
  dialogRef.afterClosed().subscribe(result => {
    //console.log("result", result);
    if(result == "yes"){
      //let token = "";
    // this.apiService.webexToken.subscribe(response=>{
    //   token= response;
    // })
    this.apiService.deleteSpace(spaceId).subscribe(response=>{

    if(response == "Space Deleted"){
    this.alertService.success('Space Deleted Successfully', this.options)
    this.getExistingSpaces()
  
    }
    else {
     // this.alertService.error(response, this.options);
    this.getExistingSpaces()

    }
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Error while deleting space.", this.options);
      throw error;
       }
      
       })
    }

   });
   
    
      }

  artifactsValue(){
let body = { 
  "projectId":this.projectId,
  "MeetingName" : this.meetingName.value,
   "ReportVendor" : this.repoVendor.value,
        "ReportType" : this.reportType.value,
        "TriggerEventBefore" :this.reportTime.value,
        "TeamID" :this.teamName.value,
        "SpaceName" : this.roomName.value,
        "ProjectName":this.projName.value,
        "OrganizationName":this.orgName.value
       // "token": this.webexToken
}
//console.log(body)

this.apiService.scheduleMeetingArtifact(body).subscribe((response:any)=>{
  if(response.Status == "Success"){
    this.alertService.success('Success', this.options)
    this.firstFormGroup.reset()  
    this.existingArtifacts = true
    this.artifactForm = false
    this.artifactTable.push(response.Data)  
    }
    else {
    //  this.alertService.error("Error setting Details", this.options);
      this.existingArtifacts = true
    this.artifactForm = false
  }
}, (error: HttpErrorResponse) => {
  if (error.status == 438){
  this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){}
  else {
  this.alertService.error("No data available.", this.options);
  throw error;
   }
  
   })
  }

  clearArtifacts(){
    
    this.artifactForm = false
  }

  onSelect(index){
    // console.log(index);
  this.selectedCard = [false, false, false, false,false];

    this.selectedCard[index] = !this.selectedCard[index];
  }

  viewUserForSpace(spaceId,spaceName,spaceMember){
    this.apiService.getMembersForSpace(spaceId).subscribe(response=>{
      // console.log(response,"memberApi")
      const dialogRef = this.dialog.open(ViewMembersSpace, {
        width: '50%',
        height: '52%',
        disableClose: true,
        data:{
            member: response
          }
      });
      
    this.apiService.selectedSpace.next(spaceName)
    
    dialogRef.afterClosed().subscribe(result => {
  // this.getExistingSpaces()

    });
    }, (error: HttpErrorResponse) => {
      if (error.status == 438){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
   
    
  }

      addDeleteTools(name,member,id,option){

        const dialogRef = this.dialog.open(AddDeleteToolsDialog, {
          width: '50%',
          height: '52%',
          disableClose: true,
          data:{
              member: member,
              id : id,
              operation : option
            }
        });
        this.apiService.selectedSpace.next(name)
        
        dialogRef.afterClosed().subscribe(result => {
       //   console.log(result)
          if(result == "Refresh"){
          this.getExistingSpaces()
        
          }
        
        });

      }

     
      public dataSaved = false;
  
      public closeWindow() {
        this.openWindow = false;
      }
      public submit() {
          this.dataSaved = true;
          this.closeWindow();
      }

  public getProjects() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.apiService.projectName.next(project[0].project_name);
      this.members = project[0].members;
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  } 
  enableEdit(){
    //this.inputDisabled = false;
    this.channelTeam = new ChannelTeam('')
    this.channelArrayData.push(this.channelTeam)

  }

  removeChannelName(index){
    this.channelArrayData.splice(index,1);
  }

  showSpace(){
    // this.showForm = true
    this.spaces = true
    this.getExistingSpaces()
    this.showMenu = false
  }
  showSpaceForm(){
    this.showForm = true
  }

  createSpaces() {
    this.spinner.show();

this.showForm = false
  this.channelArrayData.forEach(element => {
    this.channelName.push(element.channelName)
   
  });
//   let token = ""
//   this.apiService.webexToken.subscribe(response=>{
// token= response;
// })
var bodydata=
{
//"projectId":this.projectId,
"projectName":this.projectName,
"channelList":this.channelName
//"token": token,
};

this.apiService.createWebexTeams(bodydata).subscribe((data:any)=>{
if(data.length > 0){
  data.forEach(res=>{
   // console.log(res)
    this.spaceTable.push(res)
  })
this.existingSpaces = true;

}
this.spinner.hide();

}, (error: HttpErrorResponse) => {
  if (error.status == 438){
  this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){}
  else {
  this.alertService.error("Error while creating Webex.", this.options);
  throw error;
   }
  
   })
 
 
  }

 

  setMeeting(){
    this.setNewMeeting = true;
    this.existingSpaces = false;
    this.showForm = false
this.meetingArtifacts = false
this.showMenu = false

  }

  setMeetingArtifacts(){
this.meetingArtifacts = true;
this.setNewMeeting = false;
this.existingSpaces = false;
this.showForm = false
this.showMenu = false

  }

  onChange(value) {
// console.log(value)
    this.model.body = value.body;
    this.model.subject = value.subject;
    this.schedule = value.schedule;
  }

  onChangeDrop() {

    for (var i = 0; i < this.attendeesDetails.length; i++) {
      if (this.attendeesDetails[i].identity.uniqueName.length > 0) {
        this.member.push(this.attendeesDetails[i].identity.uniqueName);
      }

      this.model.attendees = this.member.join(";");
     // console.log("After Split", this.model.attendees);
    }
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  get startTime() {
    return this.secondFormGroup.get('startTime');
  }

  get endTime() {
    return this.secondFormGroup.get('endTime');
  }


   get dailyNoOfDays() {
    return this.secondFormGroup.get('dailyNoOfDays');
  } 

  get weeklyNoOfDays() {
    return this.secondFormGroup.get('weeklyNoOfDays');
  } 
  
  get monthlyDayDate() {
    return this.secondFormGroup.get('monthlyDayDate');
  }

  get monthlyDayMonthCount() {
    return this.secondFormGroup.get('monthlyDayMonthCount');
  }

  get monthlyTheWeekNo() {
    return this.secondFormGroup.get('monthlyTheWeekNo');
  }

  get monthlyTheDay() {
    return this.secondFormGroup.get('monthlyTheDay');
  }

  get monthlyTheMonthCount() {
    return this.secondFormGroup.get('monthlyTheMonthCount');
  }

  get yearlyNoOfYears() {
    return this.secondFormGroup.get('yearlyNoOfYears');
  }

  get yearlyOnMonth() {
    return this.secondFormGroup.get('yearlyOnMonth');
  }

  get yearlyOnDate() {
    return this.secondFormGroup.get('yearlyOnDate');
  }

  get yearlyOnTheWeekNo() {
    return this.secondFormGroup.get('yearlyOnTheWeekNo');
  }

  get yearlyOnTheDay() {
    return this.secondFormGroup.get('yearlyOnTheDay');
  }

  get yearlyOnTheMonth() {
    return this.secondFormGroup.get('yearlyOnTheMonth');
  }

  get endByCalendar() {
    return this.secondFormGroup.get('endByCalendar');
  }

  get endAfterCount() {
    return this.secondFormGroup.get('endAfterCount');
  }

  get startByCalendar() {
    return this.secondFormGroup.get('startByCalendar');
  }

  getMinValue(){
    this.startCalender = this.datepipe.transform(this.startByCalendar.value, 'yyyy-MM-dd');
  }

  getMinimumEndDate(){
    this.minimumEndDate = this.datepipe.transform(this.model.start, 'yyyy-MM-ddThh:mm');
  }
  get scheduleTime() {
    return this.firstFormGroup.get('scheduleTime');
  }

  get reportTime() {
    return this.firstFormGroup.get('reportTime');
  }

  get reportType() {
    return this.firstFormGroup.get('reportType');
  }

  get reportFrom() {
    return this.firstFormGroup.get('reportFrom');
  }

  get reportTo() {
    return this.firstFormGroup.get('reportTo');
  }

  get roomName() {
    return this.firstFormGroup.get('roomName');
  }
  get teamName() {
    return this.firstFormGroup.get('teamName');
  }
  get orgName() {
    return this.firstFormGroup.get('orgName');
  } 
  get repoVendor() {
    return this.firstFormGroup.get('repoVendor');
  }
  get meetingName() {
    return this.firstFormGroup.get('meetingName');
  }
  get projName() {
    return this.firstFormGroup.get('projName');
  }
  endRadio(value){
   this.endRadioValue = value
    if(this.endRadioValue == 'endBy'){
      this.secondFormGroup.get('endByCalendar').enable();
      this.secondFormGroup.get('endAfterCount').disable()
    } 
    else if(this.endRadioValue == 'noEnd'){
      this.secondFormGroup.get('endByCalendar').disable();
      this.secondFormGroup.get('endAfterCount').disable()
    }
   else if(this.endRadioValue =='endAfter'){
      this.secondFormGroup.get('endByCalendar').disable();
      this.secondFormGroup.get('endAfterCount').enable()
    }
    
  }

  recurrenceRadioDaily(value){
    // this.recurrencePatternRadio = "Daily"
   // console.log(value)
    this.recurrenceDailyValue = value
    if(value == "Every"){
      this.secondFormGroup.get('dailyNoOfDays').enable();
  }else if(value == "Every Weekday"){
    this.secondFormGroup.get('dailyNoOfDays').disable();
  }
   
  }

  recurrenceRadioMonthly(value){
    // this.recurrencePatternRadio = "Monthly"
   // console.log(value)
this.recurrenceMonthlyValue = value
    if(value == "Day"){
this.secondFormGroup.get('monthlyDayDate').enable();
this.secondFormGroup.get('monthlyDayMonthCount').enable();

this.secondFormGroup.get('monthlyTheWeekNo').disable();
this.secondFormGroup.get('monthlyTheDay').disable();
this.secondFormGroup.get('monthlyTheMonthCount').disable();
    }else if(value == "The"){
      this.secondFormGroup.get('monthlyTheWeekNo').enable();
      this.secondFormGroup.get('monthlyTheDay').enable();
      this.secondFormGroup.get('monthlyTheMonthCount').enable();

      this.secondFormGroup.get('monthlyDayDate').disable();
       this.secondFormGroup.get('monthlyDayMonthCount').disable();
    }
  }

  recurrenceRadioYearly(value){
    // this.recurrencePatternRadio = "Yearly"
   // console.log(value)

    this.recurrenceYearlyValue = value
if(value == "On"){

this.secondFormGroup.get('yearlyOnMonth').enable();
this.secondFormGroup.get('yearlyOnDate').enable();

this.secondFormGroup.get('yearlyOnTheWeekNo').disable();
this.secondFormGroup.get('yearlyOnTheDay').disable();
this.secondFormGroup.get('yearlyOnTheMonth').disable();
}else if(value == "On The"){

this.secondFormGroup.get('yearlyOnTheWeekNo').enable();
this.secondFormGroup.get('yearlyOnTheDay').enable();
this.secondFormGroup.get('yearlyOnTheMonth').enable();

this.secondFormGroup.get('yearlyOnMonth').disable();
 this.secondFormGroup.get('yearlyOnDate').disable();
}
  }

  formvalue(){
 // console.log(this.model)
  this.meetingTitle = this.model.subject
  this.meetingAgenda = this.model.body

    // this.apiService.webexToken.subscribe(response=>{
    //   this.webexToken = response
    // })
    //console.log(this.webexToken)
    let endDate = "";
    let recurrence = "";
    if(this.endRadioValue == 'endBy'){
      endDate = this.endByCalendar.value
      recurrence = "0"
    }else if(this.endRadioValue == 'endAfter'){
      endDate = ""
      recurrence = this.endAfterCount.value
    }else if(this.endRadioValue == "noEnd"){
      endDate = ""
      recurrence = "0"
    }
   
    if(this.recurrencePatternRadio == "Weekly"){
         this.responseBody =  {
        "title": this.meetingTitle,
        "agenda": this.meetingAgenda,
       // "invitees": this.membersArrayToSend ,
        "startDate": this.startByCalendar.value,
        "endDate": endDate,
        "startTime":this.startTime.value,
        "endTime":this.endTime.value,
        // "siteUrl": "ltil.webex.com",
        "meetingType":"WEEKLY",
        "recurrence":recurrence,
        "meetingOption":"1",
        "weekDays":this.selectedDays,
"projectName":this.projectNameArray,
"weekCount":this.weeklyNoOfDays.value
        //"token":this.webexToken    
     }
    }

    if(this.recurrencePatternRadio == "Daily"){    
    //  console.log("inside daily",this.recurrenceDailyValue) 
      if (this.recurrenceDailyValue == "Every"){
        this.responseBody = {
          "title": this.meetingTitle,
        "agenda": this.meetingAgenda,
       // "invitees": this.membersArrayToSend ,
          "startDate": this.startByCalendar.value,
          "endDate": endDate,
          "startTime":this.startTime.value,
          "endTime":this.endTime.value,
          //"hostEmail": localStorage.getItem('mail').toLowerCase(),
          //"siteUrl": "ltil.webex.com",
          "meetingType":"DAILY",
          "recurrence":recurrence,
          "meetingOption":"1",
"projectName":this.projectNameArray,
"dayCount":this.dailyNoOfDays.value
          //"token":this.webexToken
      
       }
      }
      if (this.recurrenceDailyValue == "Every Weekday"){
this.responseBody = {
  "title": this.meetingTitle,
  "agenda": this.meetingAgenda,
 // "invitees": this.membersArrayToSend ,
"startDate": this.startByCalendar.value,
"endDate": endDate,
"startTime":this.startTime.value,
"endTime":this.endTime.value,
//"hostEmail":localStorage.getItem('mail').toLowerCase(),
//"siteUrl": "ltil.webex.com",
"meetingType":"DAILY",
"recurrence":recurrence,
"projectName":this.projectNameArray,
"meetingOption":"2"
//"token":this.webexToken

}
//console.log(this.responseBody)

      }
    }

    if(this.recurrencePatternRadio == "Monthly"){
       if(this.recurrenceMonthlyValue == "Day"){   
this.responseBody = {
  "title": this.meetingTitle,
  "agenda": this.meetingAgenda,
  //"invitees": this.membersArrayToSend ,
"startDate": this.startByCalendar.value,
"endDate": endDate,
"startTime":this.startTime.value,
"endTime":this.endTime.value,
//"hostEmail": localStorage.getItem('mail').toLowerCase(),
//"siteUrl": "ltil.webex.com",
"meetingType":"MONTHLY",
"recurrence":recurrence,
"projectName":this.projectNameArray,
"meetingOption":"1",
"monthDay":this.monthlyDayDate.value,
"monthCount":this.monthlyDayMonthCount.value
//"token":this.webexToken
}

      }
      if(this.recurrenceMonthlyValue == "The"){
this.responseBody = {
  "title": this.meetingTitle,
  "agenda": this.meetingAgenda,
  //"invitees": this.membersArrayToSend ,
"startDate": this.startByCalendar.value,
"endDate": endDate,
"startTime":this.startTime.value,
"endTime":this.endTime.value,
//"hostEmail": localStorage.getItem('mail').toLowerCase(),
//"siteUrl": "ltil.webex.com",
"meetingType":"MONTHLY",
"recurrence":recurrence,
"projectName":this.projectNameArray,
"meetingOption":"2",
"dayPosition":this.monthlyTheWeekNo.value,
"weekDays":this.monthlyTheDay.value,
"monthCount":this.monthlyTheMonthCount.value
//"token":this.webexToken
}

      }
    }

    if(this.recurrencePatternRadio == "Yearly"){
      if(this.recurrenceYearlyValue == "On"){
        this.responseBody =  {
          "title": this.meetingTitle,
          "agenda": this.meetingAgenda,
         // "invitees": this.membersArrayToSend ,
          "startDate": this.startByCalendar.value,
          "endDate": endDate,
          "startTime":this.startTime.value,
          "endTime":this.endTime.value,
          //"hostEmail": localStorage.getItem('mail').toLowerCase(),
          //"siteUrl": "ltil.webex.com",
          "meetingType":"YEARLY",
          "recurrence":recurrence,
          "meetingOption":"1",
          "monthPosition":this.yearlyOnDate.value,
          "Month":this.yearlyOnMonth.value,
"projectName":this.projectNameArray,
"yearCount":this.yearlyNoOfYears.value
          //"token":this.webexToken
         }
      }
      if(this.recurrenceYearlyValue == "On The"){
       this.responseBody = {
        "title": this.meetingTitle,
        "agenda": this.meetingAgenda,
       // "invitees": this.membersArrayToSend ,
          "startDate": this.startByCalendar.value,
          "endDate": endDate,
          "startTime":this.startTime.value,
          "endTime":this.endTime.value,
         // "hostEmail": localStorage.getItem('mail').toLowerCase(),
          //"siteUrl": "ltil.webex.com",
          "meetingType":"YEARLY",
          "recurrence":recurrence,
          "meetingOption":"2",
          "Month":this.yearlyOnTheMonth.value,
          "yearPosition":this.yearlyOnTheWeekNo.value,
          "weekDays":this.yearlyOnTheDay.value,
"projectName":this.projectNameArray,
"yearCount":this.yearlyNoOfYears.value
          //"token":this.webexToken
        }
      }
    }
   
   //   console.log(this.responseBody)
      this.apiService.setWebexMeeting(this.responseBody).subscribe(response=>{
   //     console.log(response)
        if(response){
         this.handleClear();
         this.secondFormGroup.reset()
         this.alertService.success("Meeting set successfully",this.options)
         this.setNewMeeting = false;
   this.existingSpaces = false;
       }
      }, (error: HttpErrorResponse) => {
        if (error.status == 438){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("Unable to set meeting.", this.options);
        throw error;
         }
        
         })
    
  
   
  
  } 

   radiovalue(value){
     this.recurrencePatternRadio = value
   }
  
   onCheckboxChange(value) {
    let index = this.selectedDays.indexOf(value);

    if (index > -1) {
      this.selectedDays.splice(index, 1);
    }
    else {
      this.selectedDays.push(value);
    }
  }
 
  handleClear() {
    this.model.subject = '';
    this.model.attendees = '';
    this.model.start = '';
    this.model.end = ' ';
    this.model.body = '';
    this.model.daysOfWeek = '';
    this.model.startDate = '';
    this.model.endDate = '';

    this.setNewMeeting = false;
    this.existingSpaces = false;
    this.showMenu = true

  }
  removeUser(user, index) {
    this.membersForMeeting.splice(index, 1)
  }

  clearForm(){
    this.secondFormGroup.reset()

  }

  meetingNameBody(meetingNameBody){
  //  console.log(meetingNameBody)
    this.meetingNameArray.forEach(meeting=>{
      if(meeting.MeetingName == meetingNameBody){
        localStorage.setItem("MeetingName",meetingNameBody);
        this.meetingDetail = meeting.MeetingType
      }
    })
  }
  schedulerTimeUTC :any  =[];

  // @ViewChild('scheduleTime') schedulerTime;

  executeScheduler(){
   // console.log(this.scheduleTime.value)
    this.schedulerTimeUTC = this.scheduleTime.value.split(':');
    var schedulerTimeHourUTC = new Date();
    schedulerTimeHourUTC.setHours(this.schedulerTimeUTC[0], this.schedulerTimeUTC[1]).toLocaleString();
    let body ={
        "MeetingArtifactsScheduleTime":schedulerTimeHourUTC.getUTCHours() + ":" + schedulerTimeHourUTC.getUTCMinutes()
    }
    this.scheduleTime.setValue("")

    this.apiService.executeScheduler(body).subscribe((result:any)=>{
    //  console.log(result)
if(result.Status == "Success"){
  this.alertService.success("Scheduler started successfully",this.options)
}else{
  this.alertService.error("Unable to execute scheduler ",this.options)
}
      this.artifactForm = true
    })
  }
  getSelectedType(){
  this.apiService.selectedRecurrsiveMeetingMember.next([]); 
  this.membersForMeeting = []

  }

  onSubmit(): void {
let meetingType = "";
    if (this.selectedDays.length < 0 || this.selectedDays.length == 0 || (!this.show)) {
  
      if(this.selectedType == 'Once'){
        // let token = "";
        // this.apiService.webexToken.subscribe(response=>{
        //   token= response;
        // })

       // console.log(this.schedule)

        if(this.schedule == "Daily"){
meetingType = "Once"
        }else{
// meetingType = "Fortnightly"
meetingType = "FORTHNIGHTLY"
        }
          let responseBody = {
            "title": this.model.subject,
            "agenda": this.model.body,
            "start":this.model.start,
            "end": this.model.end,
            //"hostEmail": localStorage.getItem('mail').toLowerCase(),
           // "siteUrl": "ltil.webex.com",
            "meetingType":meetingType,
"projectName":this.projectNameArray
//"token":token,
           // "invitees": this.membersArrayToSend
         }
          // console.log("response for Once",responseBody)
          this.apiService.setWebexMeeting(responseBody).subscribe(response=>{
           // console.log(response)
            if(response){
              this.handleClear();
              this.secondFormGroup.reset()
              this.alertService.success("Meeting set successfully",this.options)
              this.setNewMeeting = false;
    this.existingSpaces = false;
    this.showMenu = true

            }
          })
        

      }
    }
    else {
    //  debugger;
      const testObject = this.model.getRecurringGraphEvent();
      let sTime = new Date(testObject.start['dateTime']);
      let eTime = new Date(testObject.end['dateTime']);
      testObject.start['dateTime'] = sTime.getHours() + ":" + sTime.getMinutes() + ":00";
      testObject.end['dateTime'] = eTime.getHours() + ":" + eTime.getMinutes() + ":00";
      testObject.recurrence.pattern.type='weekly';
      testObject.recurrence.pattern.interval = testObject.recurrence.pattern.daysOfWeek.length;
      try {
        const recurringEvent = JSON.stringify(testObject);
      }
      catch {
        
      }
      
    //  console.log(recurringEvent);
    //  debugger;
      // this.spinner.show();
     
    }

  }

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }
  
}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
}
const ELEMENT_DATAA: MemberDetails[]=[];
@Component({
  selector: 'members-space',
  templateUrl: 'members-space.html',
  styleUrls: ['./mschannel-creations.component.scss']
})

export class MembersSpaceDialog {
  memberList: any = [];
  selectedRole: any;
  projectId : any;
  spaceId : any;
  projectName : any;
  option = "";
  finalArray : any = [];
  members: any = [];
 // ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'select'];
 dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATAA);
  selection = new SelectionModel<MemberDetails>(true, []);
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  existingMeetingMembers: any;
  isallSelected: boolean;

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  // return numSelected 
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
     this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }

  constructor(public apiService : AuthService, public alertService: AlertService,private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MembersSpaceDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.memberList = data.member
      this.spaceId = data.id
      this.option = data.operation

     }

     ngOnInit(){
       this.userBody = {}
       //console.log(this.userBody)
      // @author-Priyanka Kale - getting projectId,ProjectName from localstorage ,so that data should not get cleared on page refresh

    // this.apiService.projectKey.subscribe(projectId => {
      //   this.projectId = projectId;
      // })
    this.projectId=localStorage.getItem('projectId');
  
      // this.apiService.projectName.subscribe(projectName => {
      //   this.projectName = projectName;
      // })
    this.projectName=localStorage.getItem('projectName');

      this.apiService.selectedRecurrsiveMeetingMember.subscribe((members:any)=>{
        this.existingMeetingMembers = members
      }) 
      // this.apiService.projectName.subscribe(projectName => {
      //   this.projectName = projectName;
      // })
      this.projectName=localStorage.getItem('projectName');
  if(this.option == "Add"){
    this.getUser();

  }else if (this.option=="Delete"){
    //console.log(this.memberList)
    this.dataSource.data = this.memberList as MemberDetails[]

  }
 
     }

     allSelected() {
      this.isallSelected = true
    }
     public getUser() {
      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        //console.log(project)
        this.members = project[0].members;
        // console.log(this.members)
        // console.log(this.memberList)
       this.members.forEach((element,index) => { //alll members 2
         for(var i=0; i<this.memberList.length;i++){
           if(this.memberList[i].member_email.toLowerCase() == element.user_email.toLowerCase() ){
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
           }
         }
         
       });
       this.members.forEach((object:any)=>{
        if(!object.duplicate){
          this.finalArray.push(object)
        }
      })
      //console.log(this.finalArray)
       this.dataSource.data = this.finalArray as MemberDetails[]
      }, (error: HttpErrorResponse) => {
        if (error.status == 432){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         })
    }

  addUserToMeeting(){

    if (this.isallSelected) {
      this.dialogRef.close(this.selection.selected);
    } else {
      if (this.selection.selected.length == 0) {
        this._snackBar.open("Select User", "", {
          duration: 3000,
        })
              } else {
                if (this.existingMeetingMembers.length == 0) {
                  this.dialogRef.close(this.selection.selected);
                } else {
                  this.existingMeetingMembers.forEach((member, indexi) => {
                    for(var i=0; i< this.selection.selected.length;i++){
                 if(member.user_email == this.selection.selected[i].user_email){
                      this.existingMeetingMembers[indexi]["duplicate"]= true
                      this.selection.selected[i]["duplicate"]= true
                      break;
                    }
                  }
                })
                let concatArray = this.selection.selected.concat(this.existingMeetingMembers);
                let finalArray = []
                concatArray.forEach((object:any)=>{
                  if(!object.duplicate){
                    finalArray.push(object)
                  }
                })
                this.dialogRef.close(finalArray);
                this.apiService.selectedRoleMember.next([])
                }
              }
    }
    
      // this.dialogRef.close(this.selection.selected)
     
  }
userBody = {}
  addUserForSpace(){
    let selectedSpace = "";
    let memberArray = [];
    this.userBody = {}
// console.log("api call function")

this.apiService.selectedSpace.subscribe(Space=>{
  selectedSpace = Space;
})
// console.log(this.selection.selected)
this.selection.selected.forEach(member=>{
memberArray.push(member.user_email)
})
// let token = "";
// this.apiService.webexToken.subscribe(response=>{
//   token= response;
// })

   this.userBody = {"channelList" : this.spaceId,
 "membersList" :memberArray,
"project_name":this.projectName}
//console.log(this.userBody,"memberApiBody")
this.apiService.addMemberForSpace(this.userBody).subscribe(response=>{
 // console.log("Member Response",response)
  if(response == "Success"){
    this.alertService.success('Member Added Successfully', this.options)
    this.dialogRef.close("Refresh")
    
  }
  else {
    this.alertService.error('Error adding Member', this.options);
 this.dialogRef.close("")

  }
  this.apiService.closeDialog.subscribe(res=>{
    if(res == "close"){this.dialogRef.close("")}
  })
  
}, (error: HttpErrorResponse) => {
  if (error.status == 438){
  this.alertService.error(error.error.message, this.options);
   }
   else if (error.status == 403){}
  else {
  this.alertService.error("Error while assigning member.", this.options);
  throw error;
   }
  
   })


  }

  deleteUserForSpace(){
//let token = "";
let body = {};
let deleteMemberArray = []
    // this.apiService.webexToken.subscribe(response=>{
    //   token= response
    // })
    this.selection.selected.forEach((member:any)=>{
      body = {
      "membership_id" :member.membership_id
    }
    deleteMemberArray.push(body)
    })
      if(deleteMemberArray.length > 0){
        this.apiService.deleteMemberForSpace(deleteMemberArray).subscribe(response=>{
       //   console.log("Member Response",response)
          if(response == "Member Deleted"){
            this.alertService.success('Member Deleted Successfully', this.options)
     this.dialogRef.close("Refresh")
            
          }
          else {
            //this.alertService.error('Error deleting Member', this.options);
     this.dialogRef.close("")
 
          }
          
        }, (error: HttpErrorResponse) => {
          if (error.status == 438){
          this.alertService.error(error.error.message, this.options);
           }
           else if (error.status == 403){}
          else {
          this.alertService.error("Error while deleting member.", this.options);
          throw error;
           }
          
           })
        this.apiService.closeDialog.subscribe(res=>{
          if(res == "close"){this.dialogRef.close("")}
        })
    //  this.dialogRef.close("")

      }
   
    
  }
  exit() {
    
    this.dialogRef.close("");
     
  }
}

@Component({
  selector: 'delete-member-space',
  templateUrl: 'delete-member-space.html',
  styleUrls: ['./mschannel-creations.component.scss']
})

export class DeleteMembersSpace {
  spaceId : any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  constructor(public apiService : AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<DeleteMembersSpace>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.spaceId = data.spaceId
     }
     ngOnInit(){
     }
     deleteYes(){
      this.dialogRef.close("yes")
     }

    closeDialog(): void {
      this.dialogRef.close();
    }

  exit() {
    this.dialogRef.close();
  }
 

}

@Component({
  selector: 'members-space',
  templateUrl: 'view-membersSpace.html',
  styleUrls: ['./mschannel-creations.component.scss']
})

export class ViewMembersSpace {
  memberList: any = [];
  selectedRole: any;
  projectId : any;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(public apiService : AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<ViewMembersSpace>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.memberList = data.member
     }

     ngOnInit(){
     }

  closeDialog(): void {
    this.dialogRef.close();
  }

  exit() {
    this.dialogRef.close();
  }
 

}

@Component({
  selector: 'add-delete-tools',
  templateUrl: 'add-delete-tools.html',
  styleUrls: ['./mschannel-creations.component.scss']
})

export class AddDeleteToolsDialog {
  memberList: any = [];
  selectedRole: any;
  projectId : any;
  spaceId : any;
  projectName : any;
  option = "";
  finalArray : any = [];
  members: any = [];
  displayedColumns: string[] = ['imageUrl', 'name','select'];
  dataSource = new MatTableDataSource<Tools>(ELEMENT_DATA);
  selection = new SelectionModel<Tools>(true, []);
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  ToolList = [
    {imageUrl: '../../../assets/img/gitlab-logo.png', name: 'Gitlab'},
    {imageUrl: '../../../assets/img/jira-logo.png', name: 'Jira'},
    {imageUrl: '../../../assets/img/jenkins-logo.png', name: 'Jenkins'},
  ];

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Tools): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }

  constructor(public apiService : AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<MembersSpaceDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      
      this.option = data.operation
     }

     ngOnInit(){
      this.apiService.projectKey.subscribe(projectId => {
        this.projectId = projectId;
      })
  
      this.apiService.projectName.subscribe(projectName => {
        this.projectName = projectName;
      })
  if(this.option == "Add"){

  }else if (this.option=="Delete"){
    

  }
     
     }
    

  closeDialog(): void {
    this.dialogRef.close("");
  }

  addTools(){


  }

  deleteTools(tool,index){

  }
  exit() {
    this.dialogRef.close("");
  }
}

@Component({
  selector: 'recurring-meeting',
  templateUrl: 'recurring-meeting.html',
  styleUrls: ['./mschannel-creations.component.scss']
})

export class RecurringMeeting {
 
     ngOnInit(){
     }
}

export interface Tools {
  imageUrl : string;
  name: string;
 
}

const ELEMENT_DATA: Tools[] = [
  {imageUrl: '../../../assets/img/gitlab-logo.png', name: 'Gitlab'},
  {imageUrl: '../../../assets/img/jira-logo.png', name: 'Jira'},
  {imageUrl: '../../../assets/img/jenkins-logo.png', name: 'Jenkins'},
];

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'webexmeeting-info.html',
  styleUrls: ['./mschannel-creations.component.scss']
})
export class DialogwebexMeetingInfoDialog {
  
  meetingNameArray: any = []
  projectPopName:any;
  meetingName:any;
  startDate:any;
  meetingInfoName:any;
  meetingInfoType:any;


  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(
    public dialogRef: MatDialogRef<DialogProjectInfoDialog>, public alertService: AlertService, private apiService: AuthService,public dialog: MatDialog
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  
    this.meetingName =localStorage.getItem('MeetingName');
    //console.log("this.meetingName",this.meetingName);
    this.apiService.getMeetingInfoName(this.meetingName).subscribe(result=>{
     // console.log(result)
      this.meetingNameArray = result
      this.startDate=this.meetingNameArray[0].StartDate;
      this.meetingInfoName=this.meetingNameArray[0].MeetingName;
      this.meetingInfoType=this.meetingNameArray[0].MeetingType;
      this.projectPopName=this.meetingNameArray[0].ProjectName;
        }, (error: HttpErrorResponse) => {
          if (error.status == 438){
          this.alertService.error(error.error.message, this.options);
           }
           else if (error.status == 403){}
          else {
          this.alertService.error("No data available.", this.options);
          throw error;
           }
          
           })
   
}



exit() {
  this.dialogRef.close();
}

}