<!-- New code-->

<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
      [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
             
              <li class="breadcrumb-item active" aria-current="page">Webex Properties</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 col-md-5 text-right pt-3">
          <div class="table-options">
            <span (click)="addWebX()"><i class="fas fa-plus"></i> Add</span>
            <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

            <span (click)="refreshWebX()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <a class="float-right step-back" (click)="backNavigation()">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
           
          </div>
        </div>
        <!-- <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div> -->
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/VDI-Icon-White.svg">
          <div class="vertical-line mx-3"> </div> <span>Webex Properties</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
        <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                <div class="modal-header-popup">
                  <span  class="vertical-line mx-3"> </span>
                   <span class="header mb-0">Webex Properties</span> 
                  <span  data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                  Are you sure want to Delete?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                </div>
              </div>
            </div>
          </div>
      <div *ngIf="showVdiForm" class="git-Lab-Form">
        <h2 *ngIf="!editMode">New WebX Instance</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
        <form (ngSubmit)="toolsGroupForm.form.valid && onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">
          
            <div class="form-group col-md-4">
              <label for="clientname"> Client Name <span class="required">&#42;</span></label>
              <input id="clientname" type="text" autocomplete="off" name="clientname" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.clientname" #clientname="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="clientname.invalid && (clientname.dirty || clientname.touched)"
                class="alert alert-danger">
                <div *ngIf="clientname.errors.required">
                  Client Name is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="client_id"> Client Id <span class="required">&#42;</span></label>
              <input id="client_id" type="text" autocomplete="off" name="client_id" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.client_id" #client_id="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="client_id.invalid && (client_id.dirty || client_id.touched)"
                class="alert alert-danger">
                <div *ngIf="client_id.errors.required">
                  Client Id is required.
                </div>
              </div>
            </div>
            <!-- <div class="form-group col-md-4">
                <label for="webex_config_id">Webex Config Id <span class="required">&#42;</span></label>
                <input id="webex_config_id" type="text" autocomplete="off" name="webex_config_id" class="form-control form-control-lg" required
                  [(ngModel)]="toolsGroup.webex_config_id" #webex_config_id="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return true">
                <div *ngIf="webex_config_id.invalid && (webex_config_id.dirty || webex_config_id.touched)"
                  class="alert alert-danger">
                  <div *ngIf="webex_config_id.errors.required">
                    Webex Config Id is required.
                  </div>
                </div>
              </div> -->
            <div class="form-group col-md-4">
              <label for="client_secret"> Client Secret <span class="required">&#42;</span></label>
              <input id="client_secret" type="text" autocomplete="off" name="client_secret" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.client_secret" #client_secret="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="client_secret.invalid && (client_secret.dirty || client_secret.touched)"
                class="alert alert-danger">
                <div *ngIf="client_secret.errors.required">
                  Client Secret is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="redirect_uri"> Redirect Url <span class="required">&#42;</span></label>
              <input id="redirect_uri" type="text" autocomplete="off" name="redirect_uri" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.redirect_uri" #redirect_uri="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="redirect_uri.invalid && (redirect_uri.dirty || redirect_uri.touched)"
                class="alert alert-danger">
                <div *ngIf="redirect_uri.errors.required">
                  Redirect Url is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="siteurl">  Site Url <span class="required">&#42;</span></label>
              <input id="siteurl" type="text" autocomplete="off" name="siteurl" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.siteurl" #siteurl="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="siteurl.invalid && (siteurl.dirty || siteurl.touched)"
                class="alert alert-danger">
                <div *ngIf="siteurl.errors.required">
                  Site Url Code is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="hostemail"> Host Email <span class="required">&#42;</span></label>
              <input id="hostemail" type="text" autocomplete="off" name="hostemail" class="form-control form-control-lg" required
                [(ngModel)]="toolsGroup.hostemail" #hostemail="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
              <div *ngIf="hostemail.invalid && (hostemail.dirty || hostemail.touched)"
                class="alert alert-danger">
                <div *ngIf="hostemail.errors.required">
                  Host Email is required.
                </div>
              </div>
            </div>

          
  
  
          </div>
  
          <div class="row">
            <div class="col-md-6">
             
            </div>
            <div class="col-md-6 text-right">
  
             
              <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2"
                (click)="CheckWebXDuplication()">
                Save
              </button>
              <button *ngIf="editMode" type="button" class="btn-canvas-blue mr-2"
                (click)="updateWebex()">
                Update
              </button>
              <!-- <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
                Cancel
              </button> -->
              <!--button class="mr-2 btn-canvas-blue" type="button" data-toggle="modal"
              data-target="#confirmationModal">Cancel</button-->
              <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="toolsGroupForm.form.pristine" (click)="closeAks()">Cancel</button>
 
      </div>
 
      <!-- Modal -->
      <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
      <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
          aria-labelledby="confirmationModal" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                      <span class="vertical-line mx-3"> </span>
                      <span class="header mb-0">Webex Properties</span>
                      <span data-dismiss="modal" class="material-icons close-icon">
                          close
                      </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                      Are you sure want to cancel?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-canvas-blue-2"
                          data-dismiss="modal">Close</button>
                      <button type="button" [disabled]="CancelConnDisable" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                  </div>
              </div>
              <!-- </div> -->
              <!-- </div> -->
          </div>
      </div>
           </div>
  
  
        </form>
      </div>
      <div class="my-5 table-container">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
                <th style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()" />
                </th>

  
  
              <th>ID</th>
              <th>Client Name</th>
              <!-- <th>Tool Type</th> -->
              <!-- <th>Client Id</th>
              <th>Client Secret</th> -->
              <!-- <th>Redirect Url</th> -->
              <th>Host Email</th>
              <th>Site Url</th>
              <th>Action</th>

            </tr>
          </thead>
          <tbody class="data-table-height">
            <tr *ngFor="let WebX of SCMArray">
                <td style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="WebX.isSelected" name="list_name" value="{{WebX.id}}"
                        (change)="isAllSelected()" />
                </td>
              <td><span class="link-col" (click)="getRecord(WebX)">{{WebX.webex_config_id}}</span></td>
              <td >{{WebX.client_name}}</td>
              <!-- <td>{{WebX.client_id}}</td>
              <td >{{WebX.client_secret}}</td> -->
              <!-- <td>{{WebX.redirect_uri}}</td> -->
              <td >{{WebX.hostemail}}</td>
              <td >{{WebX.siteurl}}</td>
              <td>  <span class="table-crud table-delete-icon" title="Template Information" (click)="showImageInfo(WebX)">
                  <img width="20" src="../../../assets/img/info.jpg"></span></td>
            </tr>
  
          </tbody>
        </table>
      </div>
      </div>
  
    </div>
  </div>