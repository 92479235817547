import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/api.service';
//import { environment } from 'src/environments/environment';

import { environment} from 'src/environments/environment.dev';

@Component({
  selector: 'app-webex-login',
  templateUrl: './webex-login.component.html',
  styleUrls: ['./webex-login.component.scss']
})
export class WebexLoginComponent implements OnInit {
  code: any;
  webexcode:any;
  successFlag:boolean = false;
  totalDaysRemaining:any;
  expireFlag:boolean=false;
  refresh_token_Second:any;
  constructor(private router: Router, private apiService: AuthService) { }

  ngOnInit(): void {    
 
    let webexCodeFirst= localStorage.getItem("webexFirst");
    let dbWebexCode="";
    this.apiService.getAccessCode().subscribe(data => {
      this.webexcode = data;
       dbWebexCode = this.webexcode.accessCode;
       let accessCode =localStorage.getItem("accessCode");
      if(webexCodeFirst =="yes")
      {
        if(dbWebexCode === undefined || dbWebexCode == "") 
        {
            if(accessCode != null)
            {
              localStorage.setItem("webexFirst","no");
              let body = {"webexCode":accessCode};
              this.apiService.submitAccessCode(body).subscribe(data => {
               // console.log(data, "submitCode");
                this.successFlag =true;
                this.expireFlag=false;
              })
            }
        }
      }
    })
  
  
    
  }
  getWebexAccessCode(){
   let accessCode="";
   
     this.apiService.getAccessCode().subscribe(data => {
     // console.log(data, "getAccessCode")
      this.code = data;
     // console.log(this.code.accessCode, "code")
      accessCode = this.code.accessCode;
     // console.log("accessCode",accessCode);
      if (accessCode == "") {

        this.GetAuthorize();
        localStorage.setItem("webexFirst", "yes");
      }
      else
      {
        //console.log("Else Part");
        this.apiService.getWebexTokenExpireTime().subscribe(data => {
        //  console.log(data, "get Webex Token expire Time")
          this.refresh_token_Second =data;
          var totalseconds =Number(this.refresh_token_Second.refreshTokenTime);
          if(6900000 < totalseconds)
          {
           // console.log("Else expire time Part");
            this.GetAuthorize();
            localStorage.setItem("webexFirst", "yes");
          }
          else
          {
  
            const totalMinutes     = Math.floor(totalseconds/60);
            const totalHours       = Math.floor(totalMinutes/60);
            const totalDays        = Math.floor(totalHours/24);
           // console.log("totalDays",totalDays);
            this.totalDaysRemaining  =Math.floor(79- totalDays);
            this.expireFlag=true;
            this.successFlag=false;
          }
        })
       
      }
    })

   }
  GetAuthorize() {
    const client_id = environment.webex_clientId;
    const client_redirectURI = environment.webex_redirect_uri;
    // var requestUrl = 'https://api.ciscospark.com/v1/authorize?' +
    //   'response_type=code&' +
    //   'scope=' + encodeURIComponent('spark:all') + '&' +
    //   'state=' + encodeURIComponent(this.randomString(63)) + '&' +
    //   'client_id=' + encodeURIComponent(client_id) + '&' +
    //   'redirect_uri=' + encodeURIComponent(client_redirectURI);
    // console.log("RequestURL:", requestUrl);
   /// var requestUrl = "https://webexapis.com/v1/authorize?client_id=" + encodeURIComponent(client_id) + "&response_type=code&redirect_uri=" + encodeURIComponent(client_redirectURI) + "&scope=meeting%3Arecordings_read%20meeting%3Aadmin_preferences_write%20spark%3Aall%20meeting%3Aadmin_schedule_write%20meeting%3Aadmin_preferences_read%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20meeting%3Aadmin_schedule_read&state=set_state_here";
   var requestUrl ="https://webexapis.com/v1/authorize?client_id="+ encodeURIComponent(client_id) +"&response_type=code&redirect_uri="+ encodeURIComponent(client_redirectURI) +"&scope=meeting%3Arecordings_read%20meeting%3Aadmin_preferences_write%20spark%3Aall%20meeting%3Aadmin_schedule_write%20meeting%3Aadmin_preferences_read%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Atranscripts_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20meeting%3Aadmin_schedule_read%20meeting%3Aadmin_transcripts_read&state=set_state_here";
    //console.log("RequestURL:", requestUrl)
    window.location.href = requestUrl;

    setTimeout(() => {
      //    console.log("webex");
      this.router.navigate(['/webex-login/:code/:state']);
    }, 60000);

  }
  randomString(length) {
    var str = "";
    var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      str += range.charAt(Math.floor(Math.random() * range.length));
    }
    return str;
  }

}
