<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<div class="inner-page-container">
  <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a (click)="goHome(secondFormGroup)">Home</a></li>
            <li class="breadcrumb-item link-blue"><a (click)="goWorkBeanch(secondFormGroup)">Devops</a></li>
            <li class="breadcrumb-item active" aria-current="page">Azure DevOps</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span (click)="addRepo()"><i class="fas fa-plus"></i> Add</span>
          <span><i class="fas fa-sync-alt"></i> Refresh</span>
          <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
    
    <div class="row">
      <div class="col-sm-3">
        <h1 class="text-light mb-0">
          <img width="40" src="https://cdn.vsassets.io/content/icons/favicon.ico">
          <div class="vertical-line mx-3"> </div> <span>Azure DevOps</span>      
        </h1>
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
        {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
      </div>
   
    </div>

  </div>
    

  <div class="inner-mid-container mt-2">
    <div class="mb-3" *ngIf="showRepo">
      
      <form [formGroup]="secondFormGroup" class="col-md-12 pl-0">
        <div class="row">
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="repoName">Enter Name</label>
              <input #input placeholder="Enter Name" name="repoName" formControlName="repoName" class="form-control form-control-height"
              [class.is-invalid]="repoName.invalid && repoName.touched">
              <small class="text-danger" [class.d-none]="repoName.valid || repoName.untouched">Name is
                required</small>
              
            </div>
          </div>
  
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="visibility">Visibility</label>
              <select formControlName="visibility" id="visibility" name="visibility" class="form-control form-control-height" 
              [class.is-invalid]="visibility.invalid && visibility.touched">
                <option value="" disabled selected hidden>Visibility</option>
                <option [value]="role" *ngFor="let role of visibilityVal">{{role}}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="visibility.valid || visibility.untouched">Visibility
                is required</small>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="versionControl">Version Control</label>
              <select formControlName="versionControl" id="versionControl" name="versionControl" class="form-control form-control-height"
              [class.is-invalid]="versionControl.invalid && versionControl.touched">
                <option value="" disabled selected hidden>Version Control</option>
                <option [value]="role" *ngFor="let role of versionControlVal">{{role}}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="versionControl.valid || versionControl.untouched">Version Control
                is required</small>
            </div>
          </div>
  
          <div class="col-md-6 col-lg-2">
            <div class="form-group">
              <label for="processTemplate">Process Template</label>
              <select formControlName="processTemplate" id="processTemplate" name="processTemplate" class="form-control form-control-height"
              [class.is-invalid]="processTemplate.invalid && processTemplate.touched" (change)="processTemplateChange($event)">
                <option value="" disabled selected hidden>Process Template</option>
                <option [value]="role.value" *ngFor="let role of processTemplateVal">{{role.viewValue}}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="processTemplate.valid || processTemplate.untouched">Process Template
                is required</small>
            </div>
          </div>
  
          <div class="mt-5 img-width">
            <span class="material-icons add-user" (click)="openUser()" title="Add User">
              person_add
            </span>
          </div>
  
          <div class="col-md-2 col-lg-1 mt-4">
            <button class="hover users-button" mat-button mat-raised-button (click)="confirmationPopup()"
            [disabled]="!secondFormGroup.valid || !selectedUserArray.length" mat-raised-button title="Create Repo">
              Create
            </button>
          </div>

          <div class="col-md-2 col-lg-1 mt-4">
            <button type="button" class="btn-canvas btn-canvas-blue-2"  (click)="toggleDisplay(secondFormGroup)">Cancel</button>
            
          </div>
  
        </div>
       
      </form>
    </div>

    <div class="row userRow" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">
        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>

    <table datatable class="table fixed-table" *ngIf="showTable">
      <thead>
        <tr>
        <th> Project Name </th>
        <th> Visibility </th>
        <th> Version Control </th>
        <th> Process Template </th>
        <th>Members</th>
        <th> Action </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let element of tableArr">
        <td> {{element.repoName}} <a href="">{{element.repoUrl}}</a></td>
        <td> {{element.visibility}} </td>
        <td> {{element.versionControl}} </td>
        <td> {{element.processTemplate}} </td>
        <td>
            <button class="view-member-btn" (click)="openAddedUserDialog(element.members)">View</button> 
        </td>
        <td>
          <span class="table-crud table-edit-icon" disabled><i class="far fa-edit"></i></span>
          <span class="table-crud table-save-icon" disabled><i class="far fa-save"></i></span>
          <span class="table-crud table-delete-icon" disabled><i class="far fa-trash-alt"></i></span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</div>