<div class="pop-up" *ngIf="!slideChecked && !viewFlag">
    <div class="">
        <span class="vertical-line mx-0 mx-md-3"> </span>
        <span class="header mb-0">{{req}}</span>
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">

        <div class="mt-3">
            <!-- <h3 class="mb-3 col-md-12 mt-2">Kindly inform assigned team members to take backup to avoid loss of data</h3> -->
           <div class="row">
            <div class="alert alert-info alert-dismissible ml-5 mr-2">
                <!-- <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> -->
                <strong>Note:</strong> VDI needs to be manually started/stopped during weekends. 
            </div>
            <div class="form-group col-5 ml-3">
                <label >Start Date</label>
                <input [min]="myDate" type="date" class="form-control" id="start" required
                    [(ngModel)]="model.start" name="start" #name="ngModel"style="height:50px; background-color:#F5F5F5" >

                    <!-- <label class="ml-3 mr-2">Minute</label>
                    <select [(ngModel)]="selectedOption" name="gender">
                      <option *ngFor="let option of selectOptions" [value]="option">
                        {{option}}
                      </option>
                    </select> -->
            </div>
            <div class="form-group col-6">

                <label class="mr-2">Start Time (IST)</label>
                <br>
                <mat-form-field appearance="fill" >
                    <mat-label>HH:MM</mat-label>
                    <mat-select [(value)]="startselectedtime" (selectionChange)="onStartTime($event)">
                      <mat-option>HH:MM</mat-option>
                      <mat-option value="T00:00">00:00</mat-option>
                      <mat-option value="T01:00">01:00</mat-option>
                      <mat-option value="T02:00">02:00</mat-option>
                      <mat-option value="T03:00">03:00</mat-option>
                      <mat-option value="T04:00">04:00</mat-option>
                      <mat-option value="T05:00">05:00</mat-option>
                      <mat-option value="T06:00">06:00</mat-option>
                      <mat-option value="T07:00">07:00</mat-option>

                      <mat-option value="T08:00">08:00</mat-option>
                      <mat-option value="T09:00">09:00</mat-option>
                      <mat-option value="T10:00">10:00</mat-option>
                      <mat-option value="T11:00">11:00</mat-option>
                      <mat-option value="T12:00">12:00</mat-option>

                      <mat-option value="T13:00">13:00</mat-option>
                      <mat-option value="T14:00">14:00</mat-option>
                      <mat-option value="T15:00">15:00</mat-option>
                      <mat-option value="T16:00">16:00</mat-option>
                      <mat-option value="T17:00">17:00</mat-option>
                      <mat-option value="T18:00">18:00</mat-option>
                      <mat-option value="T19:00">19:00</mat-option>
                      <mat-option value="T20:00">20:00</mat-option>
                      <mat-option value="T21:00">21:00</mat-option>
                      <mat-option value="T22:00">22:00</mat-option>
                      <mat-option value="T23:00">23:00</mat-option>

          
                    </mat-select>
                  </mat-form-field>
            <!-- <p>{{model.start}}{{selected}}</p> -->
        </div>
    </div>
    <div class="row ">
        <div class="form-group col-5 ml-3">
            <div class="form-group" (click)="getMinimumEndDate()">
                <label for="end">End Date </label>
                <input [min]="minimumEndDate" type="date" class="form-control" id="end" required
                    [(ngModel)]="model.end" name="end" #name="ngModel" style="height:50px; background-color:#F5F5F5">
            </div>
        </div>
        <div class="form-group col-6">

            <label class="mr-2">End Time (IST)</label>
            <br>
            <mat-form-field appearance="fill" >
                <mat-label>HH:MM</mat-label>
                <mat-select [(value)]="endselectedtime" (selectionChange)="onEndTime($event)" >
                  <mat-option>HH:MM</mat-option>
                  <mat-option value="T00:00">00:00</mat-option>
                  <mat-option value="T01:00">01:00</mat-option>
                  <mat-option value="T02:00">02:00</mat-option>
                  <mat-option value="T03:00">03:00</mat-option>
                  <mat-option value="T04:00">04:00</mat-option>
                  <mat-option value="T05:00">05:00</mat-option>
                  <mat-option value="T06:00">06:00</mat-option>
                  <mat-option value="T07:00">07:00</mat-option>

                  <mat-option value="T08:00">08:00</mat-option>
                  <mat-option value="T09:00">09:00</mat-option>
                  <mat-option value="T10:00">10:00</mat-option>
                  <mat-option value="T11:00">11:00</mat-option>
                  <mat-option value="T12:00">12:00</mat-option>

                  <mat-option value="T13:00">13:00</mat-option>
                  <mat-option value="T14:00">14:00</mat-option>
                  <mat-option value="T15:00">15:00</mat-option>
                  <mat-option value="T16:00">16:00</mat-option>
                  <mat-option value="T17:00">17:00</mat-option>
                  <mat-option value="T18:00">18:00</mat-option>
                  <mat-option value="T19:00">19:00</mat-option>
                  <mat-option value="T20:00">20:00</mat-option>
                  <mat-option value="T21:00">21:00</mat-option>
                  <mat-option value="T22:00">22:00</mat-option>
                  <mat-option value="T23:00">23:00</mat-option>

      
                </mat-select>
              </mat-form-field>
        <!-- <p>{{model.start}}{{selected}}</p> -->
    </div>
        <!-- <p>{{model.start}}</p> -->
        <!-- <p>{{model.end}}</p> -->
    </div>
        <div class="text-danger ml-3" *ngIf="(model.start == model.end) && (startselectedtime >= endselectedtime)">
            <p>End date time must be greater than start date time</p>
        </div>
     
        <div class="text-right">
            <div class="row">
                <div class="col-md-12 col-12 mt-3" *ngIf="((((model.end == model.start) &&(endselectedtime > startselectedtime) ) || (model.end >model.start) )&& (startselectedtime!='' && endselectedtime!='' ) && (model.end !=undefined) && (model.start!=undefined))">
                    <button type="button" class="btn-canvas btn-canvas-blue-2" 
                        (click)="selectedOption('Yes')">Save</button>
                </div>
                <div class="col-md-12 col-12 mt-3"  *ngIf="((model.end == model.start) &&(startselectedtime >= endselectedtime) ) || ((model.start >model.end) ) || model.start==undefined || model.end==undefined || startselectedtime=='' || endselectedtime==''">
                        <button type="button" class="btn-canvas btn-canvas-blue-2" style="background-color: grey; pointer-events: none;"
                            >Save</button>
                    </div>
               
                <!-- <div class="col-md-3 col-12 mt-3">
                    <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="popUpClose('No')" disabled>No</button>
                </div> -->
            </div>
        </div>

</div>
</div>
</div>

<div class="pop-up" *ngIf="slideChecked && !viewFlag">
        <div class="">
            <span  class="vertical-line mx-0 mx-md-3"> </span>
             <span class="header mb-0">{{req}}</span> 
            <span (click)="onNoClick()" class="material-icons close-icon">
                close
              </span>
     </div>
     <hr class="margin-class" />
        <div class="popup-container">
           
            <div class="" >
                <h3 class="mb-3 col-md-12 mt-2">After removing VDI from schedular VDI will be started at 8:30 AM and stopped at 11 PM.<br>
                    Do you want to continue ?</h3>
                <div class="text-right">
                    <div class="row" >
                        <div class="col-md-9 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="selectedOption2('Yes')">Yes</button>
                            </div>
                        <div class="col-md-3 col-12 mt-3">
                                <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="popUpClose('No')">No</button>
                            </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>


<div class="pop-up" *ngIf="viewFlag">
    <div class="">
        <span class="vertical-line mx-0 mx-md-3"> </span>
        <span class="header mb-0">{{viewID}}</span>
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">

        <div class="mt-3">
            <!-- <h3 class="mb-3 col-md-12 mt-2">Kindly inform assigned team members to take backup to avoid loss of data</h3> -->
           <div class="row">
            <div class="alert alert-info alert-dismissible ml-5 mr-3">
                <!-- <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> -->
                <strong>Note:</strong> VDI needs to be manually started/stopped during weekends. 
            </div>
            <div class="form-group col-5 ml-3">
                <label >Start Date</label>
                <input [min]="myDate2" type="date" class="form-control" id="start2" required
                    [(ngModel)]="model2.start" name="start2" #name="ngModel"style="height:50px; background-color:#F5F5F5" >

                    <!-- <label class="ml-3 mr-2">Minute</label>
                    <select [(ngModel)]="selectedOption" name="gender">
                      <option *ngFor="let option of selectOptions" [value]="option">
                        {{option}}
                      </option>
                    </select> -->
            </div>
            <div class="form-group col-6">

                <label class="mr-2">Start Time (IST)</label>
                <br>
                <mat-form-field appearance="fill" >
                    <mat-label>HH:MM</mat-label>
                    <mat-select [(value)]="startselectedtime2" (selectionChange)="onStartTime2($event)">
                      <mat-option>HH:MM</mat-option>
                      <mat-option value="T00:00">00:00</mat-option>
                      <mat-option value="T01:00">01:00</mat-option>
                      <mat-option value="T02:00">02:00</mat-option>
                      <mat-option value="T03:00">03:00</mat-option>
                      <mat-option value="T04:00">04:00</mat-option>
                      <mat-option value="T05:00">05:00</mat-option>
                      <mat-option value="T06:00">06:00</mat-option>
                      <mat-option value="T07:00">07:00</mat-option>

                      <mat-option value="T08:00">08:00</mat-option>
                      <mat-option value="T09:00">09:00</mat-option>
                      <mat-option value="T10:00">10:00</mat-option>
                      <mat-option value="T11:00">11:00</mat-option>
                      <mat-option value="T12:00">12:00</mat-option>

                      <mat-option value="T13:00">13:00</mat-option>
                      <mat-option value="T14:00">14:00</mat-option>
                      <mat-option value="T15:00">15:00</mat-option>
                      <mat-option value="T16:00">16:00</mat-option>
                      <mat-option value="T17:00">17:00</mat-option>
                      <mat-option value="T18:00">18:00</mat-option>
                      <mat-option value="T19:00">19:00</mat-option>
                      <mat-option value="T20:00">20:00</mat-option>
                      <mat-option value="T21:00">21:00</mat-option>
                      <mat-option value="T22:00">22:00</mat-option>
                      <mat-option value="T23:00">23:00</mat-option>

          
                    </mat-select>
                  </mat-form-field>
            <!-- <p>{{model.start}}{{selected}}</p> -->
        </div>
    </div>
    <div class="row ">
        <div class="form-group col-5 ml-3">
            <div class="form-group" (click)="getMinimumEndDate2()">
                <label for="end">End Date </label>
                <input [min]="minimumEndDate2" type="date" class="form-control" id="end2" required
                    [(ngModel)]="model2.end" name="end2" #name="ngModel" style="height:50px; background-color:#F5F5F5">
            </div>
        </div>
        <div class="form-group col-6">

            <label class="mr-2">End Time (IST)</label>
            <br>
            <mat-form-field appearance="fill" >
                <mat-label>HH:MM</mat-label>
                <mat-select [(value)]="endselectedtime2" (selectionChange)="onEndTime2($event)" >
                  <mat-option>HH:MM</mat-option>
                  <mat-option value="T00:00">00:00</mat-option>
                  <mat-option value="T01:00">01:00</mat-option>
                  <mat-option value="T02:00">02:00</mat-option>
                  <mat-option value="T03:00">03:00</mat-option>
                  <mat-option value="T04:00">04:00</mat-option>
                  <mat-option value="T05:00">05:00</mat-option>
                  <mat-option value="T06:00">06:00</mat-option>
                  <mat-option value="T07:00">07:00</mat-option>

                  <mat-option value="T08:00">08:00</mat-option>
                  <mat-option value="T09:00">09:00</mat-option>
                  <mat-option value="T10:00">10:00</mat-option>
                  <mat-option value="T11:00">11:00</mat-option>
                  <mat-option value="T12:00">12:00</mat-option>

                  <mat-option value="T13:00">13:00</mat-option>
                  <mat-option value="T14:00">14:00</mat-option>
                  <mat-option value="T15:00">15:00</mat-option>
                  <mat-option value="T16:00">16:00</mat-option>
                  <mat-option value="T17:00">17:00</mat-option>
                  <mat-option value="T18:00">18:00</mat-option>
                  <mat-option value="T19:00">19:00</mat-option>
                  <mat-option value="T20:00">20:00</mat-option>
                  <mat-option value="T21:00">21:00</mat-option>
                  <mat-option value="T22:00">22:00</mat-option>
                  <mat-option value="T23:00">23:00</mat-option>

      
                </mat-select>
              </mat-form-field>
        <!-- <p>{{model.start}}{{selected}}</p> -->
    </div>
        <!-- <p>{{model.start}}</p> -->
        <!-- <p>{{model.end}}</p> -->
    </div>
        <div class="text-danger ml-3" *ngIf="(model2.start == model2.end) && (startselectedtime2 >= endselectedtime2)">
            <p>End date time must be greater than start date time</p>
        </div>
     
        <div class="text-right">
            <div class="row">
                <div class="col-md-12 col-12 mt-3" *ngIf="((((model2.end == model2.start) &&(endselectedtime2 > startselectedtime2) ) || (model2.end >model2.start) )&& (startselectedtime2!='' && endselectedtime2!='' ) && (model2.end !=undefined) && (model2.start!=undefined))">
                    <button type="button" class="btn-canvas btn-canvas-blue-2" 
                        (click)="selectedOption3('Yes')">Update</button>
                </div>
                <div class="col-md-12 col-12 mt-3" *ngIf="((model2.end == model2.start) &&(startselectedtime2 >= endselectedtime2) ) || ((model2.start >model2.end) ) || model2.start==undefined || model2.end==undefined || startselectedtime2=='' || endselectedtime2==''">
                        <button type="button" class="btn-canvas btn-canvas-blue-2" style="background-color: grey; pointer-events: none;"
                            >Update</button>
                            
                    </div>
                
            </div>
        </div>

</div>

</div>
</div>
