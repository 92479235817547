<!-- New code-->
<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
            
            <li class="breadcrumb-item active" aria-current="page">AKS</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right pt-3">
        <div class="table-options ">
          <span (click)="addAks()"><i class="fas fa-plus"></i> Add</span>
          <span (click)="refreshAks()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
         
        </div>
      </div>
      <!-- <div class="col-md-1 p-md-0 my-auto">
        <a routerLink="/admin-catalog-config" class="float-right step-back">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div> -->
      
    </div>
    <div>
      <h1 class="text-light mb-0"><img width="40" src="assets/img/azure- cluster.png">
        <div class="vertical-line mx-3"> </div> <span>AKS</span>
      </h1>
    </div>

  </div>

  <div class="inner-mid-container">
    <div *ngIf="showAksForm" class="git-Lab-Form">
      <h2 *ngIf="!editMode">New AKS Instance</h2>
      <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
      <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="toolGr">Tool Group <span class="required">&#42;</span></label>
            <select id="toolGr" name="toolGr" class="form-control form-control-lg" required (change)="onChange($event)"
              [(ngModel)]="toolsGroup.toolGr" #toolGr="ngModel">
              <option selected>Choose Tool Group</option>
              <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}</option>
            </select>
            <div *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
              <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="toolGr">&nbsp;</label>
            <div>
              <a class="pt-24 link-col" (click)="openDialog()">
                <u>Add New Tool Group</u>
              </a>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="nameInstance"> Name <span class="required">&#42;</span></label>
            <input id="nameInstance" type="text" autocomplete="off" name="nameInstance" class="form-control form-control-lg" required
              [(ngModel)]="toolsGroup.nameInstance" #nameInstance="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false">
            <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
              class="alert alert-danger">
              <div *ngIf="nameInstance.errors.required">
                Name is required.
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="description">Description <span class="required">&#42;</span></label>
            <input  type="text" autocomplete="off" id="description" autocomplete="off" name="description" class="form-control  form-control-lg" required
              [(ngModel)]="toolsGroup.description" #description="ngModel">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
              <div *ngIf="description.errors.required">
                Description is required.
              </div>
            </div>
          </div>


          <!--Code added for Azure Id with Details on i button-->

          <div class="form-group col-md-4">
            <label for="azureId">Azure ID <span class="required">&#42;</span></label>
            <select id="azureId" name="azureId" class="form-control form-control-lg" required
              (change)="onChangeAzureId($event)" [(ngModel)]="toolsGroup.azureId" #azureId="ngModel">
              <option selected>Select Azure ID</option>
              <!--<option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}                
              </option>-->
              <option *ngFor="let s of SubscriptionList" [value]="s.tool_id">{{s.tool_id}}
              </option>
            </select>
            <div *ngIf="azureId.errors && azureId.touched" class="alert alert-danger">
              <div *ngIf="azureId.errors.required">Azure Id is required.</div>
            </div>
          </div>

          <div class="position-relative col-sm-1 pt-5 img-width">
            <span *ngIf="this.vdi_Azure_Id" style="font-size: 18px;cursor:pointer;" class="material-icons add-user"
              title="System Information" data-toggle="modal" data-target="#AzureDetailsModal">info</span>



            <!-- Modal -->
            <div class="modal fade pop-up" id="AzureDetailsModal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="info-header">
                    <span class="vertical-line mx-3"> </span>
                    <span class="header mb-0"> Azure ID Details</span>
                    <span type="button" data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                  </div>

                  <div class="modal-body popup-container">
                    <div class="row">
                      <div class="col-md-4">
                        <span class="field-name">Azure ID </span>
                      </div>
                      <div class="col-md-8">
                        <span> : &nbsp;{{this.vdi_Azure_Id}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <span class="field-name">Subscription ID </span>
                      </div>
                      <div class="col-md-8">
                        <span> : &nbsp;{{this.vdi_Subscription_ID}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <span class="field-name">Tenant ID </span>
                      </div>
                      <div class="col-md-8">
                        <span> : &nbsp;{{this.vdi_tenantId}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <span class="field-name">Connection Name </span>
                      </div>
                      <div class="col-md-8">
                        <span> : &nbsp;{{this.vdi_Azure_ConnectionName}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <span class="field-name">Tool Group Name </span>
                      </div>
                      <div class="col-md-8">
                        <span> : &nbsp;{{this.Vdi_ToolGr_Name}}</span>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="Vnet">Vnet <span class="required">&#42;</span></label>
            <input id="Vnet" type="text" autocomplete="off" name="Vnet" class="form-control form-control-lg" required [(ngModel)]="toolsGroup.Vnet"
              #Vnet="ngModel">

            <div *ngIf="Vnet.invalid && (Vnet.dirty || Vnet.touched)" class="alert alert-danger">
              <div *ngIf="Vnet.errors.required">
                Vnet is required.
              </div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="Subnet">Subnet <span class="required">&#42;</span></label>
            <input id="Subnet" type="text" autocomplete="off" class="form-control form-control-lg" required name="Subnet"
              [(ngModel)]="toolsGroup.Subnet" #Subnet="ngModel">


            <div *ngIf="Subnet.invalid && (Subnet.dirty || Subnet.touched)" class="alert alert-danger">
              <div *ngIf="Subnet.errors.required">
                Subnet is required.
              </div>
            </div>
          </div>

          <div class="form-group col-md-4">
            <label for="ResourceGroup">Resource Group <span class="required">&#42;</span></label>
            <input id="ResourceGroup" type="text" autocomplete="off" class="form-control form-control-lg" required name="ResourceGroup"
              [(ngModel)]="toolsGroup.ResourceGroup" #ResourceGroup="ngModel">


            <div *ngIf="ResourceGroup.invalid && (ResourceGroup.dirty || ResourceGroup.touched)"
              class="alert alert-danger">
              <div *ngIf="ResourceGroup.errors.required">
                Resource Group is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
           
          </div>
          <div class="col-md-6 text-right">
              
            <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2"
              (click)="CheckAksDuplication()">
              Save
            </button>
            <button *ngIf="editMode" [disabled]="UpdateConnDisable" type="button" class="btn-canvas-blue mr-2"
              (click)="updateAks()">
              Update
            </button>
            <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
              Cancel
            </button>
          </div>
        </div>


      </form>
    </div>
    <div class="my-5 table-container">
      <div class="table-responsive">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
        <thead>
          <tr>

            <th>ID</th>
            <th>Connection Name</th>
            <!-- <th>Tool Type</th> -->
            <th>Vnet</th>
            <th>Subnet</th>
            <th>Resource Group</th>
            <th>Tool Group</th>
          </tr>
        </thead>
        <tbody class="data-table-height">
          <tr *ngFor="let Aks of SCMArray">

            <td><span class="link-col" (click)="getRecord(Aks)">{{Aks.ID}}</span></td>
            <td>{{Aks.name}}</td>
            <td>{{Aks.Vnet}}</td>
            <td>{{Aks.Subnet}}</td>
            <td>{{Aks.ResourceGroup}}</td>
            <td>{{Aks.toolGroup}}</td>

          </tr>

        </tbody>
      </table>
    </div>
    </div>

  </div>
</div>