<div class="pop-up">
    <div class="">
        <span  class="vertical-line mx-0 mx-md-3"> </span>
         <span class="header mb-0">Confirm</span> 
        <span (click)="onNoClick()" class="material-icons close-icon">
            close
        </span>
    </div>
 <hr class="margin-class" />
    <div class="popup-container">
        
        <div class="" >
            <h3 class="mb-3 col-md-12">Do you want to proceed?</h3>
            <div class="offset-md-7">
                <div class="row">
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('Yes')" class="border_none vdi-button">Yes</button>
                    </div>
                    <div class="col-md-6 col-12 mt-3 text-center">
                        <button mat-raised-button style="outline: none;" (click)="selectedOption('No')" class="border_none btn-canvas-blue-2">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>