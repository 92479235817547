<div class="contain-fluid">
    <div class="project-summary">
        <div class="sub-nav-bar mb-2" style="height: auto;">
            <div class="row">
                <div class="col-md-12 details">
                    <div class="col-md-9 vLine pull-left">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <h2 class="projectName">Project ID : <span class="projectDetail">{{projectId}}</span>
                                    <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
                                    </h2>
                                </div>
                            </div>                         
                        </div>
                            <div class="col-md-2 text-md-right pull-right position-icon">
                                <div>
                                    <!--mat-icon (click)="syncImanageTeamMemberProject()" class="refresh-icon"  aria-hidden="false" aria-label="Example home icon"
                                        title="Sync data from IManage">sync</mat-icon-->
                                        <mat-icon class="border_none" class = "home-back" title="Home" aria-hidden="false" color="primary" routerLink="/projectSummary"  aria-label="Example home icon">home</mat-icon>
                                </div>
                            </div>
                        </div>     
                    </div>    
                </div>

            <div class="container">
                <div >
                    <div class="title-class-header"> Project Summary <hr>
                    </div>
       
                    <!-- <div *ngIf="isUserTM">
                        <div id="workbench-row" *ngIf="show_workbench_tab" class="d-flex flex-row ">
                            <div class="item" routerLink="/projectdetails"> 
                                <img class="summary-icons disabled-img" 
                                    src="assets/img/Project Details.png" title="Project Details" />
                            </div>

                            <div class="item" routerLink="/workBench">
                                <img class="summary-icons" src="assets/img/workbench.png"
                                    title="Workbench" />
                            </div>
                            <div class="item">
                                <img class="summary-icons disabled-img"
                                src="assets/img/Team Members.png" title="Team Members" />
                            </div>

                            <div class="item">
                                <img src="assets/img/Approval Center.png" class="disabled-img summary-icons"
                                    title="Approval" />
                            </div>
                            <div class="item">
                                <img src="assets/img/Live Monitoring.png" class="summary-icons disabled-img" 
                                    title="Live Monitoring" />
                            </div>

                        </div>

                    </div> -->
                    <!-- <div *ngIf="!isUserTM"> -->
                        
                        <div id="workbench-row" *ngIf="show_workbench_tab" class="d-flex flex-row ">
                            <!-- <div class="flex-width" *ngIf="summary_icons.project_details?.view"> 
                                <div class="item"  *ngIf="!summary_icons.project_details?.access"> 
                                    <img class="summary-icons disabled-img"
                                        src="assets/img/Project Details.png" title="Project Details" />
                                </div>
    
                                <div class="item"  routerLink="/projectdetails" *ngIf="summary_icons.project_details?.access" (click)="displayDetails()"> 
                                    <img class="summary-icons cursor"
                                    src="assets/img/Project Details.png" title="Project Details" />
                                </div>
                            </div> -->
                           
                            <div class="flex-width" *ngIf="summary_icons?.workbench">
                                <div class="item" routerLink="/workBench">
                                    <img class="summary-icons cursor" src="assets/img/workbench.png"
                                        title="Workbench" />
                                </div>
                            </div>
                            
                            <div class="flex-width" *ngIf="summary_icons.team_members?.view">
                                <div class="item" *ngIf="!summary_icons.team_members?.access">
                                    <img class="summary-icons disabled-img"
                                    src="assets/img/Team Members.png" title="Team Members" />
                                </div>
                                <div class="item" *ngIf="summary_icons.team_members?.access" routerLink="/team-members">
                                    <img class="summary-icons cursor"
                                        src="assets/img/Team Members.png" title="Team Members" />
                                </div>
                            </div>

                            <div class="flex-width" *ngIf="summary_icons.approval_center?.view" >
                                <div class="item" *ngIf="!summary_icons.approval_center?.access">
                                    <img src="assets/img/Approval Center.png" class="disabled-img summary-icons"
                                        title="Approval" />
                                </div>
                                <div class="item" *ngIf="summary_icons.approval_center?.access"> 
                                    <img src="assets/img/Approval Center.png" class="summary-icons cursor"
                                    title="Approval" />
                                </div>
                            </div>

                            <div class="flex-width" *ngIf="summary_icons.live_monitoring?.resilience.view">
                                <div class="item" *ngIf="!summary_icons.live_monitoring?.resilience.access">
                                    <img src="assets/img/Live Monitoring.png" class="summary-icons disabled-img" 
                                        title="Live Monitoring" />
                                </div>
                                
                                <!-- <div class="item" *ngIf="summary_icons.live_monitoring?.access">
                                    <img src="assets/img/Live Monitoring.png" class="summary-icons cursor" routerLink="/reports"
                                    title="Live Monitoring" />
                                </div> -->

                                <div class="item" *ngIf="summary_icons.live_monitoring?.resilience.access">
                                    <img src="assets/img/Live Monitoring.png" class="summary-icons cursor"  (click)="routeMonitoring()"
                                    title="Live Monitoring" />
                                </div>
                            </div>
                            <div class="flex-width" *ngIf="summary_icons.reports?.view">
                                <div class="item" *ngIf="!summary_icons.reports?.access">
                                    <img src="assets/img/ReportNew.png" class="summary-icons disabled-img" 
                                        title="Report" />
                                </div>
                                <div class="item" *ngIf="summary_icons.reports?.access">
                                    <img src="assets/img/ReportNew.png" class="summary-icons cursor" routerLink="/report-cost"
                                    title="Report" />
                                </div>
                            </div>
                            
                        </div>
                    
                    <!-- </div> -->
                

            <div *ngIf="show_workbench_items">
                <div #container></div>
            </div>
         
        </div>
    </div>
</div>

<footer>
    <p>Powered By &nbsp;
            <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" >
       <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
      </p>
  </footer>
