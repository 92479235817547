<!-- New code-->
<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Dev Framework</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 col-md-5 text-right">
          <div class="table-options pt-3">
            <span (click)="addDevFramework()"><i class="fas fa-plus"></i> Add</span>
            <span (click)="refreshDevTest()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <!--<span><i class="fas fa-cog"></i> Manage</span> -->
            <a routerLink="/admin-catalog-config" class="float-right step-back">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
          </div>
        </div>
        <!-- <div class="col-sm-1 pt-4">
          <a routerLink="/admin-catalog-config" class="float-right text-light"><i class="fas fa-times"></i></a>
        </div> -->
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/Dev Framework White.svg">
          <div class="vertical-line mx-3"> </div> <span>Framework</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
      <div *ngIf="showVdiForm" class="git-Lab-Form">
        <h2 *ngIf="!editMode">New Framework Instance</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
        <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">

               <!-- Template Name TextBox-->
               <div class="form-group col-md-4">
                <label for="tempName"> Template Name <span class="required">&#42;</span></label>
                <input id="tempName" name="tempName" class="form-control form-control-lg" required
                  [(ngModel)]="toolsGroup.tempName" #tempName="ngModel">
                <div *ngIf="tempName.invalid && (tempName.dirty || tempName.touched)"
                  class="alert alert-danger">
                  <div *ngIf="tempName.errors.required">
                    Template Name is required.
                  </div>
                </div>
              </div>
  
              <!-- Description TextBox-->
              <div class="form-group col-md-4">
                <label for="des">Description <span class="required">&#42;</span></label>
                <input id="des" name="des" class="form-control  form-control-lg" required
                  [(ngModel)]="toolsGroup.des" #des="ngModel">
                <div *ngIf="des.invalid && (des.dirty || des.touched)"
                 class="alert alert-danger">
                  <div *ngIf="des.errors.required">
                    Description is required.
                  </div>
                </div>
              </div>

              <!-- framework Dropdown -->
            <div class="form-group col-md-4">
              <label for="framework">Framework<span class="required">&#42;</span></label>
              <select id="framework" name="framework" class="form-control form-control-lg" required
              (change)="onChange($event)"
                [(ngModel)]="toolsGroup.framework" #framework="ngModel">
                <option selected>Select Framework</option>
                <option *ngFor="let t of FrameworkList" [value]="t.frameworkName">{{t.frameworkName}}</option>
              </select>
              <div *ngIf="framework.errors && framework.touched" class="alert alert-danger">
                <div *ngIf="framework.errors.required">framework is required.</div>
              </div>
            </div>

               <!-- language Dropdown -->
            <div class="form-group col-md-4">
              <label for="language">Select Language <span class="required">&#42;</span></label>
              <select id="language" name="language" class="form-control form-control-lg" required
              (change)="onChange($event)"
                [(ngModel)]="toolsGroup.language" #language="ngModel">
                <option selected>Choose Tool Group</option>
                <option *ngFor="let t of LanguageList" [value]="t">{{t}}</option>
              </select>
              <div *ngIf="language.errors && language.touched" class="alert alert-danger">
                <div *ngIf="language.errors.required">language is required.</div>
              </div>
            </div>

              <!-- Path TextBox-->
              <div class="form-group col-md-4">
                <label for="path">Path <span class="required">&#42;</span></label>
                <input id="path" name="path" class="form-control  form-control-lg" required
                  [(ngModel)]="toolsGroup.path" #path="ngModel">
                <div *ngIf="path.invalid && (path.dirty || path.touched)" class="alert alert-danger">
                  <div *ngIf="path.errors.required">
                    path is required.
                  </div>
                </div>
              </div>

            <!-- Tag Popup -->
            <div class="form-group col-md-4">
              <label for="toolGr">Tag &nbsp;</label>
              <div>
                <a class="pt-24 link-col" (click)="openDialog()">
                  <u>Change Tag</u>
                </a>
              </div>
            </div>
           
           </div>
         
          <div class="row">
            <div class="col-md-6">
             <!-- <div class="form-group ">
                <label> &nbsp; </label>
                <a class="pt-24 link-col" (click)="openDialog()">
                  <u>Add New Tool Group</u></a>
              </div> -->
            </div>
            <div class="col-md-6 text-right">
  
              <!-- <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2" (click)="CheckgitLabDuplication()">
                Save
              </button> -->
              <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2" (click)="VdiSave()">
                Save
              </button>
              <button *ngIf="editMode" [disabled]="UpdateConnDisable" type="button" class="btn-canvas-blue mr-2" (click)="updateAks()">
                Update
              </button>
              <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
                Cancel
              </button>
            </div>
          </div>  
  
  
        </form>
      </div>
      <div class="my-5 table-container">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
          <thead>
            <tr>
            
              
              <th>Template Name</th>
              <!-- <th>Tool Type</th> -->
              <th>Framework</th>
              <th>Language</th>
              <th>Path</th>
            
            </tr>
          </thead>
          <tbody class="data-table-height">
             <tr *ngFor="let dev of devTestFramework">
             
             <!-- <td><span class="link-col" (click)="getRecord(Aks)">{{Aks.ID}}</span></td> -->
              <td>{{dev.tempName}}</td>
              <td>{{dev.framework}}</td>
              <td>{{dev.language}}</td>
              <td>{{dev.path}}</td>
             
              
            </tr> 
  
          </tbody>
        </table>
        </div>
      </div>
  
    </div>
  </div>
