<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
 
   <div class="project-summary">
    <div class="sub-nav-bar mb-4" style="height: auto;">
        <div class="row">
            <div class="col-md-12 details">
                <div class="col-md-9 vLine pull-left">
                    <div class="row">
                        <div class="col-md-12 col-lg-6">
                            <h2 class="projectName">Project ID : <span class="projectDetail">
                                    {{projectId}}</span>
                                <span class="material-icons show-project-info" title="System Information"
                                    (click)="showProjectInfo()">info</span>
                            </h2>
 
                        </div>
                    </div>
 
                </div>
                <div class="col-md-2 text-md-right pull-right position-icon">
                        <mat-icon class="border_none" class="home-back color-white" title="Home" aria-hidden="false"
                            color="primary" routerLink="/projectSummary" aria-label="Example home icon">home
                        </mat-icon>
                </div>
            </div>
        </div>
 
    </div>
    <div class="container">

<!-- <div> -->

   
    <div class="title-class-header">
        Workbench
        <hr>
     </div>
        <!-- <div class="mb-4 d-block text-right">
           
        </div> -->

        <!-- <div *ngIf="isUserTM">
            <div id="workbench-row" *ngIf="show_workbench_tab" class="d-flex flex-row ">
                
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/project-role.png">
                </div>
                
                <div class="workbench-items flex-width" (click)="getToolName('VDI'); openToolDiaLog()">
                    <img class="" src="assets/img/assign-vdi.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img"  src="assets/img/scm-tool.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/story-board.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/DevopsIcon.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/development-fremwork.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/LTI-store.png">
                </div>
    
                <div class="workbench-items flex-width">
                    <img class=" disabled-img" src="assets/img/deployment-store.png">
                </div>
        
               <div class="workbench-items flex-width">
                <img class=" disabled-img" src="assets/img/collaboration.png">
               </div>
    
            </div>
        </div> -->
        <!-- <div *ngIf="!isUserTM"> -->
            <div id="workbench-row" *ngIf="show_workbench_tab" class="d-flex flex-row " style="border: 1px solid white;">
            
                <div class="col-md-3" *ngIf="workbench_icons.project_role?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.project_role?.access">
                        <img class="disabled-img" src="assets/img/project-role.png">
                    </div>
                    
                    <div class="workbench-items" *ngIf="workbench_icons.project_role?.access">
                        <img class="cursor" (click)="displayProjectRole()" src="assets/img/project-role.png">
                    </div> 
                </div>
                
                
                <!-- VDI -->
                <div [ngClass]="!isUserTM? 'col-md-3' : 'col-md-12'" *ngIf="workbench_icons.assign_vdi?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.assign_vdi?.access">
                        <img class="disabled-img" src="assets/img/assign-vdi.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.assign_vdi?.access">
                        <img class="cursor" src="assets/img/assign-vdi.png"  (click)="getToolName('VDI'); openToolDiaLog()">
                    </div>
                </div>
               
    
                <!-- SCM -->
                <div class="col-md-3" *ngIf="workbench_icons.scm_tool?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.scm_tool?.access" >
                        <img class="disabled-img"  src="assets/img/scm-tool.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.scm_tool?.access">
                        <img class="cursor"  src="assets/img/scm-tool.png"  (click)="getToolName('SCM'); openToolDiaLog()">
                    </div>
                </div>
              
    
                <!-- Story Board -->
                <div class="col-md-3" *ngIf="workbench_icons.story_board?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.story_board?.access" >
                        <img class="disabled-img" src="assets/img/story-board.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.story_board?.access">
                        <img class="cursor" src="assets/img/story-board.png"  (click)="getToolName('STORY'); openToolDiaLog()">
                    </div>
                </div>
                
    
                <!-- DevOps -->
                <div class="col-md-3" *ngIf="workbench_icons.devops?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.devops?.access">
                        <img class="disabled-img" src="assets/img/DevopsIcon.png">
                    </div>
        
                    <div class="workbench-items" *ngIf="workbench_icons.devops?.access">
                        <img class="cursor" src="assets/img/DevopsIcon.png"  (click)="getToolName('Azzure'); openToolDiaLog()">
                    </div>
                </div>
                
    
                <!-- Dev-test -->
                <div class="col-md-3" *ngIf="workbench_icons.development_frameworks?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.development_frameworks?.access">
                        <img class="disabled-img" src="assets/img/development-fremwork.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.development_frameworks?.access">
                        <img class="cursor" src="assets/img/development-fremwork.png" routerLink="/dev-test">
                    </div>
                </div>
                
    
                <!-- LTI Store -->
                <div class="col-md-3" *ngIf="workbench_icons.lti_store?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.lti_store?.access">
                        <img class="disabled-img" src="assets/img/LTI-store.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.lti_store?.access">
                        <img class="cursor" src="assets/img/LTI-store.png" (click)="getToolName('Cast'); openToolDiaLog()">
                    </div>
                </div>
                
    
                <!-- Deployment-store -->
                <div class="col-md-3" *ngIf="workbench_icons.deployment_store?.view">
                    <div class="workbench-items" *ngIf="!workbench_icons.deployment_store?.access">
                        <img class="disabled-img" src="assets/img/deployment-store.png">
                    </div>
                    <div class="workbench-items" *ngIf="workbench_icons.deployment_store?.access">
                        <img class="cursor" src="assets/img/deployment-store.png" (click)="getToolName('deployStore'); openToolDiaLog()">
                    </div>
                </div>
                
               <!-- Collaboration -->
               <!-- <div class="col-md-3" *ngIf="workbench_icons.collaboration?.view">
                <div class="workbench-items" *ngIf="!workbench_icons.collaboration?.access">
                    <img class="disabled-img" src="assets/img/collaboration.png">
                   </div>
                    <div class="workbench-items" *ngIf="workbench_icons.collaboration?.access">
                        <img class="cursor" src="assets/img/collaboration.png" (click)="getToolName('Teams'); openToolDiaLog()">
                    </div>
               </div> -->
              
               <div class="col-md-3" *ngIf="!isUserTM && adminRole">
                    <div class="workbench-items">
                        <img class="cursor"  routerLink="/recycle-bin" src="assets/img/Recycle-Icon.png">
                    </div>
               </div>
    
            </div>
        <!-- </div> -->

        <!-- </div> -->
        <footer>
            <p>Powered By &nbsp;
              <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" >
               <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
              </p>
          </footer>
        </div>
  </div>
        
        <div class="modal fade" id="myModal"  role="dialog">
            <div class="modal-dialog">
            
              <!-- Modal content-->
              <div class="modal-content modal-adjust" >
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                  <h2 class="text-center">No tool instance Available</h2>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
              </div>
              
            </div>
          </div>
