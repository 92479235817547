import { Component, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

export interface vdiTool {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  Vnet: string;
  toolGroup: string;
  toolGroup_id: string;
  Subnet: string;
  ResourceGroup: string;
}

@Component({
  selector: 'app-dev-test',
  templateUrl: './dev-test.component.html',
  styleUrls: ['./dev-test.component.scss']
})
export class DevTestComponent implements OnInit {

  showVdiForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  ToolGroupList = [];
  FrameworkList =[];
  LanguageList=[];

  project_toolGrName:string;
  saveConnDisable = false;
  CancelConnDisable=false;
  UpdateConnDisable=true;
  devTestFramework = [];
  project_toolGrId: string;
  vdiObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  
  constructor(private formBuilder: FormBuilder,private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,public alertService: AlertService ) { }

  ngOnInit(): void {

    //this.getTypeT();
    
    this.getFrameworkList();

    var PopupToolGroup = localStorage.getItem('Popup_Open_Vdi')
 if(PopupToolGroup=="true"){
   this.showVdiForm = true;
   var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Vdi')
   this.toolsGroup.toolGr = Popup_NewToolGroupID;
  //  console.log("this.toolsGroup.toolGr");
  //  console.log(this.toolsGroup.toolGr);
   var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Vdi')
   this.project_toolGrName  = Popup_NewToolGroupName;
   localStorage.setItem("Popup_Open_Vdi", "false");
 }else{
   this.showVdiForm = false;
 }
 this.GetDevTestDetails();
 
  }

  getFrameworkList()
  {
    this.FrameworkList = [{ "_id": 1, "frameworkName": "Development" }, { "_id": 2, "frameworkName": "Test" }];
  }

  getLanguageList(typeName)
  {
    if(typeName=="Development"){
      this.LanguageList = ['Java','DotNet','C#','Angular','Node'];
    }
    else if (typeName =="Test" ) {
      this.LanguageList = ['selenium', 'JUnit', 'JMeter', 'Jasmine'];
    
    }
  }

  GetDevTestDetails(){

    
    this.spinner.show();
    //debugger;
    this.apiService.Admin_DevTestTableDetails().subscribe((res: any) => {
      
      // console.log(res,"DevTestDetails")
      //debugger;
      res.forEach(member => {
    
        let obj = {
         
         
          "db_id":member._id,
          "tempName": member.template_name,
          "framework": member.framework,
          "language": member.language,
          "path": member.path
       
         
        }
        this.devTestFramework.push(obj)
       
      })
   
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 441) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }

 


  setVdiEditing(vdiObj: vdiTool) {
   // //debugger;
    //console.log("scmObj", scmObj)
    this.toolsGroup.framework = vdiObj.toolGroup_id;
    this.toolsGroup.path = vdiObj.Vnet;
    this.toolsGroup.language = vdiObj.Subnet;
   
    this.toolsGroup.tempName = vdiObj.name;
    this.toolsGroup.des = vdiObj.description;
   
  }

  getRecord(row){
//
    this.clearVdiDetails();
    this.vdiObj = row;
    try {
      localStorage.setItem('DevTestFramework', JSON.stringify(this.vdiObj));
      var getObj = JSON.parse(localStorage.getItem('DevTestFramework'));
      this.setVdiEditing(getObj);
    }
    catch {
      return throwError('Data not found');
    }
    this.editMode = true;
    this.showVdiForm = true;
    this.UpdateConnDisable = true;
  }
  clearVdiDetails() {
    this.toolsGroup.framework = "";
    this.toolsGroup.path = "";
    this.toolsGroup.language = "";
    this.toolsGroup.tempName = "";
    this.toolsGroup.des = "";
   
  
  }

  addDevFramework() {
    this.clearVdiDetails(); 
    this.showVdiForm = true;
    this.saveConnDisable=false;
    this.editMode=false;
  }


  refreshDevTest() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-dev-test']);
  });
  }


  onChange($event) {
    let frameworkName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = frameworkName;
  //  console.log("frameworkName",frameworkName);

   this.getLanguageList(frameworkName)

  }


  CheckAksDuplication()
  {
    if (
      this.toolsGroup.tempName == undefined|| this.toolsGroup.tempName == "" || 
      this.toolsGroup.tempName == " " || this.toolsGroup.des == undefined 
      || this.toolsGroup.des == " "  || this.toolsGroup.des == ""
     
       || this.toolsGroup.framework == "" || this.toolsGroup.language == "" 
       ||this.toolsGroup.path == " " || this.toolsGroup.path == ""
      
       || this.toolsGroup.path == undefined) {
      this.alertService.error("Please enter details In all the fields",this.options);
    }
  else { 

    
   // this.project_nameInstance = this.storyBoard.nameInstance;

    let validateBody ={
      "resource_type":"tool",
      "tool_category": "Network-Config",
      "tool_name": "Network-Config-VDI",
      "tool_instance_details": {
      "network_config_tool_instance": {
      "tool_instance_name": this.toolsGroup.nameInstance
              }
          }
      }

    this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
      ////debugger;
      // console.log("Inside Validate");
      // console.log(res.body);
      if (res.status == 200) {

        let code = res.body.code
        
         switch (code) {
           case "01":
            this.alertService.error(res.body.msg, this.options);
             break;
             case "02":
              this.alertService.error(res.body.msg, this.options);
              break;
         
           default:
             this.VdiSave();
             break;
         }
  
      }
     

    }, (error: HttpErrorResponse) => {
      if (error.status == 436) //doesnt work
      {
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("already Exist", this.options);
       
      }
      else {       
      
        this.alertService.error("Invalid Input", this.options);

        throw error;
      }
    })

  } //else
    
  }

  VdiSave() {
    
    //debugger;
     if (
      this.toolsGroup.tempName == undefined || this.toolsGroup.tempName == ""
       ||this.toolsGroup.tempName == " " || this.toolsGroup.framework == undefined 
       || this.toolsGroup.framework == " " || this.toolsGroup.framework == "" 
       || this.toolsGroup.des == " " || this.toolsGroup.des == " "
       || this.toolsGroup.language == undefined || this.toolsGroup.language == " "    
       || this.toolsGroup.language == "" || this.toolsGroup.des == undefined
        ||this.toolsGroup.path == undefined || this.toolsGroup.path == " " 
        ||this.toolsGroup.path == "") {
       this.alertService.error("Please enter details In all the fields",this.options);
     }
     else {
       var saveBody: any;
       this.project_toolGrId = this.toolsGroup.toolGr;
       try {
        var getObj = JSON.parse(localStorage.getItem('tagObj'));
       }
       catch {
        return throwError('Data not found');
       }
       
  // ##### Code for New_Save Funtionality ########
         saveBody = {
         
          "template_name" : this.toolsGroup.tempName,
          "description" :this.toolsGroup.des,
          "framework": this.toolsGroup.framework,
          "language":this.toolsGroup.language,
          "path": this.toolsGroup.path,
          
          "tags": [getObj]
       
      }

     

      //  console.log("saveBody");
        //debugger;

       this.apiService.Admin_DevTestFrameworkSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        //debugger; 
         if (res.status == 200) 
         {
 //          console.log(res);
          this.alertService.success("Data Saved Successfully",this.options);
           
           //this.router.navigate(['/admin-scm-tool']);
           this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
             this.router.navigate(['/admin-dev-test']);
         });
         }
        else if (res.status == 202) 
         {
          this.alertService.success("Data Saved Successfully",this.options);
           
           //this.router.navigate(['/admin-scm-tool']);
           this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
             this.router.navigate(['/admin-dev-test']);
         }); 
         }        
 
       }, (error: HttpErrorResponse) => {
        //

        if (error.status == 441) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
 
     }//else
 
   }
 
  onSubmit() {

  }

  updateAks() {

  }

  openDialog() {
    const dialogRef = this.dialog.open(AddTagDetailsDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px",
       
    });
  }

  closeAks() {
this.showVdiForm=false;
  }

  
}


@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./dev-test.component.scss']
})

export class AddTagDetailsDialog {


  tagObj: object[];
  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,public alertService: AlertService,private spinner: NgxSpinnerService ) {
      
     }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.tagName = "";
    this.model.tagValue = "";
  }

    ToolGrSaveData() {

      if (this.model.tagName == undefined || this.model.tagValue == undefined
      || this.model.tagName == " " || this.model.tagName == "" || this.model.tagValue == " "
      || this.model.tagValue == "") {
    }
    else {

      this.tagObj=[];
     
      let obj = {
        "name": this.model.tagName,
        "value": this.model.tagValue
      }

      this.tagObj.push(obj)
      try {
        localStorage.setItem('tagObj', JSON.stringify(this.tagObj));
      }
       catch {
        return throwError('Data not found');
       }

    }//else

  }
 

}


