<div class="scm-tool-container">
    <h2 mat-dialog-title style="color: #00529C;">Deployment Store</h2>
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <div class="col-md-6 text-center">
            <a routerLink="/admin-deploymentStore-VM" mat-dialog-close>
                <img src="assets/img/azure-vm.png" title="GitHub" class="img-fluid">
                <div class="d-block mt-3">
                  <button mat-raised-button>VM</button>
              </div>
            </a>
          </div>
          <div class="col-md-6 text-center">
            <a mat-dialog-close>
                <img src="assets/img/azure- cluster.png" title="Git Lab" class="img-fluid">
                <div class="d-block mt-3">
                    <button mat-raised-button>AKS Cluster</button> 
                </div>            
            </a>
          </div>
          
      </div>
    </mat-dialog-content>
  </div>