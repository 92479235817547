import { Component, OnInit } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface vdiTool {
  toolName: string;
  ID: string;
  azureId: string;
  db_id: string;
  name: string;
  description: string;
  Vnet: string;
  toolGroup: string;
  toolGroup_id: string;
  Subnet: string;
  ResourceGroup: string;
  VMResourceGroup: string;
  azure_subscription_id:string;
}


export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}

@Component({
  selector: 'app-admin-vdi',
  templateUrl: './admin-vdi.component.html',
  styleUrls: ['./admin-vdi.component.scss']
})
export class AdminVdiComponent implements OnInit,CanComponentLeave {
  showVdiForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  checklist: any;
  project_selectedcheckbox = false;
  masterSelected: boolean;

  VdiConfig_details:any=[];
  check:any=[]

  checkedList: any;

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];

  ResourceGroupArr = [];
  VnetArr = [];
  SubnetArr = [];

  project_VNetResourceGroup:string;
  project_VMResourceGroup:string;
  project_Vnet:string;
  project_Subnet:string;

  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  AzuredrpDisable = false;
  toolGrDisable=false;
  NameDisable=false;

  ResourceDisable=false;
  VnetDisable=false;
  SubDisable=false;
  SCMArray = [];
  project_toolGrId: string;
  vdiObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;
  Role:string="";
  SubscriptionList: Subscription_iddropdown[] = [];
  itemSelected:boolean=false;
  selectedTool_group_name: any;
  nextUrl: any;

  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.rgdd == '' || this.toolsGroup.rgdd == undefined) &&
      (this.toolsGroup.Vnetdd == '' || this.toolsGroup.Vnetdd == undefined) &&
       (this.toolsGroup.Subnetdd == '' || this.toolsGroup.Subnetdd == undefined)  &&
       (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined)  &&
       (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
       (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined)  &&
       (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)  &&
       (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
        (this.toolsGroup.azureId == '' || this.toolsGroup.azureId == undefined)
         && (this.toolsGroup.proToken == '' || this.toolsGroup.proToken == undefined)){
        return true
      }
      if ((this.toolsGroup.rgdd != '' || this.toolsGroup.rgdd != undefined) &&
      (this.toolsGroup.Vnetdd != '' || this.toolsGroup.Vnetdd != undefined) &&
       (this.toolsGroup.Subnetdd != '' || this.toolsGroup.Subnetdd != undefined)  &&
       (this.toolsGroup.toolGr != '' || this.toolsGroup.toolGr != undefined)  &&
       (this.toolsGroup.description != '' || this.toolsGroup.description != undefined)  &&
       (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr != undefined)  &&
       (this.toolsGroup.nameInstance != '' || this.toolsGroup.nameInstance != undefined)  &&
       (this.toolsGroup.description != '' || this.toolsGroup.description != undefined)  &&
        (this.toolsGroup.azureId != '' || this.toolsGroup.azureId != undefined)
         && (this.toolsGroup.proToken != '' || this.toolsGroup.proToken != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) {
      this.masterSelected = false;

     }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.getTypeT();
    this.dtOptions = {
      order: []
    };
    var PopupToolGroup = localStorage.getItem('Popup_Open_Vdi')
    if (PopupToolGroup == "true") {
      this.showVdiForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Vdi')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
     // console.log("this.toolsGroup.toolGr");
      //console.log(this.toolsGroup.toolGr);
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Vdi')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Vdi", "false");
    } else {
      this.showVdiForm = false;
    }
    this.getSubscriptionList();
    this.GeVditableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  backNavigation(){
    if ((this.toolsGroup.rgdd == '' || this.toolsGroup.rgdd == undefined) &&
     (this.toolsGroup.Vnetdd == '' || this.toolsGroup.Vnetdd == undefined) &&
      (this.toolsGroup.Subnetdd == '' || this.toolsGroup.Subnetdd == undefined)  &&
      (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined)  &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
      (this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined)  &&
      (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)  &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
       (this.toolsGroup.azureId == '' || this.toolsGroup.azureId == undefined)
        && (this.toolsGroup.proToken == '' || this.toolsGroup.proToken == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  getSubscriptionList(): void {
   // debugger
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      //this.apiService.Admin_AzureTableDetails().subscribe(data => {
      if (res) {
        // console.log(res, "data");
     //   debugger
        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name
          }
          this.SubscriptionList.push(obj)
        })
        // console.log(this.SubscriptionList, "SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }
  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
   // debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  
  DeleteConfig(){
    // debugger;
     var deleteBody: any;
     this.check=[]
     this.VdiConfig_details.forEach(element => {
       this.check.push(element.vdi_dropdown_id)
       
     });
     deleteBody = {
       "tool_ids":this.check
    
     }
    
     this.apiService.NetworkConfigVdiDropdowDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-vdi']);
         });
         this.alertService.success(res.body.message, this.options);
       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
  GeVditableDetails() {

    this.spinner.show();
    this.apiService.Admin_VdiToolGrTableDetails().subscribe((res: any) => {
      // 
      //console.log(res, "OUTPut")
      // //debugger;
      res.forEach(member => {

        let VMResourceGroupAssign;
        if (!member.tool_instance_details.network_config_tool_instance.vm_resource_group){
          VMResourceGroupAssign = member.tool_instance_details.network_config_tool_instance.resource_group
        }else{
          VMResourceGroupAssign = member.tool_instance_details.network_config_tool_instance.vm_resource_group
        }
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "azureId":member.tool_instance_details.network_config_tool_instance.azure_tool_instance_id,
         "azure_subscription_id":member.tool_instance_details.network_config_tool_instance.azure_subscription_id,
          "db_id": member._id,
          "name": member.tool_instance_details.network_config_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.network_config_tool_instance.description,
          "Vnet": member.tool_instance_details.network_config_tool_instance.vnet,
          "Subnet": member.tool_instance_details.network_config_tool_instance.subnet,
          "ResourceGroup": member.tool_instance_details.network_config_tool_instance.resource_group,
          "VMResourceGroup": VMResourceGroupAssign,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }

  getResourceGroup(SubscriptionId): void {
    //debugger;
    let ResourceBody = {     
      "subscriptionId":SubscriptionId
    }

      this.apiService.Admin_ResourceGroupDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      //  debugger;
        // console.log(res,"Resource Group Body");
        if (res.status == 200) //doesnt work
        {
          this.ResourceGroupArr=[];
          for (let i = 0; i < res.body.value.length; i++) {
            //@author-priyanka kale - value should  be null instead of undefined
            if (res.body.value[i] != null) {
              //@author -priyanka kale - dropdown should have id and name not only name value
              // this.ResourceGroupArr.push(res.body.value[i].name);
              this.ResourceGroupArr.push(res.body.value[i]);
            }
          }
        }
        if (res.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);
        }
      },(error:HttpErrorResponse) => {
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);  
        }
        else if (error.status == 403){}
        else
        {
        this.alertService.error("Invalid Input",this.options);  
        throw error;
        }
      })


  }

  getVnetValue(SubscriptionId, ResourceGroup): void {
    //debugger;
    let ResourceBody = {     
      "subscriptionId":SubscriptionId,
      "ResourceGroups":ResourceGroup
    }

      this.apiService.Admin_VnetDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      //  debugger;
        // console.log(res,"Vnet Body");
        if (res.status == 200) //doesnt work
        {
          this.VnetArr=[];
          for (let i = 0; i < res.body.value.length; i++) {
             //@author-priyanka kale - value should be null instead of undefined
             if (res.body.value[i] != null) {
              // @author-priyanka kale- dropdown should have name and id both values not only name
              // this.VnetArr.push(res.body.value[i].name);
              this.VnetArr.push(res.body.value[i]);
            }
          }
        }
        if (res.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);
        }
      },(error:HttpErrorResponse) => {
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);  
        }
        else if (error.status == 403){}
        else
        {
        this.alertService.error("Invalid Input",this.options);  
        throw error;
        }
      })


  }

  getSubnetValue(SubscriptionId, ResourceGroup, Vnet): void {
    //debugger;
    let ResourceBody = {     
      "subscriptionId":SubscriptionId,
      "ResourceGroups":ResourceGroup,
      "virtualNetworkName":Vnet
    }

      this.apiService.Admin_SubnetDrp(ResourceBody).subscribe((res: HttpResponse<any>) => {
      //  debugger;
        // console.log(res,"Subnet Body");
        if (res.status == 200) //doesnt work
        {
          this.SubnetArr=[];
          for (let i = 0; i < res.body.value.length; i++) {
            //@author-priyanka kale - value should be null instead of undefined
            if (res.body.value[i] != undefined) {
              // @author-priyanka kale - subnet dropdown should have id and name not only name value
              // this.SubnetArr.push(res.body.value[i].name);
              this.SubnetArr.push(res.body.value[i]);
            }
          }
        }
        if (res.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);
        }
      },(error:HttpErrorResponse) => {
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("Please Enter Valid Inputs",this.options);  
        }
        else if (error.status == 403){}
        else
        {
        this.alertService.error("Invalid Input",this.options);  
        throw error;
        }
      })


  }

  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        // console.log("SCM");
        //console.log(data.body);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }


  setVdiEditing(vdiObj: vdiTool) {
   // debugger; 
   
    this.toolsGroup.toolGr = vdiObj.toolGroup_id;
    this.get_EditeAzureDetails(vdiObj.azureId);
   var azure_subscription_id=vdiObj.azure_subscription_id;
    this.getResourceGroup(azure_subscription_id);
    this.toolsGroup.rgdd=vdiObj.ResourceGroup;

   //  this.toolsGroup.ResourceGroup = vdiObj.ResourceGroup;
    this.getVnetValue(azure_subscription_id, this.toolsGroup.rgdd);
    this.toolsGroup.Vnetdd=vdiObj.Vnet;

    this.getSubnetValue(azure_subscription_id, this.toolsGroup.rgdd, this.toolsGroup.Vnetdd);
    this.toolsGroup.Subnetdd = vdiObj.Subnet
    this.toolsGroup.rgvm = vdiObj.VMResourceGroup


    this.project_Vnet=this.toolsGroup.Vnetdd
    this.project_Subnet=this.toolsGroup.Subnetdd;
    this.project_VNetResourceGroup=this.toolsGroup.rgdd;
    this.project_VMResourceGroup=this.toolsGroup.rgvm;
    
    this.project_id_AksEdit = vdiObj.ID;
    this.project_id_db = vdiObj.db_id;
    this.toolsGroup.nameInstance = vdiObj.name;
    this.toolsGroup.description = vdiObj.description;
   
  }

  getRecord(row) {
    //
    this.clearVdiDetails();
    this.vdiObj = row;
    
    try {
      localStorage.setItem('vdiToolObj', JSON.stringify(this.vdiObj));
      var getObj = JSON.parse(localStorage.getItem('vdiToolObj'));
      this.setVdiEditing(getObj);
    }
    catch {
      return throwError('Data not found');
    }
    this.editMode = true;
    this.showVdiForm = true;
    this.UpdateConnDisable = true;
    this. ResourceDisable=false;
    this.VnetDisable=false;
    this.SubDisable=false;
    this.AzuredrpDisable = true;
    this.toolGrDisable=true;
    this.NameDisable=true;
  }
  clearVdiDetails() {
    this.toolsGroup.Vnetdd = "";
    this.toolsGroup.nameInstance = "";
    this.toolsGroup.description = "";
    this.toolsGroup.Subnetdd = "";
    this.toolsGroup.rgvm = "";

    this.toolsGroup.toolGr = "";
    this.toolsGroup.rgdd = "";
    this.toolsGroup.azureId = "";
    this.ResourceGroupArr=[];
    this.VnetArr=[];
    this.SubnetArr=[];
   this. ResourceDisable=false;
    this.VnetDisable=false;
    this.SubDisable=false;
this.UpdateConnDisable=true;
this.AzuredrpDisable = false;
this.toolGrDisable=false;
this.NameDisable=false;
  }

  addVdi() {
    this.clearVdiDetails();
    this.showVdiForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshVdi() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-vdi']);
    });
  }

  onChange($event) {
    let AksToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = AksToolGrName;
    // console.log("scmToolGrName", AksToolGrName);
  }

  onChangeVnet($event) {
    let VnetValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_Vnet = VnetValue;
    // console.log("onChangeVnet", this.project_Vnet);

    //*** Get Vnet Value from Azure ***//
    this.getSubnetValue(this.vdi_SubId, this.project_VNetResourceGroup, this.project_Vnet)

  }

  onChangeSubnet($event) {
    let SubnetValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_Subnet = SubnetValue;
    // console.log("onChangeSubnet", this.project_Subnet);
  }

  onChangeResourceGoup($event) {
    let ResourceGroupValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_VNetResourceGroup = ResourceGroupValue;
    
    // console.log("ResourceGroupValue", this.project_VNetResourceGroup);

    //*** Get Vnet Value from Azure ***//

    this.getVnetValue(this.vdi_SubId, this.project_VNetResourceGroup)
    this.VnetArr=[];

  this.SubnetArr=[];

  }

  onChangeVMResourceGoup($event) {
    
    let VMResourceGroupValue = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_VMResourceGroup = VMResourceGroupValue;
  }


  onChangeAzureId($event) {
   // debugger
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;
    // console.log("Azure selected value", azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    // console.log("Split Value ", i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
  //  debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
    //  debugger
      if (res) {
        // console.log(res, "Id details");


        res.forEach(member => {


          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;


        })

       
        localStorage.setItem("vdi_SubId", this.vdi_SubId);
        // localStorage.setItem("vdi_tenantId",  this.vdi_tenantId);

      //  debugger;
        //*** Get Resource Group From Azure ***//
        this.getResourceGroup(this.vdi_SubId)

      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  get_EditeAzureDetails(azureId) {
  //  debugger
    this.spinner.show();
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
    //  debugger
      if (res) {
        // console.log(res, "Id get_EditeAzureDetails");


        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
      this.spinner.hide();
  }

  CheckAksDuplication() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


    //debugger;

    if (

    this.toolsGroup.rgdd == undefined || this.toolsGroup.rgdd == "" || this.toolsGroup.rgdd == " "
    || this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " " 
    || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.Subnetdd == "" || this.toolsGroup.Subnetdd == " " 

      || this.toolsGroup.toolGr == undefined  || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" 
      || this.toolsGroup.nameInstance == ""  || this.toolsGroup.nameInstance == ""
      || this.toolsGroup.description == " " || this.toolsGroup.description == ""
      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving VDI Name ", this.options);
        return;
      }
      if(sp.test(this.toolsGroup.nameInstance)){
      
        this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving VDI Name ", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving  VDI Description ", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving VDI Description ", this.options);
        return;
      }
      let validateBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-VDI",
        "tool_instance_details": {
          "network_config_tool_instance": {
            "tool_instance_name": this.toolsGroup.nameInstance
          }
        }
      }

      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
        ////debugger;
        // console.log("Inside Validate");
        // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.VdiSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  VdiSave() {
    
   //debugger;
    if (
    
       this.toolsGroup.rgdd == undefined || this.toolsGroup.rgdd == "" || this.toolsGroup.rgdd == " "
      || this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " " 
      || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.Subnetdd == "" || this.toolsGroup.Subnetdd == " " 
      || this.toolsGroup.toolGr == undefined  || this.toolsGroup.toolGr == " " || this.toolsGroup.nameInstance == undefined
      || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == ""
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " 
      || this.toolsGroup.description == " " || this.toolsGroup.description == ""
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " "
      || this.toolsGroup.azureId == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving VDI Name ", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving VDI Name ", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving VDI Description ", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving VDI Description ", this.options);
        return;
      }
      var saveBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########
      saveBody = {
        "resource_type": "tool",
        "tool_category": "Network-Config",
        "tool_name": "Network-Config-VDI",
        "tool_group_details": {
          "tool_group_id": this.project_toolGrId,
          "tool_group_name": this.project_toolGrName
        },
        "tool_instance_details": {
          "network_config_tool_instance": {
            "azure_tool_instance_id": this.vdi_Azure_Id,
            "azure_subscription_id": this.vdi_Subscription_ID,
            "azure_tenant_id": this.vdi_tenantId,
            "tool_instance_name": this.toolsGroup.nameInstance,

            "description": this.toolsGroup.description,
            "vnet": this.project_Vnet,
            "subnet": this.project_Subnet,
            "resource_group": this.project_VNetResourceGroup,
            "vm_resource_group": this.project_VMResourceGroup
          }
        },
        "created_by":localStorage.getItem('mail').toLowerCase()

      }
      //console.log("saveBody");
      // //debugger;

      this.apiService.Admin_Azure_Vdi_SaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        //  //debugger; 
        if (res.status == 200) {
          //          console.log(res);
          // this.alertService.success("Data Saved Successfully", this.options);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi']);
          });
          this.alertService.success(res.body.message, this.options);

        }
        else if (res.status == 202) {
          // this.alertService.success("Data Saved Successfully", this.options);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi']);
          });
          this.alertService.success(res.body.message, this.options);

        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {

  }
  cancelok(){
    this.clearVdiDetails();

  if(this.nextUrl==undefined){
    this.refreshVdi()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  closeAks() {
    this.showVdiForm = false;
    this.editMode = false;
    this.refreshVdi()

  }
  updateVdi() {
   // debugger;
    if (
     
       this.toolsGroup.rgdd == undefined || this.toolsGroup.rgdd == "" || this.toolsGroup.rgdd == " "
      || this.toolsGroup.Vnetdd == undefined || this.toolsGroup.Vnetdd == "" || this.toolsGroup.Vnetdd == " " 
      || this.toolsGroup.Subnetdd == undefined || this.toolsGroup.Subnetdd == "" || this.toolsGroup.Subnetdd == " " 
      || this.toolsGroup.toolGr == undefined  || this.toolsGroup.toolGr == " " || this.toolsGroup.nameInstance == undefined
      || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == ""
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " "
       || this.toolsGroup.description == " " || this.toolsGroup.description == ""
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " "
      || this.toolsGroup.azureId == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      var updateBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########
      
      this.ToolGroupList.forEach(element => {
        if(element.tool_group_id == this.project_toolGrId){
         this.selectedTool_group_name = element.tool_group_name
        }
      });
      updateBody = {
        "category":"VDI",
        "tool_id":this.project_id_AksEdit,
        "tool_group_id": this.project_toolGrId,
        "tool_group_name": this.selectedTool_group_name,

        "azure_subscription_id": this.vdi_Subscription_ID,
            "azure_tenant_id": this.vdi_tenantId,
            "tool_instance_name": this.toolsGroup.nameInstance,
            "description": this.toolsGroup.description,
           
            "vnet": this.project_Vnet,
            "subnet": this.project_Subnet,
            "azure_tool_instance_id": this.vdi_Azure_Id,
        "resource_group": this.project_VNetResourceGroup,
        "vm_resource_group":this.project_VMResourceGroup,
        "updated_by":localStorage.getItem('mail').toLowerCase()
        }
      this.apiService.Admin_Azure_Vdi_Update(updateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi']);
            // this.alertService.success("Data Updated Successfully", this.options);
            this.alertService.success(res.body.message, this.options);

          });
        }
        else if (res.status == 202) {
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi']);
            // this.alertService.success("Data Updated Successfully", this.options);
            this.alertService.success(res.body.message, this.options);

          });
        }

      },(error: HttpErrorResponse) => {
        if (error.status == 430||error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupVdiDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"

    });
  }
}


@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-vdi.component.scss']
})

export class AddToolGroupVdiDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
        
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Vdi", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Vdi", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Vdi", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-vdi']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}
