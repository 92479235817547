<div class="pop-up">
 <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0"> Meeting Details</span> 
        <span (click)="exit()" class="material-icons close-icon">
            close
          </span>
 </div>
 <hr class="margin-class" />
<div class="popup-container">
<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Meeting Name </span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{meetingInfoName}}</span>
                </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Meeting Type </span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{meetingInfoType}}</span>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Project Name </span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{projectPopName}}</span>
    </div>
</div>

<div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">Start Date </span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{startDate}}</span>
    </div>
</div>
<!-- <div class="row pt-2">
    <div class="col-md-4">
        <span class="field-name">End Date </span>
    </div>
    <div class="col-md-6">
            <span> : &nbsp;{{endDate}}</span>
    </div>
</div> -->
</div>
</div>