<div class="pop-up">
    <div class="">
      <span  class="vertical-line mx-2 mb-1"> </span>
       <span class="header mb-0">Update Primary Manager</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
  
      <hr class="margin-class" />
      <div class="popup-container justify-content-center">
        <div class="noData offset-md-5" *ngIf="dataSource.filteredData.length==0">
          <h2>No Users Available</h2>
        </div>
  
        <div class="example-container1" *ngIf="dataSource.filteredData.length!=0">
          <table mat-table [dataSource]="dataSource">
      
      
            <!-- Name Column -->
            <ng-container matColumnDef="user_name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
            </ng-container>
      
            <!-- Email Column -->
            <ng-container matColumnDef="user_email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
            </ng-container>

         
		<ng-container matColumnDef="selection">
			<th mat-header-cell *matHeaderCellDef> X </th>
			<td mat-cell *matCellDef="let element; let rowIndex = index;">
				<mat-radio-button color="primary"
         name="rhSelectionRadio" 
         [value]="rowIndex" 
         
          (click)="selectRow($event, element)"
				 [checked]="element.selected">
				</mat-radio-button>

			</td>
		</ng-container>
              
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns">
            </tr>
          </table>
      
      
        </div>
      </div>
    
      <div class="row mt-4" *ngIf="dataSource.filteredData.length!=0">
        <div class="col-12 offset-md-9 text-center col-md-3">
    
          <button mat-button  mat-raised-button class="add-button" (click)="updatePM()">Update</button>
        </div>
      </div>
    </div>