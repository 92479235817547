<div class="pop-up">
           <span  class="vertical-line mx-3"> </span>
            <span class="header mb-0"> VM Request Details</span> 
           <span (click)="exit()" class="material-icons close-icon">
               close
             </span>
    <hr class="margin-class" />
   <div class="popup-container">
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Application Name </span>
       </div>
       <div class="col-md-6">
            <span> : {{info.application_name}}</span>
       </div>
   </div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Location </span>
       </div>
       <div class="col-md-6">
               <span> : {{info.location}}</span>
       </div>
   </div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">OS Disk Type </span>
       </div>
       <div class="col-md-6">
               <span> : {{info.os_disk_type}}</span>
       </div>
   </div>
   
   <div class="row pt-2">
       <div class="col-md-4">
           <span class="field-name">Resource Group </span>
       </div>
       <div class="col-md-6">
               <span> : {{info.resource_group}}</span>
       </div>
   </div>
   </div>
   </div>