<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

  <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">VDI Images</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-6 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span routerLink="/vdi-config-setting">
          <span (click)="setFormMode()"><i class="fas fa-plus"></i> Add</span>
        </span>
        <span  (click) = "deleteRecord()" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

          <span (click)="refreshVdi()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span routerLink="/admin-catalog-config"><i class="step-back-icon fa fa-step-backward "></i> Back</span>          
         
        </span>
      </div>
    
    </div>
          
      
          <h1 class="text-light mb-0">
            <img width="40" src="assets/img/VDI-Icon-White.svg">
            <div class="vertical-line mx-3"> </div> <span>VDI Images</span>      
          </h1>
      
        </div>

        <div class="inner-mid-container">
            <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog"
      aria-labelledby="confirmationModal2" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content pop-up">
          <div class="modal-header-popup">
            <span class="vertical-line mx-3"> </span>
            <span class="header mb-0">VDI Images</span>
            <span data-dismiss="modal" class="material-icons close-icon">
              close
            </span>
          </div>
          <hr class="margin-class" />
          <div class="modal-body">
            Are you sure want to Delete?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
          </div>
        </div>
      </div>
    </div>
          <div class="mt-5 table-container">
            <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
            <thead>
              <tr>
                  <th style="width: 15px;">
                      <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()" />
                    </th>
      
                <th style="width: 10%;">ID</th>
                <th style="width: 10%;">Azure Tool ID</th>
                <th style="width: 10%;">VDI Type</th>
                <th style="width: 10%;">Endpoint-Security</th>
                <th style="width: 20%;">Image Name</th>
                <th style="width: 10%;">Tool Group Name</th>
                <th style="width: 10%;">Location</th>
                <th style="width: 10%;">Type</th>
                <th style="width: 5%;">Info</th>
              </tr>
            </thead>
            <tbody class="data-table-height">
              <tr *ngFor="let catelog of CatlogArray">
                  <td style="width: 15px;">
                      <input type="checkbox" [(ngModel)]="catelog.isSelected" name="list_name" value="{{catelog.id}}"
                        (change)="isAllSelectedcheck()" />
                    </td>
                <!-- <td style="width: 10%;"><span class="link-col">{{catelog.toolID}}</span></td> -->
                <td style="width: 10%;">{{catelog.toolID}}</td>

                <td style="width: 10%;">{{catelog.azure_tool_instance_id}}</td>
                <td style="width: 10%;">{{catelog.vdi_type}}</td>
                <td style="width: 10%;">{{catelog.endpointsecurity}}</td>
                <td style="width: 20%;">{{catelog.image_name}}</td>
                <td style="width: 10%;">{{catelog.tool_group_name}}</td>
                <td style="width: 10%;">{{catelog.location}}</td>
                <td style="width: 10%;" class="limit-text">{{catelog.type}}</td>
                <td style="width: 5%;"> <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfo(catelog)">info</span></td>
              </tr>
        
            </tbody>
          </table>
        </div>
        </div>

        </div>
</div>

