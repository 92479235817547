<div class="pop-up">
    <span class="vertical-line mx-0 mx-md-3"> </span>
    <span class="header mb-0"> Member Details</span>
    <span (click)="closeDialog()" class="material-icons close-icon close-btn">
        close
    </span>
</div>

<hr class="margin-class" />

<div class="row">
    <div class="col-12 col-md-6 col-lg-4 img__wrap userRow" *ngFor="let user of NewmemberList">
            
            <img src="../../../assets/img/UserIcon.png"><span style="font-size: 18px;" class="position-icons material-icons show-project-info" title="Member Information"
            (click)="showMemberInfo(user.name)">info</span><br>
            {{user.name}}
            
            <br>
            <!-- <span class="img__description_layer img__details card">
                  <img src="../../../assets/img/UserIcon.png">
                  <h3 class="font-14">{{user.name}}</h3>
                  
                 
                </span> -->
        
    </div>


</div>