// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-aws-workspace',
//   templateUrl: './aws-workspace.component.html',
//   styleUrls: ['./aws-workspace.component.scss']
// })
// export class AwsWorkspaceComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
///
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { AuthService } from 'src/app/service/api.service';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-aws-workspace',
  templateUrl: './aws-workspace.component.html',
  styleUrls: ['./aws-workspace.component.scss']
})
export class AwsWorkspaceComponent implements OnInit {
  secondFormGroup: FormGroup;
  compute_type_nameVal = [];
  running_modeVal = [];
  root_volume_size_gbVal = [];
  user_volume_size_gbVal = [];
  bundle_idVal = ["wsb-bck6dktxg"]
  directory_idVal = ["d-96671ddfca"]
  showRepo: boolean = false;
  projectId: any;
  projectName: any;
  catalog_id: any;
  catalog_name: any;
  showUserRow: boolean = false;
  flag: boolean = false;

  selectedUserArray: any = []
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  tableRepoArray: any = [];
  vdiTableData: any = [];
  tableArr: any = [];
  awsData: any;
  showAwsTable: boolean = false;
  showForm: boolean = false;
  Role: string = "";
  template_id: any;
  template_name: any;
  ImageName: any;
  ImageValue: any;
  NetworkName: any;
  NetworkValue: any;
  ComputeName: any;
  ComputeValue: any;
  RunningName: any;
  RunningValue: any;
  Network: any[];
  ImageNameData: any[];
  computeData: any;
  runningData: any;
  ComputeValueRootVolumeSize: any;
  ComputeValueUserVolumeSize: any;
  memberArray:any = [];
  awsConfig: any;
  toolgrp: any;
  getTableData: any=[];
  filterStatus: any;
  refreshDisable: boolean;
  ImageinfoMap={}
  NetworkinfoMap= {};
  ComputeinfoMap={};
  RunninginfoMap={};
  masterSelected: boolean;
  project_selectedcheckbox: boolean;
  checkedList: any[];
  checklist: any;
  VdiConfig_details: any[];
  manageDisable: boolean=true;
  itemSelected: boolean;
  checkDisable = true;


  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService, private _formBuilder: FormBuilder, public router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.secondFormGroup = this._formBuilder.group({
        bundle_id: [null, Validators.required],
        directory_id: [null, Validators.required],
        compute_type_name: [null, Validators.required],
        // root_volume_size_gb: [null, Validators.required],
        running_mode: [null, Validators.required],
        // user_volume_size_gb: [null, Validators.required]
      });

      this.apiService.componentName.next("AWSWorkspace");

      this.projectId = localStorage.getItem('projectId');



      this.projectName = localStorage.getItem('projectName');
      this.flag=false
      this.selectedUserArray = [];

      this.catalog_id = localStorage.getItem('catalogId');

      this.catalog_name = localStorage.getItem('catalogName');
      this.apiService.getProjectById(this.projectId).subscribe((data: any[]) => {
        // debugger

        this.template_id = data[0].template_id;
        this.template_name = data[0].template_name;

      },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }
  
      })
      let newCatalog_id = localStorage.getItem('catalogId');
      this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
        // if (data.deploymentstore_tools.length == 0) {
        //   this.deploymentFlag = true;
        // }
      },
      (error: HttpErrorResponse) => {
        if (error.status == 440) {
          this.alertService.error(error.error.message, this.options);
        }
        else {
          this.alertService.error("Unable to start.", this.options);
          throw error;
        }
  
      })
      // this.getAllAwsStatusWorkspace()
      // this.getAllAwsStatusFilterWorkspace()
      this.getAWSWorkspace()
      this.GetDropdownValueWorkspace()
      this.getCatalogByIdImage(this.catalog_id, "AWS-Workspace-Image")
      this.getCatalogByIdNetwork(this.catalog_id, "AWS-Workspace-Network-Config")
      // this.getAWSVdi();
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }
  checkUncheckAll() {
    // console.log(this.VdiConfig_details);
    // console.log(this.checklist);
    
    

    
    
    for (var i = 0; i < this.checklist.length; i++) {
      if(
        this.checklist[i].workspace.approval=='Stopped' || this.checklist[i].workspace.approval=='Running'
      ){
        this.checklist[i].isSelected = this.masterSelected;

      }
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    
    this.masterSelected = this.checklist.every(function (item: any) {
      if(item.isSelected){
        // console.log(item);
        
      }
      
      return item.isSelected == true;
    })
    // console.log(this.masterSelected);
    
    

    this.getCheckedItemList();
  }
  getCheckedItemList() {
    
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    // console.log(this.checkedList);
    //console.log(this.checklist);
    
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push([this.checklist[i].aws_config_tool_id,this.checklist[i].workspace.workspace_id]);

    }
    // console.log(this.checkedList);
    
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index][0];
                  var SelectedItemStatus=this.checkedList[index][1];

                 let VdiConfigBoby={
                    "request_id":SelectedItem,
                    "status":SelectedItemStatus,

                    }
                   this.VdiConfig_details.push(VdiConfigBoby);


                  
                 // For Loop of tag

                // Add image des 
                               
             }
            //  console.log(this.VdiConfig_details);
             if(this.VdiConfig_details.length==0 ){
               this.manageDisable=true
               
             }
             else if(this.VdiConfig_details.length!=0 ){
              this.manageDisable=false
              
            }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  startvm(){
    // console.log(this.VdiConfig_details);
    var arr=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      arr.push(this.VdiConfig_details[i].status)
    }
    let body={

      "aws_config_tool_id": this.VdiConfig_details[0].request_id,
  
      "workspaceId": arr
  
  }
  
    
  }
  onChangeImageName($event) {


    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.ImageName = $event.target.options[$event.target.options.selectedIndex].text;
    this.ImageValue = $event.target.options[$event.target.options.selectedIndex].value;
    // console.log(this.ImageValue);
    


    // console.log(this.diskDisplayName);
    // console.log(this.diskPropertyName);




  }
  onChangeNetworkConfig($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.NetworkName = $event.target.options[$event.target.options.selectedIndex].text;
    this.NetworkValue = $event.target.options[$event.target.options.selectedIndex].value;


    // console.log(this.diskDisplayName);
    // console.log(this.diskPropertyName);




  }
  onChangeComputeTypeName($event) {
    // debugger
    // console.log(this.compute_type_name);
    // console.log();
    
    

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.ComputeName = $event.target.options[$event.target.options.selectedIndex].text;
    this.ComputeValueRootVolumeSize = $event.target.options[$event.target.options.selectedIndex].value;
    this.ComputeValueUserVolumeSize=$event.target.options[$event.target.options.selectedIndex].title;


    // console.log(this.diskDisplayName);
    // console.log(this.diskPropertyName);




  }
  getCatalogByIdImage(id, type) {
    this.apiService.getCatalogById(id, type).subscribe((data: any) => {
      this.ImageNameData = data.Tool_list
      // console.log(this.ImageNameData);
      
      this.toolgrp=data.Tool_list[0].tool_group_details.tool_group_id
      for (let i = 0; i < this.ImageNameData.length; i++) {
        let key = this.ImageNameData[i].tool_id
        let val = this.ImageNameData[i].tool_instance_details.aws_workspace_image_tool_instance
        this.ImageinfoMap[key] = val
      }

      // console.log(data);




    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })
  }
  getCatalogByIdNetwork(id, type) {
    this.apiService.getCatalogById(id, type).subscribe((data: any) => {
      // debugger
      this.Network = data.Tool_list
      for (let i = 0; i < this.Network.length; i++) {
        let key = this.Network[i].tool_id
        let val = this.Network[i].tool_instance_details.aws_workspace_network_config_tool_instance
        this.NetworkinfoMap[key] = val
      }
      // console.log(this.NetworkinfoMap);
      


    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })
  }
  getAllAwsStatusWorkspace() {
    this.apiService.getAllAwsStatusWorkspace(this.projectId).subscribe((data: any[]) => {
      // console.log("1");
      //  this.ImageName=data
      // console.log(data);



    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })
  }
  showPopupInfo(type){

    if (type == "Image" && this.ImageValue != undefined) {
      const dialogRef = this.dialog.open(ImageInfoPopWS, {
        width: '53%',
        height: '40%',
        disableClose: true,
        data: {
          ImageInfo: this.ImageinfoMap[this.ImageValue],
          type: type
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }

    if (type == "Network" && this.NetworkName != undefined) {
      // console.log(this.NetworkName);
      // console.log(this.NetworkinfoMap);
      
      
      const dialogRef = this.dialog.open(NetworkInfoPopWS, {
        width: '53%',
        height: '30%',
        disableClose: true,
        data: {
          ImageInfo: this.NetworkinfoMap[this.NetworkName],
          type: type
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    if (type == "Compute" && this.ComputeName != undefined) {
      // console.log(this.NetworkName);
      // console.log(this.NetworkinfoMap);
      
      
      const dialogRef = this.dialog.open(ComputeInfoPopWS, {
        width: '53%',
        height: '30%',
        disableClose: true,
        data: {
          ImageInfo: this.ComputeinfoMap[this.ComputeName],
          type: type
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    if (type == "Running" && this.RunningName != undefined) {
      // console.log(this.RunningName);
      // console.log(this.RunninginfoMap);
      
      
      const dialogRef = this.dialog.open(RunningInfoPopWS, {
        width: '53%',
        height: '30%',
        disableClose: true,
        data: {
          ImageInfo: this.RunninginfoMap[this.RunningName],
          type: type
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  getAllAwsStatusFilterWorkspace() {
    this.apiService.getAllAwsStatusFilterWorkspace(this.projectId, "Active").subscribe((data: any[]) => {
      // console.log("2");
      //  this.ImageName=data
      this.getTableData=data

      // console.log(data);



    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })
    // this.apiService.getAllAwsEc2StatusFilter(this.projectId, this.filterStatus).subscribe((data: any[]) => {
  

    //   this.getTableData=data
    //   for (let i = 0; i < this.getTableData.length; i++) {
        
    // let tableObj =
    // {
    //   "request_id": this.getTableData[i].request_id,
    //   "os": this.getTableData[i].ec2.os,
    //   "type": this.getTableData[i].ec2.ec2_type,
    //   "name": this.getTableData[i].ec2.name,
    //   "ip_address": this.getTableData[i].ec2.ip_address,
    //   "members": this.getTableData[i].ec2.approval,
    //   "approval": this.getTableData[i].ec2.approval,

    // }

    //   }
 

    // })

  }
  showImageInfo(element){
    const dialogRef = this.dialog.open(requestImageInfo, {
      width: '50%',
      height: '30%',
      disableClose: true,
      data:{
        info: element,

      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    





  }
  GetDropdownValueWorkspace() {
    this.apiService.GetDropdownValueWorkspace().subscribe((data: any) => {
      // console.log("3");
      // console.log(data);
      // debugger
      this.computeData = data.awsPropertyList[0].compute_types
      this.runningData = data.awsPropertyList[0].running_mode
      for (let i = 0; i < this.computeData.length; i++) {
        let key = this.computeData[i].compute_type_name
        let val = this.computeData[i].description
        this.ComputeinfoMap[key] = val
      }

      for (let i = 0; i < this.runningData.length; i++) {
        let key = this.runningData[i].running_mode_name
        let val = this.runningData[i].description
        this.RunninginfoMap[key] = val
      }
      // console.log(this.RunninginfoMap);
      


    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })

  }
  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
          member: memberArray
        }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  getAWSWorkspace() {
    this.getTableData = [];
    this.tableRepoArray = [];
    this.refreshDisable = false;

    setTimeout(() => {
      this.refreshDisable = true;
    }, 2000)
    this.getAllAwsStatusWorkspace()

    this.getStatus('Active')
    this.projectId = localStorage.getItem('projectId');

  }
  getStatus(status){
    // console.log(status);
    this.spinner.show()
    this.filterStatus = status;
    this.apiService.getAllAwsStatusFilterWorkspace(this.projectId, this.filterStatus).subscribe((data: any[]) => {
      // debugger
// console.log(data);

      this.getTableData=data
      this.checklist = this.getTableData;

      this.spinner.hide()

      

    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    })


  
        // this.router.navigate(['/awsVdi'])

  }
  downloadData(type){
    if(type=='pdf'){
      this.apiService.downloadAwsVdiInPdf(this.projectId,this.filterStatus).subscribe(data=>{
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName+'-AwsVDI.pdf'
        link.click();
      })
    }
    else if(type=='excel'){
      this.apiService.downloadAwsVdiInExcel(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-AwsVDI.xlsx';
        link.click();        
      })
    }
    else if(type=='csv'){
      this.apiService.downloadAwsVdiInCsv(this.projectId,this.filterStatus).subscribe(data=>{
        const blob = new Blob([data], { type: 'text/csv' });
        const url= window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName+'-AwsVDI.csv';
        link.click();        
      })
    }

  }
  onChangeRunningMode($event) {

    // debugger
    // this.model.ProptValue=""
    // this.propertyValueList=[];

    this.RunningName = $event.target.options[$event.target.options.selectedIndex].text;
    this.RunningValue = $event.target.options[$event.target.options.selectedIndex].value;


    // console.log(this.diskDisplayName);
    // console.log(this.diskPropertyName);




  }
  get compute_type_name() {
    return this.secondFormGroup.get('compute_type_name');
  }
  get root_volume_size_gb() {
    return this.secondFormGroup.get('root_volume_size_gb');
  }
  get running_mode() {
    return this.secondFormGroup.get('running_mode');
  }
  get user_volume_size_gb() {
    return this.secondFormGroup.get('user_volume_size_gb');
  }
  get bundle_id() {
    return this.secondFormGroup.get('bundle_id');
  }
  get directory_id() {
    return this.secondFormGroup.get('directory_id');
  }

  AddAwsVdi() {
    this.showRepo = true;
    this.selectedUserArray=[]
    this.apiService.selectedVDIMembersWorkspace.next([])

    // console.log(this.selectedUserArray,"ddddddddddddd");
    
    // let dropdownBody = {
    //   "dropdown_category": "Workbench-Workspace",
    //   "screen_name": "Workbench-Workspace"
    // }
    // this.apiService.getDropdowns(dropdownBody).subscribe((data: any) => {
    //   this.compute_type_nameVal = data.dropdownComputeTypeName;
    //   this.running_modeVal = data.dropdownRunningMode;
    //   this.root_volume_size_gbVal = data.dropdownRootVolumeSize;
    //   this.user_volume_size_gbVal = data.dropdownUserVolumeSize;

    // }, (error: HttpErrorResponse) => {
    //   if (error.status == 446) {
    //     this.alertService.error(error.error.message, this.options);
    //   }
    //   else {
    //     this.alertService.error("No data available.", this.options);
    //     throw error;
    //   }

    // })
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '30%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.createAwsFirst();
          // this.SaveAWS();
          
        }
      }
    );
  }

  toggleDisplay(secondFormGroup) {


    if (secondFormGroup.pristine == false) {
      this.showRepo = false;
      // this.flag=true
      this.selectedUserArray=[]
      // this.ngOnInit()
      

    } else {
      // this.flag=true
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray=[]
      // this.ngOnInit()
     
      


    }
  }

  openUser() {
    // console.log(this.selectedUserArray,"gggggggggggg/gggggggg");
    
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      // if(this.flag){
      //   this.selectedUserArray=[]
      // }
      // console.log(this.selectedUserArray,"fffffffffff");
      
      this.selectedUserArray = result
      this.apiService.selectedVDIMembersWorkspace.next(this.selectedUserArray);
    })
  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }
  createAwsFirst(){
    this.apiService.Admin_GetAWSConfig(this.toolgrp).subscribe((result: any) => {
      // debugger

      this.awsConfig=result[0].tool_id    
      this.SaveAWS()
  


    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    });
    // debugger
    // console.log(this.awsConfig);
  }
  SaveAWS() {
    for (var i = 0; i < this.selectedUserArray.length; i++) {

      let bodymember=
      { 
        "user_email": this.selectedUserArray[i].user_email, 
        "user_name": this.selectedUserArray[i].user_name,
       }
       this.memberArray.push(bodymember)
      //Do something
  }
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    let obj = { 
      "request_type": "AWS-Workspace", 
    "resource_type": "request", 
    "request_status": "Pending", 
    "requestor_id": userPsNumber, 
    "requestor_name": userName, 
    "project_details": { 
      "project_id": this.projectId,
      "project_name": this.projectName,
      "catalog_id": this.catalog_id,
      "catalog_name": this.catalog_name
    }, 
    "request_details": { 
      "aws_workspace_details": {
         "workspace_details": { 
           "aws_config_tool_id": this.awsConfig, 
           "aws_workspace_image_tool_id": this.ImageValue,
            "aws_workspace_network_config_tool_id": this.NetworkName, 
            "compute_type_name": this.ComputeName, 
            "root_volume_size_gb": this.ComputeValueRootVolumeSize, 
            "running_mode": this.RunningName, 
            "user_volume_size_gb": this.ComputeValueUserVolumeSize, 
            "members": this.memberArray,
          } 
        } 
      },
      "created_by":localStorage.getItem('mail').toLowerCase()


    }
    // console.log(obj);
    this.apiService.LaunchAwInstanceWorkspace(obj).subscribe((result: any) => {

      // debugger

      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsWorkspace']);
      });
      this.alertService.success(result.message, this.options);

      // this.router.navigate(['/awsVdi'])



    },
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    });
    
  }

  createAWSVdi() {
    this.tableArr = [];
    let member = this.selectedUserArray[0].user_name.replace(' ', '_').toLowerCase();
    let vdiBody = {
      "project_id": this.projectId,
      "vdi_type": "Dedicated",
      "vdi_request": [
        {
          "vdi_user": {
            "resource_type": "request",
            "bundle_id": this.bundle_id.value,
            "directory_id": this.directory_id.value,
            "user_name": member,
            "compute_type_name": this.compute_type_name.value,
            "root_volume_size_gb": this.root_volume_size_gb.value,
            "running_mode": this.running_mode.value,
            "user_volume_size_gb": this.user_volume_size_gb.value
          }
        }
      ]
    }

    this.apiService.createAWSVdi(vdiBody).subscribe((data: any) => {
      let windowsMembers = [];
      this.selectedUserArray.forEach(el => {
        let membObj = {
          name: el.user_name,
          email: el.user_mail,
        }
        windowsMembers.push(membObj)
      });

      let tableObj =
      {
        
        "status": "Pending",
        //"members": windowsMembers,
        "bundle_id": this.bundle_id.value,
        "directory_id": this.directory_id.value,
        "user_name": member,
        "compute_type_name": this.compute_type_name.value,
        "root_volume_size_gb": this.root_volume_size_gb.value,
        "running_mode": this.running_mode.value,
        "user_volume_size_gb": this.user_volume_size_gb.value
      }
      this.tableArr.push(tableObj)
      this.awsData = this.tableArr
      this.showAwsTable = true;
      this.showRepo = false;
      this.showUserRow = false;
      this.dtTrigger.next();

    }, (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  getAWSVdi() {
    this.vdiTableData = [];
    this.tableRepoArray = [];
    let pid = {
      "project_id": this.projectId
    }
    this.apiService.getAWSVdi(pid).subscribe((data: any) => {
      data.forEach(request => {
        let tableObj =
        {
          "status": request.State,
          // "members": request.members,
          "bundle_id": request.BundleId,
          "directory_id": request.DirectoryId,
          "user_name": request.UserName,
          "compute_type_name": request.WorkspaceProperties.ComputeTypeName,
          "root_volume_size_gb": request.WorkspaceProperties.RootVolumeSizeGib,
          "running_mode": request.WorkspaceProperties.RunningMode,
          // "user_volume_size_gb": request.user_volume_size_gb
        }

        this.tableRepoArray.push(tableObj)
        this.vdiTableData = this.tableRepoArray;
        this.showAwsTable = true;
        this.dtTrigger.next();
      });
    }, (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  startAws(reqId,id) {
    this.spinner.show()
    this.alertService.success('Workspace start process initiated', this.options);
    // let reqBody = 
    //   { 
    //     "aws_config_tool_id": reqId, 
    //     "workspaceId": id
    //    }
    var arr=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      arr.push(this.VdiConfig_details[i].status)
    }
    let reqBody={

      "aws_config_tool_id": this.VdiConfig_details[0].request_id,
  
      "workspaceId": arr
  
  }
    
    this.apiService.StartAwInstanceWorkspace(reqBody).subscribe((data: any) => {
      setTimeout(() => {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/awsWorkspace']);
        });
        this.spinner.hide()
      }, 3000)
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsWorkspace']);
      });
      // this.spinner.hide()
      this.alertService.success(data.message, this.options);
    }, 
    (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to start.", this.options);
        throw error;
      }

    }
    )
  }

  stopAws(reqId,id) {
    // this.alertService.success('Workspace stop process initiated', this.options);
    this.spinner.show()
    // let reqBody = 
    // { 
    //   "aws_config_tool_id": reqId, 
    //   "workspaceId": id
    //  }
    var arr=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      arr.push(this.VdiConfig_details[i].status)
    }
    let reqBody={

      "aws_config_tool_id": this.VdiConfig_details[0].request_id,
  
      "workspaceId": arr
  
  }
    this.apiService.StopAwInstanceWorkspace(reqBody).subscribe((data: any) => {
      setTimeout(() => {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/awsWorkspace']);
        });
        this.spinner.hide()
      }, 3000)
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/awsWorkspace']);
      });
      // this.spinner.hide()
      this.alertService.success(data.message, this.options);
    }, (error: HttpErrorResponse) => {
      if (error.status == 440) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.alertService.error("Unable to stop.", this.options);
        throw error;
      }

    })
  }
}
@Component({
  selector: 'dialog-info-image-ws',
  templateUrl: 'imageDetails.html',
  styleUrls: ['./aws-workspace.component.scss']
})

export class ImageInfoPopWS {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<ImageInfoPopWS>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type
    // console.log(thsis.type);
    


    this.dataInfo = data.ImageInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-info-network-ws',
  templateUrl: 'networkDetails.html',
  styleUrls: ['./aws-workspace.component.scss']
})

export class NetworkInfoPopWS {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<NetworkInfoPopWS>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type
    // console.log(thsis.type);
    


    this.dataInfo = data.ImageInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'dialog-info-compute-ws',
  templateUrl: 'computeDetails.html',
  styleUrls: ['./aws-workspace.component.scss']
})

export class ComputeInfoPopWS {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<ComputeInfoPopWS>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type
    // console.log(thsis.type);
    


    this.dataInfo = data.ImageInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-info-running-ws',
  templateUrl: 'runningDetails.html',
  styleUrls: ['./aws-workspace.component.scss']
})

export class RunningInfoPopWS {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<RunningInfoPopWS>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
    this.flag = true

    this.type = data.type
    // console.log(thsis.type);
    


    this.dataInfo = data.ImageInfo




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}


@Component({
  selector: 'request-info-ws',
  templateUrl: 'requestImageInfo.html',
  styleUrls: ['./aws-workspace.component.scss']
})

export class requestImageInfo {
  dataInfo: any;
  type: any;
  flag: boolean = false;

  constructor(private apiService: AuthService,
    public dialogRef: MatDialogRef<requestImageInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.dataInfo = data.ImageInfo
 
    // console.log(thsis.type);
    


    this.dataInfo = data.info




  }
  ngOnInit() {

    // console.log(this.dataInfo);

  }
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close('No');
    }
  }
  exit(): void {
    this.dialogRef.close();
  }

}


