
//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, ComponentRef ,Inject} from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ProjectRolesComponent } from './../../project-configurations/project-roles/project-roles.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { waitForAsync } from '@angular/core/testing';
import { delay } from 'rxjs/operators';
import { TeamMembersComponent } from 'src/app/project-configurations/team-members/team-members.component';
import { VdiDetailsComponent, DialogProjectInfoDialog } from 'src/app/project-configurations/vdi-details/vdi-details.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { env } from 'process';
import * as CryptoJS from 'crypto-js';  

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss']
})
export class ProjectSummaryComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  catalog_id: string;
  catalog_name: any;
  singleCatalogData: any;
  summary_icons: any = [];
  isUserTM: boolean =false;
  isUserSM: boolean =false;
  conversionEncryptOutput: string;  
  conversionDecryptOutput:string;  
  plainText:string;  
  encryptText: string;  
  encPassword: string;  
  decPassword:string;
  userMailId: string;
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  private footerLogoPath = environment.footerLogoPath;
  footerimageSrc:any;
  footerimageAlt:any;
  template_id: any;
  
  constructor(
    public apiService: AuthService,public alertService: AlertService,
    public dialog: MatDialog,
    private _router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {

    // for loading the same component when redirected from the workbench items
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this._router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

    //console.log("footer Logo Path", this.footerLogoPath);
   this.footerimageSrc = this.footerLogoPath;  
   this.footerimageAlt = 'Footer';

  }
  
  projectId: any;
  projectName: any;
  project_id:string;
  compassId: any;
  createdDate: any;
  members: any = [];
  pipe = new DatePipe('en-US');
  dateformat: any;

  new_members: any = []
  previous_members: []
  removed_members: []

  pmName: any;
  kdmName: any;
  actuStartDate: any;
  actuEndDate: any;
  status: any;
  type: any;
  customerName: any;
  deliveryBU: any;
  activeTab: string;

  toolName: string

  tabEventDisplay;

  vdiInformation : any 
  roles_assigned:any;

  infoVdiArray : any = []

  vdiStatus : string
  flagValue:string;
  flagValueSM:string;




  ngOnInit() {
    // debugger
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.projectId=localStorage.getItem('projectId');
    this.isUserSM = false;
    this.flagValueSM="false";

    localStorage.setItem('isUserSM',this.flagValueSM);
    let userPsNumber = "";
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })

    //just replace ID_002 with projectId bs name
    
      
      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        this.project_id = project[0].project_id;

        this.roles_assigned = project[0].roles_assigned;

      this.roles_assigned.forEach(element => {
        if(element.role_name =="Project Manager"){
        
          localStorage.setItem('projectCreatedBy', element.members[0].user_name);
         
        }
      });

      project[0].members.forEach(element => {
        this.userMailId = localStorage.getItem("mail").toLowerCase();
        if(element.user_email == this.userMailId){
            for(let i = 0; i<element.role_name.length; i++){

            if(element.role_name[i] =="Secondary Project Manager"){
              this.isUserSM = true;
              this.flagValueSM="true";
          
              localStorage.setItem('isUserSM',this.flagValueSM);

            }
            
            if(element.role_name[i] != "Project Manager" && element.role_name[i] != "Secondary Project Manager" && element.role_name[i] != "PM"){
              this.isUserTM = true;
              this.flagValue="true";
          
              localStorage.setItem('isUserTM',this.flagValue);
            }
            else{
              this.isUserTM = false;
               
              this.flagValue="false";
              
              localStorage.setItem('isUserTM',this.flagValue);
              break;
            }
          };
           //Disable icons in workbench

          let reqBody = {
            "project_id": this.projectId,
            "user_role_name": this.isUserTM ? 'Team Member' : 'Project Manager'
          }
          
          this.apiService.getIconsForDisable(reqBody).subscribe((data:any)=>{
            this.summary_icons = data.icons;
            
          })
        }
      });
        localStorage.setItem('catalogId',project[0].catalog_id);
        localStorage.setItem('projectName',project[0].project_name);
        localStorage.setItem('catalogName',project[0].catalog_name);
        localStorage.setItem('template_id', project[0].template_id);
        localStorage.setItem('template_name', project[0].template_name);


        this.template_id = project[0].template_id;
        this.catalog_id = project[0].catalog_id;
        this.catalog_name = project[0].catalog_name;
        this.createdDate = project[0].createdAt;
        this.dateformat = this.pipe.transform(this.createdDate, 'MM/dd/yyyy');
        this.projectName = project[0].project_name;
        this.compassId = project[0]._id;
        this.members = project[0].members
        this.previous_members = project[0].members
        if(project[0].imanage_project_info){
          this.actuStartDate = project[0].imanage_project_info.ActuStartDate;
          this.actuEndDate = project[0].imanage_project_info.ActuEndDate;
          this.status = project[0].imanage_project_info.ProjStatus;
          this.type = project[0].imanage_project_info.ProjType;
          this.customerName = project[0].imanage_project_info.CustomerId;
          this.deliveryBU = project[0].imanage_project_info.DeliveryBu
        }
        
        
        this.apiService.previousMembers.next(this.previous_members)
        
      }, (error: HttpErrorResponse) => {
        if (error.status == 432){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         })
        }
 else{
    this._router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/admin-catalog-config']);
  });
  }
  // console.log(localStorage);
  
  }
  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });
 
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  


  public show_workbench_items = true;
  public show_workbench_tab = true;
  public show_icons = false;
  public show_details = false;
  ref: ComponentRef<any>;

  displayProjectRole() {
    this.removeComponent();
    this.show_workbench_items = true;
    this.show_workbench_tab = false;
    this.show_icons = false;
    const factory = this.componentFactoryResolver.resolveComponentFactory(ProjectRolesComponent);
    this.ref = this.viewContainerRef.createComponent(factory);
    this.ref.changeDetectorRef.detectChanges();
  }

  displayAssignMembers() {
    this.removeComponent();
    this.show_workbench_items = true;
    this.show_workbench_tab = false;
    this.show_icons = false;
    const factory = this.componentFactoryResolver.resolveComponentFactory(TeamMembersComponent);
    this.ref = this.viewContainerRef.createComponent(factory);
    this.ref.changeDetectorRef.detectChanges();
  }

  displayWorkbenchIcons(){
    this.show_workbench_tab = false;
this.show_icons = true;
  }

  displayDetails(){
    this.show_workbench_tab = false;
this.show_details = true;
  }

  tabClick(tab: any) {
    if (tab.tab.textLabel == "Workbench") {
      this.removeComponent();
      this.show_workbench_tab = true;
    } else if (tab.tab.textLabel == "Team Members") {
      this.displayAssignMembers();
    } else {
      this.removeComponent();
    }
  }

  removeComponent() {
    
    this.show_workbench_tab = false;
    if (this.ref != undefined) {
      this.ref.destroy();
    }
  }


  getToolName(value) {

    this.apiService.selectedTool.next(value)


  }
  convertText(conversion:string,ele) {  
    console.log(ele);
    
    if (conversion=="encrypt") {  
      this.plainText=ele
      this.encPassword=""
      this.conversionEncryptOutput = CryptoJS.AES.encrypt(ele, "token").toString();  
      console.log(this.conversionEncryptOutput);

    }  
    
    else {  
      this.conversionDecryptOutput = CryptoJS.AES.decrypt(ele, "token").toString(CryptoJS.enc.Utf8);  
      console.log(this.conversionDecryptOutput);

     
  }  
  

}  
routeMonitoring(){
  // console.log(localStorage);
  
  
  let element=localStorage.ssoToken

  // console.log(element,"real_token");

  this.conversionEncryptOutput = CryptoJS.AES.encrypt(element, "token").toString();  
  // this.conversionEncryptOutput = CryptoJS.AES.encrypt(JSON.stringify({ element }), 'token').toString(); 

  // console.log(this.conversionEncryptOutput,"encrypt_token");
  const finalToken= encodeURIComponent(this.conversionEncryptOutput)
  // console.log(y,"encode_encrypt_token");
  
  // console.log(    encodeURIComponent(this.conversionEncryptOutput));
  // console.log(decodeURIComponent(y));
  // const z=decodeURIComponent(y)
  // console.log(z,"decode_encrpyt_token");
  
  // console.log(this.conversionEncryptOutput==z);
  
  
  
  
  
  
  
  

  // this.conversionDecryptOutput = CryptoJS.AES.decrypt(z, "token").toString(CryptoJS.enc.Utf8);  
  


  // console.log(this.conversionDecryptOutput,"third");


  // console.log(element);
  let project_id=localStorage.projectId

  let url= environment.resillenceUrl+finalToken+"&projectID="+project_id

  // let url= environment.resillenceUrl+this.conversionEncryptOutput
  // console.log(url);
  
  // let url= 'http://localhost:4401/?token='+ finalToken
  // https://canvasinternal.lntinfotech.com/apps/resilience/dev/CanvasResilience/#/login
  // '/api/aws/ec2?project_id=' + id 
  
    window.open(url, "_blank");
}


  


  syncImanageTeamMemberProject() {
    let projectSummary = {
      "ps_number": "10603933",
      "project_id": this.projectId

    }
    this.apiService.syncImanageTeamMembers(projectSummary).subscribe((data: any) => {

      this.new_members = data.new_members
      this.removed_members = data.removed_members


      if (this.new_members.length == 0 && this.removed_members.length == 0) {
        this.apiService.previousMembers.next(this.previous_members)
        this.apiService.newMembers.next(this.new_members)
        this.apiService.removedMembers.next(this.removed_members)

      } else {

        this.apiService.previousMembers.next(this.previous_members)

        this.apiService.newMembers.next(this.new_members)
        this.apiService.removedMembers.next(this.removed_members)
      }

    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }


}
