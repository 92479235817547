<!-- New code-->
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Tool Group</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right">
        <div class="table-options pt-3">
          <span (click)="addToolGroup()"><i class="fas fa-plus"></i> Add</span>
          <span (click)="refreshToolGroup()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span (click)="deleteRecord()" *ngIf="editMode" data-toggle="modal" data-target="#confirmationModal2"><i
              class="fas fa-trash"></i> Delete</span>

          <a class="float-right step-back" (click)="backNavigation()">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div>
      </div>
      <!-- <div class="col-md-1 p-md-0 my-auto">
        <a routerLink="/admin-catalog-config" class="float-right step-back">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div> -->

    </div>
    <div>
      <h1 class="text-light mb-0"><img width="40" src="assets/svg/Tool Group.svg">
        <div class="vertical-line mx-3"> </div> <span>Tool Group</span>
      </h1>
    </div>

  </div>

  <div class="inner-mid-container">
    <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog"
      aria-labelledby="confirmationModal2" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content pop-up">
          <div class="modal-header-popup">
            <span class="vertical-line mx-3"> </span>
            <span class="header mb-0">Tools</span>
            <span data-dismiss="modal" class="material-icons close-icon">
              close
            </span>
          </div>
          <hr class="margin-class" />
          <div class="modal-body">
            Are you sure want to Delete?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showToolGroupForm" class="tool-group-Form">
      <h2 *ngIf="editMode">Edit Form</h2>
      <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Tool Group Name</label>
            <input type="text" autocomplete="off" name="toolName" maxlength="20"  minlength="3"  pattern="^[a-zA-Z0-9_-]+$" [disabled]="ToolGroupDisable"
              class="form-control form-control-lg" [(ngModel)]="model.toolName" #toolName="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && toolName.invalid }" required
              (keydown.space)="$event.preventDefault();" onPaste="return false">
            <div *ngIf="f.submitted && toolName.invalid" class="invalid-feedback">
              <div *ngIf="toolName.errors.required">Tool Name is required</div>
            </div>
            <div *ngIf="toolName.invalid && toolName.errors.minlength" class="alert alert-danger">
              Please enter minimum 3 characters for saving Tool Name
            </div>
            <div *ngIf="toolName.invalid && toolName.errors.pattern" class="alert alert-danger">Name must include only alphabets ,numbers or _,-.</div>

          </div>
          <div class="form-group col-md-4">
            <label>Tool Description</label>
            <textarea name="toolDescription" class="form-control form-control-lg" rows="1" minlength="20" maxlength="50" pattern="^[a-zA-Z0-9_ -]+$"
              [(ngModel)]="model.toolDescription" #toolDescription="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && toolDescription.invalid }" required></textarea>
            <div *ngIf="f.submitted && toolDescription.invalid" class="invalid-feedback">
              <div *ngIf="toolDescription.errors.required">Tool description is required</div>
            </div>
            <div *ngIf="toolDescription.invalid && toolDescription.errors.minlength" class="alert alert-danger">
              Please enter minimum 20 characters for saving Description 

          </div>
          <div *ngIf="toolDescription.invalid && !toolDescription.errors.minlength &&toolDescription.errors.pattern" class="alert alert-danger">Description must include only alphabets ,numbers or _,-.</div>

          </div>

          <div class="form-group col-md-4  button-area">
            <button [disabled]="saveButtonDisable" type="button"  [disabled]="!f.form.valid" *ngIf="!editMode" (click)="CheckToolNameDuplication()"
              class="btn-canvas-blue mr-2">Save</button>
            <button type="button" *ngIf="editMode"  [disabled]="!f.form.valid" (click)="updateData()" class="btn-canvas-blue mr-2">Update</button>
            <!-- <button type="reset" class="btn-canvas-blue" (click)="closeToolGroup()">
               Cancel
             </button> -->
            <button class="mr-2 btn-canvas-blue" data-toggle="modal" *ngIf="!f.form.pristine"
              data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="f.form.pristine" (click)="closeToolGroup()">Cancel</button>

         </div>

         <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
         
         <!-- Modal -->
         <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
           aria-labelledby="confirmationModal" aria-hidden="true">
           <div class="modal-dialog" role="document">
             <div class="modal-content pop-up">
               <div class="modal-header-popup">
                 <span class="vertical-line mx-3"> </span>
                 <span class="header mb-0">Tool Group</span>
                 <span data-dismiss="modal" class="material-icons close-icon">
                   close
                 </span>
               </div>
               <hr class="margin-class" />
               <div class="modal-body">
                 Are you sure want to cancel?
               </div>
               <div class="modal-footer">
                 <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                 <button type="button" class="btn btn-canvas-blue-2"
                   (click)="cancelOK()">Ok</button>
               </div>
             </div>
           </div>
           <!-- </div> -->
         </div>

        </div>
      </form>
      <div *ngIf="showToolGroupEditTable">
        <table datatable [dtOptions]="dtOptionsToolGroup" [dtTrigger]="dtTriggerToolGroup" class="table fixed-table">
          <thead>
            <tr>
              <th style="width: 15px;">
                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                  (change)="checkUncheckAll()" />
              </th>

              <th>Resource Type</th>
              <th>Tool ID</th>
              <!-- <th>Action</th>-->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let resource of tableResourceArray;let i = index">
              <td style="width: 15px;">
                <input type="checkbox" [(ngModel)]="resource.isSelected" name="list_name" value="{{resource.id}}"
                  (change)="isAllSelectedcheck()" />
              </td>
              <td>{{resource.resourceType}}</td>
              <td>{{resource.tool_id}}</td>

            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="my-5 table-container" *ngIf="showToolGroupMasterTable">
      <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
          <thead>
            <tr>
              <!-- <th></th> -->
              <!-- <th style="width: 15px;">
                  <input type="checkbox" [(ngModel)]="masterSelectedcheckToolGroup" name="list_name" value="m1" (change)="checkUncheckAllcheckToolGroup()"/>
                </th> -->
              <!--<th style="width: 15px;"><input type="checkbox"></th>-->
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tool of ToolGroupArray">
              <!-- <td style="width: 15px;">
                  <input type="checkbox" [(ngModel)]="tool.isSelected" name="list_name" value="{{tool.id}}" (change)="isAllSelectedcheckToolGroup()"/>
                </td> -->
              <!--td style="width: 15px;"><input type="checkbox"></td>-->
              <td><span class="link-col" (click)="getRecord(tool)">{{tool.ID}}</span></td>
              <td>{{tool.Name}}</td>
              <td>{{tool.Description}}</td>
              <td> <span class="table-crud table-delete-icon px-4" data-toggle="modal" data-target="#myModal" (click)="deleteRecordtool(tool)"
                  title="Delete">

                  <img width="20" src="../../../assets/img/Delete-blue.png"></span>

              </td>



            </tr>

          </tbody>
        </table>
        <div class="modal fade " id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
              <div class="modal-header-popup">
                <span class="vertical-line mx-3"> </span>
                <span class="header mb-0">Tool Group</span>
                <span data-dismiss="modal" class="material-icons close-icon">
                  close
                </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                Are you sure want to Delete?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteCon()">Ok</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<!--New code close-->