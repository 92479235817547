<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
  <div class="inner-top-container z100">
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item  link-blue"><a (click)="goHome(secondFormGroup, vmFormGroup)">Home</a></li>
            <li class="breadcrumb-item  link-blue"><a (click)="goWorkBeanch(secondFormGroup, vmFormGroup)">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{!showVm && !showRepo ? 'Azure' : showVm ? 'Azure-VM' : 'Azure-Cluster'}}</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <!--span (click)="showVm || showRepo ? null : AddCluster()" [class.disabled]="showVm || showRepo"><i class="fas fa-plus"></i> Add</span-->
          <!-- <span *ngIf="deploymentFlag"  data-toggle="modal" data-target="#myModal"><i class="fas fa-plus"></i> Add</span>
          <span (click)="AddCluster()" *ngIf="!deploymentFlag"><i class="fas fa-plus"></i> Add</span> -->

          <span (click)="checkToolData()" ><i class="fas fa-plus"></i> Add</span>
            <span id="CallModel"  data-toggle="modal" data-target="#myModal"></span>
          <span *ngIf="refreshDisable" (click)="showRepo ? null : getVMProject()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span *ngIf="!refreshDisable" class="disabled-img"><i class="fas fa-sync-alt"></i> Refresh</span>
          <!-- <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span> -->
          <span class="dropdown"  [ngClass]="manageDisable ? 'disabled-img' : ''">
            
              <button  class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-cog"></i>
                Manage
              </button>
              <!-- <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                  <i class="fas fa-download "></i> Export
                </a>
                <a href="#" class="nav-link" data-toggle="dropdown">
                    <i class="fas fa-ellipsis-h"></i>
                  </a> -->
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        
                    <a class="dropdown-item">
                        <!-- <span  class="table-crud table-edit-icon"  disabled> -->
                          <!-- <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Start VDI</span> -->
                        <span  class="" (click)="startvm()">
                          <img width="20" src="../../../assets/img/Start-blue.png"> <span  class="vertical-line-more mx-2"></span > <label style="color: black; font-weight: 500;">Start VM</label> </span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item">
                          <!-- <span  class="table-crud table-save-icon"  disabled > -->
                            <!-- <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop VDI </span> -->
                        <span   class="" (click)="stopvm()"> 
                            <img width="20" src="../../../assets/img/Stop-blue.png"> 
                            <span class="vertical-line-more mx-2">

                            </span> <label style="color: black; font-weight: 500;">Stop VM</label>
                          </span>
                        </a>
                <!-- <a class="dropdown-item" href="#">Action</a> -->
                <!-- <a class="dropdown-item" href="#">Another action</a> -->
                <!-- <a class="dropdown-item" href="#">Something else here</a> -->
              </div>
            </span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
    
  </div>

  <div class="inner-mid-container z0">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-sm-5">
            <h1 class="mb-0">
              <img width="40" src="assets/img/Azure.png">
              <div class="vertical-line mx-3"> </div>
               <span>{{!showVm && !showRepo ? 'Azure' : showVm ? 'Azure-VM' : 'Azure-Cluster'}}</span>  
            </h1>
          </div>
          <div class="col-md-7"><span class="field-name">Project ID </span><span class="field-value"> :
            {{projectId}}</span>
            <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-md-6 mb-2" >
 
        <div class="text-right">
          <div class="row">
          <div class="col-md-10 col-12">
            <mat-form-field class="mr-1">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="filterStatus" (selectionChange)="getStatus($event.value)"> 
                <mat-option value="All">All</mat-option>
                <mat-option value="Active">Active</mat-option>
                <mat-option value="Failed">Failed</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="col-md-2 col-12">
            <div class="dropdown" *ngIf="vmData.length>0">
              <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                <i class="fas fa-download "></i> Export
              </a>
              <div class="dropdown-menu more-drop-down">
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('pdf')">
                    <i width="20" class="fas fa-file-pdf color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> PDF</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('excel')">
                    <i width="20" class="fas fa-file-excel color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> EXCEL</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span class="color-black" (click) = "downloadData('csv')">
                    <i width="20" class="fas fa-file-csv color-blue"></i>
                    <span class="vertical-line-more mx-2"></span> CSV</span>
                </a>
              </div>
            </div>
          </div>
        </div>
          
        </div>
      </div>
    </div>
    <div class="mb-4" *ngIf="showRepo">
      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label for="cluster">Enter Cluster Name</label>
              <input #input placeholder="Enter Cluster Name" name="cluster" formControlName="cluster" class="form-control form-control-height"
              [class.is-invalid]="cluster.invalid && cluster.touched">
              <small class="text-danger" [class.d-none]="cluster.valid || cluster.untouched">Cluster is
                required</small>
              
            </div>
          </div>
  
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label for="dnsPrefix">Enter DNS Prefix Name</label>
              <input #input placeholder="Enter DNS Prefix Name" name="dnsPrefix" formControlName="dnsPrefix" class="form-control form-control-height"
                [class.is-invalid]="dnsPrefix.invalid && dnsPrefix.touched">
              <small class="text-danger" [class.d-none]="dnsPrefix.valid || dnsPrefix.untouched">DNS
                is required</small>
            </div>
          </div>
  
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label for="nodeCount">Node Count</label>
              <select formControlName="nodeCount" id="nodeCount" name="nodeCount" class="form-control form-control-height"
              [class.is-invalid]="nodeCount.invalid && nodeCount.touched">
                <option value="" disabled selected hidden>Node Count</option>
                <option [value]="role" *ngFor="let role of nodeCountVal">{{role}}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="nodeCount.valid || nodeCount.untouched">Node Count
                is required</small>
            </div>
          </div>
  
          <div class="col-12 col-md-6 col-lg-2">
            <div class="form-group">
              <label for="vmSize">Size</label>
              <select formControlName="vmSize" id="vmSize" name="vmSize" class="form-control form-control-height"
              [class.is-invalid]="vmSize.invalid && vmSize.touched">
                <option value="" disabled selected hidden>VM Size</option>
                <option [value]="role" *ngFor="let role of vmSizeVal">{{role}}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="vmSize.valid || vmSize.untouched">VM Size
                is required</small>
            </div>
          </div>
  
          <div class="col-12 text-center col-md-1 mt-4 mr-4">
            <button class="hover users-button width-100" mat-button (click)="confirmationPopup('cluster')" mat-raised-button
              [disabled]="!secondFormGroup.valid" title="Create Cluster">
              Create 
            </button>
          </div>

          <div class="col-12 text-center col-md-1 mt-4 mb-2">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2" (click)="toggleDisplay(secondFormGroup)">Cancel</button>
            
          </div>
        </div>
        
      </form>
    </div>

    <div class="mb-4" *ngIf="showVm">
       <form [formGroup]="vmFormGroup" class="pl-0">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="image">Image</label>
              <select formControlName="image" id="image" (change)="onSelectImageDropdown(image.value)" name="image" class="form-control form-control-height"
              [class.is-invalid]="image.invalid && image.touched">
                <option [ngValue]="null" disabled selected hidden>Select</option>
                <option [ngValue]="role" *ngFor="let role of imageVal">{{role.name}} 
                </option>
              </select>
              <small class="text-danger" [class.d-none]="image.valid || image.untouched">Image
                is required</small>
              
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style=" top: 8px">
            <span style="font-size: 18px;" class="material-icons add-user" title="VM Image Information" (click)="showVMSizeInfo(image.value, 'imageInfo')">info</span>
          </div>

          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="location">Location</label>
              <select formControlName="location" id="location" name="location" class="form-control form-control-height"
              [class.is-invalid]="location.invalid && location.touched">
                <option [ngValue]="null" disabled selected hidden>Select</option>
                <option [ngValue]="role" *ngFor="let role of locationVal">{{role.display_name}} 
                </option>
              </select>
              <small class="text-danger" [class.d-none]="location.valid || location.untouched">Location
                is required</small>
            </div>
          </div>

            <div class="col-12 col-md-3">
              <div class="form-group">
                <label for="network_config_vm">Network Config</label>
                <select id="network_config_vm" name="network_config_vm" class="form-control form-control-height"
                  required formControlName="network_config_vm" [class.is-invalid]="network_config_vm.invalid && network_config_vm.touched" >
                  
                  <option [ngValue]="null" disabled selected hidden>Select</option>
                <option [ngValue]="role" *ngFor="let role of networkConfigTools">{{role.tool_id}}
                </option>
                </select>
                <small class="text-danger" [class.d-none]="network_config_vm.valid || network_config_vm.untouched">Network Config is
                  required</small>
                
              </div>
            </div>
            <div class="position-relative mt-4 img-width" style=" top: 8px">
              <span style="font-size: 18px;" class="material-icons add-user" title="VM Image Information" (click)="showVMSizeInfo(network_config_vm.value, 'networkConfig')">info</span>
            </div>

            <!-- <div class="col-12 col-md-3">
              <div class="form-group">
                <label for="size">VM Size</label> -->
                <!-- <span class="material-icons show-vm-info" title="VM Size Information" (click)="showsizeInfo()">info</span> -->
                <!-- <select formControlName="size" id="size" name="size" class="form-control form-control-height"
                [class.is-invalid]="size.invalid && size.touched" (change)="selectedValue(size.value)">
                  <option [value]="null" disabled selected hidden>Select VM Size</option>
                  <option [value]="vm" *ngFor="let vm of VMSizeVal">{{vm}} 
                  </option>
                </select>
                <small class="text-danger" [class.d-none]="size.valid || size.untouched">VM Size
                  is required</small>
              </div>
            </div>
            <div class="position-relative mt-4 img-width" style=" top: 8px">
              <span style="font-size: 18px;" class="material-icons add-user" title="VM Size Information" (click)="showVMSizeInfo(size.value, 'vmSizeInfo')">info</span>
            </div> -->
        </div>

        <div class="row">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="size">Size</label>
                <!-- <span class="material-icons show-vm-info" title="VM Size Information" (click)="showsizeInfo()">info</span> -->
                <select formControlName="size" id="size" name="size" class="form-control form-control-height"
                [class.is-invalid]="size.invalid && size.touched" (change)="selectedValue(size)">
                  <option [value]="null" disabled selected hidden>Select</option>
                  <option [ngValue]="vm" *ngFor="let vm of VMSizeVal">{{vm.display_name}} 
                  </option>
                </select>
                <small class="text-danger" [class.d-none]="size.valid || size.untouched">VM Size
                  is required</small>
              </div>
            </div>
            <div class="position-relative mt-4 img-width" style=" top: 8px">
              <span style="font-size: 18px;" class="material-icons add-user" title="VM Size Information" (click)="showVMSizeInfo(size.value.property_value, 'vmSizeInfo')">info</span>
            </div>
          <!-- <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="computerName">VM Name</label>
              <input #input placeholder="Enter VM Name" name="computerName" formControlName="computerName" class="form-control form-control-height"
                [class.is-invalid]="computerName.invalid && computerName.touched">
              <small class="text-danger" [class.d-none]="computerName.valid || computerName.untouched">{{getVMNameErrorMessage()}}</small>
            </div>
          </div> -->
          

          <!-- <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="location">Location</label>
              <select formControlName="location" id="location" name="location" class="form-control form-control-height"
              [class.is-invalid]="location.invalid && location.touched">
                <option [ngValue]="null" disabled selected hidden>Select Location</option>
                <option [value]="role" *ngFor="let role of locationVal">{{role}} 
                </option>
              </select>
              <small class="text-danger" [class.d-none]="location.valid || location.untouched">Location
                is required</small>
            </div>
          </div> -->

          
          <div class="col-12 col-md-4 ">
            <div class="form-group">
              <label for="vmDiskType">Disk Type</label>
              <select formControlName="vmDiskType" id="vmDiskType" name="vmDiskType" class="form-control form-control-height"
              [class.is-invalid]="vmDiskType.invalid && vmDiskType.touched">
                <option [value]="null" disabled selected hidden>Select</option>
                <option [ngValue]="disk" *ngFor="let disk of diskTypeVal">{{disk.display_name}} 
                </option>
              </select>
              <small class="text-danger" [class.d-none]="vmDiskType.valid || vmDiskType.untouched">VM Disk Type
                is required</small>
            </div>
          </div>

          <div class="col-12 col-md-3 ">
            <div class="form-group">
              <label for="userName">Admin User Name </label>
              <input #input placeholder="Enter Admin User Name" name="userName" formControlName="userName" class="form-control form-control-height"
                [class.is-invalid]="userName.invalid && userName.touched">
              <small class="text-danger" [class.d-none]="userName.valid || userName.untouched">{{getUserNameErrorMessage()}}</small>
                
            </div>
          </div>
        </div>
         
        <div class="row">
          

          <div class="col-12 col-md-4">
            <div class="form-group">
              <label for="password">Password </label>
              <input #input  type="password" placeholder="Enter Password" name="password" formControlName="password" class="form-control form-control-height"
                [class.is-invalid]="password.invalid && password.touched">
              <small class="text-danger" [class.d-none]="password.valid || password.untouched">{{getPasswordErrorMessage()}}</small>
            </div>
          </div>
          
          <div class="col-12 col-md-4 ml-md-4">
            <div class="form-group">
              <label for="confirmPassword">Confirm Password </label>
              <input #input  type="password" placeholder="Enter Password" name="confirmPassword" formControlName="confirmPassword" class="form-control form-control-height"
                [class.is-invalid]="confirmPassword.invalid && confirmPassword.touched">
              <small class="text-danger" [class.d-none]="confirmPassword.valid || confirmPassword.untouched">{{getconfirmPasswordErrorMessage()}}</small>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="form-group">
              <label for="usage">Usage Type</label>
              <select formControlName="usage" id="usage" name="usage" class="form-control form-control-height"
              [class.is-invalid]="usage.invalid && usage.touched">
                <option [ngValue]="null" disabled selected hidden>Select</option>
                <option [ngValue]="role" *ngFor="let role of usageVal">{{role}} 
                </option>
              </select>
              <small class="text-danger" [class.d-none]="usage.valid || usage.untouched">Usage Type
                is required</small>
            </div>
            <!-- <p>{{usage.value}}</p> -->
          </div>
          
        </div>

        <div class="row">

        


        <div class="col-12 col-md-4  ">
          <div class="form-group">
            <label for="bu">BU  Name </label>
            <input #input placeholder="Enter BU  Name" name="bu" formControlName="bu" class="form-control form-control-height"
              [class.is-invalid]="bu.invalid && bu.touched">
            <small class="text-danger" [class.d-none]="bu.valid || bu.untouched">{{getBUErrorMessage()}}</small>
              
          </div>
          <!-- <p>{{bu.value}}</p> -->
        </div>

        <div class="col-12 col-md-4 ml-md-4">
          <div class="form-group">
            <label for="dbHosted">DB Hosted</label>
            <select formControlName="dbHosted" id="dbHosted" name="dbHosted" class="form-control form-control-height"
            [class.is-invalid]="dbHosted.invalid && usage.touched">
              <option [ngValue]="null" disabled selected hidden>Select</option>
              <option [ngValue]="role" *ngFor="let role of dbHostedVal">{{role}} 
              </option>
            </select>
            <small class="text-danger" [class.d-none]="dbHosted.valid || dbHosted.untouched">DB Hosted Value
              is required</small>
          </div>
          <!-- <p>{{dbHosted.value}}</p> -->
        </div>
      </div>







        <div class="row">
          <div class="text-center offset-lg-8 col-12 col-md-1 mr-md-4 img-width">
            <span class="material-icons add-user-icon-one" *ngIf="addUserIcon" (click)="openUser(null,'Add')" title="Add User">
              person_add
            </span>
          </div>

          <div class="col-12 text-center col-md-1 mt-4 mr-4" *ngIf="addUserIcon"> <!-- for windows -->
            <button class="hover users-button width-100" mat-button (click)="confirmationPopup('VM')" mat-raised-button
              [disabled]="!vmFormGroup.valid || !selectedUserArray.length" title="Create VM">
              Create 
            </button>
          </div>

          <div class="col-12 text-center col-md-1 mt-4 mr-4" *ngIf="!addUserIcon"> <!-- for linux -->
            <button class="hover users-button width-100" mat-button (click)="confirmationPopup('VM')" mat-raised-button
              [disabled]="!vmFormGroup.valid" title="Create VM">
              Create 
            </button>
          </div>

          <div class="col-12 text-center col-md-1 mt-4">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2" (click)="toggleDisplay(vmFormGroup)">Cancel</button>
          </div>
        </div>
        



      </form> 
      
    </div>

    <div class="row userRow" *ngIf="showUserRow && addUserIcon">
      <div class="col-md-1" *ngFor="let user of selectedUserArray;let i = index">
        <span class="close" (click)="removeUser(user,i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png"><br>
        {{user.user_name}}
      </div>
    </div>
    
    <div class="table-responsive">
      <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="vmData.length>0">
        <thead>
          <tr>
              <th  style="width: 15px;">
                  <input type="checkbox"  [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
            </th>
            <th >Request ID</th>
           <th> Operating System </th>
          <th> VM Size </th>
          <th> VM Name </th>
          <th> IP Address</th>
          <th>Status</th>
          <th class="w15">Members</th>
          <th> Action </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of vmData">
            <td class="pl-3"  style="width: 15px;" *ngIf="element.status=='running' || element.status=='stopped'">
                <input type="checkbox"   [(ngModel)]="element.isSelected" name="list_name" value="{{element.reqId}}" (change)="isAllSelected()"/>
              </td>
              <td class="pl-3"  style="width: 15px;" *ngIf="element.status!='running' && element.status!='stopped'">
                  <input type="checkbox"  [disabled]="checkDisable" [(ngModel)]="element.isSelected" name="list_name" value="{{element.reqId}}" (change)="isAllSelected()"/>
                </td>
          <td *ngIf="element.status!='Moved_to_Recycle'">{{element.request_id}} <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showRequestInfo(element)">info</span></td>
          <td class="softDelete-cell" *ngIf="element.status=='Moved_to_Recycle'" >{{element.request_id}} <span style="font-size: 18px;color: gray;" class="material-icons add-user" title="System Information" (click)="showRequestInfo(element)">info</span></td>
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.os_type}} </td>
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.vm_size}} </td>
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.deployment_name ? element.deployment_name : '--NA--'}} </td>
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''"> {{element.ip_address ? element.ip_address : '--NA--'}}</td>
          <td *ngIf='element.status!="Moved_to_Recycle"'>{{element.status | titlecase}}</td>
          <td class="softDelete-cell" *ngIf='element.status=="Moved_to_Recycle"'>Moved to Recycle</td>    
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''" class="w15" *ngIf="element.members.length >0">
              <h4 *ngIf="element.status=='removed' || element.status=='Pending'  || element.status=='Deleted' || element.status=='Failed'">
                  --NA--
                </h4>

            <button *ngIf="element.members.length >1 && (element.status!='removed' && element.status!='Moved_to_Recycle' && element.status!='Deleted' &&  element.status!='Failed' &&  element.status!='Failed' &&  element.status!='Pending')" class="view-member-btn" (click)="openUserDialog(element.members)">View</button> 
            <button *ngIf="element.members.length >1 && (element.status=='Moved_to_Recycle')" class="view-member-btn-inactive" (click)="openUserDialog(element.members)">View</button> 
  
            <div *ngIf="element.members.length == 1">
                <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                  <h4 *ngIf="element.status=='running'  || element.status=='Processing' || element.status=='stopping' ||  element.status=='stopped' ||  element.status=='starting' ||  element.status=='Deleting'">{{user.name}}</h4>
                  <h4 class="softDelete-cell" *ngIf="element.status=='Moved_to_Recycle'">{{user.name}}

                </h4> 
                </span>
              </div>
              
          </td>
          
          <td [ngClass]="element.status=='Moved_to_Recycle' ? 'softDelete-cell' : ''" class="w15" *ngIf="element.members.length ==0">--NA--</td>
          <td *ngIf="element.status!='Moved_to_Recycle'">
            <div class="dropdown">
              <a href="#" class="nav-link" data-toggle="dropdown">
                <i class="fas fa-ellipsis-h"></i>
              </a>
              <div class="dropdown-menu more-drop-down" style="max-height: 145px;overflow-y:auto">
  
                <span *ngIf="element.os_type == 'Windows 2016' || element.os_type == 'Windows 2019' "> <!-- hide if OS type is linux -->
                <a class="dropdown-item">
                  <span  class="table-crud table-edit-icon"  *ngIf="element.status != 'running'" disabled >
                    <img width="20" src="../../../assets/img/AddMember-disabled.png"> <span class="vertical-line-more mx-2"></span> Add Member</span>
                  <span  class="table-crud table-edit-icon"  *ngIf="element.status == 'running'" (click)="openUser(element,'Update')">
                    <img width="20" src="../../../assets/img/AddMember.png"> <span class="vertical-line-more mx-2"></span> Add Member</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span  class="table-crud table-edit-icon"  *ngIf="element.status != 'running'" disabled >
                      <img width="20" src="../../../assets/img/AddMember-disabled.png"> <span class="vertical-line-more mx-2"></span> Delete Member</span>
                    <span  class="table-crud table-edit-icon"  *ngIf="element.status == 'running'" (click)="openUser(element,'Delete')">
                      <img width="20" src="../../../assets/img/AddMember.png"> <span class="vertical-line-more mx-2"></span> Delete Member</span>
                    </a>
                  <!-- <div class="dropdown-divider"></div> -->
                  </span>
  
                <!-- <a class="dropdown-item">
                <span  class="table-crud table-edit-icon"  *ngIf="!element.startStatus" disabled >
                  <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Start</span>
                <span  class="table-crud table-edit-icon"  *ngIf="element.startStatus" (click)="startVM(element.request_id)">
                  <img width="20" src="../../../assets/img/Start-blue.png"> <span class="vertical-line-more mx-2"></span> Start</span>
                </a> -->
                <!-- <div class="dropdown-divider"></div> -->
                <!-- <a class="dropdown-item">
                  <span  class="table-crud table-edit-icon"  *ngIf="!element.stopStatus" disabled >
                    <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop</span>
                  <span  class="table-crud table-edit-icon"  *ngIf="element.stopStatus" (click)="stopVM(element.request_id)">
                    <img width="20" src="../../../assets/img/Stop-blue.png"> <span class="vertical-line-more mx-2"></span> Stop</span>
                  </a> -->
                <div class="dropdown-divider" *ngIf="element.os_type!='Linux CentOS 7'"></div>
                <a class="dropdown-item">
                  <span  class="table-crud table-delete-icon" *ngIf="element.deleteStatus" (click)="deleteconfirmationPopUp(element)">
                    <img width="20" src="../../../assets/img/Delete-blue.png"> <span class="vertical-line-more mx-2"></span>Move to Recycle</span>
                    <span  class="table-crud table-delete-icon" *ngIf="!element.deleteStatus" disabled>
                      <img width="20" src="../../../assets/img/Delete-Deactivate.png"> <span class="vertical-line-more mx-2"></span>Move to Recycle</span>
                  </a>
                  <!-- installl softwareee -->
                    <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span  class="table-crud table-delete-icon" *ngIf="element.status=='running'" (click)="installSoftware(element.os_type, element.ip_address, element.deployment_name)">
                      <img width="20" src="../../../assets/img/Ant Migration Tool.png"> <span class="vertical-line-more mx-2"></span>Install Software</span>
                      <span  class="table-crud table-delete-icon" *ngIf="element.status!='running'" disabled>
                        <img width="20" src="../../../assets/img/AntMigrationDisabled.png"> <span class="vertical-line-more mx-2"></span>Install Software</span>

                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span  class="table-crud table-delete-icon" *ngIf="element.status=='running'" (click)="uninstallSoftwareVdi(element.os_type, element.ip_address, element.deployment_name)">
                      <img width="20" src="../../../assets/img/restore.png"> <span class="vertical-line-more mx-2"></span>Uninstall Software</span>
                      <span  class="table-crud table-delete-icon" *ngIf="element.status!='running'" disabled>
                        <img width="20" src="../../../assets/img/restore-disabled.png"> <span class="vertical-line-more mx-2"></span>UnInstall Software</span>
                    </a>
              </div>
            </div>
           
          </td>
          <td *ngIf="element.status=='Moved_to_Recycle'" class="softDelete-cell"> 
              <a href="#" class="nav-link" data-toggle="dropdown" *ngIf="element.status=='Moved_to_Recycle'">
                  <i class="fas fa-ellipsis-h" style="color:rgba(0, 0, 0, 0.86);"></i>
                </a>
          </td>
       
        </tr>
        </tbody>
      </table>

      <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="vmData.length==0">
        <thead>
          <tr>
            <th>Request ID</th>
           <th> Operating System </th>
          <th> VM Size </th>
          <th> VM Name </th>
          <th> IP Address</th>
          <th>Status</th>
          <th class="w15">Members</th>
          <th> Action </th>
        </tr>
        </thead>
        <tbody>
          
        </tbody>
      </table>
    </div>

    </div>


</div>
<div class="modal fade" id="myModal"  role="dialog">
  <div class="modal-dialog">
  
    <!-- Modal content-->
    <div class="modal-content modal-adjust" >
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <h2 class="text-center">No tool instance Available</h2>
      </div>
      <div class="modal-header">
        
      </div>
    </div>
    
  </div>
</div>