import { OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectSummaryComponent } from 'src/app/project-details/project-summary/project-summary.component';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { HttpErrorResponse } from '@angular/common/http';

const ELEMENT_DATA: RepoTable[]=[];
@Component({
  selector: 'app-azzure-devops',
  templateUrl: './azzure-devops.component.html',
  styleUrls: ['./azzure-devops.component.scss']
})

export class AzzureDevopsComponent implements OnInit {
  projectId;
  leadEmailId;
  projectName;
  projectData : any;
  projectKey: string;
  members : any = [];
  newUser: any = [];
  removedUser: any = [];
  username : any = [];
jira_project_url : any;
  jira_project_name: any;
  jira_project_key : string;
  pipeCompletionStatus : String = "Not Initiated";
  onboarding_message: any;
  jira_project_members: any= [];
  toolName;
  showCatalog : boolean = false;

  showAddRepo: boolean = true;
  showUserRow: boolean = false;
  memberString = [];
   tableArr  = [];

  newArray: any = [];
  finalMemberArray: any = [];
  tableArray: any = [];
  userStatus = "";
  deletedStatus: string;
  newStatus: string;

  secondFormGroup: FormGroup;
  visibilityVal: any = [
    "Public",
    "Private"
  ];
  versionControlVal: any = [
    "Git",
    "Team Foundation Version Control"
  ];
  processTemplateVal: any= [
    { value: 'adcc42ab-9882-485e-a3ed-7678f01f66bc', viewValue: 'Agile' },
    { value: '6b724908-ef14-45cf-84f8-768b5384da45', viewValue: 'Scrum' },
    { value: '27450541-8e31-4150-9947-dc59f998fc01', viewValue: 'CMMI' },
    { value: 'e31fd6e1-c6af-44bb-925e-dfa71f551463', viewValue: 'Custom' }
  ];
 
  roleNameVal: any = ["TM","PM"];
 
  tableRepoArray : any = [];
  showTable : boolean = false;
  showRepo : boolean = false;
  selectedUserArray : any = []
  templateTypeId : any;
  //ELEMENT_DATA: RepoTable[];
  displayedColumns: string[] = ['repoName', 'visibility', 'versionControl','members','action'];
  dataSource = new MatTableDataSource<RepoTable>(ELEMENT_DATA);

  selection = new SelectionModel<MemberDetails>(true, []);
  processTemplateId ;

options = {
  autoClose: true,
  keepAfterRouteChange: true
};
  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  processTemplateViewValue: any;
  catalog_id: string;
  catalog_name: string;
  Role:string="";
  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService : AuthService,
    private _snackBar: MatSnackBar,public router :Router,private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.alertService.yesClickedEvent
    .subscribe((data:string) => {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.secondFormGroup.reset();
    });

    this.alertService.workbenchYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/workBench'])
    });

    this.alertService.homeYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/projectSummary'])
    });

    this.projectId=localStorage.getItem('projectId');
    this.projectName=localStorage.getItem('projectName');

    this.catalog_id=localStorage.getItem('catalogId');
  
    this.catalog_name=localStorage.getItem('catalogName');

    this.apiService.leadEmailId.subscribe(leadEmailId => {
      this.leadEmailId = leadEmailId;
    })

    this.apiService.toolSelectedFromDialog.subscribe(data =>{
      this.toolName = data 
      })

    

      this.secondFormGroup = this._formBuilder.group({
        repoName: ['', Validators.required],
        visibility: ['', Validators.required],
        versionControl: ['', Validators.required],
        processTemplate: ['', Validators.required],
       
      });
    }
    else{
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
    });
    }
  }

  get repoName() {
    return this.secondFormGroup.get('repoName');
  }
  get visibility() {
    return this.secondFormGroup.get('visibility');
  }
  get versionControl() {
    return this.secondFormGroup.get('versionControl');
  }
  get processTemplate() {
    return this.secondFormGroup.get('processTemplate');
  }
  

  removeUser(user,index){
    this.selectedUserArray.splice(index,1)
  }
  

  addRepo() {
    this.showRepo = true;
    this.showAddRepo = false
  }
  
  //Cancel click
  toggleDisplay(secondFormGroup) {
    if(secondFormGroup.pristine == false){
    this.cancelconfirmationPopUp();
    } else {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];;
    }
 
  }

  // confirmation popup
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(devOpsCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
     
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.scmworkbenchConfirmationPopup();
      } else {
        this.router.navigate(['/workBench'])
      }
  }
  goHome(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.homeConfirmationPopup();
      } else {
        this.router.navigate(['/projectSummary'])
      }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(devOpsWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe( data => {
      }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(devOpsHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {   
      }
    );
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.createProject();
        }
      }
    );
  }
  
  openUser() {
    const azureUser = this.dialog.open(AzureMembers, {
      width: '80%',
      height: '60%',
      disableClose: true
    });

    azureUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
    })

  }

  addCatalog(){
    this.showCatalog = true;
    }

    memberArray=[]
 
  generateProjectKey() {
    var projectId = "";
    var length = 7;
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    for (var i = 0; i < length; i++)
        projectId += possible.charAt(Math.floor(Math.random() * possible.length));
    this.projectKey = projectId;
    return projectId;
}

processTemplateChange(event){ 
  this.processTemplateViewValue = event.target.options[event.target.selectedIndex].text; 
}

openAddedUserDialog(memberArray) {
  const dialogRef = this.dialog.open(MembersListDialog, {
    width: '50%',
    height: '52%',
    disableClose: true,
    data:{
        member: memberArray
      }
  });

  dialogRef.afterClosed().subscribe(result => {
  });
}

createProject() {
  let memberArrayToSend = []
  this.showAddRepo = true;
  this.showRepo = false;
  this.showUserRow = false;
  this.memberString = [];
  
  this.selectedUserArray.forEach((user: any) => {
    let membObj={
      name: user.user_name,
      email : user.user_email,
      role : user.role_name
    }
    this.memberString.push(membObj)
    let memberObj = {
      "roleName": user.role_name,
      "groupType": user.groupType,
      "email" : user.user_email,
      "accountLicenseType" : user.accountLicenseType
    }
    memberArrayToSend.push(memberObj)
  })

  let tableObj =
  {
    "repoName": this.repoName.value,
    "visibility": this.visibility.value,
    "versionControl": this.versionControl.value,
    "processTemplate": this.processTemplateViewValue, 
    "members": this.memberString
  }

  this.tableArr.push(tableObj)

  let projectList = [];
  projectList.push(this.repoName.value)
  let userPsNumber = "";
  this.apiService.psNumber.subscribe((psNumber:any)=>{
    userPsNumber = psNumber
  })
 
  let azureBody =   {
    "project_id" :  this.projectId,
    "organization_name" : {
    "name" : "CanvasEngineering",
    "visibility" : this.visibility.value
        },
    "name" : this.repoName.value,
    "sourceControlType" : this.versionControl.value,
    "templateTypeId" : this.processTemplate.value,
    "members"  : memberArrayToSend
    }


    this.dataSource.data = this.tableArr as RepoTable[]
    this.secondFormGroup.reset()
    this.showTable = true;
    this.apiService.createAzzureProject(azureBody).subscribe(azureResult =>{
    this.dtTrigger.next();
   
  }, (error: HttpErrorResponse) => {
    if (error.status == 449){
    this.alertService.error(error.error.message, this.options);
     }
    else {
    this.alertService.error("Error while creating project.", this.options);
    throw error;
     }
     })
 
  
}
  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  createPipeline(){
    for(var i =0;i< this.selection.selected.length;i++){
      var name = this.selection.selected[i].user_email;
      var username = name.slice(0,name.indexOf("@"));

      let obj = {
              "user_name" :this.selection.selected[i].user_name,
              "email_id" :this.selection.selected[i].user_email,
              "role_name" :this.selection.selected[i].role_name,
            
                }
            this.username.push(obj)
    }
    if(this.username.length == 0){

this._snackBar.open("Select User", "" ,{
  duration: 2000,
});
    }
    else{
      this.generateProjectKey();
      let pipelineObj = {
        "project_name" : this.projectName,
        "project_id" : this.projectId,
        "members" : this.username,
        "planning_project_key": this.projectKey,
        "webhook_url":"https://outlook.office.com/webhook/0aa4f7ed-1502-4006-95c0-9ec5cc46a2c9@ff355289-721e-4dd7-a663-afec62ab9d54/JIRA/7b46c36dc2594bd89d5a72802fd1bedf/fb18ee70-be4d-4158-a4f0-160de1cfc384"
                       }
   
     // console.log(pipelineObj);
      this.apiService.createJiraProject(pipelineObj).subscribe((data:any)=>{
      //  console.log(data,"after create button");
        if( data.status == "Jira Project Task Initialized"){
          this.apiService.castToolDialog.next("Project Creation Initiated")
         this.router.navigate(['/workBench']);
        }else{
          this._snackBar.open("Project Creation Initiated", "" ,{
            duration: 2000,
          });
          
         this.router.navigate(['/workBench']);
        }
        

       })
    }
  } 

}

export interface RepoTable {
  repoName: string;
  visibility : string;
  versionControl : string;
  processTemplate : string;
  accountLicenceType : string;
  members: string;
  action: string;
}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  groupType: string;
  accountLicenseType: string;
}

interface GroupTypeVal {
  value: string;
  viewValue: string;
}
interface accountLicenceTypeVal {
  value: string;
  viewValue: string;
}
const ELEMENT_DATAA: MemberDetails[]=[];
@Component({
  selector: 'azure-members',
  templateUrl: 'azure-members.html',
  styleUrls: ['./azzure-devops.component.scss']
})
export class AzureMembers {
  projectId: any;
  projectName: any;
  members: any = [];
  newUser: any = [];
  removedUser: any = [];
  newArray: any = [];
  finalMemberArray: any = [];
  tableArray: any = [];
  newStatus: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  roleNameVal: any = ["TM","PM"];
  GroupTypeVal: GroupTypeVal[] = [
    { viewValue: 'Project Administrator', value: 'projectAdministrator' },
    { viewValue: 'Project Contributor', value: 'projectContributor' },
    { viewValue: 'Project Reader', value: 'projectReader' },
    { viewValue: 'Project Stakeholder', value: 'projectStakeholder' },
  ];
  accountLicenceTypeVal: accountLicenceTypeVal[] = [
    { value: 'Stakeholder', viewValue: 'Stakeholder' },
    { value: 'Professional', viewValue: 'Professional' },
    { value: 'None', viewValue: 'None' },
    { value: 'Express', viewValue: 'Express' },
    { value: 'EarlyAdopter', viewValue: 'EarlyAdopter' },
    { value: 'Advanced', viewValue: 'Advanced' },
  ];

  secondFormGroup: FormGroup;

 // ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'role_name','groupType', 'accountLicenseType','select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATAA);
  selection = new SelectionModel<MemberDetails>(true, []);

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    //return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   this.isAllSelected() ?
     this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog,public alertService: AlertService,private _formBuilder: FormBuilder, private apiService: AuthService, private _snackBar: MatSnackBar, public router: Router, public dialogRef: MatDialogRef<SCMMembers>) { }

  ngOnInit(): void {

   
    this.projectId=localStorage.getItem('projectId');

    
    this.projectName=localStorage.getItem('projectName');
    this.getUser();
  }

  get accountLicenseType() {
    return this.secondFormGroup.get('accountLicenseType');
  }
  get groupType() {
    return this.secondFormGroup.get('groupType');
  }

  getVDITypeValue(value) {
   
    
  }

  addUserForRepo() {
    if (this.selection.selected.length == 0) {
    } else {
      this.dialogRef.close(this.selection.selected);
      
    }
  }

  
  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;
      this.newUser = project[0].team_members_delta.newMembers;
      this.removedUser = project[0].team_members_delta.removedMembers;

      if (this.newUser == undefined || this.newUser.length == 0) {
        this.members.forEach(member => {
          let obj = {
            "user_id": member.user_id,
            "user_name": member.user_name,
            "user_email": member.user_email,
            "role_name": member.role_name,
            "accountLicenseType" :  "Stakeholder",
            "groupType" : "projectStakeholder",
          }
          this.newArray.push(obj)
        })
        this.members = this.newArray;
      } else {
        this.members.forEach((member, index) => {
          this.newStatus = "existing"

          for (var i = 0; i < this.newUser.length; i++) {
            if (member.user_id == this.newUser[i].EmployeeId) {
              this.newStatus = "new";
              break;
            }
          }
          this.members[index]['user_project_status'] = this.newStatus
        })
      }
      if (this.removedUser == undefined || this.removedUser.length == 0) {
        this.tableArray = this.members;
        this.finalMemberArray = this.members;
        this.dataSource.data = this.members as MemberDetails[]
      } else {
        this.members.forEach((array, index) => {
          this.newStatus = array.user_project_status
          for (var i = 0; i < this.removedUser.length; i++) {
            if (array.user_id == this.removedUser[i].EmployeeId) {
              this.newStatus = "deleted";
              break;
            }
          }
          this.members[index]['user_project_status'] = this.newStatus;
        })
        this.dataSource.data = this.members as MemberDetails[]
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }
  exit() {
    this.dialogRef.close();
  }
}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./azzure-devops.component.scss']
})
export class devOpsCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<devOpsCancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./azzure-devops.component.scss']
})
export class devOpsWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<devOpsWorkbenchConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  workbenchPopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./azzure-devops.component.scss']
})
export class devOpsHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<devOpsHomeConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  homePopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
