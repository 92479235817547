import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from 'src/app/service/api.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { from, Subject, throwError } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ProjectTable } from 'src/app/project-configurations/story-board/story-board.component';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface CatalogEdit {
  in_catalogName: string;
  in_ID: string;
  in_catalogDescription: string;
  in_Cost: string;

}

export interface CatlogDetails {
  Name: string;
  ID: string;
  Description: string;
}


export interface configList {
  Name: string;
  ID: string;
  Description: string;
  Cost: string;
  tool_group_id: string;
}


export interface ResourceTable {
  resourceName: string;
  resourceType: string;
  description: string;
  db_id: String;
  tool_id: String;
  action: string;
  tool_group_id: string;
  tool_instance_name: string;
}

export interface ToolType {
  tool_group_name: string;
  _id: string;

}

const ELEMENT_DATAA: ResourceTable[] = [];
const ELEMENT_DATAA1: CatlogDetails[] = [];
@Component({
  selector: 'app-admin-catalog-config',
  templateUrl: './admin-catalog-config.component.html',
  styleUrls: ['./admin-catalog-config.component.scss']
})
export class AdminCatalogConfigComponent implements OnInit,CanComponentLeave {
  SubscriptionId: any;
  SCMPopupData: any;
  selectedUserArrayMaster = [];
  catalogName: any;
  catalogDescription: any;
  ToolArray = [];
  ToolGroupArray = [];
  ProjectArray = [];
  SubEditToolGroupArray = [];
  catalogToolGrDD: any;
  toolGroup: any;
  editMode: boolean = false;
  saveButtonDisable: boolean = false
  project_toolGrName: any;

  toolGroup_selected_ID: string;
  model: any = {};
  formMode: string = "";
  Role: string = "";
  confirmDelete: boolean = false;
  toolsGroup = [];
  tools = [];
  toolType = [];
  GetTool = [];
  resource = ['Tool Group', 'Project Template'];
  catalog_description: string;
  catalog_name: string;
  TableScreen: string;
  Catlog_Id: any;
  submitted = false;
  disableCatalogAdd: boolean = false;
  isDisplayToolDD: boolean = false;
  isDisplayToolGroupDD: boolean = false;
  isShown: boolean = false;
  showTable: boolean = false;
  tableResourceArray: any = [];
  selectedUserArray: any = []
  arrayWithDuplicates = [];
  project_id_scmEdit: string;
  saveEditArray: any = [];
  isDisplayToolType: boolean = false;

  popoverTitle: string = "Delete";
  // popoverTitleProject:string = "Delete Project Information";

  popoverMessage: string = "Are you sure want delete?";
  cancelClicked: boolean = false;


  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  projectId;
  catalogObj: object[];

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  showForm: boolean = false;
  hideTable: boolean = true;
  CatlogArray = [];
  errorMsgAddDrop="";
  showDivAddDrop: boolean;
  // ELEMENT_DATAA: ResourceTable[];
  displayedColumns: string[] = ['resource', 'resourceType', 'Description'];
  dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATAA);

  deleteErrorFlag: boolean = false;


  Project_list: any;
  deleteErrorMsg: any; gitlabBody = []; jenkinsBody = []; jiraBody = []; vdiBody = []; vmBody = [];
  catalogid: any;
  catalogindex: any;
  deleteResourceRecordRow: any;
  deleteResourceRecordindex: any;
  ProjectRecordRow: any;
  showDivAdd: boolean;
  errorMsgAdd: string;
  errorMsgDelete: string;
  showDivDelete: boolean;
  addFlag: number;
  nextUrl: any;
  

  canLeave(url):boolean{
   // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((  this.model.catalogName == undefined ||   this.model.catalogName == "" ) && (this.model.catalogDescription == undefined
        || this.model.catalogDescription == "")){
        return true
      }
      if ((  this.model.catalogName != undefined ||   this.model.catalogName != "" ) && (this.model.catalogDescription != undefined
        || this.model.catalogDescription != "")){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, public alertService: AlertService,
    private apiService: AuthService, public dialog: MatDialog, private spinner: NgxSpinnerService) { }

  ELEMENT_DATAA1: CatlogDetails[];
  displayedColumns1: string[] = ['Name', 'ID', 'Description', 'select'];
  dataSource1 = new MatTableDataSource<CatlogDetails>(ELEMENT_DATAA1);
  selection = new SelectionModel<CatlogDetails>(true, []);

  ngOnInit(): void {
    // debugger
    //debugger;
    this.dtOptions = {
      order: []
    };
    this.Role = localStorage.getItem("Role");
    if(this.Role==""){
      this.router.navigate(["/vdiRequestForm"])
      // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/admin-gig']);
      // });
      return 

    }
    else if(this.Role==null){
      this.router.navigate(["/vdiRequestForm"])
      // window.location.reload();
      // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/admin-gig']);
      // });
      return 

    }
    else if (this.Role == "Admin") {
      this.formMode = localStorage.getItem('formMode')

      if (this.formMode == "Edit") {

      }

      this.spinner.show();
      this.GetCatalogtableDetails();

      this.spinner.hide();

    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
      });
    }
  }
  refreshCatalog() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
    });
  }
  GetCatalogtableDetails() {
    //
    this.apiService.Admin_CatlogTableDetails().subscribe((res: any) => {
      //console.log("Catalog Details ")
      // console.log(res)
      res.forEach(member => {
        let obj = {
          "Name": member.catalog_name,
          "ID": member.catalog_id,
          "Description": member.catalog_description,
        }
        this.CatlogArray.push(obj)
      })
      this.dataSource1.data = this.CatlogArray as CatlogDetails[]
      this.dtTrigger.next();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 437) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
    // SCMArray
  }
  deleteRecord(id, index) {
    // debugger
    this.catalogid = id
    this.catalogindex = index

  }
  deleteSubscription() {
    let body = {
      "catalog_ids": [this.catalogid]
    }
    this.apiService.Admin_deleteSubscription(body).subscribe((res: any) => {

      if (res.status == 200) {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });
        this.tableResourceArray.splice(this.catalogindex, 1);
        this.alertService.success(res.body.message, this.options);
        //this.alertService.success(this.catalogid + " Deleted Successfully", this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.error.code == "02") {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });
        this.alertService.error(error.error.msg, this.options);
        return;
      }
      else if (error.status == 403) { }
      if (error.status == 437) {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });
        this.alertService.error(error.error.message, this.options);
      }
      else {
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
  }

  deleteResourceRecord(row, i) {
    this.deleteResourceRecordRow = row;
    this.deleteResourceRecordindex = i;


  }



  deleteResource(row, i) {
    this.deleteResourceRecordRow = row;
    this.deleteResourceRecordindex = i;



    // console.log(row);



    let resource_type;



    if (this.deleteResourceRecordRow.resourceType == 'Project-Template') {



      resource_type = 'template'



    }



    else if (this.deleteResourceRecordRow.resourceType == "ToolGroup") {



      resource_type = 'tool-group'



    }



    else if (this.deleteResourceRecordRow.resourceType == "Tool") {



      resource_type = 'tool'



    }





    //this.tableResourceArray.splice(i,1);

    if (this.editMode == false) {
      this.tableResourceArray.splice(this.deleteResourceRecordindex, 1);
    }
    else {

      let body = {



        "catalog_id": this.project_id_scmEdit,



        "resource_type": resource_type,



        "id": this.deleteResourceRecordRow.tool_id,
        "updated_by":localStorage.getItem('mail').toLowerCase()



      }

      console.log(body);





      this.apiService.Admin_deleteResource(body).subscribe((res: any) => {





        if (res.status == 200) {
          /*this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {



            this.router.navigate(['/admin-catalog-config']);



          });*/



          this.tableResourceArray.splice(this.deleteResourceRecordindex, 1);



         // this.alertService.success(this.deleteResourceRecordRow.tool_id + " Deleted Successfully", this.options);
          this.alertService.success(res.body.message, this.options);


        }



      }, (error: HttpErrorResponse) => {



        //



        //debugger;



        if (error.error.code == "02") {



          this.deleteErrorFlag = true;



          this.deleteErrorMsg = error.error.msg



          setTimeout(() => {



            this.deleteErrorFlag = false;



          }, 2000);



          /*this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      
      
      
          this.router.navigate(['/admin-catalog-config']);
      
      
      
          });*/



          this.alertService.error(error.error.msg, this.options);



          return;



        }



        else if (error.status == 403) { }



        if (error.status == 446) {



          this.alertService.error(error.error.message, this.options);



        }



        else {



          this.alertService.error("Invalid Input", this.options);



          throw error;



        }





      })
    }


  }

  addToolResouce() {

  }

  selectOption(selectedID: string) {
    this.toolGroup_selected_ID = selectedID;
  }

  addToolGroupResouce() {

    //console.log(this.toolGroup_selected_ID,"addToolGroupResouce_sem")
    this.apiService.Admin_getcatalogToolGroupName(this.toolGroup_selected_ID).subscribe((res: any) => {
      //console.log(" Value from DB")
      //console.log(res)

      var NewArryList = [];
      var Dupcated_NewArryList = [];
      this.arrayWithDuplicates = [];
      if (this.tableResourceArray.length > 0) {


        for (var s = 0; s < res.body.length; s++) {
          let tempObj =
          {
            "resource": "Grouping",
            "resourceType": "ToolGroup",
            "tool_instance_name": res.body[0].tool_group_name,
            "Description": res.body[0].tool_group_description,
            "db_id": res.body[0]._id,
            "tool_id": res.body[0].tool_group_id,

          }
          //debugger;
          this.arrayWithDuplicates.push(tempObj);

        }

        for (var s = 0; s < this.tableResourceArray.length; s++) {
          this.arrayWithDuplicates.push(this.tableResourceArray[s]);

        }

        var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
        //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


        for (var s = 0; s < this.tableResourceArray.length; s++) {
          for (var j = 0; j < uniqueArray.length; j++) {



            if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
              uniqueArray.splice(j, 1);
            }
          }
        }
        if (uniqueArray.length > 0) {
          //debugger;
          uniqueArray.forEach(member => {
            let tableObj =
            {

              "resource": "Group",//member.resource,
              "resourceType": "ToolGroup",
              "tool_instance_name": member.tool_instance_name,
              "Description": member.Description,
              "db_id": member.db_id,
              "tool_id": member.tool_id
              // "action": ""
            }
            //
            this.tableResourceArray.push(tableObj)
          })

          this.dataSource.data = this.tableResourceArray as ResourceTable[]

          this.showTable = true;
          this.isDisplayToolGroupDD = false;
          this.disableCatalogAdd = false;
          this.dtTrigger.next();

        }



      }
      else {

        //debugger;
        res.body.forEach(member => {
          let tableObj =
          {
            "resource": "Group",
            "resourceType": "ToolGroup",
            "tool_instance_name": res.body[0].tool_group_name,
            "Description": res.body[0].tool_group_description,
            "db_id": res.body[0]._id,
            "tool_id": res.body[0].tool_group_id,
          }
          //
          this.tableResourceArray.push(tableObj)
        })

        this.dataSource.data = this.tableResourceArray as ResourceTable[]

        this.showTable = true;
        this.isDisplayToolGroupDD = false;
        this.disableCatalogAdd = false;
        this.dtTrigger.next();
      }
    })


  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  setFormMode() {
    this.addFlag=1
    this.showForm = true;
    this.hideTable = false;
    this.editMode = false;
    this.showTable = false;
    this.tableResourceArray=[];
    this.Project_list=[];
    this.clearCatalogDetails();
    localStorage.setItem("formMode", "Save");
  }


  GetSubscriptionDetails(row) {

    this.SubscriptionId = row.ID;
    this.project_id_scmEdit = this.SubscriptionId;
    var SubscriptionName = row.Name;
    var SubscriptionDesc = row.Description;
    this.model.catalogName = SubscriptionName;
    this.model.catalogDescription = SubscriptionDesc;
    this.tableResourceArray = [];

    this.apiService.Admin_GetSubscriptionDetails(this.SubscriptionId).subscribe((res: any) => {
      res.body.ToolGroup_list.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          let ToolGroupobj = {
            "resource": "Group",
            "resourceType": "ToolGroup",

            "tool_instance_name": member.tool_group_name,
            "Description": member.tool_group_description,
            "db_id": member._id,
            "tool_id": member.tool_group_id
            //"action": "Remove"                      
          }
          this.tableResourceArray.push(ToolGroupobj)
        }
      })

      this.Project_list = res.body.Project_list;

      res.body.Template_list.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          let Projectobj = {
            "resource": "Template",
            "resourceType": "Project-Template",
            "tool_instance_name": member.template_name,
            "Description": member.template_description,
            "db_id": member._id,
            "tool_id": member.template_id
            //"action": "Remove"                      
          }
          this.tableResourceArray.push(Projectobj)
        }
      })



      res.body.Tool_list.forEach(member => {

        if (member == "null" || member == null || member == undefined) {
          //console.log("res.body.Tool_list");
          //console.log(res.body.Tool_list);
          // return;
        }
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          //console.log("Tool object")


          let Toolobj: any;

          if (member.tool_category == "VDI") {
            //debugger;
            //console.log("Inside the VDI condition")
            Toolobj = {
              "resource": "VDI",
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.name,
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }

            //console.log(Toolobj);
          }
          else if (member.tool_category == "Deployment-Store") {
            //debugger;
            //console.log("Inside the Deployment-Store condition")
            Toolobj = {
              "resource": "Deployment-VM",
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.name,
              // "Description": "-",
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }

          else if (member.tool_category == "CICD") {
            //   debugger;
            // console.log("Inside the Jenkins condition")
            Toolobj = {
              "resource": "Jenkins",
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,

              // "Description": "-",
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }

          else if (member.tool_category == "Network-Config") {
            //debugger;
            // console.log("Inside the Network condition")
            Toolobj = {
              "resource": member.tool_name,
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,
              "Description": member.tool_instance_details.description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }
          else if (member.tool_category == "StoryBoard") {
            //console.log("Inside the others (SCM, StoryBoard, Azure Config)")
            Toolobj = {
              "resource": "Jira",
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }
          else if (member.tool_category == "SCM") {
            // console.log("Inside the others (SCM, StoryBoard, Azure Config)")
            Toolobj = {
              "resource": "GitLab",
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }
          else if (member.tool_category == "AWS") {
            //debugger;
            //console.log("Inside the AWS condition")
            Toolobj = {
              "resource": member.tool_name,
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,
              "Description": member.tool_instance_details.description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }
          else if (member.tool_category == "AWS") {
            //debugger;
            //console.log("Inside the AWS condition")
            Toolobj = {
              "resource": member.tool_name,
              "resourceType": "Tool",
              "tool_instance_name": member.tool_instance_details.tool_instance_name,
              "Description": member.tool_instance_details.description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }
          else {
            // console.log("Inside the others (SCM, StoryBoard, Azure Config)")
            Toolobj = {
              "resource": member.tool_instance_details.tool_instance_name,
              "resourceType": "Tool",
              "Description": member.tool_instance_details.tool_description,
              "db_id": member._id,
              "tool_id": member.tool_id
              // "action": "Remove"                      
            }
          }

          this.tableResourceArray.push(Toolobj)

        }
      })


      //console.log(this.tableResourceArray,"Edit")

      this.showTable = true;

      this.dtTrigger.next();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 437) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })

  }

  projectInfo(projectid) {
    this.apiService.Admin_projectInfo(projectid).subscribe((res: any) => {
      this.gitlabBody = res.body.gitlab_projects
      this.jenkinsBody = res.body.jenkins_jobs
      this.jiraBody = res.body.jira_projects
      this.vdiBody = res.body.vdi_info
      this.vmBody = res.body.vm_info

    })
  }
  deleteProjectRecord(row) {
    this.ProjectRecordRow = row;


  }

  deleteProject(row, i) {
    // debugger
    this.ProjectRecordRow = row;

    let requestor = localStorage.getItem('mail').toLowerCase();
    let body = {
      "project_id": this.ProjectRecordRow.project_id,
      "project_name": this.ProjectRecordRow.project_name,
      "catalog_id": this.project_id_scmEdit,
      "requester_id": requestor
    }
    // console.log(this.tableResourceArray, "array");
    // console.log(this.Project_list, "list");

    this.apiService.Admin_deleteProject(body).subscribe((res: any) => {
      if (res.status == 200) {
        /*this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });*/
        this.Project_list.splice(i, 1);


        // console.log(this.tableResourceArray, "array_end");
        // console.log(this.Project_list, "list_end");
       // this.alertService.success(this.ProjectRecordRow.project_name + " Deleted Successfully", this.options);
       this.showDivDelete = true;
      //  this.errorMsgDelete=this.ProjectRecordRow.project_name + " Deleted Successfully";
       this.errorMsgDelete=res.body.message;

      // this.alertService.success(res.body.message, this.options);

       setTimeout(() => {
         this.showDivDelete = false;

       }, 3000);
        this.alertService.success(res.body.message, this.options);
      }
    }, (error: HttpErrorResponse) => {

      if (error.error.code == "02") {
        this.deleteErrorFlag = true;
        this.deleteErrorMsg = error.error.msg
        setTimeout(() => {
          this.deleteErrorFlag = false;
        }, 2000);
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
        });
        this.alertService.error(error.error.msg, this.options);
        return;
      }
      else if (error.status == 403) { }
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else {
        // this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/admin-catalog-config']);
        // });
        this.showDivAdd = true;
        this.errorMsgAdd="Project can't be deleted. Delete active resources."
        setTimeout(() => {
          this.showDivAdd = false;
          
        }, 3000);
        // this.alertService.error("Invalid Input", this.options);
        throw error;
      }

    })

  }

  getRecord(row) {
    this.addFlag=1
    this.tableResourceArray=[];
    this.GetSubscriptionDetails(row);

    this.catalogObj = row;
    //this.apiService.SCMToolDetails.next(this.scmObj)
    try {
      localStorage.setItem('catalogObj', JSON.stringify(this.catalogObj));
      localStorage.setItem("formMode", "Edit")
    }
    catch {
      return throwError('Data not found');
    }
    this.editMode = true;

    // this.router.navigate(['/admin-catalog-config']);
    this.showForm = true;
    this.hideTable = false;
    // console.log(this.Project_list, "list_end");
    // console.log(this.tableResourceArray, "list_end");



  }

  onSelectResource(value) {

    this.toolsGroup = [];
    if (value == "Tool") {
      this.isDisplayToolDD = true;
      this.isDisplayToolGroupDD = false;
      this.getToolList();
      this.isShown = false;

    }
    else if (value == "Tool Group") {
      this.isDisplayToolDD = false;
      this.isDisplayToolGroupDD = true;
      this.getToolGroupList();
      this.isShown = false;
    }
    else if (value == "Project Template") {
      //  this.isDisplayToolDD = false;
      //this.isDisplayToolGroupDD = true;
      //this.getToolGroupList();
      localStorage.setItem("TablePopup", value);
      this.openDialog(value);
      this.disableCatalogAdd = false
      this.isShown = false;
    }
    else{
      this.isShown = true;
      this.showDivAddDrop = true;
      this.errorMsgAddDrop="Please Select Resource "
      setTimeout(() => {
        this.showDivAddDrop = false;
      }, 3000);
return;
    }
    
  }

  selectToolName(value) {

    //console.log(value);
    this.isDisplayToolDD = false;
    this.isDisplayToolType = true;

    if (value == 'SCM') {
      this.toolType = ['GitLab', 'GitHub'];
    }
    else if (value == "Story Board") {
      this.toolType = ['Jira'];

    }
    else if (value == "Devops") {
      this.toolType = ['Jenkins'];

    }
    else if (value == "VDI") {
      this.toolType = ['VDI-Images'];
      //  localStorage.setItem("TablePopup", value);
      //this.openDialog("VDI");
    }
    else if (value == "VM") {
      this.toolType = ['VM-Images'];

    }
    else if (value == "Network-Config") {
      this.toolType = ['Network-AKS', 'Network-VDI', 'Network-VM'];
    }
    else if (value == "AWS") {
      this.toolType = ['AWS-Work-Space'];
    }
    else{
      this.isDisplayToolDD = true;
    this.isDisplayToolType = false;
    this.showDivAddDrop = true;
    this.errorMsgAddDrop="Please Select Tool Type "
    setTimeout(() => {
      this.showDivAddDrop = false;
    }, 3000);
return;
    }
  }

  getToolTypeList(value) {
    //
    if (value == "SCM") {

      this.openDialog(value);
    }
    else if (value == "Story Board") {
      this.openDialog(value);

    }
    else if (value == "Devops") {
      this.openDialog(value);

    }
    else if (value == "VDI") {
      this.openDialog(value);

    }
    else if (value == "VM") {
      this.openDialog(value);

    }

    else if (value == "Network-Config") {
      this.openDialog(value);

    }
    else if (value == "AWS") {
      this.openDialog(value);

    }
    else{
      this.showDivAddDrop = true;
      this.errorMsgAddDrop="Please Select Tool"
      setTimeout(() => {
        this.showDivAddDrop = false;
      }, 3000);
  return;
    }
  }

  GetToolType(value) {
    if(value!="Select Tool"){
      localStorage.setItem("TablePopup", value);
    this.openDialog(value);

    }
    //
    else{
    //console.log(value);
    this.showDivAddDrop = true;
    this.errorMsgAddDrop="Please select valid tool"
    setTimeout(() => {
      this.showDivAddDrop = false;
    }, 3000);
return;
    }
  }

  getToolList() {

    this.tools = [{ "_id": 1, "tool_group_name": "SCM" }, { "_id": 2, "tool_group_name": "VDI" }, { "_id": 3, "tool_group_name": "Story Board" }, { "_id": 4, "tool_group_name": "VM" },
    { "_id": 5, "tool_group_name": "Network-Config" }, { "_id": 6, "tool_group_name": "Devops" }, { "_id": 7, "tool_group_name": "AWS" }];
  }

  CancelToolType() {

    this.isDisplayToolType = false;
    this.isDisplayToolDD = true;

  }

  CancelTools() {

    this.isDisplayToolDD = false;
    this.isShown = true;
  }

  onChange($event) {
    ////debugger;
    let CataToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = CataToolGrName;
    //console.log("CataToolGrName",CataToolGrName);
    localStorage.setItem("TablePopup", CataToolGrName);
  }

  CancelToolGroupResouce() {

    this.isDisplayToolGroupDD = false;
    this.isShown = true;
  }
  getToolGroupList(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        var i;
        for (i = 0; i < data.body.length; i++) {
          //   
          // console.log(data.body[i].tool_group_name);
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.toolsGroup.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        if (error.status == 500) {

          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }

  CheckUniqueNameValidation(){
    // debugger
  

    this.apiService.Admin_CheckValidateSubscriptionName(this.model.catalogName).subscribe((res: HttpResponse<any>) => {
      // debugger
       //console.log("Inside Validate");
      // console.log(res.body);
       if (res.status == 200) {
         
 
         let code = res.body.code
         console.log(code);
         
 
         switch (code) {
  
           case "02":
             
            this.alertService.error(res.body.message, this.options);
            break;
       
           default:
             this.CatalogSaveData();
             break;
         }
 
       }
 
 
     }
     )

  }
  toggleShow() {

    this.disableCatalogAdd = true;
    this.isShown = true
//this.tableResourceArray=[];
//this.Project_list=[];
  }

  setCatalogEditing(catalogObj) {
    var catalogNm: string;
    var catalogDes: string;
    var catalogId: string;

    catalogNm = catalogObj.Name;
    this.model.catalogName = catalogNm.trim();

    catalogDes = catalogObj.Description;
    this.model.catalogDescription = catalogDes.trim();

    catalogId = catalogObj.ID;
    this.Catlog_Id = catalogId.trim();

  }

  selectedTool(e) {

  }

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }



  CancelResouceType() {
    this.disableCatalogAdd = false;
    this.isShown = true;
    this.showDivAddDrop = true;
    this.errorMsgAddDrop="Please select valid values"
    setTimeout(() => {
      this.showDivAddDrop = false;
    }, 3000);
return;
  }
  cancelOK(){
    this.clearCatalogDetails();
    // debugger
    // console.log("hjhkj");
    // console.log(this.nextUrl);
    if(this.nextUrl==undefined){
      this.refreshCatalog()
    }
    else{
      this.router.navigate([this.nextUrl])

    }
    
   
    

  }
  CatalogCancel() {
    this.disableCatalogAdd = false;
    this.isShown = false;
    this.isDisplayToolDD = false;
    this.isDisplayToolGroupDD = false;
    this.isDisplayToolType = false;
    this.editMode = false;
    this.tableResourceArray = [];
    this.Project_list=[];
   // this.dataSource.data = this.tableResourceArray as ResourceTable[];
    this.showForm = false;
    this.hideTable = true;
    this.clearCatalogDetails();
    this.refreshCatalog()

  }
  backNavigation(){
    // console.log("hi");
    // debugger
    if ((  this.model.catalogName == undefined ||   this.model.catalogName == "" ) && (this.model.catalogDescription == undefined
       || this.model.catalogDescription == "")
      ){
        
        this.refreshCatalog()
    
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
    //  this.router.navigate(["/admin-catalog-config"])
    //  this.closeToolGroup()
    }
  }
  clearCatalogDetails() {
    this.model.catalogName = "";
    this.model.catalogDescription = "";
  }

  updateCatalogData() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;
    let descriptionsp= /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    //console.log("Save")  
    if (this.model.catalogName == undefined || this.model.catalogDescription == undefined
      || this.model.catalogName == " " || this.model.catalogName == ""
      || this.model.catalogDescription == " " || this.model.catalogDescription == "") {


      this.saveButtonDisable = true;
      this.alertService.error("  Please enter details In all the fields ", this.options);


      setTimeout(() => {
        this.saveButtonDisable = false;
      }, 2500)


    }
    else {
      if (this.model.catalogName.length < 3) {
        this.alertService.error("Please enter minimum 3 characters for saving Subscription Name ", this.options);
        return;
      }
      if (sp.test(this.model.catalogName)) {
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      if (this.model.catalogName.length > 20) {
        this.alertService.error("Please enter maximum 20 characters for saving Subscription Name", this.options);
        return;
      }
      if (this.model.catalogDescription.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving Description ", this.options);
        return;
      }
      if (this.model.catalogDescription.length > 50) {
        this.alertService.error("  Please enter minimum 50 characters for saving Description ", this.options);
        return;
      }

      if (descriptionsp.test(this.model.catalogDescription)) {
        this.alertService.error("Description must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      this.ToolArray = [];
      this.ToolGroupArray = [];
      this.ProjectArray = [];
      var saveBody: any;
      this.catalog_description = this.model.catalogDescription;
      this.catalog_name = this.model.catalogName;


      // ##### Code for Added__ID_Tools_&_ToolsGroup_Array ########
      //console.log("Dispaly_Table_With_Value")  
      //console.log(this.tableResourceArray);

      for (let i = 0; i < this.tableResourceArray.length; i++) {
        //console.log(this.tableResourceArray[i].resourceType,"resourceType");
        var temp = this.tableResourceArray[i].resourceType;
        // console.log(temp,"temp"); 
        if (this.tableResourceArray[i].resourceType == "ToolGroup") {

          var ToolGroupID = this.tableResourceArray[i].tool_id;
          //console.log(ToolGroupID,"ToolGroupID"); 
          this.ToolGroupArray.push(ToolGroupID);
          //console.log(this.ToolGroupArray,"this.ToolGroupArray_if")  
          ToolGroupID = "";
        }
        else if (this.tableResourceArray[i].resourceType == "Project-Template") {
          var ProjectID = this.tableResourceArray[i].tool_id;
          //console.log(ToolGroupID,"ToolGroupID"); 
          this.ProjectArray.push(ProjectID);
          //console.log(this.ToolGroupArray,"this.ToolGroupArray_if")  
          ProjectID = "";
        }
        else {
          var ToolID = this.tableResourceArray[i].tool_id;
          this.ToolArray.push(ToolID);
          // console.log(ToolID,"ToolID");
          ToolID = "";
        }
      }
      //console.log(this.ToolGroupArray,"ToolGroupArray") 
      //console.log(this.ToolArray,"ToolArray") 
      // debugger;
      if (this.tableResourceArray.length == 0) {
        this.alertService.error("  Please add one tool", this.options);
        return;
      }

      if (this.ToolGroupArray.length == 0 || this.ToolGroupArray.length > 1) {
        this.alertService.error("  Please add one tool group", this.options);
        return;
      }

      if (this.ProjectArray.length == 0) {
        this.alertService.error("  Please add one project template", this.options);
        return;
      }
      this.saveEditArray = [];
      // ##### Code for Save Funtionality ########
      saveBody = {

        "catalog_id": this.SubscriptionId,
        "catalog_name": this.catalog_name,
        "catalog_description": this.catalog_description,
        "tool_groups": this.ToolGroupArray,
        "tools": this.ToolArray,
        "project_templates": this.ProjectArray,
        "updated_by":localStorage.getItem('mail').toLowerCase()


      }



      this.saveEditArray.push(saveBody);
      //
      //console.log("saveBody")
      // console.log(this.saveEditArray)
      this.apiService.Admin_CatalogSaveData(this.saveEditArray, "Edit").subscribe((res: HttpResponse<any>) => {
        //console.log(res.status);

        if (res.status == 200) //doesnt work
        {
          // console.log(res);


          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-catalog-config']);
          });
          this.alertService.success(res.body.message, this.options);
        }
      }, (error: HttpErrorResponse) => {
        //
        if (error.status == 437 || error.status == 430) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error(" Invalid Input ", this.options);

          throw error;
        }
      })

    }//else

  }



  CatalogSaveData() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    //debugger;
    if (this.model.catalogName == undefined || this.model.catalogDescription == undefined
      || this.model.catalogName == " " || this.model.catalogName == ""
      || this.model.catalogDescription == " " || this.model.catalogDescription == "") {


      this.saveButtonDisable = true;
      this.alertService.error("  Please enter details In all the fields ", this.options);


      setTimeout(() => {
        this.saveButtonDisable = false;
      }, 2700)


    }
    else {
      // debugger;
      if (this.model.catalogName.length < 3) {
        this.alertService.error("Please enter minimum 3 characters for saving Subscription Name ", this.options);
        return;
      }
      if (sp.test(this.model.catalogName)) {
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      if (this.model.catalogName.length > 20) {
        this.alertService.error("Please enter maximum 20 characters for saving Subscription Name", this.options);
        return;
      }
      if (this.model.catalogDescription.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving Description ", this.options);
        return;
      }
      if (this.model.catalogDescription.length > 50) {
        this.alertService.error("  Please enter minimum 50 characters for saving Description ", this.options);
        return;
      }
      
      if (sp.test(this.model.catalogDescription)) {
        this.alertService.error("Description must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      var saveBody: any;
      this.catalog_description = this.model.catalogDescription;
      this.catalog_name = this.model.catalogName;


      // ##### Code for Added__ID_Tools_&_ToolsGroup_Array ########
      //console.log("Dispaly_Table_With_Value")  
      //console.log(this.tableResourceArray);
      this.ProjectArray = [];
      this.ToolArray = [];
      this.ToolGroupArray = [];
      for (let i = 0; i < this.tableResourceArray.length; i++) {
        //console.log(this.tableResourceArray[i].resourceType,"resourceType");
        var temp = this.tableResourceArray[i].resourceType;
        //console.log(temp,"temp"); 
        if (this.tableResourceArray[i].resourceType == "ToolGroup") {

          var ToolGroupID = this.tableResourceArray[i].tool_id;
          //console.log(ToolGroupID,"ToolGroupID"); 
          this.ToolGroupArray.push(ToolGroupID);
          //console.log(this.ToolGroupArray,"this.ToolGroupArray_if")  
          ToolGroupID = "";
        }
        else if (this.tableResourceArray[i].resourceType == "Project-Template") {
          var ProjectID = this.tableResourceArray[i].tool_id;
          //console.log(ToolGroupID,"ToolGroupID"); 
          this.ProjectArray.push(ProjectID);
          //console.log(this.ToolGroupArray,"this.ToolGroupArray_if")  
          ProjectID = "";
        }
        else {
          var ToolID = this.tableResourceArray[i].tool_id;
          this.ToolArray.push(ToolID);
          //console.log(ToolID,"ToolID");
          ToolID = "";
        }
      }
      // console.log(this.ToolGroupArray,"ToolGroupArray") 
      // console.log(this.ToolArray,"ToolArray") 
     // debugger;
      if (this.tableResourceArray.length == 0) {
        this.alertService.error("  Please add one tool", this.options);
        return;
      }

      if (this.ToolGroupArray.length == 0 || this.ToolGroupArray.length > 1) {
        this.alertService.error("  Please add one tool group", this.options);
        return;
      }

      if (this.ProjectArray.length == 0) {
        this.alertService.error("  Please add one project template", this.options);
        return;
      }

      // ##### Code End ########

      this.saveEditArray = [];
      // ##### Code for Save Funtionality ########
      saveBody = {


        "catalog_name": this.catalog_name,
        "catalog_description": this.catalog_description,
        "tool_groups": this.ToolGroupArray,
        "tools": this.ToolArray,
        "project_templates": this.ProjectArray,
        "created_by":localStorage.getItem('mail').toLowerCase()

      }



      this.saveEditArray.push(saveBody);
      //
      //console.log("saveBody")
      //console.log(this.saveEditArray)

      this.apiService.Admin_CatalogSaveData(this.saveEditArray, "Add").subscribe((res: HttpResponse<any>) => {
        // console.log(res.status);
        if (res.status == 200) //doesnt work
        {
          // console.log(res);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-catalog-config']);
          });
          this.alertService.success(res.body.message, this.options);
        }
      }, (error: HttpErrorResponse) => {
        //
        if (error.status == 437 || error.status == 430) //doesnt work
        {


          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error(" Invalid Input ", this.options);

          throw error;
        }
      })

    }//else

  }

  openDialog(ScreenName) {

    const dialogRef = this.dialog.open(CatalogToolTableModel, {
      width: '70%',
      height: '72%',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedUserArray = result

      this.apiService.Admin_CatalogToolSCM_Dialog.next(this.selectedUserArray);
      this.TableScreen = localStorage.getItem('TablePopup')
     // debugger;
      if (this.TableScreen == "VDI-Images") {

        var NewArryList = [];
        var Dupcated_NewArryList = [];
        if (this.tableResourceArray.length > 0) {


          for (var s = 0; s < this.selectedUserArray.length; s++) {
            this.arrayWithDuplicates.push(this.selectedUserArray[s]);

          }

          for (var s = 0; s < this.tableResourceArray.length; s++) {
            this.arrayWithDuplicates.push(this.tableResourceArray[s]);

          }

          var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
          //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


          for (var s = 0; s < this.tableResourceArray.length; s++) {
            for (var j = 0; j < uniqueArray.length; j++) {

              if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                uniqueArray.splice(j, 1);

              }

            }
          }
          //this.tableResourceArray=[];
          //console.log("VDI_After_Close");
          //console.log(uniqueArray);
          if (uniqueArray.length > 0) {
            uniqueArray.forEach(member => {
              let tableObj = {
                "resource": "VDI",
                "resourceType": "Tool",
                "tool_instance_name": member.image_name,
                // "Description":"-",  
                "Description": member.description,
                "tool_group_id": member.tool_group_id,
                "db_id": member.db_id,
                "tool_id": member.toolID
                // "action": "Remove",                 
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }
        } else {
          //console.log("VDI_After_Close");
          //console.log(this.selectedUserArray);
          this.selectedUserArray.forEach(member => {
            let tableObj = {
              "resource": "VDI",
              "resourceType": "Tool",
              "tool_instance_name": member.image_name,
              // "Description":"-",  
              "Description": member.description,
              "tool_group_id": member.tool_group_id,
              "db_id": member.db_id,
              "tool_id": member.toolID
              // "action": "Remove",                     
            }

            this.tableResourceArray.push(tableObj)

          })
          this.dataSource.data = this.tableResourceArray as ResourceTable[]
          this.showTable = true;
          this.selectedUserArray = [];
          this.isDisplayToolType = false;
          this.disableCatalogAdd = false;
          this.dtTrigger.next();
        }
      }
      else {

        if (this.TableScreen == "VM-Images") {

          var NewArryList = [];
          var Dupcated_NewArryList = [];
          if (this.tableResourceArray.length > 0) {


            for (var s = 0; s < this.selectedUserArray.length; s++) {
              this.arrayWithDuplicates.push(this.selectedUserArray[s]);

            }

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              this.arrayWithDuplicates.push(this.tableResourceArray[s]);

            }

            var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
            // console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


            for (var s = 0; s < this.tableResourceArray.length; s++) {
              for (var j = 0; j < uniqueArray.length; j++) {

                if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                  uniqueArray.splice(j, 1);

                }

              }
            }
            //this.tableResourceArray=[];
            //console.log("VM_After_Close");
            //console.log(uniqueArray);
            if (uniqueArray.length > 0) {
              uniqueArray.forEach(member => {
                let tableObj = {
                  "resource": "Deployment-VM",
                  "resourceType": "Tool",
                  "Description": member.description,
                  "tool_instance_name": member.image_name,
                  "tool_group_id": member.tool_group_id,
                  "db_id": member.db_id,
                  "tool_id": member.toolID
                  // "action": "Remove",                 
                }

                this.tableResourceArray.push(tableObj)

              })
              this.dataSource.data = this.tableResourceArray as ResourceTable[]
              this.showTable = true;
              this.selectedUserArray = [];
              this.isDisplayToolType = false;
              this.disableCatalogAdd = false;
              this.dtTrigger.next();
            }
          } else {
            //console.log("VM_After_Close");
            //console.log(this.selectedUserArray);
            this.selectedUserArray.forEach(member => {
              let tableObj = {
                "resource": "Deployment-VM",
                "resourceType": "Tool",
                "tool_instance_name": member.image_name,
                "Description": member.description,
                "tool_group_id": member.tool_group_id,
                "db_id": member.db_id,
                "tool_id": member.toolID
                // "action": "Remove",                     
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }
        }

        if (this.TableScreen == "AWS-Work-Space") {

          var NewArryList = [];
          var Dupcated_NewArryList = [];
          if (this.tableResourceArray.length > 0) {


            for (var s = 0; s < this.selectedUserArray.length; s++) {
              this.arrayWithDuplicates.push(this.selectedUserArray[s]);

            }

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              this.arrayWithDuplicates.push(this.tableResourceArray[s]);

            }

            var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
            // console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


            for (var s = 0; s < this.tableResourceArray.length; s++) {
              for (var j = 0; j < uniqueArray.length; j++) {

                if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                  uniqueArray.splice(j, 1);

                }

              }
            }

            //console.log("VM_After_Close");
            //console.log(uniqueArray);
            if (uniqueArray.length > 0) {
              uniqueArray.forEach(member => {
                let tableObj = {
                  "resource": "AWS-Workspace",
                  "resourceType": "Tool",
                  "Description": member.description,
                  "tool_instance_name": member.name,
                  "tool_group_id": member.tool_group_id,
                  "db_id": member.db_id,
                  "tool_id": member.toolID
                  // "action": "Remove",                 
                }

                this.tableResourceArray.push(tableObj)

              })
              this.dataSource.data = this.tableResourceArray as ResourceTable[]
              this.showTable = true;
              this.selectedUserArray = [];
              this.isDisplayToolType = false;
              this.disableCatalogAdd = false;
              this.dtTrigger.next();
            }
          } else {
            //console.log("VM_After_Close");
            //console.log(this.selectedUserArray);
            this.selectedUserArray.forEach(member => {
              let tableObj = {
                "resource": "AWS-Workspace",
                "resourceType": "Tool",
                "tool_instance_name": member.name,
                "Description": member.description,
                "tool_group_id": member.tool_group_id,
                "db_id": member.db_id,
                "tool_id": member.toolID
                // "action": "Remove",                     
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }
        }
        //debugger;
        if (this.TableScreen == "Network-AKS" || this.TableScreen == "Network-VDI" || this.TableScreen == "Network-VM") {

          var NewArryList = [];
          var Dupcated_NewArryList = [];
          if (this.tableResourceArray.length > 0) {


            for (var s = 0; s < this.selectedUserArray.length; s++) {
              this.arrayWithDuplicates.push(this.selectedUserArray[s]);

            }

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              this.arrayWithDuplicates.push(this.tableResourceArray[s]);

            }

            var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
            //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


            for (var s = 0; s < this.tableResourceArray.length; s++) {
              for (var j = 0; j < uniqueArray.length; j++) {

                if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                  uniqueArray.splice(j, 1);

                }

              }
            }
            //this.tableResourceArray=[];
            //console.log("Network_After_Close");
            //console.log(uniqueArray);
            if (uniqueArray.length > 0) {
              uniqueArray.forEach(member => {
                let tableObj = {
                  // "resource": this.TableScreen,
                  "resource": member.toolName,
                  "resourceType": "Tool",
                  "tool_instance_name": member.name,
                  "Description": member.description,
                  "tool_group_id": member.tool_group_id,
                  "db_id": member.db_id,
                  "tool_id": member.toolID
                  // "action": "Remove",                 
                }

                this.tableResourceArray.push(tableObj)

              })
              this.dataSource.data = this.tableResourceArray as ResourceTable[]
              this.showTable = true;
              this.selectedUserArray = [];
              this.isDisplayToolType = false;
              this.disableCatalogAdd = false;
              this.dtTrigger.next();
            }
          } else {
            //console.log("Network_After_Close");
            // console.log(this.selectedUserArray);
            this.selectedUserArray.forEach(member => {
              let tableObj = {
                // "resource": this.TableScreen,
                "resource": member.toolName,
                "resourceType": "Tool",
                "Description": member.description,
                "tool_instance_name": member.name,
                "tool_group_id": member.tool_group_id,
                "db_id": member.db_id,
                "tool_id": member.toolID
                // "action": "Remove",                 
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }
        }

        if (this.TableScreen == "Project Template") {
         // debugger;
          var NewArryList = [];
          var Dupcated_NewArryList = [];
          if (this.tableResourceArray.length > 0) {


            for (var s = 0; s < this.selectedUserArray.length; s++) {
              this.arrayWithDuplicates.push(this.selectedUserArray[s]);

            }

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              this.arrayWithDuplicates.push(this.tableResourceArray[s]);

            }

            var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
            //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));


            for (var s = 0; s < this.tableResourceArray.length; s++) {
              for (var j = 0; j < uniqueArray.length; j++) {

                if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                  uniqueArray.splice(j, 1);

                }

              }
            }
            //this.tableResourceArray=[];
            if (uniqueArray.length > 0) {
              uniqueArray.forEach(member => {
                let tableObj = {
                  // "resource": this.TableScreen,
                  "resource": "Template",
                  "resourceType": "Project-Template",
                  "tool_instance_name": member.template_name,
                  "Description": member.template_description,
                  //"tool_group_id" :member.tool_group_id,
                  "db_id": member.db_id,
                  "tool_id": member.template_id
                  // "action": "Remove",                 
                }

                this.tableResourceArray.push(tableObj)

              })
              this.dataSource.data = this.tableResourceArray as ResourceTable[]
              this.showTable = true;
              this.selectedUserArray = [];
              this.isDisplayToolType = false;
              this.disableCatalogAdd = false;
              this.dtTrigger.next();
            }
          } else {
            this.selectedUserArray.forEach(member => {
              let tableObj = {
                // "resource": this.TableScreen,
                "resource": "Template",
                "resourceType": "Project-Template",
                "tool_instance_name": member.template_name,
                "Description": member.template_description,
                //"tool_group_id" :member.tool_group_id,
                "db_id": member.db_id,
                "tool_id": member.template_id
                // "action": "Remove",                 
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }

        }

        else {
          //############ SCM/CICD/Storyboard code for displying table below the dropdown #######

          var NewArryList = [];
          var Dupcated_NewArryList = [];
          if (this.tableResourceArray.length > 0) {


            for (var s = 0; s < this.selectedUserArray.length; s++) {
              this.arrayWithDuplicates.push(this.selectedUserArray[s]);

            }

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              this.arrayWithDuplicates.push(this.tableResourceArray[s]);

            }

            var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "db_id");
            //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));

            for (var s = 0; s < this.tableResourceArray.length; s++) {
              for (var j = 0; j < uniqueArray.length; j++) {

                if (uniqueArray[j].db_id == this.tableResourceArray[s].db_id) {
                  uniqueArray.splice(j, 1);

                }

              }
            }


            //this.tableResourceArray=[];

            if (uniqueArray.length > 0) {
              uniqueArray.forEach(member => {
                let tableObj = {
                  "resource": this.TableScreen,
                  "resourceType": "Tool",

                  "Description": member.description,
                  "tool_instance_name": member.name,
                  "db_id": member.db_id,
                  "tool_id": member.ID
                  //"action": "Remove",                     
                }

                this.tableResourceArray.push(tableObj)

              })
              this.dataSource.data = this.tableResourceArray as ResourceTable[]
              this.showTable = true;
              this.selectedUserArray = [];
              this.isDisplayToolType = false;
              this.disableCatalogAdd = false;
              this.dtTrigger.next();
            }
          }
          else {
            this.selectedUserArray.forEach(member => {
              let tableObj = {
                "resource": this.TableScreen,
                "resourceType": "Tool",
                "Description": member.description,
                "tool_instance_name": member.name,
                "db_id": member.db_id,
                "tool_id": member.ID,
                // "action": "Remove",                     
              }

              this.tableResourceArray.push(tableObj)

            })
            this.dataSource.data = this.tableResourceArray as ResourceTable[]
            this.showTable = true;
            this.selectedUserArray = [];
            this.isDisplayToolType = false;
            this.disableCatalogAdd = false;
            this.dtTrigger.next();
          }
        }
      }
    }
    )

  }
  removeDuplicates(originalArray, prop) {

    var newArray = [];
    var lookupObject = {};
    var DuplArray = [];

    for (var i in originalArray) {

      lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  removeDuplicates_ToolGroup(originalArray, prop) {

    var newArray = [];
    var lookupObject = {};
    var DuplArray = [];

    for (var i in originalArray) {
      if (lookupObject[originalArray[i][prop]] != undefined) {
        lookupObject[originalArray[i][prop]] = originalArray[i]
      }
      else {
        lookupObject[originalArray[i]["_id"]] = originalArray[i]
      }

    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
}


export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
}

//############SCMToolpopupModel-Start#########################################

@Component({
  selector: 'Catalog-tool-SCM-popup',
  templateUrl: 'Catalog-tool-SCM-popup.html',
  styleUrls: ['./admin-catalog-config.component.scss']
})

export class CatalogToolScmModel {
  isDisplaySCM: boolean = false;
  isDisplayStoryBroad: boolean = false;
  isDisplayCICD: boolean = false;
  selectedUserArray: any = []
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, public alertService: AlertService,
    private apiService: AuthService, public dialog: MatDialog) { }

  getSCMDetails(toolName) {
    localStorage.setItem("TablePopup", toolName);
    // alert("Test "+toolName);
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(CatalogToolTableModel, {
      width: '70%',
      height: '50%',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedUserArray = result
      this.apiService.Admin_CatalogToolSCM_Dialog.next(this.selectedUserArray);
    })

  }

}
//############SCMToolpopupModel-End#########################################

//############SCMToolTableModel-Start#########################################
export interface configList {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  tool_group_id: string;
  projectToken: string;
  tool_group_name: string;
  azure_tool_instance_id: string;
  vdi_type: string;
}

export interface CatalogTableDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
  tool_group_name: string;
  azure_tool_instance_id: string;
  vdi_type: string;
  tool_group_id: string;
}

export interface VDITableDetails {
  toolName: string;
  image_name: string;
  azure_tool_instance_id: string;
  vdi_type: string;
  tool_group_name: string;
  tool_group_id: string;
  toolID: string;
}
export interface VMTableDetails {
  name: string;
  toolID: string;
  location: string;
  type: string;
  action: string;
  tags: string;
  id: string;
  vdiType: string;
  vmos: string;
  vmse: string;
  vmty: string;
}

export interface NetworkTableDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  Vnet: string;
  toolGroup: string;
  toolGroup_id: string;
  Subnet: string;
  ResourceGroup: string;
  toolID: string;
}

export interface AWSTableDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  Directories: string;
  toolGroup: string;
  toolGroup_id: string;
  Bundles: string;

  toolID: string;
}

export interface ProjectTableDetails {

  projectID: string;
  db_id: string;
  name: string;
  description: string;

}

export interface cicdDetails {
  toolName: string;
  ID: string;
  db_id: string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

const ELEMENT_DATA: CatalogTableDetails[] = [];
const ELEMENT_DATA1: VDITableDetails[] = [];
const ELEMENT_DATA2: VMTableDetails[] = [];
const ELEMENT_DATA3: NetworkTableDetails[] = [];
const ELEMENT_DATA4: AWSTableDetails[] = [];
const ELEMENT_DATA5: ProjectTableDetails[] = [];
@Component({
  selector: 'Catalog-Tool-Table-Model',
  templateUrl: 'Catalog-Tool-Table-Model.html',
  styleUrls: ['./admin-catalog-config.component.scss']
})


export class CatalogToolTableModel {

  members: any = [];
  tableResourceArray: any = [];
  TableMode: string;
  isDisplayTool: boolean = false;
  isDisplayToolVDI: boolean = false;
  isDisplayToolVM: boolean = false;
  isDisplayToolNetwork: boolean = false;
  isDisplayToolAWS: boolean = false;
  isDisplayProjectT: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router, public alertService: AlertService,
    private apiService: AuthService, public dialog: MatDialogRef<CatalogToolScmModel>) { }
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  SelectedTools: object[];
  ELEMENT_DATA: CatalogTableDetails[];
  ELEMENT_DATA1: VDITableDetails[];
  ELEMENT_DATA2: VMTableDetails[];
  ELEMENT_DATA3: NetworkTableDetails[];
  ELEMENT_DATA4: AWSTableDetails[];
  ELEMENT_DATA5: ProjectTableDetails[];
  displayedColumns: string[] = [];
  displayedColumnsVDI: string[] = [];
  displayedColumnsVM: string[] = [];
  displayedColumnsNetwork: string[] = [];
  displayedColumnsAWS: string[] = [];
  displayedColumnsProjectT: string[] = [];

  dataSource = new MatTableDataSource<CatalogTableDetails>(ELEMENT_DATA);
  dataSource1 = new MatTableDataSource<VDITableDetails>(ELEMENT_DATA1);
  dataSource2 = new MatTableDataSource<VMTableDetails>(ELEMENT_DATA2);
  dataSource3 = new MatTableDataSource<NetworkTableDetails>(ELEMENT_DATA3);
  dataSource4 = new MatTableDataSource<AWSTableDetails>(ELEMENT_DATA4);
  dataSource5 = new MatTableDataSource<ProjectTableDetails>(ELEMENT_DATA5);
  selection = new SelectionModel<CatalogTableDetails>(true, []);
  selectionVDI = new SelectionModel<VDITableDetails>(true, []);
  selectionVM = new SelectionModel<VMTableDetails>(true, []);
  selectionNetwork = new SelectionModel<NetworkTableDetails>(true, []);
  selectionAWS = new SelectionModel<AWSTableDetails>(true, []);
  selectionProjectT = new SelectionModel<ProjectTableDetails>(true, []);

  ngOnInit(): void {
    // debugger;
    this.TableMode = localStorage.getItem('TablePopup')
    //console.log(this.TableMode )=
    if (this.TableMode == "GitLab") {
      this.GetScmtableDetails();
      this.displayedColumns = ['ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
      this.isDisplayTool = true;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "Jenkins") {
      this.GetCICDTableDetails();
      this.displayedColumns = ['ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
      this.isDisplayTool = true;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "Jira") {
      //this.GetScmtableDetails();
      this.GetStoryBoardtableDetails();
      this.isDisplayTool = true;
      this.isDisplayToolVDI = false;
      this.displayedColumns = ['ID', 'name', 'toolName', 'projectURL', 'toolGroup', 'select'];
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "VDI-Images") {

      this.GetVDItableDetails();
      this.displayedColumnsVDI = ['toolID', 'azureTool', 'vdiType', 'imageType', 'toolGroup', 'select'];
      this.isDisplayTool = false;
      this.isDisplayToolVDI = true;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "VM-Images") {

      this.GetDeploymentStore_VMtableDetails();

      this.displayedColumnsVM = ['toolID', 'azureTool', 'imageType', 'toolGroup', 'select'];
      this.isDisplayTool = false;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = true;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "Network-AKS" || this.TableMode == "Network-VDI" || this.TableMode == "Network-VM") {

      this.GetNetworktableDetails();

      this.displayedColumnsNetwork = ['toolID', 'name', 'Vnet', 'Subnet', 'ResourceGroup', 'select'];
      this.isDisplayTool = false;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = true;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "AWS-Work-Space") {

      this.GetAwstableDetails();

      this.displayedColumnsAWS = ['toolID', 'name', 'Bundles', 'Directories', 'select'];
      this.isDisplayTool = false;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = true;
      this.isDisplayProjectT = false;
    }
    else if (this.TableMode == "Project Template") {
     // debugger;
      this.GetDomaintableDetails();

      this.displayedColumnsProjectT = ['projectID', 'name', 'description', 'select'];
      this.isDisplayTool = false;
      this.isDisplayToolVDI = false;
      this.isDisplayToolVM = false;
      this.isDisplayToolNetwork = false;
      this.isDisplayToolAWS = false;
      this.isDisplayProjectT = true;
    }
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.dataSource1.filter = value.trim().toLocaleLowerCase();
    this.dataSource2.filter = value.trim().toLocaleLowerCase();
    this.dataSource3.filter = value.trim().toLocaleLowerCase();
    this.dataSource4.filter = value.trim().toLocaleLowerCase();
    this.dataSource5.filter = value.trim().toLocaleLowerCase();
  }

  GetDomaintableDetails() {
    this.spinner.show();


    this.apiService.Admin_Domain_ProjectTableDetails().subscribe((res: any) => {

      res.forEach(member => {
        let obj = {
          // "functional_area": member.functional_area,
          "template_description": member.template_description,
          "template_id": member.template_id,
          "template_name": member.template_name,
          "db_id": member._id,

        }
        this.tableResourceArray.push(obj)

      })
      // console.log(this.StoryBoardArray)

      this.dataSource5.data = this.tableResourceArray as ProjectTableDetails[]
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
    // SCMArray
  }
  GetNetworktableDetails() {

    this.apiService.Admin_NetworkToolGrTableDetails(this.TableMode).subscribe((res: any) => {
      // 
      //console.log(res,"OUTPut")
      //debugger;
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "toolID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.network_config_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.network_config_tool_instance.description,
          "Vnet": member.tool_instance_details.network_config_tool_instance.vnet,
          "Subnet": member.tool_instance_details.network_config_tool_instance.subnet,
          "ResourceGroup": member.tool_instance_details.network_config_tool_instance.resource_group,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.tableResourceArray.push(obj)

      })
      //console.log(this.tableResourceArray,"Network")
      this.dataSource3.data = this.tableResourceArray as NetworkTableDetails[]
    }
    ), (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
  }

  GetAwstableDetails() {
    // this.spinner.show();
    this.apiService.Admin_AwsTableDetails().subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "toolID": member.tool_id,
          // "ID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.aws_workspace_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.aws_workspace_tool_instance.description,

          "Bundles": member.tool_instance_details.aws_workspace_tool_instance.bundle_id,

          "Directories": member.tool_instance_details.aws_workspace_tool_instance.directory_id,
          //  member.tool_instance_details.aws_workspace_tool_instance.directory_id,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.tableResourceArray.push(obj)

      })

      this.dataSource4.data = this.tableResourceArray as AWSTableDetails[]
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
    // SCMArray
  }
  GetDeploymentStore_VMtableDetails() {

    this.apiService.Admin_Deployment_VM_TableDetails().subscribe((res: any) => {
      //console.log("Deployment Store VM get table information");
      //console.log(res)
      //debugger;
      res.forEach(member => {
        let obj = {
          "db_id": member._id,
          "toolID": member.tool_id,
          "tool_group_id": member.tool_group_details.tool_group_id,
          "tool_group_name": member.tool_group_details.tool_group_name,
          "image_name": member.tool_instance_details.deploymentstore_tool_instance.name,
          //"vdi_type":member.tool_instance_details.vdi_tool_instance.vdi_type, 
          "azure_tool_instance_id": member.tool_instance_details.deploymentstore_tool_instance.azure_tool_instance_id,
          "description": member.tool_instance_details.deploymentstore_tool_instance.tool_description
        }

        this.tableResourceArray.push(obj)
      })


      //console.log(this.tableResourceArray,"VDI")
      this.dataSource2.data = this.tableResourceArray as VMTableDetails[]
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
    // SCMArray
  }

  GetVDItableDetails() {
    this.apiService.Admin_VDITableDetails().subscribe((res: any) => {
      //console.log("VDI get table information");
      //console.log(res)
      //debugger;
      res.forEach(member => {
        let obj = {
          "db_id": member._id,
          "toolID": member.tool_id,
          "tool_group_name": member.tool_group_details.tool_group_name,
          "tool_group_id": member.tool_group_details.tool_group_id,
          "image_name": member.tool_instance_details.vdi_tool_instance.name,
          "vdi_type": member.tool_instance_details.vdi_tool_instance.vdi_type,
          "azure_tool_instance_id": member.tool_instance_details.vdi_tool_instance.azure_tool_instance_id,
          "description": member.tool_instance_details.vdi_tool_instance.tool_description
        }

        this.tableResourceArray.push(obj)
      })
      //console.log(this.tableResourceArray,"VDI")
      this.dataSource1.data = this.tableResourceArray as VDITableDetails[]

    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
    // SCMArray
  }


  GetStoryBoardtableDetails() {
    this.apiService.Admin_StoryBoard_JiraTableDetails().subscribe((res: any) => {
      //
      // console.log(res)
      res.forEach(member => {
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "projectToken": member.tool_instance_details.storyboard_tool_instance.tool_auth.auth_token,
          "email": member.tool_instance_details.storyboard_tool_instance.tool_auth.auth_mail,
          "name": member.tool_instance_details.storyboard_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.storyboard_tool_instance.tool_description,
          "projectURL": member.tool_instance_details.storyboard_tool_instance.tool_url,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
        }
        this.tableResourceArray.push(obj)

      })
      //console.log(this.tableResourceArray)
      this.dataSource.data = this.tableResourceArray as CatalogTableDetails[]
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else {

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
    // SCMArray
  }

  GetCICDTableDetails() {
    // 
    this.apiService.Admin_CICDTableDetails().subscribe((res: any) => {
      // 
      // console.log(res)

      res.forEach(member => {
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "projectToken": member.tool_instance_details.cicd_tool_instance.tool_auth.auth_token,
          "projectURL": member.tool_instance_details.cicd_tool_instance.tool_url,
          "email": member.tool_instance_details.cicd_tool_instance.tool_auth.auth_mail,
          "name": member.tool_instance_details.cicd_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.cicd_tool_instance.tool_description,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
          //  [11].tool_groups[0].tools[0]

        }

        this.tableResourceArray.push(obj)
      })
      // console.log(this.tableResourceArray)
      this.dataSource.data = this.tableResourceArray as CatalogTableDetails[]
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      }
    )
    // SCMArray
  }

  GetScmtableDetails() {
    this.spinner.show();
    this.apiService.Admin_ScmToolGrTableDetails().subscribe((res: any) => {
      // 
      //console.log(res,"SMCDetails")
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.scm_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.scm_tool_instance.tool_description,
          "projectToken": member.tool_instance_details.scm_tool_instance.tool_auth.auth_token,
          "projectURL": member.tool_instance_details.scm_tool_instance.tool_url,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id

        }
        this.tableResourceArray.push(obj)

      })
      // console.log(this.tableResourceArray)
      this.dataSource.data = this.tableResourceArray as CatalogTableDetails[]
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
    // SCMArray
  }

  AddToolsDetials() {
    // 

    if (this.TableMode == "VDI-Images") {
      if (this.selectionVDI.selected.length == 0) {
        //console.log(this.selectionVDI.selected);
        // this.apiService.Admin_CatalogToolSCM_Dialog.next("select user")
      }

      else {
        // console.log("VDI_Value");
        //console.log(this.selectionVDI.selected.length);
        //console.log(this.selectionVDI.selected);
        this.SelectedTools = this.selectionVDI.selected;
        this.dialog.close(this.selectionVDI.selected);

      }


    }

    if (this.TableMode == "VM-Images") {
      if (this.selectionVM.selected.length == 0) {
        // console.log(this.selectionVM.selected);
        // this.apiService.Admin_CatalogToolSCM_Dialog.next("select user")
      }

      else {
        // console.log("VM_Value");
        //console.log(this.selectionVM.selected.length);
        //console.log(this.selectionVM.selected);
        this.SelectedTools = this.selectionVM.selected;
        this.dialog.close(this.selectionVM.selected);

      }


    }

    if (this.TableMode == "Network-AKS" || this.TableMode == "Network-VDI" || this.TableMode == "Network-VM") {
      if (this.selectionNetwork.selected.length == 0) {
        //console.log(this.selectionNetwork.selected);
        //console.log(this.selectionNetwork.selected);
        // this.apiService.Admin_CatalogToolSCM_Dialog.next("select user")
      }

      else {
        // console.log("Network_Value");
        //console.log(this.selectionNetwork.selected.length);
        // console.log(this.selectionNetwork.selected);
        this.SelectedTools = this.selectionNetwork.selected;
        this.dialog.close(this.selectionNetwork.selected);

      }


    }

    if (this.TableMode == "AWS-Work-Space") {
      if (this.selectionAWS.selected.length == 0) {
        // console.log(this.selectionVM.selected);

      }

      else {
        // console.log("VM_Value");
        //console.log(this.selectionVM.selected.length);
        //console.log(this.selectionVM.selected);
        this.SelectedTools = this.selectionAWS.selected;
        this.dialog.close(this.selectionAWS.selected);

      }


    }
    if (this.TableMode == "Project Template") {
      if (this.selectionProjectT.selected.length == 0) {
        // console.log(this.selectionVM.selected);

      }

      else {
        // console.log("VM_Value");
        //console.log(this.selectionVM.selected.length);
        //console.log(this.selectionVM.selected);
        this.SelectedTools = this.selectionProjectT.selected;
        this.dialog.close(this.selectionProjectT.selected);

      }


    }
    else {
      if (this.selection.selected.length == 0) {
        //console.log(this.selection.selected);
        // this.apiService.Admin_CatalogToolSCM_Dialog.next("select user")
      } else {

        // console.log(this.selection.selected);
        this.SelectedTools = this.selection.selected;
        this.dialog.close(this.selection.selected);

      }
    }


  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolName + 1}`;
  }


  /** For1-VDI */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedVDI() {
    const numSelected = this.selectionVDI.selected.length;
    const numRows = this.dataSource1.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleVDI() {
    this.isAllSelectedVDI() ?
      this.selectionVDI.clear() :
      this.dataSource1.data.forEach(row => this.selectionVDI.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelVDI(row?: VDITableDetails): string {
    if (!row) {
      return `${this.isAllSelectedVDI() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionVDI.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }

  /** For1-VM */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedVM() {
    const numSelected = this.selectionVM.selected.length;
    const numRows = this.dataSource2.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleVM() {
    this.isAllSelectedVM() ?
      this.selectionVM.clear() :
      this.dataSource2.data.forEach(row => this.selectionVM.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelVM(row?: VMTableDetails): string {
    if (!row) {
      return `${this.isAllSelectedVM() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionVM.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }





  /** For1-network */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedNetwork() {
    const numSelected = this.selectionNetwork.selected.length;
    const numRows = this.dataSource3.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleNetwork() {
    this.isAllSelectedNetwork() ?
      this.selectionNetwork.clear() :
      this.dataSource3.data.forEach(row => this.selectionNetwork.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelNetwork(row?: NetworkTableDetails): string {
    if (!row) {
      return `${this.isAllSelectedNetwork() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionNetwork.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }


  /** For1-AWS */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedAWS() {
    const numSelected = this.selectionAWS.selected.length;
    const numRows = this.dataSource4.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleAWS() {
    this.isAllSelectedAWS() ?
      this.selectionAWS.clear() :
      this.dataSource4.data.forEach(row => this.selectionAWS.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelAWS(row?: AWSTableDetails): string {
    if (!row) {
      return `${this.isAllSelectedAWS() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionAWS.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }

  /** For1-ProjectT */
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedProjectT() {
    const numSelected = this.selectionProjectT.selected.length;
    const numRows = this.dataSource5.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleProjectT() {
    this.isAllSelectedProjectT() ?
      this.selectionProjectT.clear() :
      this.dataSource5.data.forEach(row => this.selectionProjectT.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelProjectT(row?: ProjectTableDetails): string {
    if (!row) {
      return `${this.isAllSelectedProjectT() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionProjectT.isSelected(row) ? 'deselect' : 'select'} row ${row.projectID + 1}`;
  }


  exit() {

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource1.sort = this.sort;
    this.dataSource1.paginator = this.paginator;
    this.dataSource2.sort = this.sort;
    this.dataSource2.paginator = this.paginator;
    this.dataSource3.sort = this.sort;
    this.dataSource3.paginator = this.paginator;
    this.dataSource4.sort = this.sort;
    this.dataSource4.paginator = this.paginator;
    this.dataSource5.sort = this.sort;
    this.dataSource5.paginator = this.paginator;
  }

} 
