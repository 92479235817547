<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

        <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-7">        
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
                <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Azure Config</li>
              </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right pt-3">
        <span class="table-options">
          <span (click)="addForm()"><i class="fas fa-plus"></i> Add</span>
          <span (click)="refreshAzure()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

         <!-- <span><i class="fas fa-cog"></i> Manage</span> -->
         <a class="float-right step-back" (click)="backNavigation()">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </span>
      </div>
      <!-- <div class="col-md-1 p-md-0 my-auto">
        <a routerLink="/admin-catalog-config" class="float-right step-back">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div> -->
    </div>

    <h1 class="text-light mb-0">
      <img width="40" src="../../../assets/icon/menu/azure-active-icon.png">
      <div class="vertical-line mx-3"> </div> <span>Azure Config</span>      
    </h1>

  </div>

        <div class="inner-mid-container">
                    <!-- Modal -->
                    <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
                    <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
                        aria-labelledby="confirmationModal" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content pop-up">
                                <div class="modal-header-popup">
                                    <span class="vertical-line mx-3"> </span>
                                    <span class="header mb-0">Azure Config</span>
                                    <span data-dismiss="modal" class="material-icons close-icon">
                                        close
                                    </span>
                                </div>
                                <hr class="margin-class" />
                                <div class="modal-body">
                                    Are you sure want to cancel?
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-canvas-blue-2"
                                        data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal" (click)="cancelok()">Ok</button>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- </div> -->
                        </div>
                    </div>   
            <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content pop-up">
                    <div class="modal-header-popup">
                      <span  class="vertical-line mx-3"> </span>
                       <span class="header mb-0">Azure Config</span> 
                      <span  data-dismiss="modal" class="material-icons close-icon">
                          close
                        </span>
                    </div>
                    <hr class="margin-class" />
                    <div class="modal-body">
                      Are you sure want to Delete?
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                    </div>
                  </div>
                </div>
              </div>
            <div class="mt-1 azure-form-area" *ngIf="showForm">
              <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
                <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label for="toolGr">Tool Group <span class="required">&#42;</span></label>
                          <select  id="toolGr" name="toolGr" class="form-control form-control-lg"
                          (change)="onChange($event)" [disabled]="ToolGroupDisable"
                            required [(ngModel)]="toolsGroup.toolGr" #toolGr="ngModel">
                            
                            <option value="" disabled selected hidden>Select Tool Group</option>
                         
                            <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}</option>
                          </select>
        
                          <div  *ngIf="toolGr.errors && toolGr.touched"
                            class="alert alert-danger">
                            <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
                          </div> 
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <label for=""> &nbsp;</label>
                        <div class="">
                          <a  (click)="openDialog()" class="link-col">
                            <u>Add New Tool Group</u></a>
                      </div>
                      </div>
                      
                     
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label for="nameInstance"> Name <span class="required">&#42;</span></label>
                          <input id="nameInstance" autocomplete="off" minlength="3" pattern="^[a-zA-Z0-9_-]+$" name="nameInstance"  maxlength="20" 
                            class="form-control form-control-lg" required [(ngModel)]="toolsGroup.nameInstance" [disabled]="ToolInstanceNameDisable" #nameInstance="ngModel"
                            (keydown.space)="$event.preventDefault();" onPaste="return false">
        
                          <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
                            class="alert alert-danger">
        
                            <div *ngIf="nameInstance.errors.required">
                              Name is required.
                            </div>
                            <div *ngIf="nameInstance.errors.minlength">
                              Please enter minimum 3 characters for saving Tool Name
                            </div>
                            <div *ngIf="nameInstance.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>

                          </div>
                        </div>
                      </div>
                     
                    </div>
                    <!-- #######++++++++##### Name and Description #######++++++++#####-->
                    <div class="row">
                      <div class="col-12 col-md-4">
                        <div class="form-group">
                          <label for="description">Description <span class="required">&#42;</span></label>
                          <input id="description" maxlength="50" minlength="20"  pattern="^[a-zA-Z0-9_ -]+$" autocomplete="off" name="description" class="form-control form-control-lg"
                            required [(ngModel)]="toolsGroup.description" #description="ngModel">
        
                          <div *ngIf="description.invalid && (description.dirty || description.touched)"
                            class="alert alert-danger">
        
                            <div *ngIf="description.errors.required">
                              Description is required.
                            </div>
                            <div *ngIf="description.errors.minlength">
                              Please enter minimum 20 characters for saving Description 

                          </div>
                          <div *ngIf="description.invalid && !description.errors.minlength &&description.errors.pattern">
                              Description must include only alphabets ,numbers or _,-.</div>
                
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
        
                        <div class="form-group">
                          <label for="Subscription">Subscription ID <span class="required">&#42;</span></label>
                          <input id="Subscription" autocomplete="off" name="Subscription" pattern="^[a-zA-Z0-9-]+$" class="form-control form-control-lg" required
                            [(ngModel)]="toolsGroup.Subscription" #Subscription="ngModel" [disabled]="SubscriptionDisable">
        
                          <div *ngIf="Subscription.invalid && (Subscription.dirty || Subscription.touched)" class="alert alert-danger">
        
                            <div *ngIf="Subscription.errors.required">
                              Subscription ID is required.
                            </div>
                            <div *ngIf="Subscription.errors.pattern">
                                Invalid Subscription ID
                              </div>
        
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
        
                        <div class="form-group">
                          <label for="Tenant">Tenant ID <span class="required">&#42;</span></label>
                          <input  id="Tenant" autocomplete="off" class="form-control form-control-lg" pattern="^[a-zA-Z0-9-]+$" required
                            name="Tenant" [(ngModel)]="toolsGroup.Tenant" #Tenant="ngModel" [disabled]="TenantDisable">
        
        
                          <div *ngIf="Tenant.invalid && (Tenant.dirty || Tenant.touched)" class="alert alert-danger">
        
                            <div *ngIf="Tenant.errors.required">
                              Tenant ID is required.
                            </div>
                            <div *ngIf="Tenant.errors.pattern">
                                Invalid Tenant ID
                              </div>
        
                          </div>
                        </div>
        
                      </div>
                    </div>

                     
                    <div class="row">
                      <!-- <div class="col-md-6"></div> -->
                      <div class="col-12 text-right">
                        <!-- <div class="pull-right mb-2"> -->
                         <button [disabled]="testConnDisable || !toolsGroupForm.form.valid" type="submit" class="btn-canvas-blue mb-md-0 mb-1 mr-md-2 mr-0">
                            Test ID
                          </button>
                          <button *ngIf="!editMode" [disabled]="saveConnDisable || !toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
                            (click)="CheckDuplication()">
                            Save
                          </button>
                          <button *ngIf="editMode"  [disabled]="UpdateConnDisable || !toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
                            (click)="UpdateAzureForm()"> <!-- [disabled]="UpdateConnDisable"-->
                            Update
                          </button>
                          <!-- <button type="button" class="btn-canvas-blue"
                            (click)="hideForm()" >
                            Cancel
                          </button> -->
                        <!-- </div> -->
                            <button class="mr-2 btn-canvas-blue"   type="button" data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="toolsGroupForm.form.pristine" (click)="hideForm()">Cancel</button>


                    </div>

                           
                  </div>
                
        
                  </form>
                </div>

            <div class="my-5 table-container">
              <div class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"  class="table ">
                <thead>
                  <tr>
                    <!--Code_comment_for_furture_use_Don't_Delete-->
                   <th style="width: 15px;">
                      <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
                    </th>
                     <!--Code_comment_for_furture_use_Don't_Delete-->
                    <th>ID</th>
                    <th>Connection Name</th>
                    <!--<th>Description</th> -->
                    <th>Subscription</th>
                    <th>Tool Group</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let Azure of AzureArray">
                     <!--Code_comment_for_furture_use_Don't_Delete-->
                   <td style="width: 15px;">
                      <input type="checkbox" [(ngModel)]="Azure.isSelected" name="list_name" value="{{Azure.id}}" (change)="isAllSelected()"/>
                    </td>
                     <!--Code_comment_for_furture_use_Don't_Delete-->
                    <td>
                      <span class="link-col" (click)="getRecord(Azure)">{{Azure.ID}}</span></td>
                    <td>{{Azure.name}}</td>
                    <!--<td>{{Azure.description}}</td> -->
                    <td>{{Azure.subscription_id}}</td>
                    <td>{{Azure.toolGroup}}</td>
                  </tr>
    
                </tbody>
              </table>
            </div>
            </div>

            
        </div>
</div>