import { Component, OnInit, Inject } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { AlertService } from 'src/app/_alert';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
}

export interface vdiTool {
  tenant_config_id:string;
  client_name:string;
  client_id: string;
  client_secret: string;
  client_secret_mst: string;
  tenant_id: string;
  subscription_id:string;
  group_name:string;
  group_id:string;
  azure_id: string;
}

@Component({
  selector: 'app-admin-tenant-propertire',
  templateUrl: './admin-tenant-propertire.component.html',
  styleUrls: ['./admin-tenant-propertire.component.scss']
})
export class AdminTenantPropertireComponent implements OnInit,CanComponentLeave {

  showVdiForm: boolean = false;
  editMode: boolean = false;

  toolsGroup: any = {};
  toolGr: any;
  nameInstance: any;
  description: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ToolGroupList = [];
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox = false;

  VdiConfig_details:any=[];
  check:any=[]
  ResourceGroupArr = [];
  VnetArr = [];
  SubnetArr = [];
  checkedList: any;

  project_ResourceGroup:string;
  project_Vnet:string;
  project_Subnet:string;

  project_toolGrName: string;
  saveConnDisable = false;
  CancelConnDisable = false;
  UpdateConnDisable = true;
  AzuredrpDisable = false;

  ResourceDisable=false;
  VnetDisable=false;
  SubDisable=false;
  SCMArray = [];
  project_toolGrId: string;
  vdiObj: object[];
  project_id_AksEdit: string;
  project_id_db: string;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  vdi_SubId: string;
  vdi_tenantId: string;
  vdi_EditeAzureId: string;
  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Azure_ConnectionName: string;
  Role:string="";
  itemSelected:boolean=false;
  userMailId: string;
  SubscriptionList: Subscription_iddropdown[] = [];
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.client_name == '' || this.toolsGroup.client_name == undefined) &&
      (this.toolsGroup.client_id == '' || this.toolsGroup.client_id == undefined) &&
       (this.toolsGroup.client_secret == '' || this.toolsGroup.client_secret == undefined)  &&
       (this.toolsGroup.group_name == '' || this.toolsGroup.group_name == undefined)  &&
       (this.toolsGroup.azureId == '' || this.toolsGroup.azureId == undefined)  &&
        (this.toolsGroup.client_secret_mst == '' || this.toolsGroup.client_secret_mst == undefined)
         && (this.toolsGroup.group_id == '' || this.toolsGroup.group_id == undefined)){
        return true
      }
      if ((this.toolsGroup.client_name != '' || this.toolsGroup.client_name != undefined) &&
      (this.toolsGroup.client_id != '' || this.toolsGroup.client_id != undefined) &&
       (this.toolsGroup.client_secret != '' || this.toolsGroup.client_secret != undefined)  &&
       (this.toolsGroup.group_name != '' || this.toolsGroup.group_name != undefined)  &&
       (this.toolsGroup.azureId != '' || this.toolsGroup.azureId != undefined)  &&
        (this.toolsGroup.client_secret_mst != '' || this.toolsGroup.client_secret_mst != undefined)
         && (this.toolsGroup.group_id != '' || this.toolsGroup.group_id != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) { 
      this.masterSelected = false;

    }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };
    var PopupToolGroup = localStorage.getItem('Popup_Open_tenant')
    if (PopupToolGroup == "true") {
      this.showVdiForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_tenant')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;
     // console.log("this.toolsGroup.toolGr");
      //console.log(this.toolsGroup.toolGr);
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_tenant')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_tenant", "false");
    } else {
      this.showVdiForm = false;
    }
    this.getSubscriptionList();
    this.GeTenanttableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  backNavigation(){
    if ((this.toolsGroup.client_name == '' || this.toolsGroup.client_name == undefined) &&
     (this.toolsGroup.client_id == '' || this.toolsGroup.client_id == undefined) &&
      (this.toolsGroup.client_secret == '' || this.toolsGroup.client_secret == undefined)  &&
      (this.toolsGroup.group_name == '' || this.toolsGroup.group_name == undefined)  &&
      (this.toolsGroup.azureId == '' || this.toolsGroup.azureId == undefined)  &&
       (this.toolsGroup.client_secret_mst == '' || this.toolsGroup.client_secret_mst == undefined)
        && (this.toolsGroup.group_id == '' || this.toolsGroup.group_id == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  getSubscriptionList(): void {
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      //this.apiService.Admin_AzureTableDetails().subscribe(data => {
      if (res) {
        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name
          }
          this.SubscriptionList.push(obj)
        })
        // console.log(this.SubscriptionList, "SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  GeTenanttableDetails() {

    this.spinner.show();
    this.apiService.Admin_TenantrTableDetails().subscribe((res: any) => {
 
      res.forEach(member => {
        //  
        let obj = {
          
          
          "client_id": member.client_id,
          "client_name": member.client_name,
          "client_secret":member.client_secret,
          "client_secret_mst": member.client_secret_mst,
          "group_name":member.group_name,
          "group_id":member.group_id,
          "subscription_id": member.subscription_id,
          "tenant_id":member.tenant_id,
          "azure_id": member.azure_id,
          "tenant_config_id":member.tenant_config_id,


                }
                // console.log(obj);
                
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    }
    )
  }


  setVdiEditing(vdiObj: vdiTool) {
    // debugger
    this.toolsGroup.client_id = vdiObj.client_id;
    this.toolsGroup.client_name = vdiObj.client_name;
    this.toolsGroup.client_secret = vdiObj.client_secret;
    this.toolsGroup.client_secret_mst = vdiObj.client_secret_mst;
    this.toolsGroup.tenant_id = vdiObj.tenant_id;
    this.toolsGroup.group_name = vdiObj.group_name;
    this.toolsGroup.group_id = vdiObj.group_id;
    this.project_id_AksEdit=vdiObj.tenant_config_id;

    
    this.SubscriptionList.forEach(element => {
      if(element.tool_id.split(' (')[0] == vdiObj.azure_id){
        this.toolsGroup.azureId = element.tool_id;
      }
    });

  }

  getRecord(row) {
    this.clearTenantDetails();
    this.vdiObj = row;
    try {
      localStorage.setItem('vdiToolObj', JSON.stringify(this.vdiObj));
      var getObj = JSON.parse(localStorage.getItem('vdiToolObj'));
    }
    catch{
      return throwError('Data not found');
    }
    this.setVdiEditing(getObj);
    this.editMode = true;
    this.showVdiForm = true;
    this.UpdateConnDisable = true;
    this. ResourceDisable=true;
    this.VnetDisable=true;
    this.SubDisable=true;
    this.AzuredrpDisable = true;
    
  }
  clearTenantDetails() {
    this.toolsGroup.client_name = "";
    this.toolsGroup.client_id = "";
    this.toolsGroup.client_secret = "";
    this.toolsGroup.client_secret_mst = "";
    this.toolsGroup.group_id = "";
    this.toolsGroup.group_name = "";
    this.toolsGroup.azureId = "";
this.UpdateConnDisable=true;
this.AzuredrpDisable = false;
  }

  addTenant() {
    this.clearTenantDetails();
    this.showVdiForm = true;
    this.saveConnDisable = false;
    this.editMode = false;
  }
  refreshTenant() {
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-tenant-propertire']);
    });
  }
  showImageInfo(domain) {
    // console.log(domain);
    const dialogRef = this.dialog.open(TenantInfoDialog, {
      width: '50%',
      height: '30%',
      disableClose: true,
      data: {
        // image: domain.showImage,
         tenantvar:domain
       }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
   
   
  }
  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].client_name);
        // this.checkedList.push(this.checklist[i].tenant_config_id);

        
    }
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].tenant_config_id);
        // this.checkedList.push(this.checklist[i].tenant_config_id);

        
    }
   // this.checkedList = JSON.stringify(this.checkedList);
  //  console.log(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length-1; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                  var SelectedItemtwo=this.checkedList[index+1]
                 let VdiConfigBoby={
                    "tenant_config_id":SelectedItemtwo,
                    "client_name":SelectedItem,


                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
 
  DeleteConfig(){
    // debugger
    // console.log(this.VdiConfig_details);
    // console.log(this.VdiConfig_details[0]);
         var deleteBody: any;

    
    this.userMailId = localStorage.getItem("mail").toLowerCase()
    
    this.check=[]
    for(let i=0;i<this.VdiConfig_details.length;i++){
      // console.log(this.VdiConfig_details[i]);
      this.check.push(this.VdiConfig_details[i])
      
    }
    //  this.VdiConfig_details.forEach(element => {
    //    console.log(element);
       
    //    this.check.push(element)
       
    //  });
    // console.log(this.check);
    
     deleteBody = {
       "tenant_details":this.check,
       "requester_email":this.userMailId,
       "resource_type": "client"
    
     }
    //  console.log(deleteBody,"deletebody");
     
    
    // debugger;
    //  var deleteBody: any;
    //  this.check=[]
    //  this.VdiConfig_details.forEach(element => {
    //    this.check.push(element.vdi_dropdown_id)
       
    //  });
    //  deleteBody = {
    //    "tool_ids":this.check
    
    //  }
    // deleteBody=this.VdiConfig_details[0]
    
     this.apiService.Admin_TenantDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-tenant-propertire']);
         });
        // this.alertService.success("Data Delete Successfully", this.options);
         this.alertService.success(res.body.message, this.options); 
       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
  onChange($event) {
    let AksToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = AksToolGrName;
    // console.log("scmToolGrName", AksToolGrName);
  }




  onChangeAzureId($event) {
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;

   
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
    var i = azureId.split(" ");
    azureId = i[0];
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {

      if (res) {
        res.forEach(member => {

          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;


        })

       
        localStorage.setItem("vdi_SubId", this.vdi_SubId);
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  get_EditeAzureDetails(azureId) {
    this.spinner.show();
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
      if (res) {

        res.forEach(member => {
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

          this.vdi_EditeAzureId = this.vdi_Azure_Id + " (" + this.vdi_Azure_ConnectionName + ")";

        })

        this.toolsGroup.azureId = this.vdi_EditeAzureId;


      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
      this.spinner.hide();
  }

  CheckTenantDuplication() {
    if (
      this.toolsGroup.client_name == undefined || this.toolsGroup.client_name == "" || this.toolsGroup.client_name == " "
      || this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " " 
      || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " " 
      || this.toolsGroup.client_secret_mst == undefined  || this.toolsGroup.client_secret_mst == " " || this.toolsGroup.client_secret_mst == undefined
      || this.toolsGroup.client_secret_mst == "" || this.toolsGroup.client_secret_mst == " " 
      || this.toolsGroup.group_id == undefined || this.toolsGroup.group_id == ""|| this.toolsGroup.group_id == " "
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " " || this.toolsGroup.azureId == ""
      || this.toolsGroup.group_name == undefined || this.toolsGroup.group_name == " " || this.toolsGroup.group_name == ""
      ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      let validateBody = {
        "client_name": this.toolsGroup.client_name,
        "tenant_id": this.vdi_tenantId,
        "subscription_id": this.vdi_Subscription_ID
    }

      this.apiService.Admin_CheckTenantValidation(validateBody).subscribe((res: HttpResponse<any>) => {

        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.TenantSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  TenantSave() {
    
    if (

    
       this.toolsGroup.client_name == undefined || this.toolsGroup.client_name == "" || this.toolsGroup.client_name == " "
      || this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " " 
      || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " " 
      || this.toolsGroup.client_secret_mst == undefined  || this.toolsGroup.client_secret_mst == " " || this.toolsGroup.client_secret_mst == undefined
      || this.toolsGroup.client_secret_mst == "" || this.toolsGroup.client_secret_mst == " " 
      || this.toolsGroup.group_id == undefined || this.toolsGroup.group_id == ""|| this.toolsGroup.group_id == " "
      || this.toolsGroup.azureId == undefined || this.toolsGroup.azureId == " " || this.toolsGroup.azureId == ""
      || this.toolsGroup.group_name == undefined || this.toolsGroup.group_name == " " || this.toolsGroup.group_name == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else { 
      var saveBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      // ##### Code for New_Save Funtionality ########
      saveBody = {
            "client_name": this.toolsGroup.client_name,
            "ACCESS_TOKEN": "",
            "ACCESS_TOKEN_MST": "",
            "client_id":  this.toolsGroup.client_id,
            "client_secret":  this.toolsGroup.client_secret,
            "client_secret_mst":  this.toolsGroup.client_secret_mst,
            "subscription_id": this.vdi_Subscription_ID,
            "group_id":  this.toolsGroup.group_id,
            "group_name": this.toolsGroup.group_name,
            "tenant_id": this.vdi_tenantId,
            "azure_id" : this.toolsGroup.azureId.split(' (')[0],
            "resource_type": "tenant",
            "tenant_config_id": "",
            "created_by":localStorage.getItem('mail').toLowerCase()
        }
        
      this.apiService.Admin_Tenant_SaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tenant-propertire']);
          });
          this.alertService.success(res.body.message, this.options); 
        }
        else if (res.status == 202) {
          this.alertService.success("Data Saved Successfully", this.options);

          //this.router.navigate(['/admin-scm-tool']);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tenant-propertire']);
            this.alertService.success(res.body.message, this.options);           });
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }

  onSubmit() {


  }
  cancelok(){
    this.clearTenantDetails();

  if(this.nextUrl==undefined){
    this.refreshTenant()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  
  closeAks() {
    this.showVdiForm = false;
    this.editMode = false;
    this.refreshTenant()

  }
  updateTenant() {
    if(
    
      this.toolsGroup.client_name == undefined || this.toolsGroup.client_name == "" || this.toolsGroup.client_name == " "
     || this.toolsGroup.client_id == undefined || this.toolsGroup.client_id == "" || this.toolsGroup.client_id == " " 
     || this.toolsGroup.client_secret == undefined || this.toolsGroup.client_secret == "" || this.toolsGroup.client_secret == " " 
     || this.toolsGroup.client_secret_mst == undefined  || this.toolsGroup.client_secret_mst == " " || this.toolsGroup.client_secret_mst == undefined
     || this.toolsGroup.client_secret_mst == "" || this.toolsGroup.client_secret_mst == " " 
     || this.toolsGroup.group_id == undefined || this.toolsGroup.group_id == ""|| this.toolsGroup.group_id == " "
     || this.toolsGroup.group_name == undefined || this.toolsGroup.group_name == " " || this.toolsGroup.group_name == "") {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {
      var updateBody: any;
      this.project_toolGrId = this.toolsGroup.toolGr;
      this.getAzureIdDetails(this.toolsGroup.azureId);
      setTimeout(() => {
        updateBody = 
        {
          "client_name":  this.toolsGroup.client_name,
          "client_id":  this.toolsGroup.client_id,
          "client_secret":  this.toolsGroup.client_secret,
          "client_secret_mst":  this.toolsGroup.client_secret_mst,
          "tenant_id": this.vdi_tenantId,
          "subscription_id": this.vdi_Subscription_ID,
          "group_name": this.toolsGroup.group_name,
          "group_id": this.toolsGroup.group_id,
          "ACCESS_TOKEN": "",
          "ACCESS_TOKEN_MST": "",
          "updated_by":localStorage.getItem('mail').toLowerCase()

      }
      this.apiService.Admin_UpdateTenantProperties(updateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {          
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tenant-propertire']);
            //this.alertService.success("Data Updated Successfully", this.options);
            this.alertService.success(res.body.message, this.options); 
          });
        }
        else if (res.status == 202) {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tenant-propertire']);
            //this.alertService.success("Data Updated Successfully", this.options);
            this.alertService.success(res.body.message, this.options); 
          });
        }

      },(error: HttpErrorResponse) => {
        if (error.status == 430||error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
      }, 1000);
     
    
    
      
      

    }//else

  }
  openDialog() {
    const dialogRef = this.dialog.open(AddToolGrouptenantDialog, {
      width: '30%',
      height: '48%',

    });
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'project-info.html',
  styleUrls: ['./admin-tenant-propertire.component.scss']
})

export class TenantInfoDialog {
  //  image: any = [];
  // Template_id:any;
  //   enabledIcons: any[];
  //   workbenchIcons: any[];
  //   roleData: any;
  clientID:any;
  clientSecret:any;
  clientSecretmst:any;
  groupID:any;

    
    constructor(
      public dialogRef: MatDialogRef<TenantInfoDialog>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService, public alertService: AlertService,
    ) { 
    
    
      this.clientID=data.tenantvar.client_id;
      this.clientSecret=data.tenantvar.client_secret;
      this.clientSecretmst=data.tenantvar.client_secret_mst;
      this.groupID=data.tenantvar.group_id;

      
  
      
  
    }


    exit(): void {
      this.dialogRef.close();
    }
  
  }

@Component({
  selector: 'add-tool-group-dialog',
  templateUrl: 'add-tool-group-dialog.html',
  styleUrls: ['./admin-tenant-propertire.component.scss']
})

export class AddToolGrouptenantDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }



  clearAllSCMDetails() {
    this.model.toolName = " ";
    this.model.toolDescription = " ";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
        
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {

          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_tenant", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_tenant", new_ToolGroupName);
          localStorage.setItem("Popup_Open_tenant", "true");
          this.alertService.success("Data Saved Successfully", this.options);

          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tenant-propertire']);
          });

          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}
