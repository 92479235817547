
<h2 mat-dialog-title style="color: #00529C;">SCM Tools</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-md-6 text-center">
        <img src="assets/img/gitlab-logo.png" title="Git Lab" class="img-fluid">
            <div class="d-block mt-3">
              <button type="button" class="btn-canvas-blue" (click)="getSCMDetails('Gitlab')" mat-dialog-close>Gitlab</button>
            </div>  
      </div>
      <div class="col-md-6 text-center">
        <img width="53" src="assets/img/github-logo.png" title="GitHub" class="img-fluid">
            <div class="d-block mt-3">
              <button type="button" class="btn-canvas-blue" (click)="getSCMDetails('GitHub')" mat-dialog-close>GitHub</button>
          </div>
      </div>
  </div>
</mat-dialog-content>

