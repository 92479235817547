<!-- New code-->
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
      [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
             
              <li class="breadcrumb-item active" aria-current="page">Client Properties</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 col-md-5 text-right pt-3">
          <div class="table-options">
            <span (click)="addVdi()"><i class="fas fa-plus"></i> Add</span>
            <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

            <span (click)="refreshVdi()"><i class="fas fa-sync-alt"></i> Refresh</span>
            <a class="float-right step-back" (click)="backNavigation()">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
           
          </div>
        </div>
        <!-- <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div> -->
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/VDI-Icon-White.svg">
          <div class="vertical-line mx-3"> </div> <span>Client Properties</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
        <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                <div class="modal-header-popup">
                  <span  class="vertical-line mx-3"> </span>
                   <span class="header mb-0">Client Properties</span> 
                  <span  data-dismiss="modal" class="material-icons close-icon">
                      close
                    </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                  Are you sure want to Delete?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                </div>
              </div>
            </div>
          </div>
      <div *ngIf="showVdiForm" class="git-Lab-Form">
        <h2 *ngIf="!editMode">New VDI Instance</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
        <form (ngSubmit)="toolsGroupForm.form.valid && onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">
            
            <div class="form-group col-md-4">
              <label for="global_id">Global Id <span class="required">&#42;</span></label>
              <input id="global_id" type="text" autocomplete="off" name="global_id" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.global_id" #global_id="ngModel">
              <div *ngIf="global_id.invalid && (global_id.dirty || global_id.touched)" class="alert alert-danger">
                <div *ngIf="global_id.errors.required">
                  Global Id is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="admin_account_name">Admin Account Name <span class="required">&#42;</span></label>
              <input id="admin_account_name" type="text" autocomplete="off" name="admin_account_name" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.admin_account_name" #admin_account_name="ngModel">
              <div *ngIf="admin_account_name.invalid && (admin_account_name.dirty || admin_account_name.touched)" class="alert alert-danger">
                <div *ngIf="admin_account_name.errors.required">
                  Admin Account Name is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="admin_account_password">Admin Account Password <span class="required">&#42;</span></label>
              <input id="admin_account_password" type="password" autocomplete="off" name="admin_account_password" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.admin_account_password" #admin_account_password="ngModel">
              <div *ngIf="admin_account_password.invalid && (admin_account_password.dirty || admin_account_password.touched)" class="alert alert-danger">
                <div *ngIf="admin_account_password.errors.required">
                  Admin Account Password is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="stealthwatch_email">Stealthwatch Email <span class="required">&#42;</span></label>
              <input id="stealthwatch_email" type="text" autocomplete="off" name="stealthwatch_email" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.stealthwatch_email" #stealthwatch_email="ngModel">
              <div *ngIf="stealthwatch_email.invalid && (stealthwatch_email.dirty || stealthwatch_email.touched)" class="alert alert-danger">
                <div *ngIf="stealthwatch_email.errors.required">
                  Stealthwatch Email is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="stealthwatch_password">Stealthwatch Password <span class="required">&#42;</span></label>
              <input id="stealthwatch_password" type="password" autocomplete="off" name="stealthwatch_password" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.stealthwatch_password" #stealthwatch_password="ngModel">
              <div *ngIf="stealthwatch_password.invalid && (stealthwatch_password.dirty || stealthwatch_password.touched)" class="alert alert-danger">
                <div *ngIf="stealthwatch_password.errors.required">
                  Stealthwatch Password is required.
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label for="stealthwatch_token">Stealthwatch Token <span class="required">&#42;</span></label>
              <input id="stealthwatch_token" type="text" autocomplete="off" name="stealthwatch_token" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.stealthwatch_token" #stealthwatch_token="ngModel">
              <div *ngIf="stealthwatch_token.invalid && (stealthwatch_token.dirty || stealthwatch_token.touched)" class="alert alert-danger">
                <div *ngIf="stealthwatch_token.errors.required">
                  Stealthwatch Token is required.
                </div>
              </div>
            </div>
             
  
              <!-- Resource Group DropDown -->
              <div class="form-group col-md-4">
                <label for="amp_access_token">Amp Access Token Group <span class="required">&#42;</span></label>
                <input id="amp_access_token" type="text" autocomplete="off" name="amp_access_token" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.amp_access_token" #amp_access_token="ngModel">
              <div *ngIf="amp_access_token.invalid && (amp_access_token.dirty || amp_access_token.touched)" class="alert alert-danger">
                <div *ngIf="amp_access_token.errors.required">
                  Amp Access Token Group is required.
                </div>
              </div>
            </div>
  
             <!-- Vnet DropDown -->
            <div class="form-group col-md-4">
              <label for="imanage_token">Imanage Token <span class="required">&#42;</span></label>
              <input id="imanage_token" type="text" autocomplete="off" name="imanage_token" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.imanage_token" #imanage_token="ngModel">
              <div *ngIf="imanage_token.invalid && (imanage_token.dirty || imanage_token.touched)" class="alert alert-danger">
                <div *ngIf="imanage_token.errors.required">
                  Imanage Token is required.
                </div>
              </div>
            </div>
  
            
             <!-- Subnet DropDown -->
             <div class="form-group col-md-4">
              <label for="azure_auth_token">Azure Auth Token<span class="required">&#42;</span></label>
              <input id="azure_auth_token" type="text" autocomplete="off" name="azure_auth_token" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.azure_auth_token" #azure_auth_token="ngModel">
              <div *ngIf="azure_auth_token.invalid && (azure_auth_token.dirty || azure_auth_token.touched)" class="alert alert-danger">
                <div *ngIf="azure_auth_token.errors.required">
                  Azure Auth Token is required.
                </div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label for="jenkins_email">Jenkins Email<span class="required">&#42;</span></label>
              <input id="jenkins_email" type="text" autocomplete="off" name="jenkins_email" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.jenkins_email" #jenkins_email="ngModel">
              <div *ngIf="jenkins_email.invalid && (jenkins_email.dirty || jenkins_email.touched)" class="alert alert-danger">
                <div *ngIf="jenkins_email.errors.required">
                  Jenkins Email is required.
                </div>
              </div>
            </div>

            <div class="form-group col-md-4">
              <label for="jenkins_auth_token">Jenkins Auth Token<span class="required">&#42;</span></label>
              <input id="jenkins_auth_token" type="text" autocomplete="off" name="jenkins_auth_token" class="form-control  form-control-lg" required
                [(ngModel)]="toolsGroup.jenkins_auth_token" #jenkins_auth_token="ngModel">
              <div *ngIf="jenkins_auth_token.invalid && (jenkins_auth_token.dirty || jenkins_auth_token.touched)" class="alert alert-danger">
                <div *ngIf="jenkins_auth_token.errors.required">
                  Jenkins Auth Token is required.
                </div>
              </div>
            </div>
  
  
          </div>
  
          <div class="row">
            <div class="col-md-6">
             
            </div>
            <div class="col-md-6 text-right">
  
             
              <button *ngIf="!editMode" [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2"
                (click)="CheckAksDuplication()">
                Save
              </button>
              <button *ngIf="editMode" type="button" class="btn-canvas-blue mr-2"
                (click)="updateClient()">
                Update
              </button>
              <!-- <button *ngIf="editMode"  type="button" class="btn-canvas-blue mr-2"   data-toggle="modal" data-target="#confirmationModal3"
              (click)="deleteRecord()">
              Delete
            </button> -->
              <!-- <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
                Cancel
              </button> -->
              <!-- <button class="mr-2 btn-canvas-blue" type="button" data-toggle="modal"
              data-target="#confirmationModal">Cancel</button> -->
              <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="toolsGroupForm.form.pristine" (click)="closeAks()">Cancel</button>
 
      </div>
 
      <!-- Modal -->
      <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
      <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
          aria-labelledby="confirmationModal" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                  <div class="modal-header-popup">
                      <span class="vertical-line mx-3"> </span>
                      <span class="header mb-0">Client Properties</span>
                      <span data-dismiss="modal" class="material-icons close-icon">
                          close
                      </span>
                  </div>
                  <hr class="margin-class" />
                  <div class="modal-body">
                      Are you sure want to cancel?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-canvas-blue-2"
                          data-dismiss="modal">Close</button>
                      <button type="button" [disabled]="CancelConnDisable" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                  </div>
              </div>
              <!-- </div> -->
              <!-- </div> -->
          </div>
      </div>
      <div class="modal fade " id="confirmationModal3" tabindex="-1" role="dialog"
      aria-labelledby="confirmationModal3" aria-hidden="true">
      <div class="modal-dialog" role="document">
          <div class="modal-content pop-up">
              <div class="modal-header-popup">
                  <span class="vertical-line mx-3"> </span>
                  <span class="header mb-0">Client Properties</span>
                  <span data-dismiss="modal" class="material-icons close-icon">
                      close
                  </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                  Are you sure want to delete?
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2"
                      data-dismiss="modal">Close</button>
                      <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
                    </div>
          </div>
          <!-- </div> -->
          <!-- </div> -->
      </div>
  </div>
           </div>
  
  
        </form>
      </div>
      <div class="my-5 table-container">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
                <th style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                        (change)="checkUncheckAll()" />
                </th>

  
              <th>ID</th>
              <th>Admin Account Name</th>
             <!-- <th>Admin Account Password</th>-->
              <th>Stealthwatch Email</th>
             <!--  <th>stealthwatch Password</th>-->
              <!-- <th>stealthwatch Token</th> -->
              <!-- <th>Amp Access Token</th> -->
              <!-- <th>Imanage Token</th> -->
              <!-- <th>Azure Auth Token</th> -->
              <th>Jenkins Email</th>
              <!-- <th>Jenkins Auth Token</th> -->
              <th>Action</th>

            </tr>
          </thead>
          <tbody class="data-table-height">
            <tr *ngFor="let Client of SCMArray">
              
                <td style="width: 15px;">
                    <input type="checkbox" [(ngModel)]="Client.isSelected" name="list_name" value="{{Client.id}}"
                        (change)="isAllSelected()" />
                </td>
  
              <td><span class="link-col" (click)="getRecord(Client)">{{Client.client_config_id}}</span></td>
              <td >{{Client.admin_account_name}}</td>
           <!--  <td>{{Client.admin_account_password}}</td>-->
              <td >{{Client.stealthwatch_email}}</td>
              <!-- <td>{{Client.stealthwatch_password}}</td>-->
              <!-- <td >{{Client.stealthwatch_token}}</td> -->
              <!-- <td >{{Client.amp_access_token}}</td> -->
              <!-- <td >{{Client.imanage_token}}</td> -->
              <!-- <td >{{Client.azure_auth_token}}</td> -->
              <td >{{Client.jenkins_email}}</td>
              <!-- <td >{{Client.jenkins_auth_token}}</td> -->
              <td>  <span class="table-crud table-delete-icon" title="Template Information" (click)="showImageInfo(Client)">
                <img width="20" src="../../../assets/img/info.jpg"></span></td>

  
            </tr>
  
          </tbody>
        </table>
      </div>
      </div>
  
    </div>
  </div>