<div class="admin-home-container">
    <div class="canvas-card py-5">
      <div>       
        <div>
          <div class="text-left table-crud-icons">
            <img class="img-fluid mr-2" src="assets/icon/menu/ci-cd-icon.png" />
            <span style="font-size: 20px;" class="add-text">CI/CD</span>
          </div>
          <div class="text-right table-crud-icons">           
            <span class="material-icons">delete</span>
            <span (click)="openDialog()" style="cursor: pointer;">
              <span class="material-icons last">add</span> <span (click)="setFormMode()" class="add-text">Add</span>
            </span>
          </div>

          <div class="my-5 table-container">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table fixed-table">
              <thead>
                <tr>
                  <th style="width: 15px;"><input type="checkbox"></th>
                  <th>Tool Name</th>
                  <th>ID</th>  
                  <th>Project URL</th>               
                  <th>Email</th>                 
                  <th>Tool Group</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cicd of cicdArray">
                  <td style="width: 15px;"><input type="checkbox"></td>
                  <td><span class="link-col" (click)="getRecord(cicd)">{{cicd.name}}</span></td>
                  <td>{{cicd.ID}}</td>
                  <td>{{cicd.projectURL}}</td>
                  <td>{{cicd.email}}</td>
                  <td>{{cicd.toolGroup}}</td>
                </tr>  
              </tbody>
            </table>
          </div>
  
         
        </div>
      </div>
  
    </div>
  </div>