import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ViewChild, ComponentRef, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { RouterModule, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { waitForAsync } from '@angular/core/testing';
import { delay } from 'rxjs/operators';
import { TeamMembersComponent } from 'src/app/project-configurations/team-members/team-members.component';
import { VdiDetailsComponent } from 'src/app/project-configurations/vdi-details/vdi-details.component';
import { ProjectRolesComponent } from '../project-roles/project-roles.component';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';




@Component({
  selector: 'app-work-bench',
  templateUrl: './work-bench.component.html',
  styleUrls: ['./work-bench.component.scss']

})
export class WorkBenchComponent implements OnInit {

  projectId: any;
  projectName: any;
  project_id: string;
  compassId: any;
  createdDate: any;
  members: any = [];
  pipe = new DatePipe('en-US');
  dateformat: any;
  new_members: any = []
  previous_members: []
  removed_members: []
  pmName: any;
  kdmName: any;
  actuStartDate: any;
  actuEndDate: any;
  status: any;
  type: any;
  customerName: any;
  deliveryBU: any;
  activeTab: string;
  toolName: string
  tabEventDisplay;
  vdiInformation: any
  infoVdiArray: any = []
  vdiStatus: string
  public show_workbench_items = true;
  public show_workbench_tab = true;
  public show_icons = false;
  public show_details = false;
  ref: ComponentRef<any>;

  scm_flag: boolean = false;
  storyboard_flag: boolean = false;
  vdi_flag: boolean = false;
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  
  deployment_flag:boolean=false;


  @ViewChild('container', { read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  catalog_id: any;
  catalog_name: any;
  newCatalog_id: any;
  workbench_icons: any = [];
  private uiUrl = environment.uiUrl;
  isUserTM: boolean = false;
  private footerLogoPath = environment.footerLogoPath;
  footerimageSrc:any;
  footerimageAlt:any;
  userMailId: string;
  adminRole: boolean;
  selectedtoolName: {};

  constructor(
    public apiService: AuthService, public alertService: AlertService,
    public dialog: MatDialog,
    private _router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {

    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this._router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this._router.navigated = false;
        window.scrollTo(0, 0);
      }
    });

   this.footerimageSrc = this.footerLogoPath;  
   this.footerimageAlt = 'Footer';

  }

  ngOnInit() {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){

    if(localStorage.getItem('isUserTM') == 'false'){
       this.isUserTM=false;
    }else{
      this.isUserTM=true;
    }

    if(localStorage.getItem('canvasAdminRole')=="false"){
      this.adminRole = false;
    }
    else{
      this.adminRole = true;
    }
      this.projectId=localStorage.getItem('projectId');
   
      let reqBody = {
          "project_id": this.projectId,
          "user_role_name": this.isUserTM ? 'Team Member' : 'Project Manager'
      }
      this.apiService.getIconsForDisable(reqBody).subscribe((data: any) => {
        this.workbench_icons = data.icons.workbench[0];
      }, (error: HttpErrorResponse) => {
        if (error.status == 441){
        this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
        this.alertService.error("Unable to fetch data.", this.options);
        throw error;
        }
        
        })
 


      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        this.project_id = project[0].project_id;
        this.apiService.projectName.next(project[0].project_name);
        this.createdDate = project[0].createdAt;
        this.dateformat = this.pipe.transform(this.createdDate, 'MM/dd/yyyy');
        this.projectName = project[0].project_name;
        this.compassId = project[0]._id;
        this.catalog_id = project[0].catalog_id;
        this.catalog_name = project[0].catalog_name;
        this.members = project[0].members
        this.previous_members = project[0].members
        if(project[0].imanage_project_info){
          this.pmName = project[0].imanage_project_info.PmName;
          this.kdmName = project[0].imanage_project_info.KdmId;
          this.actuStartDate = project[0].imanage_project_info.ActuStartDate;
          this.actuEndDate = project[0].imanage_project_info.ActuEndDate;
          this.status = project[0].imanage_project_info.ProjStatus;
          this.type = project[0].imanage_project_info.ProjType;
          this.customerName = project[0].imanage_project_info.CustomerId;
          this.deliveryBU = project[0].imanage_project_info.DeliveryBu
        }
        this.apiService.previousMembers.next(this.previous_members)
      }, (error: HttpErrorResponse) => {
        if (error.status == 432){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         })
   
         this.apiService.refreshVMStatus(this.projectId).subscribe((status: any) => {
          
    

         }
          )
    this.newCatalog_id=localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(this.newCatalog_id).subscribe((data: any) => {
      if (data.scm_tools.length == 0) {
        this.scm_flag = true;
      }
      if (data.storyboard_tools.length == 0) {
        this.storyboard_flag = true;
      }
      if (data.vdi_tools.length == 0) {
        this.vdi_flag = true;

      } 
      if(data.deploymentstore_tools.length == 0){
        this.deployment_flag=true;
        localStorage.setItem('deploymentFlag','true');
      }else{
        localStorage.setItem('deploymentFlag','false');
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })
      }
      else{
        this._router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this._router.navigate(['/admin-catalog-config']);
      });
      }
  }

  displayProjectRole() {
    this.removeComponent();
    this.show_workbench_items = true;
    this.show_workbench_tab = false;
    this.show_icons = false;
    this._router.navigate(['/project-roles'])
    
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  displayAssignMembers() {
    this.removeComponent();
    this.show_workbench_items = true;
    this.show_workbench_tab = false;
    this.show_icons = false;
    const factory = this.componentFactoryResolver.resolveComponentFactory(TeamMembersComponent);
    this.ref = this.viewContainerRef.createComponent(factory);
    this.ref.changeDetectorRef.detectChanges();
  }

  displayWorkbenchIcons() {
    this.show_workbench_tab = false;
    this.show_icons = true;
  }

  displayDetails() {
    this.show_workbench_tab = false;
    this.show_details = true;
  }

  tabClick(tab: any) {
    if (tab.tab.textLabel == "Workbench") {
      this.removeComponent();
      this.show_workbench_tab = true;
    } else if (tab.tab.textLabel == "Team Members") {
      this.displayAssignMembers();
    } else {
      this.removeComponent();
    }
  }

  removeComponent() {
    this.show_workbench_tab = false;
    if (this.ref != undefined) {
      this.ref.destroy();
    }
  }


  getToolName(value) {
    this.apiService.selectedTool.next(value)
  }

  openToolDiaLog() {
    this.apiService.selectedTool.subscribe(data => {
      this.selectedtoolName = data
      })
      
      
    // console.log(this.workbench_icons.deployment_store);
    
    const toolSelectDialogRef = this.dialog.open(ToolDialogComponent, {
      
      width: "55vw",
      height: "50vh",
      maxWidth: "500px",
      maxHeight: "330px",
      disableClose: true,
      // data: this.selectedtoolName == 'deployStore' ? this.workbench_icons.deployment_store : '',
      data:this.workbench_icons


    });

    toolSelectDialogRef.afterClosed().subscribe(result => {
    });
  }

  syncImanageTeamMemberProject() {
    let projectSummary = {
      "ps_number": "10603933",
      "project_id": this.projectId

    }
    this.apiService.syncImanageTeamMembers(projectSummary).subscribe((data: any) => {
      this.new_members = data.new_members
      this.removed_members = data.removed_members

      if (this.new_members.length == 0 && this.removed_members.length == 0) {
        this.apiService.previousMembers.next(this.previous_members)
        this.apiService.newMembers.next(this.new_members)
        this.apiService.removedMembers.next(this.removed_members)
      } else {
        this.apiService.previousMembers.next(this.previous_members)
        this.apiService.newMembers.next(this.new_members)
        this.apiService.removedMembers.next(this.removed_members)
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

 /* getVdiInfo(project_id) {
    this.apiService.getVDIInfo(project_id).subscribe((data: any) => {

      this.vdiInformation = data

      if (data == null || data == undefined || data.length == 0) {
        this.vdiStatus = "NotCreated"
        this.apiService.vdiStatus.next(this.vdiStatus);

      } else {
        this.vdiStatus = "Created"
        this.apiService.vdiStatus.next(this.vdiStatus);
      }

      data.forEach(vdi => {
        if (vdi.vdi_type == "Shared") {
          vdi.vdi_users.forEach(user => {
            let tableObject = {


              "user_email": user.user_mail,
              "user_name": user.user_name,
              "type": "Shared",
              "status": user.approval,
              "image": "Shared-Stack",
              "vdi_type": "Shared"
            }
            this.infoVdiArray.push(tableObject)

          })

        }
        if (vdi.vdi_type == "Dedicated") {
          vdi.vdi_users.forEach(user => {
            let tableObject = {


              "user_email": user.user_mail,
              "user_name": user.user_name,
              "type": "Dedicated",
              "status": user.approval,
              "image": "Full-Stack",
              "vdi_type": "Dedicated"
            }
            this.infoVdiArray.push(tableObject)
          })
        }
      })
      this.apiService.vdiInformationArray.next(this.infoVdiArray)
    })
  }*/
}


@Component({
  selector: 'dialog-tool',
  templateUrl: 'tool-dialog.html',
  styleUrls: ['./work-bench.component.scss']
})
export class ToolDialogComponent {

  projectId;
  toolName;
  showLogoByEnv: boolean;
  toolViewAccess: any;
  vdiData:any
  toolViewAccessVdi: any;

  constructor(public _router: Router, public dialogRef: MatDialogRef<ToolDialogComponent>,
    public apiService: AuthService, public dialog: MatDialog, public _snackBar: MatSnackBar,@Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(data);
    
    
    this.toolViewAccess = data.deployment_store
    this.toolViewAccessVdi=data.assign_vdi
    // console.log(this.toolViewAccess);
    // console.log(this.toolViewAccessVdi);
    
    

   }



  exit() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.apiService.selectedTool.subscribe(data => {
      this.toolName = data
    })
    if(environment.msTeamsScreen){

      this.showLogoByEnv = true;

    }

    else{

      this.showLogoByEnv = false;

    }

  }

  openCastToolDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '22%',
      height: '13%',
      disableClose: true,
    });
  }


  getSelectedToolValue(value) {

    this.apiService.toolSelectedFromDialog.next(value)
    if (value == "Java") {

      setTimeout(() => {

      }, 800);

      setTimeout(() => {
      }, 4000);


    } else if (value == "Dot Net") {
      setTimeout(() => {

      }, 800);

      setTimeout(() => {
      }, 4000);

    } 

  }

  GetAuthorize() {
    const client_id = environment.webex_clientId;
    const client_redirectURI = environment.webex_redirect_uri;
    // var requestUrl = 'https://api.ciscospark.com/v1/authorize?' +
    //   'response_type=code&' +
    //   'scope=' + encodeURIComponent('spark:all') + '&' +
    //   'state=' + encodeURIComponent(this.randomString(63)) + '&' +
    //   'client_id=' + encodeURIComponent(client_id) + '&' +
    //   'redirect_uri=' + encodeURIComponent(client_redirectURI);
    // console.log("RequestURL:", requestUrl);
    var requestUrl="https://webexapis.com/v1/authorize?client_id="+ encodeURIComponent(client_id) +"&response_type=code&redirect_uri="+ encodeURIComponent(client_redirectURI) +"&scope=meeting%3Arecordings_read%20meeting%3Aadmin_preferences_write%20spark%3Aall%20meeting%3Aadmin_schedule_write%20meeting%3Aadmin_preferences_read%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20meeting%3Aadmin_schedule_read&state=set_state_here";
    window.location.href = requestUrl;

    setTimeout(() => {
      this._router.navigate(['/webEx/:code/:state']);
    }, 60000);

  }
  randomString(length) {
    var str = "";
    var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      str += range.charAt(Math.floor(Math.random() * range.length));
    }
    return str;
  }

}

@Component({
  selector: 'cast-dialog',
  templateUrl: 'cast-dialog.html',
  styleUrls: ['./work-bench.component.scss']
})
export class CastDialogComponent {

  castDialog;

  constructor(public router: Router, public dialogRef: MatDialogRef<CastDialogComponent>,
    public apiService: AuthService, public dialog: MatDialog, public _snackBar: MatSnackBar,
  ) {
  }

  exit() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.apiService.castToolDialog.subscribe(value => {
      this.castDialog = value
    })
  }

}

