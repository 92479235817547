import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AlertService } from 'src/app/_alert';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';


export interface configList {
  Name: string;
  ID: string;
  Description: string;
  Cost: string;
  DB_toolGroup_ID: string;
}

export interface ToolGroupDetails {
  Name: string;
  ID: string;
  Description: string;
  DB_toolGroup_ID: string;
}



@Component({
  selector: 'app-admin-toolgroup',
  templateUrl: './admin-toolgroup.component.html',
  styleUrls: ['./admin-toolgroup.component.scss']
})
export class AdminToolgroupComponent implements OnInit, CanComponentLeave {

  projectId
  ToolGroupArray = [];
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;
  model: any = {};
  toolGr_description: string;
  toolGr_name: string;
  formMode: string = "";
  tool_DB_Id: any;
  checklist: any;
  masterSelected: boolean;
  masterSelectedcheckToolGroup: boolean;
  project_selectedcheckbox = false;
  checkedList: any;

  VdiConfig_details: any = [];
  Toolgrp: any = [];
  check: any = []

  editMode: boolean = false;

  dtOptions: DataTables.Settings = {};
  dtOptionsToolGroup: DataTables.Settings = {};
  dtTrigger = new Subject();
  dtTriggerToolGroup = new Subject();
  Role: string = "";
  toolGrObj: object[];
  saveButtonDisable: boolean = false
  ToolGroupDisable = false;


  showToolGroupForm: boolean = false;

  showToolGroupEditTable: boolean = false;
  showToolGroupMasterTable: boolean = true;
  ToolgroupId: any;
  tableResourceArray: any = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  itemSelected: boolean = false;
  nextUrl: string;

  canLeave(url): boolean {
    // console.log(url);

    // debugger
    this.nextUrl = url
    if (this.nextUrl == "/admin-scm-tool") {
      return true
    }
    else {
      if ((this.model.toolName == undefined || this.model.toolName == "") && (this.model.toolDescription == undefined
        || this.model.toolDescription == "")) {
        return true
      }
      if ((this.model.toolName != undefined || this.model.toolName != "") && (this.model.toolDescription != undefined
        || this.model.toolDescription != "")) {
        let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
        return false
      }
    }


    return true
  }
  constructor(public apiService: AuthService, private router: Router, private spinner: NgxSpinnerService,
    public dialog: MatDialog, public alertService: AlertService) {
    this.masterSelected = false;
    this.masterSelectedcheckToolGroup = false;

  }

  ELEMENT_DATA: ToolGroupDetails[];
  displayedColumns: string[] = ['Name', 'ID', 'Description', 'select'];
  //dataSource = new MatTableDataSource<ToolGroupDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<ToolGroupDetails>(true, []);

  ngOnInit(): void {
    this.dtOptions = {
      order: []
    };
    this.dtOptionsToolGroup = {
      order: []
    }
    this.Role = localStorage.getItem("Role");
    if (this.Role == "Admin") {
      this.apiService.projectKey.subscribe(projectId => {
        // this.userEmail = currentUserEmail;
        this.projectId = projectId;
      })
      this.GetToolGrtableDetails();
      this.showToolGroupMasterTable = true;
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
      });
    }
  }

  refreshToolGroup() {

    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-tool-group']);
    });


  }

  onSubmit() {
    // 
  }


  GetToolGrtableDetails() {
    // 
    this.spinner.show();
    this.apiService.Admin_ToolGrTableDetails().subscribe((res: any) => {
      res.forEach(member => {
        let obj = {
          "Name": member.tool_group_name,
          "ID": member.tool_group_id,
          "Description": member.tool_group_description,
          "DB_toolGroup_ID": member._id

        }
        this.ToolGroupArray.push(obj)
        // this.checklist = this.ToolGroupArray;

      })

      // this.dataSource.data = this.ToolGroupArray as ToolGroupDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
    // SCMArray
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTriggerToolGroup.unsubscribe();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    // const numRows = this.dataSource.data.length;
    //return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    // this.isAllSelected() ?
    // this.selection.clear() :
    //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: configList): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Name + 1}`;
  // }

  //displayedColumns: string[] = ['Name', 'ID', 'Description', 'select'];



  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }

  setToolGroupForm(getObj) {

    this.model.toolName = getObj.Name;
    this.model.toolDescription = getObj.Description;
    this.model.toolId = getObj.ID;
  }

  //Update row data
  updateData() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
      this.alertService.error("Please enter details In all the fields", this.options);

    }
    else {
      if (this.model.toolName.length < 3) {
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if (sp.test(this.model.toolName)) {
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      if (this.model.toolName.length > 20) {
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if (this.model.toolDescription.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
        return;
      }
      if (this.model.toolDescription.length > 50) {
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
      if (sp.test(this.model.toolDescription)) {
        this.alertService.error("Description must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      // this.toolGr_name = this.model.toolName;

      saveBody = {

        "tool_group_description": this.toolGr_description,
        "tool_group_id": this.model.toolId,
        "updated_by": localStorage.getItem('mail').toLowerCase()
      }
      this.apiService.Admin_ToolGrUpdateData(saveBody).subscribe((res: HttpResponse<any>) => {

        if (res.status == 200) //doesnt work
        {

          // this.alertService.success("Data Saved Successfully", this.options);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tool-group']);
          });
          this.alertService.success(res.body.message, this.options);
        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 436 || error.status == 430) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }



  // On click ID get row data
  getRecord(row) {
    this.toolGrObj = row;
    try {
      localStorage.setItem('toolGrObj', JSON.stringify(this.toolGrObj));
      localStorage.setItem('formMode', "Edit");
    }
    catch {
      return throwError('Data not found');
    }
    this.formMode = localStorage.getItem('formMode')
    if (this.formMode == "Edit") {
      this.ToolGroupDisable = true
      var getObj = JSON.parse(localStorage.getItem('toolGrObj'));
      this.setToolGroupForm(getObj);
      this.editMode = true;
      this.getToolIdDetails(row);
    }
    this.showToolGroupForm = true;
    this.showToolGroupEditTable = true;
    this.showToolGroupMasterTable = false;

  }

  getToolIdDetails(row) {

    this.ToolgroupId = row.ID;
    this.apiService.Admin_GetToolgroupIdDetails(this.ToolgroupId).subscribe((res: any) => {
      // console.log("ToolGroup Edit Details ")
      //  console.log(res)

      let Toolobj: any;
      this.tableResourceArray = [];
      res.body.tools.scm_tools.forEach(member => {

        if (member == "null" || member == null || member == undefined) {

        }
        else {

          // console.log("Inside the SCM condition")
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
            // "db_id":member._id,
          }


          // console.log(Toolobj);
        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;


      })
      res.body.tools.storyboard_tools.forEach(member => {


        if (member == "null" || member == null || member == undefined) {

        }
        else {


          // console.log("Inside the storyboard_tools condition")
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
            // "db_id":member._id,
          }


          // console.log(Toolobj);
        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })


      res.body.tools.cicd_tools.forEach(member => {


        if (member == "null" || member == null || member == undefined) {

        }
        else {


          // console.log("Inside the Jenkins condition")
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
            // "db_id":member._id,
          }


          //console.log(Toolobj);
        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

      res.body.tools.vdi_tools.forEach(member => {


        if (member == "null" || member == null || member == undefined) {

        }
        else {


          // console.log("Inside the vdi_tools condition")
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
            // "db_id":member._id,
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })


      res.body.tools.deploymentstore_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {

          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })
      //

      res.body.tools.network_config_tools.forEach(member => {

        if (member == "null" || member == null || member == undefined) {

        }
        else {


          // console.log("Inside the Network condition")
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
            // "db_id":member._id,
          }


          // console.log(Toolobj);
        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

      res.body.tools.azure_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

      res.body.tools.aws_config_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })
      res.body.tools.aws_ec2_image_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

      res.body.tools.aws_network_config_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

      res.body.tools.aws_workspace_network_config_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })

       res.body.tools.aws_workspace_image_tools.forEach(member => {
        if (member == "null" || member == null || member == undefined) {

        }
        else {
          Toolobj = {
            "resourceType": member.tool_name,
            "tool_id": member.tool_id
          }

        }

        this.tableResourceArray.push(Toolobj)
        this.checklist = this.tableResourceArray;

      })


      //console.log(this.tableResourceArray,"Edit")

      this.dtTriggerToolGroup.next();
    }
      , (error: HttpErrorResponse) => {
        //

        if (error.status == 436) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

  }

  openDialog() {
    const dialogRef = this.dialog.open(AdminToolgroupDialog, {
      width: '40%',
      height: '40%',
    });
  }

  addToolGroup() {
    // this.closeToolGroup();
    this.showToolGroupForm = true;
    this.ToolGroupDisable = false;
    this.showToolGroupEditTable = false;
    this.showToolGroupMasterTable = true;
    this.editMode = false;

  }
  deleteRecord() {
    if (this.VdiConfig_details.length == 0) {
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }

  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelectedcheck() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.project_selectedcheckbox = false;
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].tool_id);
    }
    this.itemSelected = false;
    this.VdiConfig_details = [];

    for (let index = 0; index < this.checkedList.length; index++) {
      this.itemSelected = true;

      this.project_selectedcheckbox = true;
      var SelectedItem = this.checkedList[index];
      let VdiConfigBoby = {
        "vdi_dropdown_id": SelectedItem,
      }
      this.VdiConfig_details.push(VdiConfigBoby);

    }
  }

  backNavigation() {
    if ((this.model.toolName == undefined || this.model.toolName == "") && (this.model.toolDescription == undefined
      || this.model.toolDescription == "")
    ) {

      this.router.navigate(["/admin-catalog-config"])


    }
    else {
      let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
      //  this.router.navigate(["/admin-catalog-config"])
      //  this.closeToolGroup()
    }
  }

  DeleteConfig() {
    if (this.editMode) {
      var deleteBody: any;
      this.check = []
      //console.log(this.VdiConfig_details)
      this.VdiConfig_details.forEach(element => {
        this.check.push(element.vdi_dropdown_id)

      });
      deleteBody = {
        "tool_ids": this.check

      }
      this.apiService.Admin_ToolGroupEditDeleteData(deleteBody).subscribe((res: any) => {
        if (res.status == 200) //doesnt work
        {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-tool-group']);
          });
          this.alertService.success(res.body.message, this.options);
          // this.alertService.success("Data Delete Successfully", this.options);
        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 446) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })


    }


  }

  deleteRecordtool(row) {
    this.Toolgrp = [];
    //console.log(row);

    var SelectedItemtool = row.ID
    let ToolgrpBody = {
      "tool_grp_id": SelectedItemtool,
    }
    this.Toolgrp.push(ToolgrpBody);
    //console.log(this.Toolgrp);

    // this.router.navigate(["/admin-catalog-config"])


  }
  DeleteCon() {
    // console.log(this.Toolgrp);

    // debugger
    var deleteBodymain: any;
    this.check = []
    for (let i = 0; i < this.Toolgrp.length; i++) {
      this.check.push(this.Toolgrp[i].tool_grp_id)
    }
    // this.Toolgrp.forEach(element => {
    //   this.check.push(element.tool_group_id)

    // });



    deleteBodymain = {
      "tool_group_ids": this.check

    }
    //console.log(deleteBodymain);


    this.apiService.Admin_ToolGroupCreateDeleteData(deleteBodymain).subscribe((res: any) => {

      if (res.status == 200) //doesnt work
      {


        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-tool-group']);
        });
        // this.alertService.success("Data Delete Successfully", this.options);
        this.alertService.success(res.body.message, this.options);
      }

    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 446) //doesnt work
      {

        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403) { }
      else if (error.error.code == "02") {
        // console.log("hi");

        // this.alertService.error(error.error.message, this.options);

        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-tool-group']);
        });
        this.alertService.error(error.error.message, this.options);
        return;
      }
      else {
        // console.log("hi2");


        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-tool-group']);
        });

        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })


  }


  cancelOK() {
    this.clearToolGroupForm();
    // debugger
    //console.log("hjhkj");
    //console.log(this.nextUrl);
    if (this.nextUrl == undefined) {
      this.refreshToolGroup()
    }
    else {
      this.router.navigate([this.nextUrl])

    }




  }

  closeToolGroup() {
    this.clearToolGroupForm();
    this.showToolGroupForm = false;
    this.editMode = false;
    this.showToolGroupMasterTable = true;
    this.refreshToolGroup()
    // this.router.navigate(["/admin-catalog-config"])
    // this.router.navigate(["/admin-tool-group"])


  }
  clearToolGroupForm() {
    this.model.toolName = "";
    this.model.toolDescription = ""
  }


  CheckToolNameDuplication() {
    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == ""
      || this.model.toolDescription == " " || this.model.toolDescription == "") {

      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      let toolGroupName = this.model.toolName;
      this.apiService.Admin_CheckToolGroupNameValidation(toolGroupName).subscribe((res: HttpResponse<any>) => {

        if (res.status == 200) {

          let code = res.body.code
          switch (code) {
            case "01":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.ToolGrSaveData();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {

        if (error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }


  ToolGrSaveData() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;


    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {


      this.saveButtonDisable = true;
      this.alertService.error("Please enter details In all the fields", this.options);

      setTimeout(() => {
        this.saveButtonDisable = false;
      }, 2500)
    }
    else {
      if (this.model.toolName.length < 3) {
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name", this.options);
        return;
      }
      if (sp.test(this.model.toolName)) {
        this.alertService.error("Name must include only alphabets ,numbers or _,-.", this.options);
        return;
      }
      if (this.model.toolName.length > 20) {
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if (this.model.toolDescription.length < 20) {
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if (this.model.toolDescription.length > 50) {
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
      if (sp.test(this.model.toolDescription)) {
        this.alertService.error("Description must include only alphabets ,numbers or _,-.", this.options);
        return;
      }

      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;



      // ##### Code for Save Funtionality ########
      saveBody = {
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by": localStorage.getItem('mail').toLowerCase()
      }

      console.log(saveBody);
      debugger;
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        //
        //console.log(res.status);
        if (res.status == 200) //doesnt work
        {

          this.refreshToolGroup();

          // this.router.navigateByUrl('/admin-scm-group', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['/admin-tool-group']);
          // });
          this.refreshToolGroup()
          this.alertService.success(res.body.message, this.options);
        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 436 || error.status == 430) //doesnt work
        {

          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403) { }
        else {

          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }
}


@Component({
  selector: 'admin-toolgroup-dialog',
  templateUrl: 'admin-toolgroup-dialog.html'
})



export class AdminToolgroupDialog { }
