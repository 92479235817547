<!-- New code-->

<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-sm-7">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
            <!-- <li class="breadcrumb-item"><a routerLink="/admin-scm-tool">SCM Tool</a></li>-->
            <li class="breadcrumb-item active" aria-current="page">Network Config VM</li>
          </ol>
        </nav>
      </div>
      <div class="col-sm-4 col-md-5 text-right">
        <div class="table-options pt-3">
          <span (click)="addVm()"><i class="fas fa-plus"></i> Add</span>
          <span  (click) = "deleteRecord()" data-toggle="modal" *ngIf="!editMode" data-target="#confirmationModal2"><i class="fas fa-trash"></i> Delete</span>

          <span (click)="refreshVm()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <a class="float-right step-back" (click)="backNavigation()">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
         
        </div>
      </div>
      <!-- <div class="col-md-1 p-md-0 my-auto">
        <a routerLink="/admin-catalog-config" class="float-right step-back">
          <i class="step-back-icon fa fa-step-backward "></i> Back</a>
      </div> -->
    </div>
    <div>
      <h1 class="text-light mb-0"><img width="40" src="assets/img/azure-vm.png">
        <div class="vertical-line mx-3"> </div> <span>VM</span>
      </h1>
    </div>

  </div>

  <div class="inner-mid-container">
      <div class="modal fade " *ngIf="itemSelected" id="confirmationModal2" tabindex="-1" role="dialog" aria-labelledby="confirmationModal2" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
              <div class="modal-header-popup">
                <span  class="vertical-line mx-3"> </span>
                 <span class="header mb-0">Network Config VM</span> 
                <span  data-dismiss="modal" class="material-icons close-icon">
                    close
                  </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                Are you sure want to Delete?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
              </div>
            </div>
          </div>
        </div>
    <div *ngIf="showVmForm" class="git-Lab-Form">
      <h2 *ngIf="!editMode">New VM Instance</h2>
      <h2 *ngIf="editMode">Update ID : {{ this.project_id_AksEdit }}</h2>
      <form (ngSubmit)="toolsGroupForm.form.valid && onSubmit()" #toolsGroupForm="ngForm">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="toolGr">Tool Group <span class="required">&#42;</span></label>
            <select id="toolGr" name="toolGr" class="form-control form-control-lg" required (change)="onChange($event)"
              [(ngModel)]="toolsGroup.toolGr" #toolGr="ngModel" [disabled]="toolGrDisable">
           <!--   <option selected>Choose Tool Group</option>-->
           <option value="" disabled selected hidden>Select Tool Group</option>
              <option *ngFor="let t of ToolGroupList" [value]="t.tool_group_id">{{t.tool_group_name}}</option>
            </select>
            <div *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
              <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="toolGr">&nbsp;</label>
            <div>
              <a class="pt-24 link-col" (click)="openDialog()">
                <u>Add New Tool Group</u>
              </a>
            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="nameInstance"> Name <span class="required">&#42;</span></label>
            <input id="nameInstance" type="text" minlength="3" maxlength="20" pattern="^[a-zA-Z0-9_-]+$" autocomplete="off" name="nameInstance" class="form-control form-control-lg" required
              [(ngModel)]="toolsGroup.nameInstance" #nameInstance="ngModel" (keydown.space)="$event.preventDefault();" onPaste="return false"  [disabled]="nameDisable">
            <div *ngIf="nameInstance.invalid && (nameInstance.dirty || nameInstance.touched)"
              class="alert alert-danger">
              <div *ngIf="nameInstance.errors.required">
                Name is required.
              </div>
              <div *ngIf="nameInstance.errors.minlength">
                Please enter minimum 3 characters for saving Tool Name
              </div>
              <div *ngIf="nameInstance.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>

            </div>
          </div>
          <div class="form-group col-md-4">
            <label for="description">Description <span class="required">&#42;</span></label>
            <input id="description" type="text" minlength="20" maxlength="50" autocomplete="off" pattern="^[a-zA-Z0-9_ -]+$" name="description" class="form-control  form-control-lg" required
              [(ngModel)]="toolsGroup.description" #description="ngModel">
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger">
              <div *ngIf="description.errors.required">
                Description is required.
              </div>
              <div *ngIf="description.errors.minlength">
                Please enter minimum 20 characters for saving Description 

            </div>
            <div *ngIf="description.invalid && !description.errors.minlength &&description.errors.pattern">
              Description must include only alphabets ,numbers or _,-.</div>

            
            </div>
          </div>
          <!--Code added for Azure Id with Details on i button-->

          <div class="form-group col-md-4">
            <label for="azureId">Azure ID <span class="required">&#42;</span></label>
            <select id="azureId" name="azureId" class="form-control form-control-lg" required [disabled]="AzuredrpDisable"
              (change)="onChangeAzureId($event)" [(ngModel)]="toolsGroup.azureId" #azureId="ngModel">
            <!--  <option selected>Select Azure ID</option>-->
            <option value="" disabled selected hidden>Select Azure ID</option>
              <option *ngFor="let s of SubscriptionList" [value]="s.tool_id">{{s.tool_id}}
              </option>
            </select>
            <div *ngIf="azureId.errors && azureId.touched" class="alert alert-danger">
              <div *ngIf="azureId.errors.required">Azure Id is required.</div>
            </div>
          </div>

          <div class="position-relative col-sm-1 pt-5 img-width">
            <span *ngIf="this.vdi_Azure_Id" style="font-size: 18px;cursor:pointer;" class="material-icons add-user"
              title="System Information" data-toggle="modal" data-target="#AzureDetailsModal">info</span>
  
  
  
            <!-- Modal -->
            <div class="modal fade pop-up" id="AzureDetailsModal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="info-header">
                    <span  class="vertical-line mx-3"> </span>
                     <span class="header mb-0"> Azure ID Details</span> 
                    <span  type="button"  data-dismiss="modal" class="material-icons close-icon">
                        close
                      </span>
                  </div>
                
                  <div class="modal-body popup-container">
                    <div class="row">
                      <div class="col-md-4">
                          <span class="field-name">Azure ID  </span>
                      </div>
                      <div class="col-md-8">
                              <span> : &nbsp;{{this.vdi_Azure_Id}}</span>
                      </div>
                    </div>
                  <div class="row">
                    <div class="col-md-4">
                        <span class="field-name">Subscription ID </span>
                    </div>
                    <div class="col-md-8">
                            <span> : &nbsp;{{this.vdi_Subscription_ID}}</span>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                      <span class="field-name">Tenant ID </span>
                  </div>
                  <div class="col-md-8">
                          <span> : &nbsp;{{this.vdi_tenantId}}</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                    <span class="field-name">Connection Name  </span>
                </div>
                <div class="col-md-8">
                        <span> : &nbsp;{{this.vdi_Azure_ConnectionName}}</span>
                </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                  <span class="field-name">Tool Group Name  </span>
              </div>
              <div class="col-md-8">
                      <span> : &nbsp;{{this.Vdi_ToolGr_Name}}</span>
              </div>
          </div>
                  
                  </div>
                
                </div>
              </div>
            </div>
          </div>

  
            <!-- Resource Group DropDown -->
            <div class="form-group col-md-4">
              <label for="rgdd">VNet Resource Group <span class="required">&#42;</span></label>
              <select id="rgdd" name="rgdd" class="form-control form-control-lg" required (change)="onChangeResourceGoup($event)"
                [(ngModel)]="toolsGroup.rgdd" #rgdd="ngModel" [disabled]="ResourceDisable">
             <!--   <option selected>Choose Resource Group</option>-->
                 <!-- @author -priyanka kale - added v.id for value and dropdwon value as a name -->
                 <option value="" disabled selected hidden>Select VNet Resource Group</option>
                <option *ngFor="let r of ResourceGroupArr"  [value]="r.name">{{r.name}}</option>
              </select>
              <div *ngIf="rgdd.errors && rgdd.touched" class="alert alert-danger">
                <div *ngIf="rgdd.errors.required">Resource Group is required.</div>
              </div>
            </div>

           <!-- Vnet DropDown -->
          <div class="form-group col-md-4">
            <label for="Vnetdd">Vnet <span class="required">&#42;</span></label>
            <select id="Vnetdd" name="Vnetdd" class="form-control form-control-lg" required (change)="onChangeVnet($event)"
              [(ngModel)]="toolsGroup.Vnetdd" #Vnetdd="ngModel" [disabled]="VnetDisable">
             <!-- <option selected>Choose Vnet Value</option>-->
             <option value="" disabled selected hidden>Select Vnet</option>
              <!-- @author -priyanka kale - added v.id for value and dropdwon value as a name -->
              <option *ngFor="let v of VnetArr" [value]="v.name">{{v.name}}</option>
            </select>
            <div *ngIf="Vnetdd.errors && Vnetdd.touched" class="alert alert-danger">
              <div *ngIf="Vnetdd.errors.required">Vnet is required.</div>
            </div>
          </div>

          
           <!-- Subnet DropDown -->
           <div class="form-group col-md-4">
            <label for="Subnetdd">Subnet<span class="required">&#42;</span></label>
            <select id="Subnetdd" name="Subnetdd" class="form-control form-control-lg" required (change)="onChangeSubnet($event)"
              [(ngModel)]="toolsGroup.Subnetdd" #Subnetdd="ngModel" [disabled]="SubDisable">
             <!-- <option selected>Choose Subnet value</option>-->
               <!-- @author -priyanka kale - added v.id for value and dropdwon value as a name -->
               <option value="" disabled selected hidden>Select Subnet</option>
              <option *ngFor="let s of SubnetArr" [value]="s.name">{{s.name}}</option>
            </select>
            <div *ngIf="Subnetdd.errors && Subnetdd.touched" class="alert alert-danger">
              <div *ngIf="Subnetdd.errors.required">Subnet is required.</div>
            </div>
          </div>

          <!-- Resource Group DropDown -->
          <div class="form-group col-md-4">
            <label for="rgvm">VM Resource Group <span class="required">&#42;</span></label>
            <select id="rgvm" name="rgvm" class="form-control form-control-lg" required (change)="onChangeVMResourceGoup($event)"
              [(ngModel)]="toolsGroup.rgvm" #rgvm="ngModel" [disabled]="ResourceDisable">
           <!--   <option selected>Choose Resource Group</option>-->
               <!-- @author -priyanka kale - added v.id for value and dropdwon value as a name -->
               <option value="" disabled selected hidden>Select VM Resource Group</option>
              <option *ngFor="let r of ResourceGroupArr"  [value]="r.name">{{r.name}}</option>
            </select>
            <div *ngIf="rgvm.errors && rgvm.touched" class="alert alert-danger">
              <div *ngIf="rgvm.errors.required">Resource Group is required.</div>
            </div>
          </div>


        </div>

      

        <div class="row">
          <div class="col-md-6">
            
          </div>
          <div class="col-md-6 text-right">

              
            <button *ngIf="!editMode" [disabled]="saveConnDisable || !toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
              (click)="CheckAksDuplication()">
              Save
            </button>
            <button *ngIf="editMode" [disabled]="!toolsGroupForm.form.valid" type="button" class="btn-canvas-blue mr-2"
              (click)="updateVm()">
              Update
            </button>
            <!-- <button [disabled]="CancelConnDisable" type="button" class="btn-canvas-blue" (click)="closeAks()">
              Cancel
            </button> -->
            <!--button class="mr-2 btn-canvas-blue" type="button" data-toggle="modal"
            data-target="#confirmationModal">Cancel</button-->
            <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!toolsGroupForm.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="toolsGroupForm.form.pristine" (click)="closeAks()">Cancel</button>

    </div>

    <!-- Modal -->
    <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
    <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
        aria-labelledby="confirmationModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
                <div class="modal-header-popup">
                    <span class="vertical-line mx-3"> </span>
                    <span class="header mb-0">VM</span>
                    <span data-dismiss="modal" class="material-icons close-icon">
                        close
                    </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                    Are you sure want to cancel?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-canvas-blue-2"
                        data-dismiss="modal">Close</button>
                    <button type="button"  [disabled]="CancelConnDisable" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                </div>
            </div>
            <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
       </div>


      </form>
    </div>
    <div class="my-5 table-container">
      <div class="table-responsive">
      <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
        <thead>
          <tr>
              <th style="width: 15px;">
                  <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                      (change)="checkUncheckAll()" />
              </th>

            <th>ID</th>
            <th>Connection Name</th>
            <!-- <th>Tool Type</th> -->
            <th>Vnet</th>
            <th>Subnet</th>
            <th>Resource Group</th>
            <th>VM Resource Group</th>
            <th>Tool Group</th>
          </tr>
        </thead>
        <tbody class="data-table-height">
          <tr *ngFor="let Aks of SCMArray">

              <td style="width: 15px;">
                  <input type="checkbox" [(ngModel)]="Aks.isSelected" name="list_name" value="{{Aks.id}}"
                      (change)="isAllSelected()" />
              </td>
            <td><span class="link-col" (click)="getRecord(Aks)">{{Aks.ID}}</span></td>
            <td>{{Aks.name}}</td>
            <td>{{Aks.Vnet}}</td>
            <td>{{Aks.Subnet}}</td>
            <td>{{Aks.ResourceGroup}}</td>
            <td>{{Aks.VMResourceGroup}}</td>
            <td>{{Aks.toolGroup}}</td>

          </tr>

        </tbody>
      </table>
    </div>
    </div>

  </div>
</div>