<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css" />
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="inner-page-container">
  <div class="inner-top-container z100">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue">
              <a (click)="goHome(secondFormGroup)">Home</a>
            </li>
            <li class="breadcrumb-item link-blue">
              <a (click)="goWorkBeanch(secondFormGroup)">Workbench</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">VDI</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <!-- <span *ngIf="isUserTM=='false' && !vdi_flag" (click)="addVdi()"><i class="fas fa-plus"></i> Add</span>
          <span *ngIf="isUserTM=='false' && vdi_flag" data-toggle="modal" data-target="#myModal"><i class="fas fa-plus"></i> Add</span> -->
          <span *ngIf="isUserTM != 'false'" class="disabled-img"><i class="fas fa-plus"></i> Add</span>

          <span *ngIf="isUserTM == 'false'" (click)="checkToolData()"><i class="fas fa-plus"></i> Add</span>
          <span id="CallModel" data-toggle="modal" data-target="#myModal"></span>
          <span *ngIf="refreshDisable" (click)="
              isUserTM == 'false'
                ? getVdiInfo(projectId, filterStatus)
                : getVDIForTM()
            "><i class="fas fa-sync-alt"></i> Refresh</span>
          <span *ngIf="!refreshDisable" class="disabled-img"><i class="fas fa-sync-alt"></i> Refresh</span>
          <!-- <span class="disabled-img"><i class="fas fa-cog"></i> Manage</span> -->
          <span class="dropdown" [ngClass]="manageDisable ? 'disabled-img' : ''">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-cog"></i>
              Manage
            </button>
            <!-- <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                  <i class="fas fa-download "></i> Export
                </a>
                <a href="#" class="nav-link" data-toggle="dropdown">
                    <i class="fas fa-ellipsis-h"></i>
                  </a> -->
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item">
                <!-- <span  class="table-crud table-edit-icon"  disabled> -->
                <!-- <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Start VDI</span> -->
                <span class="" (click)="startvdi()">
                  <img width="20" src="../../../assets/img/Start-blue.png" />
                  <span class="vertical-line-more mx-2"></span>
                  <label style="color: black; font-weight: 500">Start VDI</label>
                </span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">
                <!-- <span  class="table-crud table-save-icon"  disabled > -->
                <!-- <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop VDI </span> -->
                <span class="" (click)="stopvdi()">
                  <img width="20" src="../../../assets/img/Stop-blue.png" />
                  <span class="vertical-line-more mx-2"> </span>
                  <label style="color: black; font-weight: 500">Stop VDI</label>
                </span>
              </a>
              <!-- <div class="dropdown-divider"></div> -->
              <!-- <a class="dropdown-item"> -->
                <!-- <span  class="table-crud table-save-icon"  disabled > -->
                <!-- <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop VDI </span> -->
                <!-- <span class="" (click)="installSoftware()">
                  <img width="20" src="../../../assets/img/Ant Migration Tool.png" />
                  <span class="vertical-line-more mx-2"> </span>
                  <label style="color: black; font-weight: 500">Install Software</label>
                </span>
              </a> -->
              <!-- <a class="dropdown-item" href="#">Action</a> -->
              <!-- <a class="dropdown-item" href="#">Another action</a> -->
              <!-- <a class="dropdown-item" href="#">Something else here</a> -->
            </div>
          </span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward"></i> Back
        </span>
      </div>
    </div>
  </div>

  <div class="inner-mid-container z0">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-sm-5">
            <h1 class="mb-0">
              <img width="40" src="../../../assets/img/addVdi.png" />
              <div class="vertical-line mx-3"></div>
              <span>VDI</span>
            </h1>
          </div>
          <div class="col-md-7">
            <span class="field-name">Project ID </span><span class="field-value"> : {{ projectId }}</span>
            <span style="font-size: 18px" class="material-icons show-project-info" title="System Information"
              (click)="showProjectInfo()">info</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-2" *ngIf="this.isUserTM == 'false'">
        <div class="text-right">
          <div class="row">
            <div class="col-md-10 col-12">
              <mat-form-field class="mr-1">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="filterStatus" (selectionChange)="getStatus($event.value)">
                  <mat-option value="all">All</mat-option>
                  <mat-option value="active">Active</mat-option>
                  <mat-option value="failed">Failed</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-12">
              <div class="dropdown" *ngIf="vdiTableData.length > 0">
                <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                  <i class="fas fa-download"></i> Export
                </a>
                <div class="dropdown-menu more-drop-down">
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('pdf')">
                      <i width="20" class="fas fa-file-pdf color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> PDF</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('excel')">
                      <i width="20" class="fas fa-file-excel color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> EXCEL</span>
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('csv')">
                      <i width="20" class="fas fa-file-csv color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> CSV</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-3 w-100 border-bottom" *ngIf="showVDIForm">
      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label for="vdi">Type</label>
              <select id="vdi" name="vdi" class="form-control form-control-height" required formControlName="vdi"
                (change)="onSelectFirstDropdown(vdi.value)" [class.is-invalid]="vdi.invalid && vdi.touched">
                <option [value]="null" disabled selected hidden>
                  Select
                </option>
                <option *ngFor="let vdi of vditype" [value]="vdi">
                  {{ vdi }}
                </option>
              </select>

              <small class="text-danger" [class.d-none]="vdi.valid || vdi.untouched">VDI type is required</small>
            </div>
          </div>

          <div class="position-relative mt-4 img-width ml-3" style="top: 8px">
            <!-- <span style="font-size: 18px;" class="material-icons add-userr" title="System Information">info</span> -->
            <span></span>
          </div>

          <div class="col-md-4 col-lg-4 ml-1">
            <div class="form-group">
              <label for="toolStack">Image</label>
              <select id="toolStack" name="toolStack" class="form-control form-control-height" required
                formControlName="toolStack" [class.is-invalid]="toolStack.invalid && toolStack.touched"
                (change)="onSelectSecondDropdown(toolStack.value)">
                <option [value]="null" disabled selected hidden>
                  Select
                </option>
                <option disabled *ngIf="emptyvdiImageList">
                  No Instance Available
                </option>
                <option *ngFor="let role of vdiImageList" [ngValue]="role">
                  {{ role.tool_instance_name }}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="toolStack.valid || toolStack.untouched">Tool Stack is
                required</small>
            </div>
          </div>

          <div class="position-relative mt-4 img-width ml-1" style="top: 8px">
            <span style="font-size: 18px" class="material-icons add-user" title="System Information"
              (click)="showImageInfo('imageInfo', 'Dummy')">info</span>
          </div>

          <div class="col-md-4 col-lg-4 ml-1">
            <div class="form-group">
              <label for="location">Location</label>
              <select formControlName="location" id="location" name="location" class="form-control form-control-height"
                [class.is-invalid]="location.invalid && location.touched">
                <option [value]="null" disabled selected hidden>
                  Select 
                </option>
                <option [ngValue]="location" *ngFor="let location of locationVal">
                  {{ location.display_name }}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="location.valid || location.untouched">Location is
                required</small>
            </div>
          </div>

          <div class="col-md-4 col-lg-3">
            <div class="form-group">
              <label for="network_config_vdi">Network Config</label>
              <select id="network_config_vdi" name="network_config_vdi" class="form-control form-control-height"
                required formControlName="network_config_vdi" [class.is-invalid]="
                  network_config_vdi.invalid && network_config_vdi.touched
                ">
                <option [value]="null" disabled selected hidden>
                  Select
                </option>
                <option disabled *ngIf="emptyvdiImageList">
                  No Instance Available
                </option>
                <option *ngFor="let role of networkConfigList" [ngValue]="role">
                  {{ role.name }}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="
                  network_config_vdi.valid || network_config_vdi.untouched
                ">Network Config is required</small>
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style="top: 8px">
            <span style="font-size: 18px" class="material-icons add-user" title="VM Image Information" (click)="
                showVMSizeInfo(network_config_vdi.value, 'networkConfig')
              ">info</span>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="form-group">
              <label for="VMSize">Size</label>
              <!-- <span class="material-icons show-vm-info" title="VM Size Information" (click)="showVMSizeInfo()">info</span> -->
              <select formControlName="VMSize" (change)="selectedValue(VMSize)" id="VMSize" name="VMSize"
                class="form-control form-control-height" [class.is-invalid]="VMSize.invalid && VMSize.touched">
                <option [value]="null" disabled selected hidden>
                  Select
                </option>
                <option [ngValue]="vm" *ngFor="let vm of VMSizeVal">
                  {{ vm.display_name }}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="VMSize.valid || VMSize.untouched">VDI Size is required</small>
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style="top: 8px">
            <span style="font-size: 18px" class="material-icons add-user" title="VDI Size Information"
              (click)="showVmInfo('vmsize', VMSize.value.property_value)">info</span>
          </div>

          <div class="col-md-4 col-lg-4">
            <div class="form-group">
              <label for="vmDiskType">Disk Type</label>
              <select formControlName="vmDiskType" id="vmDiskType" name="vmDiskType"
                class="form-control form-control-height" [class.is-invalid]="vmDiskType.invalid && vmDiskType.touched">
                <option [value]="null" disabled selected hidden>
                  Select
                </option>
                <option [ngValue]="disk" *ngFor="let disk of diskTypeVal">
                  {{ disk.display_name }}
                </option>
              </select>
              <small class="text-danger" [class.d-none]="vmDiskType.valid || vmDiskType.untouched">VDI Disk Type is
                required</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="text-center offset-lg-8 col-md-1 mt-4 mr-md-3 img-width" *ngIf="secondFormGroup.valid">
            <span class="material-icons add-user-icon-one" (click)="openUser()" title="Add User">
              person_add
            </span>
          </div>
          <div class="text-center offset-lg-8 col-md-1 mt-4 mr-md-3 img-width" *ngIf="!secondFormGroup.valid">
            <span class="material-icons add-user-icon-one-disabled" title="Add User">
              person_add
            </span>
          </div>

          <div class="col-md-1 mt-4 mr-md-3">
            <button mat-button (click)="confirmationPopup()" mat-raised-button
              [disabled]="!secondFormGroup.valid || !selectedUserArray.length" class="hover vdi-button width-100"
              title="Assign VDI">
              Create
            </button>
          </div>
          <div class="col-md-1 mt-4">
            <button type="button" class="btn-canvas width-100 btn-canvas-blue-2"
              (click)="toggleDisplay(secondFormGroup)">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="row userRow" *ngIf="showUserRow">
      <div class="col-md-1" *ngFor="let user of selectedUserArray; let i = index">
        <span class="close" (click)="removeUser(user, i)" title="Remove User">x</span>
        <img src="../../../assets/img/UserIcon.png" /><br />
        {{ user.user_name }}
      </div>
    </div>

    <!-- <div class="mt-2"> -->
    <div class="table-responsive scrollbar">
      <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="vdiTableData.length > 0">
        <thead>
          <tr>
            <th style="width: 15px">
              <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1"
                (change)="checkUncheckAll()" />
            </th>
            <th>Request Id</th>
            <th>VDI Type</th>
            <th>VM Name</th>
            <th>Status</th>
            <th class="w15">Members</th>
            <th>Scheduler</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let val of vdiTableData; let i = index">
            <td class="pl-3" style="width: 15px" *ngIf="
                (isUserTM == 'true' &&
                  val.vdi != 'Shared' &&
                  (val.status == 'Running' || val.status == 'Stopped')) ||
                (isUserTM == 'false' &&
                  (val.status == 'Running' || val.status == 'Stopped'))
              ">
              <input type="checkbox" [(ngModel)]="val.isSelected" name="list_name" value="{{ val.reqId }}"
                (change)="isAllSelected()" />
            </td>
            <td class="pl-3" style="width: 15px" *ngIf="
                (isUserTM == 'true' && val.vdi == 'Shared') ||
                (isUserTM == 'true' &&
                  val.vdi != 'Shared' &&
                  val.status != 'Running' && val.status != 'Stopped') ||
                (isUserTM == 'false' &&
                  val.status != 'Running' && val.status != 'Stopped')
              ">
              <input type="checkbox" [disabled]="checkDisable" [(ngModel)]="val.isSelected" name="list_name"
                value="{{ val.reqId }}" (change)="isAllSelected()" />
            </td>
            <td class="" *ngIf="val.status != 'Moved_to_Recycle'">
              {{ val.reqId }}
              <span style="font-size: 18px" class="material-icons add-user" title="System Information"
                (click)="showImageInfo('vdiInfo', val)">info</span>
            </td>
            <td class="softDelete-cell" *ngIf="val.status == 'Moved_to_Recycle'">
              {{ val.reqId }}
              <span style="font-size: 18px; color: gray" class="material-icons add-user" title="System Information"
                (click)="showImageInfo('vdiInfo', val)">info</span>
            </td>
            <td [ngClass]="
                val.status == 'Moved_to_Recycle' ? 'softDelete-cell' : ''
              ">
              {{ val.vdi == "Shared" ? "Pooled" : "Personal" }}
            </td>
            <td [ngClass]="
                val.status == 'Moved_to_Recycle' ? 'softDelete-cell' : ''
              ">
              {{ val.name_prefix ? val.name_prefix : "--NA--" }}
            </td>
            <td *ngIf="val.status != 'Moved_to_Recycle'">{{ val.status }}</td>
            <td class="softDelete-cell" *ngIf="val.status == 'Moved_to_Recycle'">
              Moved to Recycle
            </td>
            <td class="w15" *ngIf="val.status != 'Moved_to_Recycle'">
              <h4 *ngIf="
                  val.status == 'Removed' ||
                  val.status == 'Pending' ||
                  val.status == 'Deleted' ||
                  val.status == 'Failed'
                ">
                --NA--
              </h4>
              <h4 *ngIf="
                  val.members.length == 0 &&
                  (val.status == 'Running' ||
                    val.status == 'Processing' ||
                    val.status == 'Stopped' ||
                    val.status == 'Stopping')
                ">
                --NA--
              </h4>
              <button *ngIf="
                  val.members.length > 1 &&
                  val.status != 'Removed' &&
                    val.status != 'Deleted' &&
                    val.status != 'Failed' &&
                    val.status != 'Pending'
                " class="view-member-btn" (click)="openUserDialog(val.members)">
                View
              </button>
              <div class="" class="w15" *ngIf="val.members.length == 1">
                <span class="img__wrap1 userRowOne" *ngFor="let user of val.members">
                  <h4 *ngIf="
                      val.status == 'Running' ||
                      val.status == 'Processing' ||
                      val.status == 'Stopped' ||
                      val.status == 'Stopping' ||
                      val.status == 'Starting'
                    ">
                    {{ user.name }}
                  </h4>
                </span>
              </div>
            </td>
            <td *ngIf="val.status == 'Moved_to_Recycle'" class="softDelete-cell">
              <h4 *ngIf="
                  val.members.length == 0 && val.status == 'Moved_to_Recycle'
                ">
                --NA--
              </h4>
              <button *ngIf="
                  val.members.length > 1 && val.status != 'Moved_to_Recycle'
                " class="view-member-btn-inactive" (click)="openUserDialog(val.members)">
                View
              </button>
              <div class="" *ngIf="val.members.length == 1">
                <span class="img__wrap1 userRowOne" *ngFor="let user of val.members">
                  <h4 *ngIf="val.status == 'Moved_to_Recycle'">
                    {{ user.name }}
                  </h4>
                </span>
              </div>
            </td>
            <td [ngClass]="
                val.status == 'Moved_to_Recycle' ? 'softDelete-cell' : ''
              ">
              <mat-slide-toggle [disabled]="
                  (isUserTM == 'true' && val.vdi == 'Shared') ||
                  val.status == 'Pending' ||
                  val.status == 'Processing' ||
                  val.status == 'Removed' ||
                  val.status == 'Failed' ||
                  val.status == 'Moved_to_Recycle' ||
                  (isUserSM == 'true' && val.vdi == 'Shared') ||
                    val.status == 'Pending' ||
                    val.status == 'Processing' ||
                    val.status == 'Removed' ||
                    val.status == 'Failed' ||
                    val.status == 'Moved_to_Recycle' ||
                  (isUserSM == 'true' &&
                    val.vdi == 'Dedicated' &&
                    val.members[0].email[0] != userMailId) ||
                    val.status == 'Pending' ||
                    val.status == 'Processing' ||
                    val.status == 'Removed' ||
                    val.status == 'Failed' ||
                    val.status == 'Moved_to_Recycle'
                " [(ngModel)]="isChecked[i]" [color]="color" (click)="onToggleChange2($event, i, val)">
              </mat-slide-toggle>
              <!-- <mat-slide-toggle
                class="example-margin"
                [(ngModel)]="checkedBtn[i]"
                (change)="updateActiveStatus(i)"

                [disabled]="val.status=='Processing'">
                {{val.flag}}
             
            </mat-slide-toggle>             -->
            </td>
            <td *ngIf="val.status != 'Moved_to_Recycle'">
              <div class="dropdown">
                <a href="#" class="nav-link" data-toggle="dropdown">
                  <i class="fas fa-ellipsis-h"></i>
                </a>

                <div class="dropdown-menu more-drop-down" style="max-height: 145px;overflow-y:auto">
                  <span *ngIf="val.vdi == 'Shared'">
                    <a class="dropdown-item">
                      <span class="table-crud table-edit-icon" (click)="addMemberToVdi(val, 'add')" *ngIf="
                          (val.status == 'Running' ||
                            val.status == 'Stopped') &&
                          isUserTM == 'false'
                        ">
                        <img width="20" src="../../../assets/img/AddMember.png" />
                        <span class="vertical-line-more mx-2"></span> Add
                        Member</span>
                      <span class="table-crud table-edit-icon" *ngIf="
                          (val.status != 'Running' &&
                            val.status != 'Stopped') ||
                          isUserTM == 'true'
                        " disabled>
                        <img width="20" src="../../../assets/img/AddMember-disabled.png" />
                        <span class="vertical-line-more mx-2"></span> Add
                        Member</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">
                      <span class="table-crud table-edit-icon" (click)="addMemberToVdi(val, 'delete')"
                        *ngIf="(val.status == 'Running' || val.status =='Stopped') && isUserTM == 'false'">
                        <img width="20" src="../../../assets/img/AddMember.png" />
                        <span class="vertical-line-more mx-2"></span> Delete Member</span>
                      <span class="table-crud table-edit-icon" *ngIf="(val.status != 'Running' && val.status != 'Stopped') || isUserTM == 'true'"
                        disabled>
                        <img width="20" src="../../../assets/img/AddMember-disabled.png" />
                        <span class="vertical-line-more mx-2"></span> Delete Member</span>
                    </a>
                    <div class="dropdown-divider" *ngIf="isUserTM == 'false'"></div>
                  </span>

                  <!-- <a class="dropdown-item">
                  <span  class="table-crud table-edit-icon" *ngIf="(!val.startStatus) || (isUserTM == 'true' && val.vdi == 'Shared')" disabled>
                    <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Start VDI</span>
                  <span  class="table-crud table-edit-icon"  *ngIf="(val.startStatus) && (val.vdi == 'Dedicated' || isUserTM == 'false')" (click)="startVdi(val.reqId)">
                    <img width="20" src="../../../assets/img/Start-blue.png"> <span class="vertical-line-more mx-2"></span> Start VDI</span>
                  </a> -->
                  <!-- <div class="dropdown-divider"></div> -->
                  <!-- <a class="dropdown-item">
                    <span  class="table-crud table-save-icon" *ngIf="(!val.stopStatus) || (isUserTM == 'true' && val.vdi == 'Shared')" disabled >
                      <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop VDI </span>
                    <span  class="table-crud table-save-icon" *ngIf="val.stopStatus  && (val.vdi == 'Dedicated' || isUserTM == 'false')" (click)="stopVdi(val.reqId)">
                      <img width="20" src="../../../assets/img/Stop-blue.png"> <span class="vertical-line-more mx-2"></span> Stop VDI</span>
                  </a> -->
                  <!-- <div class="dropdown-divider" *ngIf="isUserTM == 'false'"></div> -->
                  <a class="dropdown-item" *ngIf="isUserTM == 'false'">
                    <span class="table-crud table-delete-icon" disabled *ngIf="!val.deleteStatus">
                      <img width="20" src="../../../assets/img/Delete-Deactivate.png" />
                      <span class="vertical-line-more mx-2"></span>Move to
                      Recycle</span>
                    <span class="table-crud table-delete-icon" *ngIf="val.deleteStatus"
                      (click)="deleteconfirmationPopUp(val)">
                      <img width="20" src="../../../assets/img/Delete-blue.png" />
                      <span class="vertical-line-more mx-2"></span>Move to
                      Recycle</span>
                  </a>
                  <div class="dropdown-divider"></div>

                  <a class="dropdown-item">
                    <span class="table-crud table-delete-icon" disabled *ngIf="
                        val.custom_scheduler == 'OFF' ||
                        (this.isUserTM == 'true' && val.vdi == 'Shared') ||
                          (this.isUserSM == 'true' && val.vdi == 'Shared')
                          ||
                          (this.isUserSM=='true' && val.vdi=='Dedicated' && val.members[0].email[0]!=userMailId)
                      ">
                      <img width="20" src="../../../assets/img/pencil-de.png" />
                      <span class="vertical-line-more mx-2"></span>View
                      Scheduler</span>
                    <span class="table-crud table-delete-icon" *ngIf="(
                      val.custom_scheduler=='ON' 
                      &&
                      (
                      (((this.isUserTM == 'true' && val.members[0].email[0]==userMailId)) && val.vdi == 'Dedicated')
                      ||
                      (((this.isUserSM == 'true' && val.members[0].email[0]==userMailId)) && val.vdi == 'Dedicated')

                      || 
                      (this.isUserTM == 'false'  && this.isUserSM=='false')
                      )
                                  
                      )" (click)="viewSchedularConfirmation(val)">
                      <img width="20" src="../../../assets/img/pencil.png" />
                      <span class="vertical-line-more mx-2"></span>View
                      Scheduler</span>
                  </a>
                  <!-- // commenting  install and uninstall  -->
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span  class="table-crud table-delete-icon" *ngIf="val.status=='Running'" (click)="installSoftwareVdi(val.ip, val.name_prefix,val.vdi)">
                      <img width="20" src="../../../assets/img/Ant Migration Tool.png"> <span class="vertical-line-more mx-2"></span>Install Software</span>
                      <span  class="table-crud table-delete-icon" *ngIf="val.status!='Running'" disabled>
                        <img width="20" src="../../../assets/img/AntMigrationDisabled.png"> <span class="vertical-line-more mx-2"></span>Install Software</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item">
                      <span  class="table-crud table-delete-icon" *ngIf="val.status=='Running'" (click)="uninstallSoftwareVdi(val.ip, val.name_prefix,val.vdi)">
                        <img width="20" src="../../../assets/img/restore.png"> <span class="vertical-line-more mx-2"></span>Uninstall Software</span>
                        <span  class="table-crud table-delete-icon" *ngIf="val.status!='Running'" disabled>
                          <img width="20" src="../../../assets/img/restore-disabled.png"> <span class="vertical-line-more mx-2"></span>UnInstall Software</span>
                      </a>
                   
                </div>
              </div>
            </td>
            <td *ngIf="val.status == 'Moved_to_Recycle'" class="softDelete-cell">
              <a href="#" class="nav-link" data-toggle="dropdown" *ngIf="val.status == 'Moved_to_Recycle'">
                <i class="fas fa-ellipsis-h" style="color: rgba(0, 0, 0, 0.86)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <table datatable class="table fixed-table" [dtOptions]="dtOptions" *ngIf="vdiTableData.length == 0">
        <thead>
          <tr>
            <th>Request Id</th>
            <th>VDI Type</th>
            <th>VM Name</th>
            <th>Status</th>
            <th>Members</th>
            <th>Scheduler</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <!-- </div> -->
  </div>
</div>

<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content modal-adjust">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <h2 class="text-center">No tool instance Available</h2>
      </div>
      <div class="modal-header"></div>
    </div>
  </div>
</div>