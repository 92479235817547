<div class="pop-up" *ngIf="installPopup">
    <div class="row">
        <div class="col-sm-6 pt-3">
          <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Install Software</span> 
        </div>
        <div class="col-sm-4">
          <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="60%">
              <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-2 pt-3">
            <span (click)="exit()" class="material-icons close-icon">
                close
              </span>
        </div>
       
      </div>
      <hr class="margin-class" />
      <div class="popup-container justify-content-center">
  
      <div class="example-container1" >
        <h1 *ngIf="!getList">Loading...</h1>

        <table *ngIf="getList" mat-table [dataSource]="dataSource">
    
    
          <!-- Name Column -->
          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> <span><img width="30"  src="assets/software_icons/{{element.software_name}}.jpg"> </span></td>
          </ng-container>
    
          <!-- Email Column -->
          <ng-container matColumnDef="software_name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.software_name}} </td>
          </ng-container>
                <!-- Email Column -->
        <ng-container matColumnDef="software_version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let element"> {{element.software_version}} </td>
            </ng-container>
                    <!-- Email Column -->
          <!-- <ng-container matColumnDef="software_ritm">
            <th mat-header-cell *matHeaderCellDef> RITM </th>
            <td mat-cell *matCellDef="let element"> {{element.software_ritm}} </td>
          </ng-container> -->
                <!-- Title Column -->
      <ng-container matColumnDef="software_ritm">
        <th mat-header-cell *matHeaderCellDef> RITM </th>
        <td mat-cell *matCellDef="let element;" >
            <mat-form-field >
                <!-- <mat-label>RITM</mat-label> -->
                <!-- <input matInput [value]="input.value" #input> -->
                <input  [(ngModel)]="element.software_ritm" matInput type="text" />
  
                <!-- <input matInput placeholder="Insert your text" #input> -->
                <!-- <p>{{input.value}}</p> -->
  
            </mat-form-field>
        </td>
      </ng-container>
  
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (click)="allSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]=true [aria-label]="checkboxLabel(row)"> 
                      </mat-checkbox>
            
                      <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
  
          </tr>
        </table>
    
    
      </div>
      </div>
  
      <div class="row mt-4" >
        <div class="col-6 offset-md-7">
    
          <button mat-button *ngIf="getList"  mat-raised-button class="add-button" (click)="softwareDetails()" >Submit</button>
          <button mat-button *ngIf="getList"  mat-raised-button class="add-button ml-3"  (click)="requestDetails()" >+Request Software</button>
  
        </div>
      </div>
    </div>
  
  
    <div class="pop-up" *ngIf="requestPopup">
      <div class="row">
        <div class="col-sm-6 pt-3">
          <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Image Software</span> 
        </div>
        <div class="col-sm-4">
          <div fxLayout fxLayoutAlign="center center">
            <!-- <mat-form-field fxFlex="60%">
              <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
            </mat-form-field> -->
          </div>
        </div>
        <div class="col-sm-2 pt-3">
            <span (click)="exit()" class="material-icons close-icon">
                close
              </span>
        </div>
       
      </div>
      <hr class="margin-class" />
      <div class="popup-container justify-content-center">
    
      <div class="example-container1" style="overflow-x: hidden;" >
        <form class="example-form" [formGroup]="userForm">
       
          <div  *ngFor="let group of addressArray.controls; let i = index;" formArrayName="software_request">
            <div class="row" [formGroupName]='i'>
              <div class="col-3">
                <input class="form-control mb-3" placeholder="Software Name" value="" formControlName="software_name" > 
    
              </div>
    
              <div class="col-3">
                <input class="form-control mb-3" placeholder="Version" value="" formControlName="software_version" >
    
              </div>
              <div class="col-3">
                <input class="form-control mb-3" placeholder="RITM" value="" formControlName="ritm" >
    
              </div>
              <div class="col-3">
                <button *ngIf="i>0" class="btn btn-danger" (click)="removeAddress(i)">Remove</button>
    
              </div>
    
              <!-- <input class="form-control mb-3" placeholder="State" value="" formControlName="state"> -->
            
            </div>
          </div>
          <!-- <button mat-button  mat-raised-button class="add-button-software"  (click)="addAddress()" >Add Software</button> -->
          
          <div class="row mb-2">
            <div class="col-3">
              <mat-error *ngIf="requestFormNameErrorFlag">
                Please enter Software name
              </mat-error>
            </div>
            <div class="col-3">
              <mat-error *ngIf="requestFormVersionErrorFlag">
                Please enter Software version
              </mat-error>
            </div>
            <div class="col-3">
              <mat-error *ngIf="requestFormRitmErrorFlag">
                Please enter RITM 
              </mat-error>
            </div>
          </div>
    
    
          <div class="form-row org-desc-parent-margin">
            <button class="btn btn-primary ml-3" style="background: #272283" (click)="addAddress()">Add Software</button>
    
           
           </div>
      </form>
      <!-- <p>{{userForm.value | json}}</p> -->
    
    
      </div>
      </div>
      <!-- <hr class="margin-class" /> -->
    
      <div class="row mt-4" >
        <div class="col-12 mb-2" style="text-align: right;">
          <hr class="margin-class" />
    
          <!-- <button mat-button  mat-raised-button class="add-button" (click)="softwareDetails()" >+ Install Software</button> -->
          <!-- <button mat-button  mat-raised-button class="add-button ml-3" (click)="addRequestSoftware()">+Request Software</button> -->
          <button mat-button mat-raised-button class="add-button ml-3" (click)="addRequestSoftware()"
          *ngIf="this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_name !=null &&
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_name !='' &&
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_version!='' &&
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_version!=null &&
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].ritm!='' &&
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].ritm!=null">Request
            Software</button>
          <button mat-button mat-raised-button class="add-button-disabled ml-3" 
          *ngIf="this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_name ==null ||
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_name =='' ||
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_version=='' ||
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].software_version==null ||
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].ritm=='' ||
          this.userForm.value.software_request[this.userForm.value.software_request.length-1].ritm==null">Request
            Software</button>
    
        </div>
      </div>
    
    </div>