<div class="scm-tool-container">
  <h2 mat-dialog-title style="color: #00529C;">SCM Tools</h2>
  <mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-6 text-center">
          <a routerLink="/admin-gitlab" mat-dialog-close>
              <img src="assets/img/gitlab-logo.png" title="Git Lab" class="img-fluid">
              <div class="d-block mt-3">
                  <button mat-raised-button>Git lab</button> 
              </div>            
          </a>
        </div>
        <div class="col-md-6 text-center">
          <a routerLink="/admin-github" mat-dialog-close>
              <img src="assets/img/github-logo.png" title="GitHub" class="img-fluid">
              <div class="d-block mt-3">
                <button mat-raised-button>GitHub</button>
            </div>
          </a>
        </div>
    </div>
  </mat-dialog-content>
</div>
<!-- <div class="admin-home-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="canvas-card py-5">


    <div>
       <div class="col-md-2 mt-5 text-center">
                  <img src="assets/img/project-role.png" class="img-fluid project-role-img">
                </div> 
      <div>
        <div class="text-left table-crud-icons">
          <img class="img-fluid mr-2" src="assets/icon/menu/scm-tool-icon.png" />
          <span style="font-size: 20px;" class="add-text">SCM Tool</span>
        </div>
        <div class="text-right table-crud-icons">
           <span class="material-icons">save</span>     
                    <span class="material-icons">edit</span> 

          <span class="material-icons">delete</span>
          <span (click)="openDialog()" style="cursor: pointer;">
            <span class="material-icons last">add</span> <span (click)="setFormMode()" class="add-text">Add</span>
          </span>
        </div>

        <div class="my-5 table-container">
          <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table fixed-table">
            <thead>
              <tr>
                <th style="width: 15px;"><input type="checkbox"></th>
                <th>ID</th>
                <th>Instance Name</th>
                <th>Tool Type</th>
                <th>Instance URL</th>
                <th>Tool Group</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let SCM of SCMArray">
                <td style="width: 15px;"><input type="checkbox"></td>
                <td><span class="link-col" (click)="getRecord(SCM)">{{SCM.ID}}</span></td>
                <td>{{SCM.name}}</td>
                <td>{{SCM.toolName}}</td>
                <td>{{SCM.projectURL}}</td>
                <td>{{SCM.toolGroup}}</td>
              </tr>

            </tbody>
          </table>
        </div>


      </div>
    </div>

  </div>
</div> -->