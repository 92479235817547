import { OnInit, Inject,Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from 'src/app/service/api.service';
import { WorkBenchComponent } from '../work-bench/work-bench.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { SCMMembers } from '../scm-tool/scm-tool.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/_alert';
import { Router } from '@angular/router';
import { DialogProjectInfoDialog } from '../vdi-details/vdi-details.component';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-project-roles',
  templateUrl: './project-roles.component.html',
  styleUrls: ['./project-roles.component.scss']
})


export class ProjectRolesComponent implements OnInit {
  projectId: any;
  isUserSM: boolean =false;
  flagValueSM:string;
  project_id:string;
  roles_assigned:any;
  userMailId: string;
  isUserTM: boolean =false;
  flagValue:string;

  public unique_key: number;
  public parentRef: WorkBenchComponent;
  projectName: any;
  showAddRole : boolean = false
  secondFormGroup: FormGroup;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
    dtTrigger =  new Subject();
  memberString = [];
  private footerLogoPath = environment.footerLogoPath;
  footerimageSrc:any;
  footerimageAlt:any;

  configList: any=  []
  catalog_id: string;
  catalog_name: string;
  selectedUserArray: any = [];
  showUserRow: boolean;
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  showTable: boolean = false;
  tableArr = [];
  roleArr: any =[];
  selectRoleArray : any = []
  opsArr: any = [];
  template_id: string;
  projectCreatedBy: string;
  profile: string;
  roleDataTable = [];
  addRoleDisable: boolean=true;
  addRoleFlagOne: any;
  addRoleFlagSecond: any;
  addRoleDisableSecond: boolean=false;

  constructor(public apiService : AuthService,private _formBuilder: FormBuilder,public dialog: MatDialog, 
    public alertService: AlertService, public router :Router,private spinner: NgxSpinnerService) {
   this.footerimageSrc = this.footerLogoPath;  
   this.footerimageAlt = 'Footer';
     }

  ngOnInit() {
    this.dtOptions = {
      searching: true,
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // processing: true,
      order:[]
    };
    this.dtOptions1 = {
      searching: true,
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      order:[]
    }
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){

    this.alertService.yesClickedEvent
    .subscribe((data:string) => {
      this.showAddRole = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.secondFormGroup.reset();
    });
    //////////////////////////////////////////////////////////////////////////////////////////////
    this.projectId=localStorage.getItem('projectId');
    this.addRoleDisableSecond=false
    this.isUserSM = false;
    this.flagValueSM="false";

    localStorage.setItem('isUserSM',this.flagValueSM);
    let userPsNumber = "";
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })

    //just replace ID_002 with projectId bs name
    
      
      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        
        this.addRoleFlagOne=project[0].roles_assigned.length
        this.project_id = project[0].project_id;

        this.roles_assigned = project[0].roles_assigned;
        // console.log(this.roles_assigned);
        

      this.roles_assigned.forEach(element => {
        if(element.role_name =="Project Manager"){
        
          localStorage.setItem('projectCreatedBy', element.members[0].user_name);
         
        }
      });

      project[0].members.forEach(element => {
        this.userMailId = localStorage.getItem("mail").toLowerCase();
        if(element.user_email == this.userMailId){
            for(let i = 0; i<element.role_name.length; i++){

            if(element.role_name[i] =="Secondary Project Manager"){
              this.isUserSM = true;
              this.flagValueSM="true";
          
              localStorage.setItem('isUserSM',this.flagValueSM);

            }
            
            if(element.role_name[i] != "Project Manager" && element.role_name[i] != "Secondary Project Manager" && element.role_name[i] != "PM"){
              this.isUserTM = true;
              this.flagValue="true";
          
              localStorage.setItem('isUserTM',this.flagValue);
            }
            else{
              this.isUserTM = false;
               
              this.flagValue="false";
              
              localStorage.setItem('isUserTM',this.flagValue);
              break;
            }
          };
           //Disable icons in workbench

          let reqBody = {
            "project_id": this.projectId,
            "user_role_name": this.isUserTM ? 'Team Member' : 'Project Manager'
          }
          
          // this.apiService.getIconsForDisable(reqBody).subscribe((data:any)=>{
          //   this.summary_icons = data.icons;
          // })
        }
      });
        localStorage.setItem('catalogId',project[0].catalog_id);
        localStorage.setItem('projectName',project[0].project_name);
        localStorage.setItem('catalogName',project[0].catalog_name);
        localStorage.setItem('template_id', project[0].template_id);
        localStorage.setItem('template_name', project[0].template_name);
        this.template_id = localStorage.getItem("template_id");
        this.apiService.getTemplateRoles(this.template_id).subscribe((data: any)=> {
          this.configList = data;  
          this.addRoleFlagSecond=data.length
          if(this.addRoleFlagOne == this.addRoleFlagSecond){
            this.addRoleDisableSecond=true
          }

          
          this.getRoles()    
        })
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })



    /////////////////////////////////////////////////////////////////////////////////////////

    this.alertService.workbenchYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/workBench'])
    });

    this.alertService.homeYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/projectSummary'])
    });

    this.apiService.componentName.next("Role");

    this.projectCreatedBy = localStorage.getItem('projectCreatedBy');
    this.profile=localStorage.getItem('profile');
    
    this.projectId=localStorage.getItem('projectId');

    // this.apiService.projectName.subscribe(projectName => {
    //   this.projectName = projectName;
    // })
    this.projectName=localStorage.getItem('projectName');

    // this.apiService.catalog_id.subscribe(id => {
    //   this.catalog_id = id;
    // })
    this.catalog_id=localStorage.getItem('catalogId');
    // this.apiService.catalog_name.subscribe(name => {
    //   this.catalog_name = name;
    // })
    this.catalog_name=localStorage.getItem('catalogName');

    this.secondFormGroup = this._formBuilder.group({
      roleType: ['', Validators.required]
    });



    
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }

  // console.log(localStorage);
  
  }

  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  getRoles(){
    // this.dtOptions = {
    //   searching: true
    // };
    this.spinner.show();
    this.tableArr = [];
    this.opsArr = this.configList
    this.apiService.getCatalogProjectById(this.projectId).subscribe((roleData: any) => {
      
      if(roleData[0].roles_assigned.length == 1 && roleData[0].members.length>1) {
        // console.log("hi");
        
        
                  
        
                  // if(!this.isUserTM){
                  //   // debugger
                    // this.alertService.error('You have not assigned any role yet for this project.', this.options);
                    const dialogRef = this.dialog.open(ModalComponent, {
                      width: '30%',
                      height: '27%',
                      disableClose: true,
                      // id:req_id,
                   
                     
                    });
        
                
                  // }
        
              }

     if(roleData[0].roles_assigned.length == 0 ){
       
     }
     else{
      this.selectRoleArray = []
      this.roleArr = roleData[0].roles_assigned;

      this.roleArr.forEach((element,indexOne) => {
        for(var i =0;i< this.configList.length;i++){
          if(element.template_role_name == this.configList[i].template_role_name){
           
            this.configList[i]['duplicate']="true";
            break
          }else{
          }
        }
      });

      this.configList.forEach((object,index)=>{
        if(object.duplicate == "true"){
          
        }
        else{
          
          this.selectRoleArray.push(object)
        }
      })
     
      this.roleArr.forEach(element => {
        // if(element.role_name == "Project Manager"){
        //   console.log(element);
          
        // }
        // else{
          //this.tableArr = this.roleArr
         
          this.tableArr.push(element);
          this.roleDataTable = this.tableArr;
          this.dtTrigger.next()
          this.spinner.hide();
          this.showTable=true;
        // }
      });
      console.log(this.tableArr);
      
      if(this.tableArr.length==0){
            this.spinner.hide();
          }
    }
    
    }, (error: HttpErrorResponse) => {
        this.spinner.hide();
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

  get roleType() {
    
    return this.secondFormGroup.get('roleType');

  }
  showAddRoleMethod(){

this.selectedUserArray = [];
// this.apiService.selectedRoleMember.next(["bkl"])
 this.apiService.selectedRoleMember.next([])
 this.showAddRole = true;
  }

    //Cancel click
    toggleDisplay(secondFormGroup) {
      if(secondFormGroup.pristine == false){
      this.cancelconfirmationPopUp();
      } else {
        this.showAddRole = false;
        this.showUserRow = false;
        this.selectedUserArray = [];
      }
   
    }

   // confirmation popup
   cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(rolesCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
     
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.scmworkbenchConfirmationPopup();
      } else {
        this.router.navigate(['/workBench'])
      }
  }
  goHome(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.homeConfirmationPopup();
      } else {
        this.router.navigate(['/projectSummary'])
      }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(rolesWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe( data => {
      }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(rolesHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {   
      }
    );
  }


  addRowApi() {
   // this.getRoles()
    this.showAddRole = false;

    let memberArrayToSend = []
   this.showTable = true;
    this.showUserRow = false;
    this.memberString = [];

    this.selectedUserArray.forEach((user: any) => {
      let membObj = {
        name: user.user_name,
        email: user.user_email,
        role: user.role_name
      }
      this.memberString.push(membObj)
      let memberObj = {
        "user_name": user.user_name,
        "user_email": user.user_email,
        
      }
      memberArrayToSend.push(memberObj)
    })

    let roleBody = {
      "project_id":  this.projectId,
      "roles_assigned" : [
        {
          "role_id":"",
          "role_name":  this.roleType.value.role_name,
          "template_role_name": this.roleType.value.template_role_name,
          "privilege_access" : this.roleType.value.privilege_access,
          "members": memberArrayToSend
        }
       
      ],
      "created_by":localStorage.getItem('mail').toLowerCase(),
      "updated_by":localStorage.getItem('mail').toLowerCase(),


    }    
      
    this.apiService.addNewRole(roleBody).subscribe((roleResult: any) => {
     // if (roleResult[0].Status == "Role added") {
    
    this.secondFormGroup.reset()
      //  this.alertService.success('Role Added Successfully', this.options)
        this.alertService.success(roleResult.message, this.options)
        this.spinner.show();
        setTimeout(() => {
           this.getRoles()
        }, 2000);
        this.addRoleDisable = false;
        setTimeout(() => {
          this.addRoleDisable = true;
        }, 2000)
        this.ngOnInit()
        this.spinner.hide();
       
    //  }
    }, (error: HttpErrorResponse) => {
      if (error.status == 433){
        this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        this.alertService.error(" Invalid Input ", this.options);
        throw error;
       }
      
       })

    this.ngOnInit()
  }

  openAddedUserDialog(role_name,template_role,memberArray,privilege_access,Type) {    
    const dialogRef = this.dialog.open(MembersRoleDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data:{
          member: memberArray,
          type:Type

        }
    });
    this.apiService.selectedRole.next(role_name)
    this.apiService.selectedRoleTemplate.next(template_role)
    this.apiService.selectedPrivilegeAccess.next(privilege_access)

    dialogRef.afterClosed().subscribe(result => {
      this.getRoles();
    });
  }

  openUser() {
    this.apiService.parentSelectedRole.next(this.roleType.value.role_name);
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
     
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
       this.apiService.selectedRoleMember.next(this.selectedUserArray); 
    })

  }

  openUserForRole(role_name,template_role,memberArray,privilege_access){
    const scmUser = this.dialog.open(roleMemberDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        member: memberArray
      }
    });

    this.apiService.selectedRole.next(role_name)
    this.apiService.selectedRoleTemplate.next(template_role)
    this.apiService.selectedPrivilegeAccess.next(privilege_access)

    scmUser.afterClosed().subscribe(result => {
      setTimeout(() => {
        this.getRoles()
     }, 2000);
    })
  }

  updatePM(role_name,memberArray){
    const scmUser = this.dialog.open(updatePMDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data:{
        member: memberArray,
        role_name: role_name
      }
    });

    this.apiService.selectedRole.next(role_name)
    scmUser.afterClosed().subscribe(result => {
    this.getRoles()
    })
  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }
}

@Component({
  selector: 'members-role',
  templateUrl: 'members-role.html',
  styleUrls: ['./project-roles.component.scss']
})

export class MembersRoleDialog {
  memberList: any = [];
  selectedRole: any;
  projectId : any;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  template_role_name: any;
  privilege_access: any;
  Type:any;

  constructor(public apiService : AuthService, public alertService: AlertService,
    public dialogRef: MatDialogRef<MembersRoleDialog>,@Inject(MAT_DIALOG_DATA) public data: any) {

      this.memberList = data.member,
      this.Type = data.type

     }

     ngOnInit(){
      this.apiService.selectedRole.subscribe(data=>{
        this.selectedRole=data;
      })
     }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteUser(user,i){
    this.projectId=localStorage.getItem('projectId');
    this.apiService.selectedRoleTemplate.subscribe(res=>{
      this.template_role_name=res;        
    })

    this.apiService.selectedPrivilegeAccess.subscribe(access=>{
      this.privilege_access = access;        
    })

    let body={
      "project_id":this.projectId,
      "role_name": this.selectedRole,
      "template_role_name": this.template_role_name,
      "privilege_access": this.privilege_access,
      "user_email":user.user_email

  }

   
  this.apiService.deleteUser(body).subscribe((data:any)=>{
    this.memberList.splice(i,1)
   // if(data.Status == "Member has been removed from the role"){
     // this.alertService.success('Member has been removed from the role', this.options)
      this.alertService.success(data.message, this.options)
   // }

  }
  , (error: HttpErrorResponse) => {
    if (error.status == 433){
    this.alertService.error(error.error.message, this.options);
     }
     else if (error.status == 403){}
    else {
    this.alertService.error('Error while removing user.', this.options);
    throw error;
     }
    
     })
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  checked: boolean
}

const ELEMENT_DATA: MemberDetails[]=[];
@Component({
  selector: 'role-member-dialog',
  templateUrl: 'add-role-user.html',
  styleUrls: ['./project-roles.component.scss']
}) 

  export class roleMemberDialog {
    projectId: any;
    projectName: any;
    members: any = [];
    ELEMENT_DATA: MemberDetails[];
    displayedColumns: string[] = ['user_name', 'user_email', 'select'];
    dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
    selection = new SelectionModel<MemberDetails>(true, []);
    componentName: any;
    isallSelected: boolean = false;
  
    existingJiraMembers: any;
  role_name: any;
  memberList: any = [];
  newMembersAdd: any = [];
  finalArray:any =[];
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  template_role_name: string;
  privilege_access: string;
  


    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: MemberDetails): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
    }
    constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { this.memberList = data.member}
  
    ngOnInit(): void {
      this.Role = localStorage.getItem("Role");
      if(this.Role=="User"){
      // this.apiService.projectKey.subscribe(projectId => {
      //   this.projectId = projectId;
      // })
      this.projectId=localStorage.getItem('projectId');
  
      // this.apiService.projectName.subscribe(projectName => {
      //   this.projectName = projectName;
      // })
      this.projectName=localStorage.getItem('projectName');
  
      this.getUser();
      }
      else{
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
      });
      }
    }
    allSelected() {
      this.isallSelected = true
    }
      
    public getUser() {
      this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
        this.members = project[0].members;
       
       this.members.forEach((element,index) => { //alll members 2
         for(var i=0; i<this.memberList.length;i++){
           if(this.memberList[i].user_email == element.user_email ){
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
           }
         }
         
       });

       this.members.forEach((object:any)=>{
        if(!object.duplicate){
          this.finalArray.push(object)
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432){
        this.alertService.error(error.error.message, this.options);
         }
         else if (error.status == 403){}
        else {
        this.alertService.error("No data available.", this.options);
        throw error;
         }
        
         })

      

      let tempArray = [...this.finalArray];
      this.apiService.selectedRole.subscribe((role:any)=>{
        this.finalArray.forEach((member,index) => {
          for(let i=0; i<member.role_name.length; i++){
            if(member.role_name[i] == "Project Manager"){
              tempArray.splice(index,1)
            }
            else{
             
            }
          }
        });

        this.finalArray = tempArray
      })
      
        this.dataSource.data = this.finalArray as MemberDetails[]
      })
    }

    addUserForRole(){
      this.apiService.selectedRole.subscribe(data=>{
        this.role_name=data;
      })
      this.apiService.selectedRoleTemplate.subscribe(res=>{
        this.template_role_name=res;        
      })
      this.apiService.selectedPrivilegeAccess.subscribe(access=>{
        this.privilege_access = access;        
      })
      let memberArray = [];
      this.selection.selected.forEach(obj=>{
        let object ={
          "user_name": obj.user_name,
          "user_email": obj.user_email
        }
        memberArray.push(object)
      })
     
      let roleBody = {
        "project_id":  this.projectId,
        "roles_assigned" : [
          {
            "role_id":"",
            "role_name":  this.role_name,
            "template_role_name": this.template_role_name,
            "privilege_access" : this.privilege_access,
            "members": memberArray
          }
        ],
        "created_by":localStorage.getItem('mail').toLowerCase(),
        "updated_by":localStorage.getItem('mail').toLowerCase(),



      }
      this.apiService.addNewRole(roleBody).subscribe((roleResult: any) => {
        //if (roleResult[0].Status == "Member added") {
          this.alertService.success(roleResult.message, this.options)
       // }
      
      }, (error: HttpErrorResponse) => {
          if (error.status == 432){
          this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403){}
          else {
          this.alertService.error("No data available.", this.options);
          throw error;
          }
      
       })
      this.dialogRef.close()
    }
    
    exit() {
      this.dialogRef.close();
    }
  }

  //Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./project-roles.component.scss']
})
export class rolesCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<rolesCancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'info-pop',
  templateUrl: 'info.html',
  styleUrls: ['./project-roles.component.scss']
})

export class ModalComponent {
  flag: number=1;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService, public router: Router
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      
        // this._router.navigate(['/projectSummary'])

        // this._router.navigate(['/project-roles'])
      this.router.navigateByUrl('/projectSummary', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/project-roles']);

      });
      this.dialogRef.close();

      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      // console.log(localStorage);

      // if(this.flag=1){
      //   this.flag=0
      //   this.router.navigateByUrl('/projectSummary', { skipLocationChange: true }).then(() => {
      //     this.router.navigate(['/project-roles']);
  
      //   });
        
      // }

      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./project-roles.component.scss']
})
export class rolesWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<rolesWorkbenchConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  workbenchPopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./project-roles.component.scss']
})
export class rolesHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<rolesHomeConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  homePopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


export interface PMDetails {
  user_name: string;
  user_email: string;
  selected: boolean
}

const ELEMENT_DATAA: PMDetails[]=[];
@Component({
  selector: 'update-pm-dialog',
  templateUrl: 'update-pm.html',
  styleUrls: ['./project-roles.component.scss']
}) 

  export class updatePMDialog {
    
  //  ELEMENT_DATA: PMDetails[];
    displayedColumns: string[] = ['user_name', 'user_email','selection'];
    dataSource = new MatTableDataSource<PMDetails>(ELEMENT_DATAA);
    
    
  role_name: any;
  memberList: any = [];
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  projectId: string;
  selectedUserPM : any;
  userMailId: any;
  profile:any;
  userRole:any;
  projectName: string;
  selection: SelectionModel<Element> = new SelectionModel<Element>(false, []);
  template_id: string;

    constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { this.memberList = data.member}
  
    ngOnInit(): void {
      this.Role = localStorage.getItem("Role");
      if(this.Role=="User"){
      this.projectId=localStorage.getItem('projectId');
      this.projectName=localStorage.getItem('projectName');
      this.getUser();
      }
      else{
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-catalog-config']);
      });
      }
    }

    updatePM(){
      this.userMailId=localStorage.getItem('mail').toLowerCase();
    this.profile=localStorage.getItem('profile');
    this.userRole= localStorage.getItem('role');
      let body;
      body = {
        "project_id": this.projectId,
        "project_name": this.projectName,
        "primary_manager_details": {
            "user_name": this.profile,
            "user_email": this.userMailId,
            "user_role": "Project Manager"
        },
        "new_manager_details": {
            "user_name": this.selectedUserPM.user_name,
            "user_email": this.selectedUserPM.user_email,
            "user_role": "Secondary Project Manager"
        },
        "updated_by":localStorage.getItem('mail').toLowerCase()

    }
    this.template_id = localStorage.getItem('template_id');
    let arr = [body];
    this.apiService.getTemplateRoles(this.template_id).subscribe((data: any)=>{
      arr.forEach(element => {
        data.forEach(template => {
          if(element.primary_manager_details.user_role === template.role_name) {              
            element.primary_manager_details.template_role_name = template.template_role_name
            element.primary_manager_details.privilege_access = template.privilege_access
          }
          if(element.new_manager_details.user_role === template.role_name) {              
            element.new_manager_details.template_role_name = template.template_role_name
            element.new_manager_details.privilege_access = template.privilege_access
          }
        })
      });
        
      this.apiService.updateProjectManager(body).subscribe((data:any)=>{
        this.alertService.success(data.message, this.options)
        this.router.navigate(['/home']);
      }, (error: HttpErrorResponse) => {
        if (error.status == 433) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Error while updating project manager.", this.options);
          throw error;
        }
  
      })
      this.dialogRef.close();
    })
    
    
      
    }
      
    public getUser() {
     let memberArray=[];
      this.memberList.forEach(obj => {
        let object ={
          "user_name": obj.user_name,
          "user_email": obj.user_email
        }
        memberArray.push(object)
      });
        this.dataSource.data = memberArray as PMDetails[]
    }

    
  selectRow($event: any, row){
    $event.preventDefault();
        this.dataSource.data.forEach((row) => row.selected = false);
        row.selected = true;
        this.selection.select(row);
        this.selectedUserPM  = row;
  }

       
    exit() {
      this.dialogRef.close();
    }
  }
