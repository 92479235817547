import { Router } from '@angular/router';
import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from 'src/app/_alert';
import { Subject, throwError } from 'rxjs';	
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


export interface VDIDetails {
  toolID: string;  
  tool_group_name: string;  
azure_tool_instance_id: string;  
vdi_type: string;  
}


export interface configList {
  toolID: string;
  tenantID: string;
  subscriptionID: string;
  Cost: string;
  tool_group_name: string;
  azure_tool_instance_id:string;
  vdi_type:string; 
  showImage: [];
}

@Component({
  selector: 'app-admin-vdi-config',
  templateUrl: './admin-vdi-config.component.html',
  styleUrls: ['./admin-vdi-config.component.scss']
})
export class AdminVdiConfigComponent implements OnInit {
  
  projectId
  vdiObj: object[];
  CatlogArray =[];
  public unique_key: number;
  checklist: any;
  masterSelected: boolean;
  project_selectedcheckbox = false;
  checkedList: any;
  VdiConfig_details:any=[];
  check:any=[]

  public parentRef: ProjectSummaryComponent;

  showImage = [];
  

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();
  Role:string="";
  itemSelected:boolean=false;

  constructor(public apiService: AuthService, private router: Router,
     private spinner: NgxSpinnerService, public alertService: AlertService,public dialog: MatDialog,) {
      this.masterSelected = false;

      }

  ELEMENT_DATA: VDIDetails[];
  displayedColumns: string[] = ['toolID', 'tenantID', 'subscriptionID', 'select'];
  //dataSource = new MatTableDataSource<VDIDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<VDIDetails>(true, []);
  

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };
    this.spinner.show();
    this.GetCatalogtableDetails();
  this.spinner.hide();
    }
    else{
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
    });
    }
  }


  refreshVdi(){
    
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-vdi-config']);
      });
      }
      deleteRecord(){
        if(this.VdiConfig_details.length==0){
          this.alertService.error("Select one or more option to delete", this.options);
        }

      }
      checkUncheckAll() {
        for (var i = 0; i < this.checklist.length; i++) {
          this.checklist[i].isSelected = this.masterSelected;
        }
        this.getCheckedItemList();
      }
      isAllSelectedcheck() {
        this.masterSelected = this.checklist.every(function (item: any) {
          return item.isSelected == true;
        })
        this.getCheckedItemList();
      }
      getCheckedItemList() {
        this.project_selectedcheckbox = false;
        this.checkedList = [];
        
        for (var i = 0; i < this.checklist.length; i++) {
          if (this.checklist[i].isSelected)
            this.checkedList.push(this.checklist[i].toolID);
        }
       this.itemSelected=false;
       this.VdiConfig_details=[];
       
        for (let index = 0; index < this.checkedList.length; index++) 
                  {
                   this.itemSelected=true;
      
                    this.project_selectedcheckbox = true;
                      var SelectedItem=this.checkedList[index];
                     let VdiConfigBoby={
                        "vdi_dropdown_id":SelectedItem,
                        }
                       this.VdiConfig_details.push(VdiConfigBoby);
                                   
                 }
        //}
      }
      
      DeleteConfig(){
         var deleteBody: any;
         this.check=[]
         this.VdiConfig_details.forEach(element => {
           this.check.push(element.vdi_dropdown_id)
           
         });
         
         
         deleteBody = {
           "tool_ids":this.check
        
         }
        
         this.apiService.Admin_VdiImagesDropdowDeleteData(deleteBody).subscribe((res:any) => {
    
           if (res.status == 200) //doesnt work
           {
             
            
             this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
               this.router.navigate(['/admin-vdi-config']);
             });
            //  this.alertService.success("Data Delete Successfully", this.options);
            this.alertService.success(res.body.message,this.options)

           }
         }, (error: HttpErrorResponse) => {
           //
      
           if (error.status == 446) //doesnt work
           {
                
             this.alertService.error(error.error.message, this.options);
      
           }
           else if (error.status == 403){}
           else {
            
             this.alertService.error("Invalid Input", this.options);
             throw error;
           }
         })
      
       }
      
  GetCatalogtableDetails(){
  
       this.apiService.Admin_VDITableDetails().subscribe((res:any)=>{
        res.forEach(member => {
          let obj = {
            "toolID": member.tool_id,
            "tool_group_name": member.tool_group_details.tool_group_name,
            "image_name":member.tool_instance_details.vdi_tool_instance.name,
            "endpointsecurity":member.tool_instance_details.vdi_tool_instance.Endpoint_Security,
            "vdi_type":member.tool_instance_details.vdi_tool_instance.vdi_type, 
            "azure_tool_instance_id":member.tool_instance_details.vdi_tool_instance.azure_tool_instance_id,  
            "location":member.tool_instance_details.vdi_tool_instance.location,
            "type": member.tool_instance_details.vdi_tool_instance.type,
            "showImage": member.tool_instance_details.vdi_tool_instance.tags
          }
          
         this.CatlogArray.push(obj)
         this.checklist = this.CatlogArray;

        })

      
       // this.dataSource.data  =this.CatlogArray as VDIDetails[]
        this.dtTrigger.next();
      }
      , (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
      
  }

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   // const numRows = this.dataSource.data.length;
    //return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolID + 1}`;
  }



  setFormMode() {

    localStorage.setItem("formMode", "Save");
    
  }

  getRecord(row) {
    this.vdiObj = row;
    try {
      localStorage.setItem('vdiObj', JSON.stringify(this.vdiObj));
      localStorage.setItem("formMode", "Edit")
      this.router.navigate(['/vdi-config-setting']);
    }
    catch {
      return throwError('Data not found');
    }
  }
  
  showImageInfo(catalog) {
    console.log(catalog);
    
    const dialogRef = this.dialog.open(AdminDialogOverview, {
      width: '55%',
      height: '65%',
      disableClose: true,
      data: {
        image: catalog.showImage,
        toolId:catalog.toolID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}

@Component({
  selector: 'admin-dialog-overview',
  templateUrl: 'image-details.html',
  styleUrls: ['./admin-vdi-config.component.scss']
})
export class AdminDialogOverview {
  image: any = [];
  ToolId:any;
  Listcategory=["Open-Source","Licensed"]
  imageList: any[][];
  constructor(
    public dialogRef: MatDialogRef<AdminDialogOverview>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService, public alertService: AlertService,
  ) { 
  
    this.image = data.image
    this.ToolId=data.toolId;
    this.divideData(this.image);
  }
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  divideData(imageData){
    const n = 3 //number of tables
    this.imageList = [[], [], []] 
    const wordsPerLine = Math.ceil(imageData.length / 3)
    for (let line = 0; line < n; line++) {
      for (let i = 0; i < wordsPerLine; i++) {
        const value = imageData[i + line * wordsPerLine]
        if (!value) continue //avoid adding "undefined" values
        this.imageList[line].push(value)
      }
    }
    //console.log(this.imageList);
    
    
  }


  onChange($event) {
    let Category = $event.target.options[$event.target.options.selectedIndex].text;
    
if(Category=="All"){
  Category="";
}
else{
  Category=Category;
}

   this.apiService.Admin_VDIFilterDetails(Category,this.ToolId).subscribe((res:any)=>{

   this.image=res.body;
   this.divideData(this.image);

    
  }, (error: HttpErrorResponse) => {
    if (error.status == 441) //doesnt work
    {
         
      this.alertService.error(error.error.message, this.options);

    }
    else if (error.status == 403){}
    else {
     
      this.alertService.error("Invalid Input", this.options);
      throw error;
    }
  }
  )
  
   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
