<div class="popup-dialog">
    <div class="">
        <span (click)="onNoClick()" class="material-icons close-icon">
            <i class="fa fa-times" aria-hidden="true"></i>
        </span>
    </div>
  
        <div class="content">
            <span class="exl-icon">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            </span>
            <span  class="vertical-line mx-3">
            </span>
            <span class="text">
                Do you want to cancel changes?
            </span>
        </div>
        <div class="text-right">
            <button type="button" class="btn-canvas btn-canvas-blue-2 mr-3" (click)="popUpClose('Yes')" >Yes</button>
            <button type="button" class="btn-canvas btn-canvas-blue-2" (click)="popUpClose('No')">No</button>
        </div>
    
</div>