<!-- New code-->
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="backNavigation()">Admin Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Master Properties</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 text-right">
          <div class="table-options pt-3">
            <span (click)="addToolGroup()"><i class="fas fa-plus"></i> Add</span>
            <span  (click) = "deleteRecord()" *ngIf="!editMode" data-toggle="modal" data-target="#confirmationModal"><i class="fas fa-trash"></i> Delete</span>
            <span (click)="refreshToolGroup()"><i class="fas fa-sync-alt"></i> Refresh</span>
           <!-- <span><i class="fas fa-cog"></i> Manage</span>-->
          
          </div>
         
        </div>
        <div class="col-md-1 p-md-0 my-auto">
          <a class="float-right step-back" (click)="backNavigation()">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div>
       
      </div>
     
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/svg/Masterproperties.svg">
          <div class="vertical-line mx-3"> </div> <span>Master Properties</span>
        </h1>
      </div>
     
    </div>
  
    <div class="inner-mid-container">
      <!-- @athor -priyanka kale - addded If condition to check itemSelected flag -if item is selected from the list or not then it will show popup -->
      <div class="modal fade " *ngIf="itemSelected" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModal" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content pop-up">
            <div class="modal-header-popup">
              <span  class="vertical-line mx-3"> </span>
               <span class="header mb-0"> Master Properties</span> 
              <span  data-dismiss="modal" class="material-icons close-icon">
                  close
                </span>
            </div>
            <hr class="margin-class" />
            <div class="modal-body">
              Are you sure want to Delete?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-canvas-blue-2" (click)="DeleteConfig()">Ok</button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showToolGroupForm" class="tool-group-Form">
        <h2 *ngIf="!editMode">New Master Properties</h2>
        <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label>Screen Name</label> <!--[disabled]="ScreenNameDisable"-->
              <select id="screenname" name="screenname" class="form-control form-control-lg" required (change)="onChange($event)"
               
                [(ngModel)]="model.screenname" #screenname="ngModel">
                <!--<option selected>Select Screen Name</option>-->
                <option value="" disabled selected hidden>Select Screen Name</option>
                <option *ngFor="let s of ScreenDropdownList" [value]="s">{{s}}</option>
              </select>
              <div *ngIf="screenname.errors && screenname.touched" class="alert alert-danger">
                <div *ngIf="screenname.errors.required">Screen Name is required.</div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Property Name</label>
              <select id="toolGr" name="toolGr" class="form-control form-control-lg" required (change)="onChangePropertyName($event)"
                [(ngModel)]="model.toolGr" #toolGr="ngModel">
               <!-- <option selected>Choose Property Name</option> -->
                <option value="" disabled selected hidden>Select Property Name</option>
                <option *ngFor="let v of propertyNameValueList" [value]="v">{{v}}</option>
              </select>
              <div *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
                <div *ngIf="toolGr.errors.required">Property Name is required.</div>
              </div>
            </div>
            <div class="form-group col-md-4">
              <label>Property Value</label>
           <!-- <textarea name="toolDescription" class="form-control form-control-lg" rows="1"
                [(ngModel)]="model.toolDescription" #toolDescription="ngModel"
                [ngClass]="{ 'is-invalid': f.submitted && toolDescription.invalid }" required></textarea>
              <div *ngIf="f.submitted && toolDescription.invalid" class="invalid-feedback">
                <div *ngIf="toolDescription.errors.required">Property value is required</div>
              </div>-->
  
              <select id="ProptValue" (change)="onChangePropertyValue($event)" name="ProptValue" class="form-control form-control-lg" required
              
               [(ngModel)]="model.ProptValue" #ProptValue="ngModel">
               <!--<option selected>Choose Property Name</option> -->
               <option value="" disabled selected hidden>Select Property Value</option>
               <option *ngFor="let p of propertyValues" [value]="p.property_value">{{p.property_value}}</option>
             </select>
             <div *ngIf="ProptValue.errors && ProptValue.touched" class="alert alert-danger">
               <div *ngIf="ProptValue.errors.required">Property Value is required.</div>
             </div>
            
            </div>
  
            <div class="form-group col-md-12 text-right button-area">
              <button [disabled]="saveButtonDisable" type="button" *ngIf="!editMode" (click)="CheckToolNameDuplication()" class="btn-canvas-blue mr-2">Save</button> 
              <button type="button" *ngIf="editMode" (click)="CheckToolNameDuplicationUpdate()" class="btn-canvas-blue mr-2">Update</button> 
               <!-- <button type="reset" class="btn-canvas-blue" (click)="closeToolGroup()">
                 Cancel
               </button> -->
               <!--button class="mr-2 btn-canvas-blue" type="button" data-toggle="modal"
               data-target="#confirmationModal">Cancel</button-->
               <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!f.form.pristine" data-target="#confirmationModal">Cancel</button>
              <button class="mr-2 btn-canvas-blue" *ngIf="f.form.pristine" (click)="closeToolGroup()">Cancel</button>
  
       </div>
  
       <!-- Modal -->
       <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>
       <div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog"
           aria-labelledby="confirmationModal" aria-hidden="true">
           <div class="modal-dialog" role="document">
               <div class="modal-content pop-up">
                   <div class="modal-header-popup">
                       <span class="vertical-line mx-3"> </span>
                       <span class="header mb-0">Master Properties</span>
                       <span data-dismiss="modal" class="material-icons close-icon">
                           close
                       </span>
                   </div>
                   <hr class="margin-class" />
                   <div class="modal-body">
                       Are you sure want to cancel?
                   </div>
                   <div class="modal-footer">
                       <button type="button" class="btn btn-canvas-blue-2"
                           data-dismiss="modal">Close</button>
                       <button type="button" class="btn btn-canvas-blue-2" (click)="cancelok()">Ok</button>
                   </div>
               </div>
               <!-- </div> -->
               <!-- </div> -->
           </div>
       </div>
      <!-- Modal -->
  
  
          </div>
       </form>
    
      </div>
  
      <div class="my-5 table-container" *ngIf="showToolGroupMasterTable">
        <div class="table-responsive">
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table">
          <thead>
            <tr>
              <!--<th style="width: 15px;"><input type="checkbox"></th>-->
               <!--Code_comment_for_furture_use_Don't_Delete-->
               <th style="width: 15px;">
                <input type="checkbox" [(ngModel)]="masterSelected" name="list_name" value="m1" (change)="checkUncheckAll()"/>
              </th>
                     <!--Code_comment_for_furture_use_Don't_Delete-->
              <th>ID</th>
              <th>Screen Name</th>
              <th>Property Name</th>
              <th>Property Value</th>
              <th>Display Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tool of ToolGroupArray">
               <!--Code_comment_for_furture_use_Don't_Delete-->
               <td style="width: 15px;">
                <input type="checkbox" [(ngModel)]="tool.isSelected" name="list_name" value="{{tool.id}}" (change)="isAllSelected()"/>
              </td>
                     <!--Code_comment_for_furture_use_Don't_Delete-->
              <!--td style="width: 15px;"><input type="checkbox"></td>-->
              <td><span class="link-col" (click)="getRecord(tool)">{{tool.ID}}</span></td>
              <td>{{tool.screen_name}}</td>
              <td>{{tool.Name}}</td>
              <td>{{tool.Description}}</td>
              <td>{{tool.dropdown_display_name}}</td>
  
            </tr>
  
          </tbody>
        </table>
       </div>
      </div>
      
    </div>
  </div>
  
  <!--New code close-->
  