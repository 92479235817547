import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { environment } from 'src/environments/environment'
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../service/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { logoutModal } from '../main-nav/main-nav.component';

const GRAPH_ENDPOINT = environment.GRAPH_ENDPOINT;

@Component({
  selector: 'app-admin-main-nav',
  templateUrl: './admin-main-nav.component.html',
  styleUrls: ['./admin-main-nav.component.scss']
})
export class AdminMainNavComponent {

  mobileQuery: MediaQueryList;
  profile;
  initials;
  showFiller = false;
  mailId;
  userRole;
  profileImageFlag;

  showSubmenu: boolean = false;
  showScmSubmenu: boolean = false;
  showStoryBoardSubmenu: boolean = false;
  showCdSubmenu: boolean = false;
  showCISubmenu: boolean = false;
  showDSSubmenu: boolean = false;
  showSCASubmenu: boolean = false;
  showNetworkSubmenu: boolean = false;
  showVpcSubmenu: boolean = false;
  showPropertiresSubmenu: boolean = false;
  showAzureSubmenu: boolean = false;
  showAwsSubmenu: boolean = false;
  showAwsDSSubmenu: boolean = false;


  private _mobileQueryListener: () => void;
  projectData: any
  userMailId: string;
  // constructor(private authService: MsalService, private http: HttpClient, private _AuthServices:AuthService ) { }

  screenWidth: number;
  urlsearch: any;
  urlcode: any;
  adminRole: boolean;
  roleAccess: string;
  gigFlag: boolean=false;

  constructor(private http: HttpClient,private msalBroadcastService: MsalBroadcastService, private authService: MsalService, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    public dialog: MatDialog,
    public _AuthServices: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar, private apiService: AuthService) {


    // constructor(private http: HttpClient, changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    //   public dialog: MatDialog,
    //   public _AuthServices:AuthService,
    //   private _router:Router,
    //   private _snackBar: MatSnackBar){
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };

  }
  closeTools() {
    this.showSubmenu = false;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleMenu() {
    this.showSubmenu = !this.showSubmenu;
  }

  toggleScmMenu() {
    this.showScmSubmenu = !this.showScmSubmenu;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleStoryBoardMenu() {
    this.showStoryBoardSubmenu = !this.showStoryBoardSubmenu;
    this.showScmSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleCdMenu() {
    this.showCdSubmenu = !this.showCdSubmenu;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleCIMenu() {
    this.showCISubmenu = !this.showCISubmenu;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleDSMenu() {
    this.showDSSubmenu = !this.showDSSubmenu;
  }
  toggleAwsDSMenu() {
    this.showAwsDSSubmenu = !this.showAwsDSSubmenu;
  }
  toggleSCAMenu() {
    this.showSCASubmenu = !this.showSCASubmenu;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
  }
  toggleNetworkMenu() {
    this.showNetworkSubmenu = !this.showNetworkSubmenu;
  }
  toggleVPCMenu() {
    this.showVpcSubmenu = !this.showVpcSubmenu;
  }

  togglePropertiresMenu() {
    this.showPropertiresSubmenu = !this.showPropertiresSubmenu;
  }
  toggleAzureMenu() {
    this.showAzureSubmenu = !this.showAzureSubmenu;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAwsSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }
  toggleAwsMenu() {
    this.showAwsSubmenu = !this.showAwsSubmenu;
    this.showScmSubmenu = false;
    this.showStoryBoardSubmenu = false;
    this.showCdSubmenu = false;
    this.showAzureSubmenu = false;
    this.showCISubmenu = false;
    this.showSCASubmenu = false;
  }

  logout() {

    //code start - after the logout to clear all local storage

    localStorage.clear();
    sessionStorage.clear();

    //code end - after the logout to clear all local storage

    this.authService.logout();
    this.apiService.projectKey.next("");


  }

  openLogoutModal(){
    const dialogRef = this.dialog.open(logoutModal, {
      width: '40%',
      height: '32%',
      disableClose: true,
    });
  }


  ngAfterContentInit() {



    setTimeout(() => {
      this.userMailId = localStorage.getItem('mail').toLowerCase();
      // console.log("profile 2..",this.profile);
    }, 2000)

  }

  ngOnInit() {
    // console.log(localStorage,"nav");
    // console.log(this.gigFlag,"fiurst");
    // debugger
    if(localStorage.onboardID !="undefined"){
      this.gigFlag=environment.gig
      this.gigFlag=true
      

    }
    // console.log(this.gigFlag,"aftr");


    //
    this.getProfile();

    this.userMailId = localStorage.getItem('mail').toLowerCase();
    this.profile = localStorage.getItem('profile');
    this.initials = this.profile.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join("");
    this.userRole = localStorage.getItem('role');

    if(localStorage.getItem('canvasAdminRole')=="false"){
      this.adminRole = false;
    }
    else{
      this.adminRole = true;
    }
    this.roleAccess = localStorage.getItem("Role");

    //this.apiService.profileName.next(this.profile);

   // this.apiService.getAllProjects().subscribe(projects => {
      // console.log(projects);     
     // this.projectData = projects
      // console.log( this.projectData+"Project Data");
  //})
  this.apiService.profileImageFlag.subscribe(data=>{
    this.profileImageFlag = data
  })

  this.apiService.getProjectByEmailId(this.userMailId).subscribe((data:any) =>{
    //console.log(data);       
      this.projectData = data.user_projects        
    
  })
  this.apiService.getProfileImage(GRAPH_ENDPOINT + "/me/photo/$value").subscribe(data=>{
     
    this.createImageFromBlob(data)
  
    

    })

    


  }

 

  imageToShow: any;
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  routingFunction(compponent_name, event) {
    alert("Hi");
  }

  openSnackBarDiaLog() {

    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {

      width: '32%',

      height: '23%',

      disableClose: true,


    });

  }

  changeAccess(){
   
    let reqBody = {
      "user_mail": this.mailId.toLowerCase(),
      // "user_type": this.roleAccess=='Admin' ? 'User' : 'Admin'
    }
    this.apiService.changeRole(reqBody).subscribe((data: any)=>{
      window.location.reload();
    })
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT + "/me").toPromise()
      .then(profile => {
        this.apiService.loggedInMailId.next(profile['mail'])

        localStorage.setItem("profile", profile['displayName'])

        this.profile = localStorage.getItem('profile')

        localStorage.setItem("mail", profile['mail'])

        this.mailId = localStorage.getItem('mail')

        localStorage.setItem("role", profile['jobTitle'])

       // this.mailId = localStorage.getItem('role')

        if (this.mailId == "") {
          this.openSnackBarDiaLog()
          this.apiService.castToolDialog.next("AD Error. Contact Administrator.")

        }

      });
  }

  selectedIndex: number = null;
  setIndex(index: number) {
    this.selectedIndex = index;
  }


  setProjectId(event, id) {
    this.apiService.projectKey.next(id);
  }





}
