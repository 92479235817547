<div class="pop-up">
  <div class="row">
    <div class="col-md-5 mt-3">
      <span  class="vertical-line mx-1 mx-md-3"> </span>
     <span class="header mb-0">{{type=='add'? 'Add' : 'Delete'}} Member</span>
    </div>
    <div class="col-md-5">
      <mat-form-field fxFlex="60%">
      <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
    </mat-form-field>
    </div>
    <hr class="margin-class" />
    <div class="col-md-2 mt-3">
       <span (click)="exit()" class="material-icons close-icon">
        close
      </span>
    </div>
</div>
      
      <hr class="margin-class" />
      <div class="popup-container">
        <div class="noData offset-md-5" *ngIf="dataSource.filteredData.length==0">
          <h2>No Users Available</h2>
        </div>
  
        <div class="example-container1" *ngIf="dataSource.filteredData.length!=0">
          <table mat-table [dataSource]="dataSource">
      
      
            <!-- Name Column -->
            <ng-container matColumnDef="user_name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.user_name}} </td>
            </ng-container>
      
            <!-- Email Column -->
            <ng-container matColumnDef="user_email">
              <th mat-header-cell *matHeaderCellDef> Email </th>
              <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
            </ng-container>
        
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (click)="allSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]=true [aria-label]="checkboxLabel(row)"> 
                </mat-checkbox>
      
                <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
            </tr>
          </table>
      
      
        </div>
      </div>
      
      <div class="row justify-content-center">
        <div class="col-md-8">
          <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
          </mat-paginator>
        </div>
        <div *ngIf="dataSource.filteredData.length!=0">
          <div class="col-auto col-md-3 mt-3">
            <button mat-button  mat-raised-button class="add-button" (click)="addDeleteMemberToRepo()" >{{type=='add'? '+ Add' : '- Delete'}} Member</button>
          </div>
        </div>
      </div>
    </div>