<div class="pop-up" *ngIf="installPopup">
    <div class="row">
        <div class="col-sm-6 pt-3">
          <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">Uninstall Software</span> 
        </div>
        <div class="col-sm-4">
          <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="60%">
              <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-2 pt-3">
            <span (click)="exit()" class="material-icons close-icon">
                close
              </span>
        </div>
       
      </div>
      <hr class="margin-class" />
      <div class="popup-container justify-content-center">
  
      <div class="example-container1" >
        <h1 *ngIf="!uninstallFlag">Loading...</h1>
        <h1 *ngIf="uninstallFlagList" style="margin-left: 20%;margin-top: 20%;">There are no softwares to Uninstall</h1>
        <table  *ngIf="uninstallFlag && !uninstallFlagList" mat-table [dataSource]="dataSource">
    
    
          <!-- Name Column -->
          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let element"> <span><img width="30"  src="assets/software_icons/{{element.software_name}}.jpg"> </span></td>
          </ng-container>
    
          <!-- Email Column -->
          <ng-container matColumnDef="software_name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.software_name}} </td>
          </ng-container>
                <!-- Email Column -->
        <ng-container matColumnDef="software_version">
            <th mat-header-cell *matHeaderCellDef> Version </th>
            <td mat-cell *matCellDef="let element"> {{element.software_version}} </td>
            </ng-container>
                    <!-- Email Column -->
          <!-- <ng-container matColumnDef="software_ritm">
            <th mat-header-cell *matHeaderCellDef> RITM </th>
            <td mat-cell *matCellDef="let element"> {{element.software_ritm}} </td>
          </ng-container> -->
                <!-- Title Column -->
   
  
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                      <mat-checkbox (click)="allSelected()" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                      </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]=true [aria-label]="checkboxLabel(row)"> 
                      </mat-checkbox>
            
                      <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
  
          </tr>
        </table>
    
    
      </div>
      </div>
  
      <div class="row mt-4" >
        <div class="col-10"></div>

        <div class="col-2">
    
          <button mat-button  mat-raised-button class="add-button"  *ngIf="uninstallFlag && !uninstallFlagList" (click)="softwareDetails()" >Submit</button>
          <!-- <button mat-button  mat-raised-button class="add-button ml-3"  (click)="requestDetails()" >+Request Software</button> -->
  
        </div>
      </div>
    </div>
  