import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';

import { CommonModule } from '@angular/common';
import { ProjectRolesComponent } from './project-roles/project-roles.component';
import { TeamMembersComponent } from './team-members/team-members.component';
// import { VdiAddComponent, VdiDetailsComponent } from './vdi-details/vdi-details.component';
import { MaterialModule } from '../material.module';
import { AppRoutingModule } from '../app-routing.module';
import { ScmToolComponent } from './scm-tool/scm-tool.component';
//import { StoryBoardComponent} from './story-board/story-board.component';
import { CiCdComponent, ciCdCancelConfirmationPopUpDialog, ciCdWorkbenchConfirmationPopUpDialog,ciCdHomeConfirmationPopUpDialog } from './ci-cd/ci-cd.component';
// import { WorkbenchComponent } from './workbench/workbench.component';
import { CastComponent } from './cast/cast.component';
import { FaqComponent } from './faq/faq.component';
import { TeamsCollabComponent } from './teams-collab/teams-collab.component';
import { WorkBenchComponent ,  } from './work-bench/work-bench.component';
import { AzzureDevopsComponent,devOpsHomeConfirmationPopUpDialog , devOpsWorkbenchConfirmationPopUpDialog, devOpsCancelConfirmationPopUpDialog} from './azzure-devops/azzure-devops.component';
import { DeploymentStoreComponent, storeHomeConfirmationPopUpDialog, storeWorkbenchConfirmationPopUpDialog, storeCancelConfirmationPopUpDialog } from './deployment-store/deployment-store.component';
// import { WorkbenchIconsComponent } from './workbench-icons/workbench-icons.component';
import { DataTablesModule } from 'angular-datatables';
import { DevTestFrameworkComponent } from './dev-test-framework/dev-test-framework.component';
import { InsightsComponent, insightCancelConfirmationPopUpDialog, insightWorkbenchConfirmationPopUpDialog, 
  insightHomeConfirmationPopUpDialog  } from './insights/insights.component';
import {  DetailViewDialog,computerDetailViewDialog,observationInfoDialog,PolicyOverviewDialog,vdiAuditLogDetailViewDialog, gitlabDetailViewDialog , jiraDetailViewDialog} from './reports/reports.component';
//import { ReportsComponent } from './reports/reports.component';
//import { ChartsModule } from 'ng2-charts';
import { AwsVdiComponent } from './aws-vdi/aws-vdi.component';
import { TeamsDashboardComponent } from './teams-dashboard/teams-dashboard.component';
import { TeamsChannelComponent, DeleteMembersSpace, ViewMembersSpace, RemoveMemberDialog } from './teams-channel/teams-channel.component';
// import { MschannelCreationsComponent } from './mschannel-creations/mschannel-creations.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { TeamsArtifactsComponent,DialogMeetingInfoDialog } from './teams-artifacts/teams-artifacts.component';
import { ReportCostComponent } from './report-cost/report-cost.component';
import { RecycleBinComponent } from './recycle-bin/recycle-bin.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { AwsWorkspaceComponent } from './aws-workspace/aws-workspace.component';
import { VdiRequestFormComponent } from './vdi-request-form/vdi-request-form.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [ProjectRolesComponent,
     TeamMembersComponent,  ScmToolComponent, ciCdCancelConfirmationPopUpDialog,ciCdWorkbenchConfirmationPopUpDialog,ciCdHomeConfirmationPopUpDialog,
      CiCdComponent, CastComponent, FaqComponent,devOpsHomeConfirmationPopUpDialog, devOpsWorkbenchConfirmationPopUpDialog, devOpsCancelConfirmationPopUpDialog,
      TeamsCollabComponent, WorkBenchComponent, AzzureDevopsComponent, DeploymentStoreComponent, DevTestFrameworkComponent, InsightsComponent,
      insightCancelConfirmationPopUpDialog, insightWorkbenchConfirmationPopUpDialog, insightHomeConfirmationPopUpDialog, storeHomeConfirmationPopUpDialog,
      storeWorkbenchConfirmationPopUpDialog, storeCancelConfirmationPopUpDialog, DetailViewDialog,computerDetailViewDialog,PolicyOverviewDialog,observationInfoDialog,
      vdiAuditLogDetailViewDialog,jiraDetailViewDialog, gitlabDetailViewDialog,AwsVdiComponent, TeamsDashboardComponent,ViewMembersSpace,DeleteMembersSpace,
       TeamsChannelComponent, RemoveMemberDialog, TeamsArtifactsComponent,DialogMeetingInfoDialog, RecycleBinComponent, AwsWorkspaceComponent, VdiRequestFormComponent
     ],
  imports: [

    CommonModule,
    ChartsModule,
    MaterialModule,
    AppRoutingModule,
    DataTablesModule,
    NgxSpinnerModule,
    Ng2GoogleChartsModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  entryComponents:[
    devOpsHomeConfirmationPopUpDialog, devOpsWorkbenchConfirmationPopUpDialog, devOpsCancelConfirmationPopUpDialog,
    ciCdCancelConfirmationPopUpDialog,ciCdWorkbenchConfirmationPopUpDialog, ciCdHomeConfirmationPopUpDialog,
    insightCancelConfirmationPopUpDialog, insightWorkbenchConfirmationPopUpDialog, insightHomeConfirmationPopUpDialog,
    storeHomeConfirmationPopUpDialog, storeWorkbenchConfirmationPopUpDialog, storeCancelConfirmationPopUpDialog,
    gitlabDetailViewDialog,jiraDetailViewDialog,computerDetailViewDialog,vdiAuditLogDetailViewDialog,observationInfoDialog,PolicyOverviewDialog,
     DetailViewDialog,DeleteMembersSpace,ViewMembersSpace, RemoveMemberDialog,DialogMeetingInfoDialog

  ]
})
export class ProjectConfigurationsModule { }
