import { VdiConfigSettingComponent } from './admin/vdi-config-setting/vdi-config-setting.component';
import { AdminVdiConfigComponent } from './admin/admin-vdi-config/admin-vdi-config.component';
import { AdminJenkinsComponent } from './admin/admin-jenkins/admin-jenkins.component';
import { AdminCiCdComponent } from './admin/admin-ci-cd/admin-ci-cd.component';
import { AdminJiraComponent } from './admin/admin-jira/admin-jira.component';
import { AdminStoryBoardComponent } from './admin/admin-story-board/admin-story-board.component';
import { ToolConfigComponent } from './admin/tool-config/tool-config.component';
import { AdminToolgroupComponent } from './admin/admin-toolgroup/admin-toolgroup.component';
import { AdminGitlabComponent } from './admin/admin-gitlab/admin-gitlab.component';
import { TeamsCollabComponent } from './project-configurations/teams-collab/teams-collab.component';
import { TeamsDashboardComponent } from './project-configurations/teams-dashboard/teams-dashboard.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { ProjectSummaryComponent } from './project-details/project-summary/project-summary.component';
import { ProjectListPageComponent } from './project-list-page/project-list-page.component';
import { ProjectRolesComponent } from './project-configurations/project-roles/project-roles.component';
import { TeamMembersComponent } from './project-configurations/team-members/team-members.component';
import { VdiDetailsComponent } from './project-configurations/vdi-details/vdi-details.component';
import { AppComponent } from './app.component';
import { ScmToolComponent } from './project-configurations/scm-tool/scm-tool.component';
import { AwsVdiComponent } from './project-configurations/aws-vdi/aws-vdi.component';
import { StoryBoardComponent } from './project-configurations/story-board/story-board.component';
import { CiCdComponent } from './project-configurations/ci-cd/ci-cd.component';
import { CastComponent } from './project-configurations/cast/cast.component';
import { FaqComponent } from './project-configurations/faq/faq.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AdminScmToolComponent } from './admin/admin-scm-tool/admin-scm-tool.component';
import { WorkBenchComponent } from './project-configurations/work-bench/work-bench.component';
import { AdminGithubComponent } from './admin/admin-github/admin-github.component';
import { AdminCatalogConfigComponent } from './admin/admin-catalog-config/admin-catalog-config.component';
import { AzzureDevopsComponent } from './project-configurations/azzure-devops/azzure-devops.component';
import { DeploymentStoreComponent } from './project-configurations/deployment-store/deployment-store.component';
import { AdminCastConfigComponent } from './admin/admin-cast-config/admin-cast-config.component';
import { AdminAzureConfigComponent } from './admin/admin-azure-config/admin-azure-config.component';
import { DevTestFrameworkComponent } from './project-configurations/dev-test-framework/dev-test-framework.component';
import { InsightsComponent } from './project-configurations/insights/insights.component';
import { AdminDeploymentStoreVMComponent } from './admin/admin-deployment-store-vm/admin-deployment-store-vm.component';
import { AdminDeploymentStoreComponent } from './admin/admin-deployment-store/admin-deployment-store.component';
import { AdminDeploymentVMConfigComponent } from './admin/admin-deployment-vm-config/admin-deployment-vm-config.component';
import { AdminAksClusterComponent } from './admin/admin-aks-cluster/admin-aks-cluster.component';
import { AdminAksComponent } from './admin/admin-aks/admin-aks.component';
import { AdminVmComponent } from './admin/admin-vm/admin-vm.component';
import { AdminVdiComponent } from './admin/admin-vdi/admin-vdi.component';
import { DevTestComponent } from './admin/dev-test/dev-test.component';
import { ReportsComponent,alertDescription } from './project-configurations/reports/reports.component';

import { AdminReportsComponent } from './admin/admin-reports/admin-reports.component';
import { MschannelCreationsComponent } from './project-configurations/mschannel-creations/mschannel-creations.component';
import { AdminVDIDropdownConfigComponent } from './admin/admin-vdi-dropdown-config/admin-vdi-dropdown-config.component';
import { AdminAwsConfigComponent } from './admin/admin-aws-config/admin-aws-config.component';
import { AdminTestComponent } from './admin/admin-test/admin-test.component';
import { CatchClearTestComponent } from './admin/catch-clear-test/catch-clear-test.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
// @author-priyanka kale - imported component product/project details new component
import{ ProductDetailsComponent} from './project-details/product-details/product-details.component';
import { from } from 'rxjs';
import { TeamsChannelComponent } from './project-configurations/teams-channel/teams-channel.component';
import { WebexLoginComponent } from './admin/webex-login/webex-login.component';
import { TeamsArtifactsComponent } from './project-configurations/teams-artifacts/teams-artifacts.component';
import { AdminTenantPropertireComponent } from './admin/admin-tenant-propertire/admin-tenant-propertire.component';
import { AdminClientPropertireComponent } from './admin/admin-client-propertire/admin-client-propertire.component';
import { AdminWebexPropertireComponent } from './admin/admin-webex-propertire/admin-webex-propertire.component';
import { AdminProjectTemplateComponent } from './admin/admin-project-template/admin-project-template.component';
import { ReportCostComponent } from './project-configurations/report-cost/report-cost.component';
import { RecycleBinComponent } from './project-configurations/recycle-bin/recycle-bin.component';
import { AdminAwsComponent } from './admin/admin-aws/admin-aws.component';
import { UnsavedChangesGuard } from './unsaved-changes.guard';
import { AwsWorkspaceComponent } from './project-configurations/aws-workspace/aws-workspace.component';
import { CanvasGigComponent } from './admin/canvas-gig/canvas-gig.component';
import { VdiRequestFormComponent } from './project-configurations/vdi-request-form/vdi-request-form.component';

var role = localStorage.getItem("Role");
//alert(role);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'projectSummary',
    component: ProjectSummaryComponent
  },
  // @author- priyanka kale - added path for product/project details component
  {
    path: 'projectdetails',
    component: ProductDetailsComponent
  },
  {
    path: 'home',
    component: ProjectListPageComponent
  },
  {
    path: 'memberRoles',
    component: ProjectRolesComponent
  },
  {
    path: 'teamMembers',
    component: TeamMembersComponent
  },
  {
    path: 'vdiDetails',
    component: VdiDetailsComponent
  }, 
  {
    path: 'scmTool',
    component: ScmToolComponent
  },
  {
    path: 'storyBoard',
    component: StoryBoardComponent
  },
  {
    path : 'awsEc2',
    component : AwsVdiComponent
  },
  {
    path: 'VdiRequestForm',
    component:VdiRequestFormComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path : 'awsWorkspace',
    component : AwsWorkspaceComponent
  },
  {
    path: 'ciCD',
    component: CiCdComponent
  },
  {
    path: 'cast',
    component : CastComponent
  },
  {
    path: 'faq',
    component : FaqComponent
  },
  {
    path: 'deployStore',
    component : DeploymentStoreComponent
  },
  {
    path: 'admin-home',
    component : AdminHomeComponent
  },
  {
    path: 'admin-gitlab',
    component : AdminGitlabComponent,
    canDeactivate:[UnsavedChangesGuard],
  },
  {
    path: 'admin-github',
    component : AdminGithubComponent
  },
  {
    path: 'admin-jira',
    component : AdminJiraComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-jenkins',
    component : AdminJenkinsComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-gig',
    component : CanvasGigComponent,
    // canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-scm-tool',
    component : AdminScmToolComponent
  },
  {
    path: 'admin-tool-group',
    component : AdminToolgroupComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-tool-config',
    component : ToolConfigComponent
  },
  {
    path: 'admin-catalog-config',
    component : AdminCatalogConfigComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-vdi-config',
    component : AdminVdiConfigComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-cast-config',
    component : AdminCastConfigComponent
  },
  {
    path: 'admin-azure-config',
    component : AdminAzureConfigComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-deploymentStore-VM',
    component : AdminDeploymentStoreVMComponent
  },
  {
    path: 'admin-aks-cluster',
    component : AdminAksClusterComponent
  },
  {
    path: 'admin-reports',
    component : AdminReportsComponent
  },
  {
    path: 'admin-deployment-vm-config',
    component : AdminDeploymentVMConfigComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-deploymentStore',
    component : AdminDeploymentStoreComponent
  },
  {
    path: 'admin-projectTemplate',
    component : AdminProjectTemplateComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'vdi-config-setting',
    component : VdiConfigSettingComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-story-board',
    component : AdminStoryBoardComponent
  },
  {
    path: 'admin-ci-cd',
    component : AdminCiCdComponent
  },
  {
    path: 'admin-aks',
    component : AdminAksComponent
  },
  {
    path: 'admin-vm',
    component : AdminVmComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-vdi',
    component : AdminVdiComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-dev-test',
    component : DevTestComponent
  },
  {
    path: 'webex-login',
    component : WebexLoginComponent
  },
  {
    path: 'teamsChannel',
    component : TeamsChannelComponent
  },
  {
    path: 'teamsMeeting',
    component : TeamsCollabComponent
  },
  {
    path: 'teamsartifact',
    component : TeamsArtifactsComponent
  },
  {
    path: 'teamsDashboard',
    component : TeamsDashboardComponent
  },
  {
    path: 'workBench',
    component : WorkBenchComponent
  },
  {
    path: 'reports',
    component : ReportsComponent
  },
  {
    path: 'report-cost',
    component : ReportCostComponent
  },
  {
    path: 'reports/alert-description/:id',
    component : alertDescription
  },
  
  {
    path: 'azureDevops',
    component : AzzureDevopsComponent
  },
  {
    path: 'project-roles',
    component : ProjectRolesComponent
  },
  {
    path: 'dev-test',
    component : DevTestFrameworkComponent
  },
  {
    path: 'insights',
    component : InsightsComponent
  },
  {
    path: 'webEx',
    component : MschannelCreationsComponent
  }
  ,
  {
    path: 'recycle-bin',
    component: RecycleBinComponent
  },
  {
    path: 'admin-vdi-dropdown-config',
    component : AdminVDIDropdownConfigComponent,
    canDeactivate:[UnsavedChangesGuard],

  } ,
  {
    path: 'admin-aws-config',
    component : AdminAwsConfigComponent
  }
  ,
  {
    path: 'admin-Test',
    component : AdminTestComponent
  },
  {
    path: 'webex-login',
    component : WebexLoginComponent
  },
  {
    path: 'catch-clear-test',
    component : CatchClearTestComponent
  },
  {
    path: 'team-members',
    component : TeamMembersComponent
  },
  {
    path: 'admin-tenant-propertire',
    component : AdminTenantPropertireComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-client-propertire',
    component : AdminClientPropertireComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-webex-propertire',
    component : AdminWebexPropertireComponent,
    canDeactivate:[UnsavedChangesGuard],

  },
  {
    path: 'admin-aws',
    component : AdminAwsComponent
  },
  //Checking # routing issue
  // {
  //   path: '404',    
  //  component : PageNotFoundComponent
  // },
  {
    path: '**',
  // redirectTo : '/404'
  component : PageNotFoundComponent
  }
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
