import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/api.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/_alert';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject, throwError } from 'rxjs';
import { strict } from 'assert';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface VDIEdit {
  in_catalogName: string;
  in_ID: string;
  in_catalogDescription: string;
  in_Cost: string;

}

export interface ResourceTable {
  name: string;
  location: string;
  type: string;
  action: string;
  tags: string;
  id: string;
  vdiType: string;
  vmos: string;
  vmse: string;
  vmty: string;
  vm_image_id: string;
  image:string;
}

export interface Subscription_iddropdown {
  subscription_id: string;
  tenant_id: string;
  Azure_ConnectionName: string;
  tool_id: string;
  tool_group_id: string;
  tool_group_name: string;
  vnet: string;
  subnet: string;
  resourceGroup: string;
}

export class azureVdiDetails {
  azure_Id: string;
  subscription_id: string;
  azure_toolGr_id: string;
  azure_toolGr_name: string;
}

const ELEMENT_DATA: ResourceTable[]=[];

@Component({
  selector: 'app-admin-deployment-vm-config',
  templateUrl: './admin-deployment-vm-config.component.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})
export class AdminDeploymentVMConfigComponent implements OnInit,CanComponentLeave {

  vditenantID: any;
  Subscription: any;
  vdiSubscriptionID: any;

  SubscriptionList: Subscription_iddropdown[] = [];
  model: any = {};
  formMode: string = "";

  vdi_Azure_Id: string;
  Vdi_ToolGr_Id: string;
  Vdi_ToolGr_Name: string;
  vdi_Subscription_ID: string;
  vdi_Tenant_ID: string;
  vdi_Azure_ConnectionName: string;
  vdi_ID: any;

  VM_Vnet: any;
  VM_Subnet: any;
  VM_ResourceGroup: any;


  submitted = false;
  selectedUserArray: any = []
  showTable: boolean = false;
  tableResourceArray: any = [];
  VM_tool_instance = [];
  saveBody: any;
  vdi_SubId: string;
  vdi_tenantId: string;
  saveEditArray: any = [];
  powers: any = [];
  VId_TypeList = ['Shared', 'Dedicated']
  arrayWithDuplicates = [];
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  ImageDescTemp: any = [];
  Role:string="";
  // ####### Popup Message ##########
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  popoverTitle:string = "Delete Tool";
  popoverMessage:string = "Are you sure want delete?";
  cancelClicked:boolean = false;

  
  displayedColumns: string[] = ['name', 'location', 'type', 'vmos', 'vmse', 'vmty', 'vm_image_id'];
  dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATA);
  nextUrl: any;
  //dataSource = new MatTableDataSource<ResourceTable>(this.ELEMENT_DATA);

  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-deploymentStore-VM"){
      return true
    }
    else{
      if(this.model.Subscription =="" || this.model.Subscription== undefined){
        return true
      }
      if (this.model.Subscription !="" || this.model.Subscription!= undefined){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, public alertService: AlertService,
    private apiService: AuthService, public dialog: MatDialog, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.dtTrigger.unsubscribe();
    this.dtOptions={destroy:true}
    this.alertService.yesClickedEvent
      .subscribe((data: string) => {
        this.model.Subscription == " ";
        this.router.navigate(['/admin-deploymentStore-VM'])
      });

    this.alertService.homeYesClickedEvent
      .subscribe((data: string) => {
        this.model.Subscription == " ";
        this.router.navigate(['/admin-catalog-config'])
      });
    this.formMode = localStorage.getItem('formMode')
    this.spinner.show();
    try {
      if (this.formMode == "Edit") {
        var getObj = JSON.parse(localStorage.getItem('vdiObj'));
        //console.log("vdiObj Info ");
        //console.log(getObj);
        this.setVDIEditing(getObj);
      }
    }
    catch {
      
    }
    this.getSubscriptionList();
    this.spinner.hide();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  refreshVdi() {

    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-deploymentStore-VM']);
    });
  }
  cancelok(){
    
    this.model.Subscription = ""
    this.router.navigate(['/admin-deploymentStore-VM'])


  
  if(this.nextUrl==undefined){
    this.refreshVdi()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  getSubscriptionList(): void {
    //
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      //this.apiService.Admin_AzureTableDetails().subscribe(data => {
      if (res) {
        //console.log(res, "data");
        // 

        res.forEach(member => {
          //  
          let obj = {
            "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
            "Azure_ConnectionName": member.tool_instance_details.azure_tool_instance.tool_instance_name,
            "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
            "tool_id": member.tool_id + " (" + member.tool_instance_details.azure_tool_instance.tool_instance_name + ")",
            "tool_group_id": member.tool_group_details.tool_group_id,
            "tool_group_name": member.tool_group_details.tool_group_name,
            "vnet": "",
            "subnet": "",
            "resourceGroup": "ee"
          }
          this.SubscriptionList.push(obj)
        })
        //console.log(this.SubscriptionList, "SubscriptionList")
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  vdiGetImage() {
    //    

    if (this.model.Subscription == undefined

      || this.model.Subscription == " " || this.model.Subscription == "") {

    }
    else {
      this.vdi_SubId = this.model.Subscription;
     
      localStorage.setItem("vdi_SubId", this.vdi_SubId);
    

      this.openDialog();

      //console.log("vdiGetImage");

     
    }
  }

  openDialog() {
    // 
    const dialogRef = this.dialog.open(VMImageModel, {
      width: '70%',
      height: '72%',
    });
    dialogRef.afterClosed().subscribe(result => {
      let tags: string = "";
      this.selectedUserArray = result
      var NewArryList = [];
      var Dupcated_NewArryList = [];
      this.arrayWithDuplicates=[];


      if (this.tableResourceArray.length > 0) {


        for (var s = 0; s < this.selectedUserArray.length; s++) {
          this.arrayWithDuplicates.push(this.selectedUserArray[s]);

        }

        for (var s = 0; s < this.tableResourceArray.length; s++) {
          this.arrayWithDuplicates.push(this.tableResourceArray[s]);

        }

        var uniqueArray = this.removeDuplicates(this.arrayWithDuplicates, "id");
        //console.log("uniqueArray is: " + JSON.stringify(uniqueArray));

        this.tableResourceArray = [];
        uniqueArray.forEach(member => {
          if (member.tags != undefined) {
            tags = member.tags.image_type;
            //console.log("tags");
            //console.log(tags);
          }
          let VM_Image_Id: string = "";
          if (member.name != undefined) {
            var i = member.name.split("-");
            var tempID = i.length;
            VM_Image_Id = i[tempID - 1];
          }
          
          let tableObj = {
            "id": member.id,
            "name": member.name,
            "location": member.location,
            "type": member.type,
            "vmos": member.vmos,
            "vmse": member.vmse,
            "vmty": member.vmty,
            "vm_image_id": VM_Image_Id,
            //"tags" :{ "Docker": "Demo"} 
            "tags": { "image_type": tags },
            "image": member.image
          }

          this.tableResourceArray.push(tableObj)
          //console.log("tableResourceArray");
         // console.log(this.tableResourceArray.length);
        })

       

      }
      else {

        let tags: string = "";

        this.selectedUserArray.forEach(member => {
          if (member.tags != undefined) {
            tags = member.tags.image_type;
            //console.log("tags");
            //console.log(tags);
          }
          let VM_Image_Id: string = "";
          if (member.name != undefined) {
            var i = member.name.split("-");
            var tempID = i.length;
            VM_Image_Id = i[tempID - 1];
          }

          
          let tableObj = {
            "id": member.id,
            "name": member.name,
            "location": member.location,
            "type": member.type,
            "vmos": member.vmos,
            "vmse": member.vmse,
            "vmty": member.vmty,
            "vm_image_id": VM_Image_Id,
            //"tags" :{ "Docker": "Demo"} 
            "tags": { "image_type": tags },
            "image": member.image
          }
          this.tableResourceArray.push(tableObj)
         // console.log("tableResourceArray");
         // console.log(this.tableResourceArray.length);
        })
       
      }
      //  console.log(this.selectedUserArray);

       this.dataSource.data = this.tableResourceArray as ResourceTable[]
        this.showTable = true;
        this.dtTrigger.next();
        //console.log(this.dataSource, "this.dataSource");
    })

    //  })
   // console.log(this.dataSource, "this.dataSource");
  }


  deleteVDI(i) {
    // alert("hi");

    this.tableResourceArray.splice(i, 1);
  }
  onSubmit() {

  }


  removeDuplicates(originalArray, prop) {

    var newArray = [];
    var lookupObject = {};
    var DuplArray = [];

    for (var i in originalArray) {

      lookupObject[originalArray[i][prop]] = originalArray[i]
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }


  setVDIEditing(vdiObj) {

    // 
    var vdi_Tenant_ID: string;
    var vdi_Subscription_ID: string;
    var vdi_Id: string;

    vdi_Tenant_ID = vdiObj.tenantID;
    this.model.vditenantID = vdi_Tenant_ID.trim();

    vdi_Subscription_ID = vdiObj.subscriptionID;
    this.model.vdiSubscriptionID = vdi_Subscription_ID.trim();

    vdi_Id = vdiObj.toolID;
    this.vdi_ID = vdi_Id.trim();

  }



  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  public setSelectedStatus(value: string): void {

    //console.log("DeploymentStore VM selected value", value);
    if (this.SubscriptionList && value) {
      var status: Subscription_iddropdown = this.SubscriptionList.find(s => s.subscription_id == value);
      if (status)
        this.vdi_Azure_Id = status.tool_id;
      this.vdi_Subscription_ID = status.subscription_id;
      this.Vdi_ToolGr_Id = status.tool_group_id;
      this.Vdi_ToolGr_Name = status.tool_group_name;
      this.VM_ResourceGroup = status.resourceGroup;
      this.VM_Vnet = status.vnet;
      this.VM_Subnet = status.subnet;
    }
    else {
      this.vdi_Azure_Id = '';
    }

    //console.log("Azure selected values 1 ", this.vdi_Azure_Id + " -- " + this.vdi_Subscription_ID);
    //console.log("Azure selected values 2 ", this.Vdi_ToolGr_Id + " -- " + this.Vdi_ToolGr_Name);
   // console.log("Azure selected values 3 ", this.VM_ResourceGroup + " -- " + this.VM_Subnet);
   // console.log("Azure selected values 4 ", this.VM_Vnet + " -- ");

  }

  onChange($event) {
    let azuretext = $event.target.options[$event.target.options.selectedIndex].text;
   // console.log("Azure selected value", azuretext);
    //Split the code and pass Azure ID
    var i = azuretext.split(" ");
    //console.log("Split Value ", i);
    azuretext = i[0];
    this.getAzureIdDetails(azuretext);
  }

  getAzureIdDetails(azureId) {
    //debugger
    this.apiService.Admin_AzureIdDetails(azureId).subscribe((res: any) => {
      //debugger
      if (res) {
       // console.log(res, "Id details");


        res.forEach(member => {


          this.vdi_SubId = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.vdi_tenantId = member.tool_instance_details.azure_tool_instance.tenant_id;
          this.vdi_Azure_Id = member.tool_id;
          this.vdi_Subscription_ID = member.tool_instance_details.azure_tool_instance.subscription_id;
          this.Vdi_ToolGr_Id = member.tool_group_details.tool_group_id;
          this.Vdi_ToolGr_Name = member.tool_group_details.tool_group_name;
          this.vdi_Azure_ConnectionName = member.tool_instance_details.azure_tool_instance.tool_instance_name;

        
        })

       
        localStorage.setItem("vdi_SubId", this.vdi_SubId);
        // localStorage.setItem("vdi_tenantId",  this.vdi_tenantId);

      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 436) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }

      })
  }

  VMSaveData() {

    //console.log(localStorage.getItem('vdi_SubId'), "vdiSubscriptionID");
    this.model.vdiSubscriptionID = localStorage.getItem('vdi_SubId');
    if (this.model.Subscription == undefined

      || this.model.Subscription == " " || this.model.Subscription == "") {

    }
    else {
      // Validation Code 
      if (this.tableResourceArray.length != 0) {
        this.VM_tool_instance = [];
        //console.log(this.tableResourceArray, "tableResourceArray")
        for (let index = 0; index < this.tableResourceArray.length; index++) {

          let VMDetailsBody = {
            "azure_tool_instance_id": this.vdi_Azure_Id,
            "vdi_type": "Shared",
            "id": this.tableResourceArray[index].id,
            "name": this.tableResourceArray[index].name,
            "type": this.tableResourceArray[index].type,
            "location": this.tableResourceArray[index].location,
            "vm_os": this.tableResourceArray[index].vmos,
            "vm_server": this.tableResourceArray[index].vmse,
            "vm_type": this.tableResourceArray[index].vmty,
            "vm_image_id": this.tableResourceArray[index].vm_image_id,
            "tool_description" :  this.tableResourceArray[index].image
          }
          this.VM_tool_instance.push(VMDetailsBody);
        }  // ##### For loop ########
        this.SaveVMDetailsService(this.VM_tool_instance);
      }
      else {
        this.alertService.error("Please select images for Save", this.options);
      }
    }//else

  }


  SaveVMDetailsService(vm_tool_instance) {
    this.saveBody = [];
    this.saveBody = {
      "resource_type": "tool",
      
      "tool_category": "Deployment-Store",
      "tool_name": "Deployment-VM",
      "tool_group_details": {
        "tool_group_id": this.Vdi_ToolGr_Id,
        "tool_group_name": this.Vdi_ToolGr_Name
      },
      "tool_instance_details": {
        "deploymentstore_tool_instance": vm_tool_instance

      },
      "created_by":localStorage.getItem('mail').toLowerCase()
    }
    // //debugger;
    //console.log("saveBody");
   // console.log(this.saveBody);
    this.apiService.Admin_deploymentStore_VMSaveData(this.saveBody).subscribe((res: HttpResponse<any>) => {
      //  //debugger;
      //console.log(res.status);      
      if (res.status == 200) {
        var TotalImages = res.body["total image added"]
        // this.alertService.success(TotalImages + " VM Image Saved Successfully", this.options);
        this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/admin-deploymentStore-VM']);
          this.alertService.success(res.body.message,this.options)
        });
      }
    }, (error: HttpErrorResponse) => {
      //
      //console.log(error.status);
      if (error.status == 436||error.status == 430) {
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else if (error.status == 404) {
        this.alertService.error(" Service Not Found  ", this.options);
      }
      else {

        this.alertService.error(" Invalid Input ", this.options);

        throw error;
      }
    })

  }

  //Home button form validation 
  goHome(form) {
    if (form.pristine == false) {
      this.homeConfirmationPopup();
    } else {
      this.router.navigate(['/admin-catalog-config'])
    }
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(vmHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }

  //Cancel click
  toggleDisplay(form) {
    //console.log("Form data", form);
    if (form.pristine == false) {
      this.cancelconfirmationPopUp();
    } else {
      this.router.navigate(['/admin-deploymentStore-VM'])
      this.model.Subscription == " "
    }

  }

  // confirmation popup
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(vmCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  showImageInfo() {

    const dialogRef = this.dialog.open(DialogOverviewDVMialog, {
      width: '50%',
      height: '52%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



}


export interface configList {
  name: string;
  location: string;
  type: string;
  action: string;
  tags: string;
  id: string;
  vdiType: string;
  vmos: string;
  vmse: string;
  vmty: string;
  vm_image_id: string;
  image:string;
}

@Component({
  selector: 'Vdi-Table-Model',
  templateUrl: 'Vdi-Table-Model.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})


export class VMImageModel {

  members: any = [];
  tableResourceArray: any = [];
  TableMode: string;
  Server_List: any = [
    { value: 'Select', viewValue: 'Select' },
   
  ];
   
  VId_TypeList = [
    { value: 'Shared', viewValue: 'Shared' },
    { value: 'Dedicated', viewValue: 'Dedicated' },
  ];
  VM_OS_List = [
    { value: 'Select', viewValue: 'Select' },
    { value: 'Windows 2016', viewValue: 'Windows 2016' },
    { value: 'Windows 2019', viewValue: 'Windows 2019' },
    { value: 'Linux CentOS 7', viewValue: 'Linux CentOS 7' },
    { value: 'Linux RHEL 8', viewValue: 'Linux RHEL 8' },
    { value: 'Ubuntu 20.04', viewValue: 'Ubuntu 20.04' },
  ];

  VM_Server_List = [
    { value: 'Select', viewValue: 'Select' },
    { value: 'DataBase', viewValue: 'DataBase' },
    { value: 'App', viewValue: 'App' },
    { value: 'Web', viewValue: 'Web' },
    { value: 'Orchestration', viewValue: 'Orchestration' },
    { value: 'Code Analysis', viewValue: 'Code Analysis' },
    { value: 'None', viewValue: 'None' },
    { value: 'Others', viewValue: 'Others' },
  ];

  VMType_List = [
    { value: 'Select', viewValue: 'Select' },
    { value: 'Apache-2.4', viewValue: 'Apache-2.4' },
    { value: 'Apache24 + MySql8', viewValue: 'Apache24 + MySql8' },
    { value: 'IIS-10', viewValue: 'IIS-10' },
    { value: 'IIS10 + SqlExpr2019', viewValue: 'IIS10 + SqlExpr2019' },
    { value: 'Jenkins-2.263', viewValue: 'Jenkins-2.263' },
    { value: 'MongoDB-4.4', viewValue: 'MongoDB-4.4' },
    { value: 'MSSQLExpr2019', viewValue: 'MSSQLExpr2019' },
    { value: 'MySql8', viewValue: 'MySql8' },
    { value: 'MySql8 + Tomcat9', viewValue: 'MySql8 + Tomcat9' },
    { value: 'OS Only', viewValue: 'OS Only' },
    { value: 'SonarQube-7.6', viewValue: 'SonarQube-7.6' },
    { value: 'Tomcat-9', viewValue: 'Tomcat-9' },
    { value: 'Tomcat9 + MSSQLExpr2019', viewValue: 'Tomcat9 + MSSQLExpr2019' },
    { value: 'Tomcat9 + Mysql8', viewValue: 'Tomcat9 + Mysql8' },
    { value: 'SQLExpr2016 + DuckCreek', viewValue: 'SQLExpr2016 + DuckCreek' },
    
    
    
  ];


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    private http: HttpClient, private router: Router, public alertService: AlertService,
    private apiService: AuthService, public dialog: MatDialogRef<VMImageModel>,public Mdialog: MatDialog  ) { }

  error: boolean = false;
  errorMsg: string;
  selectedVMOsName: string;
  vdi_SubId_Popup: any;
  vdi_tenantId_Popup: any;
  SelectedTools: object[];
  SelectType:boolean = false;
  ELEMENT_DATA: ResourceTable[];
  displayedColumns: string[] = ['name', 'location', 'type', 'vmos', 'vmse', 'vmty', 'select'];

  dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATA);
  selection = new SelectionModel<ResourceTable>(true, []);

  ngOnInit(): void {

    this.error = false;
    this.selectedVMOsName = "";
    this.vdi_SubId_Popup = localStorage.getItem('vdi_SubId');
    this.vdi_tenantId_Popup = localStorage.getItem('vdi_tenantId');
    
    this.apiService.Admin_VDI_getImageDetails(this.vdi_SubId_Popup).subscribe((res: any) => {

      //console.log("VM_Serv_data")
      //console.log(res)
      // 

      let tags: string = "";
      let images_desc:string;
       let data = res.body.value.filter(x => x.name.startsWith('Canvas') && x.name.endsWith('-VM-Image'));

      data.forEach(member => {
        if (member.tags != undefined) {
          tags=member.tags;
         // console.log("tags");
         // console.log(tags);
          if(tags["Image-Description"]!=undefined)
          {

            images_desc=tags["Image-Description"];
          }
        else{
          images_desc="-";
        }
        }

        let VM_Image_Id: string = "";
        if (member.name != undefined) {
          var i = member.name.split("-");
          var tempID = i.length;
          VM_Image_Id = i[tempID - 1];
        }
      
        let tableObj = {
          "id": member.id,
          "name": member.name,
          "location": member.location,
          "type": member.type,
          "vmos": "Select",
          "vmse": "Select",
          "vmty": "Select",
          "vm_image_id": VM_Image_Id,
          //"tags" :{ "Docker": "Demo"} 
          "tags": tags ,
          "image":images_desc,
        }
        this.tableResourceArray.push(tableObj)
      })
      //console.log(this.tableResourceArray, "tableResourceArray")
     this.dataSource.data = this.tableResourceArray as ResourceTable[]
    })

     }

  onChangeVmOs($event) {
   // debugger
    let selectedVMOs = $event.target.options[$event.target.options.selectedIndex].text;
   // console.log("VM Om selected value", selectedVMOs);
    this.selectedVMOsName = selectedVMOs;
    this.error = false;
  }

  onChangeVmServer($event) {
    //debugger
    if (this.selectedVMOsName == "" || this.selectedVMOsName == "Select" || this.selectedVMOsName == undefined) {
      this.error = true;
      this.errorMsg = "Please select the VM OS Name";
    }
    else {
      this.error = false;
      let selectedVMServer = $event.target.options[$event.target.options.selectedIndex].text;
      //console.log("VM type selected value", selectedVMServer);
      this.selectOption(selectedVMServer, this.selectedVMOsName);
    }

  }

  selectOption(ServerType: string, SelectedOS: string) {
    //debugger
    if (SelectedOS == "Windows 2016" || SelectedOS == "Windows 2019") {
      if (ServerType == "Select") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
        ];
      }
      if (ServerType == "DataBase") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'MSSQLExpr2019', viewValue: 'MSSQLExpr2019' },
          
        ];
      }
      else if (ServerType == "App") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Tomcat-9', viewValue: 'Tomcat-9' },

        ];
      }
      else if (ServerType == "Orchestration") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Jenkins-2.263', viewValue: 'Jenkins-2.263' },

        ];
      }
      else if (ServerType == "Code Analysis") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'SonarQube-7.6', viewValue: 'SonarQube-7.6' },

        ];
      }
      else if (ServerType == "None") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'OS Only', viewValue: 'OS Only' },

        ];
      }
      else if (ServerType == "Others") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'IIS10 + SqlExpr2019', viewValue: 'IIS10 + SqlExpr2019' },
          { value: 'Tomcat9 + MSSQLExpr2019', viewValue: 'Tomcat9 + MSSQLExpr2019' },
          { value: 'Tomcat9 + Mysql8', viewValue: 'Tomcat9 + Mysql8' },
        ];
      }
      else if (ServerType == "Web") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Apache-2.4', viewValue: 'Apache-2.4' },
          { value: 'IIS-10', viewValue: 'IIS-10' },
        ];

      }
    }
    else if (SelectedOS == "Linux CentOS 8" || SelectedOS == "Linux RHEL 8") {

      if (ServerType == "Select") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
        ];
      }
      if (ServerType == "DataBase") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'MongoDB-4.4', viewValue: 'MongoDB-4.4' },
          { value: 'MySql8', viewValue: 'MySql8' },
         
        ];
      }
      else if (ServerType == "App") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Tomcat-9', viewValue: 'Tomcat-9' },

        ];
      }
      else if (ServerType == "Orchestration") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Jenkins-2.263', viewValue: 'Jenkins-2.263' },

        ];
      }
      else if (ServerType == "Code Analysis") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'SonarQube-7.6', viewValue: 'SonarQube-7.6' },

        ];
      }
      else if (ServerType == "None") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'OS Only', viewValue: 'OS Only' },

        ];
      }
      else if (ServerType == "Others") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Apache24 + MySql8', viewValue: 'Apache24 + MySql8' },
          { value: 'MySql8 + Tomcat9', viewValue: 'MySql8 + Tomcat9' },
        ];
      }
      else if (ServerType == "Web") {
        this.Server_List = [
          { value: 'Select', viewValue: 'Select' },
          { value: 'Apache-2.4', viewValue: 'Apache-2.4' },
         
        ];

      }
    }
    else {
        // Default msg
    }

  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  AddVdiImage() {
   // debugger
    this.SelectType=false;
    if (this.selection.selected.length == 0) {


    } else {

      this.SelectedTools = this.selection.selected;
      //debugger;
      for (let i = 0; i < this.selection.selected.length; i++) {
     if(this.selection.selected[i].vmos=="Select"||this.selection.selected[i].vmse=="Select"||this.selection.selected[i].vmty=="Select")
     {
      this.SelectType=true;
       return;
     }

      }

      this.apiService.Admin_VdiGetImage.next(this.selection.selected);
      this.dialog.close(this.selection.selected);
     // console.log(this.selection.selected, "selection")
    }
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
   
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name + 1}`;
  }




  deleteResource() {
    alert("hi");
  }

  exit() {

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  showImageInfo(catalog) {
    //debugger;
    if(catalog.tags==""){

    }
    else{
        const dialogRef = this.Mdialog.open(AdminDialogImageVM, {
          width: '50%',
          height: '58%',
          disableClose: true,
          data: {
            image: catalog.tags,
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    }

}




@Component({
  selector: 'dialog-overview-dialog',
  templateUrl: 'show-information.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})
export class DialogOverviewDVMialog {
  //dialogRef: any;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewDVMialog>
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})
export class vmHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<vmHomeConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  homePopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})
export class vmCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<vmCancelConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
@Component({
  selector: 'admin-dialog-Image',
  templateUrl: 'image-details.html',
  styleUrls: ['./admin-deployment-vm-config.component.scss']
})
export class AdminDialogImageVM {
  image: any = [];
  ELEMENT_DATA: ResourceTable[];
displayedColumns: string[] = ['name', 'tags'];
Selecterror:boolean = false;
dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATA);
tableResourceArray : any = []; 
Vdisoftware_details : any = [];
@ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator; 
  constructor(
    public dialogRef: MatDialogRef<AdminDialogImageVM>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService,
  ) { 
    //debugger;
    //console.log(data.image)
    this.image = data.image
   // console.log("image software",this.image)
    let TempTags:any=[];
    TempTags.push(this.image);

          for (let index = 0; index < TempTags.length; index++) 
            {
              TempTags.forEach(element => {
                const entries = Object.entries(element);
              //console.log(entries);
              for (let index = 0; index < entries.length; index++) 
              {
                var softwareName=entries[index][0];
                var softwareVersion=entries[index][1];
               let VdiTagsBoby={
                  "software_name":softwareName,
                  "software_version":softwareVersion
                  }
                 this.Vdisoftware_details.push(VdiTagsBoby);
              }
                });
           }

if(this.Vdisoftware_details.length>0){
  this.Selecterror=false;
  this.Vdisoftware_details.forEach(member => {
    //debugger;
    let tableObj = {
    "name":member.software_name,
     "tags" : member.software_version
    }
     this.tableResourceArray.push(tableObj)
   })
 // console.log(this.tableResourceArray,"tableResourceArray")
   this.dataSource.data = this.tableResourceArray as ResourceTable[]   

}
else{
  this.Selecterror=true;
}

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}