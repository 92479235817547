import { Injectable, NgModule } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment'
import { AlertService } from 'src/app/_alert';
// import { _throw} from 'rxjs/observable/throw'
import { throwError } from 'rxjs'
import { resource } from 'selenium-webdriver/http';

@Injectable()
export class AuthService {
  projectKey = new BehaviorSubject({});
  profileName = new BehaviorSubject({});
  projectName = new BehaviorSubject({});
  psNumber = new BehaviorSubject({});
  leadEmailId = new BehaviorSubject({});
  loggedInMailId = new BehaviorSubject({});
  vdiEmail = new BehaviorSubject({});
  teamStatus = new BehaviorSubject("Not Created");
  parentSelectedRole = new BehaviorSubject("");
  selectedSCMMembers = new BehaviorSubject([]);
  selectedVDIMembers = new BehaviorSubject([]);
  selectedVDIMembersWorkspace = new BehaviorSubject([]);
  selectedVDIMembersEC2 = new BehaviorSubject([]);

  selectedRoleMember = new BehaviorSubject([]);
  selectedRecurrsiveMeetingMember = new BehaviorSubject([]);

  selectedRole = new BehaviorSubject("");
  selectedRoleTemplate = new BehaviorSubject("");
  selectedSpace = new BehaviorSubject("");
  closeDialog = new BehaviorSubject("");
  selectedJiraMembers = new BehaviorSubject([]);
  catalog_id = new BehaviorSubject("");
  catalog_name = new BehaviorSubject("");
  userStatus = new BehaviorSubject("");
  webexToken = new BehaviorSubject("");
  singleCatalogInfo = new BehaviorSubject([]);
  projectCreatedBy = new BehaviorSubject("");
  modalType = new BehaviorSubject("");
  modalData = new BehaviorSubject([]);
  AddMemberVMData = new BehaviorSubject({});
  VMModalType = new BehaviorSubject("");
  reportModalType = new BehaviorSubject("");
  selectedTemplateId = new BehaviorSubject("");
  selectedPrivilegeAccess = new BehaviorSubject("");
  modalTypeInProjectList = new BehaviorSubject("")
  isUserTM = new BehaviorSubject(true);

  SCMToolDetails = new BehaviorSubject({});

  castToolDialog = new BehaviorSubject({});

  castStatusExtra = new BehaviorSubject(false);


  profileImageFlag = new BehaviorSubject(false);

  selectedJenkinsTool = new BehaviorSubject("Java");

  dateJenkinsData = new BehaviorSubject("");
  componentName = new BehaviorSubject("");

  //Members Sync Arrays
  previousMembers = new BehaviorSubject({});
  newMembers = new BehaviorSubject({});
  removedMembers = new BehaviorSubject({});
  vdiStatus = new BehaviorSubject({});
  Admin_VdiGetImage = new BehaviorSubject({});

  selectedTool = new BehaviorSubject({});

  toolSelectedFromDialog = new BehaviorSubject({});

  vdiInformationArray = new BehaviorSubject([]);
  Admin_CatalogToolSCM_Dialog = new BehaviorSubject({});
  VmImageInfo = new BehaviorSubject({});
  networkConfigData = new BehaviorSubject({});

  option = new Option()

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  private serviceUrl = environment.apiUrl;

  constructor(private httpclient: HttpClient, private _snackBar: MatSnackBar, private _router: Router, public alertService: AlertService, ) { }

  getProfileImage(imageUrl: string): Observable<Blob> {
    return this.httpclient.get(imageUrl, { responseType: 'blob' }).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {


      } if (res.status === 200) {

      }
      if (res.status === 404) {
        this.profileImageFlag.next(true);

      }
      return throwError(res.status);

    }));;
  }

  loginUser(user): Observable<any> {
    return this.httpclient.post<any>(this.serviceUrl + "/login", user)
  }
  loggedIn() {
    return !!localStorage.getItem('token')
  }
  getToken() {
    return localStorage.getItem('token')
  }
  getUser() {
    return localStorage.getItem('user')
  }
  logout() {
    localStorage.removeItem('user')
    return localStorage.removeItem('token')
  }

  getProjectByEmailId(mailID) {
    return this.httpclient.get(this.serviceUrl + '/api/getUserProfile?user_mail=' + mailID).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 404) {
        this.userStatus.next("invalid user")
      }
      if (res.status === 500) {
        this.userStatus.next("invalid user")
      }
      if (res.status === 434) {
        //this.userStatus.next(res.error.message)
        this.userStatus.next("invalid user")
      }
      return throwError(res.status);

    }));
  }

  registerNewUser(body) {
    //  console.log(body,"service body")
    return this.httpclient.post(this.serviceUrl + '/api/userRegistration', body)

  }


  Admin_ScmToolGrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=SCM&toolName=Gitlab')
  }
  checkToolDuplicateName(id,tool_category,projID,repoName) {
    return this.httpclient.get(this.serviceUrl + '/api/checkExistingProject?catalog_id=' + id+ '&tool_category='+ tool_category + '&project_id='+ projID + '&projectName='+ repoName)
  }

  checkGitlabGroupName(id,tool_category,projID) {
    return this.httpclient.get(this.serviceUrl + '/api/checkGitlabGroupName?catalog_id=' + id+ '&tool_category='+ tool_category + '&project_id='+ projID )
  }
  GigVdiVmImages(resource, type) {
    // api/gig/resource/images?resource=VDI&type=Pooled,Personal
    return this.httpclient.get(this.serviceUrl + '/api/external/resource/images?resource=' + resource + '&type=' + type)
  }
  submitGig(body): Observable<HttpResponse<any>> {
    // console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/external/request', body, { observe: 'response' })
  }
  Admin_AwsTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=AWS&toolName=AWS-Workspace')
  }
  Admin_NetworkToolGrTableDetails(Screen) {
    if (Screen == "Network-AKS") {
      return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-AKS')
    }
    if (Screen == "Network-VDI") {
      return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-VDI')
    }
    if (Screen == "Network-VM") {
      return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-VM')
    }
  }

  Admin_AksToolGrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-AKS')
  }

  Admin_VmToolGrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-VM')
  }

  Admin_VdiDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteVdiDropdown', body, { observe: 'response' })
  }
  Admin_GitlabDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }
  Admin_JiraDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }

  Admin_JenkinsDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }
  Admin_AzureConfigDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }
  Admin_VdiImagesDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }

  NetworkConfigVdiDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }
  NetworkConfigVMDropdowDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }
  Admin_ToolGroupEditDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTool', body, { observe: 'response' })
  }

  Admin_ToolGroupCreateDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteToolGroup', body, { observe: 'response' })
  }

  Admin_TemplateDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteTemplate', body, { observe: 'response' })
  }

  Admin_ClientDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteAdminCredential', body, { observe: 'response' })
  }
  Admin_WebexDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteWebexAccessToken', body, { observe: 'response' })
  }
  Admin_TenantDeleteData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteAccessToken', body, { observe: 'response' })
  }
  // Admin_GitlabDropdowDeleteData(body){
  //   //  console.log(body)
  //     // return this.httpclient.delete<any>(this.serviceUrl + '/api/deleteTool',body, { observe: 'response' })
  //     return this.httpclient.delete<any>(this.serviceUrl + '/api/deleteTool',body)

  //   }

  Admin_VdiToolGrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Network-Config&toolName=Network-Config-VDI')
  }

  Admin_DevTestTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllDevTestFramework')
  }

  Admin_AzureTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=AZURE')
  }

  Admin_AzureIdDetails(azureId) {
    return this.httpclient.get(this.serviceUrl + '/api/getToolById?tool_id=' + azureId);
  }

  Admin_CICDTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=CICD&toolName=Jenkins')
  }

  Admin_StoryBoard_JiraTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=StoryBoard&tool_name=Jira')
  }


  Admin_ToolGrTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllToolGroups')
  }

  Admin_VdiDropdownConfigTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllDropdownValuesForVdi')
  }
  Admin_CatlogTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllCatalogs')
  }

  Admin_VDITableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=VDI')
  }

  Admin_Deployment_VM_TableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Deployment-Store&toolName=Deployment-VM')
    // return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=Deployment-VM')
    // api/getAllTools?category=Deployment-Store&toolName=Deployment-VM
  }

  // Admin_VDI_getImageDetails(SubId) {

  //   return this.httpclient.get<any>(this.serviceUrl + '/api/vm_images?subscriptionID={' + SubId + '}&tenantID={' + tenantID + '}', { observe: 'response' })
  // }
  Admin_VDI_getImageDetails(SubId) {

    //return this.httpclient.get<any>(this.serviceUrl + '/api/vm_images?subscriptionID={' + SubId + '}&tenantID={' + 1234 + '}', { observe: 'response' })
    return this.httpclient.get<any>(this.serviceUrl + '/api/vm_images?subscription_ID=' + SubId, { observe: 'response' })
    // https://lti-canvas-dev-api-india.azurewebsites.net/api/vm_images?subscription_ID=fd3add44-9df1-45fc-b4fd-a805a8468edb
  }

  Admin_VDI_getDomainImageDetails(Azure_Tool) {

    //return this.httpclient.get<any>(this.serviceUrl + '/api/vm_images?subscriptionID={' + SubId + '}&tenantID={' + 1234 + '}', { observe: 'response' })
    return this.httpclient.get<any>(this.serviceUrl + '/api/getVdiByAzureId?azure_id=' + Azure_Tool, { observe: 'response' })
    // https://lti-canvas-dev-api-india.azurewebsites.net/api/vm_images?subscription_ID=fd3add44-9df1-45fc-b4fd-a805a8468edb
  }

  Admin_VDIFilterDetails(Category, ToolId) {

    //return this.httpclient.get<any>(this.serviceUrl + '/api/vm_images?subscriptionID={' + SubId + '}&tenantID={' + 1234 + '}', { observe: 'response' })
    return this.httpclient.get<any>(this.serviceUrl + '/api/getSoftwareByCategory?tool_id=' + ToolId + '&software_category=' + Category, { observe: 'response' })
    // https://lti-canvas-dev-api-india.azurewebsites.net/api/vm_images?subscription_ID=fd3add44-9df1-45fc-b4fd-a805a8468edb
    // getSoftwareByCategory?tool_id=TOOL-VDI-9&software_category=open-source
  }

  Admin_ScmToolGrDrp() {

    return this.httpclient.get<any>(this.serviceUrl + '/api/getAllToolGroups', { observe: 'response' })
  }

  Admin_ResourceGroupDrp(body) {
    return this.httpclient.post<any>(this.serviceUrl + '/api/getAllResourceGroups', body, { observe: 'response' })
  }

  Admin_BundlesDrp(body) {
    return this.httpclient.post<any>(this.serviceUrl + '/api/getAllBundlesForWorkspace', body, { observe: 'response' })
  }

  Admin_DirectoriesDrp(body) {
    return this.httpclient.post<any>(this.serviceUrl + '/api/getAllDirectoriesForAwsAccount', body, { observe: 'response' })
  }

  Admin_VnetDrp(body) {
    return this.httpclient.post<any>(this.serviceUrl + '/api/getAllVnet', body, { observe: 'response' })
  }

  Admin_SubnetDrp(body) {
    return this.httpclient.post<any>(this.serviceUrl + '/api/getAllSubnet', body, { observe: 'response' })
  }
  Admin_getPropertyNameValues(body) {
    return this.httpclient.get<any>(this.serviceUrl + '/api/getPropertyNames?screen_name=' + body, { observe: 'response' })
    // return this.httpclient.get<any>(this.serviceUrl + '/api/getAllToolGroups?id=' + ToolGroupID, { observe: 'response' })

  }
  Admin_getPropertyValues(arg1, arg2) {
    return this.httpclient.get<any>(this.serviceUrl + '/api/getPropertyValues?screen_name=' + arg1 + '&property_name=' + arg2, { observe: 'response' })
    // /api/getPropertyValues?screen_name=Workbench-Workspace&property_name=User-Volume-Size
  }

  Admin_ScmTestConnection(body) {

    return this.httpclient.post<any>(this.serviceUrl + '/api/testConnection', body, { observe: 'response' })
  }

  Admin_SubscriptionTestConnection(body) {

    return this.httpclient.post<any>(this.serviceUrl + '/api/validateAzureSubscriptionId', body, { observe: 'response' })
  }

  Admin_JiraTestConnection(body) {

    return this.httpclient.post<any>(this.serviceUrl + '/api/testConnection', body, { observe: 'response' })
  }

  Admin_getcatalogToolGroupName(ToolGroupID) {
    //console.log(body)

    return this.httpclient.get<any>(this.serviceUrl + '/api/getAllToolGroups?id=' + ToolGroupID, { observe: 'response' })
  }

  Admin_GetSubscriptionDetails(SubscriptionId) {
    //console.log(body)

    return this.httpclient.get<any>(this.serviceUrl + '/api/getInfoByCatalogId?catalog_id=' + SubscriptionId, { observe: 'response' })
  }
  Admin_deleteProject(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteProject', body, { observe: 'response' })
  }

  Admin_projectInfo(body): Observable<HttpResponse<any>> {
    return this.httpclient.get<any>(this.serviceUrl + '/api/getActiveResources?project_id=' + body, { observe: 'response' })
  }

  Admin_deleteResource(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteFromCatalog', body, { observe: 'response' })
  }

  Admin_deleteSubscription(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/deleteCatalog', body, { observe: 'response' })
  }


  Admin_GetToolgroupIdDetails(ToolgroupId) {
    //console.log(body)

    return this.httpclient.get<any>(this.serviceUrl + '/api/getToolGroupByToolGroupId?tool_group_id=' + ToolgroupId, { observe: 'response' })
  }


  Admin_JenkinsTestConnection(body) {
    //console.log(body)

    return this.httpclient.post<any>(this.serviceUrl + '/api/testConnection', body, { observe: 'response' })
  }

  Admin_ScmSaveEditConnData(body): Observable<HttpResponse<any>> {

    //  console.log(body)
    // ######## Save Service ########
    return this.httpclient.post<any>(this.serviceUrl + '/api/addTool', body, { observe: 'response' })

    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })

  }

  Admin_Scm_UpdateConnData(body): Observable<HttpResponse<any>> {

    //  console.log(body)   
    return this.httpclient.post<any>(this.serviceUrl + '/api/updateTool', body, { observe: 'response' })
  }

  Admin_Jira_UpdateConnData(body): Observable<HttpResponse<any>> {
    // console.log(body)   
    return this.httpclient.post<any>(this.serviceUrl + '/api/updateTool', body, { observe: 'response' })
  }


  Admin_DevTestFrameworkSaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/saveDevTestFramework', body, { observe: 'response' })

  }


  Admin_Azure_AksSaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addNetworkConfigTool', body, { observe: 'response' })

  }

  Admin_Azure_Vdi_SaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addNetworkConfigTool', body, { observe: 'response' })

  }

  Admin_Azure_Vdi_UpdateEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addNetworkConfigTool', body, { observe: 'response' })

  }
  Admin_UpdateClientProperties(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/updateClientProperty', body, { observe: 'response' })

  }

  Admin_UpdateTenantProperties(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/updateTenantProperty', body, { observe: 'response' })

  }
  Admin_Azure_UpdateWebexProp(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/updateWebexAccessToken', body, { observe: 'response' })

  }

  Admin_Azure_Vdi_Update(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/updateNetworkConfig', body, { observe: 'response' })
  }
  Admin_Azure_VM_Update(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/updateNetworkConfig', body, { observe: 'response' })
  }

  Admin_Azure_VM_SaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addNetworkConfigTool', body, { observe: 'response' })

  }
  Admin_DomainUpdate(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/updateProjectTemplate', body, { observe: 'response' })

  }

  Admin_Azure_VM_UpdateEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addNetworkConfigTool', body, { observe: 'response' })

  }


  Admin_AzureSaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addTool', body, { observe: 'response' })

  }
  Admin_AzureUpdateData(body) {
    return this.httpclient.post(this.serviceUrl + '/api/updateAzureConfig', body);
  }

  Admin_AwsSaveData(body): Observable<HttpResponse<any>> {
    //console.log(body)

    // ######## Save Service ########
    return this.httpclient.post<any>(this.serviceUrl + '/api/addAWSworkspaceTool', body, { observe: 'response' })
  }

  Admin_CheckValidation(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/validate', body, { observe: 'response' })

  }

  Admin_CheckVDIConfigValidation(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/validateVdiDropdown', body, { observe: 'response' })

  }




  Admin_CheckToolGroupNameValidation(ToolgroupName) {

    return this.httpclient.get<any>(this.serviceUrl + '/api/validateToolgroup?tool_group_name=' + encodeURIComponent(ToolgroupName), { observe: 'response' })
  }

  Admin_CheckValidateTemplateName(TemplateName) {
    //console.log(body)

    return this.httpclient.get<any>(this.serviceUrl + '/api/validateTemplateName?templateName=' + TemplateName, { observe: 'response' })
  }
  Admin_CheckValidateSubscriptionName(TemplateName) {
    //console.log(body)

    return this.httpclient.get<any>(this.serviceUrl + '/api/validateSubscriptionName?subscription_name=' + TemplateName, { observe: 'response' })
  }
  Admin_JenkinsSaveConnData(body): Observable<HttpResponse<any>> {
    // console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addJenkinsTool', body, { observe: 'response' })
  }

  Admin_DomainSaveConnData(body): Observable<HttpResponse<any>> {
    // console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addProjectTemplate', body, { observe: 'response' })
  }

  Admin_ToolGrSaveData(body): Observable<HttpResponse<any>> {
    //console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addToolGroup', body, { observe: 'response' })
  }

  Admin_VdiDropdownConfigSaveData(body): Observable<HttpResponse<any>> {
    //  console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addDropdownValues', body, { observe: 'response' })
  }

  Admin_ToolGrUpdateData(body): Observable<HttpResponse<any>> {
    // console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/updatetoolGroupDesc', body, { observe: 'response' })
  }

  Admin_VdiDropdownUpdateData(body): Observable<HttpResponse<any>> {
    // console.log(body)
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/updateVdiDropDown', body, { observe: 'response' })
  }


  Admin_CatalogSaveData(body, mode): Observable<HttpResponse<any>> {
    // console.log(body,"In service")

    if (mode == 'Edit') {
      // ######## Edit Service ########
      return this.httpclient.post<any>(this.serviceUrl + '/api/addCatalog', body, { observe: 'response' })
    }
    else {
      // ######## Save Service ########
      return this.httpclient.post<any>(this.serviceUrl + '/api/addCatalog', body, { observe: 'response' })
    }

    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })

  }

  Admin_vdiSaveData(body): Observable<HttpResponse<any>> {

    // console.log(body);
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addToolVdi', body, { observe: 'response' })
  }
  Admin_deploymentStore_VMSaveData(body): Observable<HttpResponse<any>> {

    //  console.log(body+" In service");
    //return this.httpclient.post<any>(this.serviceUrl + '/api/addTool',body, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/addToolDeployment', body, { observe: 'response' })
  }
  Admin_testIDAws(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/iam/validate', body);

  }

  Admin_saveConfigureAwsTool(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/configTool', body);

  }

  Admin_awsToolTable() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=AWS-Config');

  }
  // User API workspace

  getAllAwsStatusWorkspace(id) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/workspace/status?project_id=' + id);
  }
  getkeyPairWorkspace(id) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-Config&tool_group=' + id);
  }
  getAllAwsStatusFilterWorkspace(id, status) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/workspace?project_id=' + id + '&status=' + status)
  }

  GetDropdownValueWorkspace() {
    return this.httpclient.get(this.serviceUrl + '/api/aws/workspace/dropdowns')
  }
  LaunchAwInstanceWorkspace(awsRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', awsRequestBody);
  }
  StartAwInstanceWorkspace(awsStartRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/workspace/start', awsStartRequestBody);
  }
  StopAwInstanceWorkspace(awsStopRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/workspace/stop', awsStopRequestBody);
  }



  //
  //Admin AWS Workspace
  Admin_getAllResourceGroupWorkspace(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ram', body);

  }
  Admin_getWorkspaceDirectoriesAccount(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/workspace/directories', body);

  }
  Admin_AddAWSWorkspaceNetworkConfigTool(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/workspace/networkConfigTool', body);

  }
  Admin_GetAWSBundlesAccount(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/workspace/bundles', body);

  }
  Admin_AddWorkspaceImageTool(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/workspaceImageTool', body);

  }
  Admin_GetAWSWorkspaceImageTools(toolgrp) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-Workspace-Image&tool_group=' + toolgrp)
  }
  Admin_GetAWSWorkspaceImageToolsNeworkConfig(toolgrp) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-Workspace-Network-Config&tool_group=' + toolgrp)
  }
  //


  //Admin AWS APIS
  Admin_awsAddEc2ImageTool(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ec2ImageTool', body);

  }

  Admin_awsRG(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ram/images', body);

  }
  Admin_awsNetworkConfigTool(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/networkConfigTool', body);

  }
  Admin_awsSecurityGroup(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ram/securityGroups', body);

  }

  Admin_awsGetAllSubnets(body) {

    // return this.httpclient.post(this.serviceUrl + '/api/aws/ram/subnets',body);
    return this.httpclient.post(this.serviceUrl + '/api/aws/EC2/subnets', body);


  }
  // Admin_awsGetAllSubnetsEC2(body){

  //   return this.httpclient.post(this.serviceUrl + '/api/aws/EC2/subnets',body);

  // }
  Admin_awsGetAllVPCs(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ram/VPCs', body);

  }
  Admin_GetAWSConfig(toolgrp) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-Config&tool_group=' + toolgrp)
  }
  //

  Admin_getResourceGroup(body) {

    return this.httpclient.post(this.serviceUrl + '/api/aws/ram', body);

  }

  // Admin_NetworkConfigToolTable(){

  //   return this.httpclient.get(this.serviceUrl + '/api/getAllTools?category=AWS-Network-Config');

  // }
  Admin_NetworkConfigToolTable(toolgrp) {

    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-Network-Config&tool_group=' + toolgrp);

  }
  Admin_CreateAmazonTable(toolgrp) {

    return this.httpclient.get(this.serviceUrl + '/api/aws/tools?tool_category=AWS-EC2-Image&tool_group=' + toolgrp);

  }
  // #####################++++++++++++++++++++++#####################################

  createVDI(user): Observable<any> {
    // console.log(user)

    return this.httpclient.get('https://remotefirstapi.azurewebsites.net/api/createSharedVdi?user_mail=' + user)

  }
  addToQueue(body) {
    // console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', body).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {
        // this._snackBar.open("InternalServer Err", "", {
        //   duration: 3000,
        // });
      }
      if (res.status === 404) {
        this._snackBar.open("Invalid Api", "", {
          duration: 3000,
        });
      }
      return throwError(res.status);
    }));
  }
  getAccessCode() {
    return this.httpclient.get(this.serviceUrl + '/api/getAccessCode')
  }
  getWebexTokenExpireTime() {
    return this.httpclient.get(this.serviceUrl + '/api/getWebexTokenExpireTime')
  }
  submitAccessCode(body) {
    return this.httpclient.post(this.serviceUrl + '/api/submitWebexCode', body)
  }
  createCluster(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/create_aks_cluster', body)
  }


  createTeamsChannel(body) {
    // console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/teams', body).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {
        // this._snackBar.open("Error while triggering Action", "Try Again Later", {
        //   duration: 3000,
        // });
        this._router.navigate(['/teams'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Creation of channel initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/teams'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/teams'])
      }
      return throwError(res.status);

    }));
  }



  createDedicatedVDI(body): Observable<any> {
    // console.log("In service dedicated ");
    //console.log(JSON.stringify(body));
    return this.httpclient.post(this.serviceUrl + '/api/createDedicatedVdi', body).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Creation of vdi initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      return throwError(res.status);

    }));

  }

  // --------------Delete Shared VDI ----------------

  offboardSharedVdi(body): Observable<any> {
    // console.log("In service - Delete Shared VDI " + JSON.stringify(body));

    return this.httpclient.post(this.serviceUrl + '/api/offboardSharedVdi', body).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      } if (res.status === 200) {
        alert(res.status);
        window.alert("Deleted Shared vdi Successfull.Status Code:" + res.status)
        this._snackBar.open("Deleted Shared vdi Successfull", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      return throwError(res.status);

    }));

  }


  // -------------------------------------------------



  createSharedVDI(body): Observable<any> {
    // console.log("In service shared ");
    //console.log(body);
    return this.httpclient.post(this.serviceUrl + '/api/createSharedVdi', body).pipe(catchError((res: HttpErrorResponse) => {
      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Creation of vdi initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/projectSummary'])
      }
      return throwError(res.status);

    }));

  }



  getImanageProjects(user_mail) {
    return this.httpclient.get(this.serviceUrl + '/api/getImanageProjects?user_mail=' + user_mail)
  }

  deleteUser(body) {
    return this.httpclient.post(this.serviceUrl + '/api/deleteRole', body);
  }

  getTeamMembers(project_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getTeamMembers?project_id=' + project_id)
  }

  createWebexTeams(body) {
    return this.httpclient.post(this.serviceUrl + '/api/createWebex', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  createChannel(body) {
    return this.httpclient.post(this.serviceUrl + '/api/createChannel', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/teamsChannel'])
      }

      return throwError(res.status);

    }));;;
  }

  getSpaces(project_name) {
    return this.httpclient.get(this.serviceUrl + '/api/onInitWebex?project_name=' + project_name).pipe(catchError((res: HttpErrorResponse) => {
      return throwError(res.status);
    }));;;
  }

  // getChannels(body){
  //   return this.httpclient.post(this.serviceUrl + '/api/onInItTeams', body).pipe(catchError((res: HttpErrorResponse )=>{
  //       return throwError(res.status);
  //   }));;;
  // }

  getChannels(body) {
    return this.httpclient.post(this.serviceUrl + '/api/onInItTeams', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.closeDialog.next("close")
        this.alertService.error("Internal Server Error", this.options);
        // this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  addMemberForSpace(body) {
    return this.httpclient.post(this.serviceUrl + '/api/assignMember', body, { responseType: 'text' }).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.closeDialog.next("close")
        this.alertService.error("Internal Server Error", this.options);
        // this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  addMemberForChannel(body) {
    return this.httpclient.post(this.serviceUrl + '/api/assignMSMember', body, { responseType: 'text' }).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.closeDialog.next("close")
        this.alertService.error("Internal Server Error", this.options);
        // this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  deleteMemberForSpace(body) {
    // console.log(body,"deleteMemberApiBody")
    return this.httpclient.post(this.serviceUrl + '/api/deleteMember', body, { responseType: 'text' }).pipe(catchError((res: HttpErrorResponse) => {
      //  console.log(res,"response")
      if (res.status === 500) {
        this.closeDialog.next("close")
        this.alertService.error(res.error, this.options);
        // this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  deleteMemberForChannel(body) {
    // console.log(body,"deleteMemberApiBody")
    return this.httpclient.post(this.serviceUrl + '/api/deleteMsMember', body, { responseType: 'text' }).pipe(catchError((res: HttpErrorResponse) => {
      //  console.log(res,"response")
      if (res.status === 500) {
        this.closeDialog.next("close")
        this.alertService.error(res.error, this.options);
        // this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }

  getMembersForSpace(spaceId) {
    return this.httpclient.get(this.serviceUrl + '/api/getMember?space_id=' + spaceId).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;
  }
  getMembersForChannel(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/viewMSMember', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/teamsChannel'])
      }

      return throwError(res.status);

    }))

  }

  deleteSpace(spaceId) {
    return this.httpclient.get(this.serviceUrl + '/api/deleteSpace?space_id=' + spaceId, { responseType: 'text' }).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }));;;

  }

  deleteChannel(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/deleteMsChannel', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/teamsChannel'])
      }

      return throwError(res.status);

    }))

  }

  setWebexMeeting(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/createwebexmeeting', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }))

  }

  setMsTeaMMeeting(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/createmsmeeting', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }))

  }

  getMSMeetingName(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/GetMSMeetingName', body)
  }
  getMSTeamNames(userEmail) {
    //  console.log(body)
    return this.httpclient.get(this.serviceUrl + '/api/lstMSTeam?userEmail=' + userEmail);
  }
  getChannelNamesforArtifacts(teamId) {
    return this.httpclient.get(this.serviceUrl + '/api/lstMSChannelList?teamId=' + teamId);
  }
  getMSArtifacts(projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/lstMSMeetingArtifacts?projectId=' + projectId)

  }
  executeMSScheduler(body) {
    return this.httpclient.post(this.serviceUrl + '/api/executeMSscheduler', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/teamsartifact'])
      }

      return throwError(res.status);

    }))
  }

  scheduleMSMeetingArtifact(body) {
    return this.httpclient.post(this.serviceUrl + '/api/meetingMSSheduleDetail', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/teamsartifact'])
      }

      return throwError(res.status);

    }))

  }

  getMeetingName(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/GetMeetingName', body)
  }
  getMeetingInfoName(meetingName) {
    return this.httpclient.get(this.serviceUrl + '/api/GetMeetingInfo?MeetingName=' + meetingName);
  }
  getMSMeetingInfo(meetingName) {
    return this.httpclient.get(this.serviceUrl + '/api/GetMSMeetingInfo?MeetingName=' + meetingName);
  }
  getTeamNames() {
    //  console.log(body)
    return this.httpclient.get(this.serviceUrl + '/api/lstwebexteam')
  }
  templateInfo(data) {
    return this.httpclient.get(this.serviceUrl + '/api/getAllProjectTemplate?template_id=' + data)
  }

  getSpaceNamesforArtifacts(body) {
    //  console.log(body)
    return this.httpclient.post(this.serviceUrl + '/api/lstwebexroom', body)
  }

  scheduleMeetingArtifact(body) {
    return this.httpclient.post(this.serviceUrl + '/api/meetingSheduleDetail', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }))

  }

  getProjectListForWebex(body) {
    return this.httpclient.post(this.serviceUrl + '/api/getprojectdetails', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }))

  }

  executeScheduler(body) {
    return this.httpclient.post(this.serviceUrl + '/api/executescheduler', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        this.alertService.error("Internal Server Error", this.options);
        this._router.navigate(['/webEx'])
      }

      return throwError(res.status);

    }))
  }

  getJiraProjectForArtifacts() {
    return this.httpclient.get(this.serviceUrl + '/api/lstjiraproject')

  }

  getArtifacts(projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/lstmeetingartifacts?projectId=' + projectId)

  }

  getVDIInfo(project_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getVdiInfo?project_id=' + project_id)
  }

  getVMProject(project_id, status) {
    return this.httpclient.get(this.serviceUrl + '/api/getDeploymentVmInfo?project_id=' + project_id + '&status=' + status)
  }
  getCostEstimate(project_id, month, year, ps) {
    // api/getCostEstimateForProject?project_id=20December2021&billing_currency=INR&month=01&year=2022
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateForProject?project_id=' + project_id + '&billing_currency=INR&month=' + month + '&year=' + year + '&requestor_id=' + ps)

    // /api/getCostEstimateForProject?project_id=20December2021&billing_currency=INR&month=7&year=2021&requestor_id=10685490
  }
  getCostEstimateJiraCost(project_id, month, year, currency) {
    // api/getCostEstimateForProject?project_id=20December2021&billing_currency=INR&month=01&year=2022
    // return this.httpclient.get(this.serviceUrl + '/api//getCostEstimateJira?project_id='+ project_id+'&billing_currency='+ currency+month='+month+'&year='+year)
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateJira?project_id=' + project_id + '&billing_currency=' + currency + '&month=' + month + '&year=' + year)


  }
  /*
  *
  *Get All projects
  *
  */
  getAllProjects() {

    // return this.httpclient.get(this.serviceUrl + '/api/getAllProjects')

    return this.httpclient.get(this.serviceUrl + '/api/getAllProjects');


  }

  deleteUserFromJira(body) {
    return this.httpclient.post(this.serviceUrl + '/api/offboardPlanningToolUsers', body);
  }

  deleteMemberGitlab(body) {
    return this.httpclient.post(this.serviceUrl + '/api/deleteMemberGitlab', body);
  }

  deleteProjectGitlab(body) {
    return this.httpclient.post(this.serviceUrl + '/api/deleteProjectGitlab', body);
  }
  softdeleteProject(body) {
    return this.httpclient.post(this.serviceUrl + '/api/softDelete', body);
  }



  restoreProjectGitlab(body) {
    return this.httpclient.post(this.serviceUrl + '/api/restore', body);
  }

  /*
  *
  *Get project By Id
  *
  */
  getProjectById(project_id) {
    //  console.log(this.serviceUrl)

    return this.httpclient.get(this.serviceUrl + '/api/getProjectById?project_id=' + project_id);


  }
  getProjectList() {
    // console.log(this.serviceUrl)
    return this.httpclient.get(this.serviceUrl + '/api/getImanageProjects');
  }

  getCatalogList() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllCatalogs');
  }

  getUserVdiInfo(project_id, mail) {
    return this.httpclient.get(this.serviceUrl + '/api/getUserVdiInfo?project_id=' + project_id + '&user_mail=' + mail)
  }

  getActualCost(actualCostBody) {
    return this.httpclient.post(this.serviceUrl + '/api/cost_estimate', actualCostBody);
  }

  getUserVerification(user_mail) {
    return this.httpclient.get(this.serviceUrl + '/api/adUserVerification?user_mail=' + user_mail);
  }

  addUsersToExistingVM(body) {
    return this.httpclient.post(this.serviceUrl + '/api/addUsersToVM', body);
  }

  getAlerts(projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/stealthWatchAlerts?status=open&project_id=' + projectId);
  }

  downloadJiraInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForJira?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadJiraInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForJira?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadJiraInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForJira?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadGitlabInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForGitLab?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadGitlabInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForGitLab?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadGitlabInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForGitLab?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVDIInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForVDI?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVDIInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForVDI?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVDIInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForVDI?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVMInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForVM?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVMInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForVM?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadVMInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForVM?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadJenkinsInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForJenkins?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadJenkinsInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForJenkins?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadJenkinsInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForJenkins?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }
  //AWS export
  downloadAwsVmInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForAwsVm?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadAwsVmInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForAwsVm?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadAwsVmInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForAwsVm?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }


  downloadAwsVdiInPdf(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getPdfForAwsVdi?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadAwsVdiInExcel(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getExcelForAwsVdi?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  downloadAwsVdiInCsv(projectName, status): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCsvForAwsVdi?project_id=' + projectName + '&status=' + status, { responseType: 'blob' });
  }

  // 
  getAlertObservation(alertId, startDate, endDate) {
    return this.httpclient.get(this.serviceUrl + '/api/alertObservationDetails?alert_id=' + alertId + '&start_date=' + startDate + '&end_date=' + endDate);
  }

  filterAlert(status, startDate, endDate, projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/stealthWatchAlertsWithFiltering?project_id=' + projectId + '&status=' + status + '&start_date=' + startDate + '&end_date=' + endDate);
  }
  filterObservation(observationType, startDate, endDate, projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/stealthWatchObservationsWithfiltering?project_id=' + projectId + '&observation_type=' + observationType + '&start_date=' + startDate + '&end_date=' + endDate);
  }
  filterComputers(ostype, startDate, endDate, projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/filterComputers?project_id=' + projectId + '&start_date=' + startDate + '&end_date=' + endDate + '&os_type=' + ostype);
  }
  filterAuditLog(username, startDate, endDate, projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/filterAuditLogs?project_id=' + projectId + '&start_date=' + startDate + '&end_date=' + endDate + '&user_name=' + username);
  }
  stealthWatchObservations(projectId) {
    return this.httpclient.get(this.serviceUrl + '/api/stealthWatchObservations?project_id=' + projectId);
  }

  getCostEstimateAzure() {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateAzure');
  }

  getCostEstimateJira(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateJira?project_id=' + id + '&billing_currency=USD');
  }

  getCostEstimateAzureExcel(id, month, year): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateAzureExcel?project_id=' + id + '&billing_currency=INR&month=' + month + '&year=' + year, { responseType: 'blob' });
  }
  getCostEstimateAzureCsv(id, month, year): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateAzureCsv?project_id=' + id + '&billing_currency=INR&month=' + month + '&year=' + year, { responseType: 'blob' });
  }
  getCostEstimateAzurePdf(id, month, year): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateAzurePdf?project_id=' + id + '&billing_currency=INR&month=' + month + '&year=' + year, { responseType: 'blob' });
  }

  getCostEstimateJiraExcel(id): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateJiraExcel?project_id=' + id + '&billing_currency=USD', { responseType: 'blob' });
  }
  getCostEstimateJiraCsv(id): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateJiraCsv?project_id=' + id + '&billing_currency=USD', { responseType: 'blob' });
  }
  getCostEstimateJiraPdf(id): Observable<Blob> {
    return this.httpclient.get(this.serviceUrl + '/api/getCostEstimateJiraPdf?project_id=' + id + '&billing_currency=USD', { responseType: 'blob' });
  }
  getSummaryDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportcountDetail?project_id=' + id);
  }
  getComputersReportData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getComputers?project_id=' + id);
  }
  getVdiAuditLogData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getAuditLogs?project_id=' + id);
  }
  getVdiVMDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforvdivm?project_id=' + id);
  }
  getVdiDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforvdi?project_id=' + id);
  }
  getVmDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforVm?project_id=' + id);
  }
  getJenkinsDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforjenkins?project_id=' + id);
  }
  getSCMDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforscm?project_id=' + id);
  }
  getGigetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/admin-gig?id=' + id);
  }
  getGigVdiImagesData(resource, type) {
    return this.httpclient.get(this.serviceUrl + '/gig/resource/images?resource=' + resource + '&type=' + type);
  }
  getJiraDetailsData(id) {
    return this.httpclient.get(this.serviceUrl + '/api/reportdetailsforjira?project_id=' + id);
  }
  getCatalogProjectById(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectById?project_id=' + id)
  }
  getProjectByIdWithTeamMemberStatus(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectByIdWithTeamMemberStatus?project_id=' + id)
  }

  getCatalogProjectByIdVdi(id, template, role) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectMemberByRole?project_id=' + id + '&template_id=' + template + '&template_role_name=' + role)
  }
  getPolicyInfoByName(policyName) {
    return this.httpclient.get(this.serviceUrl + '/api/getPolicyDetails?policy_name=' + policyName);
  }
  generateToken(body) {
    return this.httpclient.post(this.serviceUrl + '/api/generateJwtAccessToken', body)
  }
  generateTokengig(body) {
    return this.httpclient.post(this.serviceUrl + '/api/external/generateJwtAccessToken', body)
  }


  saveToCatalog(projectBody) {
    return this.httpclient.post(this.serviceUrl + '/api/saveProjectCatalogue', projectBody)
  }

  //Azzure service
  createAzzureProject(body) {
    return this.httpclient.post(this.serviceUrl + '/api/createAzureDevopsProject', body);
  }

  getCatalogById(id, type) {
    return this.httpclient.get(this.serviceUrl + '/api/getToolsCatalogById?catalog_id=' + id + '&tool_category=' + type);
  }

  //get information of single catalog
  getCatalogInformation(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getToolGroupsByCatalogId?catalog_id=' + id);
  }
  //aws api user panel

  getAwsEc2Status(id) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/status?project_id=' + id);
  }
  getAllAwsEc2Status(id) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/status?project_id=' + id);
  }
  getAllAwsEc2StatusFilter(id, status) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/ec2?project_id=' + id + '&status=' + status)
  }
  GetAllAWSComputePurposes() {
    return this.httpclient.get(this.serviceUrl + '/api/aws/ec2/getPurpose')
  }
  GetDropdownValueEC2(purpose) {
    return this.httpclient.get(this.serviceUrl + '/api/aws/ec2/aws_dropDowns?purpose=' + purpose)
  }
  LaunchAwInstance(awsRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', awsRequestBody);
  }
  getkeyPairDataWorkspace(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/ram/keypairs', body);
  }
  getVnetGraphDetails(body) {
    return this.httpclient.post(this.serviceUrl + '/api/ProjectVnetLimit', body);
  }
  getMonthlyGraphDetails(projectId,month,year) {
    return this.httpclient.get(this.serviceUrl + '/api/getMonthlyProjectCost?project_id=' + projectId + '&month=' + month + '&year=' + year);
  }
  getResourceGraphDetails(resourceName,costType,duration,from,to) {
    return this.httpclient.get(this.serviceUrl + '/api/resourceGroupCostsSummary?resourceGroupName=' + resourceName + '&costType=' + costType + '&duration=' + duration + '&from=' + from + '&to=' + to);
  }
  getDailyMonthlyMachineDetails(projectId,month,year) {
    return this.httpclient.get(this.serviceUrl + '/api/getMachineCost?resourceName=' + projectId + '&month=' + month + '&year=' + year);
  }
  StartAwInstance(awsStartRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/ec2/start', awsStartRequestBody);
  }
  StopAwInstance(awsStopRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/ec2/stop', awsStopRequestBody);
  }
  //

  //add new member to existing project
  addNewMember(body) {
    return this.httpclient.post(this.serviceUrl + '/api/addUpdateUser', body);
  }

  // add new role with member
  addNewRole(body) {
    return this.httpclient.post(this.serviceUrl + '/api/addProjectRole', body);
  }


  /*
  Sent cast email
  */
  sendCastEmail(body) {
    return this.httpclient.post(this.serviceUrl + '/api/sendMail', body);
  }

  refreshGitlab(projectId, status) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectByIdWithUpdatedStatus?project_id=' + projectId + '&status=' + status);
  }

  refreshJenkins(projectId, status) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectByIdforJenkinsByStatus?project_id=' + projectId + '&status=' + status);
  }

  createJiraProject(projectBody) {
    // console.log(projectBody);
    //return this.httpclient.post(this.serviceUrl + '/api/createJiraProject', projectBody);
    return this.httpclient.post(this.serviceUrl + '/api/createJiraProject', projectBody).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/workBench'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Creation of project initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      return throwError(res.status);

    }));
  }


  onBoardJiraUser(body) {
    return this.httpclient.post(this.serviceUrl + '/api/createJiraProject', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Onboarding initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      return throwError(res.status);

    }));
  }



  offBoardJiraUser(body) {
    return this.httpclient.post(this.serviceUrl + '/api/offboardPlanningToolUsers', body).pipe(catchError((res: HttpErrorResponse) => {

      if (res.status === 500) {
        // window.alert("Internal Server Error.Status Code:"+res.status)
        this._snackBar.open("Error while triggering Action", "Try Again Later", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      } if (res.status === 200) {
        // window.alert("Creation of vdi Successfull.Status Code:"+res.status)
        this._snackBar.open("Offboarding initiated", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      if (res.status === 400) {
        // window.alert("Select necessary parameters..Status Code:"+res.status)
        this._snackBar.open("Wrong Input", "", {
          duration: 3000,
        });
        this._router.navigate(['/storyBoard'])
      }
      return throwError(res.status);

    }));
  }


  syncImanageProjectDetails(projectSummary) {
    //  console.log(projectSummary);

    return this.httpclient.post(this.serviceUrl + '/api/syncProject', projectSummary);
  }

  syncImanageTeamMembers(projectSummary) {
    // console.log(projectSummary);
    return this.httpclient.post(this.serviceUrl + '/api/syncTeamMembers', projectSummary);
  }

  createInsightsProject(insightsBody) {
    return this.httpclient.post(this.serviceUrl + '/api/createUser', insightsBody);
  }
  getVdiCost() {
    return this.httpclient.get(this.serviceUrl + '/api/cost_of_vm?vm_type=D2 v3/D2s v3&location=IN Central&duration=730&VM_os=Windows')
  }
  deleteInsightsUsers(body) {
    return this.httpclient.post(this.serviceUrl + '/api/deleteUser', body);
  }
  checkProjectNameAvailibility(project_name) {
    return this.httpclient.get(this.serviceUrl + '/api/checkIfProjectNameAvailable?name=' + project_name);
  }
  createVm(vmRequestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', vmRequestBody);
  }
  createJenkins(requestBody) {
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', requestBody);
  }
  getToolById(id) {
    return this.httpclient.get(this.serviceUrl + '/api/getToolById?tool_id=' + id);
  }
  getTagsInfo(tool_id) {
    return this.httpclient.get(this.serviceUrl + '/api/iconInfo?tool_id=' + tool_id);
  }
  getAllFrameworks() {
    return this.httpclient.get(this.serviceUrl + '/api/getAllDevTestFramework');
  }
  createVdi(personalBody) {
    return this.httpclient.post(this.serviceUrl + '/api/sendCanvasRequest', personalBody);
  }
  toggleVDI(personalBody) {
    return this.httpclient.post(this.serviceUrl + '/api/vdi_custom_schedule', personalBody);
  }
  deleteVdi(body) {
    return this.httpclient.post(this.serviceUrl + '/api/delete_vdi', body);
  }
  getVdiOnStatus(projectId, status) {
    return this.httpclient.get(this.serviceUrl + '/api/getVdiOnStatus?project_id=' + projectId + '&status=' + status);
  }



  //Dashboard api call
  getDashboardData() {
    return this.httpclient.get('./assets/dashBoardData.json').pipe(map((resp: any) => { return resp; }))
  }
  getsample() {
    return this.httpclient.get('./assets/sample.json').pipe(map((resp: any) => { return resp; }))
  }
  getEmail() {
    return this.httpclient.get('./assets/emailDetail.json').pipe(map((resp: any) => { return resp; }))
  }


  //Tools data api call
  // getToolsData(data:any):Observable<HttpResponse<any>>{
  //   return this.httpclient.post<any>(this.serviceUrl + '/path', data )
  // }

  startVdi(body) {
    return this.httpclient.post(this.serviceUrl + '/api/start_vdi', body);
  }
  stopVdi(body) {
    return this.httpclient.post(this.serviceUrl + '/api/stop_vdi', body);
  }

  //create aws vdi
  createAWSVdi(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws_vdi_create', body);
  }
  getAWSVdi(project_id) {
    return this.httpclient.post(this.serviceUrl + '/api/aws_vdi_describe', project_id);
  }

  updateProjectManager(body) {
    return this.httpclient.post(this.serviceUrl + '/api/changePrimaryPM', body);
  }

  //get Icons for disabling in User Panel
  getIconsForDisable(body) {
    //return this.httpclient.get(this.serviceUrl + '/api/getAllDemoDevIcons');
    return this.httpclient.post(this.serviceUrl + '/api/getAllIconsByProjectId', body);
  }
  statusUpdate(project_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getAzureStatusForVdi?project_id=' + project_id);
  }
  //VDI dropdowns comment
  getDropdowns(body) {
    //return this.httpclient.get(this.serviceUrl + '/api/getAllDropdownValuesForUserPanel');
    return this.httpclient.post(this.serviceUrl + '/api/getAllDropdownValuesForUserPanel', body);
  }
  //get jira projects
  getJiraProjects(project_id, status) {
    return this.httpclient.get(this.serviceUrl + '/api/getProjectByIdforJira?project_id=' + project_id + '&status=' + status);
  }

  //refresh status for VM
  refreshVMStatus(project_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getAzureStatusForVm?project_id=' + project_id);
  }
  //validate vm name
  validateVMname(vmName) {
    return this.httpclient.post(this.serviceUrl + '/api/validateDeploymentName', vmName);
  }

  //add new member to existing gitlab repo
  addMemberinExisingProject(scmBody) {
    return this.httpclient.post(this.serviceUrl + '/api/addMemberinExisingProject', scmBody);
  }



  //stop vm
  stopVM(body) {
    return this.httpclient.post(this.serviceUrl + '/api/stopVM', body);
  }

  //start VM
  startVM(body) {
    return this.httpclient.post(this.serviceUrl + '/api/startVM', body);
  }

  //Start AWS
  startWorkspace(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws_vdi_start', body);
  }
  //Stop AWS
  stopWorkspace(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws_vdi_stop', body);
  }
  //add to VDI
  addMemberinExisingVDI(body) {
    return this.httpclient.post(this.serviceUrl + '/api/addMemberstoShared', body);
  }
  //add to Ec2
  addMemberinExisingEc2(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/ec2/users/add', body);
  }

  //delete from VDI
  deleteMemberFromExisingVDI(body) {
    return this.httpclient.post(this.serviceUrl + '/api/delete_mem_shared_vdi', body);
  }

  //delete from ec2

  deleteMemberFromExisingEc2(body) {
    return this.httpclient.post(this.serviceUrl + '/api/aws/ec2/users/delete', body);
  }
  //deleteVM
  deleteVM(body) {
    return this.httpclient.post(this.serviceUrl + '/api/delete_vm', body);
  }
  //delete members from VM
  deleteMemberFromVM(body) {
    return this.httpclient.post(this.serviceUrl + '/api/deleteUsersFromVM', body);
  }

  deleteJiraProject(body) {

    return this.httpclient.post(this.serviceUrl + '/api/offboardPlanningTool', body);

  }
  restoreTools(body) {
    return this.httpclient.post(this.serviceUrl + '/api/restoreSoftDeletedTool', body);
  }
  deleteJenkinsProj(body) {

    return this.httpclient.post(this.serviceUrl + '/api/deleteJenkinsProject', body);

  }
  //deleteMemberFromProject
  deleteMemberFromProject(body) {
    return this.httpclient.post(this.serviceUrl + '/api/offboardTeamMember', body);

  }
  deleteMemberFromProjectTest(body) {
    return this.httpclient.post(this.serviceUrl + '/api/offboardTeamMemberTest', body);

  }
  //memberInfo
  getMemberOnboardedInTools(body) {
    return this.httpclient.post(this.serviceUrl + '/api/getMemberInfo', body);
  }
  getMemberInfoForTool(body) {
    return this.httpclient.post(this.serviceUrl + '/api/getMemberInfoForTool', body);
  }
  
  //getAllTemplateRoles
  getTemplateRoles(template_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getAllTemplateRoles?template_id=' + template_id);
  }


  getActiveVDI(project_id, month, year) {
    return this.httpclient.get(this.serviceUrl + '/api/getActiveVdiInProject?project_id=' + project_id + '&month=' + month + '&year=' + year);
  }

  getChartData(project_id, vdi_type, request_id, requestor_id) {
    return this.httpclient.get(this.serviceUrl + '/api/getVdiUserStatusForProject?project_id=' + project_id + '&vdi_type=' + vdi_type + '&request_id=' + request_id + '&requestor_id=' + requestor_id);
  }
  jwtTokenLogout(awsStopRequestBody) {



    // return this.httpclient.post(this.serviceUrl + 'api/jwt/logout', awsStopRequestBody);

    return this.httpclient.post<any>(this.serviceUrl + '/api/jwt/logout', awsStopRequestBody, { observe: 'response' })

  }



  //getJiraCostInfoPerMember
  getJiraCostInfoPerMember(projectid, user_name, month, year) {

    return this.httpclient.get(this.serviceUrl + '/api/getUserDetailsforJiraInstance?project_id=' + projectid + '&user_name=' + user_name + '&month=' + month + '&year=' + year);
  }

  //change role access
  changeRole(body) {
    return this.httpclient.post(this.serviceUrl + '/api/changeAdminStatus', body);
  }

  //getRecycleBin
  getDeletedRequests(project_id, tool_name) {
    return this.httpclient.get(this.serviceUrl + '/api/softDeletedTools?project_id=' + project_id + '&tool_name=' + tool_name);
  }

  Admin_Client_SaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addAdminCredential', body, { observe: 'response' })

  }

  Admin_CheckClientValidation(ClientValue) {

    return this.httpclient.get<any>(this.serviceUrl + '/api/validateCredentials?global_id=' + ClientValue, { observe: 'response' })

  }

  Admin_Tenant_SaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addAccessToken', body, { observe: 'response' })

  }

  Admin_CheckTenantValidation(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/validateAccessToken', body, { observe: 'response' })

  }
  Admin_TenantrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getAccessTokenData')
  }

  Admin_ClientTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getClientData')
  }

  Admin_WebX_SaveEditConnData(body): Observable<HttpResponse<any>> {

    return this.httpclient.post<any>(this.serviceUrl + '/api/addWebexAccessToken', body, { observe: 'response' })

  }


  Admin_WebXGrTableDetails() {
    return this.httpclient.get(this.serviceUrl + '/api/getWebexData')
  }

  Admin_WebXValidateTableDetails(ClientValue) {
    return this.httpclient.get(this.serviceUrl + '/api/validateWebexData?client_name=' + ClientValue, { observe: 'response' })
    return this.httpclient.post<any>(this.serviceUrl + '/api/validateCredentials?global_id=' + ClientValue, { observe: 'response' })
  }

  Admin_Domain_ProjectTableDetails() {

    return this.httpclient.get(this.serviceUrl + '/api/getAllProjectTemplate')
  }

  getMemberStatusForProject(projectName) {
    return this.httpclient.get(this.serviceUrl + '/api/memberStatusForProject?project_id=' + projectName)
  }
  installSoftwareListVDI(body) {
    // return this.httpclient.get(this.serviceUrl + '/api/software?os=' + os + '&task='+task)
    return this.httpclient.post<any>(this.serviceUrl + '/api/software', body, { observe: 'response' })

  }
  installSoftwareListVDISIT(os,task) {
    return this.httpclient.get(this.serviceUrl + '/api/software?os=' + os + '&task='+task)
    // return this.httpclient.post<any>(this.serviceUrl + '/api/software', body, { observe: 'response' })

  }
  uninstallSoftwareListVDI(body) {
    // return this.httpclient.get(this.serviceUrl + '/api/software?os=' + os + '&task='+task)
    return this.httpclient.post<any>(this.serviceUrl + '/api/software/installed', body, { observe: 'response' })

  }
  installSoftwareList(os,task) {
    return this.httpclient.get(this.serviceUrl + '/api/software?os=' + os + '&task='+task)
    // return this.httpclient.get<any>(this.serviceUrl + '/api/software', body)

  }
  postInstallSoftware(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/sendCommand', body, { observe: 'response' })
  }
  postRequestSoftware(body): Observable<HttpResponse<any>> {
    return this.httpclient.post<any>(this.serviceUrl + '/api/software/request', body, { observe: 'response' })
  }
}

