<!-- @author-priyanka kale -created new component for project details page  -->
<!-- <div class="project-fluid"> -->
    <!-- <div class="contain-fluid"> -->
    <div class="project-summary">
       <div class="sub-nav-bar mb-4" style="height: auto;">
            <div class="row">
                <div class="col-md-12 details">
                    <div class="col-md-9 vLine pull-left">
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <h2 class="projectName">Project ID : <span class="projectDetail">
                                        {{projectId}}</span>
                                    <span class="material-icons show-project-info" title="System Information"
                                        (click)="showProjectInfo()">info</span>
                                </h2>

                            </div>
                        </div>

                    </div>
                    <div class="col-md-2 text-md-right pull-right position-icon">
                        <!-- <div> -->
                            <!-- <mat-icon (click)="syncImanageTeamMemberProject()" class="refresh-icon" aria-hidden="false"
                                aria-label="Example home icon" title="Sync data from IManage">sync</mat-icon -->

                                <mat-icon class="border_none" class="home-back color-white" title="Home" aria-hidden="false"
                                color="primary" routerLink="/projectSummary" aria-label="Example home icon">home
                            </mat-icon>
                        <!-- </div> -->
                    </div>
                </div>
            </div>

        </div>
        <div class="container">
          
            <div class="canvas-card tab-card">
                <div class="py-5">
                    <table class="table table-bordered" style="text-align: left;margin-top: -2vh;">
                        <tr style="background-color: #3d5caf;color: white;text-decoration: solid;">
                            <th>Particulars</th>
                            <th>Details</th>
                        </tr>
                        <tr>
                            <td>Project Id</td>
                            <td>{{projectId}}</td>
                        </tr>
                        <tr>
                            <td>Project Name</td>
                            <td>{{projectName}} </td>
                        </tr>
                        <tr *ngIf="pmName">
                            <td>PM Name</td>
                            <td>{{pmName}}</td>
                        </tr>
                        <!--tr>
                    <td>KDM Name</td>
                    <td>-</td>
                </tr-->
                        <tr *ngIf="actuStartDate">
                            <td>Actual Start Date</td>
                            <td>{{actuStartDate}}</td>
                        </tr>
                        <tr *ngIf="actuEndDate">
                            <td>Actual End Date</td>
                            <td>{{actuEndDate}}</td>
                        </tr>
                        <tr *ngIf="status">
                            <td>Status</td>
                            <td>{{status}}</td>
                        </tr>
                        <tr *ngIf="type">
                            <td>Type</td>
                            <td>{{type}}</td>
                        </tr>
                        <tr *ngIf="customerName">
                            <td>Customer Name</td>
                            <td>-{{customerName}}</td>
                        </tr>
                        <tr *ngIf="deliveryBU">
                            <td>Delivery Bu</td>
                            <td>{{deliveryBU}}</td>
                        </tr>
                    </table>
                </div>
                <!-- <footer>
                    <p>Powered By LTI</p>
                </footer> -->
            </div>
     
        </div>
    </div>
    <footer>
        <p>Powered By &nbsp;
          <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" >
           <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
          </p>
      </footer>
