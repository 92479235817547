<!-- @author - priyanka kale - added bootstrap css file paths, jquery .js bootstrap.js file path for modal -->


  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js" integrity="894YE6QWD5I59HgZOGReFYm4dnWc1Qt5NtvYSaNcOP+u1T9qYdvdihz0PPSiiqn/+/3e7Jo4EaG7TubfWGUrMQ==" crossorigin="anonymous"></script>
 
<!-- path end  -->

<div class="member-card">
    <div class="row ">
      <div class="offset-md-11 col-md-1">
        <span class="material-icons" mat-dialog-close title="close" style="float: right;cursor: pointer;" (click)="exit()">
          close
        </span>
      </div>
    </div>
    
    <div class="row mt-3">
        <!--div class="col-12 col-md-6 configuration-items mt-3">
            <img src="../../../assets/img/azure- cluster.png" height="60" width="60"><br><br>
            <button mat-raised-button style="width:100px; outline: none;" class="border_none text-center">AKS cluster</button>
        </div--> <!--  (click)="selectedOption('cluster')"-->
        <div class="col configuration-items mt-3" *ngIf="!deploymentFlag">
            <img src="../../../assets/img/azure-vm.png" height="60" width="60"><br><br>
            <button mat-raised-button style="width:100px; outline: none;" (click)="selectedOption('VM')" class="border_none text-center mb-3">VM</button>
        </div>
        <div class="col configuration-items mt-3" *ngIf="deploymentFlag">
          <img src="../../../assets/img/azure-vm.png" height="60" width="60"><br><br>
          <button  data-toggle="modal" data-target="#myModal" mat-raised-button style="width:100px; outline: none;" class="border_none text-center mb-3">VM</button>

          
      </div>
      
       
    </div>
    
  
  </div>
 
  <div class="modal fade" id="myModal"  role="dialog">
    <div class="modal-dialog" style="margin: 15% auto;">
    
      <!-- Modal content-->
      <div class="modal-content modal-adjust" >
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <h2 class="text-center">No tool instance Available</h2>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      
    </div>
  </div>