<div class="pop-up">
    <div class="">
        <span class="vertical-line"> </span>
        <span class="header mb-0">Gitlab Detail View</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>

    <hr class="margin-class" />
    <div class="popup-container" *ngIf="screen === 'usage'">
        <div class="row">
            <div class="col-sm-4">
                <div class="field-name">Gitlab Username</div>
                <div>{{detailData.gitlab_repo_members[0].gitlab_username || "-"}}</div>
            </div>
            <div class="col-sm-3">
                <div class="field-name">User Name</div>
                <div>{{detailData.gitlab_repo_members[0].user_name || "-"}}</div>
            </div>
            <div class="col-sm-5">
                <div class="field-name">Email</div>
                <div>{{detailData.gitlab_repo_members[0].email || "-"}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="field-name">Role</div>
                <div>{{detailData.gitlab_repo_members[0].role || "-"}}</div>
            </div>
            <div class="col-sm-8">
                <div class="field-name">WVD Version</div>
                <div>{{detailData.gitlab_repo_url || "-"}}</div>
            </div>
        </div>
    </div>
    
      <div class="popup-container" *ngIf="screen === 'resource'">
        <div class="table-responsive  mt-2" >
        <table  datatable [dtOptions]="dtOptions" class="table fix-table">
            <thead>
              <tr>
               
                <th>Gitlab Username</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>WVD Version</th>
              </tr>
            </thead>
            <tbody>
            <tr class="break__word">
             
              <td class="w-20">{{detailData.gitlab_repo_members[0].gitlab_username || "-"}}</td>
              <td class="w-20">{{detailData.gitlab_repo_members[0].user_name || "-"}}</td>
              <td class="w-20">{{detailData.gitlab_repo_members[0].email || "-"}}</td>
              <td class="w-20">{{detailData.gitlab_repo_members[0].role || "-"}}</td>
              <td class="w-20">{{detailData.gitlab_repo_url || "-"}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
</div>