import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { error } from '@angular/compiler/src/util';
import { Subject } from 'rxjs';	
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface configList {
  toolName: string;
  ID: string;
  db_id:string;
  email: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}


export interface cicdDetails {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}
export interface jenkinsTool {
  toolName: string;
  ID: string;
  db_id:string;
  name: string;
  description: string;
  projectURL: string;
  email:string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}
export interface jiraTool {
  toolName: string;
  ID: string;
  db_id:string;
  name: string;
  description: string;
  projectURL: string;
  email:string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}


@Component({
  selector: 'app-admin-jenkins',
  templateUrl: './admin-jenkins.component.html',
  styleUrls: ['./admin-jenkins.component.scss']
})
export class AdminJenkinsComponent implements OnInit,CanComponentLeave {

  //toolName:any;
  toolDescription:any;
  model:any={}; 
  //formMode:string = "";
  masterSelected: boolean;

  testConnDisable = false;
  saveConnDisable = true;
  UrlDisable=false;
  PrivateDisable=false;
  EmailDisable=false;
  project_selectedcheckbox = false;

  //submitted = false;

  // Variable for Save/Edit body
  project_Token: string;
  project_URL: string;
  project_toolGroupName: string;
  project_id_JenkinsEdit: string;
  project_id_db:string;
  project_nameInstance: string;
  project_description: string;
  project_jenkinsEmail: string;
  project_id_scmEdit: string;
  project_toolId:String;
  project_toolGrId: string;


  token: any;
  //projectURLVal: any;
  toolName: any;

  jenkins: any = {};

  jenkinsToolDetails: jenkinsTool;
  selectedToolvalue: any;
  checkedList: any;
  VdiConfig_details:any=[];
  check:any=[]



  formMode: string = "";
  checklist: any;

  toolGr: any;
  proURL: any;
  proToken: any;
  nameInstance:any;
  description:any;
  jiraEmail:any;
  project_toolGrName: string;
  ToolGroupList = [];

  submitted = false;
  //dialogCheck: boolean= true;
  showJenkin: boolean =true;
  cicdArray = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  showForm:boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  storyBoardObj: object[];
  UpdateConnDisable = true;
  editMode: boolean = false;
  ToolGroupDisable=false;
  ToolInstanceNameDisable =false
  Role:string="";
  itemSelected:boolean=false;
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-story-board"){
      return true
    }
    else{
      if((this.jenkins.proURL == '' || this.jenkins.proURL == undefined) &&
      (this.jenkins.toolGr == '' || this.jenkins.toolGr == undefined) &&
       (this.jenkins.description == '' || this.jenkins.description == undefined)  &&
       (this.jenkins.jenkinstoolGR == '' || this.jenkins.jenkinstoolGR == undefined)  &&
       (this.jenkins.jenkinsEmail == '' || this.jenkins.jenkinsEmail == undefined)  &&
        (this.jenkins.nameInstance == '' || this.jenkins.nameInstance == undefined)
         && (this.jenkins.proToken == '' || this.jenkins.proToken == undefined)){
        return true
      }
      if ((this.jenkins.proURL != '' || this.jenkins.proURL != undefined) &&
      (this.jenkins.toolGr != '' || this.jenkins.toolGr != undefined) &&
       (this.jenkins.description != '' || this.jenkins.description != undefined)  &&
       (this.jenkins.jenkinstoolGR != '' || this.jenkins.jenkinstoolGR != undefined)  &&
       (this.jenkins.jenkinsEmail != '' || this.jenkins.jenkinsEmail != undefined)  &&
        (this.jenkins.nameInstance != '' || this.jenkins.nameInstance != undefined)
         && (this.jenkins.proToken != '' || this.jenkins.proToken != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,
    private spinner: NgxSpinnerService, public alertService: AlertService ) { 
      this.masterSelected = false;

    }

    ELEMENT_DATA: cicdDetails[];
    displayedColumns: string[] = ['toolName', 'ID', 'projectURL', 'email', 'toolGroup', 'select'];
   // dataSource = new MatTableDataSource<cicdDetails>(this.ELEMENT_DATA);
    selection = new SelectionModel<cicdDetails>(true, []);

  ngOnInit(): void {    
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
      this.dtOptions = {
        order: []
      };
    var PopupToolGroup = localStorage.getItem('Popup_Open_Jenkins')
    if(PopupToolGroup=="true"){
      this.showJenkinsPage();
      this.addJenkins(); 
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Jenkins')
      this.jenkins.jenkinstoolGR = Popup_NewToolGroupID;
      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Jenkins')
      this.project_toolGroupName  = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Jenkins", "false");
    }else{
      this.hideJenkin();
      var Refresh_Jira = localStorage.getItem('Jenkins')
      if(Refresh_Jira=="true"){
      this.showJenkinsPage();
      localStorage.setItem("Jenkins", "false");
      }
   
    }
    this.getTypeT();
    this.GetCICDTableDetails(); 
    }
    else{
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home']);
    });
    }
  }

  refreshJenkins(){
    this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-jenkins']);
  });
  }

  backNavigation(){
    if ((this.jenkins.proURL == '' || this.jenkins.proURL == undefined) &&
     (this.jenkins.toolGr == '' || this.jenkins.toolGr == undefined) &&
      (this.jenkins.description == '' || this.jenkins.description == undefined)  &&
      (this.jenkins.jenkinstoolGR == '' || this.jenkins.jenkinstoolGR == undefined)  &&
      (this.jenkins.jenkinsEmail == '' || this.jenkins.jenkinsEmail == undefined)  &&
       (this.jenkins.nameInstance == '' || this.jenkins.nameInstance == undefined)
        && (this.jenkins.proToken == '' || this.jenkins.proToken == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
   // debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  
  DeleteConfig(){
    // debugger;
     var deleteBody: any;
     this.check=[]
     this.VdiConfig_details.forEach(element => {
       this.check.push(element.vdi_dropdown_id)
       
     });
     deleteBody = {
       "tool_ids":this.check
    
     }
    
     this.apiService.Admin_JenkinsDropdowDeleteData(deleteBody).subscribe((res:any) => {

       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-jenkins']);
         });
        //  this.alertService.success("Data Delete Successfully", this.options);
        this.alertService.success(res.body.message, this.options); 

       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }
  GetCICDTableDetails() {
    // 
    this.spinner.show();
 
     this.apiService.Admin_CICDTableDetails().subscribe((res: any) => {
      // 
       //console.log(res)
      // debugger;
       res.forEach(member => {
         let obj = {
           "toolName": member.tool_name,
           "ID": member.tool_id,
           "db_id":member._id,
           "projectToken": member.tool_instance_details.cicd_tool_instance.tool_auth.auth_token,
           "projectURL": member.tool_instance_details.cicd_tool_instance.tool_url,
           "email": member.tool_instance_details.cicd_tool_instance.tool_auth.auth_mail,
           "name": member.tool_instance_details.cicd_tool_instance.tool_instance_name,
           "description": member.tool_instance_details.cicd_tool_instance.tool_description,
           "toolGroup": member.tool_group_details.tool_group_name,
           "toolGroup_id": member.tool_group_details.tool_group_id
           //  [11].tool_groups[0].tools[0]
 
         }
         this.cicdArray.push(obj)
         this.checklist = this.cicdArray;

       })
      // console.log(this.cicdArray)
     
      this.spinner.hide();
       this.dtTrigger.next();
     }
     , (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
     // SCMArray
   }
 
 
   ngOnDestroy(): void {    
     this.dtTrigger.unsubscribe();
   }

  showJenkinsPage(){
    //this.dialogCheck = false;
    this.showJenkin = true;
  }

  addJenkins(){
    // debugger
    // console.log("hi");
    
    this.showForm= true;
    this.editMode=false;
    this.ToolGroupDisable=false;
    this.ToolInstanceNameDisable=false;
    this.clearAllJenkinsDetails();
  }
  cancelok(){
    this.clearAllJenkinsDetails();
    localStorage.setItem("refreshJira", "true");
  //   this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
  //     this.router.navigate(['/admin-jira']);
  // });
  if(this.nextUrl==undefined){
    this.refreshJenkins()
  }
  else{
    this.router.navigate([this.nextUrl])

  }
  
  }
  hideJenkin(){
    this.showForm=false;
    this.editMode = false;
    this.testConnDisable=false;
    this.saveConnDisable=true;
    this.UpdateConnDisable=true;
    this.ToolGroupDisable=false;
    this.ToolInstanceNameDisable=false;
    //this.clearAllJenkinsDetails();
  }

  getRecord(row) {
    // debugger
    
    // console.log(row);
    this.addJenkins();
     this.setJenkinEditing(row);
     this.testConnDisable=false;
     this.editMode = true;
     this.ToolGroupDisable=true
     this.ToolInstanceNameDisable = true;
     this.UpdateConnDisable = true;
   }

   setJenkinEditing(jiraObj: jiraTool){
//debugger;
    this.jenkins.jenkinstoolGR = jiraObj.toolGroup_id;
    this.jenkins.proURL = jiraObj.projectURL;
    this.jenkins.nameInstance = jiraObj.name;
    this.jenkins.description = jiraObj.description;
    this.jenkins.jenkinsEmail = jiraObj.email;
    this.jenkins.proToken = jiraObj.projectToken;
    this.project_id_scmEdit = jiraObj.ID;
    this.project_id_db =jiraObj.db_id;
    this.project_toolId = jiraObj.ID
    this.project_toolGrId = jiraObj.toolGroup_id;
    this.project_toolGroupName= jiraObj.toolGroup;
   // this.project_toolGrName== jiraObj.toolGroup;
   }

  getTypeT(): void {
    //
    this.spinner.show();
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        
       // 
        //  this.powers = data;
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
        //  console.log(data.body[i].tool_group_name);
          if (data.body[i].tool_group_name != undefined) {
           // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
        this.spinner.hide();
      }
    }
    ,(error: HttpErrorResponse) => {
     // 
    
      if (error.status == 500)
      {
        this.alertService.error("Please Enter Valid Inputs", this.options);       
      }
      else if (error.status == 403){}
      else {
        this.alertService.error("Invalid Input", this.options);       
        throw error;
      }
      this.spinner.hide();
    })
  }




  selectedTool(e) {

  }
  validateEmail(email) {
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
   }

  onSubmit() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    // debugger
    //
   

   // console.log(this.jenkins.proURL);

    if (this.jenkins.proURL == undefined || this.jenkins.jenkinstoolGR == undefined
      ||this.jenkins.nameInstance == undefined || this.jenkins.description == undefined
      ||this.jenkins.jenkinsEmail == undefined || this.jenkins.jenkinstoolGR == "" || this.jenkins.jenkinstoolGR == " " 
      || this.jenkins.proToken == undefined  || this.jenkins.jenkinsEmail == " " || this.jenkins.description == " " || this.jenkins.nameInstance == " "
      || this.jenkins.proURL == " " || this.jenkins.proToken == " "
      || this.jenkins.jenkinstoolGR == "" || this.jenkins.jenkinstoolGR == "" 
      || this.jenkins.proToken == undefined  || this.jenkins.jenkinsEmail == "" || this.jenkins.description == "" || this.jenkins.nameInstance == ""
      || this.jenkins.proURL == "" || this.jenkins.proToken == "") {
        this.alertService.error("Please enter details In all the fields", this.options);    
        setTimeout( ()=>{
          this.testConnDisable = false;
          }, 2500)
        
    }
    else if(this.jenkins.description.length<20 || this.jenkins.nameInstance.length<3 || this.jenkins.nameInstance>20 || this.jenkins.description.length>50 || (!this.validateEmail(this.jenkins.jenkinsEmail))|| sp.test(this.jenkins.nameInstance)){
      this.testConnDisable = true;

      // this.alertService.error("Please enter minimum 20 characters for Description ", this.options);
      setTimeout(() => {
        this.testConnDisable = false;
      }, 2)

          }
    else {

      //
      if (!this.validateEmail(this.jenkins.jenkinsEmail))
      {
        this.alertService.error("Jenkins Email not valid", this.options);  
        return;
      }
      var url: string = this.jenkins.proURL;
      var nameInstance: string = this.jenkins.nameInstance;
      var description: string = this.jenkins.description;
      var jenkinsEmail: string = this.jenkins.jenkinsEmail;
      var token: string = this.jenkins.proToken;
      var toolGroup: string = this.jenkins.jenkinstoolGR;

      url = url.trim();
      nameInstance = nameInstance.trim();
      description = description.trim();
      jenkinsEmail = jenkinsEmail.trim();
      token = token.trim();
      toolGroup = toolGroup.trim();

      this.jenkinsTestConnection(url, token, jenkinsEmail);
    }
    

  }


  jenkinsTestConnection(project_URL, project_Token, project_email) {
    // debugger
  
    let testConnBody = {
      "tool_name": "Jenkins",
      "tool_url": project_URL,
      "auth_token": project_Token,
      "auth_mail":project_email
    
    }

   // console.log(testConnBody);
    this.spinner.show();
    this.apiService.Admin_JenkinsTestConnection(testConnBody).subscribe((res: HttpResponse<any>) => {
      //
     // console.log(res);
      if (res.status == 200) //doesnt work
      {
       
        // this.alertService.success("Test Connection Successfully. Please Save the Connection", this.options);  
        this.alertService.success(res.body.message, this.options); 

        this.testConnDisable = true;
        // this.saveConnDisable = false;
        // this.UpdateConnDisable=false;
        this.spinner.hide();
        if (this.editMode==true){
          this.UpdateConnDisable = false;
          this.saveConnDisable = true;
          this.UrlDisable=true;
          this.PrivateDisable=true;
          this.EmailDisable=true;
          // this.TenantDisable=true;

        }
        else{
          this.UpdateConnDisable = true;
          this.saveConnDisable = false;
          this.UrlDisable=true;
          this.PrivateDisable=true
          this.EmailDisable=true;
          // this.TenantDisable=true;
        }

      }
      if (res.status == 500) //doesnt work
      {        
        this.alertService.error("Please Enter Valid Inputs", this.options);  
        this.spinner.hide();

      }
    }, (error: HttpErrorResponse) => {
      //
    //  console.log(error.status);
    this.spinner.hide();
    if (error.status == 436) //doesnt work
    {
      this.alertService.error(error.error.message, this.options); 
    }
    else if (error.status == 403){}
      if (error.status == 500) //doesnt work
      {
        this.alertService.error("Please Check URL and Email-ID or confirm if your token has sufficient access permissions", this.options); 
        this.spinner.hide();

      }
      else {        
        //this.alertService.error("Invalid Inputs", this.options); 
        this.spinner.hide();
        throw error;
      }
    })

  }

  clearAllJenkinsDetails() {
    this.jenkins.proURL = "";
    this.jenkins.proToken = "";
    this.jenkins.jenkinstoolGR = "";
    this.jenkins.nameInstance = "";
    this.jenkins.description = "";
    this.jenkins.jenkinsEmail = "";
    this.UrlDisable=false;
    this.PrivateDisable=false;
    this.EmailDisable=false;
  }


  CheckJenkinLabDuplication() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (this.jenkins.proURL == undefined || this.jenkins.jenkinstoolGR == undefined || this.jenkins.jenkinstoolGR == " " 
    || this.jenkins.jenkinsEmail == undefined || this.jenkins.nameInstance == undefined 
    || this.jenkins.description == undefined  || this.jenkins.proURL == ""  
    || this.jenkins.jenkinstoolGR == "" || this.jenkins.proURL == " " || this.jenkins.proToken == " "  ||
     this.jenkins.jenkinsEmail == " " ||  this.jenkins.nameInstance == " " ||
       this.jenkins.description == " " || this.jenkins.proToken == undefined
       || this.jenkins.jenkinstoolGR == "" || this.jenkins.jenkinstoolGR == "" 
      || this.jenkins.proToken == undefined  || this.jenkins.jenkinsEmail == "" || this.jenkins.description == "" || this.jenkins.nameInstance == ""
      || this.jenkins.proURL == "" || this.jenkins.proToken == "") {      
      this.alertService.error("Please enter details In all the fields", this.options); 
       }

    else {
      if(this.jenkins.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(sp.test(this.jenkins.nameInstance)){
        
        this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
        return;
      }
      if(this.jenkins.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name", this.options);
        return;
      }
      if(this.jenkins.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description", this.options);
        return;
      }
      if(this.jenkins.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }

      // this.project_nameInstance = this.storyBoard.nameInstance;

        let validateBody = {
          "resource_type": "tool",
          "tool_category": "CICD",
          "tool_name": "Jenkins",
          "tool_instance_details": {
          "cicd_tool_instance": {
          "tool_instance_name": this.jenkins.nameInstance
                  }
              }
      }

    //  debugger

      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
      //  debugger
       // console.log("Inside Validate");
       // console.log(res.body);
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            // case "01":
            //   this.alertService.error(res.body.msg, this.options);
            //   break;
            case "02":
              this.alertService.error(res.body.message, this.options);
              break;

            default:
              this.JenkinsConnSave();
              break;
          }

        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }


  JenkinsConnSave() {

    //
    if (this.jenkins.proURL == undefined || this.jenkins.jenkinstoolGR == undefined || this.jenkins.jenkinstoolGR == " " 
    || this.jenkins.jenkinsEmail == undefined || this.jenkins.nameInstance == undefined 
    || this.jenkins.description == undefined  || this.jenkins.proURL == ""  
    || this.jenkins.jenkinstoolGR == "" || this.jenkins.proURL == " " || this.jenkins.proToken == " "  ||
     this.jenkins.jenkinsEmail == " " ||  this.jenkins.nameInstance == " " ||
       this.jenkins.description == " " || this.jenkins.proToken == undefined
       || this.jenkins.jenkinstoolGR == "" || this.jenkins.jenkinstoolGR == "" 
      || this.jenkins.proToken == undefined  || this.jenkins.jenkinsEmail == "" || this.jenkins.description == "" || this.jenkins.nameInstance == ""
      || this.jenkins.proURL == "" || this.jenkins.proToken == "") {      
      this.alertService.error("Please enter details In all the fields", this.options); 
    }
    else {
      if(this.jenkins.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.jenkins.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving  Tool Name ", this.options);
        return;
      }
      if(this.jenkins.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if(this.jenkins.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }
      var saveBody: any;
      this.project_URL = this.jenkins.proURL;
      this.project_nameInstance = this.jenkins.nameInstance;
      this.project_description = this.jenkins.description;
      this.project_jenkinsEmail = this.jenkins.jenkinsEmail;
      this.project_Token = this.jenkins.proToken;
     // this.project_toolGroupName = this.jenkins.jenkinstoolGR;
     
     

        saveBody = {
          "resource_type": "tool",
          "tool_category": "CICD",
          "tool_name": "Jenkins",
          "tool_group_details": {
            "tool_group_id": this.jenkins.jenkinstoolGR,//"TOOLGROUP-1",
           "tool_group_name":this.project_toolGroupName
           },
          "tool_instance_details": {
          "cicd_tool_instance": {
          "tool_instance_name": this.project_nameInstance,
          "tool_url": this.project_URL,
          "tool_description": this.project_description,
          "tool_auth": {
          "auth_type": "Basic",
          "auth_username": "",
          "auth_password": "",
          "auth_token": this.project_Token,
          "auth_mail": this.project_jenkinsEmail
           }
          }
        },
        "created_by":localStorage.getItem('mail').toLowerCase()
          }

      }

     // console.log(saveBody);
      //
     this.spinner.show();
      this.apiService.Admin_JenkinsSaveConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        //
        //console.log(res.status);

        if (res.status == 200) //doesnt work
        {
         // console.log(res);         
          this.testConnDisable = false;
          this.spinner.hide();
          this.editMode = true;

          this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-jenkins']);
        });
        this.alertService.success(res.body.message, this.options); 

        

        }
      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

  }


  updateJenkin() {

    
  
    if (this.jenkins.proURL == undefined || this.jenkins.jenkinstoolGR == undefined || this.jenkins.jenkinstoolGR == " " 
    || this.jenkins.jenkinsEmail == undefined || this.jenkins.nameInstance == undefined 
    || this.jenkins.description == undefined  || this.jenkins.proURL == ""  
    || this.jenkins.jenkinstoolGR == "" || this.jenkins.proURL == " " || this.jenkins.proToken == " "  ||
     this.jenkins.jenkinsEmail == " " ||  this.jenkins.nameInstance == " " ||
       this.jenkins.description == " " || this.jenkins.proToken == undefined) {      
      this.alertService.error("Please enter details In all the fields", this.options); 
     
    }
    else {
      if (!this.validateEmail(this.jenkins.jenkinsEmail))
    {
      this.alertService.error("Jenkins Email not valid", this.options);  
      return;
    }
    if(this.jenkins.nameInstance.length<3){
      this.alertService.error("  Please enter minimum 3 characters for saving Tool Name ", this.options);
      return;
    }
    if(this.jenkins.nameInstance.length>20){
      this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
      return;
    }
    if(this.jenkins.description.length<20){
      this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
      return;
    }
    if(this.jenkins.description.length>50){
      this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
      return;
    }
      var updateBody: any;
      this.project_URL = this.jenkins.proURL;
      this.project_nameInstance = this.jenkins.nameInstance;
      this.project_description = this.jenkins.description;
      this.project_jenkinsEmail = this.jenkins.jenkinsEmail;
      this.project_Token = this.jenkins.proToken;
      //this.project_toolGroupName = this.jenkins.jenkinstoolGR;

        // ##### Code for Update Funtionality ########
      updateBody = {
        "tool_id": this.project_toolId,
        "tool_category": "CICD",
        "tool_name": "Jenkins",
        "tool_group_details": {
          "tool_group_id": this.project_toolGrId,//"TOOLGROUP-1",
          "tool_group_name": this.project_toolGroupName
            },
        "tool_instance_details": {
        "cicd_tool_instance": {
        "tool_instance_name":  this.project_nameInstance,
        "tool_url": this.project_URL,
        "tool_description":this.project_description,
        "tool_auth": {
          "auth_type": "Basic",
          "auth_username": "",
          "auth_password": "",
          "auth_token": this.project_Token,
          "auth_mail": this.project_jenkinsEmail
                    }
                }
            },
            "updated_by":localStorage.getItem('mail').toLowerCase()
        }
     
       //console.log("Update Body");
       //console.log(updateBody);
      //  debugger
      this.apiService.Admin_Scm_UpdateConnData(updateBody).subscribe((res: HttpResponse<any>) => {
      //  debugger
       // console.log(res);
        if (res.status == 200) {
         
          // this.alertService.success("Data Updated Successfully", this.options);
          this.testConnDisable = false;
         
          this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-jenkins']);
            // this.editMode = true;
            this.alertService.success(res.body.message, this.options);

        });
        }
        else if (res.status == 202) {
          // this.alertService.success("Data Saved Successfully", this.options);
          this.testConnDisable = false;
          this.editMode = true;
         
          this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-jenkins']);
            this.alertService.success(res.body.message, this.options); 

        });
        }

      }, (error: HttpErrorResponse) => {
        if (error.status == 430||error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("Invalid Responce", this.options);

        }
        else {

          this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    }//else

  }

  onChange($event) {
    let JenkinToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
   
    this.project_toolGroupName= JenkinToolGrName;
   // console.log("scmToolGrName", scmToolGrName);
  }

  openDialog() {    
    const dialogRef = this.dialog.open(AddJenkinsToolGroupDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"      
    });
  }

 


}


@Component({
  selector: 'add-jenkins-tool-group-dialog',
  templateUrl: 'add-jenkins-tool-group-dialog.html',
  styleUrls: ['./admin-jenkins.component.scss']
})

export class AddJenkinsToolGroupDialog {

  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog,public alertService: AlertService,private spinner: NgxSpinnerService ) {
      
     }

  ngOnInit(): void {


  }

 

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  clearAllSCMDetails() {
    this.model.toolName = "";
    this.model.toolDescription = "";
    
  }

  

  ToolGrSaveData() {

    // t

    if (this.model.toolName == undefined || this.model.toolDescription == undefined

      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
      // this.openSnackBarDiaLog()
      //this.apiService.castToolDialog.next(" Please enter details In all the fields ")
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;

     //       

        // ##### Code for Save Funtionality ########
        saveBody = {
        
          "resource_type":"tool-group",
          "tool_group_description":this.toolGr_description,        
          "tool_group_name": this.toolGr_name, 
          "created_by":localStorage.getItem('mail').toLowerCase()
        }

     // console.log(saveBody);
      //
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
      //  
        //console.log(res.status);

        if (res.status == 200) //doesnt work
        {
         
          var new_ToolGroupId=  res.body.tool_group_id;
          var new_ToolGroupName=  res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Jenkins", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Jenkins", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Jenkins", "true");
            this.alertService.success("Data Saved Successfully",this.options);
  
            this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/admin-jenkins']);
          });          
         
         this.dialog.closeAll();
         
        }
      }, (error: HttpErrorResponse) => {
        //
       
        if (error.status == 436||error.status == 430) //doesnt work
        {
          
          this.alertService.error(error.message,this.options);
        }
        else if (error.status == 403){}
        else {
         
          this.alertService.error("Invalid Input",this.options);
          throw error;
        }
      })

    }//else

  }


}
