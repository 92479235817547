<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">

<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"> -->

<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
          
            <li class="breadcrumb-item active" aria-current="page">Home</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-6 p-md-0 my-auto">
        <span class="table-options pull-right">

          <span (click)="setFormMode()"><i class="fas fa-plus"></i> Add</span>

          <span (click)="refreshCatalog()"><i class="fas fa-sync-alt"></i> Refresh</span>
          <a class="float-right step-back" *ngIf="addFlag==1" (click)="backNavigation()">
              <i class="step-back-icon fa fa-step-backward "></i> Back</a>
         
        </span>
      </div>
  
    </div>


    <h1 class="text-light mb-0">
      <img width="40" src="assets/icon/menu/Catalog-White.svg">
      <div class="vertical-line mx-3"> </div> 
      <span>Canvas </span>
      <span class="offset-3 offset-md-0">Subscription </span>
      
    </h1>

  </div>

  <div class="inner-mid-container">

    <div class="mt-3" *ngIf="hideTable">
      <div class="my-5 table-container">
      <div class="table-responsive">
      
        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let catelog of CatlogArray; let i=index">
              <td><span class="link-col" (click)="getRecord(catelog)">{{catelog.ID}}</span></td>
              <td style="word-break: break-all;">{{catelog.Name}}</td>
              <td>{{catelog.Description}}</td>
              <td><span class="table-crud table-delete-icon px-4 pointer" data-toggle="modal" data-target="#myModal" (click)="deleteRecord(catelog.ID,i)">
                <img width="20" src="../../../assets/img/Delete-blue.png"></span></td>
            </tr>
          </tbody>
        </table>
        <div class="modal fade " id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content pop-up">
              <div class="modal-header-popup">
                <span class="vertical-line mx-3"> </span>
                <span class="header mb-0">Canvas Subscription</span>
                <span data-dismiss="modal" class="material-icons close-icon">
                  close
                </span>
              </div>
              <hr class="margin-class" />
              <div class="modal-body">
                Are you sure want to Delete?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-canvas-blue-2" (click)="deleteSubscription()">Ok</button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 mt-3 catalog-form" *ngIf="showForm">
      
      <h2 *ngIf="!editMode">New Subscription</h2>
      <h2 *ngIf="editMode">Update ID : {{ this.project_id_scmEdit }}</h2>
      <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
        <div class="row">
          <div class="form-group col-md-4">
            <label>Canvas Subscription Name  <span class="required">&#42;</span></label>
            <input type="text" autocomplete="off" name="catalogName" maxlength="50"  minlength="3"  pattern="^[a-zA-Z0-9_-]+$" class="form-control form-control-lg" [(ngModel)]="model.catalogName"
              #catalogName="ngModel" [ngClass]="{ 'is-invalid': f.submitted && catalogName.invalid }" required>
            <div *ngIf="f.submitted && catalogName.invalid" class="invalid-feedback">
              
              <div *ngIf="catalogName.errors.required">Subscription name is required</div>
              
              <!-- <div *ngIf="catalogName.errors.minlength">
                Please enter minimum 3 characters for saving Tool Name
              </div> -->
              <div *ngIf="catalogName.errors.pattern">Name must include only alphabets ,numbers or _,-.</div>
            </div>
            <div *ngIf="catalogName.invalid && catalogName.errors.minlength" class="alert alert-danger">
              Please enter minimum 3 characters for saving Tool Name
            </div>
            <div *ngIf="catalogName.invalid && catalogName.errors.pattern" class="alert alert-danger">Name must include only alphabets ,numbers or _,-.</div>

          </div>

          <div class="form-group col-md-4">
            <label>Canvas Subscription Description  <span class="required">&#42;</span></label>

            <input type="text" autocomplete="off" name="catalogDescription" pattern="^[a-zA-Z0-9_ -]+$" maxlength="50" minlength="20"
             class="form-control form-control-lg"
              [(ngModel)]="model.catalogDescription" #catalogDescription="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && catalogDescription.invalid }" required>
            <div *ngIf="f.submitted && catalogDescription.invalid" class="invalid-feedback">
              <div *ngIf="catalogDescription.errors.required">Subscription description is required</div>
           
            </div>
            <div *ngIf="catalogDescription.invalid && catalogDescription.errors.minlength" class="alert alert-danger">
              Please enter minimum 20 characters for saving Description 

          </div>
          <div *ngIf="catalogDescription.invalid && !catalogDescription.errors.minlength &&catalogDescription.errors.pattern" class="alert alert-danger">Description must include only alphabets ,numbers or _,-.</div>

           
          </div>

          <div class="form-group col-md-4 btn-group-margin">

            <button (click)="toggleShow()" 
            [disabled]="!f.form.valid || isShown || isDisplayToolGroupDD"
            
           
              class="mr-2 btn-canvas-blue" type="button">
              Add
            </button>

            <button *ngIf="!editMode" [disabled]="saveButtonDisable || !f.form.valid " type="button" (click)="CheckUniqueNameValidation()" class="mr-2 btn-canvas-blue">Save</button>

            <button *ngIf="editMode" type="button" [disabled]="!f.form.valid" class="btn-canvas-blue mr-2" (click)="updateCatalogData()">
              Update
            </button>

            <button class="mr-2 btn-canvas-blue"  data-toggle="modal" *ngIf="!f.form.pristine" data-target="#confirmationModal">Cancel</button>
            <button class="mr-2 btn-canvas-blue" *ngIf="f.form.pristine" (click)="CatalogCancel()">Cancel</button>
            
          </div>
          <button data-toggle="modal" hidden="true" id="CallModel" data-target="#confirmationModal"></button>


          <!-- Modal -->
<div class="modal fade " id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModal" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content pop-up">
      <div class="modal-header-popup">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0"> Subscription Config</span> 
        <span  data-dismiss="modal" class="material-icons close-icon">
            close
          </span>
      </div>
      <hr class="margin-class" />
      <div class="modal-body">
        Are you sure want to cancel?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-canvas-blue-2" (click)="cancelOK()">Ok</button>
      </div>
    </div>
  </div>
</div>
        </div>
      </form>
      <!-- ####################  Resource Type Drop Down Code    ##################-->
      <div class="offset-md-2 col-md-6">
        <div *ngIf="showDivAddDrop" class="alert alert-danger">
          {{errorMsgAddDrop}}
        </div>
      </div>
      <div *ngIf="isShown" class="my-4">
        <div class="row">
          <div class="py-4 w-100 border-top border-bottom">
            <form class="form-inline">

              <div class="col-md-4">
                <label for="resourceType" class="">Select Resource Type:</label>
              </div>
              <div class="col-md-4">
                <select name="resourceType" #resourcetype class="form-control-lg">
                 <!--  <option value="">Choose Resource </option> -->
                 <option [ngValue]="null" disabled selected hidden>Select Resource</option>
                  <option *ngFor="let r of resource" [value]="r">{{r}}</option>
                </select>
              </div>
              <div class="col-md-4">

                <button type="button" class="btn btn-lg btn-success mr-2 mt-md-0 mt-2"
                  (click)='onSelectResource(resourcetype.value)'>Next</button>
                <button type="button" (click)="CancelResouceType()" class=" mt-md-0 mt-2 btn btn-lg btn-success">Previous</button>

              </div>

            </form>

          </div>
        </div>
      </div>

      <!-- ####################  Tool Group Drop Down Code    ##################-->

      <div *ngIf="isDisplayToolGroupDD" class="my-4">
        <div class="py-4 w-100 border-top border-bottom">
          <form class="form-inline">
            <div class="col-md-4">
              <label for="toolGroup" class="mr-2">Select Tool Group:</label>
            </div>
            <div class="col-md-4">
              <select (change)="selectOption($event.target.value)" name="toolGroup" class="form-control-lg">
                <!-- <option value="">Choose Tool Group</option> -->
                <option [ngValue]="null" disabled selected hidden>Select Tool Group</option>
                <option *ngFor="let t of toolsGroup" [value]="t._id">{{t.tool_group_name}}</option>
              </select>
            </div>
            <div class="col-md-4">
              <button type="button" (click)="addToolGroupResouce()" class="btn btn-lg btn-success mr-2">
                Add
              </button>
              <button type="button" class="btn btn-lg btn-success" (click)="CancelToolGroupResouce()">Previous</button>
            </div>
          </form>
        </div>
      </div>
      <!-- ####################  Tool Catagory Drop Down Code    ##################-->

      <div *ngIf="isDisplayToolDD" class="my-4">
        <div class="py-4 w-100 border-top border-bottom">
          <form class="form-inline">
            <div class="col-md-4">
              <label for="tool" class="float-right">Select Tool Type:</label>
            </div>
            <div class="col-md-4">
              <select name="tool" #ToolType class="form-control-lg">
                 <!-- <option value="">Choose Tool Type</option> -->
                 <option [ngValue]="null" disabled selected hidden>Select Tool Type</option>
                <option *ngFor="let ts of tools" [value]="ts.tool_group_name">{{ts.tool_group_name}}</option>
              </select>
            </div>
            <div class="col-md-4">

              <button type="button" (click)="selectToolName(ToolType.value)" class="btn btn-lg btn-success mr-2">
                Next
              </button>
              <button type="button" class="btn btn-lg btn-success" (click)="CancelTools()">Previous</button>
            </div>
          </form>
        </div>
      </div>

      <!-- ####################  Tool Type Drop Down Code    ##################-->

      <div *ngIf="isDisplayToolType" class="my-4">
        <div class="py-4 w-100 border-top border-bottom">
          <form class="form-inline">
            <div class="col-md-4">
              <label for="tool" class="float-right">Select Tool:</label>
            </div>
            <div class="col-md-4">
              <select name="tool" #GetTool class="form-control-lg"
              (change)="onChange($event)" aria-placeholder="Choose Tool"
             >
                <!--<option value="">Choose Tool</option>-->
                <option [ngValue]="null" disabled selected hidden>Select Tool</option>

                <option *ngFor="let tt of toolType" [value]="tt">{{tt}}</option>
              </select>
            </div>
            <div class="col-md-4">

              <button type="button" (click)="GetToolType(GetTool.value)" class="btn btn-lg btn-success mr-2">
                Add Tool
              </button>
              <button type="button" class="btn btn-lg btn-success" (click)="CancelToolType()">Previous</button>
            </div>
          </form>
        </div>
      </div>


      <div *ngIf="showTable">
        <div class="my-5">
          <div class="table-responsive">
            <h2>Resource Information</h2>
          <table class="table fixed-table">
            <thead>
              <tr>
                <th>Resource Type</th>
                <th>Resource</th>
                <th>Connection Name</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resource of tableResourceArray;let i = index">
                <td>{{resource.resourceType}}</td>
                <td>{{resource.resource}}</td>
                <td>{{resource.tool_instance_name}}</td>
                <td>{{resource.Description}}</td>
                <td>
                <!-- <td><span class="table-crud table-delete-icon px-4 pointer"  data-toggle="modal" data-target="#myModal2" (click)="deleteResourceRecord(resource,i)">
                  <img width="20" src="../../../assets/img/Delete-blue.png"></span></td> -->
                  <span
                  class="hover" 
                  mwlConfirmationPopover
                  [popoverTitle]="popoverTitle"
                  [popoverMessage]="popoverMessage"
                  placement="top"
                  (confirm)="deleteResource(resource,i)"
                  (cancel)="cancelClicked = true"
                >
                  <i class="table-crud table-delete-icon px-4 pointer" 
                  aria-hidden="true">
                  <img width="20" src="../../../assets/img/Delete-blue.png"></i>
            </span>
          </td>
                  
              </tr>
            </tbody>
          </table>
          <!-- <div class="modal fade " id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModal2" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                <div class="modal-header-popup">
                  <span class="vertical-line mx-3"> </span>
                  <span class="header mb-0">Resource Information</span>
                  <span data-dismiss="modal" class="material-icons close-icon">
                    close
                  </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                  Are you sure want to Delete?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-canvas-blue-2" (click)="deleteResource()">Ok</button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="showDivAdd" class="alert alert-danger">
            {{errorMsgAdd}}
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="showDivDelete" class="alert alert-success">
            {{errorMsgDelete}}
          </div>
        </div>

        <div class="my-5">
          <div class="table-responsive">
            <h2>Project Information</h2>
            <div class="alert alert-danger" *ngIf="deleteErrorFlag">
             {{deleteErrorMsg}}
            </div>
          <table class="table fixed-table">
            <thead>
              <tr>
                <th>Project ID</th>
                <th>Project Manager</th>
                <th>Project Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let resource of Project_list;let i = index">
                <td>{{resource.project_id}}</td>
                <td>{{resource.project_manager}}</td>
                <td>{{resource.project_name}}</td>
                <td>
                  <span class="table-crud table-delete-icon pointer" (click)="projectInfo(resource.project_id)" data-toggle="modal" data-target="#myModal">
                    <img width="20" src="../../../assets/img/info.jpg"></span>
                  <!-- <span class="table-crud table-delete-icon px-4 pointer"data-toggle="modal" data-target="#myModal3"  (click)="deleteProjectRecord(resource)">
                    <img width="20" src="../../../assets/img/Delete-blue.png"></span> -->
                    <!-- <td> -->
                      <!-- <td><span class="table-crud table-delete-icon px-4 pointer"  data-toggle="modal" data-target="#myModal2" (click)="deleteResourceRecord(resource,i)">
                        <img width="20" src="../../../assets/img/Delete-blue.png"></span></td> -->
                        <span
                        class="hover" 
                        mwlConfirmationPopover
                        [popoverTitle]="popoverTitle"
                        [popoverMessage]="popoverMessage"
                        placement="top"
                        (confirm)="deleteProject(resource,i)"
                        (cancel)="cancelClicked = true"
                      >
                        <i class="table-crud table-delete-icon px-4 pointer"
                        aria-hidden="true">
                        <img width="20" src="../../../assets/img/Delete-blue.png"></i>
                  </span>
                <!-- </td> -->
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="modal fade " id="myModal3" tabindex="-1" role="dialog" aria-labelledby="myModal3" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content pop-up">
                <div class="modal-header-popup">
                  <span class="vertical-line mx-3"> </span>
                  <span class="header mb-0">Project Information</span>
                  <span data-dismiss="modal" class="material-icons close-icon">
                    close
                  </span>
                </div>
                <hr class="margin-class" />
                <div class="modal-body">
                  Are you sure want to Delete?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-canvas-blue-2" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-canvas-blue-2" (click)="deleteProject()">Ok</button>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Modal -->
<div id="myModal" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
       <span class="title"> Project Info</span>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
              <span class="title" *ngIf="gitlabBody.length > 0">Gitlab Projects</span>
              <table class="icon-table table-bordered">
                  <tbody>
                      <tr>
                          <td class="enabled-icons" *ngFor="let cat of gitlabBody">{{cat}}</td>
                      </tr>
                  </tbody>
              </table>
              <span class="title" *ngIf="jenkinsBody.length > 0">Jenkins Projects</span>
              <table class="icon-table table-bordered">
                  <tbody>
                      <tr>
                          <td class="enabled-icons" *ngFor="let cat of jenkinsBody">{{cat}}</td>
                      </tr>
                  </tbody>
              </table>
              <span class="title" *ngIf="jiraBody.length > 0">Jira Projects</span>
              <table class="icon-table table-bordered">
                  <tbody>
                      <tr>
                          <td class="enabled-icons" *ngFor="let cat of jiraBody">{{cat}}</td>
                      </tr>
                  </tbody>
              </table>
              <span class="title" *ngIf="vdiBody.length > 0">VDI Projects</span>
              <table class="icon-table table-bordered">
                  <tbody>
                      <tr>
                          <td class="enabled-icons" *ngFor="let cat of vdiBody">{{cat}}</td>
                      </tr>
                  </tbody>
              </table>
              <span class="title" *ngIf="vmBody.length > 0">VM Projects {{vmBody.length}}</span>
              <table class="icon-table table-bordered">
                  <tbody>
                      <tr>
                          <td class="enabled-icons" *ngFor="let cat of vmBody">{{cat}}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
      </div>
    </div>

  </div>
</div>
        </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- old code -->
