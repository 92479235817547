<div class="sub-nav-bar">
  <div class="row">
    <div class="col-sm-6">
      <div class="d-flex flex-row">
        <div  *ngIf="minajFlag" class="px-1">
          <img alt="Avatar"
            src="../../assets/img/minaj.jpeg"
            class="img-user">
        </div>
        <div *ngIf="!minajFlag" class="px-1">
            <div class="img-user-initials" *ngIf="profileImageFlag">{{initials}}</div>
            <img *ngIf="!profileImageFlag" alt="Avatar"
            [src]="imageToShow"
            class="img-user">
        </div>
        <div class="px-1 vLine">
        </div>
        <div class="px-1 align-self-center">
          <div><span class="profileTittle">{{profile}}</span></div>
          <div><span class="profile-subtitle">{{designation}} </span></div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="d-flex flex-row-reverse">
        <span class="add-project" (click)="addProject()" *ngIf="!isUserTM">+ Add Project </span>
        <span class="add-project disabled-img" *ngIf="isUserTM">+ Add Project </span>
      </div>
    </div>
  </div>
</div>

<div class="projects-container">
  <div class="row projects-list">
    <div class="offset-md-0 col-md-6" *ngIf="projectData?.length == 0">
                  <div class="row grid-divider " style="color:black;">
                      No Projects Available
                      <br>
                      Kindly Onboard Project Using Add Project 
                  </div>
      </div>
    <div class="col-sm-6 col-md-6 col-lg-3" *ngFor="let element of projectData">
      <mat-card class="project-card" (click)=getProjectId($event,element.project_id)>
        <div class="d-flex flex-row">
          <div class="px-0">
          </div>
          <div class="mx-2 vertical-line-one"></div>
          <div class="px-2">
            <div class="project-title">
              <a>{{element.project_id}}</a>
            </div>
            <div class="color-secondary">
              <mat-divider [vertical]="true"></mat-divider>
              <a style="font-size: 12px;">( {{element.project_name}} )</a>
            </div>
          </div>
        </div>
        <a class="projectTitle" ></a>

      </mat-card>
    </div>
  </div>
</div>

<footer>
  <p>Powered By &nbsp;
        <img class="" [src]="footerimageSrc" [alt]="footerimageAlt" height="35" >
     <!-- <img  src="/assets/img/Cisco.svg" alt="Logo" height="35" > -->
    </p>
</footer>
