<div class="pop-up">
    <div class="">
      <span  class="vertical-line mx-1 mx-md-3"> </span>
       <span class="header mb-0">Policy Detail</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
    
      <hr class="margin-class" />
      <div class="popup-container">
        <div class="table-responsive">
          <table datatable [dtOptions]="dtOptions"  class="table fix-table">
              <thead>
                <tr>
                  <th>GuID</th>
                  <th>Product</th>
                  <th>Default</th>
                  <th>Serial Number</th>
                  <th>Description</th>
                  <th>Links</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of alertInfo">
                  <td>{{data.guid}}</td>
                  <td>{{data.product || "-"}}</td>
                  <td>{{data.default || "-"}}</td>
                  <td>{{data.serial_number || "-"}}</td>
                  <td>{{data.description || "-"}}</td>
                  <td>{{data.links.policy || "-"}}</td>
                </tr> 
              </tbody>
            </table>
          </div>
          
      </div>
    </div>