import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Subject, throwError } from 'rxjs';	

export interface configList {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}


export interface storyBoardDetails {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

@Component({
  selector: 'app-admin-story-board',
  templateUrl: './admin-story-board.component.html',
  styleUrls: ['./admin-story-board.component.scss']
})
export class AdminStoryBoardComponent implements OnInit {

  StoryBoardArray = [];
  storyBoardObj: object[];

  projectId
  
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;

  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();


  constructor(public apiService: AuthService, private router: Router,
    public dialog: MatDialog) { }


  ELEMENT_DATA: storyBoardDetails[];
  displayedColumns: string[] = ['toolName', 'ID', 'projectURL', 'email', 'toolGroup', 'select'];
  //dataSource = new MatTableDataSource<storyBoardDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<storyBoardDetails>(true, []);

  ngOnInit(): void {
    this.apiService.projectKey.subscribe(projectId => {
      // this.userEmail = currentUserEmail;
      this.projectId = projectId;
    })
    this.GetStoryBoardtableDetails();
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  GetStoryBoardtableDetails() {
 //   
   

    this.apiService.Admin_StoryBoard_JiraTableDetails().subscribe((res: any) => {
      //
    //  console.log(res)
      res.forEach(member => {
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id":member._id,
          "projectToken": member.tool_auth.auth_token,
          "email": member.tool_auth.auth_mail,
          "name": member.tool_instance_name,
          "description": member.tool_description,
          "projectURL": member.tool_url,
          "toolGroup": member.tool_groups[0].tool_group_name,
          "toolGroup_id": member.tool_groups[0]._id
          //  [11].tool_groups[0].tools[0]

        }
        this.StoryBoardArray.push(obj)

      })
     // console.log(this.StoryBoardArray)
    //  this.dataSource.data = this.StoryBoardArray as storyBoardDetails[]
      this.dtTrigger.next();
    })
    // SCMArray
  }

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
  //  const numRows = this.dataSource.data.length;
  //  return numSelected === numRows;
  return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
   // this.isAllSelected() ?
     // this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolName + 1}`;
  }

  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }

  getRecord(row) {
    
   // console.log(row);
    this.storyBoardObj = row;
    //this.apiService.SCMToolDetails.next(this.scmObj)
    try {
      localStorage.setItem('storyBoardObjToolObj', JSON.stringify(this.storyBoardObj));
      localStorage.setItem("formMode", "Edit")
    }
    catch {
      return throwError('Data not found');
    }
    this.router.navigate(['/admin-jira']);
  }


  openDialog() {   
    localStorage.setItem("formMode", "Add");
    const dialogRef = this.dialog.open(StoryBoardDialog, {
      width: '40%',
      height: '40%',
    });  
  }

}

@Component({
  selector: 'story-board-dialog',
  templateUrl: 'story-board-dialog.html',
  styleUrls: ['./admin-story-board.component.scss']
})

export class StoryBoardDialog { }


