<h2 mat-dialog-title>Tool Group</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-md-6 text-center">
        <a routerLink="/admin-tool-config" mat-dialog-close>
            <img src="assets/img/gitlab-logo.png" class="img-fluid">
        </a>
      </div>
      <div class="col-md-6 text-center">
        <a routerLink="/admin-tool-config" mat-dialog-close>
            <img src="assets/img/jira-logo.png" class="img-fluid">
        </a>
      </div>
  </div>
</mat-dialog-content>
