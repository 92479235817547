import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { error } from '@angular/compiler/src/util';
import { AlertService } from 'src/app/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { CanComponentLeave } from 'src/app/unsaved-changes.guard';

export interface scmTool {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
  subscription_id: string;
  tenant_id: string;
  
}
export interface configList {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  subscription_id: string;
  toolGroup: string;
  toolGroup_id: string;
  tenant_id: string;
  isSelected: boolean
}
export interface AzureDetails {
  toolName: string;
  ID: string;
  db_id: string;
  name: string;
  description: string;
  subscription_id: string;
  toolGroup: string;
  toolGroup_id: string;
  tenant_id: string;
  isSelected: boolean;
}



@Component({
  selector: 'app-admin-azure-config',
  templateUrl: './admin-azure-config.component.html',
  styleUrls: ['./admin-azure-config.component.scss']
})
export class AdminAzureConfigComponent implements OnInit,CanComponentLeave {

  //toolName:any;
  toolDescription: any;
  model: any = {};
  //formMode:string = "";

  testConnDisable = false;
  saveConnDisable = true;
  UpdateConnDisable = true;
  //submitted = false;
  AzureArray = [];
  checked = true;
  vdi_Checkbox_checked = true;
  vm_checkbox_checked = true;
  VdiConfig_details:any=[];
  project_selectedcheckbox = false;

  check:any=[]
  // Variable for Save/Edit body
  project_Tenant: string;
  project_Subscription: string;
  project_toolGrId: string;
  project_toolGrName: string;
  project_id_scmEdit: string;
  project_id_db: string;
  project_nameInstance: string;
  project_description: string;
  
  project_old_toolGr_ID: string;
  project_new_ToolGr_ID: string;
  showTable: boolean = true;
  showForm: boolean = false;
  editMode: boolean = false;
  SubscriptionDisable=false;
  TenantDisable=false;
  token: any;
  //projectURLVal: any;
  toolName: any;

  toolsGroup: any = {};

  scmToolDetails: scmTool;
  selectedToolvalue: any;

  formMode: string = "";

  toolGr: any;
  Subscription: any;
  Tenant: any;
  db_id: any;
  nameInstance: any;
  description: any;



  scmObj: object[];

  ToolGroupList = [];

  AksChecked: boolean;
  VMChecked: boolean;
  VDIChecked: boolean;
  submitted = false;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  isChecked = false;
  masterSelected: boolean;
  checklist: any;
  checkedList: any;
  ToolGroupDisable=false;
  ToolInstanceNameDisable =false;
  Role:string="";
  itemSelected:boolean=false;
  selectedTool_group_name: any;
  nextUrl: any;
  canLeave(url):boolean{
    // console.log(url);
    
    // debugger
    this.nextUrl=url
    if(this.nextUrl=="/admin-scm-tool"){
      return true
    }
    else{
      if((this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
      (this.toolsGroup.Subscription == '' || this.toolsGroup.Subscription == undefined)  &&
       (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
        && (this.toolsGroup.Tenant == '' || this.toolsGroup.Tenant == undefined)){
        return true
      }
      if ((this.toolsGroup.toolGr != '' || this.toolsGroup.toolGr != undefined) &&
      (this.toolsGroup.description != '' || this.toolsGroup.description != undefined)  &&
      (this.toolsGroup.Subscription != '' || this.toolsGroup.Subscription != undefined)  &&
       (this.toolsGroup.nameInstance != '' || this.toolsGroup.nameInstance != undefined)
        && (this.toolsGroup.Tenant != '' || this.toolsGroup.Tenant != undefined)){
        let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
      element.click();
        return false
      }
    }
    
 
    return true
  }
  
  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router, private spinner: NgxSpinnerService,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService) {
    this.masterSelected = false;
  }

  ELEMENT_DATA: AzureDetails[];
  displayedColumns: string[] = ['ID', 'name', 'description', 'subscription', 'toolGroup', 'select'];
  //dataSource = new MatTableDataSource<AzureDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<AzureDetails>(true, []);

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="Admin"){
    this.getTypeT();
    this.dtOptions = {
      order: []
    };
    var PopupToolGroup = localStorage.getItem('Popup_Open_Azure')
    if (PopupToolGroup == "true") {
      
      this.showForm = true;
      var Popup_NewToolGroupID = localStorage.getItem('Popup_NewToolGroupID_Azure')
      this.toolsGroup.toolGr = Popup_NewToolGroupID;

      var Popup_NewToolGroupName = localStorage.getItem('Popup_NewToolGroupName_Azure')
      this.project_toolGrName = Popup_NewToolGroupName;
      localStorage.setItem("Popup_Open_Azure", "false");
    } else {
     
    }
    this.GetAzuretableDetails();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/home']);
  });
  }
  }

  backNavigation(){
    if ((this.toolsGroup.toolGr == '' || this.toolsGroup.toolGr == undefined) &&
      (this.toolsGroup.description == '' || this.toolsGroup.description == undefined)  &&
      (this.toolsGroup.Subscription == '' || this.toolsGroup.Subscription == undefined)  &&
       (this.toolsGroup.nameInstance == '' || this.toolsGroup.nameInstance == undefined)
        && (this.toolsGroup.Tenant == '' || this.toolsGroup.Tenant == undefined)){
      this.router.navigate(["/admin-catalog-config"])
    }
    else{
      let element : HTMLElement = document.getElementById('CallModel') as HTMLElement;
            element.click();
    }
  }

  onAskChanged(value: boolean) {
   
    this.AksChecked = value;
    if (value == true) {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }

  onVDIChanged(value: boolean) {
   
    this.VDIChecked = value;
    if (value == true) {
      this.vdi_Checkbox_checked = false;
    } else {
      this.vdi_Checkbox_checked = true;
    }
  }

  onVMChanged(value: boolean) {
    
    this.VMChecked = value;
    if (value == true) {
      this.vm_checkbox_checked = false;
    } else {
      this.vm_checkbox_checked = true;
    }
  }

  refreshAzure() {

    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-azure-config']);
    });
  }
  

  addForm() {
   
    this.hideForm();
    this.testConnDisable= false;
    this.showTable = false;
    this.showForm = true;
    this.AksChecked = false;
    this.VMChecked = false;
    this.VDIChecked = false;
    this.onAskChanged(false);
    this.onVMChanged(false);
    this.onVDIChanged(false);
    this.ToolGroupDisable=false;
    this.ToolInstanceNameDisable = false;
  }
  hideForm() {
    //this.showTable  = false;
    this.clearAzureDetails();
    this.editMode = false;
    this.showForm = false;
    this.UpdateConnDisable = true;

    

  }
  cancelok(){
    this.clearAzureDetails();
 
  if(this.nextUrl==undefined){
    this.refreshAzure()
  }
  else{
    this.router.navigate([this.nextUrl])

  }

  }
  getTypeT(): void {
    //
    this.apiService.Admin_ScmToolGrDrp().subscribe(data => {
      if (data) {
        //console.log("Tool Gr Dropdown Data");
        //console.log(data);
        var i;
        for (i = 0; i < data.body.length; i++) {
          //
          if (data.body[i].tool_group_name != undefined) {
            // 
            this.ToolGroupList.push(data.body[i]);
          }
        }
      }
    }
      , (error: HttpErrorResponse) => {
        // 
        if (error.status == 500) {
          this.alertService.error("Please Enter Valid Inputs", this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })
  }

  // Assign row data into form
  setScmEditing(scmObj: scmTool) {
    //console.log("scmObj", scmObj)

    this.toolsGroup.toolGr = scmObj.toolGroup_id;
    this.toolsGroup.proURL = scmObj.projectURL;
    this.toolsGroup.proToken = scmObj.projectToken;
    this.project_id_scmEdit = scmObj.ID;
    this.project_id_db = scmObj.db_id;
    this.toolsGroup.nameInstance = scmObj.name;
    this.toolsGroup.description = scmObj.description;
    this.project_old_toolGr_ID = scmObj.toolGroup_id;
    this.toolsGroup.Subscription = scmObj.subscription_id;
    this.toolsGroup.Tenant = scmObj.tenant_id;
   
  }

  selectedTool(e) {

  }

  //On click ID get row data
  getRecord(row) {
    //    console.log(row);
    this.scmObj = row;
    try {
      localStorage.setItem('scmToolObj', JSON.stringify(this.scmObj));
      localStorage.setItem("formMode", "Edit")
      this.formMode = localStorage.getItem('formMode')
      if (this.formMode == "Edit") {
        var getObj = JSON.parse(localStorage.getItem('scmToolObj'));
      this.clearAzureDetails();
        this.setScmEditing(getObj);
        this.editMode = true;
        this.showForm = true;
        this.testConnDisable = false;
        this.UpdateConnDisable = true;
        this.ToolGroupDisable=true
          this.ToolInstanceNameDisable = true;
          this.SubscriptionDisable=false;
          this.TenantDisable=false;

      }
    }
    catch {
      return throwError('Data not found');
    }
    
    
  }

  onSubmit() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    
    if (this.toolsGroup.Subscription == undefined || this.toolsGroup.Subscription == " " || this.toolsGroup.Subscription == "" 
      || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "  || this.toolsGroup.toolGr == "" 
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " 
      || this.toolsGroup.description == undefined  || this.toolsGroup.description == ""  || this.toolsGroup.description == " "
      || this.toolsGroup.Tenant == " " || this.toolsGroup.Tenant == ""  || this.toolsGroup.Tenant == undefined
     
     
    ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else if(this.toolsGroup.description.length<20 || this.toolsGroup.nameInstance.length<3 || this.toolsGroup.nameInstance>20 || this.toolsGroup.description.length>50 || sp.test(this.toolsGroup.nameInstance)){
      this.testConnDisable = true;

      // this.alertService.error("Please enter minimum 20 characters for Description ", this.options);
      setTimeout(() => {
        this.testConnDisable = false;
      }, 2)

          }
    else {
      
     
      var SubscriptionId: string = this.toolsGroup.Subscription;
      var TenantId: string = this.toolsGroup.Tenant;

      SubscriptionId = SubscriptionId.trim();
      TenantId = TenantId.trim();


      this.scmTestConnection(SubscriptionId,TenantId);
      
    }

  }

  scmTestConnection(SubscriptionId,TenantId) {
    // debugger
    
    let testConnBody = {
      "subscriptionId": SubscriptionId,
      "tenantId":TenantId
    }

    //console.log(testConnBody);
    this.apiService.Admin_SubscriptionTestConnection(testConnBody).subscribe((res: HttpResponse<any>) => {
      //
      // debugger
      
      //console.log(res);
      if (res.status == 200) {
       // this.alertService.success("Valid Subscription Id. Please Save all details", this.options);
        this.alertService.success(res.body.message, this.options);
        this.testConnDisable = true;
       
        if (this.editMode==true){
          this.UpdateConnDisable = false;
          this.saveConnDisable = true;
          this.SubscriptionDisable=true;
          this.TenantDisable=true;

        }
        else{
          this.UpdateConnDisable = true;
          this.saveConnDisable = false;
          this.SubscriptionDisable=true;
          this.TenantDisable=true;
        }

        
      }
      if (res.status == 500) {
        this.alertService.success("Please Enter Valid Inputs", this.options);
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 500) {
        this.alertService.error("Invalid Subscription Id.", this.options);
      }
      if (error.error.code == "02") {
        this.alertService.error(error.error.message, this.options);
        return;
      }
      else if (error.status == 403){}
      if (error.error.code == "03") {
        this.alertService.error(error.error.message, this.options);
        return;
      }
      else {
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })

  }

  clearAzureDetails() {
    this.toolsGroup.proURL = "";
    this.toolsGroup.nameInstance = "";
    this.toolsGroup.description = "";
    this.toolsGroup.Tenant = "";
    this.toolsGroup.toolGr = "";
    this.toolsGroup.Subscription = "";
    this.SubscriptionDisable=false;
    this.TenantDisable=false;
  
  }

  onChange($event) {
    let azureToolGrName = $event.target.options[$event.target.options.selectedIndex].text;
    this.project_toolGrName = azureToolGrName;
    // console.log("azureToolGr Name", azureToolGrName);
    //console.log("azureToolGr Id", this.toolsGroup.toolGr);
  }

  CheckDuplication() {
    let sp = /[!^+$@#%&*()\=\\{};':"\\|,.<>\/?]+/;

    if (this.toolsGroup.Subscription == undefined  || this.toolsGroup.Subscription == " " || this.toolsGroup.Subscription == "" 
      || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "  || this.toolsGroup.toolGr == "" 
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.nameInstance == "" || this.toolsGroup.nameInstance == " " 
      || this.toolsGroup.description == undefined  || this.toolsGroup.description == ""  || this.toolsGroup.description == " "
      || this.toolsGroup.Tenant == " " || this.toolsGroup.Tenant == "" || this.toolsGroup.Tenant == undefined) {
        
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters for saving Tool Name", this.options);
        return;
      }
      if(sp.test(this.toolsGroup.nameInstance)){
        
        this.alertService.error("Name must include only alphabets ,numbers or _,-", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters for saving Tool Name ", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }    
     
      this.project_Subscription = this.toolsGroup.Subscription;
      this.project_nameInstance = this.toolsGroup.nameInstance;
      this.project_description = this.toolsGroup.description;
      this.project_Tenant = this.toolsGroup.Tenant;

      let validateBody = {
        "resource_type":"tool",
        "tool_category": "AZURE",
        "tool_name": "AZURE",
        "tool_group_name":this.project_toolGrName,
        "tool_instance_details": {
            "azure_tool_instance": {
                "tool_instance_name": this.project_nameInstance,
                "tenant_id": this.project_Tenant,
                "subscription_id": this.project_Subscription,
                "description":this.project_description
            }
        }
    }
      this.apiService.Admin_CheckValidation(validateBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {

          let code = res.body.code

          switch (code) {
            case "01":
              this.alertService.error(res.body.message, this.options);
              break;
            case "02":
              this.alertService.error(res.body.msg, this.options);
              break;

            default:
              this.AzureSave();
              break;
          }
       
        }


      }, (error: HttpErrorResponse) => {
        if (error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        if (error.status == 500) //doesnt work
        {
          this.alertService.error("already Exist", this.options);

        }
        else {

          //this.alertService.error("Invalid Input", this.options);

          throw error;
        }
      })

    } //else

  }

  AzureSave() {

    if (this.toolsGroup.Subscription == undefined || this.toolsGroup.toolGr == undefined || this.toolsGroup.toolGr == " "
      || this.toolsGroup.nameInstance == undefined || this.toolsGroup.description == undefined
      || this.toolsGroup.toolGr == "" || this.toolsGroup.Subscription == " " || this.toolsGroup.Tenant == " "
      || this.toolsGroup.nameInstance == "" || this.toolsGroup.description == " "
      || this.toolsGroup.Tenant == undefined
    ) {
      this.alertService.error("Please enter details In all the fields", this.options);
    }
    else {

     
      if(this.toolsGroup.nameInstance.length<3){
        this.alertService.error("  Please enter minimum 3 characters saving for Tool Name ", this.options);
        return;
      }
      if(this.toolsGroup.nameInstance.length>20){
        this.alertService.error("  Please enter maximum 20 characters saving for Tool Name ", this.options);
        return;
      }
      if(this.toolsGroup.description.length<20){
        this.alertService.error("  Please enter minimum 20 characters for saving Tool Description ", this.options);
        return;
      }
      if(this.toolsGroup.description.length>50){
        this.alertService.error("  Please enter maximum 50 characters for saving Tool Description ", this.options);
        return;
      }    
     
      var saveBody: any;
      this.project_Subscription = this.toolsGroup.Subscription;
      this.project_nameInstance = this.toolsGroup.nameInstance;
      this.project_description = this.toolsGroup.description;
      this.project_Tenant = this.toolsGroup.Tenant;
      this.project_toolGrId = this.toolsGroup.toolGr;
      this.project_new_ToolGr_ID = this.toolsGroup.toolGr;

      // #### Save and Edite Code - Start #####
        saveBody = {
          "resource_type": "tool",
          "tool_category": "AZURE",
          "tool_name": "AZURE",
          "tool_group_details": {
            "tool_group_id": this.project_toolGrId,//"TOOLGROUP-3",
            "tool_group_name": this.project_toolGrName //"Chevron Group"
          },
          "tool_instance_details": {
            "azure_tool_instance": {
              "tool_instance_name": this.project_nameInstance,
              "tenant_id": this.project_Tenant,
              "subscription_id": this.project_Subscription,
              "description": this.project_description,
            }
          },
          "created_by":localStorage.getItem('mail').toLowerCase()
        }
      
      
      this.apiService.Admin_AzureSaveEditConnData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
         // this.alertService.success("Data Saved Successfully", this.options);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-azure-config']);
          });
          this.alertService.success(res.body.message, this.options);
        }
        else if (res.status == 202) {
          
         
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-azure-config']);
          });
          this.alertService.success(res.body.message, this.options);
        }

      }, (error: HttpErrorResponse) => {
        //

        if (error.status == 430||error.status == 436) //doesnt work
        {
             
          this.alertService.error(error.error.message, this.options);

        }
        else if (error.status == 403){}
        else {
         
         // this.alertService.error("Invalid Input", this.options);
         // throw error;
        }
      })

      // #### Save and Edite Code - End #####


    }//else

  }

  //Update Azure row data
  UpdateAzureForm() {
    this.project_Subscription = this.toolsGroup.Subscription;
      this.project_nameInstance = this.toolsGroup.nameInstance;
      this.project_description = this.toolsGroup.description;
      this.project_Tenant = this.toolsGroup.Tenant;
      this.project_toolGrId = this.toolsGroup.toolGr;
      this.project_new_ToolGr_ID = this.toolsGroup.toolGr;

      this.ToolGroupList.forEach(element => {
        if(element.tool_group_id == this.project_toolGrId){
         this.selectedTool_group_name = element.tool_group_name
        }
      });

    let body = {
      "tool_id": this.project_id_scmEdit,
      "tool_group_id":this.project_toolGrId,
      "tool_group_name":this.selectedTool_group_name,
      "tool_instance_name": this.project_nameInstance,
      "tenant_id": this.project_Tenant,
      "subscription_id": this.project_Subscription,
      "description": this.project_description,
      "updated_by":localStorage.getItem('mail').toLowerCase()

      }

      this.apiService.Admin_AzureUpdateData(body).subscribe((res: any) => {
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-azure-config']);
            //this.alertService.success("Data Updated Successfully", this.options);
          });
          
          this.alertService.success(res.message, this.options);


      }, (error: HttpErrorResponse) => {
        if (error.status == 430||error.status == 436) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })




  }

  openDialog() {
    const dialogRef = this.dialog.open(AddToolGroupAzureDialog, {
      width: "55vw",

      height: "50vh",

      maxWidth: "500px",

      maxHeight: "330px"

    });
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  GetAzuretableDetails() {
    this.spinner.show();
    this.apiService.Admin_AzureTableDetails().subscribe((res: any) => {
      
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.azure_tool_instance.tool_instance_name,
          "description": member.tool_instance_details.azure_tool_instance.description,
          "tenant_id": member.tool_instance_details.azure_tool_instance.tenant_id,
          "subscription_id": member.tool_instance_details.azure_tool_instance.subscription_id,
          "toolGroup": member.tool_group_details.tool_group_name,
          "toolGroup_id": member.tool_group_details.tool_group_id
         
        }
        this.AzureArray.push(obj)
        this.checklist = this.AzureArray;
      })
     // this.dataSource.data = this.AzureArray as AzureDetails[]
      this.dtTrigger.next();
      this.spinner.hide();
    }
    , (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })
   
  }
  deleteRecord(){
    if(this.VdiConfig_details.length==0){
      this.alertService.error("Select one or more option to delete", this.options);
    }

  }
  checkUncheckAll() {
    for (var i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
  getCheckedItemList() {
    this.project_selectedcheckbox = false;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push(this.checklist[i].ID);
    }
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {  
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
                  var SelectedItem=this.checkedList[index];
                 let VdiConfigBoby={
                    "vdi_dropdown_id":SelectedItem,
                    }
                   this.VdiConfig_details.push(VdiConfigBoby);
                  
                 // For Loop of tag

                // Add image des 
                               
             }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
  DeleteConfig(){
     var deleteBody: any;
     this.check=[]
     this.VdiConfig_details.forEach(element => {
       this.check.push(element.vdi_dropdown_id)
       
     });
     deleteBody = {
       "tool_ids":this.check
    
     }
    
     this.apiService.Admin_AzureConfigDropdowDeleteData(deleteBody).subscribe((res:any) => {
       if (res.status == 200) //doesnt work
       {
         
        
         this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
           this.router.navigate(['/admin-azure-config']);
         });
         this.alertService.success(res.body.message, this.options);
         //this.alertService.success("Data Delete Successfully", this.options);
       }
     }, (error: HttpErrorResponse) => {
       //
 
       if (error.status == 446) //doesnt work
       {
            
         this.alertService.error(error.error.message, this.options);
 
       }
       else if (error.status == 403){}
       else {
        
         this.alertService.error("Invalid Input", this.options);
         throw error;
       }
     })
 
   }



  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected_old() {
    const numSelected = this.selection.selected.length;
  //  const numRows = this.dataSource.data.length;
   // return numSelected === numRows;
   return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
 //   this.isAllSelected_old() ?
   //   this.selection.clear() :
     // this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected_old() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolName + 1}`;
  }

  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }





}


@Component({
  selector: 'add-tool-group-azure-dialog',
  templateUrl: 'add-tool-group-azure-dialog.html',
  styleUrls: ['./admin-azure-config.component.scss']
})

export class AddToolGroupAzureDialog {


  toolId: any;
  toolName: any;
  toolDescription: any;
  model: any = {};
  formMode: string = "";

  toolGr_description: string;
  toolGr_name: string;

  submitted = false;

  mySubscription: any;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  constructor(private formBuilder: FormBuilder,
    private http: HttpClient, private router: Router,
    private apiService: AuthService, public dialog: MatDialog, public alertService: AlertService, private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {

  }

  onSubmit() {
    // 
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  clearAllSCMDetails() {
    this.model.toolName = "";
    this.model.toolDescription = "";
  }

  ToolGrSaveData() {

    if (this.model.toolName == undefined || this.model.toolDescription == undefined
      || this.model.toolName == " " || this.model.toolName == "" || this.model.toolDescription == " "
      || this.model.toolDescription == "") {
    }
    else {
      var saveBody: any;
      this.toolGr_description = this.model.toolDescription;
      this.toolGr_name = this.model.toolName;
      // ##### Code for Save Funtionality ########
      saveBody = {
       
        "resource_type": "tool-group",
        "tool_group_description": this.toolGr_description,
        "tool_group_name": this.toolGr_name,
        "created_by":localStorage.getItem('mail').toLowerCase()
      }

      //      console.log(saveBody);
      
      this.apiService.Admin_ToolGrSaveData(saveBody).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) //doesnt work
        {
          ;
          var new_ToolGroupId = res.body.tool_group_id;
          var new_ToolGroupName = res.body.tool_group_name;
          localStorage.setItem("Popup_NewToolGroupID_Azure", new_ToolGroupId);
          localStorage.setItem("Popup_NewToolGroupName_Azure", new_ToolGroupName);
          localStorage.setItem("Popup_Open_Azure", "true");
          this.alertService.success("Data Saved Successfully", this.options);
          this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/admin-azure-config']);
          });
          this.dialog.closeAll();
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 436||error.status == 430) //doesnt work
        {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403){}
        else {
          this.alertService.error("Invalid Input", this.options);
          throw error;
        }
      })

    }//else

  }


}
