<div class="pop-up"   >
    <div class="" >
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0"> Image Details:</span>
        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>
    <hr class="margin-class" />

    <div class="popup-container">
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Bundle id :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.bundle_id || "-" }}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Compute type : </span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.computeType || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Image id :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.imageId || "-"}}</span>
            </div>
        </div>
      

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Image Name :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.name || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Root storage</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.rootStorage}}</span>
            </div>
        </div>
        
        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">Image description :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.tool_description || "-"}}</span>
            </div>
        </div>

        <div class="row pt-2">
            <div class="col-md-4">
                <span class="field-name">User storage :</span>
            </div>
            <div class="col-md-6">
                <span> : &nbsp;{{dataInfo.userStorage || "-"}}</span>
            </div>
        </div>
    </div>
</div>
