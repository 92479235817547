


<div class="pop-up">

  <div class="row">
    <div class="col-8" style="padding-left: 16px;width: 51%;">
      <span *ngIf="this.path=='gitlab'"><img width="30"  src="../../../assets/img/gitlab-logo.png">
      </span>
        <!-- {{element.gitlab}} -->
        <span *ngIf="this.path=='Jenkin'"><img width="30"  src="assets/img/Jenkins.png">
        <!-- {{element.jenkins}} -->
        </span>
        <span *ngIf="this.path=='Jira'"><img width="30" src="assets/img/jira-logo.png">
          <!-- {{element.jira}} -->
        </span>
        <span *ngIf="this.path=='VDI_Creation'"><img width="30"  src="../../../assets/img/addVdi.png">
          <!-- {{element.vdi}} -->
        </span>
        <span *ngIf="this.path=='Virtual Machine'"><img width="30"  src="assets/img/azure-vm.png">
          <!-- {{element.vm}} -->
        </span>
        <span *ngIf="this.path=='TeamMembers'"><img width="30"  src="assets/img/Team Members.png">
          <!-- {{element.vm}} -->
        </span>
        <span *ngIf="this.path=='Project_role'"><img width="30"  src="assets/img/project-role.png">
          <!-- {{element.vm}} -->
        </span>
        <span *ngIf="this.path=='project_onboarding'"><img width="30"  src="assets/img/project-icon.png">
          <!-- {{element.vm}} -->
        </span>
      <span class="vertical-line mx-1 mx-md-3"> </span>
      <!-- <span class="header mb-0"> Member Tools Information</span> -->
      <span class="header mb-0">  {{data.question}}</span>
    </div>
  
    <div class="col-4" >
      <span (click)="onNoClick()" class="material-icons close-icon">
        close
      </span>
    </div>
  </div>
<!--   
  style="padding-left: 347px;padding-top:5px ;"
      <div class="">
          <span  class="vertical-line mx-1 mx-md-3"> </span>
           <span class="header mb-0">{{data.question}}</span> 
          <span (click)="onNoClick()" class="material-icons close-icon">
              close
            </span>
          
      </div>
       -->
      <hr class="margin-class" />
      <div class="image-video-wrp">
          <div class="video-container">
            <video width="100%" autoplay="autoplay" controls style="margin-top: 16px; margin-bottom: 8px;">
              <source src="./assets/FAQ_videos/{{data.info}}.mp4" type="video/mp4">
            </video>
          </div>
       </div>
   
  
  
  </div>
  
  