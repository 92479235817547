<div class="pop-up">
    <div class="">
        <span class="vertical-line mx-3"> </span>
        <span class="header mb-0" *ngIf="option=='Add'">Add Tools</span>
        <span class="header mb-0" *ngIf="option=='Delete'">Remove Tools</span>

        <span (click)="exit()" class="material-icons close-icon">
            close
        </span>
    </div>

    <hr class="margin-class" />

    <div class="popup-container">

        <div class="example-container1" *ngIf="option=='Add'">
        
          <table mat-table [dataSource]="dataSource" >
    
    
          
            <ng-container matColumnDef="imageUrl">
              <th mat-header-cell *matHeaderCellDef> Image  </th>
              <td mat-cell *matCellDef="let element"> <img class="table-img" [src]="element.imageUrl" /> </td>
            </ng-container>
    
            <!-- Email Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Tool Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
    
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox *ngIf="row.checked" (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null" [checked]=true [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
    
                <mat-checkbox *ngIf="!row.checked" (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                  [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
            </tr>
          </table>
        </div>
          <div class="example-container1" *ngIf="option=='Delete'">
            <span class="col-md-3 toolRow" *ngFor="let tool of ToolList;let i = index">
                <span class="close" (click)="deleteTools(tool,i)" title="Delete Tool">x</span>
                <img class="table-img" [src]="tool.imageUrl"><br>
                {{tool.name}}
              
            </span>
               
        </div>
    
        
      </div>


    <div class="row" >
        <div class="offset-md-9 col-md-3">
    
          <button mat-button mat-raised-button class="add-button"  *ngIf="option=='Add'">+ Add
            Tools</button>
    
    
        </div>
      </div>



</div>