<!--New code-->
<div class="inner-page-container">

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
  [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
              <!--<li class="breadcrumb-item"><a routerLink="/admin-scm-tool">SCM Tool</a></li> -->
              <li class="breadcrumb-item active" aria-current="page">GitHub</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 text-right">
          <div class="table-options pt-3">
            <span (click)="addGitHub()"><i class="fas fa-plus"></i> Add</span>
            <span><i class="fas fa-sync-alt"></i> Refresh</span>
           <!-- <span><i class="fas fa-cog"></i> Manage</span> -->
          </div>
        </div>
        <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div>
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/img/GitHub.svg">
          <div class="vertical-line mx-3"> </div> <span>GitHub</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container">
      <div *ngIf="showGitHubForm" class="git-Hub-Form">
        <form (ngSubmit)="onSubmit()" #toolsGroupForm="ngForm">
          <div class="form-row">
          

            <div class="form-group col-md-4">
                <label for="toolGr">Tool Group</label>
                <select  id="toolGr" name="toolGr"
                    class="form-control form-control-lg" required [(ngModel)]="hero.toolGr" #toolGr="ngModel">
                    <option value="">Choose Tool Group</option>
                    <option *ngFor="let p of powers" [value]="p">{{p}}</option>
                </select>

                <div  *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
                    <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label for="proURL">Project URL</label>
                <input  id="proURL" name="proURL"
                    class="form-control form-control-lg" required [(ngModel)]="hero.proURL" #proURL="ngModel">

                <div   *ngIf="proURL.invalid && (proURL.dirty || proURL.touched)" class="alert alert-danger">

                    <div *ngIf="proURL.errors.required">
                        Project URL is required.
                    </div>


                </div>
            </div>

            <div class="form-group col-md-4">
                <label for="proToken">Private Token</label>
                <input id="proToken" class="form-control form-control-lg"
                    required name="proToken" [(ngModel)]="hero.proToken" #proToken="ngModel">

                <div     *ngIf="proToken.invalid && (proToken.dirty || proToken.touched)"
                    class="alert alert-danger">

                    <div *ngIf="proToken.errors.required">
                        Project Token is required.
                    </div>

                </div>
            </div>
  
          </div>
          <div class="text-right">
            <button [disabled]="testConnDisable" type="submit" class="btn-canvas-blue mr-2">
                Test Connection
            </button> 
            <button [disabled]="saveConnDisable" type="button" class="btn-canvas-blue mr-2" (click)="SCMConnSave()">
                Save Connection
            </button>
            <button [disabled]="saveConnDisable" type="button" class="btn-canvas-blue" (click)="closeGitHub()">
                Cancel
              </button>
          </div>
  
        </form>
      </div>
      <div class="mt-5 table-container">
        <table datatable class="table fixed-table">
            <thead>
              <tr>
               
                <th>ID</th>
                <th>Tool Group</th>
                <th>Project URL</th>
                <th>Private Token</th>
              </tr>
            </thead>
            <tbody>
            <!--  <tr *ngFor="let story of StoryBoardArray">
                <td style="width: 15px;"><input type="checkbox"></td>
                <td><span class="link-col" >{{story.toolName}}</span></td>
                <td>{{story.ID}}</td>
                <td>{{story.projectURL}}</td>
                <td>{{story.email}}</td>
                <td>{{story.toolGroup}}</td>
              </tr> -->
            </tbody>
          </table>
        </div>
  
    </div>
  </div>
<!--New code end-->

<!-- <div class="p-5">
    <div>
        <div class="canvas-card p-5">

            <div class="mb-5 d-block">      
                <a routerLink="/admin-scm-tool"><i class="fas fa-arrow-alt-circle-left back-arrow"></i></a> 
                <img src="assets/img/github-logo.png" class="img-fluid project-top-img">
                <span class="project-tool-name">GitHub</span>
              </div>

           

            <div class="row">
                
                <div class="col-md-12">

                    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">


                        <div class="form-group">
                            <label for="toolGr">Tool Group</label>
                            <select style="width: 50%; font-size: 14px; height: 35px;" id="toolGr" name="toolGr"
                                class="form-control" required [(ngModel)]="hero.toolGr" #toolGr="ngModel">
                                <option value="">Choose Tool Group</option>
                                <option *ngFor="let p of powers" [value]="p">{{p}}</option>
                            </select>

                            <div style="width: 50%; font-size: 14px; height: 35px;"
                                *ngIf="toolGr.errors && toolGr.touched" class="alert alert-danger">
                                <div *ngIf="toolGr.errors.required">Tool Group is required.</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="proURL">Project URL</label>
                            <input style="width: 50%; font-size: 14px; height: 35px;" id="proURL" name="proURL"
                                class="form-control" required [(ngModel)]="hero.proURL" #proURL="ngModel">

                            <div style="width: 50%; font-size: 14px; height: 35px;"
                                *ngIf="proURL.invalid && (proURL.dirty || proURL.touched)" class="alert alert-danger">

                                <div *ngIf="proURL.errors.required">
                                    Project URL is required.
                                </div>


                            </div>
                        </div>

                        <div class="form-group">
                            <label for="proToken">Private Token</label>
                            <input style="width: 50%; font-size: 14px; height: 35px;" id="proToken" class="form-control"
                                required name="proToken" [(ngModel)]="hero.proToken" #proToken="ngModel">


                            <div style="width: 50%; font-size: 14px; height: 35px;"
                                *ngIf="proToken.invalid && (proToken.dirty || proToken.touched)"
                                class="alert alert-danger">

                                <div *ngIf="proToken.errors.required">
                                    Project Token is required.
                                </div>

                            </div>
                        </div>

                        <button [disabled]="testConnDisable" type="submit" class="btn-canvas-blue mr-2">
                            Test Connection
                        </button> 
                        <button [disabled]="saveConnDisable" type="button" class="btn-canvas-blue" (click)="SCMConnSave()">
                            Save Connection
                        </button>


                    </form>
                </div>
            </div>

        </div>
    </div>
</div> -->