<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="example-toolbar">
    <div class="mr-5" *ngIf="vdiFormFlag"></div>

    <button type="button"  *ngIf="!vdiFormFlag" mat-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name" routerLink="/home" *ngIf="!vdiFormFlag">
      <img class="img-fluid" [src]="imageSrc" [alt]="imageAlt" height="35" width="250" style="margin-left: -15px">
      <!-- <img class="img-fluid" src="/assets/img/LTI-Canvas-Workplace-logo.svg" alt="Logo" height="35" width="250"> -->
      <!-- <img class="img-fluid" src="/assets/img/Cisco-infinity-Platform.png" alt="Logo" > -->
    </h1>
    <h1 class="" *ngIf="vdiFormFlag">
      <img class="img-fluid" [src]="imageSrc" [alt]="imageAlt" height="35" width="280" style="margin-left: -50px">
      <!-- <img class="img-fluid" src="/assets/img/LTI-Canvas-Workplace-logo.svg" alt="Logo" height="35" width="250"> -->
      <!-- <img class="img-fluid" src="/assets/img/Cisco-infinity-Platform.png" alt="Logo" > -->
    </h1>
    <!-- TBD -->
    
                                

    <!--TBD End-->
    <span class="spacer"></span>

    <span>

      <!-- <button id="iconBtn" value="Dashboard" matTooltip="Dashboard" class="menuIcon ">
        <mat-icon class="iconsetting color-theme-blue">search</mat-icon>
      </button>
      <span class="VerticalLine mx-0 mx-sm-3">|</span> -->
     

    
      <button class="menuIconFAQ color-theme-blue"  *ngIf="!vdiFormFlag" id="iconBtn" style="vertical-align:super" value="Dashboard" matTooltip="FAQs" routerLink='/faq'>
        FAQs
      </button>
      <span class="VerticalLine mx-0 mx-sm-3"  *ngIf="!vdiFormFlag">|</span>
    </span>

    <a class="menuIcon color-theme-blue">{{profile}}</a>

    <div style="background: transparent !important;">
      <button class="menuIcon mt-0" mat-button [matMenuTriggerFor]="beforeMenu">
        <mat-icon class="login color-theme-yellow">arrow_drop_down_circle</mat-icon>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <div class="d-flex flex-row">
          <div class="px-1">
            <div class="img-user-initials" *ngIf="profileImageFlag">{{initials}}</div>
            <img *ngIf="!profileImageFlag" alt="Avatar"
            [src]="imageToShow"
            class="user-avatar">
          </div>          
          <div class="px-1 vLine">
            <span class="user-name">{{profile}}</span><br />
            <span class="user-email">{{userMailId}}</span>
          </div>
        </div>
      <div class="d-flex flex-row my-3" *ngIf="adminRole">
          <div class="px-2 user-role"  *ngIf="!vdiFormFlag">Switch Role</div>
          <div class="px-2"  *ngIf="!vdiFormFlag">
            <div class="role-dropdown" (click)="changeAccess()">
              <div *ngIf="roleAccess == 'User'">Enterprise Admin</div>
              <div *ngIf="roleAccess == 'Admin'">User</div>
            </div>
            <!-- <select class="role-dropdown" (change)="changeAccess($event)">
                <option value="Admin">Admin</option>
                <option value="User">User</option>              
            </select>             -->
          </div>
        </div>
        <div class="seprator">&nbsp;</div>
        <div class="col-md-12 text-center user-name mt-2">
          <span class="material-icons">
            login
            </span>
            <span class="logout-text" (click)="openLogoutModal();">Logout</span>
        </div>
      </mat-menu>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container"  autosize>
      <mat-sidenav #drawer class="side-nav-border" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="47">
      <mat-nav-list class="side-nav-title" *ngFor="let project of projectData; let i = index" [class.active]="selectedIndex === i" (click)="setIndex(i)">
        <mat-list-item class="parent">
            <span (click)=getProjectId($event,project.project_id)>
              Project ({{project.project_id}}) <br />
              <small>{{project.project_name}}</small>
            </span>
        </mat-list-item>        
      </mat-nav-list> 
    </mat-sidenav>
  
    <div class="example-sidenav-content admin-main">
     <div class="bg">
      <div class="error-continaer">
        <alert></alert>
      </div>       
        <router-outlet></router-outlet>
      </div>   
      
    </div>
  </mat-sidenav-container>

  
  
</div>

