<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css">
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="inner-page-container">

    <div class="inner-top-container">
        <div class="row">
            <div class="col-md-6">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent px-0">
                        <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
                        <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Recycle</li>
                    </ol>
                </nav>
            </div>
            <div class="col-md-5 p-md-0 my-auto">
                <span class="table-options pull-right">
                  <span (click)="refreshDisable? refresh():''" [ngClass]="!refreshDisable ? 'disabled-img':''"><i class="fas fa-sync-alt"></i> Refresh</span>
                </span>
                
              </div>
            <div class="col-md-1 p-md-0 my-auto table-options">
                <span routerLink="/workBench" class="float-right text-light">
                    <!-- <i class="fas fa-times"></i> -->
                    <i class="fa fa-step-backward "></i> Back
                </span>
            </div>
        </div>


    </div>

    <div class="inner-mid-container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-sm-5">
                        <h1 class="mb-0">
                            <img width="40" src="../../../assets/img/recycle.png">
                            <div class="vertical-line mx-3"> </div> <span>Recycle</span>
                        </h1>
                    </div>
                    <div class="col-md-7"><span class="field-name">Project ID </span><span class="field-value"> :
                            {{projectId}}</span>
                        <span class="material-icons show-project-info" title="System Information"
                            (click)="showProjectInfo()">info</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-2 position-parent-div">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="selectedTab=='Gitlab'? 'active':''" *ngIf="tableArr?.length > 0"
                        href="#gitLabView" data-toggle="tab" (click)="getTab('Gitlab')" aria-selected="true">Gitlab</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="selectedTab=='Jira'? 'active':''" *ngIf="jiraArray?.length > 0"
                        href="#jiraView" data-toggle="tab" (click)="getTab('Jira')">Jira</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="selectedTab=='VM'? 'active':''" *ngIf="vmData?.length > 0"
                        href="#vmView" data-toggle="tab" (click)="getTab('VM')">VM</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="selectedTab=='Jenkins'? 'active':''" *ngIf="cicdTable?.length > 0"
                        href="#jenkinsView" data-toggle="tab" (click)="getTab('Jenkins')">Jenkins</a>
                </li>
                <li>
                    <a class="nav-link" [ngClass]="selectedTab=='VDI'? 'active':''" *ngIf="vdiDetails?.length > 0"
                        href="#vdiView" data-toggle="tab" (click)="getTab('VDI')">VDI</a>
                </li>
            </ul>

            <div class="tab-content">
                <div class="tab-pane" [ngClass]="selectedTab=='Gitlab'? 'active':''" id="gitLabView">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive mt-2">
                                <table class="table fixed-table" datatable [dtOptions]="dtOptions" *ngIf="showGit">
                                    <thead>
                                        <tr>
                                            <th>Request Id</th>
                                            <th>Repo Name</th>
                                            <th>URL</th>
                                            <th>Tool Instance</th>
                                            <!-- <th>Status</th> -->
                                            <th class="w15">Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let element of tableArr">
                                            <td>{{element.request_id}}</td>
                                            <td class="word-break">{{element.repoName}}</td>
                                            <td class="word-break" *ngIf="element.repoUrl">{{element.repoUrl}}</td>
                                            <td *ngIf="!element.repoUrl">--NA--</td>
                                            <td>{{element.toolInstance}}</td>
                                            <!-- <td>{{element.status=='Soft-Deleted'? 'Moved to Recycle' : '--NA--'}}</td> -->

                                            <td class="w15">
                                                <h4 *ngIf="element.members.length ==0">--NA--</h4>

                                                
                                                <button *ngIf="element.members.length >1" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.request_id,'SCM')">View</button> 
                                                <div *ngIf="element.members.length == 1">
                                                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                                                    <h4>{{user.name}}
                                                  </h4> 
                                                  </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="dropdown">
                                                    <a href="#" class="nav-link" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </a>

                                                    <div class="dropdown-menu more-drop-down">


                                                        <a class="dropdown-item" (click)="showPopUp('Restore',element, 'Gitlab')">
                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20" src="../../../assets/img/restore.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Restore</span>

                                                        </a>
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" (click)="showPopUp('Delete',element, 'Gitlab')">

                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20"
                                                                    src="../../../assets/img/Delete-blue.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Delete Permanently</span>
                                                        </a>

                                                    </div>
                                                </div>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="tab-pane " [ngClass]="selectedTab=='Jira'? 'active':''" id="jiraView">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive mt-2">
                                <table class="table fixed-table" datatable [dtOptions]="dtOptionsJira" *ngIf="showJira">
                                    <thead>
                                        <tr>
                                            <th>Request Id</th>
                                            <th>Project Name</th>
                                            <th>URL</th>
                                            <th>Tool Instance</th>
                                            <th class="w15">Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let element of jiraArray">
                                            <td>{{element.request_id}}</td>
                                            <td class="word-break">{{element.repoName}}</td>
                                            <td *ngIf="element.repoUrl" class="word-break">{{element.repoUrl}}</td>
                                            <td *ngIf="!element.repoUrl">--NA--</td>
                                            <td>{{element.toolInstance}}</td>

                                            <td class="w15">
                                                <h4 *ngIf="element.members.length ==0">--NA--</h4>

                                                <button *ngIf="element.members.length >1" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.request_id,'SCM')">View</button> 
                                                <div *ngIf="element.members.length == 1">
                                                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                                                    <h4>{{user.name}}
                                                  </h4> 
                                                  </span>
                                                </div>
                                            </td>


                                            <td>
                                                <div class="dropdown">
                                                    <a href="#" class="nav-link" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </a>

                                                    <div class="dropdown-menu more-drop-down">
                                                        <a class="dropdown-item" (click)="showPopUp('Restore',element, 'Jira')">
                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20" src="../../../assets/img/restore.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Restore</span>

                                                        </a>
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" (click)="showPopUp('Delete',element, 'Jira')">

                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20"
                                                                    src="../../../assets/img/Delete-blue.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Delete Permanently</span>
                                                        </a>

                                                    </div>
                                                </div>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-content">
                    <div class="tab-pane" [ngClass]="selectedTab=='VM'? 'active':''" id="vmView">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="table-responsive mt-2">
                                    <table datatable class="table fixed-table" [dtOptions]="dtOptionsVM" *ngIf="showVM">
                                        <thead>
                                            <tr>
                                                <th>Request ID</th>
                                                <th> Operating System </th>
                                                <th> VM Size </th>
                                                <th> VM Name </th>
                                                <th> IP Address</th>
                                                <th>Status</th>
                                                <th class="w15">Members</th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let element of vmData">
                                                <td>{{element.request_id}} <span style="font-size: 18px;"
                                                        class="material-icons add-user" title="System Information"
                                                        (click)="showRequestInfo(element)">info</span></td>
                                                <td> {{element.os_type}} </td>
                                                <td> {{element.vm_size}} </td>
                                                <td> {{element.deployment_name ? element.deployment_name : '--NA--'}}
                                                </td>
                                                <td> {{element.ip_address ? element.ip_address : '--NA--'}}</td>
                                                <td  *ngIf='element.status!="Moved_to_Recycle"'> {{element.status | titlecase}}</td>
                                                <td *ngIf='element.status=="Moved_to_Recycle"'>Moved to Recycle</td>
                                                <td class="w15">
                                                    <h4 *ngIf="element.members.length ==0">--NA--</h4>
                                                    <button *ngIf="element.members.length >1" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.request_id,'SCM')">View</button> 
                                                <div *ngIf="element.members.length == 1">
                                                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                                                    <h4>{{user.name}}
                                                  </h4> 
                                                  </span>
                                                </div>
                                                </td>

                                                <td>
                                                        <div class="dropdown" *ngIf="element.status=='Moved_to_Recycle'">
                                                                <a href="#" class="nav-link" data-toggle="dropdown">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </a>
                                                                <div class="dropdown-menu more-drop-down">
        
        
                                                                    <a class="dropdown-item" (click)="showPopUp('Restore',element, 'VM')">
                                                                        <span class="table-crud table-delete-icon">
                                                                            <img width="20"
                                                                                src="../../../assets/img/restore.png">
                                                                            <span class="vertical-line-more mx-2"></span>
                                                                            Restore</span>
        
                                                                    </a>
                                                                    <div class="dropdown-divider"></div>
                                                                    <a class="dropdown-item" (click)="showPopUp('Delete',element, 'VM')">
        
                                                                        <span class="table-crud table-delete-icon">
                                                                            <img width="20"
                                                                                src="../../../assets/img/Delete-blue.png">
                                                                            <span class="vertical-line-more mx-2"></span>
                                                                            Delete Permanently</span>
                                                                    </a>
        
                                                                </div>
                                                            </div>
        
                                                    <div *ngIf="element.status=='Deleting' || element.status=='Processing'">
                                                        <a href="#" class="nav-link" data-toggle="dropdown">
                                                            <i class="fas fa-ellipsis-h"></i>
                                                        </a>
                                                        <div class="dropdown-menu more-drop-down" *ngIf="element.status!='Deleting' || element.status!='Processing'">


                                                            <a class="dropdown-item" >
                                                                <span class="table-crud table-delete-icon">
                                                                    <img width="25"
                                                                        src="../../../assets/img/restore-disabled.png">
                                                                    <span class="vertical-line-more mx-2"></span>
                                                                    Restore</span>

                                                            </a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item">

                                                                <span class="table-crud table-delete-icon">
                                                                    <img width="20"
                                                                        src="../../../assets/img/Delete-Deactivate.png">
                                                                    <span class="vertical-line-more mx-2"></span>
                                                                    Delete Permanently</span>
                                                            </a>

                                                        </div>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane " [ngClass]="selectedTab=='Jenkins'? 'active':''" id="jenkinsView" >
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive mt-2">
                                <table class="table fixed-table" datatable [dtOptions]="dtOptionsJenkins"
                                    *ngIf="showJenkins">
                                    <thead>
                                        <tr>
                                            <th>Request ID</th>
                                            <th>Project Name</th>
                                            <th>Repository</th>
                                            <th>Jenkin URL</th>
                                            <th>Project Type</th>
                                            <th>Tool Instance</th>
                                            <!-- <th>Status</th> -->
                                            <th>Members</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let element of cicdTable">
                                            <td>{{element.request_id}}</td>
                                            <td class="word-break">{{element.project_name}}</td>
                                            <td class="word-break">{{element.repo ? element.repo : '--NA--'}}</td>
                                            <td  class="word-break" *ngIf="element.Jenkin_url">{{element.Jenkin_url}}</td>
                                            <td *ngIf="!element.Jenkin_url">--NA--</td>
                                            <td>{{element.project_type}}</td>
                                            <td class="word-break">{{element.tool_instance}}</td>
                                            <!-- <td>{{element.status}}</td> -->
                                            <td>
                                                <h4 *ngIf="element.members.length ==0">--NA--</h4>

                                                <button *ngIf="element.members.length >1" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.request_id,'SCM')">View</button> 
                                                <div *ngIf="element.members.length == 1">
                                                  <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                                                    <h4>{{user.name}}
                                                  </h4> 
                                                  </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="dropdown">
                                                    <a href="#" class="nav-link" data-toggle="dropdown">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </a>
                                                    <div class="dropdown-menu more-drop-down">
                                                        <a class="dropdown-item" (click)="showPopUp('Restore',element, 'Jenkins')">
                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20" src="../../../assets/img/restore.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Restore</span>

                                                        </a>
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" (click)="showPopUp('Delete',element, 'Jenkins')">

                                                            <span class="table-crud table-delete-icon">
                                                                <img width="20"
                                                                    src="../../../assets/img/Delete-blue.png">
                                                                <span class="vertical-line-more mx-2"></span>
                                                                Delete Permanently</span>
                                                        </a>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-pane " [ngClass]="selectedTab=='VDI'? 'active':''" id="vdiView">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="table-responsive mt-2"> 
                                <table datatable class="table fixed-table" [dtOptions]="dtOptionsVDI" *ngIf="showVDI">
                                  <thead>
                                    <tr>
                                      <th>Request Id</th>
                                      <th>VDI Type</th>
                                      <th>VDI Name</th>
                                      <th>Status</th>
                                      <th>Members</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let element of vdiTable">
                                      <td>{{element.request_id}}
                                        <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfo('vdiInfo',element)">info</span> 
                                      </td> 
                                      <td>{{element.vdi == 'Shared' ? 'Pooled' : 'Personal'}}</td>
                                      <td>{{element.name_prefix ? element.name_prefix : '--NA--'}}</td>
                                      <td  *ngIf='element.status!="Moved_to_Recycle"'>{{element.status}}</td>
                                      <td *ngIf='element.status=="Moved_to_Recycle"'>Moved to Recycle</td>

                                      <td> 
                                          <h4 *ngIf="element.members.length==0">--NA--</h4>
                                        <button *ngIf="element.members.length >1" class="view-member-btn" (click)="openAddedUserDialog(element.members,element.status,element.request_id,'SCM')">View</button> 
                                        <div *ngIf="element.members.length == 1">
                                          <span class="img__wrap1 userRowOne" *ngFor="let user of element.members">
                                            <h4>{{user.name}}
                                          </h4> 
                                          </span>
                                        </div>
                                      </td>
                                     
                                      <td>
                                        <div class="dropdown">
                                            <a href="#" class="nav-link" data-toggle="dropdown">
                                                <i class="fas fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu more-drop-down">


                                                <a class="dropdown-item" (click)="showPopUp('Restore',element, 'VDI')">
                                                    <span class="table-crud table-delete-icon">
                                                        <img width="20"
                                                            src="../../../assets/img/restore.png">
                                                        <span class="vertical-line-more mx-2"></span>
                                                        Restore</span>

                                                </a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" (click)="showPopUp('Delete',element, 'VDI')">

                                                    <span class="table-crud table-delete-icon">
                                                        <img width="20"
                                                            src="../../../assets/img/Delete-blue.png">
                                                        <span class="vertical-line-more mx-2"></span>
                                                        Delete Permanently</span>
                                                </a>

                                            </div>
                                        </div>
                                        
                                      </td>
                                      
                                    </tr>
                                  </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="position-div" *ngIf="displayNoData">
                No Tools have been deleted
            </div>
        </div>
    </div>

    <!--</div>-->
</div>