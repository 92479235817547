<!-- New code-->
<div class="inner-page-container">

    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
    <div class="inner-top-container">
      <div class="row">
        <div class="col-sm-7">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent px-0">
              <li class="breadcrumb-item"><a routerLink="/admin-catalog-config">Admin Home</a></li>
              <li class="breadcrumb-item active" aria-current="page">Reports</li>
            </ol>
          </nav>
        </div>
        <div class="col-sm-4 text-right">
          <div class="table-options pt-3">
           <!--  <span (click)="refreshDevTest()"><i class="fas fa-sync-alt"></i> Refresh</span>
           <span><i class="fas fa-cog"></i> Manage</span> -->
          </div>
        </div>
        <div class="col-md-1 p-md-0 my-auto">
          <a routerLink="/admin-catalog-config" class="float-right step-back">
            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
        </div>
      </div>
      <div>
        <h1 class="text-light mb-0"><img width="40" src="assets/icon/menu/Dashboard White.svg">
          <div class="vertical-line mx-3"> </div> <span>Reports</span>
        </h1>
      </div>
  
    </div>
  
    <div class="inner-mid-container contentent-area">
        <div class="row">
            <div class="col-sm-6">
              
              <div class="graph-layout"> 
               <div class="row">
                 <div class="col-sm-4">
                  <h3 class="heading" >Project Cost          
                  </h3>
                 </div>
                 <div class="col-sm-4">
                  <select class="form-control" #mySelect (change)=onOptionSelected(mySelect.value)>
                    <option *ngFor="let month of months">{{month}}</option>
                  </select>
                 </div>
                 <div class="col-sm-4">
                  <i class="fa fa-ellipsis-v float-right" 
                  aria-hidden="true"></i> 
                 </div>
               </div> 
              
               
                <!-- <canvas baseChart
                  [colors]="barChartColors"
                  [datasets]="barChartData"
                  [labels]="barChartLabels"
                  [options]="barChartOptions"
                  [plugins]="barChatPlugins"
                  [legend]="barChartLegend"
                 
                  >[chartType]="barChartType"
                </canvas> -->
              </div>
            </div>
            <div class="col-sm-6">
              
              <div class="graph-layout"> 
                  <div class="overley-pie-popup" *ngIf="overleyFlag" >
                    <h3> 
                      Tools Filter <span (click)="cancelOverley()" class="material-icons close-icon float-right">
                        close
                      </span>
                    </h3>
                    <form (ngSubmit)="onToolFilterSubmit()" #toolFilterForm="ngForm">
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleFormControlSelect2">Select Project</label>
                            <select class="form-control input-sm" name="toolSelect" (change)=onToolSelected(toolSelect.value)
                             [(ngModel)]="toolFilter.toolSelect"  #toolSelect="ngModel">
                              <option *ngFor="let tool of tools">{{tool}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="exampleFormControlSelect2">Select Month</label>
                            <select class="form-control input-sm" name="monthSelect" (change)=onToolSelected(monthSelect.value)
                             [(ngModel)]="toolFilter.monthSelect"  #monthSelect="ngModel">
                              <option *ngFor="let month of months">{{month}}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="float-right">
                        <button type="submit" class="btn-canvas btn-canvas-blue-2 mr-3 mt-5">Submit</button>
                        <button type="button" class="btn-canvas btn-secondary  mt-5" (click) = "cancelOnToolFilterOverley()">Cancel</button>
                      </div>
                    </form>
                 </div>
               <div class="row">
                 <div class="col-sm-4">
                  <h3 class="heading">Tool Cost          
                  </h3>
                 </div>
                 <div class="col-sm-4">
                  <select class="form-control input-sm" #monthSelect (change)=onToolSelected(monthSelect.value)>
                    <option *ngFor="let month of months">{{month}}</option>
                  </select>
                 </div>
                 <div class="col-sm-4">
                  <span  class="float-right table-bar-icons" (click)="clickOverley()">
                    <i class="fa fa-ellipsis-v float-right" 
                    aria-hidden="true"></i> 
                   </span>
                   <span class="float-right table-bar-icons" [ngClass] = "{'icon-bg': showToolBarChart}" (click)= showBarChart()>
                    <i class="far fa-chart-bar"></i>
                   </span>
                   <span class="float-right table-bar-icons" [ngClass] = "{'icon-bg': ShowToolTableView}" (click)= showTableView()>
                    <i class="fa fa-table" 
                    aria-hidden="true"></i> 
                  </span>
                    
                 </div>
               </div> 
              
               <div *ngIf="showToolBarChart">
                <!-- <canvas baseChart
                [colors]="toolBarChartColors"
                [datasets]="toolBarChartData"
                [labels]="toolBarChartLabels"
                [options]="toolBarChartOptions"
                [plugins]="toolBarChatPlugins"
                [legend]="toolBarChartLegend"
                [chartType]="toolBarChartType">
               </canvas> -->
               </div>
               <div *ngIf="ShowToolTableView">
                 <div class="table-responsive">
                <table datatable [dtOptions]="dtOptions" class="table ">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Bandwidth</th>
                      <th>CIS</th>
                      <th>Jira</th>
                      <th>Storage</th>
                      <th>VM</th>
                      <th>VML</th>
                      <th>VN</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let tool of toolChartArray">
                      <td>{{tool.label}}</td>
                      <td>{{tool.bandwidth || "-"}}</td>
                      <td>{{tool.cis || "-"}}</td>
                      <td>{{tool.jira || "-"}}</td>
                      <td>{{tool.storage || "-"}}</td>
                      <td>{{tool.vm || "-"}}</td>
                      <td>{{tool.vml || "-"}}</td>
                      <td>{{tool.vn || "-"}}</td>
                      <td>{{tool.Total || "-"}}</td>
                    </tr> 
                  </tbody>
                </table>
              </div>
               </div>
               
              </div>
            </div>
            <!-- <div class="col-sm-6">
              <div class="graph-layout">
                <div class="overley-pie-popup" *ngIf="overleyPieFlag" >
                  <h3> 
                    Pie Filter Heading <span (click)="cancelPieOverley()" class="material-icons close-icon float-right">
                      close
                    </span>
                  </h3>
                  <form>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <label for="exampleFormControlSelect2">Filter select</label>
                          <select  class="form-control input-sm" id="exampleFormControlSelect1">
                            <option>15 Days</option>
                            <option>30 Days</option>
                          </select>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="exampleFormControlSelect2">Filter select</label>
                          <select  class="form-control input-sm" id="exampleFormControlSelect2">
                            <option>AKS</option>
                            <option>VDI</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="float-right">
                      <button type="submit" class="btn-canvas btn-canvas-blue-2 mr-3 mt-5">Submit</button>
                      <button type="submit" class="btn-canvas btn-secondary  mt-5" (click) = "cancelPieOverley()">Cancel</button>
                    </div>
                  </form>
               </div>
                <h3 class="heading"  (click)="clickPieOverley()">Project Cost<i class="fa fa-ellipsis-v float-right" aria-hidden="true"></i></h3>
              <canvas baseChart
              [data]="pieChartData"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [colors]="pieChartColors"
              [legend]="pieChartLegend">
            </canvas>
            </div>
             
            </div> -->
            
          </div>
       
      </div>
  
  </div>
