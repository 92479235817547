<div class="inner-page-container" *ngIf="authenticate && !submission">
    <div class="inner-top-container">
        <div class="row">
            <div class="col-sm-7">
                <nav aria-label="breadcrumb">
                    <!-- <ol class="breadcrumb bg-transparent px-0">
                        <li class="breadcrumb-item"><a class="breadcrumb-title" (click)="back()">Admin Home</a></li>
                        <li class="breadcrumb-item"><a routerLink="/admin-scm-tool">SCM Tool</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Canvas Gig</li>
                      </ol> -->
                </nav>
            </div>
            <div class="col-sm-4 col-md-5 text-right">
                <!-- <div class="table-options pt-3">
              
                      
                      <a class="float-right step-back" (click)="back()">
                            <i class="step-back-icon fa fa-step-backward "></i> Back</a>
                    </div> -->
            </div>
        </div>
        <div>
            <h1 class="text-light mb-0"><img width="40" src="assets/img/gitla-logo.png">
                <div class="vertical-line mx-3"> </div> <span class="mainHead">Canvas Tools Onboarding</span>
            </h1>
        </div>

    </div>

    <div class="inner-mid-container">

        <!-- <p>canvas-gig works!</p>
        <p>{{id}}</p>
        <h2 >Assignment Details</h2> -->

        <form (ngSubmit)="onSubmit()" #storyBoardForm="ngForm">
            <div class="row ml-1 mt-3">
                <div class="col-md-3">
                    <div class="form-group">

                        <label class="projectHead" for="projectName">Project ID <span class="required">&#42;</span></label>
                        <input id="projectName" autocomplete="off" name="projectName" type="text" maxlength="20"
                            minlength="3" class="form-control form-control-lg" pattern="^[a-zA-Z0-9_-]+$" required
                            [(ngModel)]="storyBoard.projectName" ngDefaultControl #projectName="ngModel" [disabled]="NameDisable"
                            onPaste="return false">
                        <div *ngIf="projectName.invalid && (projectName.dirty || projectName.touched)"
                            class="alert alert-danger">
                            <div *ngIf="projectName.errors.pattern">Project Name must include only alphabets ,numbers or
                                _,-.
                            </div>
                            <div *ngIf="projectName.errors.minlength">
                                Please enter minimum 3 characters for saving Project Name
                            </div>
                            <div *ngIf="projectName.errors.required">
                                Project Name is required.
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="projectOwner"> Project Owner Name <span class="required">&#42;</span></label>
                        <input id="projectOwner" autocomplete="off" name="projectOwner" type="text" maxlength="50"
                            minlength="3" pattern="^[a-zA-Z0-9_ -]+$" class="form-control form-control-lg" required
                            [(ngModel)]="storyBoard.projectOwner" ngDefaultControl #projectOwner="ngModel">
                        <div *ngIf="projectOwner.invalid && (projectOwner.dirty || projectOwner.touched)"
                            class="alert alert-danger">
                            <div *ngIf="projectOwner.errors.required">
                                Project Owner Name is required.
                            </div>
                            <div *ngIf="projectOwner.errors.minlength">
                                Please enter minimum 3 characters for saving Project Owner Name

                            </div>
                            <div
                                *ngIf="projectOwner.invalid && !projectOwner.errors.minlength &&projectOwner.errors.pattern">
                                Project Owner Name must include only alphabets ,numbers or _,-.</div>

                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="Email">Project Owner Email <span class="required">&#42;</span></label>
                        <input id="Email" autocomplete="off" name="Email" type="text" [disabled]="EmailDisable"
                            class="form-control form-control-lg" required email [(ngModel)]="storyBoard.Email" ngDefaultControl
                            #Email="ngModel">



                        <div *ngIf="Email.invalid && (Email.dirty || Email.touched)" class="alert alert-danger">

                            <div *ngIf="Email.errors.required">
                                Project Owner Email is required.
                            </div>

                            <div id="emailerror" *ngIf="Email.errors?.email">
                                Project Owner Email not valid.
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="domainName"> Domain Name <span class="required">&#42;</span></label>
                        <input id="domainName" autocomplete="off" name="domainName" type="text" maxlength="20"
                            minlength="3" pattern="^[a-zA-Z0-9_ -]+$" class="form-control form-control-lg" required
                            [(ngModel)]="storyBoard.domainName" #domainName="ngModel" ngDefaultControl>
                        <div *ngIf="domainName.invalid && (domainName.dirty || domainName.touched)"
                            class="alert alert-danger">
                            <div *ngIf="domainName.errors.required">
                                Domain Name is required.
                            </div>
                            <div *ngIf="domainName.errors.minlength">
                                Please enter minimum 3 characters for saving Domain Name

                            </div>
                            <div *ngIf="domainName.invalid && !domainName.errors.minlength &&domainName.errors.pattern">
                                Domain Name must include only alphabets ,numbers or _,-.</div>

                        </div>
                    </div>
                </div>

            </div>

            <div class="row ml-3 mt-3">
                <!-- <div class="alert alert-info">
                        <strong>Note:</strong> New users can be added using LTI Canvas Workplace once infrastructure provisioning is done
                    </div> -->
                <div class="alert alert-info alert-dismissible">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>Note:</strong> New users can be added using LTI Canvas Workplace once infrastructure
                    provisioning is done
                </div>
            </div>

            <div class="row  mt-3">
                <div class="col">
                    <fieldset class="border p-3 example-margin">
                        <legend class="w-auto" style="font-weight: bold;">Select Tools</legend>
                        <div class="table-responsive show">
                            <section class="example-section pl-3">
                                <mat-checkbox class="example-margin" [checked]="chkHideSCM"
                                    (change)="seleccionRubrosHideGitlab($event)">Gitlab</mat-checkbox>

                                <mat-checkbox class="example-margin" [checked]="chkHideStoryBoard"
                                    (change)="seleccionRubrosHideStoryBoard($event)">Jira</mat-checkbox>

                                <mat-checkbox class="example-margin" [checked]="chkJenkins"
                                    (change)="seleccionRubrosHideJenkins($event)">Jenkins</mat-checkbox>

                                <mat-checkbox class="example-margin" [checked]="chkMsteams"
                                    (change)="seleccionRubrosHideMsteams($event)">MS teams</mat-checkbox>

                                <mat-checkbox class="example-margin" [checked]="chkWebex"
                                    (change)="seleccionRubrosHideWebex($event)">Webex</mat-checkbox>


                                <!-- VDI nested  -->
                                <div style="margin-top: 58px;">

                                    <mat-checkbox class="example-margin" [checked]="allCompleteVdi"
                                        [color]="taskVdi.color" [indeterminate]="someCompleteVdi()"
                                        (change)="setAllVdi($event.checked)">
                                        {{taskVdi.name}}

                                    </mat-checkbox>
                                    <span class="material-icons show-project-info" (click)="showProjectInfo('VDI')" title="System Information"
                                    >info</span>

                                    <div class="tests">
                                        <li *ngFor="let subtaskVdi of taskVdi.subtasksVdi">
                                            <mat-checkbox [(ngModel)]="subtaskVdi.completed" ngDefaultControl [color]="subtaskVdi.color"
                                                (ngModelChange)="updateAllCompleteVdi()"
                                                [ngModelOptions]="{standalone: true}">
                                                {{subtaskVdi.name}}
                                            </mat-checkbox>

                                        </li>
                                    </div>
                                </div>
                                <!-- Deployment store nested  -->
                                <div style="margin-top: 58px;">
                                        <mat-checkbox class="example-margin" [checked]="allComplete" [color]="task.color"
                                        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                        {{task.name}}
                                        </mat-checkbox>
                                        <span class="material-icons show-project-info" (click)="showProjectInfo('VM')" title="System Information"
                                        >info</span>
                                        <div class="tests">
                                        <li *ngFor="let subtask of task.subtasks">
                                            <mat-checkbox [(ngModel)]="subtask.completed" ngDefaultControl [color]="subtask.color"
                                            (ngModelChange)="updateAllComplete()" [ngModelOptions]="{standalone: true}">
                                            {{subtask.name}}
                                            </mat-checkbox>
                                        
                                        </li>
                                        </div>
                                    </div>
    

                            </section>

                        </div>


                    </fieldset>

                </div>

            </div>

            <div class="row  ml-2">
                <span col-1 class="example-margin mt-5" *ngIf="taskVdi.subtasksVdi[0].completed"  ><label>VDI Type :</label> </span>
                <div class="col-4 mt-5" *ngIf="taskVdi.subtasksVdi[0].completed" >
                        <!-- <mat-radio-group aria-label="Select an option" [(ngModel)]="favoriteSeason" [ngModelOptions]="{standalone: true}">
                                <mat-radio-button value="1">Personal</mat-radio-button>
                                <mat-radio-button class="ml-3" value="2">Pooled</mat-radio-button>
                        </mat-radio-group> -->
                        <mat-checkbox class="example-margin" [checked]="chkHidePersonal"
                        (change)="seleccionRubrosHidePersonal($event)">Personal</mat-checkbox>
                        <mat-checkbox class="example-margin" [checked]="chkHidePooled"
                        (change)="seleccionRubrosHidePooled($event)">Pooled</mat-checkbox>
                    
                </div>

                <div class="col text-right mt-5"  *ngIf="task.subtasks[0].completed"  >
                        <span col-1><label class="example-margin" >VM Type :</label> </span>

                        <mat-checkbox class="example-margin" [checked]="chkHideWin16"
                        (change)="seleccionRubrosHideWin16($event)">Windows 2016</mat-checkbox>
                        <mat-checkbox class="example-margin" [checked]="chkHideWin19"
                        (change)="seleccionRubrosHideWin19($event)">Windows 2019</mat-checkbox>
                        <mat-checkbox class="example-margin" [checked]="chkHideCentos7"
                        (change)="seleccionRubrosHideCentos7($event)">CentOS 7</mat-checkbox>

                </div>
                



            </div>

            <div class="row mt-5 ml-2">
                    <!-- <div class="col-1"></div> -->
                    <div class="table-responsive col-6" *ngIf="chkHidePooled || chkHidePersonal" style="max-height: 300px;" >
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">

                                    <thead>
                                            <tr>
                                                <th style="width: 15px;">
                                                    <!-- <input type="checkbox" [(ngModel)]="masterSelectedVDI" name="list_name" value="m1"
                                                        (change)="checkUncheckAllVDI()" /> -->
                                                        <input type="checkbox" [(ngModel)]="masterSelectedVDI"  ngDefaultControl
                                                        (change)="checkUncheckAllVDI()" [ngModelOptions]="{standalone: true}" />
                                                </th>
                    
                                                <th>VDI Images</th>
                                                <th>Location</th>
                                                <th>Type</th>
                                                <th>Info</th>
                                
                                            </tr>
                                        </thead>

                                        <tbody>
                                                <tr *ngFor="let story of checklistVDI">
                        
                                                    <td style="width: 15px;">
                                                        <!-- <input type="checkbox" [(ngModel)]="story.checked" 
                                                            (change)="isAllSelectedVDI()" /> -->
                                                            <input type="checkbox" [(ngModel)]="story.checked" ngDefaultControl
                                                            (change)="isAllSelectedVDI()" [ngModelOptions]="{standalone: true}"/>
                                                    </td>
                                                    <td>{{story.name}}</td>
                                                    <td>{{story.location}}</td>
                                                    <td>{{story.type}}</td>

                                                    <td> <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfo(story)">info</span></td>

                                                </tr>
                                            </tbody>


                            </table>

                        
                    </div>
                    <div class="col-6" *ngIf="!chkHidePooled && !chkHidePersonal"></div>
                    <!-- <div class="col-1 mr-3"></div> -->
                    <div class="table-responsive col-6 " style="max-height: 300px;" *ngIf="chkHideWin19 || chkHideWin16 || chkHideCentos7">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table ">

                                    <thead>
                                            <tr>
                                                <th style="width: 15px;">
                                                    <input type="checkbox" [(ngModel)]="masterSelected" ngDefaultControl
                                                        (change)="checkUncheckAllVM()" [ngModelOptions]="{standalone: true}" />
                                                </th>
                    
                                                <th>VM Images</th>
                                                <th>Location</th>
                                                <th>Type</th>
                                                <th>Info</th>
                                
                                            </tr>
                                        </thead>

                                        <tbody>
                                                <tr *ngFor="let SCM of checklist">
                        
                                                    <td style="width: 15px;">
                                                        <input type="checkbox" [(ngModel)]="SCM.checked" ngDefaultControl
                                                            (change)="isAllSelectedVM()" [ngModelOptions]="{standalone: true}"/>
                                                    </td>
                                                    <td>{{SCM.name}}</td>
                                                    <td>{{SCM.location}}</td>
                                                    <td>{{SCM.type}}</td>
                                                    <td> <span style="font-size: 18px;" class="material-icons add-user" title="System Information" (click)="showImageInfo(SCM)">info</span></td>


                                                </tr>
                                            </tbody>


                            </table>

                        
                    </div>



            </div>

            <div class="row mt-3 ml-1">
                    <span class="col-3"><label>Other software <br>(Request software if not found in the list)</label></span>
                    
                    <!-- <span class="col-2"><label>Other software</label></span> -->


                <div class="col-md-8">
                    <div class="form-group">

                        <!-- <label for="projectName">Project ID <span class="required">&#42;</span></label> -->
                        <input id="otherSoftware" autocomplete="off" name="otherSoftware" type="text"  class="form-control form-control-lg"  
                            [(ngModel)]="storyBoard.otherSoftware" #otherSoftware="ngModel" ngDefaultControl
                            onPaste="return false">
                        <!-- <div *ngIf="otherSoftware.invalid && (otherSoftware.dirty || otherSoftware.touched)"
                            class="alert alert-danger">
                       
                        

                        </div> -->
                    </div>
                </div>

            </div>

            <div class="row mt-3 mt-4 ml-1">
                <div class="col-12 col-md-4">
                    <div class="form-group">
                      <label for="start">Start Date</label>
                      <input [min]="myDate" type="date" class="form-control" id="start" required
                        [(ngModel)]="model.start" name="start" #name="ngModel" ngDefaultControl>
                    </div>
                    <!-- <p>{{model.start}}</p> -->
                  </div>
                  <div class="col-12 col-md-4">
                    <div class="form-group" (click)="getMinimumEndDate()">
                      <label for="end">End Date </label>
                      <input [min]="minimumEndDate" type="date" class="form-control" id="end" 
                        [(ngModel)]="model.end" name="end" #name="ngModel" ngDefaultControl>
                    </div>
                  </div>
                    <!-- <div class="col-12 col-md-4">
                            <div class="form-group">
                              <label for="start" class="mr-3">Start Date</label>
                              <mat-form-field appearance="fill">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="picker">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>
        

                            </div>
                          </div>
                          <div class="col-12 col-md-4 ml-3">
                            <div class="form-group" >
                              <label for="end" class="mr-3">End Date </label>
                              <mat-form-field appearance="fill">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="picker2">
                                    <mat-hint>MM/DD/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                  </mat-form-field>

                            </div>
                          </div> -->
                          <div class="col-1"></div>
                          <div class="mt-3 ml-5 text-right">
                                <button  type="button" class="btn-canvas-blue"  *ngIf="!submitFlag"
                                [disabled]="(!chkHideSCM &&  !chkHideStoryBoard && !chkJenkins && !chkMsteams && !chkWebex && !taskVdi.subtasksVdi[0].completed && !taskVdi.subtasksVdi[1].completed && !task.subtasks[0].completed && !task.subtasks[1].completed) 
                                || (!storyBoardForm.form.valid)"

                                (click)="get()">
                                Submit
                              </button>
                              <button  type="button" (click)="reset()" *ngIf="!submitFlag" class="btn-canvas-blue ml-4">
                                    Reset
                                  </button>
                        </div>
                          
            </div>
         
 


        </form>

    </div>


</div>

<div class="inner-page-container" *ngIf="submission">

    <div class="inner-mid-container" style="text-align: center; height: 500px;padding: 130px 0; ">
        <img src="assets/img/GigSubmit.png" alt="Submit">

        <h1 class="heading mt-3">Thank you</h1>
        <p style="font-size: 18px;">Your submission has been sent </p>
    </div>
</div>

<div class="inner-page-container" *ngIf="!authenticate && !submission">

    <div class="inner-mid-container" style="text-align: center; height: 500px;padding: 130px 0; ">
        <h1 class="heading">Not found</h1>
        <p style="font-size: 18px;">The page requested could not be found</p>
    </div>
</div>