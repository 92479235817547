<div class="pop-up">
    <div class="">
      <span  class="vertical-line mx-0 mx-md-3"> </span>
       <span class="header mb-0">VDI Audit Log Detail Data</span> 
      <span (click)="exit()" class="material-icons close-icon">
          close
        </span>
    </div>
    
      <hr class="margin-class" />
      <div class="popup-container">
          <!-- login code start here -->
          <div  *ngIf="modalType == 'login'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">User</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.user || "-"}}</div>
                </div>
            </div>
           
          </div>
          <!-- login code end here -->

          <!-- logout code start here -->
          <div  *ngIf="modalType == 'logout'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">User</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.user || "-"}}</div>
                </div>
            </div>
          </div>
          <!-- logout code end here -->

          <!-- update code start here -->
          <div  *ngIf="modalType == 'update'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div><b>Old Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">Analysis State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.analysis_state || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">Original File Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.original_filename || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">Fle Size</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.filesize || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.state || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">IP External</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.ip_external || "-"}}</div>
                </div>
            </div>
            <div><b>New Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">Analysis State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.analysis_state || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">Original File Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.original_filename || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">File Size</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.filesize || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'FetchedFile'">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.state || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">IP External</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.ip_external || "-"}}</div>
                </div>
            </div>
           
          </div>
           <!-- update code end here -->

            <!-- upload code start here -->
          <div  *ngIf="modalType == 'upload'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">User</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.user || "-"}}</div>
                </div>
            </div>
      
            <div><b>Old Attributes</b></div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">File Size</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.filesize || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.state || "-"}}</div>
                </div>
            </div>
            <div><b>New Attributes</b></div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">File Size</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.filesize || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.state || "-"}}</div>
                </div>
            </div>
          </div>
          <!-- upload code end here -->

          <!-- create code start here -->
          <div  *ngIf="modalType == 'create'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">User</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.user || "-"}}</div>
                </div>
            </div>
            <div><b>Old Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Host Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.hostname || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">IP External</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.ip_external || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Group ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.group_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Operating System ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.operating_system_id || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Agent'">
                <div class="col-sm-3">
                    <div class="field-name">Policy ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.policy_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Agent'">
                <div class="col-sm-3">
                    <div class="field-name">Product Version ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.product_version_id || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Application Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.application_name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Api Client ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.api_client_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Scope</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.scope || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.state || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Notification Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.notification_email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Admin</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.admin || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Preferred Locale</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.preferred_locale || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Policies</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.policies || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Name First</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name_first || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Name Last</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name_last || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'ScanRequest'">
                <div class="col-sm-3">
                    <div class="field-name">Scan Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.scan_type || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ScanRequest'">
                <div class="col-sm-3">
                    <div class="field-name">Agent GuID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.agent_guid || "-"}}</div>
                </div>
            </div>

            <div><b>New Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Host Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.hostname || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">IP External</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.ip_external || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Group ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.group_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Computer'">
                <div class="col-sm-3">
                    <div class="field-name">Operating System ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.operating_system_id || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Agent'">
                <div class="col-sm-3">
                    <div class="field-name">Policy ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.policy_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Agent'">
                <div class="col-sm-3">
                    <div class="field-name">Product Version ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.product_version_id || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Application Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.application_name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Api Client ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.api_client_id || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Scope</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.scope || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">State</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.state || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Notification Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.notification_email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Admin</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.admin || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'User'">
                <div class="col-sm-3">
                    <div class="field-name">Preferred Locale</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.preferred_locale || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Policies</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.policies || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Email</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.email || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Name First</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name_first || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'Person'">
                <div class="col-sm-3">
                    <div class="field-name">Name Last</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name_last || "-"}}</div>
                </div>
            </div>

            <div class="row"  *ngIf="detailData.audit_log_type == 'ScanRequest'">
                <div class="col-sm-3">
                    <div class="field-name">Scan Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.scan_type || "-"}}</div>
                </div>
            </div>
            <div class="row"  *ngIf="detailData.audit_log_type == 'ScanRequest'">
                <div class="col-sm-3">
                    <div class="field-name">Agent GuID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.agent_guid || "-"}}</div>
                </div>
            </div>
          </div>
          <!-- create code end here -->

          <!-- destroy code start here -->
          <div  *ngIf="modalType == 'destroy'">
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log Type</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_type || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Audit Log ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.audit_log_id || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">Details</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.details || "-"}}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="field-name">User</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.user || "-"}}</div>
                </div>
            </div>
            <div><b>Old Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Active</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.active || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Default</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.default || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Ancestry</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.ancestry || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Application Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.application_name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Api Client ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.api_client_id || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Scope</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.scope || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">User ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.user_id || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Expires At</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.expires_at || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Audit Logs for Downloads Enabled</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.audit_logs_fr_downloads_enabled || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Default</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.default || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Policy'">
                <div class="col-sm-3">
                    <div class="field-name">Description</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.old_attributes.description || "-"}}</div>
                </div>
            </div>
           
            <div><b>New Attributes</b></div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Active</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.active || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Default</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.default || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'Group'">
                <div class="col-sm-3">
                    <div class="field-name">Ancestry</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.ancestry || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Application Name</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.application_name || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Api Client ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.api_client_id || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Scope</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.scope || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">User ID</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.user_id || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Expires At</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.expires_at || "-"}}</div>
                </div>
            </div>
            <div class="row" *ngIf="detailData.audit_log_type == 'ApiCredential'">
                <div class="col-sm-3">
                    <div class="field-name">Audit Logs for Downloads Enabled</div>
                </div>
                <div class="col-sm-9">
                    <div class="detail-field-value">{{detailData.new_attributes.audit_logs_fr_downloads_enabled || "-"}}</div>
                </div>
            </div>
          </div>
          <!-- destroy code end here -->
          
      </div>
    </div>