<div class="inner-page-container">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple"
    [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">MS Teams</li>
          </ol>
        </nav>
      </div>
      <div class="offset-md-5 col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/teamsDashboard" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>

   

  </div>
  <div class="inner-mid-container mt-2">
    <div class="row">
      <div class="col-md-3">
        <h1 class="page-name mb-0">
          <img width="40" src="assets/img/teams_logo.png">
          <div class="vertical-line mx-3"> </div> <span>MS Teams</span>
        </h1>
      </div>
      <div class="col-md-3">
        <span class="field-name">Project ID </span><span class="field-value"> :
          {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information"
          (click)="showProjectInfo()">info</span>
      </div>

    </div>
    <div class="row ptPercent">
      <span title="Add" class="offset-10 offset-md-11 hover" (click)="showArtifactForm()"><i class="fas fa-plus"></i> Add</span>

      <form [formGroup]="firstFormGroup" class="pl-0" *ngIf="artifactForm" style="width:100%">
        <div class="col-lg-12 pl-3">
          <label>Meeting Artifact Details :</label>
        </div>

        <div class="row padding0 pt10 pl-3">
          <div class="col-12 pl-3"><label>Meeting Name</label>
            <span class="material-icons show-meteing-info" title="System Information"
            (click)="showMeetingInfo()">info</span>
           </div>
          
         
        </div>
        <div class="row padding0 pt10 pl-3">
          <div class="col-12  col-md-4 pl-3">
          <select id="meetingName" name="meetingName" formControlName="meetingName" [(ngModel)]="meetingNameValue"
          class="form-control form-control-recurring form-control-height select"
          (change)="meetingNameBody(meetingNameValue)">
          <option value="" disabled selected hidden>Select meeting name</option>
          <option *ngFor="let meetingName of meetingNameArray" class="dropdown-css"
            [value]="meetingName.MeetingName">{{meetingName.MeetingName}}</option>
          </select>
           
          <!-- <small class="text-danger" [class.d-none]="meetingName.valid || meetingName.untouched">Required</small> -->
          
          <!-- <div>
            <label>Meeting Details </label><br>{{meetingDetail}} 
         </div>  -->

          
        </div>
          
        </div>
        <!-- ////////////
        <div class="col-lg-12 padding0">
          <div class="flex-container">
            <div>
              <label for="meetingName">Meeting Name </label> <span class="material-icons show-meteing-info" title="System Information"
              (click)="showMeetingInfo()">info</span>
              <select id="meetingName" name="meetingName" formControlName="meetingName" [(ngModel)]="meetingNameValue"
                class="form-control form-control-recurring form-control-height select"
                (change)="meetingNameBody(meetingNameValue)">
                <option value="" disabled selected hidden>Select meeting name</option>
                <option *ngFor="let meetingName of meetingNameArray" class="dropdown-css"
                  [value]="meetingName.MeetingName">{{meetingName.MeetingName}}</option>
              </select>
              -->
              <!-- <small class="text-danger" [class.d-none]="meetingName.valid || meetingName.untouched">Required</small> -->

            <!-- </div>
            <div>
              <label>Meeting Details </label><br>{{meetingDetail}} 
           </div> -->
          <!-- </div>
        </div> -->
        <!-- ///////////// -->
        <div class="row  padding0 pt10 pl-3">
          <div class="col-lg-12 pl-3"><label>Report Utilization :</label> </div>
        </div>
        <div class="row  padding0 pt10 pl-3">
          <div class="form-group col-md-6 col-12">
            <label for="reportType">Report Type </label>
            <select id="reportType" name="reportType" formControlName="reportType"
              class="form-control form-control-recurring form-control-height select">
              <option value="" disabled selected hidden>Select report type</option>
              <option class="dropdown-css" *ngFor="let reportType of reportTypeArray" [value]="reportType">
                {{reportType}}</option>
            </select>
            <!-- <small class="text-danger" [class.d-none]="reportType.valid || reportType.untouched">Required</small> -->

          </div>
          <div class="form-group col-md-6 col-12">
            <label for="reportTime">Report send before minutes </label>

            <input formControlName="reportTime" type="number" min="1" class="form-control form-control-recurring">
            <!-- <small class="text-danger" [class.d-none]="reportTime.valid || reportTime.untouched">Required</small> -->

          </div>
        </div>
        <div class="row padding0 pt10 pl-3">
          <div class="form-group col-12 col-md-4">
            <label for="repoVendor">Report Source </label>
            <select (change)="getRepoVendorValue()" formControlName="repoVendor"
              class="form-control form-control-recurring form-control-height select" required>
              <option value="" disabled selected hidden>Select report vendor</option>
              <option class="dropdown-css" *ngFor="let repoVendor of vendor" [value]="repoVendor">{{repoVendor}}
              </option>
            </select>
            <!-- <small class="text-danger" [class.d-none]="repoVendor.valid || repoVendor.untouched">Required</small> -->
          </div>
          <div class="form-group col-12 col-md-4">
          <div *ngIf="repoVendorValue == 'Azure DevOps'">
           
              <label for="orgName">Organization Name </label>
              <select id="orgName" name="orgName" formControlName="orgName"
                class="form-control form-control-recurring form-control-height select">
                <option value="" disabled selected hidden>Select organization name</option>
                <option class="dropdown-css" *ngFor="let orgName of orgNameArray" [value]="orgName">{{orgName}}
                </option>
              </select>
              <!-- <small class="text-danger" [class.d-none]="orgName.valid || orgName.untouched">Required</small> -->

            </div>
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="projName">Project Name </label>
            <select id="projName" name="projName" formControlName="projName"
              class="form-control form-control-recurring form-control-height select">
              <option value="" disabled selected hidden>Select project name</option>
              <option class="dropdown-css" *ngFor="let projName of projNameArray" [value]="projName">{{projName}}
              </option>
            </select>
            <!-- <small class="text-danger" [class.d-none]="projName.valid || projName.untouched">Required</small> -->

          </div>
        </div>

        <div>

        </div>



        <div class="row padding0 pt10 pl-3">
          <div class="col-lg-12"><label>MS Teams Details :</label> </div>

          <div class="col-lg-4 pull-left padding-right0">
            <div class="form-group">
              <label for="teamName">Team Name </label>
              <select (change)="getSpaceNameForArti()"  formControlName="teamName"
                class="form-control form-control-recurring form-control-height select" required>
                <option value="" disabled selected hidden>Select team name</option>
                <option class="dropdown-css" *ngFor="let teamName of teamNameArray" [value]="teamName.teamid">
                  {{teamName.teamName}}</option>
              </select>
              <!-- <small class="text-danger" [class.d-none]="teamName.valid || teamName.untouched">Required</small> -->
            </div>
          </div>
          <div class="col-lg-4 pull-left padding-right0">
            <div class="form-group">
              <label for="roomName">Channel Name </label>
              <select id="roomName" name="roomName" formControlName="roomName"
                class="form-control form-control-recurring form-control-height select">
                <option value="" disabled selected hidden>Select Channel name</option>
                <option class="dropdown-css" *ngFor="let spaceName of spaceNameArray" [value]="spaceName.channelName">
                  {{spaceName.channelName}}</option>
              </select>
              <!-- <small class="text-danger" [class.d-none]="roomName.valid || roomName.untouched">Required</small> -->

            </div>
          </div>
        </div>

        <div class="row padding0 pt10 pl-3">
          <div class="col-lg-12"><label>Scheduler Configuration :</label> </div>
          <div class="col-lg-1 pull-left padding-right0">
            <div class="form-group select-editable">
              <!-- <label for="url">Schedule Time </label> -->
              <select onchange="this.nextElementSibling.value=this.value" formControlName="scheduleTime"
                class="form-control form-control-recurring form-control-height select" required>
                <option value="" disabled selected hidden>Select Schedule Time</option>
                <option class="dropdown-css" *ngFor="let scheduleTime of recurrencetime" [value]="scheduleTime">
                  {{scheduleTime}}</option>
              </select>
              <input type="text" name="format" value=""sformControlName="scheduleTime" />
              <!-- <small class="text-danger" [class.d-none]="scheduleTime.valid || scheduleTime.untouched">Required</small> -->
            </div>
          </div>

          <span title="Execute Scheduler" class="hover" style="padding-left: 3%;
        padding-bottom: 2%;" (click)="executeMSScheduler()"><i class="fas fa-sync-alt"></i> </span>


        </div>

        <div class="col-12 padding0 pl-3">
          <button class="col-5" mat-raised-button color="primary" type="submit" 
            (click)="artifactsMSValue()" class="btn btn-secondary" [disabled]="!firstFormGroup.valid">Submit</button>
          <!-- <button class="col-5" type="submit" mat-raised-button color="primary" 
            (click)="artifactsMSValue()" [disabled]="!firstFormGroup.valid" >Submit</button> -->
          <!-- <button class="col-5" mat-raised-button color="primary" type="button" style="margin-left: 1%;" (click)="executeScheduler()" [disabled]="!firstFormGroup.valid" class="btn btn-secondary">Execute Scheduler</button> -->
          <button class="col-5 " mat-raised-button color="primary" type="button"
            (click)="clearArtifacts()" class="btn btn-secondary ml-3">Cancel</button>
        </div>
      </form>

      <div class="row mt-2" *ngIf="existingMSArtifacts">
        <table class="table fixed-table" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>Meeting Name</th>
              <th>Report Vendor</th>
              <th>Report Type</th>
              <th>Project Name</th>
              <th>Team Name</th>
              <th>Space Name</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Trigger Event Before</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let artifact of artifactTable">
              <td>{{artifact.MeetingName}}</td>
              <td>{{artifact.ReportVendor}}</td>
              <td>{{artifact.ReportType}}</td>
              <td>{{artifact.ProjectName}}</td>
              <td>{{artifact.TeamName}}</td>
              <td>{{artifact.SpaceName}}</td>
              <td>{{artifact.createdAt}}</td>
              <td>{{artifact.updatedAt}}</td>
              <td>{{artifact.TriggerEventBefore}}</td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>
  </div>
</div>