<div class="pop-up">
    <div class="">
        <span  class="vertical-line mx-3"> </span>
         <span class="header mb-0">View Member</span> 
        <span (click)="closeDialog()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <div class="row">
            <div class="col-sm-4 col-12 img__wrap userRow" *ngFor="let user of memberList;let i = index">
                <span >
                    <!-- <span class="close" (click)="deleteUser(user,i)" *ngIf="selectedRole!='Project Manager'" title="Delete User">x</span> -->
                    <img src="../../../assets/img/UserIcon.png"><br>
                    {{user.displayName}}
                    <span class="img__description_layer img__details card">
                      <img src="../../../assets/img/UserIcon.png">
                      <h3 class="font-14">{{user.displayName}}</h3>
                      <h5>{{user.mail}}</h5>
                    </span>
                </span>
            </div>
          
        </div>
        
           
    </div>
</div>