<link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css">
<div class="inner-page-container">

  <div class="inner-top-container">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">MS Teams</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/teamsDashboard" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
  </div>
  <div class="inner-mid-container">
    <div class="row">
      <div class="col-md-3">
        <h1 class="page-name mb-0">
          <img width="40" src="assets/img/teams_logo.png">
          <div class="vertical-line mx-3"> </div> <span>MS Teams</span>
        </h1>
      </div>
      <div class="col-md-3"><span class="field-name">Project ID </span><span class="field-value"> :
          {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information"
          (click)="showProjectInfo()">info</span>
      </div>


    </div>
    <div class="row ml-md-2">
        <!-- <br> -->
        <!-- <b>Meeting Details :</b> -->
        <br>
        <label>Select Agile Ceremonies </label>
      
      </div>
      <!-- <br> -->
      <div class="row">
        <!-- To display the agile ceremonies -->
       
          
          
        
          <mat-card [ngClass]="{'highlight' : selectedCard[i], 'not-highlight' : !selectedCard[i]}"
            class=" col-md-2  ceremony-tiles" *ngFor="let ceremony of ceremonys; let i = index"
            (click)="onChange(ceremony);onSelect(i)"> {{ceremony.subject}}</mat-card>

        
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6">
            <div class="form group">
              <div><label>Select Projects </label></div>
              
                <ng-multiselect-dropdown [placeholder]="'Select Projects'" [settings]="dropdownSettings" [data]="dropdownList"
                  [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)" name="fieldName" ngDefaultControl>
                </ng-multiselect-dropdown>
          
        

            </div>

        </div>
      </div>


      <!-- <div class="row"> -->
        
        <form (ngSubmit)="onSubmit()" #newEventForm="ngForm">
            <div class="row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="subject">Subject</label>
                <input type="text" class="form-control" id="subject" [(ngModel)]="model.subject" name="subject"
                  #name="ngModel">
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="body">Agenda</label>
                <input type="text" class="form-control" id="body" [(ngModel)]="model.body" name="body" #name="ngModel">
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="schedule">Schedule</label>
                <input type="text" class="form-control" id="schedule" readonly name="schedule" #name="ngModel"
                  [(ngModel)]="schedule">
              </div>
            </div>
          </div>
       


          <div class="row mt-2">
            <div class="col-12">

              <div class="form-group">
                <label id="example-radio-group-label">Select Meeting Type :</label>
                <mat-radio-group aria-labelledby="example-radio-group-label" name="opList" [(ngModel)]="selectedType">
                  <mat-radio-button class="col-6 col-md-1 ml-5 example-radio-button" *ngFor="let selectedtype of types"
                    [value]="selectedtype" (click)="getSelectedType()">
                    {{selectedtype}}
                  </mat-radio-button>
                </mat-radio-group>

              </div>
            </div>

          </div>
         

          <div class="row mt-2" *ngIf="selectedType == 'Once'">
            <div class="col-12 col-md-4">
              <div class="form-group">
                <label for="start">Start Date</label>
                <input [min]="myDate" type="datetime-local" class="form-control" id="start" required
                  [(ngModel)]="model.start" name="start" #name="ngModel">
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="form-group" (click)="getMinimumEndDate()">
                <label for="end">End Date </label>
                <input [min]="minimumEndDate" type="datetime-local" class="form-control" id="end" required
                  [(ngModel)]="model.end" name="end" #name="ngModel">
              </div>
            </div>

          </div>



          <div class="pop-up" *ngIf="selectedType == 'Recurring'">

            <div class="popup-container">

              <div class="example-container1 ">
                <form [formGroup]="secondFormGroup" class="pl-0">

                  <div class="row mt-2">
                    <div class="col-12 col-md-12">
                      Appointment Time :
                    </div>
                    
                    
                  
                  </div>
                  <div class="row mt-2">

                  
                  <div class="col-12 col-md-4">

                      <div class="form-group">
                        <label for="startTime">Start </label>
                        <select id="sttime" name="startTime" formControlName="startTime"
                          class="form-control form-control-recurring form-control-height select">
                          <option value="" disabled selected hidden>Select start time</option>
                          <option class="dropdown-css" *ngFor="let startTime of recurrencetime" [value]="startTime">
                            {{startTime}}</option>
                        </select>
                        <small class="text-danger"
                          [class.d-none]="startTime.valid || startTime.untouched">Required</small>

                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="endTime">End </label>
                        <select formControlName="endTime"
                          class="form-control form-control-recurring form-control-height select" required>
                          <option value="" disabled selected hidden>Select end time</option>
                          <option class="dropdown-css" *ngFor="let endTime of recurrencetime" [value]="endTime">
                            {{endTime}}</option>
                        </select>
                        <small class="text-danger" [class.d-none]="endTime.valid || endTime.untouched">Required</small>
                      </div>
                    </div>

                  </div>




                  <div class="row" >
                    <div class="col-md-12 col-lg-12">Recurrence Pattern :</div>
                  </div>
                    <div class="row" >
                    <div class="form-group col-lg-2 col-6 pl-5 pt-2">
                      <input type="radio" id="Daily" name="Recurrence" value="Daily" (change)="radiovalue('Daily')">
                      <label for="Daily">Daily</label><br>
                      <input type="radio" id="Weekly" name="Recurrence" value="Weekly" checked
                        (change)="radiovalue('Weekly')">
                      <label for="Weekly">Weekly</label><br>
                      <input type="radio" id="Monthly" name="Recurrence" value="Monthly"
                        (change)="radiovalue('Monthly')">
                      <label for="Monthly">Monthly</label><br>
                      <input type="radio" id="Yearly" name="Recurrence" value="Yearly" (change)="radiovalue('Yearly')">
                      <label for="Yearly">Yearly</label>
                      
                    </div>
                    <span class="vertical-line mx-3" style="height: 16vh !important;"> </span>

                    <div class="col-lg-9 ml-4" *ngIf="recurrencePatternRadio == 'Weekly'">
                      <div class="row"> <span> Recur every </span>
                        <input formControlName="weeklyNoOfDays" type="number" min="1"
                          class="col-lg-1 col-5  form-control form-control-recurring" style="margin: 0 1vh">
                        <span>week(s) on</span></div>
                      <div class=" row form-group ">
                        <label class="btn monday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Monday')"> Monday
                        </label> &nbsp;
                        <label class="btn tuesday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Tuesday')"> Tuesday
                        </label> &nbsp;
                        <label class="btn wednesday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Wednesday')"> Wednesday
                        </label> &nbsp;
                        <label class="btn thursday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Thursday')"> Thursday
                        </label> &nbsp;
                        <label class="btn friday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Friday')"> Friday
                        </label> &nbsp;
                        <label class="btn saturday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Saturday')"> Saturday
                        </label> &nbsp;
                        <label class="btn sunday" ngbButtonLabel>
                          <input type="checkbox" ngbButton (change)="onCheckboxChange('Sunday')"> Sunday
                        </label>
                      </div>
                    </div>

                    <div class="col-lg-9" *ngIf="recurrencePatternRadio == 'Daily'">
                      <div class="col-lg-4 form-group">
                        <input type="radio" id="Every" name="DailyOption" value="Every" checked
                          (click)="recurrenceRadioDaily('Every')">
                        <label for="Every">Every : </label><br>
                        <input type="radio" id="Every Weekday" name="DailyOption" value="Every Weekday"
                          (click)="recurrenceRadioDaily('Every Weekday')">
                        <label for="Every Weekday">Every Weekday</label>
                      </div>
                      <div class="col-lg-8 form-group ml18Percent">
                        <input formControlName="dailyNoOfDays" type="number" min="1"
                          class="col-lg-1 form-control form-control-recurring" style="margin: 0 1vh">
                        <span>day(s)</span>
                      </div>
                    </div>

                    <div class="col-lg-9" *ngIf="recurrencePatternRadio == 'Monthly'">
                      <div class="col-lg-2 form-group">
                        <input type="radio" id="Day" name="MonthlyOption" value="Day" checked
                          (click)="recurrenceRadioMonthly('Day')">
                        <label for="Day">Day</label><br>
                        <input type="radio" id="The" name="MonthlyOption" value="The"
                          (click)="recurrenceRadioMonthly('The')">
                        <label for="The">The</label><br>
                      </div>
                      <div class="col-lg-10">
                        <div class="row ptPercent">
                          <input formControlName="monthlyDayDate" type="number" min="1" max="31"
                            class="col-md-1 ml-md-5 col-2 form-control form-control-recurring">
                          <span class="col-1 ml-3  mr-5 ml-md-0 mr-md-3">of every</span>
                          <input formControlName="monthlyDayMonthCount" type="number" min="1" max="12"
                            class="col-md-1 col-2 ml-md-0 ml-2 form-control form-control-recurring">
                          <span class="col-md-4 col-2 ">month(s) </span>
                        </div>

                        <div class="row ptPercent">
                          <select formControlName="monthlyTheWeekNo"
                              class="col-md-2 col-5 ml-md-5 form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Week no</option>
                              <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                {{weekNo.name}}</option>
                            </select>
                            <select id="weekDayValue" name="weekDayValue" formControlName="monthlyTheDay"
                              class="ml-2 col-5 col-md-2  form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Day</option>
                              <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                {{selectedDay}}</option>
                            </select>
                            <span class="col-lg-1 ml-md-1">of every</span>
                            <input formControlName="monthlyTheMonthCount" type="number" min="1" max="12"
                            class="ml-1 col-md-1 col-5 ml-md-1 form-control form-control-recurring" style="margin: 0 -3vh">
                            <span class="col-1 ml-4">month(s) </span>
                       
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-9  ml-4" *ngIf="recurrencePatternRadio == 'Yearly'">
                      <div class="row ptPercent"> <span> Recur every </span>
                        <input formControlName="yearlyNoOfYears" type="number" min="1"
                          class="col-lg-1 form-control form-control-recurring" style="margin: 0 1vh">
                        <span>year (s) on</span></div>
                      <div class="row ptPercent">
                        <input type="radio" id="On" name="YearlyOption" value="On" checked
                            (click)="recurrenceRadioYearly('On')">
                            <span class="col-md-1 col-12">On</span>
                          <select formControlName="yearlyOnMonth"
                                class="col-md-2 col-5 offset-md-1 form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>
                              <input formControlName="yearlyOnDate" type="number" min="1" max="31"
                              class="col-md-1 col-5 ml-2 form-control form-control-recurring">
                        <!-- <div class="col-lg-3 form-group">
                          <input type="radio" id="On" name="YearlyOption" value="On" checked
                            (click)="recurrenceRadioYearly('On')">
                          <label for="On">On</label><br>
                          <input type="radio" id="On The" name="YearlyOption" value="On The"
                            (click)="recurrenceRadioYearly('On The')">
                          <label for="On The">On The</label><br>
                        </div>
                        <div class="col-lg-9">
                          <div class="row ptPercent" style="margin: 0 -10vh">
                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnMonth"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>

                            </div>

                            <input formControlName="yearlyOnDate" type="number" min="1" max="31"
                              class="col-lg-2 form-control form-control-recurring">
                          </div>
                          <div class="row " style="margin: 0 -10vh">
                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnTheWeekNo"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select week No.</option>
                                <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                  {{weekNo.name}}</option>
                              </select>

                            </div>

                            <div class="form-group col-lg-3 ">
                              <select formControlName="yearlyOnTheDay"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Day</option>
                                <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                  {{selectedDay}}</option>
                              </select>

                            </div>

                            <span class="col-lg-1">Of</span>
                            <div class="form-group col-lg-3 " style="margin: 0 -7vh">
                              <select formControlName="yearlyOnTheMonth"
                                class="form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Month</option>
                                <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                  [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                              </select>
                            </div>
                          </div>

                        </div> -->
                      </div>
                        <div class="row pt-2 ptPercent">
                          <input type="radio" id="On The" name="YearlyOption" value="On The"
                          (click)="recurrenceRadioYearly('On The')">
                          <span class="col-md-1 col-12">On The</span>
                            <select formControlName="yearlyOnTheWeekNo"
                                class="col-md-2 col-3 offset-md-1 form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select week No.</option>
                                <option class="dropdown-css" *ngFor="let weekNo of weekRank" [value]="weekNo.id">
                                  {{weekNo.name}}</option>
                              </select>
                              <select formControlName="yearlyOnTheDay"
                                class="col-md-2 col-3 ml-2  form-control form-control-recurring form-control-height select">
                                <option value="" disabled selected hidden>Select Day</option>
                                <option class="dropdown-css" *ngFor="let selectedDay of weekDay" [value]="selectedDay">
                                  {{selectedDay}}</option>
                              </select>
                              <span class="ml-md-3 mr-md-3 ml-1 mr-1">Of</span>
                              <select formControlName="yearlyOnTheMonth"
                              class="col-md-2 col-4 form-control form-control-recurring form-control-height select">
                              <option value="" disabled selected hidden>Select Month</option>
                              <option class="dropdown-css" *ngFor="let selectedMonth of month"
                                [value]="selectedMonth.id">{{selectedMonth.name}}</option>
                            </select>


                    

                      </div>
                    </div>

                  </div>
                    
                  <div class="row">
                    <div class="col-12"><label>Range of Recurrence :</label></div>
                  </div>
                    <!-- <div class="col-md-12 col-lg-12">Range of Recurrence :</div> -->
                    <div class="row">
                      <div class="col-12 col-md-4">
                
                          <div class="form-group">
                            <label for="endTime">Start </label>
                            <input [min]="customDate" type="date" formControlName="startByCalendar"
                              class="form-control form-control-recurring">
                            <small class="text-danger"
                              [class.d-none]="startByCalendar.valid || startByCalendar.untouched">Required</small>
                          </div>
                        <!-- </div> -->
                      </div>

                      <div class="col-12 col-md-2">
                        <input type="radio" id="End By" name="EndDate" checked value="End By"
                          (click)="endRadio('endBy')">
                        <label for="End By">End By :</label><br>
                        <input type="radio" id="End After" name="EndDate" value="End After"
                          (click)="endRadio('endAfter')">
                        <label for="End After">End After :</label><br>
                        <input type="radio" id="No End Date" name="EndDate" value="No End Date"
                          (click)="endRadio('noEnd')">
                        <label for="No End">No End Date</label>
                      </div>

                      <div class="col-lg-6">
                        <div class=" row form-group col-8 col-md-4" (click)="getMinValue()">
                          <input [min]="startCalender" formControlName="endByCalendar" type="date"
                            class="form-control form-control-recurring">
                        </div>

                        <div class=" row form-group col-8">
                          <input type="number" formControlName="endAfterCount" min="1"
                            class="col-lg-4 form-control form-control-recurring">
                          <span class="col-lg-2">occurences</span>
                        </div>


                      </div>
                    </div>
                  

                </form>
              </div>
            </div>

          </div>

          <div class="col-lg-8 padding0 row" *ngIf="selectedType == 'Once'">
            <button class="col-1" class="btn btn-secondary" type="submit" mat-raised-button color="primary" style="width: 16vh;margin-left: 2%;"
              [disabled]="!newEventForm.form.valid">Create</button>
            <button class="col-5" mat-raised-button color="primary" type="button" style="margin-left: 4%;width: 16vh"
              (click)="handleClear()" class="btn btn-secondary">Cancel</button>
          </div>
          <div class="col-lg-8 padding0 row" *ngIf="selectedType == 'Recurring'">
            <button class="col-1" class="btn btn-secondary" type="submit" mat-raised-button color="primary" style="width: 16vh;margin-left: 2%;"
              (click)="formvalue()" [disabled]="!secondFormGroup.valid">Create</button>
            <button class="col-5" mat-raised-button color="primary" type="button" style="margin-left: 4%;width: 16vh"
              class="btn btn-secondary" (click)="handleClear();clearForm()">Cancel</button>
          </div>
        </form>
      <!-- </div> -->
      
    </div>
  </div>