import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CastDialogComponent } from 'src/app/project-configurations/work-bench/work-bench.component';
import { Subject, throwError } from 'rxjs';	

export interface configList {
  toolName: string;
  ID: string;
  db_id:string;
  email: string;
  name: string;
  description: string;
  projectURL: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}


export interface cicdDetails {
  toolName: string;
  ID: string;
  db_id:string;
  projectURL: string;
  email: string;
  name: string;
  description: string;
  toolGroup: string;
  toolGroup_id: string;
  projectToken: string;
}

@Component({
    selector: 'app-admin-ci-cd',
    templateUrl: './admin-ci-cd.component.html',
    styleUrls: ['./admin-ci-cd.component.scss']
  })
  export class AdminCiCdComponent implements OnInit {

  cicdArray = [];
  cicdToolObj: object[];

  projectId
 
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;

  dtOptions: DataTables.Settings = {};
	dtTrigger =  new Subject();


  constructor(public apiService: AuthService, private router: Router,
    public dialog: MatDialog) { }


  ELEMENT_DATA: cicdDetails[];
  displayedColumns: string[] = ['toolName', 'ID', 'projectURL', 'email', 'toolGroup', 'select'];
  //dataSource = new MatTableDataSource<cicdDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<cicdDetails>(true, []);

  ngOnInit(): void {
    this.apiService.projectKey.subscribe(projectId => {
      // this.userEmail = currentUserEmail;
      this.projectId = projectId;
    })
    this.dtOptions = {
      order: []
    };
    this.GetCICDTableDetails();
  }

  openSnackBarDiaLog() {
    const toolSelectDialogRef = this.dialog.open(CastDialogComponent, {
      width: '32%',
      height: '23%',
      disableClose: true,

    });
  }

  GetCICDTableDetails() {
   // 
   

    this.apiService.Admin_CICDTableDetails().subscribe((res: any) => {
     // 
     // console.log(res)
      res.forEach(member => {
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id":member._id,
          "projectToken": member.tool_auth.auth_token,
          "projectURL": member.tool_url,
          "email": member.tool_auth.auth_mail,
          "name": member.tool_instance_name,
          "description": member.tool_description,
          "toolGroup": member.tool_groups[0].tool_group_name,
          "toolGroup_id": member.tool_groups[0]._id
          //  [11].tool_groups[0].tools[0]

        }
        this.cicdArray.push(obj)

      })
     // console.log(this.cicdArray)
     // this.dataSource.data = this.cicdArray as cicdDetails[]
      this.dtTrigger.next();
    }
    )
    // SCMArray
  }


  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    //const numRows = this.dataSource.data.length;
  //  return numSelected === numRows;
  return numSelected 
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
  //  this.isAllSelected() ?
    //  this.selection.clear() :
    //  this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.toolName + 1}`;
  }

  setFormMode() {
    localStorage.setItem("formMode", "Save");
  }

  getRecord(row) {
    //console.log(row);
    this.cicdToolObj = row;
    //this.apiService.SCMToolDetails.next(this.scmObj)
    try {
      localStorage.setItem('cicdToolObj', JSON.stringify(this.cicdToolObj));
      localStorage.setItem("formMode", "Edit")
      this.router.navigate(['/admin-jenkins']);
    }
    catch {
      return throwError('Data not found');
    }
  }


  openDialog() {   
    localStorage.setItem("formMode", "Add");
    const dialogRef = this.dialog.open(CICDDialog, {
      width: '40%',
      height: '40%',
    });  
  }

}

@Component({
  selector: 'ci-cd-dialog',
  templateUrl: 'ci-cd-dialog.html',
  styleUrls: ['./admin-ci-cd.component.scss']
})

export class CICDDialog { }


