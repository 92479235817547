<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css"
/>
<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate-multiple"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
<div class="inner-page-container">
  <div class="inner-top-container z100">
    <div class="row">
      <div class="col-md-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue">
              <a class="text-white" routerLink="/projectSummary">Home</a>
            </li>
            <li class="breadcrumb-item link-blue">
              <a class="text-white" routerLink="/workBench">Workbench</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Workspace
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
        <span class="table-options pull-right">
          <span (click)="AddAwsVdi()"><i class="fas fa-plus"></i> Add</span>
          <span *ngIf="refreshDisable" (click)="getAWSWorkspace()"
            ><i class="fas fa-sync-alt"></i> Refresh</span
          >
          <span *ngIf="!refreshDisable" class="disabled-img"
            ><i class="fas fa-sync-alt"></i> Refresh</span
          >
          <!-- <span
            class="disabled-img"><i class="fas fa-cog"></i> Manage
          </span> -->
          <span
            class="dropdown"
            [ngClass]="manageDisable ? 'disabled-img' : ''"
          >
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-cog"></i>
              Manage
            </button>
            <!-- <a href="#" class="nav-link k-button k-button-icontext" data-toggle="dropdown">
                <i class="fas fa-download "></i> Export
              </a>
              <a href="#" class="nav-link" data-toggle="dropdown">
                  <i class="fas fa-ellipsis-h"></i>
                </a> -->
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item">
                <!-- <span  class="table-crud table-edit-icon"  disabled> -->
                <!-- <img width="20" src="../../../assets/img/Start-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Start VDI</span> -->
                <span class="" (click)="startAws('a', 'b')">
                  <img width="20" src="../../../assets/img/Start-blue.png" />
                  <span class="vertical-line-more mx-2"></span>
                  <label style="color: black; font-weight: 500">Start</label>
                </span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">
                <!-- <span  class="table-crud table-save-icon"  disabled > -->
                <!-- <img width="20" src="../../../assets/img/Stop-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Stop VDI </span> -->
                <span class="" (click)="stopAws('a', 'b')">
                  <img width="20" src="../../../assets/img/Stop-blue.png" />
                  <span class="vertical-line-more mx-2"> </span>
                  <label style="color: black; font-weight: 500">Stop</label>
                </span>
              </a>
              <!-- <a class="dropdown-item" href="#">Action</a> -->
              <!-- <a class="dropdown-item" href="#">Another action</a> -->
              <!-- <a class="dropdown-item" href="#">Something else here</a> -->
            </div>
          </span>
        </span>
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward"></i> Back
        </span>
      </div>
    </div>
  </div>

  <div class="inner-mid-container mt-2 z0">
    <div class="row">
      <div class="col-sm-3">
        <h1 class="mb-0">
          <img width="40" src="assets/img/AWS-Title.jpg" />
          <div class="vertical-line mx-3"></div>
          <span>Workspace</span>
        </h1>
      </div>
      <div class="col-md-3">
        <span class="field-name">Project ID </span
        ><span class="field-value"> : {{ projectId }}</span>
        <span
          class="material-icons show-project-info"
          title="System Information"
          (click)="showProjectInfo()"
          >info</span
        >
      </div>
      <div class="col-md-6 mb-2">
        <div class="text-right">
          <div class="row">
            <div class="col-md-10 col-12">
              <mat-form-field class="mr-1">
                <mat-label>Status</mat-label>
                <mat-select
                  [(ngModel)]="filterStatus"
                  (selectionChange)="getStatus($event.value)"
                  name="fieldName"
                  ngDefaultControl
                >
                  <mat-option value="All">All</mat-option>
                  <mat-option value="Active">Active</mat-option>
                  <mat-option value="Failed">Failed</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col-md-2 col-12">
              <div class="dropdown" *ngIf="getTableData.length > 0">
                <a
                  href="#"
                  class="nav-link k-button k-button-icontext"
                  data-toggle="dropdown"
                >
                  <i class="fas fa-download"></i> Export
                </a>
                <div class="dropdown-menu more-drop-down">
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('pdf')">
                      <i width="20" class="fas fa-file-pdf color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> PDF</span
                    >
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('excel')">
                      <i width="20" class="fas fa-file-excel color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> EXCEL</span
                    >
                  </a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    <span class="color-black" (click)="downloadData('csv')">
                      <i width="20" class="fas fa-file-csv color-blue"></i>
                      <span class="vertical-line-more mx-2"></span> CSV</span
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4" *ngIf="showRepo">
      <form [formGroup]="secondFormGroup" class="pl-0">
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label for="bundle_id">Image</label>
              <select
                formControlName="bundle_id"
                id="bundle_id"
                name="bundle_id"
                class="form-control form-control-height"
                [class.is-invalid]="bundle_id.invalid && bundle_id.touched"
                (change)="onChangeImageName($event)"
              >
                <option [ngValue]="null" disabled selected hidden>
                  Image Name
                </option>
                <option
                  [value]="role.tool_id"
                  *ngFor="let role of ImageNameData"
                >
                  {{
                    role.tool_instance_details.aws_workspace_image_tool_instance
                      .name
                  }}
                </option>
              </select>

              <small
                class="text-danger"
                [class.d-none]="bundle_id.valid || bundle_id.untouched"
                >Image Name is required</small
              >
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style="top: 8px">
            <span
              style="font-size: 18px"
              class="material-icons add-user"
              title="System Information"
              (click)="showPopupInfo('Image')"
              >info</span
            >
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="form-group">
              <label for="directory_id">Network Config</label>
              <select
                formControlName="directory_id"
                id="directory_id"
                name="directory_id"
                class="form-control form-control-height"
                [class.is-invalid]="
                  directory_id.invalid && directory_id.touched
                "
                (change)="onChangeNetworkConfig($event)"
              >
                <option [ngValue]="null" disabled selected hidden>
                  Network Config
                </option>
                <option [value]="role" *ngFor="let role of Network">
                  {{ role.tool_id }}
                </option>
              </select>
              <small
                class="text-danger"
                [class.d-none]="directory_id.valid || directory_id.untouched"
                >Network Config is required</small
              >
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style="top: 8px">
            <span
              style="font-size: 18px"
              class="material-icons add-user"
              title="System Information"
              (click)="showPopupInfo('Network')"
              >info</span
            >
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label for="compute_type_name">Compute Type Name</label>
              <select
                formControlName="compute_type_name"
                id="compute_type_name"
                name="compute_type_name"
                class="form-control form-control-height"
                [class.is-invalid]="
                  compute_type_name.invalid && compute_type_name.touched
                "
                (change)="onChangeComputeTypeName($event)"
              >
                <option [ngValue]="null" disabled selected hidden>
                  Compute Type Name
                </option>
                <option
                  [value]="role.root_volume_size"
                  [title]="role.user_volume_size"
                  *ngFor="let role of computeData"
                >
                  {{ role.compute_type_name }}
                </option>
              </select>
              <small
                class="text-danger"
                [class.d-none]="
                  compute_type_name.valid || compute_type_name.untouched
                "
                >Compute Type Name is required</small
              >
            </div>
          </div>
          <div class="position-relative mt-4 img-width" style="top: 8px">
            <span
              style="font-size: 18px"
              class="material-icons add-user"
              title="System Information"
              (click)="showPopupInfo('Compute')"
              >info</span
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="form-group">
              <label for="running_mode">Running Mode</label>
              <select
                formControlName="running_mode"
                id="running_mode"
                name="running_mode"
                class="form-control form-control-height"
                [class.is-invalid]="
                  running_mode.invalid && running_mode.touched
                "
                (change)="onChangeRunningMode($event)"
              >
                <option [ngValue]="null" disabled selected hidden>
                  Running Mode
                </option>
                <option
                  [value]="role.running_mode_name"
                  *ngFor="let role of runningData"
                >
                  {{ role.running_mode_name }}
                </option>
              </select>
              <small
                class="text-danger"
                [class.d-none]="running_mode.valid || running_mode.untouched"
                >Running Mode is required</small
              >
            </div>
          </div>
          <div class="position-relative mt-4 img-width ml-1" style="top: 8px">
            <span
              style="font-size: 18px"
              class="material-icons add-user"
              title="System Information"
              (click)="showPopupInfo('Running')"
              >info</span
            >
          </div>
          <div class="col-md-4"></div>

          <div class="col-md-2 col-lg-1 mt-4">
            <span
              class="material-icons add-user-icon-one"
              (click)="openUser()"
              title="Add User"
            >
              person_add
            </span>
          </div>
          <div class="col-md-2 col-lg-1 mt-4">
            <button
              class="hover users-button width-100"
              mat-button
              (click)="confirmationPopup()"
              mat-raised-button
              [disabled]="!secondFormGroup.valid || !selectedUserArray.length"
              title="Create VDI"
            >
              Create
            </button>
          </div>

          <div class="col-md-2 col-lg-1 mt-4">
            <button
              type="button"
              class="btn-canvas btn-canvas-blue-2 width-100"
              (click)="toggleDisplay(secondFormGroup)"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="row userRow" *ngIf="showUserRow">
      <div
        class="col-md-1"
        *ngFor="let user of selectedUserArray; let i = index"
      >
        <span class="close" (click)="removeUser(user, i)" title="Remove User"
          >x</span
        >
        <img src="../../../assets/img/UserIcon.png" /><br />
        {{ user.user_name }}
      </div>
    </div>

    <table
      datatable
      class="table fixed-table"
      [dtOptions]="dtOptions"
      *ngIf="getTableData.length > 0"
    >
      <thead>
        <tr>
          <th style="width: 15px">
            <input
              type="checkbox"
              [(ngModel)]="masterSelected"
              name="list_name"
              value="m1"
              (change)="checkUncheckAll()"
            />
          </th>

          <th>Request ID</th>
          <th>Compute Type</th>
          <th>Running Mode</th>
          <th>Workspace Name</th>
          <th>Workspace Id</th>
          <th>IP Address</th>
          <th>Status</th>
          <th>Members</th>
          <!-- <th>Action</th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let element of getTableData">
          <td
            class="pl-3"
            style="width: 15px"
            *ngIf="
              element.workspace.approval == 'Running' ||
              element.workspace.approval == 'Stopped'
            "
          >
            <input
              type="checkbox"
              [(ngModel)]="element.isSelected"
              name="list_name"
              value="{{ element.reqId }}"
              (change)="isAllSelected()"
            />
          </td>
          <td
            class="pl-3"
            style="width: 15px"
            *ngIf="
              element.workspace.approval != 'Running' &&
              element.workspace.approval != 'Stopped'
            "
          >
            <input
              type="checkbox"
              [disabled]="checkDisable"
              [(ngModel)]="element.isSelected"
              name="list_name"
              value="{{ element.reqId }}"
              (change)="isAllSelected()"
            />
          </td>
          <td style="width: 10%">
            {{ element.request_id }}
            <span
              style="font-size: 18px"
              class="material-icons add-user"
              title="System Information"
              (click)="showImageInfo(element)"
              >info</span
            >
          </td>
          <td>
            {{
              element.workspace.compute_type_name
                ? element.workspace.compute_type_name
                : "-NA-"
            }}
          </td>
          <td>
            {{
              element.workspace.running_mode
                ? element.workspace.running_mode
                : "-NA-"
            }}
          </td>
          <td style="word-break: break-all">
            {{
              element.workspace.name_prefix
                ? element.workspace.name_prefix
                : "-NA-"
            }}
          </td>

          <td>
            {{
              element.workspace.workspace_id
                ? element.workspace.workspace_id
                : "-NA-"
            }}
          </td>

          <td>
            {{
              element.workspace.ip_address
                ? element.workspace.ip_address
                : "-NA-"
            }}
          </td>
          <!-- <td> {{element.running_mode}} </td> -->
          <td>{{ element.workspace.approval }}</td>

          <!-- <td> {{element.running_mode}} </td> -->

          <!-- <td> {{element.workspace.approval}}</td> -->
          <td class="w15">
            <h4
              *ngIf="
                element.workspace.approval == 'Removed' ||
                element.workspace.approval == 'Pending'
              "
            >
              --NA--
            </h4>
            <h4
              *ngIf="
                element.workspace.members.length == 0 &&
                (element.workspace.approval == 'Stopped' ||
                  element.workspace.approval == 'Running')
              "
            >
              --NA--
            </h4>
            <!-- <button *ngIf="element.ec2.members.length >1 " class="view-member-btn-inactive">View</button>  -->

            <button
              *ngIf="
                element.workspace.members.length > 1 &&
                element.workspace.approval != 'Pending' &&
                  element.workspace.approval != 'Removed'
              "
              class="view-member-btn"
              (click)="openUserDialog(element.workspace.members)"
            >
              View
            </button>
            <div
              *ngIf="
                element.workspace.members.length == 1 &&
                element.workspace.approval != 'Pending' &&
                  element.workspace.approval != 'Removed'
              "
            >
              <span
                class="img__wrap1 userRowOne"
                *ngFor="let user of element.workspace.members"
              >
                <h4>
                  {{ user.name }}

                  <!-- <span class="close_icon cursor" *ngIf="element.status=='Completed' && user.status == 'Approved'" (click)="deleteUser(element.reqId,user.email)" title="Remove User">x</span> -->
                </h4>
                <!-- <h4 class="table-inactive" *ngIf="user.status!='offboarded' && (element.status=='Moved_to_Recycle')">{{user.name}}</h4>  -->
              </span>
            </div>
          </td>

          <!-- <td>
            <div class="dropdown">
              <a href="#" class="nav-link" data-toggle="dropdown">
                <i class="fas fa-ellipsis-h"></i>
              </a>
              <div class="dropdown-menu more-drop-down">
                <a class="dropdown-item">
                  <span
                    class="table-crud table-edit-icon"
                    *ngIf="element.workspace.approval != 'Stopped'"
                    disabled
                  >
                    <img
                      width="20"
                      src="../../../assets/img/Start-Deactivate.png"
                    />
                    <span class="vertical-line-more mx-2"></span> Start
                  </span>
                  <span
                    class="table-crud table-edit-icon"
                    *ngIf="element.workspace.approval == 'Stopped'"
                    (click)="
                      startAws(
                        element.aws_config_tool_id,
                        element.workspace.workspace_id
                      )
                    "
                  >
                    <img width="20" src="../../../assets/img/Start-blue.png" />
                    <span class="vertical-line-more mx-2"></span> Start
                  </span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span
                    class="table-crud table-save-icon"
                    *ngIf="element.workspace.approval != 'Running'"
                    disabled
                  >
                    <img
                      width="20"
                      src="../../../assets/img/Stop-Deactivate.png"
                    />
                    <span class="vertical-line-more mx-2"></span> Stop
                  </span>
                  <span
                    class="table-crud table-save-icon"
                    *ngIf="element.workspace.approval == 'Running'"
                    (click)="
                      stopAws(
                        element.aws_config_tool_id,
                        element.workspace.workspace_id
                      )
                    "
                  >
                    <img width="20" src="../../../assets/img/Stop-blue.png" />
                    <span class="vertical-line-more mx-2"></span> Stop
                  </span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item">
                  <span  class="table-crud table-delete-icon" disabled >
                    <img width="20" src="../../../assets/img/Delete-Deactivate.png"> <span class="vertical-line-more mx-2"></span> Delete Workspace</span>
                  </a>
              </div>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>
    <table
      class="table fixed-table"
      datatable
      [dtOptions]="dtOptions"
      *ngIf="getTableData.length == 0"
    >
      <thead>
        <tr>
          <th>Request ID</th>
          <th>Compute Type</th>
          <th>Running Mode</th>
          <th>Workspace Name</th>
          <th>Workspace Id</th>
          <th>IP Address</th>
          <th>Status</th>
          <th>Members</th>
          <!-- <th>Action</th> -->
        </tr>
      </thead>
      <tbody></tbody>
    </table>
  </div>
</div>
