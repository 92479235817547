<div class="pop-up">
    <div class="">
        <span  class="vertical-line mx-1"> </span>
         <span class="header mb-0">{{Type}} Member</span> 
        <span (click)="closeDialog()" class="material-icons close-icon">
            close
          </span>
    </div>
    <hr class="margin-class" />
    <div class="popup-container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 img__wrap userRow"  *ngFor="let user of memberList;let i = index">
                <div *ngIf="Type=='Delete'">
                    <span class="close" (click)="deleteUser(user,i)" *ngIf="selectedRole!='Project Manager'" title="Delete User">x</span>
                </div>
                <!--  -->
                <img src="../../../assets/img/UserIcon.png"><br>
                {{user.user_name}}
                <span class="img__description_layer img__details card">
                  <img src="../../../assets/img/UserIcon.png">
                  <h3 class="font-14">{{user.user_name}}</h3>
                  <h5>{{user.user_email}}</h5>
                  <h4>{{user.role_name}}</h4>
                </span>
            </div>
        </div>
       
           
    </div>
</div>