import { Component, OnInit, Inject,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router, NavigationEnd } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { NgModule } from '@angular/core';


import { NgxSpinnerService } from 'ngx-spinner';
import { NewEvent } from 'src/app/project-configurations/mschannel-creations/create-meeting';
import { VdiDetailsComponent, DialogProjectInfoDialog } from 'src/app/project-configurations/vdi-details/vdi-details.component';
import { AlertService } from 'src/app/_alert';
import { Subject, throwError } from 'rxjs';	

import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import { timingSafeEqual } from 'crypto';
import { any } from 'prop-types';

export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}
export interface TaskVdi {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasksVdi?: TaskVdi[];
}
export interface ResourceTable {
  name: string;
  location : string;
  type : string; 
  action: string;
  tags:string;
  id:string;
  vdiType:string;
  image:string;
  endpointsecurity:string;
}
@Component({
  selector: 'app-canvas-gig',
  templateUrl: './canvas-gig.component.html',
  styleUrls: ['./canvas-gig.component.scss']
})

export class CanvasGigComponent implements OnInit {

  id: any;
  authenticate:boolean=true;
  storyBoard: any = {
  };
SCMArray=[];
  NameDisable=false;
  favoriteSeason: number=-1;
  tokenVal: string;
submission:boolean=false
  selectedStatus:  number ;  
  EmailDisable=false;
  chkHideStoryBoard=false;
chkHideSCM=false;
chkJenkins=false;
submitFlag:boolean=false;
chkMsteams=false;
chkWebex=false;
VdiImage=[];
VmImage=[];
chkHidePooled=false;
chkHidePersonal=false;
chkHideDeployment=false;
chkHideMonitoring=false;
chkHideReports=false;
chkHideWin16=false;
chkHideWin19=false;
chkHideCentos7=false;
myDate: any;
customDate: any;
model = new NewEvent();
minimumEndDate: string;
masterSelected: boolean=false;
masterSelectedVDI: boolean;

StoryBoardArrayVDI = [
  { name: 'Canvas-Pooled-Golden-VDI-Image', },
  { name: 'Canvas-FullStackPersonalGolden-Windows10-VDI-Image',}


];
StoryBoardArrayVM = [
  { name: 'Canvas-MongoDB50-CentOS7-VM-Image', },
  { name: 'Canvas-MySql8Tomcat9-CentOS7-VM-Image',},
  { name: 'Canvas-Apache24MySql8-CentOS7-VM-Image', },
  { name: 'Canvas-CISL1-CentOS7-VM-Image',},

  // { name: 'Canvas-Apache24-Windows2016-VM-Image', },
  // { name: 'Canvas-Tomcat9MSSqlExp2019-Windows2016-VM-Image',},
  // { name: 'Canvas-IIS10SQLExp2019-Windows2016-VM-Image', },
  // { name: 'Canvas-Tomcat9-Windows2016-VM-Image',},

  // { name: 'Canvas-MSSQLExpr2019-Windows2016-VM-Image', },
  // { name: 'Canvas-MySql8Tomcat9-CentOS7-VM-Image',},
  // { name: 'Canvas-Apache24MySql8-CentOS7-VM-Image', },
  // { name: 'Canvas-CISL1-CentOS7-VM-Image',},


];

  vdiMsg: string;
  vmMsg: string;
  msg: any;

  dtOptions: DataTables.Settings = {
    // "paging":   false,
    // "ordering": false,
    // "info":     false
  };
  dtOptions2: DataTables.Settings = {
    // "paging":   false,
    // "ordering": false,
    // "info":     false
  };
	dtTrigger =  new Subject();
  vargig: string;
  Role: string;
  checklist: any=[];
  checklistVDI:any=[];
  GigDetailsData: any;
  private authService: MsalService
  
  constructor(private spinner: NgxSpinnerService,private apiService: AuthService,private activatedRoute: ActivatedRoute,private router: Router,public datepipe: DatePipe,public dialog: MatDialog,public Mdialog: MatDialog
   , public alertService: AlertService ) {

      this.masterSelected = false;
      this.masterSelectedVDI = false;

    this.activatedRoute.queryParams.subscribe(params =>{
      // console.log(params,"admin-gig");
      this.id=params.id
      
      // console.log(params.id);
      // this.id=params.id,
      // this.color=params.color

      
    })
   }

  ngOnInit() {
    // console.log(localStorage);
    // console.log(this.model.end);

    
    
    // this.checklistVDI=this.StoryBoardArrayVDI
    // this.GetScmtableDetails()
    // debugger
    this.Role = localStorage.getItem("Role");
    const onboard=localStorage.getItem("onboardID");

    if(this.Role!="undefined" && onboard=="undefined" ){
      this.router.navigate(["/admin-catalog-config"])
    }
    else {
      // this.router.navigate(["/admin-gig"])
       // const onboard=localStorage.getItem("onboardID");
    this.vargig=onboard
    // console.log(this.vargig,"onBoard id");
    
    this.getGigDetails(this.vargig)
    // console.log(this.vargig,"finalid");
    // console.log(localStorage,"before clear");
    
    // localStorage.setItem("onboardID",'undefined');
    // localStorage.removeItem('onboardID');
        // localStorage.clear();

    // console.log(localStorage,"after clear");

    
    // console.log("dd");
    // console.log();
    
    
    this.dtOptions = {
      "paging":   false,
      "ordering": false,
      "info":     false,
      "searching":false,

      order: []
    };
    this.dtOptions2 = {
      "paging":   false,
      "ordering": false,
      "info":     false,
      "searching":false,

      order: []
    };
    this.myDate = new Date();
    this.myDate = this.datepipe.transform(this.myDate, 'yyyy-MM-ddThh:mm');
    this.customDate = this.datepipe.transform(this.myDate, 'yyyy-MM-dd');
    this.selectedStatus=1

    }

    
   
  }
  back(){
    this.router.navigate(["/admin-catalog-config"])

  }
onSubmit(){

}
getGigDetails(id){
  this.apiService.getGigetailsData(id).subscribe((data:any) => {
    // this.authenticate=false,
    // console.log("Gig details data", data);
    // debugger
     this.GigDetailsData = data;
    //  console.log(this.GigDetailsData);
     
     
     this.storyBoard.projectName=this.GigDetailsData.assignment_id,
     this.storyBoard.projectOwner=this.GigDetailsData.project_owner_details.user_name,
     this.storyBoard.Email=this.GigDetailsData.project_owner_details.user_mail,
     this.storyBoard.domainName=this.GigDetailsData.domain_name,
     this.model.start=this.GigDetailsData.request_date.split("T")[0] ;
      // const myArray = text.split("T");


    //  this.model.start=this.GigDetailsData.request_date
    this.NameDisable=true
    //  this.dtTrigger.next();
   this.spinner.hide();
   }, (error: HttpErrorResponse) => {
     if (error.status == 432){
      //  console.log(error);
       
          if(error.error.code=="02"){
                  this.alertService.error(error.error.message);
                  this.submitFlag=true
                  setTimeout(() => {
      
                    this.logout()
              
                    }, 3000);

          }
          else{
            this.authenticate=false;
      
            setTimeout(() => {
      
            this.logout()
      
            }, 2000);

          }


      // this.alertService.error(error.error.message);
      // this.logout()

      }
      else if (error.status == 403){}
     else {
    //  this.alertService.error("No data available.", this.options);
     throw error;
      }
     
      });
}
logout() {
 

  //code start - after the logout to clear all local storage

  this.tokenVal = localStorage.getItem("token")



  let Body = {

    "token": this.tokenVal

  }

  // console.log(Body);



  this.apiService.jwtTokenLogout(Body).subscribe((res: HttpResponse<any>) => {

    // debugger;
    // console.log("fhfh");
    
    localStorage.clear();

    sessionStorage.clear();

    this.authService.logout();

    this.apiService.projectKey.next("");

    localStorage.removeItem('profile')

    // console.log(res.body.message);



  }, (error: HttpErrorResponse) => {

    // debugger;

  })


}
public seleccionRubrosHideStoryBoard(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHideStoryBoard=false;
  }
  else{
    this.chkHideStoryBoard=true;
  }
}
public seleccionRubrosHideGitlab(event: MatCheckboxChange) {

  if (!event.source.checked) {
    this.chkHideSCM=false;
  }
  else{
    this.chkHideSCM=true;
  }
}
public seleccionRubrosHideJenkins(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkJenkins=false;
  }
  else{
    this.chkJenkins=true;
  }
}
public seleccionRubrosHideMsteams(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkMsteams=false;
  }
  else{
    this.chkMsteams=true;
  }
}
public seleccionRubrosHideWebex(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkWebex=false;
  }
  else{
    this.chkWebex=true;
  }
}
public seleccionRubrosHideWin16(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHideWin16=false;
    this.getVmImages()

  }
  else{
    this.chkHideWin16=true;
    this.getVmImages()

  }
}
public seleccionRubrosHidePersonal(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHidePersonal=false;
    this.getVdiImages()
    // console.log("hello");
    
  }
  else{
    this.chkHidePersonal=true;
    this.getVdiImages()

    // console.log("hiii");
    
  }
}
public seleccionRubrosHidePooled(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHidePooled=false;
    this.getVdiImages()

  }
  else{
    this.chkHidePooled=true;
    this.getVdiImages()

  }
}
public seleccionRubrosHideWin19(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHideWin19=false;
    this.getVmImages()

  }
  else{
    this.chkHideWin19=true;
    this.getVmImages()

  }
}
public seleccionRubrosHideCentos7(event: MatCheckboxChange) {
  if (!event.source.checked) {
    this.chkHideCentos7=false;
    this.getVmImages()
  }
  else{
    this.chkHideCentos7=true;
    this.getVmImages()

  }
}

task: Task = {
  name: 'VM',
  completed: false,
  color: 'accent',
  subtasks: [
    {name: 'Azure VM', completed: false, color: 'accent'},
    {name: 'AWS EC2', completed: false, color: 'accent'},
    // {name: 'Warn', completed: false, color: 'accent'},
  ],
};
taskVdi: TaskVdi = {
  name: 'VDI',
  completed: false,
  color: 'accent',
  subtasksVdi: [
    {name: 'Azure VDI', completed: false, color: 'accent'},
    {name: 'AWS Workplace', completed: false, color: 'accent'},
    // {name: 'Warn', completed: false, color: 'accent'},
  ],
};
allComplete: boolean = false;
allCompleteVdi: boolean = false;

  // Deploytment store nested functions
  updateAllComplete() {
    //  console.log("update");
     
    //  debugger
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    if(this.task.subtasks[0].completed==false){
      this.chkHideWin16=false;
      this.chkHideWin19=false;
      this.chkHideCentos7=false;

    }  
  }
 
  someComplete(): boolean {
    // console.log("some");
    
    // debugger
    if (this.task.subtasks == null) {
      return false;
    }
    else{
      if(this.task.subtasks[0].completed==false){
        this.chkHideWin16=false;
        this.chkHideWin19=false;
        this.chkHideCentos7=false;
  
      }  
      return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
     
      
    }
  
    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
    if(this.task.subtasks[0].completed==false){
      this.chkHideWin16=false;
      this.chkHideWin19=false;
      this.chkHideCentos7=false;

    }   
  }
  getVdiImages(){
    this.masterSelectedVDI = false
    var resourceType=""
    if(this.chkHidePersonal && this.chkHidePooled){
      resourceType="Pooled,Personal"
    }
    else if(this.chkHidePersonal && !this.chkHidePooled){
      resourceType="Personal"
    }
    else if(!this.chkHidePersonal && this.chkHidePooled){
      resourceType="Pooled"
    }

    this.apiService.GigVdiVmImages('VDI',resourceType).subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
          this.checklistVDI=res

 
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      // this.dtTrigger.next();
      // this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        // this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        // this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })



  }
  getVmImages(){
    this.masterSelectedVDI = false
    // Windows 2016,Windows 2019,CentOS7
    var resourceType=""
    if(this.chkHideWin16 && this.chkHideWin19 && this.chkHideCentos7){
      resourceType="Windows 2016,Windows 2019,CentOS7"
    }
    else if(this.chkHideWin16 && !this.chkHideWin19 && !this.chkHideCentos7){
      resourceType="Windows 2016"
    }
    else if(!this.chkHideWin16 && this.chkHideWin19 && !this.chkHideCentos7){
      resourceType="Windows 2019"
    }
    else if(!this.chkHideWin16 && !this.chkHideWin19 && this.chkHideCentos7){
      resourceType="CentOS7"
    }
    else if(this.chkHideWin16 && this.chkHideWin19 && !this.chkHideCentos7){
      resourceType="Windows 2016,Windows 2019"
    }
    else if(this.chkHideWin16 && !this.chkHideWin19 && this.chkHideCentos7){
      resourceType="Windows 2016,CentOS7"
    }
    else if(!this.chkHideWin16 && this.chkHideWin19 && this.chkHideCentos7){
      resourceType="Windows 2019,CentOS7"
    }

    this.apiService.GigVdiVmImages('VM',resourceType).subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
          this.checklist=res

 
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      // this.dtTrigger.next();
      // this.spinner.hide();
    }, (error: HttpErrorResponse) => {
      //

      if (error.status == 436) //doesnt work
      {
           
        // this.alertService.error(error.error.message, this.options);

      }
      else if (error.status == 403){}
      else {
       
        // this.alertService.error("Invalid Input", this.options);
        throw error;
      }
    })



  }
  // Vdi nested functions

  updateAllCompleteVdi() {
    //  console.log("update");
     
    //  debugger
    this.allCompleteVdi = this.taskVdi.subtasksVdi != null && this.taskVdi.subtasksVdi.every(t => t.completed);
    if(this.taskVdi.subtasksVdi[0].completed==false){
      this.chkHidePooled=false;
      this.chkHidePersonal=false;
    }
    // console.log(this.taskVdi.subtasksVdi,"updateall");


  }
 
  someCompleteVdi(): boolean {
    // console.log("some");
    
    // debugger
    if (this.taskVdi.subtasksVdi == null) {
      return false;
    }
    else{
      // console.log(this.taskVdi.subtasksVdi,"some");
      if(this.taskVdi.subtasksVdi[0].completed==false){
        this.chkHidePooled=false;
        this.chkHidePersonal=false;
      }
      // console.log(this.taskVdi.subtasksVdi[0].completed,"some");

  
      return this.taskVdi.subtasksVdi.filter(t => t.completed).length > 0 && !this.allCompleteVdi;


     
      
    }
  
    // return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAllVdi(completed: boolean) {
    // debugger
    this.allCompleteVdi = completed;
    if (this.taskVdi.subtasksVdi == null) {
      return;
    }
    this.taskVdi.subtasksVdi.forEach(t => (t.completed = completed));
    if(this.taskVdi.subtasksVdi[0].completed==false){
      this.chkHidePooled=false;
      this.chkHidePersonal=false;
    }   

  }
  check(){

  }
  GetScmtableDetails() {
    // this.spinner.show();
    this.apiService.Admin_ScmToolGrTableDetails().subscribe((res: any) => {
      // 
      // console.log(res,"OUTPut")
      res.forEach(member => {
        //  
        let obj = {
          "toolName": member.tool_name,
          "ID": member.tool_id,
          "db_id": member._id,
          "name": member.tool_instance_details.scm_tool_instance.tool_instance_name,
          // "checked":true
        }
        this.SCMArray.push(obj)
        this.checklist = this.SCMArray;


      })
      // this.dataSource.data = this.SCMArray as SCMDetails[]
      this.dtTrigger.next();
      // this.spinner.hide();
    }
    )
    // SCMArray
  }
  get(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    let t = dd + '-' + mm + '-' + yyyy;
    this.VdiImage=[]
    // console.log(this.checklistVDI);
    
    for(let i=0;i<this.checklistVDI.length;i++){
      if(this.checklistVDI[i].checked){
        let body={
          "name":this.checklistVDI[i].name,
          "type":this.checklistVDI[i].image_type
        }
        
        this.VdiImage.push(body)
      }
    }
    this.VmImage=[]
    for(let i=0;i<this.checklist.length;i++){

      if(this.checklist[i].checked){
        let body={
          "name":this.checklist[i].name,
          "type":this.checklist[i].image_type
        }
        this.VmImage.push(body)
      }
    }
    // console.log(localStorage);
    
   let body={
     "service_id":localStorage.onboardID,
     "project_owner_details":{
      "user_name":this.storyBoard.projectOwner,
      "user_mail":this.storyBoard.Email,
    // "user_id":"10685512"
  },
  "domain_name":"Insurance",
    // "assignment_id":this.storyBoard.projectName,
    "gitlab": {
        "access": this.chkHideSCM,
    },
    "jira": {
        "access": this.chkHideStoryBoard
    },
    "jenkins": {
        "access": this.chkJenkins
    },
    "ms_teams": {
        "access": this.chkMsteams
    },
    "webex": {
        "access": this.chkWebex
    },
    "assign_vdi": {
        "azure": {
            "access": this.taskVdi.subtasksVdi[0].completed,
            "azure_images": this.VdiImage,
        },
        "aws": {
            "access": this.taskVdi.subtasksVdi[1].completed,
            "aws_images": []
        }
    },
    "deployment_store": {
        "azure": {
            "access": this.task.subtasks[0].completed
            ,
            "azure_images": this.VmImage
        },
        "aws": {
            "access": this.task.subtasks[1].completed,
            "aws_images": []
        }
    },
    "softwares": this.storyBoard.otherSoftware,
    "start_date": this.model.start,
    "end_date": this.model.end,
    "tools_request_date": t,
    "requester_email": localStorage.getItem("mail")


}


    console.log(body);
    this.apiService.submitGig(body).subscribe((res: HttpResponse<any>) => {
         
        this.submission=true
        setTimeout(() => {

          this.logout()
    
          }, 4000);
      // if (res.status == 200) //doesnt work
      // {        
     

      //   this.router.navigateByUrl('/admin-story-board', { skipLocationChange: true }).then(() => {
      //     this.router.navigate(['/admin-projectTemplate']);
      // });
      this.alertService.success(res.body.message);

      // }
    }, (error: HttpErrorResponse) => {
      //

    })
    
    
  }
  checkUncheckAllVDI(){
    
    for(let i=0;i<this.checklistVDI.length;i++){
      this.checklistVDI[i].checked=this.masterSelectedVDI
    }
  }
  isAllSelectedVDI(){
    if(this.checklistVDI.length>0){
      var flag=this.checklistVDI[0].checked
    
    for(let i=0;i<this.checklistVDI.length;i++){
      flag=flag && this.checklistVDI[i].checked
    }
    this.masterSelectedVDI=flag
    }
  }
  reset(){
    this.chkHideSCM=false
    this.chkHideStoryBoard=false
    this.chkJenkins=false
    this.chkMsteams=false
    this.chkWebex=false
    this.taskVdi.subtasksVdi[0].completed=false
    this.taskVdi.subtasksVdi[1].completed=false
    this.task.subtasks[0].completed=false
    this.task.subtasks[1].completed=false
    this.model.end=""
    this.storyBoard.otherSoftware=""
    this.storyBoard.projectOwner=""
    this.storyBoard.Email=""
    this.storyBoard.domainName=""
  }
  checkUncheckAllVM(){
    // debugger
    // console.log(this.masterSelected);
    
    for(let i=0;i<this.checklist.length;i++){
      this.checklist[i].checked=this.masterSelected
    }

  }
  isAllSelectedVM(){
    
// console.log(this.checklist);
if(this.checklist.length>0){
  var flag=this.checklist[0].checked

for(let i=0;i<this.checklist.length;i++){
  flag=flag && this.checklist[i].checked
}
this.masterSelected=flag
}

  }
  getMinimumEndDate() {

    this.minimumEndDate = this.datepipe.transform(this.model.start, 'yyyy-MM-dd');
    this.submitFlag=false
  }
  showImageInfo(catalog) {
    //debugger;
    if(catalog.tags==""){

    }
    else{
        const dialogRef = this.Mdialog.open(AdminDialogImageGig, {
          width: '50%',
          height: '58%',
          disableClose: true,
          data: {
            image: catalog.tags,
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    }

  showProjectInfo(val) {
    this.vdiMsg="VDI or Virtual Desktop infrastructure uses virtual machines to provide and manage virtual desktops"
    this.vmMsg="VM or Virtual Machine where users can host applications by accessing the servers through any device or location"
    if(val=="VDI"){
      this.msg=this.vdiMsg
    }
    else{
      this.msg=this.vmMsg
    }
    const dialogRef = this.dialog.open(VdiInfo, {
      width: '40%',
      height: '25%',
      // overflow:any,
      data:{
        value:val,
        msg:this.msg
      },
      disableClose: true
      
    });
 
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}


//Delete confirmation pop up
// ELEMENT_DATA: ResourceTable[];


@Component({
  selector: 'info-dialog',
  templateUrl: 'info.html',
  styleUrls: ['./canvas-gig.component.scss']
})
export class VdiInfo {

  value: any;
  message: any;
  constructor(
    public dialogRef: MatDialogRef<VdiInfo
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
    this.value=data.value
    this.message=data.msg
  }
  
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
const ELEMENT_DATAA: ResourceTable[]=[];

@Component({
  selector: 'dialogGig-Image',
  templateUrl: 'image-details.html',
  styleUrls: ['./canvas-gig.component.scss']
})
export class AdminDialogImageGig {
  image: any = [];
//  ELEMENT_DATA: ResourceTable[];
displayedColumns: string[] = ['name', 'tags'];
Selecterror:boolean = false;
dataSource = new MatTableDataSource<ResourceTable>(ELEMENT_DATAA);
tableResourceArray : any = []; 
Vdisoftware_details : any = [];
@ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator; 
  constructor(
    public dialogRef: MatDialogRef<AdminDialogImageGig>,@Inject(MAT_DIALOG_DATA) public data: any,public apiService: AuthService,
  ) { 
    //debugger;
    console.log(data.image)
    this.image = data.image
   // console.log("image software",this.image)
    let TempTags:any=[];
    TempTags.push(this.image);

          for (let index = 0; index < TempTags.length; index++) 
            {
              TempTags.forEach(element => {
                const entries = Object.entries(element);
              //console.log(entries);
              for (let index = 0; index < entries.length; index++) 
              {
                var softwareName=entries[index][0];
                var softwareVersion=entries[index][1];
               let VdiTagsBoby={
                  "software_name":softwareName,
                  "software_version":softwareVersion
                  }
                 this.Vdisoftware_details.push(VdiTagsBoby);
              }
                });
           }

if(this.Vdisoftware_details.length>0){
  this.Selecterror=false;
  this.Vdisoftware_details.forEach(member => {
    //debugger;
    let tableObj = {
    "name":member.software_name,
     "tags" : member.software_version
    }
     this.tableResourceArray.push(tableObj)
   })
  //console.log(this.tableResourceArray,"tableResourceArray")
   this.dataSource.data = this.tableResourceArray as ResourceTable[]   

}
else{
  this.Selecterror=true;
}

  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}