import { Component, OnInit } from '@angular/core';
import {  ChartOptions, ChartType, Chart } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { AuthService } from 'src/app/service/api.service';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {

  overleyFlag:boolean = false;
  overleyPieFlag:boolean = false;

  costChartArray : any[];
  costLabelArray : any[];
  costValueArray : any[];

  toolChartArray : any[];
  toolLabelArray : any[];
  toolBandWidthArray : any[];
  toolCisPlanArray : any[];
  toolJiraArray : any[];
  toolStorageArray : any[];
  toolVmArray : any[];
  toolVmlArray : any[];
  toolVnArray : any[];
 // toolTotalArray : any[];

 toolFilter:any = {};

 showToolBarChart:boolean = true;
 ShowToolTableView:boolean = false;

  //Cost Bar chart code start's here
  // barChartOptions: ChartOptions = {
  //   indexAxis: 'y',
  //   responsive: true,
  //   scales: {
  //     xAxes: {
      
  //      ticks : {
  //         beginAtZero: true,
  //         callback: label => `${label}k`
  //       },
  //       grid: {
  //           color: "rgba(0, 0, 0, 0)",
  //       }
  //   },
  //     y: {
  //       grid: {
  //         color: "rgba(0, 0, 0, 0)",
  //     }
  //     }
  //   },
  //   plugins: {
  //     // datalabels: {
  //     //   anchor: 'end',
  //     //   align: 'end',
  //     // }
  //   }
  // };
  barChartLabels: Label[] = [
  //  'Canvas Demo Env', 'Canvas Dev Env', 'Canvas Engineering', 'Canvas Implementation',
  //'CITI', 'FSTI', 'HSBC', 'KIMS', 'Maersk Drilling'
];
 // barChartType: ChartType = 'horizontalBar';
 barChartLegend = true;
  barChatPlugins = [];
  barChartColors: Color[] = [
    { backgroundColor: '#09519B' },
   // { backgroundColor: '#6A227C' },
  ]
  // barChartData: ChartDataset[] = [
  //  // {label: 'Cost in $'}
  //   //{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Cost' },
  //  // { data: [2.61, 1.69, 1.87, 0.15, 0.54, 0.23, 0.10, 0.30, 0.07], label: 'Cost' }
  // ]
   //Cost Bar chart code end's here
 
   //Tool Bar chart code start's here
  toolBarChartOptions = {
    responsive: true,
    scales: {
      y: [{
        ticks: {
          beginAtZero: true,
          stepSize : 50,
          callback: label => `${label}$`
        },
        grid: {
          display:false
      }
      }]
    }
  };
  toolBarChartLabels: Label[] = [
];
  toolBarChartType: ChartType = 'bar';
  toolBarChartLegend = true;
 toolBarChatPlugins = [];
  toolBarChartColors: Color[] = [
    { backgroundColor: '#09519B' },
    { backgroundColor: '#F5C767' },
    { backgroundColor: '#7A62BA' },
    { backgroundColor: '#566970' },
    { backgroundColor: '#EF7721' },
    { backgroundColor: '#39A768' },
    { backgroundColor: '#347ED3' },
    { backgroundColor: '#FFD75E' },
  ]
  // toolBarChartData: ChartDataset[] = [
  // //  {label: 'Cost in $'},
  //   {data: []}
  // ]
   //Tool Bar chart code end's here

 // Pie chart data start's here
 public pieChartOptions: ChartOptions = {
  responsive: true,
  // legend: {
  //   position: 'top',
  // },
  plugins: {
    legend: {
        position: 'top',
      }
    // datalabels: {
    //   formatters: (value, ctx) => {
    //     const label = ctx.chart.data.labels[ctx.dataIndex];
    //     return label;
    //   },
    // },
  }
};
public pieChartLabels: Label[] = ['Canvas Demo Env', 'Canvas Dev Env', 'Canvas Engineering', 'CITI', 'FSTI', 'HSBC', 'KIMS'];
public pieChartData: number[] = [164.628, 134.333, 69.768, 47.463, 13.516, 7.894, 20.759];
public pieChartType: ChartType = 'pie';
public pieChartLegend = true;
public pieChartColors = [
  {
    backgroundColor: ['#09519B', '#0299DE', '#A1D5EB', '#5B8CB7' , '#07529D', '#B7D8F3' ,'#3F7BB1'],
  },
];

months:any = ['October-20', 'November-20'];
tools:any = ['ALL','CITI', 'KIMS'];
dtOptions: DataTables.Settings = {};

   constructor(private apiService: AuthService) { }

   ngOnInit(): void {
    this.dtOptions = {
      paging: false, searching: false
    };
     this.getDashBoardDetails();
     this.getToolsDataDetails();
   }

   getDashBoardDetails(){
     this.apiService.getDashboardData().subscribe(post => {
      //console.log("post data", post);
      this.costChartArray = post.octProjectCostData;
      this.getCostChartData();
      this.getCostChart();
    }, error => {

    })
   }

   getCostChartData(){
     this.costLabelArray = [];
     this.costValueArray = [];
     if(this.costChartArray){
        for(let i=0; i < this.costChartArray.length; i++){
          this.costLabelArray.push(this.costChartArray[i]['label']);
          this.costValueArray.push(this.costChartArray[i]['value']);
        }
     }
   }

   getCostChart(){
     this.barChartLabels =  this.costLabelArray;
    //  this.barChartData = [
    //    {data : this.costValueArray, label : 'Cost in $'}
    //  ];
   }

   onOptionSelected(value){
     if(value == "October-20"){
      this.apiService.getDashboardData().subscribe(post => {
        //console.log("post data", post);
        this.costChartArray = post.octProjectCostData;
        this.getCostChartData();
        this.getCostChart();
      }, error => {
 
      })
     }
     else if(value == "November-20"){
      this.apiService.getDashboardData().subscribe(post => {
       // console.log("post data", post);
        this.costChartArray = post.novProjectCostData;
        this.getCostChartData();
        this.getCostChart();
      }, error => {
 
      })
    }
   }

   getToolsDataDetails(){
    this.apiService.getDashboardData().subscribe(post => {
      //console.log("post data", post);
      this.toolChartArray = post.octToolData;
      this.getToolChartData();
      this.getToolChart();
    }, error => {

    })
  }

  getToolChartData(){
    this.toolLabelArray = [];
    this.toolBandWidthArray = [];
    this.toolCisPlanArray = [];
    this.toolJiraArray = [];
    this.toolStorageArray = [];
    this.toolVmArray = [];
    this.toolVmlArray = [];
    this.toolVnArray = [];
    //console.log("Original tool data" , this.toolChartArray);
    //this.toolTotalArray = [];
    if(this.toolChartArray){
       for(let i=0; i < this.toolChartArray.length; i++){
         this.toolLabelArray.push(this.toolChartArray[i]['label']);
         this.toolBandWidthArray.push(this.toolChartArray[i]['bandwidth']);
         this.toolCisPlanArray.push(this.toolChartArray[i]['cis']);
         this.toolJiraArray.push(this.toolChartArray[i]['jira']);
         this.toolStorageArray.push(this.toolChartArray[i]['storage']);
         this.toolVmArray.push(this.toolChartArray[i]['vm']);
         this.toolVmlArray.push(this.toolChartArray[i]['vml']);
         this.toolVnArray.push(this.toolChartArray[i]['vn']);
        // this.toolTotalArray.push(this.toolChartArray[i]['Total'])
       }
    }
  }

  getToolChart(){
    this.toolBarChartLabels =  this.toolLabelArray;
    // this.toolBarChartData = [
    //   {data : this.toolBandWidthArray, label : 'Bandwidth', stack: 'a'},
    //   {data : this.toolCisPlanArray, label : 'CIS Plan', stack: 'a'},
    //   {data : this.toolJiraArray, label : 'Jira Cloud', stack: 'a'},
    //   {data : this.toolStorageArray, label : 'Storage', stack: 'a'},
    //   {data : this.toolVmArray, label : 'Virtual Machines', stack: 'a'},
    //   {data : this.toolVmlArray, label : 'Virtual Machines Licenses', stack: 'a'},
    //   {data : this.toolVnArray, label : 'Virtual Network', stack: 'a'},
    //  // {data : this.toolTotalArray, label : 'Total', stack: 'a'}
    // ];
   // console.log("tools data", this.toolBarChartData);
  }

  onToolSelected(value){
    if(value == "October-20"){
     this.apiService.getDashboardData().subscribe(post => {
       //console.log("post data", post);
       this.toolChartArray = post.octToolData;
       this.getToolChartData();
       this.getToolChart();
     }, error => {

     })
    }
    else if(value == "November-20"){
      this.apiService.getDashboardData().subscribe(post => {
       // console.log("post data", post);
        this.toolChartArray = post.novToolData;
        this.getToolChartData();
        this.getToolChart();
      }, error => {
 
      })
    }
  }

  showBarChart(){
    this.showToolBarChart = true;
    this.ShowToolTableView = false;
  }
  showTableView(){
    this.showToolBarChart = false;
    this.ShowToolTableView = true;
  }
  onToolFilterSubmit(){
    // this.apiService.getToolsData().subscribe(res => {

    // }, error =>{

    // })
  }
  cancelOnToolFilterOverley(){
    this.overleyFlag = false;
    this.toolFilter.monthSelect="";
    this.toolFilter.toolSelect="";
   }
   cancelOverley(){
     
   }
   clickOverley(){
    this.overleyFlag = !this.overleyFlag;
   }
  
  
   clickPieOverley(){
    this.overleyPieFlag = !this.overleyPieFlag;
   }
   cancelPieOverley(){
    this.overleyPieFlag = false;
   }
  

}
