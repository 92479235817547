import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/api.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  mailId: any;

  
  constructor( private apiService: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
          this.apiService.getEmail().subscribe((res:any) => {
            // console.log(res);
            this.mailId=res.emailRecord.faqmail
            


          })

  }

  panelOpenState = false;

  

  onClick(path,ques) {

    localStorage.setItem("selectedTool", path);
    const User = this.dialog.open(VideoFAQ, {
      width: '60%',
      height: '80%',
      disableClose: true,

      data: {
       info: path,
       question:ques
      }
    });
  }

}
@Component({
  selector: 'temp-dialog',
  templateUrl: 'faq-video.html',
  styleUrls: ['./faq.component.scss']
})
export class VideoFAQ implements OnInit {
  ToolName: string;
  path: string="Jenkin";
  ques: string="";
  

  constructor(public dialogRef: MatDialogRef<VideoFAQ>, @Inject(MAT_DIALOG_DATA) public data: {info: string, question: string}){
    // console.log(data)
    this.path = data.info;
    this.ques = data.question;
  }
  
  ngOnInit(): void {
    // this.ToolName = localStorage.getItem("selectedTool");
    // console.log(this.ToolName);
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}