<link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css">
<div class="inner-page-container">

  <div class="inner-top-container">    
    <div class="row">
      <div class="col-md-6">        
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent px-0">
            <li class="breadcrumb-item link-blue"><a routerLink="/projectSummary">Home</a></li>
            <li class="breadcrumb-item link-blue"><a routerLink="/workBench">Workbench</a></li>
            <li class="breadcrumb-item active" aria-current="page">MS Teams</li>
          </ol>
        </nav>
      </div>
      <div class="col-md-5 p-md-0 my-auto">
      </div>
      <div class="col-md-1 p-md-0 my-auto table-options">
        <span routerLink="/workBench" class="float-right text-light">
          <!-- <i class="fas fa-times"></i> -->
          <i class="fa fa-step-backward "></i> Back</span>
      </div>
    </div>
</div>
<div class="inner-mid-container mt-2">
  <div class="row">
    <div class="col-md-3">
      <h1 class="page-name mb-0">
        <img width="40" src="assets/img/teams_logo.png">
        <div class="vertical-line mx-3"> </div> <span>MS Teams</span>      
      </h1>
    </div>
    <div class="col-md-3"><span class="field-name">Project ID </span>
      <span class="field-value"> : {{projectId}}</span>
        <span class="material-icons show-project-info" title="System Information" (click)="showProjectInfo()">info</span>
      </div>
    

</div>
    <div class="row optionDiv">
        <div class="offset-2 col-2 hover" routerLink="/teamsChannel"  title="Create/View Spaces">
          <img width="70" src="assets/img/createSpaces.png"><br>
          <span class="optionLabel">Teams Channel</span> 
        </div>
        <div class="offset-1 col-2 hover" routerLink="/teamsMeeting"   title="Setup Meetings">
          <img width="65" src="assets/img/setMeeting.png"><br>
          <span class="optionLabel">Teams Meetings</span> 
        </div>
        <div class="offset-1 col-2 hover" routerLink="/teamsartifact"   title="Meetings Artifacts">
          <img width="65" src="assets/img/artifactIcon.png"><br>
          <span class="optionLabel">Teams Artifacts</span> 
        </div>
      </div>

</div>
