<div class="pop-up">
        <div class="">
            <span  class="vertical-line mx-0 mx-md-3"> </span>
            
             <span class="header mb-0" >{{value}}</span> 
             <!-- <span class="header mb-0" *ngIf="value='VM'">VM</span>  -->

            <span (click)="onNoClick()" class="material-icons close-icon">
                close
              </span>
     </div>
     <hr class="margin-class" />
        <div class="popup-container">
           
            <div class=""  >
                <h3 class="mb-3 col-md-12 mt-2">{{message}}</h3>
            
            </div>
           
        </div>
    </div>