import { Router } from '@angular/router';
import { AuthService } from './../../service/api.service';
import { ProjectSummaryComponent } from './../../project-details/project-summary/project-summary.component';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxSpinnerService } from "ngx-spinner";
import { AlertService } from 'src/app/_alert';
import { Subject, throwError } from 'rxjs';


export interface CatlogDetails {
  Name: string;
  ID: string;
  Description: string;
  
}


export interface configList {
  Name: string;
  ID: string;
  Description: string;
  Cost: string;
}

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent implements OnInit {
  projectId
  catalogObj: object[];
  CatlogArray =[];
  public unique_key: number;
  public parentRef: ProjectSummaryComponent;

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
  dtTrigger =  new Subject();
  

  constructor(public apiService: AuthService, private router: Router,
     private spinner: NgxSpinnerService, public alertService: AlertService) { }

  ELEMENT_DATA: CatlogDetails[];
  displayedColumns: string[] = ['Name', 'ID', 'Description', 'select'];
  //dataSource = new MatTableDataSource<CatlogDetails>(this.ELEMENT_DATA);
  selection = new SelectionModel<CatlogDetails>(true, []);
  

  ngOnInit(): void {

    this.spinner.show();

    this.apiService.projectKey.subscribe(projectId => {
      // this.userEmail = currentUserEmail;
      this.projectId = projectId;
    })    

    this.GetCatalogtableDetails();

    this.spinner.hide();

    

    

  }


  GetCatalogtableDetails(){
    //
       this.apiService.Admin_CatlogTableDetails().subscribe((res:any)=>{
        // console.log(res)
        res.forEach(member => {
          let obj = {
            "Name": member.catalog_name,
            "ID": member._id,
            "Description":member.catalog_description,                       
          }
         this.CatlogArray.push(obj)
        })
      //  this.dataSource.data  =this.CatlogArray as CatlogDetails[]      
        this.dtTrigger.next();
      })
       // SCMArray
  }

  ngOnDestroy(): void {    
    this.dtTrigger.unsubscribe();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
   // const numRows = this.dataSource.data.length;
    //return numSelected === numRows;
    return numSelected
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    //this.isAllSelected() ?
      //this.selection.clear() :
      //this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: configList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.Name + 1}`;
  }



  setFormMode() {
    localStorage.setItem("formMode", "Save");
    
  }

  getRecord(row) {
   // console.log(row);
    this.catalogObj = row;
    //this.apiService.SCMToolDetails.next(this.scmObj)
    try {
      localStorage.setItem('catalogObj', JSON.stringify(this.catalogObj));
      localStorage.setItem("formMode", "Edit")
      this.router.navigate(['/admin-catalog-config']);
    }
    catch {
      return throwError('Data not found');
    }
  }

}
