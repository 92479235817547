<div class="dialogue">
  
    <div class="member-card pop-up">
        <div class="row">
          <div class="col-sm-6 pt-3">
            <span  class="vertical-line mx-3"> </span>
           <span class="header mb-0">Image Software details</span> 
          </div>
          <div class="col-sm-4">
            <div fxLayout fxLayoutAlign="center center">
              <mat-form-field fxFlex="60%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Search here" autocomplete="off">
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-2 pt-3">
            <span class="material-icons close-icon" mat-dialog-close>
              close
            </span>
          </div>
         
        </div>
        <hr class="margin-class" />
      
        <div class="example-container vdi-table">
          
          <div>
            <div style="text-align:center;color: red;padding-top: 5px;" *ngIf="Selecterror">Their is no software details for this VDI image</div>
            <table mat-table [dataSource]="dataSource" style="width: 100%;" matSort>
      
              <!-- Resource  Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} 
                
                </td>
              </ng-container>
      
              <!-- Resource Type Column -->
              <ng-container matColumnDef="tags">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Detail </th>
                <td mat-cell *matCellDef="let element"> {{element.tags}} </td>
                
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true"
              (mouseout)="row.hovered = false">
            </tr>
             </table>
        </div>
      
        </div>
        <div class="row">
          <div class="col-sm-8">
            <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 50]">
            </mat-paginator>
          </div>
         
        </div>
       
      
      </div>
</div>