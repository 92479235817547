<div class="dialogue">
  
    <div class="inner-top-container">
        <div class="row">
            <div class="col-sm-4">
                <h3 class="mb-0">Software Details</h3>
            </div>
            <div class="col-sm-1">
                <div class="form-group">
                    <label>
                        Category <span class="required"></span>
                    </label>
                </div>
            </div>
                <div class="col-sm-3">
                        <select  (change)="onChange($event)" class="form-control form-control-lg">
                            <option selected>All</option>
                            <option *ngFor="let C of Listcategory" [value]="C">{{C}}</option>
                          </select>
                    </div>
                 
           
            <div class="col-sm-4 text-right">
                <span (click)="onNoClick()" class="material-icons" style="float: right;cursor: pointer;">
                    close
                </span>
            </div>
         </div>
         <hr class="margin-class" />
        <!-- <div class="row"> -->
            <!-- <span class="field-name">Hardware Details</span> -->
           <!-- <div class="">
                <div class="mb-1">
                    <strong>Operating System</strong><span> : Windows 10 Enterprise</span>
                </div>
                <div class="mb-1">
                    <strong>Installed memory (RAM)</strong><span> : 4.00 GB</span>
                </div>
                <div class="mb-1">
                    <strong>System Type</strong> <span> : 64-bit Operating System</span>
                </div>
                <div class="mb-1">
                    <strong>Domain Name</strong> <span> : lntinfotech.com</span>
                </div>
            </div> -->
            <!-- <span class="field-name">Software Details</span> -->
        <!-- </div> -->
      <!--  <div class="row scroll">
            <div class="col-sm-4 img-margin" *ngFor="let img of image">
                    <div class="text-center"><img class="w40" src="{{img.software_image_path}}"></div>
                    <div  class="text-center">{{img.software_name}}</div>
                    <div  class="text-center"><span>Version - </span>{{img.software_version}}</div>
        
        </div>
        </div>-->

        <h2 *ngIf="imageList[0].length==0" class="noData">No Data Available</h2>
      <div class="row mt-3 scroll-container" *ngIf="imageList[0].length>0">
       
            <div class="col-sm-4 img-margin" *ngFor="let imgtable of imageList; let i = index">
                
                <table class="table table-bordered" *ngIf="imageList[i].length>0">
                    <tr class="table-th">
                        <td>Software Name</td>
                        <td>Version</td>
                    </tr>
                    <tbody>
                        <tr *ngFor="let img of imageList[i]">
                            <td>{{img.software_name}}</td>
                            <td>{{img.software_version}}</td>
                        </tr>
                    </tbody>
                </table>
  
        </div>
        </div>  
    </div>
</div>