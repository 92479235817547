import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/_alert';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  model: any = {};
  projectId: any;
  projectName: any;
  members: any = [];
  username: any = [];
  toolName;
  memberArray: any = [];
  showTable: boolean = false;
  showRepo: boolean = false;
  showUserRow: boolean = false;
  showAddRepo: boolean = true;
  selectedUserArray: any = []
  secondFormGroup: FormGroup;
  toolGroup: any = [];
  memberString = [];
  tableArr = [];
  Role:string="";
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  catalog_id: string;
  catalog_name: string;

  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService, private _snackBar: MatSnackBar, private _formBuilder: FormBuilder, public router: Router) { }


  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if(this.Role=="User"){
    this.alertService.yesClickedEvent
    .subscribe((data:string) => {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
      this.secondFormGroup.reset();
    });

    this.alertService.workbenchYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/workBench'])
    });

    this.alertService.homeYesClickedEvent
    .subscribe((data:string) => {
      this.secondFormGroup.reset();
      this.router.navigate(['/projectSummary'])
    });
   
   // @author-Priyanka Kale - getting projectId,ProjectName,catalogId,catalogName from localstorage ,so that data should not get cleared on page refresh

    this.projectId=localStorage.getItem('projectId');
    
   
    this.catalog_id=localStorage.getItem('catalogId');
   
    this.catalog_name=localStorage.getItem('catalogName');

    this.apiService.componentName.next("SCM");

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };

   
    this.projectName=localStorage.getItem('projectName');

    this.apiService.toolSelectedFromDialog.subscribe(data => {
      this.toolName = data
    })

    this.secondFormGroup = this._formBuilder.group({
      insightProjName: ['', Validators.required]
    });

    this.getInsightsInfo();
  }
  else{
    this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin-catalog-config']);
  });
  }
  }

  get insightProjName() {
    return this.secondFormGroup.get('insightProjName');
  }


  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup() {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '40%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {          
          this.createProj();
        }
      }
    );
  }
  
  addRepo() {
    this.selectedUserArray = [];
    this.apiService.selectedSCMMembers.next([])
    this.showRepo = true;
    this.showAddRepo = false;
  }

  openUser() {
    const scmUser = this.dialog.open(SCMMembers, {
      width: '50%',
      height: '53%',
      disableClose: true
    });

    scmUser.afterClosed().subscribe(result => {
      this.showUserRow = true;
      this.selectedUserArray = result
      this.apiService.selectedSCMMembers.next(this.selectedUserArray);
    })

  }

  openAddedUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '52%',
      disableClose: true,
      data: {
        member: memberArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }

  getInsightsInfo() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe((project: any) => {
      project[0].insights_info.forEach(project => {
        let memberArray = [];
        project.insights_users.forEach(member => {
          let memberObj = {
            name: member.username,
            email: member.email,
            role: member.role
          }
          memberArray.push(memberObj)
        })
        let tableObj =
        {
          "lobName": project.lob_name,
          "projectName": project.project_name,
          "users": memberArray
        }

        this.tableArr.push(tableObj)
        if (this.tableArr.length == 0) {
          this.showTable = false
        } else {
          this.showTable = true
        }
      })
    }, (error: HttpErrorResponse) => {
      if (error.status == 432){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
      
       })
  }

  createProj() {
    this.apiService.checkProjectNameAvailibility(this.insightProjName.value).subscribe((result: any) => {
      let memberArrayToSend = []
      this.showAddRepo = true;

      this.memberString = [];

      if (result.status === "Name exist! Choose Another") {
        this.alertService.error(result.status, this.options);
      }
      else {
        this.showRepo = false;
        this.showUserRow = false;
        this.selectedUserArray.forEach((user: any) => {
          memberArrayToSend.push(user.user_email)
          let membObj = {
            name: user.user_name,
            email: user.user_email,
            role: "TM"
          }
          this.memberString.push(membObj)
        })


        let insightsBody =
        {
          "project_id": this.projectId,
          "lob_name": "CanvasEngineering",
          "project_name": this.insightProjName.value,
          "insights_users": memberArrayToSend
        }
        this.showTable = true;
        this.apiService.createInsightsProject(insightsBody).subscribe((insightResult: any) => {

          if (insightResult.status == "Succeeded") {
            let tableObj =
            {
              "lobName": "CanvasEngineering",
              "projectName": this.insightProjName.value,
              "users": this.memberString
            }

            this.tableArr.push(tableObj);
            this.dtTrigger.next();
            this.secondFormGroup.reset()

            this.alertService.success('Project Created Successfully', this.options)
          }
        }, (error: HttpErrorResponse) => {
          if (error.status == 451){
          this.alertService.error(error.error.message, this.options);
           }
          else {
          this.alertService.error("Error creating project.", this.options);
          throw error;
           }
           })
      }

    }, (error: HttpErrorResponse) => {
      if (error.status == 451){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("No data available.", this.options);
      throw error;
       }
       })




  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  refreshTable() {
    this.dtTrigger.next();
  }


  //Cancel click
  toggleDisplay(secondFormGroup) {
    if(secondFormGroup.pristine == false){
    this.cancelconfirmationPopUp();
    } else {
      this.showRepo = false;
      this.showUserRow = false;
      this.selectedUserArray = [];
    }
 
  }

  // confirmation popup
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(insightCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
     
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.scmworkbenchConfirmationPopup();
      } else {
        this.router.navigate(['/workBench'])
      }
  }
  goHome(secondFormGroup) {
    if(secondFormGroup.pristine == false){
      this.homeConfirmationPopup();
      } else {
        this.router.navigate(['/projectSummary'])
      }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(insightWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe( data => {
      }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(insightHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {   
      }
    );
  }

  deleteUser() {
    let body = {
      "project_id": this.projectId,
      "username": ""
    }
    this.apiService.deleteInsightsUsers(body).subscribe((response) => {
      this.alertService.success('User deleted successfully', this.options)
    }, (error: HttpErrorResponse) => {
      if (error.status == 451){
      this.alertService.error(error.error.message, this.options);
       }
      else {
      this.alertService.error("Error deleting insights.", this.options);
      throw error;
       }
       })
  }

}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./insights.component.scss']
})
export class insightCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<insightCancelConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  popUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./insights.component.scss']
})
export class insightWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<insightWorkbenchConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  workbenchPopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./insights.component.scss']
})
export class insightHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<insightHomeConfirmationPopUpDialog>,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
  }
  homePopUpClose(option){
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

