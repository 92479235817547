import { OnInit, ViewChild, Inject } from '@angular/core';
import { AuthService } from 'src/app/service/api.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'src/app/_alert';
import { Subject } from 'rxjs';
import { DialogProjectInfoDialog, DialogConfirmationPopup } from '../vdi-details/vdi-details.component';
import { SCMMembers, MembersListDialog } from '../scm-tool/scm-tool.component';
import { MatPaginator } from '@angular/material/paginator';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

export function MatchValues(controlName: string, values: string[]) {
  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];

    let invalidWords = [];
    values.forEach(element => {
      if (control.value == element) {
        invalidWords.push(element);
      }
      else {
      }
    });

    if (control.errors) {

      // return if another validator has already found an error on the control
      return;

    }
    // set error on control if validation fails
    if (invalidWords.length > 0) {
      control.setErrors({ notMatch: true });
    } else {
      control.setErrors(null);
    }
    if (control.errors) {

      // return if another validator has already found an error on the control
      return;

    }
    //set error on control if control ends in . 
    let position = control.value.indexOf('.')
    if (position == control.value.length - 1) {
      control.setErrors({ containsDot: true });
    } else {
      control.setErrors(null);
    }
  }

}
export function MustMatch(controlName: string, matchingControlName: string) {

  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];

    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {

      // return if another validator has already found an error on the matchingControl
      return;

    }
    // set error on matchingControl if validation fails

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    }
    else {
      matchingControl.setErrors(null);
    }

  }

}
@Component({
  selector: 'app-deployment-store',
  templateUrl: './deployment-store.component.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class DeploymentStoreComponent implements OnInit {
  projectId: any;
  projectName: any;
  members: any = [];
  username: any = [];
  toolName;
  memberArray: any = [];
  showTable: boolean = false;
  showRepo: boolean = false;
  showUserRow: boolean = false;
  showAddRepo: boolean = true;
  selectedUserArray: any = []
  secondFormGroup: FormGroup;
  vmSizeVal: any = [
    "Standard_D2S_V3"
  ];
  nodeCountVal: any = [1, 2, 3, 4, 5]
  memberString = [];
  tableArr = [];

  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };

  ELEMENT_DATA: RepoTable[];
  displayedColumns: string[] = ['cluster', 'dnsPrefix', 'nodeCount', 'vmSize'];
  //dataSource = new MatTableDataSource<RepoTable>(this.ELEMENT_DATA);
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  catalog_id: string;
  catalog_name: string;
  vmFormGroup: FormGroup;
  showVm: boolean = false;
  typeVal: any = [];
  selectedServer: any;
  OSVal: any = [];
  serverVal: any = [];
  dbType: any = [];
  appType: any = [];
  webType: any = [];
  locationVal = [];
  usageVal=['Test', 'Production', 'Staging', 'QA', 'Jump Server', 'Development']
  dbHostedVal=['Yes','No']
  VMSizeVal = [];
  vmData: any[];
  showVmTable: boolean;
  azure_instance_id: any;
  subscription_ID: any;
  resourceGroup: any;
  VMresourceGroup:any;
  virtual_network: any;
  subnet: any;
  imageRef: any;
  vmResults: any;
  vmRequestBody: any;
  networkConfigVmId: any;
  networkConfigTools: any;
  imageVal: any = [];
  imgData: any;
  diskTypeVal = [];
  startBtn: boolean = false;
  stopBtn: boolean = false;
  deleteBtn: boolean = false;
  createVMFlag: boolean = false;
  addUserIcon: boolean = false;
  windowsUserArray: any = [];
  refreshDisable: boolean = true;
  resource_grp: any;
  subscription: any;
  diskValues = [];
  filterStatus: any = "Active";
  deploymentFlag: boolean = false;
  downloadFile: any;
  VMlocationVal: any = [];
  Role: string;
  memberArraysoft: any[];
  idproject: string;
  template_id: any;
  template_name: any;
  msg: any;
  checklist: any;
  masterSelected: boolean;
  checkedList: any[];
  itemSelected: boolean;
  VdiConfig_details: any[];
  project_selectedcheckbox: boolean;
  manageDisable: boolean = true;
  targets: any[];
  targetsVM: any[];

  checkDisable = true;

  // desk: 0;

  constructor(public alertService: AlertService, public dialog: MatDialog, private apiService: AuthService, private spinner: NgxSpinnerService, private _formBuilder: FormBuilder, public router: Router) { }


  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.dtOptions = {
        order: []
      };
      this.alertService.yesClickedEvent
        .subscribe((data: string) => {
          this.showRepo = false;
          this.showUserRow = false;
          this.showVm = false;
          this.selectedUserArray = [];
          this.OSVal = [];
          this.serverVal = [];
          this.dbType = [];
          this.appType = [];
          this.webType = [];
          this.typeVal = [];
          this.secondFormGroup.reset();
        });
        this.idproject = localStorage.getItem('projectId');

        this.apiService.getProjectById(this.idproject).subscribe((data: any[])=>{
         // debugger
     
         this.template_id = data[0].template_id;
         this.template_name = data[0].template_name;
         
       })  


      let newCatalog_id = localStorage.getItem('catalogId');
      this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
        if (data.deploymentstore_tools.length == 0) {
          this.deploymentFlag = true;
        }
      })

      this.alertService.workbenchYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this.vmFormGroup.reset();
          this.router.navigate(['/workBench'])
        });

      this.alertService.homeYesClickedEvent
        .subscribe((data: string) => {
          this.secondFormGroup.reset();
          this.vmFormGroup.reset();
          this.router.navigate(['/projectSummary'])
        });

      this.projectId = localStorage.getItem('projectId');
      this.projectName = localStorage.getItem('projectName');
      this.catalog_id = localStorage.getItem('catalogId');
      this.catalog_name = localStorage.getItem('catalogName');

      this.apiService.toolSelectedFromDialog.subscribe(data => {
        this.toolName = data
      })

      this.apiService.componentName.next("VM");

      this.secondFormGroup = this._formBuilder.group({
        cluster: ['', Validators.required],
        dnsPrefix: ['', Validators.required],
        nodeCount: ['', Validators.required],
        vmSize: ['', Validators.required]
      });
      let values = ["1", "123", "a", "actuser", "adm", "admin", "Admin", "ADMIN", "admin1", "admin2", "administrator", "aspnet", "backup", "console", "david", "guest", "john", "owner", "root", "server", "sql", "support_388945a0", "support", "sys", "test", "test1", "test2", "test3", "user", "user1", "user2"]
      this.vmFormGroup = this._formBuilder.group({
        image: [null, Validators.required],
        network_config_vm: [null, Validators.required],
        location: [null, Validators.required],
        usage: [null, Validators.required],
        dbHosted: [null, Validators.required],


        size: [null, Validators.required],
        // computerName: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^([0-9]*)([a-zA-Z-]+)([0-9]*)([a-zA-Z-0-9]*)$')]],
        userName: ['', [Validators.required, Validators.maxLength(20),Validators.pattern('^(?=.*?[A-Za-z])[a-zA-Z0-9]+$')]],
        bu: ['', [Validators.required, Validators.maxLength(20),Validators.pattern('^(?=.*?[A-Za-z])[a-zA-Z0-9]+$')]],

        vmDiskType: ['', Validators.required],
        password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\\W_]).{14,}$'), Validators.minLength(14)]],
        confirmPassword: ['', [Validators.required]]
      }, {
        validator: [MustMatch('password', 'confirmPassword'), MatchValues('userName', values)]
      });
      this.getVMProject();


      this.showAddRepo = false;
      this.OSVal = [];
      this.serverVal = [];
      this.dbType = [];
      this.appType = [];
      this.webType = [];
      this.typeVal = [];
      this.imageVal = [];
      this.apiService.selectedVDIMembers.next([])
      this.apiService.getCatalogById(this.catalog_id, 'Deployment-Store').subscribe((result: any) => {
        this.vmResults = result;

        result.Tool_list.forEach(element => {
          this.imageVal.push(element.tool_instance_details.deploymentstore_tool_instance)
        });
        this.apiService.getCatalogInformation(this.catalog_id).subscribe((data: any) => {
          this.networkConfigTools = data.network_config_tools.filter(x => x.tool_name == "Network-Config-VM");
        }, (error: HttpErrorResponse) => {
          if (error.status == 437) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.alertService.error("Unable to fetch data", this.options);
            throw error;
          }

        })
      })
      let dropdownBody = {
        "dropdown_category": "Azure-VM",
        "screen_name": "Workbench-VM"
      }
      this.apiService.getDropdowns(dropdownBody).subscribe((data: any) => {
        this.locationVal = data.dropdownLocation;
        this.VMSizeVal = data.dropdownVmSize;
        this.diskTypeVal = data.dropdownVmDiskType;
        this.diskValues = [...this.diskTypeVal];
      }, (error: HttpErrorResponse) => {
        if (error.status == 446) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }
  installSoftware(os,ip,name){
    // console.log(this.targets);
    if (os.includes("Windows",0)){
      os = "windowsVM";
    }
    else{
      os = "linux";
    }
    this.targets=[]
    //console.log(os,ip,name);
    let installVMSoftware={

      "host": ip,
     
     "hostname":name,
     
      "username":"canvasadmin",
     
      "password":"Canvas@dm!n202o",
     
      "os": os
     
     }
     this.targets.push(installVMSoftware);
     //console.log(this.targets);
    const scmUser = this.dialog.open(softwareDialogVM, {
      width: '50%',
      height: '72%',
      disableClose: true,
      data:{
        member: this.targets
      }
    });
    scmUser.afterClosed().subscribe(result => {      
      // console.log(result);
      
      setTimeout(() => {
        this.getVMProject();
      }, 1000);
     
  });
  }
  uninstallSoftwareVdi(os,ip,name){
    // console.log(this.targets);
    if (os.includes("Windows",0)){
      os = "windowsVM";
    }
    else{
      os = "linux";
    }
    this.targetsVM=[]
    //console.log(os,ip,name);
    let installVMSoftware={

      "host": ip,
     
     "hostname":name,
     
      "username":"canvasadmin",
     
      "password":"Canvas@dm!n202o",
     
      "os": os
     
     }
     this.targetsVM.push(installVMSoftware);
     //console.log(this.targets);
    const scmUser = this.dialog.open(softwareDialogUninstallVM, {
      width: '50%',
      height: '72%',
      disableClose: true,
      data:{
        member: this.targetsVM
      }
    });
    scmUser.afterClosed().subscribe(result => {      
      // console.log(result);
      
      setTimeout(() => {
        this.getVMProject();
      }, 1000);
     
  });
  }
  checkUncheckAll() {
    // console.log(this.VdiConfig_details);
    // console.log(this.checklist);
    

    
    // debugger
    for (var i = 0; i < this.checklist.length; i++) {
      if(
        this.checklist[i].status=='stopped' || this.checklist[i].status=='running'
      ){
        this.checklist[i].isSelected = this.masterSelected;

      }
    }
    this.getCheckedItemList();
  }
  isAllSelected() {
    // debugger
    this.masterSelected = this.checklist.every(function (item: any) {
      if(item.isSelected){
        // console.log(item);
        
      }
      
      return item.isSelected == true;
    })
    // console.log(this.masterSelected);
    
    

    this.getCheckedItemList();
  }
  getCheckedItemList() {
    // debugger
    this.project_selectedcheckbox = false;
  //  debugger;
    // if(this.checkedList!=undefined){
    this.checkedList = [];
    // console.log(this.checkedList);
    //console.log(this.checklist);
    
    for (var i = 0; i < this.checklist.length; i++) {
      if (this.checklist[i].isSelected)
        this.checkedList.push([this.checklist[i].request_id,this.checklist[i].status]);

    }
    // console.log(this.checkedList);
    
   // this.checkedList = JSON.stringify(this.checkedList);
   
   // @author - Priyanka Kale - set itemSelected flag to false 
   this.itemSelected=false;
   this.VdiConfig_details=[];
   
    for (let index = 0; index < this.checkedList.length; index++) 
              {
               //@author Priyanka Kale - added itemSelected flag here - it will check it item is selected from the list or not   
               this.itemSelected=true;

                this.project_selectedcheckbox = true;
           // debugger;
                  var SelectedItem=this.checkedList[index][0];
                  var SelectedItemStatus=this.checkedList[index][1];

                 let VdiConfigBoby={
                    "request_id":SelectedItem,
                    "status":SelectedItemStatus,

                    }
                   this.VdiConfig_details.push(VdiConfigBoby);


                  
                 // For Loop of tag

                // Add image des 
                               
             }
            //  console.log(this.VdiConfig_details);
             if(this.VdiConfig_details.length==0 ){
               this.manageDisable=true
               
             }
             else if(this.VdiConfig_details.length!=0 ){
              this.manageDisable=false
              
            }
    // console.log(this.VdiConfig_details, "checkedList")
    //}
  }
startvm(){

  let vmBody = {
    "project_id": this.projectId,
    "request_info": this.VdiConfig_details
     
  
  }

  this.apiService.startVM(vmBody).subscribe((data: any) => {
    this.manageDisable=true

    // this.alertService.success(data.message, this.options)
    if(data.code!=1){
      this.refreshvdi()

      setTimeout(() => {
        this.getVMProject()
      }, 2000);
    }
    else{
      this.alertService.success(data.message,this.options)
      setTimeout(() => {
        this.getVMProject()
      }, 2000);

    }

   
   // this.getVMProject()

    // if(data.Status){
    //   // this.alertService.success("VM start process initiated.", this.options);
    //   this.alertService.success(data.message,this.options)

    // }
  }, (error: HttpErrorResponse) => {
    if (error.status == 445) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403) { }
    else {
      this.alertService.error("Error while starting VM.", this.options);
      throw error;
    }

  })

}
stopvm(){
  let vmBody = {
    "project_id": this.projectId,
    "request_info": this.VdiConfig_details
  }

  this.apiService.stopVM(vmBody).subscribe((data: any) => {
    if(data.code!=1){
      this.refreshvdi()

      setTimeout(() => {
        this.getVMProject()
      }, 2000);
    }
    else{
      this.alertService.success(data.message,this.options)
      setTimeout(() => {
        this.getVMProject()
      }, 2000);

    }
  }, (error: HttpErrorResponse) => {
    if (error.status == 445) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403) { }
    else {
      this.alertService.error("Error while stopping VM.", this.options);
      throw error;
    }

  })

}
  get cluster() {
    return this.secondFormGroup.get('cluster');
  }
  get dnsPrefix() {
    return this.secondFormGroup.get('dnsPrefix');
  }
  get nodeCount() {
    return this.secondFormGroup.get('nodeCount');
  }
  get vmSize() {
    return this.secondFormGroup.get('vmSize');
  }

  get image() {
    return this.vmFormGroup.get('image');
  }
  get network_config_vm() {
    return this.vmFormGroup.get('network_config_vm');
  }
  get os() {
    return this.vmFormGroup.get('os');
  }
  get server() {
    return this.vmFormGroup.get('server');
  }
  get type() {
    return this.vmFormGroup.get('type');
  }
  get location() {
    return this.vmFormGroup.get('location');
  }
  get usage() {
    return this.vmFormGroup.get('usage');
  }
  get dbHosted() {
    return this.vmFormGroup.get('dbHosted');
  }
  get size() {
    return this.vmFormGroup.get('size');
  }
  get vmDiskType() {
    return this.vmFormGroup.get('vmDiskType');
  }
  // get computerName() {
  //   return this.vmFormGroup.get('computerName');
  // }
  get userName() {
    return this.vmFormGroup.get('userName');
  }
  get bu() {
    return this.vmFormGroup.get('bu');
  }
  get password() {
    return this.vmFormGroup.get('password');
  }
  get confirmPassword() {
    return this.vmFormGroup.get('confirmPassword');
  }

  checkToolData() {
    this.selectedUserArray=[]
    this.apiService.selectedVDIMembers.next([])
    let newCatalog_id = localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
      if (data.deploymentstore_tools.length == 0) {
        this.deploymentFlag = true;
        let element: HTMLElement = document.getElementById('CallModel') as HTMLElement;
        element.click();
      }
      else {
        this.getVMFormDetails();
        this.AddCluster();
      }
    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Error while stopping VM.", this.options);
        throw error;
      }

    })
  }

  getVMFormDetails() {
    this.showAddRepo = false;
    this.OSVal = [];
    this.serverVal = [];
    this.dbType = [];
    this.appType = [];
    this.webType = [];
    this.typeVal = [];
    this.imageVal = [];
    this.apiService.getCatalogById(this.catalog_id, 'Deployment-Store').subscribe((result: any) => {
      this.vmResults = result;

      result.Tool_list.forEach(element => {
        this.imageVal.push(element.tool_instance_details.deploymentstore_tool_instance)
      });
      this.apiService.getCatalogInformation(this.catalog_id).subscribe((data: any) => {
        this.networkConfigTools = data.network_config_tools.filter(x => x.tool_name == "Network-Config-VM");
      }, (error: HttpErrorResponse) => {
        if (error.status == 437) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("Unable to fetch data", this.options);
          throw error;
        }

      })
    })
    let dropdownBody = {
      "dropdown_category": "Azure-VM",
      "screen_name": "Workbench-VM"
    }
    this.apiService.getDropdowns(dropdownBody).subscribe((data: any)=>{
        this.locationVal = data.dropdownLocation;
        this.VMlocationVal=data.dropdownLocation;

        this.VMSizeVal = data.dropdownVmSize;
        this.diskTypeVal = data.dropdownVmDiskType;
        this.diskValues = [...this.diskTypeVal];
    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  AddCluster() {
    this.showVm = true;
    this.vmFormGroup.reset();
    // this.openDialog();
  }
  deleteconfirmationPopUp(req_id) {
    // debugger

    const dialogRef = this.dialog.open(vmdeleteConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      id:req_id,
      data:{
        req:req_id.request_id
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          this.softDeleteVM(req_id);
        }
      }
    );
  
  }
  softDeleteVM(element){
    this.memberArraysoft=[]
    for (let i = 0; i < element.members.length; i++) {
      element.members[i]
      let body={
        "user_name": element.members[i].name,
        "user_mail":    element.members[i].email,
        "user_access":element.members[i].user_access,
        "user_status": element.members[i].status,
      }
      this.memberArraysoft.push(body)
    }
    let body={
      "project_details": {
          "project_id": this.projectId,        
          "project_name": this.projectName,        
          "catalog_id": this.catalog_id,
          "catalog_name": this.catalog_name,
          "template_id": this.template_id,
          "template_name": this.template_name,
          "env": environment.scmEnv
        },
      "request_details": {
          "vm_request_details": [
              {
                  "request_id": element.request_id,
                  "vm_data": {
                      "status": element.status,
                      "os_type": element.os_type,
                      "server_type": element.server_type,
                      "application_name": element.application_name,
                      "deployment_name": element.deployment_name,
                      "location": element.location,
                      "os_disk_type": element.os_disk_type,
                      "vm_size": element.vm_size,
                      "admin_username": element.admin_username,
                      // "admin_password": "ZXCvbnm@1234",
                      "resourceID": element.resourceID,
                      "ip_address": element.ip_address,
                      "members": this.memberArraysoft,
                      "requestor_name": localStorage.getItem('profile'),
                  }
              }
          ]
      }
  }
  this.spinner.show()
  this.apiService.softdeleteProject(body).subscribe((data: any)=>{
    this.spinner.hide()
    this.getVMProject();
    this.alertService.success(data.message, this.options);
    // this.refreshvdi(element.request_id);
    setTimeout(() => {
      this.getVMProject();
    }, 1500);
  }, (error: HttpErrorResponse) => {
    if (error.status == 435) {
      this.spinner.hide()
      //this.getVMProject();


      this.alertService.error(error.error.message, this.options);

    }
    else if (error.status == 453){
      this.spinner.hide()

      this.alertService.error(error.error.message, this.options);

    }
    else {
      this.spinner.hide()

      this.alertService.error(error.error.message, this.options);
      throw error;
    }

  })
  // console.log(body);
  

  }

  getStatus(status) {
    this.filterStatus = status;
    this.getVMProject();
  }
  refreshvdi(){
    const dialogRef = this.dialog.open(vmRefreshInformationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,
      data: {
        // req: request_id,
       
      }
     
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes') {
          // console.log("hello");
          //this.getVMProject()
          // setTimeout(() => {
          //   this.getVMProject()
          //   },1000)
    
        }
      }
    );
  }
  stopVM(request_id) {
    let vmBody = {
      "project_id": this.projectId,
      "request_id": request_id
    }

    this.apiService.stopVM(vmBody).subscribe((data: any) => {
      // this.refreshvdi(request_id)

      setTimeout(() => {
        this.getVMProject()
      }, 1000);
      // this.alertService.success(data.message, this.options)

      // if(data.Status){
      //   // this.alertService.success("VM stop process initiated.", this.options);
      //   this.alertService.success(data.message,this.options)
      // }
    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Error while stopping VM.", this.options);
        throw error;
      }

    })
  }

  startVM(request_id) {
    let vmBody = {
      "project_id": this.projectId,
      "request_id": request_id
    }

    this.apiService.startVM(vmBody).subscribe((data: any) => {
      // this.alertService.success(data.message, this.options)
      // this.refreshvdi(request_id)

      setTimeout(() => {
        this.getVMProject()
      }, 3000);
     // this.getVMProject()

      // if(data.Status){
      //   // this.alertService.success("VM start process initiated.", this.options);
      //   this.alertService.success(data.message,this.options)

      // }
    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Error while starting VM.", this.options);
        throw error;
      }

    })
  }

  deleteVM(request_id) {
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    let mail = localStorage.getItem('mail');
    let body = {

      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName
      },
      "request_details": {
        "request_id": request_id,
        "requestor_mail": mail,
        "requestor_name": userName,
      }

    }

    this.apiService.deleteVM(body).subscribe((data: any) => {

      this.getVMProject()
      // this.alertService.success("VM delete process initiated.", this.options);
      this.alertService.success(data.message, this.options)


    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { 
        this.alertService.error(error.error.message, this.options);

      }
      else{
        this.alertService.error(error.error.message, this.options);

      }
    })
  }

  public doFilter = (value: string) => {
    // this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  showRequestInfo(value) {
    const dialogRef = this.dialog.open(VMInfoModal, {
      width: '50%',
      disableClose: true,
      data: {
        info: value
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openUser(value, type) {
    this.apiService.VMModalType.next(type);
    if (type == 'Update' || type == 'Delete') {
      this.apiService.AddMemberVMData.next(value)
    }
    const vmUser = this.dialog.open(VMMembers, {
      width: '50%',
      height: '60%',
      disableClose: true
    });

    vmUser.afterClosed().subscribe(result => {
      if (type == 'Update' || type == 'Delete') {
        this.showUserRow = false;
      }
      else {
        this.showUserRow = true;
      }
     // this.getVMProject();
      this.selectedUserArray = result
      this.apiService.selectedVDIMembers.next(this.selectedUserArray);
    })



  }

  removeUser(user, index) {
    this.selectedUserArray.splice(index, 1)
  }

  onSelectImageDropdown(data){
 
    
    // this.locationVal=this.VMlocationVal
    if  (data.name=="MS-WindowsServer2019-CIS-L1-VM-Image-EastUS-V1"){
      // this.desk=1;
    //  this.locationVal=['eastus']
        this.locationVal = this.locationVal.filter(x => x.property_value == 'eastus')
    }
    else{
      this.locationVal = this.VMlocationVal.filter(y => y.property_value != 'eastus')

    }
 
    // debugger
    this.selectedUserArray = [];
        // this.apiService.selectedVDIMembers.next([])
        this.apiService.selectedVDIMembers.next([])


    if (data.vm_os.includes("Windows")) {
      this.addUserIcon = true;
    }
    else {
      this.addUserIcon = false;
    }
  }

  getVMProject() {
    this.dtOptions = {
      order: []
    };
    this.spinner.show();
    this.vmData = [];
    this.tableArr = [];
   // if (data.length > 0) {
   this.apiService.refreshVMStatus(this.projectId).subscribe((status: any) => {
      //  this.alertService.success(data.message, this.options);
        this.msg = status.message;
        this.refreshDisable = false;
        setTimeout(() => {
          this.refreshDisable = true;
        }, 2000)

        this.apiService.getVMProject(this.projectId, this.filterStatus).subscribe((data: any) => {
          // debugger
          if (data.length == 0) {
            this.spinner.hide();
          }
          else{
            // this.alertService.success(this.msg, this.options);
          
            
             
          data.forEach(element => {
    
            let requestStatus = (element.vm_data.status).toLowerCase();
    
            if (requestStatus == "pending") {
              this.startBtn = false;
              this.stopBtn = false;
              this.deleteBtn = false;
            }
            else if (requestStatus == "running") {
              this.startBtn = false;
              this.stopBtn = true;
              this.deleteBtn = true;
            }
            else if (requestStatus == "stopped") {
              this.startBtn = true;
              this.stopBtn = false;
              this.deleteBtn = true;
            }
            else if (requestStatus == "stopping") {
              this.startBtn = false;
              this.stopBtn = false;
              this.deleteBtn = false;
            }
            else if (requestStatus == "removed") {
              this.startBtn = false;
              this.stopBtn = false;
              this.deleteBtn = false;
            }
    
            else {
              this.startBtn = false;
              this.stopBtn = false;
              this.deleteBtn = false;
            }
            let memberArray = [];
    
            element.vm_data.members.forEach(member => {
              let membObj = {
                name: member.user_name,
                email: member.user_mail,
                user_access: member.user_access,
                status: member.user_status
              }
    
              if (member.user_status != 'Offboarded') {
                memberArray.push(membObj)
              }
    
            });
    
    
            if (element.vm_data.resourceID) {
              this.subscription = element.vm_data.resourceID.split('/')[2]
              this.resource_grp = element.vm_data.resourceID.split('/')[4]
            }
    
    
            let tableObj =
            {
              "request_id": element.request_id,
              "os_type": element.vm_data.os_type,
              "location": element.vm_data.location,
              "vm_size": element.vm_data.vm_size,
              "deployment_name": element.vm_data.deployment_name,
              "os_disk_type": element.vm_data.os_disk_type,
              "resource_group": this.resource_grp,
              "status": element.vm_data.status,
              "subnet_name": element.vm_data.subnet_name,
              "subscription_id": this.subscription,
              "vnet_name": element.vm_data.vnet_name,
              "startStatus": this.startBtn,
              "ip_address": element.vm_data.ip_address,
              "application_name": element.vm_data.application_name,
              "server_type": element.vm_data.server_type,
              "stopStatus": this.stopBtn,
              "deleteStatus": this.deleteBtn,
              "admin_username":element.vm_data.admin_username,
              "resourceID":element.vm_data.resourceID,
              "members": memberArray
            }
    
            this.tableArr.push(tableObj)
            this.vmData = this.tableArr
            this.checklist = this.vmData;

    
            this.dtTrigger.next();
            this.showVmTable = true;
            this.spinner.hide();
          });
          }
         
        }, (error: HttpErrorResponse) => {
          this.spinner.hide();
          if (error.status == 445) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.alertService.error("No data available.", this.options);
            throw error;
          }
    
        })
      }, (error: HttpErrorResponse) => {
        if (error.status == 445) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })
   // }
   
   
  }

  openUserDialog(memberArray) {
    const dialogRef = this.dialog.open(MembersListDialog, {
      width: '50%',
      height: '60%',
      disableClose: true,
      data: {
        member: memberArray
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //Cancel click
  toggleDisplay(secondFormGroup) {
    if (secondFormGroup.pristine == false) {
      this.cancelconfirmationPopUp();
    } else {
      this.showRepo = false;
      this.showUserRow = false;
      this.showVm = false;
      this.addUserIcon = false;
      this.selectedUserArray = [];
      this.OSVal = [];
      this.serverVal = [];
      this.dbType = [];
      this.appType = [];
      this.webType = [];
      this.typeVal = [];
    }

  }

  // confirmation popup
  cancelconfirmationPopUp() {
    const dialogRef = this.dialog.open(storeCancelConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true,

    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  goWorkBeanch(secondFormGroup, vmFormGroup) {
    if (secondFormGroup.pristine == false || vmFormGroup.pristine == false) {
      this.scmworkbenchConfirmationPopup();
    } else {
      this.router.navigate(['/workBench'])
    }
  }
  goHome(secondFormGroup, vmFormGroup) {
    if (secondFormGroup.pristine == false || vmFormGroup.pristine == false) {
      this.homeConfirmationPopup();
    } else {
      this.router.navigate(['/projectSummary'])
    }
  }

  scmworkbenchConfirmationPopup() {
    const dialogRef = this.dialog.open(storeWorkbenchConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }

  homeConfirmationPopup() {
    const dialogRef = this.dialog.open(storeHomeConfirmationPopUpDialog, {
      width: '30%',
      height: '33%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(data => {
    }
    );
  }


  showProjectInfo() {
    const dialogRef = this.dialog.open(DialogProjectInfoDialog, {
      width: '50%',
      height: '30%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmationPopup(option) {
    const dialogRef = this.dialog.open(DialogConfirmationPopup, {
      width: '30%',
      height: '30%',
      disableClose: true,
      data: {
        data: "VM"
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'Yes' && option == 'VM') {
          this.createVM();
          this.createVMFlag = true;
        }
        else if (data == 'Yes' && option == 'cluster') {
          this.createRepo();
        }
        else {
          dialogRef.close();
        }
      }
    );
  }

  showVMSizeInfo(data, type) {
    // debugger

    this.apiService.modalType.next(type);
    if (type == 'imageInfo') {
      this.apiService.VmImageInfo.next(data);
    }
    if (type == 'networkConfig') {
      this.apiService.getToolById(this.network_config_vm.value.tool_id).subscribe((response: any) => {
        
        this.subscription_ID = response[0].tool_instance_details.network_config_tool_instance.azure_subscription_id;
        this.resourceGroup = response[0].tool_instance_details.network_config_tool_instance.resource_group;
        if(!response[0].tool_instance_details.network_config_tool_instance.vm_resource_group){
          this.VMresourceGroup = response[0].tool_instance_details.network_config_tool_instance.resource_group;
        }else{
          this.VMresourceGroup = response[0].tool_instance_details.network_config_tool_instance.vm_resource_group;
        }
        this.VMresourceGroup = response[0].tool_instance_details.network_config_tool_instance.vm_resource_group;
        this.virtual_network = response[0].tool_instance_details.network_config_tool_instance.vnet;
        this.subnet = response[0].tool_instance_details.network_config_tool_instance.subnet;

        let networkConfigData = {
          "subscription_id": this.subscription_ID,
          "resource_group": this.resourceGroup,
          "vm_resource_group":this.VMresourceGroup,
          "virtual_network": this.virtual_network,
          "subnet": this.subnet,
          "tool_name":response[0].tool_name
        }
        
        this.apiService.networkConfigData.next(networkConfigData);
      })
    }

    let vmInfo = [{
      "VMSize": "Standard_D2s_v3",
      "RAM": 8,
      "vCPUs": 2,
      "Temp_Storage": 32,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D4s_v4",
      "RAM": 16,
      "vCPUs": 4,
      "Temp_Storage": 0,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D8s_v3",
      "RAM": 32,
      "vCPUs": 8,
      "Temp_Storage": 32,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_A8_v2",
      "RAM": 16,
      "vCPUs": 8,
      "Temp_Storage": 80,
      "Description": "Entry level workloads (Dev or Test)",
    },
    {
      "VMSize": "Standard_D16s_v4",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 0,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_D16as_v4",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Latest generation, general purpose workloads",
    },
    {
      "VMSize": "Standard_B16ms",
      "RAM": 64,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Ideal for workloads that do not need continuous full CPU performance",
    },
    {
      "VMSize": "Standard_F8s_v2",
      "RAM": 64,
      "vCPUs": 8,
      "Temp_Storage": 16,
      "Description": "Compute Optimized(Up to 2x performance for vector processing workloads)",
    },
    {
      "VMSize": "Standard_F16s_v2",
      "RAM": 32,
      "vCPUs": 16,
      "Temp_Storage": 128,
      "Description": "Compute Optimized(Up to 2x performance for vector processing workloads)",
    }]

    let vmInfoObj;
    vmInfo.forEach(el => {
      if (data == el.VMSize) {

        vmInfoObj = {
          "VMSize": el.VMSize,
          "RAM": el.RAM,
          "vCPUs": el.vCPUs,
          "Temp_Storage": el.Temp_Storage,
          "Description": el.Description,
        }
      }

    })
    const dialogRef = this.dialog.open(DialogVMSizeInfo, {

      width: '50%',
      height: '30%',
      disableClose: true,
      data: {
        vmInfo: vmInfoObj
      }
    }
    );

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  createRepo() {
    let memberArrayToSend = []
    this.showAddRepo = true;
    this.showRepo = false;
    this.showUserRow = false;

    let tableObj =
    {
      "cluster": this.cluster.value,
      "nodeCount": this.nodeCount.value,
      "vmSize": this.vmSize.value,
      "dnsPrefix": this.dnsPrefix.value,
      "status": "Pending",
      "members": this.memberString,
      "action": "Update"
    }

    this.tableArr.push(tableObj)

    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })

    let deployBody = {
      "dns_prefix": this.dnsPrefix.value,
      "node_count": this.nodeCount.value,
      "vm_size": this.vmSize.value,
      "cluster_name": this.cluster.value,
      "resourceGroupName": "LTI-CANVAS-DEMO-RG",
      "clientID": "962c5a92-8875-4343-a3a1-5cb3fe07f348",
      "client_secret": "d54~Xv.qdf.yiTV3~_PWVe031x0CmLu2Wy",
      "location": "    central india",
      "subscriptionID": "fd3add44-9df1-45fc-b4fd-a805a8468edb",
      "tenantID": "ff355289-721e-4dd7-a663-afec62ab9d54"

    }


    // this.dataSource.data = this.tableArr as RepoTable[]
    this.secondFormGroup.reset()
    this.showTable = true;
    this.apiService.createCluster(deployBody).subscribe(deployResult => {
      this.dtTrigger.next();
    }, (error: HttpErrorResponse) => {
      if (error.status == 448) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("Error creating cluster.", this.options);
        throw error;
      }
    })

    this.alertService.success('Cluster Added Successfully !', this.options);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  openDialog() {
    const dialogRef = this.dialog.open(aksVmModal, {
      width: '28%',
      height: '37%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'VM') {
          this.getVMProject();
          this.showVm = true;
          this.showAddRepo = false;
          this.OSVal = [];
          this.serverVal = [];
          this.dbType = [];
          this.appType = [];
          this.webType = [];
          this.typeVal = [];
          this.imageVal = [];
          this.apiService.selectedVDIMembers.next([])
          this.apiService.getCatalogById(this.catalog_id, 'Deployment-Store').subscribe((result: any) => {
            this.vmResults = result;

            result.Tool_list.forEach(element => {
              this.imageVal.push(element.tool_instance_details.deploymentstore_tool_instance)
            });
            this.apiService.getCatalogInformation(this.catalog_id).subscribe((data: any) => {
              this.networkConfigTools = data.network_config_tools.filter(x => x.tool_name == "Network-Config-VM");
            }, (error: HttpErrorResponse) => {
              if (error.status == 437) {
                this.alertService.error(error.error.message, this.options);
              }
              else if (error.status == 403) { }
              else {
                this.alertService.error("Unable to fetch data", this.options);
                throw error;
              }

            })
          })
          let dropdownBody = {
            "dropdown_category": "Azure-VM",
            "screen_name": "Workbench-VM"
          }
          this.apiService.getDropdowns(dropdownBody).subscribe((data: any) => {
            this.locationVal = data.dropdownLocation;
            this.VMSizeVal = data.dropdownVmSize;
            this.diskTypeVal = data.dropdownVmDiskType;
            this.diskValues = [...this.diskTypeVal];
          }, (error: HttpErrorResponse) => {
            if (error.status == 446) {
              this.alertService.error(error.error.message, this.options);
            }
            else if (error.status == 403) { }
            else {
              this.alertService.error("No data available.", this.options);
              throw error;
            }

          })
        }
        else if (data == 'cluster') {
          this.showRepo = true;
          this.showAddRepo = false;
          this.showVmTable = false;
        }
        else {
          this.showVm = false;
          this.showRepo = false;
        }
      }

    );
  }

  downloadData(type) {
    if (type == 'pdf') {
      this.apiService.downloadVMInPdf(this.projectId, this.filterStatus).subscribe(data => {
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = this.projectName + '-VM.pdf'
        link.click();
      })
    }
    else if (type == 'excel') {
      this.apiService.downloadVMInExcel(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-VM.xlsx';
        link.click();
      })
    }
    else if (type == 'csv') {
      this.apiService.downloadVMInCsv(this.projectId, this.filterStatus).subscribe(data => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.download = this.projectName + '-VM.csv';
        link.click();
      })
    }

  }

  onSelectServer(type) {
    this.selectedServer = type.target.value;
    this.type.setValidators([Validators.required])
    if (this.selectedServer == 'DB') {
      this.typeVal = this.dbType;
    }
    else if (this.selectedServer == 'Web') {
      this.typeVal = this.webType;
    }
    else if (this.selectedServer == 'App') {
      this.typeVal = this.appType;
    }
    else {
      this.typeVal = []
      this.type.clearValidators();
    }
    this.type.updateValueAndValidity()
  }

  selectedValue(value) {
    if (value.property_value == "Standard_A8_v2") {
      this.diskTypeVal = this.diskValues.filter(el => el.property_value != "Premium_LRS")
    }
    else {
      this.diskTypeVal = this.diskValues;
    }
  }

  createVM() {
    this.windowsUserArray = []
    this.apiService.getToolById(this.network_config_vm.value.tool_id).subscribe((response: any) => {
      this.subscription_ID = response[0].tool_instance_details.network_config_tool_instance.azure_subscription_id;
      this.resourceGroup = response[0].tool_instance_details.network_config_tool_instance.resource_group;
      if(!response[0].tool_instance_details.network_config_tool_instance.vm_resource_group){
        this.VMresourceGroup = response[0].tool_instance_details.network_config_tool_instance.resource_group;
      }else{
        this.VMresourceGroup = response[0].tool_instance_details.network_config_tool_instance.vm_resource_group;
      }
      this.virtual_network = response[0].tool_instance_details.network_config_tool_instance.vnet;
      this.subnet = response[0].tool_instance_details.network_config_tool_instance.subnet;

      //  let validateBody = {
      //    "resource_group": this.resourceGroup,
      //    "subscription_id": this.subscription_ID ,
      //    "deployment_name": this.computerName.value,
      //    "project_id": this.projectId,
      //  }
      // this.apiService.validateVMname(validateBody).subscribe((data: any)=>{
      //   if (data.message == "VM can be added"){
      let userPsNumber = "";
      let userName = localStorage.getItem('profile');
      this.apiService.psNumber.subscribe((psNumber: any) => {
        userPsNumber = psNumber
        if (this.addUserIcon == true) { //for windows
          this.selectedUserArray.forEach(user => {
            let object = {
              "user_mail": user.user_email,
              "user_access": user.user_access
            }
            this.windowsUserArray.push(object)
          })

          this.vmRequestBody = {
            "request_type": "DeploymentStore-VM",
            "resource_type": "request",
            "request_status": "Pending",
            "requestor_id": userPsNumber,
            "requestor_name": userName,
            "project_details": {
              "project_id": this.projectId,
              "project_name": this.projectName,
              "catalog_id": "",
              "catalog_name": ""
            },
            "request_details": {
              "deployment_store_details": {
                "vm_details": {
                  "os_type": this.image.value.vm_os,
                  "validate_status": "Pending",
                  "server_type": this.image.value.vm_server,
                  "application_name": this.image.value.vm_type,
                  //   "deployment_name": this.computerName.value,
                  "resource_group": this.resourceGroup,
                  "vm_resource_group":this.VMresourceGroup,
                  "subscription_id": this.subscription_ID,
                  "vnet_name": this.virtual_network,
                  "subnet_name": this.subnet,
                  "location": this.location.value.property_value,
                  "os_disk_type": this.vmDiskType.value.property_value,
                  "vm_size": this.size.value.property_value,
                  "admin_username": this.userName.value,
                  "admin_password": this.password.value,
                  "classification": this.usage.value,

                  "bu_name": this.bu.value,
  
                  "db_hosted": this.dbHosted.value,
                  "image_reference": this.image.value.id,
                  "members": this.windowsUserArray
                }
              }
            },
            "created_by":localStorage.getItem('mail').toLowerCase()
          }
        }
        else { //for linux
          this.vmRequestBody = {
            "request_type": "DeploymentStore-VM",
            "resource_type": "request",
            "request_status": "Pending",
            "requestor_id": userPsNumber,
            "requestor_name": userName,
            "project_details": {
              "project_id": this.projectId,
              "project_name": this.projectName,
              "catalog_id": "",
              "catalog_name": ""
            },
            "request_details": {
              "deployment_store_details": {
                "vm_details": {
                  "os_type": this.image.value.vm_os,
                  "validate_status": "Pending",
                  "server_type": this.image.value.vm_server,
                  "application_name": this.image.value.vm_type,
                  //   "deployment_name": this.computerName.value,
                  "resource_group": this.resourceGroup,
                  "vm_resource_group":this.VMresourceGroup,
                  "subscription_id": this.subscription_ID,
                  "vnet_name": this.virtual_network,
                  "subnet_name": this.subnet,
                  "location": this.location.value.property_value,
                  "os_disk_type": this.vmDiskType.value.property_value,
                  "vm_size": this.size.value.property_value,
                  "admin_username": this.userName.value,
                  "admin_password": this.password.value,
                  "classification": this.usage.value,

                  "bu_name": this.bu.value,
  
                  "db_hosted": this.dbHosted.value,
                  "image_reference": this.image.value.id
                }
              }
            },
            "created_by":localStorage.getItem('mail').toLowerCase()
          }
        }
       // console.log(this.vmRequestBody);
        
        this.apiService.createVm(this.vmRequestBody).subscribe((vmResult: any) => {
          //  this.alertService.success('VM Request Created Successfully !', this.options);
          this.alertService.success(vmResult.message, this.options)
          let windowsMembers = [];
          this.selectedUserArray.forEach(el => {
            let membObj = {
              name: el.user_name,
              email: el.user_mail
            }
            windowsMembers.push(membObj)
          });

          // let tableObj =
          // {
          //   "request_id": vmResult.request_id,
          //   "os_type": this.image.value.vm_os,
          //   "location": this.location.value,
          //   "vm_size": this.size.value,
          //   //  "deployment_name": this.computerName.value,
          //   "status": "Pending",
          //   "ip_address": "--NA--",
          //   "members": windowsMembers
          // }
          //this.tableArr.push(tableObj)
          //this.vmData = this.tableArr
          this.getVMProject()
          this.showVmTable = true;
          this.showUserRow = false;
          this.showVm = false;
          this.dtTrigger.next();
          this.vmFormGroup.reset();
        }, (error: HttpErrorResponse) => {
          if (error.status == 435) {
            this.alertService.error(error.error.message, this.options);
          }
          else if (error.status == 403) { }
          else {
            this.alertService.error("Error creating VM request.", this.options);
            throw error;
          }

        })
      })

    })



  }
  getPasswordErrorMessage() {

    return this.vmFormGroup.controls.password.hasError('minlength') ? 'Password must be 14 characters long' : this.vmFormGroup.controls.password.hasError('pattern') ?
      'Password must include alteast one uppercase, one lowercase letter, one number and one special character' :
      'Password is required';
  }
  getconfirmPasswordErrorMessage() {
    return this.vmFormGroup.controls.confirmPassword.hasError('mustMatch') ?
      'Passwords must match' :
      'Confirm Password is required';
  }
  // getVMNameErrorMessage() {
  //   return this.vmFormGroup.controls.computerName.hasError('pattern') ?
  //     'VM Name must include only alphabets and numbers': this.vmFormGroup.controls.computerName.hasError('maxlength') ? 'Maximum length can be 15 characters':
  //     'VM Name is required';
  // }
  getUserNameErrorMessage() {
    return this.vmFormGroup.controls.userName.hasError('notMatch') ?
    'This Admin user name is not allowed' : this.vmFormGroup.controls.userName.hasError('maxlength') ? 'Maximum length can be 20 characters' : this.vmFormGroup.controls.userName.hasError('containsDot') ? 'Admin user name can not end in a period(".")' : this.vmFormGroup.controls.userName.hasError('pattern') ? 'Admin user name must not contain any special characters or only numbers' :
    'Admin user name is required';
    }

    getBUErrorMessage() {
      return this.vmFormGroup.controls.bu.hasError('notMatch') ?
      'This BU Name is not allowed' : this.vmFormGroup.controls.bu.hasError('maxlength') ? 'Maximum length can be 20 characters' : this.vmFormGroup.controls.bu.hasError('containsDot') ? 'BU Name can not end in a period(".")' : this.vmFormGroup.controls.bu.hasError('pattern') ? 'BU Name must not contain any special characters or only numbers' :
      'BU Name is required';
      }

}
//VDI refresh information pop up
@Component({
  selector: 'refresh-information-popup',
  templateUrl: 'refresh-information.html',
  styleUrls: ['./deployment-store.component.scss']
})

export class vmRefreshInformationPopUpDialog {
  req_id;
  reqID: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<vmRefreshInformationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    // this.reqID=data.req
  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
export interface RepoTable {
  dns_prefix: string;
  node_count: number;
  vm_size: string;
  cluster_name: string

}

@Component({
  selector: 'aks-vm-modal',
  templateUrl: 'aks-vm-modal.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class aksVmModal {
  deploymentFlag: boolean = false;
  constructor(public dialogRef: MatDialogRef<aksVmModal>, private apiService: AuthService) { }
  ngOnInit(): void {
    let newCatalog_id = localStorage.getItem('catalogId');
    this.apiService.getCatalogInformation(newCatalog_id).subscribe((data: any) => {
      if (data.deploymentstore_tools.length == 0) {
        this.deploymentFlag = true;
      }
    })
  }
  selectedOption(type) {
    if (type == 'cluster') {
      this.dialogRef.close('cluster');
    }
    else {
      this.dialogRef.close('VM');
    }
  }
  exit() {
    this.dialogRef.close();
  }

}

//Delete confirmation pop up
@Component({
  selector: 'delete-confirmation-popup-dialog',
  templateUrl: 'delete-confirmation-popup.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class vmdeleteConfirmationPopUpDialog {
  req_id;
  req: any;
  selectedOption(option) {
    if (option == 'Yes') {
      this.dialogRef.close('Yes');
    }
    else {
      this.dialogRef.close();
    }
  }


  constructor(
    public dialogRef: MatDialogRef<vmdeleteConfirmationPopUpDialog
    >,@Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) { 
    this.req=data.req

  }
  popUpClose(option){
    if (option == 'Yes') {
      // this.deleteSCM(this.req_id)
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'dialog-vm-size-info',
  templateUrl: 'vmsize-info.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class DialogVMSizeInfo {

  desk = false;
  vmSizeInfo: any;
  networkConfigData: any;
  vmImageInfo: any;
  type: any;
  deploymentFlag: string;
  constructor(
    private apiService: AuthService,
    public dialogRef: MatDialogRef<DialogVMSizeInfo>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.vmSizeInfo = data.vmInfo
  }
  ngOnInit() {
    this.deploymentFlag = localStorage.getItem('deploymentFlag');

    this.apiService.modalType.subscribe(data => {
      this.type = data;
    })
    this.apiService.VmImageInfo.subscribe((data: any) => {
      this.vmImageInfo = data;
    })
    this.apiService.networkConfigData.subscribe((data: any) => {
      this.networkConfigData = data;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}

//Cancel Confirmation pop up
@Component({
  selector: 'cancel-confirmation-popup-dialog',
  templateUrl: 'cancel-confirmation-info.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class storeCancelConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<storeCancelConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  popUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.yesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Workbench Confirmation pop up
@Component({
  selector: 'workbench-confirmation-popup-dialog',
  templateUrl: 'workbench-confirmation-info.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class storeWorkbenchConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<storeWorkbenchConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService
  ) {
  }
  workbenchPopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.workbenchYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

//Home Confirmation pop up
@Component({
  selector: 'home-confirmation-popup-dialog',
  templateUrl: 'home-confirmation-info.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class storeHomeConfirmationPopUpDialog {

  constructor(
    public dialogRef: MatDialogRef<storeHomeConfirmationPopUpDialog>, @Inject(MAT_DIALOG_DATA) public data: any,
    public alertService: AlertService,private spinner: NgxSpinnerService
  ) {
  }
  homePopUpClose(option) {
    if (option == 'Yes') {
      this.dialogRef.close();
      this.alertService.homeYesClicked('Yes is clicked!!');
    }
    else {
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface MemberDetails {
  user_name: string;
  user_email: string;
  role_name: string;
  user_status: string;
  user_access: string;
  checked: boolean
}
const ELEMENT_DATA: MemberDetails[] = [];
@Component({
  selector: 'vm-members',
  templateUrl: 'vm-members.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class VMMembers {
  projectId: any;
  projectName: any;
  members: any = [];
  newUser: any = [];
  removedUser: any = [];
  newArray: any = [];
  finalMemberArray: any = [];
  tableArray: any = [];
  newStatus: string;
  existingScmMembers: any;
  existingVdiMembers: any;
  existingRoleMembers: any;
  //ELEMENT_DATA: MemberDetails[];
  displayedColumns: string[] = ['user_name', 'user_email', 'user_access', 'select'];
  dataSource = new MatTableDataSource<MemberDetails>(ELEMENT_DATA);
  selection = new SelectionModel<MemberDetails>(true, []);
  componentName: any;
  isallSelected: boolean = false;
  accessVal = ["user"]
  selected = 'user'
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  existingVMMembers: any;
  secondFormGroup: FormGroup;
  VMModalType: any;
  AddMemberVMData: any;
  memberList: any = [];
  finalArray: any = [];
  AddfinalArray: any = [];
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  Role: string = "";
  vm_flag: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
    // return numSelected 
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MemberDetails): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.user_email + 1}`;
  }
  constructor(public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,private spinner: NgxSpinnerService, public router: Router, public dialogRef: MatDialogRef<VMMembers>) { }

  ngOnInit(): void {
    this.Role = localStorage.getItem("Role");
    if (this.Role == "User") {
      this.projectId = localStorage.getItem('projectId');
      this.projectName = localStorage.getItem('projectName');

      this.apiService.componentName.subscribe(componentName => {
        this.componentName = componentName;
      })

      this.apiService.selectedVDIMembers.subscribe((members: any) => {
        this.existingVdiMembers = members
      })
      this.apiService.VMModalType.subscribe((type: any) => {
        this.VMModalType = type
      })
      this.apiService.AddMemberVMData.subscribe((data: any) => {
        this.AddMemberVMData = data
      })

      if (this.VMModalType == 'Add') {
        this.getUser();
      } else {
        this.getUserToUpdate();
      }
    }
    else {
      this.router.navigateByUrl('/admin-scm-tool', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/admin-catalog-config']);
      });
    }
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  get user_access() {
    return this.secondFormGroup.get('user_access');
  }
  allSelected() {
    this.isallSelected = true
  }
  addUserForRepo() {
    if (this.VMModalType == 'Add') {
      if (this.isallSelected) {
        this.dialogRef.close(this.selection.selected);
      } else {
        if (this.componentName == "VM") {
          if (this.existingVdiMembers.length == 0) {
            this.dialogRef.close(this.selection.selected);
          } else {
            this.existingVdiMembers.forEach((member, indexi) => {
              for (var i = 0; i < this.selection.selected.length; i++) {
                if (member.user_email == this.selection.selected[i].user_email) {
                  this.existingVdiMembers[indexi]["duplicate"] = true
                  this.selection.selected[i]["duplicate"] = true
                  break;
                }
              }
            })
            let concatArray = this.selection.selected.concat(this.existingVdiMembers);
            this.AddfinalArray = []
            concatArray.forEach((object: any) => {
              if (!object.duplicate) {
                this.AddfinalArray.push(object)
              }
            })
            this.dialogRef.close(this.AddfinalArray);
            this.apiService.selectedVDIMembers.next([])
          }
        }

      }
    }

    if (this.VMModalType == 'Update') {
      this.addUserToExisting();
    }
  }

  deleteUserFromExisting() {
    let userPsNumber = "";
    let userName = localStorage.getItem('profile');
    this.apiService.psNumber.subscribe((psNumber: any) => {
      userPsNumber = psNumber
    })
    let memberArray = [];
    this.selection.selected.forEach(user => {
      memberArray.push(user.user_email)
    })
    let body = {
      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName
      },
      "request_details": {
        "request_id": this.AddMemberVMData.request_id,
        "requestor_name": userName,
        "requestor_id": userPsNumber,
        "members": memberArray
      }
    }

    this.apiService.deleteMemberFromVM(body).subscribe((data: any) => {
      this.dialogRef.close();
      this.router.navigateByUrl('/workBench', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/deployStore']);
      });
      this.alertService.success(data.message, this.options)

      // this.alertService.success("User offboarded successfully.", this.options);
    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.status, this.options);
      }
      else if (error.status == 403) { }
    })
    this.dialogRef.close()
  }


  addUserToExisting() {
    this.spinner.show()

    
    let addedMembers = [];
    this.selection.selected.forEach(element => {
      let tempObj = {
        "user_mail": element.user_email,
        "user_access": element.user_access
      }
      addedMembers.push(tempObj)
    })
    let userName = localStorage.getItem('profile');
    let body = {
      "project_details": {
        "project_id": this.projectId,
        "project_name": this.projectName
      },
      "request_details": {
        "request_id": this.AddMemberVMData.request_id,
        "requestor_name": userName,
        "subscription_id": this.AddMemberVMData.subscription_id,
        "resource_group": this.AddMemberVMData.resource_group,
        "vm_resource_group": this.AddMemberVMData.vm_resource_group,
        "deployment_name": this.AddMemberVMData.deployment_name,
        "os_type": this.AddMemberVMData.os_type,
        "server_type": this.AddMemberVMData.server_type,
        "application_name": this.AddMemberVMData.application_name,
        "ip_address": this.AddMemberVMData.ip_address,
        "members": addedMembers
      }
    }
    this.apiService.addUsersToExistingVM(body).subscribe((data: any) => {
      // this.alertService.success("Adding Member to VM process initiated.", this.options);
      // this.router.navigate(['/deployStore'])
      this.router.navigateByUrl('/workBench', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/deployStore']);
      });
      this.alertService.success(data.message, this.options)


      this.dialogRef.close();
      this.spinner.hide()
    }, (error: HttpErrorResponse) => {
      if (error.status == 445) {
        this.alertService.error(error.error.message, this.options);
        this.spinner.hide()

      }
      else if (error.status == 403) { 
        this.spinner.hide()

      }
      else {
        this.alertService.error("Error while adding members.", this.options);
        throw error;
        this.spinner.hide()

      }

    })
    this.dialogRef.close();
  }

  public getUser() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;

      this.members.forEach(member => {
        let obj = {
          "user_id": member.user_id,
          "user_name": member.user_name,
          "user_email": member.user_email,
          "role_name": member.role_name,
          "user_access": "user",
        }
        this.newArray.push(obj)
      })
      this.members = this.newArray;

      if (this.componentName == "VM") {
        this.members.forEach((member, index) => {
          member['checked'] = false
          for (var i = 0; i < this.existingVdiMembers.length; i++) {
            if (member.user_id == this.existingVdiMembers[i].user_id) {
              this.members[index]['checked'] = true
              break;
            }
          }
        })
      }

      this.dataSource.data = this.members as MemberDetails[]
    }, (error: HttpErrorResponse) => {
      if (error.status == 432) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
  }

  public getUserToUpdate() {
    this.apiService.getCatalogProjectById(this.projectId).subscribe(project => {
      this.members = project[0].members;
      this.memberList = this.AddMemberVMData.members;
      this.members.forEach((element, index) => { //alll members 2
        for (var i = 0; i < this.memberList.length; i++) {
          if (this.memberList[i].user_email == element.user_email) {
            this.memberList[i]["duplicate"] = true;
            this.members[index]["duplicate"] = true;
            break;
          }
        }

      });

      this.members.forEach((object: any) => {
        if (!object.duplicate) {
          this.finalArray.push(object)
        }
      }, (error: HttpErrorResponse) => {
        if (error.status == 432) {
          this.alertService.error(error.error.message, this.options);
        }
        else if (error.status == 403) { }
        else {
          this.alertService.error("No data available.", this.options);
          throw error;
        }

      })

      if (this.VMModalType === "Update") {
        this.memberList.forEach(member => {
          for (let i = 0; i < this.finalArray.length; i++) {
            if (member.name == this.finalArray[i].user_name) {
              this.finalArray.splice(i, 1);
            }
            else {
            }
          }
        });
      }
      let members = [];
      if (this.VMModalType === "Delete") {
        this.memberList.forEach(user => {
          let object = {
            "user_name": user.name,
            "user_email": user.email,
          }
          members.push(object)
        })
        this.finalArray = members
      }


      this.finalArray.forEach(member => {
        let obj = {
          "user_id": member.user_id,
          "user_name": member.user_name,
          "user_email": member.user_email,
          "role_name": member.role_name,
          "user_access": "user",
        }
        this.newArray.push(obj)
      })
      this.finalArray = this.newArray

      this.dataSource.data = this.finalArray as MemberDetails[]
    })
  }


  exit() {
    if (this.componentName == "VM") {
      this.dialogRef.close(this.existingVdiMembers);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }
}

//Show info popup component
@Component({
  selector: 'vm-info-modal',
  templateUrl: 'vm-request-info.html',
  styleUrls: ['./deployment-store.component.scss']
})
export class VMInfoModal {

  info: any;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<VMInfoModal>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.info = data.info
  }

  ngOnInit() {

  }

  exit(): void {
    this.dialogRef.close();
  }
  

}
export interface SoftwareDetails {
  icon: string;
  software_name: string;
  software_version: string;
  software_ritm: string;
  checked: boolean
}
const ELEMENT_DATAA: SoftwareDetails[]=[
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},



];
@Component({
  selector: 'install-software-dialog',
  templateUrl: 'install-software-vm.html',
  styleUrls: ['./deployment-store.component.scss']
}) 
export class softwareDialogVM {
  // ELEMENT_DATA: softwareDialog[];
  dataSource = new MatTableDataSource<SoftwareDetails>(ELEMENT_DATAA);
  displayedColumns: string[] = ['icon', 'software_name', 'software_version','software_ritm','select'];
  selection = new SelectionModel<SoftwareDetails>(true, []);
  isallSelected: boolean = false;
  installPopup:boolean=true
  requestPopup:boolean=false

  public userForm: FormGroup;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  softwareList: any=[];
  softwareArray: any=[];
  targets: any=[];
  requestFormNameErrorFlag: boolean=false;
  requestFormVersionErrorFlag: boolean=false;
  requestFormRitmErrorFlag:boolean=false;
  getList: boolean=false;



      /** Whether the number of selected elements matches the total number of rows. */
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SoftwareDetails): string {
      
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.software_version + 1}`;
    }
  constructor(private _fb: FormBuilder,public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.targets = data.member
    //this.targets = ["WindowsVM"];
    //console.log(this.targets[0].os)
    this.userForm = this._fb.group({
      software_request: this._fb.array([this.addAddressGroup()])
    });
  }
  
  ngOnInit(): void {
    this.getList=false

    let vmBody={
 

      "machine_details": 
  
          {
  
              "host": this.targets[0].host,
  
              "username": this.targets[0].username,
  
              "password": this.targets[0].password
  
          },
          "os": this.targets[0].os,
    
          "task": "install",
          "project_id":localStorage.getItem('projectId'),

  
  }
    
    this.apiService.installSoftwareListVDI(vmBody).subscribe((data: any) => {
      this.getList=true

      // this.softwareList.push(data)
      // debugger
      // console.log(this.softwareList);
      var i=1

      for (var prop in data.body) {
        // console.log("Key:" + prop);
        // console.log("Value:" + data[prop]);
        let body=  {icon: i, software_name: prop, software_version: data.body[prop], software_ritm: '',checked:false}

        i+=1
        this.softwareArray.push(body)
      }
      this.dataSource.data = this.softwareArray as SoftwareDetails[]

      
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })

}
  //Append Fields Set
  private addAddressGroup(): FormGroup {
    return this._fb.group({
      software_name: [],
      software_version: [],
      ritm:[]      
    });
  }
  //Add Fields
  addAddress(): void {
    // debugger
    this.requestFormNameErrorFlag=false
    this.requestFormVersionErrorFlag=false
    this.requestFormRitmErrorFlag=false

    let len=this.userForm.value.software_request.length
    if(this.userForm.value.software_request[len-1].software_name == null ||
      this.userForm.value.software_request[len-1].software_name == ''){
        this.requestFormNameErrorFlag=true
        // return

    }
    if(this.userForm.value.software_request[len-1].software_version == null ||
      this.userForm.value.software_request[len-1].software_version == ''){
        this.requestFormVersionErrorFlag=true
        // return

    }
    if(this.userForm.value.software_request[len-1].ritm == null ||
      this.userForm.value.software_request[len-1].ritm == ''){
        this.requestFormRitmErrorFlag=true
        // return

    }
    
    if(this.requestFormVersionErrorFlag || this.requestFormNameErrorFlag || this.requestFormRitmErrorFlag){
      

      return
    }
   
    
    // console.log(this.addressArray.length);
    
    this.addressArray.push(this.addAddressGroup());
  }

  //Remove Fields
  removeAddress(index: number): void {
    this.addressArray.removeAt(index);
  }
  //Fields Array
  get addressArray(): FormArray {
    return <FormArray>this.userForm.get('software_request');
  }
allSelected() {
  this.isallSelected = true
}
public doFilter = (value: string) => {
  this.dataSource.filter = value.trim().toLocaleLowerCase();
}
exit() {
  this.dialogRef.close();
}
softwareDetails(){
  
  let softwarefinalArray = [];
  this.selection.selected.forEach(obj=>{
    // console.log(obj);
    // softwarefinalArray.push(obj)
    
    // debugger
   let body={

     "software":obj.software_name,
    
    "version":obj.software_version,
    
    "ritm":obj.software_ritm,
   }
   softwarefinalArray.push(body)
  })
  // console.log(this.softwareArray);
  

  let finalBody={
    "targets":this.targets,
    "task":"install",
    
     "softwares":softwarefinalArray,
     "project_id":localStorage.getItem('projectId'),
    
    }

    //console.log(finalBody);
    this.apiService.postInstallSoftware(finalBody).subscribe((data: any) => {
      this.dialogRef.close();
     // console.log(data);
      

      this.alertService.success(data.body.message, this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
    this.dialogRef.close()
}
requestDetails(){
  this.installPopup=false
  this.requestPopup=true
}
// addRequestSoftware(){
//   this.requestFormNameErrorFlag=false
//   this.requestFormVersionErrorFlag=false

//   let len=this.userForm.value.software_request.length
//   if(this.userForm.value.software_request[len-1].software_name == null ||
//     this.userForm.value.software_request[len-1].software_name == ''){
//       this.requestFormNameErrorFlag=true
//       // return

//   }
//   if(this.userForm.value.software_request[len-1].software_version == null ||
//     this.userForm.value.software_request[len-1].software_version == ''){
//       this.requestFormVersionErrorFlag=true
//       // return

//   }
  
//   if(this.requestFormVersionErrorFlag || this.requestFormNameErrorFlag){
    

//     return
//   }
//   //console.log(this.userForm.value);
//   this.apiService.postRequestSoftware(this.userForm.value).subscribe((data: any) => {
//     this.dialogRef.close();
//     // console.log(data);
    

//     this.alertService.success(data.body.message, this.options);

//   }, (error: HttpErrorResponse) => {
//     if (error.status == 446) {
//       this.alertService.error(error.error.message, this.options);
//     }
//     else if (error.status == 403) { }
//     else {
//       this.alertService.error("No data available.", this.options);
//       throw error;
//     }

//   })
//   this.dialogRef.close()
// }
addRequestSoftware(){
  // console.log(this.userForm.value);
  let body={
    "machine_details":this.targets[0],
    "software_request":this.userForm.value.software_request
  }
  this.apiService.postRequestSoftware(body).subscribe((data: any) => {
    this.dialogRef.close();
    // console.log(data);
    

    this.alertService.success(data.body.message, this.options);

  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403) { }
    else {
      this.alertService.error("No data available.", this.options);
      throw error;
    }

  })
  this.dialogRef.close()
}
}
//////////////////////////////////////---------------------------->>>
export interface SoftwareDetailsUninstall {
  icon: string;
  software_name: string;
  software_version: string;
  checked: boolean
}
const ELEMENT_DATAA_Uninstall: SoftwareDetailsUninstall[]=[
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},
  // {icon: "1", software_name: 'Hydrogen', software_version: "22", software_ritm: 'dhhd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "33", software_ritm: 'jjdjd',checked:false},
  // {icon: "1", software_name: 'Oxygen', software_version: "44", software_ritm: 'jdjdj',checked:false},



];
@Component({
  selector: 'uninstall-software-dialog',
  templateUrl: 'uninstall-software-vm.html',
  styleUrls: ['./deployment-store.component.scss']
}) 
export class softwareDialogUninstallVM {
  // ELEMENT_DATA: softwareDialog[];
  dataSource = new MatTableDataSource<SoftwareDetailsUninstall>(ELEMENT_DATAA_Uninstall);
  displayedColumns: string[] = ['icon', 'software_name', 'software_version','select'];
  selection = new SelectionModel<SoftwareDetailsUninstall>(true, []);
  isallSelected: boolean = false;
  installPopup:boolean=true
  requestPopup:boolean=false

  public userForm: FormGroup;
  options = {
    autoClose: true,
    keepAfterRouteChange: true
  };
  softwareList: any=[];
  softwareArray: any=[];
  targets: any=[];
  requestFormNameErrorFlag: boolean=false;
  requestFormVersionErrorFlag: boolean=false;
  requestFormRitmErrorFlag:boolean=false;
  uninstallFlag:boolean=false;
  uninstallFlagList:boolean=false;



      /** Whether the number of selected elements matches the total number of rows. */
      isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
      }
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: SoftwareDetailsUninstall): string {
      
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.software_version + 1}`;
    }
  constructor(private _fb: FormBuilder,public dialog: MatDialog, public alertService: AlertService, private apiService: AuthService,  public router: Router, public dialogRef: MatDialogRef<SCMMembers>,@Inject(MAT_DIALOG_DATA) public data: any) { 
    this.targets = data.member
    //this.targets = ["WindowsVM"];
    //console.log(this.targets[0].os)
 
  }
  
  ngOnInit(): void {
    this.uninstallFlag=false;
    this.uninstallFlagList=false;
    // console.log(this.targets);
    // this.spinner2.show()
    
    let vdiBody={
 

      "machine_details": 
  
          {
  
              "host": this.targets[0].host,
  
              "username": this.targets[0].username,
  
              "password": this.targets[0].password
  
          },
          "os": this.targets[0].os,
    
          "task": "uninstall",
          "project_id":localStorage.getItem('projectId'),

  
  }

    this.apiService.installSoftwareListVDI(vdiBody).subscribe((data: any) => {
      // this.uninstallFlag=true
      // debugger
      // this.spinner2.hide()
      if(data.body.length==0 || data.body.length==undefined){
        this.uninstallFlagList=true
        
      }
      this.uninstallFlag=true
      
      // this.softwareList.push(data.body)
      // console.log(data.body[0][5]);
      
      // debugger
      // console.log(this.softwareList);
      /////////////////////////////////////////////
      var i=1

      for (var prop in data.body) {
        // console.log("Key:" + prop);
        // console.log("Value:" + data[prop]);
        let body=  {icon: i, software_name: prop,software_version: data.body[prop],checked:false}
        i+=1
        this.softwareList.push(body)
      }
      this.dataSource.data = this.softwareList as SoftwareDetailsUninstall[]

      //////////////////////////////////////////////
      // var i=0

      // for (let i = 0; i < this.softwareList[0].length; i++) {
      //   // console.log("Key:" + prop);
      //   // console.log("Value:" + data[prop]);
        
      //   let body=  {icon: i+1, software_name: this.softwareList[0][i],checked:false}
      //   if (this.softwareList[0][i].length>0){
      //     this.softwareArray.push(body)

      //   }
      //   i+=1

      // }
      // this.dataSource.data = this.softwareArray as SoftwareDetailsUninstall[]

      
    }, (error: HttpErrorResponse) => {
      if (error.status == 437){
        // this.spinner2.hide()

      this.alertService.error(error.error.message, this.options);
       }
       else if (error.status == 403){}
      else {
        // this.spinner2.hide()

      this.alertService.error("Unable to fetch data", this.options);
      throw error;
       }
      
       })


}
  //Append Fields Set

allSelected() {
  this.isallSelected = true
}
public doFilter = (value: string) => {
  this.dataSource.filter = value.trim().toLocaleLowerCase();
}
exit() {
  this.dialogRef.close();
}
softwareDetails(){
  // console.log(this.targets);
  
  let softwarefinalArray = [];
  this.selection.selected.forEach(obj=>{
    // console.log(obj);
    // softwarefinalArray.push(obj)
    
    // debugger
   let body={

     "software":obj.software_name,
    
    "version":obj.software_version,
    
   }
   softwarefinalArray.push(body)
  })
  // console.log(this.softwareArray);
  

  let finalBody={
    "task":"uninstall",

    "targets":this.targets,
    
     "softwares":softwarefinalArray
    
    }

    //console.log(finalBody);
    this.apiService.postInstallSoftware(finalBody).subscribe((data: any) => {
      this.dialogRef.close();
     // console.log(data);
      

      this.alertService.success(data.body.message, this.options);

    }, (error: HttpErrorResponse) => {
      if (error.status == 446) {
        this.alertService.error(error.error.message, this.options);
      }
      else if (error.status == 403) { }
      else {
        this.alertService.error("No data available.", this.options);
        throw error;
      }

    })
    this.dialogRef.close()
}
requestDetails(){
  this.installPopup=false
  this.requestPopup=true
}
// addRequestSoftware(){
//   this.requestFormNameErrorFlag=false
//   this.requestFormVersionErrorFlag=false

//   let len=this.userForm.value.software_request.length
//   if(this.userForm.value.software_request[len-1].software_name == null ||
//     this.userForm.value.software_request[len-1].software_name == ''){
//       this.requestFormNameErrorFlag=true
//       // return

//   }
//   if(this.userForm.value.software_request[len-1].software_version == null ||
//     this.userForm.value.software_request[len-1].software_version == ''){
//       this.requestFormVersionErrorFlag=true
//       // return

//   }
  
//   if(this.requestFormVersionErrorFlag || this.requestFormNameErrorFlag){
    

//     return
//   }
//   //console.log(this.userForm.value);
//   this.apiService.postRequestSoftware(this.userForm.value).subscribe((data: any) => {
//     this.dialogRef.close();
//     // console.log(data);
    

//     this.alertService.success(data.body.message, this.options);

//   }, (error: HttpErrorResponse) => {
//     if (error.status == 446) {
//       this.alertService.error(error.error.message, this.options);
//     }
//     else if (error.status == 403) { }
//     else {
//       this.alertService.error("No data available.", this.options);
//       throw error;
//     }

//   })
//   this.dialogRef.close()
// }
addRequestSoftware(){
  // console.log(this.userForm.value);
  let body={
    "machine_details":this.targets[0],
    "software_request":this.userForm.value.software_request
  }
  this.apiService.postRequestSoftware(body).subscribe((data: any) => {
    this.dialogRef.close();
    // console.log(data);
    

    this.alertService.success(data.body.message, this.options);

  }, (error: HttpErrorResponse) => {
    if (error.status == 446) {
      this.alertService.error(error.error.message, this.options);
    }
    else if (error.status == 403) { }
    else {
      this.alertService.error("No data available.", this.options);
      throw error;
    }

  })
  this.dialogRef.close()
}
}